import { Dispatch, SetStateAction, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button } from '@mui/material';
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikProvider,
  useFormik,
} from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { updateProfessional } from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { Professional } from 'src/types/professional';
import * as Yup from 'yup';

import RelatedOperationForm from './RelatedOperationForm';
import RelatedOperationsList from './RelatedOperationsList';

function ProfessionalRelatedOperations({
  openForm,
  setOpenForm,
  professional,
  professionalRefetch,
}: {
  openForm: boolean;
  setOpenForm: Dispatch<SetStateAction<boolean>>;
  professional: Professional | undefined;
  professionalRefetch: () => void;
}) {
  const dispatch = useDispatch();
  const [index, setIndex] = useState<number | undefined>();
  const [actionType, setActionType] = useState<
    'create' | 'update' | 'delete'
  >();

  const FORM_VALIDATION = Yup.object().shape({
    relatedOperations: Yup.array(
      Yup.object({
        operation: Yup.object({
          id: Yup.string().required('Campo obrigatório'),
        }),
        role: Yup.string().required('Campo obrigatório'),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      relatedOperations: professional?.relatedOperations ?? [],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const handleAddRelatedOperation = (arrayHelpers: FieldArrayRenderProps) => {
    setIndex(undefined);
    setOpenForm(true);
    setActionType('create');
    arrayHelpers.push({
      operation: {
        name: '',
      },
      role: '',
    });
  };

  const updateProfessionalMutation = useMutation(updateProfessional, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: `Não foi possível ${
              actionType === 'update'
                ? 'editar'
                : actionType === 'delete'
                  ? 'remover'
                  : 'adicionar'
            } a operação.`,
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: `Operação ${
              actionType === 'update'
                ? 'atualizada'
                : actionType === 'delete'
                  ? 'removida'
                  : 'adicionada'
            } com sucesso.`,
          }),
        );
      }
      setOpenForm(false);
      setActionType(undefined);
      professionalRefetch();
    },
  });

  const handleUpdate = async (values: any) => {
    if (professional?.id) {
      await updateProfessionalMutation.mutateAsync({
        id: professional.id,
        values: {
          data: {
            relatedOperations: values,
          },
        },
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="relatedOperations"
        render={(arrayHelpers) => (
          <Box sx={{ padding: '32px 24px 24px' }}>
            <If condition={!openForm}>
              <Button
                startIcon={<AddCircleIcon color="primary" fontSize="large" />}
                color="secondary"
                onClick={() => handleAddRelatedOperation(arrayHelpers)}
                sx={{
                  textTransform: 'none',
                  letterSpacing: 'unset',
                  textDecoration: 'underline !important',
                  fontSize: '16px',
                  fontWeight: 700,
                  padding: '16px 8px',
                  color: '#FF8211',
                }}>
                Adicionar operação
              </Button>
              <If condition={formik.values.relatedOperations?.length > 0}>
                <RelatedOperationsList
                  rows={formik.values.relatedOperations}
                  index={index}
                  arrayHelpers={arrayHelpers}
                  setIndex={setIndex}
                  setOpen={setOpenForm}
                  setActionType={setActionType}
                  handleUpdate={handleUpdate}
                />
              </If>
            </If>
            <If condition={openForm}>
              <RelatedOperationForm
                open={openForm}
                onClose={() => setOpenForm(false)}
                formik={formik}
                index={index ?? formik.values.relatedOperations.length - 1}
                arrayHelpers={arrayHelpers}
                actionType={actionType}
                handleUpdate={handleUpdate}
              />
            </If>
          </Box>
        )}
      />
    </FormikProvider>
  );
}

export default ProfessionalRelatedOperations;
