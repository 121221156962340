import { gql } from '@apollo/client';

export const GET_CRA_CHARACTERISTICS_BY_OPERATION_HASH_ID = gql`
  query GetCraCharacteristicsByOperationHashId($hash: String!) {
    getCraCharacteristicsByOperationHashId(hash: $hash) {
      operationName
      operationProduct
      operationCreditRiskCategory
      operationDebtor
      operationTypeofAgreement
      operationProduct
      operationFeedbackESG
    }
  }
`;

export const GET_CRA_ASSET_DATA_BY_OPERATION_HASH_ID = gql`
  query getCraAssetDataByOperationHashId($hash: String!) {
    getCraAssetDataByOperationHashId(hash: $hash) {
      competence
      totalCreditoryRights
      totalDueByDeadline
      totalUnpaidOverdue
      totalPeriodPrepayments
    }
  }
`;

export const GET_CRA_ASSIGNORS_BY_OPERATION_HASH_ID = gql`
  query GetCraAssignorsByOperationHashId(
    $hash: String!
    $page: Int!
    $pageSize: Int!
  ) {
    getCraAssignorsByOperationHashId(
      hash: $hash
      page: $page
      pageSize: $pageSize
    ) {
      assignors {
        name
        cnpj
        strapiId
        slug
        market
      }
      pages
      total
    }
  }
`;

export const GET_FIDC_ASSIGNORS_BY_OPERATION_HASH_ID = gql`
  query GetFidcAssignorsByOperationHashId($hash: String!) {
    getFidcAssignorsByOperationHashId(hash: $hash) {
      assignors {
        name
        cnpj
        strapiId
        slug
        market
      }
    }
  }
`;
