import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { Professional } from 'src/types/professional';

type Props = {
  open: boolean;
  professional?: Professional;
  handleClose: () => void;
  handleUpdate: (values: any) => void;
  isLoading: boolean;
};

function AboutForm({
  open,
  professional,
  handleClose,
  handleUpdate,
  isLoading,
}: Props) {
  const formik = useFormik({
    initialValues: {
      profileText: professional?.profileText ?? '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional) {
        const rest = _.omit(professional, 'id');
        handleUpdate({
          data: {
            ...rest,
            ...values,
          },
        });
      }
    },
  });

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={(e) => {
        formik.handleReset(e);
        handleClose();
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '16px 0',
        },
      }}
      sx={{
        margin: '0 auto',
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          Editar biografia
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            minWidth: '750px',
            [theme.breakpoints.down('md')]: {
              minWidth: 'unset',
            },
          })}>
          <TextAreaProfile
            name="profileText"
            label="Biografia resumida"
            value={formik.values.profileText}
            handleChange={formik.handleChange}
            placeholder="Escreva um texto sobre você e suas habilidades profissionais"
            variant="outlined"
            maxCaracters={2660}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '18px 24px', gap: 2 }}>
          <Hidden smDown>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar edição'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                handleClose();
              }}
              disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar edição'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar edição'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    handleClose();
                  }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar edição'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AboutForm;
