import { useEffect, useState } from 'react';

import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import OperationCharts from 'src/components/OperationCharts';
import SubTitle from 'src/components/v2/SubTitle';
import OperationTableByDueRange from 'src/pages/Operation/OperationTableByDueRange';
import { WalletReceivable } from 'src/types/operation';
import { convertNumber, convertValue } from 'src/utils/number';

export default function OperationCRIAssetReceivablesDue({
  data,
  isLoading,
  operationName,
  selectOptions,
  hasPermission,
}: {
  data: WalletReceivable[];
  isLoading: boolean;
  operationName: string;
  selectOptions: string[];
  hasPermission: boolean | undefined;
}) {
  const [period, setPeriod] = useState('');
  const market = 'cri';
  const indicator = 'recebíveis a vencer';

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string);
  };

  const selectedData = data?.find(
    (option: any) => option.dataReferencia === period,
  );

  useEffect(() => {
    if (selectOptions?.length) {
      setPeriod(selectOptions[0]);
    }
  }, [selectOptions]);

  const handleExport = () => {
    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      filename: `${operationName}_${market}_${indicator}`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Data de referência',
        'A vencer',
        'Até 30',
        '31 a 60',
        '61 a 90',
        '91 a 120',
        '121 a 150',
        '151 a 180',
        '181 a 360',
        'Acima de 361 dias',
        'Nome da operação',
      ],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (data) {
      const dataToExport = data.map((row: WalletReceivable) => ({
        dataReferencia: format(
          parse(row.dataReferencia, 'yyyy-MM-dd', new Date()),
          'MM/yyyy',
        ),
        aVencer: convertNumber(row.aVencer ?? 0),
        aVencerAte30Dias: convertNumber(row.aVencerAte30Dias ?? 0),
        aVencer31A60Dias: convertNumber(row.aVencer31A60Dias ?? 0),
        aVencer61A90Dias: convertNumber(row.aVencer61A90Dias ?? 0),
        aVencer91A120Dias: convertNumber(row.aVencer91A120Dias ?? 0),
        aVencer121A150Dias: convertNumber(row.aVencer121A150Dias ?? 0),
        aVencer151A180Dias: convertNumber(row.aVencer151A180Dias ?? 0),
        aVencer181A360Dias: convertNumber(row.aVencer181A360Dias ?? 0),
        aVencerAcima361Dias: convertNumber(row.aVencerAcima361Dias ?? 0),
        operationName,
      }));

      csvExporter.generateCsv(dataToExport);
    }
  };

  const series = [
    {
      data: data
        ?.sort((a, b) => {
          return a.dataReferencia > b.dataReferencia
            ? 1
            : a.dataReferencia < b.dataReferencia
              ? -1
              : 0;
        })
        ?.map((item) => item.aVencer ?? 0),
    },
  ];

  const xAxisCategories = selectOptions?.length ? [...selectOptions] : [];

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: 'ReceivablesDue',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#E0E0E0',
          fillColor: '#E0E0E0',
          opacity: 0,
        },
      ],
    },
    noData: {
      text: 'Nenhum dado encontrado',
    },
    xaxis: {
      categories: xAxisCategories
        ?.sort((a, b) => {
          return a > b ? 1 : a < b ? -1 : 0;
        })
        .map((date) =>
          format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/yyyy'),
        ),
    },
    colors: ['#FDA85E'],
    tooltip: {
      theme: 'dark',
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => convertValue(value),
      },
      marker: {
        show: false,
      },
    },
  };

  const getNormalizedData = () => {
    return data?.map((item: WalletReceivable) => ({
      ate30dias: item.aVencerAte30Dias ?? 0,
      '31a50dias': item.aVencer31A60Dias ?? 0,
      '61a90dias': item.aVencer61A90Dias ?? 0,
      '91a120dias': item.aVencer91A120Dias ?? 0,
      '121a150dias': item.aVencer121A150Dias ?? 0,
      '151a180dias': item.aVencer151A180Dias ?? 0,
      '181a360dias': item.aVencer181A360Dias ?? 0,
      acima361dias: item.aVencerAcima361Dias ?? 0,
      total: item.aVencer ?? 0,
      dataReferencia: item.dataReferencia,
    }));
  };

  if (!data) return null;

  return (
    <Box>
      <SubTitle title="Recebíveis a vencer" />
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {hasPermission ? (
            <>
              <OperationCharts
                title="Recebíveis a vencer"
                subtitle={`${convertValue(selectedData?.aVencer ?? 0)}`}
                valueSelect={period}
                handleChangeSelect={handleChange}
                type="area"
                series={series}
                options={options}
                selectOptions={selectOptions}
                permission="operacoes_cri_recebiveis_a_vencer_e_np_e_pre_pgto_exportar"
                handleExport={handleExport}
                hasData={data && data?.length > 0}
                enabledExport={true}
              />
              <OperationTableByDueRange
                data={getNormalizedData()}
                selectOptions={selectOptions}
                title="Por faixa de vencimento"
              />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
}
