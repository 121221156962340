// Material ui components
import { Box, Grid } from '@material-ui/core';

// Global components
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import Subtitle from '../../../../../components/Subtitle';

export default function OperationCRILiabilityData({ data, loading }) {
  const totals = data.reduce(
    (acc, curr) => ({
      totalIssued: acc.totalIssued + curr.amountIssued,
      totalDebtor: acc.totalDebtor + curr.debitBalance,
    }),
    { totalIssued: 0, totalDebtor: 0 },
  );

  return (
    <div>
      <Subtitle title="Dados" />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg>
            <PaperPanelInfo
              numeric
              title="Saldo Devedor"
              value={totals.totalDebtor}
              date={data.debitBalanceDate}
              dateTitle="Em"
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg>
            <PaperPanelInfo
              numeric
              title="Total Emitido"
              value={totals.totalIssued}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
