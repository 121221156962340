import { PageData } from '../types';

export const pageData: PageData[] = [
  {
    title: 'Abertura',
    subtitle: '',
    text: [
      `A Resolução CVM 175 (RCVM 175), publicada em dezembro de 2022, reformulou as regras para Fundos de Investimento em Direitos Creditórios (FIDC) no Brasil. A nova norma praticamente refunda o marco regulatório do veículo. Entretanto, não é possível dizer que o mercado estivesse particularmente desejoso ou dependente dessa alteração regulatória.  Em 2022, o patrimônio líquido desses fundos ultrapassou a marca de R$ 300 bilhões, um recorde histórico, e o número de fundos em atividade também bateu recorde, superando a marca de 1700.
    Os FIDC são fundos de investimento que adquirem direitos creditórios originados dos mais diversos segmento econômicos, e começam a ser descobertos por um número cada vez maior de empresas e pessoas físicas, como os dados a seguir mostrarão. 
    Um segmento que começa a despontar no mercado de FIDC são os Fundos de Investimento em Direitos Creditórios do Agronegócio (FIAGROs). Criados em 2020 pela Lei nº 14.130, esses fundos permitem que os produtores rurais obtenham recursos com base em direitos creditórios decorrentes de suas atividades. 
    Em resumo, a RCVM 175 e o crescimento do mercado de FIDC têm atraído cada vez mais investidores interessados em diversificar suas carteiras e ou captar recursos. O recorde de patrimônio líquido e número de fundos em atividade, além do surgimento dos FIAGROs, indicam que esse mercado continuará em expansão nos próximos anos.
    `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Primário',
    subtitle: '',
    text: [
      `
      Os números a respeito do mercado primário de FIDC revelam o quanto de “dinheiro novo” está ingressando no mercado. Um olhar para a composição desses números, a seu turno, indica o destino desse dinheiro. Nesse quesito, enquanto o montante emitido em cotas de FIDC em 2022 não foi suficiente para superar 2021, mas o situou na segunda melhor marca histórica. Da cifra emitida, chama a atenção a preponderância das emissões relacionadas aos FIDC de Crédito Pessoal, como se verá adiante.
    `,
      `
      <b>Emissões de FIDC</b>
      As emissões de FIDCs no mercado primário registraram uma retração, totalizando R$ 95,3 bilhões em montante emitido, o que representa uma queda de 15,30% em relação ao recorde anterior de 2021 (R$ 112,52 bilhões). No entanto, em comparação com 2020, o ano de 2022 apresentou um aumento de 29,97% no montante emitido, e em relação a 2019, o aumento foi de 16,98%. Além disso, em comparação com 2018, o ano de 2022 registrou um aumento significativo de 119,23% no montante emitido.
    `,
      `
      <b>Emissões de FIDC por tipo de Condomínio</b>
      No mercado de FIDCs em 2022, o tipo de condomínio mais representativo continuou sendo o  Fechado, como nos anos anteriores, respondendo por 64,53% das emissões. Em comparação com 2021, houve uma queda de 2,38 p.p. Já os fundos do tipo de Condomínio Aberto representaram 35,47% das emissões em 2022, registrando um aumento de 2,39 p.p. em relação a 2021.
      
    `,
      `
      <b>Emissões de FIDC Padronizados perdem espaço em 2022</b>
      Em 2022, a maioria das emissões foi executada por FIDCs da modalidade Padronizada, representando 59,01% do montante total. Os 40,98% restantes foram emitidos por meio de FIDCs da modalidade Não Padronizada. Essa predominância é equivalente ao averiguado em 2021, quando aos Padronizados corresponderam a 71,89% do total de emissões. A preferência histórica dos Padronizados se justifica por ser mais palatável aos investidores, além do fato que os requisitos para investimento em cotas de FIDC NP serem mais restritivos. Por outro lado, as emissões de FIDC NP, que desfrutam de um caráter anticíclico, também fluem ao sabor das condições conjunturais do mercado.
    `,
      `
      <b>Recebíveis Comerciais é o destaque como Ativo Lastro em 2022</b>
      Em 2022, os Recebíveis Comerciais continuam sendo o destaque como ativo lastro no mercado de FIDCs, representando 27,24% do montante emitido. Houve uma queda de 7,20% em relação ao ano anterior. O Crédito Pessoal registrou um crescimento de 18,93% em comparação a 2021, respondendo por 26,79% do total. Já a Multiclasse de ativo-lastro teve uma participação de 12,32%, o que representa uma retração de 10,15% em relação a 2021.
    `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Secundário',
    subtitle: '',
    text: [
      `Ao contrário de outros títulos de securitização, as cotas de FIDC não registram uma trajetória consistente no mercado secundário. Além de situarem em patamar bastante inferior ao de CRI, por exemplo, parte dos indicadores de liquidez do mercado secundário de FIDC no mercado de balcão organizado da B3 apresentaram redução entre 2022 e 2021.
    `,
      `
      <b>Indicadores de liquidez do secundário de FIDC caminham em direções opostas</b>
      Os dados do mercado secundário de cotas de FIDC revelam uma diminuição (-19,72%) no valor financeiro negociado em 2022 em comparação com o ano anterior, apesar de um aumento significativo no número de negócios realizados. Em 2021, foram realizados 14.049 negócios, resultando em um valor financeiro total de R$ 12,07 bilhões. Já em 2022, houve um aumento considerável (51,71%) no número de negócios, com um total de 21.314, mas o valor financeiro negociado diminuiu para R$ 9,69 bilhões. A despeito dos indicadores se movimentando em sentido contrário, isso pode ser indicativo de uma maior liquidez no mercado secundário, permitindo que os investidores realizem mais negociações, mesmo que em volumes menores.
    `,
    ],
    authors: [],
  },
  {
    title: 'Estoque',
    subtitle: '',
    text: [
      `O estoque de FIDC, medido pelos valores de patrimônio líquido, revela um significativo crescimento desse indicador no último ano. O valor auferido em dezembro de 2022 faz empalidecer qualquer patrimônio líquido de anos anteriores.  Ao mesmo tempo, não se registra nenhum súbita mudança na composição desses números em 2022. Porém, em uma escala mais longa de tempo, percebe-se que alguns ativos-lastro de FIDC perderam participação, enquanto outros ressurgiram ou despontaram.
    `,
      `
      <b>Patrimônio líquido dos FIDCs em 2022</b>
      Apesar dos desafios enfrentados pela economia doméstica e internacional, o mercado de FIDCs encerrou 2022 com um recorde no patrimônio líquido, atingindo R$ 308,09 bilhões. O mercado também registrou um recorde em termos de quantidade, com 1722 fundos no total. Em comparação com dezembro de 2021, o patrimônio líquido e o número de FIDCs aumentaram em 35,87% e 28,03%, respectivamente.
      `,
      `
      <b>Condomínio fechado predomina o mercado de FIDCs</b>

      Em 2022, o tipo de condomínio fechado continuou a predominar no mercado de FIDCs, representando 81% do patrimônio líquido, ou seja, cerca de R$ 250 bilhões em PL. Já o condomínio aberto representou 19% do PL, totalizando em torno de R$ 58 bilhões. Em dezembro de 2021, o tipo de condomínio fechado correspondia a 84% do PL, o que representa uma queda de 3,70% em relação a dezembro de 2022. Naquela época, o condomínio aberto totalizava 16% do PL.
      `,
      `
      <b>FIDCs Padronizados representam a maior parte do patrimônio</b>

      Os FIDCs Padronizados, que se enquadram na ICVM 356 em relação aos direitos creditórios, responderam por 60% do patrimônio líquido (PL) dos FIDCs em dezembro de 2022, enquanto os FIDCs Não-Padronizados (cujo direitos creditórios são diversos e não aderentes à ICVM 356) representaram 40% do PL. Historicamente, os FIDCs padronizados têm maior participação no mercado de FIDCs. De acordo com o gráfico ao lado, o mês de fevereiro de 2019 foi o que registrou a maior participação desses FIDCs, atingindo 50%.
      `,
      `
      <b>PL de FIDC por Ativo-lastro</b>

      Em 2022, os FIDCs de Recebíveis Comerciais tiveram um desempenho destacado em termos de patrimônio líquido (PL), correspondendo a 35,7% do PL total dos FIDCs. Em segundo lugar, os FIDCs Multiclasse somaram 14,6% do PL, seguidos pelos FIDCs de Crédito Pessoal, que ocuparam o terceiro lugar, com 11,6% do PL. Historicamente, tem sido observado que os FIDCs de Recebíveis Comerciais têm a maior participação percentual na composição do PL dos FIDCs.
      `,
      `
      <b>PL dos FIDC Multicedente Multissacado</b>
      No ano de 2022, os FIDCs multicedente-multissacado (MM), que têm carteira composta direitos creditórios de diversos cedentes e sacados, apresentaram um patrimônio líquido de R$ 28,40 bilhões registrando um aumento de 41,29% em comparação com dezembro de 2021. O número de FIDCs também aumentou para 379, o que representa uma alta de 11,47% em relação ao final de 2021. Em relação a dezembro de 2020, o patrimônio líquido dos FIDCs MM teve um aumento de 34,72% e a quantidade desses fundos cresceu 34,88%.
      `,
    ],
    authors: [],
  },
  {
    title: 'Rankings Uqbar',
    subtitle: '',
    text: [
      `O mercado de FIDC é uma alternativa de financiamento amplamente utilizada por diversos setores da economia, consolidando-se como o principal veículo de securitização no Brasil. Comparado a outros mercados de securitização, o mercado de FIDCs apresenta um estágio mais avançado, contando com um conjunto diversos de prestadores de serviços, por exemplo, o que, consequentemente, se reflete em uma variedade maior de rankings. Como é comum em rankings que utilizam o critério de patrimônio líquido, a Uqbar divide os rankings em duas categorias: os fundos que iniciaram suas operações em 2022 (ranking Anual) e todos os fundos operacionais no final desse ano (ranking Acumulado).
    `,
      `<b>Ranking FIDCs por Montante Emitido</b>

    O mercado primário possui um ranking de FIDCs por Montante Emitido, com um total de 1.003 classificados, que atingiu a marca de R$ 95,35 bilhões. Os três primeiros colocados do ranking representam uma concentração de 20,14%, sendo que o líder é o Empírica Noverde EP, tendo emitido R$ 7,79 bilhões. O FIDC de Crédito Pessoal é seguido pelo Perseus NP, com R$ 6,24 bilhões, correspondendo a 6,55% do total. A terceira posição é ocupada pelo For-Te, com R$ 5,11 bilhões, correspondendo a 5,36%. 
    `,
      `<b>Ranking de Assessores Jurídicos por Montante Emitido</b>  

    O ranking mencionado apresenta 14 Assessores Jurídicos ranqueados e um montante emitido de R$ 9,66 bilhões. A seguir, detalharemos os cinco primeiros colocados: Pinheiro Neto ficou em primeiro lugar, com R$ 3,00 bilhões, representando 31,08% do total; A VBSO Advogados ficou em segundo lugar, com R$ 2,16 bilhões, representando 22,41% do total; A terceira posição ficou com Machado Meyer, que atingiu a cifra de R$ 2,10 bilhões, ou 21,76%; Na quarta posição está Mayer Brown, com R$ 838,36 milhões, ou 8,68%; E, na quinta posição, Barcellos Tucunduva com R$ 577,56 milhões, ou 5,98%. Com esses dados, percebe-se que 75,24% do total de montante emitido está concentrado nos três primeiros assessores jurídicos do ranking.
    `,
      `<b>Ranking de Assessores Jurídicos por Número de Operações</b>  

    O ranking em questão apresentou 14 Assessores Jurídicos e um total de 58 operações, destacando a liderança da Barcellos Tucunduva com 17 operações (29,31% do total). Os três primeiros colocados no ranking, que incluem também VBSO e Pinheiro Neto, somam 60,36% das operações realizadas. Em quarto e quinto lugares, estão Martinelli Advocacia Empresarial e Mayer Brown, respectivamente, cada um com 5 e 3 operações.
    `,
      `<b>Ranking de Agências de Classificação de Risco por Montante Classificado</b>  

    No ranking apresentado, o montante de R$ 23,06 bilhões em 2022 representou uma queda de 13,83% em relação ao ano anterior. A lista inclui 5 instituições, sendo que a Liberum Ratings lidera com R$ 10,26 bilhões (44,48%), seguida pela S&P com R$ 7,52 bilhões (32,63%) e a Moodys em terceiro lugar com R$ 3,35 bilhões (14,51%). Os três primeiros ranqueados concentram 91,62% do total.
    `,
      `<b>Ranking de Agências de Classificação de Risco por Número de Títulos Classificados</b> 

    Foram classificados um total de 256 títulos e identificadas 5 instituições no ranking de Agências de Classificação de Risco por número de Títulos Classificados. A Liberum Rating lidera com 150 títulos, o que corresponde a 58,59% do total. Em segundo lugar, a S&P com 41 títulos (16,02%) e a Austin em terceiro com 40 títulos (15,63%). As três primeiras instituições do ranking concentram 90,24% do total de títulos classificados.
    `,
      `<b>Ranking de Estruturadores por Montante Emitido</b> 

    No ano de 2022, foram emitidos R$ 2,24 bilhões, com a participação de 4 instituições no ranking. O Banco Itaú BBA lidera com R$ 2,10 bilhões, representando 93,92% do montante emitido. Em segundo lugar, está o Banco Crédit Agricole Brasil com R$ 95,33 milhões, correspondendo a 4,26%. E, na terceira posição, temos a Terra Investimentos com R$ 30,70 milhões, representando 1,37%. Desse modo, é possível observar que as três primeiras instituições do ranking concentram 99,55% do montante emitido.
    `,
      `<b>Ranking de Estruturadores por Número de Operações</b>

    No ranking de Estruturadores por Número de Operações, foram identificadas 5 operações realizadas por 4 instituições. O Banco Itaú BBA lidera o ranking com 2 operações, correspondendo a 40,00% do total, seguido pelo Banco Crédit Agricole Brasil, Terra Investimentos e Reag DTVM, cada um com 1 operação, correspondente a 20,00%. Portanto, conclui-se que 80,00% do total de operações estão concentrados nas 3 primeiras instituições do ranking.
    `,
      `<b>Ranking FIDC por Montante de PL</b>

    O ranking de FIDCs por Montante de PL do mercado de capitais totalizou R$ 38,78 bilhões em 2022, um aumento de 1,54% em relação a 2021, quando era de R$ 38,18 bilhões. Os três primeiros colocados do ranking são responsáveis por 29,46% do total: a Perseus NP lidera com R$ 6,26 bilhões, correspondente a 16,15%, seguida pelo Santander Flex, com R$ 2,59 bilhões, correspondente a 6,69%, e Maldivas NP, com R$ 2,57 bilhões, correspondente a 6,64%.
    `,
      `<b>Ranking FIDC por Montante de PL - Acumulado</b>

    O ranking de todos os FIDC em atividade, ordenados por montante de patrimônio líquido, registra 1711 ranqueados, totalizando R$ 307,96 bilhões. Esse valor representa um aumento de 37,22% em relação ao ano anterior, quando o montante era de R$ 224,42 bilhões. A concentração de patrimônio líquido fica em 10,07% nas três primeiras posições, que são ocupadas por:
    
    * Tapso, líder com R$ 15,81 bilhões, correspondente a 5,13% do total;
    * Cielo, na 2ª posição, com R$ 8,74 bilhões, correspondente a 2,84%;
    * Perseus NP, na 3ª posição, com R$ 6,26 bilhões, correspondente a 2,03%.
    `,
      `<b>Ranking Administradores por Montante de PL</b>

    O ranking de administradores do mercado de FIDC apresenta 31 instituições ranqueadas e um montante de R$ 38,78 bilhões. Em comparação a 2021, houve um aumento de 1,54%. Os três primeiros colocados detêm 50,44% do total, sendo que a BRL Trust DTVM lidera o ranking com R$ 8,71 bilhões, correspondentes a 22,46%. A Indigo DTVM ocupa a segunda posição com R$ 6,30 bilhões, correspondentes a 16,24%. Já o Banco Daycoval ocupa a terceira posição com R$ 4,56 bilhões, correspondentes a 11,75%.
    `,
      `<b>Ranking de Administradores por Número de Operações</b> 

      Com um total de 337 operações e 31 empresas ranqueadas, as seguintes instituições foram identificadas no ranking de Administradores por Número de Operações:
      
      * Singulare, com 87 operações, correspondendo a 25,44% do total;
      * Banco Daycoval, com 28 operações, correspondendo a 8,19%; e
      * BRL Trust DTVM, com 23 operações, correspondendo a 6,73%.
      
      Dessa forma, conclui-se que as três primeiras instituições do ranking concentraram 39,65% do número total de operações.
      `,
      `<b>Ranking de Administradores por PL - Acumulado</b>
      O ranking de administradores por montante de PL de todos os FIDC em atividade é composto por 49 instituições e totalizou a cifra de R$ 307,96 bilhões, representando um aumento de 37,22% em relação a 2021, quando o montante girava em torno de R$ 224,42 bilhões. É importante destacar que os três primeiros colocados do ranking concentram quase metade do total, ou seja, 48,95%. A liderança é ocupada pela Oliveira Trust, com R$ 72,09 bilhões, correspondente a 23,41% do total. Em segundo lugar está a Singulare, com R$ 44,10 bilhões, o que equivale a 14,32% do montante. Na terceira posição, aparece a BRL Trust DTVM, com R$ 34,51 bilhões e 11,21% do total.
      `,
      `<b>Ranking de Administradores por Número de Operações - Acumulado</b>
      O Ranking de Administradores de FIDCs por número de operações em atividade apresentou, no final de 2022, o total de 1.704 operações ativas e um total de 49 ranqueados. A média de operações por ranqueado ficou em torno de 35. O top 3 de Administradores compõe 43,52% de participação no ranking. A instituição que aparece em primeiro lugar é a Singulare, com 491 operações ativas, somando assim um total de 28,81% na composição do ranking. Em seguida figura a BRL Trust DTVM, somando 9,45% do total do ranking com um total de 162 operações ativas. E, em terceiro lugar, Oliveira Trust, com 90 operações ativas, equivalendo assim a 5,28% do total do ranking.
      `,
      `<b>Ranking de Auditores por Montante de PL - Acumulado</b>
      O Ranking de Auditores de FIDCs por montante de patrimônio líquido (PL) dos fundos ativos em dezembro de 2022 registra perfaz R$ 245,21 bilhões e um total de 31 empresas de auditoria ranqueadas. A média do PL por empresa ficou em torno de R$ 7,91 bilhões. O top 3 de Auditores ficou concentrado em 57,82% do total do ranking. KPMG, no primeiro lugar, com R$ 62,43 bilhões de patrimônio líquido por operações ativas, totalizando 25,46% de participação no ranking. Em seguida, Next Auditores, somando 19,76% de participação no ranking, com PL de R$ 48,46 bilhões por operações ativas. E, em terceiro lugar, EY, com R$ 30,91 de PL por operações ativas, somando um total de 12,61% do ranking.
      `,
      `<b>Ranking de Auditores por Número de Operações - Acumulado</b>
      O Ranking de Auditores de FIDCs por número de operações em atividade resultou, no ano de 2022, no total de 1257 operações ativas e 31 empresas de auditoria ranqueadas. Houve em média 40,55 operações por empresa. As três empresas que mais se destacaram em quantidade de operações somaram juntas o equivalente a 63,88% de participação no ranking. No primeiro lugar, Next Auditores, com um total de 512 operações, valor este que equivale a 40,67% do ranking. Em seguida, BDO RCS Auditores Independentes, com 164 operações, totalizando 13,03% de participação no ranking. E, em terceiro lugar, KPMG, com 140 operações ativas, somando 11,12% de participação.
      `,
      `<b>Ranking de Custodiantes por Montante de PL em 2022</b>
        O Ranking de Custodiantes de FIDCs por montante de patrimônio líquido (PL) computou, no ano de 2022, a cifra de R$ 38,72 bilhões de PL dos fundos que entraram em operação em 2022, distribuídos em um total de 32 empresas ranqueadas. Comparado com 2021, o PL subiu em 1,41%. Em 2022, a média do PL ficou em torno de R$ 1,17 bilhão por participante. O top 3 de Custodiantes concentrou-se em 50,28% do total do ranking, sendo a BRL Trust DTVM a primeira colocada com montante de PL em R$ 8,67 bilhões. Em 2021 a Oliveira Trust ficou em primeiro lugar com R$ 14,55 bilhões de PL.
      `,
      `<b>Ranking de Custodiantes por Número de Operações em 2022</b> 
      O Ranking de Custodiantes de FIDCs por número de Operações em atividade em 2022 registrou o total de 335 operações ativas, 8,74% maior que em 2021. As operações estão distribuídas em um total de 32 empresas ranqueadas. A média de operações por participante ficou em 10,18. As três empresas que compuseram o top 3 do ranking somaram juntas 38,50% de participação e são: Singulare (com 83 operações), Banco Daycoval (com 26 operações) e BRL Trust DTVM (com 20 operações).
      `,
      `<b>Ranking de Custodiantes por Montante de PL - Acumulado</b> 
      O Ranking de Custodiantes de FIDCs por montante de PL dos fundos operacionais apresentou o total de R$ 307,89 bilhões, 37,19% a mais que em 2021. As operações estão distribuídas em um total de 55 empresas ranqueadas. A média de operações por participante ficou em R$ 5,60 bilhões. As 3 primeiras colocadas somaram juntas 48,28% no nível de concentração do ranking. São elas: Oliveira Trust (com R$ 70,90 bilhões de PL), Singulare (com R$ 44,20 bilhões de PL) e BRL Trust DTVM (com R$ 33,57 bilhões de PL).
      `,
      `
      <b>Ranking de Custodiantes por Número de Operações - Acumulado</b> 
      O Ranking de Custodiantes de FIDCs por número de operações em atividade registrou, em dezembro de 2022, o total de 1709 operações ativas com um total de 55 ranqueados. A média de operações por participante ficou em 31,07. As três empresas que compuseram o top 3 do ranking somaram juntas 43,69% de participação e são: Singulare (com 496 operações e 29,02% de participação), BRL Trust DTVM (com 156 operações e 9,13% de participação) e Oliveira Trust (com 95 operações e 5,56% de participação). Comparado com 2021, o número de operações teve um aumento de 29,76%.
      `,
      `
      <b>Ranking de Gestores por Montante de PL em 2022</b> 
      O Ranking de Gestores de FIDCs por montante de patrimônio líquido (PL) dos fundos que entraram em operação em 2022 apresenta a cifra de R$ 38,78 bilhões, 1,54% maior que em 2021, e um total de 121 gestores ranqueados. A média do PL por gestor ficou em torno de R$ 320,49 milhões. O top 3 de Gestores ficou concentrado em 34,66% do total do ranking. Sueste Capital Gestão de Recursos, no primeiro lugar, com R$ 6,26 bilhões de patrimônio líquido por operações ativas, somando 16,08% de participação no ranking. A seguir aparece a Genial Gestão, totalizando 9,55% de participação no ranking, com PL de R$ 3,72 bilhões por operações ativas. E, em terceiro lugar, Acura Gestora de Recursos, com R$ 3,48 bilhões de PL por operações ativas, somando um total de 8,92% do ranking. 
      `,
      `
      <b>Ranking de Gestores por Número de Operações em 2022</b> 
      O Ranking de Gestores de FIDCs por número de operações que iniciaram em 2022 perfez o total de 343 operações ativas e 121 gestores ranqueados. Houve em média 2,83 operações por empresa. O top 3 gestores por quantidade de operações apresentou o equivalente a 13,72% de participação no ranking. O lider  No primeiro lugar, Tercon Investimentos, com um total de 20 operações, valor este que equivale a 5,83% do ranking. Em seguida, Ouro Preto Gestão de Recursos, com 14 operações, totalizando 4,08% de participação no ranking. E, em terceiro lugar, Aura Gestora de Recursos, com 13 operações ativas, somando 3,79% de participação.  
      `,
      `
      <b>Ranking de Gestores por Montante de PL - Acumulado</b> 
      O Ranking de Gestores de FIDCs por montante de patrimônio líquido (PL) dos fundos ativos registrou, em dezembro de 2022, R$ 308,03 bilhões e um total de 256 gestores ranqueados. Comparado com dezembro de 2021, observa-se um aumento de 37,23% no PL e de 28% no número de gestores ranqueados. A média do PL por empresa ficou em torno de R$ 1,20 bilhão. O top 3 de Gestores ficou concentrado em 22,96% do total do ranking tendo a Oliveira Trust Servicer no primeiro lugar, com R$ 28,42 bilhões de patrimônio líquido por operações ativas, totalizando 9,22% de participação no ranking. Em seguida, Oliveira Trust, somando 8,15% de participação no ranking, com PL de R$ 25,12 bilhões por operações ativas. E, em terceiro lugar, Genial Gestão, com R$ 17,18 bilhões de PL por operações ativas, totalizando 5,58% de participação no ranking. 
      `,
      `<b>Ranking de Gestores por Número de Operações - Acumulado</b> 
      O Ranking de Gestores de FIDCs por número de operações em atividade computou, em dezembro de 2022, o total de 1714 operações ativas com um total de 256 ranqueados. A média de operações por gestor ficou em 6,68. Os três gestores que compuseram o top 3 do ranking somaram juntos 17,09% de participação e são: Tercon Investimentos (com 161 operações e 9,39% de participação), Ouro Preto Gestão de Recursos (com 80 operações e 4,67% de participação) e PETRA Capital Gestão de Investimentos (com 53 operações e 3,09% de participação). 
      `,
    ],
    authors: [],
  },
  {
    title: 'RESOLUÇÃO CVM 175',
    subtitle:
      'Os avanços do novo marco regulatório dos Fundos de Investimentos para a construção de um mercado mais seguro, competitivo e eficiente',
    text: [
      `Ao apagar das luzes do ano de 2022, a CVM editou a Resolução CVM nº 175 (“RCVM 175”), o novo marco regulatório de Fundos de Investimentos, resultado da Audiência Pública SDM nº 08/20, que substituirá as já conhecidas Instruções CVM nºs 356 e 555, além de outras 36 normas, consolidando todas as matérias sobre o assunto em uma única resolução.
    A mudança já vinha sendo cobrada pelo mercado há bastante tempo e busca incorporar as inovações trazidas pela Lei de Liberdade Econômica (“LLE”), publicada em 20 de setembro de 2019, bem como refletir melhorias necessárias para um mercado mais seguro, competitivo e eficiente. Segundo João Pedro Nascimento, atual presidente da CVM, “[a] nova regra de Fundos de Investimento retrata a relevância de um ambiente regulatório sólido e funcional para esta indústria tão importante do Mercado de Capitais do Brasil. (...) Além disso, um arcabouço único e objetivo facilita o entendimento e contribui na redução do chamado custo de observância regulatória. Menos custos, mais oportunidades.”.
    A resolução, que entra em vigor em abril de 2023, será estruturada em dois grandes blocos, uma parte geral, que trata de assuntos mais abrangentes e aplicáveis a todos os fundos de investimentos, e uma segunda parte composta de anexos com regras específicas para cada tipo de fundo de investimento. Por ora, a CVM já disponibilizou os anexos normativos relativos aos Fundos de Investimentos Financeiro (FIFs) e aos Fundos de Investimentos em Direitos Creditórios (FIDCs), enquanto os demais anexos serão editados pela CVM até abril/2023, incluindo os Fundos de Investimento Imobiliários (FIIs), os Fundos de Investimento nas Cadeias Produtivas Agroindustriais (FIAgros) e os Fundos de Investimento em Participações (FIPs).
    <strong>Mas, afinal, o que mudou?</strong> Veja abaixo as alterações mais relevantes introduzidas pela RCVM 175 e quando eles começam a valer: 

    <b>CLASSES E SUBCLASSES DE COTAS: vários Fundos de Investimento dentro de um único Fundo de Investimento</b>
    A grande inovação ficou por conta da nova estrutura permitida aos Fundos de Investimentos, possibilitando a existência de diferentes classes de cotas, que contarão com patrimônios segregados e poderão prever direitos e obrigações distintos.
    Com isso, os agentes de mercado não precisarão mais constituir diversos Fundos de Investimento, buscando atender as mais variáveis políticas de investimento, basta constituir uma nova classe, que poderá ter as seguintes características:
    <ul>
      Regime de distribuição;
      Responsabilidade do cotista;
      Público-alvo;
      Estratégias de investimentos;
      Prazos e condições de aplicação, amortização e resgate; e,
      Prestadores de serviços adicionais.
    </ul>
    Vale dizer que, caso o Fundo possua em sua estrutura mais de uma classe de cotas, cada uma deverá contar com um registro de funcionamento próprio, bem como denominação e CNPJ individualizados, permitindo, assim, a destinação de seus ativos e passivos para patrimônios separados, de modo que não se comuniquem entre si.
    Além da possibilidade de classes, será possível também a separação em subclasses, que compartilharão entre si o patrimônio comum a classe, mas que poderão se diferenciar nos seguintes aspectos:
    <ul>
      • Público-alvo;
      • Prazos e condições de aplicação, amortização e resgate; e,
      • Prestadores de serviços adicionais.
    </ul>
    O desafio dessa mudança está na capacidade do mercado em viabilizar essa dinâmica operacional de segregação, especialmente das registradoras e demais depositárias que ficarão responsáveis pela guarda desses ativos. Muito em razão dessas dificuldades, a CVM prolongou a entrada em vigor dessa regra, que passará a valer somente em 1º de abril de 2024.
    <b>RESPONSABILIDADE LIMITADA: maior segurança e previsibilidade aos investidores</b>
    Um dos avanços trazidos pela LLE e transportado para a nova regulamentação foi a limitação da responsabilidade dos cotistas ao valor de suas cotas, ou seja, ao contrário do que acontecia anteriormente, em que o cotista poderia ser chamado para cobrir eventual patrimônio líquido negativo do Fundo, agora, caso essa situação ocorra, não haverá mais a possibilidade de chamada de aportes adicionais junto aos cotistas, devendo os prestadores essenciais elaborar um plano de resolução do patrimônio líquido.
    Assim, a partir de abril, os fundos deverão deixar claro que a responsabilidade dos cotistas está limitada ao valor por eles subscritos, incluindo na denominação da classe de cotas do Fundo o sufixo “Responsabilidade Limitada”.
    No caso de a responsabilidade ser ilimitada, o cotista deve atestar formalmente que está ciente dos riscos decorrentes desta decisão, o que traz maior previsibilidade de eventuais perdas.
    <b>OBRIGATORIEDADE DE REGISTRO OU DEPÓSITO CENTRALIZADO: segurança e eficiência no controle dos ativos</b>
    A partir de 1º de abril de 2023, data em que entra em vigor a maior parte das regras estabelecidas pela RCVM 175, todos os ativos adquiridos pelos Fundos de Investimento deverão estar registrados em sistemas de registro autorizadas pelo BACEN ou ser objeto de depósito centralizado, conforme a natureza do ativo, devendo o respectivo registro ou depósito ser realizado em contas específicas, abertas diretamente em nome da classe titular, bem como o ativo estar identificado por ISIN (Internacional Securities Identification Number) ou por outro código capaz de caracterizá-lo de forma individualizada.
    Assim, a título exemplificativo, apesar da Lei nº 14.195/2021 permitir que Notas Comerciais sejam apenas escrituradas, o Fundo de Investimento que tenha interesse em investir em um determinado ativo que esteja nesta situação, deverá diligenciar para que seja efetivado o seu depósito central.
    <b>FIDC: é chegada a hora de escalar!</b>
    As inovações mais relevantes da RCVM 175, certamente, dizem respeito aos Fundos de Investimento em Direitos Creditórios, os chamados FIDCs. Isso porque, a antiga norma que regulamentava os FIDCs, a Instrução CVM nº 356, era datada de 2001 e já estava bem ultrapassada e obsoleta, ainda mais considerando um mercado que se renova a cada segundo.
	  Dentre as principais novidades, destacamos as seguintes: 
    <ul>
      • Abertura dos FIDCs para distribuição aos investidores varejo;
      • Fim da modalidade de FIDC Não Padronizado, podendo o FIDC constituir classes de cotas específicas destinadas à aquisição de créditos não padronizados;
      • Verificação do lastro sob responsabilidade do gestor, que poderá contratar terceiros para essa atividade, que poderá ser realizada tanto pelo custodiante, como na regra anterior, mas também por outros players, como os responsáveis pela guarda dos ativos (registradoras ou depositárias);
      • O administrador desenvolverá uma fiscalização mais pontual, cabendo ao gestor ou até mesmo o consultor especializado a responsabilidade pela negociação dos ativos, incluindo as ordens de compra e celebração dos respectivos instrumentos;
      • Liberação do modelo originate-to-sell, podendo ser afastada a vedação de aquisição de direitos creditórios originados ou cedidos pelo administrador, gestor, consultoria especializada ou partes a eles relacionadas;
      • Contratação de agência classificadora de risco de crédito apenas para classe ou subclasse de cotas que forem destinadas ao público varejo;
    </ul>
    Com essa série de inovações, especialmente a abertura para investidores varejo e a possibilidade de redução dos custos pela eficiência operacional, é possível arriscar que o mercado de FIDCs, que hoje conta com um público de 35 mil investidores, venha a se tornar a maior classe de Fundos de Investimentos, podendo expandir seu alcance ao patamar dos FIIs, atualmente com 1,9 milhão de investidores.
    `,
    ],
    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/jessica-cristina-mota/1304">Jéssica Mota</a>',
        description:
          'Advogada com mais de 15 anos de experiência nos mercados financeiros, de capitais e imobiliário. Mestranda em Economia Setorial e Mercados de Real Estate pela Escola Politécnica da USP (POLI/USP). Membro da Comissão de Direito Imobiliário do Instituto dos Advogados de São Paulo (IASP), das Comissões de Crédito e Garantias Imobiliárias e Mercado de Capitais do Instituto Brasileiro de Direito Imobiliário (IBRADIM) e da Associação Brasileira de Lawtechs e Legaltechs (AB2L). ',
        image: 'Mota.png',
      },
    ],
  },
  {
    title:
      'A Utilização de FIDCs aliada a Soluções de Tecnologia na Estratégia Financeira das Empresas Brasileiras',
    subtitle: '',
    text: [
      `
      No Brasil, segundo dados do Sebrae, a cada 4 empresas, 1 fecha antes de completar 2 anos de existência no mercado. Ainda que existam problemas relacionados à instabilidade econômica dos últimos anos, o excesso de burocracia ou erros de racionalidade na hora de lidar com as potencialidades do mundo digital. A verdade é que muitas dessas falências tem causa primordial em problemas de fluxo de caixa, pequenas e médias empresas costumam ter dificuldades de organizar as próprias contas numa sociedade com um grande volume de compras em crédito. 
      Em 2018, por exemplo, levantamento da Social Miner estimou que 30% das compras foram parceladas no cartão em 3 vezes e 22% em 5. Enquanto, para o cliente, isso representa uma simples comodidade, para as empresas o lento retorno pode comprometer o fluxo de caixa em face de necessidades de investimento de capital ou mesmo despesas correntes. Não resta dúvida que resolver problemas de fluxo de caixa e oferta de crédito a seus clientes e consumidores se tornaram dois grandes gargalos para a sobrevivência da maior parte das empresas. 
      O aumento da aproximação das Fintechs, Indústrias e Varejo junto aos FIDCs tem possibilitado o surgimento de novas soluções para lidar com esses problemas. Saiba um pouco mais sobre as plataformas existentes aqui
      À medida com que qualquer empresa cresce, por consequência suas despesas, necessidade de funding, o aumento na oferta de crédito para seus clientes para sustentação das vendas e crescimento da rede de lojas, se torna uma preocupação latente, que ultrapassa o âmbito das PME, nas grandes empresas, precisam se preocupar com a manutenção de um fluxo de caixa saudável para os seus fornecedores. Neste contexto,<strong> as Plataformas de Crédito, baseadas em Soluções Tecnológicas </strong> despontam como uma ótima ferramenta para trabalhar o alongamento do contas a pagar de forma competitiva bem como no aumento e melhora da competitividade da oferta de crédito ao consumidor final, sem onerar seus custos com fornecedores ou aumento do risco de inadimplência. Assim, os fornecedores podem antecipar a fatura do que tem a receber com uma taxa mais adequada, podendo vender a prazo, melhorando assim o a gestão do seu fluxo de caixa.
      Felizmente, o boom das Fintechs no Brasil tem aumentado a oferta de serviços disponíveis para resolver situações como esta. Entre as soluções disponíveis, pode se destacar as <strong>plataformas tecnológicas para operações de crédito </strong>que têm sido utilizadas como uma ferramenta para solucionar esse tipo de dificuldade. Nessa modalidade, as Fintechs podem operar como financiadores da Cadeia Produtiva das empresas conectando-as com o mercado de capitais, que proporciona uma oferta de taxas mais competitivas, com foco na agilidade e na experiência do cliente e usuário. Outro caminho de grande sucesso no Brasil no segmento Industrial, são as estruturações de FIDCs proprietários, utilizando inicialmente o próprio capital da empresa para financiar a cadeira produtiva e com isso ter uma melhora efetiva na gestão do seu fluxo de caixa e ainda proporcionar uma entrega de produtos mais adequados à cada realidade, com menos burocracia e maior flexibilidade do que em uma Instituição financeira tradicional.
      Já no varejo, dentre as inúmeras preocupações, uma das principais, é o aumento de consumo de sua base de clientes de forma estruturada para que possam se manter competitivos e manter a inadimplência o mais controlada possível, melhorar a oferta de crédito será um dos grandes impulsionadores de crescimento neste mercado. Uma estratégia de sucesso comprovada nos últimos anos é a constituição de FIDCs para financiar as operações de Cartão de Crédito Private Label e Pré-pago seu foco é aumentar o fomento e consumo dos clientes, por meio de Plataformas de Tecnológicas conectadas ao ecossistema dessas operações, o varejista conseguirá através deste veículo de securitização de ativos, atender as necessidades de seu negócio, através da:
      <ol>
          • Adequação a modalidade de cobrança de juros (uma vez que a maior parte dessas empresas não são instituições financeiras homologadas no Bacen e por isso restritas a concessão de crédito – principal fonte de ganho de uma Administradora de Cartão)
          • Ferramenta para captação com alto nível de governança – através das soluções de crédito mais modernas, permite-se um acompanhamento e controle da carteira a nível de CPF e transação, mesmo para as carteiras mais pulverizadas, uma vez que esses serviços são prestados por empresas especializadas garante ao investidor um acompanhamento preciso da saúde financeira da carteira,
          • Melhor Gestão tributária – uma vez que os acionistas dessas empresas são os primeiros e principais cotistas desses FIDCs, permite-se uma melhor gestão tributária e agregam segurança durante o processo de captação, tornando esse tipo de FIDCs altamente atrativos para investidores,
      </ol>
      Todas essas estratégias tem por finalidade o aumento da oferta de produtos de crédito e por sua vez o aumento do consumo dos usuários desses cartões nas redes de varejos atreladas, o que melhoram as vendas e por meio de estratégias combinadas com as plataformas de Risco Sacado – onde o FIDC proprietário pode ser o principal financiador, o que garante uma melhor gestão de fluxo de caixa e com isso todo o funding necessário para aumentar suas vendas, seja na próprio varejo ou nas administradoras de cartões dessas companhias.
      O Brasil possui centenas de empresas de meios de pagamento relacionadas a este segmento e, agora, com as atualizações das regras e normativas do setor, a estratégia de ter um FIDC como veículo financeiro se torna cada vez mais atrativo e os números falam por si só, toda a capacidade de execução e robustez tecnológica que essas empresas têm buscado e investido, estão proporcionando eficácia, crescimento sustentável e melhor gestão do capital de giro. 
      Na iDtrust, temos orgulho de fazer parte dessa mudança no mercado brasileiro. Desde 2016, desenvolvemos soluções tecnológicas para o mercado financeiro. É o caso, por exemplo, do Finanfor e Financard, como nossas soluções tecnológicas com foco no mercado de crédito que permite que as empresas consigam acesso à credito de forma mais adequada proporcionando uma ferramenta que irá ajudar melhorar de fluxo de caixa, e um excelente meio de captação e alavancagem na oferta de produtos crédito a seus clientes. 
      Somente em 2022 a iDtrust facilitou a concessão de mais de R$74 bi em crédito, tendo conectado em sua plataforma + de 70 Agentes Financeiros, milhares de Fornecedores e dezenas de sacados onde junto das operações de nossa carteira de clientes formada por empresas como Nubank, Neon, Mercado Pago, Petrobras, Corteva, Mosaic, Lojas Torra, entre muitas outras, negociamos na Plataforma mais de 300 milhões de unidades de CCBs, tendo isso refletido em milhões de CPFs de Brasileiros, tendo com isso sua oferta de crédito ampliada e o consumo fomentado.
      Tem muita coisa bacana acontecendo no mercado de Fintech e FIDCs no Brasil. É preciso ter um olhar atento para as dores das empresas, para construir boas soluções, que tornem a vida das empresas mais rápida, prática, eficiente e conectada.
            
      
      `,
    ],
    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/junior-santos/1317">Junior Santos</a>',
        description:
          'Engenheiro de produção, MBA em Inovação e Transformação Digital pelo IGTA, Certificação em Foresight Especialização em Mercado de Capitais e Fintechs pela FGV SP- Graduado e Membro do Founders Institute Brasil, Mentor na Singularity University, CEO e Co-Founder da iDtrust e palestrante junto a ANFAC e Sinfacs de todo o Brasil.',
        image: 'JuniorSantos.jpg',
      },
    ],
  },
  {
    title: 'FIDC, 20 ANOS DEPOIS',
    subtitle:
      'Passados mais de 20 anos desde a criação dos fundos de investimento em direitos creditórios (FIDC), a CVM autoriza, com a Resolução 175, o investimento em cotas seniores para o público em geral. ',
    text: [
      `Após anos de consultas ao mercado e da audiência pública, a Comissão de Valores Mobiliários editou a Resolução 175 que atualizou e unificou as regras aplicáveis a todas as classes de fundos de investimento. 
    A CVM dividiu a norma entre as regras gerais, aplicáveis a todos os tipos de fundos, e as regras específicas, na forma de anexos à resolução dedicados cada qual a uma determinada categoria. O Anexo II da Resolução aplica-se aos FIDCs, substituindo a Instrução 356, publicada no final de 2001. 
    No âmbito geral, entre as principais mudanças nas regras de funcionamento dos fundos, destacam-se: a) a possibilidade dos fundos declararem insolvência; b) a possibilidade de se estipular no regulamento do fundo que a responsabilidade dos cotistas está limitada ao valor do capital aportado; e  c) a permissão para a criação, dentro de um fundo, de classes com patrimônio segregado e a criação de subclasses que poderão conceder direitos distintos aos respectivos cotistas.   
    Entre as inovações especialmente para os fundos de direitos creditórios, podemos citar como principais: a) a possibilidade do público em geral (investidores de varejo) investirem em cotas da subclasse sênior de FIDCs, ressalvadas as hipóteses previstas no anexo e comentadas adiante; b) a obrigatoriedade do registro centralizado dos direitos creditórios em instituição autorizada a funcionar pelo BACEN; c) a transformação dos fundos em classes e das atuais classes em subclasses; d) o aumento da responsabilidade do gestor no processo de estruturação e operacional do fundo. 
    Para os FIDCs, a alteração que trará maior impacto é, sem dúvida, a permissão de que as cotas da classe sênior possam ser ofertadas ao público em geral. A CVM demorou 21 anos, desde a criação desse tipo de fundo em 2001, para permitir o acesso dos investidores de varejo aos fundos de recebíveis. Até então, somente os investidores qualificados e profissionais podiam investir em FIDCs.
    Mesmo assim, essa permissão não veio sem algumas ressalvas. A distribuição de cotas ao público em geral requer o cumprimento cumulativo dos seguintes requisitos: I – o público em geral não pode adquirir cotas subordinadas; II – o regulamento deve estipular um cronograma para amortização de cotas ou distribuição de dividendos; III – caso se trate de classe aberta, o prazo de carência, em conjunto com o prazo total entre o pedido de resgate e seu pagamento, não podem totalizar um prazo superior a 180 dias; IV – a política de investimento não pode admitir a aplicação em: a) direitos creditórios que sejam originados por contratos mercantis de compra e venda de produtos, mercadorias e serviços para entrega ou prestação futura, exceto se os cedentes forem: 1. Empresas concessionárias de serviços públicos; ou 2. Companhias constituídas para implementar projetos de investimento na área de infraestrutura ou de produção econômica intensiva em pesquisa, desenvolvimento e inovação, considerados prioritários na forma regulamentada pelo Poder Executivo Federal; b) direitos creditórios originados ou cedidos pelo administrador, gestor, consultoria especializada, custodiante, entidade registradora dos direitos creditórios e partes a ele relacionadas; e V – a subclasse de cotas seniores seja objeto de classificação de risco por agência classificadora de risco registrada na CVM. 
    Ainda assim, a permissão para a realização de aplicações, pelo público em geral, em cotas seniores, será possível para um número grande de fundos de recebíveis, que já se enquadram ou se enquadrarão nos critérios acima, o que levará esse tipo de fundo a um outro patamar. Em pouco tempo, os FIDCs deverão se tornar tão populares quanto os FIIs e conseguirão atrair, em 6 anos ou mais anos, mais investidores do que os fundos imobiliários e o mercado de ações. 
    Isso ocorrerá pelas seguintes razões: o tamanho do mercado de crédito que pode ser abrangido por esse tipo de fundo, o grande número de operações que poderão ser lançadas e a diversidade de estruturas que poderão ser criadas. Além disso, os retornos oferecidos deverão superar as rentabilidades de quaisquer outros papéis tradicionais de renda fixa, assim como os prêmios pagos aos distribuidores serão maiores e as possibilidades de mitigação de riscos e de agregação de garantias estruturais ou externas trarão maior segurança para o investidor; tudo isso tornará as cotas seniores dos FIDCs em um produto de investimento cada vez mais atraente para o público em geral.  
    Acredito que não demorará muito tempo até que o FIDC chegue a figurar entre as 3 principais classes de fundos em número de cotistas e de PL entre as diversas classes de fundos existentes.`,
    ],
    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/joao-baptista-peixoto-neto/969">João Peixoto</a>',
        description:
          'Empresário, sócio-diretor da Ouro Preto Investimentos e sócio da Peixoto Neto Sociedade de Advogados. É graduado pela Faculdade de Direito da Universidade de São Paulo (USP). Cursou História, Economia e fez mestrado em Direito Internacional na USP, e é especialista em Produtos Financeiros e Gestão de Riscos pela FIA/FEA/USP. Nos anos de 2006 a 2017, esteve entre os primeiros colocados na categoria de estruturação de fundos de recebíveis no Brasil (FIDCs) pelo ranking da empresa UQBAR. Responsável pela estruturação de mais de 300 fundos de investimento estruturados (FIP, FIDC e FII).',
        image: 'Peixoto.jpg',
      },
    ],
  },
  {
    title:
      'Investir no futuro: como fundos de ESG se tornaram a nova tendência para investimentos sustentáveis',
    subtitle: '',
    text: [
      `À medida que o mundo muda, também mudam os negócios e os investimentos. Um relatório recente do BNP Paribas, que conversou com mais de 1.000 indivíduos de alto poder aquisitivo em 19 países diferentes para descobrir seus interesses em matéria de investimento, teve um retorno esclarecedor. A ação relacionada à mudança climática se destaca como meta de investimento sustentável mais importante, considerada a preocupação número um para um terço dos entrevistados, logo à frente de trabalho digno e crescimento econômico, e de energia limpa e acessível, em segundo e terceiro lugares.
    Essa tendência acompanha as somas de dinheiro cada vez maiores dedicadas a iniciativas sociais e ambientais. O ESG, sigla em inglês para Ambiental, Social e Governança, está entre os setores de investimento que crescem mais rapidamente. O critério ESG é um conjunto de padrões dentro das operações de uma empresa que permite que investidores com consciência social examinem e avaliem um potencial investimento.
    Mas qual é realmente a melhor maneira de criar impacto com seu negócio? Fundamentalmente, a resposta a essa pergunta é diferente para cada empreendedor. Um investimento com retorno seguro e ganhos sociais e ambientais consistentes pode atrair um, enquanto um payout financeiramente arriscado de um projeto de mais impacto pode despertar o interesse de outro – ou mesmo um investimento puramente filantrópico talvez faça mais sentido.
    Se as ações climáticas são a principal preocupação dos empreendedores, as instituições financeiras terão que se esforçar mais para fornecer produtos e serviços relevantes. Se os investidores se interessam atualmente por investimentos sustentáveis e impactantes, eles também têm inseguranças quanto à sua viabilidade e retorno sobre o risco. E isso antes de levar em consideração as variações geográficas.
    <b>Tendência global</b>
    Nos últimos dois anos, o mercado de investimento sustentável cresceu 34%, chegando a US$ 30,7 trilhões. Mais de três quartos dos empresários na Europa, Ásia e Estados Unidos afirmam que já estão envolvidos ou estariam interessados em ter um maior envolvimento em investimentos sustentáveis. E, sendo os empreendedores mais jovens os mais preocupados com questões sustentáveis, podemos esperar uma aceleração desse movimento.
    A nível mundial, 33% dos empresários elegem a ação climática como um dos cinco principais Objetivos de Desenvolvimento Sustentável da ONU. O tema é importante para todas as gerações, com os Baby Boomers (42%) mais propensos a essa opinião que os Millenials (37%). As ações relacionadas à mudança climática são a prioridade na Europa; nos Estados Unidos, o acesso à energia limpa de custo acessível; e na Ásia, promover o crescimento inclusivo.  
    Mais de 22% dos detentores de investimentos sustentáveis os avaliam como uma parte “muito arriscada” de seu portfólio, enquanto que 58% acredita que os investimentos sustentáveis demandam o sacrifício do retorno a longo prazo. Essas preocupações da parte dos investidores merecem ser levadas a sério e abordadas de frente.
    Melhorar a visibilidade do ESG será central para atrair potenciais investidores na Europa, particularmente na Itália, no Reino Unido, na Bélgica e na Espanha. Porém, nos Estados Unidos, os empreendedores ainda não têm certeza se esses investimentos são “mainstream”: 43% gostaria de ter mais acesso a opções de investimento em ESG e mais informações sobre essas oportunidades.
    <b>Brasil e o ESG</b>
    O Brasil está em uma posição desejável para se tornar um líder global em finanças sustentáveis. O país ainda tem um longo caminho a percorrer, mas segundo a Associação Brasileira das Entidades dos Mercados Financeiro e de Capitais (Anbima), R$ 700 milhões foram investidos em 167 fundos ESG no Brasil no ano passado. E Otávio Damaso, responsável de regulamentação no Banco Central do Brasil, afirma que o investimento verde no Brasil pode chegar a USD 1,3 trilhão em 2030. Esse é um setor que a Next Auditores está acompanhando de perto.
    <b>Comprometimento com a sustentabilidade</b>
    Muitas empresas multinacionais e startups estão comprometidas com a sustentabilidade e a responsabilidade social. Alguns exemplos incluem:
        • Unilever: A Unilever é uma empresa multinacional que tem um forte compromisso com a sustentabilidade. A empresa tem um plano ambicioso para se tornar carbono positivo até 2030 e está trabalhando para reduzir o impacto ambiental de seus produtos.
        • Tesla: A Tesla é uma empresa de carros elétricos que está ajudando a impulsionar a transição para uma economia de baixo carbono. A empresa está trabalhando para tornar os carros elétricos mais acessíveis e está expandindo sua linha de produtos para incluir painéis solares e baterias de armazenamento.
        • Natura: A Natura é uma empresa brasileira de cosméticos que tem um forte compromisso com a sustentabilidade. A empresa usa ingredientes naturais e orgânicos em seus produtos e está trabalhando para reduzir o impacto ambiental de suas operações.
        • Ecofiltro: A Ecofiltro é uma startup guatemalteca que está ajudando a fornecer acesso à água limpa em comunidades rurais. A empresa produz filtros de cerâmica que podem ser usados para purificar a água e está trabalhando para levar esses filtros para as comunidades que mais precisam.
    <b>Futuro próximo</b>
    De acordo com uma entrevista dada por Florian Bartunek, sócio-fundador da Constellation, ao Valor Econômico, acredita-se que os investimentos ESG serão obrigatórios para os fundos de investimento no futuro próximo. Ele também aponta que o Brasil está atrasado em relação a outros países em relação ao ESG, mas ressalta que a situação está mudando e que cada vez mais empresas estão adotando práticas sustentáveis.
    O investimento ESG está crescendo em popularidade à medida que mais investidores buscam oportunidades de investir em empresas que estão comprometidas com a sustentabilidade e a responsabilidade social. Embora os investimentos ESG possam ter riscos e volatilidade, eles oferecem uma série de benefícios para os investidores, incluindo investir em empresas sustentáveis, potencial de alto desempenho, redução do risco e impacto positivo no mundo. Se você está interessado em investir em ESG, é importante pesquisar fundos de investimento com diligência e ter uma estratégia de investimento a longo prazo.
    `,
    ],
    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/ricardo-artur-spezia/1185">Ricardo Artur Spezia</a>',
        description:
          'Cofundador da Next Auditores, Ricardo. Aprendeu cedo a cercar-se das melhores pessoas para entregar o melhor trabalho, mas usa a tecnologia para implementar e garantir a consistência dos processos. Por outro lado, o que o torna único é a sua criatividade e a sua intuição, características conhecidas nas artes, mas imprescindíveis para humanizar e decodificar a linguagem do mercado financeiro. Para ele, a vida acontece agora, mas acredita que só é possível dar um passo adiante se estivermos preparados. Mestre em Ciências Contábeis pela FURB (Fundação Universidade Regional de Blumenau), Ricardo é também especialista em Estratégia para Inovação em Novos Mercados pela Wharton School (Escola de negócios da Universidade da Pensilvânia – EUA). Pelo Insper, Ricardo é especialista em Finanças Avançadas e pela FGV (Fundação Getúlio Vargas) se especializou como CFO Estrategista em Marketing de Alto Impacto, com MBA em Finanças, Auditoria e Controladoria pela mesma instituição. Pés no chão, mas disposto a ampliar seus horizontes, Ricardo é fascinado pelo novo e por diferentes culturas. Observar o mundo (e o mundo das pessoas), o transformou em um executivo completo. Seu estilo de trabalho equilibra com maestria os dois lados do cérebro, e se para números combinados entre si não existem limites, para ele, muito menos. Sua busca é pela eficiência do seu mercado e pelo sucesso de seus clientes. Sua maior motivação são os desafios que encontra pelo caminho, mas também, os que ele mesmo procura. Ao ser questionado sobre qual seu método para encontrar as soluções de problemas novos ou problemas velhos, ele diz que o segredo é um só: é preciso saber fazer as perguntas certas. Mas quando é ele quem precisa de suporte, é na família, no esporte e na fé que se abastece. ',
        image: 'Spezia.jpg',
      },
    ],
  },
  {
    title: 'Inovações da Resolução 175',
    subtitle: '',
    text: [
      `A edição da Resolução 175 pela Comissão de Valores Mobiliários é um marco importante e há não dúvidas que a nova norma trouxe inovações importantes, principalmente com a criação da nova estrutura de fundos (fundo, classes e subclasses), consolidando a responsabilidade do gestor, dentre outros.  
    Nos FIDCs, com a possibilidade de distribuição para investidores em geral, se imagina atrair mais investidores, popularizando ainda mais os FIDCs, que depois de muitos anos e desafios passou a ser reconhecido como um importante mecanismo de fomento, sobretudo as empresas de pequeno e médio porte.
    Também a possibilidade de classe com direitos creditórios padronizados e não padronizados pode ser considerada uma importante ferramenta para o gestor, que por vezes acabava perdendo ótimas oportunidades até mesmo em fundos considerados padronizados. 
    No entanto, a flexibilização de determinados controles que a nova norma trouxe devem ser vistos com atenção pelo administrador e principalmente pelo gestor uma vez que este passou a ser o responsável pela estruturação do Fundo. Dentre eles podemos citar o inciso II do art. 52 quando se refere às cotas de classe destinada a investidor profissional. O cedente passou a poder receber os recursos oriundos da liquidação financeira dos direitos creditórios em conta corrente de livre movimentação, para posterior repasse à classe.  Assim, caso o cedente não realize a transferência em tempo adequado, a classe sofrerá prejuízo. Essa conta estará sujeita a todo tipo de constrição judicial. A rápida atuação do cobrador será determinante para evitar os prejuízos à classe.
    Outro dispositivo que merece atenção é a alínea “a” do artigo 33, pois da leitura exaustiva, é possível entender que a validação dos critérios de elegibilidade e a observância aos requisitos de composição e diversificação da carteira podem ser verificados por amostragem. Em uma estrutura de FIDC, permitir que a concentração e os critérios de elegibilidade sejam verificados por amostragem, nos parece fragilizá-la. 
    Quanto à sinergia entre Administrador e Gestor passará por ajustes uma vez que a norma redistribui a maior parte das atividades do custodiante para esses prestadores que passaram a ser denominados “Essenciais”, o que implicará em adequação operacional e de processos. De certo modo, a Administradora continuará a ter um papel importante na supervisão das atividades e na divulgação de informações obrigatórias do Fundo em tempo hábil, consistente e suficientes para tomada de decisão dos investidores.`,
    ],
    authors: [
      {
        name: 'Guaraci Sillos Moreira',
        description: `Bacharel em Direito e pós-graduado em Direito Empresarial e em Direito Corporativo e Compliance, atua há mais de 20 anos na área Jurídica do Mercado Financeiro, tendo trabalhado em instituições como Câmara do Comércio do Mercosul, BEM DTVM, HSBC Bank Brasil e Grupo Petra.
      É Membro da Comissão de FIP da ANBIMA e da Comissão de FIDCs.
      Ingressou na Singulare em 2014 e, em 2019, foi nomeado Diretor. Em 2022 tornou-se também sócio.`,
        image: 'Moreira.jpeg',
      },
    ],
  },
  {
    title: 'O CUSTO DA ASSIMETRIA DE INFORMAÇÃO NO MERCADO DE FIDCs',
    subtitle: '',
    text: [
      `O objetivo deste artigo é demonstrar, de maneira clara e objetiva, a existência e relevância do custo de assimetria de informação no mercado de FIDCs refletida na ampla variação das taxas de descontos aplicada sobre os cedentes em FIDCs na modalidade conhecida como Multicedente/Multisacado.
    De acordo com a base de dados e análise da Liberum Ratings, um FIDC Multicedente/Multisacado tradicional conta, em média, com cerca de 200 cedentes, neste estudo para fins de demonstração do problema escolhemos apenas os 10 maiores cedentes da nossa base (em termos de valor cedido e desconsiderando cedentes financeiras – emissoras de CCB) de forma a ilustrar que o problema é real e, acima de tudo, custoso.
    É importante ressaltar que a Liberum Ratings conta com uma base consolidada de 18.767 mil cedentes, distribuídos em 291 fundos, 149 dos quais são Multicedente/Multisacado, além de um market-share de 58% no ano de 2022. Assim sendo, as inferências produzidas representam significativa relevância para ponto proposto.
    No mês de referência – dezembro de 2022 - as cedentes escolhidas somavam R$ 435,41 milhões em direitos creditórios cedidos. A distribuição do valor cedido por cedente pode ser vista no gráfico 1 abaixo.
    
    <h4>GRÁFICO 1 – VALOR CEDIDO, R$ MILHÕES, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 1 – VALOR CEDIDO, R$ MILHÕES, DEZEMBRO 2022." id="liberum-1" footer="Fonte: Central de Risco – Liberum Ratings." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings.</caption>

    Esses 10 cedentes, também em dezembro/22, estavam distribuídos em diversos fundos, como se pode ver no gráfico 2 abaixo. Desconsiderando intersecções, ao total, são 54 fundos envolvidos. Todos com lastro em recebíveis comerciais, i.e, Multicedente/Multisacado.
    
    <h4>GRÁFICO 2 – DISPERSÃO EM FUNDOS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 2 – DISPERSÃO EM FUNDOS, DEZEMBRO 2022." id="liberum-2" footer="Fonte: Central de Risco – Liberum Ratings." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings.</caption>

    O ponto central da análise, entretanto, são as taxas de desconto praticadas em cada cedente. Em termos médios, é o que se pode observar no gráfico 3 a seguir.
    
    <h4>GRÁFICO 3 – TAXA MÉDIA POR CEDENTE, AO MÊS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 3 – TAXA MÉDIA POR CEDENTE, AO MÊS, DEZEMBRO 2022." id="liberum-3" footer="Fonte: Central de Risco – Liberum Ratings." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings.</caption>
    
    A taxa média por si só, entretanto, não é a métrica mais precisa para o evidenciar o custo de assimetria de informação dado que os cedentes se encontram dispersos em vários fundos. Assim, propomos uma segunda visão complementar, que permita a visualização da dispersão de taxas praticadas para uma mesma cedente dentro dos diversos fundos para os quais ele antecipa seus recebíveis. Por motivos de concisão e exemplificação, decidimos mostrar de maneira mais detalhada apenas aqueles cedentes dispersos em mais fundos (cedente 1,6 e 7), que podem ser vistos nos gráficos 4, 5 e 6, respectivamente.
    
    <h4>GRÁFICO 4 – CEDENTE 1 – DISPERSÃO DE TAXAS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 4 – CEDENTE 1 – DISPERSÃO DE TAXAS, DEZEMBRO 2022." id="liberum-4" footer="Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 3,18% a.m." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 3,18% a.m.</caption>      

    <h4>GRÁFICO 5 – CEDENTE 7 – DISPERSÃO DE TAXAS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 5 – CEDENTE 7 – DISPERSÃO DE TAXAS, DEZEMBRO 2022." id="liberum-5" footer="Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 1,85% a.m." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 1,85% a.m.</caption>

    <h4>GRÁFICO 6 – CEDENTE 6 – DISPERSÃO DE TAXAS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 6 – CEDENTE 6 – DISPERSÃO DE TAXAS, DEZEMBRO 2022." id="liberum-6" footer="Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 2,16% a.m." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings. A Média, como visto no gráfico 3, é de 2,16% a.m.</caption>

    Como se pode observar, as taxas para um mesmo cedente apresentam uma variação significativa fundo a fundo, situando-se abaixo ou acima da média praticada no mercado. E este é precisamente o ponto focal deste artigo: acreditamos que a falta de informações suficientes acerca dos cedentes leva a uma ineficiência quando da precificação dos mesmos. Esta falta de informação acarreta custos, um custo de oportunidade, que pode ser mensurado em termos monetários (aquilo que se deixa de ganhar) e que é consequência da ineficiência de precificação causada pela informação incompleta à disposição dos consultores.
    Vamos estimar esse custo mais à frente. Antes disso, convém fazer aqui uma pequena observação metodológica. Certamente, o desvio de taxas praticadas também está ligado às diferentes operações de cada cedente, mais especificamente, relacionada a condições como prazo, volume cedido, tipo de título e tipo/volume de liquidação. No entanto, a ligação da variação de taxas com esses parâmetros não anula o problema da assimetria de informação, em verdade, o reafirma. Em condições iguais dos fatores acima citados, a assimetria de informação ainda aconteceria.
    Os consultores sempre tentarão precificar uma determinada cedente da melhor forma possível, considerando o seu universo de operações. A falta de uma visão geral, e de como essa cedente se comporta no mercado como um todo é, precisamente, a raiz do problema por trás das taxas. Este assunto será tratado em outros estudos pela Liberum Ratings, por ora, seguimos com o objetivo original: demonstrar a existência e o custo do problema.
    Convém agora apresentar os diferenciais de taxa para todos os cedentes escolhidos. Para sermos concisos, aqui apresentamos as taxas apenas em termos de máximo, média e mínimo.
    
    <h4>GRÁFICO 7 – CEDENTE 6 – DISPERSÃO DE TAXAS, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 7 – CEDENTE 6 – DISPERSÃO DE TAXAS, DEZEMBRO 2022." id="liberum-7" footer="Fonte: Central de Risco – Liberum Ratings." ></chart>
    <caption>Fonte: Central de Risco – Liberum Ratings.</caption>

    Como então podemos estimar, de maneira mais acurada: i) o custo desta assimetria de informação refletida nas diferenças de taxas de deságio? e ii) O quanto essa diferença nas taxas poderia potencialmente contribuir para o resultado de um FIDC? 
    Nosso primeiro passo foi calcular a elasticidade Taxa de Desconto da Rentabilidade. Ou seja, o quão sensível é a rentabilidade (apurada na cota subordinada do FIDC) às variações da taxa de desconto praticada nas cedentes.
    Utilizando uma regressão linear do tipo “log-log”, chegamos a uma elasticidade-taxa da rentabilidade de 0,8903%1. Isto significa que, em média, para a nossa amostra, o aumento de 1% na taxa de desconto deve gerar um aumento de aproximadamente 0,90% na rentabilidade acruada na cota subordinada do fundo em questão.
    Com este dado em mãos, identificamos todos os fundos que, para uma determinada cedente, praticavam (na data base de dezembro/22) taxas abaixo da média de mercado desta mesma cedente, reajustamos as taxas para serem equivalentes à média e calculamos a rentabilidade “perdida” (aquilo que se deixou de ganhar – o custo da assimetria de informação quanto a taxas) dada a elasticidade previamente calculada. Podemos observar os resultados calculados por cedente no gráfico 8 abaixo.
    
    <h4>GRÁFICO 8 – RENTABILIDADE EFETIVA E POTENCIAL, POR CEDENTE, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 8 – RENTABILIDADE EFETIVA E POTENCIAL, POR CEDENTE, DEZEMBRO 2022." id="liberum-8" footer="" ></chart>
    <h4></h4>
    Ao todo, considerando os 10 cedentes selecionados, estimamos um custo de R$ 785 mil somente no mês de dezembro/22 e considerando apenas uma elevação das taxas do intervalo abaixo da média para a média. Como podemos perceber, o custo de oportunidade, apesar de existir para todos os cedentes em maior ou menor grau, aparece com maior importância em alguns cedentes específicos. Abaixo podemos ver a participação percentual de cada cedente no custo da informação estimado para dezembro:
    
    <h4>GRÁFICO 9 – PARTICIPAÇÃO PERCENTUAL NA RENTABILIDADE POTENCIAL POR CEDENTE, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 9 – PARTICIPAÇÃO PERCENTUAL NA RENTABILIDADE POTENCIAL POR CEDENTE, DEZEMBRO 2022." id="liberum-9" footer="" ></chart>
    <h4></h4>
    
    Também podemos observar o custo de oportunidade por fundo, considerando a diferença de abaixo da média x média das taxas. Como se tratam de 54 fundos, para que a visualização fique mais clara decidimos por exibir aqui apenas os 10 fundos mais afetados tanto em termos nominais (gráfico 10) como em termos percentuais (gráfico 11).
    
    <h4>GRÁFICO 10 – RENTABILIDADE POTENCIAL POR FUNDO, DEZEMBRO 2022, EM R$</h4>
    <chart title="GRÁFICO 10 – RENTABILIDADE POTENCIAL POR FUNDO, DEZEMBRO 2022, EM R$" id="liberum-10" footer="" ></chart>

    <h4>GRÁFICO 11 – PARTICIPAÇÃO PERCENTUAL NA RENTABILIDADE POTENCIAL POR FUNDO, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 11 – PARTICIPAÇÃO PERCENTUAL NA RENTABILIDADE POTENCIAL POR FUNDO, DEZEMBRO 2022." id="liberum-11" footer="" ></chart>
    <h4></h4>
    
    Digno de nota, ainda neste tópico, é o papel desempenhado pela alavancagem quando se trata do custo de oportunidade. A Liberum estimou a mesma regressão “log-log”, para os mesmos cedentes e fundos, mas considerando um cenário sem alavancagem. Isto é, aplicando um ajuste à rentabilidade apurada em um cenário onde a participação do patrimônio subordinado júnior no PL total fosse de 100%. Sem surpresas, a elasticidade taxa da rentabilidade desalavancada foi menor do que a anterior, alcançando 0,7041%.
    
    <h4>GRÁFICO 12 – RENTABILIDADE POTENCIAL, ALAVANCADO E DESALAVANCADO, POR CEDENTE, R$, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 12 – RENTABILIDADE POTENCIAL, ALAVANCADO E DESALAVANCADO, POR CEDENTE, R$, DEZEMBRO 2022." id="liberum-12" footer="" ></chart>
    <h4></h4>

    O custo estimado neste cenário foi de R$ 621 mil, volume 21% menor do que a perda estimada no cenário real, com alavancagem. A distribuição dos custos neste cenário pode ser vista nos gráficos 12 (por cedente) e 13 (por fundo).
    
    <h4>GRÁFICO 13 – RENTABILIDADE POTENCIAL, ALAVANCADO E DESALAVANCADO, R$, POR FUNDO, DEZEMBRO 2022.</h4>
    <chart title="GRÁFICO 13 – RENTABILIDADE POTENCIAL, ALAVANCADO E DESALAVANCADO, R$, POR FUNDO, DEZEMBRO 2022." id="liberum-13" footer="" ></chart>
    <h4></h4>
    
    Em última instância, o objetivo da análise é demonstrar que a assimetria de informação quando da precificação de cedentes em um FIDC existe, e representa um custo aos consultores e gestores do mercado de FIDCs. Ainda, os efeitos desse problema são potencializados pela alavancagem, o que coloca os originadores em uma situação duplamente desafiadora: precificar corretamente seus cedentes, especialmente se alavancado, visto que o custo da má precificação aumenta vis-à-vis à alavancagem, fora o custo do funding em si.
    A Liberum Ratings acredita que é essencial o alerta sobre a significativa diferença entre as taxas e o consequente dilema da assimetria de informação. Os consultores de FIDCs, com suas respectivas áreas de risco e análise devem sempre desenvolver e aperfeiçoar suas metodologias de precificação, visto que a própria viabilidade e sustentabilidade do negócio podem estar em jogo.
    `,
    ],
    authors: [
      {
        name: 'Henrique Pinheiro Campos',
        description: `Sólidos conhecimentos no mercado de crédito e securitização, com mais de 10 anos de experiência profissional em agências de ratings. Expertise especialmente em finanças estruturadas com emissões no mercado local, com os mais diversos tipos de ativos, sejam eles tradicionais: recebíveis comerciais e/ou imobiliários ou considerados ativos alternativos: recebíveis lastreados em cartão de crédito, ações judiciais, precatórios, etc.
        Graduado em Administração de Empresas pela Universidade Mackenzie, pós-graduação em finanças pela FGV.`,
        image: 'PinheiroCampos.jpg',
      },
      {
        name: 'José Marcos de Oliveira Redighieri',
        description: `Especializado em Macroeconomia e Economia Monetária: mercado de capitais, política monetária e macroprudencial, economia internacional. Atuando como Analista de Riscos III pela Liberum Ratings, onde lida não só com análise de riscos operacionais de finanças estruturadas em geral, mas, também, com análise de risco setorial e macroeconômico por meio da elaboração de modelos-cenário e indicadores diversos.
        Graduado em Ciências Econômicas pela Universidade Federal do Espírito Santo;
        Mestre em Economia pela Pontifícia Universidade Católica de São Paulo (PUC-SP);
        Pós-graduando em Ciência de dados ela Faculdade de Informática e Administração Paulista (FIAP).`,
        image: 'Redighieri.jpg',
      },
    ],
  },
];

export const rankingsData = [
  null,
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=stock&criteria=amountissued&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=legaladvisor&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=legaladvisor&criteria=operationsissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=agencyrating&criteria=amountclassified&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=agencyrating&criteria=titlesnumberclassified&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=structurer&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=structurer&criteria=operationsissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=stock&criteria=amountnetworth&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=stock&criteria=amountnetworth&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=admin&criteria=amountnetworth&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=admin&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=admin&criteria=amountnetworth&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=admin&criteria=operations&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=auditor&criteria=amountnetworth&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=auditor&criteria=operations&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=custodian&criteria=amountnetworth&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=custodian&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=custodian&criteria=amountnetworth&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=custodian&criteria=operations&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=manager&criteria=amountnetworth&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=manager&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=manager&criteria=amountnetworth&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FIDC&year=2022&ranking=manager&criteria=operations&timeRange=consolidated',
];
