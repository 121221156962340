import { Stack } from '@mui/material';
import { Market } from 'src/types/market';
import { Plan } from 'src/types/plans';

import MarketsFilter from './MarketsFilter';
import PlansFilter from './PlansFilter';

export default function APIFilters({
  plans,
  markets,
  state,
  setState,
  INITIAL_STATE,
}: {
  plans?: Plan[];
  markets?: Market[];
  state: any;
  setState: any;
  INITIAL_STATE: any;
}) {
  return (
    <Stack
      spacing={2}
      direction={'row'}
      sx={(theme) => ({
        overflowX: 'auto',
        overflowY: 'hidden',
        pb: 0.625,
        '&::-webkit-scrollbar': {
          height: 10,
          width: 8,
          WebkitAppearance: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: theme.palette.grey['400'],
        },
        '& > .MuiButton-root': {
          fontWeight: '700',
          fontSize: '14px',
          boxShadow: '0px 4px 4px 0px #00000026 !important',
          height: '48px',
          bgcolor: '#FFF',
          minWidth: 'fit-content',
          padding: '16px 8px 16px 16px',
          '.MuiSvgIcon-root': {
            fontSize: '20px',
          },
          '&:hover': {
            bgcolor: '#FFF',
          },
        },
      })}>
      <MarketsFilter
        data={markets}
        state={state}
        setState={setState}
        INITIAL_STATE={INITIAL_STATE}
      />
      <PlansFilter
        data={plans}
        state={state}
        setState={setState}
        INITIAL_STATE={INITIAL_STATE}
      />
    </Stack>
  );
}
