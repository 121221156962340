import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import PasswordField from 'src/components/FormsUI/PasswordField';
import PasswordRules from 'src/components/PasswordRules';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { setBannerMessage } from 'src/store/display';
import * as Yup from 'yup';

import { resetPassword } from '../../services/auth';
import { FieldTitle } from '../Login/styles';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;

const FORM_VALIDATION = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório')
    .matches(passwordRegex, ' '),
  passwordConfirmation: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
});

const INITIAL_VALUES = {
  password: '',
  passwordConfirmation: '',
};

export default function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleLogin, notifyAuthChannel } = useAuthContext();
  const searchParams = new URLSearchParams(history.location.search);
  const code = searchParams.get('token') ?? '';
  const setNewPasswordByRecoveryCode = useMutation(resetPassword, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Erro ao salvar nova senha, tente novamente',
          type: 'error',
          autoclose: false,
        }),
      );
    },
    onSuccess: (data) => {
      dispatch(
        setBannerMessage({
          message: 'Senha alterada com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      const { jwt } = data.data;
      localStorage.removeItem('@App:token');
      sessionStorage.setItem('@App:token', jwt);
      sessionStorage.removeItem('@App:tempToken');
      handleLogin(true);
      notifyAuthChannel('login', jwt);
      history.push('/');
    },
  });

  const { isLoading } = setNewPasswordByRecoveryCode;

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION,
    onSubmit: (values) => {
      setNewPasswordByRecoveryCode.mutate({
        code,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      });
    },
  });

  return (
    <Box
      sx={(theme) => ({
        paddingTop: '75px',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '130px',
        },
      })}
      component="section">
      <Container maxWidth="md">
        <Box
          sx={{
            height: 'calc(100vh - 75px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 700,
              [theme.breakpoints.down('sm')]: {
                fontSize: '32px',
              },
            })}>
            Cadastre uma nova senha
          </Typography>
          <Box maxWidth={400}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4} sx={{ marginTop: 6 }}>
                <Grid item xs={12}>
                  <FieldTitle>Senha*</FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    {/* @ts-ignore */}
                    <Test id="change-form-password">
                      <PasswordField
                        name="password"
                        placeholder="Digite uma senha"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password ? formik.errors.password : ''
                        }
                      />
                    </Test>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FieldTitle>Repetir senha*</FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    {/* @ts-ignore */}
                    <Test id="change-form-password-confirmation">
                      <PasswordField
                        name="passwordConfirmation"
                        placeholder="Confirme sua senha"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.passwordConfirmation &&
                          Boolean(formik.errors.passwordConfirmation)
                        }
                        helperText={
                          formik.touched.passwordConfirmation &&
                          formik.errors.passwordConfirmation
                        }
                      />
                    </Test>
                  </FormControl>
                </Grid>
              </Grid>
              <PasswordRules
                password={formik.values.password}
                canVerify={formik.touched.password}
              />
              <Grid container justifyContent="center" sx={{ marginTop: 6 }}>
                {/* @ts-ignore */}
                <Test id="btn-change-password">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {isLoading ? (
                      <CircularProgress sx={{ color: '#FFFFFF' }} />
                    ) : (
                      'Finalizar'
                    )}
                  </Button>
                </Test>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
