import { Box, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

export default function Details({ classes, details }) {
  return (
    <Box p={2}>
      <Box display="flex">
        <LocationOnIcon color="secondary" />
        <Typography gutterBottom className={classes.details}>
          <b>Acesso: </b>
          {details.accessInfo}
        </Typography>
      </Box>
      {details?.requirements && (
        <Box display="flex">
          <PlaylistAddCheckIcon color="secondary" />
          <Typography gutterBottom className={classes.details}>
            <b>Requisitos mínimos: </b>
            {details.requirements}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
