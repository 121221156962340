import { Button as MuiButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styled from 'styled-components';

export const Form = styled.div`
  position: absolute;
  border-radius: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 800px;
  height: min-content;
  max-height: 785px;
  overflow: auto;
  padding: 20px 40px 30px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 20px 24px;
`;

export const Button = styled(MuiButton)`
  font-size: 14px;
`;

export const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-start;
`;

export const ButtonWrapper = styled(Grid)`
  &.MuiGrid2-root {
    column-gap: 20px;
    margin-top: 50px;
    margin-bottom: 0;
  }
`;
