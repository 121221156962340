import { cloneElement, useEffect, useState } from 'react';

import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import OnboardingModal from 'src/components/OnboardingModal';
import ModalButton from 'src/components/OnboardingModal/ModalButton';
import MarketsNavigation from 'src/components/UI/MarketsNavigation/MarketsNavigation';
import TabTemplate from 'src/components/UI/TabTemplate';
import { updateUser } from 'src/services/user';
import { User } from 'src/types/user';
import { useQueryParams } from 'src/utils/hooks';
import { pickData } from 'src/utils/object';

import RadarConvocations from './RadarConvocations';
import RadarDashboard from './RadarDashboard';
import RadarIndicators from './RadarIndicators';
import RadarIcon from '../../components/Icons/RadarIcon';
import MetaTags from '../../components/Seo/MetaTags';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getInformativeTextByLocation } from '../../services/informative';
import { ScrollToTop } from '../../utils/scroll';
import { allowedFields } from '../AccountProfile/UserData/LegalPersonForm';

export default function Radar() {
  const { auth } = useAuthContext();

  const markets = ['cri', 'cra', 'fii', 'fidc'];

  const [{ query }, setQuery] = useQueryParams<{
    market: 'cri' | 'cra' | 'fii' | 'fidc';
    mode: 'indicadores' | 'assembleias' | 'dashboard';
    favoriteId?: number;
  }>({
    market: 'cri',
    mode: 'indicadores',
    favoriteId: undefined,
  });

  const handleSelectMarket = (market: string) => {
    setQuery({
      ...query,
      market,
      favoriteId: undefined,
    });
  };

  const [previewText, setPreviewText] = useState<string>();
  const [buttonList, setButtonList] = useState([
    {
      name: 'Indicadores',
      key: 'indicadores',
      description: '',
      tab: <RadarIndicators query={query} />,
    },
    {
      name: 'Assembleias',
      key: 'assembleias',
      description: '',
      tab: <RadarConvocations query={query} />,
    },
    {
      name: 'Dashboard',
      key: 'dashboard',
      description: '',
      tab: <RadarDashboard query={query} />,
    },
  ]);

  useQuery(
    'infoIndicators',
    () => getInformativeTextByLocation('radarIndicators'),
    {
      onSuccess: (data) => {
        const newButtonList = [...buttonList];
        newButtonList[0].description =
          data?.data?.radarIndicators?.informationText || '';
        setButtonList(newButtonList);
      },
      enabled: !auth.isLoading,
    },
  );
  useQuery(
    'infoConvocations',
    () => getInformativeTextByLocation('radarConvocations'),
    {
      onSuccess: (data) => {
        const newButtonList = [...buttonList];
        newButtonList[1].description =
          data?.data?.radarConvocations?.informationText || '';
        setButtonList(newButtonList);
      },
      enabled: !auth.isLoading,
    },
  );
  useQuery(
    'infoDashboard',
    () => getInformativeTextByLocation('radarDashboard'),
    {
      onSuccess: (data) => {
        const newButtonList = [...buttonList];
        newButtonList[2].description =
          data?.data?.radarDashboard?.informationText || '';
        setButtonList(newButtonList);
      },
      enabled: !auth.isLoading,
    },
  );

  const [activeButton, setActiveButton] = useState(
    buttonList.find((button) => button.key === query.mode) ?? buttonList[0],
  );

  const handleClick = (button: (typeof buttonList)[0]) => {
    setActiveButton(button);
    setQuery(
      {
        mode: button.key,
        market: 'cri',
        favoriteId: undefined,
      },
      'push',
    );
  };

  const handleMouseEnter = (button: (typeof buttonList)[0]) => {
    setPreviewText(button.description);
  };
  const handleMouseLeave = () => {
    setPreviewText(undefined);
  };

  const [openOnboarding, setOpenOnboarding] = useState(false);
  const handleOpenOnboarding = () => setOpenOnboarding(true);
  const handleCloseOnboarding = () => setOpenOnboarding(false);
  const radarType =
    query.mode === 'indicadores' ? 'indicators' : 'convocations';
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.[`radar_${radarType}`];

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      auth.refetch();
    },
  });

  useEffect(() => {
    if (hasPermission && !auth.user?.onboarding?.[radarType]) {
      setOpenOnboarding(true);
      const pickedData = pickData(
        {
          ...auth.user,
          onboarding: {
            ...auth.user?.onboarding,
            [radarType]: new Date(),
          },
        },
        allowedFields,
      ) as User;
      updateUserMutation.mutateAsync({ data: pickedData });
    }
  }, [auth.user?.onboarding?.[radarType]]);

  return (
    <ScrollToTop>
      <MetaTags
        title="Radar"
        description="Entenda o desenvolvimento dos mercados de FII, FIDC, CRI e CRA acompanhando a evolução dos principais agentes envolvidos no mercado de Finanças Estruturadas. Filtre por mercado, ano e segmento de atuação."
      />
      <Box component="section">
        <TabTemplate
          title="Radar"
          breadcrumbs1="Radar"
          titleIcon={<RadarIcon style={{ marginTop: '8px' }} />}
          tabContent={[]}
          tabIndex={0}
          handleChange={() => {}}
        />
        <Paper elevation={2}>
          <Box pt={2.75} pr={2.5} pl={1.875}>
            <Stack direction={'row'} gap={4} mb={5}>
              <Stack
                direction={'row'}
                gap={2}
                width={{ xs: 'fit-content', sm: '100%' }}
                maxWidth={
                  buttonList.length * 128 + (buttonList.length - 1) * 16
                }
                flexWrap={'wrap'}
                sx={{
                  '.MuiButtonBase-root': {
                    width: '128px',
                    minWidth: 'unset',
                    height: '60px',
                    borderRadius: '16px',
                    color: '#FFF',
                    fontSize: '0.875rem',
                    fontWeight: 700,
                    lineHeight: '19.6px',
                  },
                }}>
                {buttonList.map((button, index) => (
                  <Button
                    key={index}
                    color="primary"
                    variant="contained"
                    sx={(theme) =>
                      button?.key !== query.mode
                        ? {
                            backgroundColor: '#F1F1F1 !important',
                            color: `${theme.palette.primary.main} !important`,
                            '&:hover': {
                              filter: 'brightness(0.9)',
                            },
                          }
                        : {}
                    }
                    onClick={() => handleClick(button)}
                    onMouseEnter={() => handleMouseEnter(button)}
                    onMouseLeave={handleMouseLeave}>
                    {button.name}
                  </Button>
                ))}
              </Stack>
              <Typography
                fontSize={'0.875rem'}
                textAlign={'justify'}
                color={'#4F4F4F'}
                pt={1}
                pr={0.5}
                width={'100%'}>
                {previewText ?? activeButton?.description}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <MarketsNavigation
                value={query.market}
                handleSelectMarket={handleSelectMarket}
                markets={markets}
              />
              {activeButton?.key !== 'dashboard' && (
                <Box>
                  <ModalButton
                    onClick={handleOpenOnboarding}
                    disabled={!hasPermission}
                  />
                </Box>
              )}
              <OnboardingModal
                open={openOnboarding}
                handleClose={handleCloseOnboarding}
                type={radarType}
              />
            </Stack>
          </Box>
          {cloneElement(activeButton.tab, { query })}
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
