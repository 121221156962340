// material ui components
import { Grid, List } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-query';

// components
import ListItemLink from '../../../components/ListItemLink';
// apollo query
import { getRelatedArticlesByMarket } from '../../../services/article';

export default function ArticleMoreNews({ articleId, market }) {
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery(['related_article', articleId], () =>
    getRelatedArticlesByMarket(market, articleId, 5),
  );

  if (loading) return null;
  if (error) return '';
  if (!data) return '';

  return (
    <Grid item container>
      <Grid item xs>
        <List>
          {data?.map(({ id, slug, title, publishedAt }) => {
            return (
              <ListItemLink
                to={`/artigo/${slug}/${id}`}
                key={id}
                title={title}
                publishDate={moment(publishedAt).format('DD/MM/YYYY')}
                disableGutters
              />
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}
