import theme from 'src/theme';
import styled from 'styled-components';

import * as YearbookSection from '../Section';

export const Wrapper = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    width: calc(100% + 1rem);
    border-top-right-radius: 25px;
    height: 70%;
    background-color: #ff8211;
    left: 0;
    border-bottom-right-radius: 12.5px;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: calc(100% - 1.5rem);
      height: 80%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1.25rem;
  background-color: white;
  margin-left: calc(160px + 8rem);
  padding: 2rem;
  max-width: 460px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: ${theme.breakpoints.values.lg}px) {
    margin-left: calc(160px + 6rem);
    max-width: 380px;
  }

  @media screen and (max-width: 1000px) {
    max-width: 320px;
  }

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 2rem;
    max-width: inherit;
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 0.5rem 0;
`;

export const Paragraph = styled(YearbookSection.Paragraph)`
  color: #585858;
  font-size: 0.9rem;
  font-weight: 600;

  p {
    margin: 0;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.8rem;
    line-height: inherit;
  }
`;

export const ValueTitle = styled.h4`
  font-weight: bold;
  margin: 2rem 0 0 0;
  text-align: right;
  font-size: 0.8rem;
  line-height: 120%;
`;

export const Value = styled.h4`
  font-size: 2.25rem;
  color: #ff8211;
  font-weight: bold;
  text-align: right;
  margin: 0;
  line-height: 120%;
`;

export const ValueUnitType = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
  line-height: 120%;
  margin: 0 0 1rem 0;
`;
