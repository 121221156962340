import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Modules({ classes, program }) {
  return (
    <Box mt={1} mb={2} py={1}>
      {program.map(({ name, id, modules }) => (
        <Box key={id} mt={2}>
          <Typography variant="h6">{name}</Typography>
          {modules.map(
            ({ moduleName, moduleDuration, moduleDescription }, index) => (
              <Accordion key={index} square elevation={0}>
                <AccordionSummary
                  style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    <b>{`${index + 1}. ${moduleName}`}</b>{' '}
                    {moduleDuration && `(duração ${moduleDuration})`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {moduleDescription}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ),
          )}
        </Box>
      ))}
    </Box>
  );
}
