// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationFIIDistributedIncome from './OperationFIIDistributedIncome';
import OperationFIIQuotesAndNetWorth from './OperationFIIQuotesAndNetWorth';
import OperationFIIResumeGeneralData from './OperationFIIResumeGeneralData';
import OperationFIIResumeServiceProvider from './OperationFIIResumeServiceProvider';
import OperationFIIResumeWallet from './OperationFIIResumeWallet';
import OperationFIISecondary from './OperationFIISecondary';

export default function OperationFIIResume({ hash }) {
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <OperationFIIResumeGeneralData hash={hash} />
        </Grid>
        <Grid item xs={12} md>
          <OperationFIIResumeServiceProvider hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIIResumeWallet hash={hash} />
        </Grid>
        <Grid item xs={12} md={6}>
          <OperationFIIQuotesAndNetWorth hash={hash} />
        </Grid>
        <Grid item xs={12} md>
          <OperationFIISecondary hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIIDistributedIncome hash={hash} />
        </Grid>
      </Grid>
    </Box>
  );
}
