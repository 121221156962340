import { useState } from 'react';

// Material ui componentes
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationCRILiabilityData from './OperationCRILiabilityData';
import OperationCRILiabilityTitle from './OperationCRILiabilityTitle';
import { PanelLogin } from '../../../../components/PanelLogin';
// Apollo client
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_CRI_LIABILITIES_BY_OPERATION_HASH_ID } from '../../../../graphql/markets/cri/queries';
// Providers

export default function OperationCRILiability({ hashId }) {
  const { auth } = useAuthContext();
  const [liabilities, setLiabilities] = useState([]);
  const { loading, error } = useQuery(
    GET_CRI_LIABILITIES_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cri_passivo'
      ],
      onCompleted: ({ getCriLiabilitiesByOperationHashId: { titles } }) =>
        setLiabilities(titles),
    },
  );

  if (error) return null;

  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cri_passivo'] ? (
        <Box p={2}>
          <PanelLogin />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRILiabilityData data={liabilities} loading={loading} />
          </Grid>
          <Grid item xs={12}>
            <OperationCRILiabilityTitle data={liabilities} loading={loading} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
