import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  & + & {
    margin-top: 30px;
  }
`;

export const Image = styled.img`
  float: left;
  width: 150px;
  height: 150px;
  margin-right: 20px;
`;

export const Divider = styled.div`
  width: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 170px;
`;

export const TextWrapper = styled.div`
  margin-top: 40px;

  div:nth-child(2) {
    margin-top: 60px;
    color: #ff8211;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    & > span {
      text-decoration: none;
      & > a {
        text-decoration: none;
      }
    }
  }

  div:nth-child(3) {
    margin-top: 10px;
    font-size: 16px;
  }
`;
