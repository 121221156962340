import { AddCircle, Cancel } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FieldArray, FormikProps, FormikProvider } from 'formik';
import PhoneFormat from 'src/components/FormsUI/PhoneFormat';
import { If } from 'src/components/If';
import { InstitutionInput } from 'src/types/institution';

import { FieldTitle } from '../style';

type Props = {
  formik: FormikProps<InstitutionInput>;
};

function ContactForm({ formik }: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const getEmailsError = (fieldName: keyof { email: string }) => {
    const error = formik.errors.emails?.[0] as { email: string } | undefined;
    return error ? error[fieldName] : '';
  };

  const getPhoneError = (fieldName: keyof { number: string }) => {
    const error = formik.errors.phones?.[0] as { number: string } | undefined;
    return error ? error[fieldName] : '';
  };
  return (
    <Grid container spacing={2} sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Grid
        item
        xs={12}
        md={6}
        container
        sx={{
          height: 'fit-content',
          justifyContent: 'flex-start',
        }}>
        <FormikProvider value={formik}>
          <FieldArray
            name="emails"
            render={(arrayHelpers) => (
              <>
                {formik.values?.emails &&
                  formik.values?.emails?.length > 0 &&
                  formik.values?.emails.map((_, index) => (
                    <Grid
                      key={`email-${index}`}
                      xs={12}
                      md={12}
                      item
                      sx={{
                        paddingBottom: '16px',
                      }}>
                      <FieldTitle>
                        {index === 0 ? 'E-mail*' : 'E-mail adicional'}
                      </FieldTitle>
                      <Grid
                        xs={12}
                        md={12}
                        sx={{ flexWrap: 'nowrap' }}
                        container>
                        <Grid item xs={10} md={10} sx={{ width: '100%' }}>
                          <FormControl fullWidth variant="outlined">
                            <TextField
                              required
                              name={`emails[${index}].email`}
                              value={formik.values.emails[index].email}
                              onChange={formik.handleChange}
                              placeholder="Insira o e-mail"
                              error={
                                formik?.touched.emails &&
                                formik?.touched?.emails[index]?.email &&
                                Boolean(getEmailsError('email'))
                              }
                              helperText={
                                formik?.touched.emails &&
                                formik?.errors.emails &&
                                formik?.touched?.emails[index]?.email &&
                                getEmailsError('email')
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={2}
                          sx={(theme) => ({
                            '& button': {
                              fontSize: '2.1875rem',
                            },
                            [theme.breakpoints.down('sm')]: {
                              display: 'block',
                              alignSelf: 'center',
                              paddingLeft: 2,
                              '& button': {
                                padding: 0,
                                fontSize: '28px',
                              },
                            },
                          })}>
                          <If
                            condition={
                              formik.values?.emails?.length < 3 &&
                              formik.values?.emails?.length - index === 1
                            }>
                            <Grid item xs={6}>
                              <IconButton
                                disabled={formik.values?.emails?.length > 2}
                                sx={(theme) => ({
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.secondary.light,
                                  },
                                })}
                                onClick={() => {
                                  if (formik.values?.emails?.length > 2) return;
                                  arrayHelpers.push({ email: '' });
                                }}>
                                <AddCircle
                                  fontSize={isMobile ? 'inherit' : 'large'}
                                  color="primary"
                                />
                              </IconButton>
                            </Grid>
                          </If>
                          <If
                            condition={
                              formik.values?.emails?.length > 1 && index > 0
                            }>
                            <Grid item xs={6}>
                              <IconButton
                                sx={(theme) => ({
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.secondary.light,
                                  },
                                })}
                                onClick={() => arrayHelpers.pop()}>
                                <Cancel
                                  fontSize={isMobile ? 'inherit' : 'large'}
                                  color="primary"
                                />
                              </IconButton>
                            </Grid>
                          </If>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}
          />
        </FormikProvider>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        sx={{
          height: 'fit-content',
          justifyContent: 'flex-start',
        }}>
        <FormikProvider value={formik}>
          <FieldArray
            name="phones"
            render={(arrayHelpers) => (
              <>
                {formik.values?.phones &&
                  formik.values?.phones?.length > 0 &&
                  formik.values?.phones.map((_, index) => (
                    <Grid
                      key={`phone-${index}`}
                      xs={12}
                      md={12}
                      item
                      sx={{
                        paddingBottom: '16px',
                      }}>
                      <FieldTitle>
                        {index === 0 ? 'Telefone*' : 'Telefone adicional'}
                      </FieldTitle>
                      <Grid
                        xs={12}
                        md={12}
                        sx={{ flexWrap: 'nowrap' }}
                        container>
                        <Grid item xs={10} md={10} sx={{ width: '100%' }}>
                          <FormControl fullWidth variant="outlined">
                            <TextField
                              required
                              name={`phones[${index}].number`}
                              InputProps={{
                                inputComponent: PhoneFormat,
                              }}
                              value={formik.values.phones[index].number}
                              onChange={formik.handleChange}
                              placeholder="Digite o telefone"
                              error={
                                formik?.touched.phones &&
                                formik?.touched?.phones[index]?.number &&
                                Boolean(getPhoneError('number'))
                              }
                              helperText={
                                formik?.touched.phones &&
                                formik?.errors.phones &&
                                formik?.touched?.phones[index]?.number &&
                                getPhoneError('number')
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={2}
                          sx={(theme) => ({
                            '& button': {
                              fontSize: '2.1875rem',
                            },
                            [theme.breakpoints.down('sm')]: {
                              display: 'block',
                              alignSelf: 'center',
                              paddingLeft: 2,
                              '& button': {
                                padding: 0,
                                fontSize: '28px',
                              },
                            },
                          })}>
                          <If
                            condition={
                              formik.values?.phones?.length < 3 &&
                              formik.values?.phones?.length - index === 1
                            }>
                            <Grid item xs={6}>
                              <IconButton
                                disabled={formik.values?.phones?.length > 2}
                                sx={(theme) => ({
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.secondary.light,
                                  },
                                })}
                                onClick={() => {
                                  if (formik.values?.phones?.length > 2) return;
                                  arrayHelpers.push({ number: '' });
                                }}>
                                <AddCircle
                                  fontSize={isMobile ? 'inherit' : 'large'}
                                  color="primary"
                                />
                              </IconButton>
                            </Grid>
                          </If>
                          <If
                            condition={
                              formik.values?.phones?.length > 1 && index > 0
                            }>
                            <Grid item xs={6}>
                              <IconButton
                                sx={(theme) => ({
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: theme.palette.secondary.light,
                                  },
                                })}
                                onClick={() => arrayHelpers.pop()}>
                                <Cancel
                                  fontSize={isMobile ? 'inherit' : 'large'}
                                  color="primary"
                                />
                              </IconButton>
                            </Grid>
                          </If>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}
          />
        </FormikProvider>
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldTitle>LinkedIn</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="linkedin.url"
            placeholder="Insira a url do seu linkedin institucional"
            onChange={formik.handleChange}
            value={formik.values.linkedin.url}
            error={
              formik?.touched?.linkedin?.url &&
              Boolean(formik?.errors?.linkedin?.url)
            }
            helperText={
              formik?.touched?.linkedin?.url && formik?.errors?.linkedin?.url
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldTitle>Instagram</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="instagram.url"
            placeholder="Insira a url do seu instagram institucional"
            onChange={formik.handleChange}
            value={formik.values.instagram.url}
            error={
              formik?.touched?.instagram?.url &&
              Boolean(formik?.errors?.instagram?.url)
            }
            helperText={
              formik?.touched?.instagram?.url && formik?.errors?.instagram?.url
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldTitle>Facebook</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="facebook.url"
            placeholder="Insira a url do seu facebook institucional"
            onChange={formik.handleChange}
            value={formik.values.facebook.url}
            error={
              formik?.touched?.facebook?.url &&
              Boolean(formik?.errors?.facebook?.url)
            }
            helperText={
              formik?.touched?.facebook?.url && formik?.errors?.facebook?.url
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldTitle>Twitter</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="twitter.url"
            placeholder="Insira a url do seu twitter profissional"
            onChange={formik.handleChange}
            value={formik.values.twitter.url}
            error={
              formik?.touched?.twitter?.url &&
              Boolean(formik?.errors?.twitter?.url)
            }
            helperText={
              formik?.touched?.twitter?.url && formik?.errors?.twitter?.url
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
