import React from 'react';

import * as S from './style';

type Props = {
  title: string;
  id: string;
};

const ImageDisplayer = (props: Props) => {
  const [image, setImage] = React.useState('');

  React.useEffect(() => {
    const src = require(
      `../../../assets/imgs/yearbook/sponsors-charts/${props.id}.png`,
    );

    setImage(src.default);
  }, []);

  return (
    <S.Wrapper>
      <img src={image} alt={props.title} />
    </S.Wrapper>
  );
};

export default ImageDisplayer;
