import {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_SortingState,
} from 'material-react-table';
import { useInfiniteQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { buildFilters } from 'src/pages/AllOperations/utils';
import OperationInvestorsInfiniteTable from 'src/pages/Operation/OperationInvestorsInfiniteTable';
import { convertSorting } from 'src/pages/Operation/utils';
import { getCriCraInvestorsPositions } from 'src/services/operation';
import { convertNumber, convertValue } from 'src/utils/number';

const PAGE_SIZE = 100;

type Props = {
  operationName: string;
  operationId: string;
};

export default function OperationCRIInvestorsPosition({
  operationName,
  operationId,
}: Props) {
  const { auth } = useAuthContext();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>({});

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'investor.name',
        header: 'FII Investidor',
        filterFn: 'contains',
        enableColumnFilterModes: false,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <Link
              component={RouterLink}
              to={`/operacao/fii/${cell?.row?.original?.investor?.slug}/${cell?.row?.original?.investor?.operationId}`}>
              {cell.getValue()}
            </Link>
          ) : (
            'Ops! Não encontramos\ninvestidores para esta operação'
          ),
      },
      {
        accessorKey: 'quantidade',
        header: 'Quantidade',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertNumber(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'valor',
        header: 'Valor de mercado',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'serieTratada',
        header: 'Série Investida',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'relatedOperation.class',
        accessorFn: (row) => row.relatedOperation?.class,
        header: 'Classe Investida',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataReferencia',
        header: 'Mês de referência',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => {
          if (!cell.getValue()) return '-';
          const formattedDate = format(new Date(cell.getValue()), 'MMMM/yyyy', {
            locale: ptBR,
          });
          return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        filterFn: 'equals',
        filterVariant: 'date',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
    ],
    [],
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: [
        'operation-cri-investors-positions',
        operationId,
        columnFilters,
        sorting,
      ],
      queryFn: ({ pageParam = 1 }) => {
        const filter = buildFilters(columnFilters, columnFilterFns);
        const sortingParams = convertSorting(sorting);

        return getCriCraInvestorsPositions({
          operationId,
          permission: 'operacoes_cri_posicao_investidores',
          page: pageParam,
          pageSize: PAGE_SIZE,
          filters: filter,
          sorting: sortingParams,
        });
      },
      getNextPageParam: (lastPage) => {
        if (
          lastPage?.meta?.pagination?.page <
          lastPage?.meta?.pagination?.pageCount
        ) {
          return lastPage.meta.pagination.page + 1;
        }
        return undefined;
      },
      enabled: !!operationId,
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    });

  const extraExportColumns: any = [
    {
      accessorKey: 'operationName',
      header: 'Nome da Operação',
    },
  ];

  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page.data) ?? [],
    [data],
  );
  const totalCount = data?.pages?.[0]?.meta?.pagination?.total ?? 0;
  const totalFetched = data?.pages?.[0]?.data?.length ?? 0;
  const previousScrollTopRef = useRef<number>(0);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (
        containerRefElement &&
        hasNextPage &&
        !isFetching &&
        totalFetched < totalCount
      ) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalCount, hasNextPage],
  );

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop } = target;

    if (scrollTop !== previousScrollTopRef.current) {
      previousScrollTopRef.current = scrollTop;
      fetchMoreOnBottomReached(target);
    }
  };

  useEffect(() => {
    const filterFns = columns.reduce(
      (obj: any, item: any) => ({
        ...obj,
        [item.accessorKey]: item._filterFn ?? item.filterFn,
      }),
      {},
    );
    setColumnFilterFns(filterFns);
  }, [setColumnFilterFns]);

  return (
    <Box p={4}>
      <SubTitle title={`Posição dos investidores do CRI ${operationName}`} />
      {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
        <PanelLogin small={undefined} message={undefined} />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22.1px',
            }}>
            Acompanhe a composição dos investidores em diferentes momentos do
            tempo, veja quem está investindo e em que quantidades. Informações
            disponíveis apenas para investidores que sejam fundos de
            investimentos.
          </Typography>
          <OperationInvestorsInfiniteTable
            data={flatData}
            columns={columns}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            sorting={sorting}
            setSorting={setSorting}
            columnFilterFns={columnFilterFns}
            setColumnFilterFns={setColumnFilterFns}
            isLoading={isLoading}
            operationName={operationName}
            market="cri"
            indicator={'posicao'}
            hasBottom={true}
            permission={'operacoes_cri_posicao_investidores'}
            exportPermission={'operacoes_posicao_investidores_exportar'}
            extraExportColumns={extraExportColumns}
            defaultColumnSort="dataReferencia"
            handleScroll={handleScroll}
            totalCount={totalCount}
          />
        </>
      )}
    </Box>
  );
}
