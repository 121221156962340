import { makeStyles } from '@material-ui/core';

export const AllArticlesStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  itemInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
  container: {
    width: '100%',
    margin: '0 auto',
  },
  articleGrid: {
    width: '100%',
  },
  chipSelected: {
    border: 0,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    '&.MuiChip-root:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiChip-root:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  chip: {
    border: 0,
    fontWeight: 500,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.secondary.contrastText,
  },
  chipGrid: {
    margin: theme.spacing(0.5, 0.5),
  },
}));
