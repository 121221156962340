// Declarative routing
import {
  Box,
  Divider,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

// Material ui components
// Images
import breakDefault from '../../assets/imgs/break-default.svg';
import { getSmallestImageUrl } from '../../utils/image';

const useStyles = makeStyles((theme) => ({
  paper: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  logo: {
    width: 80,
    height: '100%',
    backgroundSize: 'contain',
  },
  serviceProvider: {
    fontWeight: theme.typography.fontWeightBold,
  },
  name: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
    width: 170,
    [theme.breakpoints.between('md', 'sm')]: {
      width: 280,
    },
    [theme.breakpoints.up('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('xs')]: {
      width: 190,
    },
  },
  dividerSecondary: {
    height: 2,
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.5,
  },
}));

export default function CardServiceProviderDivider({
  serviceProvider,
  logo,
  name,
  startDate,
  slug,
  hashId,
}) {
  const classes = useStyles();

  return (
    <>
      {serviceProvider === 'Securitizadora' || !hashId ? (
        <Box>
          <Paper square className={classes.paper}>
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <img
                  src={breakDefault}
                  alt="Sem logo"
                  className={classes.logo}
                />
                <Box p={3}>
                  <Typography
                    component="div"
                    variant="subtitle2"
                    className={classes.name}>
                    <Box textOverflow="ellipsis" overflow="hidden">
                      {name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Divider className={classes.dividerSecondary} />
              <Box display="flex" justifyContent="flex-end">
                {startDate ? (
                  <Typography variant="caption">{`Mandato iniciado em: ${startDate}`}</Typography>
                ) : (
                  <Typography style={{ height: 19 }} />
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Link
          component={RouterLink}
          underline="none"
          to={`/instituicao/${slug}/${hashId}`}>
          <Paper square className={classes.paper}>
            <Box p={2}>
              <Box display="flex" alignItems="center">
                {logo ? (
                  <img
                    src={getSmallestImageUrl(logo)}
                    alt="Logo"
                    className={classes.logo}
                  />
                ) : (
                  <img
                    src={breakDefault}
                    alt="Sem logo"
                    className={classes.logo}
                  />
                )}
                <Box p={3}>
                  <Typography
                    component="div"
                    variant="subtitle2"
                    className={classes.name}>
                    <Box textOverflow="ellipsis" overflow="hidden">
                      {name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Divider className={classes.dividerSecondary} />
              <Box display="flex" justifyContent="flex-end">
                {startDate ? (
                  <Typography variant="caption">{`Mandato iniciado em: ${startDate}`}</Typography>
                ) : (
                  <Typography style={{ height: 19 }} />
                )}
              </Box>
            </Box>
          </Paper>
        </Link>
      )}
    </>
  );
}
