// Material ui components
import { useEffect } from 'react';

import {
  Box,
  Breadcrumbs,
  Divider,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useMutation, useQuery } from 'react-query';
// Declarative routing
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useAuthContext } from '../../context/AuthContextProvider';
import {
  addFavorite,
  findFavoriteByType,
  removeFavorite,
} from '../../services/favorite';
import { useScript } from '../../utils/hooks';
import { truncateText } from '../../utils/string';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
  market: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 400,
    lineHeight: 1.235,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  customTooltip: {
    maxWidth: 600,
    padding: '1rem',
    fontSize: '12px',
  },
  customTooltipMobile: {
    maxWidth: '98%',
    padding: '1rem',
    fontSize: '14px',
  },
}));

export default function Subheader(props) {
  const {
    title,
    market,
    breadcrumbs1,
    breadcrumbs2,
    favoriteType,
    tooltipText,
  } = props;
  useScript('https://tally.so/widgets/embed.js');
  const classes = useStyles();
  const { auth } = useAuthContext();
  const { hashId } = useParams();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { data: favorite, refetch } = useQuery(
    ['favorite', favoriteType],
    () => findFavoriteByType({ [favoriteType]: hashId }),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (favoriteType && auth?.user?.id) {
      refetch();
    }
  }, [auth.user]);

  const addFavoriteMutation = useMutation(addFavorite, {
    onSuccess: () => refetch(),
  });

  const { isLoading: addFavoriteLoading } = addFavoriteMutation;

  const removeFavoriteMutation = useMutation(removeFavorite, {
    onSuccess: () => refetch(),
  });

  const { isLoading: removeFavoriteLoading } = removeFavoriteMutation;

  const handleAddFavorite = () => {
    if (!addFavoriteLoading && !removeFavoriteLoading) {
      addFavoriteMutation.mutate({
        data: {
          user: auth?.user?.id,
          [favoriteType]: parseInt(hashId),
        },
      });
    }
  };

  const handleRemoveFavorite = () => {
    if (!addFavoriteLoading && !removeFavoriteLoading) {
      removeFavoriteMutation.mutate(favorite?.data[0]?.id);
    }
  };

  return (
    <div style={{ width: '100%', padding: '24px 0' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box p={1}>
          <Typography className={classes.title}>
            {title}
            {tooltipText ? (
              <Tooltip
                enterTouchDelay={10}
                arrow
                placement={isExtraSmall ? 'bottom' : 'right'}
                title={tooltipText}
                classes={{
                  tooltip: isExtraSmall
                    ? classes.customTooltipMobile
                    : classes.customTooltip,
                }}>
                <IconButton>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
            {market && <span className={classes.market}>{` ${market}`}</span>}
            {auth?.isLoggedIn && favoriteType && (
              <>
                <Tooltip
                  enterTouchDelay={10}
                  arrow
                  placement={isExtraSmall ? 'bottom' : 'right'}
                  title={
                    favorite?.data?.length > 0
                      ? 'Remover dos meus favoritos'
                      : 'Adicionar nos meus favoritos'
                  }
                  classes={{
                    tooltip: isExtraSmall
                      ? classes.customTooltipMobile
                      : classes.customTooltip,
                  }}>
                  {favorite?.data?.length > 0 ? (
                    <IconButton
                      onClick={handleRemoveFavorite}
                      disabled={addFavoriteLoading || removeFavoriteLoading}>
                      <StarIcon fontSize="small" color="secondary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleAddFavorite}
                      disabled={addFavoriteLoading || removeFavoriteLoading}>
                      <StarBorderIcon fontSize="small" color="secondary" />
                    </IconButton>
                  )}
                </Tooltip>
              </>
            )}
            {favoriteType === 'operation' && (
              <Tooltip
                enterTouchDelay={10}
                arrow
                placement={isExtraSmall ? 'bottom' : 'right'}
                title="Faltou algo? Conte para nós"
                classes={{
                  tooltip: isExtraSmall
                    ? classes.customTooltipMobile
                    : classes.customTooltip,
                }}>
                <IconButton
                  onClick={() => {
                    window.Tally.openPopup('w5XL9b', {
                      width: 640,
                      autoClose: 2000,
                      layout: 'modal',
                      overlay: true,
                      hiddenFields: {
                        mercado: market,
                        operacao: title,
                      },
                    });
                  }}>
                  <EditOutlined color="secondary" />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Box>
        <Hidden xsDown>
          <Box p={1}>
            {(breadcrumbs1 || breadcrumbs2) && (
              <Breadcrumbs
                className={classes.breadcrumbs}
                aria-label="navegação-secundária">
                <Link
                  component={RouterLink}
                  to="/"
                  underline="none"
                  className={classes.link}>
                  Home
                </Link>
                <Typography>{breadcrumbs1}</Typography>
                {breadcrumbs2 && (
                  <Typography title={breadcrumbs2}>
                    {truncateText(breadcrumbs2, 30)}
                  </Typography>
                )}
              </Breadcrumbs>
            )}
          </Box>
        </Hidden>
      </Box>
      <Divider className={classes.divider} />
    </div>
  );
}
