import { useMemo } from 'react';

import { alpha, Paper, useTheme } from '@mui/material';
import {
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { Profitability } from 'src/types/profitability';

import {
  calculateLogarithmicRegression,
  createChartOptions,
  generateRegressionLine,
} from './chartConfiguration';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

type ScatterPlotProps = {
  data: Profitability[];
  width: number;
  height: number;
  showRegression: boolean;
};

export function ScatterPlotWithRegression({
  data,
  width,
  height,
  showRegression = false,
}: ScatterPlotProps) {
  const theme = useTheme();
  const { slope, yIntercept } = useMemo(
    () => calculateLogarithmicRegression(data),
    [data],
  );

  const regressionLine = useMemo(
    () => generateRegressionLine(data, slope, yIntercept),
    [data, slope, yIntercept],
  );

  const options = useMemo(() => createChartOptions(data), [data]);

  const regressionLineData: ChartDataset<'scatter'> = useMemo(
    () => ({
      data: regressionLine.map((point) => ({
        x: point.x,
        y: point.y,
        label: point.id,
      })),
      showLine: true,
      pointRadius: 0,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
      pointHitRadius: 10,
      pointHoverRadius: 8,
      pointBackgroundColor: theme.palette.primary.main,
    }),
    [regressionLine, theme],
  );

  const chartData: ChartData<'scatter'> = useMemo(
    () => ({
      datasets: [
        {
          data: data.map((point) => ({
            x: point.duration,
            y: point.taxa,
            label: point.id,
          })),
          backgroundColor: alpha(theme.palette.primary.main, 0.5),
          pointRadius: 8,
        },
        ...(showRegression ? [regressionLineData] : []),
      ],
    }),
    [data, showRegression, regressionLineData, theme],
  );

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        width: '100%',
        bgcolor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(1),
      })}>
      <Scatter
        data={chartData}
        options={options}
        width={width}
        height={height}
      />
    </Paper>
  );
}
