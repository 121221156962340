import { api } from 'src/api/api';

export const createSessionStripe = async ({ type }: { type: string }) => {
  const { data } = await api.post<{ url: string }>('/create-session', {
    data: { type },
  });

  return data;
};

export const redirectCheckout = async (values: any) => {
  const { data } = await api.post<{ url: string }>('/redirect-checkout', {
    data: values,
  });

  return data;
};
