import { useEffect, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory, useParams } from 'react-router-dom';
import TabTemplate from 'src/components/UI/TabTemplate';
import { Tab } from 'src/components/UI/TabTemplate/styles';
import { v4 as uuid } from 'uuid';

import AccountManagement from './AccountManagement';
import UserData from './UserData';

function AccountProfile() {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const params = useParams<{ tab: string }>();
  const tabData = [
    {
      name: 'dados',
      content: <UserData />,
      tab: (
        <Tab
          key={uuid()}
          icon={<ContactPageOutlinedIcon />}
          iconPosition="start"
          label="Dados essenciais"
        />
      ),
    },
    {
      name: 'acesso',
      content: <AccountManagement />,
      tab: (
        <Tab
          key={uuid()}
          icon={<LockOutlinedIcon />}
          iconPosition="start"
          label="Acesso à conta"
        />
      ),
    },
  ];

  useEffect(() => {
    if (params.tab) {
      const index = tabData.findIndex((i) => i.name === params.tab);
      if (index >= 0) {
        setTabIndex(index);
      }
    }
  }, [params]);

  return (
    <TabTemplate
      title="Configurações da conta"
      titleIcon={<AccountCircleIcon />}
      tabItems={tabData.map((i) => i.tab)}
      tabIndex={tabIndex}
      handleChange={(tab) => {
        setTabIndex(tab);
        const tabName = tabData[tab]?.name;
        if (tabName) history.push(`/perfil/${tabName}`);
      }}
      tabContent={tabData.map((i) => i.content)}
      breadcrumbs1="Configurações da conta"
    />
  );
}

export default AccountProfile;
