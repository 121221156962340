/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CurrencyInput from 'react-currency-input-field';

import { Container } from './styles';
import { DataContext } from '../../../../context/DataContext';
import CalculateIcon from '../../icons/Calculate';
import { handleFormatDate, startDateOfMonth } from '../../utils/handleDate';
import Benchmark from '../Benchmark';

const Calculator = ({ formik }) => {
  const { security, calculator } = formik;
  const {
    loading,
    simulateResult,
    timeMeasures,
    calculatePrice,
    calculateResultPrice,
    calculateResultReturn,
    setCalculateResultPrice,
    calculateReturn,
  } = useContext(DataContext);
  const [isPrice, setIsPrice] = useState(true);
  const [price, setPrice] = useState(null);
  const [cashFlow, setCashFlow] = useState({});

  const handleSwitch = (event) => {
    setIsPrice(event.target.checked);
  };

  const disabledDate = (date) => {
    return date.getDate() !== 1;
  };

  const getCashFlow = () => {
    const propCashFlow = {};
    simulateResult?.total.forEach((item) => {
      propCashFlow[`${item.date}`] = item.value;
    });
    setCashFlow(propCashFlow);
  };

  const handleCalculate = () => {
    if (isPrice) {
      const data = {
        date: calculator.values.price.date,
        cash_flow: cashFlow,
        rate: {
          time_measure: calculator.values.price.rate.time_measure,
          index_def_id: calculator.values.price.rate.index_def_id,
          index_factor: calculator.values.price.rate.index_factor,
          index_spread: calculator.values.price.rate.index_spread,
        },
      };
      calculatePrice(data);
    } else {
      const data = {
        cash_flow: cashFlow,
        date: calculator.values.return.date,
        price: price && price,
        time_measure: calculator.values.return.time_measure,
      };
      calculateReturn(data);
    }
  };

  const getCurrentResultValue = () => {
    if (isPrice) {
      if (calculateResultPrice?.present_value) {
        return parseFloat(calculateResultPrice?.present_value).toFixed(2);
      } else {
        return '';
      }
    } else {
      if (calculateResultReturn?.rate) {
        return (parseFloat(calculateResultReturn.rate) * 100).toFixed(2);
      } else {
        return '';
      }
    }
  };

  const getCurrentMeasureValuePrice = () => {
    if (calculator.values.price.rate.time_measure !== '') {
      return timeMeasures?.find(
        (timeMeasure) =>
          timeMeasure.key === calculator.values.price.rate.time_measure,
      );
    }

    return '';
  };

  useEffect(() => {
    calculator.setFieldValue('price.date', startDateOfMonth());
    calculator.setFieldValue('return.date', startDateOfMonth());
    setPrice(null);
    setCalculateResultPrice({ present_value: '' });
  }, [isPrice]);

  useEffect(() => {
    getCashFlow();
  }, [simulateResult]);

  useEffect(() => {
    if (timeMeasures.length > 0) {
      calculator.setFieldValue('price.rate.time_measure', timeMeasures[0].key);
      calculator.setFieldValue('return.time_measure', timeMeasures[0].key);
    }
  }, [timeMeasures]);

  return (
    <Container>
      <div className="box-calculator">
        <div className="box-calculator-title">
          <CalculateIcon />
          Calculadora
        </div>
        <div className="header-switch">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Retorno</span>
            <Switch
              checked={isPrice}
              onChange={handleSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
              style={{ color: '#000' }}
            />
            <span>Preço</span>
          </div>
        </div>
      </div>
      <div className="options-calculator">
        <div className="option">
          <KeyboardDatePicker
            disableToolbar
            disableFuture
            fullWidth
            autoOk
            variant="inline"
            views={['year', 'month']}
            invalidDateMessage="Formato Inválido"
            format="MM/yyyy"
            shouldDisableDate={disabledDate}
            value={calculator.values.price.date || null}
            onChange={(newValue) => {
              const momentString = handleFormatDate(newValue, true);
              calculator.setFieldValue('price.date', momentString);
              calculator.setFieldValue('return.date', momentString);
            }}
          />
          <CurrencyInput
            id="input-price"
            name="input-name"
            placeholder="Saldo"
            value={price ? price : ''}
            decimalsLimit={2}
            onValueChange={(value) => {
              value && setPrice(value);
            }}
            onBlur={security.handleBlur}
            prefix="R$ "
            className={isPrice ? 'hide' : 'show input-price'}
          />
          <Autocomplete
            style={{ marginTop: '0.5rem' }}
            className={!isPrice ? 'hide' : 'show'}
            fullWidth
            size="small"
            disableClearable
            onBlur={security.handleBlur}
            value={getCurrentMeasureValuePrice()}
            onChange={(event, newValue) => {
              calculator.setFieldValue('price.rate.time_measure', newValue.key);
              calculator.setFieldValue('return.time_measure', newValue.key);
            }}
            options={timeMeasures ? timeMeasures : []}
            getOptionLabel={(option) => option.text || ''}
            autoHighlight
            renderInput={(params) => (
              <TextField {...params} label="Base de cálculo" />
            )}
          />
        </div>
        <div className={!isPrice ? 'hide option triple' : 'option triple'}>
          <Benchmark formikObj={calculator} />
        </div>
        <div>
          <div className="inline">
            <div style={{ width: '50%' }}>
              <Button
                onClick={handleCalculate}
                variant="contained"
                size="small"
                color="secondary"
                className="btn-calculate"
                fullWidth>
                {!loading ? (
                  'Calcular'
                ) : (
                  <CircularProgress size="1.45rem" color="#fff" />
                )}
              </Button>
            </div>
            <div style={{ width: '50%' }}>
              <FormControl fullWidth>
                {isPrice ? (
                  <InputLabel>Resultado</InputLabel>
                ) : (
                  <InputLabel>Retorno (%aa)</InputLabel>
                )}
                <Input
                  onBlur={security.handleBlur}
                  value={getCurrentResultValue() || ''}
                  startAdornment={
                    isPrice ? (
                      <InputAdornment position="start">R$</InputAdornment>
                    ) : (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }
                  label="Resultado"
                  readOnly
                  fullWidth
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Calculator;
