import { useEffect } from 'react';

import {
  Bookmark,
  BookmarkBorder,
  Star,
  StarOutline,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  addFavorite,
  findFavoriteByType,
  removeFavorite,
} from 'src/services/favorite';

import { CustomTooltip } from '../CustomTooltip';

export const FavoriteButton = ({
  type,
  id,
  icon = 'star',
  iconWidth,
  iconHeight,
  tooltipPlacement = 'right',
  withText = false,
  disabled = false,
}: {
  type:
    | 'operation'
    | 'professional'
    | 'institution'
    | 'article'
    | 'service'
    | 'event'
    | 'presentation';
  id?: number;
  icon?: 'star' | 'bookmark';
  iconWidth?: string;
  iconHeight?: string;
  tooltipPlacement?: string;
  withText?: string | boolean;
  disabled?: boolean;
}) => {
  const { auth } = useAuthContext();
  const { hashId } = useParams<{ hashId: string }>();
  const { data: favorite, refetch } = useQuery(
    ['favorite', type, id, hashId],
    () =>
      findFavoriteByType({
        [type]: id ?? hashId,
      } as any),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (type && auth?.user?.id) {
      refetch();
    }
  }, [auth.user]);

  const addFavoriteMutation = useMutation(addFavorite, {
    onSuccess: () => refetch(),
  });

  const { isLoading: addFavoriteLoading } = addFavoriteMutation;

  const removeFavoriteMutation = useMutation(removeFavorite, {
    onSuccess: () => refetch(),
  });

  const { isLoading: removeFavoriteLoading } = removeFavoriteMutation;

  const handleAddFavorite = () => {
    if (!addFavoriteLoading && !removeFavoriteLoading) {
      addFavoriteMutation.mutate({
        data: {
          user: auth?.user?.id,
          [type]: id ?? parseInt(hashId),
        },
      });
    }
  };

  const handleRemoveFavorite = () => {
    if (!addFavoriteLoading && !removeFavoriteLoading) {
      removeFavoriteMutation.mutate(favorite?.data[0]?.id);
    }
  };

  const handleStartIcon = () => {
    if (favorite?.data?.length > 0) {
      return (
        <CustomTooltip
          title={`Remover dos meus ${
            icon === 'star' ? 'favoritos' : 'itens salvos'
          }`}
          placement={tooltipPlacement}
          disableHoverListener={disabled}>
          <IconButton
            sx={{
              padding: 0,
              '&:hover': { background: 'transparent' },
              height: iconHeight ?? '31px',
              width: iconWidth ?? '31px',
            }}
            onClick={handleRemoveFavorite}
            disabled={disabled}
            aria-disabled={disabled}>
            {icon === 'star' ? (
              <Star
                sx={{
                  height: iconHeight ?? '31px',
                  width: iconWidth ?? '31px',
                  color: disabled ? '#00000042' : '#FF8211',
                }}
              />
            ) : (
              <Bookmark
                sx={{
                  height: iconHeight ?? '31px',
                  width: iconWidth ?? '31px',
                  color: disabled ? '#00000042' : '#FF8211',
                }}
              />
            )}
          </IconButton>
        </CustomTooltip>
      );
    }
    return (
      <CustomTooltip
        title={`Adicionar nos meus ${
          icon === 'star' ? 'favoritos' : 'itens salvos'
        }`}
        placement={tooltipPlacement}
        disableHoverListener={disabled}>
        <IconButton
          sx={{
            padding: 0,
            '&:hover': { background: 'transparent' },
            height: iconHeight ?? '31px',
            width: iconWidth ?? '31px',
          }}
          onClick={handleAddFavorite}
          disabled={disabled}
          aria-disabled={disabled}>
          {icon === 'star' ? (
            <StarOutline
              sx={{
                height: iconHeight ?? '31px',
                width: iconWidth ?? '31px',
                color: disabled ? '#00000042' : '#FF8211',
              }}
            />
          ) : (
            <BookmarkBorder
              sx={{
                height: iconHeight ?? '31px',
                width: iconWidth ?? '31px',
                color: disabled ? '#00000042' : '#FF8211',
              }}
            />
          )}
        </IconButton>
      </CustomTooltip>
    );
  };

  return (
    <Box
      sx={{ height: 'fit-content' }}
      aria-label={icon === 'star' ? 'Favoritar' : 'Salvar'}
      display="flex"
      gap={2}>
      {auth?.isLoggedIn && type && handleStartIcon()}
      {withText && (
        <Typography
          onClick={
            favorite?.data?.length > 0
              ? handleRemoveFavorite
              : handleAddFavorite
          }
          color="#828282"
          fontWeight="600">
          {withText}
        </Typography>
      )}
    </Box>
  );
};
