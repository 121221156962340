import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    height: 100%;
    box-shadow: 3px 3px 8px 1px #e6e6e6;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 3px 8px 1px #e6e6e6;
    background: #ffffff;
    overflow: auto;

    .box-graphic {
      font-weight: bold;
      padding: 16px;
      border-bottom: 2px solid #303030;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .box-data-content {
      font-weight: bold;
      font-size: 1rem;
      padding: 22px 22px 18px 23px;
      border-bottom: 2px solid #303030;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .box-graphic span {
      font-weight: normal;
    }

    .content-alert-graphic {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0px 40px;
    }

    .content-table {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 85%;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .box-graphic {
      height: 100%;
      overflow-x: auto;
    }

    @media screen and (max-width: 1640px) {
      .content-table {
        height: 81%;
      }
    }
  `}
`;
