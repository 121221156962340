import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 210px;
  object-fit: cover;

  @media screen and (max-width: 425px) {
    height: 160px;
  }
`;

export const IFrameCustom = styled.iframe`
  border: 0;
  overflow: hidden;
  alignself: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
