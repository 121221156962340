import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import warningImage from 'src/assets/imgs/warningImage.png';

type Props = {
  open: boolean;
  onClose: () => void;
  handleAction: () => void;
  institution: string;
};

function ConfirmInstitutionRequestDialog({
  open,
  onClose,
  handleAction,
  institution,
}: Props) {
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 10px 0',
          overflow: 'hidden',
        }}>
        <img src={warningImage} width={185} height={185} />
        <Typography
          sx={{ padding: '0px 32px', fontSize: '28px' }}
          variant="h4"
          textAlign="center">
          Uma solicitação será enviada para o administrador da instituição.
        </Typography>
        <Typography textAlign="center" sx={{ fontSize: '0.875rem' }}>
          {`Você informou fazer parte da empresa ${institution}, iremos enviar uma solicitação 
          ao administrador desta instituição e caso aceita seu perfil será vinculado a ela.`}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 0 40px 0',
        }}>
        <Button
          size="large"
          variant="contained"
          onClick={handleAction}
          sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
          Confirmar solicitação
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmInstitutionRequestDialog;
