import moment from 'moment';

export const startDateOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  const momentObj = moment(date);
  return momentObj.format('YYYY-MM-01');
};

export const handleFormatDate = (date, hyphen) => {
  const dateObj = new Date(date);
  const momentObj = moment(dateObj);
  return hyphen ? momentObj.format('MM-YYYY') : momentObj.format('MM/YYYY');
};
