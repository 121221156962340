import { useEffect, useState } from 'react';

import {
  Avatar,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';

import { CustomTooltip } from '../../../components/CustomTooltip';
import { If } from '../../../components/If';
import { useAuthContext } from '../../../context/AuthContextProvider';

function CustomTableHead({ classes, order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell className={classes.tableTitle}>
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}>
            Nome/CNPJ
            {orderBy ? <span className={classes.visuallyHidden} /> : null}
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.tableTitle}>
          Segmentos
          <CustomTooltip
            bg="#FF8211"
            placement="top"
            title="Conforme autodeclaração da própria instituição">
            <IconButton>
              <InfoOutlinedIcon color="secondary" fontSize="small" />
            </IconButton>
          </CustomTooltip>
        </TableCell>
        <TableCell className={classes.tableTitle}>
          Mercados
          <CustomTooltip
            bg="#FF8211"
            placement="top"
            title="Conforme autodeclaração da própria instituição">
            <IconButton>
              <InfoOutlinedIcon color="secondary" fontSize="small" />
            </IconButton>
          </CustomTooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function TableInstitutions({
  classes,
  rows,
  sortHistory,
  setSortHistory,
  setLastFound,
  setSize,
  setSort,
  total,
  filters,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { auth } = useAuthContext();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setSortHistory({});
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setSortHistory({
        ...sortHistory,
        [newPage]: rows?.at(-1)?.sortValue,
      });
    }
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSortHistory({});
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    setLastFound(sortHistory[page]);
  }, [page]);

  useEffect(() => {
    setSize(rowsPerPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [rowsPerPage]);

  useEffect(() => {
    setSort({ 'name.keyword': order });
  }, [order]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <CustomTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          <If condition={rows?.length > 0}>
            {rows.map((row) => (
              <TableRow
                className={classes.tableRow}
                component={Link}
                key={row.id}
                to={`/instituicao/${row.slug}/${row.id}`}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ width: '100px', height: '100px' }}>
                  {JSON.parse(row.logo) ? (
                    <Avatar
                      className={classes.logo}
                      src={`${
                        JSON.parse(row.logo)?.formats?.thumbnail.url ||
                        JSON.parse(row.logo).url
                      }`}
                    />
                  ) : (
                    <Avatar className={classes.avatar}>
                      <BusinessIcon fontSize="large" />
                    </Avatar>
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ width: '500px' }}>
                  <Typography className={classes.rowName}>
                    {row.name}
                  </Typography>
                  <Typography
                    className={`${classes.rowLight} ${
                      !auth.isLoggedIn && classes.blur
                    }`}>
                    {row.cnpj}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography className={classes.rowSegments}>
                    {row.segments?.join(', ')}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.markets?.map((market) => (
                    <Chip
                      className={classes.chip}
                      key={market}
                      label={market}
                      color="secondary"
                    />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </If>

          <If condition={rows?.length === 0}>
            <TableRow>
              <TableCell
                colSpan={4}
                rowSpan={10}
                component="td"
                scope="row"
                className={classes.notificationBox}>
                <Typography>Nenhum resultado encontrado</Typography>
              </TableCell>
            </TableRow>
          </If>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 30, 50]}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={'Registros por página'}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
