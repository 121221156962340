import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Professional } from 'src/types/professional';
import styled from 'styled-components';
import * as Yup from 'yup';

const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;

type Props = {
  open: boolean;
  index: number;
  professional?: Professional;
  handleClose: () => void;
  handleUpdate: (values: any) => void;
  isLoading: boolean;
};

function CertificationsForm({
  open,
  index,
  professional,
  handleClose,
  handleUpdate,
  isLoading,
}: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const FORM_VALIDATION = Yup.object().shape({
    certifications: Yup.array(
      Yup.object({
        name: Yup.string().required('Campo obrigatório'),
        issuer: Yup.string().required('Campo obrigatório'),
        issuedAt: Yup.string().required('Campo obrigatório').nullable(),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      certifications: professional?.certifications ?? [],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional) {
        const rest = _.omit(professional, 'id');
        handleUpdate({
          data: {
            ...rest,
            certifications: values.certifications,
          },
        });
      }
    },
  });

  const getErrors = <T extends { [key: string]: any }>(
    fieldName: keyof T,
    index: number,
  ) => {
    const error = formik.errors.certifications?.[index] as T | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={(e) => {
        formik.handleReset(e);
        handleClose();
      }}
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
      }}
      PaperProps={{
        sx: {
          height: 'fit-content',
          borderRadius: '16px',
          padding: isMobile ? '8px 20px 10px 20px' : '16px',
        },
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          Editar certificação
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            padding: isMobile ? '16px 0' : '16px',
          }}>
          <Grid
            container
            spacing={3}
            sx={(theme) => ({ padding: theme.spacing(2) })}>
            <Grid item xs={12}>
              <FieldTitle>Nome*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name={`certifications[${index}].name`}
                  value={formik.values.certifications[index].name}
                  onChange={formik.handleChange}
                  placeholder="Ex: ESG no mercado financeiro"
                  error={Boolean(getErrors<{ name: string }>('name', index))}
                  helperText={getErrors<{ name: string }>('name', index)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <FieldTitle>Emissor*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name={`certifications[${index}].issuer`}
                  value={formik.values.certifications[index].issuer}
                  onChange={formik.handleChange}
                  placeholder="Ex: XP Academy"
                  error={Boolean(
                    getErrors<{ issuer: string }>('issuer', index),
                  )}
                  helperText={getErrors<{ issuer: string }>('issuer', index)}
                />
              </FormControl>
            </Grid>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}>
              <Grid item xs={12} md={4}>
                <FieldTitle>Emitido em*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <DatePicker
                    views={['month', 'year']}
                    format="MM/yyyy"
                    maxDate={new Date()}
                    slotProps={{
                      textField: {
                        name: `certifications[${index}].issuedAt`,
                        placeholder: 'MM/AAAA',
                        error: Boolean(
                          getErrors<{ issuedAt: string }>('issuedAt', index),
                        ),
                      },
                    }}
                    onChange={(value) => {
                      formik.setFieldValue(
                        `certifications[${index}].issuedAt`,
                        value && isValid(value)
                          ? format(value, 'yyyy-MM-dd')
                          : value,
                      );
                    }}
                    value={
                      formik.values.certifications?.[index]?.issuedAt
                        ? parseISO(formik.values.certifications[index].issuedAt)
                        : undefined
                    }
                  />

                  {Boolean(
                    getErrors<{ issuedAt: string }>('issuedAt', index),
                  ) && (
                    <FormHelperText sx={{ color: '#EB5757' }}>
                      {getErrors<{ issuedAt: string }>('issuedAt', index)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '0 16px 16px', gap: 2 }}>
          <Hidden smDown>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar edição'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                handleClose();
              }}
              disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar edição'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar edição'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    handleClose();
                  }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar edição'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CertificationsForm;
