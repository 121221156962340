import { Dispatch, SetStateAction } from 'react';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { ProfitabilityFilter, State } from 'src/types/profitability';

type ProfitabilityFiltersProps = {
  filters?: ProfitabilityFilter;
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
  isLoading: boolean;
};

const NOT_NULL_FILTERS = ['mercado', 'metodo'];

export function ProfitabilityFilters({
  filters,
  state,
  setState,
  disabled,
  isLoading,
}: ProfitabilityFiltersProps) {
  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const handleFilterChange = (filterCode: string, value: string) => {
    setState((currentState: State) => {
      if (filterCode === 'mercado') {
        return {
          mercado: value,
          ativo_lastro: '',
          metodo: 'PRE',
          tipo_devedor: '',
          pulverizacao: '',
          classificacao_risco: '',
        };
      }

      return { ...currentState, [filterCode]: value };
    });
  };

  return (
    <Grid
      width={1000}
      maxWidth="100%"
      container
      spacing={isExtraSmall ? 1 : 2}
      alignItems="center">
      {isLoading ? (
        <Skeleton variant="rounded" width={'100%'} height={70} />
      ) : (
        Object.entries(filters || ({} as ProfitabilityFilter)).map(
          ([filterName, filter]) => (
            <Grid item xs={12} sm={6} md={2} key={filterName}>
              <FormControl variant="standard" fullWidth disabled={disabled}>
                <InputLabel id={filterName}>{filter.label}</InputLabel>
                <Select
                  labelId={filterName}
                  disabled={filter.options.length === 0 || disabled}
                  value={state[filter.code as keyof State]}
                  onChange={(e) =>
                    handleFilterChange(filter.code, e.target.value)
                  }
                  inputProps={{ 'aria-label': filterName }}>
                  {!NOT_NULL_FILTERS.includes(filter.code) && (
                    <MenuItem value="">Selecione</MenuItem>
                  )}
                  {filter.options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ),
        )
      )}
    </Grid>
  );
}
