import {
  Grid,
  makeStyles,
  Avatar as MuiAvatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Skeleton } from '@material-ui/lab';
import Avatar from 'react-avatar';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import SubtitleLink from '../../../components/SubtitleLink';
import { getProfessionalsForHome } from '../../../services/professional';
import { getSmallestImageUrl } from '../../../utils/image';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
  },
  tableContainer: {
    padding: theme.spacing(0, 6),
    minHeight: '420px',
    maxHeight: '420px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  container: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
  },
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tableTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  row: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#47484C',
  },
  rowLight: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#797979',
  },
  rowSectors: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#47484C',
    fontSize: '14px',
  },
  avatarCell: {
    padding: '16px 16px 16px 32px',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

export default function Professionals() {
  const classes = useStyles();

  const { data, isLoading, isFetched, error } = useQuery(
    'professionalsHome',
    () => getProfessionalsForHome(),
  );

  if (isFetched && !data?.length) return null;
  if (error) return null;

  return (
    <Paper elevation={0} className={classes.paper}>
      <SubtitleLink
        title="Profissionais"
        url="/profissionais"
        labelLink="Ver todos os profissionais"
      />
      <Grid container spacing={6} className={classes.container}>
        {isLoading ? (
          <Skeleton height={354} variant={'rect'} animation={'wave'} />
        ) : (
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.tableTitle}>
                    Nome/Cargo
                  </TableCell>
                  <TableCell className={classes.tableTitle}>Empresa</TableCell>
                  <TableCell className={classes.tableTitle}>Áreas</TableCell>
                  <TableCell className={classes.tableTitle}>Mercados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    className={index === 2 ? classes.tableRow : ''}
                    key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: '100px' }}>
                      {row.photo ? (
                        <Avatar
                          src={getSmallestImageUrl(row.photo)}
                          round
                          size="60"
                          maxInitials={2}
                          name={row.fullName}
                        />
                      ) : (
                        <MuiAvatar round className={classes.avatar}>
                          <PersonIcon fontSize="large" />
                        </MuiAvatar>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: '300px' }}>
                      <Typography className={classes.rowName}>
                        <RouterLink
                          to={`/professional/${row.slug}/${row.id}`}
                          className={classes.link}
                          title={row.fullName}>
                          {row.fullName}
                        </RouterLink>
                      </Typography>
                      <Typography className={classes.rowLight}>
                        {row.role}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: '250px' }}>
                      <Typography className={classes.row}>
                        {row?.relatedInstitutions?.razaoSocial}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.rowSectors}>
                        {row?.professionalSector
                          ?.map((sector) => sector.name)
                          ?.join(', ')}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.markets?.map((market, index) => (
                        <Typography
                          key={`${index}-professional`}
                          className={classes.row}
                          style={{ fontSize: '14px' }}>
                          {market.name}
                        </Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Paper>
  );
}
