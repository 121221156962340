import {
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { useEmbedAnalyticalStyles } from './styles';
import { useEmbedContext } from '../../../../context/EmbedContextProvider';
import { findRanking } from '../../../../services/ranking';
import { TableRanking } from '../../../Rankings/TableRanking';

export default function WidgetAnalyticalRanking({ state, hasConsolidated }) {
  const classes = useEmbedAnalyticalStyles();
  const { widgetKey } = useEmbedContext();
  const { data, isLoading } = useQuery(['rankings', state], () =>
    findRanking({
      filters: {
        market: state.market.toLowerCase(),
        year: state.year,
        type: state.ranking,
        criteria: state.criteria,
        timeRange: hasConsolidated ? state.timeRange : 'default',
      },
      widgetKey,
    }),
  );

  if (isLoading) return <CircularProgress color="secondary" />;

  return (
    <>
      <Typography className={classes.title}>{data?.label}</Typography>
      <Grid container>
        <Grid item xs={3} md={2}>
          <InputLabel className={classes.label}>Mercado</InputLabel>
          {state.market.toUpperCase()}
        </Grid>
        <Grid item xs={3} md={2}>
          <InputLabel className={classes.label}>Critério</InputLabel>
          {data?.valueLabel}
        </Grid>
        <Grid item xs={3} md={2}>
          <InputLabel className={classes.label}>Período</InputLabel>
          {state.year}
        </Grid>
        <Grid item xs={3} md={2}>
          <InputLabel className={classes.label}>Visão</InputLabel>
          {state.timeRange === 'consolidated'
            ? 'Consolidado'
            : state.timeRange === 'yearly'
              ? 'Anual'
              : 'Padrão'}
        </Grid>
      </Grid>
      <TableRanking
        rank={data?.ranks}
        valueLabel={data?.valueLabel}
        state={state}
        hasConsolidated={hasConsolidated}
        month={data?.month}
        hasPermission={false}
        openLinkNewTab={true}
      />
    </>
  );
}
