import qs from 'qs';
import { Service } from 'src/types/marketplace';

import { api, publicApi } from '../../api/api';
import { StrapiResponse } from '../../types/http';

export const getServicesByInstitution = async (institutionId: number) => {
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      filters: {
        institution: {
          id: institutionId,
        },
        status: 'Ativo',
        type: 'Oferta',
      },
      populate: '*',
      pagination: {
        pageSize: 3,
        page: 1,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await publicApi.get<StrapiResponse<Service[]>>(
    `/instituicao-servicos?${query}`,
  );

  return data;
};

export const getServicesByUserAndType = async (type: string, page = 1) => {
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      filters: {
        type,
      },
      populate: '*',
      pagination: {
        pageSize: 10,
        page,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(
    `/owned/marketplaces?${query}&publicationState=preview`,
  );

  return data;
};

export const notificationMarketplace = async (values: any) => {
  return api.post('/instituicao-servico/notificacao', {
    data: {
      ...values,
    },
  });
};
