import { validateCNPJ, validateCPF } from 'src/utils/document';
import * as Yup from 'yup';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
export const passwordValidationMessage =
  'A senha deve conter ao menos 8 caracteres, contendo um caractere especial, um número e uma letra maiúscula';

export const ValidateRegisterPrivatePerson = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required('Campo obrigatório')
      .email('Digite um e-mail válido'),
    name: Yup.string().required('Campo obrigatório'),
    otherName: Yup.string().required('Campo obrigatório'),
    document: Yup.string()
      .required('Campo obrigatório')
      .test(
        'len',
        'CPF inválido',
        (val) => val?.length === 11 && validateCPF(val),
      ),
    acceptTerms: Yup.boolean()
      .required('Campo obrigatório')
      .oneOf([true], 'Os termos e a política de privacidade são obrigatórios'),
    password: Yup.string()
      .required('Campo obrigatório')
      .matches(passwordRegex, ' '),
    repeatPassword: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais'),
  });
};

export const ValidateRegisterLegalPerson = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required('Campo obrigatório')
      .email('Digite um E-mail válido'),
    name: Yup.string().required('Campo obrigatório'),
    document: Yup.string()
      .required('Campo obrigatório')
      .test(
        'len',
        'Digite um CNPJ válido',
        (val) => val?.length === 14 && validateCNPJ(val),
      ),
    emailAdmin: Yup.string()
      .required('Campo obrigatório')
      .email('Digite um E-mail válido'),
    acceptTerms: Yup.boolean()
      .required('Campo obrigatório')
      .oneOf([true], 'Os termos e a política de privacidade são obrigatórios'),
    password: Yup.string()
      .required('Campo obrigatório')
      .matches(passwordRegex, ' '),
    repeatPassword: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais'),
  });
};
