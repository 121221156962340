import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 220px;
  min-width: 180px;
`;

export const VideoThumbnail = styled.img`
  border-radius: 10px;
  width: 100%;
  min-width: 220px;
  background-color: #575757;
  height: 120px;
  cursor: pointer;
`;

export const Title = styled.h3`
  font-size: 1rem;
  margin: 0;
`;

export const Author = styled.p`
  margin: 0;
`;
