import React from 'react';

import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import InstitutionalProfile from './components/InstitutionalProfile';
import ProfessionalProfile from './components/ProfessionalProfile';
import * as S from './components/styles';
import TabTemplate from '../../components/UI/TabTemplate';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getRelatedInstitution } from '../../services/institution';

const SocialProfile = () => {
  const { auth } = useAuthContext();
  const { data, isLoading, refetch } = useQuery(
    'getRelatedInstitution',
    getRelatedInstitution,
    {
      enabled: auth.isFetched,
    },
  );
  const history = useHistory();
  const params = useParams<{ tab: string }>();

  const [tabIndex, setTabIndex] = React.useState(0);

  const institution = data && Object.keys(data).length ? data : null;

  const isLegalPerson = auth.user?.type === 'legal_person';

  const tabData = [
    {
      name: 'institucional',
      content: (
        <InstitutionalProfile
          key={0}
          relatedInstitution={institution}
          relatedLoading={isLoading}
          refetch={refetch}
        />
      ),
      tab: (
        <S.Tab
          key={uuid()}
          icon={<BusinessIcon />}
          iconPosition="start"
          label="Perfil institucional"
        />
      ),
    },
    {
      name: 'profissional',
      content: <ProfessionalProfile key={1} relatedInstitution={data} />,
      tab: (
        <S.Tab
          key={uuid()}
          icon={<BadgeIcon />}
          iconPosition="start"
          label="Perfil profissional"
        />
      ),
    },
  ].filter((tab, index) => {
    if (isLegalPerson) {
      if (institution) {
        return true;
      } else {
        return index !== 1;
      }
    } else {
      return index !== 0;
    }
  });

  React.useEffect(() => {
    if (params.tab) {
      const index = tabData.findIndex((i) => i.name === params.tab);
      if (index >= 0) {
        setTabIndex(index);
      }
    }
  }, [params]);

  return (
    <TabTemplate
      title="Perfil social"
      titleIcon={<WorkIcon />}
      tabItems={tabData.map((i) => i.tab)}
      tabIndex={tabIndex}
      handleChange={(tab) => {
        const tabName = tabData[tab]?.name;
        if (tabName) history.push(`/perfil-social/${tabName}`);
      }}
      tabContent={tabData.map((i) => i.content)}
      breadcrumbs1="Perfil social"
    />
  );
};

export default SocialProfile;
