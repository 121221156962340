import qs from 'qs';

import { publicApi } from '../../api/api';

export const getBannerRotating = async () => {
  const query = qs.stringify(
    {
      populate: '*',
      order: ['order:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await publicApi.get(`/banner-rotatings?${query}`);
  return data;
};
