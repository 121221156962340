import { useState } from 'react';

import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { PanelLogin } from 'src/components/PanelLogin';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getCraWalletPDD } from 'src/services/operation/operationCra';

import OperationCraAssetPDD from './OperationCraAssetPDD';
import OperationCraAssetWallet from './OperationCraAssetWallet';

function OperationCraAssetWalletPDD({
  operationId,
  operationName,
}: {
  operationId: string;
  operationName: string;
}) {
  const { auth } = useAuthContext();
  const [error, setError] = useState(false);
  const hasPermission =
    auth.user?.subscription.plan.permissions
      .operacoes_cra_carteira_de_recebiveis_e_pdd;

  const { data } = useQuery(
    ['meta-cra-wallet-pdd', operationId],
    () => getCraWalletPDD(operationId),
    {
      onError: (error: any) => {
        if (error?.response?.data?.error?.name === 'PolicyError') {
          setError(true);
        }
      },
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const newData = data?.length ? [...data] : [];

  const selectOptions = data?.map((option) => option.dataReferencia);

  if (!data?.length && !error) return null;

  return (
    <Grid container columnSpacing={8} sx={{ paddingBottom: 4 }}>
      <Grid item xs={12} md={6}>
        <OperationCraAssetWallet
          data={newData}
          selectOptions={selectOptions}
          operationName={operationName}
          hasPermission={hasPermission}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OperationCraAssetPDD
          data={newData}
          selectOptions={selectOptions}
          operationName={operationName}
          hasPermission={hasPermission}
        />
      </Grid>
      <If
        condition={
          !auth.isLoggedIn ||
          auth?.user?.subscription?.plan?.type === 'free' ||
          error
        }>
        <Grid item xs={12}>
          <PanelLogin small={undefined} message={undefined} />
        </Grid>
      </If>
    </Grid>
  );
}

export default OperationCraAssetWalletPDD;
