import { useEffect, useState } from 'react';

import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format, parse } from 'date-fns';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getPresentationLive } from 'src/services/events';
import { Speaker as SpeakerType } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

import LiveCarousel from './LiveCarousel';
import SponsorBanner from './SponsorBanner';
import SubHeader from '../../../../components/UI/SubHeader/SubHeader';
import SpeakersDialog from '../../Schedule/Content/SpeakersDialog';
import LiveScreen from '../LiveScreen';

export default function EventPresentationLive() {
  const { id, slug, presentationId } = useParams<{
    id: string;
    slug: string;
    presentationId: string;
  }>();
  const history = useHistory();
  const [presentation, setPresentation] = useState<any>();
  const [openSpeakersDialog, setOpenSpeakersDialog] = useState(false);
  const [activeSpeakerIndex, setActiveSpeakerIndex] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const eventUrl = `/evento/${slug}/${id}`;

  const { isLoading } = useQuery(
    ['presentation', 'live', presentationId],
    () => getPresentationLive(id, presentationId),
    {
      onSuccess: (data) => {
        setPresentation(data);
      },
      onError: () => {
        history.replace(`${eventUrl}/programacao`);
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (presentation) {
      const endDate = new Date(`${presentation.date} ${presentation.endTime}`);
      const scheduleUrl = `${eventUrl}/programacao`;
      const eventTimeout =
        endDate.getTime() - new Date(presentation.serverDate).getTime();
      const redirectionTime = eventTimeout + 30 * 60 * 1000;

      const timer = setTimeout(() => {
        history.replace(scheduleUrl);
      }, redirectionTime);

      return () => clearInterval(timer);
    }
  }, [presentation]);

  if (isLoading || !presentation) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        padding="24px 0px">
        <CircularProgress color="primary" size="3rem" />
      </Box>
    );
  }

  if (!presentation.liveUrl) {
    history.replace(`${eventUrl}/programacao?presentationId=${presentationId}`);
  }

  const handleOpenSpeakersDialog = (index: number) => {
    setOpenSpeakersDialog(true);
    setActiveSpeakerIndex(index);
  };

  return (
    <>
      <SubHeader
        breadcrumbs={[
          { title: 'Eventos', link: '/eventos' },
          { title: presentation.event.title, link: eventUrl },
          { title: 'Programação', link: `${eventUrl}/programacao` },
          {
            title: presentation.name,
            link: `${eventUrl}/programacao?presentationId=${presentationId}`,
          },
          'Live',
        ]}
      />
      <Paper sx={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 3,
            pb: 2.25,
          }}
          overflow={'hidden'}>
          <Typography
            color="#4F4F4F"
            fontSize={{ md: 20, xs: 16 }}
            pl={{ md: 5.375, xs: 3 }}
            pr={{ md: 9, xs: 3 }}
            flexShrink={0}>
            Patrocínio de:
          </Typography>
          <SponsorBanner data={presentation.event.sponsors} mode={'preview'} />
        </Box>
        <LiveScreen presentation={presentation} />
        <Box
          sx={{
            py: 2.25,
            pl: 3,
            pr: isMobile ? 3 : 0,
            maxWidth: isMobile ? '100%' : '76.56%',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'space-between',
            }}>
            <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontSize={{ md: 40, xs: 32 }}
                fontWeight={700}
                color={'#333'}>
                {presentation.name}
              </Typography>
              <Typography fontSize={{ md: 18, xs: 16 }} color="#333">
                {`${format(
                  parse(presentation.startTime, 'HH:mm:ss', new Date()),
                  'HH:mm',
                )}
                \xa0-\xa0 ${format(
                  parse(presentation.endTime, 'HH:mm:ss', new Date()),
                  'HH:mm',
                )}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              <AvatarGroup
                max={isMobile ? 2 : 3}
                sx={(theme) => ({
                  cursor: 'pointer',
                  '& .MuiAvatar-root': {
                    width: '52px',
                    height: '52px',
                  },
                  '& > div:nth-of-type(1)': {
                    backgroundColor: '#FFA14D',
                    fontSize: '16px',
                    fontWeight: 700,
                  },
                  [theme.breakpoints.down('sm')]: {
                    '& .MuiAvatar-root': {
                      width: '48px',
                      height: '48px',
                    },
                  },
                })}>
                {presentation?.speakers?.length > 0 &&
                  presentation?.speakers?.map(
                    (speaker: SpeakerType, index: number) => (
                      <Avatar
                        onClick={() => handleOpenSpeakersDialog(index)}
                        key={speaker?.id}
                        src={getSmallestImageUrl(speaker?.professional?.photo)}
                        imgProps={{
                          sx: {
                            objectFit: 'contain',
                          },
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      />
                    ),
                  )}
              </AvatarGroup>
              <Typography
                variant="caption"
                fontSize={{ md: 16, xs: 14 }}
                color="#828282">
                Palestrantes
              </Typography>
            </Box>
          </Box>
          <Typography
            width="100%"
            mt={4}
            fontSize={18}
            color="#4F4F4F"
            textAlign="justify">
            {presentation.description}
          </Typography>
        </Box>
        <SpeakersDialog
          activeSpeakerIndex={activeSpeakerIndex}
          speakers={presentation.speakers}
          open={openSpeakersDialog}
          handleClose={() => setOpenSpeakersDialog(false)}
        />
        {presentation.state !== 'ended' && (
          <Box mt={7.75} sx={{ borderRadius: '5px' }}>
            <LiveCarousel presentation={presentation} />
          </Box>
        )}
      </Paper>
    </>
  );
}
