import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { EventSeatSharp, VideoCameraFront } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import ButtonDatePicker from 'src/components/ButtonDatePicker';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { EventFilter } from 'src/types/event';
import { useDebounce } from 'src/utils/hooks';

import CustomToggleButton from '../CustomToggleButton';

type Props = {
  query: EventFilter;
  isMobile: boolean;
  setQuery: (nextParams: any, method?: 'push' | 'remove' | undefined) => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  handleChange: (event: any, key: keyof EventFilter) => void;
  handleClear: (key: keyof EventFilter, value?: string) => void;
};

const FilterList = ({
  query,
  setQuery,
  setOpenDialog,
  isMobile,
  handleChange,
  handleClear,
}: Props) => {
  const { auth } = useAuthContext();
  const [openFormat, setOpenFormat] = useState(false);
  const [openLiveType, setOpenLiveType] = useState(false);
  const debouncedSearchTerm = useDebounce(query.search, 700);

  const handleClickFormat = () => {
    if (query.format) return handleClear('format', '');
    setOpenFormat((prev) => !prev);
  };

  const handleClickLiveType = () => {
    if (query.eventPresentationType)
      return handleClear('eventPresentationType', '');
    setOpenLiveType((prev) => !prev);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setQuery({
        search: debouncedSearchTerm,
      });
    } else {
      setQuery(
        {
          search: undefined,
        },
        'remove',
      );
    }
  }, [debouncedSearchTerm]);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      width={'100%'}
      minWidth={0}
      spacing={isMobile ? 0 : 'clamp(10px, 1.75vw, 30px)'}
      alignItems={isMobile ? 'start' : 'center'}
      sx={(theme) => ({
        justifyContent: 'start',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'end',
        },
      })}>
      <CustomToggleButton
        value="favorite"
        selected={Boolean(query.favorite)}
        startIcon={<StarIcon color="primary" />}
        handleChange={handleChange}>
        Favoritos
      </CustomToggleButton>
      <ButtonDatePicker
        views={['year', 'month']}
        label={
          query.date == null ? null : format(new Date(query.date), 'MM/yy')
        }
        value={query.date == null ? null : new Date(query.date)}
        onChange={() => true}
        onAccept={(e: any) => handleChange(e, 'date')}
        setState={handleClear}
        isMobile={isMobile}
      />
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          order: isMobile ? 2 : 0,
        }}>
        <Select
          name="format"
          value={query.format}
          displayEmpty
          startAdornment={
            <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
              <EventSeatSharp color="primary" />
            </InputAdornment>
          }
          onClick={handleClickFormat}
          onChange={(e) => !isMobile && handleChange(e, 'format')}
          open={!isMobile && openFormat}
          inputProps={{
            'aria-label': 'formato',
            IconComponent: () => null,
          }}
          renderValue={(selected: string) => {
            if (!selected) {
              return (
                <Typography fontSize={'inherit'} fontWeight={'inherit'}>
                  Formato
                </Typography>
              );
            }
            return selected;
          }}
          sx={(theme) => ({
            '&': {
              color: query.format ? theme.palette.primary.main : '#828282',
              fontWeight: 600,
              fontSize: '14px',
              paddingInline: '0 !important',
              alignSelf: 'self-start',
            },
            '& fieldset': {
              border: 'none',
            },
            '& .MuiSelect-select': {
              padding: '0 !important',
            },
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
            '& .MuiSelect-select::first-letter': {
              textTransform: 'capitalize',
            },
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: (theme) => ({
              '& li:hover': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: '#FFF',
              },
              '& .Mui-selected': {
                backgroundColor: 'unset !important',
              },
            }),
          }}>
          <MenuItem value={'on-line'}>On-line</MenuItem>
          <MenuItem value={'presencial'}>Presencial</MenuItem>
          <MenuItem value={'híbrido'}>Híbrido</MenuItem>
        </Select>
        <Stack
          direction="row"
          spacing={1}
          mt={3.5}
          display={isMobile ? 'flex' : 'none'}>
          {['On-line', 'Presencial', 'Híbrido'].map((label) => (
            <Chip
              key={label}
              label={label}
              onClick={() => handleChange(label, 'format')}
              variant={query.format === label ? 'filled' : 'outlined'}
              color={query.format === label ? 'primary' : 'secondary'}
            />
          ))}
        </Stack>
      </FormControl>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          order: isMobile ? 2 : 0,
        }}>
        <Select
          name="eventPresentationType"
          value={query.eventPresentationType}
          displayEmpty
          startAdornment={
            <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
              <VideoCameraFront color="primary" />
            </InputAdornment>
          }
          onClick={handleClickLiveType}
          onChange={(e) =>
            !isMobile && handleChange(e, 'eventPresentationType')
          }
          open={!isMobile && openLiveType}
          inputProps={{
            'aria-label': 'tipo de live',
            IconComponent: () => null,
          }}
          renderValue={(selected: string) => {
            if (!selected) {
              return (
                <Typography fontSize={'inherit'} fontWeight={'inherit'}>
                  Tipo de live
                </Typography>
              );
            }
            return selected;
          }}
          sx={(theme) => ({
            '&': {
              color: query.eventPresentationType
                ? theme.palette.primary.main
                : '#828282',
              fontWeight: 600,
              fontSize: '14px',
              paddingInline: '0 !important',
              alignSelf: 'self-start',
            },
            '& fieldset': {
              border: 'none',
            },
            '& .MuiSelect-select': {
              padding: '0 !important',
            },
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
            '& .MuiSelect-select::first-letter': {
              textTransform: 'capitalize',
            },
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: (theme) => ({
              '& li:hover': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: '#FFF',
              },
              '& .Mui-selected': {
                backgroundColor: 'unset !important',
              },
            }),
          }}>
          <MenuItem value={'independente'}>Live independente</MenuItem>
          <MenuItem value={'principal'}>Live principal</MenuItem>
        </Select>
        <Stack
          direction="row"
          spacing={1}
          mt={3.5}
          display={isMobile ? 'flex' : 'none'}>
          {['independente', 'principal'].map((label) => (
            <Chip
              key={label}
              label={label}
              onClick={() => handleChange(label, 'eventPresentationType')}
              variant={
                query.eventPresentationType === label ? 'filled' : 'outlined'
              }
              color={
                query.eventPresentationType === label ? 'primary' : 'secondary'
              }
            />
          ))}
        </Stack>
      </FormControl>
      <Box
        maxWidth={isMobile ? 'unset' : '150px'}
        sx={{ display: 'flex', alignItems: 'flex-end', order: 0 }}>
        <SearchIcon color="primary" sx={{ mr: 1 }} />
        <TextField
          value={query.search}
          onChange={(e) => handleChange(e, 'search')}
          size="small"
          placeholder="Buscar"
          variant="standard"
          sx={{
            width: '100%',
            '& > label, & > .MuiInput-root': {
              fontSize: '14px',
            },
          }}
        />
      </Box>
      <Box sx={{ marginLeft: 'auto !important' }}>
        <CustomTooltip
          title="Faça login para acessar esta funcionalidade"
          disableHoverListener={auth.isLoggedIn}
          disableTouchListener={auth.isLoggedIn}
          placement="top">
          <span>
            <Button
              startIcon={<AddCircle color="primary" fontSize="large" />}
              color="primary"
              onClick={() => setOpenDialog(true)}
              disabled={!auth.isLoggedIn}
              sx={(theme) => ({
                textTransform: 'none',
                textDecoration: 'underline !important',
                textWrap: 'nowrap',
                letterSpacing: 'unset',
                fontSize: '16px',
                fontWeight: 700,
                padding: 0,
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}>
              Cadastrar evento
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    </Stack>
  );
};

export default FilterList;
