import { Box, IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .alice-carousel': {
      padding: 0,
      '& .alice-carousel__stage-item': {
        maxWidth: 410.66,
      },
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
}));

const Dots = ({ length, activeIndex }) => {
  return (
    <Box display="flex">
      {Array.from({ length }).map((item, index) => (
        <div
          key={`${index}-${item}`}
          style={{
            width: activeIndex === index ? '27px' : '7px',
            height: '7px',
            backgroundColor: '#A7A8AC',
            borderRadius: '5px',
            margin: '0 8px',
          }}
        />
      ))}
    </Box>
  );
};

export function Carrousel({ children, totalItems, page, setPage }) {
  const classes = useStyles();
  const responsiveOptions = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    960: {
      items: 3,
    },
  };

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const itemsSlide = isExtraSmall ? 1 : isSmall ? 2 : 3;

  const slidePrev = () => setPage(page - 1);
  const slideNext = () => setPage(page + 1);
  const syncActiveIndex = ({ item }) => setPage(item);

  return (
    <Box className={classes.carousel}>
      <AliceCarousel
        responsive={responsiveOptions}
        onSlideChanged={syncActiveIndex}
        activeIndex={page}
        disableDotsControls
        disableButtonsControls
        touchTracking={false}
        mouseTracking={false}>
        {children}
      </AliceCarousel>
      {totalItems > itemsSlide && (
        <Box className={classes.controls}>
          <IconButton onClick={slidePrev} disabled={page === 0}>
            <ArrowBackIosIcon style={{ color: '#A7A8AC' }} />
          </IconButton>
          <Dots
            length={Math.ceil(totalItems / itemsSlide)}
            activeIndex={page}
          />
          <IconButton
            onClick={slideNext}
            disabled={page >= Math.floor((totalItems - 1) / itemsSlide)}>
            <ArrowForwardIosIcon style={{ color: '#A7A8AC' }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
