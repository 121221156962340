// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationCRAAssetAssignors from './OperationCRAAssetAssignors';
import OperationCRAAssetCharacteristics from './OperationCRAAssetCharacteristics';
import OperationCRAAssetData from './OperationCRAAssetData';
import OperationCRAAssetReceivables from './OperationCRAAssetReceivables';
import OperationCraAssetWalletPDD from './OperationCraAssetWalletPDD';
import { PanelLogin } from '../../../../components/PanelLogin';
// Providers
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRAAsset({
  hashId,
  operationName,
}: {
  hashId: string;
  operationName: string;
}) {
  const { auth } = useAuthContext();
  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cra_ativo'] ? (
        <Box p={2}>
          <PanelLogin message={null} small={null} />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRAAssetCharacteristics hash={hashId} />
          </Grid>
          <Grid item xs={12}>
            <OperationCRAAssetData hash={hashId} />
          </Grid>
          <Grid item xs={12}>
            <OperationCRAAssetAssignors hash={hashId} />
          </Grid>
          <OperationCraAssetWalletPDD
            operationId={hashId}
            operationName={operationName}
          />
          <OperationCRAAssetReceivables
            hash={hashId}
            operationName={operationName}
          />
        </Grid>
      )}
    </Box>
  );
}
