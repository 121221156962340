import { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  debounce,
  DialogActions,
  DialogContent,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import lodash from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import Test from 'react-test-attributes';
import RoleSelectField from 'src/components/FormsUI/RoleSelectField';
import ImageInput from 'src/components/ImageInput';
import {
  createProfessionalRequest,
  findInstitutionByNameOrCNPJ,
} from 'src/services/institution';
import {
  createProfessional,
  getResponsibilities,
} from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';
import { User } from 'src/types/user';

import * as S from '../styles';
import { ValidationProfessionalForm } from '../validation';

const PROFESSIONAL_PERMITED_MARKETS = ['CR', 'CRA', 'CRI', 'FII', 'FIDC'];

interface InstitutionOptions {
  id?: number;
  fantasyName: string;
  isOther?: boolean;
}

interface ResponsibilityOptions {
  inputValue?: string;
  name: string;
}

type Props = {
  segments: Segment[];
  markets: Market[];
  user?: User | null;
  institution?: Institution;
  handleClose?: () => void;
  handleFinished?: () => void;
  isInvited: boolean;
};

function ProfessionalForm({
  segments,
  markets,
  user,
  institution,
  handleClose,
  isInvited,
  handleFinished,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const dispatch = useDispatch();
  const filterOptionInstitution = createFilterOptions<InstitutionOptions>();
  const filterOptions = (options: any[], state: FilterOptionsState<any>) => {
    const results = filterOptionInstitution(options, state);

    return [{ fantasyName: 'Outra', isOther: true }, ...results];
  };
  const [institutions, setInstitutions] = useState<InstitutionOptions[]>([]);
  const [institutionName, setInstitutionName] = useState<string>(
    user?.company ?? '',
  );
  const [selectedInstitution, setSelectedInstitution] = useState<
    InstitutionOptions | string | null
  >(
    user?.company
      ? { fantasyName: 'Outra', isOther: true }
      : institution ?? { fantasyName: '' },
  );
  const [responsibilities, setResponsibilities] = useState<
    ResponsibilityOptions[]
  >([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string>();

  const { isLoading: institutionsLoading, refetch: institutionsRefetch } =
    useQuery(
      'institutions',
      () => findInstitutionByNameOrCNPJ(institutionName),
      {
        enabled: !!institutionName,
        onSuccess: ({ data }) => {
          setInstitutions(data);
        },
      },
    );

  const { isLoading: responsibilityLoading } = useQuery(
    'responsibilities',
    getResponsibilities,
    {
      onSuccess: (data) => {
        setResponsibilities(data);
      },
    },
  );

  const createProfessionalLinkMutation = useMutation(createProfessionalRequest);

  const createProfessionalMutation = useMutation(createProfessional, {
    onSuccess: () => {
      if (typeof selectedInstitution !== 'string' && selectedInstitution?.id) {
        createProfessionalLinkMutation.mutateAsync(selectedInstitution.id);
      }
      if (handleClose) {
        handleClose();
      }
      if (handleFinished) {
        handleFinished();
      }
      dispatch(
        setBannerMessage({
          message:
            'Perfil profissional criado com sucesso, acesse clicando <redirect path="/perfil-social/profissional">aqui</redirect>',
          type: 'success',
          autoclose: true,
        }),
      );
    },
    onError: () => {
      setBannerMessage({
        message: 'Ocorreu um erro. Tente novamente',
        type: 'error',
        autoclose: true,
      });
    },
  });

  const institutionsRefetchDebounce = useCallback(
    debounce(institutionsRefetch, 500),
    [],
  );

  const formik = useFormik({
    initialValues: {
      photo: null,
      name: user?.name ?? '',
      surname: user?.otherName ?? '',
      markets: [],
      professionalSegments: [],
      role: user?.companyRole ?? '',
      relatedInstitutions: {
        id: institution?.id ?? undefined,
        fantasyName: institution?.fantasyName ?? user?.company ?? '',
      },
      otherInstitution: user?.company ?? '',
    },
    validationSchema: ValidationProfessionalForm(institutionName),
    onSubmit: (values) => {
      const formData = new FormData();
      const {
        photo,
        relatedInstitutions,
        otherInstitution,
        ...professionalData
      } = values;
      if (photo) {
        formData.append('files.photo', photo);
      }
      formData.append(
        'data',
        JSON.stringify({
          ...professionalData,
          slug: lodash.kebabCase(
            `${professionalData.name} ${professionalData.surname} ${user?.id}`,
          ),
          institutionName:
            institutionName === 'Outra' ? otherInstitution : institutionName,
          owner: user?.id,
        }),
      );
      createProfessionalMutation.mutateAsync(formData);
    },
  });

  const handleTooltip = (status: boolean) => {
    setTooltipOpen(status);
  };

  if (responsibilityLoading) return <CircularProgress />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent
        sx={{ padding: isMobile ? '8px 20px 10px 30px' : '8px 60px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems="center">
            <ImageInput
              type="circular"
              name="photo"
              value={formik.values.photo}
              setValue={formik.setFieldValue}
              error={formik.touched.photo && Boolean(formik.errors.photo)}
              helperText={formik.errors.photo}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            container
            justifyContent="center"
            flexDirection="column"
            spacing={isMobile ? 0 : 2}>
            <Grid item>
              <S.FieldTitle>Nome*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                {/* @ts-ignore */}
                <Test id="ProfessionalProfile-form-name">
                  <TextField
                    name="name"
                    placeholder="Insira aqui seu nome"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Test>
              </FormControl>
            </Grid>
            <Grid item sx={{ paddingTop: isMobile ? 3 : 0 }}>
              <S.FieldTitle>Sobrenome*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                {/* @ts-ignore */}
                <Test id="ProfessionalProfile-form-secondName">
                  <TextField
                    name="surname"
                    placeholder="Insira aqui seu sobrenome"
                    onChange={formik.handleChange}
                    value={formik.values.surname}
                    error={
                      formik.touched.surname && Boolean(formik.errors.surname)
                    }
                    helperText={formik.touched.surname && formik.errors.surname}
                  />
                </Test>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>
              Qual segmento mais se relaciona com a sua área de atuação?*
            </S.FieldTitle>
            <Tooltip
              title={selectedSegments}
              open={
                tooltipOpen && formik.values.professionalSegments.length > 0
              }
              placement="top">
              {/* @ts-ignore */}
              <Test id="ProfessionalProfile-select-segments">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={
                    formik.touched.professionalSegments &&
                    Boolean(formik.errors.professionalSegments)
                  }
                  onMouseEnter={() => {
                    handleTooltip(true);
                  }}
                  onMouseLeave={() => {
                    handleTooltip(false);
                  }}
                  onClick={() => {
                    handleTooltip(false);
                  }}
                  onMouseDown={() => {
                    handleTooltip(false);
                  }}>
                  <Select
                    name="professionalSegments"
                    value={formik.values.professionalSegments}
                    onChange={formik.handleChange}
                    multiple
                    displayEmpty
                    renderValue={(selected: any) => {
                      if (!selected?.length) {
                        return (
                          <Typography sx={{ color: '#BDBDBD' }}>
                            Selecione o(s) segmento(s)
                          </Typography>
                        );
                      }
                      const selectedSegments: Segment[] = segments.filter(
                        (segment: any) =>
                          selected.some((s: any) => s === segment.id),
                      );
                      setSelectedSegments(
                        selectedSegments.map(({ name }) => name).join(', '),
                      );
                      return (
                        <Typography noWrap sx={{ maxWidth: 600 }}>
                          {selectedSegments.map(({ name }) => name).join(', ')}
                        </Typography>
                      );
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                        },
                      },
                      MenuListProps: {
                        sx: {
                          maxHeight: 240,
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: 10,
                            width: 8,
                            WebkitAppearance: 'none',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: 4,
                            backgroundColor: theme.palette.grey['400'],
                          },
                        },
                      },
                    }}>
                    {segments?.map((opt: Segment) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.professionalSegments.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.errors.professionalSegments) &&
                    formik.touched.professionalSegments && (
                      <FormHelperText>
                        {formik.errors.professionalSegments}
                      </FormHelperText>
                    )}
                </FormControl>
              </Test>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={
              typeof selectedInstitution !== 'string' &&
              selectedInstitution?.isOther
                ? 6
                : 12
            }>
            <S.FieldTitle>Empresa relacionada*</S.FieldTitle>
            {/* @ts-ignore */}
            <Test id="ProfessionalProfile-form-relatedInstitutions">
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  disabled={isInvited && !!institutionName.length}
                  fullWidth
                  freeSolo
                  autoHighlight
                  filterOptions={filterOptions}
                  loading={institutionsLoading}
                  options={institutions || []}
                  isOptionEqualToValue={(option, value) =>
                    option.fantasyName === value.fantasyName
                  }
                  onChange={(event, newValue, reason) => {
                    if (newValue?.isOther) {
                      setSelectedInstitution(newValue);
                      formik.setFieldValue('relatedInstitutions', {
                        id: undefined,
                        fantasyName: 'Outra',
                      });
                    } else if (reason === 'clear') {
                      setSelectedInstitution({ fantasyName: '' });
                      formik.setFieldValue('relatedInstitutions', {
                        id: undefined,
                        fantasyName: '',
                      });
                    } else {
                      setSelectedInstitution(newValue);
                      formik.setFieldValue('relatedInstitutions', newValue);
                    }
                  }}
                  value={selectedInstitution}
                  inputValue={institutionName}
                  onInputChange={(event, newInputValue) => {
                    setInstitutionName(newInputValue);
                    institutionsRefetchDebounce();
                  }}
                  getOptionLabel={(option: InstitutionOptions | string) =>
                    typeof option === 'string' ? option : option.fantasyName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="relatedInstitutions"
                      placeholder="Selecione ou digite sua empresa"
                      error={
                        formik.touched.relatedInstitutions?.fantasyName &&
                        Boolean(formik.errors.relatedInstitutions?.fantasyName)
                      }
                      helperText={
                        formik.touched.relatedInstitutions?.fantasyName &&
                        formik.errors.relatedInstitutions?.fantasyName
                      }
                    />
                  )}
                />
              </FormControl>
            </Test>
          </Grid>
          {typeof selectedInstitution !== 'string' &&
            selectedInstitution?.isOther && (
              <Grid item xs={6}>
                <S.FieldTitle>Nome da empresa*</S.FieldTitle>
                <FormControl fullWidth variant="outlined">
                  {/* @ts-ignore */}
                  <Test id="ProfessionalProfile-form-otherInstitution">
                    <TextField
                      name="otherInstitution"
                      placeholder="Digite o nome da empresa"
                      onChange={formik.handleChange}
                      value={formik.values.otherInstitution}
                      error={
                        formik.touched.otherInstitution &&
                        Boolean(formik.errors.otherInstitution)
                      }
                      helperText={
                        formik.touched.otherInstitution &&
                        formik.errors.otherInstitution
                      }
                    />
                  </Test>
                </FormControl>
              </Grid>
            )}
          <Grid item xs={12} md={6}>
            <S.FieldTitle>Mercados*</S.FieldTitle>
            {/* @ts-ignore */}
            <Test id="ProfessionalProfile-select-markets">
              <FormControl
                fullWidth
                variant="outlined"
                error={
                  formik.touched.markets && Boolean(formik.errors.markets)
                }>
                <Select
                  name="markets"
                  value={formik.values.markets}
                  onChange={formik.handleChange}
                  multiple
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (!selected?.length) {
                      return (
                        <Typography sx={{ color: '#BDBDBD' }}>
                          Selecione o(s) mercado(s)
                        </Typography>
                      );
                    }
                    const selectedMarkets: Market[] = markets.filter(
                      (market: any) =>
                        selected.some((s: any) => s === market.id),
                    );
                    return (
                      <Typography noWrap sx={{ maxWidth: 600 }}>
                        {selectedMarkets.map(({ name }) => name).join(', ')}
                      </Typography>
                    );
                  }}>
                  {markets
                    ?.filter((m: Market) =>
                      PROFESSIONAL_PERMITED_MARKETS.includes(m.name),
                    )
                    .map((opt: Market) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.markets.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                </Select>
                {Boolean(formik.errors.markets) && formik.touched.markets && (
                  <FormHelperText>{formik.errors.markets}</FormHelperText>
                )}
              </FormControl>
            </Test>
          </Grid>
          <Grid item xs={12} md={6}>
            <RoleSelectField
              id="ProfessionalProfile-select-role"
              options={responsibilities}
              loading={responsibilityLoading}
              label="Cargo*"
              name="role"
              placeholder="Selecione ou digite o cargo"
              value={formik.values.role}
              setFieldValue={formik.setFieldValue}
              error={formik.errors.role}
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: '#4F4F4F',
            fontSize: '12px',
            marginTop: '30px',
            textAlign: 'center',
          }}>
          Caso queira completar seu perfil faça login, clique no avatar e
          selecione a opção “perfil social”.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '18px 0' }}>
        {/* @ts-ignore */}
        <Test id="ProfessionalProfile-button-submit">
          <Button variant="contained" size="large" type="submit">
            {createProfessionalMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Test>
      </DialogActions>
    </form>
  );
}

export default ProfessionalForm;
