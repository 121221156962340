import { useEffect, useState } from 'react';

import { Close, Edit } from '@mui/icons-material';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import Section from 'src/components/ProfileSection';
import { Professional } from 'src/types/professional';

import ContactsForm from './ContactsForm';

type Props = {
  professional: Professional | undefined;
  handleUpdate: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
};

function Contact({ professional, handleUpdate, isSuccess, isLoading }: Props) {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<{
    id: number;
    key: string;
  }>();
  const [editType, setEditType] = useState<'emails' | 'phones' | undefined>();

  const handleDelete = () => {
    if (professional?.id && itemToRemove) {
      let updatedItems = professional[itemToRemove.key as keyof Professional];
      if (Array.isArray(updatedItems)) {
        updatedItems = [...updatedItems].filter(
          (item: any) => item.id !== itemToRemove.id,
        );
      }

      const values = _.omit(professional, 'id');
      handleUpdate({
        data: {
          ...values,
          [itemToRemove.key]: updatedItems,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setConfirmDialogOpen(false);
      setItemToRemove(undefined);
      setEditDialogOpen(false);
      setEditType(undefined);
    }
  }, [isSuccess]);

  return (
    <Section
      title="Contatos"
      hasPaywall={true}
      paywallBanner="professionalProfile"
      canEdit={professional?.isOwner}
      isEditing={isEditing}
      setIsEditing={setIsEditing}>
      <Grid container columnSpacing={6} rowSpacing={2}>
        <Grid item sm={6}>
          {!!professional?.emails?.length &&
            professional?.emails.map((email) => (
              <>
                <Box
                  key={email.id}
                  display="flex"
                  sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                  <IconButton
                    sx={{
                      marginRight: 2,
                      width: 20,
                      height: 20,
                      backgroundColor: 'black',
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}>
                    <MailRoundedIcon
                      sx={{ fontSize: '0.75rem', color: 'white' }}
                    />
                  </IconButton>
                  <Typography>{email.email}</Typography>
                  {isEditing && (
                    <Box sx={{ marginLeft: '14px' }}>
                      <IconButton
                        sx={{
                          marginRight: 0.5,
                          width: 20,
                          height: 20,
                        }}
                        onClick={() => {
                          setEditType('emails');
                          setEditDialogOpen(true);
                        }}>
                        <Edit
                          sx={{
                            fontSize: '1rem',
                            color: 'rgba(79, 79, 79, 1)',
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          marginRight: 2,
                          width: 20,
                          height: 20,
                        }}
                        onClick={() => {
                          if (email.id) {
                            setItemToRemove({
                              id: email.id,
                              key: 'emails',
                            });
                          }
                          setConfirmDialogOpen(true);
                        }}>
                        <Close
                          sx={{
                            fontSize: '1rem',
                            color: 'rgba(79, 79, 79, 1)',
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </>
            ))}
        </Grid>
        <Grid item sm={6}>
          {!!professional?.phones?.length &&
            professional?.phones.map((phone) => (
              <Box
                key={phone.id}
                display="flex"
                sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                <IconButton
                  sx={{
                    marginRight: 2,
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}>
                  <LocalPhoneRoundedIcon
                    sx={{ fontSize: '0.75rem', color: 'white' }}
                  />
                </IconButton>
                <Typography>{phone.number}</Typography>
                {isEditing && (
                  <Box sx={{ marginLeft: '14px' }}>
                    <IconButton
                      sx={{
                        marginRight: 0.5,
                        width: 20,
                        height: 20,
                      }}
                      onClick={() => {
                        setEditType('phones');
                        setEditDialogOpen(true);
                      }}>
                      <Edit
                        sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        marginRight: 2,
                        width: 20,
                        height: 20,
                      }}
                      onClick={() => {
                        if (phone.id) {
                          setItemToRemove({
                            id: phone.id,
                            key: 'phones',
                          });
                        }
                        setConfirmDialogOpen(true);
                      }}>
                      <Close
                        sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
        </Grid>
      </Grid>
      <ContactsForm
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
        professional={professional}
        handleUpdate={handleUpdate}
        type={editType}
        isLoading={isLoading}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        submitAction={handleDelete}
        title="Tem certeza que deseja remover seu dado de contato?"
        subTitle="Ao remover este dado, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar a informação dentro do perfil profissional."
        hasActionButton={true}
      />
    </Section>
  );
}

export default Contact;
