import { useState } from 'react';

import {
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  navTitle: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    fontSize: '1.25rem',
    fontWeight: 500,
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
  },
  titleMenuItem: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    alignItems: 'start',
    padding: theme.spacing(0, 3),
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
  },
  subMenuItem: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0, 1),
    minHeight: '25px',
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
  },
  subMenuText: {
    color: theme.palette.secondary.contrastText,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.2px',
    cursor: 'pointer',
    '&:hover': {
      color: '#FF8211',
    },
  },
  popOverRoot: {
    pointerEvents: 'none',
  },
  button: {
    backgroundColor: '#FF8211',
    color: theme.palette.secondary.contrastText,
    marginLeft: '10px',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      textDecoration: 'none',
    },
  },
  menuDropdown: {
    backgroundColor: 'rgba(224, 224, 224, 0.15)',
    borderRadius: '4px',
    padding: '2px',
    marginRight: '16px',
    maxHeight: '20px',
    maxWidth: '20px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  headerButton: {
    fontSize: '1.5rem',
    color: 'white',
  },
}));

export default function HeaderDropdownMenu({ menuItems }) {
  let currentlyHovering = false;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHover = () => {
    currentlyHovering = true;
  };

  const handleCloseHover = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  };

  return (
    <>
      <IconButton
        className={classes.menuDropdown}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={handleCloseHover}>
        <KeyboardArrowDown className={classes.headerButton} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        MenuListProps={{
          onMouseEnter: handleHover,
          onMouseLeave: handleCloseHover,
          style: { pointerEvents: 'auto' },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            minWidth: 200,
            marginTop: '1.5rem',
            boxShadow: '1.03158px 1.03158px 4.12632px rgba(0, 0, 0, 0.25)',
          },
        }}
        PopoverClasses={{
          root: classes.popOverRoot,
        }}>
        {menuItems?.map((item) => (
          <MenuItem
            key={item.id}
            className={classes.titleMenuItem}
            onClick={(event) => handleClose(event)}
            component={Link}
            href={item.url}
            target={item.target}>
            {item.title}
            <List>
              {item.children?.map((subItem, index) => (
                <ListItem
                  key={index}
                  className={classes.subMenuItem}
                  component={Link}
                  href={subItem.url}
                  target={subItem.target}>
                  <Typography className={classes.subMenuText}>
                    {subItem?.title}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
