import { ChangeEvent, useState } from 'react';

import {
  Business,
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  PlayCircle,
  Twitter,
  Verified,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import TagManager from 'react-gtm-module';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import defaultCoverImage from 'src/assets/imgs/defaultCoverImage.png';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { If } from 'src/components/If';
import { SocialNetworkButton } from 'src/components/SocialNetworkButton';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { requestOwnershipInstitution } from 'src/services/institution';
import { Institution } from 'src/types/institution';
import { getSmallestImageUrl } from 'src/utils/image';
import { formatUrl } from 'src/utils/string';
import { getUtmUrl } from 'src/utils/url';

import { CoverVideoModal } from './CoverVideoModal';
import { Image } from './styles';
import MetaTags from '../../../components/Seo/MetaTags';

type Props = {
  institution: Institution | undefined;
  refetch: () => void;
  tabList: {
    label: string;
    value: string;
    index: number;
  }[];
  tabValue: string;
  handleChangeTabValue: (event: ChangeEvent<{}>, newValue: number) => void;
};

function Header({
  institution,
  refetch,
  tabList,
  tabValue,
  handleChangeTabValue,
}: Props) {
  const history = useHistory();
  const { auth } = useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const requestOwner = useMutation(
    (value: number) => requestOwnershipInstitution(value),
    {
      onSuccess: () => {
        refetch();
        setOpenDialog(false);
      },
    },
  );

  const handleConfimation = async () => {
    if (institution) {
      requestOwner.mutateAsync(institution.id);
    }
  };

  const handleCTA = () => {
    if (process.env.REACT_APP_GTM) {
      const tagManagerArgs = {
        dataLayer: {
          event: `cta_header_${institution?.callToAction}`,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    let offsetTop = 0;

    if (institution?.callToAction) {
      offsetTop =
        document.getElementById(institution?.callToAction)?.offsetTop ?? 0;
    }

    switch (institution?.callToAction) {
      case 'rankings':
        history.push(
          `/instituicao/${institution.slug}/${institution.id}/ranking`,
        );
        break;
      case 'documents':
        history.push(
          `/instituicao/${institution.slug}/${institution.id}/perfil?to=documentos`,
        );
        break;
      case 'services':
        history.push(
          `/instituicao/${institution.slug}/${institution.id}/perfil?to=servicos`,
        );
        break;
      default:
        break;
    }
  };

  const getCTA = () => {
    switch (institution?.callToAction) {
      case 'rankings':
        return 'Ver rankings';
      case 'documents':
        return 'Ver documentos';
      case 'services':
        return 'Nossos serviços';
      default:
        return '';
    }
  };

  const getLabel = () => {
    if (
      !auth?.user ||
      auth.user.type !== 'legal_person' ||
      auth.user?.subscription?.status !== 'Pago'
    ) {
      return {
        title: 'Reivindique o perfil desta empresa',
        subTitle:
          'Contrate um plano como PJ para reivindicar o perfil desta empresa.',
      };
    }
    if (auth?.user?.isInstitutionOwner) {
      return {
        title: 'Você já é administrador de uma empresa',
        subTitle:
          'Entre em contato pelo e-mail contato@uqbar.com.br caso necessite de alteração.',
      };
    }
    if (institution?.userLoggedHasPendingSolicitation) {
      return {
        title: 'Sua solicitação está sendo analisada.',
        subTitle:
          'Acompanhe esta solicitação pela aba Empresa do seu perfil ou aguarde o recebimento de um e-mail com o resultado desta análise.',
      };
    }
    if (institution?.verified) {
      return {
        title: 'Tem certeza que deseja reivindicar o perfil desta instituição?',
        subTitle:
          'Ao reivindicar, sua solicitação será submetida à análise, pois o perfil desta empresa já é administrado por outro usuario.',
      };
    }
    return {
      title: 'Tem certeza que deseja reivindicar o perfil desta instituição?',
      subTitle:
        'Ao reivindicar, você se tornará o administrador do perfil desta Instituição.',
    };
  };

  return (
    <>
      {!!institution && (
        <MetaTags
          title={institution?.fantasyName}
          description={institution?.institutionTitle}
          imageUrl={institution?.coverImage?.url ?? defaultCoverImage}
          seo={institution?.seo}
        />
      )}
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          position: 'relative',
          borderRadius: '0px 0px 5px 5px',
          borderRight: '1px solid var(--gray-5, #E0E0E0)',
          borderBottom: '1px solid var(--gray-5, #E0E0E0)',
          borderLeft: '1px solid var(--gray-5, #E0E0E0)',
          minHeight: 460,
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center"
          sx={(theme) => ({
            height: 210,
            [theme.breakpoints.down('sm')]: { height: 160 },
          })}>
          {institution?.verified ? (
            <Image src={institution?.coverImage?.url ?? defaultCoverImage} />
          ) : (
            <Image src={defaultCoverImage} />
          )}
          {institution?.verified && institution?.coverVideoURL && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
              }}>
              <PlayCircle
                color="secondary"
                sx={(theme) => ({
                  fontSize: '80px',
                  color: theme.palette.primary.contrastText,
                  opacity: 0.75,
                  '&:hover': {
                    transition: 'all .2s ease-in',
                    cursor: 'pointer',
                    opacity: 1,
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '70px',
                  },
                })}
                onClick={() => setOpenVideoModal(true)}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: 'fit-content',
            left: 33,
            top: 137,
            [theme.breakpoints.down('sm')]: {
              left: 10,
              top: 100,
            },
          })}>
          {institution?.logo ? (
            <Avatar
              variant="square"
              imgProps={{
                sx: {
                  objectFit: 'contain',
                  backgroundColor: 'white',
                },
              }}
              sx={(theme) => ({
                width: '200px',
                height: '200px',
                border: '5px solid #FAFAFA',
                [theme.breakpoints.down('sm')]: {
                  width: '132px',
                  height: '132px',
                },
              })}
              src={getSmallestImageUrl(institution?.logo)}
            />
          ) : (
            <Avatar
              variant="square"
              sx={(theme) => ({
                width: '200px',
                height: '200px',
                border: `5px solid ${theme.palette.background.paper}`,
                [theme.breakpoints.down('sm')]: {
                  width: '132px',
                  height: '132px',
                },
              })}>
              <Business sx={{ fontSize: '94px' }} />
            </Avatar>
          )}
        </Box>
        <Grid
          container
          sx={(theme) => ({
            padding: '24px 56px 0px 270px',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
              padding: '30px 20px 0px 270px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: '70px 20px 0',
            },
          })}>
          <Grid
            item
            xs={12}
            md={9}
            rowSpacing={1}
            container
            alignItems="center"
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                paddingTop: 2,
              },
            })}>
            <Grid item>
              <Typography
                sx={(theme) => ({
                  fontSize:
                    institution?.fantasyName &&
                    institution.fantasyName.length > 25
                      ? '28px'
                      : '32px',
                  fontWeight: 700,
                  lineHeight: '35.2px',
                  color: '#1D1D1D',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '28px',
                  },
                })}>
                {institution?.fantasyName}
                {institution?.verified && (
                  <Verified color="primary" sx={{ margin: '0 8px' }} />
                )}
                <Box
                  sx={{
                    display: 'inline-block',
                    fontSize: '43px',
                    marginLeft: institution?.verified ? 0 : 1,
                  }}>
                  <FavoriteButton type="institution" />
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                }}>
                {institution?.institutionTitle}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                marginLeft: '2px',
              }}
            >
              <b>{institution?.followers}</b> seguidores
            </Typography>
          </Grid> */}
            <Grid
              container
              item
              xs={12}
              sx={(theme) => ({
                flexDirection: 'row',
                flexWrap: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                },
              })}>
              <Grid
                container
                item
                sm={6}
                md={10}
                sx={{ height: 'fit-content' }}>
                {(institution?.twitter?.url ||
                  institution?.linkedin?.url ||
                  institution?.facebook?.url ||
                  institution?.instagram?.url) && (
                  <>
                    <Grid
                      container
                      item
                      sx={(theme) => ({
                        height: 'fit-content',
                        '-webkit-filter': !institution.verified
                          ? 'blur(5px)'
                          : 'none',
                        [theme.breakpoints.only('sm')]: {
                          alignItems: 'flex-start',
                        },
                      })}>
                      {institution?.twitter?.url && (
                        <SocialNetworkButton
                          disabled={!institution.verified}
                          url={getUtmUrl(
                            formatUrl(institution.twitter.url),
                            'perfil_institucional',
                            'plataforma_uqbar',
                          )}
                          icon={<Twitter fontSize="small" />}
                        />
                      )}
                      {institution?.linkedin?.url && (
                        <SocialNetworkButton
                          disabled={!institution.verified}
                          url={getUtmUrl(
                            formatUrl(institution.linkedin.url),
                            'perfil_institucional',
                            'plataforma_uqbar',
                          )}
                          icon={<LinkedIn fontSize="small" />}
                        />
                      )}
                      {institution?.facebook?.url && (
                        <SocialNetworkButton
                          disabled={!institution.verified}
                          url={getUtmUrl(
                            formatUrl(institution.facebook.url),
                            'perfil_institucional',
                            'plataforma_uqbar',
                          )}
                          icon={<Facebook fontSize="small" />}
                        />
                      )}
                      {institution?.instagram?.url && (
                        <SocialNetworkButton
                          disabled={!institution.verified}
                          url={getUtmUrl(
                            formatUrl(institution.instagram.url),
                            'perfil_institucional',
                            'plataforma_uqbar',
                          )}
                          icon={<Instagram fontSize="small" />}
                        />
                      )}
                      {institution?.websiteUrl && (
                        <SocialNetworkButton
                          disabled={!institution.verified}
                          url={getUtmUrl(
                            formatUrl(institution?.websiteUrl),
                            'perfil_institucional',
                            'plataforma_uqbar',
                          )}
                          icon={<Language fontSize="small" />}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            container
            sx={(theme) => ({
              justifyContent: 'flex-end',
              [theme.breakpoints.down('md')]: {
                position: 'absolute',
                top: '30px',
                left: '-19px',
              },
            })}>
            <If condition={!institution?.verified}>
              <Button
                variant="contained"
                onClick={() => setOpenDialog(true)}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '120px',
                  },
                })}>
                Reivindicar perfil
              </Button>
            </If>
            <If
              condition={
                institution?.verified &&
                !!institution?.callToAction &&
                (institution?.callToAction === 'services'
                  ? institution?.services?.length !== 0
                  : true) &&
                (institution?.callToAction === 'documents'
                  ? institution?.documents?.length !== 0
                  : true)
              }>
              <Button
                variant="outlined"
                sx={(theme) => ({
                  fontSize: '14px',
                  fontWeight: 500,
                  [theme.breakpoints.down('sm')]: {
                    minWidth: '120px',
                  },
                })}
                onClick={handleCTA}>
                {getCTA()}
              </Button>
            </If>
          </Grid>
        </Grid>
        <Tabs
          variant="scrollable"
          value={tabValue}
          scrollButtons={false}
          onChange={handleChangeTabValue}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={(theme) => ({
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '0 56px 0 20px',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
            },
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 100,
              width: '100%',
              backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              '& .MuiTabs-indicatorSpan': {
                maxWidth: 80,
              },
            },
          })}>
          {tabList.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              value={tab.value}
              sx={(theme) => ({
                minWidth: 120,
                maxWidth: 150,
                textTransform: 'none',
                color: '#282828',
                fontSize: '1rem',
                fontWeight: 400,
                '&.Mui-selected': {
                  color: '#282828',
                },
                [theme.breakpoints.down('sm')]: {
                  '&:first-child': {
                    minWidth: 80,
                  },
                },
              })}
            />
          ))}
        </Tabs>
        <ConfirmDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          title={getLabel().title}
          subTitle={getLabel().subTitle}
          submitAction={handleConfimation}
          hasActionButton={
            auth?.user &&
            !institution?.userLoggedHasPendingSolicitation &&
            !auth?.user?.isInstitutionOwner &&
            auth?.user?.type === 'legal_person' &&
            auth.user?.subscription?.status === 'Pago'
          }
        />
        {institution?.coverVideoURL && (
          <CoverVideoModal
            open={openVideoModal}
            onClose={() => setOpenVideoModal(false)}
            url={institution?.coverVideoURL}
          />
        )}
      </Box>
    </>
  );
}

export default Header;
