import { useCallback, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Close, InsertDriveFile, UploadFileRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { If } from 'src/components/If';
import { createVideo, updateVideo } from 'src/services/gallery';
import { setBannerMessage } from 'src/store/display';
import { Video } from 'src/types/institution';
import { formatBytes } from 'src/utils/number';
import { videoRegex } from 'src/utils/regex';
import * as Yup from 'yup';

import * as S from '../../style';

const MAX_FILE_SIZE = 2000000;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type Props = {
  open: boolean;
  onClose: () => void;
  institutionId: number | undefined;
  selectedItem?: Video | undefined;
  institutionRefetch: () => void;
};

function VideoModal({
  open,
  onClose,
  institutionId,
  selectedItem,
  institutionRefetch,
}: Props) {
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('thumbnail', acceptedFiles[0]);
    }
    if (rejectedFiles.length) {
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        formik.setErrors({
          thumbnail: 'A imagem excede o tamanho máximo. Max: 2MB',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        formik.setErrors({
          thumbnail: 'Formato não suportado',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'too-many-files') {
        formik.setErrors({
          thumbnail: 'Selecione somente um arquivo',
        });
      }
    }
  }, []);

  const FORM_VALIDATION: any = Yup.object().shape({
    title: Yup.string().required('Campo obrigatório'),
    urlVideo: Yup.string()
      .required('Campo obrigatório')
      .matches(videoRegex, 'URL inválida, verifique se o link está correto'),
    description: Yup.string().required('Campo obrigatório'),
    thumbnail: Yup.mixed()
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (selectedItem?.thumbnail || !value) {
            return true;
          }
          return value?.type && value?.size <= MAX_FILE_SIZE;
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (selectedItem?.thumbnail || !value) {
          return true;
        }
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .required('Imagem obrigatória'),
  });

  const submitForm = (formData: FormData, rest: any) => {
    formData.append(
      'data',
      JSON.stringify({
        ...rest,
        institution: institutionId,
      }),
    );

    if (selectedItem) {
      updateVideoMutation.mutate({
        id: selectedItem?.id,
        formData,
      });
    } else {
      createVideoMutation.mutate(formData);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: selectedItem?.title ?? '',
      urlVideo: selectedItem?.urlVideo ?? '',
      description: selectedItem?.description ?? '',
      thumbnail: selectedItem?.thumbnail ?? null,
    } as {
      title: string;
      urlVideo: string;
      description: string;
      thumbnail:
        | File
        | { url: string; name: string; mime: string; size: number }
        | null;
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      const { thumbnail, ...rest } = values;

      if (thumbnail) {
        if (thumbnail instanceof File) {
          formData.append('files.thumbnail', thumbnail);
        } else if (typeof thumbnail === 'object' && thumbnail.url) {
          fetch(thumbnail.url)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], thumbnail.name, {
                type: thumbnail.mime,
              });
              formData.append('files.thumbnail', file);
              submitForm(formData, rest);
            });
          return;
        }
      }

      submitForm(formData, rest);
    },
  });

  const createVideoMutation = useMutation(createVideo, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Erro ao adicionar vídeo, tente novamente',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Vídeo adicionado com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const updateVideoMutation = useMutation(updateVideo, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Erro ao editar o vídeo, tente novamente',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Vídeo editado com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
    },
    onDrop,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    noDragEventsBubbling: true,
  });

  const handleRemove = () => {
    formik.setFieldValue('thumbnail', null);
  };

  const handleLeaveDialog = () => {
    setOpenLeaveDialog(false);
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{
        maxWidth: 750,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <form
        onSubmit={formik.handleSubmit}
        onChange={(e) => e.stopPropagation()}>
        <DialogContent
          sx={(theme) => ({
            padding: '40px 56px 0px',
            [theme.breakpoints.down('sm')]: {
              padding: '20px 24px',
            },
          })}>
          <Typography fontSize={18} fontWeight={700} mb={3} textAlign="center">
            {`${selectedItem ? 'Editar' : 'Adicionar'} vídeo`}
          </Typography>
          <Typography fontSize={14} mb={3} textAlign="center">
            {`${
              selectedItem
                ? ''
                : 'Insira a URL do vídeo e faça o upload de uma imagem para a capa.'
            }`}
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                paddingBottom: '16px',
              }}>
              <S.FieldTitle>Título do vídeo*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="title"
                  placeholder="Insira um título para o vídeo"
                  variant="outlined"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </FormControl>
            </Grid>
            <TextAreaProfile
              name="description"
              label="Legenda*"
              placeholder="Escreva uma legenda para o vídeo"
              variant="outlined"
              maxCaracters={300}
              value={formik.values.description}
              handleChange={formik.handleChange}
              errors={formik.errors.description}
              touched={formik.touched.description}
            />
            <Grid
              item
              xs={12}
              sx={{
                paddingBottom: '16px',
              }}>
              <S.FieldTitle>URL do vídeo*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="urlVideo"
                  placeholder="Insira um link do Youtube ou Vimeo"
                  variant="outlined"
                  value={formik.values.urlVideo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.urlVideo && Boolean(formik.errors.urlVideo)
                  }
                  helperText={formik.touched.urlVideo && formik.errors.urlVideo}
                />
              </FormControl>
            </Grid>
            <S.FieldTitle>
              Envie uma imagem de capa (thumbnail) para o vídeo*
            </S.FieldTitle>
            {!formik.values.thumbnail ? (
              <Box
                {...getRootProps()}
                sx={{
                  width: '100%',
                  height: 150,
                  backgroundColor: '#FAFAFA',
                  border: '1px dashed #E0E0E0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}>
                <input {...getInputProps()} />
                <UploadFileRounded
                  sx={{ fontSize: '48px', color: '#BDBDBD' }}
                />
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '0.875rem',
                  })}>
                  <span style={{ textDecoration: 'underline' }}>
                    Clique aqui
                  </span>{' '}
                  ou arraste um arquivo
                </Typography>
                {Boolean(formik.errors.thumbnail) && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {formik.errors.thumbnail}
                  </FormHelperText>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  height: 62,
                  width: '100%',
                  padding: '0 16px',
                  backgroundColor: '#FAFAFA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Box sx={{ display: 'flex', textAlign: 'start' }}>
                  <InsertDriveFile
                    sx={{ fontSize: '40px', color: '#BDBDBD' }}
                  />
                  <Box ml={1}>
                    <If condition={formik.values.thumbnail}>
                      <Typography fontSize={14} fontWeight={600}>
                        {formik.values.thumbnail?.name}
                      </Typography>
                      <Typography sx={{ color: '#828282', fontSize: '14px' }}>
                        {formatBytes(formik.values.thumbnail?.size)}
                      </Typography>
                    </If>
                  </Box>
                </Box>
                <IconButton onClick={handleRemove}>
                  <Close />
                </IconButton>
              </Box>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            justifyContent: 'center',
            padding: '32px 0 40px 0',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
              gap: '16px',
              padding: '0 24px 40px 24px',
            },
            '& > button': {
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
                marginLeft: '0 !important',
              },
            },
          })}>
          <Button variant="contained" type="submit">
            {createVideoMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
          <Button
            variant="outlined"
            type="reset"
            color="secondary"
            sx={{ marginLeft: '32px !important' }}
            onClick={() => setOpenLeaveDialog(true)}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
      <ConfirmLeaveDialog
        title={
          selectedItem
            ? 'Tem certeza que deseja fechar a edição de vídeo?'
            : 'Tem certeza que deseja fechar a adição de vídeo?'
        }
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleLeaveDialog}
        labelButton={selectedItem ? 'Continuar edição' : 'Continuar'}
      />
    </Dialog>
  );
}

export default VideoModal;
