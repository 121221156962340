import qs from 'qs';
import { User } from 'src/types/user';

import { api, publicApi } from '../../api/api';

export const me = async () => {
  return api.get('/users/me');
};

export const refreshToken = async () => {
  return api.get('/auth/refresh-token');
};

export const updateUser = async ({ data }: { data: User }) => {
  const { id, ...rest } = data;
  return api.put(`/users/${id}`, rest);
};

export const changeEmail = async (data: {
  password: string;
  currentEmail?: string;
  newEmail: string;
}) => {
  return api.post('/users/changeEmail', data);
};

export const changePassword = async (data: any) => {
  return api.post('/users/changePassword', data);
};

export const refreshCodeChangeEmail = async (data: any) => {
  return api.post('/users/refreshCodeChangeEmail', data);
};

export const sendConfimationCodeChangeEmail = async (data: any) => {
  return api.post('/users/confirmationChangeEmail', data);
};

export const count = async () => {
  const { data } = await publicApi.get('/users/count');
  return data;
};

// TODO: mudar para pegar direto de invitation
export const findInvitations = async (id?: number) => {
  const query = qs.stringify(
    {
      fields: ['id'],
      populate: ['invitations', 'invitations.user'],
      sort: ['invitations.createdAt:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/users/${id}?${query}`);
  return data;
};
