import { useState } from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import CollapseTableRanking from '../../../../components/CollapseTableRanking';
import { If } from '../../../../components/If';
import { convertValue } from '../../../../utils/number';
import { getComparator, stableSort } from '../../../../utils/table';

export const TableInstitutionRanking = ({
  classes,
  state,
  data,
  loading,
  valueLabel,
  month,
}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('rank.baseYear');

  const numberCriterias = [
    'operations',
    'negotiatedefundnumber',
    'tradesnumber',
    'titlesnumber',
    'operationsissued',
    'trades',
    'negotiatedefundnumber',
    'titlesnumberclassified',
    'fundnumber',
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (loading) return <CircularProgress color="secondary" />;

  const renderCell = (column, label) => (
    <TableSortLabel
      active={true}
      IconComponent={() => <ImportExportIcon color="primary" />}
      direction={orderBy === column ? order : 'asc'}
      onClick={(e) => handleRequestSort(e, column)}>
      {label}
    </TableSortLabel>
  );

  const StyledTableCell = withStyles(() => ({
    head: {
      fontSize: '12px',
      fontWeight: 700,
    },
  }))(TableCell);

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow className={classes.tableRoot}>
          <TableCell width="200" />
          <TableCell>{row.rank.baseYear}</TableCell>
          <TableCell style={{ padding: '2rem 0' }}>
            <Box display="flex">
              <Typography className={classes.position}>
                {row.position}
              </Typography>
              {!row.lastYearPosition ? (
                <TrendingFlatIcon htmlColor="#c3c3c3" />
              ) : row.position > row.lastYearPosition ? (
                <TrendingDownIcon htmlColor="#f44336" />
              ) : row.position === row.lastYearPosition ? (
                <TrendingFlatIcon htmlColor="#c3c3c3" />
              ) : (
                <TrendingUpIcon htmlColor="#4caf50" />
              )}
            </Box>
          </TableCell>
          <TableCell>{`${convertValue(
            row.percentValue,
            null,
            false,
            'decimal',
          )}%`}</TableCell>
          <TableCell>
            {numberCriterias.includes(state.criteria)
              ? row.value
              : valueLabel.includes('Montante')
                ? `${convertValue(row.value, null, true, 'currency')}`
                : `${convertValue(row.value, null, false, 'decimal')}`}
          </TableCell>
          <TableCell align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              placeholder="Detalhe"
              color="secondary">
              {open ? (
                <>
                  <Typography>Detalhe</Typography>
                  <ArrowDropUpIcon />
                </>
              ) : (
                <>
                  <Typography>Detalhe</Typography>
                  <ArrowDropDownIcon />
                </>
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <CollapseTableRanking
              open={open}
              itemId={row.uqbarId}
              state={state}
              month={month}
              year={row.rank.baseYear}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Grid container>
      <If condition={data?.length}>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>
                    {renderCell('rank.baseYear', 'Ano')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {renderCell('position', 'Posição')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {renderCell('percentValue', 'Percentual')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {renderCell('value', valueLabel)}
                  </StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map((row) => (
                  <Row key={row.rank.baseYear} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </If>
      <If condition={!data?.length}>
        <Box p={2}>
          <Typography>
            Ops… Não encontramos informações mencionadas no ranking para esta
            instituição.
          </Typography>
        </Box>
      </If>
    </Grid>
  );
};
