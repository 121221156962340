import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';

type EventContextType = {
  acceptTermsAndConditions: boolean;
  setAcceptTermsAndConditions: Dispatch<SetStateAction<boolean>>;
  refElement: HTMLElement | null;
  scrollToRefElement: () => void;
  createRefElement: <T>() => React.Ref<T> | null;
};

const initialContext: EventContextType = {
  acceptTermsAndConditions: false,
  setAcceptTermsAndConditions: () => {},
  refElement: null,
  scrollToRefElement: () => {},
  createRefElement: () => null,
};

const EventContext = createContext<EventContextType>(initialContext);

export function EventProvider({ children }: PropsWithChildren) {
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const refElement: React.Ref<HTMLElement | null> = useRef<HTMLElement | null>(
    null,
  );

  function createRefElement<T>() {
    return refElement as React.Ref<T> | null;
  }

  const scrollToRefElement = () => {
    if (refElement?.current) {
      const elementRect = refElement.current.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.scrollY;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({ top: middle, behavior: 'smooth' });
    }
  };

  return (
    <EventContext.Provider
      value={{
        acceptTermsAndConditions,
        setAcceptTermsAndConditions,
        refElement: refElement.current,
        scrollToRefElement,
        createRefElement,
      }}>
      {children}
    </EventContext.Provider>
  );
}

export function useEventContext() {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEventContext must be used within an EventProvider');
  }
  return context;
}
