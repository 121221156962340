import { useEffect, useMemo, useState } from 'react';

import * as Rankings from './styles';
import DataSelector from '../../components/DataSelector';
import RankingsWidget from '../../components/RankingsWidget';
import ScrollWrapper from '../../components/ScrollWrapper';
import { withLinks } from '../../link-mapper';
import { descriptions, marketRankings } from '../../presentation/data';
import { YearbookMarket, YearbookMarketRanking } from '../../types';
import { extractRankingParams } from '../../utils';

interface RankingsSectionProps {
  market: YearbookMarket;
}

export default function RankingsSection({ market }: RankingsSectionProps) {
  const [selectedRanking, setSelectedRanking] =
    useState<YearbookMarketRanking | null>(null);
  const rankings = useMemo(() => marketRankings[market.name], [market]);

  function getRankingByName(name: string) {
    return rankings.find((ranking) => ranking.name === name);
  }

  const description = useMemo(
    () => descriptions[market.name].rankings,
    [market.name],
  );

  function select(name: string) {
    const ranking = getRankingByName(name) ?? null;
    setSelectedRanking(ranking);
  }

  function openRanking() {
    if (!selectedRanking || !selectedRanking.endpointUrl) return;
    const params = extractRankingParams(selectedRanking.endpointUrl);
    if (!params) return;

    window.open(
      `/rankings/dados?market=${params.market}&year=${params.year}&ranking=${params.ranking}&criteria=${params.criteria}&timeRange=${params.timeRange}`,
      '_blank',
    );
  }

  useEffect(() => {
    setSelectedRanking(rankings[0]);
  }, [rankings]);

  return (
    <Rankings.Section data-yearbook-section="Rankings">
      <Rankings.Title>Rankings</Rankings.Title>
      <Rankings.Description>
        {description.map((text, idx) => (
          <Rankings.DescriptionParagraph key={`${market}-${idx}`}>
            {text}
          </Rankings.DescriptionParagraph>
        ))}
      </Rankings.Description>
      <ScrollWrapper
        items={rankings}
        select={select}
        selected={selectedRanking}>
        {selectedRanking && (
          <Rankings.Content>
            <Rankings.WidgetContent>
              <Rankings.WidgetTitle>
                {selectedRanking.details.title}
              </Rankings.WidgetTitle>
              {selectedRanking.details.texts.map((text, idx) => (
                <Rankings.WidgetContentParagraph
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: withLinks(text) }}
                />
              ))}
            </Rankings.WidgetContent>
            <Rankings.WidgetWrapper>
              <DataSelector
                items={rankings}
                select={select}
                selected={selectedRanking}
                id="rankings-selector"
              />
              <RankingsWidget
                urlSource={selectedRanking.endpointUrl}
                data={selectedRanking.queryData}
                criteria={selectedRanking.criteria}
              />
              {selectedRanking.endpointUrl && (
                <Rankings.Button onClick={openRanking}>
                  Acessar plataforma Uqbar
                </Rankings.Button>
              )}
            </Rankings.WidgetWrapper>
          </Rankings.Content>
        )}
      </ScrollWrapper>
    </Rankings.Section>
  );
}
