import { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useQuery } from 'react-query';

import logo from '../../assets/imgs/logo.svg';
import { getFooterList } from '../../services/misc';
import { useLocalStorage } from '../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(4),
    marginTop: 'auto',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
  container: {
    maxWidth: '1150px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 700,
    marginTop: '0.72rem',
    fontSize: '1.25rem',
  },
  logo: {
    maxWidth: 150,
    height: 75,
  },
  text: {
    paddingRight: '120px',
    margin: theme.spacing(1.6, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
      paddingRight: 0,
    },
  },
  textNewsletter: {
    margin: theme.spacing(2, 0, 1.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  social: {
    paddingLeft: 0,
  },
  version: {
    color: theme.palette.disabled.main,
  },
  items: {
    padding: theme.spacing(0.5, 0),
  },
  gridNewsLetter: {
    margin: '0.9rem 0 0 2rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  gridItems: {
    marginTop: '1.7rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

function Copyright() {
  return (
    <Typography variant="body2">
      {`copyright © ${new Date().getFullYear()}. Todos os direitos reservados`}
    </Typography>
  );
}

function Version() {
  return (
    <Typography variant="body2">
      {`Versão: ${process.env.REACT_APP_VERSION}`}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();
  const [appEnv, setAppEnv] = useLocalStorage(
    'isEnvDevelopmentZRP',
    process.env.REACT_APP_ENV,
  );
  const { data: footerList } = useQuery('footer', getFooterList);

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: '40123311',
        region: 'na1',
        formId: 'be3d44ce-a7ab-40db-9308-c25cbdc8f4dc',
        target: '#form-newsletter-plataforma-2-54a993fac8c214b1efb0',
      });
    }
  }, [window.hbspt]);

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <img src={logo} alt="UQBAR" className={classes.logo} />
            <Typography className={classes.text}>
              Acreditamos no Mercado de Capitais e em sua força multiplicadora.
              Acreditamos na mudança de paradigma proporcionado pelas Finanças
              Estruturadas, que ao aproximar tomadores e doadores de recursos
              estabelece as bases de criação de valor econômico de longo prazo.
              Acreditamos no crescimento sustentável desse mercado, baseado em
              transparência, padronização e simetria.
            </Typography>
            <IconButton
              className={classes.social}
              target="_blank"
              rel="noopener"
              href="https://www.linkedin.com/company/uqbar/"
              color="secondary"
              edge="end"
              aria-label="linkedin">
              <LinkedInIcon fontSize="large" titleAccess="Linkedin" />
            </IconButton>
            <IconButton
              target="_blank"
              rel="noopener"
              href="https://www.youtube.com/user/uqbaronline"
              color="secondary"
              edge="end"
              aria-label="youtube">
              <YouTubeIcon fontSize="large" titleAccess="Youtube" />
            </IconButton>
            <IconButton
              target="_blank"
              rel="noopener"
              href="https://www.facebook.com/uqbarconhecimentofinanceiro"
              color="secondary"
              edge="end"
              aria-label="facebook">
              <FacebookIcon fontSize="large" titleAccess="Facebook" />
            </IconButton>
            <IconButton
              target="_blank"
              rel="noopener"
              href="https://www.instagram.com/uqbar_conhecimentofinanceiro"
              color="secondary"
              edge="end"
              aria-label="instagram">
              <InstagramIcon fontSize="large" titleAccess="Instagram" />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridItems}>
            <Typography
              gutterBottom
              variant="subtitle2"
              className={classes.title}
              component="h3">
              Páginas
            </Typography>
            <Grid container style={{ marginTop: '0.85rem' }}>
              {footerList?.items?.map(({ id, title, url, target }) => (
                <Grid key={id} item xs={12}>
                  <Typography gutterBottom className={classes.items}>
                    <Link
                      href={url}
                      target={target}
                      rel="noopener"
                      variant="body1"
                      color="inherit">
                      {title}
                    </Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridNewsLetter}>
            <Typography
              gutterBottom
              variant="subtitle2"
              className={classes.title}
              component="h3">
              Esteja sempre atualizado
            </Typography>
            <Typography
              className={classes.textNewsletter}
              style={{ marginTop: '1.1rem' }}>
              Receba dados consolidados de mercado, acompanhe o que nossos
              especialistas estão escrevendo e fique por dentro de eventos e
              treinamentos
            </Typography>
            <div
              role="main"
              id="form-newsletter-plataforma-2-54a993fac8c214b1efb0"></div>
          </Grid>
          <Grid item xs={12} style={{ padding: '5px' }}>
            <Box textAlign="center">
              <Copyright />
              <Version />
              {'development' === process.env.REACT_APP_ENV && (
                <Button
                  color={'secondary'}
                  onClick={() => {
                    setAppEnv(
                      appEnv === 'development' ? 'production' : 'development',
                    );
                    window.location = window.location.href;
                  }}>
                  {appEnv.toUpperCase()}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
