// Material ui components
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import breakDefault from '../../../assets/imgs/articles/detail.png';
import { If } from '../../../components/If';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: '230px',
  },
  image: {
    width: '100%',
    minHeight: 180,
    height: '100%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    width: '100%',
    marginTop: '1rem',
  },
  boxCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  cardActions: {
    justifyContent: 'space-between',
    padding: '1rem',
  },
  chip: {
    minWidth: 60,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '0.2rem',
    marginTop: '0.2rem',
  },
  boxLink: {
    minWidth: '70px',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export default function SingleArticle({
  id,
  name,
  markets,
  publishedAt,
  slug,
  headerImage,
}) {
  const classes = useStyles();

  return (
    <Card key={id} className={classes.card}>
      <CardActionArea component={RouterLink} to={`/artigo/${slug}/${id}`}>
        <Grid item container xs={12} className={classes.gridContainer}>
          <Grid item xs={12} sm={6}>
            <CardMedia
              image={headerImage?.url ?? breakDefault}
              title={name}
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.boxCard}>
              <CardContent>
                <Typography variant="h5" className={classes.title}>
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Publicado em ${publishedAt}`}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Box>
                  <If condition={markets?.length}>
                    {markets?.map((market, index) => (
                      <Chip
                        key={`${index}-${market}`}
                        label={market}
                        color="secondary"
                        className={classes.chip}
                      />
                    ))}
                  </If>
                </Box>
                <Box className={classes.boxLink}>
                  <Typography>
                    <Link
                      to={`/artigo/${slug}/${id}`}
                      component={RouterLink}
                      color="secondary">
                      Ver mais
                    </Link>
                  </Typography>
                </Box>
              </CardActions>
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
