import { useMemo, useState } from 'react';

import { PlayCircleOutline } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { CoverVideoModal } from 'src/pages/Institution/Header/CoverVideoModal';
import { Institution, Video } from 'src/types/institution';
import { getSmallestImageUrl } from 'src/utils/image';
import { truncateText } from 'src/utils/string';

import UploadVideoModal from '../VideoModal';

const VideosList = ({
  institution,
  handleRemoveDocument,
  institutionRefetch,
}: {
  institution?: Institution;
  handleRemoveDocument: (values: Video) => void;
  institutionRefetch: () => void;
}) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Video | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenDialog = (video: Video) => {
    setSelectedItem(video);
    setOpenDialog(true);
  };

  const handleEdit = (video: Video) => {
    setSelectedItem(video);
    setOpenEdit(true);
  };

  const handleRemove = () => {
    if (selectedItem?.id && institution) {
      handleRemoveDocument(selectedItem);
      setOpenDialog(false);
      setSelectedItem(undefined);
    }
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Nome do arquivo',
        size: 50,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: { cursor: 'pointer' },
          onClick: () => {
            setOpenPreviewDialog(true);
            setSelectedItem(cell.row.original);
          },
        }),
        Cell: ({ cell }: { cell: any }) => (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <img
                src={getSmallestImageUrl(cell.row.original.thumbnail)}
                alt={cell.row.original.thumbnail.alternativeText}
                width={35}
                height={42}
                style={{
                  borderRadius: '2px',
                  filter: 'brightness(0.5)',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  top: 12,
                }}>
                <PlayCircleOutline
                  color="secondary"
                  sx={(theme) => ({
                    fontSize: '18px',
                    color: theme.palette.primary.contrastText,
                  })}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={'secondary'}
                sx={(theme) => ({
                  '&:hover': { color: theme.palette.primary.main },
                })}>
                {truncateText(cell.row.original.title, 50)}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={'#828282'}>
                URL
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'uploadDate',
        header: 'Data de upload',
        size: 40,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        sortingFn: (rowA: any, rowB: any): number => {
          const dateA = new Date(rowA.original.createdAt);
          const dateB = new Date(rowB.original.createdAt);
          return dateB == dateA ? 0 : dateB < dateA ? 1 : -1;
        },
        Cell: ({ cell }: { cell: any }) => (
          <Typography fontSize={14} fontWeight={400} color={'#4F4F4F'}>
            {format(new Date(cell.row.original.createdAt), 'dd/MM/yyyy')}
          </Typography>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(cell.row.original)}>
              Editar vídeo
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
                textWrap: 'nowrap',
              }}
              onClick={() => handleOpenDialog(cell.row.original)}>
              Excluir vídeo
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: institution?.galleryVideos ?? [],
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    initialState: {
      sorting: [
        {
          id: 'uploadDate',
          desc: false,
        },
      ],
    },
    enableStickyHeader: true,
    enableSorting: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        padding: '16px 0 40px',
      },
    },
  });

  return (
    <>
      <MaterialTable table={table} />
      {selectedItem?.urlVideo && (
        <CoverVideoModal
          open={openPreviewDialog}
          onClose={() => setOpenPreviewDialog(false)}
          url={selectedItem?.urlVideo}
          description={selectedItem?.description}
        />
      )}
      <ConfirmDialog
        title={`Tem certeza que deseja remover o “${selectedItem?.title}”?`}
        subTitle="Ao remover o vídeo, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar um novo vídeo no perfil institucional."
        submitAction={handleRemove}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasActionButton
      />
      <UploadVideoModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        institutionId={institution?.id}
        selectedItem={selectedItem}
        institutionRefetch={institutionRefetch}
      />
    </>
  );
};

export default VideosList;
