import qs from 'qs';

import { publicApi } from '../../api/api';

export const getOurClients = async () => {
  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await publicApi.get(`/our-clients?${query}`);
  return data;
};
