import { StrapiResponse } from 'src/types/http';

import { publicApi } from '../../api/api';

type LandingPagedata = {
  title: string;
  url: string;
};

export const getLandingPageData = async () => {
  const { data } =
    await publicApi.get<StrapiResponse<LandingPagedata>>('/landing-page');

  return data;
};
