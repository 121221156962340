// Format numbers
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
// Typechecking

export default function PhoneFormat(props) {
  const { inputRef, ...other } = props;

  const mask = (rawValue) => {
    const phoneNumber = rawValue.replace(/\D/g, '');
    if (phoneNumber.length <= 10) {
      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  };

  return (
    <MaskedInput {...other} ref={inputRef} mask={mask} showMask guide={false} />
  );
}
PhoneFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
