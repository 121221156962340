import React from 'react';

import { v4 as uuid } from 'uuid';

import * as S from './styles';
import ImageDisplayer from '../../../../../Widget/ImageDisplayer';
import { cast } from '../../../utils';
import UqbarTip from '../../Widgets/Tooltip/Uqbar';

const setSpaceInElement = (Element: JSX.Element) => {
  return <> {Element} </>;
};

const replaceLink = (text: string) => {
  const hrefMatch = text.match(/href="(\w.+)"/);
  const nameMatch = text.match(/>(\w.+)</);

  if (hrefMatch && nameMatch) {
    const [, href] = hrefMatch;
    const [, name] = nameMatch;

    return (
      <UqbarTip href={href}>
        <a href={href}>{name}</a>
      </UqbarTip>
    );
  }

  return text;
};

const replaceStrong = (text: string) => {
  const tagMatch = text.match(/>\s?\W?(\w.+)\s?\W?</);

  if (tagMatch) {
    const [, content] = tagMatch;

    return <strong>{content}</strong>;
  }

  return text;
};

const replaceList = (text: string) => {
  const isUl = Boolean(text.match(/<ul>/));

  const content = text
    .replace(/<(ul|ol)>/, '')
    .replace(/<\/(ul|ol)>/, '')
    .replace(/•/g, '')
    .split('\n')
    .map((i) => i.trim())
    .filter((i) => i);

  return isUl ? (
    <ul>
      {content.map((c) => (
        <li key={uuid()}>{c}</li>
      ))}
    </ul>
  ) : (
    <ol>
      {content.map((c) => (
        <li key={uuid()}>{c}</li>
      ))}
    </ol>
  );
};
const replaceBold = (text: string) => {
  const content = text.replace('<b>', '').replace('</b>', '');

  return (
    <p>
      <strong>{content}</strong>
    </p>
  );
};

const replaceItalic = (text: string) => {
  const content = text.replace('<i>', '').replace('</i>', '');

  return <i>{content}</i>;
};

const replaceChart = (text: string) => {
  const paramsMatch = text.match(/\w+=".+?"/g);

  if (paramsMatch) {
    const paramsEntry = paramsMatch
      .map((e) => e.split('='))
      .map(([key, value]) => [key, value.replace(/"/g, '')]);

    const params = Object.fromEntries(paramsEntry);

    return <ImageDisplayer title={params.title} id={params.id} />;
  }

  return text;
};

const replaceH4 = (text: string) => {
  const content = text.replace('<h4>', '').replace('</h4>', '');

  return <S.GraphTitle>{content}</S.GraphTitle>;
};

const replaceCaption = (text: string) => {
  const content = text.replace('<caption>', '').replace('</caption>', '');

  return <S.GraphReference>{content}</S.GraphReference>;
};

const parseDomElements = (elements: Array<string | JSX.Element>) => {
  return elements.reduce((arr, el) => {
    if (typeof el === 'string') {
      const elementsArray = [...arr];
      const [lastElement] = elementsArray.slice(-1);

      if (typeof lastElement === 'string') {
        elementsArray.pop();
        elementsArray.push(`${lastElement} ${el}`);
        return elementsArray;
      }

      elementsArray.push(el);
      return elementsArray;
    }

    return [...arr, setSpaceInElement(el)];
  }, cast<typeof elements>([]));
};

export const replaceStringifiedElements = (text: string) => {
  const textArray = removeBlankInTags(text)
    .split(' ')
    .map((word) => undoRenameTags(word));

  const regexElements = [
    {
      regex: /<a href="(\w.+)?uqbar\.com\.br(.+)?>.+<\/a>/g,
      fn: replaceLink,
    },
    {
      regex: /<strong>.+<\/strong>/g,
      fn: replaceStrong,
    },
    {
      regex: /<(ul|ol)>[.\w\s\W]+?<\/(ul|ol)>/g,
      fn: replaceList,
    },
    {
      regex: /<b>\w.+<\/b>/g,
      fn: replaceBold,
    },
    {
      regex: /<chart .* ><\/chart>/g,
      fn: replaceChart,
    },
    {
      regex: /<i>.*<\/i>/g,
      fn: replaceItalic,
    },
    {
      regex: /<h4>.*<\/h4>/g,
      fn: replaceH4,
    },
    {
      regex: /<caption>.*<\/caption>/g,
      fn: replaceCaption,
    },
  ];

  const replacedElements = textArray.map((text) => {
    const matchedRegexElement = regexElements.find(({ regex }) => {
      return regex.test(text);
    });

    if (matchedRegexElement) {
      return matchedRegexElement.fn(text);
    }

    return text;
  });

  return parseDomElements(replacedElements);
};

const removeBlankInTags = (text: string) => {
  const tags = text.match(/<\w.*?>[.\w\s\W]*?<\/\w*>/g);

  if (tags) {
    const newText = tags.reduce((sentence, tag) => {
      const renamedTag = tag.replace(/ /g, '§');

      return sentence.replace(tag, `${renamedTag} `);
    }, text);

    return newText;
  }

  return text;
};

const undoRenameTags = (text: string) => {
  return text.replace(/§/g, ' ');
};

const protectTags = (text: string) => {
  const regex = /<\w.*?>[.\w\s\W]+?<\/\w*>/g;

  const matched = text.match(regex);

  if (matched) {
    const tags = matched.map((m) => [m, m.replace(/\n/g, '§')]);

    const textReplaced = tags.reduce(
      (finalText, [tag, formatedTag]) => finalText.replace(tag, formatedTag),
      text,
    );
    const textSliced = textReplaced.split('\n');

    const backToOriginalText = textSliced.map((paragraph) =>
      paragraph.replace(/§/g, '\n'),
    );

    return backToOriginalText;
  }

  return text.split('\n');
};

const formatText = (text: string) => {
  const paragraphs = protectTags(text).filter((i) => i);

  return paragraphs.map((p, index) => {
    return <p key={`paragraph-${index}`}>{replaceStringifiedElements(p)}</p>;
  });
};

type Props = {
  text: string[] | string;
};

const Text = (props: Props) => {
  return (
    <S.Wrapper>
      {Array.isArray(props.text)
        ? props.text.map((text) => formatText(text))
        : formatText(props.text)}
    </S.Wrapper>
  );
};

export default Text;
