import {
  Box,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateText } from 'src/utils/string';

import SubtitleLink from '../../../components/SubtitleLink';
import { getOperationForHome } from '../../../services/operation';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    minHeight: 300,
    maxHeight: 300,
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24.2px',
    marginBottom: '1rem',
  },
  verticalDivider: {
    height: 200,
  },
  divider: {
    width: '100%',
  },
  gridItem: {
    minHeight: '65px',
    maxHeight: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  gridItemWrapper: {
    width: '100%',
    padding: theme.spacing(0, 3),
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  text: {
    margin: theme.spacing(1, 0),
    fontWeight: 500,
    fontSize: '14px',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  market: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#797979',
  },
}));

export default function Operations() {
  const classes = useStyles();
  const { data: operations } = useQuery('operationsHome', () =>
    getOperationForHome(),
  );

  return (
    <Paper elevation={0} className={classes.paper}>
      <SubtitleLink
        title="Operações recentes"
        url={'/operacoes'}
        labelLink="Ver todas as operações"
      />
      <Box className={classes.box}>
        <Grid container className={classes.container}>
          {operations?.data?.slice(0, 3)?.map((operation, index) => (
            <div key={`op-l-${index}`} className={classes.gridItemWrapper}>
              <Grid item container xs className={classes.gridItem}>
                <Grid item xs={10}>
                  <RouterLink
                    to={`/operacao/${operation?.market?.name?.toLowerCase()}/${
                      operation.slug
                    }/${operation.hashId}`}
                    className={classes.text}
                    title={operation.name}>
                    {truncateText(operation.name, 45)}
                  </RouterLink>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.market}>
                    {operation?.market?.name}
                  </Typography>
                </Grid>
              </Grid>
              {index < 2 && <Divider className={classes.divider} />}
            </div>
          ))}
        </Grid>
        <Hidden xsDown>
          <Divider
            orientation={'vertical'}
            className={classes.verticalDivider}
          />
        </Hidden>
        <Hidden smUp>
          <Divider className={classes.divider} />
        </Hidden>
        <Grid container className={classes.container}>
          {operations?.data?.slice(3, 6)?.map((operation, index) => (
            <div key={`op-r-${index}`} className={classes.gridItemWrapper}>
              <Grid item container xs className={classes.gridItem}>
                <Grid item xs={10}>
                  <RouterLink
                    to={`/operacao/${operation?.market?.name?.toLowerCase()}/${
                      operation.slug
                    }/${operation.hashId}`}
                    className={classes.text}
                    title={operation.name}>
                    {truncateText(operation.name, 40)}
                  </RouterLink>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.market}>
                    {operation?.market?.name}
                  </Typography>
                </Grid>
              </Grid>
              {index < 2 && <Divider className={classes.divider} />}
            </div>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}
