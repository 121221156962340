import qs from 'qs';

import { publicApi } from '../../api/api';

export const getTrainings = async ({ from, limit, markets = [] }) => {
  const query = qs.stringify(
    {
      filters: {
        markets: {
          id: {
            $in: markets,
          },
        },
      },
      pagination: {
        start: from,
        limit,
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/cursos-list?${query}`);
  return data;
};

export const getTrainingById = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        supporters: {
          populate: '*',
        },
        organizers: {
          populate: '*',
        },
        mainOrganizer: {
          populate: '*',
        },
        headerImage: {
          populate: '*',
        },
        program: {
          populate: '*',
        },
        resume: {
          populate: '*',
        },
        courseFacilitators: {
          populate: {
            facilitator: {
              populate: '*',
            },
          },
        },
        details: {
          populate: '*',
        },
        markets: {
          populate: '*',
        },
        level: {
          populate: '*',
        },
        presentation: {
          populate: '*',
        },
        testmonials: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/cursos/${id}?${query}`);
  return data;
};
