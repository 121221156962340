import React from 'react';

import * as S from './styles';
import { If } from '../../../../../components/If';
import { replaceStringifiedElements } from '../Page/Text';

type Props = {
  title: string;
  subtitle?: string;
  half?: boolean;
  authors?: string[];
};

const Title = (props: Props) => {
  return (
    <S.Wrapper small={!!props.half}>
      <S.Title>{props.title}</S.Title>

      {props.authors && props.authors.length > 0 && (
        <S.Authors>
          {props.authors.map((a) => (
            <span key={`author-${a}`}>{replaceStringifiedElements(a)}</span>
          ))}
        </S.Authors>
      )}

      <If condition={props.subtitle}>
        <S.Subtitle>{props.subtitle}</S.Subtitle>
      </If>
    </S.Wrapper>
  );
};

export default Title;
