// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Global components
import GeneralDataItem from '../../../../../components/GeneralDataItem';
import Subtitle from '../../../../../components/Subtitle';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRA_CADASTRAL_DATA_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

export default function OperationCRAResumeGeneralData({ hashId }) {
  const { data, loading, error } = useQuery(
    GET_CRA_CADASTRAL_DATA_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Subtitle title="Dados Gerais" />
      <Box py={4}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <GeneralDataItem
              title="Emissor"
              subtitle={data.getCraCadastralDataByOperationHashId?.securitizer}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <GeneralDataItem
              title="Nº da Emissão"
              subtitle={
                data.getCraCadastralDataByOperationHashId?.emissionNumber
              }
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <GeneralDataItem
              title="Nº da séries"
              subtitle={data.getCraCadastralDataByOperationHashId?.seriesNumber}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
