export const validateCPF = (cpf) => {
  let sum;
  let rest;
  sum = 0;
  if (cpf === '00000000000' || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const validateCNPJ = (cnpj) => {
  if (cnpj === undefined) return false;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Eliminate known invalid CNPJs
  if (cnpj === '00000000000000') {
    return false;
  }
  if (/^(.)\1+$/.test(cnpj)) {
    return false;
  }
  // Validate VDs
  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  const digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (String(resultado) !== digits.charAt(0)) return false;

  size += 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (String(resultado) !== digits.charAt(1)) return false;

  return true;
};

export const formatCNPJ = (value) => {
  if (value)
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
};
