export type MarketName = 'CRs' | 'FII' | 'FIDC' | 'FIAGRO';

export interface YearbookRouteParams {
  market?: MarketName;
  section?: string;
}

export interface YearbookMarket {
  name: MarketName;
}

export const enum SponsorLevel {
  DIAMOND = 'Diamond',
  PLATINUM = 'Platinum',
  GOLD = 'Gold',
  SILVER = 'Silver',
  BRONZE = 'Bronze',
}

export interface AdvertisementBanner {
  url: string;
  banner: string;
  article?: string;
}

export interface Sponsor {
  site: string;
  markets: MarketName[];
  level: SponsorLevel;
  image: string;
}

export interface YearbookDescriptions {
  primary: string[];
  secondary: string[];
  stock: string[];
  rankings: string[];
}

export interface YearbookMarketChartDetails {
  title: string;
  texts: string[];
  valueTitle?: string;
  value?: number;
  valueUnit?: string;
}
export type ChartInfoSection = 'primary' | 'secondary' | 'stock';

export interface YearbookMarketChartInfo {
  name: string;
  details: YearbookMarketChartDetails;
  link?: string;
  chart: YearbookMarketChartData | any;
  section: ChartInfoSection;
}

export interface RankingWidgetParams {
  market: string;
  year: number;
  ranking: string;
  criteria: string;
  timeRange: string;
}

export interface YearbookMarketRankingDetails {
  title: string;
  texts: string[];
}

interface YearbookMarketRankingQueryDataRank {
  id?: number;
  name: string;
  institutionId: number;
  logo?: string;
  operationId?: string;
  percentage: number;
  position: number;
  slug: string;
  uqbarId: number;
  value: number;
}

export interface YearbookMarketRankingQueryData {
  market: MarketName;
  valueLabel: string;
  ranks: YearbookMarketRankingQueryDataRank[];
}

export interface YearbookMarketRanking {
  name: string;
  details: YearbookMarketRankingDetails;
  endpointUrl?: string;
  queryData?: YearbookMarketRankingQueryData;
  criteria?: string;
}

interface ArticleAdvertisement {
  image: string;
  link: string;
}

export interface ArticleAuthor {
  name: string;
  link: string;
}

interface ArticlePartner {
  name: string;
  link: string;
}

export interface ArticleProps {
  article: YearbookMarketArticle;
  select?: (article: YearbookMarketArticle) => void;
  active: boolean;
}

export interface YearbookMarketArticle {
  title: string;
  authorLink: string;
  ads: ArticleAdvertisement;
  partner: ArticlePartner;
  texts: string[];
}

export interface YearbookMarketVideo {
  title: string;
  author: string;
  videoId: string;
  market: MarketName;
}

interface YearbookMarketChartData {
  chartType: 'line-column' | 'pie' | 'column';
  data:
    | {
        name: string;
        type?: 'line' | 'column';
        data: number[];
      }[]
    | number[];
  categories: string[];
  numeric?: boolean;
  stacked?: boolean;
  monetary?: boolean;
  animated?: boolean;
}

export interface ChartsList {
  [key: string]: YearbookMarketChartData;
}
