import { Dispatch, SetStateAction } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { MenuItem, Select } from '@mui/material';
import { Indicator, State } from 'src/types/radar';

export default function IndicatorType({
  indicator,
  indicators,
  setState,
  disabled,
  isLoading,
}: {
  indicator: string;
  indicators?: Indicator[];
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
  isLoading: boolean;
}) {
  const handleChange = (value: string) => {
    setState((prevState) => ({ ...prevState, indicator: value }));
  };

  return (
    <Select
      value={indicator}
      onChange={(e) => handleChange(e.target.value)}
      renderValue={(value) =>
        `Indicador: ${
          indicators?.find((indicator) => indicator.code === value)?.name
        }`
      }
      inputProps={{ 'aria-label': 'Indicador' }}
      IconComponent={ExpandMoreRoundedIcon}
      sx={(theme) => ({
        '&': {
          color: indicator ? theme.palette.primary.main : '#4F4F4F',
        },
        '& fieldset': {
          border: 'none',
        },
        '& .MuiSelect-select': {
          padding: '16px !important',
          pr: '32px !important',
        },
        '& .MuiSvgIcon-root': {
          fill: disabled
            ? undefined
            : indicator
              ? theme.palette.primary.main
              : '#4F4F4F',
          right: '4px',
        },
      })}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: (theme) => ({
          '& li:hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
          '& .Mui-selected': {
            backgroundColor: 'unset !important',
          },
        }),
      }}
      disabled={disabled || isLoading}>
      {indicators?.map((indicator, index) => (
        <MenuItem key={`${index}-${indicator}`} value={indicator.code}>
          {indicator.name}
        </MenuItem>
      ))}
    </Select>
  );
}
