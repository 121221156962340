import { Button, Container, useMediaQuery } from '@material-ui/core';

import { useBannerRotatingSlideStyles } from './styles';

export default function BannerRotatingSlide({
  id,
  image,
  title,
  subtitle,
  ctaMain,
  ctaOptional,
  titleColor,
  subtitleColor,
  bestReader,
  index,
}) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const classes = useBannerRotatingSlideStyles({ bestReader });
  return (
    <div
      className={`${classes.slide} slide ${index === 0 ? 'selected' : ''}`}
      id={`slide-${index}`}
      aria-hidden="false"
      data-id={id}>
      <div className={classes.imageContainer}>
        <picture>
          {image.url && (
            <img
              src={image.url}
              className={classes.image}
              alt=""
              aria-hidden="true"
            />
          )}
        </picture>
        <div className={classes.textWrapper}>
          <div className={classes.textContainer}>
            <Container>
              {title === 'Conecte-se a profissionais, instituições e dados' ? (
                <h1
                  className={classes.titleContainer}
                  aria-label={title}
                  style={{ color: titleColor }}>
                  {title}
                </h1>
              ) : (
                <h2
                  className={classes.titleContainer}
                  aria-label={title}
                  style={{ color: titleColor }}>
                  {title}
                </h2>
              )}
              <p
                className={classes.subtitleContainer}
                aria-label={subtitle}
                style={{ color: subtitleColor }}>
                {subtitle}
              </p>
            </Container>
          </div>
        </div>
        <Container>
          <div className={classes.actions}>
            <Button
              fullWidth={isSmall}
              color={'secondary'}
              variant={'contained'}
              className={classes.buttonCtaMain}
              href={ctaMain.link}>
              {ctaMain.text}
            </Button>
            {ctaOptional?.link && (
              <Button
                fullWidth={isSmall}
                color={'secondary'}
                variant={'outlined'}
                className={classes.buttonCtaOptional}
                href={ctaOptional.link}>
                {ctaOptional.text}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
