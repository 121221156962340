// Material ui components
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';

import BannerAdvertisement from '../../../components/BannerAdvertisement';
import BannerRotating from '../../../components/BannerRotating';
import { getLandingPageData } from '../../../services/landing-page';
// Local components
import Article from '../Article';
import Benefits from '../Benefits';
import Indicators from '../Indicators';
import Institutions from '../Institutions';
import OurClients from '../OurClients';
import Rankings from '../Rankings';
import Testimonials from '../Testimonials';
import Trainings from '../Trainings';

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    marginTop: theme.spacing(9),
  },
  container: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
    },
  },
  grid: {
    margin: theme.spacing(6, 0, 1),
    '& > .MuiGrid-item:nth-child(even)': {
      padding: theme.spacing(1, 0, 1, 1),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 0),
      },
    },
    '& > .MuiGrid-item:nth-child(odd)': {
      padding: theme.spacing(1, 1, 1, 0),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 0),
      },
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 1),
    },
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const { data } = useQuery('info', getLandingPageData);

  return (
    <Box component="section">
      <Grid container className={classes.wrapperContainer}>
        <Grid item xs={12}>
          <BannerRotating />
        </Grid>
        <Grid item xs={12}>
          <Container>
            <OurClients />
          </Container>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: 'white' }}>
          <Container>
            <Benefits
              buttonText={data?.data?.title || ''}
              url={data?.data?.url || ''}
            />
          </Container>
        </Grid>
        <Container className={classes.container}>
          <Grid item xs={12}>
            <BannerAdvertisement location="Home_Area1" />
          </Grid>
          <Grid item container className={classes.grid}>
            <Grid item xs={12} lg={6}>
              <Rankings />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Indicators />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Institutions isLoggedIn={false} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Article />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BannerAdvertisement location="Home_Area2" />
          </Grid>
          <Grid item xs={12}>
            <Trainings />
          </Grid>
          <Grid item xs={12}>
            <Testimonials />
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
}
