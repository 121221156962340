import { FormHelperText } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';

export const renderSchedulesErrors = (
  errors: string | FormikErrors<{ startDate: null; endDate: null } | undefined>,
  touched: FormikTouched<{ startDate: null; endDate: null }> | undefined,
) => {
  if (!touched) return null;
  if (!errors) return null;
  if (typeof errors === 'string')
    return <FormHelperText sx={{ color: '#EB5757' }}>{errors}</FormHelperText>;
  if (errors.startDate)
    return (
      <FormHelperText sx={{ color: '#EB5757' }}>
        {errors.startDate}
      </FormHelperText>
    );
  if (errors.endDate)
    return (
      <FormHelperText sx={{ color: '#EB5757' }}>
        {errors.endDate}
      </FormHelperText>
    );
  return null;
};
