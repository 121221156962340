import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import feedbackDialog from '../../../assets/imgs/feedbackDialogImage.png';

type Props = {
  open: boolean;
  title: string;
  subTitle: string;
  labelButton: string;
  handleClick: () => void;
};

function AcceptInviteDialog({
  open,
  title,
  subTitle,
  handleClick,
  labelButton,
}: Props) {
  return (
    <Dialog open={open} maxWidth="md" sx={{ zIndex: 9999 }}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 50px 0',
          overflow: 'hidden',
          minHeight: '400px',
        }}>
        <img src={feedbackDialog} width={185} height={185} />
        <Typography
          sx={{ padding: '0px 64px' }}
          variant="h4"
          textAlign="center">
          {title}
        </Typography>
        <Typography
          maxWidth={394}
          textAlign="center"
          sx={{ fontSize: '0.875rem' }}>
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '0 0 40px 0' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Button
            size="large"
            variant="contained"
            onClick={handleClick}
            sx={{ fontSize: '0.875rem' }}>
            {labelButton}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default AcceptInviteDialog;
