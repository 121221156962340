import { MouseEvent, useState } from 'react';

import { Delete } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import { OperationFilter } from 'src/types/operation';

import {
  deleteOperationsFilter,
  getOperationsFilters,
} from '../../../services/operation';

export default function MyFiltersButton({
  market,
  type,
  indicator,
  handleSelectFilter,
  disabled,
}: {
  market: string;
  type: string;
  indicator?: string;
  handleSelectFilter: (values: any) => void;
  disabled: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<any>(null);

  const { isLoading, isFetching, data, refetch } = useQuery(
    [market, type, 'my-filters'],
    () => getOperationsFilters({ market, type, indicator }),
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const deleteOperationFilterMutation = useMutation(deleteOperationsFilter, {
    onSuccess: () => {
      setOpenConfirmDeleteDialog(false);
      refetch();
    },
  });

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    refetch();
  };

  const handleDelete = () => {
    deleteOperationFilterMutation.mutate(filterToDelete?.id);
  };

  const handleItemClick = (values: any) => {
    handleSelectFilter(values);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="meus-filtros"
        onClick={handleOpen}
        disabled={disabled}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&::after': {
            content: '"Meus filtros"',
            color: disabled ? 'inherit' : '#4F4F4F',
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 1,
          },
        }}>
        <SortIcon
          color={disabled ? 'disabled' : 'primary'}
          sx={{ transform: 'scaleY(-1)' }}
        />
      </IconButton>
      {!!anchorEl && (
        <>
          <Menu
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            open={!!anchorEl}
            MenuListProps={{
              sx: {
                minWidth: '300px',
                maxWidth: '300px',
                maxHeight: '200px',
              },
            }}
            PaperProps={{
              sx: (theme) => ({
                '&::-webkit-scrollbar': {
                  height: 10,
                  width: 10,
                  WebkitAppearance: 'none',
                  border: '1px solid #E0E0E0',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: 5,
                  backgroundColor: theme.palette.grey['800'],
                },
              }),
            }}>
            {isLoading || isFetching ? (
              <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={20} />
              </MenuItem>
            ) : data?.length > 0 ? (
              data?.map((filter: OperationFilter) => (
                <MenuItem
                  key={`${filter.id}-filter`}
                  onClick={() => handleItemClick(filter)}
                  title={filter.title}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center">
                    <Typography
                      sx={{
                        height: 'fit-content',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}>
                      {filter.title}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFilterToDelete(filter);
                        setOpenConfirmDeleteDialog(true);
                      }}
                      disabled={deleteOperationFilterMutation.isLoading}>
                      <Delete sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </Box>
                </MenuItem>
              ))
            ) : (
              <MenuItem
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
                disableRipple>
                {indicator
                  ? 'Sem filtros salvos para este indicador.'
                  : 'Sem filtros salvos para este mercado.'}
              </MenuItem>
            )}
          </Menu>
          <ConfirmDialog
            open={openConfirmDeleteDialog}
            onClose={() => setOpenConfirmDeleteDialog(false)}
            submitAction={handleDelete}
            title={`Tem certeza que deseja remover seu filtro “${filterToDelete?.title}”?`}
            hasActionButton={true}
          />
        </>
      )}
    </>
  );
}
