import qs from 'qs';

import { api, publicApi } from '../../api/api';

export const getYearbooks = async () => {
  const query = qs.stringify(
    {
      populate: [
        'button1',
        'button2',
        'image',
        'items',
        'items.subcategories',
        'items.subcategories.document',
        'items.subcategories.icon',
        'seo',
      ],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/anuario?${query}`);
  return data;
};

export const requestArticle = async (formData) => {
  const { data } = await api.post('/solicitacoes-artigos', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return data;
};

export const rankingLogos = async () => {
  const query = qs.stringify(
    {
      populate: [
        'rankingLogos',
        'rankingLogos.logo1.image',
        'rankingLogos.logo2.image',
        'rankingLogos.logo3.image',
        'rankingLogos.logo4.image',
        'rankingLogos.logo5.image',
      ],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/image?${query}`);
  return data;
};

export const getHeaderMenu = async () => {
  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/menus/1?nested&${query}`);
  return data;
};

export const getFooterList = async () => {
  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/menus/2?nested&${query}`);
  return data;
};

export const getSeoPlans = async () => {
  const query = qs.stringify(
    {
      populate: ['seo'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/seo-plano?${query}`);
  return data;
};

export const getSeoHome = async () => {
  const query = qs.stringify(
    {
      populate: ['seo'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/seo-home?${query}`);
  return data;
};
