import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default function Radar(props) {
  return (
    <SvgIcon {...props}>
      <svg
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M22 8.22481C22 5.51881 21.021 3.04381 19.404 1.12982C19.085 0.755815 18.524 0.711815 18.172 1.06381C17.875 1.36081 17.842 1.84481 18.106 2.16381C19.503 3.79181 20.35 5.91481 20.35 8.22481C20.35 10.5348 19.503 12.6578 18.106 14.2968C17.831 14.6158 17.875 15.0998 18.172 15.3968C18.524 15.7488 19.085 15.7048 19.404 15.3308C21.021 13.4058 22 10.9308 22 8.22481ZM1.65 8.22481C1.65 5.91481 2.497 3.79181 3.894 2.15281C4.169 1.83381 4.125 1.34981 3.828 1.05281C3.487 0.711815 2.915 0.744815 2.596 1.11881C0.979 3.04381 0 5.51881 0 8.22481C0 10.9308 0.979 13.4058 2.596 15.3308C2.915 15.7048 3.476 15.7488 3.828 15.3968C4.125 15.0998 4.158 14.6158 3.894 14.2968C2.497 12.6578 1.65 10.5348 1.65 8.22481ZM17.05 8.22481C17.05 9.63281 16.566 10.9418 15.752 11.9758C15.499 12.2948 15.532 12.7568 15.829 13.0538C16.181 13.4058 16.764 13.3728 17.072 12.9768C18.084 11.6678 18.7 10.0178 18.7 8.22481C18.7 6.43181 18.084 4.78181 17.061 3.48381C16.753 3.08781 16.17 3.05481 15.818 3.40681C15.532 3.69281 15.488 4.15481 15.741 4.48481C16.566 5.50781 17.05 6.81681 17.05 8.22481ZM6.182 13.0428C6.468 12.7568 6.512 12.2948 6.259 11.9648C5.434 10.9418 4.95 9.63281 4.95 8.22481C4.95 6.81681 5.434 5.50781 6.248 4.47381C6.501 4.15481 6.468 3.69281 6.171 3.39581C5.83 3.05481 5.247 3.08781 4.939 3.47281C3.916 4.78181 3.3 6.43181 3.3 8.22481C3.3 10.0178 3.916 11.6678 4.939 12.9768C5.247 13.3618 5.83 13.3948 6.182 13.0428Z" />
      </svg>
    </SvgIcon>
  );
}
