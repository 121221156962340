import { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Close, Info } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  IconButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, isAfter, isValid, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  findInstitutionById,
  getMarkets,
  getSegments,
  updateService,
} from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { Market } from 'src/types/market';
import { Service } from 'src/types/marketplace';
import { Segment } from 'src/types/segments';
import * as Yup from 'yup';

import * as S from '../CreateServiceModal/styles';

type Props = {
  isOpen: boolean;
  handleClose: (changed: boolean) => void;
  institution?: Institution;
  service?: Service;
  isUpdate: boolean;
  type: 'Serviço' | 'Projeto';
  setQuery?: (nextParams: any, method?: 'push' | 'remove' | undefined) => void;
};

const EditServiceModal = (props: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { auth } = useAuthContext();
  const [markets, setMarkets] = useState<Market[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const [hasTouched, setHasTouched] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string>('');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const errorMessages: Record<string, string> = {
    required: 'Campo obrigatório',
    'string.badword':
      'Identificamos possíveis palavras ofensivas, por favor revise o texto. Caso não seja palavra ofensiva entre em contato pelo e-mail <a href="mailto:info@uqbar.com.br">info@uqbar.com.br</a>',
  };

  const ValidateForm = Yup.object().shape({
    title: Yup.string().required(errorMessages.required),
    description: Yup.string().required(errorMessages.required),
    dueDate: Yup.date()
      .nullable()
      .test(
        'isAfter',
        'A data de expiração precisa ser maior do que o dia atual',
        (value) => {
          if (!value) return true;
          return isAfter(value, new Date());
        },
      ),
    segments: Yup.array().of(Yup.number()).min(1, errorMessages.required),
    markets: Yup.array().of(Yup.number()).min(1, errorMessages.required),
    toPriceRange: Yup.number()
      .when('fromPriceRange', (fromPriceRange, schema) => {
        if (fromPriceRange) {
          return schema
            .min(
              fromPriceRange,
              'O valor final precisa ser maior ou igual ao inicial',
            )
            .required(errorMessages.required);
        }
        return schema;
      })
      .nullable(),
    fromPriceRange: Yup.number()
      .test('toPriceRange', 'Campo obrigatório', (value) => {
        if (!value && formik.values.toPriceRange) return false;
        return true;
      })
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      title: props.service?.title,
      description: props.service?.description,
      dueDate: props.service?.dueDate,
      fromPriceRange: props.service?.fromPriceRange,
      toPriceRange: props.service?.toPriceRange,
      segments:
        props.service?.segments?.map(({ id }: { id: number }) => id) ||
        ([] as number[]),
      markets: props.service?.markets?.length
        ? props.service.markets?.map(({ id }: { id: number }) => id)
        : [],
      shouldShare: props.service?.shouldShare,
      isDisabled: !props.service?.publishedAt,
    },
    validationSchema: ValidateForm,
    onSubmit: (values) => {
      updateServiceMutation.mutate({
        id: props.service?.id,
        values: {
          ...values,
          dueDate: values.dueDate ?? null,
          type: props.type,
          fromPriceRange: values.fromPriceRange || null,
          toPriceRange: values.toPriceRange || null,
          ...(!props.service?.publishedAt !== values.isDisabled
            ? {
                publishedAt: !values.isDisabled ? new Date() : null,
              }
            : {}),
        },
      });
    },
  });

  const { isLoading: isLoadingMarkets } = useQuery('markets', getMarkets, {
    enabled: props.type === 'Projeto',
    onSuccess: (data) => {
      setMarkets(
        data?.data?.filter((m: Market) =>
          ['CRI', 'CRA', 'FII', 'FIDC'].includes(m.name),
        ),
      );
    },
  });
  const { isLoading: isLoadingSegments } = useQuery('segments', getSegments, {
    enabled: props.type === 'Projeto',
    onSuccess: (data) => {
      setSegments(data?.data);
    },
  });
  const { isLoading: loadingInstitution } = useQuery(
    ['institution', props.institution?.id],
    () => {
      if (props.institution)
        return findInstitutionById(props.institution?.id, false);
    },
    {
      onSuccess: (data: Institution) => {
        if (data?.markets?.length) {
          setMarkets(data.markets);
        }
        if (data?.segments?.length) {
          setSegments(data.segments);
        }
      },
      enabled: props.type === 'Serviço',
    },
  );

  const updateServiceMutation = useMutation(updateService, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ProfanityValidationError') {
        formik.setErrors(error?.response?.data?.error?.details);
      } else {
        dispatch(
          setBannerMessage({
            message: `Falha ao atualizar o ${props.type.toLowerCase()}`,
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
    onSuccess: (data) => {
      dispatch(
        setBannerMessage({
          message: `${props.type} atualizado com sucesso`,
          type: 'success',
          autoclose: true,
        }),
      );
      props.handleClose(true);
      auth.refetch();
      if (props.setQuery) {
        props.setQuery({ mode: props.type });
      }
    },
  });

  const isLoading =
    props.type === 'Projeto'
      ? isLoadingSegments || isLoadingMarkets
      : loadingInstitution;

  useEffect(() => {
    formik.resetForm();
  }, [props.service]);

  const handleClose = () => {
    props.handleClose(false);
    formik.resetForm();
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="md"
      onClose={() => {
        if (hasTouched) {
          setOpenLeaveDialog(true);
        } else {
          handleClose();
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxHeight: 'none',
          height: '700px',
        },
      }}>
      <DialogContent
        sx={{
          padding: '20px 48px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        }}>
        <form
          onSubmit={formik.handleSubmit}
          onChange={() => setHasTouched(true)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 -32px',
            }}>
            <IconButton
              aria-label="fechar"
              color="inherit"
              size="small"
              onClick={() => {
                if (hasTouched) {
                  setOpenLeaveDialog(true);
                } else {
                  handleClose();
                }
              }}>
              <Close
                fontSize="small"
                sx={{
                  color: 'black',
                }}
              />
            </IconButton>
          </Box>
          <S.Title>
            <Typography variant="h5">
              {props.type === 'Serviço'
                ? 'Oferta de serviço'
                : 'Demanda de projeto'}
            </Typography>
          </S.Title>
          <Grid container spacing={2} gap={2} paddingTop={2}>
            <Grid xs={12}>
              <S.FieldTitle>
                {props.type === 'Serviço'
                  ? 'Dê um título para o seu serviço*'
                  : 'Dê um título descritivo para o seu projeto*'}
              </S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="title"
                  placeholder={
                    props.type === 'Serviço'
                      ? 'Ex: Estruturação de FIDCs'
                      : 'Ex: Treinamentos corporativos'
                  }
                  onChange={formik.handleChange}
                  inputProps={{ maxLength: 50 }}
                  value={formik.values.title}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={
                    formik.touched.title &&
                    formik.errors.title &&
                    Boolean(errorMessages[formik.errors.title]) ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: errorMessages[formik.errors.title],
                        }}
                      />
                    ) : (
                      formik.errors.title
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <S.FieldTitle>
                {props.type === 'Serviço'
                  ? 'Descreva o seu serviço*'
                  : 'Descreva o que procura para o seu projeto*'}
                <CustomTooltip
                  title={
                    props.type === 'Serviço'
                      ? 'Descreva claramente o serviço oferecido, destacando suas características e benefícios.'
                      : 'Descreva claramente o seu projeto, destacando suas características e necessidades.'
                  }
                  placement="top">
                  <Info
                    fontSize="small"
                    htmlColor="#4F4F4F"
                    sx={{ marginLeft: '4px' }}
                  />
                </CustomTooltip>
              </S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="description"
                  placeholder={
                    props.type === 'Serviço'
                      ? 'Ex: Realizo treinamentos sobre....'
                      : 'Ex: Procuro um(a)...'
                  }
                  multiline
                  minRows={4}
                  maxRows={4}
                  inputProps={{ maxLength: 1500 }}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description &&
                    formik.errors.description &&
                    Boolean(errorMessages[formik.errors.description]) ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: errorMessages[formik.errors.description],
                        }}
                      />
                    ) : (
                      formik.errors.description
                    )
                  }
                />
                <Typography
                  sx={{
                    width: '100%',
                    textAlign: 'right',
                    position: 'relative',
                    top: formik.errors.description ? '-50px' : '-30px',
                    right: '20px',
                    fontSize: '12px',
                    lineHeight: '19px',
                    opacity: 0.25,
                  }}>
                  Número de caracteres: {formik.values.description?.length}/1500
                </Typography>
              </FormControl>
            </Grid>
            <Grid xs={12} sx={{ marginTop: '-20px' }}>
              <S.FieldTitle>
                Deseja definir uma data de expiração?
                <CustomTooltip
                  title="A partir desta data a oferta ficará oculta."
                  placement="top">
                  <Info
                    fontSize="small"
                    htmlColor="#4F4F4F"
                    sx={{ marginLeft: '4px' }}
                  />
                </CustomTooltip>
              </S.FieldTitle>
              <CustomTooltip
                title={
                  formik.values.dueDate &&
                  isAfter(Date.now(), parseISO(formik.values.dueDate))
                    ? `${props.type} expirado`
                    : ''
                }
                placement="top">
                <FormControl fullWidth variant="outlined">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ptBR}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: {
                          name: 'dueDate',
                          placeholder: 'DD/MM/AAAA',
                        },
                      }}
                      onChange={(value) => {
                        formik.setFieldValue(
                          'dueDate',
                          value && isValid(value)
                            ? format(value, 'yyyy-MM-dd')
                            : value,
                        );
                      }}
                      disablePast
                      value={
                        formik.values.dueDate && parseISO(formik.values.dueDate)
                      }
                    />
                  </LocalizationProvider>
                  {Boolean(formik.errors.dueDate) && formik.touched.dueDate && (
                    <FormHelperText sx={{ color: '#EB5757' }}>
                      {formik.errors.dueDate}
                    </FormHelperText>
                  )}
                </FormControl>
              </CustomTooltip>
            </Grid>
            <Grid xs={12}>
              <S.FieldTitle>
                Faixa de preço
                <CustomTooltip
                  title="Considere um modelo de precificação adequado para o serviço, como por hora, por tipo de serviço ou por êxito."
                  placement="top">
                  <Info
                    fontSize="small"
                    htmlColor="#4F4F4F"
                    sx={{ marginLeft: '4px' }}
                  />
                </CustomTooltip>
              </S.FieldTitle>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  gap: '24px',
                }}>
                <Grid
                  container
                  xs={6}
                  gap={1}
                  sx={{
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}>
                  <Typography fontSize={14}>De</Typography>
                  <TextField
                    name="fromPriceRange"
                    placeholder="R$"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.fromPriceRange}
                    error={
                      formik.touched.fromPriceRange &&
                      Boolean(formik.errors.fromPriceRange)
                    }
                  />
                </Grid>
                <Grid
                  container
                  xs={6}
                  gap={1}
                  sx={{
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}>
                  <Typography fontSize={14}>Até</Typography>
                  <TextField
                    name="toPriceRange"
                    placeholder="R$"
                    type="number"
                    disabled={
                      !formik.values.fromPriceRange &&
                      !formik.values.toPriceRange
                    }
                    onChange={formik.handleChange}
                    value={formik.values.toPriceRange}
                    error={
                      formik.touched.toPriceRange &&
                      Boolean(formik.errors.toPriceRange)
                    }
                  />
                </Grid>
              </FormControl>
              {Boolean(formik.errors.toPriceRange) &&
                formik.touched.toPriceRange && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {formik.errors.toPriceRange}
                  </FormHelperText>
                )}
              {Boolean(formik.errors.fromPriceRange) &&
                formik.touched.fromPriceRange && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {formik.errors.fromPriceRange}
                  </FormHelperText>
                )}
            </Grid>
            <Grid xs={12}>
              <S.FieldTitle>
                {props.type === 'Serviço'
                  ? 'Selecione o(s) segmento(s) que mais se relaciona com seu serviço*'
                  : 'Selecione o(s) segmento(s) que mais se relaciona com seu projeto*'}
                {props.type === 'Serviço' && (
                  <CustomTooltip
                    title="Caso queira adicionar um novo segmento inclua em seu perfil institucional"
                    placement="top">
                    <Info
                      fontSize="small"
                      htmlColor="#4F4F4F"
                      sx={{ marginLeft: '4px' }}
                    />
                  </CustomTooltip>
                )}
              </S.FieldTitle>
              <CustomTooltip
                title={selectedSegments}
                open={tooltipOpen && formik.values.segments.length > 0}
                placement="top">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={
                    formik.touched.segments && Boolean(formik.errors.segments)
                  }
                  onMouseEnter={() => {
                    setTooltipOpen(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipOpen(false);
                  }}
                  onClick={() => {
                    setTooltipOpen(false);
                  }}
                  onMouseDown={() => {
                    setTooltipOpen(false);
                  }}>
                  <Select
                    name="segments"
                    value={formik.values.segments}
                    onChange={formik.handleChange}
                    multiple
                    disabled={isLoadingSegments}
                    displayEmpty
                    renderValue={(selected: any) => {
                      if (!selected?.length) {
                        return (
                          <Typography sx={{ color: '#BDBDBD' }}>
                            Consultoria, Financeiro, Treinamentos
                          </Typography>
                        );
                      }
                      const selectedSegments: Segment[] = segments?.filter(
                        (segment: any) =>
                          selected.some((s: any) => s === segment.id),
                      );
                      setSelectedSegments(
                        selectedSegments.map(({ name }) => name).join(', '),
                      );
                      return selectedSegments
                        .map(({ name }) => name)
                        .join(', ');
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                        },
                      },
                      MenuListProps: {
                        sx: (theme) => ({
                          maxHeight: 240,
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: 10,
                            width: 8,
                            WebkitAppearance: 'none',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: 4,
                            backgroundColor: theme.palette.grey['400'],
                          },
                        }),
                      },
                    }}>
                    {segments.map((opt: Segment) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.segments.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.errors.segments) &&
                    formik.touched.segments && (
                      <FormHelperText>{formik.errors.segments}</FormHelperText>
                    )}
                </FormControl>
              </CustomTooltip>
            </Grid>
            <Grid xs={12}>
              <S.FieldTitle>
                {props.type === 'Serviço'
                  ? 'Selecione o(s) mercado(s) que mais se relaciona com seu serviço*'
                  : 'Selecione o(s) mercado(s) que mais se relaciona com seu projeto*'}
                {props.type === 'Serviço' && (
                  <CustomTooltip
                    title="Caso queira adicionar um novo mercado inclua em seu perfil institucional"
                    placement="top">
                    <Info
                      fontSize="small"
                      htmlColor="#4F4F4F"
                      sx={{ marginLeft: '4px' }}
                    />
                  </CustomTooltip>
                )}
              </S.FieldTitle>
              <FormControl
                fullWidth
                variant="outlined"
                error={
                  formik.touched.markets && Boolean(formik.errors.markets)
                }>
                <Select
                  name="markets"
                  value={formik.values.markets}
                  onChange={formik.handleChange}
                  multiple
                  disabled={isLoadingMarkets}
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (!selected?.length) {
                      return (
                        <Typography sx={{ color: '#BDBDBD' }}>
                          Selecione o(s) mercado(s)
                        </Typography>
                      );
                    }
                    const selectedMarkets: Market[] = markets?.filter(
                      (market: any) =>
                        selected.some((s: any) => s === market.id),
                    );
                    return selectedMarkets.map(({ name }) => name).join(', ');
                  }}>
                  {markets.map((opt: Market) => (
                    <MenuItem value={opt.id} key={opt.id}>
                      <Checkbox
                        checked={formik.values.markets.some(
                          (value) => value === opt.id,
                        )}
                      />
                      <ListItemText primary={opt.name} />
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.errors.markets) && formik.touched.markets && (
                  <FormHelperText>{formik.errors.markets}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid container xs={12} gap={2}>
              <Grid xs={12} container alignItems="center" paddingLeft="16px">
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 600,
                    },
                    marginRight: 0,
                  }}
                  onChange={() =>
                    formik.setFieldValue(
                      'shouldShare',
                      !formik.values.shouldShare,
                    )
                  }
                  checked={formik.values.shouldShare}
                  name="shouldShare"
                  control={<Checkbox size="small" />}
                  label="Enviar proposta de interesse aos usuários vinculados ao meu plano"
                />
                <CustomTooltip
                  title="Ao desabilitar essa opção o e-mail cadastrado ainda receberá notificações"
                  placement="top">
                  <Info
                    fontSize="small"
                    htmlColor="#4F4F4F"
                    sx={{ marginLeft: '4px' }}
                  />
                </CustomTooltip>
              </Grid>
              <Grid xs={12} container alignItems="center" paddingLeft="16px">
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 600,
                    },
                    marginRight: 0,
                  }}
                  onChange={() =>
                    formik.setFieldValue(
                      'isDisabled',
                      !formik.values.isDisabled,
                    )
                  }
                  checked={formik.values.isDisabled}
                  name="isDisabled"
                  control={<Checkbox size="small" />}
                  label="Desativar publicação"
                />
                <CustomTooltip
                  title={`Ao habilitar essa opção seu ${props.type.toLowerCase()} será ocultado do marketplace`}
                  placement="top">
                  <Info
                    fontSize="small"
                    htmlColor="#4F4F4F"
                    sx={{ marginLeft: '4px' }}
                  />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            gap={4}
            sx={{
              justifyContent: 'center',
              marginTop: '50px',
            }}>
            <Grid>
              <Button variant="contained" type="submit">
                {updateServiceMutation.isLoading ? (
                  <CircularProgress sx={{ color: '#FFF' }} />
                ) : (
                  'Salvar edição'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <ConfirmLeaveDialog
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={() => {
          handleClose();
        }}
        labelButton="Continuar"
        labelCloseButton="Fechar"
        title="Tem certeza que deseja fechar o formulário?"
      />
    </Dialog>
  );
};

export default EditServiceModal;
