import { useState } from 'react';
import { useEffect } from 'react';

import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import ArticleRequestModal from './ArticleRequestModal';
import peopleAtTable from '../../../assets/imgs/people-at-table.png';
import peopleStanding from '../../../assets/imgs/people-standing.png';
import MetaTags from '../../../components/Seo/MetaTags';
import Subheader from '../../../components/Subheader';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { ScrollToTop } from '../../../utils/scroll';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
  },
  imageGrid: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  description: {
    textAlign: 'justify',
  },
  descriptionBox: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  buttonContainer: {
    marginTop: '32px',
  },
  linkContainer: {
    marginTop: '16px',
    '& span': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
  container: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(0, 6),
  },
  citation: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    padding: theme.spacing(0, 15),
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
  instructionsTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

export default function PublishArticle() {
  const { auth } = useAuthContext();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSuccess(false);
      }, 500);
    }
  }, [open]);

  return (
    <ScrollToTop>
      <MetaTags
        title="Publique seu artigo | UQBAR"
        description="Faça uma requisição e nos envie seu artigo para nós publicarmos na plataforma"
      />
      <Box component="section">
        <Subheader
          title="Publique seu artigo"
          breadcrumbs1={
            <Link
              component={RouterLink}
              to="/artigos"
              underline="none"
              className={classes.link}>
              Artigos
            </Link>
          }
          breadcrumbs2="Publique seu artigo"
        />
        <Box py={3}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12} sm={8} md={8}>
                <Box className={classes.descriptionBox}>
                  <Typography className={classes.description}>
                    Aqui na Uqbar acreditamos que o compartilhamento de
                    conhecimento entre pares é o que faz nossa indústria
                    progredir.
                    <br />
                    <br />
                    Se você tem alguma experiência com o mercado de crédito e de
                    capitais, ideias que queira compartilhar, conclusões
                    acadêmicas e outros, então gostaríamos de ter você por
                    perto.
                    <br />
                    <br />
                    Aproveite para alavancar sua carreira ganhando visibilidade
                    e consolidando seu conhecimento frente a uma comunidade
                    engajada e técnica.
                  </Typography>
                  <Grid container className={classes.buttonContainer}>
                    <Grid item xs={12} md={3}>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        disableHoverListener={
                          auth?.user?.subscription?.plan?.permissions
                            ?.artigos_publicar
                        }
                        arrow
                        title={'Faça upgrade para publicar artigos'}
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}>
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disabled={
                              !auth?.user?.subscription?.plan?.permissions
                                ?.artigos_publicar
                            }
                            onClick={() => setOpen(true)}>
                            Publique na Uqbar
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.imageGrid}>
                <img className={classes.image} src={peopleStanding} />
              </Grid>
            </Grid>
          </Paper>
          <Grid container className={classes.container}>
            <Grid item md={12}>
              <Typography className={classes.citation}>
                A Uqbar é uma comunidade de pessoas interessadas na promoção e
                no desenvolvimento de um mercado de capitais e de crédito mais
                analítico e conectado. Estamos sempre em busca de artigos de
                qualidade que possam inspirar nossos leitores.
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: '32px' }}>
              <Grid item xs={12} sm={8} md={8}>
                <Box className={classes.descriptionBox}>
                  <Typography className={classes.description}>
                    Quem lê a Uqbar está em busca de um conteúdo original,
                    técnico, preciso e sem interferência do marketing.
                    <br />
                    <br />
                    Nos norteamos pelos mais elevados princípios jornalísticos,
                    embora reconheçamos que não somos jornalistas, mas
                    profissionais dos mercados de crédito e de capitais.
                    <br />
                    <br />
                    Entre em contato conosco para falar sobre sua ideia e
                    discutirmos a sua publicação.
                  </Typography>
                  <Grid container className={classes.linkContainer}>
                    <Grid item xs={12} md={3}>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        disableHoverListener={
                          auth?.user?.subscription?.plan?.permissions
                            ?.artigos_publicar
                        }
                        arrow
                        title={'Faça upgrade para publicar artigos'}
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}>
                        <span
                          onClick={
                            auth?.user?.subscription?.plan?.permissions
                              ?.artigos_publicar
                              ? () => setOpen(true)
                              : undefined
                          }>
                          Publique na Uqbar
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.imageGrid}>
                <img className={classes.image} src={peopleAtTable} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ArticleRequestModal
        open={open}
        handleClose={handleClose}
        success={success}
        setSuccess={setSuccess}
      />
    </ScrollToTop>
  );
}
