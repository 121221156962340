import qs from 'qs';

import { api, publicApi } from '../../api/api';

export const getArticles = async (page, limit) => {
  const start = Math.ceil((page - 1) * limit);
  const query = qs.stringify(
    {
      pagination: {
        start,
        limit,
      },
      sort: ['publishedAt:desc'],
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data, meta },
  } = await publicApi.get(`/artigos?${query}`);

  const {
    pagination: { total },
  } = meta;
  const pages = Math.ceil(total / limit);

  return { total, pages, data };
};

export const getArticlesFilters = async () => {
  const query = qs.stringify(
    {
      fields: ['id', 'name', 'keyword', 'startDate', 'endDate'],
      populate: {
        categories: {
          fields: ['title'],
        },
        markets: {
          fields: ['name'],
        },
        relatedInstitutions: {
          fields: ['razaoSocial'],
        },
        relatedProfessionals: {
          fields: ['fullName'],
        },
        relatedOperations: {
          fields: ['name'],
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await api.get(`/artigos-filtros?${query}`);

  return data;
};

export const getArticlesToHome = async (limit) => {
  const query = qs.stringify(
    {
      pagination: {
        start: 0,
        limit,
      },
      sort: ['publishedAt:desc'],
      fields: ['title', 'slug', 'id'],
      populate: ['headerImage'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/artigos?${query}`);

  return data;
};

export const getArticle = async (id, hasPermission) => {
  const query = qs.stringify(
    {
      populate: {
        seo: '*',
        categories: '*',
        headerImage: '*',
        author: {
          populate: ['photo', 'linkedin', 'facebook', 'twitter', 'email'],
        },
        markets: '*',
        relatedInstitutions: '*',
        relatedOperations: {
          populate: '*',
        },
        relatedProfessionals: '*',
        addons: {
          populate: '*',
        },
      },
      hasPermission,
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/artigos/${id}?${query}`);
  return data;
};

export const getRelatedArticlesByMarket = async (market, id, limit) => {
  const query = qs.stringify(
    {
      pagination: {
        limit,
      },
      fields: ['id', 'slug', 'title', 'publishedAt'],
      sort: ['publishedAt:desc'],
      filters: id
        ? {
            id: {
              $ne: id,
            },
            markets: {
              name: {
                $eq: market,
              },
            },
          }
        : {
            markets: {
              name: {
                $eq: market,
              },
            },
          },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/artigos?${query}`);
  return data;
};

export const getOperationsRelatedArticles = async () => {
  const query = qs.stringify(
    {
      pagination: {
        pageSize: '100',
      },
      filters: {
        relatedOperations: {
          id: {
            $notNull: true,
          },
        },
      },
      populate: ['relatedOperations'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/artigos?${query}`);
  return data;
};

export const findRelatedArticlesByProfessionalId = async (id, limit) => {
  const query = qs.stringify(
    {
      pagination: {
        limit,
      },
      sort: ['publishedAt:desc'],
      fields: ['title', 'slug', 'id'],
      populate: ['markets'],
      filters: {
        relatedProfessionals: {
          id: {
            $eq: id,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await publicApi.get(`/artigos?${query}`);

  return data;
};

export const getComments = async (articleId) => {
  const { data } = await api.get(`/comments/api::artigo.artigo:${articleId}`);

  return data;
};

export const createComment = async (values) => {
  return api.post(`/comments/api::artigo.artigo:${values.articleId}`, {
    ...values.comment,
  });
};

export const updateComment = async (values) => {
  return api.put(
    `/comments/api::artigo.artigo:${values.articleId}/comment/${values.comment.id}`,
    {
      ...values.comment,
    },
  );
};

export const deleteComment = async (values) => {
  return api.delete(
    `/comments/api::artigo.artigo:${values.articleId}/comment/${values.id}?authorId=${values.authorId}`,
  );
};
