import { Dashboard } from 'src/types/radar';

import BarChart from './BarChart';
import ComboChart from './ComboChart';
import PieChart from './PieChart';

export default function Charts({ dashboard }: { dashboard: Dashboard }) {
  const getChartByType = (type: string) => {
    switch (type) {
      case 'pie':
        return <PieChart dashboard={dashboard} />;
      case 'bar':
        return <BarChart dashboard={dashboard} />;
      case 'combo':
        return <ComboChart dashboard={dashboard} />;
      default:
        return 'GRÁFICO AQUI';
    }
  };

  return <>{getChartByType(dashboard.settings.display)}</>;
}
