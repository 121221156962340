import { makeStyles } from '@material-ui/core';

export const AllProfessionalsStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  itemInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
  tableTitle: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    lineHeight: '16px',
  },
  table: {
    minWidth: 500,
    height: 'auto',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    padding: '2rem',
  },
  rowName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  rowLight: {
    fontWeight: theme.typography.fontWeightLight,
  },
  rowSegments: {
    fontWeight: theme.typography.fontWeightLight,
    maxWidth: '300px',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: '0 auto',
  },
  chip: {
    margin: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: '600',
    lineHeight: '18px',
  },
  tableRow: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  notificationBox: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(20, 20),
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    backdropFilter: 'blur(10px)',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: '29px',
  },
  subTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '17px',
    marginTop: '1rem',
  },
}));
