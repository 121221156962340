// Material ui components
// Apollo client
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
// Global components
import { useHistory } from 'react-router-dom';
import SubTitle from 'src/components/v2/SubTitle';

import CardAssignor from '../../../../../components/CardAssignor';
import SkeletonGrid from '../../../../../components/SkeletonGrid';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRI_ASSIGNORS_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cri/queries';

export default function OperationCRIAssetAssignors({ hash }) {
  const { auth } = useAuthContext();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [assignors, setAssignors] = useState([
    {
      name: null,
      cnpj: null,
    },
  ]);
  const lock = false;

  const onChangePagination = (event, value) => {
    setPage(value);
  };

  const handleClick = (id, slug, market) => {
    if (id && slug) {
      if (market) {
        history.push(`/operacao/${market.toLowerCase()}/${slug}/${id}`);
      } else {
        history.push(`/instituicao/${slug}/${id}`);
      }
    }
  };

  const { loading, error } = useQuery(GET_CRI_ASSIGNORS_BY_OPERATION_HASH_ID, {
    variables: {
      hash,
      pageSize: 12,
      page: page,
    },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions['operacoes_cri_ativo'],
    onCompleted: ({
      getCriAssignorsByOperationHashId: { assignors, pages },
    }) => {
      setAssignors(assignors);
      setPages(pages);
    },
  });

  if (error) return null;

  const mappedData = (assignors) =>
    assignors.length > 0 &&
    assignors.map(({ id, name, cnpj, strapiId, slug, market }) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={4}
        key={`${id}-cri-assignor`}
        onClick={() => handleClick(strapiId, slug, market)}
        style={strapiId && slug && { cursor: 'pointer' }}>
        <CardAssignor
          hasLink={Boolean(strapiId) && Boolean(slug)}
          lock={lock}
          name={name}
          document={cnpj}
        />
      </Grid>
    ));

  return (
    <div>
      <SubTitle title="Cedentes" />
      {assignors.length ? (
        <Box py={2}>
          <Grid container spacing={1}>
            {!loading ? (
              mappedData(assignors)
            ) : (
              <SkeletonGrid width="28em" height="6em" skeletonNumber={3} />
            )}
          </Grid>
          {!lock && !loading && (
            <Box pt={3} display="flex" justifyContent="flex-end">
              <Pagination
                count={pages}
                page={page}
                onChange={onChangePagination}
                shape="rounded"
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="start" m={1}>
          <Typography>Não foram encontrados cedentes</Typography>
        </Box>
      )}
    </div>
  );
}
