import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { initialValuesCalculator, initialValuesSecurity } from './formObject';
import Calculator from '../components/Calculator';
import DataContent from '../components/DataContent';
import Security from '../components/Security';
import SideMenu from '../components/SideMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  security: {
    order: 1,
    padding: theme.spacing(2, 0, 2, 2),
    minHeight: 320,
    [theme.breakpoints.only('xs')]: {
      order: 2,
    },
  },

  calculator: {
    order: 3,
    padding: theme.spacing(2, 2, 2, 0),
    minHeight: 320,
    [theme.breakpoints.down('sm')]: {
      order: 5,
    },
    [theme.breakpoints.only('md')]: {
      order: 4,
    },
  },

  sideMenu: {
    order: 4,
    padding: theme.spacing(0, 0, 2, 2),
    minHeight: 320,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
    [theme.breakpoints.only('md')]: {
      order: 3,
    },
  },

  dataTable: {
    order: 2,
    padding: theme.spacing(2),
    height: 450,
    [theme.breakpoints.only('xs')]: {
      order: 3,
    },
  },

  dataGraphic: {
    order: 5,
    padding: theme.spacing(0, 2, 2, 2),
    // minHeight: 320,
    // maxHeight: 450,
    minHeight: 450,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },
}));

const validationSchema = yup.object().shape({
  id: yup.number().nullable().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  start_date: yup.string().required('Campo obrigatório'),
  payment: yup.object().shape({
    balance: yup.string().required('Campo obrigatório'),
    principal_payment: yup.number().required('Campo obrigatório'),
    principal_start: yup.number().required('Campo obrigatório'),
    step: yup.number().required('Campo obrigatório'),
  }),
});

function MainPageCalculator() {
  const classes = useStyles();
  const security = useFormik({
    initialValues: initialValuesSecurity,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const calculator = useFormik({
    initialValues: initialValuesCalculator,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.security}>
          <Security formik={{ security, calculator }} />
        </Grid>

        <Grid item xs={12} md={8} lg={6} className={classes.dataTable}>
          <DataContent showTableFormat />
        </Grid>

        <Grid item xs={12} sm={6} lg={3} className={classes.calculator}>
          <Calculator formik={{ security, calculator }} />
        </Grid>

        <Grid item xs={12} sm={6} lg={3} className={classes.sideMenu}>
          <SideMenu formik={{ security }} />
        </Grid>

        <Grid item xs={12} lg={9} className={classes.dataGraphic}>
          <DataContent />
        </Grid>
      </Grid>
    </div>
  );
}

export default MainPageCalculator;
