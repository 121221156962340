// Apollo client
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
// Material ui components
import { Box, CircularProgress, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import OperationFIIAsset from './OperationFIIAsset';
import OperationFIDCGeneralData from './OperationFIIGeneralData';
import OperationFIIInvestidors from './OperationFIIInvestors';
import OperationFIILiability from './OperationFIILiability';
import OperationFIIResume from './OperationFIIResume';
import OperationFIIServiceProvider from './OperationFIIServiceProvider';
import Competence from '../../../components/Competence';
import { If } from '../../../components/If';
import MetaTags from '../../../components/Seo/MetaTags';
// Global components
import Subheader from '../../../components/Subheader';
import { TabSection } from '../../../components/TabSection';
import ipanema from '../../../graphql/clients/ipanema';
import { GET_FII_GENERAL_DATA_BY_HASH_ID } from '../../../graphql/queries';
import OperationDocuments from '../OperationDocuments';
import OperationRelatedArticles from '../OperationRelatedArticles';
import OperationSecondary from '../OperationSecondary';
// Local components

export default function OperationFII() {
  const { tab, hashId, market, slug } = useParams();
  const history = useHistory();
  const [value, setValue] = useState(
    parseInt(window.localStorage.getItem('operationTabIndex')) || 'resumo',
  );
  const tabs = [
    { label: 'Resumo', value: 'resumo' },
    { label: 'Dados Gerais', value: 'dados' },
    { label: 'Prestadores de Serviço', value: 'prestadores' },
    { label: 'Ativo', value: 'ativo' },
    { label: 'Passivo', value: 'passivo' },
    { label: 'Secundário', value: 'secundario' },
    { label: 'Investidores', value: 'investidores' },
    { label: 'Documentos', value: 'documentos' },
    { label: 'Artigos Relacionados', value: 'artigos' },
  ];

  const { data, loading } = useQuery(GET_FII_GENERAL_DATA_BY_HASH_ID, {
    variables: { hash: hashId },
    client: ipanema,
  });

  const handleTabChange = (tabIndex) => {
    setValue(tabIndex);
    if (tabIndex === 'resumo') {
      history.push(`/operacao/${market}/${slug}/${hashId}`);
    } else {
      history.push(`/operacao/${market}/${slug}/${hashId}/${tabIndex}`);
    }
  };

  useEffect(() => {
    window.localStorage.setItem('operationTabIndex', JSON.stringify(value));
    return function clean() {
      localStorage.removeItem('operationTabIndex');
    };
  }, [value]);

  useEffect(() => {
    setValue(tab ?? 'resumo');
  }, []);

  if (loading) return <CircularProgress color="secondary" />;

  const name = data?.getFiiGeneralDataByHashId?.operationName ?? '';
  const operationCnpj = data?.getFiiGeneralDataByHashId?.operationCnpj ?? '';
  const operationName = data?.getFiiGeneralDataByHashId?.operationName ?? '';

  return (
    <>
      <MetaTags
        title={`${name} - FII`}
        description={`Encontre as principais informações sobre ${name}`}
      />
      <Subheader
        title={name}
        market={market.toUpperCase()}
        breadcrumbs1="Fundos"
        breadcrumbs2={`${name} ${market.toUpperCase()}`}
        favoriteType="operation"
      />
      <Box py={2}>
        <TabSection tabList={tabs} value={value} setValue={handleTabChange}>
          <Paper square>
            <If condition={value === 'resumo'}>
              <Box p={2}>
                <Competence value={hashId} searchType="HASH_ID" />
              </Box>
              <OperationFIIResume hash={hashId} />
            </If>
            <If condition={value === 'dados'}>
              <OperationFIDCGeneralData hash={hashId} />
            </If>
            <If condition={value === 'prestadores'}>
              <OperationFIIServiceProvider hash={hashId} />
            </If>
            <If condition={value === 'ativo'}>
              <OperationFIIAsset hash={hashId} operationCnpj={operationCnpj} />
            </If>
            <If condition={value === 'passivo'}>
              <OperationFIILiability hash={hashId} />
            </If>
            <If condition={value === 'secundario'}>
              <OperationSecondary hash={hashId} market={market.toUpperCase()} />
            </If>
            <If condition={value === 'documentos'}>
              <OperationDocuments hash={hashId} />
            </If>
            <If condition={value === 'artigos'}>
              <OperationRelatedArticles hash={hashId} />
            </If>
            <If condition={value === 'investidores'}>
              <OperationFIIInvestidors
                operationCnpj={operationCnpj}
                operationName={operationName}
              />
            </If>
          </Paper>
        </TabSection>
      </Box>
    </>
  );
}
