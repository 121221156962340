import { useEffect, useState } from 'react';

import { Box, Grid, Hidden, Paper, Stack, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { useMutation, useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import {
  deleteFavoriteRadar,
  getFavoriteRadar,
  getRadarConvocations,
} from 'src/services/radar';
import { Convocation, FavoriteRadar, State } from 'src/types/radar';
import { hasChanged } from 'src/utils/validations';

import DateCalendarServerRequest from './Calendar';
import ConvocationCard from './ConvocationCard';
import ConvocationDate from './ConvocationsFilters/ConvocationDate';
import ConvocationDocumentType from './ConvocationsFilters/ConvocationDocumentType';
import ConvocationOperations from './ConvocationsFilters/ConvocationOperations';
import ConvocationType from './ConvocationsFilters/ConvocationType';
import RadarFavorite from '../RadarFavorite';

export default function RadarConvocations({ query }: { query: any }) {
  const { auth } = useAuthContext();

  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.radar_convocations;

  const INITIAL_STATE: State = {
    date: undefined,
    documentType: ['Carta Consulta', 'Consulta Formal', 'Edital de Convocação'],
    type: ['AG - Especial de Investidores', 'AGE', 'AGO', 'AGO/E'],
    operations: [],
  };

  const [convocations, setConvocations] = useState<Convocation[]>([]);
  const [state, setState] = useState(INITIAL_STATE);
  const [stateChanged, setStateChanged] = useState(false);
  const [favoriteRadars, setFavoriteRadars] = useState<FavoriteRadar[]>();
  const [selectedFavoriteRadar, setSelectedFavoriteRadar] =
    useState<FavoriteRadar>();
  const [selectedRemoveFavoriteRadar, setSelectedRemoveFavoriteRadar] =
    useState<FavoriteRadar>();
  const [openDialog, setOpenDialog] = useState(false);

  const { refetch } = useQuery(
    [query.market, 'convocations', state],
    () =>
      getRadarConvocations({
        filters: query,
        state,
      }),
    {
      onSuccess: ({ data }) => {
        setConvocations(
          state.date ? data : data?.filter((c: Convocation) => !c.isFinished),
        );
      },
      enabled: hasPermission && !auth.isLoading,
    },
  );

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('radarConvocations'),
    {
      enabled: !hasPermission && !auth.isLoading,
    },
  );

  const { isLoading: isLoadingFavoriteRadar, refetch: refetchFavoriteRadar } =
    useQuery(
      'favoriteRadar',
      () => getFavoriteRadar(query.market, query.mode),
      {
        onSuccess: ({ data }: { data: FavoriteRadar[] }) => {
          setFavoriteRadars(data);
        },
        enabled: auth.isLoggedIn && hasPermission,
      },
    );

  const removeFavoriteRadar = useMutation(
    (value: number) => deleteFavoriteRadar(value),
    {
      onSuccess: () => {
        refetchFavoriteRadar();
      },
    },
  );

  const handleDeleteFavoriteRadar = () => {
    setSelectedFavoriteRadar(undefined);
    if (selectedRemoveFavoriteRadar?.id) {
      removeFavoriteRadar.mutate(selectedRemoveFavoriteRadar.id);
      setOpenDialog(false);
    }
  };

  const handleOpenModal = () => {
    setOpenDialog((prevState) => !prevState);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!selectedFavoriteRadar) {
      setStateChanged(hasChanged(INITIAL_STATE, state, ['date']));
    } else {
      setStateChanged(hasChanged(selectedFavoriteRadar.data, state, ['date']));
    }
  }, [state, auth.isFetched, selectedFavoriteRadar]);

  useEffect(() => {
    if (!query.favoriteId || !favoriteRadars?.length) {
      return setState(INITIAL_STATE);
    }
    const favoriteRadar = favoriteRadars.find(
      (radar) => radar.id === +query.favoriteId,
    );
    const newValue = { ...INITIAL_STATE, ...favoriteRadar?.data };
    setState(newValue);
    if (favoriteRadar) {
      setSelectedFavoriteRadar({
        ...favoriteRadar,
        data: newValue,
      });
    }
  }, [query.favoriteId, favoriteRadars]);

  useEffect(() => {
    setState(INITIAL_STATE);
    setSelectedFavoriteRadar(undefined);
    refetchFavoriteRadar();
  }, [query.market]);

  useEffect(() => {
    refetch();
  }, [state]);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          margin: '0',
          position: 'relative',
        }}>
        <Box pt={2.5} pb={1.375} pl={2.25}>
          <Stack
            direction={'row'}
            bgcolor={'#FFF'}
            gap={2}
            alignItems={'center'}
            sx={(theme) => ({
              overflowX: 'auto',
              overflowY: 'hidden',
              '&::-webkit-scrollbar': {
                height: 10,
                width: 8,
                WebkitAppearance: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: 4,
                backgroundColor: theme.palette.grey['400'],
              },
              pb: 0.625,
              '& > *': {
                fontWeight: '700 !important',
                fontSize: '14px !important',
                paddingInline: '0 !important',
                boxShadow: '0px 4px 4px 0px #00000026 !important',
                height: '48px !important',
                margin: '0 !important',
                backgroundColor: 'transparent !important',
                minWidth: 'fit-content !important',
                '.MuiSvgIcon-root': {
                  fontSize: '20px !important',
                },
              },
              '& > .MuiButtonBase-root': {
                padding: '16px 8px 16px 16px !important',
              },
            })}>
            <RadarFavorite
              state={state}
              setState={setState}
              mode={query.mode}
              initialState={INITIAL_STATE}
              favoriteRadars={favoriteRadars}
              loadingFavoriteRadar={isLoadingFavoriteRadar}
              selectedFavoriteRadar={selectedFavoriteRadar}
              setSelectedFavoriteRadar={setSelectedFavoriteRadar}
              handleOpenModal={handleOpenModal}
              setSelectedRemoveFavoriteRadar={setSelectedRemoveFavoriteRadar}
              stateChanged={stateChanged}
              disabled={
                !hasPermission ||
                (!stateChanged && favoriteRadars?.length === 0)
              }
              refetchFavoriteRadar={refetchFavoriteRadar}
            />
            <ConvocationDate
              INITIAL_STATE={INITIAL_STATE}
              state={state}
              setState={setState}
              data={convocations}
              disabled={!hasPermission}
            />
            <ConvocationOperations
              state={state}
              setState={setState}
              disabled={!hasPermission}
              market={query.market}
            />
            <ConvocationType
              INITIAL_STATE={INITIAL_STATE}
              state={state}
              setState={setState}
              data={convocations}
              disabled={!hasPermission}
            />
            <ConvocationDocumentType
              INITIAL_STATE={INITIAL_STATE}
              state={state}
              setState={setState}
              data={convocations}
              disabled={!hasPermission}
            />
          </Stack>
        </Box>
        <Paper
          sx={(theme) => ({
            padding: 2.5,
            margin: theme.spacing(2.5, 0),
          })}>
          {!hasPermission && contentPayWallBanner?.['radarConvocations'] ? (
            <Box
              sx={{
                position: 'relative',
                minHeight: !hasPermission ? '300px' : 'unset',
              }}>
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  top: '10%',
                  left: 0,
                  px: '10px',
                }}>
                <CardDemonstration
                  title={contentPayWallBanner?.['radarConvocations']?.title}
                  subTitle={
                    contentPayWallBanner?.['radarConvocations']?.subTitle
                  }
                  labelButton={
                    contentPayWallBanner?.['radarConvocations']?.labelButton
                  }
                  url={contentPayWallBanner?.['radarConvocations']?.url}
                />
              </Box>
            </Box>
          ) : (
            <Grid container gap={2}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={7}
                lg={8}
                sx={{
                  backgroundColor: '#F1F1F1',
                  borderRadius: '5px',
                  padding: '12px',
                  gap: 3,
                  height: 'fit-content',
                }}>
                {convocations?.length > 0 &&
                  convocations?.map((convocation: Convocation) => (
                    <ConvocationCard
                      key={`convocation-${convocation.id}`}
                      convocation={convocation}
                    />
                  ))}
                {!convocations?.length && (
                  <Typography
                    sx={{
                      color: '#4F4F4F',
                      fontSize: '16px',
                      width: '100%',
                      padding: '32px',
                      textAlign: 'center',
                    }}>
                    Não foram encontrados resultados para essa busca.
                  </Typography>
                )}
              </Grid>
              <Hidden mdDown>
                <Grid item>
                  <DateCalendarServerRequest
                    highlightedDays={convocations?.map(
                      (convocation: Convocation) => parseISO(convocation.date),
                    )}
                    referenceDate={
                      state.date ? new Date(state.date) : new Date()
                    }
                  />
                </Grid>
              </Hidden>
            </Grid>
          )}
        </Paper>
      </Box>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={`Tem certeza que deseja excluir o filtro ${selectedRemoveFavoriteRadar?.name}?`}
        subTitle={''}
        submitAction={handleDeleteFavoriteRadar}
        hasActionButton={Boolean(handleDeleteFavoriteRadar)}
      />
    </Box>
  );
}
