import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { format, parse } from 'date-fns';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
  card: {
    cursor: 'pointer',
    borderRadius: '8px',
  },
  cardContent: {
    padding: theme.spacing(2, 2, 1),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textContent: {
    color: '#47484C',
  },
  button: {
    fontSize: '0.875rem',
    padding: theme.spacing(1, 0),
  },
  cardActions: {
    padding: theme.spacing(1, 2, 0),
  },
}));

export default function CardTraining({
  hashId,
  title,
  image,
  type,
  markets,
  slug,
  resume,
}) {
  const classes = useStyles();

  return (
    <Link
      to={`/treinamento/${slug}/${hashId}`}
      style={{ textDecoration: 'none' }}>
      <Card elevation={2} square className={classes.card}>
        <CardMedia
          className={classes.media}
          image={image?.formats?.small?.url}
          title={title}
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>{title}</Typography>
          <Grid container>
            <Grid item xs={6} sm>
              <Typography component="p" className={classes.textContent}>
                {resume?.startDate
                  ? format(
                      parse(resume.startDate, 'yyyy-MM-dd', new Date()),
                      'dd/MM',
                    )
                  : 'A definir'}
                {resume?.endDate
                  ? ` a ${format(
                      parse(resume.endDate, 'yyyy-MM-dd', new Date()),
                      'dd/MM',
                    )}`
                  : ''}
              </Typography>
            </Grid>
            {type && (
              <Grid item xs={6} sm>
                <Typography className={classes.textContent}>{type}</Typography>
              </Grid>
            )}
            <Grid item container xs={12} sm>
              {markets &&
                markets.map((market) => (
                  <Grid key={market.id} item xs>
                    <Typography className={classes.textContent}>
                      {market.name}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button color="secondary" className={classes.button}>
            Saiba mais
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
}
