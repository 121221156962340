import { forwardRef, useMemo } from 'react';

// Typechecking
import {
  Chip,
  Grid,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// Declarative routing
import { Link as RouterLink } from 'react-router-dom';
// Material ui components

const useStyles = makeStyles((theme) => ({
  chip: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
  },
}));

export default function ListItemLink({
  to,
  title,
  publishDate,
  markets,
  ...other
}) {
  const classes = useStyles();
  const renderLink = useMemo(
    () =>
      /* eslint-disable-next-line react/display-name */
      forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} title={title} {...itemProps} />
      )),
    [to],
  );
  renderLink.displayName = 'RenderLinkForward';

  return (
    <li>
      <ListItem button divider component={renderLink} {...other}>
        <Grid container spacing={2} alignItems="center">
          {markets &&
            markets.map((market) => (
              <Grid item xs key={market}>
                <Chip
                  label={market.name}
                  size="small"
                  color="secondary"
                  className={classes.chip}
                />
              </Grid>
            ))}
          <Grid item xs={12} sm={9}>
            <Typography component="h3">{title}</Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Typography variant="caption">{publishDate}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
};
