import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import { FieldArrayRenderProps } from 'formik';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { Certification } from 'src/types/professional';

const CertificationsList = ({
  rows,
  index,
  setIndex,
  setOpen,
  setActionType,
  arrayHelpers,
  handleUpdate,
}: {
  rows: Certification[];
  index: number | undefined;
  setIndex: Dispatch<SetStateAction<number | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setActionType: Dispatch<
    SetStateAction<'create' | 'update' | 'delete' | undefined>
  >;
  arrayHelpers: FieldArrayRenderProps;
  handleUpdate: (values: any) => void;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState<
    Certification | undefined
  >();

  const handleEdit = (index: number) => {
    setIndex(index);
    setOpen(true);
    setActionType('update');
  };

  const handleOpenDialog = (index: number) => {
    setSelectedCertification(rows[index]);
    setIndex(index);
    setOpenDialog(true);
    setActionType('delete');
  };

  const handleRemove = () => {
    let updatedItems = rows;
    if (Array.isArray(updatedItems)) {
      updatedItems = [...updatedItems].filter(
        (item: any) => item.id !== selectedCertification?.id,
      );
    }
    handleUpdate(updatedItems);
    if (index !== undefined) {
      arrayHelpers.remove(index);
      setOpenDialog(false);
      setOpen(false);
    }
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'certificates',
        header: 'Certificados',
        size: 300,
        Cell: ({ cell }: any) => (
          <Box>
            <Typography fontSize={16} fontWeight={700} color={'secondary'}>
              {cell.row.original.name}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        muiTableBodyCellProps: {
          align: 'left',
        },
        Cell: ({ row }: any) => (
          <Box display={'grid'}>
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(row.index)}>
              Editar
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleOpenDialog(row.index)}>
              Excluir
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: rows ?? [],
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    enableStickyHeader: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        padding: '16px 0 40px',
      },
    },
  });

  return (
    <>
      <MaterialTable table={table} />
      <ConfirmDialog
        title={`Tem certeza que deseja remover sua certificação “${index !== undefined ? rows[index]?.name : ''}”?`}
        subTitle="Ao remover sua certificação, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar uma nova certificação."
        submitAction={handleRemove}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasActionButton
      />
    </>
  );
};

export default CertificationsList;
