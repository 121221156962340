import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Avatar from 'react-avatar';
import { Link as RouterLink } from 'react-router-dom';
import logoSymbol from 'src/assets/imgs/uqbar-logo-symbol.svg';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { Speaker as SpeakerType } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

export default function SpeakerCard({ data }: { data: SpeakerType }) {
  const theme = useTheme();
  const nextThreeLectures = data.presentations?.slice(0, 3);

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        position: 'relative',
        '&:after': {
          content: '""',
          display: 'block',
          height: '100%',
          position: 'absolute',
          top: 0,
          width: '100%',
          background:
            'linear-gradient(1.06deg, #D9D9D9 -41.01%, #000000 -41.01%, rgba(0, 0, 0, 0) 49.97%)',
          zIndex: 0,
        },
        '&:hover': {
          p: '11px 6px 29px 11px',
          '&:after, .MuiCardMedia-root': {
            display: 'none',
          },
          '.MuiCardHeader-root, .MuiCardContent-root': {
            display: 'flex',
          },
        },
      }}>
      <CardHeader
        sx={{
          pb: '8px',
          display: 'none',
          '& p': {
            maxWidth: '100cqw',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '& .MuiCardHeader-content': {
            containerType: 'inline-size',
            display: 'grid',
            gap: '8px',
          },
          '.img-avatar_custom > img': {
            objectFit: 'contain',
          },
        }}
        avatar={
          <Avatar
            name={data.professional.fullName}
            maxInitials={2}
            src={getSmallestImageUrl(data.professional.photo)}
            round
            size="66"
            className="img-avatar_custom"
          />
        }
        title={
          <Link
            component={RouterLink}
            to={(location) => ({
              ...location,
              pathname: `/profissional/${data.professional.slug}/${data.professional.id}`,
            })}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}>
            <Typography
              display={'flex'}
              title={data.professional.fullName}
              sx={{
                fonSize: '16px',
                fontWeight: 700,
                color: theme.palette.primary.main,
                maxWidth: '190px !important',
              }}>
              {data.professional.fullName}
            </Typography>
            <img
              src={logoSymbol}
              alt="Logo da Uqbar"
              style={{
                width: '16px',
                height: '16px',
                padding: '4px',
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                color: 'black',
              }}
            />
          </Link>
        }
        subheader={
          <Stack fontSize={12} fontWeight={400} color={'#47484C'} gap={'8px'}>
            <Typography variant="inherit">{data.professional.role}</Typography>
            <Typography variant="inherit">
              {data.professional.institutionName}
            </Typography>
          </Stack>
        }
      />
      <CardMedia
        image={data.poster.url}
        title={data.professional.fullName}
        sx={{
          height: '100%',
        }}>
        <Box
          position={'absolute'}
          bottom={0}
          ml={'32px'}
          mb={'30px'}
          zIndex={1}
          sx={{
            '&::before': {
              content: '""',
              position: 'absolute',
              left: '-32px',
              backgroundColor: theme.palette.primary.main,
              width: '17.92px',
              height: '56.76px',
            },
            '& > p': {
              maxWidth: '317px',
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          }}>
          <Typography fontSize={20} fontWeight={700} color={'#FFF'}>
            {data.professional?.fullName}
          </Typography>
          <Typography fontSize={18} fontWeight={400} color={'#FFF'}>
            {data.professional?.relatedInstitutions?.fantasyName ??
              data.professional?.institutionName}
          </Typography>
        </Box>
      </CardMedia>
      <CardContent sx={{ display: 'none' }}>
        <Box>
          <Typography
            sx={{
              overflow: 'hidden',
              minHeight: '80px',
              maxHeight: '80px',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              textAlign: 'justify',
              lineHeight: '19.6px',
              color: '#828282',
              fontSize: 12,
            }}>
            {data.professional.profileText}
          </Typography>
          {data?.presentations?.length ? (
            <>
              <Typography fontSize={12} color={'#4F4F4F'} py={2}>
                Estará em:
              </Typography>
              <Stack
                direction={'column'}
                gap={'25px'}
                color={theme.palette.primary.main}
                fontWeight={600}
                fontSize={'12px'}
                lineHeight={'19.6px'}>
                {nextThreeLectures?.map((presentation) => (
                  <Stack
                    key={presentation.id}
                    direction={'row'}
                    alignItems={'center'}
                    gap={'17px'}
                    sx={{
                      '& > div > button': {
                        width: '13px',
                      },
                    }}>
                    <FavoriteButton
                      type="presentation"
                      id={presentation.id}
                      icon="bookmark"
                      iconHeight="22.67px"
                      iconWidth="unset"
                      tooltipPlacement="top"
                    />
                    <Link
                      component={RouterLink}
                      to={(location) => ({
                        ...location,
                        pathname: `programacao?presentationId=${presentation.id}`,
                      })}
                      underline="none"
                      target="_blank">
                      <Typography variant="inherit">
                        {presentation.name}
                      </Typography>
                    </Link>
                  </Stack>
                ))}
              </Stack>
            </>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
}
