// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationCRAResumeGeneralData from './OperationCRAResumeGeneralData';
import OperationCRAResumeReceivables from './OperationCRAResumeReceivables';
import OperationCRAResumeServiceProvider from './OperationCRAResumeServiceProvider';
import OperationCRAResumeTitles from './OperationCRAResumeTitles';

export default function OperationCRAResume({ hashId }) {
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={12} md>
          <OperationCRAResumeGeneralData hashId={hashId} />
        </Grid>
        <Grid item xs={12} md>
          <OperationCRAResumeServiceProvider hashId={hashId} />
        </Grid>
        <Grid item xs={12}>
          <OperationCRAResumeReceivables hashId={hashId} />
        </Grid>
        <Grid item xs={12}>
          <OperationCRAResumeTitles hashId={hashId} />
        </Grid>
      </Grid>
    </Box>
  );
}
