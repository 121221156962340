import qs from 'qs';

import { api, publicApi } from '../../api/api';

/**
 * Public
 *  */
export const getPlansWithPermission = async (operation) => {
  const query = qs.stringify(
    {
      filters: {
        permissions: {
          [operation]: {
            $eq: true,
          },
        },
      },
      sort: ['id:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await publicApi.get(`/plans?${query}`);
  return data;
};

/**
 * Authenticated
 *  */
export const getPlan = async (planId) => {
  const { data } = await api.get(`/plans/${planId}?populate=*`);
  return data;
};

export const getMoreAccess = async (values) => {
  const { data } = await api.post('/subscriptions/getMoreAccess', {
    data: values,
  });
  return data;
};

export const upgradePlans = async (planId) => {
  const query = qs.stringify(
    {
      populate: {
        planUpgradeRules: {
          populate: ['signatureDetails', 'products', 'descriptions'],
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/plans/${planId}/?${query}`);
  return data;
};

export const createSubscription = async (values) => {
  const { data } = await api.post('/subscriptions', values);
  return data;
};

export const cancelSubscription = async () => {
  const { data } = await api.post('/subscriptions/cancel', {});
  return data;
};

export const cancelInvitedUser = async () => {
  const { data } = await api.post('/subscriptions/cancelInvitedUser', {});
  return data;
};

export const findSubscription = async (subscriptionId) => {
  const query = qs.stringify(
    {
      populate: [
        'subscribers',
        'inactiveSubscribers',
        'inactiveSubscribersDetails',
        'inactiveSubscribersDetails.user',
        'invitations',
      ],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/subscriptions/${subscriptionId}?${query}`);
  return data;
};

export const updatePaymentMethod = async (values) => {
  const { id, ...rest } = values;
  const { data } = await api.put(`/subscriptions/${id}`, {
    data: rest,
  });
  return data;
};
