import { ReactNode } from 'react';

import { Close } from '@mui/icons-material';
import {
  DialogProps,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';

export function Dialog(
  props: DialogProps & { children: ReactNode; title?: string },
) {
  return (
    <MuiDialog {...props}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      {props.title && (
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '26.4px',
            textAlign: 'center',
          }}>
          {props.title}
        </Typography>
      )}
      {props.children}
    </MuiDialog>
  );
}
