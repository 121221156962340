import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';

import ChartStatistics from './Chart';
import { StatisticsTable } from './Table';
import { findStatisticsRanking } from '../../../services/ranking';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    color: '#47484C',
    padding: theme.spacing(1, 0),
  },
  boxGraph: {
    border: '1px solid #C8C9CC',
    borderRadius: '5px',
    paddingTop: theme.spacing(3),
    height: 185,
  },
}));

export const Statistics = ({ state, numberCriterias, valueLabel, loading }) => {
  const classes = useStyles();
  const { data, isLoading } = useQuery(['statistics-rankings', state], () =>
    findStatisticsRanking({
      market: state.market.toLowerCase(),
      year: state.year,
      type: state.ranking,
      criteria: state.criteria,
      timeRange: state.timeRange,
    }),
  );

  if (isLoading || loading) return <CircularProgress color="secondary" />;

  const chartData = data.sort(({ year: a }, { year: b }) => a - b);

  const sortedYears = chartData.map(({ year }) => year);

  const dataChart1 = [
    {
      name: 'R$',
      data: chartData.map(({ totalAmount }) => totalAmount),
    },
  ];

  const dataChart2 = [
    {
      name: 'Participação',
      data: chartData.map(({ totalParticipants }) => totalParticipants),
    },
  ];

  const dataChart3 = [
    {
      name: 'Top 10',
      data: chartData.map(({ top10 }) => top10),
    },
    {
      name: 'Top 5',
      data: chartData.map(({ top5 }) => top5),
    },
    {
      name: 'Top 3',
      data: chartData.map(({ top3 }) => top3),
    },
  ];

  return (
    <Box py={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <ChartStatistics
            classes={classes}
            data={dataChart1}
            title="Montante / Participante"
            type="area"
            xaxisCategories={sortedYears}
            formatType={
              valueLabel?.includes('Montante') ? 'currency' : 'integer'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ChartStatistics
            classes={classes}
            data={dataChart2}
            yaxisMax={dataChart2[0].data.reduce(function (a, b) {
              return Math.max(a, b);
            }, -Infinity)}
            title="Número de Participantes"
            type="area"
            xaxisCategories={sortedYears}
            formatType="integer"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ChartStatistics
            classes={classes}
            data={dataChart3}
            title="Participação de Mercado"
            type="line"
            xaxisCategories={sortedYears}
            formatType="percent"
          />
        </Grid>
        <Grid item xs={12}>
          <StatisticsTable
            state={state}
            numberCriterias={numberCriterias}
            valueLabel={valueLabel}
            data={data}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
