import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16.24">
      <path d="M1.64,7.59,7,12.93l5.35-5.34L14,9.24l-7,7-7-7Z"></path>
      <path d="M1.64,0,7,5.34,12.35,0,14,1.64l-7,7-7-7Z"></path>
    </svg>
  );
};

export default ArrowDownIcon;
