import { useEffect, useState } from 'react';

// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { handleLimitDatasetBySubscriptionPlan } from 'src/pages/Operation/utils';

import Grupo3946 from '../../../../../assets/imgs/Grupo3946.svg';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FII_NET_WORTH_DATASET } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  itens: {
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    border: 'none',
  },
  range: {
    borderBottom: '2px solid #FF8211',
  },
}));

export default function OperationFIDCLiabilityPatrimony({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const [select, setSelect] = useState([]);
  const [networth, setNetworth] = useState(true);
  const [outflow, setOutflow] = useState(false);
  const [issue, setIssue] = useState(false);
  const [noGraph, setNoGraph] = useState(false);
  const [range, setRange] = useState(12);
  const [data, setData] = useState({});

  const { loading, error } = useQuery(GET_DETAILS_FII_NET_WORTH_DATASET, {
    variables: {
      filter: {
        operationHashId: hash,
        range,
        select,
      },
    },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo'],
    onCompleted: (data) => {
      const { datasets, labels } = data?.getDetailsFiiNetWorthDataset || {};

      const newData = {
        getDetailsFiiNetWorthDataset: handleLimitDatasetBySubscriptionPlan(
          auth?.user,
          datasets,
          labels,
        ),
      };
      setData(newData);
    },
  });

  useEffect(() => {
    if (!networth && !outflow && !issue) {
      setNoGraph(true);
    }
    if (networth || outflow || issue) {
      setNoGraph(false);
    }
    networth
      ? setSelect((arr) => [...arr, 'NETWORTH'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'NETWORTH')]);
    outflow
      ? setSelect((arr) => [...arr, 'OUTFLOW'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'OUTFLOW')]);
    issue
      ? setSelect((arr) => [...arr, 'ISSUE'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'ISSUE')]);
  }, [outflow, issue, networth, noGraph]);

  if (loading) return null;
  if (error) return null;

  return (
    <Box py={2}>
      <Subtitle title="Patrimônio" />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo']
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo']
        }>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <Chip
              label="Patrimônio Líquido"
              color={networth ? 'secondary' : 'default'}
              variant={networth ? 'default' : 'outlined'}
              onClick={() => setNetworth(!networth)}
              className={classes.itens}
            />
          </Grid>
          <Grid item>
            <Chip
              label="Emissões"
              color={issue ? 'secondary' : 'default'}
              variant={issue ? 'default' : 'outlined'}
              onClick={() => setIssue(!issue)}
              className={classes.itens}
            />
          </Grid>
          <Grid item>
            <Chip
              label="Amortizações"
              color={outflow ? 'secondary' : 'default'}
              variant={outflow ? 'default' : 'outlined'}
              onClick={() => setOutflow(!outflow)}
              className={classes.itens}
            />
          </Grid>
        </Grid>
        <Box py={2}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Typography
                variant="button"
                onClick={() => setRange(12)}
                className={range === 12 ? classes.range : ''}
                style={{ cursor: 'pointer' }}>
                12 meses
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="button"
                onClick={() => setRange(60)}
                className={range === 60 ? classes.range : ''}
                style={{ cursor: 'pointer' }}>
                60 meses
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {!data?.getDetailsFiiNetWorthDataset?.datasets?.length ||
        !data?.getDetailsFiiNetWorthDataset?.labels?.length ? (
          <Box display="flex" justifyContent="center">
            <img src={Grupo3946} alt="sem gráfico" />
          </Box>
        ) : (
          <Bar
            data={{
              labels: data?.getDetailsFiiNetWorthDataset?.labels,
              datasets: data?.getDetailsFiiNetWorthDataset?.datasets,
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      return `${
                        tooltipItem.label
                      }: ${tooltipItem.raw.toLocaleString('pt-BR')}`;
                    },
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value) => {
                      return value.toLocaleString('pt-BR');
                    },
                  },
                },
              },
            }}
            type={'bar'}
          />
        )}
      </If>
    </Box>
  );
}
