// Material ui components
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';

import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_ASSET_DATA_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';
// Global components

export default function OperationFIIAssetData({ hash }) {
  const { auth } = useAuthContext();
  const [data, setData] = useState({
    operationProduct: '',
    operationPropertyType: '',
    operationFinality: '',
  });
  const { loading, error } = useQuery(GET_FII_ASSET_DATA_BY_OPERATION_HASH_ID, {
    variables: { hash },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions['operacoes_fii_ativo'],
    onCompleted: ({ getFiiAssetDataByOperationHashId }) =>
      setData(getFiiAssetDataByOperationHashId),
  });

  if (error) return null;

  return (
    <Box>
      <SubTitle title="Dados" />
      {!auth?.user?.subscription?.plan?.permissions['operacoes_fii_ativo'] ? (
        <Box>
          <PanelLogin />
        </Box>
      ) : (
        <Box py={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <PaperPanelInfo
                info
                title="Ativo"
                value={data?.operationProduct}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <PaperPanelInfo
                info
                title="Tipo de Imóvel"
                value={data?.operationPropertyType}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <PaperPanelInfo
                info
                title="Finalidade"
                value={data?.operationFinality}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <PaperPanelInfo
                info
                title="Tipo de Gestão"
                value={data?.operationTypeOfManager ?? '-'}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
