import { Box, Chip, Grid, Typography } from '@mui/material';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';

type Props = {
  segments: Segment[] | undefined;
  markets: Market[] | undefined;
};

export function SegmentsAndMarkets({ segments, markets }: Props) {
  return (
    <Section>
      <Grid container md={12} rowSpacing={2}>
        <If condition={segments && segments.length}>
          <Grid item xs={12} md={7}>
            <Typography
              sx={(theme) => ({
                fontSize: '20px',
                fontWeight: 600,
                marginBottom: 3.5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                  marginBottom: 2,
                },
              })}>
              Segmentos
            </Typography>
            <Box>
              <Grid container item sx={{ width: 'fit-content' }}>
                {segments?.map((segment) => (
                  <Grid
                    key={segment.id}
                    sx={{
                      margin: '0 8px 8px 0',
                    }}>
                    <Chip
                      sx={{
                        width: '100%',
                      }}
                      variant="outlined"
                      label={segment.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </If>
        <If condition={markets && markets.length}>
          <Grid item xs={12} md={5}>
            <Typography
              sx={(theme) => ({
                fontSize: '20px',
                fontWeight: 600,
                marginBottom: 3.5,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                  marginBottom: 2,
                },
              })}>
              Mercados
            </Typography>
            <Box>
              <Grid
                container
                item
                sx={{
                  height: 'fit-content',
                }}>
                {markets?.map((market) => (
                  <Grid
                    key={market.id}
                    sx={{
                      margin: '0 8px 8px 0',
                      height: 'fit-content',
                    }}>
                    <Chip
                      sx={{
                        width: '100%',
                      }}
                      variant="outlined"
                      label={market.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </If>
      </Grid>
    </Section>
  );
}
