import { Box, Typography } from '@material-ui/core';
import CastIcon from '@material-ui/icons/Cast';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StarsIcon from '@material-ui/icons/Stars';
import UpdateIcon from '@material-ui/icons/Update';
import { format, parse } from 'date-fns';
import { pt } from 'date-fns/locale';

export default function Resume({
  classes,
  startDate,
  endDate,
  duration,
  courseDurationType,
  digitalCertificate,
  courseMethod,
}) {
  return (
    <Box p={2}>
      <Box display="flex">
        <EventNoteIcon color="secondary" />
        <Typography gutterBottom className={classes.resumeStartDate}>
          {startDate
            ? format(parse(startDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy', {
                locale: pt,
              })
            : 'A definir'}
          {endDate
            ? ` a ${format(
                parse(endDate, 'yyyy-MM-dd', new Date()),
                'dd/MM/yyyy',
                { locale: pt },
              )}`
            : ''}
        </Typography>
      </Box>
      {duration && (
        <Box display="flex">
          <ScheduleIcon color="secondary" />
          <Typography gutterBottom className={classes.resumeStartDate}>
            {duration}
          </Typography>
        </Box>
      )}
      {courseDurationType && (
        <Box display="flex">
          <UpdateIcon color="secondary" />
          <Typography gutterBottom className={classes.resumeStartDate}>
            {courseDurationType}
          </Typography>
        </Box>
      )}
      {digitalCertificate !== null && (
        <Box display="flex">
          <StarsIcon color="secondary" />
          <Typography gutterBottom className={classes.resumeStartDate}>
            {digitalCertificate
              ? 'Certificado digital incluso'
              : 'Certificado digital não incluso'}
          </Typography>
        </Box>
      )}
      {courseMethod && (
        <Box display="flex">
          <CastIcon color="secondary" />
          <Typography gutterBottom className={classes.resumeStartDate}>
            {courseMethod}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
