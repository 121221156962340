import { makeStyles } from '@material-ui/core';

export const RadarStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginLeft: '0.5rem',
      marginTop: '1rem',
    },
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  select: {
    fontSize: '1em',
    '& .MuiOutlinedInput-input': {
      padding: '9px 14px',
    },
  },
  filter: {
    padding: theme.spacing(1),
  },
  marketSelected: {
    border: 0,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    '&.MuiChip-root:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiChip-root:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  market: {
    border: 0,
    fontWeight: 500,
    boxShadow: theme.shadows[1],
  },
  radarBox: {
    padding: theme.spacing(4, 1, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1, 0),
    },
  },
  filterBox: {
    padding: theme.spacing(4, 1),
  },
  title: {
    fontSize: '2.125rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    lineHeight: 1.235,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  icon: {
    height: '20px',
    width: '20px',
    margin: theme.spacing(0, 1.5, 0, 3),
  },
  filtersGrid: {
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(2),
  },
  accordion: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
  filterPaper: {
    boxShadow: theme.shadows[2],
  },
  filterTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  filterDivider: {
    height: theme.spacing(0.1),
    backgroundColor: theme.palette.common.black,
    opacity: '20%',
  },
  filterTitleBox: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: '3.8em',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  filterOptionsBox: {
    padding: theme.spacing(2),
  },
  filterOption: {
    fontSize: '0.875rem',
    margin: theme.spacing(0, 1),
  },
  indicatorTitle: {
    fontSize: '1.2em',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
  },
  indicatorContainer: {
    margin: theme.spacing(2, 0),
  },
  indicatorGrid: {
    margin: theme.spacing(0.5, 0.5),
  },
  checkbox: {
    '&': {
      width: '16px',
      height: '16px',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.common.white,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '21.34px',
    },
  },
  table: {
    width: '100%',
  },
  firstHeaderCell: {
    minWidth: '10em',
  },
  headerCell: {
    fontSize: '0.8em',
    opacity: '50%',
    padding: theme.spacing(1.5, 0),
    minWidth: '5em',
  },
  tableCell: {
    fontSize: '0.8em',
    padding: theme.spacing(2, 0, 0, 0),
    borderBottom: 'none',
  },
  firstCell: {
    fontSize: '0.8em',
    textAlign: 'left',
    padding: theme.spacing(2, 0, 0, 4),
    borderBottom: 'none',
  },
  totalCell: {
    fontSize: '0.8em',
    fontWeight: 550,
    margin: theme.spacing(0, 0, 2, 0),
    borderBottom: 'none',
  },
  firstTotalCell: {
    fontSize: '0.8em',
    fontWeight: 550,
    textAlign: 'left',
    padding: theme.spacing(1, 1, 1, 4),
    margin: theme.spacing(0, 0, 2, 0),
    borderBottom: 'none',
  },
  tableBox: {
    margin: theme.spacing(4, 0),
  },
  exportIcon: {
    height: '15px',
    width: '15px',
    margin: theme.spacing(0, 1.5, 0, 0.5),
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(4),
  },
  exportButton: {
    border: 'none',
    fontSize: '0.9em',
  },
  alertText: {
    fontWeight: 600,
    fontSize: '1.2em',
    marginRight: '1em',
  },
  leftArrowIcon: {
    width: '6em',
    margin: theme.spacing(1, 0),
  },
  noPermissionDialog: {
    height: '35em',
    width: '100%',
    textAlign: 'center',
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  notificationText: {
    fontWeight: 600,
    fontSize: '1.2em',
  },
  paperPopover: {
    width: '450px',
    backgroundColor: '#F9F7F7',
    padding: theme.spacing(5),
  },
  titleDialog: {
    fontWeight: 600,
    lineHeight: '23px',
  },
  subtitleDialog: {
    margin: theme.spacing(2, 0),
    lineHeight: '20px',
  },
  listFavoriteRadar: {
    padding: 0,
    width: '100%',
  },
  titleListFavoriteRadar: {
    paddingLeft: '1.5rem',
    '& .MuiTypography-body1': {
      fontWeight: 700,
    },
  },
  selectedTitleListFavoriteRadar: {
    paddingLeft: '1.5rem',
    '& .MuiTypography-body1': {
      fontWeight: 700,
    },
  },
  alert: {
    marginTop: '1rem',
  },
  notificationSubTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '17px',
    marginTop: '1rem',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  customTooltip: {
    padding: '1rem',
    fontSize: '12px',
  },
  drawerPaper: {
    backgroundColor: 'transparent',
  },
  radarFavoriteBox: {
    padding: theme.spacing(4, 1, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1, 0),
    },
  },
  gridFilters: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  gridRadarFavorite: {
    display: 'unset',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  addRadarButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 10,
      padding: '6px',
      borderRadius: '5px',
      marginTop: theme.spacing(1),
    },
  },
  gridExportButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
    },
  },
  optionsBox: {
    '& ul': {
      padding: '27px 0 14px 0',
    },
    '& ul > li': {
      padding: '12px',
      paddingLeft: '22px',
      marginBottom: '24px',
    },
    '& ul .MuiCheckbox-root': {
      margin: '2px 15px 2px 0',
    },
    '& li': {
      padding: 0,
      paddingLeft: '22px',
      marginBottom: '16px',
    },
    '& li:not(:first-child)': {
      paddingLeft: '54px',
      marginBottom: '12px',
    },
    '& li:last-child': {
      marginBottom: '16px',
    },
  },
  serviceProvicersOptions: {
    '&[data-focus="true"]': {
      backgroundColor: '#CCC',
      borderColor: 'transparent',
    },
  },
}));
