import { Grid, Skeleton } from '@mui/material';

/**
 *
 * @param skeletonNumber
 * @param width
 * @param height
 * @param variant text|rect|circle
 * @param animation
 * @param classGrid
 * @param xs
 * @param sm
 * @param md
 * @param lg
 * @return {Grid[]}
 * @constructor
 */

type Props = {
  skeletonNumber: number;
  width: string;
  height: string;
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular' | undefined;
  animation?: false | 'pulse' | 'wave' | undefined;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  borderRadius?: string;
};
export default function SkeletonGrid({
  skeletonNumber,
  width,
  height,
  variant = 'rectangular',
  animation = 'wave',
  xs = 12,
  sm = 6,
  md = 4,
  lg = 4,
  borderRadius,
}: Props): any {
  return Array(skeletonNumber)
    .fill(1)
    .map((currMock, index) => (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} key={index}>
        <Skeleton
          variant={variant}
          animation={animation}
          width={width}
          height={height}
          key={index}
          sx={{
            borderRadius,
          }}
        />
      </Grid>
    ));
}
