import styled from 'styled-components';

export const Banner = styled.div`
  width: 100%;
  height: 72px;
  background-color: #282828;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageWrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  font-weight: 700;
  font-size: clamp(14px, 2vw, 1rem);
`;
