import { useState } from 'react';

import {
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import { CTAPopUp } from 'src/types/advertisement-pop-up';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 499px) {
    display: block;
    text-align: center;
  }
`;

export const Content = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  width: 1000px;
  height: 700px;

  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 700px;
    height: calc(700px * 0.7);
  }

  @media screen and (max-width: 499px) {
    width: 300px;
    height: calc(300px * 1.4 * 2);
    margin: 0 auto;
  }
`;

const SideWrapper = styled.div<{ bgColor?: string; imgURL?: string }>`
  display: inline-block;
  width: 50%;
  height: 100%;
  background-color: ${(props: any) => props.bgColor || '#FFFFFF'};
  vertical-align: top;
  background-image: ${(props: any) => `url(${props.imgURL ?? 'none'})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:nth-child(2) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  &:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  @media screen and (min-width: 500px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 499px) {
    width: 100%;
    height: 50%;
    overflow-y: unset;

    &:nth-child(2) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      height: auto;
    }
  }
`;

type ButtonCTAProps = CTAPopUp & Omit<ButtonProps, 'type'>;

const ButtonCTALink = (
  props: ButtonCTAProps & { onClick: () => void; loading?: boolean },
) => {
  const {
    backgroundColor,
    labelColor,
    actionURL,
    label,
    onClick,
    ...restProps
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      {...(restProps as any)}
      sx={{
        backgroundColor: backgroundColor,
        color: labelColor,
        border: `1px solid ${labelColor}`,
        padding: '5px 10px',
        ':hover': {
          backgroundColor: labelColor,
          color: backgroundColor,
          borderColor: `${backgroundColor} !important`,
        },
      }}
      onClick={async () => {
        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}>
      {isLoading ? <CircularProgress /> : label}
    </Button>
  );
};

const ButtonCTANotInterested = (
  props: CTAPopUp & {
    onChange?: (checked: boolean) => void;
  },
) => {
  const [noShowCheckInput, setNoShowCheckInput] = useState(false);

  const handleChange = () => {
    if (props.onChange) {
      props.onChange(!noShowCheckInput);
    }
    setNoShowCheckInput(!noShowCheckInput);
  };

  return (
    <Typography
      color={props.labelColor}
      sx={{
        backgroundColor: props.backgroundColor,
        fontSize: '12px',
        cursor: 'pointer',
        textAlign: 'center',
      }}
      onClick={() => handleChange()}>
      <Checkbox
        sx={{ fontSize: '12px' }}
        checked={noShowCheckInput}
        onChange={() => handleChange()}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {props.label}
    </Typography>
  );
};

export const Styled = {
  Wrapper,
  Content,
  SideWrapper,
  ButtonCTANotInterested,
  ButtonCTALink,
};
