import { Moving } from '@mui/icons-material';
import { Box, Divider, MenuItem, Typography } from '@mui/material';

type SearchItem = {
  name: string;
  slug: string;
  modelId: number;
  type: string;
  market: string;
};

export default function FeaturedSearchsMenu({
  data,
  onChange,
}: {
  data: any;
  onChange: (
    name: string,
    slug: string,
    hashId: number,
    market: string,
    sub: string,
  ) => void;
}) {
  const FeaturedSearchItem = ({
    search,
    onChange,
  }: {
    search: SearchItem;
    onChange: (
      name: string,
      slug: string,
      hashId: number,
      market: string,
      sub: string,
    ) => void;
  }) => {
    return (
      <MenuItem
        sx={{
          color: '#333',
          fontSize: '14px',
          paddingLeft: 0,
        }}
        onMouseDown={() => {
          onChange(
            search.name,
            search.slug,
            search.modelId,
            search.market,
            search.type,
          );
        }}>
        <Moving
          sx={{
            fontSize: '18px',
            color: '#333',
            marginRight: '16px',
          }}
        />
        {search.type === 'operation'
          ? `${search.name} - ${search.market?.toUpperCase()}`
          : search.name}
      </MenuItem>
    );
  };

  return (
    <Box
      sx={{
        padding: '0 8px',
      }}>
      <Typography
        sx={{
          color: '#4F4F4F',
          fontSize: '12px',
          fontWeight: 600,
          marginBottom: '8px',
        }}>
        Principais buscas
      </Typography>
      <Box>
        {data?.institution?.map((search: SearchItem) => (
          <FeaturedSearchItem
            key={`${search.modelId}-${search.type}`}
            search={search}
            onChange={onChange}
          />
        ))}
        <Box marginBottom={1}>
          <Typography
            fontSize={12}
            fontWeight={600}
            color={'#BDBDBD'}
            textAlign={'end'}>
            Instituições
          </Typography>
          <Divider sx={{ margin: '4px 0' }} />
        </Box>
        {data?.operation?.map((search: SearchItem) => (
          <FeaturedSearchItem
            key={`${search.modelId}-${search.type}`}
            search={search}
            onChange={onChange}
          />
        ))}
        <Box marginBottom={1}>
          <Typography
            fontSize={12}
            fontWeight={600}
            color={'#BDBDBD'}
            textAlign={'end'}>
            Operações
          </Typography>
          <Divider sx={{ margin: '4px 0' }} />
        </Box>
        {data?.professional?.map((search: SearchItem) => (
          <FeaturedSearchItem
            key={`${search.modelId}-${search.type}`}
            search={search}
            onChange={onChange}
          />
        ))}
        <Box marginBottom={1}>
          <Typography
            fontSize={12}
            fontWeight={600}
            color={'#BDBDBD'}
            textAlign={'end'}>
            Profissionais
          </Typography>
          <Divider sx={{ margin: '4px 0' }} />
        </Box>
      </Box>
    </Box>
  );
}
