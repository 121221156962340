import { ChartsList } from '../../types';

const categoriesDatesMothly: string[] = [
  '01/19',
  '02/19',
  '03/19',
  '04/19',
  '05/19',
  '06/19',
  '07/19',
  '08/19',
  '09/19',
  '10/19',
  '11/19',
  '12/19',
  '01/20',
  '02/20',
  '03/20',
  '04/20',
  '05/20',
  '06/20',
  '07/20',
  '08/20',
  '09/20',
  '10/20',
  '11/20',
  '12/20',
  '01/21',
  '02/21',
  '03/21',
  '04/21',
  '05/21',
  '06/21',
  '07/21',
  '08/21',
  '09/21',
  '10/21',
  '11/21',
  '12/21',
  '01/22',
  '02/22',
  '03/22',
  '04/22',
  '05/22',
  '06/22',
  '07/22',
  '08/22',
  '09/22',
  '10/22',
  '11/22',
  '12/22',
  '01/23',
  '02/23',
  '03/23',
  '04/23',
  '05/23',
  '06/23',
  '07/23',
  '08/23',
  '09/23',
  '10/23',
  '11/23',
  '12/23',
];

const categoriesDatesYearly: string[] = [
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
];
export const crsPrimaryMarketCharts: ChartsList = {
  emissionsCri: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante emitido',
        type: 'column',
        data: [
          22219717060.03, 17896041890.82, 41304789051.48, 51524952821.7,
          50906263946.11,
        ],
      },
      {
        name: 'Núm. Operações',
        type: 'line',
        data: [247, 275, 476, 452, 392],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByCreditRisk: {
    chartType: 'column',
    data: [
      {
        name: 'Concentrado',
        data: [
          19268094150.38, 14819026185.77, 32230834214.22, 42124889286.2,
          45002207858.26,
        ],
      },
      {
        name: 'Pulverizado',
        data: [
          2951622909.65, 3077015705.05, 9073954837.26, 9400063535.5,
          5904056087.85,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByDebtor: {
    chartType: 'column',
    data: [
      {
        name: 'Pessoa Jurídica (Setor Público)',
        data: [25171000, 120000000, 66586160, 1674971000, 51279000],
      },
      {
        name: 'Pessoa Jurídica',
        data: [
          20051420594.13, 15502191352.42, 36048518223.22, 47139841246.38,
          44587928858.26,
        ],
      },
      {
        name: 'Pessoa Física',
        data: [
          1912413547.96, 2101852581.03, 2900128342.86, 2059094660.24,
          3649919093.46,
        ],
      },
      {
        name: 'Multidevedor',
        data: [
          105139000, 33179957.37, 2289556325.4, 562723915.08, 2617136994.39,
        ],
      },
      {
        name: 'Gov. Estadual',
        data: [125572917.94, 138818000, 0, 88322000, 0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByRemuneration: {
    chartType: 'column',
    data: [
      {
        name: 'CDI',
        data: [
          11332147028.04, 5274191100.07, 6415968999.74, 20152116076.0,
          21994092706.13,
        ],
      },
      { name: 'IGP-DI', data: [114989000.0, 44000000.0, 0.0, 0.0, 0.0] },
      {
        name: 'IGP-M',
        data: [
          1961888121.71, 1578247568.78, 414094853.0, 65207437.64, 44776000.0,
        ],
      },
      { name: 'INPC', data: [24000000.0, 49658598.81, 104450000.0, 0.0, 0.0] },
      {
        name: 'IPCA',
        data: [
          8497592910.28, 10480226754.48, 33071791736.74, 31082629308.06,
          23895597147.05,
        ],
      },
      {
        name: 'Taxa Fixa',
        data: [
          289100000.0, 349417868.68, 992807462.0, 185000000.0, 3907560092.93,
        ],
      },
      {
        name: 'INCC-DI',
        data: [0.0, 89800000.0, 140620000.0, 0.0, 1064238000.0],
      },
      { name: 'INCC-M', data: [0.0, 30500000.0, 165056000.0, 0.0, 0.0] },
      { name: 'TR', data: [0.0, 0.0, 0.0, 40000000.0, 0.0] },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCriByDeadlineRange: {
    chartType: 'column',
    data: [
      {
        name: 'até 2 anos',
        data: [
          332621000.0, 715912000.0, 242792560.0, 379246000.0, 1537587000.0,
        ],
      },
      {
        name: 'de 2 a 5 anos',
        data: [
          5506729729.64, 5292650414.34, 8414137462.4, 19094450146.0,
          22695296967.5,
        ],
      },
      {
        name: 'de 5 a 10 anos',
        data: [
          7592190129.99, 5760127534.48, 16839400837.88, 21539202779.02,
          16340064035.46,
        ],
      },
      {
        name: 'acima de 10 anos',
        data: [
          8788176200.4, 6298351942.0, 15808458191.2, 10823532896.68,
          10373315943.15,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  operationsCriByAssetBacking: {
    chartType: 'column',
    data: [
      { name: 'Aluguel', data: [47.0, 57.0, 19.0, 61.0, 48.0] },
      {
        name: 'Crédito Imobiliário',
        data: [196.0, 210.0, 454.0, 312.0, 250.0],
      },
      { name: 'Multiclasse', data: [4.0, 4.0, 2.0, 9.0, 3.0] },
      { name: 'Crédito Pessoal', data: [0.0, 4.0, 1.0, 7.0, 13.0] },
      { name: 'Reembolso', data: [0.0, 0.0, 0.0, 63.0, 78.0] },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    monetary: false,
  },
  emissionsCriByRealEstateSegment: {
    chartType: 'column',
    data: [
      {
        name: 'Apartamentos e Casas',
        data: [5850559496.12, 10197927383.72, 12819921760.88, 13691692118.98],
      },
      {
        name: 'Clínicas e Hospitais',
        data: [225390019.46, 1606500000.0, 5039990000.0, 3375125000.0],
      },
      {
        name: 'Educacional',
        data: [98102094.0, 129595435.0, 1331300000.0, 40000000.0],
      },
      {
        name: 'Hotelaria',
        data: [608940000.0, 414861000.0, 727301000.0, 1980705000.0],
      },
      {
        name: 'Infraestrutura',
        data: [104844762.19, 1165583260.0, 1647075263.92, 1972086000.0],
      },
      {
        name: 'Lajes Corporativas',
        data: [2532949305.47, 4958398649.31, 8433284000.0, 11502954229.4],
      },
      {
        name: 'Logístico e Industrial',
        data: [1379744599.95, 3557208140.0, 8321030928.31, 5966674178.0],
      },
      {
        name: 'Loteamento',
        data: [1599113344.04, 6772047382.54, 2518580428.47, 3322433227.26],
      },
      {
        name: 'Multipropriedade',
        data: [632913214.91, 2243568632.91, 592520838.1, 24934000.0],
      },
      {
        name: 'Propriedade Rural',
        data: [779500000.0, 60000000.0, 1169188000.0, 75000000.0],
      },
      {
        name: 'Varejo - Shoppings, Lojas etc',
        data: [4083985054.68, 8280097170.0, 8159147128.63, 8654086192.47],
      },
      {
        name: 'Cemitérios e Funerárias',
        data: [0.0, 100000000.0, 90000000.0, 0.0],
      },
      {
        name: 'Multisegmento',
        data: [0.0, 1819001998.0, 35613473.39, 180574000.0],
      },
      { name: 'Centros Esportivos', data: [0.0, 0.0, 640000000.0, 0.0] },
      { name: 'Parques', data: [0.0, 0.0, 0.0, 120000000.0] },
    ],
    categories: ['2020', '2021', '2022', '2023'],
    numeric: true,
  },
  investorsProfileCri: {
    chartType: 'column',
    data: [
      {
        name: 'Demais instituições financeiras',
        data: [323586000.0, 91583000.0, 144350000.0, 1397045000.0, 251045000.0],
      },
      {
        name: 'Demais pessoas jurídicas',
        data: [141509334.4, 86706075.35, 159103550.0, 295048490.0, 175860466.0],
      },
      {
        name: 'Fundos de investimento',
        data: [
          8144649610.43, 6813978955.43, 237933000.0, 10807554107.86,
          4606796460.31,
        ],
      },
      {
        name: 'Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio',
        data: [
          785389003.41, 830693001.0, 1523074000.0, 4050848000.0, 9103716000.0,
        ],
      },
      {
        name: 'Instituições financeiras ligadas a(os) cedente(s) dos créditos',
        data: [30000000.0, 0.0, 0.0, 0.0, 37380000.0],
      },
      {
        name: 'Instituições Intermediárias participantes do consórcio de distribuição',
        data: [
          1622014344.11, 837215000.0, 1093259677.0, 5344693263.92, 1898669000.0,
        ],
      },
      {
        name: 'Investidores estrangeiros',
        data: [250000000.0, 2900000.0, 0.0, 2000000.0, 640535000.0],
      },
      {
        name: 'Pessoas Físicas',
        data: [
          3444816535.0, 1251909775.15, 2493176000.0, 6896036249.0,
          13409323297.7,
        ],
      },
      {
        name: 'Pessoas (físicas ou jurídicas) ligadas ao(s) cendente(s) dos créditos',
        data: [10177232.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Clubes de investimento',
        data: [0.0, 28350000.0, 0.0, 0.0, 15000000.0],
      },
      {
        name: 'Pessoas (físicas ou jurídicas) ligadas ao(s) originador(es) dos créditos',
        data: [0.0, 0.0, 3000.0, 109467000.0, 0.0],
      },
      {
        name: 'Companhias seguradoras',
        data: [0.0, 0.0, 0.0, 115000000.0, 15816000.0],
      },
      {
        name: 'Entidades de previdência privada',
        data: [0.0, 0.0, 0.0, 129146000.0, 52750000.0],
      },
      {
        name: 'Instituições financeiras Bancos Comerciais',
        data: [0.0, 0.0, 0.0, 20000000.0, 51020000.0],
      },
      {
        name: 'Instituições financeiras ligadas a(os) originador(es) dos créditos',
        data: [0.0, 0.0, 0.0, 981966000.0, 0.0],
      },
      {
        name: 'Demais pessoas jurídicas ligadas à emissora e/ou aos participantes do consórcio',
        data: [0.0, 0.0, 0.0, 0.0, 50002000.0],
      },
      { name: 'Outros', data: [0.0, 0.0, 0.0, 0.0, 5864000.0] },
      {
        name: 'Sócios, administradores, empregados, propostos, e demais pessoas ligadas à emissora e/ou aos participantes do consórcio',
        data: [0.0, 0.0, 0.0, 0.0, 82252000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCra: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Emitido',
        type: 'column',
        data: [
          12440784075.48, 15937331000, 25371230000, 44877457855, 43589737726.29,
        ],
      },
      {
        name: 'Núm. Operações',
        type: 'line',
        data: [69, 67, 127, 242, 176],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByCreditRisk: {
    chartType: 'column',
    data: [
      {
        name: 'Concentrado',
        data: [
          10589975960.48, 13579720000, 22805896000, 43520762855, 42556084726.29,
        ],
      },
      {
        name: 'Pulverizado',
        data: [1850808115, 2357611000, 2565334000, 1356695000, 1033653000],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByDebtor: {
    chartType: 'column',
    data: [
      {
        name: 'Multidevedor',
        data: [563772984.0, 0.0, 1038590000.0, 381715000.0, 304389000.0],
      },
      {
        name: 'Pessoa Física',
        data: [
          1189030000.0, 2077338000.0, 1708189000.0, 1808668105.0, 1261439000.0,
        ],
      },
      {
        name: 'Pessoa Jurídica',
        data: [
          10687981091.48, 13859993000.0, 22624451000.0, 42687074750.0,
          42023909726.29,
        ],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  emissionsCraByBallastContract: {
    chartType: 'pie',
    data: [
      3.431241464198869, 0.7226471560300674, 0.2959412162789799,
      15.672910477664084, 0.5078924800836209, 40.343704544461254,
      0.0460108297185366, 26.642280054361844, 11.617622092150665,
      0.71974968505208,
    ],
    categories: [
      'CDCA',
      'Contrato de Fornecimento',
      'Contrato Take or pay',
      'CPR Financeira',
      'CPR Financeira, Duplicatas',
      'Debêntures',
      'Duplicatas',
      'Letras Financeiras',
      'Nota Comercial',
      'Nota Promissória',
    ],
  },
  emissionsCraByRemuneration: {
    chartType: 'column',
    data: [
      {
        name: 'CDI',
        data: [
          8060174075.48, 2887592000.0, 4614541000.0, 19046221884.0,
          25813196726.29,
        ],
      },
      {
        name: 'IPCA',
        data: [
          3616156000.0, 11328894000.0, 19591680000.0, 25542185166.0,
          8528891000.0,
        ],
      },
      {
        name: 'Taxa Fixa',
        data: [
          764454000.0, 1720845000.0, 1165009000.0, 44750000.0, 7597354000.0,
        ],
      },
      { name: 'PTAX800', data: [0.0, 0.0, 0.0, 244300805.0, 1650296000.0] },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  operationsCraBySegment: {
    chartType: 'column',
    data: [
      { name: 'Atividades Pecuárias', data: [5.0, 9.0, 14.0, 25.0, 16.0] },
      { name: 'Fibras', data: [4.0, 3.0, 6.0, 9.0, 7.0] },
      { name: 'Frutas e Legumes', data: [1.0, 4.0, 1.0, 4.0, 4.0] },
      { name: 'Grãos', data: [5.0, 10.0, 21.0, 35.0, 38.0] },
      { name: 'Insumos Agrícolas', data: [22.0, 23.0, 35.0, 50.0, 39.0] },
      {
        name: 'Máquinas Agrícolas e Equipamentos',
        data: [3.0, 2.0, 1.0, 2.0, 5.0],
      },
      { name: 'Multiclasse', data: [1.0, 1.0, 3.0, 0.0, 3.0] },
      { name: 'Serviços Técnicos', data: [1.0, 0.0, 2.0, 1.0, 0.0] },
      { name: 'Sucroenergético', data: [23.0, 10.0, 28.0, 39.0, 23.0] },
      { name: 'Tabaco', data: [1.0, 0.0, 1.0, 1.0, 0.0] },
      { name: 'Diversos', data: [0.0, 2.0, 10.0, 71.0, 32.0] },
      { name: 'Serviços Logísticos', data: [0.0, 2.0, 3.0, 3.0, 6.0] },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    monetary: false,
  },
  emissionsCraByEsgCertificate: {
    chartType: 'column',
    data: [
      {
        name: 'GBP',
        data: [
          40412000.0, 841317000.0, 2217043000.0, 4282000000.0, 196959571.29,
        ],
      },
      {
        name: 'Sem certificação',
        data: [
          12400372075.48, 15071014000.0, 21997343000.0, 39895457855.0,
          40831778155.0,
        ],
      },
      {
        name: 'CBI',
        data: [0.0, 25000000.0, 8000000.0, 500000000.0, 1550000000.0],
      },
      { name: 'SLBP', data: [0.0, 0.0, 1148844000.0, 200000000.0, 0.0] },
      {
        name: 'Impacto Socioambiental',
        data: [0.0, 0.0, 0.0, 0.0, 11000000.0],
      },
      {
        name: 'Mais de um princípio',
        data: [0.0, 0.0, 0.0, 0.0, 1000000000.0],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
  investorsProfileCra: {
    chartType: 'pie',
    data: [
      0.0751442964013931, 0.00034047716135509893, 1.8976001142965053,
      2.0118711657746586, 0.23692029256120348, 10.605977423385522,
      10.187570112905943, 6.602562719707761, 0.3116872198671958,
      67.95639215740066, 0.02960670968305208, 0.08432731085475309,
    ],
    categories: [
      'Clubes de investimento',
      'Companhias seguradoras',
      'Demais instituições financeiras',
      'Demais pessoas jurídicas',
      'Demais pessoas jurídicas ligadas à emissora e/ou aos participantes do consórcio',
      'Fundos de investimento',
      'Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio',
      'Instituições Intermediárias participantes do consórcio de distribuição',
      'Outros',
      'Pessoas Físicas',
      'Pessoas (físicas ou jurídicas) ligadas ao(s) originador(es) dos créditos',
      'Sócios, administradores, empregados, propostos, e demais pessoas ligadas à emissora e/ou aos participantes do consórcio',
    ],
  },
  emissionsCraByDeadlineRange: {
    chartType: 'column',
    data: [
      {
        name: '0.0',
        data: [
          975572368.48, 2086962000.0, 1531932000.0, 3145367300.0, 1042383000.0,
        ],
      },
      {
        name: '25.0',
        data: [
          4026267707.0, 2659851000.0, 3154449000.0, 7385097640.0, 8645467000.0,
        ],
      },
      {
        name: '50.0',
        data: [
          3328096000.0, 3942401000.0, 8430152000.0, 13420206750.0,
          12387654949.29,
        ],
      },
      {
        name: '75.0',
        data: [
          2195000000.0, 2069426000.0, 5666758000.0, 6962879165.0, 6698439777.0,
        ],
      },
      {
        name: '100.0',
        data: [
          1915848000.0, 5178691000.0, 5961897000.0, 8858409000.0, 13834503000.0,
        ],
      },
      {
        name: '175.0',
        data: [0.0, 0.0, 626042000.0, 2480498000.0, 881290000.0],
      },
      { name: '125.0', data: [0.0, 0.0, 0.0, 2500000000.0, 100000000.0] },
      { name: '225.0', data: [0.0, 0.0, 0.0, 125000000.0, 0.0] },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
  },
};

export const crsSecondaryMarketCharts: ChartsList = {
  craNegotiations: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Negociado',
        type: 'column',
        data: [
          15392594451.49, 16348469811.74, 16159908511.19, 22456899958.18,
          46376475001.91,
        ],
      },
      {
        name: 'Número de negócios',
        type: 'line',
        data: [79823, 126531, 161629, 181372, 469414],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    stacked: false,
  },

  criNegotiations: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Negociado',
        type: 'column',
        data: [
          14125432528.5, 1649361113.12, 40172559456.14, 72930225644.16,
          80616006620.13,
        ],
      },
      {
        name: 'Número de negócios',
        type: 'line',
        data: [61438, 73753, 103730, 179969, 344287],
      },
    ],
    categories: categoriesDatesYearly,
    numeric: true,
    stacked: false,
  },
};

export const crsStockCharts: ChartsList = {
  stockCra: {
    chartType: 'column',
    data: [
      {
        name: 'Soma de saldo devedor',
        data: [47212472624.54, 64150240486.04, 97688343958.02, 120487192613.02],
      },
    ],
    categories: ['12/20', '12/21', '12/22', '12/23'],
    numeric: true,
    stacked: false,
  },
  stockCri: {
    chartType: 'column',
    data: [
      {
        name: 'Soma de saldo devedor',
        data: [
          73815116345.37, 83015304136.99, 83758638319.36, 107366592312.81,
          145803938180.65, 163604207784.83,
        ],
      },
    ],
    categories: ['12/18', '12/19', '12/20', '12/21', '12/22', '12/23'],
    numeric: true,
    stacked: false,
  },
};
