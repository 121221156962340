// Material ui components
import { useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Global Components
import PaperPanelLiability from '../PaperPanelLiability';
import { RatingOperationModal } from '../RatingOperationModal';

// Styles
const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    width: '100%',
    minWidth: '900px',
    padding: theme.spacing(3),
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
    marginTop: '1rem',
  },
  serie: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  content: {
    padding: theme.spacing(2),
  },
  verticalDivider: {
    width: '2px',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(#ccc 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'right',
    backgroundSize: '2px 26px',
    backgroundRepeat: 'repeat-y',
  },
  centerGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2.5rem',
  },
}));

export default function PanelCriLiabilityTitle({
  id,
  classTitle,
  series,
  emissionDate,
  amountIssued,
  quantityIssued,
  remuneration,
  riskClassification,
  cetipCode,
  term,
  debitBalance,
  debitBalanceDate,
  offerType,
  unitValueIssued,
  leader,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <Paper elevation={2} square className={classes.paper}>
      <Grid container>
        <Grid item xs container alignItems="center">
          <Typography variant="h6" className={classes.bold}>
            {classTitle}
          </Typography>
          <Typography
            variant="h6"
            className={classes.bold}
            style={{ marginLeft: '3rem' }}>
            Série:
          </Typography>
          <Chip
            color="secondary"
            size="small"
            label={series}
            className={classes.serie}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-evenly" className={classes.content}>
        <Grid item xs>
          <Typography className={classes.title}>Emissão</Typography>
          <PaperPanelLiability title="Data" value={emissionDate} />
          <PaperPanelLiability
            title="Montante"
            value={amountIssued}
            currency={true}
          />
          <PaperPanelLiability
            title="Quantidade"
            value={quantityIssued}
            decimal={true}
          />
          <PaperPanelLiability
            title="Valor Unitário"
            value={unitValueIssued}
            currency={true}
          />
        </Grid>
        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className={classes.verticalDivider}
        />
        <Grid item xs className={classes.centerGrid}>
          <Box>
            <PaperPanelLiability title="Cód. Cetip" value={cetipCode} />
            <PaperPanelLiability title="Prazo" value={term} />
            <PaperPanelLiability title="Remuneração" value={remuneration} />
            <PaperPanelLiability
              title="Classificação de Risco"
              value={riskClassification}
            />
            <Button
              color="secondary"
              onClick={() => setOpenModal((prev) => !prev)}
              style={{ padding: 0 }}>
              VER HISTÓRICO
            </Button>
          </Box>
        </Grid>
        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className={classes.verticalDivider}
        />
        <Grid item xs style={{ padding: '2.5rem 1rem 0' }}>
          <PaperPanelLiability
            title="Saldo Devedor em:"
            value={debitBalance}
            currency={true}
            date={debitBalanceDate}
          />
          <PaperPanelLiability title="Tipo de Oferta" value={offerType} />
          <PaperPanelLiability
            title="Líder de Distribuição"
            value={leader?.name ?? 'Líder não disponível'}
            onClick={
              leader?.id
                ? () => {
                    history.push(`/instituicao/${leader?.slug}/${leader?.id}`);
                  }
                : null
            }
          />
        </Grid>
      </Grid>
      <RatingOperationModal
        open={openModal}
        onClose={handleModalClose}
        securityId={id}
      />
    </Paper>
  );
}
