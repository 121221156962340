import { ChartsList } from '../../types';

export const fiiPrimaryMarketCharts: ChartsList = {
  emissionsFII: {
    chartType: 'column',
    data: [
      {
        name: 'Montante emitido',
        data: [
          13400000000, 40910000000, 48110000000, 51390000000, 37090000000,
          34110000000,
        ],
      },
    ],
    categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
    numeric: true,
    stacked: false,
  },
  variableIncomeFII: {
    chartType: 'column',
    data: [
      {
        name: 'Imóvel',
        data: [
          9214415929.32, 27469518981.3, 30785216098.23, 23150178382.18,
          13475623222.52, 13214587372.43,
        ],
      },
      {
        name: 'Imóvel, Renda fixa',
        data: [
          67226800, 29748846.36, 770528.61, 193095796.96, 63856514.42,
          6017674.8,
        ],
      },
      {
        name: 'Renda fixa',
        data: [
          2982683897.55, 9178237200.84, 10952887153.33, 20813346101.32,
          12718465469.63, 6804231736.6,
        ],
      },
      {
        name: 'Renda fixa, Renda variável',
        data: [
          110567179.43, 698023083.9, 916820766.7, 979880784.2, 855364967.95,
          1947765334.11,
        ],
      },
      {
        name: 'Renda variável',
        data: [
          1036339758.28, 3534049936.94, 5453137364.57, 3869718725.3,
          3228484276.28, 5721713020.02,
        ],
      },
      {
        name: 'FIAGRO',
        data: [0, 0, 0, 2179226533.7, 6673152928.94, 6252948473.67],
      },
      {
        name: 'Imóvel, Renda variável',
        data: [0, 0, 0, 205466092.95, 75783505.19, 157840225],
      },
    ],
    numeric: true,
    stacked: true,
    categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
  },
  emissionsByPropertyTypeFII: {
    chartType: 'column',
    data: [
      {
        name: 'Agências, Especial',
        data: [331000.0, 207000.0, 770528.61, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escolar',
        data: [
          182274062.53, 1038837036.97, 1065199960.8, 35356305.72, 32679676.13,
          0.0,
        ],
      },
      {
        name: 'Escritórios',
        data: [
          514659263.23, 6878793472.06, 8208059538.21, 4021174327.46,
          1153238403.83, 211788705.33,
        ],
      },
      {
        name: 'Escritórios, Estacionamento',
        data: [97788948.11, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Fazenda, Residencial',
        data: [1001689.04, 0.0, 0.0, 8441707.84, 47625946.38, 0.0],
      },
      {
        name: 'Escritórios, Hospedagem, Residencial, Varejo',
        data: [
          175232725.49, 97865374.78, 228055758.08, 97145258.31, 39283036.8,
          180729441.4,
        ],
      },
      {
        name: 'Escritórios, Hospedagem, Varejo',
        data: [74442375.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Industrial',
        data: [29458.47, 32363162.7, 34622500.0, 40333094.84, 10842309.11, 0.0],
      },
      {
        name: 'Escritórios, Industrial, Varejo, Varejo - Lojas Individuais',
        data: [2363795.3, 17053353.9, 2461382.9, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Residencial',
        data: [
          340363834.25, 331380466.71, 746118291.15, 241946390.21, 283581403.64,
          0.0,
        ],
      },
      {
        name: 'Escritórios, Residencial, Varejo - Lojas Individuais',
        data: [31934.65, 10000000.0, 87198400.0, 71906841.06, 1954185.03, 0.0],
      },
      {
        name: 'Escritórios, Varejo',
        data: [3900853.6, 18072124.52, 0.0, 65709804.46, 43982089.3, 0.0],
      },
      {
        name: 'Escritórios, Varejo - Lojas Individuais',
        data: [51782000.0, 27644000.0, 2214000.0, 10295979.46, 0.0, 0.0],
      },
      {
        name: 'Especial',
        data: [
          597812940.65, 80306601.9, 378051888.7, 7154984.55, 1435222385.2,
          210700000.0,
        ],
      },
      {
        name: 'Especial, Logística, Varejo',
        data: [62960000.0, 810000.0, 1450000.0, 2348498.9, 1174000.0, 0.0],
      },
      {
        name: 'Fazenda',
        data: [
          37956162.8, 7749030.12, 490507094.06, 1272422483.36, 582055168.03,
          1048609039.75,
        ],
      },
      {
        name: 'Fazenda, Industrial',
        data: [5839549.9, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Hospedagem',
        data: [
          150000.0, 387183564.44, 37109000.0, 503799473.57, 42237974.91,
          229929030.0,
        ],
      },
      {
        name: 'Hospedagem, Varejo',
        data: [53391600.0, 29541846.36, 696105.27, 23344470.44, 0.0, 0.0],
      },
      {
        name: 'Hospitalar',
        data: [
          476608900.49, 382680594.71, 182269751.8, 335095912.08, 360197906.23,
          99500000.0,
        ],
      },
      {
        name: 'Indeterminado',
        data: [
          134064598.88, 135521229.76, 3199832378.51, 1491667715.17,
          1406022780.36, 1741948100.0,
        ],
      },
      {
        name: 'Industrial',
        data: [
          459150431.0, 408622350.68, 624004290.79, 651991583.6, 199258465.35,
          146515615.3,
        ],
      },
      {
        name: 'Industrial, Residencial',
        data: [1935451.64, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Industrial, Residencial, Varejo',
        data: [33090400.0, 3000000.0, 13550000.0, 43371742.8, 13822446.66, 0.0],
      },
      {
        name: 'Logística',
        data: [
          2973309284.14, 8440913210.92, 10811803479.86, 7827161781.53,
          4586177278.66, 5583148438.21,
        ],
      },
      {
        name: 'Residencial',
        data: [
          980073256.32, 1435267559.1, 1609305806.21, 2883299825.87,
          1559980463.36, 347381333.28,
        ],
      },
      {
        name: 'Residencial, Varejo',
        data: [
          13794890.0, 0.0, 149266046.1, 162161934.86, 177385779.18, 10500000.0,
        ],
      },
      {
        name: 'Residencial, Varejo - Lojas Individuais',
        data: [10951762.2, 0.0, 0.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Varejo',
        data: [
          1996351561.65, 6014964192.91, 1594270521.65, 1963707812.5,
          1854523558.77, 4232287282.04,
        ],
      },
      {
        name: 'Escolar, Logística',
        data: [0.0, 218008900.0, 16000000.0, 0.0, 72185743.33, 0.0],
      },
      {
        name: 'Escritórios, Hospedagem, Industrial, Residencial',
        data: [0.0, 171213000.0, 89154000.0, 1943609.64, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Industrial, Residencial',
        data: [0.0, 23329404.51, 0.0, 9379659.85, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Logística',
        data: [0.0, 1197690701.15, 178130917.72, 231429057.42, 31060406.1, 0.0],
      },
      {
        name: 'Escritórios, Logística, Residencial',
        data: [0.0, 28297576.42, 51501563.58, 149717140.5, 70372295.92, 0.0],
      },
      {
        name: 'Especial, Logística',
        data: [0.0, 20952073.04, 21781282.73, 251169470.71, 83157369.06, 0.0],
      },
      {
        name: 'Indeterminado, Logística',
        data: [0.0, 61000000.0, 475250799.89, 0.0, 0.0, 0.0],
      },
      {
        name: 'Agências, Especial, Varejo',
        data: [0.0, 0.0, 27900000.0, 36633986.31, 242354.16, 0.0],
      },
      {
        name: 'Escolar, Escritórios, Logística',
        data: [0.0, 0.0, 1388000.0, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Hospedagem, Residencial',
        data: [0.0, 0.0, 730064.23, 0.0, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Industrial, Logística',
        data: [0.0, 0.0, 250141900.0, 0.0, 22498896.23, 40000048.0],
      },
      {
        name: 'Escritórios, Logística, Residencial, Varejo',
        data: [0.0, 0.0, 95265376.0, 139056117.57, 93537769.44, 0.0],
      },
      {
        name: 'Escritórios, Logística, Varejo',
        data: [0.0, 0.0, 1080000.0, 175884.28, 0.0, 0.0],
      },
      {
        name: 'Fazenda, Indeterminado',
        data: [0.0, 0.0, 1300000.0, 10048701.18, 14309293.02, 0.0],
      },
      {
        name: 'Fazenda, Residencial',
        data: [0.0, 0.0, 1300000.0, 3731435.04, 16867145.35, 0.0],
      },
      {
        name: 'Hospitalar, Varejo',
        data: [0.0, 0.0, 108246000.0, 31669736.4, 27609959.14, 49647103.32],
      },
      {
        name: 'Agências',
        data: [0.0, 0.0, 0.0, 311476232.06, 0.0, 98774563.5],
      },
      {
        name: 'Escolar, Hospitalar, Logística, Varejo',
        data: [0.0, 0.0, 0.0, 258953174.2, 0.0, 0.0],
      },
      {
        name: 'Escritórios, Residencial, Varejo',
        data: [0.0, 0.0, 0.0, 35334419.56, 59893400.27, 0.0],
      },
      {
        name: 'Fazenda, Logística',
        data: [0.0, 0.0, 0.0, 93504408.4, 3985189.17, 1486572.1],
      },
      {
        name: 'Industrial, Logística',
        data: [0.0, 0.0, 0.0, 635440101.87, 70123480.08, 0.0],
      },
      {
        name: 'Fazenda, Logística, Residencial, Varejo',
        data: [0.0, 0.0, 0.0, 0.0, 34658995.0, 0.0],
      },
      {
        name: 'Logística, Varejo',
        data: [0.0, 0.0, 0.0, 0.0, 6535528.36, 0.0],
      },
    ],
    numeric: true,
    stacked: true,
    categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
  },
};
export const fiiSecondaryMarketCharts: ChartsList = {
  secondaryFII: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante Negociado',
        type: 'column',
        data: [
          11210000000000, 32500000000000, 54630000000000, 66840000000000,
          61060000000000, 69200000000000,
        ],
      },
      {
        name: 'Número de Negócios',
        type: 'line',
        data: [4050741, 15566616, 37026327, 66939513, 92697633, 137144578],
      },
    ],
    categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
  },
};

export const fiiStockMarketCharts: ChartsList = {
  plFII: {
    chartType: 'line-column',
    data: [
      {
        name: 'Patrimônio Líquido',
        type: 'column',
        data: [
          74581545288.66, 75651991763.84, 77940834555.44, 79043008553.04,
          79746366922.35, 80683319913.18, 81740888959.9, 82042470896.25,
          83108049059.91, 84791542945.04, 86203004363.11, 89585349921.72,
          94422360941.28, 95037637687.72, 96969355525.51, 98795896979.41,
          101449147745.95, 103381298637.89, 105182219356.49, 109703151239.96,
          112829474597.04, 118947068350.0, 123106032204.81, 134232807856.29,
          135386881438.0, 139174903488.17, 141362517032.07, 144212763048.53,
          144697938481.41, 146959204907.35, 150869278140.24, 155296098431.44,
          159009735459.61, 164067451515.57, 168324647007.45, 176935752315.26,
          180749966200.76, 183236191581.47, 188240211228.75, 192179119846.79,
          193951495706.35, 199150541897.61, 202040106080.15, 205891755382.62,
          206685560774.76, 208598663909.66, 214244049807.8, 220385292353.72,
          220605828648.15, 222301744113.85, 226092353722.8, 227816439940.13,
          231571192775.1, 234253477179.33, 237076107780.65, 240679111349.91,
          244095267858.06, 248267761880.0, 249777412018.28, 254148985690.1,
          256501325941.06, 257782761665.24, 261112619480.53, 262780125236.92,
          265663987503.43, 269456410483.96, 276107429304.25, 279194267966.87,
          281569693965.53, 285511037334.76, 289544681242.24, 308756056991.32,
        ],
      },
      {
        name: 'Núm. de FII',
        type: 'line',
        data: [
          332.0, 335.0, 340.0, 338.0, 341.0, 348.0, 355.0, 357.0, 362.0, 370.0,
          372.0, 381.0, 384.0, 386.0, 392.0, 398.0, 403.0, 403.0, 406.0, 416.0,
          427.0, 433.0, 443.0, 469.0, 473.0, 480.0, 485.0, 489.0, 498.0, 503.0,
          496.0, 504.0, 519.0, 533.0, 543.0, 564.0, 568.0, 577.0, 590.0, 598.0,
          615.0, 628.0, 635.0, 647.0, 657.0, 670.0, 684.0, 699.0, 702.0, 706.0,
          714.0, 723.0, 731.0, 748.0, 756.0, 764.0, 765.0, 775.0, 791.0, 807.0,
          813.0, 817.0, 820.0, 820.0, 825.0, 838.0, 842.0, 856.0, 882.0, 892.0,
          895.0, 919.0,
        ],
      },
    ],
    animated: false,
    categories: [
      '01/18',
      '02/18',
      '03/18',
      '04/18',
      '05/18',
      '06/18',
      '07/18',
      '08/18',
      '09/18',
      '10/18',
      '11/18',
      '12/18',
      '01/19',
      '02/19',
      '03/19',
      '04/19',
      '05/19',
      '06/19',
      '07/19',
      '08/19',
      '09/19',
      '10/19',
      '11/19',
      '12/19',
      '01/20',
      '02/20',
      '03/20',
      '04/20',
      '05/20',
      '06/20',
      '07/20',
      '08/20',
      '09/20',
      '10/20',
      '11/20',
      '12/20',
      '01/21',
      '02/21',
      '03/21',
      '04/21',
      '05/21',
      '06/21',
      '07/21',
      '08/21',
      '09/21',
      '10/21',
      '11/21',
      '12/21',
      '01/22',
      '02/22',
      '03/22',
      '04/22',
      '05/22',
      '06/22',
      '07/22',
      '08/22',
      '09/22',
      '10/22',
      '11/22',
      '12/22',
      '01/23',
      '02/23',
      '03/23',
      '04/23',
      '05/23',
      '06/23',
      '07/23',
      '08/23',
      '09/23',
      '10/23',
      '11/23',
      '12/23',
    ],
  },
  plByAssetTypeFII: {
    chartType: 'column',
    data: [
      {
        name: 'FIAGRO',
        data: [
          147688055.21, 147896964.5, 148361687.4, 148830087.1, 149301170.8,
          149774148.7, 147426939.8, 147884280.5, 148340938.3, 148799400.3,
          149260246.1, 146371788.7, 146846604.9, 147307788.5, 147769759.1,
          148233733.4, 148701275.5, 146430072.3, 146903632.1, 147359593.0,
          147820837.2, 148281197.49, 138364296.3, 135972816.2, 136397182.1,
          136819883.9, 137230537.1, 137633442.3, 138056761.2, 135962516.5,
          136382825.0, 136799631.8, 137212191.48, 137627043.1, 138066320.9,
          138104572.62, 138542806.1, 138976525.2, 139334443.0, 179489150.2,
          180035151.9, 516657473.6, 516766435.1, 559056881.31, 559780066.89,
          990413165.53, 1440927898.69, 2189748388.48, 2421260701.63,
          3336466347.05, 3433260410.24, 3661741595.72, 4051405171.03,
          4679360963.59, 5187157086.53, 5694603641.65, 6133275045.93,
          7236455267.41, 7917176089.69, 9257510982.38, 10139088910.22,
          10247041797.6, 10761404323.46, 11692964498.34, 12224717684.73,
          12696159319.6, 13370635610.36, 14057093182.43, 14728998691.84,
          15874999777.97, 15930386153.81, 16124130839.03,
        ],
      },

      {
        name: 'Imóvel',
        data: [
          61807023402.71, 62646227790.01, 64341310381.99, 65145382222.4,
          65214301038.49, 65950607836.0, 66593327014.39, 67199700268.76,
          68177813240.06, 69507892304.07, 70266898504.48, 73085473517.89,
          77451237354.92, 77772347307.65, 79455829891.65, 80566532651.85,
          81576240306.19, 82722513626.25, 83967656384.13, 85923931518.77,
          87383830334.81, 91201027385.9, 94130639715.99, 103164906809.1,
          103614585106.81, 105460819272.38, 107587837660.56, 109677058068.37,
          109935160041.15, 111534331739.11, 114745209418.82, 117465243747.46,
          119649354062.78, 123304343489.35, 126216465831.22, 131872679184.41,
          133801202279.99, 134958194563.59, 137894998882.08, 139592892217.31,
          141515269574.57, 142729308712.74, 143340171213.45, 145809821803.63,
          145702074010.68, 147137112325.7, 149603398883.21, 153266693306.22,
          153788912385.02, 154353581075.02, 155139578453.71, 155786504854.68,
          157354483604.53, 158538945673.9, 159215941717.28, 160553309497.67,
          161366579829.61, 162978294963.93, 163971852230.52, 165918265481.06,
          166389188670.93, 167366700988.47, 169610776959.47, 169780534333.34,
          170440088760.22, 172054382455.87, 175995204561.58, 177493037473.94,
          177927673193.03, 179692633439.88, 181161196648.05, 191348920046.5,
        ],
      },

      {
        name: 'Imóvel, Renda fixa',
        data: [
          15183309.22, 15177354.82, 39452169.42, 44411742.64, 68920241.08,
          68945899.37, 69224580.42, 69147842.24, 68847636.66, 69438416.97,
          69470569.26, 83495602.19, 83622844.24, 83392130.38, 83918670.02,
          83210971.45, 84216587.25, 84618272.91, 84456442.29, 92470878.55,
          104919075.9, 114346888.14, 113313604.46, 114679165.48, 115240445.58,
          116379863.25, 122500799.78, 123772027.1, 124804379.37, 130732062.01,
          113891438.62, 113819464.47, 114266841.24, 137348729.6, 121237811.86,
          117803086.07, 123557438.28, 108334793.93, 121700322.64, 139167027.27,
          138601046.74, 140065346.78, 140074562.85, 152433281.07, 153356484.31,
          204572850.87, 270936248.99, 317828660.37, 630275846.26, 628969955.32,
          676298049.81, 675138242.67, 679666523.78, 680703066.64, 676904104.13,
          696789367.24, 693974125.46, 704006405.34, 696063333.71, 785658979.31,
          783489906.52, 1156359035.56, 1153805960.22, 1152882013.08,
          1154864530.18, 1138960827.68, 1137129394.88, 1134233189.93,
          1149417998.87, 1151075804.47, 1157808491.51, 1246473368.8,
        ],
      },

      {
        name: 'Imóvel, Renda variável',
        data: [
          63014982.47, 62978249.18, 63735539.04, 63675382.36, 63646885.34,
          64177047.63, 64100382.22, 56858923.6, 56781540.12, 56699873.32,
          56651297.61, 56559977.66, 56464097.34, 56408707.36, 56349383.32,
          56306391.19, 56261871.49, 46763959.76, 46739291.31, 42413194.2,
          42397629.65, 42365903.05, 42339141.63, 42390184.34, 42359933.27,
          42337023.8, 42369381.99, 42376683.11, 42339189.74, 42342394.24,
          42305481.49, 42276890.29, 42276890.29, 45947912.89, 45293684.06,
          45033974.97, 45201943.62, 45176647.23, 45119419.44, 45075941.28,
          45059476.26, 45057628.3, 45009956.27, 70792870.33, 71025331.49,
          71666777.41, 81721576.84, 250618611.82, 202717851.32, 206149612.55,
          204747282.81, 203629007.42, 206515362.12, 224096193.91, 284576766.59,
          286154662.91, 278941803.22, 283567048.32, 269493477.04, 271889658.48,
          358273390.52, 356149994.93, 367692309.71, 368261402.08, 381589993.95,
          385453475.33, 384394621.35, 388848384.66, 487777091.09, 481871474.97,
          479044501.28, 250392094.29,
        ],
      },

      {
        name: 'Renda fixa',
        data: [
          8847905592.06, 8862952034.16, 9074482763.98, 9595469739.97,
          10038885594.56, 10011486380.73, 10621696627.65, 10586195777.15,
          10586253769.41, 10708828572.99, 11142235547.18, 11608953267.11,
          11816807727.32, 11885964986.04, 12057424528.18, 12666569191.39,
          13664083064.34, 14290525226.28, 14698262087.26, 16164480198.87,
          17107318015.8, 18777629498.93, 19497189900.89, 20504800638.03,
          21154376454.42, 22164994043.73, 23092242945.6, 23218098710.81,
          23414301432.25, 23653328803.77, 24047891466.91, 25197859162.61,
          25793899990.5, 26560269363.97, 27486759532.3, 29394241705.13,
          30750833337.45, 31724773961.16, 33559383199.31, 34988425525.97,
          34802317848.96, 37394899665.66, 39414562875.49, 40700136715.07,
          42058738799.76, 42050916826.98, 45062190850.57, 46215237537.74,
          46247316811.98, 46587049061.27, 48758461892.83, 49334894391.6,
          51064972455.76, 51901420290.36, 53357493480.92, 54359690446.56,
          55410928307.43, 56858863319.3, 57001347953.73, 57365734983.61,
          57876847348.5, 58064554979.01, 58624680972.75, 58938519624.05,
          59605813627.98, 60511671140.13, 61556369864.2, 61396268618.32,
          61773654201.81, 62982371417.82, 64698505524.09, 66239486678.17,
        ],
      },

      {
        name: 'Renda fixa, Renda variável',
        data: [
          80261405.26, 79973254.49, 82640923.9, 80919484.65, 77637836.99,
          75523501.09, 76430577.82, 75749319.87, 75513026.49, 77985433.1,
          155716445.2, 189684436.16, 191214909.36, 214732329.36, 215055882.42,
          250306960.78, 420135677.66, 425210078.94, 426148112.38, 612213259.44,
          685569759.76, 713661492.04, 893925742.05, 971284478.43, 977715619.67,
          998433556.53, 901667758.62, 907796617.73, 907515008.45, 1200204020.77,
          1411822030.24, 1435222590.13, 1501563648.27, 1491188150.57,
          1509370669.02, 1741790299.78, 1934243617.32, 2102390074.04,
          2303487167.05, 2309948545.1, 2274210439.27, 2367132768.28,
          2395901340.13, 2345326224.04, 2326792694.37, 2348503977.45,
          2411860796.85, 2547510779.32, 2552437960.33, 2520636355.88,
          2778945857.99, 2728194613.3, 2816895127.14, 2863566671.91,
          2993001111.8, 3125803343.62, 3121652333.69, 3145749046.68,
          3106118321.78, 3113137921.97, 3201647697.85, 3126759200.92,
          3098371332.53, 3161786661.33, 3229513040.87, 3542658365.39,
          3762856087.1, 3965454181.68, 4417006761.06, 4365814950.36,
          4413919309.91, 5103735298.57,
        ],
      },

      {
        name: 'Renda variável',
        data: [
          3620468541.73, 3836786116.68, 4190851089.71, 3964319893.92,
          4133674155.09, 4362805099.66, 4168682837.6, 3906934484.13,
          3994498908.87, 4221898944.29, 4362771753.28, 4414811332.01,
          4676167403.2, 4877484438.43, 4953007410.82, 5024737079.35,
          5499508963.52, 5665237401.45, 5812053407.02, 6720282597.13,
          7357618943.92, 7949755984.45, 8290259803.49, 9298773764.71,
          9346206696.15, 10255119844.58, 9478667948.42, 10106027499.11,
          10135761669.25, 10262303370.95, 10371775479.16, 10904876944.68,
          11771161835.05, 12390726826.09, 12807453158.09, 13626099492.28,
          13956384778.0, 14158345016.32, 14176187795.23, 14924121439.66,
          14996002168.65, 15957420302.25, 16187619696.86, 16254187607.17,
          15813793387.26, 15795477985.72, 15373013552.65, 15597655069.77,
          14762907091.61, 14668891706.76, 15101061775.41, 15426337234.74,
          15397254530.74, 15365384319.02, 15361033513.4, 15962760390.26,
          17089916412.72, 17060825829.02, 16815360611.81, 17436787683.29,
          17752790016.52, 17465195668.75, 17495887622.39, 17685176704.7,
          18627399865.5, 19127124899.96, 19900839164.78, 20759332935.91,
          21085166027.83, 20962270469.29, 21703820613.59, 28442918665.96,
        ],
      },
    ],
    numeric: false,
    stacked: true,
    animated: false,
    categories: [
      '01/18',
      '02/18',
      '03/18',
      '04/18',
      '05/18',
      '06/18',
      '07/18',
      '08/18',
      '09/18',
      '10/18',
      '11/18',
      '12/18',
      '01/19',
      '02/19',
      '03/19',
      '04/19',
      '05/19',
      '06/19',
      '07/19',
      '08/19',
      '09/19',
      '10/19',
      '11/19',
      '12/19',
      '01/20',
      '02/20',
      '03/20',
      '04/20',
      '05/20',
      '06/20',
      '07/20',
      '08/20',
      '09/20',
      '10/20',
      '11/20',
      '12/20',
      '01/21',
      '02/21',
      '03/21',
      '04/21',
      '05/21',
      '06/21',
      '07/21',
      '08/21',
      '09/21',
      '10/21',
      '11/21',
      '12/21',
      '01/22',
      '02/22',
      '03/22',
      '04/22',
      '05/22',
      '06/22',
      '07/22',
      '08/22',
      '09/22',
      '10/22',
      '11/22',
      '12/22',
      '01/23',
      '02/23',
      '03/23',
      '04/23',
      '05/23',
      '06/23',
      '07/23',
      '08/23',
      '09/23',
      '10/23',
      '11/23',
      '12/23',
    ],
  },
  plByGoalTypeFII: {
    chartType: 'column',
    data: [
      {
        name: 'Diversas',
        data: [
          27426879142.99, 27655255035.95, 28789195563.89, 28895688993.94,
          29079242287.94, 29427790692.24, 29612543474.31, 29961703040.46,
          30460258594.32, 31254725996.22, 31312930829.7, 32010051977.39,
          34532500994.82, 34644795760.88, 35063485974.12, 35213367412.13,
          35539153040.59, 35851973421.58, 36010406852.6, 36920170304.68,
          37676406576.15, 38604306567.49, 39829972144.13, 41133421177.72,
          40563046672.1, 41212943516.55, 42701108140.04, 44559187058.2,
          44786303680.74, 45069811814.16, 46067337679.4, 47509351741.61,
          48696674878.89, 50109131291.11, 50646450537.11, 53103117924.7,
          53517890213.68, 54599386483.21, 56221353918.9, 57055871785.16,
          57630981656.07, 59274023246.05, 59400339063.61, 60548842335.57,
          60744623011.38, 61831439991.4, 63022269242.37, 65446205199.76,
          65698173829.97, 66091497997.87, 66826337036.35, 67442056852.55,
          69028003744.32, 69784647766.91, 70423026391.36, 71421909451.99,
          73014441139.52, 74798698823.73, 75178580692.8, 77712971099.85,
          78701432943.64, 79504916107.87, 81335006127.39, 81846531733.25,
          83221996249.23, 83709515398.65, 86871474981.43, 88405081098.01,
          90237686187.21, 91517050707.87, 92079647912.01, 103995230402.38,
        ],
      },

      {
        name: 'Ganho de Capital',
        data: [
          4915041.97, 4895781.41, 4974296.0, 5039368.93, 4988996.74, 5092504.24,
          5377537.04, 5187825.24, 1717085.34, 2074908.35, 2345285.83,
          2953881.89, 6815494.44, 7022051.52, 7342096.41, 7321020.8, 8155651.43,
          8448788.18, 8723757.26, 9292981.83, 9701599.13, 10096025.81,
          10775593.34, 11815178.35, 12335511.98, 35185080.35, 35586454.84,
          35921154.37, 35685828.46, 24619550.5, 24773267.87, 24895420.48,
          24721853.76, 10468445.17, 10423779.23, 10657335.13, 5750730.88,
          5321354.12, 5339177.77, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, -9565.22, -66069056.41,
          -65733106.74, -65671346.58, -2520679.81, -1835817.47, -1853850.12,
          -1878074.73, -1898247.96, -716807.22, -725336.09, -736594.95,
          -755145.48, -763973.91, -776552.58, -1444034.41, -1466687.91,
        ],
      },

      {
        name: 'Gestão de Ativos Financeiros',
        data: [
          10146657460.89, 10382434167.3, 10738482687.47, 11144949196.39,
          11738895815.99, 11338527826.1, 11976440720.33, 11674797272.92,
          11723244417.62, 11980616507.51, 12596123192.48, 13188832448.16,
          13764371405.37, 13808439967.04, 14101964143.96, 14814314666.31,
          15953502427.41, 16669884101.8, 17306954450.81, 19382739197.85,
          20811996281.49, 22954436106.63, 23869715009.44, 25783339947.48,
          26910408223.54, 29061883495.08, 28942041379.88, 29694466565.92,
          29972722840.9, 31058033605.62, 31803018413.54, 33051531838.69,
          34044074108.77, 34822891255.97, 36089742179.6, 38871125585.56,
          40590149307.62, 41953797257.88, 43509438729.21, 44969604213.1,
          45251263538.95, 48188915545.6, 50181714347.5, 51432125599.11,
          52542264531.43, 52540482563.78, 55220751622.34, 57864357769.58,
          57798242880.41, 58811760189.01, 61326306577.53, 61994502694.01,
          63675627293.61, 64723016856.84, 66451303815.35, 68343784750.93,
          69800377431.68, 72037647379.24, 72691699662.72, 73282287790.0,
          74375512914.73, 74639381383.6, 74835301026.11, 75858913791.81,
          77172273472.51, 78613610498.16, 80009292805.37, 80333953231.61,
          80588713862.55, 81713684459.02, 83565504290.96, 85014550819.95,
        ],
      },

      {
        name: 'Renda',
        data: [
          34702098225.45, 35308618743.73, 36080396056.1, 36683779678.32,
          36608494704.33, 37585939584.31, 37780134331.85, 38035462921.85,
          38285093418.18, 38881125807.68, 39579434763.07, 41393604514.78,
          43121034081.23, 43559923298.13, 44774205163.97, 45743610471.84,
          46899808643.63, 47788804746.46, 49312759414.43, 50545056725.79,
          51475462851.07, 54513332239.7, 56517914045.87, 63597522384.85,
          64172814470.21, 65114005757.93, 65859083406.24, 66075114364.04,
          66026068440.31, 66962216582.26, 69114908981.69, 70744343967.24,
          72267616415.49, 75145037035.01, 77589906577.79, 80867577640.47,
          82549289529.23, 82570242101.37, 84404409672.52, 85988097592.6,
          86895295430.87, 87642230685.21, 88373223951.47, 89805362115.59,
          89313505112.4, 90174714659.47, 90296217906.89, 91035480242.96,
          91064830449.3, 91338220765.98, 91868136991.55, 92304767068.37,
          92864353449.13, 93419519355.87, 93857289371.19, 94436311731.38,
          94832209432.92, 94990156073.95, 95406707763.16, 96706796986.19,
          97064355933.98, 97253935725.9, 98382512115.33, 98585406306.61,
          98760267788.17, 100605940016.38, 102682272037.68, 103880654232.76,
          104147721658.39, 105658073161.61, 107053509311.5, 112072109685.44,
        ],
      },

      {
        name: 'Securitização',
        data: [
          2300995417.36, 2300788035.45, 2327785951.98, 2313551315.46,
          2314745117.35, 2325969306.29, 2366392896.37, 2365319835.78,
          2637735544.45, 2672999725.28, 2712170292.03, 2989907099.5,
          2997638965.42, 3017456610.15, 3022358147.05, 3017283408.33,
          3048527982.89, 3062187579.87, 2543374881.39, 2845892029.81,
          2855907289.2, 2864897410.37, 2877655412.03, 3706709167.89,
          3728276560.17, 3750885638.26, 3824697651.07, 3848073906.0,
          3877157691.0, 3844523354.81, 3859239797.74, 3965975463.42,
          3976648202.7, 3979923488.31, 3988123933.72, 4083273829.4,
          4086886419.35, 4107444384.89, 4099669730.35, 4165546255.93,
          4173955080.46, 4045372420.75, 4084828717.57, 4105425332.35,
          4085168119.55, 4052026695.01, 5704811036.2, 6039249141.42,
          6044581488.47, 6060265160.99, 6071573117.37, 6075113325.2,
          6003208288.04, 6326293199.71, 6344488202.75, 6477114980.83,
          6514308910.35, 6506992709.82, 6566095246.18, 6449450493.87,
          6361859966.18, 6386382297.99, 6561678286.43, 6491171653.21,
          6510166800.74, 6528069906.86, 6545126074.72, 6575334549.97,
          6596336231.29, 6623005558.84, 6847463762.18, 7675632771.46,
        ],
      },
    ],
    numeric: false,
    stacked: true,
    animated: false,
    categories: [
      '01/18',
      '02/18',
      '03/18',
      '04/18',
      '05/18',
      '06/18',
      '07/18',
      '08/18',
      '09/18',
      '10/18',
      '11/18',
      '12/18',
      '01/19',
      '02/19',
      '03/19',
      '04/19',
      '05/19',
      '06/19',
      '07/19',
      '08/19',
      '09/19',
      '10/19',
      '11/19',
      '12/19',
      '01/20',
      '02/20',
      '03/20',
      '04/20',
      '05/20',
      '06/20',
      '07/20',
      '08/20',
      '09/20',
      '10/20',
      '11/20',
      '12/20',
      '01/21',
      '02/21',
      '03/21',
      '04/21',
      '05/21',
      '06/21',
      '07/21',
      '08/21',
      '09/21',
      '10/21',
      '11/21',
      '12/21',
      '01/22',
      '02/22',
      '03/22',
      '04/22',
      '05/22',
      '06/22',
      '07/22',
      '08/22',
      '09/22',
      '10/22',
      '11/22',
      '12/22',
      '01/23',
      '02/23',
      '03/23',
      '04/23',
      '05/23',
      '06/23',
      '07/23',
      '08/23',
      '09/23',
      '10/23',
      '11/23',
      '12/23',
    ],
  },
  plByManagementTypeFII: {
    chartType: 'column',
    data: [
      {
        name: 'Gestão Ativa',
        data: [
          49017288438.96, 49835492128.87, 52084384654.96, 53172711669.65,
          53633963547.49, 54352397545.27, 55307971144.55, 55836128086.94,
          56860497723.82, 58462558048.77, 59463652303.49, 61583825297.58,
          65891334246.63, 66311013670.43, 68318184260.67, 70111568086.29,
          72620648859.51, 74451131987.51, 75831774171.98, 80619012294.02,
          83785824641.5, 89476429404.42, 93344266607.64, 102146610491.01,
          103078237007.68, 106679744494.71, 109090546676.56, 112459927615.73,
          113173576626.95, 115218284048.91, 119300278972.97, 123848447121.49,
          127256603818.93, 131376990403.94, 135720937732.15, 142934835398.26,
          146742487381.4, 149164770447.12, 154048391442.0, 157932493537.02,
          159665241793.49, 164792632294.08, 168151604169.29, 171755625913.63,
          173024963554.35, 174707828546.84, 180242775264.17, 186651746028.78,
          186910408073.46, 188579279852.72, 192184299346.49, 193601870165.44,
          197125419543.37, 199575295186.79, 202253268977.08, 205401930032.54,
          208751243702.47, 212915399489.75, 213986493922.28, 219525049414.18,
          221750957129.37, 223011994585.19, 226352647259.85, 228050119801.97,
          230991341735.22, 234300506540.48, 240949194390.88, 243960673931.08,
          246301446369.33, 250003724500.53, 253906840426.52, 273255914912.73,
        ],
      },
      {
        name: 'Gestão Passiva',
        data: [
          25349402055.41, 25601070041.91, 25641082802.56, 25654549472.59,
          25896423844.36, 26047338504.01, 26092199242.43, 26058958087.52,
          26103558377.8, 26184370798.85, 26594049038.66, 27855321380.44,
          28381096443.64, 28572619592.03, 28496957384.13, 28529870026.33,
          28678042535.33, 28856960806.4, 29277002356.75, 29074845964.11,
          29033948356.41, 29460542919.77, 29750990003.83, 32074382186.93,
          32296308918.34, 32459973913.11, 32236383900.67, 31716914278.43,
          31488676026.0, 31716301307.94, 31544225899.4, 31422755889.47,
          31728409786.92, 32679992666.46, 32593285496.07, 33990259581.87,
          34001728088.48, 34066099780.23, 34186480608.98, 34246626309.77,
          34286253912.86, 34357909603.53, 33888501910.86, 34136129468.99,
          33660597220.41, 33890835362.82, 34001274543.63, 33733546324.94,
          33695420574.69, 33722464261.13, 33908054376.31, 34214569774.69,
          34445773231.73, 34678181992.54, 34822838803.57, 35277181317.37,
          35344024155.59, 35352362390.25, 35790918096.0, 34623936275.92,
          34750368811.69, 34770767080.05, 34759972220.68, 34730005434.95,
          34672645768.21, 35155903943.48, 35158234913.37, 35233594035.79,
          35268247596.2, 35507312834.23, 35637840815.72, 35500142078.59,
        ],
      },
      {
        name: 'Não disponível ',
        data: [
          214854794.29, 215429593.06, 215367097.92, 215747410.8, 215979530.5,
          283583863.9, 340718572.92, 147384721.79, 143992958.29, 144614097.42,
          145303020.96, 146203243.7, 149930251.01, 154004425.26, 154213880.71,
          154458866.79, 150456351.11, 73205843.98, 73442827.76, 9292981.83,
          9701599.13, 10096025.81, 10775593.34, 11815178.35, 12335511.98,
          35185080.35, 35586454.84, 35921154.37, 35685828.46, 24619550.5,
          24773267.87, 24895420.48, 24721853.76, 10468445.17, 10423779.23,
          10657335.13, 5750730.88, 5321354.12, 5339177.77, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
      },
    ],
    numeric: false,
    stacked: true,
    animated: false,
    categories: [
      '01/18',
      '02/18',
      '03/18',
      '04/18',
      '05/18',
      '06/18',
      '07/18',
      '08/18',
      '09/18',
      '10/18',
      '11/18',
      '12/18',
      '01/19',
      '02/19',
      '03/19',
      '04/19',
      '05/19',
      '06/19',
      '07/19',
      '08/19',
      '09/19',
      '10/19',
      '11/19',
      '12/19',
      '01/20',
      '02/20',
      '03/20',
      '04/20',
      '05/20',
      '06/20',
      '07/20',
      '08/20',
      '09/20',
      '10/20',
      '11/20',
      '12/20',
      '01/21',
      '02/21',
      '03/21',
      '04/21',
      '05/21',
      '06/21',
      '07/21',
      '08/21',
      '09/21',
      '10/21',
      '11/21',
      '12/21',
      '01/22',
      '02/22',
      '03/22',
      '04/22',
      '05/22',
      '06/22',
      '07/22',
      '08/22',
      '09/22',
      '10/22',
      '11/22',
      '12/22',
      '01/23',
      '02/23',
      '03/23',
      '04/23',
      '05/23',
      '06/23',
      '07/23',
      '08/23',
      '09/23',
      '10/23',
      '11/23',
      '12/23',
    ],
  },

  plByPropertyTypeFII: {
    chartType: 'column',
    data: [
      {
        name: 'Agências',
        data: [
          2673714483.85, 2687863212.6, 2667995284.5, 2665985275.95,
          2665317044.06, 2797762563.86, 2778376441.46, 2794494575.26,
          2791892193.76, 2791157546.47, 2797687913.21, 2808965260.44,
          2808317748.73, 2806223638.01, 2805048621.74, 2804472190.45,
          2815913794.43, 2810173873.72, 2809104540.45, 2816446136.89,
          2997853897.39, 2997579343.51, 3208189886.92, 3219540403.94,
          3212603505.23, 3210236497.65, 3206150307.8, 3206713925.93,
          3207074925.93, 3089532938.64, 3087785660.22, 3088648067.28,
          3087321663.33, 3085884479.02, 3078811211.99, 3078877578.09,
          3183815957.69, 3180841207.45, 3176637241.74, 3177213763.01,
          3171477861.3, 3101314674.92, 3099129827.28, 3090913471.24,
          3093079980.52, 3208658697.21, 3207083779.9, 3189588580.01,
          3175084492.97, 3175442031.37, 3175195093.25, 3175454339.16,
          3160219890.22, 3268796492.88, 3267626799.67, 3267549558.62,
          3267976930.21, 3270225234.23, 3272778284.8, 3342477289.69,
          3333947569.55, 3334428207.02, 3332520593.93, 3335526122.66,
          3335542717.11, 3322068192.21, 3321265374.21, 3320374706.45,
          3302379136.46, 3301713601.45, 3299405206.21, 3417395137.0,
        ],
      },

      {
        name: 'Agências, Especial',
        data: [
          15183309.22, 15177354.82, 17854138.47, 17916933.21, 17861864.15,
          17857146.38, 17856096.78, 17876955.73, 17849024.82, 17852162.68,
          17853420.18, 17854509.6, 17854180.93, 17840668.6, 18391435.82,
          18388999.38, 18420355.8, 18396256.85, 18369041.66, 18373701.6,
          18346167.74, 18374250.61, 18363980.45, 18366567.3, 18370363.28,
          19769403.15, 19761333.28, 20015683.75, 20497951.76, 20467918.28,
          20529128.99, 20559870.68, 20595974.32, 20597121.65, 20632329.97,
          17784955.45, 20637887.86, 21486272.91, 18680463.61, 18951380.21,
          19030673.37, 18597099.19, 18613234.9, 18694138.11, 19766166.42,
          19815866.33, 19897740.98, 20260984.28, 20327849.4, 20276194.1,
          46552264.49, 46860604.53, 46827601.89, 47255611.11, 47525489.53,
          47788233.73, 48105381.78, 48434334.98, 48716800.88, 52887440.54,
          49488537.05, 49435547.18, 52119423.52, 53534486.92, 53652994.93,
          53627147.76, 53598818.21, 53572814.21, 53550884.2, 53646729.93,
          53659244.74, 53922342.45,
        ],
      },

      {
        name: 'Escolar',
        data: [
          511199810.18, 509560753.59, 510344814.15, 656867367.41, 657676920.78,
          653337338.9, 654037184.46, 655164160.31, 655533081.41, 655895784.07,
          659985386.46, 750146303.77, 749991803.55, 736849845.16, 737426182.59,
          737147819.62, 771971499.27, 776857402.23, 777091761.73, 777359471.64,
          777423811.78, 778538412.85, 779510378.64, 1915625743.67,
          1912933139.47, 1909933520.24, 1939159108.26, 1942738268.22,
          1942897063.46, 2259216524.83, 2260104056.56, 2273357005.11,
          2274901496.96, 2284540551.42, 3106355287.61, 3093922822.9,
          3093108524.95, 3089175035.64, 3087852105.12, 3122520744.6,
          3121447056.12, 3142645852.37, 3141666691.7, 3137760201.62,
          3141022717.07, 3120867433.4, 3139018373.51, 3158603395.93,
          3159281121.5, 3157352121.21, 3162514253.64, 3160535163.34,
          3164320046.31, 3152985413.01, 3153296491.29, 3169609698.62,
          3177504958.31, 3216733276.2, 3223026863.07, 3324697350.1,
          3331479626.27, 3347229270.16, 3353960497.25, 3354370292.71,
          3359950912.7, 3349301581.66, 3351530544.55, 3348248404.9,
          3343764037.29, 3351623388.39, 3321759238.87, 3349015870.74,
        ],
      },

      {
        name: 'Escolar, Escritórios, Logística, Varejo - Lojas Individuais',
        data: [
          35910978.48, 37759456.01, 37646543.58, 37979599.7, 38162950.3,
          38896424.87, 37866848.42, 37160727.79, 36840601.05, 35916550.14,
          35866973.94, 35530260.28, 35835894.57, 36054071.69, 36246051.26,
          36304819.58, 36388883.62, 43729471.95, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },

      {
        name: 'Escritórios',
        data: [
          19201308218.5, 19366422337.45, 19531615686.55, 19520402973.03,
          19521306013.86, 19593914867.75, 19862831200.44, 19834365018.3,
          20151501290.05, 20106088882.22, 20132888745.92, 21232658619.7,
          21234039110.23, 20596281769.93, 21010727237.37, 21635048196.31,
          21705916697.6, 21948769032.83, 22154211669.46, 22203425600.63,
          23108846325.33, 24413251325.64, 25760182051.98, 29015500712.44,
          29171954735.33, 29765902029.75, 30882049703.64, 30427544195.94,
          30126413035.31, 30368105135.83, 31681848233.07, 31473082218.61,
          32463216517.17, 32690049842.96, 34033997409.75, 35803628201.23,
          35994338085.45, 35638086575.47, 36069825640.52, 36343573786.32,
          36448931480.89, 37033037297.25, 37245128445.43, 37362627628.61,
          37574297736.73, 37649921576.38, 37735216616.84, 37689859607.37,
          37680100747.68, 37855344818.96, 38370766948.04, 38397751058.1,
          38192378394.45, 38231153819.4, 38273224075.25, 38455404661.64,
          38848483311.39, 38861926308.24, 38799608170.53, 38422865932.15,
          38547823479.44, 38743717043.19, 39142149806.57, 39172425384.73,
          38845355174.32, 38944134770.59, 39405351616.4, 39464937592.47,
          39663290387.79, 39819856506.36, 39596788738.71, 38945948738.76,
        ],
      },

      {
        name: 'Escritórios, Fazenda, Residencial',
        data: [
          149035691.56, 149234582.6, 149082878.3, 149103204.4, 150249672.4,
          140703032.8, 139547916.4, 140471935.3, 139992468.5, 140172357.5,
          140377608.8, 138186241.14, 140260459.2, 140481085.41, 140723033.1,
          140895445.0, 140991451.8, 141203851.9, 143531887.3, 143782445.6,
          141593806.7, 151227008.57, 126436241.1, 132035100.8, 132239626.8,
          133141398.4, 135870863.2, 136113011.1, 136301416.2, 136533790.5,
          136912869.0, 137116837.6, 130105722.2, 130746046.1, 131191833.5,
          130594459.4, 130848059.3, 131109517.3, 134441606.2, 137643148.0,
          125781788.8, 126367626.46, 130810532.6, 131449281.2, 132951154.7,
          233055796.8, 132533794.72, 154880332.03, 153758172.3, 152382560.23,
          152752526.51, 125480892.21, 125993375.74, 126237829.73, 128162282.75,
          125486274.4, 125557975.68, 125912620.59, 126686361.06, 181193187.58,
          182367266.05, 182984762.52, 183533291.36, 186070839.19, 186353844.34,
          187075231.99, 188500167.58, 189185420.52, 189831534.07, 189731761.52,
          191092785.07, 191741986.37,
        ],
      },

      {
        name: 'Escritórios, Hospedagem, Hospitalar, Varejo',
        data: [
          100964136.13, 100975743.9, 101337418.9, 101129657.9, 101019650.4,
          100508019.7, 100901924.2, 100869207.4, 100775806.2, 100879329.0,
          102527146.8, 102169621.0, 102124269.4, 101984888.6, 102017574.5,
          102027563.8, 102035517.4, 102175748.6, 102200094.4, 102218561.2,
          102228164.3, 102220473.19, 98881955.47, 98835556.83, 98769023.19,
          99185557.12, 98807042.44, 98920741.77, 98919039.54, 98884335.8,
          98962948.84, 98771695.74, 98843929.89, 98939408.1, 98986110.38,
          100689790.73, 100713718.1, 101113684.1, 100713811.6, 100596363.0,
          100922127.6, 100986885.92, 101490924.5, 101795762.5, 101783482.17,
          101697721.5, 103280071.79, 102945889.8, 103372804.55, 103792922.1,
          102785042.05, 102963803.85, 103156808.33, 103228587.18, 103082139.69,
          103249675.49, 103102754.94, 103165125.65, 109017198.23, 108262697.3,
          108408668.9, 107622681.32, 108528556.53, 109140137.58, 109424412.78,
          109818804.46, 109692448.15, 109624577.22, 109983210.13, 109486721.04,
          109497944.8, 113480575.72,
        ],
      },

      {
        name: 'Escritórios, Hospedagem, Residencial',
        data: [
          201384491.82, 201450660.55, 201556478.62, 201433296.7, 201460110.45,
          201371174.37, 201252161.86, 201655970.87, 201664925.05, 201937946.56,
          202025948.72, 238976585.69, 239173325.34, 239458533.93, 239363476.92,
          239397013.63, 239625117.01, 239638311.21, 239496162.92, 240175645.71,
          239980741.84, 239945221.69, 239882047.83, 236499449.78, 239708047.97,
          239811723.88, 239198309.22, 236628458.84, 237131396.98, 237435566.63,
          237520872.63, 237782985.37, 235799447.58, 235508184.05, 233700674.3,
          248125788.23, 248045355.41, 246391475.84, 246109661.99, 245951420.72,
          245882431.13, 245862452.8, 240575649.57, 240526544.16, 240573923.54,
          240437774.42, 240888869.71, 252220534.29, 241157539.91, 241207227.03,
          251615333.51, 251624538.16, 251669661.31, 251654103.58, 251635436.41,
          251801235.13, 251776881.19, 251766911.32, 251861189.53, 256628500.49,
          256089436.03, 256175957.54, 256516163.81, 256369482.66, 255221703.1,
          255758922.61, 256224938.67, 256548161.6, 256532729.17, 256608954.5,
          256799487.25, 318547871.72,
        ],
      },

      {
        name: 'Escritórios, Hospedagem, Residencial, Varejo',
        data: [
          1955654907.12, 1950073433.0, 1951339190.0, 1953978895.0, 1964453843.0,
          1958910167.0, 1961653495.0, 1973233019.0, 2031664931.0, 2044028631.0,
          2054725349.0, 2160690158.0, 2070194552.0, 2067333324.0, 2078332830.0,
          2114745927.0, 2128857049.0, 2144053896.0, 2089251386.0, 2124804682.0,
          2132106076.0, 2143714974.88, 2046419816.0, 2090698111.0, 1901373586.0,
          1915714718.0, 1925762204.0, 1930088560.0, 1942755835.0, 1969705396.0,
          1960103491.0, 1975666823.0, 2000860065.23, 2015226767.0, 2033117178.0,
          2296622126.69, 2308536176.0, 2325448943.0, 2325448868.0, 2354196428.0,
          2381264954.0, 2337001531.5, 2354447050.0, 2471722518.0, 2490881861.55,
          2505497688.0, 2574689163.31, 2490138967.0, 2569068488.97,
          2591380519.6, 2663328668.27, 2587128917.05, 2606845076.01,
          2591794648.75, 2611026661.1, 2632589361.17, 2651173386.82,
          2669579504.22, 2685049375.2, 2675838199.25, 2695032612.5,
          2765063202.25, 2787005179.82, 2811560871.64, 2834277080.96,
          2745276097.52, 2764153469.92, 2881153992.1, 2889812737.91,
          2900566779.0, 2912008868.77, 2847643018.92,
        ],
      },

      {
        name: 'Escritórios, Indeterminado',
        data: [
          1579579.16, 908038.18, 1377592.32, 1335591.5, 1472091.4, 1347831.96,
          277646.36, 233410.18, 888365.98, 636714.82, 658918.26, 1651655.81,
          6460960.82, 6589456.12, 6997494.27, 6446010.79, 5835458.23,
          5748115.24, 6126589.63, 6476393.78, 6702421.19, 7090416.62, 7633499.0,
          7863876.7, 133464910.2, 18092929.69, 18382672.38, 18698121.86,
          19254301.25, 20057453.71, 20763754.5, 20910859.1, 21085791.48,
          21159221.52, 20951183.06, 20851271.51, 21639342.66, 21909728.74,
          22565052.3, 23109845.82, 23810970.3, 24411219.8, 24642998.89,
          26071829.15, 26467878.98, 26782346.55, 27517917.31, 31035810.58,
          29316968.24, 31506324.87, 30916766.58, 30587018.06, 30422192.45,
          30228375.63, 29829370.49, 29436753.27, 29098595.86, 28797155.99,
          28553907.9, 27899532.29, 26084800.03, 24577709.23, 24025889.66,
          22507537.63, 20611442.11, 18568906.05, 16686954.98, 31885547.08,
          34333224.4, 36065835.13, 34793075.06, 33224844.67,
        ],
      },

      {
        name: 'Escritórios, Indeterminado, Industrial',
        data: [
          20791769.32, 20826621.95, 20937257.12, 21078711.65, 21208795.06,
          21329834.75, 21966133.57, 22097580.43, 22482776.29, 21383661.07,
          21654020.86, 24080024.52, 23061786.34, 23690036.97, 23981406.71,
          24345454.5, 24752889.74, 25149149.9, 23737434.18, 24412421.54,
          24639501.57, 23496598.3, 23540001.3, 24753365.63, 33810054.48,
          34542103.08, 31008739.81, 29933611.26, 30278423.77, 30532733.95,
          30470042.12, 29242974.25, 29407388.55, 29480885.37, 29552974.7,
          41397677.58, 40012518.33, 40188556.6, 40545037.98, 39656575.32,
          39916690.33, 39814921.88, 40137557.37, 40412748.1, 40762643.81,
          41325485.19, 41691510.3, 59514851.78, 59928052.32, 60279482.88,
          60591089.7, 60916780.18, 61220434.49, 62174428.76, 62865221.73,
          63205093.18, 63516845.39, 63874385.75, 64252488.77, 64593800.24,
          64934292.94, 161859882.69, 162281119.37, 161830319.71, 162158639.89,
          162500258.93, 162922978.23, 163341090.15, 163714058.87, 164141331.8,
          166322347.15, 166612560.58,
        ],
      },

      {
        name: 'Escritórios, Industrial',
        data: [
          433930477.55, 433011503.64, 424917831.47, 425244383.26, 425937093.24,
          425877922.49, 425646287.29, 426301888.85, 426441976.78, 426900843.41,
          427812285.78, 363455808.93, 436985693.4, 434919304.42, 434515894.14,
          434544078.93, 364415543.17, 436258508.23, 396396911.75, 445956083.55,
          436252855.07, 446744471.69, 452441948.05, 446804819.63, 441720916.2,
          446625695.65, 458336010.14, 450583616.4, 451210912.77, 453304008.96,
          465874051.34, 472349624.59, 492731476.83, 531089950.7, 509465791.39,
          549316059.72, 558757866.21, 566223324.14, 509873487.42, 510996877.09,
          533711827.53, 532338403.59, 534103859.88, 551394029.8, 552588622.56,
          553748500.92, 555434266.91, 527505375.49, 495862938.67, 498039981.17,
          499425017.25, 499990419.43, 500904664.27, 502280432.96, 502991555.71,
          504490759.92, 506054860.88, 509423600.86, 510004836.91, 470662648.48,
          477717520.88, 479850039.33, 468263928.16, 470626307.84, 471302428.29,
          481805174.52, 488346324.06, 485453504.04, 491253417.13, 497505607.34,
          503798599.9, 531115878.44,
        ],
      },

      {
        name: 'Escritórios, Industrial, Logística',
        data: [
          63014982.47, 62978249.18, 63735539.04, 63675382.36, 63646885.34,
          64177047.63, 64100382.22, 56858923.6, 56781540.12, 56699873.32,
          56651297.61, 56559977.66, 56464097.34, 56408707.36, 56349383.32,
          56306391.19, 56261871.49, 46763959.76, 46739291.31, 42413194.2,
          42397629.65, 42365903.05, 42339141.63, 42390184.34, 42359933.27,
          42337023.8, 42410341.99, 42389450.41, 114166756.45, 250006279.04,
          299103444.79, 296172036.49, 306662323.36, 310835723.59, 314188418.26,
          297872585.0, 301043281.12, 296719534.33, 315492258.24, 313766311.08,
          309691331.96, 309916619.94, 301378345.87, 301326022.06, 301944298.14,
          302145304.77, 300601439.37, 285627170.36, 248081219.75, 245662733.14,
          245083864.2, 243535217.51, 268160428.31, 255114948.79, 316452704.98,
          308273401.64, 308299773.04, 308227921.79, 307699943.11, 314737317.6,
          314564299.3, 314514097.22, 329338046.8, 329221066.49, 329507265.03,
          323111400.58, 313127824.3, 307459012.43, 307506511.69, 313998732.24,
          310754359.53, 360509003.67,
        ],
      },

      {
        name: 'Escritórios, Industrial, Residencial',
        data: [
          951783697.99, 977443503.0, 979570866.41, 981646101.52, 983073016.04,
          952683782.66, 953896649.79, 955500567.59, 958260026.26, 960592887.76,
          963885047.86, 1081892192.3, 1072191171.03, 1074179395.42,
          1076841893.45, 1078938995.94, 1089729334.79, 1083685877.08,
          1087154872.73, 1092227841.1, 1097733316.74, 1100553501.76,
          1107591517.68, 1166693110.94, 1164015111.15, 1166128889.21,
          1168644330.62, 1170875675.07, 1173327709.16, 1174371326.2,
          1174431723.7, 1178756095.7, 1180681541.95, 1203933710.37,
          1208462580.47, 1218910814.9, 1220866352.18, 1222995213.77,
          1222255360.65, 1232058110.05, 1235294510.5, 1239337585.17,
          1245729257.53, 1252681736.34, 1257280521.6, 1261354813.25,
          1266388187.52, 1624145313.24, 1630301465.21, 1632748531.6,
          1635636343.33, 1637641366.29, 1648835316.55, 1645723596.08,
          1647875233.98, 1649782704.66, 1652547235.08, 1655267248.88,
          1656153080.56, 1653138514.91, 1606492886.64, 1583369107.38,
          1588912200.55, 1592448590.09, 1595355405.1, 1590159210.66,
          1594382840.83, 1598676927.92, 1604731921.24, 1606119256.15,
          1681928999.26, 1678116059.85,
        ],
      },

      {
        name: 'Escritórios, Industrial, Varejo, Varejo - Lojas Individuais',
        data: [
          165934674.35, 166153339.8, 50681279.34, 166244622.9, 166794042.8,
          166250576.7, 166593350.2, 166531119.8, 166294547.3, 166511332.0,
          166836784.2, 158059538.0, 158854910.1, 159842194.4, 160118285.7,
          167116611.0, 167091675.3, 169555562.4, 172879206.3, 175354309.0,
          176898936.6, 180171354.62, 182755310.2, 208121169.1, 210141800.3,
          212593918.7, 213428543.7, 215643007.8, 214244916.6, 214671113.9,
          214612337.3, 218358932.0, 221173185.8, 225312642.9, 228165150.7,
          228165150.67, 228165150.7, 234134078.5, 238864489.4, 234612744.7,
          237588020.1, 239758773.69, 242753939.6, 245891681.9, 248794137.66,
          248925997.8, 248630339.96, 342391059.78, 345395031.87, 369949717.99,
          349122308.55, 352082506.91, 350319735.0, 351500419.89, 351702804.64,
          350463983.14, 357652921.64, 358259856.51, 359368839.15, 358259856.51,
          358259856.51, 358259856.51, 358975533.48, 359034947.92, 359025725.52,
          356638654.39, 356292193.1, 358371411.49, 356177753.42, 356203200.43,
          363946652.09, 422727359.92,
        ],
      },

      {
        name: 'Escritórios, Logística',
        data: [
          2279616230.29, 2246638086.72, 2231428364.98, 2229021288.18,
          2216762537.92, 2292071569.64, 2288718208.62, 2287478193.58,
          2292980793.31, 2290964050.97, 2291432393.73, 2291538582.28,
          2356911164.25, 2427283370.97, 2640177047.66, 2836901957.45,
          2982989251.88, 3595376261.8, 3595692501.12, 3600200283.49,
          3600180038.69, 3619657495.42, 3618372065.45, 3627917987.8,
          3629967146.89, 3641334860.79, 3843250103.84, 3839261082.98,
          3827209301.49, 3823673611.12, 3857677723.87, 3855058743.76,
          4004161312.77, 4236927844.57, 4286408595.92, 4338775652.74,
          4365108886.68, 4360235867.35, 4396651155.66, 4417554329.15,
          4413703169.6, 4450276918.57, 4444200987.6, 4493928125.05,
          4478740204.47, 4467380240.35, 4545963131.33, 4517094756.95,
          4509716942.74, 4500332528.5, 4501388523.98, 4498959596.07,
          4659016784.85, 4703334117.37, 4704311059.98, 4847357395.27,
          4679808979.62, 4690656349.81, 4698021674.09, 4700297927.95,
          4683438827.9, 4682285700.65, 4680229955.98, 4683936146.68,
          4683202251.0, 4675000986.97, 4670238012.16, 4658821732.15,
          4651751492.0, 4642124843.19, 4650794467.2, 4631807130.67,
        ],
      },

      {
        name: 'Escritórios, Logística, Residencial, Varejo',
        data: [
          137165535.08, 139426938.5, 146705523.0, 151943838.4, 157380491.7,
          134274421.7, 139716366.7, 145951165.4, 151681140.4, 157698516.4,
          163271686.4, 136172260.8, 141170641.1, 145431825.1, 151101389.1,
          156824066.6, 162589347.6, 137660569.5, 143738643.3, 149596979.7,
          155390914.4, 161006594.4, 166701139.6, 138748229.1, 143776257.5,
          145686300.3, 152371441.5, 157306451.0, 162234487.4, 140569533.8,
          145948965.9, 151346714.5, 156690191.08, 162018894.8, 168645724.6,
          126123644.84, 242296066.63, 266615879.5, 272908536.0, 280410079.6,
          291977210.7, 290906109.59, 302523950.0, 312253064.26, 318873364.13,
          366836256.0, 369065390.47, 369030414.4, 382015758.15, 386797433.7,
          396405868.46, 408200413.19, 428690425.88, 414148213.38, 450072956.11,
          467136509.64, 465326867.01, 501430306.56, 505054631.08, 500335073.89,
          506451199.86, 509404077.61, 516488001.19, 523784916.85, 502440746.84,
          508999446.05, 484840593.16, 490299921.29, 496731575.05, 503170822.12,
          508749326.26, 476402658.83,
        ],
      },

      {
        name: 'Escritórios, Logística, Varejo',
        data: [
          67612711.65, 67109479.4, 66575249.71, 78337215.27, 77736027.2,
          77179900.94, 75906747.66, 74665603.39, 74670480.61, 74015744.12,
          73317409.44, 97533145.83, 97330791.86, 96692274.35, 96997184.71,
          96408165.01, 97713138.26, 98080950.01, 107522432.09, 112095540.45,
          18730866.94, 19353443.34, 20000711.4, 16949207.19, 16694171.15,
          16921489.17, 17068683.16, 17543018.55, 18393274.38, 19874227.26,
          20419036.18, 15428517.14, 14646886.63, 12874741.2, 12791651.68,
          14728654.48, 14727628.53, 14685405.49, 14697511.82, 13617808.91,
          13620705.41, 12741167.19, 12380354.35, 12389116.07, 10353205.05,
          10396572.33, 10395322.71, 11067608.93, 10129726.07, 10126109.71,
          9833809.39, 9730667.01, 9859180.08, 9189070.22, 8696803.65,
          8722671.42, 8546531.25, 8562301.98, 8580336.15, 9357437.31,
          9383092.29, 8627784.08, 8588308.07, 8594290.68, 8608858.09,
          9277649.58, 9298063.48, 9310369.63, 9682079.51, 9697104.64,
          9712778.49, 12118823.02,
        ],
      },

      {
        name: 'Escritórios, Logística, Varejo - Lojas Individuais',
        data: [
          137582236.7, 138067375.5, 138544780.4, 139047318.1, 139522726.0,
          139961308.9, 140464366.9, 140925925.9, 141420800.1, 137277573.4,
          137781058.7, 142791898.0, 143266225.0, 143794902.2, 144289598.2,
          144800882.9, 145302544.1, 145652576.7, 146160882.8, 146821472.6,
          147361287.5, 142750489.21, 143242662.7, 148425709.1, 146430254.5,
          147355169.6, 147964010.8, 148561498.0, 178605637.1, 149999511.1,
          150023338.8, 150034727.3, 150044814.75, 147100015.1, 147686865.8,
          129787517.2, 129779941.8, 129738852.2, 131289459.2, 131842763.0,
          132282376.3, 132625901.91, 132955291.1, 133316155.0, 133408409.27,
          133665747.8, 133413909.27, 133675336.9, 133766169.4, 131604318.7,
          131808335.67, 131159158.65, 130471665.38, 129341669.11, 128651605.45,
          127979071.39, 127313936.34, 126635437.89, 126703668.81, 122562166.9,
          122769482.89, 122078777.33, 122197478.5, 122553365.45, 122623613.25,
          122626022.43, 122601610.47, 122563749.52, 122586609.47, 121956300.5,
          122056307.72, 125394905.43,
        ],
      },

      {
        name: 'Escritórios, Residencial',
        data: [
          627583388.66, 668169885.43, 743222765.73, 755290383.14, 832014459.52,
          911402663.19, 925579762.66, 927741252.66, 935186533.14, 1031777745.04,
          1039771055.54, 1053776633.12, 1096339269.09, 1276524801.26,
          1280888001.81, 1315584771.43, 1318588912.74, 1307783627.48,
          1326189983.3, 1332217724.3, 1406103837.03, 1396797409.52,
          1405528488.82, 1455473335.35, 1465174901.57, 1505523599.72,
          1563557976.47, 1515673644.12, 1588919701.08, 1652938319.61,
          1659662820.56, 1687634422.84, 1726865254.0, 1787820902.85,
          1794687422.86, 2216175219.96, 2216478158.12, 2131478081.94,
          2218044077.99, 2266323223.3, 2231204832.96, 2198781501.72,
          2240450156.9, 2271566387.3, 2312907959.31, 2323544272.49,
          2400863318.13, 2368246635.78, 2373367031.56, 2405549005.05,
          2444094072.33, 2470698957.7, 2535613809.66, 2574664376.25,
          2470654247.51, 2606869422.88, 2496204661.03, 2501828157.15,
          2515486184.47, 2661544086.87, 2575491089.73, 2661197805.67,
          2671992005.86, 2702073094.02, 2694797725.08, 2661053640.38,
          2648316186.95, 2632543146.22, 2668401421.46, 2676320652.45,
          2711298826.07, 2712210610.3,
        ],
      },

      {
        name: 'Escritórios, Residencial, Varejo - Lojas Individuais',
        data: [
          108284548.84, 108254053.39, 108320055.41, 108356975.63, 108363957.85,
          108155521.62, 108276350.73, 113154134.48, 108480627.34, 108479518.82,
          108532488.88, 106338792.35, 100684912.0, 100165594.18, 100657660.82,
          110369401.47, 110419163.56, 110893658.28, 110207726.15, 110675740.5,
          111012984.14, 111600947.19, 110400017.26, 110849445.29, 111212070.74,
          185170105.72, 229969189.79, 228499377.93, 230110416.79, 226580732.91,
          233485202.04, 233640760.78, 223166410.14, 221699733.82, 225959719.42,
          223104257.04, 222079667.39, 222377750.88, 223761189.39, 236886395.96,
          234470844.49, 247894723.29, 262181091.64, 263892515.07, 280884387.91,
          277730725.68, 253100668.18, 255722101.76, 256104292.23, 253757714.51,
          245893184.46, 236688752.55, 236469252.27, 233821323.39, 235195411.26,
          238893294.48, 236514917.84, 232471355.93, 227277178.77, 226199122.93,
          226278959.8, 252496067.79, 253763269.01, 253933141.59, 246115828.46,
          242294814.41, 242687087.77, 242569590.14, 242662486.96, 242489885.01,
          242198113.87, 214099296.21,
        ],
      },

      {
        name: 'Escritórios, Varejo',
        data: [
          329224332.07, 329981616.88, 330770849.37, 331462788.37, 332281407.68,
          330880403.49, 333162733.59, 330297432.8, 338820913.31, 340703137.75,
          346061054.17, 345076125.85, 345497549.42, 342224817.7, 343315515.72,
          343801800.48, 342785341.03, 341402986.56, 351250279.52, 352387489.79,
          351569274.56, 352456719.55, 371103997.86, 371167701.09, 368116127.72,
          367142305.52, 400990186.4, 401302849.2, 401803355.61, 400568203.44,
          400968894.05, 401032879.49, 401767574.31, 402545924.83, 396586907.24,
          373891321.68, 374739157.18, 375477326.22, 376489608.48, 376017116.96,
          376855249.34, 373862790.76, 374399832.34, 374475164.24, 375557249.82,
          440522012.54, 442131548.07, 363790452.38, 361636752.91, 361297875.24,
          360919400.42, 360466163.6, 360516817.97, 360334429.11, 370387632.51,
          371110972.6, 371212789.21, 378969312.82, 384062745.94, 429279406.49,
          428103443.74, 428156868.21, 454112765.85, 454376190.62, 470159589.79,
          484183828.98, 489229853.06, 490021403.19, 522855405.76, 525164520.64,
          526124396.31, 505106518.75,
        ],
      },

      {
        name: 'Escritórios, Varejo - Lojas Individuais',
        data: [
          92595893.43, 94780299.03, 94978136.95, 94330971.74, 94387651.9,
          98184999.89, 96514652.29, 97889403.86, 98002236.09, 99619706.25,
          99343532.5, 201306982.6, 202265838.2, 202640601.7, 202616589.1,
          202711064.6, 203223724.1, 228432111.7, 239568454.9, 230178675.3,
          230069501.9, 230443732.32, 230780595.1, 251582629.1, 251684651.7,
          252006402.9, 253241136.2, 253218404.5, 253188541.9, 252214024.0,
          251942433.7, 252012717.2, 252754313.42, 252771541.1, 252951947.6,
          247267431.56, 247634764.7, 248104275.6, 247733253.6, 247786515.5,
          254356373.9, 254740992.29, 255065603.7, 255038644.6, 255241751.69,
          255252957.1, 253338319.3, 189248837.24, 195051162.05, 195632645.51,
          196238301.09, 196745540.83, 194682613.92, 195185226.39, 195700769.4,
          196478286.31, 197058206.88, 197266687.7, 197298203.83, 195608594.3,
          195826719.93, 195869779.62, 195891852.31, 195872886.56, 195925821.91,
          195825346.53, 195865393.97, 195842870.11, 195644934.32, 195528144.97,
          195179318.65, 204374810.55,
        ],
      },

      {
        name: 'Escritórios, Varejo, Varejo - Galerias',
        data: [
          13274500.61, 13273430.59, 13275194.47, 13271369.5, 13273711.15,
          13275254.09, 13277325.42, 13278381.54, 13279480.82, 13281558.73,
          13282771.31, 13284149.49, 13285603.68, 57671238.65, 57670285.75,
          57666820.53, 57664908.74, 57514825.33, 57607441.32, 57566261.62,
          57524010.18, 57590435.67, 57457835.74, 57429864.36, 57488667.19,
          57492647.17, 57490295.14, 57484001.61, 57486545.8, 57483079.96,
          57480727.93, 57478020.8, 57455058.99, 57427507.74, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },

      {
        name: 'Especial',
        data: [
          1230256496.99, 1241655548.6, 1229047423.54, 1226956753.99,
          1222837522.94, 1090714303.22, 1114733531.94, 1127109421.48,
          1129359487.93, 1138031711.96, 1174677059.53, 1178250334.54,
          1317011245.64, 1314697818.38, 1215077589.96, 1244265409.94,
          1273663122.63, 1270862601.12, 1271157403.07, 1268187605.12,
          1267484426.01, 1322790303.4, 1252490274.08, 1313786566.1,
          1316317932.52, 1301498311.87, 1257199375.92, 1600079724.53,
          1595220519.6, 1594411348.71, 1601407143.94, 1600629485.4,
          1640877640.89, 1642112602.54, 1640307292.48, 1705161977.97,
          1705512968.18, 1766036801.18, 1776103322.28, 1729689085.77,
          1729638581.48, 1729126853.98, 1728283484.42, 1730407253.87,
          1726270754.77, 1725938715.58, 1726594501.24, 1966070773.67,
          1976591992.39, 1986716774.36, 1996484603.54, 2006270238.44,
          3029602058.87, 3034210355.3, 3027694086.37, 3152244102.19,
          3152717637.13, 3240085576.27, 3454747959.4, 3796576672.99,
          3762736824.61, 3762230030.96, 3763976004.5, 3785174320.0,
          3778316887.93, 3781380739.94, 3782378784.57, 3778720838.82,
          3812784677.98, 3975199073.36, 4027518701.05, 3332035268.31,
        ],
      },

      {
        name: 'Especial, Logística, Varejo',
        data: [
          62294207.45, 62274505.68, 62252864.12, 62207265.57, 62185094.42,
          62113161.48, 62085887.81, 62123178.89, 62297896.09, 62271783.71,
          62346402.25, 66744884.03, 66820287.38, 66890259.13, 66892445.64,
          66961058.01, 67062129.4, 67114115.65, 67108923.49, 66989323.19,
          66727613.98, 66603217.65, 66454098.63, 70738452.99, 70991075.43,
          71043224.47, 71033898.87, 71148939.07, 71174476.17, 71070459.4,
          71164142.79, 71043154.74, 71024813.92, 71027897.11, 71049472.27,
          70935621.6, 70854621.03, 70875390.38, 70896536.47, 71897544.49,
          71779395.76, 71739342.92, 71760600.77, 72065657.38, 72145308.93,
          72262740.16, 72180400.81, 69149902.86, 69063597.23, 69778736.29,
          69694668.57, 69639673.69, 69555936.69, 69472008.52, 69387470.25,
          69655478.41, 69569090.61, 69479089.0, 69393354.23, 66381455.52,
          66290814.35, 66243954.25, 66788214.02, 66690286.59, 66586920.1,
          51243309.62, 51091229.89, 51561220.6, 51474260.92, 51436406.11,
          51281708.89, 51208030.24,
        ],
      },

      {
        name: 'Fazenda',
        data: [
          952636732.62, 953447875.23, 982469852.13, 985524564.84, 989880275.73,
          987369523.13, 1005293377.6, 1005757641.28, 989858017.39, 991571386.85,
          990685685.59, 1033582333.55, 1034874476.69, 1036900449.24,
          1029437342.94, 1038803524.73, 1042641114.1, 1039284871.06,
          1040090843.37, 1041265850.68, 1048374581.14, 1049093799.51,
          1037138668.35, 1074101252.7, 1098210515.47, 1124521971.21,
          1113275382.89, 1124806763.86, 1125564510.53, 1123706933.06,
          1123042405.93, 1125254739.54, 1119386426.34, 1591974965.94,
          1585127548.19, 1649544647.32, 1646411987.42, 1673469753.3,
          1665092978.92, 2303712328.4, 2299688373.88, 2824672076.7,
          2890154298.66, 2927258489.83, 2640359778.95, 2947015042.61,
          2951402742.9, 3371795306.01, 3361718310.23, 3357368307.42,
          3404942428.48, 3533038643.39, 3662088080.08, 3836536503.35,
          3819970466.16, 3826156978.6, 3861635765.16, 3860173611.82,
          3863015121.02, 4257990857.06, 4287901497.21, 4284801630.63,
          4567734851.63, 4837070755.22, 4846721493.54, 5120959475.36,
          5098412202.56, 5157852042.21, 5327439383.4, 6117577174.25,
          5941711613.88, 6274458187.08,
        ],
      },

      {
        name: 'Fazenda, Industrial',
        data: [
          435720373.55, 436293015.0, 435413610.0, 434448680.1, 435403772.2,
          429010689.8, 431672048.3, 431157016.0, 430505081.7, 426948618.4,
          427118599.3, 402643077.6, 402384385.9, 403576008.7, 401309358.3,
          355802829.7, 355257676.0, 354954744.1, 352596874.4, 351893361.8,
          351291558.2, 308231077.48, 301769742.7, 264403924.0, 263487708.8,
          262541709.0, 262502053.6, 258058988.2, 257870592.1, 212558211.9,
          212400647.9, 212142015.8, 211873718.85, 211690894.3, 208655624.6,
          209883773.5, 207371378.7, 207229734.9, 206974551.4, 206865167.7,
          206692147.1, 206463008.27, 206285955.7, 206198336.8, 205397981.93,
          190751950.8, 186618059.51, 178256651.5, 178477309.63, 174314777.74,
          174305379.57, 174348894.18, 173397207.5, 136158823.92, 128079708.99,
          126360071.95, 126287653.98, 126599937.03, 128503165.29, 111062213.63,
          110222705.21, 110050874.55, 110105592.32, 109896771.31, 103679820.57,
          99848469.49, 100405346.4, 100393708.38, 100162283.32, 89659732.12,
          89188013.89, 87717102.09,
        ],
      },

      {
        name: 'Hospedagem',
        data: [
          397688864.2, 397360381.3, 400827750.8, 401219059.76, 401078974.63,
          400987984.38, 401174103.25, 400588733.06, 400506206.09, 401815490.29,
          401146544.38, 398701058.6, 563686931.03, 714908405.77, 879019922.74,
          715476246.44, 714725163.3, 715520816.39, 716391208.9, 715233866.39,
          715686840.48, 716467347.7, 735029469.08, 792461077.72, 901146725.66,
          846754161.07, 906121103.45, 905670117.91, 904713040.46, 904872042.44,
          902064769.29, 906522818.93, 906219853.65, 907832146.2, 907357444.39,
          741802818.18, 1054844426.1, 1150972506.15, 1234355342.6,
          1234458910.23, 1232758592.67, 1256970725.62, 1255898478.18,
          1276684836.4, 1312873447.62, 1321462679.7, 1310913013.95,
          1336206462.35, 1337807933.38, 1326238905.88, 1327389797.8,
          1308676767.8, 1286942395.2, 1261201030.16, 1258988667.47,
          1282237196.67, 1287239020.34, 1298465057.77, 1301370778.24,
          1373164310.06, 1360983795.17, 1373135364.65, 1373693718.02,
          1306267289.99, 1305711101.7, 1307877498.19, 1537368228.01,
          1309386311.15, 1301694640.07, 1306756704.17, 1298394737.24,
          1518128329.3,
        ],
      },

      {
        name: 'Hospedagem, Residencial',
        data: [
          109588116.3, 93043323.48, 92834927.0, 92618686.42, 92411221.37,
          92387652.23, 92712420.86, 90693401.95, 89384505.14, 92167769.99,
          91607963.08, 99416778.91, 82750789.55, 82739187.53, 83684529.93,
          83822689.94, 84086069.49, 75999859.86, 75705170.22, 66535892.12,
          66369867.39, 66352943.43, 66347328.89, 66129505.11, 66794946.8,
          62254328.42, 58305313.3, 53762623.28, 53656217.65, 35637663.14,
          34589350.81, 34656962.35, 34593232.91, 28149597.88, 27427531.22,
          27375657.37, 27421605.88, 24066647.85, 24066992.88, 20292863.91,
          20290762.33, 20304283.85, 19450952.56, 17414102.82, 17280988.72,
          14725215.69, 14713144.44, 14693955.1, 14675278.96, 14656042.17,
          14637462.6, 12618505.2, 12601537.55, 12462119.02, 9465774.36,
          9304001.39, 9290931.09, 9198730.77, 9181097.28, 9163856.88,
          9146730.34, 9134089.99, 9117015.37, 9398817.49, 8281528.33,
          8264015.85, 8246594.23, 9865176.57, 9845484.25, 9835048.89,
          9818482.54, 9788347.43,
        ],
      },

      {
        name: 'Hospedagem, Varejo',
        data: [
          220214575.82, 220368680.1, 242192622.65, 246527288.43, 270584479.03,
          270982922.09, 270588855.64, 270496352.61, 269856456.14, 270620261.79,
          270535445.08, 304494573.15, 303239663.51, 302771364.61, 304114322.15,
          303648317.57, 304519194.85, 305170747.0, 304095386.53, 303542362.4,
          323481520.97, 332966543.07, 332254037.93, 334733652.01, 351702212.53,
          350998886.73, 348414832.64, 348015325.18, 348567651.16, 349514973.9,
          348269462.82, 349125845.6, 350297119.81, 350737015.55, 353083706.02,
          346650049.87, 347005758.24, 347117642.51, 346715115.85, 347167830.31,
          347066290.2, 346571925.18, 347340892.48, 348967835.66, 349922835.27,
          366270024.35, 366551008.22, 365688918.4, 364003477.09, 364434744.24,
          364085014.8, 364128434.94, 364040460.45, 365377549.43, 365104350.77,
          366424062.48, 367942398.94, 368859443.71, 367529460.57, 377104283.17,
          376754055.24, 376470415.05, 379987475.39, 380562456.35, 383355000.53,
          379476674.5, 376704802.06, 378323330.06, 379890379.23, 381066432.3,
          382641734.78, 402549000.0,
        ],
      },

      {
        name: 'Hospitalar',
        data: [
          966866600.01, 995161495.08, 1017405250.37, 1015942966.38,
          1013639055.86, 1113291013.84, 1138125497.12, 1154837266.39,
          1420579697.48, 1410548994.6, 1438426508.62, 1516547328.3,
          1519021810.87, 1530919029.02, 1530158639.12, 1517385137.83,
          1351931583.51, 1352900268.75, 820507367.8, 1138531866.4,
          1135578906.67, 1132117799.3, 1139344206.88, 1183102771.23,
          1191564032.76, 1198158624.66, 1316716677.32, 1324696899.66,
          1333897011.44, 1309778192.73, 1310662815.91, 1306064216.59,
          1307234247.0, 1312451034.47, 1338583313.34, 1661347685.05,
          1645864881.33, 1647629136.11, 1648740407.25, 1664575721.38,
          1666316731.64, 1668357720.91, 1665281183.15, 1666313812.49,
          1666076209.37, 1656670854.54, 3345955849.22, 3775714825.94,
          3762389231.13, 3769177855.51, 3796866241.28, 3908341978.81,
          3921733972.04, 3934987176.32, 3948154468.29, 3956973290.29,
          4061176097.88, 4059802726.6, 4176921078.52, 4061064626.86,
          4051361988.19, 4073055947.02, 4067249881.27, 4079566605.65,
          4087128256.17, 4097239138.81, 4104926581.8, 4116366117.78,
          4129322245.79, 4135155835.72, 4136203726.54, 4258786090.64,
        ],
      },

      {
        name: 'Indeterminado',
        data: [
          3117413668.31, 3120904997.55, 3148147216.76, 3166754390.88,
          3167440544.69, 3395327885.87, 3446799953.88, 3451764218.78,
          3460894030.5, 3471239030.24, 3475734100.88, 3209917745.99,
          3198460038.37, 3209966473.86, 3215403603.86, 3215019425.86,
          3210511550.8, 3209185157.48, 3206498807.96, 3339036650.64,
          3313283252.34, 3310840932.32, 3180478210.19, 1878846902.06,
          1872098133.75, 1887991201.54, 2052868936.84, 2045714930.89,
          2074873123.73, 2079349965.25, 2033487204.05, 2032215612.46,
          2039282596.69, 2111571572.26, 2106393684.47, 1854898531.43,
          1809379933.8, 1718296656.37, 2118301867.06, 1909349572.03,
          1872499894.56, 1973993166.72, 2055054260.99, 2280734599.35,
          2059319712.88, 2125965939.16, 2105195288.66, 2260309842.25,
          2764505699.19, 2764023585.15, 2727759014.9, 2729034638.07,
          2772767864.38, 2814779025.59, 2908002272.33, 3144850309.09,
          3831914356.5, 3916494456.62, 4010638280.17, 4306694277.5,
          4646181960.07, 5191965362.38, 6852543199.38, 7004525530.6,
          7106430396.55, 7486481488.7, 8299085596.0, 8526419947.55,
          8711200303.82, 8889278542.17, 8836197281.8, 14435857494.77,
        ],
      },

      {
        name: 'Indeterminado, Logística',
        data: [
          -5316039.25, -5528759.89, -9990896.97, -9938834.69, -9964513.81,
          -6280161.2, -5684549.73, -3431710.84, -3975368.64, -1789574.49,
          -1618317.66, 47819949.7, 47979378.64, 43445395.16, 43643632.47,
          46342691.08, 46531608.03, 46722563.29, 48041187.04, 48515560.09,
          66242028.22, 78470350.35, 74359675.69, 64658827.55, 67045236.91,
          69486390.62, 73566523.71, 76026025.8, 79848123.16, 68390196.57,
          516420706.39, 772625278.56, 768093020.7, 756155436.83, 757709666.31,
          757573307.92, 758844787.33, 760743499.8, 758431283.15, 758574331.08,
          758729223.34, 795753305.03, 795741200.11, 795665187.26, 795649090.19,
          796075082.97, 796165432.63, 796013221.41, 795860370.46, 795902369.78,
          796688016.0, 797210116.77, 797348949.12, 801949401.12, 801950897.64,
          801893782.1, 801773069.91, 801720899.15, 801531346.63, 801284022.04,
          800973147.06, 801399653.1, 801780692.02, 802142390.29, 802407613.25,
          799040163.95, 799158943.63, 799197579.97, 799207061.28, 799361079.86,
          799329860.3, 799235587.51,
        ],
      },

      {
        name: 'Industrial',
        data: [
          1421446674.65, 1441820683.38, 1440934063.02, 1427006292.6,
          1430569094.19, 1454451602.66, 1674348117.26, 1664941478.89,
          1660899993.58, 1687249230.39, 1702850636.41, 1726478329.77,
          1787365867.22, 1968316649.04, 2106216866.65, 2114327378.99,
          2120166008.17, 2133207969.76, 2140354313.59, 2118701539.05,
          2126609635.9, 2151710256.07, 2172099959.26, 2264788141.88,
          2266535726.58, 2272474829.77, 2745320465.88, 2756863621.1,
          2763683310.42, 2785251657.9, 2789483670.24, 2804481478.75,
          2808122470.47, 2809915034.28, 2824304627.96, 3049209149.23,
          3080123088.6, 3084954367.37, 3106497632.73, 3109599203.98,
          3601151529.84, 3667455133.68, 3714672378.42, 3787643964.05,
          3818237287.8, 3810403769.81, 3910797475.51, 3879895554.31,
          3902533588.02, 3910993056.29, 3896252168.36, 3913717728.27,
          3909679385.74, 3929482945.68, 3850231984.66, 3935642805.5,
          3892679788.62, 3905815923.74, 3907156494.31, 4016422086.11,
          3970686853.7, 3978559462.03, 4152244302.5, 4059064181.95,
          4066788671.04, 3963282311.44, 3979697721.71, 3989417540.55,
          4118726710.28, 4222248554.35, 4338188167.48, 3643768452.37,
        ],
      },

      {
        name: 'Industrial, Logística, Residencial',
        data: [
          120950954.2, 121244381.7, 121352607.1, 121475956.5, 121606451.6,
          121735633.8, 120771193.5, 120710723.4, 120566984.7, 120492253.1,
          120712486.0, 120623965.8, 115986271.6, 116017599.3, 116680995.3,
          116476432.0, 116503128.1, 116717021.4, 116440604.5, 116473728.2,
          116476553.2, 116514845.76, 116598065.9, 116690660.6, 116817212.1,
          117664642.9, 117432760.8, 96628668.28, 97065995.96, 96859206.55,
          85044899.68, 84851502.88, 85042840.1, 85244979.59, 85061452.52,
          83940671.05, 44844416.04, 44621435.11, 44404010.24, 44208130.96,
          44265951.49, 45159086.99, 44710226.08, 44225000.17, 43959372.13,
          43873818.17, 43442676.45, 43198938.02, 43011291.62, 43139979.49,
          42886215.12, 42588933.72, 42320006.33, 34805184.56, 34679941.85,
          34575017.92, 33637756.65, 33744194.78, 33641488.79, 33300079.6,
          33252988.45, 33782830.8, 34038109.63, 33930888.8, 33747818.39,
          33590320.47, 33439698.24, 33380679.37, 33509509.01, 33144093.09,
          32766757.44, 33149297.92,
        ],
      },

      {
        name: 'Industrial, Residencial',
        data: [
          -2294.31, 3021137.26, 5430394.16, 6908280.33, 6918686.17, 4927076.29,
          4253163.72, 4471895.03, 5282645.71, 3755229.19, 4547077.07,
          7025792.08, -1964601.71, -2699253.27, -2625899.31, -3392582.98,
          -3722691.16, -2607064.16, -2104789.78, -895622.0, 320426.3,
          1122583.57, 2100426.88, -1122600.08, 2752769.4, 1635084.94,
          2667102.72, 4905542.91, 7444526.12, -467659.82, 3968681.12,
          4803717.45, 1246558.46, 182743425.3, 180351192.1, 191989886.85,
          303631294.1, 310467117.0, 295933849.9, 317827287.5, 317902853.0,
          311659541.61, 320601075.7, 318107660.6, 317065167.4, 306649214.5,
          305050785.01, 306646871.4, 305045182.24, 301427592.12, 279245605.48,
          282251182.41, 284810729.22, 284273042.84, 277418167.51, 286826561.1,
          256992067.3, 257687439.04, 254499846.7, 252510342.26, 250219889.15,
          246690263.01, 84596567.71, 86335571.52, 81986101.65, 72165753.93,
          70175812.7, 67928896.56, 67470855.99, 68043776.18, 48407495.68,
          44129560.52,
        ],
      },

      {
        name: 'Industrial, Residencial, Varejo',
        data: [
          221498233.65, 467962715.71, 467856367.09, 467054999.23, 467973223.55,
          466915818.77, 465563644.46, 464727074.74, 463883602.65, 492728598.57,
          492123936.7, 474247364.4, 473453605.91, 472506048.57, 471841780.81,
          471234972.74, 470599164.21, 469731792.73, 239709609.75, 239250966.08,
          238776255.4, 241287684.87, 240882600.14, 228431967.84, 227989338.53,
          227476152.9, 225900502.99, 226073358.44, 232476326.73, 232012199.93,
          231014866.74, 232100331.86, 231709230.31, 228367346.89, 228789937.28,
          226894731.75, 227630249.55, 236836346.28, 240108804.75, 239791497.74,
          264561262.08, 266500527.77, 268689537.48, 271865220.37, 273506663.18,
          277540024.14, 280017435.29, 281398490.12, 281771533.1, 282021075.12,
          282138019.69, 282379559.1, 279308581.92, 278927105.42, 278726717.08,
          280304356.17, 279521065.02, 183238694.59, 183335064.93, 728913679.91,
          210862271.69, 210597292.18, 211464032.7, 225259215.04, 209442430.33,
          209762969.86, 209539175.04, 209859623.13, 210113566.05, 210471729.02,
          210846761.83, 229033660.55,
        ],
      },

      {
        name: 'Logística',
        data: [
          3335242279.44, 3356915714.7, 3895191297.66, 3875518974.68,
          3800439103.9, 4174904673.89, 4238588844.61, 4348603886.34,
          4354413655.96, 5153759783.84, 5440558991.55, 5774745644.72,
          8416208103.52, 8477513914.94, 8589570614.41, 8636204105.21,
          9141265763.76, 9460867794.33, 9989572976.39, 10666261256.73,
          10741614373.42, 11665957279.61, 12657138383.6, 14094889019.91,
          14749683695.35, 15069737939.67, 15183840944.74, 16887029999.14,
          16985378569.03, 17350322974.11, 18755489482.1, 21066110817.4,
          21871085842.02, 23963562923.91, 24461599899.21, 26613609371.43,
          26845670712.66, 27782952455.56, 29484800195.88, 29887998426.03,
          30720171524.88, 30799534997.4, 30848700816.19, 32449967427.3,
          32476537251.37, 32557016451.35, 32570305153.69, 34158868318.05,
          34137595056.5, 34135373619.09, 34310227016.57, 34579859412.28,
          34877964367.38, 35603442111.11, 36144126620.87, 36413766010.29,
          36273472591.77, 37413681361.79, 37612512125.99, 37952104907.74,
          38505669998.12, 38503001763.75, 38512655342.3, 38554990336.62,
          39288657518.34, 39531918850.67, 41399037325.57, 42139432867.51,
          41821504633.3, 42337816689.15, 42850253237.57, 43961757301.45,
        ],
      },

      {
        name: 'Logística, Varejo',
        data: [
          130779860.89, 131114909.1, 131418969.7, 131754410.8, 132162237.6,
          132334175.7, 132736047.3, 133004383.1, 133422130.8, 133794945.2,
          134675601.8, 141431433.1, 141626248.5, 142116853.8, 142570789.7,
          143063600.6, 143619388.9, 144149463.4, 144672200.4, 145160296.5,
          145639901.7, 146115855.14, 145084619.0, 160464663.9, 145605971.1,
          145051571.4, 145416263.7, 161158000.4, 161948779.1, 162758369.6,
          161817127.1, 161721753.92, 162090801.79, 162299359.8, 162748746.3,
          172093405.14, 171834176.8, 171914834.2, 172125493.8, 172446537.7,
          172871056.1, 172703163.23, 172947658.2, 173113078.7, 173558279.05,
          174056935.3, 174069216.31, 183515019.56, 183942936.04, 184883679.15,
          185808959.34, 186244423.24, 186765744.28, 184046983.2, 184633929.98,
          184675293.16, 182763161.05, 191410672.7, 189795745.3, 204751287.7,
          191065487.16, 189423450.82, 205583304.88, 208714217.04, 209577034.23,
          207746390.88, 207411507.75, 208668915.14, 279865733.49, 288027911.84,
          290758323.49, 346886622.25,
        ],
      },

      {
        name: 'Logística, Varejo - Lojas Individuais',
        data: [
          166246259.09, 164206436.0, 164423297.8, 164677704.2, 164933699.6,
          175078027.3, 175820441.4, 176138283.4, 176421892.0, 176443872.8,
          176663139.7, 176970718.1, 180702043.2, 178379239.8, 178607730.6,
          178756836.6, 178911033.0, 179841967.3, 179859464.1, 179756687.8,
          180115338.6, 180268010.44, 180498501.3, 181459778.9, 181154745.8,
          178750077.6, 179161299.8, 179100417.9, 195032869.7, 182813548.8,
          183549268.0, 183989266.7, 184148611.43, 184205524.0, 181625348.8,
          178672692.06, 177934599.2, 179087530.4, 179055986.3, 179174483.3,
          179093742.4, 184974107.19, 185387518.4, 185574394.3, 185644589.21,
          185752616.4, 185867652.81, 185759454.5, 185831681.47, 186007628.32,
          185971206.38, 185874724.08, 186038544.78, 172464767.86, 172588674.74,
          172744337.51, 172681587.3, 172824415.02, 172614502.38, 172609310.05,
          172719505.49, 172840810.39, 172954872.36, 172972571.22, 173072424.53,
          188376016.18, 188411207.67, 188496658.48, 188556490.23, 188656079.67,
          188613590.36, 188690525.43,
        ],
      },

      {
        name: 'Residencial',
        data: [
          2820069322.81, 2847109955.01, 2859861291.01, 2879749167.26,
          2907454938.76, 2747763803.45, 2633834745.35, 2843353065.36,
          3117298453.79, 3357164935.36, 3298131333.53, 3705729141.08,
          3738177979.09, 3826996492.61, 3863095898.21, 3910536841.97,
          4236017617.91, 4152717069.5, 4289540589.79, 4543103381.85,
          4411278634.29, 4279787547.73, 4259209670.87, 4222369894.47,
          3967173061.11, 4259170304.62, 4300627901.53, 4335624971.25,
          4372542780.7, 4440004164.29, 4562695624.33, 4617817939.9,
          4716639440.47, 4871675983.95, 5033195957.45, 5106552723.57,
          5338099212.13, 5317958129.53, 5525222257.49, 5846654949.88,
          6262771195.38, 6625931572.11, 6549958207.76, 6610191613.34,
          6739128601.43, 6910771105.41, 7033234334.55, 7207180469.68,
          7397823618.29, 7377102872.46, 7418793251.68, 7505398635.91,
          7512838266.55, 7576568208.62, 7689117634.74, 7715394397.78,
          7690064539.31, 7618187027.06, 7672628052.9, 7808860464.65,
          7770321068.23, 7782923981.34, 7701257336.15, 7610743266.64,
          7654002172.69, 7673954273.64, 7814279081.51, 7856159387.8,
          7906470637.97, 7903027806.24, 7957237511.6, 7671428857.17,
        ],
      },

      {
        name: 'Residencial, Varejo',
        data: [
          458351394.3, 463323042.3, 464862507.5, 469606409.2, 482803266.6,
          559539774.9, 563870165.4, 567553361.0, 574983916.2, 577863495.2,
          585735756.9, 630700373.7, 649259310.8, 654187586.0, 668565139.3,
          672882564.4, 671808111.2, 717175690.5, 704676764.1, 719568291.3,
          731204342.7, 746597078.87, 756692893.5, 953432555.6, 967859386.8,
          989271548.4, 1001375388.0, 994918219.2, 1013990518.9, 1023728254.2,
          1037299948.0, 1048279146.0, 1056034288.03, 1058792099.5, 1065089514.1,
          1181021272.28, 1194368152.9, 1196311788.2, 1183009397.6, 1183663275.4,
          987328719.5, 1125137292.89, 1248463340.6, 1257421815.2, 998462212.32,
          1006607265.25, 1011054118.95, 1378735365.02, 1399491943.04,
          1410055244.74, 1244615306.18, 1246780569.0, 1244947278.85,
          1226477574.26, 1204942071.27, 1244973571.52, 1245486946.18,
          1258875139.31, 1271470749.88, 1455372110.57, 1449142060.34,
          1460891418.88, 1510506010.71, 1524777280.59, 1537440817.13,
          1564816089.61, 1726134574.34, 1763045615.34, 1766695326.64,
          1774592487.99, 1787592682.88, 2003308065.88,
        ],
      },

      {
        name: 'Residencial, Varejo - Lojas Individuais',
        data: [
          226060003.66, 235565763.5, 244059762.6, 243661669.1, 243606598.9,
          144000222.47, 143879590.61, 143329177.67, 143030369.79, 142905918.8,
          142512743.69, 143747213.77, 143888071.95, 143766771.62, 143565518.09,
          143392169.05, 143159834.5, 142916902.3, 95760106.73, 95567130.8,
          95415868.71, 95254569.88, 95215287.78, 94998622.49, 94849395.57,
          94755005.77, 96096431.78, 96140143.88, 95968870.78, 95959120.67,
          95703195.54, 95608642.47, 95390062.21, 136287575.7, 130163748.1,
          124089670.71, 124002274.4, 123888612.1, 122224709.7, 119122525.4,
          119119345.6, 119260695.95, 119121838.1, 120227691.9, 116401105.7,
          110751168.4, 112103878.39, 108451340.7, 108416198.23, 102042668.03,
          89957107.41, 90281399.79, 89327355.71, 83215784.41, 83049219.75,
          78097334.33, 79084967.16, 80241165.32, 79977956.76, 80115931.13,
          80303690.54, 79858015.12, 79936501.71, 80415909.07, 71728266.79,
          71557817.65, 71507048.08, 71343896.64, 71300931.5, 65008458.8,
          64836834.15, 64225072.21,
        ],
      },

      {
        name: 'Varejo',
        data: [
          15370572880.2, 15470820356.1, 16444776420.87, 16959038391.38,
          16972364346.16, 17007730615.8, 17086754190.75, 17333272494.69,
          17370564161.09, 17524142037.56, 17890289590.74, 18469182601.02,
          19703747754.24, 19879886919.83, 20547160103.34, 20815817956.68,
          20874696191.95, 20762387923.03, 22003348503.9, 22553797637.07,
          22910024260.59, 24497886167.87, 25090206596.32, 28894534533.13,
          28670705021.59, 29240066765.0, 28925309042.63, 29383385784.86,
          29528024939.53, 30128219383.68, 29978640475.57, 30130561907.48,
          30106813465.75, 30130681665.15, 30191176648.21, 30788845734.08,
          30822214997.08, 31313924523.88, 31387069903.26, 31480155777.83,
          31475956723.6, 31084473212.3, 31124078626.61, 31251091531.14,
          31304426404.7, 31751120886.11, 32081533411.23, 32225337072.96,
          32330218846.15, 32505783236.79, 32620291959.48, 32706224693.76,
          32796619016.8, 32952012507.47, 32949867253.16, 33055992584.18,
          33102735199.46, 33388117988.99, 33640666861.43, 33817493063.45,
          34117967078.54, 34378334852.89, 34463880710.13, 34512046684.48,
          34568404784.69, 35517852537.4, 35742360099.85, 36219955541.82,
          36377314074.16, 36988052135.3, 37964927619.8, 41985341432.96,
        ],
      },

      {
        name: 'Escritórios, Estacionamento',
        data: [
          0.0, 96421988.87, 96141453.06, 95969885.8, 95850640.45, 95728440.55,
          95604351.59, 95835048.94, 96094682.01, 96387956.77, 96270779.27,
          96274824.72, 116950412.6, 116963517.0, 117000002.9, 117167980.3,
          117324529.0, 117520956.8, 117299220.3, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0,
        ],
      },

      {
        name: 'Escritórios, Hospedagem, Varejo',
        data: [
          0.0, 72232257.04, 72137007.09, 72532326.92, 72627656.97, 72176316.0,
          72133061.19, 72358368.92, 72268297.35, 72244211.04, 72318254.77,
          87930092.65, 87978869.32, 88122857.12, 88082703.22, 88043713.64,
          87974360.49, 87921942.66, 87962054.25, 87976028.34, 88009616.13,
          88045747.24, 88090203.78, 90491281.64, 90549777.34, 90554690.87,
          90423572.12, 90406223.85, 90407468.25, 90418141.4, 90403230.62,
          90444450.86, 90448804.11, 90459404.61, 90490113.4, 79241612.06,
          79252493.97, 79183700.51, 79151217.54, 79125016.62, 79060153.0,
          79032278.81, 79015172.62, 79040300.76, 79016416.25, 78992496.31,
          79009528.93, 75308631.07, 75133612.2, 77799824.27, 78336432.25,
          78984616.06, 79910289.95, 80694317.97, 81474663.3, 82488951.36,
          82388382.15, 82363484.34, 82299087.2, 71939973.82, 71930941.49,
          71895390.14, 72017767.86, 71879148.69, 71950652.95, 72060703.05,
          71984230.05, 71977120.7, 72080155.3, 72193495.58, 72179699.45,
          79374720.64,
        ],
      },

      {
        name: 'Escritórios, Logística, Residencial',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 1050000.0, 2236667.66, 2729207.35, 4455136.09, 5199987.87,
          5955250.91, 22412083.96, 25564208.4, 89865216.6, 98021722.21,
          98637186.1, 118074068.64, 120127321.55, 120278724.18, 131918495.56,
          132826453.38, 143474577.27, 143786603.03, 143793400.6, 143493482.13,
          142806337.11, 149975569.24, 160416709.73, 170992897.76, 175822411.49,
          188876909.47, 261713250.11, 262589263.28, 269068457.79, 270112945.74,
          271250638.72, 272393765.36, 271363039.63, 267454046.96, 271007409.54,
          290981819.33, 294302522.9, 299623477.41, 304800906.7, 279018867.15,
          279642216.83, 287719399.04, 275247216.63, 277129848.47, 289615484.18,
          290729778.33, 292361367.77, 294300958.31, 295041959.24, 295420966.64,
          290783723.45, 291944578.88, 203864443.79, 206124946.47, 206087659.78,
          198177251.3, 206837929.44,
        ],
      },

      {
        name: 'Escritórios, Hospedagem, Industrial, Residencial',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 6103182.48, 43956884.39, 66628863.47, 70138880.69,
          130939529.2, 130505281.06, 134803333.0, 164485223.6, 157990857.8,
          180172775.3, 178695723.4, 203609314.1, 206332600.7, 236013016.7,
          234691102.7, 238910741.9, 237352154.63, 234986189.9, 200206007.6,
          195915402.4, 194598157.2, 154438604.3, 154152401.3, 111846934.2,
          115612488.1, 115279965.76, 97984682.56, 97648002.65, 96933331.78,
          80199400.49, 80146041.55, 49648686.88, 41289767.66, 39004820.12,
          38525321.69, 32822114.72, 30601682.58, 30097087.35, 29232548.35,
          27204679.14, 21351055.58, 20420661.04, 17378521.38, 16427235.96,
          12659441.17, 10110090.4, 8107952.23, 7929024.65, 7196064.51,
          6554214.25, 5841251.78, 5338762.43, 3480260.7, 0.0, 0.0, 0.0,
        ],
      },

      {
        name: 'Especial, Logística',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 18345067.49, 18418079.97, 21157765.15, 21215999.22,
          21120927.87, 21691544.7, 21117730.71, 21098113.47, 21786477.43,
          21928788.68, 23645812.17, 23615397.61, 23653641.23, 23073235.63,
          23020273.67, 22872997.89, 22991878.95, 22946717.67, 42973119.21,
          43013192.64, 45463203.39, 44773404.5, 42242859.0, 162535425.0,
          162232760.1, 164991794.92, 164460337.1, 164746870.6, 164724630.31,
          164848396.6, 164876366.56, 252891029.4, 287765894.46, 288471199.34,
          288419391.7, 288408741.91, 324578304.29, 370879933.72, 371448240.71,
          370887371.89, 370290091.16, 369807834.19, 369956160.07, 371534654.03,
          371583955.47, 371604361.89, 371607743.61, 371508637.14, 371250412.71,
          371547925.5, 370802272.62, 370789959.48, 370703430.4, 374345644.82,
          376984265.27, 370996795.08,
        ],
      },

      {
        name: 'Escolar, Logística',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 35933275.34, 35836689.22,
          35696400.96, 51068339.67, 51055591.21, 51043412.57, 51857451.73,
          51978028.44, 51978788.0, 51990141.81, 51952370.01, 37472937.57,
          37563291.29, 22944085.07, 37292516.65, 65765742.98, 22884556.92,
          23232607.55, 25566994.94, 25290452.82, 26321297.97, 27178728.05,
          118924233.7, 117631066.1, 120962305.1, 134341321.57, 125221361.94,
          127252326.08, 141569007.33, 144727828.81, 136389337.47, 139232291.3,
          215180987.4, 231483050.85, 234185441.29, 236923610.69, 241217042.46,
          245444984.55, 249711450.21, 261846981.83, 267977094.55, 272422419.68,
          277563969.09, 281979413.59, 285710669.37, 290668150.86, 294458435.7,
          298692556.72, 302805133.65, 307599106.69,
        ],
      },

      {
        name: 'Escolar, Escritórios, Logística',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1242.99, 898.3,
          18875.0, 17177.16, 15370.97, 13552.58, 21670.22, 19870.36, 18023.5,
          16211.0, 1114006.27, 15068203.41, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
      },

      {
        name: 'Hospitalar, Varejo',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 10042342.64,
          10042342.64, 0.0, 16475652.06, 16447415.7, 16477269.07, 0.0,
          45376789.82, 53716692.45, 105993354.9, 104580859.9, 105876032.93,
          105753009.4, 105886495.9, 105838049.8, 105965450.5, 106070885.4,
          109870836.84, 109620498.9, 109938982.4, 109927362.45, 109887149.6,
          109832723.37, 140392080.5, 139527018.93, 139438160.75, 138975156.25,
          138304536.95, 137475328.4, 139510715.73, 139260195.05, 139909108.47,
          140436307.81, 141074315.71, 167685996.26, 166888383.24, 167054672.37,
          167003499.89, 189048217.78, 188773637.89, 188269169.98, 212320474.4,
          211079442.48, 211084800.17, 211043876.41, 213439676.2, 213561700.19,
          235321427.84,
        ],
      },

      {
        name: 'Agências, Especial, Varejo',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 24698836.57, 27628236.78, 27543443.72,
          27507194.35, 27451461.18, 42768033.29, 32382701.5, 31713659.42,
          33987115.44, 35979532.72, 36136567.55, 36282857.71, 36479111.64,
          36594627.49, 36717017.28, 42490657.32, 42611092.78, 42729454.94,
          42715718.13, 42645698.07, 42693577.85, 42706210.6, 42705312.45,
          42695966.35, 42698691.09, 42890672.61, 43033167.66, 37179877.46,
          37351534.42, 37679596.15, 38014868.39, 38435673.72, 38995086.33,
          39342439.7, 39640769.09, 40225271.87, 40572485.74, 40820206.59,
          40841857.85, 40847240.2,
        ],
      },

      {
        name: 'Fazenda, Indeterminado',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1271199.07, 1237189.65, 2353527.47,
          10696948.08, 10595697.99, 10584405.3, 10627483.79, 10589799.25,
          10591358.69, 10584320.4, 10621724.24, 10742194.42, 10738048.11,
          10738465.76, 10738069.9, 27639140.04, 27638409.26, 28736391.49,
          28729483.48, 28728313.13, 29328037.54, 29338727.82, 32820157.21,
          34369999.9, 47326509.11, 48198677.81, 48389995.51, 48598202.54,
          51821008.15, 52027345.37, 53269338.93, 53570159.92, 57186487.18,
          60911676.8, 73527444.11, 80741892.95, 125692503.45,
        ],
      },

      {
        name: 'Fazenda, Residencial',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1271199.07, 1240789.65, 2357137.42,
          2325747.14, 2295443.17, 2465042.47, 2634551.74, 2835129.66,
          3034749.87, 3235590.33, 3470332.96, 4101924.63, 4635017.19,
          10598657.99, 10863938.24, 25778917.82, 25843705.07, 28894180.85,
          28885536.49, 28947247.05, 33092538.56, 35731544.5, 39181868.84,
          41031099.07, 41003931.65, 45860585.82, 42535605.11, 42817336.31,
          51910925.07, 52693092.29, 53257998.4, 54933765.31, 56843227.37,
          59968752.07, 72914660.09, 82845522.51, 122926690.06,
        ],
      },

      {
        name: 'Industrial, Logística',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 599002009.2, 598847678.7,
          599475171.7, 600980508.8, 601681912.79, 615112551.08, 614624948.9,
          610608924.04, 614178111.33, 651273163.56, 653248038.34, 656054049.03,
          656148198.88, 653890613.52, 659301627.28, 659517017.58, 657279758.32,
          678797712.89, 676852164.02, 697131236.2, 709801568.17, 718542104.58,
          731975061.24, 733732636.07, 740352003.27, 747402859.5, 758917035.87,
          766841864.21, 802128709.12, 775569939.21, 797727229.54, 857357328.88,
          875121872.8, 888569027.84, 902358184.68, 921502889.16,
        ],
      },

      {
        name: 'Escolar, Hospitalar, Logística, Varejo',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          258950199.0, 260567762.54, 260495377.5, 259672730.5, 258342180.74,
          257339386.2, 255916293.24, 260808926.2, 260302313.18, 259739532.45,
          259530239.98, 258248232.96, 256297429.41, 255230085.92, 254972381.8,
          255344132.45, 255882653.78, 256568105.47, 256267930.82, 247977447.94,
          247505992.49, 247028879.95, 246170038.89, 245713980.83, 247609446.67,
          244360149.32, 244201443.22, 244119645.9, 244044196.76, 243871547.21,
          243541710.53, 241376040.4,
        ],
      },

      {
        name: 'Escritórios, Residencial, Varejo',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          25910.19, 979.07, 1087587.36, 1048026.15, 1034704.4, 1048401.6,
          40653474.15, -11895247.93, 133102986.56, 41722656.0, 42440034.11,
          48337399.96, 63322470.0, 66800199.69, 69694545.45, 69873195.22,
          80362273.44, 81155733.89, 199668437.1, 203930869.25, 210553049.87,
          216424427.89, 217631304.46, 218880482.95, 222785706.21, 224092364.16,
          223331132.67, 226174059.28, 228958115.62, 233475278.17, 238508462.11,
        ],
      },

      {
        name: 'Fazenda, Logística',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 93504058.67, 88430225.47, 88850009.79,
          89237034.98, 89734360.85, 91238126.09, 91339997.45, 91818037.77,
          90035221.15, 90552842.05, 90977430.4, 94379856.19, 94693627.88,
          94955153.91, 94955153.91, 93821605.7, 95938007.46, 95940278.1,
          95925802.17, 95879867.32, 119368327.23, 119325521.99, 119205223.42,
          119075497.24, 148361111.32, 148415613.98,
        ],
      },

      {
        name: 'Fazenda, Logística, Residencial, Varejo',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 34658990.4, 34957034.23,
          35124532.25, 35353173.89, 35536604.73, 32664467.64, 32734172.34,
          32874189.07, 33027679.0, 32515887.91, 33330418.13, 33610847.01,
          33916233.15, 34200620.97, 34233842.95, 34236109.44, 34605863.26,
          34983069.78, 35264564.07, 35539067.81, 35684072.43, 33130833.28,
        ],
      },

      {
        name: 'Escritórios, Hospitalar',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 308998868.31,
        ],
      },
    ],
    animated: false,
    numeric: false,
    stacked: true,
    categories: [
      '01/18',
      '02/18',
      '03/18',
      '04/18',
      '05/18',
      '06/18',
      '07/18',
      '08/18',
      '09/18',
      '10/18',
      '11/18',
      '12/18',
      '01/19',
      '02/19',
      '03/19',
      '04/19',
      '05/19',
      '06/19',
      '07/19',
      '08/19',
      '09/19',
      '10/19',
      '11/19',
      '12/19',
      '01/20',
      '02/20',
      '03/20',
      '04/20',
      '05/20',
      '06/20',
      '07/20',
      '08/20',
      '09/20',
      '10/20',
      '11/20',
      '12/20',
      '01/21',
      '02/21',
      '03/21',
      '04/21',
      '05/21',
      '06/21',
      '07/21',
      '08/21',
      '09/21',
      '10/21',
      '11/21',
      '12/21',
      '01/22',
      '02/22',
      '03/22',
      '04/22',
      '05/22',
      '06/22',
      '07/22',
      '08/22',
      '09/22',
      '10/22',
      '11/22',
      '12/22',
      '01/23',
      '02/23',
      '03/23',
      '04/23',
      '05/23',
      '06/23',
      '07/23',
      '08/23',
      '09/23',
      '10/23',
      '11/23',
      '12/23',
    ],
  },
};
