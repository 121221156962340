import { useEffect, useState } from 'react';

// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Chip, Grid, makeStyles } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

// Global components
import Competence from '../../../../../components/Competence';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_CREDITS_DUE_COMPOSITION_DATASET } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  itens: {
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    border: 'none',
  },
}));

export default function OperationFIDCCompositionDC({ hash, cnpj }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const [chip, setChip] = useState('segments');
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const { data, loading, error } = useQuery(
    GET_DETAILS_FIDC_CREDITS_DUE_COMPOSITION_DATASET,
    {
      variables: { cnpj },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_ativo_composicao_direitos_creditorios'
      ],
    },
  );

  useEffect(() => {
    if (chip === 'segments') {
      setLabels(
        data?.getDetailsFidcCreditsDueCompositionDataset?.segmentsDataset
          ?.labels,
      );
      setDatasets(
        data?.getDetailsFidcCreditsDueCompositionDataset?.segmentsDataset
          ?.datasets,
      );
    } else if (chip === 'maturity') {
      setLabels(
        data?.getDetailsFidcCreditsDueCompositionDataset?.maturityDataset
          ?.labels,
      );
      setDatasets(
        data?.getDetailsFidcCreditsDueCompositionDataset?.maturityDataset
          ?.datasets,
      );
    } else if (chip === 'delinquencies') {
      setLabels(
        data?.getDetailsFidcCreditsDueCompositionDataset?.delinquenciesDataset
          ?.labels,
      );
      setDatasets(
        data?.getDetailsFidcCreditsDueCompositionDataset?.delinquenciesDataset
          ?.datasets,
      );
    } else if (chip === 'paidInAdvance') {
      setLabels(
        data?.getDetailsFidcCreditsDueCompositionDataset?.paidInAdvanceDataset
          ?.labels,
      );
      setDatasets(
        data?.getDetailsFidcCreditsDueCompositionDataset?.paidInAdvanceDataset
          ?.datasets,
      );
    }
  }, [
    chip,
    data?.getDetailsFidcCreditsDueCompositionDataset?.segmentsDataset?.labels,
    data?.getDetailsFidcCreditsDueCompositionDataset?.segmentsDataset?.datasets,
    data?.getDetailsFidcCreditsDueCompositionDataset?.maturityDataset?.labels,
    data?.getDetailsFidcCreditsDueCompositionDataset?.maturityDataset?.datasets,
    data?.getDetailsFidcCreditsDueCompositionDataset?.delinquenciesDataset
      ?.labels,
    data?.getDetailsFidcCreditsDueCompositionDataset?.delinquenciesDataset
      ?.datasets,
    data?.getDetailsFidcCreditsDueCompositionDataset?.paidInAdvanceDataset
      ?.labels,
    data?.getDetailsFidcCreditsDueCompositionDataset?.paidInAdvanceDataset
      ?.datasets,
  ]);

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Subtitle title="Composição dos Direitos Creditórios" />
      <Competence value={hash} searchType="HASH_ID" />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_composicao_direitos_creditorios'
          ]
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_composicao_direitos_creditorios'
          ]
        }>
        <Box p={2}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Chip
                label="Segmentos"
                color={chip === 'segments' ? 'secondary' : 'default'}
                variant={chip === 'segments' ? 'default' : 'outlined'}
                onClick={() => setChip('segments')}
                className={classes.itens}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Prazo de Vencimento"
                color={chip === 'maturity' ? 'secondary' : 'default'}
                variant={chip === 'maturity' ? 'default' : 'outlined'}
                onClick={() => setChip('maturity')}
                className={classes.itens}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Faixa de Inadimplência"
                color={chip === 'delinquencies' ? 'secondary' : 'default'}
                variant={chip === 'delinquencies' ? 'default' : 'outlined'}
                onClick={() => setChip('delinquencies')}
                className={classes.itens}
              />
            </Grid>
            <Grid item>
              <Chip
                label="Pagos Antecipadamente"
                color={chip === 'paidInAdvance' ? 'secondary' : 'default'}
                variant={chip === 'paidInAdvance' ? 'default' : 'outlined'}
                onClick={() => setChip('paidInAdvance')}
                className={classes.itens}
              />
            </Grid>
          </Grid>
        </Box>
        <Bar
          data={{
            labels: labels ?? [],
            datasets: datasets ?? [],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    return `${
                      tooltipItem.label
                    }: ${tooltipItem.raw.toLocaleString('pt-BR')}`;
                  },
                },
              },
            },
            indexAxis: 'y',
            scales: {
              x: {
                ticks: {
                  callback: (value) => {
                    return value.toLocaleString('pt-BR');
                  },
                },
              },
            },
          }}
        />
      </If>
    </Box>
  );
}
