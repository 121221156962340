// Material ui components
import { Box, makeStyles, Typography } from '@material-ui/core';
// Typechecking
// Material ui icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  titleDisabled: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: '1.5rem',
    marginLeft: theme.spacing(1),
  },
}));

export default function GeneralDataItemConditional({
  title,
  condition,
  justify,
}) {
  const classes = useStyles();
  return (
    <Box p={2} display="flex" alignItems="center" justifyContent={justify}>
      <Typography
        variant="subtitle1"
        className={condition ? classes.title : classes.titleDisabled}>
        {title}
      </Typography>
      {condition ? (
        <CheckCircleIcon
          fontSize="inherit"
          color="secondary"
          className={classes.icon}
        />
      ) : (
        <HighlightOffIcon color="disabled" className={classes.icon} />
      )}
    </Box>
  );
}

GeneralDataItemConditional.propTypes = {
  title: PropTypes.string.isRequired,
};
