import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Checkbox, Menu, MenuItem, Typography } from '@mui/material';

export default function ConvocationType({
  INITIAL_STATE,
  state,
  setState,
  data,
  disabled,
}: {
  INITIAL_STATE: any;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  data?: any;
  disabled: boolean;
}) {
  const types = ['AG - Especial de Investidores', 'AGE', 'AGO', 'AGO/E'];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCheck = (type: string | string[]) => {
    setState((prev: any) => {
      if (Array.isArray(type)) {
        return {
          ...prev,
          type: state.type.length === INITIAL_STATE.type.length ? [] : type,
        };
      }

      const updatedType = state.type.includes(type)
        ? state.type.filter((s: string) => s !== type)
        : state.type.concat(type);

      return {
        ...prev,
        type: updatedType.sort(),
      };
    });
  };

  return (
    <>
      <Button
        id="lock-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        disabled={disabled}
        sx={(theme) => ({
          color:
            state.type.length > 0 &&
            state.type.length !== INITIAL_STATE.type.length
              ? theme.palette.primary.main
              : '#4F4F4F',
        })}>
        Tipo de assembleia:{' '}
        {state.type.length === INITIAL_STATE.type.length
          ? 'Todos'
          : state.type.length > 0
            ? `+${state.type.length}`
            : 'Nenhum'}
      </Button>
      {data && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxHeight: '40dvh',
            },
          }}
          aria-disabled={disabled}>
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&.Mui-selected': {
                backgroundColor: '#F1F1F1',
              },
            }}
            selected={state.type.length === INITIAL_STATE.type.length}
            onClick={() => handleCheck(types)}>
            <Checkbox
              checked={INITIAL_STATE.type.length === state.type.length}
              inputProps={{ 'aria-label': 'checkbox' }}
              color="secondary"
              size="small"
            />
            <Typography fontSize={14} fontWeight={700} color={'#000'}>
              Todas
            </Typography>
          </MenuItem>
          {types.map((type, index) => (
            <MenuItem
              key={index}
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={() => handleCheck(type)}>
              <Checkbox
                checked={state.type.includes(type)}
                inputProps={{ 'aria-label': 'checkbox' }}
                color="primary"
                size="small"
              />
              <Typography fontSize={12} fontWeight={600} color={'#000'}>
                {type}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
