import { forwardRef, ReactNode } from 'react';

import { ExpandMore, LockOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';

import { CustomTooltip } from '../CustomTooltip';

interface AccordionControlledProps {
  children: ReactNode;
  title: string;
  disabledReason?: string;
}

type Props = AccordionProps & AccordionControlledProps;

const AccordionControlled = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { title, children, disabledReason, ...restProps } = props;
  return (
    <Box sx={{ marginTop: '26px' }}>
      <CustomTooltip
        title={
          props.disabled
            ? disabledReason ??
              'Esta funcionalidade não está disponível para o seu plano, faça um upgrade para utilizar este recurso'
            : ''
        }
        placement="top">
        <Accordion
          {...restProps}
          ref={ref}
          TransitionProps={{ unmountOnExit: true }}
          variant={'outlined'}
          sx={(theme) => ({
            border: 0,
            '&.Mui-expanded': {
              boxShadow: theme.shadows[1],
            },
          })}>
          <AccordionSummary
            expandIcon={
              <>
                {props.disabled && <LockOutlined />}
                <ExpandMore />
              </>
            }
            aria-controls="filter-segments-content"
            id="filter-segments-header"
            sx={{
              backgroundColor: '#FAFAFA',
              minHeight: '58px',
              '&.Mui-disabled': {
                opacity: '1 !important',
                color: '#828282 !important',
              },
            }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '18px',
              }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              padding: theme.spacing(0),
            })}>
            {children}
          </AccordionDetails>
        </Accordion>
      </CustomTooltip>
    </Box>
  );
});
AccordionControlled.displayName = 'AccordionControlled';

export default AccordionControlled;
