import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';

import ConvocationAutocomplete from './ConvocationAutocomplete';

export default function ConvocationOperations({
  state,
  setState,
  disabled,
  market,
}: {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  disabled: boolean;
  market: any;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (newValue: string) => {
    setState((prev: any) => {
      return {
        ...prev,
        operations: newValue,
      };
    });
  };

  return (
    <>
      <Button
        id="lock-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        disabled={disabled}
        sx={(theme) => ({
          color: state.operations.length
            ? theme.palette.primary.main
            : '#4F4F4F',
        })}>
        Operações:{' '}
        {state.operations.length ? `+${state.operations.length}` : 'Todas'}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          sx: {
            p: 0,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '40dvh',
            maxWidth: '40ch',
            minWidth: '35ch',
          },
        }}
        aria-disabled={disabled}>
        <Box mt={1} p={2}>
          <Typography fontSize={'0.875rem'} fontWeight={700} color={'#333'}>
            Operações
          </Typography>
          <ConvocationAutocomplete
            disabled={disabled}
            handleChange={handleChange}
            value={state.operations}
            market={market}
          />
        </Box>
        {state.operations.length > 4 && (
          <div>
            <Divider aria-hidden="true" />
            <ListItemButton
              onClick={() => null}
              sx={(theme) => ({
                fontSize: 14,
                fontWeight: 700,
                textAlign: 'center',
                color: theme.palette.secondary.main,
                '&:hover': {
                  color: theme.palette.primary.main,
                  textDecoration: 'underline',
                },
              })}>
              <ListItemText disableTypography>Ver tudo</ListItemText>
            </ListItemButton>
          </div>
        )}
      </Menu>
    </>
  );
}
