import { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useMutation } from 'react-query';
import { State } from 'src/types/radar';
import { useQueryParams } from 'src/utils/hooks';

import { useAuthContext } from '../../../../../../context/AuthContextProvider';
import { getServiceProviders } from '../../../../../../services/radar';

type Props = {
  state: State;
  value: string[];
  name: string;
  handleChange: any;
  disabled: boolean;
  classes: any;
};

export default function RadarAutoComplete({
  disabled,
  handleChange,
  value,
  name,
  classes,
  state,
}: Props) {
  const { auth } = useAuthContext();
  const [{ query }, setQuery] = useQueryParams<{
    market: 'cri' | 'cra' | 'fii' | 'fidc';
    mode: 'indicadores' | 'assembleias';
  }>({ market: 'fidc', mode: 'indicadores' });

  const [inputSearch, setInputSearch] = useState('');
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [serviceProvidersOptions, setServiceProvidersOptions] = useState([]);
  const serviceProvidersMutation = useMutation(
    (values: any) => getServiceProviders(values),
    {
      onSuccess: (data) => {
        setServiceProvidersOptions(data);
        setError({
          status: false,
          message: '',
        });
      },
      onError: (error: any) => {
        if (
          error.response.data.error.name === 'PolicyError' ||
          !auth.isLoggedIn
        ) {
          setError({
            status: false,
            message: '',
          });
        } else {
          setError({
            status: true,
            message: 'Não foi possível carregar as opções',
          });
        }
      },
    },
  );

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    if (query.market === 'fidc' && state.indicator) {
      delayDebounceFn = setTimeout(() => {
        serviceProvidersMutation.mutateAsync({
          indicator: state.indicator,
          key: name,
          keyword: inputSearch,
        });
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [inputSearch, state.indicator]);

  return (
    <>
      <Autocomplete
        id={name}
        fullWidth
        disableCloseOnSelect
        multiple
        disabled={disabled}
        loading={serviceProvidersMutation.isLoading}
        loadingText={<CircularProgress color="inherit" size="1.5rem" />}
        options={serviceProvidersOptions || []}
        noOptionsText={'Nenhuma opção encontrada.'}
        onChange={(event, newValue) => {
          handleChange(newValue, name);
        }}
        inputValue={inputSearch}
        onInputChange={(event, newInputValue) => {
          setInputSearch(newInputValue);
        }}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option}
          </>
        )}
        value={value as any}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            error={error.status}
            helperText={error.message}
            type="text"
            variant="standard"
            {...params}
          />
        )}
        classes={{ option: classes.serviceProvicersOptions }}
      />
      {value.map((v) => (
        <Box key={v} display="flex" alignItems="center" py={1}>
          <Checkbox
            disabled={disabled}
            checked={value as any}
            onChange={() => handleChange(v, name)}
            inputProps={{ 'aria-label': 'checkbox' }}
            color="secondary"
            className={classes.checkbox}
            size="small"
          />
          <Typography className={classes.filterOption}>{v}</Typography>
        </Box>
      ))}
    </>
  );
}
