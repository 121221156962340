import React, { useContext, useEffect, useState } from 'react';

import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PaidIcon from '@material-ui/icons/MonetizationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { DataContext } from '../../../../context/DataContext';

const AmortizationMenu = ({
  formik,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  handleChangeMenu,
}) => {
  const { enableFields, expanded, securityTypes } = useContext(DataContext);
  const [availableModels, setAvailableModels] = useState([]);

  useEffect(() => {
    if (formik.values.type) {
      securityTypes?.map((type) => {
        type.key === formik.values.type && setAvailableModels(type.pmt_models);
      });
    }
  }, [formik.values.type, securityTypes]);

  return (
    <Accordion
      expanded={expanded === 'panel3'}
      onChange={handleChangeMenu('panel3')}
      disabled={!enableFields}>
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <Typography component={'span'}>
          <div className="item">
            <PaidIcon /> 3. Amortização
            <Tooltip title="Amortização">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="space-between">
          <Grid item style={{ width: '47%', marginBottom: '1em' }}>
            <FormControl fullWidth>
              <TextField
                value={formik.values.payment.principal_payments || 1}
                onChange={(event) => {
                  formik.setFieldValue(
                    'payment.principal_payments',
                    event.target.value,
                  );
                }}
                label="Pagamentos *"
                type="number"
                inputProps={{ min: 1, max: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '47%' }}>
            <FormControl fullWidth>
              <TextField
                value={formik.values.payment.principal_start || 1}
                onChange={(event) =>
                  formik.setFieldValue(
                    'payment.principal_start',
                    event.target.value,
                  )
                }
                label="Carência * (meses)"
                InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                type="number"
                inputProps={{ min: 1, max: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '47%' }}>
            <FormControl fullWidth>
              <TextField
                value={formik.values.payment.principal_step || 1}
                onChange={(event) =>
                  formik.setFieldValue(
                    'payment.principal_step',
                    event.target.value,
                  )
                }
                label="Frequência * (meses)"
                InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                type="number"
                inputProps={{ min: 1, max: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '47%' }}>
            <Autocomplete
              fullWidth
              size="small"
              disableClearable
              onBlur={formik.handleBlur}
              value={
                (formik.values.payment.model &&
                  availableModels?.find(
                    (model) => model.key === formik.values.payment?.model,
                  )) ||
                ''
              }
              onChange={(event, newValue) => {
                formik.setFieldValue('payment.model', newValue.key);
              }}
              options={availableModels ? availableModels : []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField {...params} size="medium" label="Modelo" />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AmortizationMenu;
