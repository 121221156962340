import { abbreviatedNumber } from 'src/pages/Yearbooks/presentation/components/Charts/utils';

import { YearbookChart } from '../styles';

interface ColumnChartProps {
  data: {
    name: string;
    data: number[];
  }[];
  stacked?: boolean;
  numeric?: boolean;
  monetary?: boolean;
  animationEnabled?: boolean;
  categories: string[];
}

export default function ColumnChart(props: ColumnChartProps) {
  return (
    <YearbookChart
      type="bar"
      series={props.data}
      options={{
        chart: {
          stacked: props.stacked ?? true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: props.animationEnabled ?? true,
          },
        },
        tooltip: {
          y: {
            formatter: function (value: any) {
              if (isNaN(Number(value))) {
                return value;
              }

              if (props.stacked || props.numeric) {
                const formatedNumber = abbreviatedNumber(value);
                const isMonetary = props.monetary ?? true;

                return `${isMonetary ? 'R$' : ''} ${formatedNumber}`;
              }

              const formatedNumber = Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              });

              return `${formatedNumber} %`;
            },
          },
        },
        colors: [
          '#FD841F',
          '#CD104D',
          '#F2CD5C',
          '#600446',
          '#35D0BA',
          '#A61F69',
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: props.categories,
          type: 'date',
          labels: {
            show: props.categories.length <= 6 ? true : false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  borderRadiusWhenStacked: 'last',
                  borderRadiusApplication: 'end',
                  rangeBarGroupRows: true,
                },
              },
              chart: {
                animations: {
                  enabled: false,
                },
              },
            },
          },
        ],
      }}
    />
  );
}
