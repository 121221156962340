import React, { MutableRefObject } from 'react';

import * as S from './styles';
import bgImage from '../../../../../assets/imgs/yearbook/landing-page-bg.png';
import { ReactComponent as UqbarLogo } from '../../../../../assets/imgs/yearbook/uqbar-logo.svg';
import { If } from '../../../../../components/If';
import ProductTab from '../../components/ProductTab';
import SectionMarker from '../../components/SectionMarker';
import { MenuPlaceholder } from '../../components/layout-elements';

type Props = {
  pageRef: MutableRefObject<any>;
  setMarket: (v: string) => void;
  market: string;
  pageIndexes: Array<{
    title: string;
    ref: MutableRefObject<any>;
  }>;
  onTransition: boolean;
  setOnTransition: (v: boolean) => void;
  selectedIndex: number;
  setSelectedIndex: (v: number) => void;
  setShowMode: (v: boolean) => void;
  handleClose: () => void;
  open: boolean;
  showProductTab: boolean;
  setShowProductTab: (v: boolean) => void;
  blockedView: boolean;
};

const Intro = (props: Props) => {
  const handleEnd = () => {
    props.setShowProductTab(true);
  };

  return (
    <S.Wrapper ref={props.pageRef}>
      <S.Main>
        <MenuPlaceholder open={props.open} />
        <S.ContentWrapper>
          <S.BackgroundImage image={bgImage} hide={!!props.market} />
          <S.LogoWrapper>
            <UqbarLogo />
          </S.LogoWrapper>

          {!props.blockedView && (
            <S.TabWrapper
              headermode={!!props.market}
              ontransition={props.onTransition}
              onTransitionEnd={handleEnd}
              hide={props.showProductTab}>
              <ProductTab
                setMarket={props.setMarket}
                market={props.market}
                setOnTransition={props.setOnTransition}
                onTransition={props.onTransition}
              />
              <If condition={!props.market}>
                <S.MessageWrapper>
                  <span>Escolha um dos mercados</span>
                </S.MessageWrapper>
              </If>
            </S.TabWrapper>
          )}
        </S.ContentWrapper>
      </S.Main>
      {props.market && (
        <SectionMarker menuOpened={props.open} title="patrocinadores" />
      )}
    </S.Wrapper>
  );
};

export default Intro;
