import { useEffect, useState } from 'react';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import defaultCoverImage from 'src/assets/imgs/defaultCoverImage.png';
import ImageInput from 'src/components/ImageInput';
import ProgressBar from 'src/components/ProgressBar';
import { Professional } from 'src/types/professional';
import { Media } from 'src/types/strapi';

import { Image, StyledBadge } from './styles';
import CropImage from '../../../InstitutionalProfile/CompanyForm/CropImage';

type Props = {
  setValue: (field: string, value: any) => void;
  photoValue: File | null;
  coverValue: File | null;
  photoImage: Media | null;
  coverImage: Media | null;
  setPhotoImage: (value: any) => void;
  setCoverImage: (value: any) => void;
  errors: any;
  touched: any;
  professional: Professional | undefined;
  progressValue: number;
};

function Header({
  setValue,
  photoValue,
  coverValue,
  errors,
  touched,
  photoImage,
  setPhotoImage,
  coverImage,
  setCoverImage,
  professional,
  progressValue,
}: Props) {
  const history = useHistory();
  const [preview, setPreview] = useState<string | undefined>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!coverValue) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverValue);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [coverValue]);

  const handleClear = () => {
    if (setCoverImage) {
      setCoverImage(null);
    }
    setPreview(undefined);
    setValue('coverImage', null);
  };

  return (
    <>
      <Box sx={{ position: 'relative', padding: '0 0 20px' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center">
          <StyledBadge
            overlap={'circular'}
            color="primary"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <InputLabel
                sx={{ lineHeight: 0, color: '#FFFFFF', cursor: 'pointer' }}
                htmlFor="button-file">
                <AddAPhotoOutlinedIcon
                  fontSize="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                />
              </InputLabel>
            }>
            <Image src={preview ?? coverImage?.url ?? defaultCoverImage} />
          </StyledBadge>
          {preview || coverImage ? (
            <Box sx={{ marginTop: '1rem' }}>
              <Button
                size="small"
                variant="text"
                sx={{ fontSize: '10px', padding: 0, width: 86 }}
                onClick={handleClear}>
                Remover capa
              </Button>
            </Box>
          ) : undefined}
          {errors.coverImage && touched.coverImage && (
            <Box sx={{ marginTop: '2rem' }}>
              <Typography fontSize="0.75rem" color="error">
                {errors.coverImage}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: 'fit-content',
            left: 33,
            top: 66,
            [theme.breakpoints.down('sm')]: {
              left: 0,
              top: 22,
            },
          })}>
          <ImageInput
            type="circular"
            name="photo"
            value={photoValue}
            setValue={setValue}
            image={photoImage}
            setImage={setPhotoImage}
            width="180px"
            height="180px"
          />
          {Boolean(errors.photo) && touched.photo && (
            <FormHelperText
              sx={{
                color: '#EB5757',
                width: '200px',
                textAlign: 'center',
              }}>
              {errors.photo}
            </FormHelperText>
          )}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          marginTop:
            Boolean(errors.photo) && touched.photo ? '8rem' : '1.25rem',
          paddingLeft: '21%',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '0',
          },
        })}>
        <Grid
          container
          sx={{
            marginTop: '20px',
          }}>
          <Grid item xs={12} sm={12}>
            <Typography fontSize={18} fontWeight={700}>
              Complete as informações
            </Typography>
          </Grid>
          <Grid container alignItems="end">
            <Grid item xs={12} sm={professional ? 10 : 12}>
              <ProgressBar color="success" value={progressValue} />
            </Grid>
            {professional && (
              <Grid
                item
                xs={12}
                sm={2}
                container
                sx={(theme) => ({
                  justifyContent: 'flex-end',
                  [theme.breakpoints.down('sm')]: {
                    justifyContent: 'flex-start',
                    marginTop: '2.5rem',
                  },
                })}>
                <Button
                  variant="contained"
                  onClick={() =>
                    history.push(
                      `/profissional/${professional.slug}/${professional.id}`,
                    )
                  }>
                  Ver perfil
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <CropImage
        name="coverImage"
        setValue={setValue}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={() => setOpen(false)}
        type="professional"
        title="Redimensionar imagem da capa"
      />
    </>
  );
}

export default Header;
