import { ChartsList } from '../../types';

const categoriesDates: string[] = [
  '01/19',
  '02/19',
  '03/19',
  '04/19',
  '05/19',
  '06/19',
  '07/19',
  '08/19',
  '09/19',
  '10/19',
  '11/19',
  '12/19',
  '01/20',
  '02/20',
  '03/20',
  '04/20',
  '05/20',
  '06/20',
  '07/20',
  '08/20',
  '09/20',
  '10/20',
  '11/20',
  '12/20',
  '01/21',
  '02/21',
  '03/21',
  '04/21',
  '05/21',
  '06/21',
  '07/21',
  '08/21',
  '09/21',
  '10/21',
  '11/21',
  '12/21',
  '01/22',
  '02/22',
  '03/22',
  '04/22',
  '05/22',
  '06/22',
  '07/22',
  '08/22',
  '09/22',
  '10/22',
  '11/22',
  '12/22',
  '01/23',
  '02/23',
  '03/23',
  '04/23',
  '05/23',
  '06/23',
  '07/23',
  '08/23',
  '09/23',
  '10/23',
  '11/23',
  '12/23',
];

export const fidcPrimaryMarketCharts: ChartsList = {
  emissionsFIDC: {
    chartType: 'column',
    data: [
      {
        name: 'Montante emitido',
        data: [
          81628624471.26, 73379073185.62, 112748070244.49, 103271833302.29,
          155734051345.69,
        ],
      },
    ],
    categories: ['2019', '2020', '2021', '2022', '2023'],
    numeric: true,
    stacked: false,
  },
  emissionsFIDCByCondominumType: {
    chartType: 'column',
    data: [
      {
        name: 'Condomínio Aberto',
        data: [
          32357421501.14, 22307333577.68, 37342882948.26, 33960709633.83,
          38592471450.28,
        ],
      },
      {
        name: 'Condomínio Fechado',
        data: [
          49271202970.12, 51071739607.94, 75405187296.23, 69311123668.46,
          117141579895.41,
        ],
      },
    ],
    categories: ['2019', '2020', '2021', '2022', '2023'],
    numeric: false,
    stacked: true,
  },
  emissionsFIDCByFundType: {
    chartType: 'column',
    data: [
      {
        name: 'Padronizado',
        data: [
          47130330558.36, 50108128997.46, 81836207121.16, 60546020232.21,
          112660242745.32,
        ],
      },
      {
        name: 'Não Padronizado',
        data: [
          34498293912.9, 23270944188.16, 30911863123.33, 42716670466.17,
          43073808600.37,
        ],
      },
    ],
    categories: ['2019', '2020', '2021', '2022', '2023'],
    numeric: false,
    stacked: true,
  },
  emissionsFIDCByProductType: {
    chartType: 'column',
    data: [
      {
        name: 'Ações Judiciais e Precatórios',
        data: [
          1552567003.96, 588675082.43, 3948339131.99, 7309613387.78,
          11606739155.41,
        ],
      },
      {
        name: 'Cotas de FIDC',
        data: [
          325490989.97, 159760149.51, 381606239.09, 40600000.0, 4531777796.78,
        ],
      },
      {
        name: 'Crédito Imobiliário',
        data: [
          592112855.26, 712050836.16, 914143277.43, 948056941.93, 1684583894.45,
        ],
      },
      {
        name: 'Crédito Pessoa Jurídica',
        data: [
          4266409178.45, 5910702449.54, 8629414687.21, 7676576683.72,
          17358954955.27,
        ],
      },
      {
        name: 'Crédito Pessoal',
        data: [
          3509272341.09, 8836790679.24, 8816865008.34, 20019176511.81,
          27566398965.22,
        ],
      },
      {
        name: 'Direitos',
        data: [
          200718163.6, 1467493376.65, 378437119.47, 1050021218.08, 980643921.62,
        ],
      },
      {
        name: 'FIAGRO',
        data: [
          20000000.0, 16898614.83, 72522549.27, 1837614671.58, 4743938056.78,
        ],
      },
      {
        name: 'Financiamento de Veículos',
        data: [
          2017920777.81, 641183997.62, 5033963562.9, 2842333504.35,
          7138005008.53,
        ],
      },
      {
        name: 'Multiclasse',
        data: [
          4766116157.91, 8252078816.28, 25281837711.72, 10169748895.74,
          10729186001.62,
        ],
      },
      {
        name: 'Prestação de Serviço Público',
        data: [
          673564000.0, 1485751320.82, 114655987.86, 450868661.62, 134035142.7,
        ],
      },
      {
        name: 'Recebíveis Comerciais',
        data: [
          38175663186.35, 30065664810.8, 39164412705.88, 33587753811.59,
          55968242429.0,
        ],
      },
      {
        name: 'Recebíveis do Agronegócio',
        data: [
          13267730350.28, 2247405585.0, 4039666884.15, 12786793216.8,
          7055037802.7,
        ],
      },
      {
        name: 'Recebíveis Educacionais',
        data: [
          33500000.0, 119050000.0, 274215534.99, 78262025.77, 486989650.85,
        ],
      },
      {
        name: 'Setor Público',
        data: [
          12122119466.58, 11047283527.24, 13438978495.35, 3878528328.11,
          1961862318.65,
        ],
      },
      {
        name: 'Títulos Mobiliários',
        data: [105439999.99, 5000000.0, 1956665533.41, 0.0, 0.0],
      },
      {
        name: 'Recebíveis Médicos',
        data: [0.0, 1673283939.5, 43739903.31, 51441496.08, 43648609.38],
      },
      {
        name: 'Aluguel',
        data: [0.0, 0.0, 139305738.9, 38180900.0, 61346610.49],
      },
      {
        name: 'Tributos',
        data: [0.0, 0.0, 119300173.22, 506263047.33, 978054549.11],
      },
    ],
    categories: ['2019', '2020', '2021', '2022', '2023'],
    numeric: true,
    stacked: true,
  },
};

export const fidcSecondaryMarketCharts: ChartsList = {
  negotiatedQuotasInFIDC: {
    chartType: 'line-column',
    data: [
      {
        type: 'column',
        name: 'Montante Negociado',
        data: [
          8538229395.57, 16997939284.47, 12066834216.34, 9693706179.06,
          16406742291.59,
        ],
      },
      {
        type: 'line',
        name: 'Número de negócios',
        data: [14926, 19985, 14049, 21314, 290240],
      },
    ],
    categories: ['2019', '2020', '2021', '2022', '2023'],
    numeric: true,
    stacked: false,
  },
};

export const fidcStockMarketCharts: ChartsList = {
  plFidc: {
    chartType: 'line-column',
    data: [
      {
        name: 'Soma de PL',
        type: 'column',
        data: [
          108600949092.04, 121584318108.77, 121416267050.5, 122045461591.84,
          122357163908.52, 125617901307.91, 129044602540.53, 133656582072.49,
          146635869436.24, 147875034604.78, 148141812440.14, 154510015286.38,
          156785094632.63, 164125732132.48, 164827054097.2, 165274910565.75,
          163800792043.93, 163061440600.65, 165241679249.43, 164949516789.04,
          166660997389.5, 168471397939.06, 190849894817.91, 197192666407.29,
          192093670558.79, 197413423539.57, 209972972515.04, 215629339424.7,
          228176948479.02, 231712291921.55, 219442950382.19, 232603842505.06,
          236898925177.87, 231760393083.83, 230979038687.98, 244285436157.68,
          245987968282.66, 251399839969.78, 256139773152.28, 261557136228.78,
          265975601290.15, 275083291878.83, 282259219493.53, 291956038845.2,
          297363349303.63, 301248794098.44, 308373044404.72, 330771695978.73,
          332542786315.41, 334191456856.59, 339865576522.55, 343812809677.9,
          348124533362.53, 366940640015.22, 376462656927.63, 382987583715.86,
          394114978716.34, 392592843714.26, 401154819719.68, 447722137087.78,
        ],
      },
      {
        name: 'Núm. de FIDC',
        type: 'line',
        data: [
          769.0, 783.0, 794.0, 809.0, 816.0, 827.0, 833.0, 849.0, 866.0, 876.0,
          891.0, 913.0, 924.0, 931.0, 942.0, 949.0, 963.0, 964.0, 984.0, 996.0,
          1003.0, 1017.0, 1167.0, 1197.0, 1196.0, 1209.0, 1245.0, 1269.0,
          1306.0, 1334.0, 1343.0, 1373.0, 1415.0, 1439.0, 1447.0, 1483.0,
          1499.0, 1521.0, 1548.0, 1589.0, 1614.0, 1643.0, 1680.0, 1704.0,
          1751.0, 1780.0, 1822.0, 1879.0, 1900.0, 1915.0, 1966.0, 1996.0,
          2008.0, 2032.0, 2050.0, 2067.0, 2222.0, 2218.0, 2222.0, 2353.0,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: true,
    stacked: false,
    animated: false,
  },

  plFIDCByCondominumType: {
    chartType: 'column',
    data: [
      {
        name: 'Condomínio Aberto',
        data: [
          20106046417.15, 27059555144.13, 26009707755.47, 24673572292.3,
          24117119029.17, 24598633458.34, 23255533945.99, 23536856854.02,
          24195133642.94, 24026869340.15, 24632296133.22, 25820223624.31,
          26630397637.26, 27317848176.45, 27499567786.93, 29593551408.44,
          28771755433.19, 29407703768.73, 29599909217.23, 27608515624.48,
          28347475156.47, 28755008205.65, 31829538003.25, 32175466572.31,
          32421795087.49, 32543515339.81, 32751051161.2, 32879057159.09,
          32871177005.3, 34027577518.52, 34397924250.93, 34865299307.87,
          35329070818.15, 35912411490.37, 35012712654.35, 37864226118.53,
          35887922352.03, 36767981989.02, 37074499447.56, 39770428840.82,
          39854354768.32, 43764419714.86, 44773256530.68, 48481517242.87,
          48087198316.68, 48756139164.47, 50506764495.77, 61497217952.8,
          60086176242.22, 62106123523.84, 64822039942.06, 65241006147.15,
          65698700343.0, 68296530879.88, 73136939355.02, 74604554511.05,
          75307865303.27, 70336595380.61, 74488109585.75, 84223464196.44,
        ],
      },

      {
        name: 'Condomínio Fechado',
        data: [
          88494902674.89, 94524762964.64, 95406559295.03, 97371889299.54,
          98240044879.35, 101019267849.57, 105789068594.54, 110119725218.47,
          122440735793.3, 123848165264.63, 123509516306.92, 128689791662.07,
          130154696995.37, 136807883956.03, 137327486310.27, 135681359157.31,
          135029036610.74, 133653736831.92, 135641770032.2, 137341001164.56,
          138313522233.03, 139716389733.41, 158983996080.1, 164978990199.2,
          159634462009.16, 164832796196.53, 177184568234.8, 182714318515.13,
          195271329781.04, 197645775980.21, 185005885315.69, 197699860762.14,
          201530937358.68, 195808662798.61, 195966326033.63, 206421210039.15,
          210100045930.63, 214631857980.76, 219065273704.72, 221786707387.96,
          226121246521.83, 231318872163.97, 237485962962.85, 243474521602.33,
          249276150986.95, 252492654933.97, 257866279908.95, 269274478025.93,
          272456610073.19, 272085333332.75, 275043536580.49, 278571803530.75,
          282425833019.53, 298644109135.34, 303325717572.61, 308383029204.81,
          318807113413.07, 322256248333.65, 326666710133.93, 363498672891.34,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFIDCByFundType: {
    chartType: 'column',
    data: [
      {
        name: 'Padronizado',
        data: [
          58288339863.07, 61287303902.59, 62229085746.42, 63160174031.09,
          63721187656.11, 67580980213.52, 71105916285.29, 72603291602.53,
          83633361026.53, 83581131396.73, 84987111487.44, 88667072426.95,
          88199763634.59, 93295402442.42, 94130016187.03, 92251852064.78,
          90591318339.6, 88137980134.95, 88703712211.71, 89423712585.31,
          90084494023.57, 90777811751.46, 102118789169.85, 106467065984.8,
          107065106089.51, 109635740354.42, 115070076171.77, 116501471060.62,
          127161455587.89, 130521414475.8, 131028209063.98, 134060211066.69,
          134853952983.95, 137778339239.33, 133367493167.97, 142689119520.83,
          146446960431.0, 149890747505.48, 153252545871.21, 157354664805.83,
          161099876713.83, 164866645409.79, 169749889973.96, 174278540711.73,
          179191880668.9, 183321686059.34, 188729863255.84, 198966676404.12,
          199114730434.02, 200624498489.35, 204982392483.05, 206446776964.2,
          207563456297.02, 222811458751.98, 223176915749.62, 225383881533.36,
          230843194550.96, 232166495803.67, 234742830237.81, 263969010792.31,
        ],
      },

      {
        name: 'Não Padronizado',
        data: [
          50312609228.97, 60297014206.18, 59187181304.08, 58885287560.75,
          58635976252.41, 58036921094.39, 57938686255.24, 61053290469.96,
          63002508409.71, 64293903208.05, 63154700952.7, 65842942859.43,
          68585330998.04, 70830329690.06, 70697037910.17, 73023058500.97,
          73209473704.33, 74923460465.7, 76537967037.72, 75525804203.73,
          76576503365.93, 77693586187.6, 88694744946.76, 90687390786.71,
          84991151007.14, 87739626112.09, 94860604958.03, 99085434722.86,
          100973520772.22, 101141840121.86, 88365404434.54, 98494638544.84,
          101995633935.37, 93932212074.84, 97600915981.45, 101585553934.92,
          99530121281.22, 101498081947.85, 102876088644.65, 104191222760.75,
          104864345047.63, 110205147008.42, 112497710315.16, 117665747966.54,
          118159589751.24, 117915105141.88, 119631077845.04, 131792770930.87,
          133415662835.84, 133554454137.21, 134870522818.74, 137353253185.01,
          140548211875.87, 144116734089.97, 153273610841.04, 157603702182.5,
          163271784165.38, 160426347910.59, 166411989481.87, 183753126295.47,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFIDCByProduct: {
    chartType: 'column',
    data: [
      {
        name: 'Ações Judiciais e Precatórios',
        data: [
          4242409024.36, 4268879943.79, 4399671233.68, 5167201154.9,
          5371992063.6, 5445410018.46, 5531405659.03, 5653725812.16,
          5946966736.17, 5809846215.94, 5933457750.22, 5552936512.45,
          5589518035.33, 5619925187.62, 5340606789.48, 5340814700.56,
          5383552719.39, 5459371349.81, 5484577992.55, 5657499277.75,
          5716836178.19, 5669509529.16, 5816788462.84, 5386656811.82,
          5606527707.26, 5747234180.6, 6093299504.91, 6150074390.66,
          6224904756.14, 6129547738.57, 8281843037.54, 8213420184.34,
          9079155418.75, 9075992965.22, 9659888067.26, 10690568045.33,
          11394353364.29, 11578369838.05, 11848132259.09, 9977496167.13,
          10143607520.54, 12417919718.92, 12443311569.16, 13027911164.91,
          13475791883.83, 12540238867.98, 12962432804.09, 13946276525.5,
          14742258764.56, 15577835955.65, 15112503608.17, 14984740379.47,
          16983890447.64, 18001875942.58, 18685817563.41, 18701213317.21,
          19452742171.91, 19881966001.61, 21150653391.88, 23275569859.06,
        ],
      },
      {
        name: 'Cotas de FIDC',
        data: [
          293758506.09, 298150854.01, 295437254.74, 303017267.31, 306703775.0,
          310241800.27, 315788327.87, 134434673.53, 138336825.85, 184325271.02,
          184374482.21, 285181125.5, 286241858.61, 292883548.03, 341293251.47,
          258991393.08, 198390205.96, 203241851.22, 191666284.57, 107236546.89,
          119848498.84, 9084682.36, 17997077479.89, 18695840299.84,
          17308136374.82, 18901577289.15, 19191008500.97, 19096412409.71,
          19281904792.45, 19602330489.94, 19736464006.77, 19835917734.68,
          20130507931.85, 20213480602.76, 16236478888.44, 17522330804.65,
          17664480518.6, 17727853407.75, 18297229601.32, 19371258419.29,
          19511594358.38, 20621935312.57, 20661417991.89, 21267565220.4,
          21340049797.67, 21462395000.15, 21560311160.73, 22144658683.61,
          23095128254.4, 23401673345.93, 24002420630.2, 24415608558.0,
          24824873645.77, 26340568313.51, 26763292459.44, 27669528653.32,
          29263378613.82, 29456789566.95, 26248870024.7, 32843966123.82,
        ],
      },
      {
        name: 'Crédito Imobiliário',
        data: [
          2217832450.3, 2262378002.53, 2348931982.44, 2356725494.53,
          2261085364.85, 2272319577.12, 2170546804.4, 2243853480.49,
          2263324340.27, 2186788054.38, 2211450337.23, 2289452433.14,
          2300637093.65, 2408533348.65, 2449829983.78, 2449695484.33,
          2520871525.71, 2525656956.48, 2550646155.66, 2594295748.42,
          2524380817.94, 2534852163.88, 2526320552.48, 2526741573.14,
          2575826590.49, 2608413071.72, 2547162631.47, 2620267295.91,
          2648743902.05, 2749327542.3, 2893523204.24, 2945094011.82,
          2922379530.01, 3017070181.64, 3072747990.89, 2759988720.61,
          2799098724.66, 2835291463.07, 2899168072.68, 2932041680.77,
          2984145962.98, 3029852144.93, 3051137263.97, 3317702065.99,
          3310449722.02, 3372207859.96, 3463925375.85, 3523399043.94,
          3551236390.75, 3619909081.47, 3668553482.78, 3663986115.62,
          3621625548.37, 3611338393.17, 3653809151.0, 3692592924.38,
          3731680808.22, 3814539312.56, 3884062408.39, 4184354396.5,
        ],
      },
      {
        name: 'Crédito Pessoa Jurídica',
        data: [
          9884631700.03, 9870222093.75, 10048531720.55, 10581871826.8,
          10731073094.62, 10761251367.8, 11918551738.21, 12344779255.69,
          12764832662.53, 13008062840.39, 13086781083.06, 14492597446.44,
          16821807315.83, 18132059197.85, 18435574268.79, 18920205538.92,
          18149678988.6, 18679227684.41, 19106523596.92, 19655677080.45,
          20059643870.18, 20606309720.2, 20878084529.2, 22450583570.24,
          23130675253.07, 23143957119.53, 22594723691.38, 22900455139.87,
          23670064470.31, 23242421402.66, 20882822779.6, 21262530210.66,
          22221220951.1, 21248717745.53, 17848341318.33, 19992561210.57,
          21121759674.46, 21511845597.85, 21831993193.6, 22352667209.53,
          23030408442.11, 24101837117.37, 24816488356.66, 25247007265.21,
          24718817267.71, 25018876830.6, 26991606173.35, 27339737886.18,
          27327349104.98, 27502948652.56, 28634991051.79, 29349716475.19,
          31331379397.78, 32215983154.86, 33723546133.97, 34125864952.96,
          33599987337.29, 34052279093.72, 34706426981.82, 34036606671.45,
        ],
      },
      {
        name: 'Crédito Pessoal',
        data: [
          5637994499.99, 5953247625.75, 6418889658.7, 6792727388.22,
          6252879149.36, 5356478745.33, 6793217851.38, 6905190271.05,
          7113575823.87, 7537730746.61, 7768948146.56, 8079174906.89,
          8301586317.93, 12043412597.18, 12264871634.23, 12371656895.06,
          12401179384.66, 12236571891.71, 12308398944.75, 12458617944.19,
          12745201110.04, 12854469609.03, 13517390423.45, 14099749244.35,
          14348275954.64, 14523379551.67, 14762839393.03, 15101774286.69,
          15519166822.43, 16944064865.67, 17154178484.12, 18462937359.99,
          18299444864.77, 18842970880.11, 20148603107.22, 21563099590.07,
          22421130514.05, 22298550171.24, 22968095856.44, 24400280838.24,
          25777030334.56, 26418372342.94, 27768553431.18, 28821338521.71,
          27781950503.22, 28456916798.9, 29186973039.83, 29730462741.41,
          30161479407.63, 30335203503.22, 30719378085.0, 31808983028.94,
          32924759642.12, 44926145512.38, 46059072049.35, 47871796730.91,
          50899024370.89, 52012898990.03, 52831877291.83, 58186227280.91,
        ],
      },
      {
        name: 'Direitos',
        data: [
          329102299.05, 332549092.13, 365574383.09, 369000888.99, 418605371.64,
          425096246.72, 455601758.85, 454933615.77, 491397037.12, 522905549.94,
          542848929.8, 534474610.89, 723548423.04, 535184870.86, 544819431.32,
          597862246.62, 655334617.0, 1107066814.93, 1089584392.75,
          1305748909.63, 1292415355.02, 1853664667.14, 1848329978.37,
          1923090961.66, 1882676470.75, 1906630732.14, 1818943540.75,
          1804784355.86, 1875535666.64, 1863762668.12, 1907077805.66,
          1919037634.84, 1934015708.37, 2011396206.55, 1997951961.68,
          1946326679.11, 1955282690.0, 1923536477.49, 1967935642.53,
          2044674019.76, 2081632879.45, 2127777636.56, 2168965304.88,
          2212795644.11, 2649569996.62, 2616904121.9, 2674335740.53,
          2741176482.75, 2722502940.02, 2731357383.83, 2761956580.67,
          2772713071.26, 2727489894.18, 2861177671.96, 2927737402.2,
          2956661000.87, 2935961696.25, 2962016037.86, 3097728691.56,
          3487548760.93,
        ],
      },
      {
        name: 'Financiamento de Veículos',
        data: [
          4963786366.27, 5053124112.88, 5080449307.0, 5060410574.65,
          4990867870.37, 5001222974.02, 6071366612.94, 6049850846.25,
          6047854760.43, 5924967189.41, 5957222204.73, 5858467513.04,
          5853122579.93, 5844192201.31, 5796373268.92, 5459291512.25,
          5178889852.29, 5085798822.42, 4907082008.12, 4928602181.52,
          4965590716.59, 4984175534.51, 4940921579.06, 4992903696.22,
          5010401057.34, 5008030328.97, 4905531362.91, 4892449071.11,
          7626500749.46, 7297967956.58, 7362952508.76, 7224152942.26,
          7026399379.66, 7158982499.64, 8406774183.19, 9080038892.5,
          9087399509.1, 9135268519.22, 8974108410.85, 9175026866.87,
          9127405300.96, 9637218870.48, 10329304731.33, 10373648539.58,
          10496251943.54, 10783149578.97, 10841825755.24, 11116474971.89,
          10800130255.35, 10728270559.65, 11160567567.76, 11398693489.34,
          11275592254.04, 11608456315.15, 11795089789.83, 11699954745.2,
          12041200886.52, 11827632928.68, 11441370972.43, 11645121886.37,
        ],
      },
      {
        name: 'Multiclasse',
        data: [
          13269403716.46, 13063526792.79, 13165053788.78, 13131997346.95,
          13273071946.69, 14229907452.81, 14357218465.82, 14808153008.41,
          15599681881.12, 16076992455.37, 16946435263.63, 18366086711.59,
          18963788854.49, 19127440397.58, 19496642003.71, 21149761037.46,
          21206099942.33, 18938116671.27, 19616001830.65, 20067973896.97,
          20439368610.15, 21186523019.78, 21826888007.69, 22581777916.92,
          22934602263.31, 23231731168.07, 23871782378.72, 24651324349.89,
          25254959547.74, 26816165066.78, 25091299165.45, 26045072960.86,
          27158818149.36, 27509048549.75, 30116830787.36, 31712674500.39,
          32079417516.41, 32572789188.4, 34530353093.34, 35364294297.39,
          36184788760.44, 37166781808.99, 38213115827.5, 39092643717.12,
          39831202600.19, 40429688759.32, 41424356471.25, 42252814925.1,
          42786585014.83, 43217311113.03, 43049147371.07, 44011292407.4,
          44034873649.19, 44039958596.13, 48470852959.56, 49777907559.93,
          49627659366.16, 45294972916.22, 47540093385.5, 51573537162.06,
        ],
      },
      {
        name: 'Prestação de Serviço Público',
        data: [
          5790503440.22, 6359580828.34, 6353791414.08, 6331920199.57,
          6116321532.28, 6107577614.84, 6250977479.72, 5997930994.65,
          5962666959.23, 5909748646.81, 5532665131.69, 5785757968.35,
          5808715190.87, 6070178438.55, 7202112798.12, 7153237536.5,
          7047930516.98, 6668069947.72, 6864929220.78, 6772927223.3,
          6654663708.85, 6545071379.46, 6428340135.81, 6251918480.24,
          6142650589.9, 6138369722.02, 6048408588.43, 5880771498.43,
          5660323213.0, 5412557314.66, 5290341341.25, 4800044284.01,
          5045553626.92, 4914972227.77, 4810159180.59, 4709302468.18,
          4676712621.72, 4497773996.78, 4432496976.01, 4310750412.45,
          4186856983.04, 4032040184.24, 3894054817.72, 3737616565.88,
          3585926409.31, 3596376013.2, 3480631537.16, 3343588724.83,
          3226820272.07, 2989382491.69, 2906975993.71, 2827470678.92,
          2742477060.7, 2564501137.32, 2446907519.93, 2392829792.46,
          2290955959.09, 2265699328.15, 2241014628.63, 2230928059.34,
        ],
      },
      {
        name: 'Recebíveis Comerciais',
        data: [
          43541298323.08, 50672413654.54, 49163227402.6, 47875412057.79,
          48289632432.04, 52434886918.23, 51228651350.15, 51921385470.71,
          54016408370.25, 54006904548.01, 54072528027.79, 56563719743.61,
          54460816783.82, 55027660633.7, 55239819307.47, 53694604940.15,
          53069967004.11, 50802569761.95, 51595568665.06, 51805061184.46,
          52010280543.8, 52362591796.96, 53219051365.41, 56189662242.77,
          56784256329.08, 58255907830.09, 62874211007.04, 63751970726.29,
          70650256941.33, 70514504839.34, 69698700151.47, 71796289765.45,
          73638978330.65, 76767707774.79, 76592980379.79, 81996264166.39,
          82700314081.83, 85735317369.5, 87334015086.45, 89837935573.89,
          90269701636.95, 91861168596.83, 95834993468.08, 100670436427.85,
          104404972355.75, 106086489601.5, 107101426908.32, 114805426097.22,
          115139778900.42, 115324191737.0, 118494432621.33, 118926781350.98,
          119643813310.19, 121823185116.34, 123660622463.1, 125897575513.83,
          129848497191.78, 130993228192.93, 135353861142.97, 155825895465.65,
        ],
      },
      {
        name: 'Recebíveis do Agronegócio',
        data: [
          7483669644.07, 12064496194.9, 12208545185.43, 12247268957.97,
          12194916500.1, 12236537839.08, 12516920087.87, 14596665461.32,
          15250674621.78, 15266626915.38, 14245136909.76, 14890302318.3,
          15145325975.81, 15189674790.68, 15222745228.21, 15356474978.18,
          15404297723.14, 15491510756.65, 15209790212.26, 13408282414.94,
          13649691780.45, 13799017129.2, 15911422542.72, 16470751129.68,
          10816047071.22, 10846923279.04, 16698167982.2, 20585835309.96,
          20540713143.36, 21015431163.78, 11146633579.74, 21131736243.43,
          21395693554.87, 12358597488.98, 13012704487.52, 14899085207.86,
          10908101586.61, 11661356986.11, 11780214615.64, 11707499066.15,
          11832499608.38, 12108510453.91, 11070944569.06, 11768571235.39,
          12523552897.47, 12962391445.26, 14509076023.56, 24558021733.4,
          24558347795.58, 24400789501.73, 24533019552.07, 24088665947.26,
          22632270530.92, 21861290570.95, 20880054663.44, 20479000901.59,
          20232991595.61, 19416532529.39, 20445264888.69, 23014747002.44,
        ],
      },
      {
        name: 'Recebíveis Educacionais',
        data: [
          902811416.52, 858042746.65, 859415371.77, 835559049.54, 958350808.19,
          936086429.98, 891619088.32, 1006640943.69, 1092889804.12,
          1072398517.55, 1057971661.72, 1040511902.35, 1166941854.76,
          1147698000.75, 1127012196.98, 1088679733.51, 1059321547.28,
          1034418199.37, 1095746673.45, 1073598165.95, 1077116972.65,
          1055180922.64, 1033329735.83, 1016052032.73, 1042347321.69,
          1027002833.65, 1145558490.16, 1118050774.71, 1109773535.58,
          1689890263.15, 1686608711.85, 1850827949.63, 1841320890.42,
          1897632754.87, 1896137197.03, 1912563487.8, 2095686455.95,
          2108307227.1, 2143382215.78, 2190910248.66, 2208855725.43,
          2223587815.96, 2251189827.45, 2521877025.59, 2535206876.65,
          2568732534.83, 2604090359.57, 2636278024.59, 2672787300.75,
          2794127390.72, 3202255748.01, 3435131881.67, 3464474585.07,
          3726904195.21, 3744273520.44, 3932330028.99, 3944997011.53,
          4059559549.1, 4042832424.75, 4465638206.86,
        ],
      },
      {
        name: 'Recebíveis Médicos',
        data: [
          51402448.2, 48293134.94, 45921686.3, 34712905.02, 35058818.52,
          3265582.27, 3008051.58, 2760970.25, 2517162.66, 1446884.84, 0.0, 0.0,
          1194482.75, 5444070.76, 5454292.76, 7076427.38, 7113111.61,
          7144896.25, 7186146.23, 8919705.26, 10219475.32, 22611288.12,
          22811548.17, 26773693.81, 31609180.58, 32411060.88, 31233813.57,
          31565947.26, 30987695.23, 37380771.9, 37404010.22, 41491942.65,
          40385371.57, 41565092.39, 50874795.32, 50451876.2, 53067235.13,
          59820843.64, 58380749.67, 62119894.73, 70012719.91, 67959903.65,
          67478790.19, 76999743.45, 76542704.5, 84647682.5, 83995007.44,
          79184365.32, 80056439.51, 84066607.12, 88468365.98, 89522698.53,
          89842740.2, 102395333.02, 125807874.97, 126810848.23, 128578044.5,
          132138710.95, 134121353.84, 133679840.74,
        ],
      },
      {
        name: 'Setor Público',
        data: [
          9978263627.72, 10465309302.38, 10648284082.24, 10941378138.8,
          11139282223.84, 10091000496.1, 10412456393.29, 11409283797.24,
          19812087466.58, 20238640069.89, 20473823673.88, 20520831483.26,
          21114208704.97, 22431805755.24, 21109719690.65, 21174997992.12,
          21264415620.95, 24566573441.69, 24946446320.47, 24790594808.12,
          25105415546.71, 24694409620.32, 24597387344.54, 24290697556.6,
          24189250192.61, 25750430991.91, 26139726757.06, 25828334276.6,
          25858621443.56, 26067985412.79, 25931081712.49, 24645325622.76,
          23865344978.17, 24217214073.99, 24474563735.26, 22816283767.67,
          24349347341.94, 24604837053.52, 23759305828.5, 24147811327.89,
          24370877017.52, 25031577644.84, 25307980512.45, 25346654471.5,
          25567103338.03, 25803444351.05, 25817676813.48, 26511072668.8,
          25525527617.13, 25231729270.23, 25069255828.87, 25243256837.36,
          24946339605.02, 26137189314.39, 26209622992.41, 26174048836.42,
          28293593903.14, 28343688394.03, 29384452815.37, 31351096859.61,
        ],
      },
      {
        name: 'Títulos Mobiliários',
        data: [
          14081629.68, 14103729.39, 14542579.1, 16258340.8, 17322957.42,
          6618244.88, 127272871.1, 126993471.28, 132654984.26, 127650699.24,
          128168837.86, 230520717.0, 227611900.93, 229596693.95, 229888326.42,
          231394117.7, 233696519.53, 235474811.89, 238098563.1, 269965464.74,
          240529170.73, 241699929.73, 232070346.71, 235239946.9, 235821356.73,
          236550583.15, 1192229715.31, 1157367179.77, 2147341077.37,
          2133376075.27, 2143044666.86, 2219878595.81, 2086825434.88,
          2233239631.52, 2293558556.61, 2161137524.19, 2142699345.99,
          2158057201.22, 2179087955.85, 2443269385.39, 2467212401.58,
          2373972609.3, 2146295855.48, 2139966754.22, 2135925621.33,
          2114426726.89, 2127881418.68, 2143816494.02, 2167218287.25,
          2189046998.41, 2212876210.72, 2232157755.68, 2259252029.14,
          2274027564.03, 2288054188.1, 2330664032.03, 2343247543.85,
          2331730486.82, 2425119502.44, 1727743128.04,
        ],
      },
      {
        name: 'FIAGRO',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 19999893.57,
          20029259.91, 20042399.77, 20291624.89, 20166031.93, 20052764.39,
          20626742.88, 29432242.11, 44516236.45, 49795034.04, 52226946.57,
          53680785.74, 54227250.37, 54566845.3, 54873796.98, 58145157.13,
          57902411.98, 77150722.37, 78502756.84, 82279960.44, 88523123.59,
          89202039.53, 127866747.0, 180453441.53, 223957559.44, 281220205.67,
          587326375.67, 663641738.65, 723414038.34, 1145395322.48,
          1213020460.66, 1500745312.68, 1534534223.13, 2099929527.02,
          2299336890.03, 2459849271.4, 2703179774.96, 2763298787.09,
          2821468035.01, 2956731569.05, 3119152676.05, 3131586154.62,
          3329088726.87, 3414038371.53, 3425191663.66, 3599617920.77,
          3777409254.7, 4234112857.84, 4597982577.48,
        ],
      },
      {
        name: 'Aluguel',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 117075593.2, 116695255.73, 121561938.28, 123679016.99,
          123937661.32, 125204367.68, 138317955.98, 143739971.03, 145285583.81,
          144053083.12, 144394153.9, 144823529.4, 142840632.87, 218798863.18,
          241174146.07, 248727826.99, 443824971.27, 463675747.99, 465317621.86,
          464772386.9, 466435710.63, 471089827.7, 491556566.69, 491757465.29,
          498706196.42, 494469410.53, 496621208.13, 494627146.37, 496286068.91,
          491589752.95, 493830669.31,
        ],
      },
      {
        name: 'Tributos',
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 54786242.28, 110483700.74, 114156925.22,
          258252669.36, 328178772.76, 371292628.4, 438752786.04, 506918623.81,
          514443000.67, 557596113.09, 581378031.78, 608746064.13, 618974796.25,
          730809213.35, 746203639.26, 764304796.78, 809404710.79, 941712204.23,
          986422291.31, 1005847926.16, 1111636741.85, 1228958148.54,
          1377138302.33, 1464290587.3, 1449424527.93, 1529101889.19,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: false,
    stacked: true,
    animated: false,
  },

  plFidcMM: {
    chartType: 'column',
    data: [
      {
        name: 'Soma de pl',
        data: [
          18524055839.85, 18761261879.12, 18999218208.03, 19321181033.94,
          19643110292.49, 19819523275.3, 20325552672.87, 20403902487.27,
          20999533842.32, 21334446719.93, 21557492047.73, 21629175441.21,
          21707651137.82, 21845776582.62, 21720208405.66, 20399908746.89,
          19997489320.03, 19682544612.34, 19797932826.53, 19637215212.8,
          19658474549.81, 19943037916.03, 20542073414.92, 20885486579.74,
          21015785929.85, 21359330769.64, 21930270446.71, 22624838562.77,
          23596658912.13, 24497990618.44, 25029867398.55, 26002274643.59,
          26649363992.83, 27146566795.59, 28087398390.66, 29409445852.3,
          29854504678.5, 30676426041.49, 31990686685.7, 33148192155.96,
          33910939428.71, 34665447681.94, 35631470335.21, 36774829418.99,
          37188242319.56, 37248809967.66, 37827351137.36, 38989938940.21,
          38912547480.16, 39122597670.04, 39588052276.73, 39605504279.21,
          39867392322.28, 39553792196.02, 39500218063.62, 39617150155.83,
          40060602119.36, 40587187635.13, 41139372483.71, 41096696104.27,
        ],
      },
    ],
    categories: categoriesDates,
    numeric: true,
    stacked: false,
    animated: false,
  },
};
