import { useCallback } from 'react';

import { ApexOptions } from 'apexcharts';
import { format, getYear, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { groupBy, mapValues, uniq } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { Dashboard } from 'src/types/radar';

export default function BarChart({ dashboard }: { dashboard: Dashboard }) {
  const { series_settings } = dashboard.settings.visualization_settings;
  const stacked =
    !!dashboard.settings.visualization_settings['stackable.stack_type'];
  const [dateName, keyName] =
    dashboard.settings.visualization_settings['graph.dimensions'];
  const sumName = dashboard.settings.result_metadata.at(-1).display_name;
  const dateUnit = dashboard.settings.result_metadata?.find(
    (meta: any) => meta.name === dateName,
  ).unit;

  const formatDate = (date: Date) =>
    dateUnit === 'year'
      ? getYear(date)
      : format(date, 'MMMM yyyy', {
          locale: ptBR,
        });

  const uniqueDates = uniq(
    dashboard.content.map((item: any) => formatDate(parseISO(item[dateName]))),
  );

  const processData = useCallback(
    (content: Dashboard['content']) => {
      const graphData: { [key: string]: number[] } = mapValues(
        groupBy(content, keyName),
        (items) => {
          const groupData = items.reduce((data, item) => {
            const emissionDate = formatDate(parseISO(item[dateName]));
            const index = uniqueDates.indexOf(emissionDate);
            data[index] = item[sumName];
            return data;
          }, Array(uniqueDates.length).fill(0));
          return groupData;
        },
      );

      const sumDisplayName = series_settings?.sum?.title ?? sumName;

      const series = Object.entries(graphData).map(([name, data]) => ({
        name: stacked
          ? series_settings?.[name]?.title ??
            (name === 'null' ? '(vazio)' : name)
          : sumDisplayName,
        data,
      }));

      return series;
    },
    [dashboard],
  );

  const series = processData(dashboard.content);

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: dashboard.name,
      type: 'bar',
      stacked,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: (
          value,
          {
            series,
            dataPointIndex,
          }: { series: [number[]]; dataPointIndex: number },
        ) => {
          const sum = series.reduce((a, b) => a + b[dataPointIndex], 0);
          const percent = (value / sum) * 100;
          const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            notation: 'compact',
          }).format(value);
          const text = stacked
            ? `${formattedValue} - ${percent.toFixed(2)}%`
            : formattedValue;
          return text;
        },
      },
    },
    xaxis: { categories: uniqueDates, tickAmount: 5 },
    yaxis: {
      labels: {
        formatter: function (value) {
          const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            notation: 'compact',
          }).format(value);
          return stacked ? `${value}%` : formattedValue;
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: { radius: 12 },
    },
    grid: {
      borderColor: '#E5E5E5',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <ReactApexChart
      type="bar"
      options={options}
      series={series}
      width="100%"
      height="100%"
    />
  );
}
