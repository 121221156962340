import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    height: 100%;
    box-shadow: 3px 3px 8px 1px #e6e6e6;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 3px 8px 1px #e6e6e6;
    background: #ffffff;

    .box-title {
      font-weight: bold;
      padding: 20px 16px 20px 16px;
      border-bottom: 2px solid #303030;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .datepicker {
      input,
      button {
        padding-top: 18px;
      }
      margin-top: -0.5rem;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .triple {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .full-width {
      width: 100%;
    }

    .input-price {
      background: transparent;
      padding: 0.4rem 0;
      font-size: 1rem;
      border: 1px;
      border-color: rgba(0, 0, 0, 0.42);
      border-image: none;
      border-style: none none solid none;
    }

    .input-price:focus-visible {
      outline: none;
      border-width: 2px;
      border-color: rgba(0, 0, 0, 0.87);
      border-style: none none solid none;
    }
  `}
`;
