import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
} from '@mui/material';

const TabNavigation = ({
  value,
  handleSelect,
  disabled,
  items,
}: {
  value: string;
  handleSelect: (value: string) => void;
  disabled?: string[];
  items?: { label: string; value: string }[];
}) => {
  return (
    <Box>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          handleSelect(newValue);
        }}
        showLabels
        sx={(theme) => ({
          height: 48,
          width: 'max-content',
          justifyContent: 'flex-start',
          [theme.breakpoints.down('sm')]: { width: 'auto' },
        })}>
        {items?.map((item, index) => (
          <BottomNavigationAction
            key={`${item.value}-${index}`}
            label={item.label}
            value={item.value}
            disabled={disabled?.includes(item.value)}
          />
        ))}
      </BottomNavigation>
      <Divider />
    </Box>
  );
};

export default TabNavigation;
