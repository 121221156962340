import { CircularProgress } from '@mui/material';
import { TabTemplateMobile } from 'src/components/UI/TabTemplateMobile';

import { EventProvider } from './EventContextProvider';
import Header from './Header';
import { useEventTabManager } from './useEventTabManager';

export default function Event() {
  const {
    tabData,
    isLoading,
    isMobile,
    data,
    tabIndex,
    refetch,
    handleChange,
  } = useEventTabManager();

  if (isLoading) {
    return <CircularProgress size="1.8rem" color="secondary" />;
  }

  const commonProps = {
    data,
    refetch,
    handleChange,
    tabIndex,
    tabData,
  };

  return (
    <EventProvider>
      {isMobile ? (
        <TabTemplateMobile
          eventData={data}
          menuItems={tabData}
          {...commonProps}
        />
      ) : (
        <Header {...commonProps} />
      )}
    </EventProvider>
  );
}
