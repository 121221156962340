import _ from 'lodash';
import { Helmet } from 'react-helmet-async';

import { MetaSeo } from '../../types/meta';

export type MetaTag = {
  title: string;
  description: string;
  imageUrl?: string;
  canonicalUrl?: string;
  type?: string;
  seo?: MetaSeo;
  article?: {
    publishedTime: string; // datetime - When the article was first published.
    modifiedTime: string; // datetime - When the article was last changed.
    expirationTime: string; // datetime - When the article is out of date after.
    author: string; // <profile> - Writers of the article.
    section: string; // string - A high-level section name. E.g. Technology
    tag: string; // string array - Tag words associated with this article.
  };
  profile?: {
    firstName: string;
    lastName: string;
  };
};
const MetaTags = ({
  title,
  description,
  imageUrl = `${process.env.REACT_APP_PUBLIC_URL}/logo.png`,
  canonicalUrl = window.location.href,
  type = 'website',
  seo,
  article,
  profile,
}: MetaTag) => {
  const metaTitle =
    seo?.metaTitle ??
    (/^UQBAR - |.{,58}/.test(title) ? title : `${title} - UQBAR`)?.substring(
      0,
      65,
    );
  const metaDescription =
    seo?.metaDescription ??
    description?.replace(/<[^>]+>/g, '')?.substring(0, 320);
  const canonical =
    seo?.canonicalURL ??
    new URL(canonicalUrl, process.env.REACT_APP_PUBLIC_URL).href;
  const metaImage = seo?.metaImage?.url ?? imageUrl;

  const articleDetails = article?.publishedTime
    ? Object.entries(article)
        .filter(([, v]) => !!v)
        .map(([k, v], index) => (
          <meta
            key={index}
            property={`article:${_.snakeCase(k)}`}
            content={v}
          />
        ))
    : [];

  const profileDetails = profile?.firstName
    ? Object.entries(profile)
        .filter(([, v]) => !!v)
        .map(([k, v], index) => (
          <meta
            key={index}
            property={`profile:${_.snakeCase(k)}`}
            content={v}
          />
        ))
    : [];

  return (
    <Helmet prioritizeSeoTags>
      <meta charSet="utf-8" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={canonical} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="title" key="title" content={metaTitle} />
      <meta property="og:site_name" content="UQBAR" />
      <meta property="og:title" key="og:title" content={metaTitle} />
      <meta property="og:locale" key="og:locale" content="pt_BR" />
      <meta property="og:type" key="og:type" content={type} />
      <meta
        property="og:description"
        key="og:description"
        content={metaDescription}
      />
      {metaImage && (
        <meta property="og:image" key="og:image" content={metaImage} />
      )}
      {seo?.keywords && <meta name="keywords" content={seo.keywords} />}
      <meta property="og:url" content={canonical} />

      {article?.author && <meta name="author" content={article.author} />}

      {articleDetails}
      {profileDetails}
    </Helmet>
  );
};
export default MetaTags;
