import * as Sponsors from './styles';
import * as Section from '../../components/Section';
import SponsorsCard from '../../components/SponsorsCard';
import { SponsorLevel, YearbookMarket } from '../../types';

interface SponsorsSectionProps {
  market: YearbookMarket;
}

export default function SponsorsSection({ market }: SponsorsSectionProps) {
  return (
    <Sponsors.Section data-yearbook-section="Patrocinadores">
      <Sponsors.Texts>
        <Section.Title>Patrocinadores</Section.Title>
        <Section.Subtitle>Anuários uqBAR 2024</Section.Subtitle>
      </Sponsors.Texts>
      <Sponsors.Cards>
        <SponsorsCard level={SponsorLevel.DIAMOND} market={market} />
        <SponsorsCard level={SponsorLevel.PLATINUM} market={market} />
        <SponsorsCard level={SponsorLevel.GOLD} market={market} />
        <SponsorsCard level={SponsorLevel.SILVER} market={market} />
        <SponsorsCard level={SponsorLevel.BRONZE} market={market} />
      </Sponsors.Cards>
      <Sponsors.BackgroundImage />
    </Sponsors.Section>
  );
}
