import styled from 'styled-components';

type BannerProps = {
  type: 'success' | 'error';
};

export const Banner = styled.div<BannerProps>`
  width: 100%;
  height: 50px;
  background-color: ${(props) =>
    props.type === 'error'
      ? props.theme.palette.error.main
      : props.theme.palette.success.main};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;

  & > svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const Link = styled.span`
  text-decoration: underline;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;
