import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { parse, stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export function useForm(callback: any, initialState = {}) {
  const [values, setValues] = useState<any>(initialState);

  function onChange(event: any) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  function onSubmit(event: any) {
    event.preventDefault();
    if (values.username.length === 0 || values.password.length === 0) {
      return;
    }
    callback();
  }

  return {
    onChange,
    onSubmit,
    values,
  };
}

// Hook
export function useLocalStorage(
  key: string,
  initialValue?: any,
  stringify = true,
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    if (valueToStore) {
      window.localStorage.setItem(
        key,
        stringify ? JSON.stringify(valueToStore) : valueToStore,
      );
    } else {
      window.localStorage.removeItem(key);
    }
  };

  return [storedValue, setValue];
}

export function useSessionStorage(
  key: string,
  initialValue?: any,
  stringify = true,
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to local storage
    if (valueToStore) {
      window.sessionStorage.setItem(
        key,
        stringify ? JSON.stringify(valueToStore) : valueToStore,
      );
    } else {
      window.sessionStorage.removeItem(key);
    }
  };
  return [storedValue, setValue];
}

export function useIsElementVisible(el: React.MutableRefObject<any>) {
  const [isVisible, setIsVisible] = useState(false);
  const callback = ([entry]: IntersectionObserverEntry[]) => {
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    if (el.current) {
      const watch = new IntersectionObserver(callback);
      watch.observe(el.current);
      return () => watch.disconnect();
    }
  }, [el]);

  return isVisible && !!el;
}

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const useQueryParams: <T>(
  initialParams?: T,
) => [
  { query: T; rawQuery: string },
  (nextParams: any, method?: 'push' | 'remove' | undefined) => void,
] = (initialParams?: any) => {
  const { push, replace } = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => {
    const searchQuery = search.substring(1);

    if (!search) {
      return initialParams;
    }

    return parse(searchQuery);
  }, [search, initialParams]);

  const setQuery = useCallback(
    (nextParams: any, method = 'replace') => {
      let nextQuery: any = { ...query };

      if (method === 'remove') {
        Object.keys(nextParams).forEach((key) => {
          delete nextQuery[key];
        });
      } else {
        nextQuery = { ...query, ...nextParams };
      }

      if (method === 'push') {
        push({
          search: stringify(nextQuery, {
            encode: true,
            skipNulls: true,
            encodeValuesOnly: true,
          }),
        });
        return;
      }
      replace({
        search: stringify(nextQuery, {
          encode: true,
          skipNulls: true,
          encodeValuesOnly: true,
        }),
      });
    },
    [push, replace, query],
  );

  return [{ query, rawQuery: search }, setQuery];
};

// Hook
export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useSectionInScreen = (attribute = 'data-yearbook-section') => {
  const [currentSection, setCurrentSection] = useState<string>('');

  useEffect(() => {
    function handleScroll() {
      const sections = document.querySelectorAll('section');
      let current = '';
      sections.forEach((section) => {
        if (!section.hasAttribute(attribute)) return;
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.scrollY >= sectionTop - sectionHeight / 2.5) {
          current = section.getAttribute(attribute) ?? '';
        }
      });
      setCurrentSection(current);
    }

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [window.scrollY]);

  return currentSection;
};

const updateCursor = (el: HTMLElement) => {
  el.style.cursor = 'grabbing';
  el.style.userSelect = 'none';
};

const resetCursor = (el: HTMLElement) => {
  el.style.cursor = 'grab';
  el.style.removeProperty('user-select');
};

export const useDragScroll = () => {
  const [node, setNode] = useState<HTMLDivElement | null>();

  const ref = useCallback((nodeEle: HTMLDivElement) => {
    setNode(nodeEle);
  }, []);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!node) return;

      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor(node);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [node],
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (!node) return;

      const touch = e.touches[0];
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: touch.clientX,
        y: touch.clientY,
      };

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
        updateCursor(node);
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor(node);
      };

      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    },
    [node],
  );

  useEffect(() => {
    if (!node) return;

    node.addEventListener('mousedown', handleMouseDown);
    node.addEventListener('touchstart', handleTouchStart);
    return () => {
      node.removeEventListener('mousedown', handleMouseDown);
      node.removeEventListener('touchstart', handleTouchStart);
    };
  }, [node]);

  return [ref];
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    screenWidth: width,
    screenHeight: height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const usePreventCopyAndSelect = () => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'a') {
        event.preventDefault();
      }
    };
    const handleCopy = (event: ClipboardEvent) => {
      event?.clipboardData?.setData(
        'text/plain',
        'Não é permitido copiar os dados desta tela.',
      );
      event.preventDefault();
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('copy', handleCopy);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('copy', handleCopy);
    };
  }, []);
};
