// Material ui components
import {
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Declarative routing
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
  market: {
    border: 0,
    fontWeight: 500,
    boxShadow: theme.shadows[1],
  },
  marketSelected: {
    border: 0,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    '&.MuiChip-root:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiChip-root:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  marketLink: {
    color: theme.palette.primary.main,
  },
  marketLinkSelected: {
    color: theme.palette.secondary.contrastText,
  },
  marketGrid: {
    padding: theme.spacing(1, 0),
  },
  title: {
    width: 'max-content',
    fontSize: '2.125rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    lineHeight: 1.235,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  customTooltip: {
    maxWidth: 600,
    padding: '1rem',
    fontSize: '12px',
  },
  customTooltipMobile: {
    maxWidth: '98%',
    padding: '1rem',
    fontSize: '14px',
  },
  box: {
    display: 'flex',
    padding: theme.spacing(1),
    marginRight: '1em',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
}));

export default function SubHeaderWithMarkets({
  title,
  markets,
  setState,
  selectedMarket,
  tooltipText,
  isLoading,
}) {
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <div style={{ width: '100%', padding: '24px 0' }}>
      <Box display="flex" alignItems="center">
        <Box className={classes.box}>
          <Typography component="h1" variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Tooltip
            enterTouchDelay={10}
            arrow
            placement={isExtraSmall ? 'bottom' : 'right'}
            title={tooltipText ?? ''}
            classes={{
              tooltip: isExtraSmall
                ? classes.customTooltipMobile
                : classes.customTooltip,
            }}>
            <IconButton>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Hidden smDown>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            container
            spacing={1}
            className={classes.marketGrid}
            justifyContent="flex-start">
            {markets.map((market) => (
              <Grid item key={market}>
                <Chip
                  disabled={isLoading}
                  component={RouterLink}
                  to={title === 'Radar' ? `/radar/${market}` : '#'}
                  label={market.toUpperCase()}
                  color={market === selectedMarket ? 'secondary' : 'primary'}
                  onClick={() =>
                    title !== 'Radar' ? setState(market) : undefined
                  }
                  variant="outlined"
                  className={
                    market === selectedMarket
                      ? classes.marketSelected
                      : classes.market
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Box p={1} width="100%" display="flex" justifyContent="flex-end">
            <Breadcrumbs
              className={classes.breadcrumbs}
              aria-label="navegação-secundária">
              <Link
                component={RouterLink}
                to="/"
                underline="none"
                className={classes.link}>
                Home
              </Link>
              <Typography>{title}</Typography>
            </Breadcrumbs>
          </Box>
        </Hidden>
      </Box>
      <Divider className={classes.divider} />
      <Hidden mdUp>
        <Grid
          item
          container
          xs={12}
          sm={8}
          md={8}
          spacing={1}
          className={classes.marketGrid}
          style={{ marginTop: '1rem' }}
          justifyContent="flex-start">
          {markets.map((market) => (
            <Grid item key={market}>
              <Chip
                disabled={isLoading}
                component={RouterLink}
                to={title === 'Radar' ? `/radar/${market}` : '#'}
                label={market.toUpperCase()}
                color={market === selectedMarket ? 'secondary' : 'primary'}
                onClick={() =>
                  title !== 'Radar' ? setState(market) : undefined
                }
                variant="outlined"
                className={
                  market === selectedMarket
                    ? classes.marketSelected
                    : classes.market
                }
              />
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </div>
  );
}
