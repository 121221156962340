import * as Yup from 'yup';

const MAX_FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const ValidationProfessionalForm = () => {
  return Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    surname: Yup.string().required('Campo obrigatório'),
    professionalSegments: Yup.array()
      .of(Yup.number())
      .min(1, 'Campo obrigatório'),
    markets: Yup.array().of(Yup.number()).min(1, 'Campo obrigatório'),
    location: Yup.string().required('Campo obrigatório'),
    photo: Yup.mixed()
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          return !value || (value?.type && value?.size <= MAX_FILE_SIZE);
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        return (
          !value || (value?.type && SUPPORTED_FORMATS.includes(value.type))
        );
      }),
  });
};
