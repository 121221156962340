// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationFidcAssetAssignors from './OperationFIDCAssetAssignors';
import OperationFIDCAssetData from './OperationFIDCAssetData';
import OperationFIDCAssetWallet from './OperationFIDCAssetWallet';
import OperationFIDCCompositionDC from './OperationFIDCCompositionDC';

export default function OperationFIDCAsset({ hash, cnpj, name }) {
  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12}>
          <OperationFIDCAssetData hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFidcAssetAssignors hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCAssetWallet cnpj={cnpj} name={name} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCCompositionDC hash={hash} cnpj={cnpj} />
        </Grid>
        <Grid item xs={12}>
          {/* <OperationFIDCBusinessDC hashId={hashId} /> */}
        </Grid>
      </Grid>
    </Box>
  );
}
