import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import VideoCard from './VideoCard';
import { AllVideosStyles } from './styles';
import { Paywall } from '../../components/Paywall';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getInformativeTextByLocation } from '../../services/informative';
import { getVideoList } from '../../services/videos';
import { ScrollToTop } from '../../utils/scroll';

export default function AllVideos() {
  const classes = AllVideosStyles();
  const history = useHistory();
  const { auth, upgradedPlanByPermission } = useAuthContext();
  const { data: collections, isLoading: collectionLoading } = useQuery(
    'collections',
    getVideoList,
    {
      enabled: auth.isFetched,
    },
  );
  const hasPermission = auth?.user?.subscription?.plan?.permissions?.videos;

  const { data: informativeData, isLoading: informativeLoading } = useQuery(
    'info',
    () => getInformativeTextByLocation('videos'),
    {
      enabled: auth.isFetched,
    },
  );

  const handleClick = (collection) => {
    if (!auth?.user?.subscription?.plan?.permissions?.videos) return;
    history.push(`/videos/${collection.slug}/${collection.id}`);
  };

  if (auth.isLoading || informativeLoading || collectionLoading) {
    return <CircularProgress variant={'indeterminate'} />;
  }

  const plansUpgrade = upgradedPlanByPermission('videos');

  return (
    <ScrollToTop>
      <Box component="section">
        <MetaTags
          title="Galeria"
          description="Acesse diariamente inteligência de mercado sobre FII, FIDC, CRI e CRA, além de encontrar acervo com mais de 3 mil artigos sobre estes mercados."
        />
        <Subheader
          title="Galeria"
          breadcrumbs1="Galeria"
          tooltipText={informativeData?.data?.videos?.informationText}
        />
        <Box>
          <Paywall
            isLoading={informativeLoading || collectionLoading}
            allow={hasPermission}
            position="before"
            content={`Assine o plano ${plansUpgrade} e tenha acesso a todos os vídeos desta galeria.`}
            minHeight={200}
            margin={2}>
            <Grid container className={classes.container}>
              {collections?.map((collection, index) => (
                <Grid
                  key={collection.id}
                  item
                  xs={12}
                  className={classes.collectionContainer}>
                  <Grid container className={classes.collectionHeader}>
                    <Grid item xs className={classes.headerContainer}>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        onClick={() => handleClick(collection)}>
                        {collection.name}
                      </Typography>
                      <Typography className={classes.subtitle}>
                        {`${collection.total} vídeo(s) na playlist`}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className={classes.videosContainer}>
                      {collection.videos?.map((video) => (
                        <Grid item xs={12} sm={6} md={4} key={video.id}>
                          <VideoCard
                            id={video.id}
                            title={video.title}
                            uploadedAt={video.uploadedAt}
                            collection={collection}
                            slug={video.slug}
                            thumbnail={
                              video.payload?.pictures?.formats?.thumbnail?.url
                            }
                            duration={video.payload?.duration}
                            document={video.document}
                            allowed={hasPermission}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {index < collections.length - 1 && (
                    <Divider className={classes.divider} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Paywall>
        </Box>
      </Box>
    </ScrollToTop>
  );
}
