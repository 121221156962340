import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import PhoneFormat from 'src/components/FormsUI/PhoneFormat';
import { Professional } from 'src/types/professional';
import styled from 'styled-components';
import * as Yup from 'yup';

const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;

type Props = {
  open: boolean;
  professional?: Professional;
  handleClose: () => void;
  handleUpdate: (values: any) => void;
  type?: 'emails' | 'phones';
  isLoading: boolean;
};

function ContactsForm({
  open,
  professional,
  handleClose,
  handleUpdate,
  type,
  isLoading,
}: Props) {
  const FORM_VALIDATION = Yup.object().shape({
    emails: Yup.array(
      Yup.object({
        email: Yup.string().email('Digite um e-mail válido'),
      }),
    ).max(3),
    phones: Yup.array(
      Yup.object({
        number: Yup.string().test(
          'phoneLen',
          'O telefone é inválido',
          (val) => !val || val?.replace(/\D/g, '')?.length >= 10,
        ),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      emails: professional?.emails?.length
        ? professional?.emails
        : [
            {
              email: '',
            },
          ],
      phones: professional?.phones?.length
        ? professional?.phones
        : [
            {
              number: '',
            },
          ],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional && type) {
        if (type === 'emails') {
          values.emails = values.emails.filter((value) => !!value.email);
        } else if (type === 'phones') {
          values.phones = values.phones.filter((value) => !!value.number);
        }
        const rest = _.omit(professional, 'id');
        handleUpdate({
          data: {
            ...rest,
            [type]: values[type],
          },
        });
      }
    },
  });

  const getEmailsError = (
    fieldName: keyof { email: string },
    index: number,
  ) => {
    const error = formik.errors.emails?.[index] as
      | { email: string }
      | undefined;
    return error ? error[fieldName] : '';
  };

  const getPhoneError = (
    fieldName: keyof { number: string },
    index: number,
  ) => {
    const error = formik.errors.phones?.[index] as
      | { number: string }
      | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={(e) => {
        formik.handleReset(e);
        handleClose();
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '16px 0',
        },
      }}
      sx={{
        margin: '0 auto',
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          {type === 'emails' ? 'Editar e-mails' : 'Editar telefones'}
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            minWidth: '750px',
            [theme.breakpoints.down('md')]: {
              minWidth: 'unset',
            },
          })}>
          <Grid
            item
            xs={12}
            md={12}
            container
            sx={{
              height: 'fit-content',
              justifyContent: 'flex-start',
            }}>
            <FormikProvider value={formik}>
              {type === 'emails' ? (
                <FieldArray
                  name="emails"
                  render={() => (
                    <>
                      {formik.values?.emails &&
                        formik.values?.emails?.length > 0 &&
                        formik.values?.emails.map((_, index) => (
                          <Grid
                            key={`email-${index}`}
                            xs={12}
                            md={12}
                            item
                            sx={{
                              paddingBottom: '16px',
                            }}>
                            <FieldTitle>
                              {index === 0 ? 'E-mail' : 'E-mail adicional'}
                            </FieldTitle>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{ flexWrap: 'nowrap' }}
                              container>
                              <Grid item xs={12} md={12} sx={{ width: '100%' }}>
                                <FormControl fullWidth variant="outlined">
                                  <TextField
                                    fullWidth
                                    name={`emails[${index}].email`}
                                    value={formik.values.emails[index].email}
                                    onChange={formik.handleChange}
                                    placeholder="Insira o e-mail"
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            formik.setFieldValue(
                                              `emails[${index}].email`,
                                              '',
                                            )
                                          }>
                                          <Close color="secondary" />
                                        </IconButton>
                                      ),
                                    }}
                                    error={
                                      formik?.touched.emails &&
                                      formik?.touched?.emails[index]?.email &&
                                      Boolean(getEmailsError('email', index))
                                    }
                                    helperText={
                                      formik?.touched.emails &&
                                      formik?.errors.emails &&
                                      formik?.touched?.emails[index]?.email &&
                                      getEmailsError('email', index)
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </>
                  )}
                />
              ) : (
                <FieldArray
                  name="phones"
                  render={() => (
                    <>
                      {formik.values?.phones &&
                        formik.values?.phones?.length > 0 &&
                        formik.values?.phones.map((_, index) => (
                          <Grid
                            key={`phone-${index}`}
                            xs={12}
                            md={12}
                            item
                            sx={{
                              paddingBottom: '16px',
                            }}>
                            <FieldTitle>
                              {index === 0 ? 'Telefone' : 'Telefone adicional'}
                            </FieldTitle>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{ flexWrap: 'nowrap' }}
                              container>
                              <Grid item xs={12} md={12} sx={{ width: '100%' }}>
                                <FormControl fullWidth variant="outlined">
                                  <TextField
                                    fullWidth
                                    name={`phones[${index}].number`}
                                    InputProps={{
                                      inputComponent: PhoneFormat,
                                      endAdornment: (
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            formik.setFieldValue(
                                              `phones[${index}].number`,
                                              '',
                                            )
                                          }>
                                          <Close color="secondary" />
                                        </IconButton>
                                      ),
                                    }}
                                    value={formik.values.phones[index].number}
                                    onChange={formik.handleChange}
                                    placeholder="Digite o telefone"
                                    error={
                                      formik?.touched.phones &&
                                      formik?.touched?.phones[index]?.number &&
                                      Boolean(getPhoneError('number', index))
                                    }
                                    helperText={
                                      formik?.touched.phones &&
                                      formik?.errors.phones &&
                                      formik?.touched?.phones[index]?.number &&
                                      getPhoneError('number', index)
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </>
                  )}
                />
              )}
            </FormikProvider>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '18px 24px', gap: 2 }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar edição'
            )}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              formik.handleReset(e);
              handleClose();
            }}
            disabled={isLoading}
            type="reset">
            {isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Cancelar edição'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ContactsForm;
