import qs from 'qs';

import { publicApi } from '../../api/api';
import { StrapiResponse } from '../../types/http';
import { Media, PaginationArgs } from '../../types/strapi';

type Benefits = {
  id: number;
  image: Media;
  name: string;
  description: string;
};

export const getBenefits = async (pagination: PaginationArgs) => {
  const query = qs.stringify(
    {
      populate: '*',
      pagination,
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await publicApi.get<StrapiResponse<Benefits[]>>(
    `/benefits?${query}`,
  );
  return data;
};
