import React from 'react';

import * as S from './styles';

type SectionProps = {
  title: string;
  images: Array<{
    image: string;
    url: string;
  }>;
  size?: 'big' | 'medium' | 'small' | 'smaller';
  templateColumns?: string;
  display?: 'grid' | 'flex';
  columnGap?: string;
};

const Section = (props: SectionProps) => {
  return (
    <>
      {props.images.length > 0 && (
        <S.Wrapper>
          <S.Title>{props.title}</S.Title>
          <S.SponsorLogos
            size={props.size || 'big'}
            columns={
              props.templateColumns || 'repeat(auto-fit, minmax(200px, 25%))'
            }
            display={props.display || 'grid'}
            columngap={props.columnGap || '20px'}>
            {props.images.map(({ image, url }) => (
              <img
                src={image}
                key={url}
                onClick={() => window.open(url, '_blank')}
              />
            ))}
          </S.SponsorLogos>
        </S.Wrapper>
      )}
    </>
  );
};

export default Section;
