// Material ui components
import { useEffect, useState } from 'react';

import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { PictureAsPdfOutlined } from '@material-ui/icons';
import Vimeo from '@u-wave/react-vimeo';
import moment from 'moment';

import { calculateAspectRatioFit } from '../../../utils/image';

// Styles
const useStyles = makeStyles((theme) => ({
  video: {
    padding: '0.5em 2.75em 0',
    justifyContent: 'center',
    width: '100%',
    '& iframe': {
      width: '100%',
    },
  },
  content: {
    padding: '1em 2.75em 0',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '2.25rem',
    lineHeight: '44px',
  },
  uploadedAt: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1rem',
    lineHeight: '24px',
    marginTop: '0.5rem',
  },
  description: {
    fontSize: '1rem',
    textAlign: 'justify',
    lineHeight: '24px',
    margin: '2rem 0',
  },
  downloadButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
}));

export default function VideoPlayerCard({
  id,
  title,
  description,
  uploadedAt,
  url,
  height,
  width,
  document,
  allowed,
}) {
  const classes = useStyles();
  const maxHeight = 680;
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [videoKey, setVideoKey] = useState(`${id}-${Math.random()}`);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setDimension(
      calculateAspectRatioFit(width, height, window.innerWidth, maxHeight),
    );
    setVideoKey(`${id}-${Math.random()}`);
  }, [id]);

  return (
    <Card key={videoKey}>
      <Vimeo
        id={String(id)}
        height={dimension.height}
        width={dimension.width}
        video={url}
        title={title}
        showTitle={false}
        autoplay={true}
        className={classes.video}
      />
      <CardContent className={classes.content}>
        <Typography className={classes.title}>{title}</Typography>
        <Grid container>
          <Grid item xs={11}>
            <Typography className={classes.uploadedAt}>
              {moment(uploadedAt).format('DD/MM/yyyy')}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.downloadButtonGrid}>
            <IconButton
              disabled={allowed || !document}
              href={document?.url}
              target="_blank"
              color="primary"
              onTouchStart={(event) => event.stopPropagation()}
              onMouseDown={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              title="Realize o download do documento da apresentação">
              <PictureAsPdfOutlined
                fontSize={isExtraSmall ? 'medium' : 'large'}
              />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
