import React from 'react';

import Scroller from './components/Scroller';
import StackedDisplay from './components/StackedDisplay';
import * as S from './styles';

type Props = {
  textEl: JSX.Element[];
  imageEl: Array<JSX.Element | null>;
};

const ScrollHandler = React.memo((props: Props) => {
  const [smallView, setSmallView] = React.useState(false);
  const [textElements] = React.useState(props.textEl);
  const [imageElements] = React.useState(props.imageEl);

  const firstTextEl = textElements[0];
  const firstImageEl = imageElements[0];
  const hasIntro = firstTextEl && !firstImageEl;

  const texts = textElements.slice(hasIntro ? 1 : 0);
  const images = imageElements.slice(hasIntro ? 1 : 0);

  React.useEffect(() => {
    function getView() {
      setSmallView(window.innerWidth < 1000);
    }
    getView();

    window.addEventListener('resize', getView);

    return () => {
      window.removeEventListener('resize', getView);
    };
  }, []);

  return (
    <S.Wrapper>
      {hasIntro && <S.TextBox>{props.textEl[0]}</S.TextBox>}
      {smallView ? (
        <StackedDisplay texts={texts} images={images} />
      ) : (
        <Scroller texts={texts} images={images} />
      )}
    </S.Wrapper>
  );
});

ScrollHandler.displayName = 'ScrollHandler';

export default ScrollHandler;
