import { Box, Typography, useMediaQuery } from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import { useHistory } from 'react-router-dom';
import { EventSponsor } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

export default function SponsorBanner({
  data,
  mode,
}: {
  data: EventSponsor[];
  mode: 'preview' | 'interaction';
}) {
  const history = useHistory();
  const largeScreen = useMediaQuery('(min-width:1145px)', { noSsr: true });
  const mediumScreen = useMediaQuery('(min-width:815px)', { noSsr: true });
  const smallScreen = useMediaQuery('(min-width:540px)', { noSsr: true });
  const screenSizeConditions = [
    { screen: largeScreen, length: 4 },
    { screen: mediumScreen, length: 3 },
    { screen: smallScreen, length: 2 },
  ];

  if (!data) return null;

  const handleClick = (sponsor: EventSponsor) => {
    if (mode === 'preview') return;
    if (sponsor.link) {
      window.open(sponsor.link, '_blank', 'noopener noreferrer');
    } else {
      history.push(
        `/instituicao/${sponsor?.institution?.slug}/${sponsor?.institution?.id}`,
      );
    }
  };

  const isAnimationRequired =
    data.length > (screenSizeConditions?.find((e) => e?.screen)?.length ?? 1);

  return (
    <Box
      sx={{
        '& .alice-carousel': {
          padding: 0,
        },
      }}>
      <AliceCarousel
        autoPlay={isAnimationRequired}
        infinite={isAnimationRequired}
        autoPlayStrategy="none"
        autoPlayInterval={0}
        animationDuration={2500}
        animationEasingFunction="linear"
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        autoWidth
        items={data?.map((sponsor: EventSponsor) => (
          <Box
            key={`sponsor-${sponsor.id}`}
            boxSizing={'border-box'}
            width={'max-content'}>
            <Box
              mr={{ md: 10, xs: 5 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() => handleClick(sponsor)}
              sx={{
                width: 'fit-content',
                '&:hover': {
                  boxShadow:
                    mode === 'interaction'
                      ? '0px 4px 4px 0px rgba(0, 0, 0, 0.12)'
                      : 'none',
                  border:
                    mode === 'interaction'
                      ? '1px solid var(--Gray-5, #E0E0E0)'
                      : 'none',
                  cursor: mode === 'interaction' ? 'pointer' : 'normal',
                },
              }}>
              <img
                src={getSmallestImageUrl(sponsor.logo)}
                draggable={false}
                style={{
                  objectFit: 'contain',
                  width: 145,
                  height: 55,
                }}
                alt={sponsor.institution?.fantasyName}
              />
              <Typography
                variant="caption"
                color="#828282"
                fontSize={14}
                height={'20px'}>
                {sponsor.category}
              </Typography>
            </Box>
          </Box>
        ))}
      />
    </Box>
  );
}
