import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

export const MenuChapterTitle = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: 20,
  marginBottom: '0.5rem',
  '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
}));

export const MenuSectionTitle = styled(Typography)<TypographyProps>(() => ({
  color: '#333333',
  fontSize: 18,
  marginBottom: '0.5rem',
  '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
}));
