import { useState } from 'react';

// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

import GeneralDataItem from '../../../../components/GeneralDataItem';
import GeneralDataItemConditional from '../../../../components/GeneralDataItemConditional';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_DETAILS_CRA_GENERAL_DATA_BY_OPERATION_HASH_ID } from '../../../../graphql/queries';

export default function OperationCRAGeneralData({ hashId }) {
  const { auth } = useAuthContext();
  const [data, setData] = useState({});
  const { loading, error } = useQuery(
    GET_DETAILS_CRA_GENERAL_DATA_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cra_dados_gerais'
      ],
      onCompleted: ({ getDetailsCraGeneralDataByOperationHashId }) => {
        setData(getDetailsCraGeneralDataByOperationHashId);
      },
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={2}>
      <Subtitle title="Dados Gerais" />
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_cra_dados_gerais'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_cra_dados_gerais'
            ]
          }>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="CNPJ da Securitizadora"
                subtitle={data?.securitizerCnpj}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Securitizadora"
                subtitle={data?.securitizerName}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº da Emissão"
                subtitle={data?.operationEmissionNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº das séries"
                subtitle={data?.operationSeriesNumber}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Quantidade de séries"
                subtitle={data?.operationSeriesQuantity}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Tipo de Estrutura"
                subtitle={data?.operationStructureType}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Data de início"
                subtitle={data?.operationStartDate}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Regime Fiduciário"
                condition={data?.isFiduciaryRegiment}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiclasses"
                condition={data?.isMulticlasses}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiseries"
                condition={data?.isMultiseries}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
