import { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import { setBannerMessage } from 'src/store/display';
import { PlanPrice } from 'src/types/plans';
import * as Yup from 'yup';

import EmailDialog from './EmailDialog';
import {
  createUserEmail,
  refreshConfimationEmail,
} from '../../../services/auth';
import * as S from '../styles';

type EmailState = {
  id: number;
  email: string;
};

export function EmailConfirmationForm({
  email,
  userType,
}: {
  email: string;
  userType: string;
}) {
  const history = useHistory();
  const state = history.location?.state as {
    planPrice?: PlanPrice;
    planCount: number;
  };
  const planPriceId = state?.planPrice?.priceId;
  const planCount = state?.planCount;
  const [openDialog, setOpenDialog] = useState(false);
  const [emailData, setEmailData] = useState<EmailState>();
  const dispatch = useDispatch();
  const emailConfirmation = useMutation(createUserEmail, {
    onError: (error: any) => {
      if (error) {
        if (error?.response?.data?.error?.name === 'ValidationError') {
          formik.setErrors({ email: error?.response?.data?.error?.message });
        } else {
          dispatch(
            setBannerMessage({
              message: 'Erro ao validar cadastro do e-mail, tente novamente.',
              type: 'error',
              autoclose: true,
            }),
          );
        }
      }
    },
    onSuccess: async ({ data }) => {
      setEmailData(data);
      setOpenDialog(true);
    },
  });

  const refreshEmail = useMutation(refreshConfimationEmail, {
    onError: (error: any) => {
      if (error) {
        if (error?.response?.data?.error?.name === 'ValidationError') {
          formik.setErrors({ email: error?.response?.data?.error?.message });
        } else {
          dispatch(
            setBannerMessage({
              message: 'Erro ao validar cadastro do e-mail, tente novamente.',
              type: 'error',
              autoclose: true,
            }),
          );
        }
      }
    },
    onSuccess: async () => {
      setOpenDialog(true);
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
    history.push('/');
  };

  const ValidateForm = Yup.object().shape({
    email: Yup.string()
      .required('Campo obrigatório')
      .email('Digite um e-mail válido'),
  });

  const formik = useFormik({
    initialValues: { email },
    validationSchema: ValidateForm,
    onSubmit: (values) => {
      emailConfirmation.mutateAsync({
        email: values.email,
        userType,
        planPriceId,
        planCount,
      });
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ padding: '130px 0', maxWidth: 400, margin: '0 auto' }}>
      <Typography textAlign="center" component="h1" variant="h3">
        Crie sua conta
      </Typography>

      <Typography textAlign="center" sx={{ marginTop: 7 }}>
        Não se preocupe, para aproveitar o período de teste não é necessário
        cadastrar cartão de crédito.
      </Typography>

      <Box pt={4} pb={7}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container alignItems="center" gap={8}>
            <Grid xs={12}>
              <S.FieldTitle>E-mail*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                {/* @ts-ignore */}
                <Test id="register-form-email">
                  <TextField
                    name="email"
                    placeholder="Insira seu e-mail aqui"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Test>
              </FormControl>
            </Grid>
            <Grid xs={12} container justifyContent="center">
              {/* @ts-ignore */}
              <Test id="register-button-submitemail">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit">
                  {emailConfirmation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cadastrar e-mail'
                  )}
                </Button>
              </Test>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Typography>
        Já tem uma conta? <Link href="/login">Faça o login aqui</Link>
      </Typography>
      <EmailDialog
        open={openDialog}
        handleAction={() => refreshEmail.mutateAsync({ id: emailData?.id })}
        onClose={handleClose}
      />
    </Box>
  );
}
