import { useCallback, useEffect, useMemo, useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import CloudIcon from '@mui/icons-material/Cloud';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { If } from 'src/components/If';
import { Paywall } from 'src/components/Paywall';
import MetaTags from 'src/components/Seo/MetaTags';
import TabTemplate from 'src/components/UI/TabTemplate';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getAPIDetails, getAPIOpenApiSpecs } from 'src/services/apis';
import { API } from 'src/types/api';
import { ScrollToTop } from 'src/utils/scroll';
import { buildNamesInline } from 'src/utils/string';

import APIDetailsContent from './components/APIDetailsContent';
import APIDetailsMenu from './components/APIDetailsMenu';
import APIOpenApiSwagger from './components/APIOpenApiSwagger';
import { getChapterSectionElementRef } from './utils';
import * as S from '../styles';

enum APIDetailsTabs {
  API_DOCS = 'API_DOCS',
  API_USAGE = 'API_USAGE',
}

const apiDetailsTabs = [
  { id: APIDetailsTabs.API_DOCS, label: 'Documentação', Icon: ArticleIcon },
  { id: APIDetailsTabs.API_USAGE, label: 'Uso da API', Icon: CloudIcon },
];

type StringAttributes = Pick<
  API,
  'updateFrequency' | 'dataFrequency' | 'updateLag' | 'history'
>;

const apiDetailsVisibleAttrs: Array<{
  name: keyof StringAttributes;
  label: string;
}> = [
  { name: 'updateFrequency', label: 'Atualização' },
  { name: 'dataFrequency', label: 'Frequência dos dados' },
  { name: 'updateLag', label: 'Lag da Atualização' },
  { name: 'history', label: 'Histórico' },
];

export default function APIDetails() {
  const { auth } = useAuthContext();
  const { endpoint } = useParams<{ endpoint: string }>();
  const [activeTab, setActiveTab] = useState<APIDetailsTabs>(
    APIDetailsTabs.API_DOCS,
  );
  const [selectedChapterAndSection, setSelectedChapterAndSection] = useState({
    chapterId: 0,
    sectionId: 0,
    elementId: '',
  });

  const { isLoading: apiDetailsLoading, data: apiDetails } = useQuery<API, API>(
    'APIDetails',
    () => getAPIDetails({ endpoint }),
  );

  const { isLoading: apiOpenApiSpecLoading, data: apiOpenApiSpec } = useQuery(
    ['APIOpenApiSpecLoading', endpoint],
    () => getAPIOpenApiSpecs(endpoint),
    {
      enabled: activeTab === APIDetailsTabs.API_USAGE,
    },
  );

  const selectedChapter = useMemo(() => {
    if (apiDetails) {
      return selectedChapterAndSection.chapterId === 0
        ? apiDetails.docs.chapters[0]
        : apiDetails.docs.chapters.find(
            (chapter) => chapter.id === selectedChapterAndSection.chapterId,
          );
    }
  }, [apiDetails, selectedChapterAndSection.chapterId]);

  useEffect(() => {
    const targetElement = document.getElementById(
      selectedChapterAndSection.elementId,
    );

    if (targetElement) {
      targetElement.previousElementSibling?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedChapter, selectedChapterAndSection.elementId]);

  const handleClick = useCallback(
    (chapterId: number, sectionId: number, elementId: string) => {
      setSelectedChapterAndSection((state) => ({
        ...state,
        chapterId,
        sectionId,
        elementId,
      }));
    },
    [],
  );

  const handleTabChange = (_: React.SyntheticEvent, tab: APIDetailsTabs) => {
    setActiveTab(tab);
  };

  const pageIsLoading = apiDetailsLoading || !apiDetails;
  const chapters = apiDetails?.docs.chapters || [];

  const apiPlans = !pageIsLoading ? apiDetails.plans.map((plan) => plan) : [];
  const userPlan = !auth.isLoading && auth.user?.subscription?.plan?.name;
  const planNames = buildNamesInline(apiPlans);
  const hasPermission = apiPlans.some((plan) => plan.name === userPlan);

  return (
    <ScrollToTop>
      <MetaTags
        title={`APIs - ${apiDetails?.name}`}
        description={apiDetails?.description || ''}
      />
      <Box component="section">
        <TabTemplate
          breadcrumbs1={{ title: 'APIs', link: '/apis' }}
          breadcrumbs2={apiDetails?.name}
          tabContent={[]}
          tabIndex={0}
          handleChange={() => {}}
        />

        {pageIsLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <Paper>
            <Box sx={{ padding: 4 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={3.375}>
                <Typography
                  component="h1"
                  color="#333"
                  fontWeight={700}
                  fontSize="1.125rem">
                  {apiDetails.name}
                </Typography>
                <Stack direction="row" spacing={1}>
                  {apiDetails.plans.map((plan) => (
                    <S.CustomChip
                      variant="outlined"
                      key={plan.name}
                      label={plan.name}
                    />
                  ))}
                </Stack>
              </Stack>
              <Typography color="#4F4F4F" textAlign="justify" maxWidth="816px">
                {apiDetails.description}
              </Typography>
              <Stack direction="row" spacing={1.75} mt={4}>
                <S.CustomChip
                  label={apiDetails.market.name}
                  variant="outlined"
                />
                <S.CustomChip label={apiDetails.indicator} />
              </Stack>

              <Grid container mt={4}>
                {apiDetailsVisibleAttrs.map((visibleAttr) => (
                  <Grid item xs={12} md={3} key={visibleAttr.name}>
                    <Typography mb={2} color="#828282">
                      {visibleAttr.label}
                    </Typography>
                    <Typography>{apiDetails?.[visibleAttr.name]}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Tabs
              variant="fullWidth"
              value={activeTab}
              onChange={handleTabChange}>
              {apiDetailsTabs.map((tab) => (
                <Tab
                  iconPosition="start"
                  key={tab.id}
                  label={tab.label}
                  value={tab.id}
                  icon={<tab.Icon />}
                  sx={{ textTransform: 'unset' }}
                />
              ))}
            </Tabs>
            <Paywall
              allow={hasPermission}
              position="before"
              content={`Assine o plano ${planNames} e tenha acesso completo às APIs.`}>
              <Box p={6}>
                <If condition={activeTab === APIDetailsTabs.API_DOCS}>
                  <Grid container>
                    <Grid item xs={3}>
                      <S.ContentBox>
                        <APIDetailsMenu
                          chapters={chapters}
                          onClick={handleClick}
                        />
                      </S.ContentBox>
                    </Grid>
                    <Grid item>
                      <S.CustomVerticalDivider />
                    </Grid>
                    <Grid item xs>
                      {selectedChapter && (
                        <S.ContentBox
                          id={getChapterSectionElementRef(
                            selectedChapter.id,
                            0,
                          )}
                          sx={{
                            px: 6,
                          }}>
                          <APIDetailsContent chapter={selectedChapter} />
                        </S.ContentBox>
                      )}
                    </Grid>
                  </Grid>
                </If>

                <If condition={activeTab === APIDetailsTabs.API_USAGE}>
                  <If condition={apiOpenApiSpecLoading}>
                    <CircularProgress variant={'indeterminate'} />
                  </If>
                  <If condition={!apiOpenApiSpecLoading && apiOpenApiSpec}>
                    <APIOpenApiSwagger spec={apiOpenApiSpec} />
                  </If>
                </If>
              </Box>
            </Paywall>
          </Paper>
        )}
      </Box>
    </ScrollToTop>
  );
}
