import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, Menu, Typography } from '@mui/material';
import { State } from 'src/types/radar';

import RadarAutoComplete from './RadarAutoComplete';

type Props = {
  state: State & Required<Pick<State, 'serviceProviders'>>;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
  classes: any;
};

export default function IndicatorServiceProviders({
  state,
  setState,
  disabled,
  classes,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (
    newValue: string,
    provider: keyof typeof state.serviceProviders,
  ) => {
    let checkedProviders = state.serviceProviders[provider];

    if (checkedProviders?.indexOf(newValue) !== -1) {
      checkedProviders = checkedProviders?.filter(
        (selected) => selected !== newValue,
      );
    } else {
      checkedProviders = [...newValue];
    }

    setState({
      ...state,
      serviceProviders: {
        ...state.serviceProviders,
        [provider]: [...checkedProviders],
      },
    });
  };
  const totalServiceProviderLength = Object.values(
    state.serviceProviders,
  ).reduce((total, provider) => total + provider.length, 0);

  return (
    <>
      <Button
        id="service-providers-button"
        aria-controls={open ? 'service-providers-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        disabled={disabled}
        sx={(theme) => ({
          color: totalServiceProviderLength
            ? theme.palette.primary.main
            : '#4F4F4F',
        })}>
        Prestadores de serviço:{' '}
        {totalServiceProviderLength
          ? `+${totalServiceProviderLength}`
          : 'Todos'}
      </Button>
      <Menu
        id="service-providers-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-disabled={disabled}
        MenuListProps={{
          'aria-labelledby': 'service-providers-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '40dvh',
            minWidth: '30ch',
          },
        }}>
        <Box className={classes.filterOptionsBox}>
          <Box marginTop="8px">
            <Typography fontSize={'0.875rem'} fontWeight={700} color={'#333'}>
              Administrador
            </Typography>
            <RadarAutoComplete
              disabled={disabled}
              handleChange={handleChange}
              value={state.serviceProviders.administrador}
              name="administrador"
              classes={classes}
              state={state}
            />
          </Box>
          <Box marginTop="32px">
            <Typography fontSize={'0.875rem'} fontWeight={700} color={'#333'}>
              Gestor
            </Typography>
            <RadarAutoComplete
              disabled={disabled}
              handleChange={handleChange}
              value={state.serviceProviders.gestor}
              name="gestor"
              classes={classes}
              state={state}
            />
          </Box>
          <Box marginTop="32px">
            <Typography fontSize={'0.875rem'} fontWeight={700} color={'#333'}>
              Custodiante
            </Typography>
            <RadarAutoComplete
              disabled={disabled}
              handleChange={handleChange}
              value={state.serviceProviders.custodiante}
              name="custodiante"
              classes={classes}
              state={state}
            />
          </Box>
        </Box>
      </Menu>
    </>
  );
}
