import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import ImageInput from 'src/components/ImageInput';
import { getMarkets, getSegments } from 'src/services/institution';
import { updateProfessional } from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { Market } from 'src/types/market';
import { Professional } from 'src/types/professional';
import { Segment } from 'src/types/segments';
import { Media } from 'src/types/strapi';

import { ValidationProfessionalForm } from './validation';
import * as S from '../../styles';

const BRAZIL_STATES = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraima',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins',
];

type Props = {
  professional?: Professional;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
};

function IntroductionForm({
  openDialog,
  setOpenDialog,
  professional,
  refetch,
}: Props) {
  const dispatch = useDispatch();
  const [image, setImage] = useState<Media | null | undefined>(
    professional?.photo,
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string>();
  const { data: markets, isLoading: isLoadingMarkets } = useQuery(
    'markets',
    getMarkets,
  );
  const { data: segments, isLoading: isLoadingSegments } = useQuery(
    'segments',
    getSegments,
  );

  useEffect(() => {
    setImage(professional?.photo ?? null);
  }, [professional]);

  const updateProfessionalMutation = useMutation(updateProfessional, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar os dados do profissional',
          }),
        );
        setOpenDialog(false);
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Perfil profissional atualizado',
          }),
        );
        refetch();
        setOpenDialog(false);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      photo: null,
      name: professional?.name ?? '',
      surname: professional?.surname ?? '',
      professionalTitle: professional?.professionalTitle ?? '',
      markets: professional?.markets?.map(({ id }) => id) ?? [],
      professionalSegments:
        professional?.professionalSegments?.map(({ id }) => id) ?? [],
      location: professional?.location ?? '',
    },
    validationSchema: ValidationProfessionalForm,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional) {
        const formData = new FormData();
        const { id, ...rest } = professional;
        const { photo, ...professionalData } = values;
        if (photo) {
          formData.append('files.photo', photo);
          formData.append(
            'data',
            JSON.stringify({
              ...rest,
              ...professionalData,
            }),
          );
        } else {
          formData.append(
            'data',
            JSON.stringify({
              ...rest,
              ...professionalData,
              ...(!image ? { photo: null } : { photo: image.id }),
            }),
          );
        }

        updateProfessionalMutation.mutateAsync({
          id,
          values: formData,
        });
      }
    },
  });

  const handleTooltip = (status: boolean) => {
    setTooltipOpen(status);
  };

  if (isLoadingMarkets || isLoadingSegments) return <CircularProgress />;

  return (
    <Dialog
      open={openDialog}
      onClose={(e) => {
        formik.handleReset(e);
        setOpenDialog(false);
      }}
      maxWidth="md"
      PaperProps={{
        sx: {
          height: 'fit-content',
          maxHeight: 695,
          borderRadius: '16px',
        },
      }}>
      <DialogTitle
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ padding: '30px 70px 20px', fontSize: '18px' }}>
        Editar introdução
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            padding: '8px 32px',
            [theme.breakpoints.down('md')]: { padding: '8px 20px 10px 30px' },
          })}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              container
              sx={(theme) => ({
                justifyContent: 'flex-start',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'center',
                },
              })}
              alignItems="center">
              <ImageInput
                type="circular"
                name="photo"
                image={image}
                setImage={setImage}
                value={formik.values.photo}
                setValue={formik.setFieldValue}
                error={formik.touched.photo && Boolean(formik.errors.photo)}
                helperText={formik.errors.photo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              container
              justifyContent="center"
              flexDirection="column"
              spacing={{ sm: 0, md: 2 }}>
              <Grid item>
                <S.FieldTitle>Nome*</S.FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    name="name"
                    placeholder="Insira aqui seu nome"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                sx={(theme) => ({
                  paddingTop: 0,
                  [theme.breakpoints.down('md')]: { paddingTop: 3 },
                })}>
                <S.FieldTitle>Sobrenome*</S.FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    name="surname"
                    placeholder="Insira aqui seu sobrenome"
                    onChange={formik.handleChange}
                    value={formik.values.surname}
                    error={
                      formik.touched.surname && Boolean(formik.errors.surname)
                    }
                    helperText={formik.touched.surname && formik.errors.surname}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              flexDirection="column">
              <S.FieldTitle>Título profissional</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="professionalTitle"
                  placeholder="Ex: ANALISTA FINANCEIRO | GERENTE | AUXILIAR ADMINISTRATIVO | ESPECIALISTA EM FIDC"
                  onChange={formik.handleChange}
                  value={formik.values.professionalTitle}
                  error={
                    formik.touched.professionalTitle &&
                    Boolean(formik.errors.professionalTitle)
                  }
                  helperText={
                    formik.touched.professionalTitle &&
                    formik.errors.professionalTitle
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <S.FieldTitle>Segmentos*</S.FieldTitle>
              <Tooltip
                title={selectedSegments}
                open={
                  tooltipOpen && formik.values.professionalSegments.length > 0
                }
                placement="top">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={
                    formik.touched.professionalSegments &&
                    Boolean(formik.errors.professionalSegments)
                  }
                  onMouseEnter={() => {
                    handleTooltip(true);
                  }}
                  onMouseLeave={() => {
                    handleTooltip(false);
                  }}
                  onClick={() => {
                    handleTooltip(false);
                  }}
                  onMouseDown={() => {
                    handleTooltip(false);
                  }}>
                  <Select
                    name="professionalSegments"
                    value={formik.values.professionalSegments}
                    onChange={formik.handleChange}
                    multiple
                    displayEmpty
                    renderValue={(selected: any) => {
                      if (!selected?.length) {
                        return (
                          <Typography sx={{ color: '#BDBDBD' }}>
                            Selecione o segmento
                          </Typography>
                        );
                      }
                      const selectedSegments: Segment[] = segments.data.filter(
                        (segment: any) =>
                          selected.some((s: any) => s === segment.id),
                      );
                      setSelectedSegments(
                        selectedSegments.map(({ name }) => name).join(', '),
                      );
                      return selectedSegments
                        .map(({ name }) => name)
                        .join(', ');
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                        },
                      },
                      MenuListProps: {
                        sx: (theme) => ({
                          maxHeight: 240,
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: 10,
                            width: 8,
                            WebkitAppearance: 'none',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: 4,
                            backgroundColor: theme.palette.grey['400'],
                          },
                        }),
                      },
                    }}>
                    {segments?.data?.map((opt: Segment) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.professionalSegments.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.errors.professionalSegments) &&
                    formik.touched.professionalSegments && (
                      <FormHelperText>
                        {formik.errors.professionalSegments}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <S.FieldTitle>Mercados*</S.FieldTitle>
              <FormControl
                fullWidth
                variant="outlined"
                error={
                  formik.touched.markets && Boolean(formik.errors.markets)
                }>
                <Select
                  name="markets"
                  value={formik.values.markets}
                  onChange={formik.handleChange}
                  multiple
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (!selected?.length) {
                      return (
                        <Typography sx={{ color: '#BDBDBD' }}>
                          Selecione o mercado
                        </Typography>
                      );
                    }
                    const selectedMarkets: Market[] = markets.data.filter(
                      (market: any) =>
                        selected.some((s: any) => s === market.id),
                    );
                    return selectedMarkets.map(({ name }) => name).join(', ');
                  }}>
                  {markets?.data
                    ?.filter((m: Market) =>
                      ['CRI', 'CRA', 'FII', 'FIDC'].includes(m.name),
                    )
                    .map((opt: Market) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.markets.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                </Select>
                {Boolean(formik.errors.markets) && formik.touched.markets && (
                  <FormHelperText>{formik.errors.markets}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <S.FieldTitle>Localização atual*</S.FieldTitle>
              <FormControl
                fullWidth
                variant="outlined"
                error={
                  formik?.touched?.location && Boolean(formik?.errors?.location)
                }>
                <Select
                  name="location"
                  value={formik?.values?.location}
                  onChange={formik.handleChange}
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (!selected) {
                      return (
                        <Typography sx={{ color: '#BDBDBD' }}>
                          Selecione o estado
                        </Typography>
                      );
                    }
                    return BRAZIL_STATES.find((name) => name === selected);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: 250,
                      },
                    },
                    MenuListProps: {
                      sx: (theme) => ({
                        maxHeight: 240,
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          height: 10,
                          width: 8,
                          WebkitAppearance: 'none',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: 4,
                          backgroundColor: theme.palette.grey['400'],
                        },
                      }),
                    },
                  }}>
                  {BRAZIL_STATES.map((opt: string, index: number) => (
                    <MenuItem value={opt} key={`state-${index}`}>
                      <ListItemText primary={opt} />
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik?.errors?.location) &&
                  formik?.touched?.location && (
                    <FormHelperText>{formik?.errors?.location}</FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '18px 24px', gap: 2 }}>
          <Hidden smDown>
            <Button
              variant="contained"
              type="submit"
              disabled={updateProfessionalMutation.isLoading}>
              {updateProfessionalMutation.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar edição'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                setOpenDialog(false);
              }}
              disabled={updateProfessionalMutation.isLoading}>
              {updateProfessionalMutation.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar edição'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={updateProfessionalMutation.isLoading}>
                  {updateProfessionalMutation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar edição'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    setOpenDialog(false);
                  }}
                  disabled={updateProfessionalMutation.isLoading}>
                  {updateProfessionalMutation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar edição'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default IntroductionForm;
