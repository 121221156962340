import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  Box,
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../../assets/imgs/logoUqbar.svg';

type BreadcrumbItem =
  | string
  | {
      title: string;
      link: string;
    };

export default function SubHeader({
  title,
  titleIcon,
  subTitle,
  breadcrumbs1,
  breadcrumbs2,
  breadcrumbs,
}: {
  title?: any;
  titleIcon?: JSX.Element;
  subTitle?: any;
  breadcrumbs1?: BreadcrumbItem;
  breadcrumbs2?: BreadcrumbItem;
  breadcrumbs?: BreadcrumbItem[];
}): JSX.Element {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      square
      sx={{
        backgroundColor: 'transparent',
        paddingTop: theme.spacing(4),
        marginBottom: theme.spacing(5.375),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3),
        },
      }}>
      <Box
        sx={{
          marginRight: '1em',
          display: 'none',
          '@media print': {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        }}>
        <img src={logo} alt="UQBAR" />
      </Box>
      <Breadcrumbs
        aria-label="navegação-secundária"
        sx={(theme) => ({
          fontSize: theme.spacing(1.75),
          '@media print': {
            display: 'none',
          },
        })}
        separator={
          <ArrowForwardIosRoundedIcon
            sx={{
              fontSize: theme.spacing(1.5),
              color: '#7D83984D',
              marginTop: '2px',
            }}
          />
        }>
        <Link
          component={RouterLink}
          to="/"
          underline="none"
          sx={{ color: theme.palette.primary.main }}>
          Home
        </Link>
        {!!breadcrumbs1 && (
          <>
            {typeof breadcrumbs1 !== 'string' ? (
              <Link
                component={RouterLink}
                to={breadcrumbs1.link}
                underline="none"
                sx={{ color: theme.palette.primary.main }}>
                {breadcrumbs1.title}
              </Link>
            ) : (
              <Typography
                sx={{
                  fontSize: theme.spacing(1.75),
                }}>
                {breadcrumbs1}
              </Typography>
            )}
          </>
        )}
        {!!breadcrumbs2 && (
          <>
            {typeof breadcrumbs2 !== 'string' ? (
              <Link
                component={RouterLink}
                to={breadcrumbs2.link}
                underline="none"
                sx={{ color: theme.palette.secondary.main }}>
                {breadcrumbs2.title}
              </Link>
            ) : (
              <Typography
                sx={{
                  fontSize: theme.spacing(1.75),
                }}>
                {breadcrumbs2}
              </Typography>
            )}
          </>
        )}
        {breadcrumbs &&
          breadcrumbs.map((b, i) => (
            <div key={i}>
              {typeof b !== 'string' ? (
                <Link
                  component={RouterLink}
                  to={b.link}
                  underline="none"
                  sx={{ color: theme.palette.primary.main }}>
                  {b.title}
                </Link>
              ) : (
                <Typography
                  sx={{
                    fontSize: theme.spacing(1.75),
                  }}>
                  {b}
                </Typography>
              )}
            </div>
          ))}
      </Breadcrumbs>

      <Typography
        component="h4"
        sx={(theme) => ({
          fontSize: theme.spacing(4.625),
          fontWeight: theme.typography.fontWeightBold,
          margin: theme.spacing(4, 0, 4),
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            width: '40px',
            height: '40px',
            marginRight: '32px',
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            margin: theme.spacing(2, 0, 4),
            '& svg': {
              width: '35px',
              height: '35px',
              marginRight: '15px',
            },
          },
        })}>
        {titleIcon}
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: theme.spacing(2),
          color: theme.palette.secondary.main,
        }}>
        {subTitle}
      </Typography>
    </Paper>
  );
}
