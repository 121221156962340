import { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

import { ConfirmDialog } from '../../../components/ConfimDialog';
import { If } from '../../../components/If';
import { useAuthContext } from '../../../context/AuthContextProvider';
import CommentForm from '../CommentsForm';

export default function Comments({
  comment,
  handleReplyComment,
  handleEditComment,
  handleRemoveComment,
  loading,
  threadId,
  classes,
  commentError,
  setCommentError,
  hasPermission,
}) {
  const { auth } = useAuthContext();
  const [showForm, setShowForm] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box className={classes.commentsContent}>
      <Grid container spacing={isSmall ? 4 : 0}>
        <Grid item xs={2} sm={1}>
          <Avatar
            name={`${comment?.author?.name}`}
            maxInitials={2}
            round
            size="50"
            src={comment?.author?.professional?.photo?.formats?.thumbnail?.url}
          />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Box display="flex" alignItems="center">
            {!comment?.author?.professional ? (
              <Typography variant="subtitle1" className={classes.authorName}>
                {comment?.author?.name}
              </Typography>
            ) : (
              <Typography
                component={Link}
                to={`/profissional/${comment.author.professional.slug}/${comment.author.professional.id}`}
                variant="subtitle1"
                className={classes.authorName}>
                {comment?.author?.name}
              </Typography>
            )}
            <Typography variant="caption" style={{ marginLeft: '2rem' }}>
              {format(parseISO(comment.updatedAt), 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Box>
          <Typography className={!hasPermission && classes.blur}>
            {comment?.content}
          </Typography>
          <Box>
            <Button
              disabled={!auth.isLoggedIn || comment.blockedThread}
              onClick={() => setShowForm('replying')}>
              Responder
            </Button>
            <If condition={auth?.user?.id === comment?.author?.id}>
              <Button onClick={() => setShowForm('updating')}>Editar</Button>
              <Button onClick={() => setOpenDialog((prev) => !prev)}>
                Deletar
              </Button>
            </If>
          </Box>
          <If condition={showForm === 'replying'}>
            <CommentForm
              handleSubmit={handleReplyComment}
              loading={loading}
              submitLabel="Responder"
              threadId={threadId}
              handleClose={() => setShowForm(null)}
              isComment={false}
              placeholder="Digite sua resposta"
              commentError={commentError}
              setCommentError={setCommentError}
            />
          </If>
          <If condition={showForm === 'updating'}>
            <CommentForm
              handleSubmit={handleEditComment}
              loading={loading}
              submitLabel="Editar"
              commentId={comment.id}
              handleClose={() => setShowForm(null)}
              isComment={false}
              placeholder="Edite seu comentário"
              commentError={commentError}
              setCommentError={setCommentError}
              content={comment.content}
            />
          </If>
        </Grid>
      </Grid>
      <If condition={comment?.children?.length}>
        {comment.children
          .filter((thread) => thread.blocked === false)
          .map((thread) => (
            <Box key={thread.id} className={classes.threadContent}>
              <Comments
                comment={thread}
                threadId={comment.id}
                handleEditComment={handleEditComment}
                handleRemoveComment={handleRemoveComment}
                handleReplyComment={handleReplyComment}
                classes={classes}
                commentError={commentError}
                setCommentError={setCommentError}
                hasPermission={hasPermission}
              />
            </Box>
          ))}
      </If>
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={'Tem certeza que deseja excluir o comentário?'}
        subTitle={''}
        submitAction={() => handleRemoveComment(comment.id, comment.author.id)}
        hasActionButton={Boolean(handleRemoveComment)}
      />
    </Box>
  );
}
