/* eslint-disable no-console */
import { useState } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import uniq from 'lodash/uniq';
import { useQuery } from 'react-query';
import { getFundByName, getFundClassAndSeries } from 'src/services/operation';

type Option = {
  label: string;
  value: string;
};

export type Fund = {
  name: string;
  cnpj: string;
};

export interface SelectClassSerieValue extends Fund {
  classSerie: string;
}

type SelectFundAndSerieProps = {
  label: string;
  color?: string;
  value?: Fund;
  onSelectClassSerie: (value: SelectClassSerieValue) => void;
};

export const SelectFundAndSerie = ({
  label,
  color,
  value,
  onSelectClassSerie,
}: SelectFundAndSerieProps): JSX.Element => {
  const initialValue = value ?? { cnpj: '', name: '' };

  const [fundSelected, setFundSelected] = useState<Fund>(initialValue);
  const [classSerieSelected, setClassSerieSelected] = useState<string>('');
  const [fundOptions, setFundOptions] = useState<Option[]>([
    {
      label: value?.name ?? '',
      value: value?.cnpj ?? '',
    },
  ]);
  const [classAndSeriesOptions, setClassAndSeriesOptions] = useState<string[]>(
    [],
  );

  const { isLoading: isLoadingFunds } = useQuery(
    ['get-fund-by-name'],
    () => getFundByName(),

    {
      onSuccess: (data) => {
        if (data?.length) {
          setFundOptions(
            data.flatMap(({ cnpj_fundo, denominacao_social }) => ({
              value: cnpj_fundo,
              label: denominacao_social,
            })),
          );
        }
      },
    },
  );

  useQuery(
    ['fund-classes', fundSelected],
    () => getFundClassAndSeries(fundSelected),
    {
      onSuccess: (data) => {
        if (!data.length) {
          setClassAndSeriesOptions([]);
          setClassSerieSelected('');
          return;
        }

        const classAndSeries = uniq(
          data.flatMap(({ classe_serie }) => classe_serie),
        );

        const initialClassSerie = classAndSeries[0];

        setClassAndSeriesOptions(classAndSeries);
        setClassSerieSelected(initialClassSerie);

        onSelectClassSerie({ ...fundSelected, classSerie: initialClassSerie });
      },
    },
  );

  const handleChangeClassSerie = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setClassSerieSelected(value);
    onSelectClassSerie({ ...fundSelected, classSerie: value });
  };

  const handleChangeFund = (_: any, option: Option) => {
    setFundSelected({
      cnpj: option.value,
      name: option.label,
    });
  };

  return (
    <Stack alignItems="center" flexDirection={['column', 'row']} gap={2}>
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={2}
        sx={{ width: '100%', maxWidth: 400 }}>
        <Box height={40} width={40} borderRadius="50%" bgcolor={color} />
        <Autocomplete
          options={fundOptions}
          multiple={false}
          sx={{ flex: 1 }}
          loading={isLoadingFunds}
          disableClearable
          defaultValue={{
            label: fundSelected.name,
            value: fundSelected.cnpj,
          }}
          onChange={handleChangeFund}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.label + option.value}>
              {option.label}
            </Box>
          )}
          isOptionEqualToValue={(option: Option, value) =>
            option.value === value.value
          }
          noOptionsText="Digite para buscar..."
          loadingText="Carregando..."
          renderInput={(params) => (
            <TextField
              key={label}
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingFunds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
      <Select
        placeholder="Classe"
        value={classSerieSelected}
        onChange={handleChangeClassSerie}
        sx={{ width: '100%', maxWidth: 400 }}>
        {classAndSeriesOptions.map((classAndSeriesOption) => (
          <MenuItem key={classAndSeriesOption} value={classAndSeriesOption}>
            {classAndSeriesOption
              .replace(/\?nica/g, 'Única')
              .replace(/\?nico/g, 'Único')
              .replace(/S\?rie/g, 'Série')}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
