import { makeStyles } from '@material-ui/core';

export const useBannerRotatingStyles = makeStyles((theme) => ({
  bannerRotating: {
    position: 'relative',
    height: 567,
    [theme.breakpoints.down('xs')]: {
      marginTop: '78px',
    },
  },
  controllerWrapper: {
    width: '100%',
    maxWidth: 1280,
    position: 'relative',
    margin: '0 auto',
  },

  carouselContainer: {
    overflow: 'hidden',
  },

  carousel: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    marginBottom: 16,
  },

  carouselHideCarousel: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 1216,
  },

  slides: {
    display: 'flex',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '.2s',
    transitionProperty: 'all',
  },

  indicatorsWrapper: {
    position: 'absolute',
    bottom: theme.spacing(1),
    width: '100%',
    height: 48,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(0),
    },
  },

  indicatorsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  carouselArrows: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },

  previousIndicator: {
    color: theme.palette.common.white,
    border: 0,
    backgroundColor: 'transparent',
  },

  nextIndicator: {
    color: theme.palette.common.white,
    border: 0,
    backgroundColor: 'transparent',
  },

  indicators: {
    justifyContent: 'center',
    display: 'flex',
    height: 48,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  indicatorsOl: {
    display: 'flex',
    height: 20,
    borderRadius: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 0,
    listStyle: 'none',
    padding: 0,
    transition: 'all .2s ease-in-out',
  },

  indicatorsLi: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    margin: theme.spacing(0, 1),
    transition: 'opacity .6s ease',
  },

  indicatorsLiSelected: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(4),
    borderRadius: theme.spacing(2),
  },

  controllerContainer: {
    zIndex: 2,
    marginBottom: theme.spacing(2),
    position: 'absolute',
    left: theme.spacing(4),
    top: theme.spacing(10),
    height: theme.spacing(3),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, .3)',
      cursor: 'pointer',
    },
  },

  controller: {
    background: 'rgba(0, 0, 0, 0)',
    height: theme.spacing(3),
    border: 'none',
    padding: theme.spacing(1, 1),
    display: 'flex',
    transitionTimingFunction: 'ease',
    transitionProperty: 'all',
    transitionDuration: '.5ms',
    cursor: 'pointer',
    color: theme.palette.common.white,
    position: 'relative',
  },

  controllerRingContainer: {
    position: 'absolute',
    top: 2,
  },

  controllerProgressRing: {
    height: 20,
    width: 20,
  },

  controllerProgressRingCircle: {
    fill: 'transparent',
    stroke: theme.palette.common.white,
    strokeWidth: 8,
    transitionTimingFunction: 'linear',
    transitionDuration: '1s',
    transitionProperty: 'all',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  },

  controllerIconContainer: {
    position: 'relative',
    height: 20,
    width: 20,
  },

  controllerPlayIcon: {
    position: 'absolute',
    top: 0,
    right: 6,
  },

  controllerPauseIcon: {
    position: 'absolute',
    top: 0.2,
    right: 7,
  },

  controllerTextContainer: {
    color: theme.palette.common.white,
    fontSize: '.8rem',
    fontWeight: 600,
    lineHeight: '1.3rem',
    position: 'absolute',
    top: 0.5,
    left: 26,
  },

  controllerPlayWidth: {
    width: 100,
  },

  controllerPauseWidth: {
    width: 80,
  },
}));
