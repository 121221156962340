import { PageData } from '../types';

export const pageData: PageData[] = [
  {
    title: 'Abertura',
    subtitle: '',
    text: [
      `Os Fundos de Investimento Imobiliário (FII) se tornaram uma forma popular de investimento no setor imobiliário. Os FIIs permitem que os investidores possam obter exposição ao mercado imobiliário sem precisar comprar imóveis diretamente, uma vez que esses fundos investem em uma ampla gama de propriedades, como edifícios de escritórios, shopping centers, apartamentos e instalações industriais.
      Com a expansão do mercado, torna-se cada vez mais importante ter acesso a informações confiáveis e atualizadas sobre os FIIs. É nesse contexto que o Anuário Uqbar de Fundos de Investimento Imobiliário se destaca como uma ferramenta essencial para investidores, gestores e demais profissionais do setor.
      Além disso, o anuário apresenta uma análise do desempenho do mercado de FIIs como um todo, não se restringindo apenas àqueles FII listados para negociação em mercado de bolsa.
      `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Primário',
    subtitle: '',
    text: [
      `
    A emissão de cotas de FIIs é uma forma pela qual os fundos podem levantar capital para investir em novas propriedades ou expandir suas carteiras existentes. As emissões de cotas de FIIs podem ser uma oportunidade para os investidores acessarem um portfólio diversificado de propriedades e obter retornos potencialmente maiores do que outros investimentos imobiliários tradicionais. Nesse sentido, como os dados a seguir mostrarão, o outrora hegemônico setor de Escritórios perdeu relevância nos últimos anos de pandemia. Nesse vácuo, despontaram os FIIs de Logística, especialmente. Assim, muitos fundos realizaram emissões para ampliar, desenvolver ou adquirir imóveis pertencentes a este tipo de propriedade. Em paralelo, os FII de Renda Fixa, que, em sua maioria, investem em Certificados de Recebíveis Imobiliários (CRI), mantiveram um regime sustentado de emissões.
    `,
      `<b>Emissões de FII</b>

    Em 2022, as emissões de FII somaram R$ 37,1 bilhões, desenhando uma queda de 28% em relação ao recorde de 2021 (R$ 51,4 bilhões). Ao mesmo tempo, as emissões de FII em 2022 somam o menor valor desde 2018, quando registrou R$ 13,4 bilhões. Isso se relaciona com a conjuntura econômica adversa para os FII, como a taxa de juros em movimento de alta, além de aspectos mais relacionados ao mercado imobiliário, como a desocupação de imóveis por empresas que adotaram o home-office permanentemente.
    `,
      `<b>FIAGROS-FII é destaque em crescimento</b>

    Os FIIs de Imóvel continuam tendo a maior fatia de emissões de FII, com 36,4% do montante emitido em 2022. Entretanto, tiveram que abrir espaço para FIIs de Renda Fixa e os FIAGROS-FII. Nesse mesmo ano, os FIIs de renda fixa representaram 34,3% do montante emitido total, uma queda em relação a 2021.  Enquanto isso, os FIAGRO-FII tiveram uma participação de 18% no montante emitido em 2022, superando a fatia de Renda Variável.
    `,
      `<b>Participação dos FIIs de Escritórios nas emissões se reduz</b>

    Os FIIs do tipo Logística se mantiveram em destaque desde 2018, sempre com participação acima de 30% do montante emitido. Em 2022, Logística representou 33,2%, um aumento em relação ao ano anterior. A classe Especial, com uma participação de 8,2% em 2022, registrou crescimento em relação a 2021. Enquanto isso, Escritórios teve uma participação de 8,3%, metade do percentual obtido no ano anterior.
    `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Secundário',
    subtitle: '',
    text: [
      `O mercado secundário de cotas de FII tem sido um importante indicador do desempenho e da liquidez desses ativos no Brasil. A seção do mercado secundário do Anuário traz informações a respeito das negociações realizadas no mercado de bolsa da B3 de 2018 a 2022. Dados sobre os fundos mais negociados, os preços médios de negociação e a rentabilidade podem ser obtidos na seção Ranking desse anuário.
    `,
      `
    <b>Montante negociado de cotas de FII se reduz em 2022</b>
    O mercado secundário cotas é composto indicadores como o montante negociado e o número de negócios com cotas de FIIs na B3. Em 2022, o montante negociado atingiu R$61,1 bilhões, o que representa uma queda de 8,6% em relação ao ano anterior. Entretanto, o número de negócios obteve o recorde de 92.697.633 transações, desenvolvendo um crescimento de 38,47% em comparação a 2021.
    `,
    ],

    authors: [],
  },
  {
    title: 'Estoque',
    subtitle: '',
    text: [
      `O patrimônio líquido (PL) de um FII é o valor total dos ativos do fundo menos suas obrigações e passivos.  É importante notar que o patrimônio líquido de um FII pode flutuar ao longo do tempo, dependendo do desempenho dos ativos que o compõem e das entradas e saídas de dinheiro dos investidores. Esse “desempenho" dos ativos tem relação com a avaliação periódica dos imóveis que compõem a carteira, do valor justo dos títulos e cotas de FII. Além disso, o PL pode variar conforme as emissões ou amortizações de cotas.

    A seção de Estoque também fornece uma análise das principais tendências do mercado de FIIs em relação ao patrimônio líquido, seja em relação ao tipo de ativo, de imóvel, finalidade ou gestão.
    `,
      `
    <b>PL dos FIIs em 2022</b>

    É notório que o mercado de FIIs registrou uma variação positiva em Patrimônio Líquido (PL) e na quantidade de fundos ativos. Com um recorde de patrimônio líquido ao final de 2022, equivalente a R$ 253,51 bilhões, o mercado de FIIs também alcançou em número de FIIs, chegando aos 806 fundos. Em comparação com dezembro de 2021, o PL e o número de fundos cresceram, respectivamente, 13,1% e 13,3%.
  `,
      `
    <b>FIIs de Imóvel possuem maior visibilidade histórica</b>

    Os FIIs cujo ativo em carteira é Imóvel possuem a maior visibilidade histórica em termos de patrimônio liquido (PL), somatizando, em dezembro de 2022, 65,5% do PL dos FIIs. Em segundo lugar, os FIIs de Renda Fixa somaram 22,5% do PL, seguidos pelos FIIs de Renda Variável, com 6,7% do PL.
  `,
      `    
    <b>Fatia dos FIIs de Escritórios no PL se reduz</b>

    Ao longo dos anos é possível observar que os FIIs de Escritórios perderam espaço gradativamente em termos de PL. Em dezembro de 2022 eles passaram a representar apenas 22% do total, contra 30% em janeiro de 2018. Em paralelo, também houve uma redução da participação dos FII de Varejo. Por outro lado, os FIIs do tipo Logística ganharam muito espaço ao longo dos últimos anos, empatando com a representatividade de Escritórios.
  `,
      `
    <b>FIIs de gestão ativa são destaque em PL</b>

    Os FIIs de gestão ativa, em que os gestores possuem o maior grau de liberdade nos ativos que farão parte da carteira, se consolidaram ao longo dos anos. Em 2022, os FIIs de gestão ativa somaram incríveis 86,3% de todo o PL de FIIs, enquanto os FIIs de gestão passiva ficaram com 13,7% do PL.
   `,
      `
    <b>PL por Tipo de Finalidade</b>
    Historicamente, os FIIs que possuem como finalidade a obtenção de renda advinda dos seus ativos somam a maior parte do mercado, representando 38,4% do PL dos FIIs ao fim de 2022. Em segundo lugar está os FIIs que possuem finalidade Diversa, composta por uma conjunção de diferentes tipos de finalidade, dentre Ganho de Capital, Securitização, Desenvolvimento, somando 30,5% do PL, seguido pelos FIIs que possuem como finalidade a Gestão dos Ativos Financeiros, com 28,5% do PL.
`,
    ],

    authors: [],
  },
  {
    title: 'Rankings Uqbar',
    subtitle: '',
    text: [
      `A seção de rankings do Anuário apresenta uma análise detalhada dos fundos e dos participantes atuantes no mercado de FII. Essa seção é uma das mais requisitadas do anuário, pois oferece aos investidores e profissionais do mercado financeiro uma visão geral dos ranqueados em relação à rentabilidade, emissões e patrimônio líquido.

    Os rankings são divididos por diferentes categorias, como mercado primário, secundário e estoque.  Cada ranking de cada categoria é analisado separadamente, com informações detalhadas sobre os participantes que a compõem.
    `,
      `<b>Ranking de FII por Montante Emitido em 2022</b>
    No mercado primário temos o ranking de FIIs por Montante Emitido, composto por 403 fundos. Os três primeiros colocados do ranking possuem uma concentração de 10,0%, com a liderança do Kinea Rendimentos Imobiliários, correspondendo a 4,90% do total, representando R$ 1,82 bilhões. Na segunda colocação está o Biotic, com R$ 990 milhões, correspondendo a 2,67%. E na terceira colocação se encontra o Kinea Índice de Preços, com R$ 902,10 milhões, correspondendo a 2,43%.
  `,
      `<b>Ranking de FII por Montante Emitido em 2022 (Fundos Listados)</b>
    O mercado primário de fundos com cotas listadas na B3 possui um ranking de FII composto por 148 participantes. O líder do ranking é o Kinea Rendimentos Imobiliários com R$1,82 bilhões, representando 7,32% do total. A segunda posição é ocupado pelo Kinea Índices de Preços, com R$ 902,10 milhões, correspondendo a 3,63%. Já na terceira posição está o Urca Prime Renda, com R$ 800,64 milhões, correspondendo a 3,23%.
  `,
      `<b>Ranking de Advogados por Montante Emitido em 2022</b>

  O ranking de Assessores Jurídicos possui 6 ranqueados e um montante computado total de R$ 5,78 bilhões. Em primeiro lugar está o escritório Mello e Torres, com R$ 2,41 bilhões, representando 41,68% do total. No segundo lugar está o Mayer Brown, que atingiu a marca de R$ 1,83 bilhões, representando 31,69%. No terceiro lugar está o I2A, com R$ 830,46 milhões, ou 14,37% do total.
  `,
      `<b>Ranking de Advogados por Número de Operações em 2022</b>

  O ranking de Assessores Jurídicos possui 6 participantes, com um total de 33 operações. A liderança foi da Mayer Brown com 10 operações, no qual representa 30,30% do total. Na segunda colocação está o Mello e Torres Sociedade de Advogados com 8 operações, ou 24,24% das operações emitidas. Na terceira colocação está a I2A com 6 operações emitidas, o que representa 18,18% do total.
  `,
      `<b>Ranking de Cotas de FII por Montante Negociado em 2022</b>

  No mercado secundário, o ranking de cotas de FII é composto por 342 participantes e o total de R$ 60,59 bilhões. Os três primeiros colocados concentram 11,86% do total negociado. A liderança é do Kinea Índices de Preços, com a cifra de R$ 2,83 bilhões, na qual representa 4,64% do total. Na segunda posição está o Kinea Rendimentos Imobiliários, com R$ 2,25 bilhões, ou 3,69% do total. Em seguida, na terceira posição, está Hectare CE com R$ 2,09 bilhões, ou 3,43%.
  `,
      `<b>Ranking de Cotas de FII por Número de Negócios em 2022</b>

  O ranking de cotas de FII negociadas no mercado secundário possui o total de 92.154.732 negócios e 342 participantes ranqueados. A liderança é dada pelo Maxi Renda, com o total de 5.641.925 negócios, o que representa 6,09% do total. Na segunda posição está o Valora Hedge Fund com 2.961.979 negócios ou 3,20% do total. Na terceira posição se encontra a Kinea Rendimentos Imobiliários, com 2.675.431 negócios, o que representa 2,89% do total.
  `,
      `<b>Ranking de Cotas de FII por Variação de Preço em 2022</b>

  O ranking de cotas de FII por variação de preço conta com a participação de 465 ranqueados. O primeiro lugar em destaque é o Kinea Índices de Preços, que estabeleceu uma variação de suas cotas em 2,61% no ano. Em segundo e terceiro lugar estão o Kinea Rendimentos Imobiliários e o Hectare CE, respectivamente, ambos com variação de 2,0% a.a.
  `,
      `<b>Ranking de Cotas de FII por Dividend Yield Realizado em 2022</b>

  O Ranking de Cotas de FII por Dividend Yield Realizado coloca em primeiro lugar o Even Permuta Kinea (KINP11), com um DY realizado de 19,09% ao ano. Em segundo lugar está o JPP Capital Recebíveis (JPPA11), com 15,44%, seguido pelo Riza Akin (RZAK11) com 15,44%.
  `,
      `<b>Ranking de Cotas de FII por Rentabilidade Efetiva em 2022</b>

  A rentabilidade efetiva é equivalente à taxa interna de retorno (TIR). A primeira posição do ranking é ocupada pelo fundo Hedge Floripa Shopping, com uma rentabilidade de 44,63%, seguido pelo REAG Multi Ativos Imobiliários, com 42,97%, e Brazilian Graveyard and Death Care Services, com 35,45%.
  `,
      `<b>Ranking de Cotas de FII por Capitalização de Mercado</b>

  No topo do ranking, encontra-se o Kinea Índices de Preços, com uma capitalização de mercado de mais de R$ 7 bilhões. Em segundo lugar, temos o Kinea Rendimentos Imobiliários, com mais de R$ 5 bilhões em valor de mercado. Ambos os fundos são administrados pela Kinea Investimentos.
  
  O terceiro lugar do ranking é ocupado pelo CSHG Logística, que possui uma capitalização de mercado de quase R$ 4 bilhões. Esse fundo investe em empreendimentos logísticos e industriais, setor que tem se destacado nos últimos anos, impulsionado pelo crescimento do e-commerce.
  `,
      `<b>Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido</b>

  O Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido traz uma lista dos 244 fundos imobiliários mais valiosos em relação ao seu patrimônio líquido. O destaque vai para o Even Permuta Kinea, que lidera a lista com uma capitalização de mercado 182,81% acima de seu patrimônio líquido. Em segundo lugar está o Banrisul Novas Fronteiras, com uma capitalização de 133,85% sobre o patrimônio líquido.
  `,
      `<b>Ranking de Administradores por Capitalização de Mercado</b>

  Ranking de Administradores por Capitalização de Mercado coloca, em primeiro lugar, o BTG Pactual Serviços Financeiros DTVM, com uma participação de 24,63% e um montante de R$ 34,5 bilhões. Em segundo lugar está a Intrag DTVM, com 15,35% do mercado e um montante de R$ 21,5 bilhões, seguida da BRL Trust DTVM, com 14,60% e R$ 20,5 bilhões. Vale destacar que esses administradores possuem uma ampla variedade de fundos imobiliários sob sua gestão, desde fundos de papel até fundos de tijolo, com diferentes estratégias e perfis de risco.
  `,
      `<b>Ranking de Administradores por Número de Fundos Negociados em 2022</b>

  O Ranking de Administradores por Número de Fundos Negociados fechou 2022 com 326 fundos imobiliários negociados, um número 8,67% maior que em 2021. A média de fundos negociados por administrador ficou em 8,81. O administrador que obteve o destaque do primeiro lugar foi o BTG Pactual Serviços Financeiros DTVM, com 83 fundos negociados no ano de 2022. No segundo e terceiro lugar, aparecem BRL Trust DTVM e Vórtx com 47 e 23 fundos negociados, respectivamente.
  `,
      `<b>Ranking de Gestores por Capitalização de Mercado em 2022</b>

  O Ranking de Gestores por Capitalização de Mercado somou, em 2022, R$ 189,47 bilhões. O total de ranqueados ficou em 162 e a média de capitalização por gestor foi de R$ 1,17 bilhão. Os três primeiros colocados totalizaram 21,60% de participação no ranking, sendo eles: Kinea Investimentos, Credit Suisse Hedging Griffo CV e XP Vista Asset Management .
  `,
      `<b>Ranking de Gestores por Número de Fundos Negociados em 2022</b>

  O Ranking de Gestores por Número de Fundos Negociados apresentou, em 2022, o total de 368 fundos imobiliários distribuídos entre 162 ranqueados. Os três maiores gestores dos fundos imobiliários compõem juntos 9,53% de participação no ranking. São eles: Hedge Investments Real Estate Gestão de Recursos, com a gestão de 14 fundos (participação de 3,80%). Em seguida vem o BV Asset, com 11 fundos geridos e participação de 2,99%. E, em terceiro lugar aparece a Kinea Investimentos, gestora de 10 fundos (participação de 2,72%).
  `,
      `<b>Ranking de FII por Patrimônio Líquido em 2022</b>

  O Ranking de FII por Patrimônio Líquido (PL) exibiu a cifra de R$ 13,69 bilhões no ano de 2022, valor este distribuído entre os 131 fundos que entraram em operação no respectivo ano. Em relação a 2021, o PL caiu 14,38%. O fundo imobiliário Biotic ficou em primeiro lugar, com um patrimônio líquido de R$ 990,00 milhões, resultando em uma participação de 7,23% no ranking. Em segundo lugar ficou o FIAGRO XP Crédito Agro, com participação de 5,90% e PL de R$ 807,42 milhões. E, em terceiro lugar, esteve o FIAGRO Itaú Asset Rural com participação de 5,81% e PL de R$ 794,99 milhões.
  `,
      `<b>Ranking de Administradores por Patrimônio Líquido</b>

  O Ranking de Administradores por Patrimônio Líquido (PL) apresentou a cifra de R$ 253,75 bilhões em fundos imobiliários com o total de 57 participantes. Houve uma alta de 15,12% em relação ao PL de dezembro de 2021. O BTG Pactual Serviços Financeiros DTVM ficou em primeiro lugar com, participação de 21,19% no ranking, dado o PL de R$ 53,76 bilhões. Em segundo lugar, BRL Trust DTVM com PL de R$ 38,46 bilhões e participação de 15,16%. E, em terceiro lugar, Intrag DTVM, com participação de 10,05%, visto o PL de R$ 25,49 bilhões.
  `,
      `<b>Ranking de Administradores por Número de Fundos</b>

  O Ranking de Administradores por Número de Fundos Imobiliários apresentou o total de 804 fundos ativos com 57 ranqueados. A média por participante foi de 14,11 fundos. Os três maiores administradores somam juntos o total de 37,59% de participação no ranking, estando em primeiro lugar o BTG Pactual Serviços Financeiros DTVM. Em comparação com dezembro de 2021, o total de fundos aumentou em 15,35%.
  `,
      `<b>Ranking de Auditores por Patrimônio Líquido</b>

  O Ranking de Auditores por montante de patrimônio líquido (PL) dos fundos imobiliários ativos registrou, em dezembro de 2022, R$ 224,31 bilhões e um total de 33 empresas ranqueadas. Comparado com dezembro de 2021, observa-se um aumento de 8,11% no PL. A média do PL por participante ficou em torno de R$ 6,79 bilhões. As três primeiras colocadas concentraram 68,59% do total do ranking. No primeiro lugar ficou a empresa EY, com R$ 66,26 bilhões de PL, gerando 29,54% de participação no ranking. Em seguida, PwC, somando 23,34% de participação no ranking, com PL de R$ 52,35 bilhões. E, em terceiro lugar, KPMG, com R$ 35,22 bilhões de PL, somando 15,70% de participação no ranking.
  `,
      `<b>Ranking de Auditores por Número de Fundos</b>

  O Ranking de Auditores por Número de Fundos Imobiliários contabilizou 567 fundos, com um total de 33 ranqueados em dezembro de 2022. A média em 2022 por participante ficou em 17,18 fundos. As 3 primeiras colocadas no ranking representaram 47,61% de participação e são: EY, em primeiro lugar, com 101 fundos e participação de 17,81%; KPMG, em seguida, com 89 fundos e participação de 15,70%; Grant Thornton, em terceiro lugar, com participação de 14,11% e um total de 80 fundos.
  `,
      `<b>Ranking de Gestores por Patrimônio Líquido</b>

  O Ranking de Gestores de Fundos Imobiliários (FII) por Patrimônio Líquido (PL) registrou, em dezembro de 2022, o acumulado de R$ 242,35 bilhões em PL, com um total de 236 ranqueados. Os três primeiros colocados somaram juntos o equivalente a 21,88% de participação no ranking, sendo eles: Kinea Investimentos, BRL Trust DTVM e BTG Pactual Gestora de Recursos. Comparado com dezembro de 2021, o PL computado para o respectivo ranking cresceu 17,27%.
  `,
      `<b>Ranking de Gestores por Número de Fundos</b>

  O Ranking de Gestores por Número de Fundos Imobiliários atingiu, no final de 2022, 758 fundos ativos, uma alta de 17,88% comparado com dezembro de 2021, e apresentou 236 gestores ranqueados. Os três primeiros colocados somaram participação de 11,50%, sendo eles: Genial Gestão, com participação de 4,75%, contabilizando 36 fundos; BRL Trust DTVM, com 26 fundos e participação de 3,43%; REAG Administradora de Recursos, compondo 25 fundos e com participação de 3,30% no ranking.
  `,
    ],

    authors: [],
  },
];

export const rankingsData = [
  null,
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafiiallmarket&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=primarymarketlisted&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=amountnegotiated&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=tradesnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=pricevariance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=dividendyield&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=effectivereturn&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=capitalization&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=quotafii&criteria=marketcapitalization&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=admin&criteria=capitalization&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=admin&criteria=negotiatedefundnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=manager&criteria=capitalization&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=manager&criteria=negotiatedefundnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=stock&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=admin&criteria=networthallmarket&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=admin&criteria=fundnumber&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=auditor&criteria=networthallmarket&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=auditor&criteria=fundnumber&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=manager&criteria=networthallmarket&timeRange=consolidated',
  'https://www.uqbar.com.br/rankings/dados?market=FII&year=2022&ranking=manager&criteria=fundnumber&timeRange=consolidated',
];
