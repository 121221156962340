export const normalizeContent = (content, classes) => {
  let newContent = content?.replace(
    /<img/g,
    `<img class="${classes.contentImage}"`,
  );
  newContent = newContent?.replace(
    /class="media"/g,
    `class="${classes.contentVideo}"`,
  );
  return newContent?.replace(/style="height: [\d]*px; width: [\d]*px;/g, '');
};
