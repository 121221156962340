import { Box } from '@mui/material';

import OperationCRAInvestorsPosition from './OperationCRAInvestorsPosition';
import OperationPrimaryInvestors from '../../OperationPrimaryInvestors';

type Props = {
  operationName: string;
  hash: string;
  market: string;
};

export default function OperationInvestors({
  operationName,
  hash,
  market,
}: Props) {
  return (
    <Box>
      <OperationCRAInvestorsPosition
        operationName={operationName}
        operationId={hash}
      />
      <OperationPrimaryInvestors
        hash={hash}
        market={market.toUpperCase()}
        operationName={operationName}
      />
    </Box>
  );
}
