import { useMemo } from 'react';

function Photo({ src, thumb }: { src: string; thumb?: boolean }) {
  const [width, height] = useMemo(() => {
    if (thumb) return [160, 160];
    else return [270, 200];
  }, [thumb]);
  return (
    <img
      width={width}
      height={height}
      src={src}
      style={{ objectFit: 'cover' }}
    />
  );
}

export default Photo;
