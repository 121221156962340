// material ui components
import { Grid } from '@material-ui/core';

import CTAAddon from './CTAAddon';
import { If } from '../../../components/If';

export default function ArticleAddons({ addons }) {
  return (
    <>
      {addons?.map(({ __component, ...addon }, index) => {
        return (
          <Grid key={`${index}-${__component}`} style={{ margin: '16px 0px' }}>
            <If condition={__component === 'articles.cta'}>
              <CTAAddon data={addon} />
            </If>
          </Grid>
        );
      })}
    </>
  );
}
