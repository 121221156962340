import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import emailSent from 'src/assets/imgs/emailSent.svg';
import { notificationMarketplace } from 'src/services/marketplace';
import { setBannerMessage } from 'src/store/display';
import * as Yup from 'yup';

type ServiceSendMessageDialogProps = {
  open: boolean;
  onClose: () => void;
  service: {
    title: string;
    id: number;
    type: 'Oferta' | 'Demanda' | 'Serviço' | 'Projeto';
  };
};

interface Params {
  description: string;
  id: number;
}

export function ServiceSendMessageDialog({
  open,
  onClose,
  service,
}: ServiceSendMessageDialogProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isService = service?.type?.match(/serviço/i);
  const dispatch = useDispatch();

  const sendMessage = useMutation<any, any, Params>(
    (values) =>
      notificationMarketplace({
        ...values,
        type: isService ? 'serviço' : 'projeto',
      }),
    {
      onSuccess: () => {
        dispatch(
          setBannerMessage({
            message: isService
              ? 'Interesse enviado com sucesso'
              : 'Proposta enviada com sucesso',
            type: 'success',
            autoclose: true,
          }),
        );
      },
      onError: () => {
        dispatch(
          setBannerMessage({
            message: isService
              ? 'Falha ao enviar interesse'
              : 'Falha ao enviar proposta',
            type: 'error',
            autoclose: true,
          }),
        );
      },
    },
  );

  const ValidateForm = Yup.object().shape({
    description: Yup.string().required('Campo obrigatório'),
  });

  const handleSubmit = (description: string, resetForm: () => void) => {
    sendMessage.mutateAsync({
      description,
      id: service?.id,
    });
    resetForm();
    onClose();
  };

  const formik = useFormik({
    initialValues: { description: '' },
    validationSchema: ValidateForm,
    onSubmit: (value, { resetForm }) => {
      return handleSubmit(value.description, resetForm);
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxHeight: 'none',
          maxWidth: 'none',
          height: '700px',
          width: '700px',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        },
      }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '32px',
          }}>
          <Typography fontSize={20} fontWeight={600}>
            Escreva sua proposta
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '75px 48px 0',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <img src={emailSent} width={200} height={170} />
          <Typography
            sx={{
              color: '#1D1D1D',
              fontSize: '16px',
              lineHeight: '25.2px',
              textAlign: 'center',
            }}>
            {`Ao demonstrar seu interesse será enviada uma notificação aos responsáveis pelo
               ${service?.type?.toLowerCase()}, que entrará em contato através do e-mail cadastrado em seu perfi.`}
          </Typography>
          <Box width="100%">
            <Typography
              sx={{
                margin: '30px 0px 8px',
                color: '#1D1D1D',
                fontSize: '16px',
                lineHeight: '22.4px',
              }}>
              Descrição*
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={6}
              name="description"
              placeholder={`Insira aqui uma mensagem ao responsável pelo ${service?.type?.toLowerCase()} de até 300 caracteres`}
              inputProps={{ maxLength: 300 }}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <Typography
              sx={{
                width: '100%',
                textAlign: 'right',
                position: 'relative',
                top: formik.errors.description ? '-50px' : '-30px',
                right: '20px',
                fontSize: '12px',
                lineHeight: '19px',
                opacity: 0.25,
              }}>
              Número de caracteres: {formik.values.description.length}/1500
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            padding: '0px 0px 50px',
            gap: 3,
          }}>
          <Button
            size="medium"
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.dark }}
            type="submit">
            {sendMessage.isLoading ? <CircularProgress /> : 'Concluir'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
