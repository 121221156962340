import { useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { Document, Institution } from 'src/types/institution';
import { getSmallestImageUrl } from 'src/utils/image';
import { formatBytes } from 'src/utils/number';
import { truncateText } from 'src/utils/string';

import DocumentsModal from '../DocumentsModal';

const DocumentsList = ({
  institution,
  handleRemoveDocument,
  institutionRefetch,
}: {
  institution?: Institution;
  handleRemoveDocument: (values: Document) => void;
  institutionRefetch: () => void;
}) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<
    Document | undefined
  >();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenDialog = (document: Document) => {
    setSelectedDocument(document);
    setOpenDialog(true);
  };

  const handleEdit = (document: Document) => {
    setSelectedDocument(document);
    setOpenEdit(true);
  };

  const handleRemove = () => {
    if (selectedDocument?.id && institution) {
      handleRemoveDocument(selectedDocument);
      setOpenDialog(false);
      setSelectedDocument(undefined);
    }
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'pdf.name',
        header: 'Nome do arquivo',
        size: 50,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: { cursor: 'pointer' },
          onClick: () => {
            setOpenPreviewDialog(true);
            setPreviewUrl(cell.row.original.pdf.formats.medium.url);
          },
        }),
        Cell: ({ cell }: { cell: any }) => (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <img
              src={getSmallestImageUrl(cell.row.original?.pdf)}
              alt={cell.row.original?.pdf?.alternativeText}
              width={35}
              height={42}
              style={{ borderRadius: '2px' }}
            />
            <Box>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={'secondary'}
                sx={(theme) => ({
                  '&:hover': { color: theme.palette.primary.main },
                })}>
                {truncateText(cell.row.original?.pdf?.name, 50)}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={'#828282'}>
                {formatBytes(cell.row.original?.pdf?.size, true)}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'uploadDate',
        header: 'Data de upload',
        size: 40,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        sortingFn: (rowA: any, rowB: any): number => {
          const dateA = new Date(rowA.original.createdAt);
          const dateB = new Date(rowB.original.createdAt);
          return dateB == dateA ? 0 : dateB < dateA ? 1 : -1;
        },
        Cell: ({ cell }: { cell: any }) => (
          <Typography fontSize={14} fontWeight={400} color={'#4F4F4F'}>
            {format(new Date(cell.row.original.createdAt), 'dd/MM/yyyy')}
          </Typography>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(cell.row.original)}>
              Editar
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
                textWrap: 'nowrap',
              }}
              onClick={() => handleOpenDialog(cell.row.original)}>
              Excluir arquivo
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: institution?.documents ?? [],
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    initialState: {
      sorting: [
        {
          id: 'uploadDate',
          desc: false,
        },
      ],
    },
    enableStickyHeader: true,
    enableSorting: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        padding: '16px 0 40px',
      },
    },
  });

  return (
    <>
      <MaterialTable table={table} />
      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        maxWidth={false}
        sx={{
          maxWidth: 900,
          margin: '0 auto',
          '& .MuiPaper-root': { width: '100%' },
        }}>
        <DialogTitle>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}>
            <IconButton onClick={() => setOpenPreviewDialog(false)}>
              <Close sx={{ color: 'black' }} fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img src={previewUrl} />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title={`Tem certeza que deseja remover o documento “${selectedDocument?.pdf?.name}”?`}
        subTitle="Ao remover o documento, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar o arquivo no perfil institucional."
        submitAction={handleRemove}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasActionButton
      />
      <DocumentsModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        institutionId={institution?.id}
        selectedDocument={selectedDocument}
        institutionRefetch={institutionRefetch}
      />
    </>
  );
};

export default DocumentsList;
