import { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Hidden,
  Link,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import { findOperationsByName } from 'src/services/operation';
import { Professional, RelatedOperation } from 'src/types/professional';
import styled from 'styled-components';
import * as Yup from 'yup';

const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;

type Props = {
  open: boolean;
  index: number;
  professional?: Professional;
  handleClose: () => void;
  handleUpdate: (values: any) => void;
  type?: 'emails' | 'phones';
  isLoading: boolean;
};

function RelatedOperationsForm({
  open,
  index,
  professional,
  handleClose,
  handleUpdate,
  isLoading,
}: Props) {
  const [operationName, setOperationName] = useState<string>('');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const {
    isLoading: operationsLoading,
    refetch: operationsRefetch,
    data: operationsOptions,
  } = useQuery('operationsByName', () => findOperationsByName(operationName), {
    enabled: !!operationName,
  });

  const FORM_VALIDATION = Yup.object().shape({
    relatedOperations: Yup.array(
      Yup.object({
        operation: Yup.object({
          id: Yup.string().required('Campo obrigatório'),
        }),
        role: Yup.string().required('Campo obrigatório'),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      relatedOperations: professional?.relatedOperations ?? [],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional) {
        handleUpdate({
          data: {
            ...professional,
            relatedOperations: values.relatedOperations,
          },
        });
      }
    },
  });

  const operationsRefetchDebounce = useCallback(
    debounce(operationsRefetch, 500),
    [],
  );

  const filterOptionOperations = createFilterOptions<any>();

  const getOperationErrors = (
    fieldName: keyof { id: string },
    index: number,
  ) => {
    const operationErrors = formik.errors.relatedOperations as FormikErrors<
      RelatedOperation[]
    >;
    if (!!operationErrors && !!operationErrors[index]) {
      const error = operationErrors[index]?.operation as
        | { id: string }
        | undefined;
      return error ? error[fieldName] : '';
    }
    return '';
  };

  const getErrors = <T extends { [key: string]: any }>(
    fieldName: keyof T,
    index: number,
  ) => {
    const error = formik.errors.relatedOperations?.[index] as T | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={(e) => {
        formik.handleReset(e);
        handleClose();
      }}
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
      }}
      PaperProps={{
        sx: {
          height: 'fit-content',
          borderRadius: '16px',
          padding: isMobile ? '8px 20px 10px 20px' : '16px',
        },
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          Editar operações relacionadas
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            padding: isMobile ? '16px 0' : '16px',
          }}>
          <Typography
            textAlign="center"
            fontSize={14}
            padding={isMobile ? 0 : '8px 84px'}>
            Se você colaborou ativamente em alguma operação, descreva aqui para
            exibir em seu perfil profissional.
          </Typography>
          <Grid item xs={12} md={12} container>
            <FormikProvider value={formik}>
              <FieldArray
                name="relatedOperations"
                render={() => (
                  <Grid
                    container
                    gap={2}
                    sx={(theme) => ({
                      padding: theme.spacing(2),
                    })}>
                    <Grid item xs={12} sm={12} md={12}>
                      <FieldTitle>Nome da operação*</FieldTitle>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          autoHighlight
                          filterOptions={filterOptionOperations}
                          loading={operationsLoading}
                          options={operationsOptions?.data ?? []}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value?.name
                          }
                          onChange={(event, newValue, reason) => {
                            if (!newValue?.id || reason === 'clear') {
                              formik.setFieldValue(
                                `relatedOperations[${index}].operation`,
                                {
                                  name: '',
                                  hashId: '',
                                  slug: '',
                                },
                              );
                            } else {
                              formik.setFieldValue(
                                `relatedOperations[${index}].operation`,
                                newValue,
                              );
                            }
                          }}
                          value={
                            formik.values.relatedOperations[index]?.operation
                          }
                          inputValue={operationName}
                          onInputChange={(event, newInputValue) => {
                            setOperationName(newInputValue);
                            operationsRefetchDebounce();
                          }}
                          getOptionLabel={(option: any) =>
                            typeof option === 'string' ? option : option?.name
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`relatedOperations[${index}].operation`}
                              placeholder="Selecione o nome da operação em que atuou, ex: BR Properti FII"
                              error={Boolean(getOperationErrors('id', index))}
                              helperText={getOperationErrors('id', index)}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                      <FieldTitle>
                        Função que desempenhou na operação*
                      </FieldTitle>
                      <FormControl fullWidth variant="outlined">
                        <Grid
                          container
                          sx={(theme) => ({
                            flexDirection: 'row',
                            alignItems: 'center',
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            },
                          })}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            sx={{
                              width: '100%',
                            }}>
                            <TextField
                              fullWidth
                              name={`relatedOperations[${index}].role`}
                              value={
                                formik.values.relatedOperations[index].role
                              }
                              onChange={formik.handleChange}
                              placeholder="Ex: Custodiante"
                              error={Boolean(
                                getErrors<{ role: string }>('role', index),
                              )}
                              helperText={getErrors<{ role: string }>(
                                'role',
                                index,
                              )}
                            />
                          </Grid>
                          {formik.values.relatedOperations[index]?.operation
                            ?.name && (
                            <Hidden smDown>
                              <Grid item xs={12} sm={8} md={8}>
                                <Typography
                                  sx={(theme) => ({
                                    marginLeft: '16px',
                                    [theme.breakpoints.down('sm')]: {
                                      margin: 0,
                                    },
                                  })}>
                                  {'da operação '}
                                  <Link
                                    href={`/operacao/${formik.values.relatedOperations[index]?.operation?.market?.name?.toLocaleLowerCase()}/${formik.values.relatedOperations[index]?.operation?.slug}/${formik.values.relatedOperations[index]?.operation?.hashId}`}
                                    color="#FF8211"
                                    underline="always"
                                    target="_blank">
                                    {
                                      formik.values.relatedOperations[index]
                                        ?.operation?.name
                                    }
                                  </Link>
                                  {` no mercado de ${formik.values.relatedOperations[index]?.operation?.market?.name?.toUpperCase() ?? ''}`}
                                </Typography>
                              </Grid>
                            </Hidden>
                          )}
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              />
            </FormikProvider>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '0 16px 16px', gap: 2 }}>
          <Hidden smDown>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar edição'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                handleClose();
              }}
              disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar edição'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar edição'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    handleClose();
                  }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar edição'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default RelatedOperationsForm;
