import { Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

export const ContentChapterTitle = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: 24,
  marginBottom: '2.5rem',
}));

export const ContentSectionTitle = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: 20,
  marginBottom: '1.5rem',
  color: '#333333',
}));

export const ContentRichTextWrapper = styled('div')((props) => ({
  '& p': {
    marginBottom: '1.5rem',
    color: '#4F4F4F',
    textAlign: 'justify',
    fontSize: 18,
    fontFamily: props.theme.typography.fontFamily,
    fontWeight: 400,
  },
}));
