import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Avatar, Box, Grid, Link, Paper, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link as RouterLink } from 'react-router-dom';
import Section from 'src/components/ProfileSection';
import { Institution } from 'src/types/institution';
import { Professional } from 'src/types/professional';
import { getSmallestImageUrl } from 'src/utils/image';

type Props = {
  relatedInstitutions: Institution | undefined;
  professional: Professional | undefined;
};

export function RelatedInstitutions({
  relatedInstitutions,
  professional,
}: Props) {
  return (
    <Section
      title={relatedInstitutions ? 'Empresa relacionada' : 'Empresa atual'}>
      <Grid container columnSpacing={2}>
        <Grid item>
          <Paper elevation={2}>
            {relatedInstitutions?.logo ? (
              <Avatar
                sx={(theme) => ({
                  width: 78,
                  height: 78,
                  '& > img': {
                    objectFit: 'contain',
                  },
                  [theme.breakpoints.down('sm')]: { width: 52, height: 52 },
                })}
                variant="square"
                src={getSmallestImageUrl(relatedInstitutions?.logo)}
              />
            ) : (
              <Box
                sx={(theme) => ({
                  width: 78,
                  height: 78,
                  backgroundColor: '#828282',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  [theme.breakpoints.down('sm')]: { width: 52, height: 52 },
                })}>
                <BusinessRoundedIcon fontSize="large" />
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          display="inline-flex"
          flexDirection="column"
          justifyContent="space-between">
          {relatedInstitutions ? (
            <Link
              sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '19.6px' }}
              component={RouterLink}
              to={`/instituicao/${relatedInstitutions?.slug}/${relatedInstitutions?.id}`}>
              {relatedInstitutions?.fantasyName}
            </Link>
          ) : (
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '19.6px' }}>
              {professional?.institutionName}
            </Typography>
          )}
          <Typography
            sx={(theme) => ({
              fontSize: '16px',
              [theme.breakpoints.down('sm')]: { fontSize: '14px' },
            })}>
            {professional?.role}
          </Typography>
          {professional?.institutionStartDate && (
            <Typography
              fontSize={14}
              fontWeight={600}
              textTransform="capitalize">
              {`${format(
                parse(
                  professional?.institutionStartDate,
                  'yyyy-MM-dd',
                  new Date(),
                ),
                'MMM/yyyy',
                {
                  locale: ptBR,
                },
              )} - Atualmente`}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Section>
  );
}
