import { Storefront } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import projectImg from 'src/assets/imgs/demand.png';
import serviceImg from 'src/assets/imgs/offer.png';
import { getRelatedInstitution } from 'src/services/institution';

import TabMenu from './TabMenu';
import * as S from './styles';
import TabTemplate from '../../components/UI/TabTemplate';
import { useAuthContext } from '../../context/AuthContextProvider';

const Instructions = ({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '1px solid #F1F1F1',
        background: '#FAFAFA',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '18px',
      }}>
      <img src={icon} width="68px" height="68px" />
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 600,
          marginTop: '24px',
        }}>
        {title}
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '14px',
          marginTop: '12px',
        }}>
        {description}
      </Typography>
    </Box>
  );
};

export default function MarketplaceManagement() {
  const { auth } = useAuthContext();

  const { data } = useQuery('getRelatedInstitution', getRelatedInstitution, {
    enabled: auth.isFetched,
  });

  const institution = data && Object.keys(data).length ? data : null;

  if (auth.isLoading) return <CircularProgress />;

  return (
    <Box>
      <TabTemplate
        title="Marketplace"
        titleIcon={<Storefront />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
        breadcrumbs1="Marketplace"
      />
      <S.Content>
        <Box width="100%">
          <Typography fontSize={24} fontWeight={700} marginBottom="48px">
            Bem-vindo ao marketplace Uqbar
          </Typography>
          <Typography fontSize={16} marginBottom="16px">
            Você tem um projeto incrível e está em busca dos melhores
            prestadores de serviços para torná-lo realidade? Ou você pertence a
            uma instituição pronta para oferecer soluções inovadoras e de alta
            qualidade?
          </Typography>
          <Typography fontSize={16}>
            Seja bem-vindo ao Marketplace Uqbar, o lugar perfeito para conectar
            demandantes e ofertantes.
          </Typography>
          <Grid
            container
            sx={(theme) => ({
              marginY: '64px',
              paddingX: '32px',
              justifyContent: 'center',
              gap: 3,
              [theme.breakpoints.down('sm')]: {
                padding: 0,
              },
            })}>
            <Grid item md={5}>
              <Instructions
                icon={serviceImg}
                title="Divulgue seus serviços"
                description="Publique os serviços prestados por sua instituição e alcance um público maior, apresentando suas habilidades e experiência no nosso marketplace."
              />
            </Grid>
            <Grid item md={5}>
              <Instructions
                icon={projectImg}
                title="Solicite demandas de projeto"
                description="Solicite um serviço específico para o seu projeto. Nossa plataforma conta com instituições de diversos segmentos aptas a atender sua solicitação."
              />
            </Grid>
          </Grid>
        </Box>
        <Box width="100%">
          <TabMenu institution={institution} />
        </Box>
      </S.Content>
    </Box>
  );
}
