import { useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { Professional } from 'src/types/professional';
import { truncateText } from 'src/utils/string';

import AboutForm from './AboutForm';

function About({
  professional,
  handleUpdate,
  isSuccess,
  isLoading,
}: {
  professional: Professional | undefined;
  handleUpdate: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
}) {
  const [isReadMore, setIsReadMore] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setEditDialogOpen(false);
    }
  }, [isSuccess]);

  return (
    <Section
      title="Sobre"
      canEdit={professional?.isOwner}
      openEditDialog={() => setEditDialogOpen(true)}>
      {isReadMore && professional?.profileText ? (
        <Typography
          sx={{
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{
            __html: professional?.profileText,
          }}
        />
      ) : (
        <Typography
          sx={{
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{
            __html: truncateText(professional?.profileText, 584),
          }}
        />
      )}
      <If
        condition={
          professional?.profileText && professional?.profileText.length > 584
        }>
        <Button
          variant="text"
          color="primary"
          sx={{
            fontWeight: 500,
            marginTop: 2,
            minWidth: 'unset',
            padding: 0,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={() => setIsReadMore((prev) => !prev)}>
          {isReadMore ? 'ver menos' : 'ver mais'}
        </Button>
      </If>
      <AboutForm
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
        professional={professional}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
      />
    </Section>
  );
}

export default About;
