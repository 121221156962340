import { useRef, useState } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import AccordionControlled from 'src/components/AccordionControlled';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Institution } from 'src/types/institution';

import Register from '../Register';

type Props = {
  institution: Institution;
};

const TabMenu = ({ institution }: Props) => {
  const { auth } = useAuthContext();
  const isServiceAvailable = Boolean(
    auth.user?.type === 'legal_person' &&
      auth.user?.subscription?.plan?.permissions?.marketplace_oferta,
  );
  const isProjectAvailable = Boolean(
    auth.user?.subscription?.plan?.permissions?.marketplace_demanda,
  );
  const canRegisterService = Boolean(
    isServiceAvailable && auth.user?.institution,
  );
  const canRegisterProject = Boolean(
    isProjectAvailable &&
      (auth.user?.type === 'legal_person'
        ? !!auth.user?.institution
        : !!auth.user?.professional),
  );
  const [tabValue, setTabValue] = useState(
    auth.user?.type === 'legal_person' ? '1' : '2',
  );
  const servicesRef = useRef<null | HTMLDivElement>(null);
  const projectsRef = useRef<null | HTMLDivElement>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <TabContext value={tabValue}>
      {/* <Box mb="20px" display="flex" justifyContent="flex-end">
        <Button
          onClick={() => null}
          startIcon={
            <LightbulbSharp
              color="primary"
              sx={{ fontSize: '24px !important' }}
            />
          }
          sx={{
            color: '#828282',
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
          }}
        >
          Como funciona?
        </Button>
      </Box> */}
      <Box
        sx={(theme) => ({
          display: 'flex',
          '& > div': { flex: '1 1 auto', padding: '8px 0 0 26px' },
          [theme.breakpoints.down('md')]: {
            display: 'block',
            marginTop: '54px',
            '& > div': { padding: '0' },
          },
        })}>
        <Tabs
          orientation={isMobile ? 'horizontal' : 'vertical'}
          value={tabValue}
          onChange={(e, tab) => setTabValue(tab)}
          sx={(theme) => ({
            flex: '0 0 19% !important',
            padding: '0 26px 0 33px !important',
            borderRight: '1px solid #F1F1F1',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& button': {
              alignItems: 'flex-start',
              fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
              fontWeight: 600,
              lineHeight: 0.8,
              padding: '8px 0px',
              marginBottom: '16px',
              minHeight: 'unset',
              textTransform: 'capitalize',
              color: theme.palette.secondary.main,
            },
            '& .Mui-selected': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              marginBottom: '14px',
              color: '#828282 !important',
            },
            [theme.breakpoints.down('md')]: {
              borderRight: 'none',
              padding: '0 !important',
              '& button': {
                alignItems: 'center',
                fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
                fontWeight: 400,
                padding: '6px 0px 20px 0',
                marginBottom: '0',
                minHeight: 'unset',
                textTransform: 'capitalize',
              },
              '& .Mui-selected': {
                borderBottomWidth: '3px',
                marginBottom: '0',
                color: `${theme.palette.secondary.main} !important`,
              },
              '& .MuiTabs-flexContainer': {
                placeContent: 'space-between',
                marginBottom: '2rem',
              },
            },
          })}>
          {auth.user?.type === 'legal_person' && (
            <Tab label="Serviços" value="1" />
          )}
          <Tab label="Projetos" value="2" />
        </Tabs>
        <TabPanel value="1">
          <AccordionControlled
            style={{ marginTop: '-26px' }}
            ref={servicesRef}
            title="Cadastrar serviços"
            defaultExpanded={isServiceAvailable}
            disabled={!isServiceAvailable}
            disabledReason="Funcionalidade não disponível, faça upgrade.">
            <Register
              type="Serviço"
              institution={institution}
              canRegister={canRegisterService}
            />
          </AccordionControlled>
        </TabPanel>
        <TabPanel value="2">
          <AccordionControlled
            style={{ marginTop: '-26px' }}
            ref={projectsRef}
            title="Cadastrar projetos"
            defaultExpanded={isProjectAvailable}
            disabled={!isProjectAvailable}
            disabledReason="Funcionalidade não disponível, faça upgrade.">
            <Register
              type="Projeto"
              institution={institution}
              canRegister={canRegisterProject}
            />
          </AccordionControlled>
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default TabMenu;
