import { OpenInNew } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { Convocation } from 'src/types/radar';

export default function ConvocationCard({
  key,
  convocation,
}: {
  key: string;
  convocation: Convocation;
}) {
  const convocationTypeMap = {
    'AG - Especial de Investidores': 'Assembleia Geral Especial',
    AGO: 'Assembleia Geral Ordinária',
    'AGO/E': 'Assembleia Geral Mista',
    AGE: 'Assembleia Geral Extraordinária',
  };

  return (
    <Grid
      key={key}
      item
      container
      md={12}
      sx={{
        borderRadius: '5px',
        backgroundColor: '#FFF',
        flexDirection: 'column',
        alignItems: 'space-between',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12)',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px',
          minHeight: '170px',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              color: '#47484C',
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1,
            }}>
            <FavoriteButton
              type="operation"
              id={Number(convocation.operation?.hashId)}
              iconWidth="20px"
              iconHeight="20px"
              disabled={convocation.operation === null}
            />
            <Link
              to={`/operacao/${convocation.operation.market.name.toLowerCase()}/${
                convocation.operation.slug
              }/${convocation.operation.hashId}`}
              component={RouterLink}>
              {convocation?.operation?.name ?? convocation.fundDescription}
            </Link>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: '#47484C',
                textAlign: 'right',
              }}>
              {convocationTypeMap[convocation.type]}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: '#47484C',
                textAlign: 'right',
              }}>
              {convocation.documentType}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Link
            underline="none"
            target="_blank"
            href={convocation.url}
            rel="noopener noreferrer"
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
            }}>
            Acessar convocação
            <OpenInNew sx={{ fontSize: '14px', marginLeft: '4px' }} />
          </Link>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              color: '#47484C',
            }}>
            {format(parseISO(convocation.date), 'dd/MM/yyyy | HH:mm')}
          </Typography>
        </Box>
      </Box>
      {convocation?.isFinished ? (
        <Box
          sx={{
            backgroundColor: '#D8D8D8',
            borderRadius: '0px 0px 5px 5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            padding: '8px',
          }}>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#47484C',
            }}>
            Assembleia finalizada
          </Typography>
          {convocation.operation && (
            <Link
              underline="none"
              target="_blank"
              to={`/operacao/${convocation?.operation?.market?.name?.toLowerCase()}/${
                convocation.operation?.slug
              }/${convocation.operation?.hashId}/documentos`}
              component={RouterLink}
              sx={{
                color: '#47484C',
                fontSize: '12px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
              }}>
              Acessar ata
              <OpenInNew sx={{ fontSize: '10px', marginLeft: '4px' }} />
            </Link>
          )}
        </Box>
      ) : null}
    </Grid>
  );
}
