import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Section from 'src/components/ProfileSection';
import { Institution } from 'src/types/institution';

type Props = {
  institution: Institution;
};

function Contact({ institution }: Props) {
  return (
    <Section
      title="Contatos"
      hasPaywall={true}
      paywallBanner="institutionProfile">
      <Grid container columnSpacing={6} rowSpacing={2}>
        <Grid item sm={6}>
          {!!institution?.emails?.length &&
            institution?.emails.map((email) => (
              <>
                <Box
                  key={email.id}
                  display="flex"
                  sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                  <IconButton
                    sx={{
                      marginRight: 2,
                      width: 20,
                      height: 20,
                      backgroundColor: 'black',
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}>
                    <MailRoundedIcon
                      sx={{ fontSize: '0.75rem', color: 'white' }}
                    />
                  </IconButton>
                  <Typography>{email.email}</Typography>
                </Box>
              </>
            ))}
        </Grid>
        <Grid item sm={6}>
          {!!institution?.phones?.length &&
            institution?.phones.map((phone) => (
              <Box
                key={phone.id}
                display="flex"
                sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                <IconButton
                  sx={{
                    marginRight: 2,
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}>
                  <LocalPhoneRoundedIcon
                    sx={{ fontSize: '0.75rem', color: 'white' }}
                  />
                </IconButton>
                <Typography>{phone.number}</Typography>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Section>
  );
}

export default Contact;
