import { Button, Grid, Typography } from '@mui/material';
import { CustomTooltip } from 'src/components/CustomTooltip';

type Props = {
  title: string;
  buttons?: {
    label: string;
    handle: () => void;
    disabled: boolean;
    tooltipTitle?: string;
  }[];
};

function SubTitle({ title, buttons }: Props) {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        padding: '26px 30px',
        backgroundColor: '#F1F1F1',
        [theme.breakpoints.down('md')]: {
          padding: '26px 15px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '26px 10px',
          gap: 2,
        },
      })}>
      <Grid item xs={12} sm={buttons?.length ? 6 : 12}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      {buttons?.length && (
        <Grid
          item
          container
          gap={3}
          xs={12}
          sm={6}
          sx={(theme) => ({
            justifyContent: 'flex-end',
            gap: 3,
            [theme.breakpoints.down('md')]: {
              gap: 1,
            },
            [theme.breakpoints.down('sm')]: {
              gap: 1,
              justifyContent: 'flex-start',
            },
          })}>
          {buttons?.map((button, index) => (
            <Grid key={index} item>
              <CustomTooltip title={button.tooltipTitle} placement="top" arrow>
                <span>
                  <Button
                    variant="contained"
                    onClick={button.handle}
                    disabled={button.disabled}>
                    {button.label}
                  </Button>
                </span>
              </CustomTooltip>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default SubTitle;
