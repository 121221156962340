import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@mui/material';

function ModalButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Button
      fullWidth
      onClick={onClick}
      color="secondary"
      sx={(theme) => ({
        fontSize: '0.875rem',
        color: '#828282',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: theme.palette.grey.A100,
        },
      })}
      variant="text"
      disabled={disabled}>
      <InfoIcon
        fontSize="medium"
        color={disabled ? 'disabled' : 'primary'}
        style={{ cursor: 'pointer', marginRight: 4 }}
      />
      Como utilizar
    </Button>
  );
}

export default ModalButton;
