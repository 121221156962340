import { Box, CircularProgress, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Event, Presentation } from 'src/types/event';

import PresentationAccordion from './PresentationAccordion';
import { EventPresentationFilters } from '..';

type Props = {
  event: Event;
  schedules: Presentation[];
  endedSchedules: Presentation[];
  isLoading: boolean;
  refetchEvent: () => void;
  filters: EventPresentationFilters;
  setFilters: (values: any) => void;
};

export default function Content({
  event,
  schedules,
  endedSchedules,
  isLoading,
  refetchEvent,
  filters,
  setFilters,
}: Props) {
  const handleClickDate = (date: string) => {
    if (filters.dates?.indexOf(date) === -1) {
      setFilters({
        ...filters,
        dates: [...filters.dates, format(parseISO(date), 'yyyy-MM-dd')],
      });
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
      {schedules?.length > 0 ? (
        <Box
          sx={(theme) => ({
            padding: '12px',
            backgroundColor: '#F1F1F1',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            [theme.breakpoints.down('sm')]: {
              backgroundColor: 'white',
            },
          })}>
          {schedules?.map((presentation: any) => (
            <Box key={`${presentation.id}-presentation`} width="100%">
              <PresentationAccordion
                presentation={presentation}
                event={event}
                refetch={refetchEvent}
                handleClickDate={handleClickDate}
              />
            </Box>
          ))}
        </Box>
      ) : null}
      {endedSchedules?.length > 0 ? (
        <>
          <Typography
            sx={{
              marginLeft: '8px',
              color: '#BDBDBD',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '25.2px',
            }}>
            Encerradas:
          </Typography>
          <Box
            sx={(theme) => ({
              padding: '12px',
              backgroundColor: '#F1F1F1',
              borderRadius: '5px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              [theme.breakpoints.down('sm')]: {
                backgroundColor: 'white',
              },
            })}>
            {endedSchedules?.map((presentation: any) => (
              <Box key={`${presentation.id}-presentation-ended`} width="100%">
                <PresentationAccordion
                  presentation={presentation}
                  event={event}
                  refetch={refetchEvent}
                  handleClickDate={handleClickDate}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </Box>
  );
}
