import { ReactNode } from 'react';

import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import ReactApexChart from 'react-apexcharts';
import Export from 'src/pages/AllOperations/Export';
import { v4 as uuid } from 'uuid';

type Props = {
  title: string | ReactNode;
  subtitle?: string;
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  series: any[];
  options: ApexOptions;
  valueSelect: string;
  handleChangeSelect: (e: SelectChangeEvent) => void;
  selectOptions: string[] | undefined;
  handleExport: () => void;
  permission: string;
  hasData: boolean | undefined;
  enabledExport: boolean;
};

function OperationCharts({
  title,
  subtitle,
  type,
  series,
  options,
  valueSelect,
  handleChangeSelect,
  selectOptions,
  handleExport,
  permission,
  hasData,
  enabledExport,
}: Props) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        sx={{ marginBottom: 2 }}>
        {enabledExport && (
          <Export
            handleExport={handleExport}
            handlePrint={() => null}
            permission={permission}
            hasData={hasData}
            pdfEnabled={false}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper square sx={{ height: 245, padding: '24px 18px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Typography>{title}</Typography>
            <FormControl variant="standard" sx={{ width: 100 }}>
              <Select
                value={valueSelect}
                onChange={handleChangeSelect}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 245,
                    },
                  },
                }}>
                {selectOptions?.length &&
                  selectOptions.map((option) => (
                    <MenuItem key={uuid()} value={option}>
                      {format(
                        parse(option, 'yyyy-MM-dd', new Date()),
                        'MM/yyyy',
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {subtitle && (
            <Typography fontSize={24} fontWeight={700} marginTop={0.5}>
              {subtitle}
            </Typography>
          )}
          <Box height="75%">
            <ReactApexChart
              type={type}
              options={options}
              series={series}
              width="100%"
              height="100%"
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OperationCharts;
