import { Avatar, Box, Divider, Grid, Typography } from '@material-ui/core';

export default function Facilitators({
  classes,
  facilitators,
  setState,
  state,
}) {
  return (
    <Box p={1}>
      <Grid container spacing={2}>
        {facilitators.map(({ isGuest, facilitator }, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            className={classes.facilitatorsItem}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() =>
                setState({
                  ...state,
                  facilitatorsDetails: true,
                  name: facilitator?.name,
                  description: facilitator?.description,
                  photo: facilitator?.photo?.formats?.thumbnail?.url,
                })
              }>
              <Avatar
                alt={facilitator?.name}
                src={facilitator?.photo?.formats?.thumbnail?.url}
                className={classes.facilitatorsAvatarLarge}
              />
              <Typography align="center">
                <b>{facilitator?.name}</b>
              </Typography>
              <div style={{ width: '100%' }}>
                <Divider
                  style={{
                    height: 3,
                    backgroundColor: isGuest ? '#000000' : '#FF8211',
                  }}
                />
              </div>
              <Typography align="center">
                {isGuest ? 'Convidado' : 'Facilitador'}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
