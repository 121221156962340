// Material ui components
// Apollo client
// Global components
import { ChangeEvent, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { If } from 'src/components/If';
import { PanelLogin } from 'src/components/PanelLogin';

import CardAssignor from '../../../../../components/CardAssignor';
import SkeletonGrid from '../../../../../components/SkeletonGrid';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FIDC_ASSIGNORS_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cra/queries';

type Assignors = {
  name: string;
  cnpj: string;
  strapiId: number;
  slug: string;
  market: string;
};

export default function OperationFidcAssetAssignors({
  hash,
}: {
  hash: string;
}) {
  const { auth } = useAuthContext();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [assignors, setAssignors] = useState<Assignors[]>([]);
  const lock = false;
  const perPage = 12;
  const indexOfLast = page * perPage;
  const indexOfFirst = indexOfLast - perPage;

  const onChangePagination = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleClick = (id: number, slug: string, market: string) => {
    if (id && slug) {
      if (market) {
        history.push(`/operacao/${market.toLowerCase()}/${slug}/${id}`);
      } else {
        history.push(`/instituicao/${slug}/${id}`);
      }
    }
  };

  const { loading, error } = useQuery(GET_FIDC_ASSIGNORS_BY_OPERATION_HASH_ID, {
    variables: {
      hash,
    },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions[
      'operacoes_fidic_ativo_cedentes'
    ],
    onCompleted: ({ getFidcAssignorsByOperationHashId: { assignors } }) => {
      setAssignors(assignors);
      setPages(Math.ceil(assignors?.length / perPage));
    },
  });

  if (error) return null;

  const mappedData = (assignors: Assignors[]) =>
    assignors.length > 0 &&
    assignors
      .slice(indexOfFirst, indexOfLast)
      .map(({ name, cnpj, strapiId, slug, market }) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          key={strapiId}
          onClick={() => handleClick(strapiId, slug, market)}
          sx={{ cursor: strapiId && slug ? 'pointer' : 'default' }}>
          <CardAssignor
            hasLink={Boolean(strapiId) && Boolean(slug)}
            lock={lock}
            name={name}
            document={cnpj}
          />
        </Grid>
      ));

  return (
    <div>
      <Subtitle
        title="Cedentes"
        isIncongruitiesAlert={false}
        handleChangeType={null}
        hasSelectType={null}
        isSelectedType={null}
        titleType1={null}
        titleType2={null}
      />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_cedentes'
          ]
        }>
        <PanelLogin hasLink={true} message={null} small={null} />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_cedentes'
          ]
        }>
        {assignors.length ? (
          <Box py={2}>
            <Grid container spacing={1}>
              {!loading ? (
                mappedData(assignors)
              ) : (
                <SkeletonGrid width="28em" height="6em" skeletonNumber={3} />
              )}
            </Grid>
            {!loading && assignors.length > perPage && (
              <Box pt={3} display="flex" justifyContent="flex-end">
                <Pagination
                  count={pages}
                  page={page}
                  onChange={onChangePagination}
                  shape="rounded"
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box display="flex" justifyContent="start" m={1}>
            <Typography>Não foram encontrados cedentes</Typography>
          </Box>
        )}
      </If>
    </div>
  );
}
