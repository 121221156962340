import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from 'src/theme';
import styled from 'styled-components';

interface IntroPageProps {
  backgroundImage: string;
  blur?: boolean;
}

export const Section = styled.section<IntroPageProps>`
  position: relative;
  display: flex;
  transition: background-image 1.5s ease-in-out;
  background-color: white;
  justify-content: flex-end;
  align-items: center;
  height: 100dvh;
  width: 100%;
  padding: 4rem;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 2rem;
  }
  filter: ${(props) => (props.blur ? 'blur(20px)' : 'inherit')};
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CurrentMarketIcon = styled.img`
  width: 2rem;
  height: 2rem;
  filter: brightness(0);
`;

export const CurrentMarket = styled.p`
  display: flex;
  align-items: center;
  color: black;
  font-weight: bold;
  margin-right: 6rem;
  gap: 0.5rem;
  font-size: 1.5rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 3.5rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-right: 2rem;
    font-size: 1.1rem;
  }
`;

export const Logo = styled.img`
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    width: 320px;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    width: 75%;
  }
`;

export const DownIconContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
`;

export const DownIcon = styled(ExpandMoreIcon)`
  color: #1d1d1d !important;
  font-size: 3rem !important;
  cursor: pointer;
`;
