import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import Avatar from 'react-avatar';

import { If } from '../../../components/If';
import { useAuthContext } from '../../../context/AuthContextProvider';

export default function CommentForm({
  loading,
  handleSubmit,
  submitLabel,
  commentId,
  threadId,
  handleClose,
  currentUserAvatar,
  isComment,
  placeholder,
  commentError,
  setCommentError,
  content,
}) {
  const { auth } = useAuthContext();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: {
      content: content ?? '',
    },
    onSubmit: (values, { resetForm }) => {
      if (threadId) {
        handleSubmit({ ...values, threadOf: threadId });
        handleClose();
      } else if (commentId) {
        handleSubmit({ ...values, id: commentId });
        handleClose();
      } else {
        handleSubmit(values);
      }

      resetForm();
    },
  });

  return (
    <Grid container spacing={2} alignItems="center">
      {isComment && (
        <Grid item xs={2} sm={1}>
          <Avatar
            name={`${auth?.user?.name}`}
            maxInitials={2}
            round
            size="50"
            src={currentUserAvatar}
          />
        </Grid>
      )}
      <Grid item xs={isComment ? 10 : 12} sm={11}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={isComment ? 10 : 8}>
              <TextField
                variant="standard"
                fullWidth
                name="content"
                value={formik.values.content}
                onChange={formik.handleChange}
                placeholder={placeholder}
                disabled={!auth.isLoggedIn}
              />
            </Grid>
            <Grid
              item
              xs={isComment ? 2 : 4}
              container
              wrap="nowrap"
              justifyContent="flex-end">
              <If condition={!isComment}>
                {isSmall ? (
                  <IconButton variant="contained" onClick={handleClose}>
                    <ClearRoundedIcon />
                  </IconButton>
                ) : (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleClose}
                    style={{ marginRight: '0.5rem' }}>
                    <Typography variant="button">Cancelar</Typography>
                  </Button>
                )}
              </If>
              {isSmall ? (
                <IconButton
                  disabled={!auth.isLoggedIn}
                  color="secondary"
                  variant="contained"
                  type="submit">
                  <SendRoundedIcon />
                </IconButton>
              ) : (
                <Button
                  disabled={!auth.isLoggedIn}
                  color="secondary"
                  variant="contained"
                  type="submit">
                  <Typography variant="button">
                    {!loading ? (
                      submitLabel
                    ) : (
                      <CircularProgress
                        size="1rem"
                        color="secondary.contrastText"
                      />
                    )}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
      <If condition={commentError.status}>
        <Grid item xs={12}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="fechar"
                color="inherit"
                size="small"
                onClick={() => {
                  setCommentError({
                    ...commentError,
                    status: false,
                  });
                }}>
                <Close fontSize="inherit" />
              </IconButton>
            }>
            {commentError.message}
          </Alert>
        </Grid>
      </If>
    </Grid>
  );
}
