import theme from 'src/theme';
import styled from 'styled-components';

export const Option = styled.button`
  border: none;
  font-family: 'Inter', sans-serif;
  outline: none !important;
  padding: 1rem;
  color: #666666;
  background-color: #cfcfcf;
  font-weight: bold;
  cursor: pointer;

  &.active {
    background-color: white;
    color: #ff8211;
  }
`;

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }

  ${Option} {
    &:first-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
    &:last-child {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
`;
