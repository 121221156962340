import { useState } from 'react';

// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { Chart } from 'react-chartjs-2';
import { handleLimitDatasetBySubscriptionPlan } from 'src/pages/Operation/utils';

import { TableWallet } from './TableWallet';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_RECEIVABLES_WALLET_DATASET } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  itens: {
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    border: 'none',
  },
  range: {
    borderBottom: '2px solid #FF8211',
  },
  exportButton: {
    float: 'right',
  },
  exportIcon: {
    height: '20px',
    width: '20px',
    margin: theme.spacing(0, 1.5, 0, 0.5),
  },
  tableCell: {
    fontSize: '0.8em',
  },
}));

export default function OperationFIDCAssetWallet({ cnpj, name }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const [select, setSelect] = useState(['CREDITS_DUE']);
  const [range, setRange] = useState(0);
  const [isTable, setIsTable] = useState(false);
  const [data, setData] = useState();

  const { loading } = useQuery(GET_DETAILS_FIDC_RECEIVABLES_WALLET_DATASET, {
    variables: {
      filter: {
        cnpj,
        range,
      },
    },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions[
      'operacoes_fidic_ativo_carteira'
    ],
    onCompleted: (data) => {
      const { datasets, labels } =
        data?.getDetailsFidcReceivablesWalletDataset || {};

      const credits = datasets?.find(
        (dataset) => dataset.slug === 'CREDITS_DUE',
      );
      const delays = datasets?.find((dataset) => dataset.slug === 'DELAYS');

      const normalizedDelaysData = delays?.data?.map(
        (delay, idx) => delay / (credits?.data[idx] ?? 1),
      );

      const normalizedDelays = {
        type: 'line',
        slug: 'NORMALIZED_DELAY',
        label: 'Atraso Normalizado',
        yAxisID: 'yLine',
        data: normalizedDelaysData,
        backgroundColor: 'rgb(255, 130, 17)',
        borderColor: 'rgb(255, 130, 17)',
        order: -1,
      };

      const newDatasets = [...datasets, normalizedDelays];
      const filteredData = handleLimitDatasetBySubscriptionPlan(
        auth?.user,
        newDatasets,
        labels,
      );

      setData({
        getDetailsFidcReceivablesWalletDataset: filteredData,
      });
    },
  });

  const handleChangeType = (event) => {
    setIsTable(event.target.checked);
    setRange(0);
  };

  const handleLabelClick = (slug) => {
    if (select.indexOf(slug) != -1) {
      setSelect(select.filter((s) => s !== slug));
    } else {
      setSelect((prevState) => [...prevState, slug]);
    }
  };

  return (
    <Box p={1}>
      <Subtitle
        title="Carteira"
        hasSelectType
        isSelectedType={isTable}
        handleChangeType={handleChangeType}
        titleType1="Gráfico"
        titleType2="Tabela"
      />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_carteira'
          ]
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_ativo_carteira'
          ]
        }>
        <If condition={!isTable}>
          <Grid container spacing={1} justifyContent="center">
            {data?.getDetailsFidcReceivablesWalletDataset?.datasets.map(
              ({ label, slug }, index) => (
                <Grid item key={`${index}-${slug}`}>
                  <Chip
                    label={label}
                    color={
                      index === 0
                        ? 'primary'
                        : select.includes(slug)
                          ? 'secondary'
                          : 'default'
                    }
                    variant={select.includes(slug) ? 'default' : 'outlined'}
                    onClick={
                      index > 0 ? () => handleLabelClick(slug) : undefined
                    }
                    className={classes.itens}
                  />
                </Grid>
              ),
            )}
          </Grid>
          <Box px={4}>
            <Box py={2}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(12)}
                    className={range === 12 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    12 meses
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(60)}
                    className={range === 60 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    60 meses
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(0)}
                    className={range === 0 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    Ótimo
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Chart
              type="bar"
              data={{
                labels:
                  data?.getDetailsFidcReceivablesWalletDataset?.labels ?? [],
                datasets:
                  data?.getDetailsFidcReceivablesWalletDataset?.datasets.filter(
                    ({ slug }) => select.includes(slug),
                  ) ?? [],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const isNormalizedDelay =
                          tooltipItem.dataset.slug === 'NORMALIZED_DELAY';
                        const value = tooltipItem.raw;

                        if (isNormalizedDelay)
                          return `${
                            tooltipItem.label
                          }: ${(value * 100).toFixed(2)}%`;
                        return `${
                          tooltipItem.label
                        }: ${value.toLocaleString('pt-BR')}`;
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => {
                        return value.toLocaleString('pt-BR');
                      },
                    },
                  },
                  yLine: {
                    ticks: {
                      callback: (value) => {
                        return `${value * 100}%`;
                      },
                    },
                    display: 'auto',
                    position: 'right',
                  },
                },
              }}
            />
          </Box>
        </If>
        <If condition={isTable}>
          {data?.getDetailsFidcReceivablesWalletDataset?.datasets.length ||
          data?.getDetailsFidcReceivablesWalletDataset?.labels.length ? (
            <TableWallet
              name={name}
              rows={data?.getDetailsFidcReceivablesWalletDataset?.datasets}
              periods={data?.getDetailsFidcReceivablesWalletDataset?.labels}
              loading={loading}
              classes={classes}
            />
          ) : (
            <Typography>Nenhum registro encontrado</Typography>
          )}
        </If>
      </If>
    </Box>
  );
}
