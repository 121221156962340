import { useCallback } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import * as Summary from './styles';
import { ArticleAuthor, ArticleProps } from '../../../types';

interface ArticleAccordionSummaryProps extends ArticleProps {
  author: ArticleAuthor;
}

export default function ArticleAccordionSummary({
  article,
  author,
  active,
  select,
}: ArticleAccordionSummaryProps) {
  const handleSelect = useCallback(() => {
    if (select) select(article);
  }, [select]);

  return (
    <Summary.Wrapper>
      <Summary.TitleWrapper>
        <Summary.Title>{article.title}</Summary.Title>
        <Summary.Author href={author.link} target="_blank">
          {author.name}
        </Summary.Author>
      </Summary.TitleWrapper>
      <Summary.PartnerWrapper>
        <Summary.Partner href={article.partner.link} target="_blank">
          {article.partner.name}
        </Summary.Partner>
        <Summary.ReadMore onClick={handleSelect}>
          Ver {active ? 'menos' : 'mais'}{' '}
          {active ? <ExpandLess /> : <ExpandMore />}
        </Summary.ReadMore>
      </Summary.PartnerWrapper>
    </Summary.Wrapper>
  );
}
