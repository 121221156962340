import React from 'react';

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useQuery } from 'react-query';

import * as S from './styles';
import { useEmbedContext } from '../../../../../../context/EmbedContextProvider';
import { findRanking } from '../../../../../../services/ranking';
import { TableRanking } from '../../../../../Rankings/TableRanking';

type Params = {
  market: string;
  year: number;
  ranking: string;
  criteria: string;
  timeRange: string;
};

type Props = {
  urlSource: string;
};

const extractParams = (url: string): Params | null => {
  const paramsMatch = url.match(/www\.uqbar\.com\.br\/rankings\/dados\?(.*)/);

  if (paramsMatch) {
    const params = paramsMatch[1].split('&').map((i) => i.split('='));

    return Object.fromEntries(params);
  }

  return null;
};

function Ranking(props: Props) {
  const hasConsolidated = true;

  const params = extractParams(props.urlSource);

  const { widgetKey } = useEmbedContext();

  const { data: queryData, isLoading } = useQuery(['rankings', params], () =>
    findRanking({
      filters: {
        market: params?.market?.toLowerCase(),
        year: String(params?.year),
        type: params?.ranking,
        criteria: params?.criteria,
        timeRange: params?.timeRange,
      },
      isLoggedIn: true,
      widgetKey,
    }),
  );

  if (!params) {
    return <></>;
  }

  if (isLoading) return <CircularProgress color="secondary" />;

  const tableProps = {
    hasConsolidated,
    openLinkNewTab: true,
    searchName: '',
    criteriaLabel: undefined,
    rankingLabel: undefined,
    numberCriterias: undefined,
    loading: undefined,
    percentageCriterias: undefined,
    handlePrint: undefined,
  };
  return (
    <S.Wrapper>
      <TableRanking
        rank={queryData?.ranks}
        valueLabel={queryData?.valueLabel}
        state={params}
        month={queryData?.month}
        yearbookMode={true}
        {...tableProps}
      />
      <S.ButtonWrapper>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            window.open(
              `/rankings/dados?market=${params.market}&year=${params.year}&ranking=${params.ranking}&criteria=${params.criteria}&timeRange=${params.timeRange}`,
              '_blank',
            )
          }>
          Ver o Ranking completo na plataforma Uqbar
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
}

export default React.memo(Ranking);
