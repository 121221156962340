import { useEffect, useState } from 'react';

import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { If } from '../../../components/If';
import { Paywall } from '../../../components/Paywall';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { formatUrl } from '../../../utils/string';

export default function DocumentsTable({
  classes,
  rows,
  total,
  filters,
  setSize,
  size,
  setFrom,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { auth, upgradedPlanByPermission } = useAuthContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [filters]);

  useEffect(() => {
    setFrom(page * size);
  }, [page]);

  useEffect(() => {
    setSize(rowsPerPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [rowsPerPage]);

  return (
    <Paywall
      allow={auth?.user?.subscription?.plan?.permissions?.documentos}
      content={`Quer acesso total ao banco de dados? Assine o plano ${upgradedPlanByPermission('documentos')} e tenha acesso a todas funcionalidades`}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableTitle}>
                Tipo de Documento
              </TableCell>
              <TableCell className={classes.tableTitle}>Mercado</TableCell>
              <TableCell className={classes.tableTitle}>Operação</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <If condition={rows?.length > 4}>
              {rows
                .slice(
                  ...(!auth?.user?.subscription?.plan?.permissions?.documentos
                    ? [0, 5]
                    : []),
                )
                .map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      width={90}
                      align="center">
                      <PictureAsPdfOutlinedIcon
                        fontSize="large"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: '500px' }}>
                      <Typography className={classes.rowName}>
                        {row.type}
                      </Typography>
                      <Typography className={classes.rowLight}>
                        {row.referenceDate}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" width={150} scope="row">
                      <Chip
                        className={classes.chip}
                        label={row.market}
                        color="secondary"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.rowSegments}>
                        {row.operationName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={90}
                      align="center">
                      <IconButton
                        disabled={
                          !auth?.user?.subscription?.plan?.permissions
                            ?.documentos
                        }
                        href={formatUrl(row.urlDownload)}>
                        <GetAppIcon fontSize="large" color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </If>
            <If
              condition={
                auth?.user?.subscription?.plan?.permissions?.documentos &&
                rows?.length === 0
              }>
              <TableRow>
                <TableCell
                  colSpan={4}
                  rowSpan={10}
                  component="td"
                  scope="row"
                  className={classes.notificationBox}>
                  <Typography>Nenhum resultado encontrado</Typography>
                </TableCell>
              </TableRow>
            </If>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={'Registros por página'}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                SelectProps={{
                  disabled:
                    !auth?.user?.subscription?.plan?.permissions?.documentos,
                }}
                backIconButtonProps={
                  !auth?.user?.subscription?.plan?.permissions?.documentos
                    ? {
                        disabled: true,
                      }
                    : undefined
                }
                nextIconButtonProps={
                  !auth?.user?.subscription?.plan?.permissions?.documentos
                    ? {
                        disabled: true,
                      }
                    : undefined
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paywall>
  );
}
