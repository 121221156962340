import { useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useEmbedContext } from 'src/context/EmbedContextProvider';
import { TableRanking } from 'src/pages/Rankings/TableRanking';
import { findRanking } from 'src/services/ranking';

import * as Widget from './styles';
import { YearbookMarketRankingQueryData } from '../../types';
import { extractRankingParams } from '../../utils';

interface WidgetProps {
  urlSource?: string;
  data?: YearbookMarketRankingQueryData;
  criteria?: string;
}

export default function RankingsWidget(props: WidgetProps) {
  const hasConsolidated = true;

  const params = props.urlSource
    ? extractRankingParams(props.urlSource)
    : ({
        market: 'FIAGRO',
      } as Record<string, any>);

  const { widgetKey } = useEmbedContext();

  const {
    refetch,
    data: queryData,
    isLoading,
  } = useQuery(['rankings', params], () =>
    props.urlSource
      ? findRanking({
          filters: {
            market: params?.market?.toLowerCase(),
            year: String(params?.year),
            type: params?.ranking,
            criteria: params?.criteria,
            timeRange: params?.timeRange,
          },
          isLoggedIn: true,
          widgetKey,
        })
      : new Promise((resolve) => resolve(props.data)),
  );

  useEffect(() => {
    if (props.data) refetch();
  }, [props.data]);

  if (!params) return null;

  if (isLoading) return <CircularProgress color="secondary" />;

  const tableProps = {
    hasConsolidated,
    openLinkNewTab: true,
    searchName: '',
    criteriaLabel: undefined,
    rankingLabel: undefined,
    numberCriterias: ['numeric'],
    loading: undefined,
    percentageCriterias: undefined,
    handlePrint: undefined,
  };
  return (
    <Widget.Wrapper>
      <TableRanking
        rank={queryData?.ranks}
        valueLabel={queryData?.valueLabel}
        state={{ ...params, criteria: props.criteria ?? params.criteria }}
        month={queryData?.month}
        yearbookMode
        exportDisabled
        {...tableProps}
      />
    </Widget.Wrapper>
  );
}
