import { Clear } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';
import { Institution, InstitutionInput } from 'src/types/institution';

import { List } from '../../../ProfessionalProfile/ProfessionalForm/style';

function CTA({
  formik,
  institution,
}: {
  formik: FormikProps<InstitutionInput>;
  institution?: Institution;
}) {
  const theme = useTheme();

  return (
    <Box sx={{ padding: '32px 24px' }}>
      <Typography fontSize={14}>
        A chamada à ação é um botão que direciona os visitantes em seu perfil
        para uma àrea de destaque. Disponibilizamos 3 tipos de botões, escolha
        uma das opções para destacar em seu perfil.
      </Typography>
      <List>
        <li>Ranking - Direciona o usuário para aba de ranking</li>
        <li>Serviços - Direciona o usuário para a seção dos seus serviços</li>
        <li>Documentos - Direciona o usuário para os documentos</li>
      </List>
      <Typography fontSize={14}>
        Selecione abaixo qual CTA você quer destacar em seu perfil
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={1}
        sx={(theme) => ({
          alignItems: 'center',
          marginTop: '4px',
          flexWrap: 'nowrap',
          [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
          },
        })}>
        <Grid item md={6}>
          <FormikProvider value={formik}>
            <Select
              name="callToAction"
              fullWidth
              value={formik.values.callToAction}
              onChange={formik.handleChange}
              displayEmpty
              renderValue={(selected: any) => {
                if (!selected) {
                  return (
                    <Typography sx={{ color: '#BDBDBD' }}>
                      Selecione uma das opções
                    </Typography>
                  );
                }
                if (selected === 'rankings') return 'Ver rankings';
                if (selected === 'documents') return 'Ver documentos';
                if (selected === 'services') return 'Nossos serviços';
                return '';
              }}
              endAdornment={
                formik.values.callToAction ? (
                  <InputAdornment position="end" sx={{ marginRight: '12px' }}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue('callToAction', null)
                      }>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: 250,
                  },
                },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      height: 10,
                      width: 8,
                      WebkitAppearance: 'none',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: 4,
                      backgroundColor: theme.palette.grey['400'],
                    },
                  },
                },
              }}>
              <MenuItem value="rankings" sx={{ paddingY: '8px' }}>
                <ListItemText primary="Ver rankings" />
              </MenuItem>
              {(formik.values.callToAction === 'documents' ||
                institution?.documents?.length) && (
                <MenuItem value="documents" sx={{ paddingY: '8px' }}>
                  <ListItemText primary="Ver documentos" />
                </MenuItem>
              )}
              {institution?.services?.length &&
                institution?.services?.some(
                  (service) =>
                    ['Oferta', 'Serviço', 'Projeto'].includes(service.type) &&
                    !!service.publishedAt,
                ) && (
                  <MenuItem value="services" sx={{ paddingY: '8px' }}>
                    <ListItemText primary="Nossos serviços" />
                  </MenuItem>
                )}
            </Select>
          </FormikProvider>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CTA;
