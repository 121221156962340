import { useEffect, useRef } from 'react';

import { makeStyles, Paper } from '@material-ui/core';
import qs from 'qs';
import { useQuery } from 'react-query';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import AnalyticalRanking from './AnalyticalRanking';
import SubmitRanking from './SubmitRanking';
import SummuryRanking from './SummaryRanking';
import MetaTags from '../../components/Seo/MetaTags';
import MarketsNavigation from '../../components/UI/MarketsNavigation/MarketsNavigation';
import SubHeader from '../../components/UI/SubHeader/SubHeader';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getInformativeTextByLocation } from '../../services/informative';
import { findFilters } from '../../services/ranking';
import { useQueryParams } from '../../utils/hooks';
import { ScrollToTop } from '../../utils/scroll';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  container: {
    '@media print': {
      zoom: '80%',
    },
  },
}));

const INITIAL_STATE = {
  market: 'CRI',
  ranking: null,
  criteria: null,
  year: null,
  timeRange: null,
};

export default function Rankings() {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [{ query }, setQuery] = useQueryParams(INITIAL_STATE);
  const showAnalyticalRanking =
    query.ranking && query.criteria && query.year && query.timeRange;

  const rankingsRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => rankingsRef.current,
  });

  const { data, isLoading } = useQuery(
    'filters',
    () => findFilters(undefined, auth.isLoggedIn),
    {
      onSuccess: (data) => {
        if (!query.year) {
          setQuery({
            ...query,
            year: data[query.market].years[0],
          });
        }
      },
    },
  );

  const { data: infoData } = useQuery('info', () =>
    getInformativeTextByLocation('rank'),
  );

  useEffect(() => {
    if (showAnalyticalRanking) {
      rankingsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [query]);

  const markets = data && Object.keys(data);

  const hasConsolidated =
    data &&
    data[query.market]?.rankings?.filter(
      (ranking) => ranking.code === query?.ranking,
    )[0]?.hasConsolidated;

  const methodology =
    data &&
    data[query.market]?.rankings.filter(
      (ranking) => ranking.code === query?.ranking,
    )[0]?.methodology;

  const handleChangeMarket = (value) => {
    const search = qs.stringify({
      market: value,
      year: data[value].years[0],
    });
    history.push({ pathname: '/rankings', search });
  };

  return (
    <ScrollToTop>
      <div id="rankings-page" ref={rankingsRef} className={classes.container}>
        <MetaTags
          title="Rankings Uqbar"
          description="Produzidos desde 2004 e baseados em critérios definidos sob medida para cada um dos mercados, os Rankings Uqbar classificam operações e prestadores de serviços. O conjunto completo de todos os Rankings Uqbar está disponível em nossa plataforma."
        />
        <SubHeader
          title="Rankings"
          subTitle={infoData?.data?.rank?.informationText}
          breadcrumbs1="Rankings"
        />
        <Paper square elevation={0} className={classes.paper}>
          <Switch>
            <Route exact path={path}>
              <MarketsNavigation
                value={query.market}
                handleSelectMarket={handleChangeMarket}
              />
              <SummuryRanking
                state={query}
                setState={setQuery}
                rankings={data && data[query.market]?.rankings}
                filters={data}
              />
            </Route>
            <Route exact path={`${path}/dados`}>
              <MarketsNavigation
                value={query.market}
                handleSelectMarket={handleChangeMarket}
              />
              <AnalyticalRanking
                filters={data}
                state={query}
                setState={setQuery}
                hasConsolidated={hasConsolidated}
                markets={markets}
                rankings={data && data[query.market]?.rankings}
                methodology={methodology}
                handlePrint={handlePrint}
                loadingFilters={isLoading}
              />
            </Route>
            <Route exact path={`${path}/como-participar`}>
              <SubmitRanking />
            </Route>
          </Switch>
        </Paper>
      </div>
    </ScrollToTop>
  );
}
