import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Banner = styled.img`
  margin-bottom: 100px;
  width: 100%;
`;
