import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    height: 100%;
    box-shadow: 3px 3px 8px 1px #e6e6e6;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 3px 8px 1px #e6e6e6;
    background: #ffffff;

    .box-calculator {
      font-weight: bold;
      padding: 16px 16px 10px 16px;
      border-bottom: 2px solid #303030;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .box-calculator span {
      font-weight: normal;
      font-size: x-small;
    }

    .box-calculator-title {
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0px 8px 0px 0px;
    }

    .options-calculator {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 32px;
      padding-right: 32px;
    }

    .triple {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .inline {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      margin-top: 1rem;
    }

    .alert-calculator {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .datepicker {
      margin-bottom: 1rem;
      input,
      button {
        padding-top: 18px;
      }
    }

    .hide {
      display: none;
    }

    .btn-calculate {
      padding: 8px 0px;
      text-transform: uppercase;
    }

    .input-price {
      margin-top: 0.5rem;
      width: 100%;
      background: transparent;
      padding: 0.7rem 0;
      font-size: 1rem;
      border: 1px;
      border-color: rgba(0, 0, 0, 0.42);
      border-image: none;
      border-style: none none solid none;
    }

    .input-price:focus-visible {
      outline: none;
      border-width: 2px;
      border-color: rgba(0, 0, 0, 0.87);
      border-style: none none solid none;
    }
  `}
`;
