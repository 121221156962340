import axios from 'axios';

export function getCalculatorAPIClient() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_CALCULATOR_API,
  });

  api.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('@App:token');
    if (token) {
      config.headers = { ...config.headers, authorization: `Bearer ${token}` };
    }
    return config;
  });

  return api;
}
