import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'],
    button: {
      textTransform: 'none',
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FF8211',
      contrastText: '#FFFFFF',
    },
    default: {
      main: '#00e38c',
    },
    grey: {
      default: '#C3C3C3',
    },
    green: {
      default: '#00e38c',
    },
    red: {
      default: '#f44336',
    },
    disabled: {
      main: '#EBEBE4',
    },
    orange2: {
      default: '#FC7F12',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'body #root': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          minWidth: 320,
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: '#FF8211',
          borderColor: 'transparent',
        },
      },
      groupLabel: {
        backgroundColor: '#F1F3F5',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        fontSize: '1rem',
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          content: '" "',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderBottom: '1px solid #E4E5E9',
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        color: '#000000',
        borderBottom: '4px solid #000000',
      },
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 700,
        border: '1px solid #000000',
        backgroundColor: '#FFFFFF',
        '&$selected': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#000000',
        borderRadius: 0,
        color: '#ffffff',
      },
      arrow: {
        color: '#000000',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: '2em',
        '&$completed': {
          color: '#FF8211',
        },
        '&$active': {
          color: '#FF8211',
        },
      },
      active: {},
      completed: {},
    },
    MuiStepLabel: {
      label: {
        wordBreak: 'break-word',
      },
    },
    MuiToggleButtonGroup: {
      groupedVertical: {
        '&:not(:first-child)': {
          border: 0,
        },
        '&:not(:last-child)': {
          border: 0,
        },
        '&.Mui-selected + &.Mui-selected': {
          borderRadius: 0,
          border: '1px solid #FF8211',
        },
      },
    },
    MuiToggleButton: {
      root: {
        border: '1px solid #FF8211',
      },
    },
    MuiAccordion: {
      root: {
        '&$expanded': {
          margin: '10px 0',
        },
      },
      expanded: {},
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#F9F7F7',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'end',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0 2rem 2rem',
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: '1rem',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 2rem 2rem',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: 0,
        color: '#47484C',
        marginRight: 62.5,
        padding: '6px 12px',
        '&.Mui-selected': {
          '&::after': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#E48125',
            height: 5,
            borderRadius: '20px 20px 0 0',
          },
          color: '#E48125',
        },
        '&.Mui-disabled': {
          filter: 'opacity(0.4)',
        },
      },
      label: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem',
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiListItem: {
      root: {
        minHeight: 50,
      },
    },
  },
});
export default theme;
