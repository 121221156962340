import _ from 'lodash';

import {
  ChartDataContentPayload,
  MapingColumn,
  MapingColumnLine,
} from './types';

export const mapCreditRisk = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      ...c,
      pulverizacao: c.pulverizacao ? 'Pulverizado' : 'Concentrado',
    })),
  };
};

export const mapNegotiation = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['dateneg']),
      'Soma de montante_emitido': Number(c['Soma de amountnegotiated']),
      trades: Number(c['Soma de trades']),
    })),
  };
};

export const mapInvestorData = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((e) => ({
      amount: Number(e['Soma de montante_investido']),
      perfil: String(e.perfil),
    })),
  };
};

export const mapTerm = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((e) => ({
      ...e,
      prazo: `${e.prazo} - ${Number(e.prazo) + 30}`,
    })),
  };
};

export const mapCRITerm = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((e) => ({
      ...e,
      prazo: `${e.prazo} - ${Number(e.prazo) + 50}`,
    })),
  };
};

export const mapContractTypeData = (data: ChartDataContentPayload) => {
  const total = data.content.reduce(
    (sum, item) => Number(item['Soma de montante_emitido']) + sum,
    0,
  );

  const parsedData = {
    ...data,
    content: data.content.map((e) => ({
      amount: Number(e['Soma de montante_emitido']),
      perfil:
        Number(e['Soma de montante_emitido']) / total < 0.02
          ? 'Outros'
          : String(e.documentos),
      percent: Number(e['Soma de montante_emitido']) / total,
    })),
  };

  const uniqKeys = _.uniq(parsedData.content.map((i) => i.perfil)).map(
    (key) => ({ perfil: key, amount: 0 }),
  );

  const groupedData = parsedData.content.reduce((uniqs, data) => {
    const uniqEl = uniqs.find((e) => e.perfil === data.perfil);
    const withoutUniqEl = uniqs.filter((e) => e.perfil !== data.perfil);

    return [
      ...withoutUniqEl,
      {
        perfil: String(uniqEl?.perfil),
        amount: (uniqEl?.amount || 0) + data.amount,
      },
    ];
  }, uniqKeys);

  return { ...parsedData, content: groupedData };
};

export const mapSupply = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['date']),
      amount: Number(c['Soma de saldo_devedor']),
    })),
  };
};

export const fiPl = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['data_ref']),
      amount: Number(c['Soma de pl']),
      quantity: Number(c['Valores distintos de cnpj']),
    })),
  };
};

export const fiCollateralAssetPL = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['data_ref']),
      amount: Number(c['Soma de pl']),
      type: String(c.ativo_lastro),
    })),
  };
};

export const fiMulticedingPl = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['data_ref']),
      amount: Number(c['Soma de pl']),
      quantity: Number(c['Valores distintos de cnpj']),
    })),
  };
};

export const fiCondoPl = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['data_ref']),
      amount: Number(c['Soma de pl']),
      type: String(c.condominio),
    })),
  };
};

export const fiFund = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      data_emissao: String(c['data_ref']),
      amount: Number(c['Soma de montante_emitido']),
      type: c.np ? 'Não Padronizado' : 'Padronizado',
    })),
  };
};

export const fiFundPl = (data: ChartDataContentPayload) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      ...c,
      type: c.np ? 'Não Padronizado' : 'Padronizado',
    })),
  };
};

export const mapColumnLineData = (
  data: ChartDataContentPayload,
  maping: MapingColumnLine,
) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      date: c[maping.date],
      amount: c[maping.amount],
      quantity: c[maping.quantity],
    })),
  };
};

export const mapColumnData = (
  data: ChartDataContentPayload,
  maping: MapingColumn,
) => {
  return {
    ...data,
    content: data.content.map((c) => ({
      date: c[maping.date],
      amount: c[maping.amount],
      type: c[maping.type],
    })),
  };
};

export const mapOfferType = (data: ChartDataContentPayload) => {
  const parseCategoryName = (name: string) => {
    if (name.toLocaleLowerCase() === 'private') {
      return 'Colocação Privada';
    }

    if (name.toLocaleLowerCase() === 'waiver_icvm_400') {
      return 'Dispensa de registro';
    }

    if (name.toLocaleLowerCase() === 'blank') {
      return 'ICVM 476';
    }

    if (name.toLocaleLowerCase() === 'multiple') {
      return 'Outro';
    }

    return name.replace(/_/g, ' ');
  };

  return {
    ...data,
    content: data.content.map((c) => ({
      ...c,
      tipo_oferta: parseCategoryName(String(c.tipo_oferta)),
    })),
  };
};
