import { GetAppRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format as formatDateFns } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { Indicator, RowData, RowItem } from 'src/types/radar';
import { usePreventCopyAndSelect } from 'src/utils/hooks';

import { useAuthContext } from '../../../../context/AuthContextProvider';
import {
  calculateRadarValue,
  formatRadarValue,
} from '../../../../utils/number';

type Props = {
  segment: any;
  state: any;
  arrayDeadlineOrder: any;
  hasPermission?: boolean;
  indicators: Indicator[];
  rowData: RowData;
  market: string;
};

export default function IndicatorTable({
  segment,
  state,
  arrayDeadlineOrder,
  hasPermission,
  indicators,
  rowData,
  market,
}: Props) {
  const { auth } = useAuthContext();
  const theme = useTheme();
  usePreventCopyAndSelect();

  const hasExportPermission =
    hasPermission &&
    auth?.user?.subscription?.plan?.permissions.radar_exportar_csv;

  function generateFakeData() {
    const period = [
      '2022-12',
      '2023-1',
      '2023-2',
      '2023-3',
      '2023-4',
      '2023-5',
      '2023-6',
      '2023-7',
      '2023-8',
      '2023-9',
      '2023-10',
      '2023-11',
    ];

    const generateRandomRowItem = (filter: string): RowItem => {
      const rowItem: RowItem = { filter };
      period.forEach((date) => {
        rowItem[date] = Math.random() * 1e11;
      });
      return rowItem;
    };

    const rows: { [key: string]: RowItem[] } = {
      np: Array.from({ length: 4 }, (_, index) =>
        generateRandomRowItem(`FIDC Falso ${index + 1}`),
      ),
    };

    const totals: { [date: string]: number } = {};
    period.forEach((date) => {
      totals[date] = Math.random() * 1e12;
    });

    segment = [
      'np',
      {
        label: 'Título de dados falsos',
        options: ['Fake', 'Data'],
      },
    ];

    return { period, rows, totals };
  }

  if (!hasPermission) {
    rowData = generateFakeData();
  }

  const period = rowData?.period;
  const format = !hasPermission
    ? 'currency'
    : indicators?.find((i) => i.code === state.indicator)?.format;

  const calculateTotal = (totals: { [date: string]: number }) => {
    const format = indicators?.find((i) => i.code === state.indicator)?.format;
    if (totals) {
      return Object.entries(totals).reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]:
            format === 'currency'
              ? calculateRadarValue(curr[1])
              : calculateRadarValue(curr[1], 1, 0),
        };
      }, {} as RowItem);
    }
  };

  const calculateData = (
    items: RowItem[],
    totals: { [date: string]: number },
  ) => {
    const calcTotal = calculateTotal(totals);

    const calcData = items?.map((row) => {
      const { filter, ...data } = row;
      return Object.entries(data).reduce((acc, curr) => {
        return {
          ...acc,
          filter: filter,
          [curr[0]]:
            format === 'currency'
              ? calculateRadarValue(curr[1])
              : calculateRadarValue(curr[1], 1, 0),
        };
      }, {} as RowItem);
    });

    const newTotal: { [key: string]: number } = {};

    if (calcData) {
      calcData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          // eslint-disable-next-line no-prototype-builtins
          if (newTotal.hasOwnProperty(key)) {
            newTotal[key] = newTotal[key] + (item[key] as number);
          } else {
            newTotal[key] = item[key] as number;
          }
        });
      });

      if (calcTotal) {
        for (const [key, value] of Object.entries(calcTotal)) {
          if (newTotal[key] !== value) {
            const rest = (value as number) - newTotal[key];
            const index = calcData.findIndex((row) => row[key] !== 0);
            calcData[index][key] = +(
              (calcData[index][key] as number) + rest
            ).toFixed(1);
          }
        }
      }
    }

    return calcData;
  };

  const totals = calculateTotal(rowData?.totals);
  const data = segment.length
    ? calculateData(rowData?.rows[segment[0]], rowData?.totals)
    : [];

  const formatDate = (unformattedDate: string) => {
    const [year, month] = unformattedDate.split('-');
    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1);
    return formatDateFns(date, 'MMM yy');
  };

  const exportDataToCSV = () => {
    const headers = ['Filtros'];
    period.map((p) => headers.push(formatDate(p)));
    const options = {
      fieldSeparator: ';',
      showLabels: true,
      filename: `${market}_${state.indicator}_${
        segment[0]
      }_${formatDateFns(new Date(), 'yyyyMMdd')}`,
      headers,
    };

    const formatedData = data?.map((row) => {
      return Object.entries(row).reduce((acc, curr, index) => {
        if (index !== 0) {
          return {
            ...acc,
            [curr[0]]:
              format === 'currency'
                ? formatRadarValue(curr[1])
                : formatRadarValue(curr[1], 0),
          };
        } else {
          return {
            ...acc,
            [curr[0]]: curr[1],
          };
        }
      }, {});
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(formatedData);
  };

  return (
    <Paper
      sx={(theme) => ({
        padding: 2.5,
        margin: theme.spacing(2.5, 0),
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        'o-user-select': 'none',
      })}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography
            component="h3"
            variant="h3"
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              [theme.breakpoints.down('xs')]: {
                fontSize: '1em',
              },
            }}>
            {segment.length && segment[1].label}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
              justifyContent: 'flex-start',
              marginTop: theme.spacing(1),
            },
          }}>
          <CustomTooltip
            disableHoverListener={!hasExportPermission}
            disableFocusListener={!hasExportPermission}
            disableTouchListener={!hasExportPermission}
            title="Faça o upgrade do seu plano para exportar os dados">
            <span aria-roledescription="radar_exportar_csv">
              <Button
                variant="text"
                startIcon={
                  <GetAppRounded
                    color={!hasExportPermission ? 'disabled' : 'primary'}
                    sx={{
                      fontSize: '16px',
                    }}
                  />
                }
                onClick={exportDataToCSV}
                sx={{
                  minWidth: 'fit-content',
                }}
                disabled={!hasExportPermission}>
                <Typography
                  sx={{
                    color: !hasExportPermission ? '#00000042' : '#828282',
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    textTransform: 'none',
                  }}>
                  Exportar
                </Typography>
              </Button>
            </span>
          </CustomTooltip>
        </Grid>
      </Grid>
      <Box margin="8px 0px 16px 0px">
        <TableContainer
          sx={{
            '&::-webkit-scrollbar': {
              height: 8,
              width: 8,
              WebkitAppearance: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 4,
              backgroundColor: theme.palette.grey['400'],
            },
          }}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    border: 0,
                    minWidth: '11em',
                    maxWidth: '11em',
                  }}
                />
                {period?.map((period, index) => (
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: '10px',
                      border: 0,
                      color: '#4F4F4F',
                      fontWeight: 600,
                      padding: theme.spacing(1.5, 0),
                      minWidth: '5em',
                      backgroundColor: '#F1F1F1',
                    }}
                    key={`${period}-${index}`}>
                    {formatDate(period)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {segment[0] === 'prazo'
                ? data
                    ?.map((row) => (
                      <TableRow key={row.filter}>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: '12px',
                            textAlign: 'right',
                            fontWeight: 600,
                            padding: theme.spacing(2, 2.25, 0, 0),
                            borderBottom: 'none',
                            maxWidth: '12em',
                          }}>
                          {row.filter}
                        </TableCell>
                        {Object.values(row)
                          .slice(1)
                          .map((value, index) => (
                            <TableCell
                              align="center"
                              sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                padding: theme.spacing(2, 0, 0, 0),
                                borderBottom: 'none',
                                backgroundColor: '#F1F1F1',
                              }}
                              key={`${row.filter}-${index}`}>
                              {value === 0 || !format
                                ? '-'
                                : format === 'currency'
                                  ? formatRadarValue(value)
                                  : formatRadarValue(value, 0)}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))
                    .sort((a, b) => {
                      const aValue = Object.values(a)[2];
                      const bValue = Object.values(b)[2];
                      return (
                        arrayDeadlineOrder.indexOf(aValue) -
                        arrayDeadlineOrder.indexOf(bValue)
                      );
                    })
                : data?.map((row) => (
                    <TableRow key={row.filter}>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '12px',
                          textAlign: 'right',
                          fontWeight: 600,
                          padding: theme.spacing(2, 2.25, 0, 0),
                          borderBottom: 'none',
                          maxWidth: '12em',
                        }}>
                        {row.filter}
                      </TableCell>
                      {Object.values(row)
                        .slice(1)
                        .map((value, index) => (
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: '12px',
                              fontWeight: 400,
                              padding: theme.spacing(2, 0, 0, 0),
                              borderBottom: 'none',
                              backgroundColor: '#F1F1F1',
                            }}
                            key={`${row.filter}-${index}`}>
                            {value === 0 || !format
                              ? '-'
                              : format === 'currency'
                                ? formatRadarValue(value)
                                : formatRadarValue(value, 0)}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    textAlign: 'right',
                    padding: theme.spacing(1, 2.25, 1, 0),
                    margin: theme.spacing(0, 0, 2, 0),
                    borderBottom: 'none',
                  }}>
                  Total
                </TableCell>
                {totals &&
                  Object.values(totals).map((value, index) => (
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '0.8em',
                        fontWeight: 550,
                        margin: theme.spacing(0, 0, 2, 0),
                        borderBottom: 'none',
                        backgroundColor: '#F1F1F1',
                      }}
                      key={`total-${index}`}>
                      {!format
                        ? '-'
                        : format === 'currency'
                          ? formatRadarValue(value)
                          : formatRadarValue(value, 0)}
                    </TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
