import React from 'react';

import { v4 as uuid } from 'uuid';

import { pageData, rankingsData } from './data';
import {
  formatChartProps,
  formatPieChartProps,
  YEARS,
} from '../chart-formater';
import {
  mapCreditRisk,
  mapCRITerm,
  mapInvestorData,
  mapNegotiation,
  mapOfferType,
  mapSupply,
} from '../chart-map-data';
import ColumnChart from '../components/Charts/ColumnChart';
import ColumnLineChart from '../components/Charts/ColumnLineChart';
import PieChart from '../components/Charts/PieChart';
import Page from '../components/Page';
import ScrollHandler from '../components/Page/ScrollHandler';
import Text from '../components/Page/Text';
import Ranking from '../components/Widgets/Ranking';
import { Banners, ChartsDataRequestPayload } from '../types';
import { marketPageFns } from '../utils';

type Props = {
  open: boolean;
  indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>;
  chartsData: ChartsDataRequestPayload[];
  banners: Banners;
};

const COLLECTION_ID = 18;

const CRI = (props: Props) => {
  const banners = marketPageFns.getMarketBanners(props.banners, 'CRI');
  const chartData = marketPageFns.getChartData(props.chartsData, COLLECTION_ID);

  const [
    issuancesChart,
    collateralAssetChart,
    termChart,
    realStateChart,
    debtorChart,
    offerChart,
    remunerationChart,
    creditRiskChart,
    supplyChart,
    negotiationChart,
    investorChart,
  ] = chartData;

  const [
    opening,
    primaryMarket,
    secondaryMarket,
    supply,
    ranking,
    tozziniText,
    speziaText,
  ] = pageData.map(marketPageFns.formatPageData(props.indexes, banners));

  const getPageProps = marketPageFns.getPageProps(props.open);

  const isReady = marketPageFns.checkIsReady(
    props.indexes,
    pageData,
    chartData,
    11,
  );

  return isReady ? (
    <>
      <Page {...getPageProps(opening)}>
        <Text text={opening.text} />,
      </Page>
      <Page {...getPageProps(primaryMarket)}>
        <ScrollHandler
          textEl={primaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              market="CRI"
              key={uuid()}
              {...formatChartProps(issuancesChart)({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(mapOfferType(offerChart))({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(mapCreditRisk(creditRiskChart))({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              market="CRI"
              key={uuid()}
              {...formatChartProps(debtorChart)({
                chart: 'column',
                categories: YEARS,
                field: 'perfil',
              })}
            />,
            <ColumnChart
              market="CRI"
              key={uuid()}
              {...formatChartProps(remunerationChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(mapCRITerm(termChart))({
                chart: 'column',
                categories: YEARS,
                field: 'prazo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(collateralAssetChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(realStateChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <PieChart
              key={uuid()}
              market="CRI"
              {...formatPieChartProps(mapInvestorData(investorChart))}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(secondaryMarket)}>
        <ScrollHandler
          textEl={secondaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(mapNegotiation(negotiationChart))({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(supply)}>
        <ScrollHandler
          textEl={supply.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="CRI"
              {...formatChartProps(mapSupply(supplyChart))({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(ranking)}>
        <ScrollHandler
          textEl={ranking.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={rankingsData.map((source, index) =>
            source ? <Ranking urlSource={source} key={index} /> : null,
          )}
        />
      </Page>
      <Page {...getPageProps(tozziniText)}>
        <Text text={tozziniText.text} />
      </Page>
      <Page {...getPageProps(speziaText)}>
        <Text text={speziaText.text} />
      </Page>
    </>
  ) : (
    <></>
  );
};

export default React.memo(CRI);
