import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  TextField,
} from '@mui/material';
import Test from 'react-test-attributes';
import { FieldTitle } from 'src/components/SocialProfileDialog/styles';

type Props = {
  label: string;
  loading?: boolean;
  options: any[];
  value: string;
  name: string;
  placeholder: string;
  setFieldValue: (field: string, value: any) => void;
  id: string;
  error?: string;
};

interface Options {
  inputValue?: string;
  name: string;
}

function RoleSelectField({
  label,
  loading,
  options,
  value,
  name,
  placeholder,
  setFieldValue,
  id,
  error,
}: Props) {
  const filter = createFilterOptions<Options>();
  return (
    <>
      <FieldTitle>{label}</FieldTitle>
      {/* @ts-ignore */}
      <Test id={id}>
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            fullWidth
            freeSolo
            loading={loading}
            options={options || []}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.name,
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Adicionar "${inputValue}"`,
                });
              }

              return filtered;
            }}
            onChange={(event, newValue, reason) => {
              if (reason === 'clear') {
                setFieldValue(name, '');
              }
              if (typeof newValue === 'string') {
                setFieldValue(name, newValue);
              } else if (newValue && newValue.inputValue) {
                setFieldValue(name, newValue.inputValue);
              } else {
                setFieldValue(name, newValue?.name);
              }
            }}
            value={value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }

              if (option.inputValue) {
                return option.inputValue;
              }

              return option.name;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                placeholder={placeholder}
                error={Boolean(error)}
                helperText={error}
              />
            )}
          />
        </FormControl>
      </Test>
    </>
  );
}

export default RoleSelectField;
