import { styled, Switch, SwitchProps } from '@mui/material';

export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 30,
  padding: 0,
  margin: theme.spacing(1, 1, 1, 0),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
  '& .MuiSwitch-switchBase': {
    padding: 5,
    color: theme.palette.primary.dark,
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: theme.palette.primary.dark,
      '& + .MuiSwitch-track': {
        backgroundColor: '#BDBDBD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 30 / 2,
    backgroundColor: '#E0E0E0',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));
