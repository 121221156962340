import { User } from 'src/types/user';

export type Notification = {
  id: number;
  isRead: boolean;
  content: string;
  publishedAt: string;
  actionType: string;
  redirectTo: string;
  broadcast: boolean;
};

export type Auth = {
  user: User | undefined;
  periodDemo:
    | {
        isDemo: boolean;
        date: Date | null;
      }
    | undefined;
  isLoggedIn: boolean;
  isLoading: boolean;
  isFetched: boolean;
  error?: string | unknown;
  refetch: () => void;
  keepMeSigned: boolean;
};

export type ContextData = {
  auth: Auth;
  reloadUser: () => void;
  handleLogin: (success: boolean) => void;
  notifyAuthChannel: (type?: string, jwt?: string) => void;
  handleLogout: (v?: boolean) => void;
  upgradedPlanByPermission: (p: string) => void;
};

export const initialContext: ContextData = {
  auth: {
    user: undefined,
    periodDemo: undefined,
    isLoggedIn: false,
    isLoading: true,
    isFetched: false,
    error: undefined,
    refetch: () => {},
    keepMeSigned: false,
  },
  reloadUser: () => {},
  handleLogin: (success: boolean) => {},
  handleLogout: (v?: boolean) => {},
  notifyAuthChannel: (type?: string, jwt?: string) => {},
  upgradedPlanByPermission: () => [],
};
