// Material ui components
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Chip,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Avatar from 'react-avatar';
import Section from 'src/components/ProfileSection';
import { Service } from 'src/types/marketplace';
import { Media } from 'src/types/strapi';
import { getSmallestImageUrl } from 'src/utils/image';
import { truncateText } from 'src/utils/string';
import styled from 'styled-components';

type Props = {
  data: {
    services: Service[];
    name: string;
    image: Media;
    ownerType: string;
  };
  title?: string;
};

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
  width: 340,
  height: 240,
  border: '1px solid #E0E0E0',
  borderRadius: '5px',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    width: 260,
  },
  [theme.breakpoints.down('sm')]: {
    width: 200,
  },
}));

export default function Services({
  data,
  title = 'Serviços desta empresa',
}: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );

  const serviceCards =
    data?.services?.map((service) => (
      <CustomCard key={`${service.id}-service`}>
        <CardHeader
          avatar={
            !isMobile ? (
              <Avatar
                src={getSmallestImageUrl(data.image)}
                name={data.name}
                maxInitials={1}
                size={'48'}
                round={data?.ownerType === 'professional'}
                style={{ maxWidth: 48, maxHeight: 48 }}
                className="img-avatar_custom"
              />
            ) : undefined
          }
          title={service.title}
          subheader={
            <Box display="flex" flexDirection="column" gap={1}>
              <Box>
                {service.markets?.map((market) => (
                  <Chip
                    size="small"
                    key={market.name}
                    label={market.name}
                    variant={'outlined'}
                    sx={{ color: 'black', marginRight: 0.5 }}
                  />
                ))}
              </Box>
              <Chip
                sx={(theme) => ({
                  minWidth: 76,
                  width: 'fit-content',
                  height: 22,
                  fontSize: '12px',
                  color: theme.palette.primary.contrastText,
                  borderRadius: '2px',
                  fontWeight: 400,
                  padding: 0,
                })}
                color="primary"
                label={service.type}
              />
            </Box>
          }
          subheaderTypographyProps={{
            sx: {
              margin: '4px 0',
            },
          }}
          titleTypographyProps={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '19.5px',
          }}
          sx={{
            paddingBottom: 0,
            alignItems: 'flex-start',
            '& .MuiCardHeader-content': {
              height: 110,
            },
            '.img-avatar_custom > img': {
              objectFit: 'contain',
            },
          }}
        />
        <CardContent
          sx={(theme) => ({
            padding: '0 16px 16px',
            marginLeft: '64px',
            minHeight: '75px',
            [theme.breakpoints.down('sm')]: {
              marginLeft: 0,
            },
          })}>
          <Typography sx={{ fontSize: '14px', lineHeight: '19.5px' }}>
            {truncateText(service.description, isMedium ? 40 : 89)}
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing
          sx={(theme) => ({
            padding: 1,
            paddingLeft: '16px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: '64px',
            height: 'fit-content',
            [theme.breakpoints.down('sm')]: {
              marginLeft: 0,
            },
          })}>
          <Link
            href={`/marketplace/${service.type === 'Serviço' ? 'servico' : 'projeto'}/${service.slug}/${service.id}`}
            color="#FF8211"
            underline="always"
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '0.54px',
            }}>
            VER MAIS
          </Link>
        </CardActions>
      </CustomCard>
    )) || [];

  const renderPrevButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowLeft />
      </IconButton>
    );
  };

  const renderNextButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowRight />
      </IconButton>
    );
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    980: { items: 3 },
    1024: { items: 3 },
  };

  return (
    <Section title={title} hasPaywall={true} paywallBanner="institutionProfile">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={(theme) => ({
            width: '100%',
            overflow: 'hidden',
            '& > div': {
              padding: 0,
            },
            '& .alice-carousel > div': {
              padding: '0 54px',
              [theme.breakpoints.down('sm')]: {
                padding: '0 40px',
              },
            },
            '& .alice-carousel__stage-item': {
              verticalAlign: 'middle',
            },
            '& .alice-carousel__prev-btn': {
              position: 'absolute',
              top: '50%',
              padding: 0,
            },
            '& .alice-carousel__next-btn': {
              position: 'absolute',
              top: '50%',
              right: 0,
              padding: 0,
            },
          })}>
          <AliceCarousel
            mouseTracking
            items={serviceCards}
            responsive={responsive}
            disableDotsControls
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
            swipeExtraPadding={400}
          />
        </Box>
      </Box>
    </Section>
  );
}
