import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    height: 100%;
    min-height: 320px;
    box-shadow: 3px 3px 8px 1px #e6e6e6;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 3px 8px 1px #e6e6e6;
    background: #ffffff;

    .box-security {
      font-weight: bold;
      font-size: 1rem;
      padding: 22px 22px 18px 23px;
      border-bottom: 2px solid #303030;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .box-info-security {
      height: 100%;
      box-shadow: 3px 3px 8px 1px #e6e6e6;
      -webkit-box-shadow: 3px 3px 8px 1px #e6e6e6;
    }

    .security-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60%;
    }

    .info {
      display: flex;
      align-items: center;
      padding-top: 0.5rem;
    }

    .message-content {
      width: 100%;
      margin-top: 64px;
    }

    .security-info {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      padding-right: 1rem;
    }

    .sidemarker {
      background: rgb(228, 122, 23);
      height: 200px;
      width: 10px;
      margin-right: 16px;
    }

    .menu {
      display: flex;
    }

    .hovertext {
      position: relative;
    }

    .hovertext:before {
      content: attr(data-hover);
      visibility: hidden;
      opacity: 0;
      width: 140px;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      transition: opacity 0.5s ease-in-out;

      position: absolute;
      z-index: 1;
      left: 0;
      top: 110%;
    }

    .hovertext:hover:before {
      opacity: 1;
      visibility: visible;
    }
  `}
`;
