/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { Container } from './styles';
import { DataContext } from '../../../../context/DataContext';
import StaticAlert from '../StaticAlert';

const columns = [
  { id: 'mes', label: 'Mês', minWidth: 90 },
  {
    id: 'saldo',
    label: 'Saldo',
    minWidth: 90,
    format: (value) => parseFloat(value).toFixed(2).toString(),
  },
  {
    id: 'principal',
    label: 'Principal',
    minWidth: 90,
    align: 'right',
    format: (value) => parseFloat(value).toFixed(2).toString(),
  },
  {
    id: 'juros',
    label: 'Juros',
    minWidth: 90,
    align: 'right',
    format: (value) => parseFloat(value).toFixed(2).toString(),
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 90,
    align: 'right',
    format: (value) => parseFloat(value).toFixed(2).toString(),
  },
];

function createData(mes, saldo, principal, juros, total) {
  return { mes, saldo, principal, juros, total };
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const DataContent = ({ showTableFormat = false }) => {
  const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const { simulateResult } = useContext(DataContext);
  const [tableLabels, setTableLabels] = useState(MONTHS);
  const [graphicLabels, setGraphicLabels] = useState(MONTHS);

  //Data Table
  const rows = [];
  for (let i = 0; i < tableLabels.length; i++) {
    rows.push(
      createData(
        tableLabels[i],
        simulateResult !== null && simulateResult
          ? Number(simulateResult?.balance[i]?.value).toFixed(2)
          : '',
        simulateResult !== null && simulateResult
          ? Number(simulateResult?.principal[i]?.value).toFixed(2)
          : '',
        simulateResult !== null && simulateResult
          ? Number(simulateResult?.interest[i]?.value).toFixed(2)
          : '',
        simulateResult !== null && simulateResult
          ? Number(simulateResult?.total[i]?.value).toFixed(2)
          : '',
      ),
    );
  }

  // Data Graphic
  const data = {
    labels: graphicLabels,
    datasets: [
      {
        label: 'Saldo',
        data: simulateResult?.balance.map((balance) => Number(balance.value)),
        backgroundColor: '#4caf50',
      },
      {
        label: 'Principal',
        data: simulateResult?.principal.map((principal) =>
          Number(principal.value),
        ),

        backgroundColor: '#8bc34a',
      },
      {
        label: 'Juros',
        data: simulateResult?.interest.map((interest) =>
          Number(interest.value),
        ),

        backgroundColor: '#cddc39',
      },
      {
        label: 'Total',
        data: simulateResult?.total.map((total) => Number(total.value)),
        backgroundColor: '#E47918',
      },
    ],
  };

  useEffect(() => {
    const formattedTableLabels = [];
    const formattedGraphicLabels = [];
    simulateResult?.balance.map((item) => {
      if (item.date) {
        const numberMonth = item.date.slice(5, 7);
        formattedTableLabels.push(
          `${MONTHS[parseInt(numberMonth) - 1]}/${item.date.slice(2, 4)}`,
        );
        formattedGraphicLabels.push(
          `${MONTHS[parseInt(numberMonth) - 1]}/${item.date.slice(2, 4)}`,
        );
      }
    });
    setTableLabels(formattedTableLabels);
    setGraphicLabels(formattedGraphicLabels);
  }, [simulateResult]);

  return (
    <Container>
      <div className="box-data-content">
        <AssessmentIcon />
        {showTableFormat
          ? 'Fluxo de caixa (Tabela)'
          : 'Fluxo de caixa (Gráfico)'}
      </div>
      {simulateResult !== null ? (
        // Table
        <div className="content-table">
          {showTableFormat ? (
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'string'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            // Graphic
            <div className="box-graphic">
              <Bar options={options} data={data} height={68} />
            </div>
          )}
        </div>
      ) : (
        <div className="content-alert-graphic">
          <StaticAlert message="Defina as características do Título que deseja estruturar e depois clique em Calcular para ver o Fluxo de Caixa projetado!" />
        </div>
      )}
    </Container>
  );
};

export default DataContent;
