import { useEffect } from 'react';

import { Grid, Link, useMediaQuery } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { CarouselCustom } from '../../../components/CarouselCustom';
import Subheader from '../../../components/Subheader';
import { useAuthContext } from '../../../context/AuthContextProvider';
import {
  getOtherVideosByCollection,
  getVideoById,
} from '../../../services/videos';
import { ScrollToTop } from '../../../utils/scroll';
import VideoCard from '../VideoCard';
import VideoPlayerCard from '../VideoPlayerCard';
import { AllVideosStyles } from '../styles';

export default function SingleVideo() {
  const classes = AllVideosStyles();
  const { id } = useParams();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { auth } = useAuthContext();
  const hasPermission = auth?.user?.subscription?.plan?.permissions?.videos;

  const {
    data: video,
    isLoading,
    refetch,
  } = useQuery('video', () => getVideoById(id));
  const { data: allVideos } = useQuery(
    ['allVideos', video],
    () =>
      getOtherVideosByCollection({
        videoId: video.id,
        collectionId: video.collection.id,
      }),
    {
      enabled: !!video,
    },
  );

  useEffect(() => {
    refetch();
  }, [id]);

  const items = allVideos?.map(
    ({ id, title, uploadedAt, collection, slug, payload }) => (
      <div key={id} className={classes.card}>
        <VideoCard
          id={id}
          title={title}
          uploadedAt={uploadedAt}
          collection={collection}
          slug={slug}
          thumbnail={payload?.pictures?.formats?.thumbnail?.url}
          duration={payload?.duration}
        />
      </div>
    ),
  );

  if (isLoading) return null;

  return (
    <ScrollToTop>
      <Grid container>
        <Grid item xs={12}>
          <Subheader
            title={video?.collection?.name ?? ''}
            breadcrumbs1={
              <Link
                component={RouterLink}
                to="/videos"
                underline="none"
                className={classes.link}
                color="secondary">
                Galeria
              </Link>
            }
            breadcrumbs2={video?.collection?.name ?? ''}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridVideo}>
          <VideoPlayerCard
            id={video?.id}
            title={video?.title}
            description={video?.description}
            uploadedAt={video?.uploadedAt}
            url={video?.url}
            width={video?.payload?.width}
            height={video?.payload?.height}
            document={video?.document}
            allowed={!hasPermission}
          />
        </Grid>
        {!isExtraSmall && items?.length > 3 ? (
          <CarouselCustom items={items} />
        ) : (
          <Grid container spacing={5}>
            {allVideos?.map(
              ({
                id,
                title,
                uploadedAt,
                collection,
                slug,
                payload,
                document,
              }) => (
                <Grid key={id} item xs={12} md={4}>
                  <VideoCard
                    id={id}
                    title={title}
                    uploadedAt={uploadedAt}
                    collection={collection}
                    slug={slug}
                    thumbnail={payload?.pictures?.formats?.thumbnail?.url}
                    duration={payload?.duration}
                    document={document}
                    allowed={hasPermission}
                  />
                </Grid>
              ),
            )}
          </Grid>
        )}
      </Grid>
    </ScrollToTop>
  );
}
