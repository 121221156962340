import { Grid, List, ListItem, styled, useTheme } from '@mui/material';

const CustomList = styled(List)<{ component?: React.ElementType }>({
  fontSize: '12px',
  listStyle: 'disc',
  '& .MuiListItem-root': {
    display: 'list-item',
    paddingLeft: '0',
    '&::marker': { fontSize: '1.1rem' },
  },
});

function PasswordRules({
  password,
  canVerify,
}: {
  password: string;
  canVerify?: boolean;
}) {
  const theme = useTheme();

  const rulesList = [
    { id: 1, label: 'Uma letra maiúscula', regex: /^(?=.*?[A-Z])/ },
    { id: 2, label: 'Uma letra minúscula', regex: /^(?=.*?[a-z])/ },
    {
      id: 3,
      label: 'Um caractere especial (@,$,#)',
      regex: /^(?=.*?[#?!@$ %^&*-])/,
    },
    { id: 4, label: 'Um número', regex: /^(?=.*?[0-9])/ },
    { id: 5, label: 'Mínimo 8 caracteres', regex: /^(?=.{8,}$)/ },
  ];

  return (
    <Grid container sx={{ margin: '1rem 1.5rem' }}>
      <Grid item xs={6}>
        <CustomList disablePadding>
          {rulesList.slice(0, 3).map((rules) => (
            <ListItem
              key={rules.id}
              sx={{
                color: !canVerify
                  ? '#1D1D1D'
                  : rules.regex.test(password)
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}>
              {rules.label}
            </ListItem>
          ))}
        </CustomList>
      </Grid>
      <Grid item xs={6}>
        <CustomList disablePadding>
          {rulesList.slice(-2).map((rules) => (
            <ListItem
              key={rules.id}
              sx={{
                color: !canVerify
                  ? '#1D1D1D'
                  : rules.regex.test(password)
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}>
              {rules.label}
            </ListItem>
          ))}
        </CustomList>
      </Grid>
    </Grid>
  );
}

export default PasswordRules;
