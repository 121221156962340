import styled from 'styled-components';

export const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;

export const List = styled.u`
  font-size: 14px;
  text-decoration: none;
  display: block;
  margin: 26px 0 30px;

  li {
    margin: 10px 5px;
  }
`;
