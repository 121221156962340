import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 60%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;
