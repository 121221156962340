import { makeStyles } from '@material-ui/core';

export const useHeaderStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  yearbookMenuButton: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    height: 75,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  boxLogo: {
    width: '100%',
    maxWidth: 117,
    marginRight: theme.spacing(1),
    textAlign: 'start',
    [theme.breakpoints.down('xs')]: {
      minWidth: 110,
      marginRight: theme.spacing(0),
    },
  },
  logo: {
    maxWidth: 117,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding: '0',
      maxWidth: 100,
    },
  },
  menu: {
    backgroundColor: 'black',
    color: 'white',
    minWidth: '300px',
    maxWidth: '300px',
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1rem',
    lineHeight: '22px',
    letterSpacing: '0.2px',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  avatar: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: '8px',
    },
  },
  menuDivider: {
    backgroundColor: 'white',
    margin: '0px 8px',
  },
  menuHeaderContainer: {
    padding: theme.spacing(0, 3),
    margin: theme.spacing(2, 0),
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  menuHeaderText: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemContainer: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1, 0),
  },
  button: {
    borderRadius: '5.5px',
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 500,
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.secondary.main,
      padding: '4px 16px',
      '&:hover': {
        color: theme.palette.secondary.contrastText,
        textDecoration: 'none',
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  navTitle: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 500,
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
  },
}));
