import { ChangeEvent, useEffect } from 'react';

import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Gallery from './Gallery';
import Header from './Header';
import InstitutionProfessionals from './InstitutionProfessionals';
import InstitutionProfile from './InstitutionProfile';
import InstitutionRankings from './InstitutionRankings';
import { Operations } from './Operations';
import { If } from '../../components/If';
import MetaTags from '../../components/Seo/MetaTags';
import {
  getInformativeTextByLocation,
  getPayWallBanner,
} from '../../services/informative';
import { findInstitutionById } from '../../services/institution';
import { findInstitutionRankingCount } from '../../services/ranking';
import { useLocalStorage } from '../../utils/hooks';
import { ScrollToTop } from '../../utils/scroll';

export default function Institution() {
  const history = useHistory();
  const { hashId, tab } = useParams<{ hashId: string; tab: string }>();
  const { state } = useLocation();
  const [value, setValue] = useLocalStorage(
    'institutionTabIndex',
    state ? 'ranking' : 'perfil',
  );

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    history.push(`/instituicao/${data.slug}/${hashId}/${newValue}`);
  };

  const { data, isLoading, refetch } = useQuery(
    ['institution-profile', hashId],
    () => findInstitutionById(parseInt(hashId), true),
  );

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('institutionOwnerPlanFree'),
    {
      enabled: data?.accountOwnerIsFree,
    },
  );

  useEffect(() => {
    setValue(state ? 'ranking' : tab ?? 'perfil');
    return function clean() {
      localStorage.removeItem('institutionTabIndex');
    };
  }, [tab]);

  const { data: tooltipText } = useQuery('info', () =>
    getInformativeTextByLocation('segments'),
  );

  const { data: countRanking } = useQuery('ranking-count', () =>
    findInstitutionRankingCount(hashId),
  );

  const tabs = [
    { label: 'Perfil', value: 'perfil', index: 1 },
    { label: 'Operações', value: 'operacoes', index: 2 },
    { label: 'Ranking', value: 'ranking', index: 3 },
    { label: 'Profissionais', value: 'profissionais', index: 4 },
    { label: 'Galeria', value: 'galeria', index: 5 },
  ];

  return (
    <ScrollToTop>
      <If condition={!isLoading}>
        <MetaTags
          title={data?.fantasyName}
          description={
            data?.businessProfile ||
            `Encontre as principais informações sobre a ${data?.fantasyName}`
          }
          seo={data?.seo}
          imageUrl={data?.logo?.url}
        />
        <Header
          institution={data}
          refetch={refetch}
          tabList={tabs}
          tabValue={value}
          handleChangeTabValue={handleChange}
        />
        <Box py={1}>
          <If
            condition={
              value === 'perfil' && tabs.find((t) => t.value === 'perfil')
            }>
            <InstitutionProfile
              data={data}
              payWallBanner={contentPayWallBanner}
            />
          </If>
          <If
            condition={
              value === 'operacoes' && tabs.find((t) => t.value === 'operacoes')
            }>
            <Operations institution={data} />
          </If>
          <If
            condition={
              value === 'ranking' && tabs.find((t) => t.value === 'ranking')
            }>
            <InstitutionRankings linkState={state} />
          </If>
          <If
            condition={
              value === 'profissionais' &&
              tabs.find((t) => t.value === 'profissionais')
            }>
            <InstitutionProfessionals institution={data} />
          </If>
          <If
            condition={
              value === 'galeria' && tabs.find((t) => t.value === 'galeria')
            }>
            <Gallery institution={data} />
          </If>
        </Box>
      </If>
    </ScrollToTop>
  );
}
