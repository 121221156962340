import { useState } from 'react';

import {
  Button,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { If } from '../../If';

const useStyles = makeStyles((theme) => ({
  navTitle: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 500,
    '&:hover': {
      color: '#FF8211',
      textDecoration: 'none',
    },
  },
  titleMenuItem: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    fontWeight: 400,
    padding: theme.spacing(1, 3),
    '&:hover': {
      color: '#FF8211',
    },
  },
  popOverRoot: {
    pointerEvents: 'none',
  },
  button: {
    backgroundColor: '#FF8211',
    color: theme.palette.secondary.contrastText,
    marginLeft: '10px',
    fontSize: '1rem',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      textDecoration: 'none',
    },
  },
}));

export default function HeaderItemMenu({ id, itemMenu }) {
  let currentlyHovering = false;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSubscribersClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHover = () => {
    currentlyHovering = true;
  };

  const handleCloseHover = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 200);
  };

  return (
    <>
      <If condition={itemMenu?.type === 'button'}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          component={Link}
          href={itemMenu.url}
          target={itemMenu.target}>
          {itemMenu.title}
        </Button>
      </If>
      <If condition={itemMenu?.type === 'link'}>
        <Button
          id={id}
          aria-controls={open ? id : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleSubscribersClick}
          onMouseOver={handleSubscribersClick}
          onMouseLeave={handleCloseHover}>
          <Typography
            className={classes.navTitle}
            component={Link}
            href={itemMenu.url}
            target={itemMenu.target}>
            {itemMenu.title}
          </Typography>
        </Button>
      </If>
      <If condition={itemMenu?.children?.length}>
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          MenuListProps={{
            onMouseEnter: handleHover,
            onMouseLeave: handleCloseHover,
            style: { pointerEvents: 'auto' },
          }}
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.66)',
              minWidth: 150,
              marginTop: '1.5rem',
              boxShadow: '0px 4px 4px 0px #00000040',
            },
          }}
          PopoverClasses={{
            root: classes.popOverRoot,
          }}>
          {itemMenu?.children?.map((item) => (
            <MenuItem
              key={item.id}
              className={classes.titleMenuItem}
              onClick={() => handleClose()}
              component={Link}
              href={item.url}
              target={item.target}>
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </If>
    </>
  );
}
