import { useState } from 'react';

import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

export function DocumentSelectDate({
  classes,
  filters,
  setFilters,
  disabled,
  minDate = undefined,
}) {
  const [selectedPeriod, setSelectedPeriod] = useState('All');

  const handleChangeStartRangeDate = (event) => {
    if (event) {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          start:
            event && moment(event).format() !== filters.dateRange.start
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          start: null,
        },
      });
    }
  };

  const handleChangeEndRangeDate = (event) => {
    if (event) {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          end:
            event && moment(event).format() !== filters.dateRange.end
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          end: null,
        },
      });
    }
  };

  const handleChangeSelectPeriod = (event) => {
    const { value } = event.target;
    setSelectedPeriod(value);

    switch (value) {
      case 'All':
        setFilters({
          ...filters,
          dateRange: {
            ...filters.dateRange,
            start: null,
            end: null,
          },
        });
        break;

      case 'M12':
        setFilters({
          ...filters,
          dateRange: {
            ...filters.dateRange,
            start: moment().subtract(1, 'years').format(),
            end: moment().format(),
          },
        });
        break;

      case 'M6':
        setFilters({
          ...filters,
          dateRange: {
            ...filters.dateRange,
            start: moment().subtract(6, 'months').format(),
            end: moment().format(),
          },
        });
        break;
      case 'M3':
        setFilters({
          ...filters,
          dateRange: {
            ...filters.dateRange,
            start: moment().subtract(3, 'months').format(),
            end: moment().format(),
          },
        });
        break;
      case 'M1':
        setFilters({
          ...filters,
          dateRange: {
            ...filters.dateRange,
            start: moment().subtract(1, 'months').format(),
            end: moment().format(),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} md={3}>
        <ListItem className={classes.listItem}>
          <FormControl className={classes.itemInput}>
            <InputLabel id="select-period-label">Data de referência</InputLabel>
            <Select
              id="select-period"
              value={selectedPeriod}
              onChange={handleChangeSelectPeriod}
              disabled={disabled}>
              <MenuItem value="M12">Últimos 12 meses</MenuItem>
              <MenuItem value="M6">Últimos 6 meses</MenuItem>
              <MenuItem value="M3">Últimos 3 meses</MenuItem>
              <MenuItem value="M1">Último mês</MenuItem>
              <MenuItem value="All">Todos</MenuItem>
              <MenuItem value="Custom">Definir período</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
      </Grid>

      {selectedPeriod === 'Custom' && (
        <>
          <Grid item xs={12} md={2}>
            <ListItem className={classes.listItem}>
              <KeyboardDatePicker
                fullWidth
                className={classes.dateToolbar}
                disableFuture
                autoOk
                clearable
                invalidDateMessage="Formato Inválido"
                variant="inline"
                name="startDate"
                minDate={minDate}
                format="DD/MM/yyyy"
                label="Data inicial"
                disabled={disabled}
                InputProps={{
                  disabled: true,
                  endAdornment: filters.dateRange.start && (
                    <IconButton
                      size="small"
                      onClick={() => handleChangeStartRangeDate(null)}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={
                  filters.dateRange.start && {
                    position: 'start',
                  }
                }
                value={filters.dateRange.start}
                onChange={handleChangeStartRangeDate}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2}>
            <ListItem className={classes.listItem}>
              <KeyboardDatePicker
                fullWidth
                className={classes.dateToolbar}
                disableFuture
                autoOk
                clearable
                invalidDateMessage="Formato Inválido"
                variant="inline"
                name="endDate"
                format="DD/MM/yyyy"
                label="Data final"
                disabled={disabled}
                InputProps={{
                  disabled: true,
                  endAdornment: filters.dateRange.end && (
                    <IconButton
                      size="small"
                      onClick={() => handleChangeEndRangeDate(null)}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={
                  filters.dateRange.end && {
                    position: 'start',
                  }
                }
                value={filters.dateRange.end}
                onChange={handleChangeEndRangeDate}
              />
            </ListItem>
          </Grid>
        </>
      )}
    </>
  );
}
