// material ui components
import {
  Box,
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Chip } from '@mui/material';
// avatar
import { format, parseISO } from 'date-fns';
import Avatar from 'react-avatar';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import uqbarIcon from '../../../assets/imgs/avatarUqbar.png';

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  market: {
    margin: '4px !important',
    fontSize: '0.8125rem !important',
  },
  linkGrid: {
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
    width: '42px',
    [theme.breakpoints.down('sm')]: {
      height: '42px',
      width: '42px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '24px',
      width: '24px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chipsGrid: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
    },
  },
  categoriesGrid: {
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  authorGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  authorLink: {
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  socialContainer: {
    justifyContent: 'end',
  },
  roleText: {
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      whiteSpace: 'normal',
    },
  },
}));

export default function ArticleDetails({
  author,
  categories,
  markets,
  publishedAt,
  updatedAt,
  estimatedReadingTime,
}) {
  const classes = useStyles();
  const history = useHistory();

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isDateEqual =
    format(parseISO(publishedAt), 'dd/MM/yyyy HH:mm') ===
    format(parseISO(updatedAt), 'dd/MM/yyyy HH:mm');
  return (
    <div style={{ width: '100%' }}>
      <Grid container py={1} my={1} className={classes.container}>
        <Grid item xs={12} sm={6} md={4} className={classes.authorGrid}>
          <Box p={1}>
            <Avatar
              name={author ? `${author?.fullName}` : 'Uqbar'}
              maxInitials={2}
              round
              size="50"
              src={author?.photo?.formats?.thumbnail?.url ?? uqbarIcon}
            />
          </Box>
          <Box p={1}>
            {author ? (
              <>
                <Link
                  className={classes.authorLink}
                  rel={'author'}
                  component={RouterLink}
                  underline="none"
                  to={`/profissional/${author.slug}/${author.id}`}>
                  <Typography>
                    <b>{author?.fullName}</b>
                  </Typography>
                </Link>
                <Typography className={classes.roleText}>
                  {author?.role ?? ''}
                </Typography>
              </>
            ) : (
              <Typography>
                <b>Uqbar</b>
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} className={classes.chipsGrid}>
          <Box display="flex" justifyContent={isExtraSmall ? 'start' : 'end'}>
            {markets?.map((market) => (
              <Chip
                key={`${market.id}-market`}
                variant="default"
                color="primary"
                size="small"
                label={market.name}
                className={classes.market}
                onClick={() =>
                  history.push('/artigos', { market: [market.name] })
                }
              />
            ))}
          </Box>
          <Grid container className={classes.categoriesGrid}>
            {categories?.map((category) => (
              <Grid item key={category.id}>
                <Chip
                  variant="outlined"
                  size="small"
                  label={category.title}
                  className={classes.market}
                  onClick={() =>
                    history.push('/artigos', { category: [category.title] })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.roleText}>
        {`Publicado em: ${format(parseISO(publishedAt), 'dd/MM/yyyy')}
            ${!isDateEqual ? `- Atualizado em: ${format(parseISO(updatedAt), 'dd/MM/yyyy')}` : ''}
            ${estimatedReadingTime ? `- Tempo de leitura: ${estimatedReadingTime} minuto(s)` : ''}
            `}
      </Typography>
    </div>
  );
}
