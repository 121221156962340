export const initialValuesSecurity = {
  id: 0,
  name: '',
  type: 'fixed',
  start_date: '',
  payment: {
    balance: '0.0',
    model: '',
    principal_payments: 0,
    principal_start: 0,
    principal_step: 0,
    interest_start: 0,
    interest_step: 0,
    interest_time_measure: 'dc360',
    index_def_id: null,
    index_factor: '0.0',
    index_spread: '0.0',
  },
};

export const initialValuesIndex = {
  id: 0,
  name: '',
  entries: null,
  reference_date: null,
  created_at: '',
  updated_at: '',
};

export const initialValuesCalculator = {
  price: {
    date: '',
    cash_flow: {},
    rate: {
      time_measure: '',
      index_def_id: null,
      index_factor: '0.0',
      index_spread: '0.0',
    },
  },
  return: {
    cash_flow: {},
    date: '',
    price: null,
    time_measure: '',
  },
};
