import { useMemo } from 'react';

import * as Opening from './styles';
import { openings } from '../../presentation/data';
import { YearbookMarket } from '../../types';
import { getAdvertisementByPosition } from '../../utils';

interface OpeningSectionProps {
  market: YearbookMarket;
}

export default function OpeningSection({ market }: OpeningSectionProps) {
  const whiteParagraphs = useMemo(
    () => openings[market.name].slice(0, 2),
    [market],
  );

  const blackParagraphs = useMemo(
    () => openings[market.name].slice(2),
    [market],
  );

  const getAdvertisementImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2024/banners/${imageName}.png`);
  };

  const ads = useMemo(
    () => getAdvertisementByPosition(market.name, 1),
    [market],
  );

  return (
    <Opening.Section data-yearbook-section="Abertura">
      <Opening.BackgroundWrapper>
        <Opening.Title>ABERTURA</Opening.Title>
        <Opening.Texts>
          {whiteParagraphs.map((paragraph, idx) => (
            <Opening.Paragraph key={`${market}-${idx}`} color="white">
              {paragraph}
            </Opening.Paragraph>
          ))}
        </Opening.Texts>
      </Opening.BackgroundWrapper>
      <Opening.Texts className="black">
        {blackParagraphs.map((paragraph, idx) => (
          <Opening.Paragraph key={`${market}-${idx}`}>
            {paragraph}
          </Opening.Paragraph>
        ))}
      </Opening.Texts>
      {ads && (
        <Opening.Advertisement
          image={getAdvertisementImage(ads.banner).default}
          href={ads.url}
          target="_blank"
        />
      )}
    </Opening.Section>
  );
}
