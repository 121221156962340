import { useState } from 'react';

import { Message } from '@mui/icons-material';
import { Button, Grid, Link, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Article } from 'src/types/article';
import { Institution } from 'src/types/institution';

type Props = {
  institution: Institution;
};

export function RelatedArticles({ institution }: Props) {
  const { auth } = useAuthContext();
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? institution?.relatedArticles?.length : 4;
  const buttonText = showAll ? 'Ver Menos' : 'Ver Mais';

  const fakeArticles: Article[] = Array(2)
    .fill({})
    .map((value, index) => ({
      id: index + 1,
      slug: 'fake-slug',
      title: 'Artigo Fake',
      publishedAt: '2023-08-17',
    }));

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const articles = auth.isLoggedIn ? institution.relatedArticles : fakeArticles;

  const renderedArticles = articles
    ?.slice(0, itemsToShow)
    .map((article, index) => (
      <Grid
        item
        key={`${article.id}-article`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
        <Message />
        <Grid>
          <Typography fontSize={16}>
            {`${institution?.fantasyName} foi relacionado no artigo `}
            <Link
              href={`/artigo/${article?.slug.toLocaleLowerCase()}/${article.id}`}
              color="#FF8211"
              underline="always">
              {`"${article?.title}"`}
            </Link>
          </Typography>
          <Typography fontSize={14} color="#828282">
            {`${format(parseISO(article.publishedAt), 'dd/MM/yyyy', {
              locale: ptBR,
            })}`}
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <Section
      title="Artigos relacionados"
      hasPaywall={true}
      paywallBanner="institutionProfile">
      <Grid container direction="column" spacing={2}>
        {renderedArticles}
      </Grid>
      {articles?.length > 4 && (
        <Button
          variant="text"
          color="primary"
          sx={{
            height: 'unset',
            fontWeight: 500,
            minWidth: 'unset',
            padding: 0,
            marginTop: 3,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={toggleShowAll}>
          {buttonText}
        </Button>
      )}
    </Section>
  );
}
