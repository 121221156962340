import { ReactNode } from 'react';

import { IconButton, Link } from '@mui/material';

export const SocialNetworkButton = ({
  url,
  icon,
  disabled,
}: {
  url: string;
  icon: ReactNode;
  disabled: boolean;
}) => {
  return (
    <>
      <IconButton
        size="small"
        component={Link}
        disableTouchRipple={disabled}
        href={!disabled ? url : undefined}
        target="_blank"
        rel="noreferrer noopener"
        sx={{
          cursor: disabled ? 'default' : 'pointer',
          color: 'white',
          backgroundColor: 'black',
          margin: '0 8px 8px 0',
          height: 'fit-content',
          '&:hover': {
            color: 'white',
            backgroundColor: 'black',
          },
        }}>
        {icon}
      </IconButton>
    </>
  );
};
