import { useEffect, useState } from 'react';

import { Close, Edit } from '@mui/icons-material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, Button, Grid, IconButton, Link, Typography } from '@mui/material';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Professional, RelatedOperation } from 'src/types/professional';

import RelatedOperationsForm from './RelatedOperationsForm';

type Props = {
  relatedOperations?: RelatedOperation[];
  professional?: Professional;
  handleUpdate: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
};

export function ProfessionalRelatedOperations({
  relatedOperations,
  professional,
  handleUpdate,
  isSuccess,
  isLoading,
}: Props) {
  const { auth } = useAuthContext();
  const [showAll, setShowAll] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState<number>(0);
  const [itemToRemove, setItemToRemove] = useState<
    RelatedOperation | undefined
  >();
  const itemsToShow = showAll ? relatedOperations?.length : 3;
  const buttonText = showAll ? 'Ver Menos' : 'Ver Mais';

  const fakeOperations = Array(1)
    .fill({})
    .map((value, index) => ({
      role: `função${index + 1}`,
      id: index + 1,
      operation: {
        name: `operação${index + 1}`,
        market: {
          id: index + 1,
          description: '',
          name: `mercado${index + 1}`,
        },
        slug: `slug${index + 1}`,
        hashId: `${index} + 1`,
      },
    }));

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleDelete = () => {
    if (professional?.id && itemToRemove?.id) {
      let updatedItems = professional.relatedOperations;
      if (Array.isArray(updatedItems)) {
        updatedItems = [...updatedItems].filter(
          (item: any) => item.id !== itemToRemove?.id,
        );
      }

      handleUpdate({
        data: {
          ...professional,
          relatedOperations: updatedItems,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setConfirmDialogOpen(false);
      setItemToRemove(undefined);
      setEditDialogOpen(false);
    }
  }, [isSuccess]);

  const operations = auth.isLoggedIn ? relatedOperations : fakeOperations;

  const renderedOperations = operations
    ?.slice(0, itemsToShow)
    .map((relatedOperation, index) => (
      <Grid
        item
        key={`${relatedOperation.id}-relatedOperation`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
        <TimelineIcon />
        <Typography sx={{ fontSize: '14px' }}>
          {`${
            professional?.name
          } atuou como ${relatedOperation.role.toLocaleLowerCase()} da operação `}
          <Link
            href={`/operacao/${relatedOperation?.operation?.market?.name?.toLocaleLowerCase()}/${
              relatedOperation.operation.slug
            }/${relatedOperation.operation.hashId}`}
            color="#FF8211"
            underline="always">
            {relatedOperation?.operation?.name}
          </Link>
          {` no mercado de ${relatedOperation?.operation?.market?.name ?? ''}`}
        </Typography>
        {isEditing && (
          <Box sx={{ marginLeft: '14px' }}>
            <IconButton
              sx={{
                marginRight: 0.5,
                width: 20,
                height: 20,
              }}
              onClick={() => {
                setEditItemIndex(index);
                setEditDialogOpen(true);
              }}>
              <Edit sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }} />
            </IconButton>
            <IconButton
              sx={{
                marginRight: 2,
                width: 20,
                height: 20,
              }}
              onClick={() => {
                if (relatedOperation.id) {
                  setItemToRemove(relatedOperation);
                }
                setConfirmDialogOpen(true);
              }}>
              <Close sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }} />
            </IconButton>
          </Box>
        )}
      </Grid>
    ));

  return (
    <Section
      title="Operações relacionadas"
      subTitle="As informações abaixo não foram verificadas pela Uqbar"
      canEdit={professional?.isOwner}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      hasPaywall={true}
      paywallBanner="professionalProfile">
      <Grid container direction="column" spacing={2}>
        {renderedOperations}
      </Grid>
      {relatedOperations?.length && relatedOperations?.length > 3 && (
        <Button
          variant="text"
          color="primary"
          sx={{
            height: 'unset',
            fontWeight: 500,
            minWidth: 'unset',
            padding: 0,
            marginTop: 3,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={toggleShowAll}>
          {buttonText}
        </Button>
      )}
      <RelatedOperationsForm
        open={editDialogOpen}
        index={editItemIndex}
        handleClose={() => setEditDialogOpen(false)}
        professional={professional}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        submitAction={handleDelete}
        title={`Tem certeza que deseja remover a operação “${itemToRemove?.operation?.name}”?`}
        subTitle="Ao remover esta operação, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar uma nova operação dentro do perfil profissional."
        hasActionButton={true}
      />
    </Section>
  );
}
