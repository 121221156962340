export const data = {
  title: 'Carta ao leitor',
  text: [
    `
        Corria o ano de 2007, o mercado de securitização nacional ainda ensaiava seus primeiros passos, mas a Uqbar já registrava esta trajetória em seu <i>Relatório de Securitização</i>, uma publicação paga, comercializada junto a um pequeno grupo de leitores ávidos por informação sobre aquele mercado nascente. Foi quando resolvemos “elevar a barra” adicionando mais conteúdo e mudando o seu formato, na tentativa de ampliar o alcance do que estávamos produzindo. Passaríamos a escrever sobre o mercado de Finanças Estruturadas e a distribuição seria gratuita, patrocinada por um seleto grupo de instituições. Assim nasceram os <i>Anuários Uqbar</i>, cuja primeira impressão – tiragem de mil exemplares – aconteceu em março de 2008 e reportava o desempenho daqueles mercados no ano anterior.  A repercussão foi imediata. Naquele mesmo ano os Anuários foram fonte para matérias na mídia nacional.
        A passagem do papel para o digital, um arquivo em formato PDF, se deu na edição de 2010. Assim, ocorreu o esperado: o alcance ampliou-se de forma exponencial, cruzou fronteiras e passou a ser fonte também para instituições internacionais, dentre elas o Banco Mundial/IFC, o Fundo Monetário Internacional, o Banco Interamericano de Desenvolvimento e a IOSCO. O próximo passo da transformação digital da publicação aconteceria em 2021, quando substituímos o formato PDF para o HTML, imprimindo mais dinamismo e usabilidade, além da introdução de vídeos na publicação.
        A presente edição dá mais um passo no processo da transição digital dos <i>Anuários</i> e está em linha com a própria transformação da Uqbar. Mantivemos o formato HTML, mas o integramos à nossa plataforma de dados. Com isso, esperamos gerar uma nova experiencia para o leitor, principalmente aquele que for nosso assinante, pois a partir desta 16ª edição ele poderá, por conta própria, aprofundar suas análises utilizando as ferramentas disponíveis na plataforma. Essa nova experiencia será complementada com um ciclo de debates nos formatos on-line e presencial que acontecem após o lançamento da publicação. Para refletir toda essa mudança, a renomeamos para <i>Anuários Uqbar Experience</i>.
        Mas não foram somente os <i>Anuários</i> que se transformaram. De fato, o principal catalizador dessas mudanças é o próprio mercado brasileiro de Finanças Estruturadas, que nunca parou de se desenvolver, mesmo em anos onde o vento soprava contra. A inovação, o crescimento e a quebra sucessiva de recordes estão registrados nos vinte anos de sua história. 2022, ano de referência dessa publicação, manteve a tradição. Como o leitor poderá constatar, todos os quatro mercados registraram algum tipo de crescimento e recordes significativos.
        A história da publicação de referência para o mercado brasileiro de Finanças Estruturadas, não poderia ser contada sem a cumplicidade de instituições que acreditam, como a Uqbar, no crescimento sustentável baseado no conhecimento. A todas essas instituições, que ao longo de todos esses anos patrocinaram a existência e a evolução dos <i>Anuários</i>, fica aqui o nosso carinho e agradecimento. 
        A Uqbar acredita no Mercado de Capitais e em sua força multiplicadora, na mudança de paradigma proporcionado pelas Finanças Estruturadas, que ao aproximar tomadores e doadores de recursos estabelece as bases para criação de valor econômico de longo prazo. Acreditamos estarmos vivenciando o início de uma profunda mudança estrutural no mercado financeiro nacional e que esta poderá alcançar todos os segmentos de nossa economia, tornando-a mais resiliente, sustentável e menos dependente de recursos públicos. Acreditamos que os <i>Anuários Uqbar Experience</i> contribuem para essa transformação.
        Esperamos que vocês vivenciem o <i>Anuários Uqbar Experience</i> e que possam, a partir deles, tomar decisões assertivas que fortaleçam seus negócios e o Mercado de Capitais nacional.
        Saudações Uqbarianas,
        
        Carlos Augusto Lopes
        Fundador e CEO
        `,
  ],
};
