export function getLargestImageUrl(image) {
  if (!image) {
    return null;
  }

  if (image.formats) {
    const { small, medium, large } = image.formats;
    return large?.url || medium?.url || small?.url || image.url;
  }

  return image.url;
}

export function getSmallestImageUrl(image) {
  if (!image) {
    return null;
  }

  if (image.formats) {
    const { thumbnail } = image.formats;
    return thumbnail?.url || image.url;
  }

  return image.url;
}

export const calculateAspectRatioFit = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};
