import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Video } from 'src/types/institution';

type Props = {
  video: Video;
  isSelectedVideo: boolean;
};

function VideoBox({ video, isSelectedVideo }: Props) {
  let embedId;
  const youtubeRegex =
    /(https:\/\/)?(?:www.)?(?:(youtu).be\/(.*)|(youtube).com\/watch\?v=(.*?))/;
  if (
    /(https:\/\/)?(?:www.)?(?:(youtube).com\/watch\?v=(.*?))/.test(
      video.urlVideo,
    )
  ) {
    embedId = new URL(video?.urlVideo).searchParams.get('v');
  } else {
    embedId = new URL(video.urlVideo).pathname.split('/')[1];
  }
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Box
      width="calc(100% - 1px)"
      height="100%"
      sx={(theme) => ({
        backgroundColor: '#FAFAFA',
        padding: 2,
        [theme.breakpoints.down('lg')]: {
          maxHeight: 500,
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: 375,
        },
      })}>
      <Box
        sx={{
          overflow: 'hidden',
        }}>
        <iframe
          style={{
            border: 0,
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            aspectRatio: isMobile ? '1 / 2' : '2 / 1',
          }}
          src={
            isSelectedVideo
              ? youtubeRegex.test(video.urlVideo)
                ? `https://www.youtube.com/embed/${embedId}?autoplay=1`
                : `https://player.vimeo.com/video/${embedId}?autoplay=1&autopause=0`
              : ''
          }
          allow={
            isSelectedVideo
              ? 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;'
              : ''
          }
          allowFullScreen
          loading="lazy"
        />
      </Box>
      <Typography
        fontSize={18}
        fontWeight={400}
        lineHeight="25.2px"
        sx={{ padding: '42px 0 4px 0' }}>
        {video.description}
      </Typography>
    </Box>
  );
}

export default VideoBox;
