import { Grid } from '@material-ui/core';
import { startOfDay, subMonths } from 'date-fns';

import { DocumentsSelectCustom } from './DocumentSelectCustom';
import { DocumentSelectDate } from './DocumentSelectDate';
import { useAuthContext } from '../../../context/AuthContextProvider';

export default function DocumentsHeader({
  classes,
  options,
  loadingOptions,
  setFilters,
  filters,
}) {
  const { auth } = useAuthContext();

  const handleSelectedSegments = (event) => {
    const { value, name } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleGetMinAllowedDate = () => {
    if (auth?.user) {
      const { availableDataPeriod, type: subscriptionPlanType } =
        auth?.user?.subscription?.plan;

      const minAllowedDate = startOfDay(
        subMonths(new Date(), availableDataPeriod ?? 0),
      );

      if (subscriptionPlanType !== 'vip') {
        return minAllowedDate;
      }
    }

    return undefined;
  };

  return (
    <Grid container className={classes.container}>
      <DocumentsSelectCustom
        name="type"
        classes={classes}
        handleChange={handleSelectedSegments}
        options={options?.type}
        loading={loadingOptions}
        label="Tipo de Documento"
        values={filters.type}
        md={4}
        disabled={!auth?.user?.subscription?.plan?.permissions?.documentos}
      />
      <DocumentSelectDate
        classes={classes}
        filters={filters}
        setFilters={setFilters}
        disabled={!auth?.user?.subscription?.plan?.permissions?.documentos}
        minDate={handleGetMinAllowedDate()}
      />
    </Grid>
  );
}
