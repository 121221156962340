import { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useMutation } from 'react-query';

import DataTable from './DataTable';
import OperationHeader from './OperationHeader';
import { OperationSecondaryStyles } from './style';
import { PanelLogin } from '../../../components/PanelLogin';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { filterSecondary } from '../../../services/operation';

const INITIAL_VALUES = {
  dateRange: { start: '1900-01-01', end: new Date() },
  frequency: 'daily',
  classes: '',
  environment: '',
};

export default function OperationSecondary({ hash, market }) {
  const { auth } = useAuthContext();
  const classes = OperationSecondaryStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState(INITIAL_VALUES);

  const secondariesMutation = useMutation(filterSecondary);

  const { data, isLoading } = secondariesMutation;

  useEffect(() => {
    if (auth?.user?.subscription?.plan?.permissions?.operacoes_secundario) {
      secondariesMutation.mutate({
        data: {
          operationId: hash,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filters,
        },
      });
    }
  }, [filters, page, rowsPerPage]);

  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions?.operacoes_secundario ? (
        <PanelLogin />
      ) : (
        <>
          <OperationHeader
            classes={classes}
            filters={filters}
            setPage={setPage}
            setFilters={setFilters}
            hash={hash}
            market={market}
          />
          <DataTable
            classes={classes}
            data={data?.length ? data[0] : []}
            total={data?.length ? data[1] : 0}
            market={market}
            page={page}
            setPage={setPage}
            loading={isLoading}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </Box>
  );
}
