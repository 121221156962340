// Material ui components
import { Box, makeStyles, Typography } from '@material-ui/core';
// Typechecking
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function GeneralDataItem({ title, subtitle }) {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Box>
  );
}

GeneralDataItem.propTypes = {
  title: PropTypes.string.isRequired,
};
