import React, { createContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from './AuthContextProvider';
import { api } from '../api/api';
import { notify } from '../pages/Calculator/utils/handleNotify';
import { handleParseDecimal } from '../pages/Calculator/utils/handleParseDecimal';
import { calculatorApi } from '../services/calculator/api';

export const DataContext = createContext({});

export function DataProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuthContext();
  const [pickedSecurity, setPickedSecurity] = useState(null);
  const [securities, setSecurities] = useState([]);
  const [indexes, setIndexes] = useState([]);
  const [timeMeasures, setTimeMeasures] = useState([]);
  const [securityTypes, setSecurityTypes] = useState([]);
  const [simulateResult, setSimulateResult] = useState(null);
  const [enableFields, setEnableFields] = useState(true);
  const [expanded, setExpanded] = useState('panel1');
  const [name, setName] = useState('');
  const [date, setDate] = useState(null);
  const [type, setType] = useState('');
  const [calculateResultPrice, setCalculateResultPrice] = useState();
  const [calculateResultReturn, setCalculateResultReturn] = useState({
    rate: '',
  });

  const getAllSecurities = async () => {
    setLoading(true);
    const { data } = await calculatorApi.get('/securities');
    setSecurities(data);
    setLoading(false);
  };

  const getTemplateSecurity = async () => {
    setLoading(true);
    const { data } = await calculatorApi.get('/securities/template');
    setPickedSecurity(data);
    setLoading(false);
  };

  const newSecurity = async (securityValues, newName) => {
    setLoading(true);
    try {
      const { data, status } = await calculatorApi.post(
        '/securities',
        securityPayload(securityValues, newName),
      );
      if (status === 200) {
        const strapiPayload = securityStrapiPayload(securityValues, newName);
        api.post('/favorite-calculators/', {
          data: { id: data.id, ...strapiPayload },
        });
        setSecurities([...securities, data]);
        notify('Título criado com sucesso!', 'success');
        return data;
      }
      notify('Não foi possível criar o título.', 'error');
    } catch (err) {
      notify('Não foi possível criar o título.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateSecurity = async (security) => {
    setLoading(true);
    try {
      const { data } = await calculatorApi.put(
        `/securities/${security.id}`,
        securityPayload(security),
      );
      securities.map((security) => {
        if (security.id === data.id) {
          Object.assign(security, data);
        }
      });
      setSecurities([...securities]);
      api.put(`/favorite-calculators/${security.id}`, {
        data: securityStrapiPayload(security),
      });
      notify('Título atualizado com sucesso!', 'success');
      return data;
    } catch (err) {
      notify('Não foi possível atualizar o título.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteSecurity = async (id) => {
    setLoading(true);
    try {
      await calculatorApi.delete(`/securities/${id}`);
      api.delete(`/favorite-calculators/${id}`);
      setSecurities(securities.filter((security) => security.id !== id));
      setPickedSecurity(null);
      notify('Título excluído com sucesso!', 'success');
    } catch (err) {
      notify('Não foi possível excluir o título.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const simulate = async (id) => {
    setLoading(true);
    try {
      const { data } = await calculatorApi.post(`/securities/${id}/simulate`);
      setSimulateResult(data);
      notify('Simulação concluída!', 'success');
    } catch (err) {
      notify('Não foi possível realizar a simulação.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const simulateByParam = async (security) => {
    setLoading(true);
    try {
      const { data } = await calculatorApi.post(
        '/securities/simulate',
        securityPayload(security),
      );
      setSimulateResult(data);
      notify('Simulação concluída!', 'success');
    } catch (err) {
      notify('Não foi possível realizar a simulação.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAllIndexes = async () => {
    setLoading(true);
    const { data } = await calculatorApi.get('/indexes');
    setIndexes(data);
    setLoading(false);
  };

  const refreshAllIndexes = async () => {
    setLoading(true);
    try {
      const { data } = await calculatorApi.get('/indexes');
      setIndexes(data);
      notify('Indexadores atualizados.', 'success');
    } catch (err) {
      notify('Não foi possível atualizar os indexadores.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const newIndex = async (name, reference_date, entries) => {
    setLoading(true);
    const payload = {
      name: name,
      reference_date: reference_date,
      entries: entries,
    };
    try {
      const { data } = await calculatorApi.post('/indexes', { index: payload });
      indexes && setIndexes([...indexes, data]);
      notify('Index criado com sucesso!', 'success');
      return data;
    } catch (err) {
      notify('Não foi possível criar o index.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteIndex = async (id) => {
    setLoading(true);
    try {
      await calculatorApi.delete(`/indexes/${id}`);
      indexes && setIndexes(indexes.filter((index) => index.id !== id));
      notify('Index excluído com sucesso!', 'success');
    } catch (err) {
      notify('Não foi possível excluir o index.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateIndex = async (id, name, reference_date, entries) => {
    setLoading(true);
    const payload = {
      name: name,
      reference_date: reference_date,
      entries: entries,
    };
    try {
      const { data } = await calculatorApi.put(`/indexes/${id}`, {
        index: payload,
      });
      indexes?.map((index) => {
        if (index.id === data.id) {
          Object.assign(index, data);
        }
      });
      indexes && setIndexes([...indexes]);
      notify('Index atualizado com sucesso!', 'success');
      return data;
    } catch (err) {
      notify('Não foi possível criar o index.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAllSecurityTypes = async () => {
    setLoading(true);
    const { data } = await calculatorApi.get('/security_types');
    setSecurityTypes(data);
    setLoading(false);
  };

  const getAllTimeMeasures = async () => {
    setLoading(true);
    const { data } = await calculatorApi.get('/time_measures');
    setTimeMeasures(data);
    setLoading(false);
  };

  const calculatePrice = async (data) => {
    setLoading(true);
    const payload = {
      date: data.date,
      cash_flow: data.cash_flow,
      rate: {
        index_def_id: data.rate.index_def_id,
        index_factor:
          data.rate.index_factor && handleParseDecimal(data.rate.index_factor),
        index_spread:
          data.rate.index_spread && handleParseDecimal(data.rate.index_spread),
        time_measure: data.rate.time_measure,
      },
    };
    try {
      const { data } = await calculatorApi.post('/calc/price', payload);
      setCalculateResultPrice(data);
      notify('Cálculo concluído!', 'success');
    } catch (err) {
      notify('Não foi possível realizar o cálculo.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const calculateReturn = async (data) => {
    setLoading(true);
    const payload = {
      cash_flow: data.cash_flow,
      date: data.date,
      price: data.price,
      time_measure: 'dc360',
    };

    try {
      const { data } = await calculatorApi.post('/calc/rate', payload);
      setCalculateResultReturn(data);
      notify('Cálculo concluído!', 'success');
    } catch (err) {
      notify('Não foi possível realizar o cálculo.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const securityPayload = (securityValues, newName) => {
    const payload = {
      name: newName || securityValues.name,
      type: securityValues.type,
      start_date: securityValues.start_date,
      payment: {
        balance: securityValues.payment.balance,
        model: securityValues.payment.model,
        principal_payments: securityValues.payment.principal_payments,
        principal_start: securityValues.payment.principal_start,
        principal_step: securityValues.payment.principal_step,
        interest_start: securityValues.payment.interest_start,
        interest_step: securityValues.payment.interest_step,
        interest_time_measure: securityValues.payment.interest_time_measure,
        index_def_id: securityValues.payment.index_def_id,
        index_factor: handleParseDecimal(securityValues.payment.index_factor),
        index_spread: handleParseDecimal(securityValues.payment.index_spread),
      },
    };

    return payload;
  };

  const securityStrapiPayload = (securityValues, newName) => {
    return {
      name: newName || securityValues.name,
      type: securityValues.type,
      startDate: securityValues.start_date,
      payment: {
        balance: securityValues.payment.balance,
        model: securityValues.payment.model,
        principalPayments: securityValues.payment.principal_payments,
        principalStart: securityValues.payment.principal_start,
        principalStep: securityValues.payment.principal_step,
        interestStart: securityValues.payment.interest_start,
        interestStep: securityValues.payment.interest_step,
        interestTimeMeasure: securityValues.payment.interest_time_measure,
        indexFactor: handleParseDecimal(securityValues.payment.index_factor),
        indexSpread: handleParseDecimal(securityValues.payment.index_spread),
      },
    };
  };

  useEffect(() => {
    if (auth?.user?.subscription?.plan?.permissions?.calculadora) {
      getTemplateSecurity();
      getAllSecurities();
      getAllIndexes();
      getAllSecurityTypes();
      getAllTimeMeasures();
    }
  }, [auth.user]);

  return (
    <DataContext.Provider
      value={{
        loading,
        type,
        date,
        name,
        securities,
        pickedSecurity,
        simulateResult,
        enableFields,
        expanded,
        indexes,
        securityTypes,
        timeMeasures,
        calculateResultPrice,
        calculateResultReturn,
        setExpanded,
        setEnableFields,
        setSimulateResult,
        setPickedSecurity,
        deleteSecurity,
        updateSecurity,
        newSecurity,
        setName,
        setType,
        setDate,
        simulate,
        simulateByParam,
        newIndex,
        deleteIndex,
        refreshAllIndexes,
        updateIndex,
        calculatePrice,
        setCalculateResultPrice,
        setCalculateResultReturn,
        calculateReturn,
        getTemplateSecurity,
      }}>
      {children}
      <ToastContainer />
    </DataContext.Provider>
  );
}

DataProvider.propTypes = {
  children: PropTypes.any,
};
