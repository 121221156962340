import qs from 'qs';
import { api, publicApi } from 'src/api/api';

export const plans = async () => {
  const query = qs.stringify(
    {
      fields: [
        'name',
        'type',
        'maxSubscriptions',
        'minSubscriptions',
        'detailsLink',
      ],
      sort: ['displayOrder:asc'],
      populate: ['prices', 'descriptions'],
      filters: {
        type: {
          $ne: 'demo',
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/plans?${query}`);
  return data;
};

export const getAllPlans = async () => {
  const query = qs.stringify(
    {
      fields: ['name'],
      sort: ['displayOrder:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/plans?${query}`);
  return data;
};

export const getDaysDemoPlan = async () => {
  const query = qs.stringify(
    {
      fields: ['trialPeriodDays'],
      filters: {
        type: {
          $eq: 'demo',
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/plans?${query}`);
  return data;
};

export const comparePlansImage = async () => {
  const query = qs.stringify(
    {
      populate: ['comparePlans', 'comparePlans.image'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/image?${query}`);
  return data;
};

export const getPlansFAQ = async () => {
  const query = qs.stringify(
    {
      populate: ['sections', 'sections.questions'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`/faq-plano?${query}`);
  return data;
};

export const getSubscriptionBanner = async ({
  id,
  type,
}: {
  id?: number;
  type: string;
}) => {
  const query = qs.stringify(
    {
      fields: ['id'],
      populate: [`${type}.image`],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await api.get(`/plans/${id}?${query}`);
  return data;
};
