import { useEffect, useRef, useState } from 'react';

// Material ui components
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
// Material ui icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Global component
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import PanelTitles from '../../../../../components/PanelTitles';
// Apollo client
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRI_TITLES_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cri/queries';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  paper: {
    position: 'relative',
    height: 360,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },
  slider: {
    transition: 'all 0.5s',
  },
  button: {
    border: '1px solid',
    height: 30,
    minWidth: 30,
  },
  boxTitle: {
    backgroundColor: theme.palette.primary.main,
    width: 200,
    height: 360,
    position: 'absolute',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },
  boxContent: {
    margin: '50px 24px',
    width: 369,
    height: 265,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '70px 15px',
    },
  },
}));

function PaperTitles({ typeTitle, classes, data, isExtraSmall }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});
  const dividend = isExtraSmall ? 80 : 100;

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = dividend / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = dividend / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box className={classes.boxTitle}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;{typeTitle}
        </Typography>
      </Box>
      <Box className={classes.boxContent}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(
              ({
                key,
                series,
                emissionDate,
                cetipCode,
                amountIssued,
                term,
                remuneration,
              }) => (
                <PanelTitles
                  key={key}
                  series={series}
                  emissionDate={emissionDate}
                  cetipCode={cetipCode}
                  amountIssued={amountIssued}
                  term={term}
                  remuneration={remuneration}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

export default function OperationCRIResumeTitles({ hashId }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { data, loading, error } = useQuery(
    GET_CRI_TITLES_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cri_resumo_titulos'
      ],
    },
  );

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title}>
          &mdash;Títulos
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_cri_resumo_titulos'
          ]
        }>
        <Box py={2}>
          <PanelLogin />
        </Box>
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_cri_resumo_titulos'
          ]
        }>
        {loading
          ? null
          : error
            ? null
            : data?.getCriTitlesByOperationHashId?.seniors.length > 0 && (
                <PaperTitles
                  typeTitle="Seniores"
                  classes={classes}
                  data={data?.getCriTitlesByOperationHashId?.seniors}
                  isExtraSmall={isExtraSmall}
                />
              )}
        {loading
          ? null
          : error
            ? null
            : data?.getCriTitlesByOperationHashId?.mezzanines.length > 0 && (
                <PaperTitles
                  typeTitle="Mezaninos"
                  classes={classes}
                  data={data?.getCriTitlesByOperationHashId?.mezzanines}
                  isExtraSmall={isExtraSmall}
                />
              )}
        {loading
          ? null
          : error
            ? null
            : data?.getCriTitlesByOperationHashId?.subordinates.length > 0 && (
                <PaperTitles
                  typeTitle="Subordinadas"
                  classes={classes}
                  data={data?.getCriTitlesByOperationHashId?.subordinates}
                  isExtraSmall={isExtraSmall}
                />
              )}
        {loading
          ? null
          : error
            ? null
            : data?.getCriTitlesByOperationHashId?.uniques.length > 0 && (
                <PaperTitles
                  typeTitle="Únicos"
                  classes={classes}
                  data={data?.getCriTitlesByOperationHashId?.uniques}
                  isExtraSmall={isExtraSmall}
                />
              )}
      </If>
    </Box>
  );
}
