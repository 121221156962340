import { Box } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { createOperationFilter } from 'src/services/operation';
import * as Yup from 'yup';

import * as S from './styles';

type Props = {
  isOpen: boolean;
  handleClose: (changed: boolean) => void;
  filterData: {
    type: string;
    market: string;
    data: any;
    indicator?: string;
  };
};

const SaveFilterModal = (props: Props) => {
  const saveOperationsFilterMutation = useMutation(createOperationFilter, {
    onSuccess: () => {
      handleClose();
    },
  });

  const ValidateForm = Yup.object().shape({
    title: Yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: ValidateForm,
    onSubmit: (values) => {
      saveOperationsFilterMutation.mutate({
        data: {
          title: values.title,
          ...props.filterData,
        },
      });
    },
  });

  const handleClose = () => {
    props.handleClose(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="lg"
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}>
      <DialogContent
        sx={(theme) => ({
          padding: '20px 48px 48px',
          width: '800px',
          [theme.breakpoints.down('lg')]: {
            width: 'auto',
          },
        })}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 -32px',
            }}>
            <IconButton
              aria-label="fechar"
              color="inherit"
              size="small"
              onClick={handleClose}>
              <Close
                fontSize="small"
                sx={{
                  color: 'black',
                }}
              />
            </IconButton>
          </Box>
          <S.Title>
            <Typography variant="h5" fontSize={18}>
              Salvar novo filtro
            </Typography>
          </S.Title>
          <Grid container spacing={2} gap={2} paddingTop={2}>
            <Grid xs={12}>
              <S.FieldTitle>Nome do filtro*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="title"
                  placeholder="Dê um nome para esse conjunto de parâmetros que será salvo nos seus filtros"
                  onChange={formik.handleChange}
                  inputProps={{ maxLength: 50 }}
                  value={formik.values.title}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            gap={4}
            sx={{
              justifyContent: 'center',
              marginTop: '50px',
            }}>
            <Grid>
              <Button
                variant="contained"
                type="submit"
                disabled={saveOperationsFilterMutation.isLoading}>
                {saveOperationsFilterMutation.isLoading ? (
                  <CircularProgress sx={{ color: '#FFF' }} />
                ) : (
                  'Salvar'
                )}
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="outlined"
                type="reset"
                onClick={handleClose}
                disabled={saveOperationsFilterMutation.isLoading}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SaveFilterModal;
