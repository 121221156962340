import { Box } from '@material-ui/core';
import { Sort } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';

import MyFiltersButton from '../MyFiltersButton';

function ToolbarInternal({
  table,
  selectedFilter,
  handleSelectFilter,
  handleRemoveSelectedFilter,
  market,
  indicator,
  type,
  disabled,
}: {
  table: MRT_TableInstance<any>;
  selectedFilter: any;
  handleSelectFilter: (values: any) => void;
  handleRemoveSelectedFilter: () => void;
  market: string;
  type: 'basic' | 'advanced' | '';
  indicator?: string;
  disabled: boolean;
}) {
  return (
    <Box display="flex">
      <MRT_ShowHideColumnsButton
        color="primary"
        title="Personalizar"
        table={table}
        disabled={disabled}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&::after': {
            content: '"Personalizar"',
            color: disabled ? 'inherit' : '#4F4F4F',
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 1,
          },
        }}
      />
      <MRT_ToggleFullScreenButton
        color="primary"
        table={table}
        disabled={disabled}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&::after': {
            content: '"Tela cheia"',
            color: disabled ? 'inherit' : '#4F4F4F',
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 1,
          },
        }}
      />
      {selectedFilter ? (
        <IconButton
          aria-label="meus-filtros"
          onClick={handleRemoveSelectedFilter}
          disabled={disabled}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&::after': {
              content: `"${selectedFilter.title}"`,
              color: disabled ? 'inherit' : '#FF8211',
              fontSize: 14,
              fontWeight: 600,
              marginLeft: 1,
            },
          }}>
          <Sort
            color={disabled ? 'disabled' : 'primary'}
            sx={{ transform: 'scaleY(-1)' }}
          />
        </IconButton>
      ) : (
        <MyFiltersButton
          market={market}
          type={type}
          indicator={indicator}
          handleSelectFilter={handleSelectFilter}
          disabled={disabled}
        />
      )}
      <MRT_GlobalFilterTextField table={table} />
    </Box>
  );
}

export default ToolbarInternal;
