import { publicApi } from 'src/api/api';

export const searchHit = (data: any) => {
  return publicApi.post('/main-searchs/hit', data);
};

export const featuredSearchs = async () => {
  const { data } = await publicApi.get('/featured-searchs');
  return data;
};
