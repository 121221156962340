import { Box } from '@mui/material';

import OperationFIDCnvestorsMovements from './OperationFIDCInvestorsMovements';
import OperationFIDCnvestorsPosition from './OperationFIDCInvestorsPosition';
import OperationPrimaryInvestors from '../../OperationPrimaryInvestors';

type Props = {
  operationCnpj: string;
  operationName: string;
  hash: string;
  market: string;
};

export default function OperationInvestors({
  operationCnpj,
  operationName,
  hash,
  market,
}: Props) {
  return (
    <Box>
      <OperationFIDCnvestorsPosition
        operationCnpj={operationCnpj}
        operationName={operationName}
      />
      <OperationFIDCnvestorsMovements
        operationCnpj={operationCnpj}
        operationName={operationName}
      />
      <OperationPrimaryInvestors
        hash={hash}
        market={market}
        operationName={operationName}
      />
    </Box>
  );
}
