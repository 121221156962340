import { useState } from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { createSessionStripe, redirectCheckout } from 'src/services/portal';
import { cancelSubscription } from 'src/services/subscription';
import { Plan, PlanDescription, PlanPrice } from 'src/types/plans';

import ConfirmationDialog from '../ConfirmationDialog';
import { If } from '../If';
import { UsersCounter } from '../UserCounter';

type PlanCardProps = {
  plan: Plan;
  period: string | null;
  buttonLabel: string;
};

export function PlanCard({ plan, period, buttonLabel }: PlanCardProps) {
  const history = useHistory();
  const { auth, reloadUser } = useAuthContext();
  const [userCounter, setUserCounter] = useState<number>(
    plan?.minSubscriptions,
  );
  const planPrice = plan?.prices?.find(
    (price: PlanPrice) => price.interval === period,
  );

  const [openDialog, setOpenDialog] = useState(false);

  const cancelSubscriptionMutation = useMutation(() => cancelSubscription(), {
    onSuccess: async () => {
      history.push('/');
      reloadUser();
    },
  });

  const createSession = useMutation('createSession', createSessionStripe, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
    },
  });

  const checkoutMutation = useMutation('redirectCheckout', redirectCheckout, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
    },
  });

  const getTooltipText = (price?: PlanPrice) => {
    if (
      auth.user?.nextSubscription &&
      (!price || price?.priceId !== auth?.user?.nextSubscription?.priceId)
    ) {
      return 'Você possui uma assinatura pendente';
    } else if (price && price?.priceId === auth?.user?.subscription?.priceId) {
      return 'Plano atual';
    }
    return '';
  };

  const getFrequency = (period: string) => {
    switch (period) {
      case 'month':
        return '(Plano Mensal)';
      case 'year':
        return '(Plano Anual)';
      default:
        return '(Plano Mensal)';
    }
  };

  const handleClick = () => {
    if (!auth.isLoggedIn) {
      history.push('/cadastro', {
        planPrice,
        planCount: plan?.type === 'team' ? userCounter : 1,
      });
      return;
    }

    if (plan.type === 'free' && auth.user?.subscription.plan.type === 'demo') {
      setOpenDialog(true);
      return;
    }

    if (
      auth.user?.nextSubscription &&
      auth.user?.nextSubscription?.plan?.type === plan?.type
    ) {
      createSession.mutate({ type: 'customer_portal' });
      return;
    }

    if (
      ['free', 'demo'].includes(auth.user?.subscription?.plan?.type ?? '') ||
      !auth.user?.isOwner
    ) {
      checkoutMutation.mutate({
        priceId: planPrice?.priceId,
        quantity: plan?.type === 'team' ? userCounter : 1,
      });
      return;
    }

    createSession.mutate({ type: 'subscription_update' });
  };

  return (
    <Card
      elevation={plan.type === 'free' ? 2 : 12}
      sx={{
        minHeight: 500,
        height: '100%',
        padding: '24px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: plan.type === 'free' ? 'white' : '#F1F1F1',
        borderRadius: '10px',
      }}>
      <CardContent sx={{ padding: 0 }}>
        <Typography
          sx={{ fontSize: '19.15px', fontWeight: 700, whiteSpace: 'nowrap' }}>
          {plan.name}
        </Typography>
        {plan?.type === 'free' && (
          <Box sx={{ minHeight: 84 }}>
            <Box display="flex" alignItems="baseline" justifyContent="center">
              <Typography sx={{ fontSize: '22px' }}>R$</Typography>
              <Typography
                sx={{
                  fontSize: '44px',
                  fontWeight: 700,
                  padding: '0 0.5rem',
                }}>
                {(0).toLocaleString('pt-br')}
              </Typography>
            </Box>
          </Box>
        )}
        {plan?.prices
          ?.filter((price: PlanPrice) => price.interval === period)
          ?.map((price: PlanPrice) => {
            let planPrice = price?.price / 100;
            const planCount = userCounter - plan.minSubscriptions;

            if (price.unit_price) {
              planPrice = (price.price + price.unit_price * planCount) / 100;
            }

            return (
              <Box key={price.id} sx={{ minHeight: 84 }}>
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="center">
                  <Typography sx={{ fontSize: '22px' }}>R$</Typography>
                  <Typography
                    sx={{
                      fontSize: '44px',
                      fontWeight: 700,
                      padding: '0 0.5rem',
                    }}>
                    {planPrice.toLocaleString('pt-br')}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="flex-end"
                  marginTop="-8px">
                  <Typography sx={{ fontSize: '12px' }}>
                    {['team', 'vip'].includes(plan.type)
                      ? getFrequency('year')
                      : getFrequency(price.interval)}
                  </Typography>
                </Box>
                {price.unit_price && (
                  <Box display="flex" justifyContent="center" marginTop="16px">
                    <Typography sx={{ fontSize: '10px' }}>{`+ R$ ${
                      price.unit_price / 100
                    } por usuário`}</Typography>
                  </Box>
                )}
                {plan?.type === 'team' && (
                  <Box display="flex" justifyContent="center" marginTop="8px">
                    <UsersCounter
                      max={plan?.maxSubscriptions}
                      min={plan?.minSubscriptions}
                      value={userCounter}
                      onUpdate={setUserCounter}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        {plan.descriptions.map((description: PlanDescription) => (
          <Box
            key={description.id}
            display="flex"
            alignItems="baseline"
            mt={1}
            justifyContent="flex-start">
            <CheckCircleRoundedIcon
              color={plan.type === 'free' ? 'success' : 'secondary'}
              sx={{ paddingTop: '10px' }}
            />
            <Typography
              sx={{
                fontSize: '14.37px',
                marginLeft: '8px',
              }}>
              {description.value}
            </Typography>
          </Box>
        ))}
        {plan.type === 'free' &&
          auth.user?.subscription.status !== 'Expirado' && (
            <Box
              display="flex"
              alignItems="baseline"
              mt={1}
              justifyContent="flex-start">
              <CheckCircleRoundedIcon
                color="primary"
                sx={{ paddingTop: '10px' }}
              />
              <Typography
                sx={(theme) => ({
                  fontSize: '14.37px',
                  fontWeight: 700,
                  marginLeft: '8px',
                  color: theme.palette.primary.main,
                })}>
                Sete dias de demonstração com as funcionalidades do plano VIP
              </Typography>
            </Box>
          )}
      </CardContent>
      <CardActions
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}>
          <>
            {plan.type === 'free' && (
              <CustomTooltip placement="bottom" title={getTooltipText()}>
                <span style={{ width: '100%' }}>
                  {/* @ts-ignore */}
                  <Test id={'plans-button-subscrive' + plan.name}>
                    <Button
                      variant="contained"
                      sx={{
                        fontWeight: 700,
                        marginBottom: 1,
                        width: '100%',
                        backgroundColor: '#FF8211',
                        '&:hover': {
                          backgroundColor: '#FF8211',
                        },
                      }}
                      disabled={!!auth.user?.nextSubscription}
                      onClick={handleClick}>
                      {buttonLabel}
                    </Button>
                  </Test>
                </span>
              </CustomTooltip>
            )}
            {plan.type !== 'free' &&
              plan?.prices
                ?.filter((price: PlanPrice) => price.interval === period)
                ?.map((price, index) => (
                  <CustomTooltip
                    key={index}
                    placement="bottom"
                    title={getTooltipText(price)}>
                    <span style={{ width: '100%' }}>
                      {/* @ts-ignore */}
                      <Test id={'plans-button-subscrive' + plan.name}>
                        <Button
                          variant="contained"
                          sx={{
                            fontWeight: 700,
                            marginBottom: 1,
                            width: '100%',
                            backgroundColor: '#FF8211',
                            '&:hover': {
                              backgroundColor: '#FF8211',
                            },
                          }}
                          onClick={handleClick}
                          disabled={
                            price?.priceId ===
                              auth?.user?.subscription?.priceId ||
                            (auth?.user?.nextSubscription &&
                              price?.priceId !==
                                auth?.user?.nextSubscription?.priceId)
                          }>
                          {buttonLabel}
                        </Button>
                      </Test>
                    </span>
                  </CustomTooltip>
                ))}
          </>
          <Link
            sx={{
              textAlign: 'center',
              margin: '16px 0',
              cursor: 'pointer',
            }}
            onClick={() =>
              plan.detailsLink &&
              window.open(plan.detailsLink, '_blank', 'noopener noreferrer')
            }>
            Saiba mais
          </Link>
        </Box>
      </CardActions>
      <If condition={plan.type === 'free' && openDialog}>
        <ConfirmationDialog
          title={
            auth.user?.subscription.status !== 'Expirado'
              ? 'Você está no período de demonstração'
              : 'Seu período de demonstração expirou'
          }
          subTitle="Ao assinar o plano gratuito você perderá todos os seus benefícios, deseja assinar o plano gratuito agora?"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleAction={() => cancelSubscriptionMutation.mutate()}
        />
      </If>
    </Card>
  );
}
