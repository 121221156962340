import { gql } from '@apollo/client';

export const GET_DETAILS_CRI_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetDetailsCriServiceProvidersByOperationHashId($hash: String!) {
    getDetailsCriServiceProvidersByOperationHashId(hash: $hash) {
      securitizers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      fiduciaryAgents {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_DETAILS_CRI_GENERAL_DATA_BY_OPERATION_HASH_ID = gql`
  query GetDetailsCriGeneralDataByOperationHashId($hash: String!) {
    getDetailsCriGeneralDataByOperationHashId(hash: $hash) {
      operationName
      market
      securitizerName
      operationSeriesNumber
      operationEmissionNumber
      operationSeriesQuantity
      operationStructureType
      securitizerCnpj
      operationStartDate
      isFiduciaryRegiment
      isMultiseries
      isMulticlasses
    }
  }
`;
export const GET_CRI_RECEIVABLES_BY_OPERATION_HASH_ID = gql`
  query GetCriReceivablesByOperationHashId($hash: String!) {
    getCriReceivablesByOperationHashId(hash: $hash) {
      operationName
      liabilityId
      operationProduct
      operationCreditRiskCategory
      operationFiduciaryAlienation
      operationDebtor
      operationTypeofAgreement
      operationProduct
    }
  }
`;
export const GET_CRI_CHARACTERISTICS_BY_OPERATION_HASH_ID = gql`
  query GetCriCharacteristicsByOperationHashId($hash: String!) {
    getCriCharacteristicsByOperationHashId(hash: $hash) {
      operationName
      operationProduct
      operationCreditRiskCategory
      operationFiduciaryAlienation
      operationDebtor
      operationTypeofAgreement
      operationProduct
      operationFeedbackESG
      operationRealEstateSegment
    }
  }
`;
export const GET_CRI_TITLES_BY_OPERATION_HASH_ID = gql`
  query GetCriTitlesByOperationHashId($hash: String!) {
    getCriTitlesByOperationHashId(hash: $hash) {
      operationName
      seniors {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      subordinates {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      uniques {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      mezzanines {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
    }
  }
`;
export const GET_CRI_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetCriServiceProvidersByOperationHashId($hash: String!) {
    getCriServiceProvidersByOperationHashId(hash: $hash) {
      securitizers {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      fiduciaryAgents {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_CRI_CADASTRAL_DATA_BY_OPERATION_HASH_ID = gql`
  query GetCriCadastralDataByOperationHashId($hash: String!) {
    getCriCadastralDataByOperationHashId(hash: $hash) {
      securitizer
      operationName
      market
      emissionNumber
      seriesNumber
    }
  }
`;
export const GET_CRI_ASSET_CHARACTERISTICS_DATA = gql`
  query GetCriAssetCharacteristicsByHash($hash: String!) {
    getCriAssetCharacteristicsByHash(hash: $hash) {
      dataset {
        labels
        data
      }
    }
  }
`;
export const GET_CRI_ASSIGNORS_BY_OPERATION_HASH_ID = gql`
  query GetCriAssignorsByOperationHashId(
    $hash: String!
    $page: Int!
    $pageSize: Int!
  ) {
    getCriAssignorsByOperationHashId(
      hash: $hash
      page: $page
      pageSize: $pageSize
    ) {
      assignors {
        name
        cnpj
        strapiId
        slug
        market
      }
      pages
      total
    }
  }
`;
export const GET_CRI_LIABILITIES_BY_OPERATION_HASH_ID = gql`
  query GetCriLiabilitiesByOperationHashId($hash: String!) {
    getCriLiabilitiesByOperationHashId(hash: $hash) {
      titles {
        id
        series
        class
        emissionDate
        amountIssued
        quantityIssued
        unitValueIssued
        remuneration
        riskClassification
        cetipCode
        term
        debitBalance
        debitBalanceDate
        offerType
        leader {
          id
          name
          slug
        }
      }
    }
  }
`;
