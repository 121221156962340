import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Box, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import SubHeader from 'src/components/UI/SubHeader/SubHeader';

import YearbookSection from './YearbookSection';
import MetaTags from '../../components/Seo/MetaTags';
import { getInformativeTextByLocation } from '../../services/informative';
import { getYearbooks } from '../../services/misc';
import { ScrollToTop } from '../../utils/scroll';

export default function Yearbooks() {
  const { data: tooltipData } = useQuery('info', () =>
    getInformativeTextByLocation('yearbooks'),
  );

  const { data: yearbooks } = useQuery('yearbooks', getYearbooks);

  if (!yearbooks?.data?.items?.length) return null;

  const olderYearbooks = yearbooks?.data?.items?.slice(1);

  return (
    <ScrollToTop>
      <MetaTags
        title="Anuários | UQBAR"
        description="Acesse nosso histórico de anuários"
        seo={yearbooks.data.seo}
      />
      <Box component="section">
        <SubHeader
          title="Anuários"
          titleIcon={<AutoStoriesIcon />}
          breadcrumbs1="Anuários"
          subTitle={tooltipData?.data?.yearbooks?.informationText}
        />
        <Paper
          sx={(theme) => ({
            padding: '40px 65px',
            position: 'relative',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
              padding: '40px 24px',
            },
          })}>
          <Box
            sx={(theme) => ({
              width: '100%',
              height: '250px',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '5px',
            })}
          />
          <Box
            sx={{
              position: 'relative',
              zIndex: '10',
            }}>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 600,
                color: 'white',
              }}>
              {`Veja agora o ${yearbooks?.data?.items[0].name}!`}
            </Typography>
            <YearbookSection
              data={yearbooks?.data?.items[0].subcategories}
              description={yearbooks?.data?.items[0].description}
              publishDate={yearbooks?.data?.items[0].publishDate}
              isFeatured={true}
            />
          </Box>
          <Box
            sx={{
              marginTop: '32px',
            }}>
            <Typography
              sx={{
                color: '#6D6E71',
                fontSize: '14px',
              }}>
              Consulte também os relatórios de anos anteriores
            </Typography>
            <Box
              sx={{
                marginTop: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}>
              {olderYearbooks?.map((yearbook: any) => (
                <YearbookSection
                  key={yearbook.id}
                  data={yearbook.subcategories}
                  name={yearbook.name}
                  description={yearbook.description}
                  publishDate={yearbook.publishDate}
                />
              ))}
            </Box>
          </Box>
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
