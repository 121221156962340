import { useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { deleteDocument } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Document, Institution } from 'src/types/institution';

import DocumentsList from './DocumentsList';
import DocumentsModal from './DocumentsModal';

function Documents({
  institution,
  institutionRefetch,
}: {
  institution?: Institution;
  institutionRefetch: () => void;
}) {
  const dispatch = useDispatch();
  const [openDocumentsDialog, setOpenDocumentsDialog] =
    useState<boolean>(false);

  const handleRemoveDocument = (values: Document) => {
    if (values?.id) {
      deleteDocumentMutation.mutateAsync(values.id);
    }
  };

  const deleteDocumentMutation = useMutation(deleteDocument, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível remover o documento',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Documento removido com sucesso',
          }),
        );
      }
      institutionRefetch();
    },
  });

  const { isLoading } = deleteDocumentMutation;

  return (
    <Box sx={{ padding: '32px 24px 24px' }}>
      <Box>
        <Typography fontSize={14}>
          Adicione documentos em pdf, estes arquivos serão exibidos em seu
          perfil institucional.
        </Typography>
        <ul style={{ paddingLeft: '16px' }}>
          <li>Os arquivos podem ter até 5MB</li>
        </ul>
        <Button
          startIcon={<AddCircle color="primary" fontSize="large" />}
          color="primary"
          onClick={() => setOpenDocumentsDialog(true)}
          sx={{
            textTransform: 'none',
            textDecoration: 'underline !important',
            letterSpacing: 'unset',
            fontSize: '16px',
            fontWeight: 700,
            padding: 0,
            marginLeft: '4px',
          }}>
          Adicionar documentos
        </Button>
      </Box>
      <If condition={institution?.documents?.length}>
        <DocumentsList
          institution={institution}
          handleRemoveDocument={handleRemoveDocument}
          institutionRefetch={institutionRefetch}
        />
      </If>
      <DocumentsModal
        open={openDocumentsDialog}
        onClose={() => setOpenDocumentsDialog(false)}
        institutionId={institution?.id}
        institutionRefetch={institutionRefetch}
      />
    </Box>
  );
}

export default Documents;
