import { Box } from '@mui/material';
import { Presentation } from 'src/types/event';

const PreviewLiveScreen = ({
  presentation,
  width = '169px',
  height = '95px',
}: {
  presentation: Presentation;
  width?: string;
  height?: string;
}) => {
  const { liveUrl } = presentation;
  const vimeoEventId = liveUrl?.match(/\/event\/(\d+)/)?.[1];

  return (
    <Box
      sx={{
        height,
        width,
      }}>
      <iframe
        src={`https://vimeo.com/event/${vimeoEventId}/embed?background=1`}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden',
        }}
      />
    </Box>
  );
};

export default PreviewLiveScreen;
