import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Slide
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />
// })

const ResetSecurityAction = () => {
  const [openReset, setOpenReset] = useState(false);

  const handleCloseReset = () => {
    setOpenReset(false);
  };

  const handleReset = () => {
    setOpenReset(false);
    window.location.reload();
  };

  const handleClickOpenReset = () => {
    setOpenReset(true);
  };

  return (
    <>
      <Button
        color="default"
        onClick={handleClickOpenReset}
        data-hover="Limpar"
        className="hovertext"
        style={{ padding: '6px 10px', minWidth: 0 }}>
        <Close style={{ color: 'black' }} />
      </Button>
      <Dialog
        open={openReset}
        keepMounted
        onClose={handleCloseReset}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs">
        <DialogTitle>{'Deseja reiniciar o Estruturador?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Atenção! Ao reiniciar o Estruturador todos os dados simulados e
            calculados serão perdidos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Sim, tenho certeza</Button>
          <Button onClick={handleCloseReset} color="secondary">
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetSecurityAction;
