import { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { convertValue } from '../../../../utils/number';
import { getComparator, stableSort } from '../../../../utils/table';

export const StatisticsTable = ({
  data,
  state,
  numberCriterias,
  valueLabel,
}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('year');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const StyledTableCell = withStyles(() => ({
    head: {
      fontSize: '12px',
      fontWeight: 700,
    },
  }))(TableCell);

  const renderCell = (column, label) => (
    <TableSortLabel
      active={true}
      IconComponent={() => (
        <ImportExportIcon fontSize="small" color="primary" />
      )}
      direction={orderBy === column ? order : 'asc'}
      onClick={(e) => handleRequestSort(e, column)}>
      {label}
    </TableSortLabel>
  );

  return (
    <TableContainer>
      <Table aria-label="tabela de ranking">
        <TableHead>
          <TableRow>
            <StyledTableCell>{renderCell('year', 'Ano')}</StyledTableCell>
            <StyledTableCell>
              {renderCell('name', 'Primeiros Colocado')}
            </StyledTableCell>
            <StyledTableCell>
              {renderCell('totalAmount', 'Montante/Operação total')}
            </StyledTableCell>
            <StyledTableCell>
              {renderCell('totalParticipants', 'Núm. de participantes')}
            </StyledTableCell>
            <StyledTableCell>
              {renderCell('averageByParticipant', 'Média/Participante')}
            </StyledTableCell>
            <StyledTableCell>{renderCell('top3', 'Top 3')}</StyledTableCell>
            <StyledTableCell>{renderCell('top5', 'Top 5')}</StyledTableCell>
            <StyledTableCell>{renderCell('top10', 'Top 10')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy)).map((item) => (
            <TableRow key={item.year}>
              <TableCell>{item.year}</TableCell>
              <TableCell style={{ padding: '2rem 1rem' }}>
                {item.name}
              </TableCell>
              <TableCell>
                {numberCriterias.includes(state.criteria)
                  ? item.totalAmount
                  : valueLabel?.includes('Montante')
                    ? `${convertValue(item.totalAmount, null, true, 'currency')}`
                    : `${convertValue(item.totalAmount, null, false, 'decimal')}`}
              </TableCell>
              <TableCell>{item.totalParticipants}</TableCell>
              <TableCell>
                {valueLabel?.includes('Montante')
                  ? `${convertValue(
                      item.averageByParticipant,
                      null,
                      true,
                      'currency',
                    )}`
                  : `${convertValue(
                      item.averageByParticipant,
                      null,
                      false,
                      'decimal',
                    )}`}
              </TableCell>
              <TableCell>
                {convertValue(item.top3, null, false, 'percent')}
              </TableCell>
              <TableCell>
                {convertValue(item.top5, null, false, 'percent')}
              </TableCell>
              <TableCell>
                {convertValue(item.top10, null, false, 'percent')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
