import { handleParseDecimal } from './handleParseDecimal';

export const updateSecurity = ({ security, calculator, securityTemp }) => {
  security.setValues(securityTemp);
  security.setFieldValue(
    'payment.index_factor',
    handleParseDecimal(securityTemp.payment.index_factor, false),
  );
  security.setFieldValue(
    'payment.index_spread',
    handleParseDecimal(securityTemp.payment.index_spread, false),
  );
  calculator.setFieldValue(
    'price.rate.time_measure',
    securityTemp.payment.interest_time_measure,
  );
  calculator.setFieldValue(
    'price.rate.index_def_id',
    securityTemp.payment.index_def_id,
  );
  calculator.setFieldValue(
    'price.rate.index_def_id',
    securityTemp.payment.index_def_id,
  );
  calculator.setFieldValue(
    'price.rate.index_factor',
    handleParseDecimal(securityTemp.payment.index_factor, false),
  );
  calculator.setFieldValue(
    'price.rate.index_spread',
    handleParseDecimal(securityTemp.payment.index_spread, false),
  );
};
