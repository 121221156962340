import { useEffect, useState } from 'react';

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Section from 'src/components/ProfileSection';

import { TableInstitutionRanking } from './Table';
import { useInstitutionRankingStyles } from './style';
import { Paywall } from '../../../components/Paywall';
import RankingFilters from '../../../components/RankingFilters';
// Utils
import MarketsNavigation from '../../../components/UI/MarketsNavigation/MarketsNavigation';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { findFilters, findRankItemHistory } from '../../../services/ranking';

const ContentRanking = ({
  markets,
  state,
  setState,
  classes,
  filters,
  hasConsolidated,
  hashId,
  loadingFilters,
}) => {
  const { data, isLoading: loading } = useQuery(
    ['rank-items', state, filters],
    () =>
      findRankItemHistory({
        institutionId: hashId,
        market: state.market,
        type: state.ranking,
        timeRange: hasConsolidated ? state.timeRange : 'default',
        criteria: state.criteria,
      }),
    { enabled: !loadingFilters },
  );

  if (loading || !data) return <CircularProgress color="secondary" />;

  return (
    <>
      <Grid container style={{ marginTop: '2rem' }}>
        <Grid item xs={12} md={9}>
          <RankingFilters
            markets={markets}
            state={state}
            setState={setState}
            classes={classes}
            rankings={filters && filters[state.market]?.rankings}
            data={filters}
            hasConsolidated={hasConsolidated}
            hasCriteria
            typeRank="ranking_institution"
          />
        </Grid>
        {data?.length ? (
          <Grid item xs={12} md={3} className={classes.gridDate}>
            <Typography className="updated">{`Atualizado em ${format(
              parseISO(data[0]?.updatedAt),
              'dd/MM/yyyy',
            )}`}</Typography>
            <Typography className="baseMonth">
              {data &&
                `Mês base ${format(
                  new Date(
                    data[0]?.rank?.baseYear,
                    data[0]?.rank?.baseMonth - 1,
                  ),
                  'MM/yyyy',
                )}`}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      <Box>
        <Typography color="primary" className={classes.title}>
          Histórico
        </Typography>
        <TableInstitutionRanking
          classes={classes}
          state={state}
          data={data}
          loading={loading}
          valueLabel={data[0]?.criteria?.label}
          month={data[0]?.rank?.baseMonth}
        />
      </Box>
    </>
  );
};

const INITIAL_STATE = {
  market: 'FIDC',
};

function getFirstAvailableMarket(data) {
  const markets = ['CRI', 'CRA', 'FII', 'FIDC'];
  return markets?.filter(
    (marketName) => data[marketName].rankings.length > 0,
  )[0];
}

export default function InstitutionRankings({ linkState }) {
  const classes = useInstitutionRankingStyles();
  const { hashId } = useParams();
  const [state, setState] = useState(linkState?.state ?? INITIAL_STATE);
  const { auth, upgradedPlanByPermission } = useAuthContext();
  const { data, isLoading: loading } = useQuery(
    'filters',
    () => findFilters(hashId, auth.isLoggedIn),
    {
      onSuccess: (data) => {
        if (!state.year) {
          setState({
            ...state,
            year: data[state.market].years[0],
          });
        }
      },
      enabled:
        auth?.user?.subscription?.plan?.permissions['instituicao_rankings'],
    },
  );

  useEffect(() => {
    if (data) {
      const firstAvailableMarket = getFirstAvailableMarket(data);

      if (firstAvailableMarket && !linkState) {
        setState({
          ...state,
          market: firstAvailableMarket,
          ranking: data[firstAvailableMarket].rankings[0].code,
          criteria: data[firstAvailableMarket].rankings[0].criterias[0].code,
          year: data[firstAvailableMarket].years[0],
          timeRange: 'yearly',
        });
      }
    }
  }, [data]);

  const markets = data && Object.keys(data);

  const hasConsolidated =
    data &&
    data[state.market]?.rankings?.filter(
      (ranking) => ranking.code === state?.ranking,
    )[0]?.hasConsolidated;

  const handleChangeMarket = (value) => {
    setState({
      ...state,
      market: value,
      ranking: data[value].rankings[0].code,
      criteria: data[value].rankings[0].criterias[0].code,
      year: data[value].years[0],
      timeRange: 'yearly',
    });
  };

  return (
    <Section>
      <Paywall
        allow={
          auth?.user?.subscription?.plan?.permissions['instituicao_rankings']
        }
        content={`Assine o plano ${upgradedPlanByPermission(
          'instituicao_rankings',
        )} e tenha acesso a essa aba.`}>
        <MarketsNavigation
          value={state.market}
          handleSelectMarket={handleChangeMarket}
          filters={data}
          markets={['FIDC', 'FII', 'CRI', 'CRA']}
        />
        <ContentRanking
          classes={classes}
          state={state}
          setState={setState}
          markets={markets}
          hasConsolidated={hasConsolidated}
          hashId={hashId}
          filters={data}
          loadingFilters={loading}
        />
      </Paywall>
    </Section>
  );
}
