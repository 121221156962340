// Typechecking
// Material ui components
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import LensIcon from '@material-ui/icons/Lens';
import PropTypes from 'prop-types';

// Utils
import { convertValue } from '../../utils/number';

// Styles
const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    marginLeft: 3,
    padding: theme.spacing(3, 1),
    width: 350,
    [theme.breakpoints.down('xs')]: {
      width: 270,
      minHeight: 230,
    },
  },
  lensIcon: {
    fontSize: '0.6rem',
    color: '#FFCDA0',
    marginRight: theme.spacing(1),
  },
  typographySub: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
}));

export default function PanelTitles({
  series,
  emissionDate,
  cetipCode,
  amountIssued,
  term,
  remuneration,
}) {
  const classes = useStyles();

  return (
    <Box my={2} mx={1} display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        height={55}
        className={classes.box}
      />
      <Paper elevation={6} square className={classes.paper}>
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Série
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {series ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Data de emissão
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {emissionDate ?? 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Código Cetip
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {cetipCode ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Montante Emitido
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {amountIssued
                  ? `${convertValue(amountIssued, 'million')}`
                  : 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Prazo
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {term ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="body2" className={classes.typography}>
                  Remuneração
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.typographySub}>
                {remuneration ?? 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

PanelTitles.prototype = {
  title: PropTypes.string,
  hasInfo: PropTypes.bool,
  value: PropTypes.string,
};
