import { useEffect, useRef, useState } from 'react';

import CloudIcon from '@mui/icons-material/Cloud';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';
import MetaTags from 'src/components/Seo/MetaTags';
import TabTemplate from 'src/components/UI/TabTemplate';
import { getAPIs } from 'src/services/apis';
import { getInformativeTextByLocation } from 'src/services/informative';
import { getAllPlans } from 'src/services/plans';
import { API } from 'src/types/api';
import { Market } from 'src/types/market';
import { Plan } from 'src/types/plans';
import { ScrollToTop } from 'src/utils/scroll';

import APICard from './APICard';
import APIFilters from './APIFilters';

export default function APIs() {
  const plansRef = useRef<number[]>([]);
  const marketsRef = useRef<Market[]>([]);

  const { data } = useQuery('info', () => getInformativeTextByLocation('api'));

  const { isLoading: plansLoading, data: plansData } = useQuery<Plan[]>(
    'plans',
    getAllPlans,
    {
      enabled: !plansRef.current.length,
      onSuccess(data) {
        plansRef.current = data.map((plan) => plan.id);
      },
    },
  );
  const {
    isLoading: APIsLoading,
    refetch: APIsRefetch,
    data: APIs,
  } = useQuery(
    'APIs',
    () => getAPIs({ markets: state.markets, plans: state.plans }),
    {
      onSuccess(data) {
        if (!marketsRef.current.length) {
          marketsRef.current = uniqBy(
            data?.map((api: API) => api.market),
            'id',
          );
        }
      },
    },
  );

  const marketsIds = marketsRef.current.map((market) => market.id);

  const INITIAL_STATE = {
    plans: plansRef.current,
    markets: marketsIds,
  };
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    setState(INITIAL_STATE);
  }, [plansRef.current, marketsRef.current]);

  useEffect(() => {
    APIsRefetch();
  }, [state]);

  const isLoading = plansLoading || APIsLoading;

  return (
    <ScrollToTop>
      <MetaTags title="APIs" description="." />
      <Box component="section">
        <TabTemplate
          title="APIs"
          subtitle={data?.data?.api?.informationText}
          breadcrumbs1="APIs"
          titleIcon={<CloudIcon />}
          tabContent={[]}
          tabIndex={0}
          handleChange={() => {}}
        />
        <Paper elevation={2} sx={{ p: 2, borderRadius: '5px' }}>
          <Box bgcolor={'#F1F1F1'} p={1} borderRadius={'5px'}>
            <Box my={1} mb={2}>
              <APIFilters
                state={state}
                setState={setState}
                INITIAL_STATE={INITIAL_STATE}
                plans={plansData}
                markets={marketsRef.current}
              />
            </Box>
            <Stack
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={120}>
              {isLoading ? (
                <CircularProgress color="primary" />
              ) : APIs?.length ? (
                APIs?.map((API: API, key: number) => (
                  <APICard key={key} data={API} />
                ))
              ) : (
                <Typography color={'#4F4F4F'}>
                  Não foram encontrados resultados para essa busca.
                </Typography>
              )}
            </Stack>
          </Box>
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
