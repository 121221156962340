import styled from 'styled-components';

export const Image = styled.img`
  height: calc(100vh - 75px);
  filter: brightness(0.4);
  width: 740px;
  object-fit: cover;
  @media only screen and (max-width: 1366px) {
    width: 675px;
  }
  @media only screen and (max-width: 1200px) {
    width: 450px;
  }
`;

export const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;
