import { useEffect, useRef, useState } from 'react';

import { Container } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from 'react-query';

import BannerRotatingSlide from './BannerRotatingSlide';
import { useBannerRotatingStyles } from './styles';
import pauseIcon from '../../assets/imgs/banner-rotating/pause.svg';
import playIcon from '../../assets/imgs/banner-rotating/play.svg';
import { getBannerRotating } from '../../services/bannerRotating';
import { handleTouchMove, handleTouchStart } from '../../utils/touch';

export default function BannerRotating() {
  const classes = useBannerRotatingStyles();
  const device = deviceType();
  const isDesktop = device === 'desktop';
  const [numSliders, setNumSliders] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeLimit, setTimeLimit] = useState(0);
  const [timePassed, setTimePassed] = useState(0);
  const [timerActive, setTimerActive] = useState(true);
  const [lastIndicatorNav, setLastIndicatorNav] = useState(0);
  const [slidesList, setSlidesList] = useState([]);
  const [timerInterval, setTimerInterval] = useState(0);
  const [circumference, setCircumference] = useState(0);

  const controllerCircle = useRef();
  const sliderContainerRef = useRef();

  const circleOffset = timerActive
    ? circumference - (timePassed / timeLimit) * circumference
    : circumference;

  function toggleTimer() {
    const aux = timerActive;
    setTimerActive(!aux);
    if (aux) {
      stopTimer();
    } else {
      startTimer();
    }
  }

  function checkKeyToToggleTimer(event) {
    if (['Space', 'Enter'].includes(event.code)) {
      event.preventDefault();
      toggleTimer();
    }
  }

  function startTimer() {
    setTimePassed(0);
    setTimerActive(true);
    const newTimerInterval = setInterval(function () {
      setTimePassed((c) => c + 1);
    }, 1000);
    setTimerInterval(newTimerInterval);
  }

  function stopTimer() {
    setTimerActive(false);
    setTimePassed(0);
    clearInterval(timerInterval);
    setTimerInterval(0);
  }

  function setCircleDasharray() {
    const radius = 45;
    setCircumference(radius * 2 * Math.PI);
  }

  function timeIsUp() {
    setTimePassed(0);
    setTimeout(() => {
      nextIndicatorClick();
    }, 600);
  }

  function indicatorClick(event) {
    const newCurrentIndex = Number(event.target.id.replace(/\D/g, ''));
    if (newCurrentIndex !== currentIndex) {
      setCurrentIndex(currentIndex < numSliders ? newCurrentIndex : 0);
    }
  }

  function indicatorKey(event) {
    const clickedIdNumber = Number(event.target.id.replace(/\D/g, ''));
    switch (event.code) {
      case 'Space':
        event.preventDefault();
        indicatorClick(event);
        break;
      case 'Enter':
        event.preventDefault();
        indicatorClick(event);
        break;
      case 'NumpadEnter':
        event.preventDefault();
        indicatorClick(event);
        break;
      case 'ArrowLeft': {
        event.preventDefault();
        const previousIdNumber =
          clickedIdNumber - 1 < 0 ? numSliders - 1 : clickedIdNumber - 1;
        const previousId = `#indicator-${previousIdNumber}`;
        const previousIndicator = document.querySelector(previousId);
        previousIndicator.focus();
        break;
      }
      case 'ArrowRight': {
        event.preventDefault();
        const nextIdNumber =
          clickedIdNumber + 1 === numSliders ? 0 : clickedIdNumber + 1;
        const nextId = `#indicator-${nextIdNumber}`;
        const nextIndicator = document.querySelector(nextId);
        nextIndicator.focus();
        break;
      }
      case 'Tab': {
        if (isDesktop) {
          event.preventDefault();
          setLastIndicatorNav(clickedIdNumber);
          if (event.shiftKey) {
            const prevIndicator = document.querySelector('#prev-indicator');
            prevIndicator.focus();
          } else {
            const nextIndicator_1 = document.querySelector('#next-indicator');
            nextIndicator_1.focus();
          }
        }
        break;
      }
      default:
        return;
    }
  }

  function prevIndicatorClick() {
    const aux = currentIndex - 1;
    setCurrentIndex(aux < 0 ? numSliders - 1 : aux);
  }

  function nextIndicatorClick() {
    const aux = currentIndex + 1;
    setCurrentIndex(aux === numSliders ? 0 : aux);
  }

  function previousIndicatorKey(event) {
    if (['Space', 'Enter'].includes(event.code)) {
      event.preventDefault();
      const aux = currentIndex - 1;
      setCurrentIndex(aux < 0 ? numSliders - 1 : aux);
    }
    if (event.code === 'Tab' && !event.shiftKey && isDesktop) {
      event.preventDefault();
      const lastIndicator = document.querySelector(
        `#indicator-${lastIndicatorNav}`,
      );
      lastIndicator.focus();
    }
  }

  function nextIndicatorKey(event) {
    if (['Space', 'Enter'].includes(event.code)) {
      event.preventDefault();
      const aux = currentIndex + 1;
      setCurrentIndex(aux === numSliders ? 0 : aux);
    }
    if (event.code === 'Tab' && event.shiftKey && isDesktop) {
      event.preventDefault();
      const lastIndicator = document.querySelector(
        `#indicator-${lastIndicatorNav}`,
      );
      lastIndicator.focus();
    }
  }

  function moveSlide() {
    let offset;
    const slides = document.querySelectorAll('.slide');
    offset = slides[currentIndex]?.offsetLeft;
    if (offset <= 0) {
      offset = Math.abs(offset);
    }
    sliderContainerRef.current.style.transform = `translateX(-${offset}px)`;
  }

  function deviceType() {
    const device = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(device)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        device,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  const { isFetched, isLoading, refetch } = useQuery(
    'rotating-banner',
    getBannerRotating,
    {
      enabled: false,
      onSuccess(banners) {
        const orderedSlidesList = banners.sort((a, b) => a.order - b.order);
        setSlidesList(orderedSlidesList);
        setNumSliders(Number(banners.length));
        setTimeLimit(15);

        setTimeout(() => {
          startTimer();
        }, 1000);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isFetched) {
      moveSlide();
    }
  }, [currentIndex, isFetched]);

  useEffect(() => {
    setCircleDasharray();
    if (timeLimit < timePassed) {
      timeIsUp();
    }
  }, [timePassed, timeLimit]);

  if (isLoading)
    return <Skeleton variant={'rect'} className={classes.bannerRotating} />;

  return (
    <div className={classes.bannerRotating}>
      <div className={classes.controllerWrapper}>
        <div className={classes.controllerContainer}>
          <div
            className={
              timerActive
                ? classes.controllerPauseWidth
                : classes.controllerPlayWidth
            }
            id="controller"
            tabIndex="0"
            role="button"
            aria-label="pausar o banner rotativo"
            onClick={() => toggleTimer()}
            onKeyDown={(event) => checkKeyToToggleTimer(event)}>
            <div className={classes.controllerRingContainer}>
              <svg
                className={classes.controllerProgressRing}
                viewBox="0 0 100 100">
                <circle
                  ref={controllerCircle}
                  id="controller-circle"
                  className={classes.controllerProgressRingCircle}
                  cx="50"
                  cy="50"
                  r="45"
                  strokeDasharray={`${circumference} ${circumference}`}
                  strokeDashoffset={circleOffset}
                  style={{ display: timePassed > 0 ? 'block' : 'none' }}
                />
              </svg>

              <span className={classes.controllerIconContainer}>
                {timerActive && (
                  <img
                    className={classes.controllerPauseIcon}
                    id="controller-pause-icon"
                    src={pauseIcon}
                    alt="ícone pause"
                    aria-hidden="true"
                  />
                )}
                {!timerActive && (
                  <img
                    className={classes.controllerPlayIcon}
                    id="controller-play-icon"
                    src={playIcon}
                    alt="ícone play"
                    aria-hidden="true"
                  />
                )}
              </span>
            </div>
            <div className={classes.controllerTextContainer}>
              <span>{timerActive ? 'pausar' : 'reproduzir'}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classes.carouselContainer}
        id="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={(event) => handleTouchMove(event)}>
        <div className={classes.carousel} id="carousel">
          <div className={classes.slides} id="slides" ref={sliderContainerRef}>
            {slidesList?.map((banner, index) => (
              <BannerRotatingSlide key={index} index={index} {...banner} />
            ))}
          </div>
        </div>

        <div className={classes.indicatorsWrapper}>
          <Container>
            <div
              id="indicators-container"
              className={classes.indicatorsContainer}>
              <button
                id="prev-indicator"
                className={classes.previousIndicator}
                tabIndex="0"
                onClick={() => prevIndicatorClick()}
                onKeyDown={(event) => previousIndicatorKey(event)}>
                <ArrowBackIos className={classes.carouselArrows} />
              </button>
              <div id="indicators" className={classes.indicators}>
                <ol
                  id="indicators-ol"
                  className={classes.indicatorsOl}
                  role="tablist">
                  {slidesList?.map((banner, index) => (
                    <li
                      key={index}
                      tabIndex={0}
                      role={'tab'}
                      aria-selected={'false'}
                      className={`${classes.indicatorsLi} indicators-li ${index === currentIndex ? classes.indicatorsLiSelected : null}`}
                      id={`indicator-${index}`}
                      onClick={(event) => indicatorClick(event)}
                      onKeyDown={(event) => indicatorKey(event)}
                    />
                  ))}
                </ol>
              </div>
              <button
                type={'button'}
                id="next-indicator"
                className={classes.nextIndicator}
                onClick={() => nextIndicatorClick()}
                onKeyDown={(event) => nextIndicatorKey(event)}>
                <ArrowForwardIos className={classes.carouselArrows} />
              </button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
