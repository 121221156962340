import {
  Box,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { getSmallestImageUrl } from 'src/utils/image';

import breakDefault from '../../assets/imgs/break-default.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  name: {
    whiteSpace: 'nowrap',
    width: 150,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 85,
    backgroundSize: 'contain',
    marginLeft: theme.spacing(1),
  },
  lockOutlinedIcon: {
    marginLeft: theme.spacing(1),
    fontSize: '1.2rem',
  },
  lock: {
    '-webkit-filter': 'blur(5px)',
  },
  link: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export default function CardServiceProvider({
  logo,
  name,
  title,
  alert,
  lock,
  link,
}) {
  const classes = useStyles();

  return (
    <Card square className={`${classes.root} ${link && classes.link}`}>
      {!logo || lock ? (
        <CardMedia
          className={classes.logo}
          image={breakDefault}
          title="Sem imagem"
        />
      ) : (
        <CardMedia
          className={classes.logo}
          image={getSmallestImageUrl(logo)}
          title={name}
        />
      )}
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              component="div"
              variant="subtitle2"
              className={`${classes.name} ${lock && classes.lock}`}>
              <Box textOverflow="ellipsis" overflow="hidden">
                {lock ? 'Nome do gestor' : name}
              </Box>
            </Typography>
            {alert && (
              <Tooltip
                disableFocusListener
                disableTouchListener
                arrow
                title="Acesse a aba de Prestadores de Serviço para visualizar todos os prestadores de serviço da operação"
                placement="bottom">
                <LockOutlinedIcon
                  color="secondary"
                  titleAccess="bloqueado"
                  className={classes.lockOutlinedIcon}
                />
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </div>
    </Card>
  );
}
