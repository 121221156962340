import { useEffect, useRef, useState } from 'react';

import { QueryStats, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MetaTags from 'src/components/Seo/MetaTags';
import TabTemplate from 'src/components/UI/TabTemplate';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  getInformativeTextByLocation,
  getPayWallBanner,
} from 'src/services/informative';
import {
  getProfitabilityData,
  getProfitabilityFilters,
} from 'src/services/profitability';
import {
  ProfitabilityFilter,
  Profitability as ProfitabilityType,
  State,
} from 'src/types/profitability';
import { ScrollToTop } from 'src/utils/scroll';

import { ProfitabilityFilters } from './ProfitabilityFilters';
import { ScatterPlotWithRegression } from './ScatterPlotWithRegressionChart';

const PERIOD_OPTIONS = [
  { label: '7 dias', value: 7 },
  { label: '15 dias', value: 15 },
  { label: '30 dias', value: 30 },
  { label: 'Tudo', value: null },
];

export default function Profitability() {
  const [showTrend, setShowTrend] = useState(false);
  const [periodRange, setPeriodRange] = useState<number | null>(7);
  const [data, setData] = useState<ProfitabilityType[]>([]);
  const { auth } = useAuthContext();
  const filtersRef = useRef<ProfitabilityFilter>();
  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.rentabilidade;

  const DEFAULT_MARKET = 'CRI';
  const DEFAULT_METHOD = 'PRE';

  const INITIAL_STATE = {
    mercado: DEFAULT_MARKET,
    ativo_lastro: '',
    metodo: DEFAULT_METHOD,
    tipo_devedor: '',
    pulverizacao: '',
    classificacao_risco: '',
  };

  const [state, setState] = useState<State>(INITIAL_STATE);

  const { data: description } = useQuery('profitabilityDescription', () =>
    getInformativeTextByLocation('profitability'),
  );

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('profitability'),
    {
      enabled: !auth.isLoading,
    },
  );

  const {
    isLoading: isLoadingFilters,
    data: filtersData,
    refetch: refetchFilters,
  } = useQuery(
    'profitabilityFilters',
    () => getProfitabilityFilters(state.mercado),
    {
      enabled: hasPermission && !auth.isLoading,
      onSuccess: (data: ProfitabilityFilter) => {
        filtersRef.current = data;
      },
    },
  );

  const { isLoading: isLoadingData, refetch: refetchData } = useQuery(
    'profitabilityData',
    () => getProfitabilityData(state, periodRange),
    {
      enabled: !!filtersData && !isLoadingFilters,
      onSuccess: (response) => {
        setData(response.data);
      },
    },
  );

  const isLoading = isLoadingFilters || isLoadingData;

  useEffect(() => {
    refetchFilters();
  }, [state.mercado]);

  useEffect(() => {
    refetchData();
  }, [state, periodRange]);

  return (
    <ScrollToTop>
      <MetaTags
        title="Rentabilidades"
        description="Analise a rentabilidade dos mercados de CRI e CRA, instrumentos financeiros essenciais no setor imobiliário e do agronegócio. Compare o desempenho desses títulos ao longo do tempo, considerando fatores como taxas de juros, prazos e riscos associados, para tomar decisões de investimento mais informadas."
      />
      <TabTemplate
        title="Rentabilidades"
        subtitle={description?.data?.profitability?.informationText ?? ''}
        breadcrumbs1="Rentabilidades"
        titleIcon={<QueryStats />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
      />
      <Box component="section" position={!hasPermission ? 'relative' : 'unset'}>
        <Paper
          elevation={2}
          sx={{
            p: 2,
            borderRadius: '5px',
            minHeight: isExtraSmall && !hasPermission ? 420 : '100%',
          }}>
          {!hasPermission ? (
            <CardDemonstration
              title={contentPayWallBanner?.profitability?.title}
              subTitle={contentPayWallBanner?.profitability?.subTitle}
              labelButton={contentPayWallBanner?.profitability?.labelButton}
              url={contentPayWallBanner?.profitability?.url}
            />
          ) : (
            <Box p={1} borderRadius={'5px'}>
              <Box
                my={1}
                mb={2}
                display={'flex'}
                justifyContent={'space-between'}
                gap={2}>
                <ProfitabilityFilters
                  disabled={!hasPermission}
                  state={state}
                  filters={filtersRef.current}
                  setState={setState}
                  isLoading={isLoadingFilters}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '36px',
                    mt: 0.625,
                    gap: 1,
                  }}
                  disabled={isLoading || !hasPermission}
                  onClick={() => setShowTrend(!showTrend)}>
                  Tendência
                  {showTrend ? <VisibilityOff /> : <Visibility />}
                </Button>
              </Box>
              <Stack
                spacing={1}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight={120}>
                <Box pt={1} width={'100%'}>
                  <Grid container spacing={1} justifyContent="flex-end">
                    {PERIOD_OPTIONS.map(({ label, value }) => (
                      <Grid item key={value}>
                        <Typography
                          variant="subtitle1"
                          onClick={() => setPeriodRange(value)}
                          sx={{
                            borderBottom:
                              periodRange === value
                                ? '2px solid #FF8211'
                                : 'none',
                            pointerEvents: !hasPermission ? 'none' : 'auto',
                          }}
                          style={{ cursor: 'pointer' }}>
                          {label}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {isLoading ? (
                  <Skeleton variant="rounded" width={'100%'} height={350} />
                ) : (
                  <ScatterPlotWithRegression
                    data={data}
                    width={500}
                    height={220}
                    showRegression={showTrend}
                  />
                )}
              </Stack>
            </Box>
          )}
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
