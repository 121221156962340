import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { AuthContextRef } from '../../context/AuthContextProvider';

const graphqlError = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(
        ({ extensions }) => extensions && extensions.code === 'UNAUTHENTICATED',
      )
    ) {
      AuthContextRef.current.handleLogout(true);
      AuthContextRef.current.notifyAuthChannel(null);
    }

    if (networkError) {
      // ipanema.mutate({
      //   mutation: 'notificationAddMutation',
      //   variables: {
      //     text: 'There was a network problem. Please check your connection',
      //   },
      // });
    }
    return forward(operation);
  },
);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL_IPANEMA}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  let headers;
  if (sessionStorage.getItem('@App:token')) {
    headers = {
      Authorization: `Bearer ${sessionStorage.getItem('@App:token')}`,
    };
  }
  // add the authorization to the headers
  operation.setContext({
    headers,
  });

  return forward(operation);
});

// Initializing an ApolloClient instance
const ipanema = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      rankItem: {
        keyFields: ['id', 'position'],
      },
    },
  }),
  link: authMiddleware.concat(graphqlError).concat(httpLink),
});

export default ipanema;
