import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 400px;
  background-color: white;
  padding: 10px;
  color: #47484c;
  margin: -10px;
  column-gap: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: fit-content;

  img {
    width: 250px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex: 1;
  font-family: 'Inter';
  color: #47484c;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Description = styled.div`
  display: flex;
  flex: 1;
`;

export const Span = styled.span`
  &,
  span,
  a {
    font-size: inherit;
    color: #ff8211;
    text-decoration: underline;
    font-family: inherit;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;
