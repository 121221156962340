import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { difference } from 'lodash';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { Dashboard, State } from 'src/types/radar';

import { RadarStyles } from '../../styles';

type Props = {
  INITIAL_STATE: State;
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  isLoading: boolean;
  disabled: boolean;
};

export default function DashboardModule({
  INITIAL_STATE,
  state,
  setState,
  isLoading,
  disabled,
}: Props) {
  const theme = useTheme();
  const classes = RadarStyles(theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setModuleChecked = (dashboard: Dashboard) => {
    setState(() => {
      if (!state.dashboards) return state;
      if (
        state.dashboards &&
        difference([dashboard], state.dashboards).length === 0
      ) {
        const newValues = state.dashboards.filter(
          ({ id }) => id != dashboard.id,
        );

        return {
          ...state,
          dashboards: newValues.sort((a, b) => a.id - b.id),
        };
      }

      return {
        ...state,
        dashboards: [...state.dashboards, dashboard].sort(
          (a, b) => a.id - b.id,
        ),
      };
    });
  };
  const setAllModuleChecked = (indicator: string, dashboards: Dashboard[]) => {
    setState(() => {
      if (!state.dashboards) return state;
      const ids = dashboards.map(({ id }) => id);
      const newValues = state.dashboards.filter(({ id }) => !ids.includes(id));
      if (difference(dashboards, state.dashboards).length === 0) {
        return {
          ...state,
          dashboards: newValues.sort((a, b) => a.id - b.id),
        };
      }

      return {
        ...state,
        dashboards: [...newValues, ...dashboards].sort((a, b) => a.id - b.id),
      };
    });
  };
  function setAllChecked() {
    setState(() => {
      return {
        ...state,
        dashboards:
          totalDashboards === checkedDashboards ? [] : INITIAL_STATE.dashboards,
      };
    });
  }

  const totalDashboards = INITIAL_STATE.dashboards?.length ?? 0;
  const checkedDashboards = state.dashboards?.length ?? 0;
  // @ts-expect-error
  const modules: { [key: string]: Dashboard[] } = Object.groupBy(
    INITIAL_STATE.dashboards,
    ({ indicator }: { indicator: string }) => indicator,
  );

  return (
    <>
      <CustomTooltip
        title={disabled ? 'Para editar, entre no modo de personalização' : ''}
        placement="top">
        <span>
          <Button
            id="lock-button"
            aria-controls={open ? 'lock-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            endIcon={
              <ExpandMoreRoundedIcon
                sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
              />
            }
            onClick={handleClickMenu}
            disabled={disabled}
            sx={(theme) => ({
              color:
                checkedDashboards > 0 && checkedDashboards !== totalDashboards
                  ? theme.palette.primary.main
                  : '#4F4F4F',
            })}>
            Módulos:{' '}
            {checkedDashboards === totalDashboards
              ? 'Todos'
              : checkedDashboards > 0
                ? `+${checkedDashboards}`
                : 'Nenhum'}
          </Button>
        </span>
      </CustomTooltip>
      {!disabled && INITIAL_STATE.dashboards && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxHeight: '40dvh',
              minWidth: '30ch',
            },
          }}
          className={classes.optionsBox}
          aria-disabled={disabled}>
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&.Mui-selected': {
                backgroundColor: '#F1F1F1',
              },
            }}
            selected={checkedDashboards === totalDashboards}
            onClick={() => setAllChecked()}>
            <Checkbox
              disabled={disabled || isLoading}
              checked={checkedDashboards === totalDashboards}
              inputProps={{ 'aria-label': 'checkbox' }}
              color="secondary"
              className={classes.checkbox}
              size="small"
            />
            <Typography fontSize={14} fontWeight={700} color={'#000'}>
              Todas
            </Typography>
          </MenuItem>
          {Object.entries(modules)?.map((module) => {
            return (
              <Box key={module[0]}>
                <MenuItem
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setAllModuleChecked(module[0], module[1])}>
                  <Checkbox
                    disabled={disabled || isLoading}
                    checked={
                      state.dashboards &&
                      difference(module[1], state.dashboards).length === 0
                    }
                    inputProps={{ 'aria-label': 'checkbox' }}
                    color="primary"
                    className={classes.checkbox}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={600} color={'#000'}>
                    {module[0]}
                  </Typography>
                </MenuItem>
                {module[1].map((dashboard) => (
                  <MenuItem
                    key={dashboard.id}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => setModuleChecked(dashboard)}>
                    <Checkbox
                      disabled={disabled || isLoading}
                      checked={
                        state.dashboards &&
                        difference([dashboard], state.dashboards).length === 0
                      }
                      inputProps={{ 'aria-label': 'checkbox' }}
                      color="primary"
                      className={classes.checkbox}
                      size="small"
                    />
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={'#47484C'}>
                      {dashboard.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            );
          })}
        </Menu>
      )}
    </>
  );
}
