// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
// Global components
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
// Apollo client
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_LAST_DISTRIBUTED_INCOME_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIIDistributedIncome({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_FII_LAST_DISTRIBUTED_INCOME_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fii_resumo_rendimentos'
      ],
    },
  );

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Rendimentos
      </Typography>
      <Divider className={classes.divider} />
      <Box py={4}>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fii_resumo_rendimentos'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fii_resumo_rendimentos'
            ]
          }>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <PaperPanelInfo
                title="Últimos Rendimentos"
                operationLastDistributed={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getFiiLastDistributedIncomeByOperationHashId
                          ?.operationLastDistributedIncome
                }
                loading={loading}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
