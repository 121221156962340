// Material ui components
import { Box, Grid } from '@material-ui/core';

// Global components
import DateChip from '../../../../../components/DateChip';
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import Subtitle from '../../../../../components/Subtitle';

export default function OperationCRALiabilityData({ data, emission, loading }) {
  const totals = data.reduce(
    (acc, curr) => ({
      totalIssued: acc.totalIssued + curr.amountIssued,
    }),
    { totalIssued: 0 },
  );

  return (
    <div>
      <Subtitle title="Dados" />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <DateChip
          title="Competência"
          date={emission[0]?.competenceDate}
          loading={loading}
        />
      </Box>
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg>
            <PaperPanelInfo
              numeric
              title="Emissões Totais"
              value={totals.totalIssued}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg>
            <PaperPanelInfo
              numeric
              title="Valor Atualizado da Emissão"
              value={emission[0]?.emissionValue}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
