// Material ui components
import { useState } from 'react';

import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Test from 'react-test-attributes';

import HeaderBannerMessage from './HeaderBannerMessage';
import HeaderDropdownMenu from './HeaderDropdownMenu';
import HeaderItemMenu from './HeaderItemMenu';
import HeaderLoginMenu from './HeaderLoginMenu';
import NotificationMenu from './NotificationMenu';
import { useHeaderStyles } from './styles';
import logo from '../../assets/imgs/logo.svg';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getHeaderMenu } from '../../services/misc';
import BannerInfoDemo from '../BannerInfoDemo';
import { If } from '../If';
import SearchBar from '../SearchBar';
import SideMenu from '../SideMenu';

export default function Header() {
  const classes = useHeaderStyles();
  const { auth } = useAuthContext();
  const { pathname } = useLocation();
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [openMenu, setOpenMenu] = useState(false);

  const { data } = useQuery('header-menu', getHeaderMenu);

  const handleDrawerToggle = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const isInYearbookPage = pathname.startsWith('/anuarios/2024');

  const getSearchBarWidth = () => {
    if (isMedium) {
      return 'auto';
    }
    if (auth.isLoggedIn) {
      return isMobile ? '100%' : '40%';
    }
    return '33%';
  };

  const menuItems = !auth.isLoggedIn
    ? data?.items
    : data?.items?.filter((item) => item.showOnlyToLoggedOut !== true);

  if (isInYearbookPage) return null;

  return (
    <div>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        {auth.periodDemo.isDemo && (
          <BannerInfoDemo date={auth.periodDemo.date} />
        )}
        <Hidden mdDown>
          <Box className={classes.container}>
            <If condition={auth.isLoggedIn}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={
                  isInYearbookPage
                    ? classes.yearbookMenuButton
                    : classes.menuButton
                }>
                <MenuIcon />
              </IconButton>
            </If>
            <Box className={classes.boxLogo}>
              <Link to={'/'} component={RouterLink}>
                <img
                  src={logo}
                  alt="UQBAR - Educação, Conexão e Inteligência de Mercado"
                  className={classes.logo}
                />
              </Link>
            </Box>
            <SearchBar maxWidth={auth.isLoggedIn ? '40%' : '33%'} />
            {menuItems?.map((item) => (
              <HeaderItemMenu
                key={item.id}
                itemMenu={item}
                id={`${item.id}-${item.title}`}
              />
            ))}
            <Test id="header-button-plans">
              <Button>
                <Typography
                  className={classes.navTitle}
                  component={RouterLink}
                  to={'/planos'}
                  style={{ textDecoration: 'none' }}>
                  Planos
                </Typography>
              </Button>
            </Test>
            <Test id="header-button-login">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: auth.isLoggedIn ? '100px' : 'auto',
                }}>
                <If condition={auth.isLoggedIn}>
                  <NotificationMenu />
                </If>
                <HeaderLoginMenu classes={classes} />
              </Box>
            </Test>
          </Box>
        </Hidden>
        <Hidden lgUp xsDown>
          <Box className={classes.container}>
            <If condition={auth.isLoggedIn}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={
                  isInYearbookPage
                    ? classes.yearbookMenuButton
                    : classes.menuButton
                }>
                <MenuIcon />
              </IconButton>
            </If>
            <Box className={classes.boxLogo}>
              <Link to={'/'} component={RouterLink}>
                <img
                  src={logo}
                  alt="UQBAR - Educação, Conexão e Inteligência de Mercado"
                  className={classes.logo}
                />
              </Link>
            </Box>
            <HeaderDropdownMenu menuItems={menuItems} />
            <SearchBar maxWidth={getSearchBarWidth()} />
            <Box marginLeft="8px">
              <Test id="header-button-plans">
                <Button>
                  <Typography
                    className={classes.navTitle}
                    component={RouterLink}
                    to={'/planos'}
                    style={{ textDecoration: 'none' }}>
                    Planos
                  </Typography>
                </Button>
              </Test>
            </Box>
            <Test id="header-button-login">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: auth.isLoggedIn ? '100px' : 'auto',
                }}>
                <If condition={auth.isLoggedIn}>
                  <NotificationMenu />
                </If>
                <HeaderLoginMenu classes={classes} />
              </Box>
            </Test>
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box>
            <Box className={classes.container}>
              <Box display="flex" alignItems="center">
                <Box className={classes.boxLogo}>
                  <Link to={'/'} component={RouterLink}>
                    <img
                      src={logo}
                      alt="UQBAR - Educação, Conexão e Inteligência de Mercado"
                      className={classes.logo}
                    />
                  </Link>
                </Box>

                <HeaderDropdownMenu menuItems={menuItems} />
              </Box>
              <Box display="flex">
                <Test id="header-button-plans">
                  <Button>
                    <Typography
                      className={classes.navTitle}
                      component={RouterLink}
                      to={'/planos'}
                      style={{ textDecoration: 'none' }}>
                      Planos
                    </Typography>
                  </Button>
                </Test>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: auth.isLoggedIn ? '100px' : 'auto',
                  }}>
                  <If condition={auth.isLoggedIn}>
                    <NotificationMenu />
                  </If>
                  <HeaderLoginMenu classes={classes} />
                </Box>
              </Box>
            </Box>

            <Box className={classes.container}>
              <If condition={auth.isLoggedIn}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={
                    isInYearbookPage
                      ? classes.yearbookMenuButton
                      : classes.menuButton
                  }>
                  <MenuIcon />
                </IconButton>
              </If>
              <SearchBar maxWidth={getSearchBarWidth()} />
            </Box>
          </Box>
        </Hidden>
        <HeaderBannerMessage />
      </AppBar>
      <If condition={auth.isLoggedIn}>
        <SideMenu
          open={openMenu}
          setOpen={setOpenMenu}
          toggleMenu={handleDrawerToggle}
          isDemo={auth.periodDemo.isDemo}
        />
      </If>
    </div>
  );
}
