import React from 'react';

import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const StaticAlert = ({ message }) => {
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Alert severity="warning">{message}</Alert>
    </Box>
  );
};

export default StaticAlert;
