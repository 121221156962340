import * as Yup from 'yup';

const MAX_FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const ValidationProfessionalForm = (institutionName: string) => {
  return Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    surname: Yup.string().required('Campo obrigatório'),
    professionalSegments: Yup.array()
      .of(Yup.number())
      .min(1, 'Campo obrigatório'),
    markets: Yup.array().of(Yup.number()).min(1, 'Campo obrigatório'),
    photo: Yup.mixed()
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          return !value || (value?.type && value?.size <= MAX_FILE_SIZE);
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        return (
          !value || (value?.type && SUPPORTED_FORMATS.includes(value.type))
        );
      }),
    role: Yup.string().required('Campo obrigatório'),
    relatedInstitutions: Yup.object({
      fantasyName: Yup.string().test(
        'required',
        'Campo obrigatório',
        (value) => {
          if (value || institutionName) {
            return true;
          }
          return false;
        },
      ),
    }),
    otherInstitution:
      institutionName === 'Outra'
        ? Yup.string().required('Campo obrigatório')
        : Yup.string().optional(),
  });
};

export const ValidationInstitutionForm = () => {
  return Yup.object().shape({
    razaoSocial: Yup.string().required('Campo obrigatório'),
    fantasyName: Yup.string().required('Campo obrigatório'),
    segments: Yup.array().of(Yup.number()).min(1, 'Campo obrigatório'),
    markets: Yup.array().of(Yup.number()).min(1, 'Campo obrigatório'),
    websiteUrl: Yup.string().matches(
      /^[http|https]+:\/\//,
      'O link deve conter http:// ou https://',
    ),
    logo: Yup.mixed()
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          return !value || (value?.type && value?.size <= MAX_FILE_SIZE);
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        return (
          !value || (value?.type && SUPPORTED_FORMATS.includes(value.type))
        );
      }),
  });
};
