import {
  fiiPrimaryMarketCharts,
  fiiSecondaryMarketCharts,
  fiiStockMarketCharts,
} from './charts/FII';
import {
  YearbookDescriptions,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fiiOpeningTexts = [
  `
  O Anuário Uqbar de Fundos de Investimento Imobiliário (FII) oferece uma análise abrangente do mercado em 2023, destacando as tendências, desafios e oportunidades que moldaram o cenário dos FIIs ao longo do ano.
   Além dos aspectos particulares mencionados a seguir, é importante notar os desafios significativos enfrentados pelo mercado de FIIs, que passou por mudanças importantes, desde o impacto no mercado varejista até a modificação dos requisitos mínimos para isenção de impostos sobre investimentos.
   `,
  `
  O mercado varejista teve um papel crucial no cenário dos FIIs em 2023, com eventos que influenciaram diretamente a dinâmica desses fundos. 
  O rombo de bilhões na Americanas S.A. reverberou significativamente, levando a mudanças estruturais em várias varejistas. 
  Empresas como Tok&Stok e Marisa enfrentaram dificuldades em cumprir seus compromissos, com a Tok&Stok sendo alvo de ação de despejo movida por um FII devido à falta de pagamento do aluguel, enquanto a Marisa atrasou alguns aluguéis devidos para FIIs.
  `,
  `
  Além dos desafios no mercado varejista, o cenário regulatório também passou por mudanças significativas em 2023. Anteriormente, para que os rendimentos fossem isentos de impostos para os beneficiários pessoas físicas, era exigido um mínimo de 50 cotistas no fundo.
   No entanto, a aprovação da Lei 14.754/2023 alterou esse requisito, concedendo a isenção nos casos de FIIs e FIAGROS que possuam, no mínimo, 100 cotistas. 
   Essa mudança teve um impacto direto na estrutura e operações de alguns FIIs, alterando os critérios para a obtenção de benefícios fiscais e exigindo uma adaptação por parte dos gestores e administradores.
  `,
  `
  A mesma lei também franqueou aos FIIs constituirem ônus reais sobre imóveis ou prestarem fiança, aval, aceite ou coobrigarem-se de qualquer forma, exclusivamente para garantir obrigações assumidas pelos fundos ou por seus cotistas.
   Esta é uma adição notável, considerando que, até então, a regulamentação vigente proibia expressamente essas práticas.
   Contudo, o Anexo Normativo III da Resolução CVM nº 175, que detalha as normas para os FIIs, ainda proíbe a constituição de ônus ou a coobrigação sob qualquer hipótese. 
   A CVM, responsável pela regulamentação e supervisão dos FIIs, reconhece a necessidade de uma norma específica para implementar as mudanças e harmonizar as disposições.
  `,
  `
  O Anuário Uqbar de Fundos de Investimento Imobiliário (FII) proporciona uma visão completa e detalhada do mercado em 2023, considerando não apenas as tendências e oportunidades, mas também os desafios enfrentados pelo setor.
   Ao abordar o impacto no mercado varejista e as mudanças na legislação relacionada aos FIIs, esta publicação busca oferecer insights valiosos para os participantes do mercado interessados em compreender e navegar pelo cenário dinâmico dos FIIs no Brasil.
  `,
];

export const fiiYearbookDescriptions: YearbookDescriptions = {
  primary: [
    `As emissões de Fundos de Investimento Imobiliário (FIIs) são um indicador crucial para compreender as dinâmicas do mercado imobiliário e de capitais. 
    Em 2023, essas emissões totalizaram R$ 34,1 bilhões, refletindo uma queda de 8,7% em relação ao ano anterior e marcando uma tendência de declínio desde o pico histórico em 2021, quando atingiram R$ 51,4 bilhões. 
    Vários fatores contribuíram para esse cenário, incluindo a influência da taxa de juros e as mudanças na estrutura econômica, bem como o impacto da transição para o trabalho remoto na desocupação de imóveis comerciais.
    `,
    `
  Neste contexto, é fundamental examinar a composição das emissões de FIIs, destacando a evolução da participação de diferentes tipos de ativos e a sua relevância relativa no mercado. 
  Os FIIs de renda variável emergiram como protagonistas, dobrando sua participação em relação ao ano anterior e equiparando-se aos tradicionais FIIs de imóveis. 
  Além disso, a distribuição dessas emissões por segmento imobiliário revela mudanças significativas, com destaque para a queda drástica na participação dos FIIs de escritórios, contrastando com o crescimento contínuo dos segmentos de logística e varejo. 
  Este panorama evidencia a dinâmica complexa e em constante evolução do mercado de FIIs, influenciado por uma variedade de fatores econômicos e setoriais.
  `,
  ],
  secondary: [
    `
    A análise dos negócios envolvendo cotas de Fundos de Investimento Imobiliário (FIIs) na Bolsa de Valores de São Paulo (B3) revela um crescimento expressivo ao longo dos anos de 2018 a 2023.
    `,
    `
    Esses dados são essenciais para compreender a evolução e o impacto dos FIIs no mercado financeiro brasileiro, destacando seu papel como uma alternativa de investimento atrativa e em ascensão.
    `,
    `
    Os números apresentados refletem não apenas o aumento do volume de negócios, mas também a crescente relevância dos FIIs como uma classe de ativos, evidenciando um interesse crescente por parte dos investidores e uma maior maturidade do mercado.
    `,
    `
    Ao longo dos anos, observa-se uma tendência de crescimento contínuo tanto no volume quanto na frequência de negócios, indicando um mercado em expansão e uma crescente confiança dos investidores nesse tipo de ativo.
    `,
    `
    É fundamental acompanhar de perto essas tendências e entender os fatores que impulsionam o crescimento dos FIIs, a fim de tomar decisões de investimento informadas e aproveitar as oportunidades oferecidas por esse mercado dinâmico.
    `,
  ],
  stock: [
    `
    O Patrimônio Líquido (PL) de um Fundo de Investimento Imobiliário (FII) representa o valor líquido dos ativos do fundo após a subtração de suas obrigações e passivos.
    `,
    `
    A oscilação no Patrimônio Líquido está diretamente ligada à avaliação periódica dos imóveis presentes na carteira do fundo, assim como ao valor justo dos títulos e cotas do FII. Além disso, emissões ou amortizações de cotas também podem impactar no valor do PL.
    `,
    `
    O PL é um indicador crucial para avaliar a saúde e a dinâmica desse mercado em constante evolução. Em 2023, o mercado de FIIs testemunhou um crescimento notável, tanto em termos de PL quanto na diversificação e especialização dos fundos ativos. 
    Ao final do ano, o PL atingiu um marco histórico, totalizando R$ 308,7 bilhões, um aumento de 21,5% em comparação com dezembro de 2022. Paralelamente, o número de FIIs ativos também atingiu um recorde, chegando a 919 fundos, um aumento de 13,9% em relação ao ano anterior. 
    Este crescimento reflete não apenas a maturidade crescente do mercado de FIIs, mas também a crescente confiança dos investidores nesse tipo de ativo.
    `,
    `
    Ao analisar o PL por tipo de ativo, os FIIs de Imóvel continuam a se destacar como o segmento mais proeminente, representando quase dois terços do PL total dos FIIs em dezembro de 2023. 
    Por outro lado, os FIIs de renda fixa, renda variável e FIAGRO-FII também desempenham papéis significativos, refletindo a diversificação do mercado e as diferentes estratégias de investimento adotadas pelos gestores.
    `,
    `
    Além disso, ao observar o PL por tipo de finalidade, fica evidente que os FIIs voltados para a geração de renda permanecem como os principais impulsionadores do mercado, representando mais de um terço do PL total. 
    Enquanto isso, os FIIs com finalidades diversas, que englobam uma variedade de estratégias de investimento, também desempenham um papel importante, refletindo a busca por oportunidades de ganho de capital e diversificação de portfólio.
    `,
    `
    No que diz respeito à gestão dos FIIs, os fundos de gestão ativa mantêm uma visibilidade histórica significativa, respondendo por uma grande parte do PL total do mercado em 2023. 
    Essa preferência por gestão ativa reflete a busca por estratégias mais dinâmicas e adaptáveis em um ambiente de mercado em constante mudança.
    `,
    `
    Por fim, a análise do PL por segmento imobiliário destaca a ascensão dos FIIs de Logística como líderes de mercado, evidenciada pelo aumento gradual de sua participação no PL total ao longo dos anos. Em contrapartida, os FIIs de escritórios vêm perdendo visibilidade, ficando atrás do varejo em termos de representatividade. 
    Esse panorama reflete as mudanças nas demandas do mercado imobiliário e as oportunidades emergentes em setores específicos, como a logística, impulsionadas por mudanças estruturais na economia e no comportamento do consumidor.
    `,
  ],
  rankings: [
    `O mercado de Fundos de Investimento Imobiliário (FIIs) é um ecossistema dinâmico e multifacetado, onde diferentes atores desempenham papéis essenciais na formação e operação desses instrumentos financeiros. 
    Nesse contexto, uma gama diversificada de rankings Uqbar emerge para fornecer uma compreensão abrangente e detalhada do setor. 
    Entre esses rankings, destacam-se aqueles que abordam não apenas o montante emitido pelos FIIs, mas também o desempenho das cotas negociadas, a participação de gestores e administradores, bem como o papel dos advogados e auditores na conformidade regulatória e na transparência das operações.`,
    `Os rankings relacionados aos advogados, auditores e gestores oferecem uma visão detalhada das entidades que desempenham um papel crucial na estruturação, supervisão e gestão dos FIIs. 
    A presença de escritórios renomados, empresas de auditoria reconhecidas e gestoras de destaque nessas classificações é indicativa da qualidade e da seriedade do mercado de FIIs.`,
    'Os rankings Uqbar não apenas oferecem uma visão panorâmica do mercado de FIIs, mas também servem como ferramentas valiosas para análises detalhadas e tomadas de decisão estratégica, contribuindo assim para uma compreensão mais completa e informada desse segmento do mercado financeiro.',
  ],
};

export const fiiStockMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiStockMarketCharts.plFII,
    name: 'Patrimônio Líquido dos FIIs',
    section: 'stock',
    details: {
      title: 'PL dos FIIs em 2023',
      texts: [
        `O mercado de FIIs registra um crescimento frequente no Patrimônio Liquido (PL) e na quantidade de fundos ativos. Ao final de 2023 o PL atingiu sua máxima histórica de R$ 308,7 bilhões, além disso alcançou seu recorde em numero de FIIs, equivalente a 919 fundos. 
        Em comparação com dezembro de 2022, o PL e o número de fundos cresceram, respectivamente, 21,5% e 13,9%.`,
      ],
      value: 308.76,
      valueTitle: 'Patrimônio Líquido Total',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiStockMarketCharts.plByAssetTypeFII,
    name: 'Patrimônio Líquido por Tipo de Ativo',
    section: 'stock',
    details: {
      title: 'FIIs de Imóvel se mantém como maior destaque histórico',
      texts: [
        `
        Em termos de patrimônio liquido (PL), os FIIs quem possuem ativos sendo imóveis na carteira, somaram, em dezembro de 2023, 61,97% do PL dos FIIs. Em segundo lugar, os FIIs de renda fixa somaram 21,5% do PL, seguidos pelos FIIs de Renda Variável com 9,2% e FIAGRO-FII com 5,2% do PL.`,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByGoalTypeFII,
    name: 'Patrimônio Líquido por Tipo de Finalidade',
    section: 'stock',
    details: {
      title: 'PL por tipo de Finalidade',
      texts: [
        `Os FIIs que possuem como finalidade a obtenção de renda através de seus ativos contemplam, historicamente, a maior fatia do mercado, representando 36,3% do PL ao final do ano de 2023. 
        Na segunda posição está os FIIs que possuem finalidade Diversa, compondo uma junção de diferentes tipos de finalidade, dentre Ganho de Capital, Securitização, Desenvolvimento, somando 33,7% do PL, seguidos pelos FIIs que possuem como finalidade a Gestão de Ativos Financeiros, com 27,5% do PL.`,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByManagementTypeFII,
    name: 'Patrimônio Líquido por Tipo de Gestão',
    section: 'stock',
    details: {
      title: 'FIIs de Gestão ativa possuem maior visibilidade histórica',
      texts: [
        `
        Os FIIs de gestão ativa os gestores possuem maior dinamismo nos ativos que compõem a carteira, esta categoria se consolidou ao longo dos anos e ao final de 2023 somaram 88,5% de todo PL do mercado de FIIs. Por ora, os FIIs de gestão passiva ficaram com 11,5% do PL.
        `,
      ],
    },
  },
  {
    chart: fiiStockMarketCharts.plByPropertyTypeFII,
    name: 'Patrimônio Líquido por Tipo de Imóvel',
    section: 'stock',
    details: {
      title: 'FIIs de Logística dominam em PL',
      texts: [
        `Historicamente é possível observar que os FIIs de Logística foram ganhando espaço gradativamente em termos de PL, em outubro deste ano uma [breve análise do setor](https://uqbar.com.br/artigo/pl-do-segmento-logistico-tem-aumento-de-9-4-em-agosto/6097) feita pela Uqbar mostrava o desempenho do segmento mais pujante no mercado de FIIs, e, destacamos, dos 75 fundos pertencentes ao segmento logístico listados pela Uqbar, os cinco maiores em PL, com atualizações para o final de 2023, sendo eles: 
        Prologis Brazil Logistics Venture (PBLV11) com PL de R$6,19 bilhões, CSHG Logística (HGLG11) com R$5,35 bilhões, XP Log (XPLG11) com R$3,26 bilhões, BTG Pactual Logística e Golgi, com R$ 2,80 bilhões e R$ 1,82 bilhão, respectivamente. 
        Ao final do ano de 2023, os FIIs de Logística se tornaram o maior representante do mercado, com 22,4% do total, contra 5,4% em janeiro de 2018. Em contra partida, os FIIs do tipo Escritório vem perdendo visibilidade ao longo dos anos, ficando abaixo do varejo em representatividade.`,
      ],
    },
  },
];

export const fiiSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiSecondaryMarketCharts.secondaryFII,
    name: 'Mercado Secundário',
    section: 'secondary',
    details: {
      title: 'Montante negociado de cotas de FII se recupera em 2023',
      texts: [
        `O mercado secundário cotas é composto indicadores como o montante negociado e o número de negócios com cotas de FIIs na B3. 
        Em 2023 o montante negociado atingiu uma máxima histórica de R$69,2 bilhões, o que representa um aumento de 13,3% em relação ao ano anterior. 
        Enquanto isso, o número de negócios obteve o recorde de 137.144.578 transações, os meses de agosto e novembro foram os de maior liquidez representando mais de 13.3 milhões de negociações cada, desenvolvendo um crescimento de 47,9% em comparação a 2022.`,
      ],
      value: 69.2,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (milhões)',
    },
  },
];

export const fiiPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiiPrimaryMarketCharts.emissionsFII,
    name: 'Emissões dos FIIs',
    section: 'primary',
    details: {
      title: 'Emissões de FII',
      texts: [
        `Em 2023, as emissões de FII somaram R$ 34,1 bilhões, desenhando uma queda de 8,7% em relação a 2022 (R$ 37,1 bilhões). 
        Além disso, as emissões de FII operam em queda desde sua máxima histórica em 2021, quando registrou aproximadamente R$ 51,4 bilhões. 
        Isso se configura pela movimentação positiva da taxa de juros que é inversamente proporcional a estrutura econômica dos FIIs. 
        Além disso, aspectos como a desocupação de imóveis por empresas devido ao home-office também agregam a queda das emissões de FII.`,
      ],
      value: 34.11,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiPrimaryMarketCharts.variableIncomeFII,
    name: 'Emissões de FII por Tipo de Ativo',
    section: 'primary',
    details: {
      title: 'FIIs de renda variável dobram sua participação',
      texts: [
        `Os FIIs de Imóvel continuam tendo a maior fatia de emissões de FII, com 38,7% do montante emitido em 2023.
         Entretanto, os FIIs de renda variável dobraram sua participação se equiparando com a participação dos FIIs de renda fixa e os FIAGROS-FII.
         Nesse mesmo ano, os FIIs de renda variável representaram 16,8% do montante total emitido, tendo registrado um crescimento de 91,9% em relação ao montante de 2022.
         Enquanto isso, os FIIs de renda fixa tiveram uma participação de 19,9% no montante emitido em 2023, uma queda de 71,8% em relação ao montante de 2022; e os FIAGRO-FII mantiveram uma participação similar em 2023 quando comparado ao ano anterior, de 18,3%.`,
      ],
      value: 34,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiiPrimaryMarketCharts.emissionsByPropertyTypeFII,
    name: 'Emissões de FII por Tipo de Imóvel',
    section: 'primary',
    details: {
      title:
        'Participação dos FIIs de Escritórios em emissões reduz drasticamente.',
      texts: [
        `Os FIIs do tipo Logística se mantiveram em destaque desde 2018, sempre com participação acima de 30% do montante emitido. Em 2023, Logística representou 39,2%, um aumento em relação ao ano anterior. 
        É imprescindível destacar os FIIs do tipo Varejo, que tiveram uma participação de 29,7% em 2023, tendo registrado um crescimento constante desde 2020. 
        Enquanto isso, os FIIs do tipo Escritórios assinalaram uma participação ínfima de 1,5% do montante total, uma queda significativa em relação ao ano anterior.`,
      ],
      value: 14.23,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const fiiRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking de FII por Montante Emitido em 2023',
    details: {
      title: 'Ranking de FII por Montante Emitido em 2023',
      texts: [
        `No mercado primário temos o ranking de FIIs por Montante Emitido, composto por 168 fundos. Os três primeiros colocados do ranking possuem uma concentração de 12,41%, com a liderança do CSHG Logística, correspondendo a 4,72% do total, representando R$ 1,61 bilhões. 
        Na segunda colocação está o FIAGRO Kinea Crédito Agro, com R$ 1,38 bilhões, correspondendo a 4,06%. E na terceira colocação se encontra o XP Malls, com R$ 1,24 bilhões, correspondendo a 3,63%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafiiallmarket&criteria=amountissued&timeRange=yearly',
  },

  {
    name: 'Ranking de FII por Montante Emitido em 2023 (Fundos Listados)',
    details: {
      title: 'Ranking de FII por Montante Emitido em 2023 (Fundos Listados)',
      texts: [
        `O mercado primário de fundos com cotas listadas na B3 possui um ranking de FII composto por 129 participantes. O líder do ranking é o CSHG Logística com R$1,61 bilhões, representando 5,62% do total. 
        A segunda posição é ocupado pelo FIAGRO Kinea Crédito Agro, com R$ 1,38 bilhões, correspondendo a 4,84%. Já na terceira posição está o XP Malls, com R$ 1,24 bilhões, correspondendo a 4,33%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=primarymarketlisted&criteria=amountissued&timeRange=yearly',
  },

  {
    name: 'Ranking de Advogados por Montante Emitido em 2023',
    details: {
      title: 'Ranking de Advogados por Montante Emitido em 2023',
      texts: [
        `O ranking de Assessores Jurídicos possui 10 ranqueados e um montante computado total de R$ 6,84 bilhões. Em primeiro lugar está o escritório Ishikawa, Peyser Advogados, com R$ 3,92 bilhões, representando 57,27% do total. No segundo lugar está o NFA, com R$ 577,74 milhões, representando 8,45%. 
        No terceiro lugar está o TozziniFreire Advogados, com R$ 558,97 milhões, ou 8,17% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  },

  {
    name: 'Ranking de Advogados por Número de Operações em 2023',
    details: {
      title: 'Ranking de Advogados por Número de Operações em 2023',
      texts: [
        `O ranking de Assessores Jurídicos possui 10 participantes, com um total de 33 operações. 
        A liderança foi da Ishikawa, Peyser Advogados com 9 operações, no qual representa 27,27% do total. 
        Na segunda colocação está o NFA com 8 operações, ou 24,24% das operações emitidas. 
        Na terceira colocação está a Daló e Tognotti com 5 operações emitidas, o que representa 15,15% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Montante Negociado em 2023',
    details: {
      title: 'Ranking de Cotas de FII por Montante Negociado em 2023',
      texts: [
        `No mercado secundário, o ranking de cotas de FII é composto por 393 participantes e o total de R$ 68,99 bilhões. Os três primeiros colocados concentram 11,00% do total negociado. A liderança é do Kinea Rendimentos Imobiliários, com a cifra de R$ 2,85 bilhões, na qual representa 4,12% do total. 
        Na segunda posição está o Kinea Índices de Preços, com R$ 2,41 bilhões, ou 3,48% do total. Em seguida, na terceira posição, está CSHG Logística com R$ 2,33 bilhões, ou 3,36%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=amountnegotiated&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Número de Negócios em 2023',
    criteria: 'numeric',
    details: {
      title: 'Ranking de Cotas de FII por Número de Negócios em 2023',
      texts: [
        `O ranking de cotas de FII negociadas no mercado secundário possui o total de 136.288.936 negócios e 393 participantes ranqueados. 
        A liderança é dada pelo Maxi Renda, com o total de 7.355.227 negócios, o que representa 5,36% do total. 
        Na segunda posição está o Valora CRI CDI com 6.743.591 negócios ou 4,92% do total. Na terceira posição se encontra a Valora Hedge Fund, com 4.259.588 negócios, o que representa 3,11% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=tradesnumber&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Variação de Preço em 2023',
    details: {
      title: 'Ranking de Cotas de FII por Variação de Preço em 2023',
      texts: [
        `O ranking de cotas de FII por variação de preço conta com a participação de 577 ranqueados. O primeiro lugar em destaque é o Kinea Rendimentos Imobiliários, que estabeleceu uma variação de suas cotas em 2,70% no ano. 
        Em segundo lugar esta o Kinea Índices de Preço com 2,20% ao ano e em terceiro lugar está o Maxi Renda com 2,09% a.a.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=pricevariance&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Dividend Yield Realizado em 2023',
    details: {
      title: 'Ranking de Cotas de FII por Dividend Yield Realizado em 2023',
      texts: [
        `O Ranking de Cotas de FII por Dividend Yield Realizado coloca em primeiro lugar o XP Hotéis Mezanino (XPHT12), com um DY realizado de 7,19% ao ano. 
        Em segundo lugar está o Even Permuta Kinea (KINP11), com 5,91%, seguido pelo Shopping Jardim Sul (BRJRDMCTF002) com 2,93%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=dividendyield&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Rentabilidade Efetiva em 2023',
    details: {
      title: 'Ranking de Cotas de FII por Rentabilidade Efetiva em 2023',
      texts: [
        `A rentabilidade efetiva é equivalente à taxa interna de retorno (TIR). 
        A primeira posição do ranking é ocupada pelo fundo Hotel Maxinvest, com uma rentabilidade de 63,51%, seguido pelo BTG Pactual Shopping, com 56,85%, e General Shopping e Outlets do Brasil, com 56,81%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=effectivereturn&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Capitalização de Mercado',
    details: {
      title: 'Ranking de Cotas de FII por Capitalização de Mercado',
      texts: [
        `No topo do ranking, encontra-se o Kinea Índices de Preços, com uma capitalização de mercado de mais de R$ 7,35 bilhões. 
        Em segundo lugar, temos o Kinea Rendimentos Imobiliários, com R$ 5,80 bilhões em valor de mercado. 
        O terceiro lugar do ranking é ocupado pelo CSHG Logística, que possui uma capitalização de mercado de R$ 5,39 bilhões.
        `,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=capitalization&timeRange=yearly',
  },

  {
    name: 'Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido',
    details: {
      title:
        'Ranking de Cotas de FII por Capitalização de Mercado sobre Patrimônio Líquido',
      texts: [
        `Notavelmente, os números exibidos revelam percentuais significativamente superiores a 100%, o que indica uma situação em que o valor da capitalização é maior do que o próprio patrimônio líquido do fundo. 
        No caso do FII Unidades Autônomas II, o percentual atinge 124,42%, sugerindo um capitalização consideravelmente maior em relação ao PL. De maneira semelhante, os FIIs Mérito Cemitérios e Banrisul Novas Fronteiras exibem percentuais ainda mais elevados, com 169,53% e 142,11%, respectivamente.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=quotafii&criteria=marketcapitalization&timeRange=yearly',
  },

  {
    name: 'Ranking de Administradores por Capitalização de Mercado',
    details: {
      title: 'Ranking de Administradores por Capitalização de Mercado',
      texts: [
        `Ranking de Administradores por Capitalização de Mercado coloca, em primeiro lugar, o BTG Pactual Serviços Financeiros DTVM, com uma participação de 24,55% e um montante de R$ 44,7 bilhões. 
        Em segundo lugar está a Intrag DTVM, com 16,65% do mercado e um montante de R$ 30,3 bilhões, seguida da BRL Trust DTVM, com 12,64% e R$ 23,0 bilhões. 
        A concentração do mercado nos 3 primeiros do ranking é de 56,8% Vale destacar que esses administradores possuem uma ampla variedade de fundos imobiliários sob sua gestão, desde fundos de papel até fundos de tijolo, com diferentes estratégias e perfis de risco.
        `,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=admin&criteria=capitalization&timeRange=yearly',
  },

  {
    name: 'Ranking de Administradores por Número de Fundos Negociados em 2023',
    details: {
      title:
        'Ranking de Administradores por Número de Fundos Negociados em 2023',
      texts: [
        `O Ranking de Administradores por Número de Fundos Negociados fechou 2023 com 415 fundos imobiliários negociados, um número 26,91% maior que em 2022. 
        O administrador que obteve o destaque do primeiro lugar foi o BTG Pactual Serviços Financeiros DTVM, com 93 fundos negociados no ano de 2023. No segundo lugar, aparecem BRL Trust DTVM, com 55, e, em terceiro lugar, a Vórtx, com 28 fundos negociados.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=admin&criteria=negotiatedefundnumber&timeRange=yearly',
  },

  {
    name: 'Ranking de Gestores por Capitalização de Mercado em 2023',
    details: {
      title: 'Ranking de Gestores por Capitalização de Mercado em 2023',
      texts: [
        `O Ranking de Gestores por Capitalização de Mercado somou, em 2023, R$ 258,30 bilhões. O total de ranqueados ficou em 183. 
        Os três primeiros colocados totalizaram 23,44% de participação no ranking, sendo eles: Kinea Investimentos, com R$ 26,4 bilhões se mantendo como o primeiro do ranking, a Bluemacaw Gestora, com R$ 16,19 bilhões. 
        Já o terceiro lugar do ranking pertence à XP Vista Asset Management, com R$ 14,89 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=manager&criteria=capitalization&timeRange=yearly',
  },

  {
    name: 'Ranking de Gestores por Número de Fundos Negociados em 2023',
    details: {
      title: 'Ranking de Gestores por Número de Fundos Negociados em 2023',
      texts: [
        `O Ranking de Gestores por Número de Fundos Negociados apresentou, em 2023, o total de 456 fundos imobiliários distribuídos entre 183 ranqueados. 
        Os três maiores gestores dos fundos imobiliários compõem juntos 9,85% de participação no ranking. São eles: Hedge Investments Real Estate Gestão de Recursos, com a gestão de 16 fundos (participação de 3,51%). 
        Em seguida vem o XP Vista Asset Management, com 15 fundos geridos e participação de 3,29%. E, em terceiro lugar aparece a Kinea Investimentos, gestora de 13 fundos (participação de 2,85%).`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=manager&criteria=negotiatedefundnumber&timeRange=yearly',
  },

  {
    name: 'Ranking de FII por Patrimônio Líquido em 2023',
    details: {
      title: 'Ranking de FII por Patrimônio Líquido em 2023',
      texts: [
        `O Ranking de FII por Patrimônio Líquido (PL) exibiu a cifra de R$ 11,23 bilhões no ano de 2023, valor este distribuído entre os 64 fundos que entraram em operação no respectivo ano. Em relação a 2021, o PL caiu 21,90%. 
        O fundo imobiliário Kinea Hedge Fund ficou em primeiro lugar, com um patrimônio líquido de R$ 1,01 bilhões, resultando em uma participação de 9,00% no ranking. 
        Em segundo lugar ficou o Kinea Oportunidades Real Estate, com participação de 8,57% e PL de R$ 962,50 milhões. E, em terceiro lugar, esteve o FIAGRO Az Quest Luna com participação de 4,80% e PL de R$ 538,93 milhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=stock&criteria=amount&timeRange=yearly',
  },

  {
    name: 'Ranking de Administradores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Administradores por Patrimônio Líquido',
      texts: [
        `O Ranking de Administradores por Patrimônio Líquido (PL) apresentou a cifra de R$ 309,3 bilhões em fundos imobiliários com o total de 57 participantes. Houve uma alta de 21,7% em relação ao PL de dezembro de 2022. 
        O BTG Pactual Serviços Financeiros DTVM ficou em primeiro lugar com, participação de 21,37% no ranking, dado o PL de R$ 66,00 bilhões. 
        Em segundo lugar, BRL Trust DTVM com PL de R$ 42,73 bilhões e participação de 13,84%. E, em terceiro lugar, Intrag DTVM, com participação de 10,33%, visto o PL de R$ 31,89 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=admin&criteria=networthallmarket&timeRange=consolidated',
  },

  {
    name: 'Ranking de Administradores por Número de Fundos',
    details: {
      title: 'Ranking de Administradores por Número de Fundos',
      texts: [
        `O Ranking de Administradores por Número de fundos imobiliários apresentou o total de 925 fundos ativos com 57 ranqueados. A média por participante foi de 16,23 fundos. 
        Os três maiores administradores somam juntos o total de 35,64% de participação no ranking, estando em primeiro lugar o BTG Pactual Serviços Financeiros DTVM com 152 fundos administrados. 
        Em segundo lugar está a BRL Trust DTVM, com 115 fundos, enquanto o terceiro lugar ficou com a Vórtx, com 63 fundos administrados.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=admin&criteria=fundnumber&timeRange=consolidated',
  },

  {
    name: 'Ranking de Auditores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Auditores por Patrimônio Líquido',
      texts: [
        `O Ranking de Auditores por montante de patrimônio líquido (PL) dos fundos imobiliários ativos registrou, em dezembro de 2023, R$ 278,77 bilhões e um total de 35 empresas ranqueadas. Comparado com dezembro de 2022, observa-se um aumento de 19,7% no PL. Os três primeiros colocados concentraram 68,21% do total do ranking. 
        No primeiro lugar ficou a empresa Ernst & Young, com R$ 81,20 bilhões de PL, gerando 29,13% de participação no ranking. 
        Em seguida, PwC, somando 23,31% de participação no ranking, com PL de R$ 64,97 bilhões. E, em terceiro lugar, KPMG, com R$ 43,13 bilhões de PL, somando 15,47% de participação no ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=auditor&criteria=networthallmarket&timeRange=consolidated',
  },

  {
    name: 'Ranking de Auditores por Número de Fundos',
    details: {
      title: 'Ranking de Auditores por Número de Fundos',
      texts: [
        `O Ranking de Auditores por Número de Fundos Imobiliários contabilizou 718 fundos, com um total de 35 ranqueados em dezembro de 2023. A média em 2022 por participante ficou em 20,51 fundos. 
        As 3 primeiras colocadas no ranking representaram 46,09% de participação e são: Ernst & Young, em primeiro lugar, com 135 fundos e participação de 18,80%; Next Auditores, em seguida, com 99 fundos e participação de 13,79%; KPMG, em terceiro lugar, com participação de 13,51% e um total de 97 fundos.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=auditor&criteria=fundnumber&timeRange=consolidated',
  },

  {
    name: 'Ranking de Gestores por Patrimônio Líquido',
    details: {
      title: 'Ranking de Gestores por Patrimônio Líquido',
      texts: [
        `O Ranking de Gestores FII por PL registrou, em dezembro de 2023, o acumulado de R$ 298,56 bilhões em PL, com um total de 260 ranqueados. 
        Os três primeiros colocados somaram juntos o equivalente a 20,93% de participação no ranking, sendo eles: Kinea Investimentos, BTG Pactual Gestora de Recursos e XP Vista Asset Management. 
        Comparado com dezembro de 2022, o PL computado para o respectivo ranking cresceu 22,91%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=manager&criteria=networthallmarket&timeRange=consolidated',
  },

  {
    name: 'Ranking de Gestores por Número de Fundos',
    details: {
      title: 'Ranking de Gestores por Número de Fundos',
      texts: [
        `O Ranking de Gestores por Número de Fundos Imobiliários atingiu, no final de 2023, 880 fundos ativos, uma alta de 15,64% comparado com dezembro de 2022, e apresentou 258 gestores ranqueados. 
        Os três primeiros colocados somaram participação de 11,07%, sendo eles: Genial Gestão, com participação de 4,81%, contabilizando 42 fundos; REAG Administradora de Recursos, com 30 fundos e participação de 3,44%; BRL Trust DTVM, compondo 25 fundos e com participação de 2,86% no ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FII&year=2023&ranking=manager&criteria=fundnumber&timeRange=consolidated',
  },
];
