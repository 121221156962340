import { Box, Paper, Typography } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';

import { convertValue } from '../../../../utils/number';

export default function ChartStatistics({
  classes,
  title,
  type,
  xaxisCategories,
  data,
  formatType,
  yaxisMax,
}) {
  const state = {
    options: {
      chart: {
        id: `${title}-${type}`,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      noData: {
        text: 'Nenhum dado encontrado',
      },
      colors: ['#FD8647', '#ED500E', '#F1BF69'],
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      xaxis: {
        categories: xaxisCategories,
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        max: yaxisMax ? yaxisMax + 1 : undefined,
      },
      tooltip: {
        style: {
          fontSize: '0.875rem',
        },
        y: {
          title: {
            formatter: (seriesName) =>
              title.includes('Montante') ? undefined : seriesName,
          },
          formatter: function (value) {
            if (formatType === 'integer') {
              return value;
            }
            return convertValue(
              value,
              null,
              formatType === 'currency' ? true : false,
              formatType,
            );
          },
        },
      },
    },
    series: data,
  };
  return (
    <Paper elevation={0}>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box className={classes.boxGraph}>
        <ReactApexChart
          type={type}
          options={state.options}
          series={state.series}
          width="100%"
          height="160"
        />
      </Box>
    </Paper>
  );
}
