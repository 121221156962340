import { Box } from '@material-ui/core';
import { Button as ButtonMui } from '@material-ui/core';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min-content;
  width: 100%;
`;

export const StyledChart = styled(Chart)`
  width: 100%;

  .apexcharts-canvas {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;

export const Button = styled(ButtonMui)`
  min-width: 200px;

  @media screen and (max-width: 1550px) {
    min-width: 50px;
  }
`;
