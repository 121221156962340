// Material ui components
import {
  Box,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import LensIcon from '@material-ui/icons/Lens';

// Util
import { toCurrency } from '../../utils/number';

// Styles
const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    width: 535,
    marginLeft: 4,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  lensIcon: {
    fontSize: '0.6rem',
    color: '#FFCDA0',
    marginRight: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.common.black,
  },
  title: {
    fontSize: '0.875rem',
  },
  serie: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function PanelFiiLiabilityQuote({
  quoteSerie,
  quoteCompetence,
  quoteNetWorth,
  quoteEquityValue,
  quoteTicker,
  emissionsInMonth,
  lastDistributed,
}) {
  const classes = useStyles();
  const hasNumber = (myString) => /\d/.test(myString);

  return (
    <Box my={2} mx={1} display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        height={240}
        className={classes.box}
      />
      <Paper elevation={6} square className={classes.paper}>
        <Box pb={2}>
          <Grid container>
            <Grid item xs container alignItems="center">
              <Typography variant="subtitle1" className={classes.typography}>
                Série:
              </Typography>
              <Chip
                color="secondary"
                size="small"
                label={quoteSerie ?? 'N/D'}
                className={classes.serie}
              />
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Chip
                label={quoteCompetence ?? 'N/D'}
                color="secondary"
                className={classes.serie}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography className={classes.title}>Patrimônio Líquido</Typography>
        <Divider className={classes.divider} />
        <Box py={2}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              container
              alignItems="baseline"
              direction="column">
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="subtitle1" className={classes.typography}>
                  Valor Patrimonial
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.series}>
                {hasNumber(quoteEquityValue)
                  ? `${toCurrency(quoteEquityValue)}`
                  : quoteEquityValue}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              alignItems="baseline"
              direction="column">
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="subtitle1" className={classes.typography}>
                  Patrimônio Líquido
                </Typography>
              </Box>
              <Typography variant="subtitle2">
                {hasNumber(quoteNetWorth)
                  ? toCurrency(quoteNetWorth)
                  : quoteNetWorth}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider className={classes.divider} />
        <Box py={2}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              container
              alignItems="baseline"
              direction="column">
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="subtitle1" className={classes.typography}>
                  Ticker
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.series}>
                {quoteTicker}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider className={classes.divider} />
        <Box py={2}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              container
              alignItems="baseline"
              direction="column">
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="subtitle1" className={classes.typography}>
                  Emissões no Mês
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.series}>
                {hasNumber(emissionsInMonth)
                  ? `${toCurrency(emissionsInMonth)}`
                  : emissionsInMonth}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              alignItems="baseline"
              direction="column">
              <Box display="flex" alignItems="center">
                <LensIcon className={classes.lensIcon} titleAccess="ponto" />
                <Typography variant="subtitle1" className={classes.typography}>
                  Distribuições no mês
                </Typography>
              </Box>
              <Typography variant="subtitle2">
                {hasNumber(lastDistributed)
                  ? `${toCurrency(lastDistributed)} / cota`
                  : lastDistributed}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
