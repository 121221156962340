import TabMui from '@mui/material/Tab';
import TabsMui from '@mui/material/Tabs';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 37px;
  font-weight: 700;
  padding-top: calc(50px - clamp(0px, 1vw, 50px));
  display: flex;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 32px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 32px;

    svg {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
  }
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-top: 24px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 30px;
  padding: 0;
  @media only screen and (max-width: 600px) {
    padding: 24px;
    margin-top: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px rgba(29, 29, 29, 0.28) solid;
`;

export const Tab = styled(TabMui)`
  &.MuiTab-root {
    text-transform: none;
    color: #282828;

    &.Mui-selected {
      color: #282828;
    }
  }
`;

export const Tabs = styled(TabsMui)`
  &.MuiTabs-root {
    & > .MuiTabs-scroller {
      position: static;

      .MuiTabs-indicator {
        background-color: #333333;
        bottom: -2px;
      }
      @media only screen and (max-width: 500px) {
        overflow-x: auto !important;
        .MuiTabs-flexContainer {
          overflow: scroll;
        }
      }
    }

    .Mui-selected {
      background-color: transparent;
    }
  }
`;
