import { Step, StepLabel, Stepper, Theme, useMediaQuery } from '@mui/material';

type Step = {
  label: string;
  stepNo: number;
  status: boolean;
  componentWhenDone: JSX.Element;
};

export function CheckStep({
  steps,
  currStep,
}: {
  steps: Step[];
  currStep: Step;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const currCheckStepIndex = steps?.findIndex(
    (step) => step.stepNo === currStep.stepNo,
  );

  return (
    <Stepper
      activeStep={
        currCheckStepIndex !== steps.length - 1
          ? currCheckStepIndex
          : currCheckStepIndex + 1
      }
      sx={{
        maxWidth: 930,
        margin: '0 auto',
      }}
      alternativeLabel={isMobile}>
      {steps?.map((step) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
