import * as Switch from './styles';

interface VideosSwitchProps {
  selected: string;
  select: (value: string) => void;
}

export default function VideosSwitch({ selected, select }: VideosSwitchProps) {
  const options = [
    { label: 'Relacionados', value: 'related' },
    {
      label: 'Todos os vídeos',
      value: 'all',
    },
  ];

  return (
    <Switch.Container>
      {options.map((option) => (
        <Switch.Option
          key={option.value}
          onClick={() => select(option.value)}
          className={selected === option.value ? 'active' : ''}>
          {option.label}
        </Switch.Option>
      ))}
    </Switch.Container>
  );
}
