import {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { deleteService } from 'src/services/institution';
import { getServicesByUserAndType } from 'src/services/marketplace';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { Service } from 'src/types/marketplace';
import { Professional } from 'src/types/professional';
import { truncateText } from 'src/utils/string';

import CreateServiceModal from '../CreateServiceModal';
import EditServiceModal from '../EditServiceModal';

type Props = {
  institution?: Institution;
  type: 'Serviço' | 'Projeto';
  openCreateService: boolean;
  setOpenCreateService: (value: boolean) => void;
};

type Action = {
  id: number | null;
  status: string | null;
  professional: Professional | null;
};

export function ServicesTable({
  institution,
  type,
  openCreateService,
  setOpenCreateService,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [services, setServices] = useState<Service[]>([]);
  const [total, setTotal] = useState(0);
  const initialAction = { id: null, status: null, professional: null };
  const [action, setAction] = useState<Action>(initialAction);
  const { auth } = useAuthContext();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [openEditService, setOpenEditService] = useState(false);
  const [selectedService, setSelectedService] = useState<Service>();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const deleteMutation = useMutation<any, any, { id: number }>(
    ({ id }) => deleteService(id),
    {
      onSuccess: () => {
        setOpenConfirmation(false);
        handleRefresh();
        setSelectedService(undefined);
        dispatch(
          setBannerMessage({
            message: `${type} excluido com sucesso`,
            type: 'success',
            autoclose: true,
          }),
        );
      },
      onError: () => {
        dispatch(
          setBannerMessage({
            message: `Falha ao excluir o ${type?.toLowerCase()}`,
            type: 'error',
            autoclose: true,
          }),
        );
      },
    },
  );

  const { refetch, isLoading, isFetching, fetchNextPage } = useInfiniteQuery(
    ['marketplace', type],
    ({ pageParam = 0 }) => getServicesByUserAndType(type, pageParam),
    {
      onSuccess(data) {
        const page = data.pages.at(-1);
        setTotal(page?.meta?.pagination?.total);
        if (page?.meta?.pagination?.page === 1) {
          setServices(page.data);
        } else {
          setServices(data?.pages.flatMap((page: any) => page.data) ?? []);
        }
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        return lastPage?.data?.length !== 0 ? nextPage : undefined;
      },
      enabled: !!auth.user,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const totalFetched = services?.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < total
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, total],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const getColorStatus = (publishedAt: any) => {
    if (publishedAt) return 'success';
    return 'info';
  };

  const handleEdit = (service: Service) => {
    setSelectedService(service);
    setIsUpdate(true);
    setOpenEditService(true);
  };

  const handleOpenConfimartion = (service: Service) => {
    setSelectedService(service);
    setOpenConfirmation(true);
  };

  const handleClick = (service: Service) => {
    history.push(
      `/marketplace/${
        service.type === 'Serviço' ? 'servico' : 'projeto'
      }/${service.slug}/${service.id}`,
    );
  };

  const handleDelete = () => {
    if (selectedService) {
      deleteMutation.mutateAsync({ id: selectedService?.id });
    }
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: `${type}s cadastrados`,
        size: 70,
        Cell: ({ cell }: { cell: any }) => (
          <Box alignItems={'center'} display={'flex'} gap={2}>
            <Box>
              <Link
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(cell.row.original)}>
                {cell.row.original?.title}
              </Link>
              <Typography fontSize={14} fontWeight={400} color={'#1D1D1D'}>
                {truncateText(cell.row.original.description, 50)}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number => {
          const statusA = rowA.original.publishedAt;
          const statusB = rowB.original.publishedAt;
          if (!statusA) return 1;
          if (!statusB) return -1;
          return 0;
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Chip
              sx={(theme) => ({
                minWidth: 76,
                width: 'fit-content',
                height: 22,
                fontSize: '12px',
                color: theme.palette.primary.contrastText,
                borderRadius: '2px',
                fontWeight: 400,
                padding: 0,
              })}
              color={getColorStatus(cell.row.original.publishedAt)}
              label={cell.row.original.publishedAt ? 'Ativo' : 'Inativo'}
            />
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100%">
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'center',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(cell.row.original)}>
              Editar
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'center',
                letterSpacing: 'normal',
              }}
              onClick={() => handleOpenConfimartion(cell.row.original)}>
              Excluir
            </Button>
          </Box>
        ),
      },
    ],
    [isLoading, services],
  );

  const handleRefresh = () =>
    refetch({
      refetchPage: () => {
        setServices([]);
        return true;
      },
    });

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: services,
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false,
        },
      ],
    },
    layoutMode: 'grid',
    localization: {
      ...MRT_Localization_PT_BR,
      ...MaterialTableDefaultProps.localization,
      noRecordsToDisplay:
        type === 'Serviço'
          ? 'Nenhum serviço cadastrado'
          : 'Nenhum projeto cadastrado',
    },
    muiTableContainerProps: {
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
      ref: tableContainerRef,
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    state: {
      isLoading: isLoading,
    },
    enableStickyHeader: true,
    enableSorting: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: '100%',
        padding: '16px 0 40px',
      },
    },
  });

  return (
    <Container
      disableGutters
      sx={(theme) => ({ padding: theme.spacing(2, 0) })}>
      <ConfirmDialog
        open={
          action?.status
            ? ['Rejeitado', 'Cancelado'].includes(action?.status)
            : false
        }
        title={
          action.status === 'Rejeitado'
            ? `Tem certeza que deseja rejeitar a solicitação de “${action.professional?.fullName}”?`
            : `Tem certeza que deseja remover o usuário “${action.professional?.fullName}”?`
        }
        subTitle={
          action.status === 'Rejeitado'
            ? `Ao rejeitar a solicitação de ${action.professional?.fullName}, ele não irá fazer parte da instituição`
            : 'Ao remover o usuário, a solicitação será excluída e caso deseje vincular novamente o profissional, será necessário fazer uma nova solicitação'
        }
        onClose={() => {
          setAction(initialAction);
        }}
        submitAction={() => {}}
        hasActionButton={Boolean(action?.id)}
      />
      <Grid container>
        <MaterialTable table={table} />
      </Grid>
      {openEditService && (
        <EditServiceModal
          isOpen={openEditService}
          handleClose={(changed: boolean) => {
            setOpenEditService(false);
            if (changed) {
              handleRefresh();
            }
          }}
          institution={institution}
          isUpdate={isUpdate}
          service={selectedService}
          type={type}
        />
      )}
      {openConfirmation && (
        <ConfirmDialog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          submitAction={handleDelete}
          hasActionButton={true}
          title={`Tem certeza que deseja excluir o ${type.toLowerCase()} ${selectedService?.title}?`}
          subTitle=""
        />
      )}
      {openCreateService && (
        <CreateServiceModal
          isOpen={openCreateService}
          handleClose={() => {
            setOpenCreateService(false);
          }}
          institution={institution}
          type={type}
          handleRefresh={handleRefresh}
        />
      )}
    </Container>
  );
}
