// Typechecking
// Material ui components
import { Box, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
// Material ui icons
import LensIcon from '@material-ui/icons/Lens';
import PropTypes from 'prop-types';

// Global components
import GeneralDataItemConditional from '../GeneralDataItemConditional';
// Styles
const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    width: 365,
    marginLeft: 3,
    padding: theme.spacing(3, 1, 1),
  },
  lensIcon: {
    fontSize: '0.6rem',
    color: '#FFCDA0',
    marginRight: theme.spacing(1),
  },
  series: {
    paddingLeft: theme.spacing(2),
  },
  benchmark: {
    paddingLeft: theme.spacing(2),
    height: 40,
  },
}));

export default function PanelQuotes({ series, benchmark, hasRiskRating }) {
  const classes = useStyles();

  return (
    <Box my={2} mx={1} display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        height={55}
        className={classes.box}
      />
      <Paper elevation={6} square className={classes.paper}>
        <Box pb={2} display="flex">
          <Box pr={8}>
            <Box display="flex" alignItems="center">
              <LensIcon className={classes.lensIcon} titleAccess="ponto" />
              <Typography variant="subtitle1" className={classes.typography}>
                Série
              </Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.series}>
              {series}
            </Typography>
          </Box>
          <Box pr={5}>
            <Box display="flex" alignItems="center">
              <LensIcon className={classes.lensIcon} titleAccess="ponto" />
              <Typography variant="subtitle1" className={classes.typography}>
                Benchmark
              </Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.benchmark}>
              {benchmark}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <GeneralDataItemConditional
          title="Classificação de Risco"
          condition={hasRiskRating}
          justifyContent="center"
        />
      </Paper>
    </Box>
  );
}

PanelQuotes.prototype = {
  series: PropTypes.string,
  hasRiskRating: PropTypes.bool,
  benchmark: PropTypes.string,
};
