import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Auth } from 'src/context/context-type';
import { Event } from 'src/types/event';

declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    disabled?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    disabled: true;
  }
}

export type EventSubscribeButtonProps = ButtonProps & {
  typePosition?: 'header' | 'body';
  event?: Event;
  auth?: Auth;
};

export const EventSubscribeButton = styled(Button)<EventSubscribeButtonProps>(
  ({ theme, typePosition, event, auth }) => ({
    ...(typePosition === 'header'
      ? {
          position: 'absolute',
          top: '24px',
          right: '24px',
        }
      : {}),
    display:
      (!event?.subscribed && !event?.isSubscriptionOpen) ||
      (event?.subscribed && event?.state === 'ongoing') ||
      !event.allowSubscriptions
        ? 'none'
        : 'normal',
    backgroundColor:
      (event?.subscribed && !event?.isLive) || !auth?.isLoggedIn
        ? theme.palette.disabled.dark
        : theme.palette.primary.main,
    color: 'white',
    padding: '16px',
    textTransform: 'none',
    cursor:
      (!event?.subscribed && event?.isSubscriptionOpen) ||
      (event?.subscribed && event?.isLive)
        ? 'pointer'
        : 'auto',
    '&:hover': {
      backgroundColor:
        (event?.subscribed && !event?.isLive) || !auth?.isLoggedIn
          ? theme.palette.disabled.dark
          : theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: theme.palette.disabled.dark,
      pointerEvents: 'auto',
    },
    '&.Mui-disabled:hover': {
      backgroundColor: theme.palette.disabled.dark,
    },
    '& > p': {
      fontSize: '18px',
      fontWeight: 600,
    },
  }),
) as React.ComponentType<EventSubscribeButtonProps>;
