// material ui components
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// styles
const useStyles = makeStyles((theme) => ({
  typography: {
    marginLeft: theme.spacing(2),
  },
  link: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function ArticleRelatedSubjects({
  relatedInstitutions,
  relatedProfessionals,
  relatedOperations,
}) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item container>
      <Grid item xs>
        <List>
          {relatedInstitutions &&
            relatedInstitutions.map(({ id, fantasyName: name, slug }) => (
              <ListItem
                key={id}
                className={classes.link}
                divider
                disableGutters
                button
                onClick={() => {
                  history.push(`/instituicao/${slug}/${id}`);
                }}>
                <Box display="flex" alignItems="center">
                  <Chip
                    variant="default"
                    color="secondary"
                    size="small"
                    label="Instituição"
                  />
                  <Typography className={classes.typography}>
                    {name ? name : ''}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          {relatedProfessionals &&
            relatedProfessionals.map(({ id, name, surname, slug }) => (
              <ListItem
                key={id}
                className={classes.link}
                divider
                disableGutters
                button
                onClick={() => {
                  history.push(`/professional/${slug}/${id}`);
                }}>
                <Box display="flex" alignItems="center">
                  <Chip
                    variant="default"
                    color="secondary"
                    size="small"
                    label="Profissionais"
                  />
                  <Typography className={classes.typography}>
                    {name ? `${name} ${surname} ` : ''}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          {relatedOperations &&
            relatedOperations.map(({ id, name, slug, market, hashId }) => (
              <ListItem
                key={id}
                className={classes.link}
                divider
                disableGutters
                button
                onClick={() => {
                  history.push(
                    `/operacao/${market.name.toLowerCase()}/${slug}/${hashId}`,
                  );
                }}>
                <Box display="flex" alignItems="center">
                  <Chip
                    variant="default"
                    color="secondary"
                    size="small"
                    label="Operações"
                  />
                  <Typography className={classes.typography}>
                    {name ? name : ''}
                  </Typography>
                </Box>
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}
