import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ArticlesSelectCustom } from './ArticlesSelectCustom';
import { InputSearch } from '../../../components/InputSearch';
import { useAuthContext } from '../../../context/AuthContextProvider';
import {
  getArticlesFilters,
  getOperationsRelatedArticles,
} from '../../../services/article';
import {
  getCategories,
  getInstitutionsRelatedArticles,
  getMarkets,
} from '../../../services/institution';
import { getProfessionalsRelatedArticles } from '../../../services/professional';

export default function HeaderArticles({
  classes,
  sort,
  setFilters,
  setSort,
  filters,
}) {
  const { auth } = useAuthContext();
  const history = useHistory();
  const institutionName = new URL(window.location).searchParams.get(
    'instituicao',
  );
  const institutionId = new URL(window.location).searchParams.get('id');
  const [categories, setCategories] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
    categories: [],
    markets: [],
    relatedInstitutions:
      institutionName && institutionId ? [institutionName] : [],
    relatedProfessionals: [],
    relatedOperations: [],
  });

  const [institutions, setInstitutions] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedFilterChip, setSelectedFilterChip] = useState({});
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const hasPermission =
    auth?.user?.subscription?.plan?.permissions.artigos_filtros;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { data: chipFilters } = useQuery('chipFilters', () =>
    getArticlesFilters(),
  );

  const { isLoading: marketsLoading } = useQuery('markets', getMarkets, {
    onSuccess: ({ data }) => {
      setMarkets(data.map((market) => market.name));
    },
  });

  const { isLoading: categoriesLoading } = useQuery(
    'categories',
    getCategories,
    {
      onSuccess: ({ data }) => {
        setCategories(data.map((category) => category.title));
      },
    },
  );

  const { isLoading: institutionsLoading } = useQuery(
    'institutions',
    getInstitutionsRelatedArticles,
    {
      onSuccess: ({ data }) => {
        setInstitutions(data.map((institution) => institution.razaoSocial));
      },
    },
  );

  const { isLoading: professionalsLoading } = useQuery(
    'professionals',
    getProfessionalsRelatedArticles,
    {
      onSuccess: ({ data }) => {
        setProfessionals(data.map(({ name, surname }) => `${name} ${surname}`));
      },
    },
  );

  const { isLoading: operationsLoading } = useQuery(
    'operations',
    getOperationsRelatedArticles,
    {
      onSuccess: ({ data }) => {
        setOperations(
          data.flatMap((article) =>
            article.relatedOperations.map((operation) => operation.name.trim()),
          ),
        );
      },
    },
  );

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (selectedFilterChip?.id) {
      setSelectedFilterChip({});
    }
    setSelectedFilters({
      ...selectedFilters,
      [name]: value,
    });
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (selectedFilterChip?.id) {
      setSelectedFilterChip({});
    }
    setSelectedFilters({
      ...selectedFilters,
      search: value,
    });
    setFilters({
      ...filters,
      keyword: value.normalize('NFD').replace(/\p{Diacritic}/gu, ''),
    });
  };

  const handleSelectChip = (filter) => {
    if (filter?.id === selectedFilterChip?.id) {
      setSelectedFilters({
        search: '',
        categories: [],
        markets: [],
        relatedInstitutions: [],
        relatedProfessionals: [],
        relatedOperations: [],
      });
      setDateRange({ start: null, end: null });
      setFilters({
        keyword: '',
        categories: [],
        markets: [],
        relatedInstitutions: [],
        relatedProfessionals: [],
        relatedOperations: [],
      });
      setSelectedFilterChip({});
    } else {
      setSelectedFilters({
        search: filter?.keyword ?? '',
        categories: filter?.categories?.map((category) => category.title) ?? [],
        markets: filter?.markets?.map((market) => market.name) ?? [],
        relatedInstitutions:
          filter?.relatedInstitutions?.map(
            (institution) => institution.razaoSocial,
          ) ?? [],
        relatedProfessionals:
          filter?.relatedProfessionals?.map(
            (professional) => professional.fullName,
          ) ?? [],
        relatedOperations:
          filter?.relatedOperations?.map((operation) =>
            operation.name.trim(),
          ) ?? [],
      });
      setDateRange({
        start: filter.startDate ?? null,
        end: filter.endDate ?? null,
      });
      setFilters({
        keyword: filter?.keyword ?? '',
        categories: filter?.categories?.map((category) => category.title) ?? [],
        markets: filter?.markets?.map((market) => market.name) ?? [],
        relatedInstitutions:
          filter?.relatedInstitutions?.map(
            (institution) => institution.razaoSocial,
          ) ?? [],
        relatedProfessionals:
          filter?.relatedProfessionals?.map(
            (professional) => professional.fullName,
          ) ?? [],
        relatedOperations:
          filter?.relatedOperations?.map((operation) =>
            operation.name.trim(),
          ) ?? [],
        dateRange: {
          start: filter.startDate ?? null,
          end: filter.endDate ?? null,
        },
      });
      setSelectedFilterChip(filter);
    }
  };

  const handleChangeStartRangeDate = (event) => {
    if (event) {
      setDateRange({
        ...dateRange,
        start:
          event && moment(event).format() !== dateRange.start
            ? moment(event).format()
            : null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...dateRange,
          start:
            event && moment(event).format() !== dateRange.start
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setDateRange({
        ...dateRange,
        start: null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...dateRange,
          start: null,
        },
      });
      if (selectedFilterChip?.id) {
        setSelectedFilterChip({});
      }
    }
  };

  const handleChangeEndRangeDate = (event) => {
    if (event) {
      setDateRange({
        ...dateRange,
        end:
          event && moment(event).format() !== dateRange.end
            ? moment(event).format()
            : null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...dateRange,
          end:
            event && moment(event).format() !== dateRange.end
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setDateRange({
        ...dateRange,
        end: null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...dateRange,
          end: null,
        },
      });
      if (selectedFilterChip?.id) {
        setSelectedFilterChip({});
      }
    }
  };

  useEffect(() => {
    const state = history.location.state;
    if (state) {
      const handleStateFilters = (filters) => ({
        ...filters,
        markets: state.market ?? [],
        categories: state.category ?? [],
      });

      setSelectedFilters(handleStateFilters);
      setFilters(handleStateFilters);
    }
  }, []);

  return (
    <Box padding="2rem 0rem 1rem">
      <Grid container className={classes.container}>
        <Grid container className={classes.chipContainer}>
          {chipFilters?.map((filter) => (
            <Grid item key={`${filter.id}-chip`} className={classes.chipGrid}>
              <Chip
                label={filter.name}
                color={
                  filter?.id === selectedFilterChip?.id
                    ? 'secondary'
                    : 'primary'
                }
                onClick={() => handleSelectChip(filter)}
                variant="outlined"
                disabled={!hasPermission}
                className={
                  filter.id === selectedFilterChip?.id
                    ? classes.chipSelected
                    : classes.chip
                }
              />
            </Grid>
          ))}
        </Grid>
        <InputSearch
          classes={classes}
          label="Pesquise por palavras-chave"
          handleChange={handleChange}
          value={selectedFilters.search}
          md={12}
          disabled={!hasPermission}
        />
        <ArticlesSelectCustom
          name="categories"
          classes={classes}
          handleChange={handleFilterChange}
          options={categories}
          loading={marketsLoading}
          label="Categorias"
          values={selectedFilters.categories}
          md={2}
          disabled={!hasPermission}
        />
        <ArticlesSelectCustom
          name="markets"
          classes={classes}
          handleChange={handleFilterChange}
          options={markets}
          loading={categoriesLoading}
          label="Mercados"
          values={selectedFilters.markets}
          md={2}
          disabled={!hasPermission}
        />
        <ArticlesSelectCustom
          name="relatedInstitutions"
          classes={classes}
          handleChange={handleFilterChange}
          options={institutions}
          loading={institutionsLoading}
          label="Instituições Relacionadas"
          values={selectedFilters.relatedInstitutions}
          disabled={!hasPermission}
        />
        <ArticlesSelectCustom
          name="relatedProfessionals"
          classes={classes}
          handleChange={handleFilterChange}
          options={professionals}
          loading={professionalsLoading}
          label="Profissionais Relacionados"
          values={selectedFilters.relatedProfessionals}
          disabled={!hasPermission}
        />
        <Grid item xs={12} md={2}>
          <ListItem className={classes.listItem}>
            <FormControl className={classes.itemInput}>
              <InputLabel id={'order-select'}>Ordernar por</InputLabel>
              <Select
                disabled={!hasPermission}
                labelId="order-select"
                value={sort}
                onChange={(e) => setSort(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}>
                <MenuItem value="desc">Mais recentes</MenuItem>
                <MenuItem value="asc">Mais antigos</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItem className={classes.listItem}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              disabled={!hasPermission}
              autoOk
              clearable
              invalidDateMessage="Formato Inválido"
              maxDateMessage="Data maior do que data atual"
              variant="inline"
              name="startDate"
              format="DD/MM/yyyy"
              label="Data publicação início"
              InputProps={{
                disabled: true,
                endAdornment: dateRange.start && (
                  <IconButton
                    size="small"
                    onClick={() => handleChangeStartRangeDate(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={
                dateRange.start && {
                  position: 'start',
                }
              }
              value={dateRange.start}
              onChange={handleChangeStartRangeDate}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItem className={classes.listItem}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              disabled={!hasPermission}
              autoOk
              clearable
              invalidDateMessage="Formato Inválido"
              maxDateMessage="Data maior do que data atual"
              variant="inline"
              name="endDate"
              format="DD/MM/yyyy"
              label="Data publicação fim"
              InputProps={{
                disabled: true,
                endAdornment: dateRange.end && (
                  <IconButton
                    size="small"
                    onClick={() => handleChangeEndRangeDate(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={
                dateRange.end && {
                  position: 'start',
                }
              }
              value={dateRange.end}
              onChange={handleChangeEndRangeDate}
            />
          </ListItem>
        </Grid>
        <ArticlesSelectCustom
          name="relatedOperations"
          classes={classes}
          handleChange={handleFilterChange}
          options={operations}
          loading={operationsLoading}
          label="Operações Relacionadas"
          values={selectedFilters.relatedOperations}
          disabled={!hasPermission}
        />
      </Grid>
      <Box display="flex" justifyContent="end">
        <Button
          variant="contained"
          color="secondary"
          fullWidth={isSmall}
          onClick={() => history.push('/publicar/artigos')}>
          Publique na Uqbar
        </Button>
      </Box>
    </Box>
  );
}
