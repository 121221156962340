import React, { useEffect, useState } from 'react';

import { fetchEventSource } from '@microsoft/fetch-event-source';
import {
  Close as CloseIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import NoItemImage from 'src/assets/imgs/notification/no-items.svg';
import { Notification } from 'src/context/context-type';

import * as S from './styles';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { markAllAsRead, markAsRead } from '../../../services/notifications';

const formatNotificationDate = (date: string) =>
  formatDistanceToNowStrict(parseISO(date), {
    addSuffix: true,
    locale: ptBr,
    roundingMethod: 'floor',
  });

const NotificationMenu = () => {
  const { reloadUser } = useAuthContext();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [abortController] = useState<AbortController>(new AbortController());

  const showNotifications = notifications.filter((n) => !n.isRead);

  const isRedirect = (notification: Notification) =>
    notification.redirectTo && notification.actionType === 'redirect';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (notification: Notification) => {
    if (isRedirect(notification)) {
      history.push(notification.redirectTo);
      markAsReadRequest.mutate(notification.id);
      handleClose();
    }
  };

  const markAsReadRequest = useMutation(
    (messageId: number) => markAsRead(messageId),
    {
      onSuccess: (data) => {
        setNotifications((prevState) => {
          return [...prevState.filter((n) => n.id !== data)];
        });
      },
    },
  );

  const markAllAsReadRequest = useMutation(markAllAsRead, {
    onSuccess: () => {
      setNotifications([]);
    },
  });

  const fetchNotifications = (jwt: string) => {
    return fetchEventSource(
      `${process.env.REACT_APP_API_URL}/notification/sse`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        openWhenHidden: true,
        keepalive: true,
        signal: abortController.signal,
        onmessage(message) {
          const value = JSON.parse(message.data);
          switch (message.event) {
            case 'me':
              reloadUser();
              break;
            case 'broadcast':
            case 'notification':
              addNotification({
                ...value,
                broadcast: message.event === 'broadcast',
              } as Notification);
              break;
          }
        },
        onclose() {
          abortController.abort();
        },
        onerror() {
          abortController.abort();
          throw new Error(); // hack to close the connection
        },
      },
    );
  };

  const addNotification = (notification: Notification) => {
    setNotifications((prevState) => {
      return [
        notification,
        ...prevState.filter((n) => n.id !== notification.id),
      ];
    });
  };

  useEffect(() => {
    void fetchNotifications(sessionStorage.getItem('@App:token') || '');
    return () => {
      setNotifications([]);
      abortController.abort();
    };
  }, []);

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'notification-list' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <Badge badgeContent={showNotifications.length} color="primary">
          <NotificationsIcon
            sx={{
              color: 'white',
            }}
          />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <S.Wrapper>
          <S.Header>
            <span>Notificações</span>
            <Button
              variant={'text'}
              size={'small'}
              disableRipple
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                minWidth: 160,
                flexWrap: 'nowrap',
                textTransform: 'none',
              }}
              onClick={() => markAllAsReadRequest.mutate()}>
              Marcar tudo como lido
            </Button>
          </S.Header>
          <Divider />
          {showNotifications.length > 0 ? (
            showNotifications.map((notification) => (
              <div key={notification.id}>
                <S.NotificationItem>
                  <Box
                    sx={
                      isRedirect(notification)
                        ? {
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }
                        : {}
                    }>
                    <S.NotificationContent>
                      {isRedirect(notification) ? (
                        <Link
                          onClick={() => handleNotificationClick(notification)}>
                          {notification.content}
                        </Link>
                      ) : (
                        <span>{notification.content}</span>
                      )}
                      {!notification.broadcast && (
                        <Tooltip
                          title="Excluir notificação"
                          arrow
                          placement={'left'}>
                          <IconButton
                            onClick={() =>
                              markAsReadRequest.mutate(notification.id)
                            }
                            size={'small'}
                            sx={{
                              alignItems: 'start',
                              padding: 0,
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple>
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </S.NotificationContent>
                    <S.NotificationDate>
                      {formatNotificationDate(notification.publishedAt)}
                    </S.NotificationDate>
                  </Box>
                </S.NotificationItem>
                <Divider />
              </div>
            ))
          ) : (
            <S.NoItemsWrapper>
              <img src={NoItemImage} />
              <span>Você não tem notificação a ser exibida</span>
            </S.NoItemsWrapper>
          )}
        </S.Wrapper>
      </Menu>
    </React.Fragment>
  );
};

export default NotificationMenu;
