import qs from 'qs';
import { AdvertisementPopUp } from 'src/types/advertisement-pop-up';

import { publicApi } from '../../api/api';

export const getBanners = async (notIds: any[], location: string) => {
  const query = qs.stringify(
    {
      notIds: notIds?.length ? notIds : undefined,
      location,
    },
    {
      encodeValuesOnly: true,
    },
  );
  return publicApi.get(`/advertisement/random?${query}`);
};

export const setBannerCount = async (banner: any) => {
  return publicApi.put(`/advertisements/${banner.id}`, {
    accessCounter: banner.accessCounter,
  });
};

export const getAdvertisementPopUp = async (): Promise<AdvertisementPopUp> => {
  const query = qs.stringify(
    {
      populate: ['CTAs', 'image', 'event'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get(`advertisement-pop-up?${query}`);
  return data;
};
