import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { checkSlug } from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';

import { List } from '../style';

function EditSlug({
  name,
  value,
  onChange,
  id,
  onBlur,
}: {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<any>) => void;
  onBlur: (e: FocusEvent<any>) => void;
  id: number;
}) {
  const dispatch = useDispatch();
  const [slugFeedback, setSlugFeedback] = useState<{
    status: 'error' | 'success' | null;
    message: string;
  }>({
    status: null,
    message: '',
  });
  const checkSlugMutation = useMutation(checkSlug, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        if (error?.response?.data?.error?.message?.includes('occurred')) {
          setSlugFeedback({
            status: 'error',
            message: error?.response?.data?.error?.details?.errors[0]?.message,
          });
        } else {
          setSlugFeedback({
            status: 'error',
            message: error?.response?.data?.error?.message,
          });
        }
      }
    },
    onSuccess: () => {
      setSlugFeedback({
        status: 'success',
        message: 'URL válida',
      });
    },
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    checkSlugMutation.mutate(e.target.value);
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 1000), []);

  const slug = new URL(`/profissional/${value}/${id}`, window.location.href)
    .href;

  return (
    <Box sx={{ padding: '32px 24px' }}>
      <Typography fontSize={14}>
        Se preferir, você pode personalizar a URL do seu perfil profissional.
      </Typography>
      <List>
        <li>
          Sua URL personalizada não deve conter: Espaços, símbolos ou caracteres
          especiais;
        </li>
        <li>Utilize apenas números, letras, hífens e letras minúsculas;</li>
      </List>
      <Typography fontSize={14}>
        Insira no campo abaixo o seu nome de usuário que irá compor a URL
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={1}
        sx={(theme) => ({
          alignItems: 'center',
          flexWrap: 'nowrap',
          [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
          },
        })}>
        <Grid item>
          <TextField
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e);
              handleChangeDebounced(e);
            }}
            error={slugFeedback.status === 'error'}
            color={slugFeedback.status === 'success' ? 'success' : 'primary'}
            focused={slugFeedback.status === 'success'}
            onBlur={onBlur}
            sx={{
              verticalAlign: 'baseline',
              width: 453,
              margin: '18px 0',
            }}
          />
        </Grid>
        <Grid item>
          {checkSlugMutation.isLoading ? (
            <CircularProgress size={20} color="success" />
          ) : (
            <Typography
              sx={(theme) => ({
                maxWidth: 460,
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                color:
                  slugFeedback.status === 'error'
                    ? theme.palette.error.main
                    : theme.palette.success.main,
              })}>
              {slugFeedback.status === 'error' ? (
                <CancelIcon sx={{ marginRight: '12px' }} />
              ) : slugFeedback.status === 'success' ? (
                <CheckCircleIcon sx={{ marginRight: '12px' }} />
              ) : (
                ''
              )}
              {slugFeedback.status ? slugFeedback.message : ''}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: '14px', fontWeight: 700, marginTop: 2 }}>
        Sua URL será:
      </Typography>

      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 700,
          marginTop: 1,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          gap: 3,
        }}>
        {slug}
        <Button
          variant={'contained'}
          size={'small'}
          onClick={() => {
            navigator.clipboard.writeText(slug);
            dispatch(
              setBannerMessage({
                message: 'Link copiado com sucesso',
                type: 'success',
                autoclose: true,
              }),
            );
          }}>
          Copiar Link
        </Button>
      </Typography>
    </Box>
  );
}

export default EditSlug;
