import {
  createContext,
  createRef,
  useContext,
  useImperativeHandle,
} from 'react';

import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import logo from '../assets/imgs/logo.svg';
import { If } from '../components/If';
import { validateWidget } from '../services/widget';
import { useQueryParams } from '../utils/hooks';

const useStyles = makeStyles((theme) => ({
  error: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
    minHeight: 400,
    '& img': {
      marginBottom: theme.spacing(3),
    },
    '& a': {
      marginTop: theme.spacing(3),
    },
  },
}));

const Context = createContext({
  widgetKey: undefined,
  resource: undefined,
  isLoadingWidget: false,
});

// used to outside react components
const EmbedContextRef = createRef();

export default function EmbedContextProvider({ children }) {
  const classes = useStyles();
  const { widgetKey, resource } = useParams();
  const [{ rawQuery }] = useQueryParams();

  const {
    isFetched,
    data,
    isError,
    isLoading: isLoadingWidget,
  } = useQuery(['widget', widgetKey, resource], validateWidget, {
    enabled: !!widgetKey,
    retry: false,
  });

  const contextValues = {
    widgetKey,
    resource,
    isLoadingWidget,
  };

  useImperativeHandle(EmbedContextRef, () => contextValues);

  return (
    <Context.Provider value={contextValues}>
      {!isFetched && <CircularProgress />}
      <If condition={isError}>
        <Box className={classes.error}>
          <img src={logo} alt="UQBAR" style={{ height: '50px' }} />
          <Typography variant="subtitle2">Acesso Negado</Typography>
          <Typography variant="body2">
            O site atual não está autorizado a exibir o {capitalize(resource)}{' '}
            Uqbar. Mas, não se preocupe. Você continua podendo acessar o{' '}
            {capitalize(resource)} na plataforma Uqbar.
          </Typography>
          <Button
            variant="outlined"
            type="outlined"
            color="secondary"
            href={`${process.env.REACT_APP_PUBLIC_URL}/${resource === 'rankings' ? 'rankings/dados' : resource}${rawQuery}`}
            target="_blank">
            Ver {capitalize(resource)} na plataforma Uqbar
          </Button>
        </Box>
      </If>
      <If condition={!isError && data}>{children}</If>
    </Context.Provider>
  );
}

function useEmbedContext() {
  return useContext(Context);
}

export { useEmbedContext, EmbedContextRef };
