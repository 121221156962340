import { ChartOptions } from 'chart.js';
//@ts-ignore
import ReactDOM from 'react-dom';
import { Profitability } from 'src/types/profitability';

import { TooltipContent } from './TooltipContent';

export function calculateLogarithmicRegression(points: Profitability[]) {
  const numberOfPoints = points.length;
  let sumLogX = 0;
  let sumY = 0;
  let sumLogXSquared = 0;
  let sumYMultipliedByLogX = 0;

  points.forEach((point) => {
    const logX = Math.log(point.duration);
    sumLogX += logX;
    sumY += point.taxa;
    sumLogXSquared += logX * logX;
    sumYMultipliedByLogX += point.taxa * logX;
  });

  const slope =
    (numberOfPoints * sumYMultipliedByLogX - sumY * sumLogX) /
    (numberOfPoints * sumLogXSquared - sumLogX * sumLogX);
  const yIntercept = (sumY - slope * sumLogX) / numberOfPoints;

  return { slope, yIntercept };
}

export function generateRegressionLine(
  data: Profitability[],
  slope: number,
  yIntercept: number,
) {
  const minX = Math.min(...data.map((point) => point.duration));
  const maxX = Math.max(...data.map((point) => point.duration));
  const step = (maxX - minX) / 100;
  return Array.from({ length: 101 }, (_, i) => {
    const x = minX + i * step;
    const y = slope * Math.log(x) + yIntercept;
    const closestPoint =
      data?.length > 0
        ? data.reduce((prev, curr) =>
            Math.abs(curr.duration - x) < Math.abs(prev.duration - x)
              ? curr
              : prev,
          )
        : undefined;
    return { x, y, id: closestPoint?.id ?? '' };
  });
}

export function createChartOptions(
  data: Profitability[],
): ChartOptions<'scatter'> {
  const options: ChartOptions<'scatter'> = {
    scales: {
      x: {
        grid: {
          color: '#E0E0E0',
          borderDash: [5, 5],
        },
        type: 'linear',
        position: 'bottom',
        title: {
          font: {
            size: 18,
            weight: 'bold',
          },
          display: true,
          text: 'Duration',
        },
      },
      y: {
        grid: {
          color: '#E0E0E0',
          borderDash: [5, 5],
        },
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Taxa',
          font: {
            size: 18,
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: ({ chart, tooltip }) => {
          let tooltipEl = chart.canvas.parentNode?.querySelector('div');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.pointerEvents = 'none';
            chart.canvas.parentNode?.appendChild(tooltipEl);
          }

          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          const dataPoint = tooltip.dataPoints[0];
          const { label } = dataPoint.raw as {
            x: number;
            y: number;
            label: string;
          };

          ReactDOM.render(
            <TooltipContent
              data={data.find((point) => point.id === Number(label))}
            />,
            tooltipEl,
          );

          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = positionX + tooltip.caretX + 'px';
          tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        },
      },
    },
  };
  return options;
}
