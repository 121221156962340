import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: #f6f6f6;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem 0.5rem;
  position: relative;
  z-index: 999;
  gap: 0.5rem;
`;

export const Title = styled.div`
  color: #828282;
  font-size: 1rem;
  font-weight: 600;
`;

export const Container = styled.div`
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
`;
