// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

import CardServiceProviderDivider from '../../../../components/CardServiceProviderDivider';
// Global components
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID } from '../../../../graphql/queries';

export default function OperationFIDCServiceProvider({ hash }) {
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_DETAILS_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_prestadores_de_servicos'
      ],
    },
  );

  return (
    <Box p={2}>
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_prestadores_de_servicos'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_prestadores_de_servicos'
            ]
          }>
          <Grid container spacing={3}>
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.administrators?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Administrador" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.administrators?.map(
                    (administrator, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Administrador"
                          {...administrator}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.managers?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Gestor" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.managers?.map(
                    (manager, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Gestor"
                          {...manager}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.custodians?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Custodiante" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.custodians?.map(
                    (custodian, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Custodiante"
                          {...custodian}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.consultants?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Consultor de Crédito" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.consultants?.map(
                    (consultant, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Consultor de Crédito"
                          {...consultant}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.legalAdvisors?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Assessor Jurídico" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.legalAdvisors?.map(
                    (legalAdvisor, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Assessor Jurídico"
                          {...legalAdvisor}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.servicers?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Servicers" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.servicers?.map(
                    (servicer, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Servicer"
                          {...servicer}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.structurers?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Estruturador" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.structurers?.map(
                    (structure, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Estruturador"
                          {...structure}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsFidcServiceProvidersByOperationHashId
                      ?.auditors?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Auditor" />
                  </Grid>
                  {data?.getDetailsFidcServiceProvidersByOperationHashId?.auditors?.map(
                    (auditor, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Auditor"
                          {...auditor}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
