import styled from 'styled-components';

type WrapperProps = {
  color: 'default' | 'gray';
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) =>
    props.color === 'gray' ? 'rgba(204, 204, 204, 1)' : 'black'};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 30px;

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 15px;
    fill: ${(props) =>
      props.color === 'gray' ? 'rgba(0, 0, 0, .2)' : '#ff8211'};
  }

  span {
    margin-left: 15px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 400;
    color: rgb(77, 77, 77);
  }

  &:hover {
    background-color: rgba(255, 130, 17, 0.3);
    transition: all 0.2s linear;

    svg {
      transform: translateY(2px);
      transition: all 0.2s linear;
    }
  }
`;
