let xDown = null;
let yDown = null;

export function getTouches(event) {
  return event.touches || event.originalEvent.touches;
}

export function handleTouchStart(event) {
  const firstTouch = getTouches(event)[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
}

export function handleTouchMove(
  event,
  numSliders,
  currentIndex,
  setCurrentIndex,
) {
  if (!xDown || !yDown) {
    return;
  }
  const aux = currentIndex;
  const xUp = event.touches[0].clientX;
  const yUp = event.touches[0].clientY;
  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) {
      setCurrentIndex(aux + 1 !== numSliders ? aux + 1 : 0);
    } else {
      setCurrentIndex(aux - 1 < 0 ? numSliders - 1 : aux - 1);
    }
  } else {
    return;
  }
  xDown = null;
  yDown = null;
}
