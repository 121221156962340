import { useEffect, useState } from 'react';

import {
  Box,
  FormControl,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

type Props = {
  name: string;
  label: string;
  value: string | undefined;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder: string;
  variant: any;
  errors?: string;
  touched?: boolean;
  maxCaracters?: number;
  multiline?: boolean;
  fontSize?: string;
};

export function TextAreaProfile({
  name,
  label,
  value,
  errors,
  touched,
  handleChange,
  placeholder,
  variant = 'outlined',
  maxCaracters = 255,
  multiline = true,
  fontSize = '14px',
}: Props) {
  const [charactersCounter, setCharactersCounter] = useState(
    value?.length || 0,
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const handleChangeProfile = (
    event: React.FormEvent<HTMLInputElement>,
    handleChange: Props['handleChange'],
  ) => {
    const { value } = event.currentTarget;
    handleChange(event);
    setCharactersCounter(value.length);
  };

  useEffect(() => {
    setCharactersCounter(value?.length || 0);
  }, [value]);

  return (
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          color: '#1d1d1d',
          fontWeight: 400,
          fontSize,
          paddingBottom: '8px',
        }}>
        {label}
      </Box>
      <FormControl fullWidth variant="outlined">
        <TextField
          name={name}
          placeholder={placeholder}
          type="text"
          variant={variant}
          value={value}
          onChange={(e: any) => handleChangeProfile(e, handleChange)}
          multiline={multiline}
          minRows={isMobile ? 6 : 4}
          InputLabelProps={{ shrink: !!placeholder }}
          error={touched && Boolean(errors)}
          helperText={touched && errors}
          inputProps={{
            maxLength: maxCaracters,
          }}
          InputProps={{
            sx: {
              paddingBottom: '32px',
            },
          }}
          sx={{
            width: '100%',
            '& .MuiFormLabel-root': {
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '& .MuiFormHelperText-root': {
              color: '#f44336',
            },
          }}
        />
        <Typography
          sx={{
            width: '100%',
            textAlign: 'right',
            position: 'relative',
            top: errors ? '-50px' : '-30px',
            right: '20px',
            fontSize: '12px',
            lineHeight: '19px',
            opacity: 0.25,
          }}>
          Número de caracteres: {charactersCounter}/{maxCaracters}
        </Typography>
      </FormControl>
    </Grid>
  );
}
