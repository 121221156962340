import { PageData } from '../types';

export const pageData: PageData[] = [
  {
    title: 'Abertura',
    subtitle: '',
    text: [
      `
      O mercado de Certificados de Recebíveis Agrícolas (CRA) tem apresentado um crescimento significativo nos últimos anos no Brasil, impulsionado pela demanda de investidores que buscam diversificação em suas carteiras e pela necessidade de produtores rurais de obterem financiamentos para suas atividades. Nesse contexto, o Anuário Uqbar de CRA se torna uma publicação essencial para os profissionais que atuam nesse mercado.
      O Anuário Uqbar de CRA traz informações e análises sobre o mercado de CRA no Brasil. O objetivo do anuário é fornecer uma visão geral do mercado, com informações sobre os principais emissores, estruturas e características dos CRAs, bem como as tendências e perspectivas para o futuro. 
      O anuário é dividido em diferentes seções, que abrangem o mercado primário de CRA, o mercado secundário (uma novidade em 2022), e o estoque de CRA, além dos Rankings Uqbar e do conteúdo - em vídeo e escrito - desenvolvidos pelos patrocinadores exclusivamente para o Anuário.
      Utilizando os Anuários como plataforma, os patrocinadores trazem suas opiniões e perspectivas sobre o mercado de CRA. São discutidos temas como as mudanças regulatórias que afetam o mercado, os desafios e oportunidades para os emissores e investidores, e as perspectivas para o mercado de CRA em relação a outros instrumentos de crédito, como os CRI e os debêntures.
      Neste ano, pela primeira vez, estamos proporcionando aos leitores que são assinantes da plataforma Uqbar a possibilidade de explorar e investigar cada um dos dados apresentados nos Anuários. Cada gráfico ou ranking pode ser acessado rapidamente e em detalhes na plataforma Uqbar. Com isso, esperamos que os leitores possam fazer suas próprias análises, partindo dos dados que apresentamos aqui.
      Para os profissionais do mercado financeiro e de capitais, o Anuário Uqbar de CRA é uma fonte importante de informação e análise sobre esse mercado em expansão. Com a crescente demanda por CRAs, tanto de investidores quanto de emissores, é essencial estar atualizado sobre as tendências e as melhores práticas do setor, e o Anuário Uqbar de CRA se torna uma ferramenta essencial para isso.
        `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Primário',
    subtitle: '',
    text: [
      `
      Os CRAs se consolidaram como uma das principais alternativas de financiamento para produtores rurais e empresas do setor agropecuário. Em 2022, esse mercado bateu recorde, com um montante emitido superior em mais de 70% ao do ano anterior.
      Uma das novidades desse cenário é o recrudescimento do CDI como componente da remuneração dos CRA, em contrapartida à tendência observada nos anos anteriores de hegemonia da indexação ao IPCA.
      Outra tendência importante é o perfil cada vez mais corporativo das emissões, ou seja, a presença de empresas de maior porte entre os captadores de recursos via CRA. Além disso, em 2022, as debêntures foram o principal lastro utilizado para respaldar esses títulos, o que reforça a ideia de que o mercado de CRA está se consolidando como uma forma de financiamento exclusivo para grandes empresas.   
      Apesar dessas mudanças, os investidores de CRA ainda são majoritariamente pessoas físicas, embora existam agora os Fundos de Investimento em Direitos Creditórios do Agronegócio (FIAGROs), que foram propalados como potenciais grandes investidores de CRA. Isso mostra que o CRA ainda é um instrumento acessível para o pequeno investidor. Mas, isso tem uma causa: os incentivos tributários e o forte perfil corporativo (que torna o CRA mais palatável para o investidor menos sofisticado como o do varejo).
      `,
      ` <b>Emissões de CRA batem novo recorde em 2022</b>
      O cenário de 2022 para o mercado primário de CRAs foi de crescimento acelerado. No período, o montante emitido foi de R$ 43,62 bilhões, 72,20% superior ao recorde de 2021, que girava em torno de R$25,33 bilhões. Considerando as operações, o número saltou de 128 para 231, o que equivale a um crescimento de mais de 80% na comparação com o ano anterior.
      O patamar da soma de montante e a quantidade de títulos também cresceram, em 2022, quando comparados aos quatro anos antecedentes. De fato, a dinâmica do setor agrícola pode ter contribuído para este movimento, o que nos leva a questionar se a securitização tem sido a força motriz do agronegócio.`,

      `
      <b>Maior parte das ofertas foi realizada com esforços restritos</b>
      A modalidade de distribuição dos CRAs via Instrução CVM 476, em 2022, respondeu por 63,98% da soma do montante emitido, sendo que os 36,02% restantes foram através da oferta ICVM 400, repetindo o mesmo movimento de 2021, quando atingiu 59,96% da totalidade. Com isso, nota-se que as condições que esta oferta disponibiliza ao investidor ainda são a preferência do mercado.
      `,
      `
      <b>Categoria de risco de crédito obedece grau de concentração</b>
      Os CRAs podem ter risco de crédito concentrado ou pulverizado. De 2018 a 2022, o risco pulverizado predominou, respondendo por 97,14%, em 2022, um aumento de 7,01% se comparado a 2021. Já os CRAs pulverizados representaram apenas 2,86% do total, equivalente a uma queda de 7,01% referente ao ano anterior.
      `,
      `
      <b>Perfil dos devedores do lastro dos CRAs</b>
      A distribuição ficou concentrada nas Pessoas Jurídicas, que responderam por 95,18% das emissões, em 2022, aproximadamente R$ 41,5 bilhões do total, o que representa um aumento de 5,77 p.p. referente ao ano de 2021.
      Por outro lado, os devedores Pessoas Físicas corresponderam a 3,95% do total, em 2022, o equivalente a R$ 1,72 bilhão.
      Por fim, temos o Multidevedor (pessoas jurídicas e pessoas físicas devedoras do mesmo título), que alcançou 0,87%, girando em torno de R$ 380 milhões do mercado, valor menor quando comparado a 2021.
      `,
      `<b>Debêntures são o principal contrato-lastro de CRAs</b>
        O lastro dos CRAs foi representado, em sua maior parte, por debêntures com 59,3% do total de montante emitido, em 2022. As CPRs Financeiras registraram 23,5%, enquanto CDCAs atingiram a marca de 7,56% e as Notas Comerciais, 6%.
      `,
      `<b>IPCA foi o principal indexador dos CRAs</b>
      O principal indexador utilizado nas estruturas dos CRAs para remuneração dos investidores foi o IPCA, índice de inflação, registrando 58,10% do total de operações emitidas. O resultado apresenta uma queda de 18,96% referente a 2021. Em seguida, aparece a Taxa CDI em 41,33%, significando um aumento de 23,11% comparado a 2021. Nota-se que o IPCA passou a ser o indexador preferido desde 2020, período em que se iniciou a pandemia do Coronavírus.
      `,
      `
      <b>Quase 40% dos CRAs possuem vencimentos entre 60 e 90 meses</b>
      Os prazos de duração dos CRAs, em 2022, foram expressivos entre as faixas de 60 a 90 meses com 39,14%, queda de 9% referente ao ano 2021, ao passo que houve um aumento de 3,22% na faixa de 180 a 210 meses. A faixa de 120 a 150 meses movimentou-se pouco, atingindo os 23,81%.
      `,
      `
      <b>Segmento de atividades pecuárias é destaque de 2022</b>
      Atividades Pecuárias se sobressairam mais um ano ao utilizarem a tecnologia dos CRAs para captar recursos em 2022, 23,44% do total anual emitido, ou R$ 10,2 bilhões, queda de 7,47% referente ao ano de 2021, cifra dividida em 22 operações. Segmentos Diversos computaram 19,56%, ou R$ 8,5 bilhões do total, distribuídos em 68 operações. Sucroenergético apontou 17,60%, ou R$ 7,7 bilhões do total de 2022, distribuídos em 38 operações. Grãos foram o quarto maior segmento em termos de montante emitido apresentando 17,14% do total, aumento de 6,53% em relação a 2021, partilhado em 34 operações. Fibras atingiram 9,61% do total e oito operações. Insumos Agrícolas computaram 8,40%, ou R$ 3,7 bilhões em 47 operações.
      `,
      `
      <b>CRA ESG – começa a ganhar representatividade</b>
      A preocupação da sociedade investidora com o meio ambiente e com a responsabilidade social vem se mostrando presente através dos títulos verdes de CRAs emitidos em 2022 que movimentaram R$ 4,98 bilhões, representando 11,42% do total de montante emitido em 2022.
      Green Bond Principles (GBP), que considera o desenvolvimento sustentável ambiental do projeto e, em sua categoria, enquadram-se projetos de energia renovável e eficiência energética, representaram 9,81% do total, aumento de 1,32% referente a 2021. Títulos com princípio Sustainability-Linked Bond Principles (SLBP ou SLBs), quando os emissores se comprometem com futuras melhorias nos resultados relativos ao seu índice de sustentabilidade e com certificado Climate Bonds Initiative (CBI), completam o quadro, com 1,6% das emissões.
      `,
      `
      <b>Perfil do investidor</b>
      Na ponta compradora, os investidores Pessoas Físicas ainda são maioria e acumulam a cifra de R$ 15,38 bilhões em investimentos, que correspondem a 35,26% do mercado. Instituições intermediárias participantes do consórcio de distribuição representam 21,25% ou R$ 9,27 bilhões, enquanto os Fundos de Investimento formam 19,2% do mercado, com R$ 8,38 bilhões. Instituições financeiras ligadas à emissora ou participantes do consórcio refletem 12,5% ou o equivalente a R$ 5,45 bilhões.
      `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Secundário',
    subtitle: '',
    text: [
      `Pela primeira vez, a Uqbar traz ao anuário a evolução anual do montante negociado e do número de negócios envolvendo CRA no mercado de balcão organizado da B3.
      O caráter "secundário" das negociações envolvendo CRA no mercado de balcão deve ser objeto de atenção. Quanto mais distantes do momento de suas respectivas emissões, mais as transações dos títulos estão associadas com um mercado secundário genuíno. Isto justifica, assim, para o benefício de uma análise de melhor discernimento, a segmentação, por intervalo de tempo entre emissão e negociação, das transações realizadas de CRA.
      De forma geral, subentende-se que as negociações registradas de CRA ocorridas com intervalo de tempo entre a emissão do título e sua negociação inferior a 180 dias constituem transações mais relacionadas ao mercado primário. Por outro lado, embora existam exceções, supõe-se que a maioria das negociações ocorridas com intervalo de tempo, desde a emissão, de mais de 180 dias esteja genuinamente associada ao mercado secundário. 
      O incremento paulatino desses indicadores de negociação de CRA deve ser encarado como uma notícia positiva. Por outro lado, é razoável supor que esse crescimento ainda está limitado pela tímida transparência informacional dos CRA, em particular a respeito do lastro, uma vez que o mercado secundário de qualquer ativo está altamente relacionado com o volume e a qualidade das informações disponíveis ao mercado, contribuindo, especialmente, para a formação de preços.`,
      `<b>Negócios com CRAs</b>
      Em 2022, o montante negociado total no mercado secundário de CRAs chegou à impressionante cifra de R$ 22,33 bilhões, que marca uma elevação de 39,48%, quando comparada com a cifra R$ 16,01 bilhões de 2021.
      Referente a 2020, o montante negociado de 2022 obteve crescimento de 36,91%, equivalente a R$ 16,31 bilhões. Comparado a 2019, o crescimento de 2022 foi de 45,66%, equivalente a R$ 15,33 bilhões.
      Em paralelo, o número de negócios no mercado secundário de CRAs também cresceu em 2022, atingindo a marca de 181.354 negócios. Comparados com os 161.609 de 2021, o crescimento é de 12,22%.
      Em 2020, o número de negócios ficou em 126.529, 43,33% menor comparado com 2022. O ano de 2019 alcançou 79.819 negócios. Se comparado com 2022, são 101.535 negócios a mais ou o equivalente a um aumento de 127,21%.
      `,
    ],
    authors: [],
  },
  {
    title: 'Estoque',
    subtitle: '',
    text: [
      `Mudando o foco de análise para saldo devedor dos CRA, fica patente que o comportamento do montante em estoque reflete a amplitude de prazo dos títulos que historicamente vão sendo emitidos.
    Pela sua natureza de mais longo prazo, os títulos de securitização prolongam no tempo o efeito de um incremento anual do montante de emissões e depósitos sobre o nível de estoque.`,
      `
      O estoque do mercado de CRAs atingiu, ao final de 2022, um saldo devedor consolidado de R$ 97,69 bilhões. Isso se compara com os R$ 64,15 bilhões auferidos em dezembro de 2021. Assim, depreende-se um crescimento de impressionantes 52,28% na comparação.
      Ao mesmo tempo, esse indicador evidencia o relativo curto prazo dos CRA emitidos. Entre dezembro de 2022 e dezembro de 2020 o saldo devedor dos CRA saltou R$ 50,48 bilhões. Já o montante emitido nos 24 meses de intervalo corresponde a R$ 68,90 bilhões. Ou seja, o efeito potencialmente acumulativo das novas emissões é rapidamente minorado por uma parcela não desprezível (frente ao saldo total) de resgates ocorridos no mesmo período.`,
    ],
    authors: [],
  },
  {
    title: 'Rankings Uqbar',
    subtitle: '',
    text: [
      `Os Rankings Uqbar trazem, em 2022, duas principais novidades: os rankings de CRAs ordenados pelo montante negociado e pelo número de negócios ocorridos no mercado de balcão organizado; e o ranking de servicer (ou agentes de cobrança, em bom português) atuantes nesse mercado.
      Com isso, os Rankings Uqbar dedicados exclusivamente ao mercado de CRA somam 21 unidades. Isso é um reflexo da pujança do mercado de CRA, bem como da diversidade de participantes.`,
      `<b>Ranking de Operações de CRA por Montante Emitido</b> 
      No ranking em referência, o top 3 vai para as operações em destaque por representatividade em seu montante emitido do correspondente ao total de R$ 43,62 bilhões, a seguir: 
      1ª - Emissão 77 em série única, apelido <a href="https://uqbar.com.br/operacao/cra/vert-klabin-8889237/8889237">Klabin</a>, emitida pela Vert, com R$ 2,50 bilhões, correspondente a 5,73% do total; 
      2ª - Emissão 38 em duas séries, apelido <a href="https://uqbar.com.br/operacao/cra/true-raizen-8917794/8917794">Raizen</a>, emitida pela True, com R$ 2,00 bilhões, correspondente a 4,59%; 
      3ª - Emissão 78 em duas séries, apelido <a href="https://uqbar.com.br/operacao/cra/vert-brf-iv-8865868/8865868">BRF IV</a>, também emitida pela Vert, com R$ 1,70 bilhões, correspondente a 3,90%.    
      Deste modo, percebe-se que as 3 emissões representam o correspondente a 14,21% do total emitido em 2022. `,

      `
      <b>Ranking de Securitizadoras do Agronegócio por Montante Emitido em 2022</b>
      Pelo citado ranking 13 Securitizadoras representam o valor global de R$ 43,57 bilhões em 2022. Destacaremos as 5 primeiras, segue:
      Primeiramente, temos a Eco Securitizadora com R$ 16,14 bilhões, equivalente a 37,05% da soma total.
      Logo após, temos a Vert com a captação do ano em torno de R$ 9,96 bilhões, correspondente a 22,86% da soma total.
      Em seguida, aparece a Virgo com R$ 9,59 bilhões, representando 22,02% da soma total.
      Depois, vem a True que obteve a segunda maior captação com apenas uma operação, está na 4ª posição do ranking com R$ 4,11 bilhões, correspondente a 9,43% da soma total.
      Por fim, na 5ª posição, a Opea, com R$ 2,60 bilhões, equivalente a 5,96% do total emitido em 2022.
      `,

      `
      <b>Ranking de Securitizadoras do Agronegócio por Número de Operações de 2022</b>
      Através do ranking em referência avistamos as seguintes instituições, segue: 
        1. EcoSec, com 70 operações, correspondente a 30,70% do total 
        2. Virgo, com 61 operações, correspondente a 26,75%; 
        3. Opea, com 38 operações, correspondente a 16,67%;  
        4. True, com 18 operações, correspondente a 7,89%; 
        5. Vert e Ceres, com 11 operações cada, correspondente a 4,82% cada uma;  
        6. Canal, Província e Reit, com 4 operações cada, correspondente a 1,75% cada uma;  
        7. Octante, com 3 operações, correspondente a 1,32%; 
        8. Travessia, com 2 operações, correspondente a 0,88% e  
        9. Planeta e Habitasec, com 1 operação cada, correspondente a 0,44% cada uma.  
        Com isso, observamos que 74,13% do total de operações está concentrado nas Securitizadoras: EcoSec, Virgo e Opea.
      `,
      `
      <b>Ranking de Agentes Fiduciários por Montante Emitido em 2022</b>
      Através do ranking em referência encontramos as seguintes instituições, segue: 
      1- Oliveira Trust, com R$ 16,27 bilhões, correspondente a 37,49% do total; 
      2- Pentágono, com R$ 15,83 bilhões, correspondente a 36,47%; 
      3- Vórtx, com R$ 6,43 bilhões, correspondente a 14,80%; 
      4- Simplific Pavarini, com R$ 4,57 bilhões, correspondente a 10,52%; 
      5- Commcor, com R$ 191,50 milhões, correspondente a 0,44%, e; 
      6- Planner Corretora, com R$ 120,00 milhões, correspondente a 0,28%. 
      Diante disso, conclui-se que 88,76% do total de operações está concentrado nas instituições Oliveira Trust, Pentágono e Vórtx.
      `,
      `
      <b>Ranking de Agentes Fiduciários por Número de Operações de 2022</b>
      Pelo citado ranking identificamos as seguintes instituições, segue: 
      1- Oliveira Trust, com 128 operações, correspondente a 56,64% do total; 
      2- Vórtx, com 48 operações, correspondente a 21,24%; 
      3- Pentágono, com 32 operações, correspondente a 14,16%; 
      4- Simplific Pavarini, com 12 operações, correspondente a 5,31%; 
      5- Commcor, com 5 operações, correspondente a 2,21%, e; 
      6- Planner Corretora, com 1 operação, correspondente a 0,44%. 
      Diante disso, conclui-se que 92,04% do total de operações está concentrado nas instituições Oliveira Trust, Vórtx e Pentágono.
      `,
      `
      <b>Ranking de Assessores Jurídicos por Montante Emitido em 2022</b>
      Com R$ 17,01 bilhões no total e onze ranqueados, onde temos uma concentração de 64,07% entre os três primeiros do ranking. A escala traz Pinheiro Neto como líder do ranking por montante emitido, com R$ 5,11 bilhões. Na sequência, VBSO, com R$ 4,01 bilhões. Na terceira posição figura o Santos Neto Advogados, com R$ 1,78 bilhões. Demarest a quarta posição do ranking, tendo atingido R$ 1,60 bilhão no quesito do montante emitido. Mello e Torres posiciona-se na quinta posição do ranking, com a cifra de R$ 1,07 bilhão.
      `,
      `
      <b>Ranking de Assessores Jurídicos por Número de Operações de 2022</b>
      Pelo citado ranking, avistamos onze Assessores Jurídicos e 118 operações, de onde detalharemos os cinco primeiros, segue:
      A VBSO Advogados foi a primeira colocada, com 40 operações. Seguida por Pinheiro Neto Advogados com 19 operações. Na 3ª posição, está Santos Neto Advogados com 18 operações. Logo após, Madrona Advogados com 13 operações. E, na 5ª posição, Franco Leutewiler Henriques Advogados com 13 operações.  
      Dito isso, observa-se que 65,25% do total de operações está concentrado nos 3 primeiros assessores jurídicos do ranking.
      `,
      `
      <b>Ranking de Líderes de Distribuição por Montante Emitido em 2022</b>
      Pelo citado ranking avistamos 33 Líderes de Distribuição. Examinaremos os 5 primeiros, segue: 
      Na liderança, a XP Investimentos com R$11,40 bilhões em montante emitido, correspondente a 27,28% do total. Aumento de 90,95% comparado ao montante captado no ano de 2021, girando em torno de R$ 5,97 bilhões;  
      Seguido pelo Banco BTG Pactual, com R$ 9,27 bilhões, correspondente a 22,19%, cifra 124,46% maior que o ano anterior de R$ 4,13 bilhões;  
      Logo após, o Banco Itaú BBA, com 7,10 bilhões, correspondente a 16,99%, avançou uma posição em comparação ao ano de 2021, alcançando um crescimento de 169,96%, equivalente a R$ 2,63 bilhões;  
      Em seguida, pelo UBS Brasil, com R$ 3,38 bilhões, correspondente a 8,09%; 
      E, na 5ª posição, temos o Banco Santander (Brasil), com R$ 1,12 bilhão, correspondente a 2,68% do total emitido em 2022.  
      Portanto, conclui-se que, 66,47% do total de montante emitido está concentrado nas 3 primeiras instituições (XP Investimentos, Banco BTG Pactual e Banco Itaú BBA).
      `,
      `
      <b>Ranking de Líderes de Distribuição por Número de Títulos de 2022</b>
      Totalizando 226 títulos e 33 ranqueados, aumento superior a 83,74% em comparação a 2021, de 123 títulos. A Virgo lidera o ranking com 27 títulos, correspondente a 11,95%, crescimento de 58,82% referente ao ano anterior que atingiu 17 títulos. Em seguida, vem a EcoSec com 25 títulos, correspondente a 11,06%. O Banco Itaú BBA se manteve na 3ª posição, porém divide o espaço com a Opea, com 22 títulos cada, correspondente a 9,73% cada uma. Em compensação, a XP investimentos, 1ª colocada no ranking de Líderes de Distribuição por montante emitido de 2022, encontra-se na 5ª posição sob este critério, com 21 títulos, correspondente a 9,29% do total.
      `,
      `
      <b>Ranking de Agências de Classificação de Risco por Montante Classificado</b>
      O montante de R$ 13,78 bilhões em 2022 é o equivalente a 5,03% menor que o ano anterior. Neste ranking temos o total de 3 instituições. A Fitch aparece na liderança com R$ 6,30 bilhões, correspondente a 45,72%. Seguida pela S&P com R$ 4,78 bilhões, correspondente a 34,69%. E, na 3ª posição temos a Moodys com R$ 2,70 bilhões, correspondente a 19,59%.
      `,
      `<b>Ranking de Agências de Classificação de Risco por Número de Títulos Classificados</b>
      Contrariamente ao que ocorreu no critério por montante, houve um crescimento em número de operações equivalente a 74,36%, comparado com 2021, totalizando 68 operações. 
      A Agência de Classificação de Risco que lidera o pódio é a S&P com 42 operações, correspondente a 61,76%, avanço de 82,61% atinente ao ano anterior, seguida por Fitch com 22 operações, correspondente a 32,35%, crescimento de 83,33% referente ao ano anterior. E, finalmente a Moodys com 4 operações, correspondente a 5,88%, exatamente o mesmo número que atingiu em 2021.`,
      `<b>Ranking de Servicers por Montante Emitido em 2022</b>
      Neste Anuário de 2023, dentre tantas novidades está o lançamento do ranking de Servicers. Totalizando R$ 2,39 bilhões do montante emitido dividido em 7 Servicers, sendo que, o correspondente a 82,21% está concentrado nos 3 primeiros colocados.   
      A ACE (Agriculture Collateral Experts) é líder disparada do ranking, com R$ 2,02 bilhões, correspondente a 72,32% do total. Seguida por Ceres, na 2ª posição, com R$ 231,81 milhões, correspondente a 8,29%. E, na 3ª posição está a Duagro com R$ 216,25 milhões, correspondente a 7,744%. Destacamos que, a ACE na qualidade de agente de cobrança em emissões de CRA tem como seu braço direito a Agromatic - plataforma digital de formalização, emissão, certificação e registro de CPR e outros títulos de crédito relacionados ao agronegócio.`,
      `<b>Ranking de Servicers por Número de Operações de 2022</b>
      Pelo citado ranking constatamos 41 operações e 7 Servicers, em que 90,24%, se concentra nos 3 primeiros colocados, a seguir: 
      ACE (Agriculture Collateral Experts) lidera este pódio, com 24 operações, correspondente a 58,54% do total. Na 2ª posição, está a Ceres com 10 operações, correspondente a 24,39% do total. E, na 3ª posição, Luchesi Advogados, com 3 operações, correspondente a 7,32% do total.`,
      `<b>Ranking de CRA por Montante Negociado (+180 dias) em 2022</b>
      Em 2022 o montante negociado no mercado secundário de CRA chegou a impressionante cifra de R$ 22,33 bilhões, composta pelo somatório de negócios registrados no mercado de balcão organizado da B3. Elevação de 39,48% comparado com 2021, quando girava em torno de R$ 16,01 bilhões. 
      A Gaia Impacto foi a emissora do <a href="https://uqbar.com.br/operacao/cra/gaia-impacto-2014-909/7778264">CRA mais negociado</a>, com R$ 3,49 bilhões, correspondente a 15,62% do total.
      Na 2ª posição, o <a href="https://uqbar.com.br/operacao/cra/brazilian-securities-btg-pactual-commodities-switzerland/7797731">CRA da Brazilian Securities</a>, registrou a cifra de R$1,42 bilhões, correspondente a 6,37%. 
      E, na 3ª posição, o <a href="https://uqbar.com.br/operacao/cra/true-2019-903/8288512">CRA da True</a>, com R$ 1,01 bilhões, correspondente a 4,51% do total.
      `,
      `
      <b>Ranking de CRA por Número de Negócios (+180 dias) em 2022</b>
      O número de negócios no mercado secundário de CRA também atingiu 181.354, ultrapassando em 12,22%, a soma de negociações registradas em 2021. 
      O CRA líder foi emitido pela Opea, tendo tido 8.033 negociações, correspondente a 4,43% do total. 
      Na sequência vem um CRA da True, com 7.408 negociações, correspondente a 4,08% do total. 
      E, na 3ª posição, o CRA da série 3 da emissão 153 da EcoSec, com 6.735 negociações, correspondente a 3,71% atinente ao total de 2022.
      `,
      `
      <b>Ranking de Securitizadoras por Saldo Devedor</b>
      Iniciando a seção dos rankings baseados no preço unitário (PU) dos CRA ainda em circulação, independente do ano em que foram emitidos, estão as securitizadoras. 
      Nesse quesito, a EcoSec permanece como a líder desse ranking, totalizando R$ 28,4 bilhões. Ela é seguida de perto pela Virgo, com R$ 25,4 bilhões, que, por sua vez, registra uma diferença de pouco mais de R$ 1,0 bilhão para a terceira colocada, VERT.`,
      `
      <b>Ranking de Securitizadoras por Número de Operações em Circulação</b>
      Em relação ao número de operações de CRA em circulação, a EcoSec permanece em destaque. Com 148 operações ainda ativas, a securitizadora registra uma diferença de 44 operações para a segunda colocada, Virgo. Nesse quesito, a Opea ocupa o terceiro lugar, com 49 operações, enquanto a VERT registra 38.
      `,
      `
      <b>Ranking de Agentes Fiduciários por Saldo Devedor</b>
      Quando os agentes fiduciários são organizados segundo o critério do preço unitários dos CRA, a Pentágono ocupa a liderança isolada, com R$ 32,6 bilhões. A segunda posição é ocupada pela Oliveira Trust, que soma R$ 27,8 bilhões. A Vórtx, outrora líder desse ranking, fica na terceira colocação, com R$ 19,9 bilhões.
      `,
      `
      <b>Ranking de Agentes Fiduciários por Número de Operações em Circulação</b>
      Em relação ao número de operações de CRA ativas, a ordenação dos agentes fiduciários se altera significativamente. A Oliveira Trust passa para a primeira posição, com 178 operações ativas, a Vórtx assume o segundo lugar, com 107, e a Pentágono ocupa o terceiro lugar, com 78 operações.
      `,
      `
      <b>Ranking de Auditores de Securitizadoras por Saldo Devedor</b>
      Em relação ao Ranking de Auditores de Securitizadoras por Preço Unitário, a KPMG se destaca na primeira colocação, com R$ 34,0 bilhões, ao passo que a PwC fica com o segundo lugar, bastante próxima da BDO RCS, com R$ 28,3 bilhões e R$ 26,3 bilhões, respectivamente. 
      `,
      `
      <b>Ranking de Auditores de Securitizadoras por Número de Operações em Circulação</b>
      Ordenados pelo critério do número de operações, os auditores invertem suas posições. a PwC se torna líder, auditando 148 operações, enquanto a BDO RCS é responsável por 112 operações, enquanto a KPMG é auditora de 87 operações.
      `,
    ],
    authors: [],
  },
  {
    title:
      'CRA pulverizado - Qual a expertise necessária para essa estruturação?',
    subtitle: '',
    text: [
      `Mais um ano se passou e o mercado de CRA, independentemente de eleições, de seca em diversos estados, de novos lockdowns mundo afora ou até mesmo uma guerra, continuou crescendo e mostrando a demanda do agro brasileiro. Alcançamos um estoque de CRAS no país de aproximadamente R$ 100 Bilhões. Este volume é bastante expressivo por si só, mas ainda mais impressionante quando comparamos ao estoque de recursos controlados de R$ 192 Bilhões, que sempre foram o principal motor do setor. Tivemos ainda o aumento de operações para nomes menores, com estruturas diferentes, levando o dinheiro onde mais se precisa. Para ilustrar tal informação, em 2022, aproximadamente metade das emissões foram feitas com valor inferior a R$ 50 milhões de reais. Não digo que as maiores empresas do país não precisam de recursos e que elas não façam diferença, mas certamente as grandes encontram mais facilidades financeiras. O interessante é que, cada vez mais, estamos conseguindo aprofundar as análises e estruturas para o setor, além de contarmos com o aumento no número de pessoas especializadas que trazem operações para um mercado menos assistido. 
      Um dos mecanismos para fazer o dinheiro chegar a essas empresas menores é a operação com base em uma carteira de recebíveis pulverizada. No caso do Brasil, muitas dessas operações são feitas através de CRA.  Este tipo de operação ganhou força nos últimos anos levando recursos à diversas empresas, que não teriam a oportunidade de captar no volume ou na taxa que captam baseado em seus números. Neste caso, a estrutura fala mais alto e a principal avaliação de risco é baseada em um grupo de ativos securitizados, e não no balanço de uma empresa (normalmente recebíveis de clientes). O racional por traz da estrutura está no fato de que o fluxo que irá pagar a operação será provido da liquidez gerada através desses ativos, que dependem pouco (ou na melhor das estruturas nada) da atuação do cedente daqueles ativos. 
      Esse modelo não é novo, pois já foi muito utilizado principalmente nos mercados mais desenvolvidos para amparar operação imobiliárias, de carros, cartão de crédito etc. São os chamados ABS (Asset-backed security) que permitem que o devedor do papel faça uma captação para financiar o capital de giro relacionado a ativos, de maneira bem mais barata, caso utilizassem seu balanço junto com outras garantias. É um mercado bastante desenvolvido no exterior e somente no cenário americano girou em torno de 1,5 trilhões de dólares, segundo a SIFMA (Securities Industry and Financial Market Association). Mas é fato que esse tipo de operação requer certo cuidado na estruturação e no monitoramento. 
      No caso da estruturação, é importante ter em mente diversos temas como, por exemplo, a qualidade do ativo que se está securitizando, ou seja, que tipo de título está por traz daqueles recebíveis e qual relação comercial gerou aquele título. Outro ponto é a forma de liquidação dos mesmos, observando que o formato original de liquidação será preservado e em que momento isso pode variar. Também vale destacar os riscos regulatórios e legais que a estrutura se ampara, a pulverização que os recebíveis terão, a seleção dos tipos de ativos que devem fazer parte daquela securitização, a subordinação a ser utilizada, além da estrutura que trará robustez jurídica àqueles ativos, e ainda, como e em que periodicidade será feito o monitoramento, entre outros temas.  Enfim, montar uma estrutura onde se consiga levar ao máximo a análise do risco à carteira de ativos, e sair do risco corporativo de balanço, é fundamental para conseguir viabilizar esse tipo de CRA. Claramente não é uma ciência exata, e temos que considerar ponderações de cada uma destas características para se criar uma estrutura equilibrada.  
      No campo do monitoramento, especialmente em um país como o Brasil, e mais importante ainda dentro do agro, que é um setor com características distintas em diferentes regiões do país, é de suma importância acompanhar os vencimentos da carteira, como está sendo feita sua formalização, como está o cedente daqueles créditos e como está o desenvolvimento da região da carteira, por exemplo. Neste tipo de CRA, a estruturação é fundamental, mas a operação só “ganha vida” depois de sua liquidação. É no monitoramento onde se ganha o jogo, onde o gestor da securitização vai garantir que as regras serão seguidas. Essas complexidades muitas vezes variam não somente de país para país, mas também em relação aos setores e regiões. Devido a essa difícil equação e aos detalhes das estruturadoras, as empresas que trabalham com esse tipo de operação acabam sendo especialistas setoriais.  
      De maneira geral, essa estrutura ainda é pouco utilizada para maior parte dos players do mercado, dado a complexidade citada. Mas é importante destacar que quando a estrutura é bem-feita e monitorada, ela permite levar recursos para empresas que não teriam essa oportunidade e inclusive reduzir custos de captação para estas empresas. Utilizando este modelo de CRA, a Ecoagro teve a oportunidade de levar alguns bilhões de reais diretamente a cadeia de insumos do agro. Verdade seja dita: deu trabalho!!!! Mas para nós é um grande orgulho saber que estamos fazendo a diferença para tantas empresas e produtores. `,
    ],

    authors: [
      {
        name: 'Mathias Teixeira',
        description:
          'Diretor de Originação, responsável pela criação de novos produtos e desenvolvimento tecnológico do Grupo Ecoagro. Mais de 12 anos de experiência no Itaú BBA, com atuação em diversas áreas como Comercial, Produtos, Back Office, Mesa de operações de clientes, Crédito e Renda Fixa. Possui MBA pela IESE Business School, na Espanha, e é graduado em Engenharia de Produção pela Escola Politécnica da USP em São Paulo.',
        image: 'Teixeira.jpg',
      },
    ],
  },
];

export const rankingsData = [
  null,
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=operation&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitizer&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitizer&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=fiduciaryagent&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=fiduciaryagent&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=distributor&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=distributor&criteria=titlesnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=agencyrating&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=agencyrating&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=servicer&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=servicer&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitycra&criteria=amountnegotiated&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitycra&criteria=tradesnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=stockauditor&criteria=amount&timeRange=default',
  'https://www.uqbar.com.br/rankings/dados?market=CRA&year=2022&ranking=stockauditor&criteria=operations&timeRange=default',
];
