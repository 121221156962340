import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';

import * as S from './style';
import UqbarLogo from '../../../../../../../assets/imgs/yearbook/uqbar_tooltip.png';

type Props = {
  href: string;
};

const DEFAULT_TITLE = 'Plataforma Uqbar';
const DEFAULT_DESCRIPTION = `A Uqbar conecta todas as partes que compõem o mercado de Finanças
Estruturadas do país: operações financeiras, instituições
participantes e profissionais atuantes. Tenha acesso a dados,
artigos, contatos, rankings e mais em poucos cliques.`;

const UqbarTip = (props: React.PropsWithChildren<Props>) => {
  const [logoUrl, setLogoUrl] = React.useState(UqbarLogo);
  const [title, setTitle] = React.useState(DEFAULT_TITLE);
  const [description, setDescription] = React.useState(DEFAULT_DESCRIPTION);

  const getMeta = (
    doc: Document,
    tag: string,
    setData: (v: string) => void,
  ) => {
    const meta = doc.querySelector(`meta[property="${tag}"]`);

    if (meta) {
      const data = meta.getAttribute('content');
      if (data) setData(data);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(props.href);
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');
      getMeta(doc, 'og:image', setLogoUrl);
      getMeta(doc, 'og:title', setTitle);
      getMeta(doc, 'og:description', setDescription);
    } catch (error) {
      setLogoUrl(UqbarLogo);
      setTitle(DEFAULT_TITLE);
      setDescription(DEFAULT_DESCRIPTION);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Tooltip
      title={
        <S.Wrapper>
          <img src={logoUrl}></img>
          <S.Text>
            <S.Title>{title}</S.Title>
            <S.Description>
              <span>{description}</span>
            </S.Description>

            <div>
              <a href="https://www.uqbar.com.br">www.uqbar.com.br</a>
            </div>
          </S.Text>
        </S.Wrapper>
      }>
      <S.Span>{props.children}</S.Span>
    </Tooltip>
  );
};

export default UqbarTip;
