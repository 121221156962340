import {
  crsPrimaryMarketCharts,
  crsSecondaryMarketCharts,
  crsStockCharts,
} from './charts/CRs';
import {
  YearbookDescriptions,
  YearbookMarketArticle,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const crsOpeningTexts = [
  `O Anuário Uqbar de Certificados de Recebíveis (CR) de 2024 marca um momento histórico, consolidando, pela primeira vez, os anuários de Certificados de Recebíveis Imobiliários (CRI) e de Certificados de Recebíveis do Agronegócio (CRA) em uma única publicação. 
  Esta inovação reflete a evolução e a expansão do mercado de certificados de recebíveis, que não mais se limitam estritamente aos segmentos imobiliário e agronegócio. 
  Tal avanço não só demonstra a maturidade do mercado brasileiro de securitização, mas também destaca a crescente diversificação dos ativos subjacentes que agora podem abranger uma variedade mais ampla de setores econômicos. 
  A decisão de unificar os anuários em um compêndio de CR é um testemunho da versatilidade e do potencial desses instrumentos financeiros como mecanismos de financiamento.`,
  `No mercado primário, o anuário revela detalhes notáveis sobre o desempenho dos CRIs e CRAs ao longo de 2023. 
  A robustez do mercado de CRIs é evidenciada ao ultrapassar a marca de R$ 50 bilhões em emissões, uma clara indicação da capacidade do mercado de capitais de financiar o mercado imobiliário brasileiro. 
  Apesar de uma leve retração nas emissões em comparação com o ano anterior, a inovação e a diversificação continuam a ser motores essenciais para o crescimento do setor. 
  Paralelamente, o mercado de CRAs demonstrou resiliência e adaptabilidade, refletindo a importância crítica do agronegócio na economia nacional. 
  O destaque para o segmento de grãos e a crescente atenção aos princípios ESG, embora ainda emergente, sinaliza uma evolução na consciência ambiental e social do setor.`,
  `Adicionalmente, a análise do mercado secundário e do estoque dos CRs traz à tona a performance notável desses instrumentos. 
  A expansão do mercado secundário de CRIs e CRAs, juntamente com o crescimento gradativo no saldo devedor, reforça o papel crucial dos Certificados de Recebíveis na dinâmica financeira do país, apoiando de forma sustentável o desenvolvimento dos setores imobiliário e agrícola, além de abrir caminhos para a inclusão de novos segmentos econômicos sob o guarda-chuva dos CRs. 
  A consolidação dos anuários em uma análise abrangente de CR é um marco significativo que sublinha a relevância desses instrumentos no mercado de capitais brasileiro, promovendo uma compreensão mais profunda das tendências, desafios e oportunidades no mercado de securitização.`,
];

export const crsArticles: YearbookMarketArticle[] = [
  {
    title:
      'Certificados de Recebíveis: Panorama Regulatório de 2023 e Perspectivas para 2024',
    ads: {
      image: 'tozzini',
      link: 'https://tozzinifreire.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
    },
    authorLink: 'https://www.uqbar.com.br/profissional/ricardo-stuber/1669',
    partner: {
      name: 'TozziniFreire Advogados',
      link: 'https://www.uqbar.com.br/instituicao/tozzini-freire-teixeira-e-silva-advogados-1656120243507/8002/perfil',
    },
    texts: [
      `O ano de 2023 começou com os desafios de mudança de governo no país, e a crise do mercado de crédito no primeiro semestre levou a um recuo de mais de 13% nos títulos mais líquidos, como debêntures e notas comerciais, segundo os dados da ANBIMA (Associação Brasileira das Entidades dos Mercados Financeiro e de Capitais). 
    Mas o crédito estruturado se mostrou mais resiliente, principalmente nas operações de securitização, quando analisamos o montante emitido de CRI (Certificado de Recebíveis Imobiliários) e CRA (Certificado de Recebíveis do Agronegócio) em comparação a 2022. 
    De acordo com os dados da UQBAR, tivemos R$ 50,7 bilhões em operações de CRI em 2023, contra R$ 51,1 bilhões em 2022. 
    Os números de CRA também foram estáveis: R$ 43,5 bilhões em 2023 contra R$ 44,8 bilhões em 2022.`,
      `Os números de CR (Certificado de Recebíveis) ainda são tímidos, principalmente por dois fatores: (i) não são títulos isentos, como os CRI e os CRA; e (ii) muitos fundos de investimento ainda precisam adaptar seus regulamentos, para que possam incluir os CR em suas carteiras. 
    Foram apenas R$ 120 milhões emitidos em 2023, divididos em 4 operações, contra R$ 903 milhões em 2022, decorrentes de 3 operações.`,
      'Do ponto de vista regulatório, 2023 foi marcado (i) pelo início da entrada em vigor do novo arcabouço legal de Ofertas Públicas; (ii) pela publicação da Resolução CVM nº 194, de 17 de novembro de 2023; e (iii) pela publicação do Marco Legal das Garantias (Lei nº 14.711).',
      'Já no início de 2024, a Resolução CMN nº 5.118, alterada pela Resolução CMN nº 5.121, apresentou restrições aos lastros de CRI e CRA.',
      '### Novo Arcabouço de Ofertas Públicas',
      'O ano de 2023 foi marcado pela entrada em vigor do novo arcabouço de ofertas públicas, em que se destaca a popularização das ofertas com registro automático para investidores qualificados.',
      'Especificamente para as ofertas de securitização, vale salientar que o Ofício Circular nº 7/2023/CVM/SER confirmou a possibilidade de abertura de ofertas distintas para cada série, adequando o cronograma de distribuição de cada uma das séries ao prazo máximo de 180 dias estabelecido na Resolução CVM nº 160.',
      '### Resolução CVM nº 194',
      `A Resolução CVM nº 194 trouxe atualizações normativas importantes na Resolução CVM nº 60, a qual regula as securitizações e companhias securitizadoras de direitos creditórios, harmonizando a norma com (i) a Lei da Securitização (Lei nº 14.430), (ii) a norma de ofertas (Resolução CVM nº 160) e (iii) a norma de fundos (Resolução CVM nº 175). 
      Entre as principais alterações, podemos destacar os seguintes pontos:`,
      '*Possibilidade da Revolvência em CRI*',
      'Para o mercado imobiliário, a principal boa notícia foi a possibilidade da realização de operações com revolvência, o que trará novas perspectivas na estruturação de operações pulverizadas, como as decorrentes de loteamentos, empreendimentos residenciais, shopping centers e galpões logísticos.',
      `Com a publicação da Lei nº 14.430, a revolvência passou a ser permitida em operações de securitização em geral, ou seja, para direitos creditórios originados em qualquer segmento econômico, o que anteriormente era permitido somente para operações de Certificados de Recebíveis do Agronegócio (CRA). 
      No entanto, o Anexo Normativo I da Resolução CVM nº 60 ainda proibia a revolvência nas operações de Certificados de Recebíveis Imobiliários (CRI). 
      A "revolvência" ocorre quando os recursos gerados pelos direitos creditórios e outros ativos que lastreiam uma emissão de títulos de securitização são utilizados para adquirir novos direitos creditórios.`,
      '*Cooperativas como Grandes Devedoras ou Coobrigadas em Operações de CRA*',
      'Para o agronegócio, o ponto de destaque foi a possibilidade de cooperativas de produtores utilizarem as suas demonstrações financeiras regulatórias nas emissões Certificados de Recebíveis do Agronegócio - CRA.',
      `Até então, um devedor ou coobrigado que não fosse companhia aberta, instituição financeira ou equiparada somente poderia representar mais do que 20% do valor de uma emissão caso tivesse suas demonstrações financeiras relativas ao exercício social imediatamente anterior à emissão elaboradas, em conformidade com o disposto na Lei das S.A., e auditadas por auditor independente registrado na CVM. 
      Com a nova regra, a CVM permitiu que as cooperativas agropecuárias, cujas demonstrações financeiras são elaboradas com base em legislação específica, possam ser devedoras ou coobrigadas acima do limite de 20%, desde que suas demonstrações financeiras tenham sido auditadas por auditor independente registrado na CVM.`,
      '*Aumento da Periodicidade para Atualização da Classificação de Risco*',
      'Aumento do prazo mínimo de atualização da classificação de risco de 3 para 12 meses, ou conforme definido no instrumento de emissão, visando à redução de custos de operação.',
      '*Exclusão da Limitação da Atuação do Agente Fiduciário*',
      'A Resolução CVM nº 194 excluiu, ainda, a vedação ao agente fiduciário ou partes a ele relacionadas de prestar quaisquer outros serviços para a emissão possibilitando a concentração, por exemplo, das atividades de agente fiduciário e custodiante em uma mesma operação.',
      '### Marco Legal das Garantias',
      'A publicação da Lei nº 14.711, de 30 de outubro de 2023, também trouxe inovações importantes na estruturação de garantias.',
      'A norma possibilita alternativas ao problema do capital morto no financiamento imobiliário, ao permitir (i) a Alienação Fiduciária (AF) superveniente, (ii) a extensão ou recarregamento da AF ou da hipoteca, (iii) a designação de um agente de garantia e (iv) a execução extrajudicial da hipoteca, procedimento novo que revoga o Decreto-Lei nº 70, de 21 de novembro de 1966.',
      '### Resolução CMN nº 5.118 e Resolução CMN nº 5.121',
      'Por fim, ainda que o Anuário trate do ano de 2023, não podemos deixar de mencionar os impactos da Resolução CMN nº 5.118, que delimitou o lastro para operações de CRI e CRA no mercado.',
      'Como principais pontos de alteração, destacam-se a vedação de emissões de CRA e CRI: (i) com lastro em títulos de dívida de emissão de companhias abertas não relacionadas aos setores do agronegócio ou imobiliário; (ii) com lastro em direitos creditórios oriundos de operações entre partes relacionadas; e (iii) com lastro em direitos creditórios decorrentes de operações financeiras cujos recursos sejam utilizados para reembolso de despesas.',
      'A Resolução CMN nº 5.121 trouxe esclarecimentos pontuais à Resolução CMN nº 5.118, mas manteve pontos polêmicos, como a vedação às operações com reembolso de despesas.',
      '### Perspectivas para 2024',
      'Do ponto de vista regulatório, há uma expectativa do mercado sobre eventuais ajustes ou flexibilizações da Resolução CMN nº 5.118.',
      `Em 1º de julho de 2024, também se encerra o prazo para a adaptação das companhias securitizadoras à nova versão do Código de Ofertas Públicas da ANBIMA (Código de Ofertas Públicas). 
      Um dos pontos de maior impacto que a nova autorregulação trouxe foi a obrigatoriedade de contratação de assessores legais distintos para representar os interesses do coordenador líder (e demais coordenadores) e dos emissores (ou ofertantes/cedentes/devedores). 
      Esse requisito apenas se torna dispensável em ofertas públicas de renda fixa e de securitização destinadas a investidores profissionais.`,
      'Do ponto de vista de estruturação de operações, já vimos as primeiras operações de CRI com revolvência e CRA tokenizados e esperamos a retomada do crescimento das operações de CRI e CRA, bem como um número mais expressivo de operações com CR.',
    ],
  },
];

export const crsYearbookDescriptions: YearbookDescriptions = {
  primary: [
    'O Anuário Uqbar de CRs de 2024 apresenta uma análise detalhada do desempenho do mercado primário de Certificados de Recebíveis Imobiliários (CRIs) e do mercado de Certificados de Recebíveis do Agronegócio (CRAs) durante o ano de 2023, abordando diversas facetas que vão desde o perfil de risco, categorias de devedores, até as características dos investidores e a composição do lastro.',
    `
    O setor de CRIs demonstrou robustez e resiliência ao superar novamente a marca de R$ 50 bilhões em emissões, refletindo a confiança contínua no mercado imobiliário como um veículo atrativo para a captação de recursos. 
    Apesar de uma leve retração em comparação com o ano anterior, a diversidade nas operações e a inovação na estruturação dos produtos continuam a impulsionar o mercado. 
    A análise detalha constâncias significativas, como a concentração de risco, a predominância de pessoas jurídicas como principais devedores, e alterações nas preferências por indexadores de remuneração e variações nos prazos das operações, além de explorar a composição do ativo-lastro e segmentos imobiliários mais ativos.
  `,
    `Em paralelo, o mercado de CRAs, apesar de não ultrapassar o recorde do ano anterior, demonstrou vitalidade e capacidade de adaptação frente a desafios econômicos e setoriais. 
    As emissões refletem a relevância do agronegócio para a economia, com destaque para o segmento de grãos e a emergente atenção aos princípios ESG, apesar de sua representação ainda tímida. A análise cobre também o perfil dos investidores e as mudanças nos prazos de vencimento dos CRAs, evidenciando as preferências e tendências no mercado.`,
    `Em relação aos Certificados de Recebíveis (CRs), atualmente, houve um total de 7 operações, das quais 4 ocorreram em 2023. 
    O montante total emitido até o momento é de apenas R$ 1,02 bilhão, sendo que R$ 120,0 milhões correspondem a operações realizadas em 2023. 
    As securitizadoras que emitiram CRs em 2023 foram: Octante, Opea, Habitasec e Gaia Impacto. 
    A Opea foi responsável pela maior parcela desse valor, com R$ 46,0 milhões em CRs.`,
    `Apesar da amostra ser limitada, observa-se certa diversidade tanto nas entidades emissoras quanto nas finalidades dos recursos arrecadados. 
    Isso evidencia a versatilidade dos certificados de recebíveis como instrumento de financiamento. 
    O volume e a destinação dos recursos variam significativamente entre cada operação abrangendo desde reforço do caixa e alongamento do passivo até reperfilamento das dívidas e reposição de investimentos.`,
  ],
  secondary: [
    `
    Na seção dedicada ao mercado secundário, destacamos a performance notável tanto do CRI quanto do CRA, evidenciando o crescente interesse e a confiança dos investidores nesses instrumentos. 
    A expansão observada reflete uma combinação de fatores, incluindo a busca por diversificação de portfólio, a atração por rendimentos competitivos e a percepção de um risco relativamente controlado, considerando as garantias e a natureza dos ativos subjacentes.
    `,
    `
    Como naturalmente é feito, cabe um disclaimer ao que é considerado "secundário" pela Uqbar quando se trata de mercado de balcão organizado. 
    Quanto mais distantes do momento de suas respectivas emissões, mais as transações dos títulos estão associadas com um mercado secundário genuíno. Isto justifica, assim, para o benefício de uma análise de melhor discernimento, a segmentação, por intervalo de tempo entre emissão e negociação, das transações realizadas de CRI e CRA.
    `,
    `
    De forma geral, subentende-se que as negociações registradas de CRI e CRA ocorridas com intervalo de tempo entre a emissão do título e sua negociação inferior a 180 dias constituem transações mais relacionadas ao mercado primário. 
    Por outro lado, embora existam exceções, supõe-se que a maioria das negociações ocorridas com intervalo de tempo, desde a emissão, de mais de 180 dias esteja genuinamente associada ao mercado secundário.
    `,
  ],
  stock: [
    `
    Os CRI e os CRA representam instrumentos vitais de financiamento nos seus respectivos setores, oferecendo, também, uma oportunidade para investidores acessarem ativos de renda fixa atrelados ao mercado imobiliário e agronegócio. 
    Esses títulos de securitização, pela sua natureza de mais longo prazo, desempenham um papel crucial na dinâmica do estoque, estendendo os efeitos de incrementos anuais em emissões e depósitos ao longo do tempo. 
    Diante da aceleração observada no montante emitido de CRI e CRA ao longo dos anos e considerando que o prazo médio desses títulos se aproxima de uma década - no caso dos CRI, é de 6 anos - no caso dos CRA, é esperado um aumento gradativo no saldo devedor de ambos. 
    Esta seção busca oferecer uma perspectiva sobre a evolução dos saldos devedores dos CRI e CRA, apontando para recordes e tendências de crescimento que refletem o vigor e a maturação desses mercados ao final de 2023..
`,
    `
    Em específico, observaremos o desempenho e a evolução do saldo devedor dos CRI, que alcançou um recorde no fim de 2023, ilustrando a crescente confiança e o interesse dos investidores nesses instrumentos. 
    Além disso, será abordado o estoque do mercado de CRA, que superou a marca de R$ 100 bilhões, evidenciando um crescimento substancial e a importância crescente desse mercado no financiamento do agronegócio brasileiro. 
    Essas tendências não apenas demonstram a robustez dos mercados de CRI e CRA, mas também sinalizam a potencialidade desses instrumentos na canalização de recursos para setores chave da economia.
`,
  ],
  rankings: [
    `Os rankings Uqbar do anuário de Certificados de Recebíveis, tanto Imobiliários (CRI) quanto do Agronegócio (CRA), em 2023, desvendam um panorama rico e detalhado do desempenho desses importantes instrumentos financeiros.
     Por mais este ano, os dados compilados não apenas ilustram a vitalidade e a dinâmica do mercado de securitização brasileiro, mas também destacam as entidades-chave que lideraram em diversas categorias, refletindo suas estratégias de sucesso, inovação e influência no setor.
     Através de uma série de rankings cuidadosamente elaborados, é possível identificar as tendências de mercado, os principais players e as nuances operacionais que caracterizam tanto o mercado de CRI quanto o de CRA.
     Dentre os destaques, a liderança da Opea em montante emitido de CRI e a robustez da EcoSec no mercado de CRA sobressaem, evidenciando o crescimento e a consolidação desses mercados.
     Além disso, a participação significativa de importantes agentes fiduciários, advogados, e agências de classificação de risco reflete a complexidade e a sofisticação crescentes do mercado de securitização no Brasil.`,

    `Nesse contexto, os rankings também trazem à tona a importância da inovação e da especialização no desenvolvimento dos mercados de CRI e CRA, revelando como diferentes instituições contribuem para a expansão e a diversificação do setor.
       Com a presença destacada de empresas como a Oliveira Trust, que liderou em várias categorias de agentes fiduciários, e a atuação marcante de escritórios de advocacia e distribuidores, os dados do anuário permitem uma compreensão abrangente das estratégias que impulsionam o mercado.
       Em suma, o anuário Uqbar de 2023 destila insights valiosos sobre o estado atual e as perspectivas futuras dos mercados de CRI e CRA, sublinhando o papel essencial desses instrumentos no financiamento dos setores imobiliário e agrícola no Brasil.`,
  ],
};

export const crsPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: crsPrimaryMarketCharts.emissionsCri,
    name: 'Emissões de CRI',
    section: 'primary',
    details: {
      title: 'Pela segunda vez, emissões de CRIs superam R$ 50 bi',
      texts: [
        `
        O mercado primário de CRI vem apresentando um desempenho cada vez mais positivo, consolidando-se como uma importante alternativa de captação de recursos para o setor imobiliário. 
        Em 2023, o mercado primário de CRIs atingiu a cifra de R$ 50,91 bilhões, distribuídos em 392 operações. 
        Comparativamente, em 2022 os números haviam sido de R$ 51,52 bilhões e 452 operações. 
        Isso representa uma queda de 1,63% e de 13,71% frente aos números-recorde de 2022, respectivamente.
        `,
        `
        Além do volume financeiro, e do número de operações, o número de títulos emitidos também é uma métrica importante. 
        Em 2023, esse número chegou a 699, contra 821 no ano anterior.
        `,
      ],
      value: 50.91,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCriByCreditRisk,
    name: 'Emissões de CRI por Tipo de Risco de Crédito',
    section: 'primary',
    details: {
      title: 'Risco de Crédito dos CRIs se concentra ainda mais em 2023',
      texts: [
        `
        Os CRIs de risco de crédito concentrado, os quais, é possível inferir, têm perfil corporativo, continuam sendo maioria em mais um ano, com um aumento de 6,57 pontos percentuais em relação ao ano anterior (de 81,66% para 88,29%). 
        Esse fato demonstra que o ponto focal do mercado ainda está na securitização imobiliária de cunho corporativo, se contrastando com o cenário onde os CRIs pulverizados, e, portanto, de perfil residencial, predominam. 
        Contudo a Resolução CMN nº 5.118 de 01 de fevereiro de 2024, pode alterar esse perfil do histórico, trazendo, ao longo dos próximos anos, um mercado com perfil menos concentrado em suas operações.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCriByDebtor,
    name: 'Emissões de CRI por Tipo de Devedor',
    section: 'primary',
    details: {
      title:
        'Pessoa Jurídica é a principal categoria de devedor do lastro dos CRIs',
      texts: [
        `
        Quando se olha para o horizonte dos últimos cinco anos, Pessoa Jurídica mantém a liderança como a principal categoria de devedores dos lastro dos CRIs. 
        O ano de 2023 foi marcado por 87,60% do montante relacionado a pessoas jurídicas. Em segundo lugar estão as pessoas físicas, representando 7,2% das emissões. 
        Pelo menos sob esta perspectiva, houve um aumento da participação do montante dos CRIs devidos por pessoas físicas, alcançando o maior percentual desde 2020.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCriByRemuneration,
    name: 'Emissões de CRI por Tipo de Remuneração',
    section: 'primary',
    details: {
      title: 'IPCA perde força na remuneração dos CRIs',
      texts: [
        `
        No mercado de CRIs, o índice IPCA se mantém como principal indexador utilizado nas estruturas de remuneração dos investidores, porém com margem sustentada por 3 p.p. em relação ao CDI. 
        A remuneração indexada a inflação esteve presente em 46,7% do montante emitido dos CRIs em 2023. 
        Já a Taxa CDI esteve presente em 43,4% da cifra, representando um aumento de 4,29 p.p. em relação ao ano anterior. 
        Além disso, foi registrado um percentual de 7,71% de CRIs com taxas prefixadas.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCriByDeadlineRange,
    name: 'Emissões de CRI por Faixa de Prazo',
    section: 'primary',
    details: {
      title: 'Prazos mais longos voltam a predominar nas operações CRIs',
      texts: [
        `
        Comparando 2023 com anos anteriores, é evidente o crescimento da preferência por CRIs de médio prazo (de 2 a 5 anos), enquanto as faixas de maior e menor prazo (acima de 10 anos e até 2 anos, respectivamente) mostram dinâmicas variadas, com o curto prazo ganhando destaque em 2023. 
        A faixa de 5 a 10 anos experimentou uma leve retração em 2023, após o pico do ano anterior, sugerindo uma redistribuição das preferências dos investidores. 
        Estas tendências refletem não apenas o apetite dos investidores por diferentes horizontes de investimento, mas também podem indicar mudanças nas condições de mercado, taxas de juros e percepções de risco.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.operationsCriByAssetBacking,
    name: 'Número de Operacões de CRI por Ativo Lastro',
    section: 'primary',
    details: {
      title: 'Reembolso amplia crescimento como ativo-lastro de CRI',
      texts: [
        `
        Em 2023, o Crédito Imobiliário continua sendo o líder em termos de ativo-lastro, assim como nos anos anteriores, representando 63,85% das emissões. 
        Contudo, CRIs lastreados em Reembolso de despesas ampliaram a participação em 6,28 p.p., passando a representar 20% das emissões do período. 
        É interessante notar que os CRIs lastreados em Aluguéis continuam perdendo espaço na medida em que o Reembolso cresce.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCriByRealEstateSegment,
    name: 'Emissões de CRI por Segmento Imobiliário',
    section: 'primary',
    details: {
      title: 'Segmento de Apartamentos e Casas predomina entre CRIs de 2023',
      texts: [
        `
        Em 2023, os CRIs emitidos pertencentes ao segmento Apartamentos e Casas mantiveram sua posição dominante no mercado, representando cerca de 26,96% da cifra total emitida. 
        Esse resultado se manteve semelhante ao de 2022, demonstrando a força e a estabilidade desse segmento no mercado imobiliário.
        `,
        'Em segundo lugar, o segmento de Lajes Corporativas mantém a posição alcançada em 2022, superando o segmento de Varejo - Shoppings, Lojas por 5,23 p.p..',
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.investorsProfileCri,
    name: 'Perfil dos investidores de CRIs em 2023',
    section: 'primary',
    details: {
      title: 'Perfil dos investidores de CRIs em 2023',
      texts: [
        `
        No ano de 2023, as pessoas físicas, as Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio e os fundos de investimentos foram responsáveis por 89,22% do montante adquirido de CRI. 
        A distribuição correspondente desses investidores foi de 44,1%, 29,96% e 15,16%, respectivamente.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCra,
    name: 'Emissões de CRA',
    section: 'primary',
    details: {
      title: 'Emissões de CRA em 2023 não ultrapassa o recorde do ano anterior',
      texts: [
        `
        O ano de 2023 para o mercado primário de CRAs começou devagar, acelerando no segundo trimestre. 
        No período, o montante emitido foi de R$ 43,59 bilhões, uma queda de 2,57% em relação ao recorde de 2022, que era em torno de R$44,72 bilhões. 
        Considerando as operações, a diminuição foi de 238 para 177, o que equivale a uma queda de mais de 27,33% na comparação com o ano anterior.
        `,
      ],
      value: 43.6,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByCreditRisk,
    name: 'Emissões de CRA por Tipo de Risco de Crédito',
    section: 'primary',
    details: {
      title: 'Risco de Crédito se mantém concentrado',
      texts: [
        `
        Nos últimos anos, houve uma preferência por emissões com tipo de risco concentrado em vez do pulverizado. 
        Em 2023, o risco pulverizado representou 2,37% e o risco concentrado representou 97,63%.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByDebtor,
    name: 'Emissões de CRA por Tipo de Devedor',
    section: 'primary',
    details: {
      title: 'Perfil dos devedores do lastro dos CRAs',
      texts: [
        `
        Assim como em anos anteriores, a distribuição em 2023 ficou concentrada nas Pessoas Jurídicas, que representaram 96,41% das emissões,o montante de 2023 já bate R$ 42,02 bi, o que representa 1,56 p.p. a menos do que 2022.
        `,
        'Os devedores Pessoas Físicas corresponderam a 2,89% do total em 2023, o que equivale a R$1,26 bilhões.',
        'Por fim, temos a categoria Multidevedor (pessoas físicas e pessoas jurídicas devedoras do mesmo título), correspondendo a 0,7%, alcançando a cifra de R$304,39 mi no mercado.',
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByBallastContract,
    name: 'Emissões de CRA por Tipo de Contrato Lastro',
    section: 'primary',
    details: {
      title: 'O principal contrato lastro dos CRAs são Debêntures',
      texts: [
        `
        Repetindo 2022, o lastro dos CRAs foi, em sua maior parte, cerca de 40% do total emitido, representado por debêntures em 2023. 
        As Letras Financeiras registraram 26%, enquanto as CPRs Financeiras registraram 16%.
        `,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByRemuneration,
    name: 'Emissões de CRA por Tipo de Remuneração',
    section: 'primary',
    details: {
      title: 'CDI foi o principal indexador dos CRAs',
      texts: [
        `
       Na estrutura dos CRAs, a remuneração utilizada é principalmente a Taxa CDI, representando 59,22% do montante total emitido, ou R$ 25,8 bilhões. 
       Isso representa um aumento de 35,49% em comparação com o montante de R$ 19,05 bilhões registrado no ano de 2022. 
       Em seguida, temos o IPCA, que corresponde a 19,57% da cifra de 2023, indicando uma queda de 32,39% em relação ao montante total emitido em 2022 do IPCA. 
       Observa-se que o IPCA perdeu força em 2023 se comparado aos anos anteriores.
        `,
        `Os CRAs com variação cambial ganharam um pouco mais de destaque em 2023 representando cerca de 3,79% do montante total emitido. 
        Enquanto as emissões atreladas à Taxa Fixa representam, aproximadamente, 17,44%.`,
        'Em resumo foram contabilizados um total de 306 títulos em 2023: sendo 215 títulos referentes à Taxa CDI; 52 títulos relacionados à Taxa Fixa e 31 vinculados ao IPCA; e por fim 8 títulos referenciados à Taxa Cambial.',
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.operationsCraBySegment,
    name: 'Número de Operações CRA por Segmento',
    section: 'primary',
    details: {
      title: 'Segmento de Grãos é destaque em 2023',
      texts: [
        `
        As emissões de CRAs para o segmento de Grãos atingiram a cifra de R$ 14,36 bilhões em 38 operações, quase dobrando o montante emitido em 2022, que foi de R$ 7,46 bilhões em 35 operações - um aumento percentual de 92,49%. 
        O exponencial crescimento do instrumento de financiamento CRA utilizado no segmento de Grãos está relacionado à safra recorde em 2023 de milho, soja e outras culturas.
        `,
        `O segmento Diversos ficou em segundo lugar com R$11,63 bilhões computados, representando 26,68% do montante total emitido e distribuído em 32 operações. 
        As Atividades Pecuárias, que bateram recorde em 2022, aparecem em terceiro lugar para o ano de 2023, somando um montante emitido de R$6,05 bilhões distribuídos entre 16 operações.`,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByEsgCertificate,
    name: 'Emissões de CRA por Certificado ESG',
    section: 'primary',
    details: {
      title: 'CRA ESG - tem tímida representação',
      texts: [
        'A representação de CRAs ESG em 2023 foi limitada, com apenas 8 títulos demonstrando preocupação com o meio ambiente, responsabilidade social e impacto socioambiental.',
        'As emissões de CRAs ESG movimentaram R$ 2,79 bilhões em 2023, o que equivale a 6,34% do total emitido.',
        `Emissões com certificado CBI representaram 3,56%, enquanto as emissões com mais de um princípio compreenderam 2,30%. 
        As emissões com certificado GBP atingiram apenas 0,45%, e aquelas relacionadas ao impacto socioambiental alcançaram meros 0,03%.`,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.investorsProfileCra,
    name: 'Perfil de Investidores de CRA',
    section: 'primary',
    details: {
      title: 'Perfil do Investidor',
      texts: [
        `Na ponta compradora, os investidores Pessoas Físicas continuam sendo a maioria, acumulando um total impressionante de R$ 27,54 bilhões em investimentos, o que representa 67,96% do mercado. 
        Fundos de investimento somaram 10,61%, perfazendo R$ 4,30 bilhões. 
        As Instituições financeiras ligadas à emissora e/ou aos participantes do consórcio somaram R$ 4,13 bilhões, ou 10,19% do mercado.`,
      ],
    },
  },
  {
    chart: crsPrimaryMarketCharts.emissionsCraByDeadlineRange,
    name: 'Emissões de CRA por Faixa de Prazo',
    section: 'primary',
    details: {
      title: 'Mais de 30% dos CRAs possuem vencimentos entre 100 e 125 meses',
      texts: [
        `Os prazos de duração dos CRAs em 2023 mostraram um aumento significativo nas faixas de 100 a 125 meses, com um acréscimo de 12 pontos percentuais em comparação com o ano de 2022. 
        Por outro lado, houve uma diminuição de 3,51 pontos percentuais na faixa de 175 a 200 meses. 
        A faixa de 50 a 75 meses teve pouca variação, registrando uma queda de apenas 1,48 pontos percentuais.`,
      ],
    },
  },
];

export const crsSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: crsSecondaryMarketCharts.criNegotiations,
    name: 'Negócios com CRI',
    section: 'secondary',
    details: {
      title:
        'Número de negociações no mercado secundário de CRI quase dobra em 2023',
      texts: [
        `
        O mercado secundário de CRI manteve o crescimento em 2023, com um montante negociado total de R$ 80,6 bilhões, um aumento de 10,5% em relação a 2022. 
        Comparado a 2021, houve um crescimento de 100,68%, correspondente a R$ 40,4 bilhões. 
        Em comparação com 2020, o crescimento foi de 388,78%, equivalente a R$ 78,97 bilhões, e em relação a 2019, houve um aumento de 470,7%, correspondente a R$ 66,49 bilhões.
        `,
        `
       Além disso, o número de negócios realizados no mercado secundário de CRI também cresceu em 2023, atingindo a marca de 344.287, um aumento de 91,3% em relação a 2022. 
       Em 2021, foram realizados 103.730 negócios, 231,91% menor do que em 2022. 
       Em 2020, foram registrados 73.753 negócios, 270.534 negócios a menos do que em 2023, um aumento de 366,81%. 
       Em 2019, foram realizados 61.438 negócios, o que representa um aumento de 460,38% em relação a 2023.
        `,
      ],
      value: 80.6,
      valueTitle: 'Montante Negociado 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: crsSecondaryMarketCharts.craNegotiations,
    name: 'Negócios com CRA',
    section: 'secondary',
    details: {
      title: 'Negócios com CRAs',
      texts: [
        `
        Em 2023, o montante negociado total no mercado secundário de CRAs mais do que dobrou em relação ao ano anterior, atingindo a impressionante cifra de R$ 46,38 bilhões. 
        Isso representou um aumento de 106,5% em comparação com os R$ 22,46 bilhões do ano anterior.
        `,
        `
        O número de negócios foi ainda mais surpreendente em comparação com o ano anterior, registrando um crescimento de 158,81% no volume negociado e alcançando o recorde de 469.414 transações em 2023. 
        Esse número superou significativamente o recorde anterior de 181.372 transações registrado em 2022.
        `,
        `
        Os meses de março e maio se destacaram como os períodos com os maiores montantes negociados em 2023, totalizando R$ 6,25 bilhões e R$6,30 bilhões respectivamente.
        `,
      ],
      value: 46.4,
      valueTitle: 'Montante Negociado 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const crsStock: YearbookMarketChartInfo[] = [
  {
    chart: crsStockCharts.stockCri,
    name: 'Estoque de CRI',
    section: 'stock',
    details: {
      title: 'Saldo Devedor dos CRI alcança recorde no fim de 2023',
      texts: [
        `O gráfico apresenta o saldo devedor dos CRI em dezembro de cada ano, desde 2018 até 2023. 
        Observa-se que houve um crescimento significativo ao longo dos anos, de modo que o saldo devedor dos CRI mais do que duplicou no período entre 2018 e 2023.
        `,
        `
        É evidente um crescimento constante no estoque de CRI ao longo dos anos. 
        Em dezembro de 2018, o estoque foi de R$73,8 bilhões e subiu para R$83,0 bilhões em dezembro de 2019 - um aumento de 12,4%. 
        O valor aumentou ligeiramente para R$83,7 bilhões em dezembro de 2020 (um acréscimo de apenas 0,9%). 
        Por fim, alcançou os R$ 163,6 bilhões em dezembro de 2023.`,
      ],
      valueTitle: 'Saldo devedor em dez/23',
      value: 163.6,
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: crsStockCharts.stockCra,
    name: 'Estoque de CRA',
    section: 'stock',
    details: {
      title: 'Estoque CRA supera os R$ 100 bi',
      texts: [
        `O estoque do mercado de CRAs atingiu, ao final de 2023, um saldo devedor consolidado de R$ 120,49 bilhões. 
        Isso se compara com os R$ 97,69 bilhões auferidos em dezembro de 2022. 
        Assim, depreende-se um crescimento de 23,34% na comparação.
        `,
        `
        Ao mesmo tempo, esse indicador evidencia o relativo curto prazo dos CRA emitidos. 
        Entre dezembro de 2020 e dezembro de 2023 o saldo devedor dos CRA saltou 155,22%.
        `,
      ],
      valueTitle: 'Saldo devedor em dez/23',
      value: 120.5,
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const crsRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking de Operações de CRI por Montante Emitido em 2023',
    details: {
      title: 'Ranking de Operações de CRI por Montante Emitido em 2023',
      texts: [
        `O ranking Uqbar de Operações de CRI por montante emitido em 2023 possui, como emissor das operações nas três primeiras posições, a Opea.
        Os três CRIs possuem destinação atrelada ao reembolso de despesas já incorridas e correspondem a 9,4% do montante total emitido do período.
        Em primeiro lugar, encontra-se a emissão 173ª, com o montante de R$ 1,8 bilhão e dividida em quatro séries lastreadas em direitos creditórios devidos pelo Banco Bradesco.
        Na segunda posição, a emissão 176ª.
        Ela está dividida em três séries e perfaz um total de R$ 1,51 bilhão, sendo lastreado em direitos creditórios devidos pela Rede D’Or.
        Em terceiro lugar fica a emissão 234ª, dividida em quatro séries, que correspondem a um total de R$ 1,5 bilhão e é lastreada em direitos creditórios devidos pela Banco Itaú.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=operation&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras por Montante Emitido em 2023',
    details: {
      title: 'Ranking de Securitizadoras por Montante Emitido em 2023',
      texts: [
        `A Opea é a securitizadora que emitiu o maior montante de CRI em 2023, totalizando expressivos R$ 20,71 bilhões, o que representa 41,32% do total emitido no ano.
        Além disso, a empresa possui três operações dentre as três maiores em montante emitido.
        Em segundo lugar, a True se mantém na mesma posição do ano anterior, com R$ 13,8 bilhões, o que equivale a 25,65% do total.
        Em terceiro lugar, a Virgo aparece com R$ 3,79 bilhões em montante emitido no período, o que representa 7,49% do total.
        A empresa manteve a colocação do ano anterior, porém amargou uma queda de 13,52 p.p..`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitizer&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras por Número de Operações em 2023',
    details: {
      title: 'Ranking de Securitizadoras por Número de Operações em 2023',
      texts: [
        `A tabela exibe o ranking das securitizadoras imobiliárias com base no número de operações emitidas em 2023, conforme o índice elaborado pela Uqbar.
        Em primeiro lugar, figura a securitizadora True, com uma participação de 28,96% e um total de 106 operações emitidas.
        Em seguida, a Opea ocupa o segundo lugar, com uma fatia de mercado de 26,23% e 96 operações.
        Por fim, a Canal fecha o pódio, contribuindo com 10,11% do mercado e 37 operações realizadas.
        Essa classificação fornece uma visão clara da distribuição do volume de operações entre as securitizadoras líderes no setor imobiliário em 2023 e marca a ascensão da Canal ao rol das maiores securitizadoras do mercado.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitizer&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Advogados por Montante Emitido em 2023',
    details: {
      title: 'Ranking de Advogados por Montante Emitido em 2023',
      texts: [
        `O escritório Tauil e Chequer Sociedade de Advogados liderou, em 2023, ranking de Advogados por montante emitido, com R$ 6,81 bilhões, correspondendo a 17,85% do total.
        Machado Meyer ficou em segundo lugar com R$ 4,37 bilhões e 11,83% das emissões.
        E, em terceiro lugar, o escritório Pinheiro Neto, com R$ 4,09 bilhões emitidos e 11,08% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Advogados por Número de Operações em 2023',
    details: {
      title: 'Ranking de Advogados por Número de Operações em 2023',
      texts: [
        `A NFA mantém sua liderança em 2023, tendo assessorado 45 operações de CRI, o que representa 17,51% do total reportado.
        Em segundo lugar está o escritório Tauil e Chequer Sociedade de Advogados, com 12,45% de participação e 32 operações.
        Já Machado Meyer, Pinheiro Neto e FreitasLeite ocupam os lugares a partir da terceira posição, com 8,56% de participação e um total de 22 operações.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Montante Classificado em 2023',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Montante Classificado em 2023',
      texts: [
        `Em 2023, assim como no ano anterior, a Fitch, a S&P e a Moody’s mantiveram as três primeiras posições no ranking de agências de classificação de risco.
        A Fitch classificou 68,9% das emissões, o que equivale a um montante de R$ 11,14 bilhões.
        Enquanto isso, a S&P e a Moody's ficaram com fatias menores ao classificar 21,23% e 8,88% das emissões respectivamente, representando R$ 3,43 bilhões e R$ 1.44 bilhões em suas classificações.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=agencyrating&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados em 2023',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados em 2023',
      texts: [
        `Em 2023, a Fitch manteve a primeira possição no ranking de agências de classificação de risco em relação ao número de títulos classificados alcançada, classificando um total de 50 operações, o que representa 58,14% das classificações realizadas no período.
        A S&P e a Moody's ocupam o segundo e terceiro lugar, com 22 e 11 operações classificadas, respectivamente, representando 25,58% e 12,79% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=agencyrating&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Montante Emitido de CRI em 2023',
    details: {
      title:
        'Ranking de Agentes Fiduciários por Montante Emitido de CRI em 2023',
      texts: [
        `No ranking de Agentes Fiduciários por montante emitido de CRI em 2023, foram ranqueados 7 empresas, totalizando R$ 49,86 bilhões.
        A líder desse ranking é a Oliveira Trust, sendo agente fiduciário de CRI que somam R$ 25,80 bilhões em emissões, correspondendo a 51,7% do total.
        Comparado ao ano anterior, a empresa teve um aumento de 10,73% no montante ranqueado (R$ 23,28 bilhões), o que a colocou como líder do ranking.
        Em segundo lugar, temos a Pentágono, com um montante de R$ 9,61 bilhões, ou seja, 19,27% do total.
        Esse valor é 1,5% menor que o valor auferido pela empresa no ano anterior (R$ 9,76 bilhões).
        A Vórtx ocupa o terceiro lugar do ranking, com um montante de R$ 8,67 bilhões, representando 17,39% do total.
        É importante destacar que concentração do montante emitido dentre os três primeiros colocados sofreu uma queda de 6 p.p.
        frente ao ano anterior.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=fiduciaryagent&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Número de Operações em 2023',
    details: {
      title: 'Ranking de Agentes Fiduciários por Número de Operações em 2023',
      texts: [
        `No ranking de Agentes Fiduciários por Número de Operações de CRI em 2023, temos 7 participantes no pódio.
        A Oliveira Trust mantém a liderança no ranking com 225 operações, representando 61,81% do total.
        A Vórtx, que liderou o ranking em 2021, mantém o 2º lugar com 71 operações, ou 19,51% do total.
        A Commcor avança para a 3ª posição, com 28 operações, ou 7,69%, após ter ocupado a 4ª colocação e 2022.
        A Pentágono e a Planner Corretora ficam na 4ª e 5ª posição, com 24 e 9 operações, respectivamente, ou 6,59% e 2,47% do total.
        É importante notar que 89% do total de operações estão concentrados nas instituições Oliveira Trust, Vórtx e Commcor.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=fiduciaryagent&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Líderes de Distribuição por Montante Emitido de CRI em 2023',
    details: {
      title:
        'Ranking de Líderes de Distribuição por Montante Emitido de CRI em 2023',
      texts: [
        `A tabela apresenta o ranking Uqbar de líderes de distribuição de CRI por montante emitido em 2023.
        O Banco Itaú BBA lidera o ranking com uma participação de 18,85%, representando um montante de R$ 8,75 bilhões.
        Em segundo lugar está a XP Investimentos, com uma parcela de 14,99%, equivalente a R$ 6,95 bilhões.
        Por fim, em terceiro lugar, está a Terra Investimentos, com uma participação de 12,58% e um montante emitido de R$ 5,84 bilhões.
        Esses números refletem a importância e a posição dessas instituições no mercado de distribuição de CRIs durante o período analisado.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=distributor&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Líderes de Distribuição por Número de Títulos em 2023',
    details: {
      title: 'Ranking de Líderes de Distribuição por Número de Títulos em 2023',
      texts: [
        `Para o Ranking de Líderes de Distribuição foram computados 304 títulos em 2023, uma queda de 17,16% em relação a 2022.
        A Terra Investimentos lidera com 37 títulos, seguido pelo Itaú BBA com 23 títulos.
        A Opea está em terceiro lugar com 19 títulos.
        A XP Investimentos e a True ocupam a 5ª e a 6ª posição respectivamente.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=distributor&criteria=titlesnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Servicers por Montante Emitido de CRI em 2023',
    details: {
      title: 'Ranking de Servicers por Montante Emitido de CRI em 2023',
      texts: [
        `O ranking de Servicers de CRIs totalizou R$ 10,39 bilhões e se dividiu em 20 servicers.
        Cerca de 47,14% da cifra está concentrada nos 3 primeiros.
        Neo Service lidera com R$ 2,40 bilhões (22,02%), seguida pela Conveste Serviços Financeiros na segunda posição, com R$ 1,55 milhões (14,19%).
        MRV fica em 3º com R$ 1,42 bilhões (13,02%).
        Planeta Serv e Maximus completam o top 5.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=servicer&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de servicers por número de operações de CRI em 2023',
    details: {
      title: 'Ranking de servicers por número de operações de CRI em 2023',
      texts: [
        `O ranking apresenta 127 operações e 20 servicers.
        A Neo Service lidera com 29,46% do total, seguida pela Maximus (10,85%) e a Planeta Serv (8,53%).
        Na 4ª posição, Serv+ e, na 5ª posição, Creditas Soluções Financeiras.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=servicer&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de CRI por montante negociado em 2023 (+180 dias)',
    details: {
      title: 'Ranking de CRI por montante negociado em 2023 (+180 dias)',
      texts: [
        `O mercado secundário de CRI em 2023 negociou um total de R$ 80,67 bilhões, representando um aumento de 25,32% em relação a 2022.
        Um CRI da True (447ª série da primeira emissão) liderou o ranking com R$ 2,88 bilhões, seguida por um CRI da Vert (série 1 da 58ª emissão) com R$ 2,6 bilhões e pelo CRI da série 366ª da quarta emissão da Virgo, com R$ 2,48 bilhão.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitycri&criteria=amountnegotiated&timeRange=yearly',
  },
  {
    name: 'Ranking de CRI por Número de Negócios em 2023 (+180 dias)',
    details: {
      title: 'Ranking de CRI por Número de Negócios em 2023 (+180 dias)',
      texts: [
        `Em 2023, o número de negócios no mercado secundário de CRI cresceu 91,3 % em relação a 2022, totalizando 344.287 negociações.
        A emissão 40ª de série única da True liderou o ranking com 7.703 negociações, seguida pela primeira série da terceira emissão, também da True, com 7.645 negociações, e a série 324 da primeira emissão da Opea com 7.492 negociações.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitycri&criteria=tradesnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras por Saldo Devedor dos CRI',
    details: {
      title: 'Ranking de Securitizadoras por Saldo Devedor dos CRI',
      texts: [
        `O saldo devedor de CRI das securitizadoras atingiu R$ 163,6 bilhões em 2023, marcando um aumento de 12,18% em relação ao ano anterior, quando era R$ 145,8 bilhões.
        As três principais empresas detêm coletivamente 71% desse total: a Opea lidera com R$ 52,1 bilhões; a True fica em segundo lugar com R$ 39,6 bilhões e a Virgo ocupa o terceiro posto com um total de R$ 24,6 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitizer&criteria=debitbalance&timeRange=consolidated',
  },
  {
    name: 'Ranking de Securitizadoras por Número de Operações de CRI em Circulação',
    details: {
      title:
        'Ranking de Securitizadoras por Número de Operações de CRI em Circulação',
      texts: [
        `O ranking das Securitizadoras do mercado de CRI por Número de Operações em circulação envolveu a participação de 31 instituições, resultando em um total de 1620 operações.
        As três primeiras posições foram conquistadas por Opea, com 324 operações, True, com 348 operações e Virgo, com 263 operações.
        É interessante notar que essas três securitizadoras concentraram 57% do total de operações.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitizer&criteria=debitbalance&timeRange=consolidated',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Saldo Devedor de CRI',
    details: {
      title: 'Ranking de Agentes Fiduciários por Saldo Devedor de CRI',
      texts: [
        `O ranking do saldo devedor por Agentes Fiduciários no mercado de CRI envolveu nove empresas e totalizou um montante de R$ 163,60 bilhões.
        A concentração foi alta, com 57% nas três primeiras posições ocupadas pela Oliveira Trust em primeiro lugar com R$ 64,63 bilhões, seguida pela Vórtx com R$ 63,39 bilhões e o Pentágono em terceiro lugar com R$ 28,2 bilhões.
        Se combinarmos a posição da Vórtx com a de sua afiliada dentro do mesmo grupo econômico, Simplific Pavarini, a primeira alcançaria R$ 70,20 bilhões, colocando-a no topo do ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Número de Operações de CRI em Circulação',
    details: {
      title:
        'Ranking de Agentes Fiduciários por Número de Operações de CRI em Circulação',
      texts: [
        `O ranking de Agentes Fiduciários por Número de Operações em Circulação contou com a participação de nove instituições e um total de 1620 operações.
        As três primeiras posições foram ocupadas por Oliveira Trust, com 699 operações em circulação, Vórtx, com 701 operações, e Pentágono, com 128 operações.
        Observa-se que 57% do total de operações está concentrado nessas três securitizadoras.
        Considerando as 74 operações da Simplific Pavarini, a Vórtx teria um total de 775.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=fiduciaryagent&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Auditores de Securitizadoras por Saldo Devedor de CRI',
    details: {
      title: 'Ranking de Auditores de Securitizadoras por Saldo Devedor de CRI',
      texts: [
        `O ranking de Auditores de Securitizadoras por Saldo Devedor do mercado de CRI em 2023 contou com a participação de 16 empresas e totalizou R$ 163,6 bilhões.
        As três principais posições no ranking representam 80% do valor total, sendo ocupadas pela KPMG, com R$ 59,7 bilhões e uma participação de 37%, seguida pela BLB, com R$ 39,8 bilhões e uma fatia de 24%, e pela BDO RCS Auditores Independentes, com um montante de R$ 31,1 bilhões correspondente a 19% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  },
  {
    name: 'Ranking de Auditores de Securitizadoras por Número de Operações de CRI em Circulação',
    details: {
      title:
        'Ranking de Auditores de Securitizadoras por Número de Operações de CRI em Circulação',
      texts: [
        `O ranking de Auditores de Securitizadoras por Número de Operações em Circulação do mercado de CRI em 2023 teve um total de 1620 operações.
        As três primeiras posições foram ocupadas por KPMG, com 402 operações, BLB, com 355 operações e BDO RCS Auditores Independentes, com 337 operações.
        É importante notar que 67% do total de operações está concentrado nessas três empresas de auditoria.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRI&year=2023&ranking=stockauditor&criteria=operations&timeRange=default',
  },
  {
    name: 'Ranking de Operações de CRA por Montante emitido',
    details: {
      title: 'Ranking de Operações de CRA por Montante emitido',
      texts: [
        `No ranking mencionado, as três principais operações se destacam pela sua representatividade no montante total emitido de R$ 43,59 bilhões.
        A liderança é ocupada pela Emissão 92, também conhecida pelo apelido Banco BTG Pactual (ECTP), realizada pela Opea, que alcançou R$ 3,50 bilhões, equivalendo a 8,03% do total.
        Logo em seguida, a Emissão 106, apelidada de Engelhart V e igualmente emitida pela Opea, iguala o montante de R$ 3,50 bilhões e a mesma porcentagem de representatividade.
        A terceira colocação é atribuída à Emissão 218, conhecida como Engelhart VI e também pela Opea, com R$ 2,00 bilhões, o que corresponde a 4,59% do total emitido.
        Assim, essas três emissões somam uma participação de 20,66% no volume total de emissões de 2023, evidenciando sua importância e impacto no cenário financeiro do período.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=operation&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras do Agronegócio por Montante Emitido em 2023',
    details: {
      title:
        'Ranking de Securitizadoras do Agronegócio por Montante Emitido em 2023',
      texts: [
        `No ranking mencionado, referente ao valor global de R$ 43,56 bilhões emitidos por 13 securitizadoras em 2023, as três principais destacam-se pela significativa contribuição ao montante total.
        A Opea lidera com uma emissão de R$ 15,81 bilhões, o que representa 36,29% do total.
        Segue-se a Ecosec, com uma captação de R$ 13,11 bilhões, equivalente a 30,09% do montante global.
        A Virgo também marca presença significativa com R$ 5,94 bilhões, contribuindo com 13,64% para a soma total.
        Essas três securitizadoras, portanto, desempenham um papel fundamental no volume total de emissões do setor em 2023, destacando-se pela expressiva participação no mercado.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitizer&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras do Agronegócio por Número de Operações de 2023',
    details: {
      title:
        'Ranking de Securitizadoras do Agronegócio por Número de Operações de 2023',
      texts: [
        `No ranking mencionado, observamos a presença de diversas instituições que juntas compõem o panorama do mercado. 
        A Ecosec lidera com 58 operações, representando 33,33% do total, seguida pela Opea, com 30 operações que correspondem a 17,24%, e a Virgo, que registra 19 operações, totalizando 10,92%. 
        A Ceres segue, também, com 19 operações (10,92%), enquanto a True contabiliza 18 operações (10,34%). 
        A Canal, com 11 operações, representa 6,32%, e a Vert, com 8, contribui com 4,60%. 
        A Província, realizando 4 operações, chega a 2,30%, e tanto a Octante quanto a Gaia Impacto registram 2 operações cada, somando 1,14% para cada. Reit, Leverage e Travessia encerram a lista, cada uma com uma única operação, equivalendo a 0,57% cada. 
        Essa configuração evidencia uma concentração significativa de 61,50% do total de operações nas três primeiras securitizadoras: Ecosec, Opea e Virgo, destacando a influência predominante dessas entidades no mercado.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitizer&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Montante Emitido de CRA em 2023',
    details: {
      title:
        'Ranking de Agentes Fiduciários por Montante Emitido de CRA em 2023',
      texts: [
        `O ranking em questão revela a atuação de diversas instituições no mercado, com a Oliveira Trust liderando significativamente com R$ 31,47 bilhões, o que corresponde a 72,27% do total.
        Ela é seguida pela Pentágono, que captou R$ 6,67 bilhões, representando 15,32%, e pela Vórtx, com R$ 5,01 bilhões, equivalendo a 11,50% do total.
        Mais abaixo na lista, a Commcor contribui com R$ 357,08 milhões (0,82%), a Trustee DTVM adiciona R$ 26,50 milhões (0,06%), e a Fram Capital fecha com R$ 11,00 milhões, representando 0,03% do total.
        A partir desses dados, podemos concluir que um expressivo 99,09% do total de operações está concentrado nas três primeiras instituições: Oliveira Trust, Pentágono e Vórtx, demonstrando uma forte concentração de atividades nessas entidades.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=fiduciaryagent&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Número de Operações de 2023',
    details: {
      title: 'Ranking de Agentes Fiduciários por Número de Operações de 2023',
      texts: [
        `No ranking analisado, identificamos a Oliveira Trust como líder destacada com 116 operações, representando 67,05% do total. 
        A Vórtx segue com 28 operações, correspondendo a 16,18%, e a Pentágono aparece em terceiro lugar, com 20 operações que somam 11,56% do total. 
        A Commcor também marca presença com 7 operações, totalizando 4,05%, enquanto a Trustee DTVM e a Fram Capital contribuem com uma operação cada, representando 0,58% cada uma. 
        A partir dessas informações, fica evidente que uma grande maioria, ou seja, 94,79% do total de operações, é concentrada nas três primeiras instituições: Oliveira Trust, Vórtx e Pentágono, demonstrando a predominância destas no mercado em questão.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=fiduciaryagent&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Montante Emitido em 2023',
    details: {
      title: 'Ranking de Assessores Jurídicos por Montante Emitido em 2023',
      texts: [
        `Com R$ 36,67 bilhões no total e vinte e cinco ranqueados, onde temos uma concentração de 54,15% entre os três primeiros do ranking.
        A escala traz Machado Meyer como líder do ranking por montante emitido, com R$ 8,53 bilhões.
        Na sequência, Pinheiro Neto, com R$ 6,56 bilhões.
        Na terceira posição figura o Tauil e Chequer Sociedade de Advogados, com R$ 4,77 bilhões.
        Stocche Forbes é a quarta posição do ranking, tendo atingido R$ 3,50 bilhões no quesito do montante emitido.
        Lobo & De Rizzo Advogados posiciona-se na quinta posição do ranking, com a cifra de R$ 3,11 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Número de Operações de 2023',
    details: {
      title: 'Ranking de Assessores Jurídicos por Número de Operações de 2023',
      texts: [
        `
        Pelo citado ranking, avistamos vinte e cinco Assessores Jurídicos e 148 operações, de onde detalharemos os cinco primeiros, segue: 
        `,
        `
        A Pinheiro Neto foi a primeira colocada, com 28 operações.
        Seguida por FLH Advogados com 25 operações.
        Logo após, Santos Neto Advogados com 25 operações.
        Na 4ª posição, Machado Meyer com 15 operações.
        E, na 5ª posição, Tauil e Chequer Sociedade de Advogados com 12 operações.
        `,
        `
        Dito isso, observa-se que 52,67% do total de operações está concentrado nos 3 primeiros assessores jurídicos do ranking.
        `,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Líderes de Distribuição por Montante Emitido de CRA em 2023',
    details: {
      title:
        'Ranking de Líderes de Distribuição por Montante Emitido de CRA em 2023',
      texts: [
        `Pelo citado ranking avistamos 31 Líderes de Distribuição.
        Na liderança, o Banco BTG Pactual com R$17,95 bilhões em montante emitido, correspondente a 40,34% do total.
        Houve um aumento de 55% comparado ao montante captado pelo primeiro ranqueado no ano de 2022, que girava em torno de R$11,60 bilhões.
        Seguido pela XP Investimentos, com R$8,90 bilhões representando 20,01% do montante emitido.
        No entanto, esse valor é 3,99% menor que o captado pelo segundo colocado no ranking no ano de 2022 que foi de R$9,27 bilhões.
        Logo após temos o Banco Itaú BBA com R$4,47 bilhões e uma participação correspondente a 10,04%.
        Em seguida vem o Banco Bradesco BBI com R$1,73 bilhões representando 3,89%.
        E na quinta posição está o Ecosec com R$1,41 bilhão equivalendo a uma participação de 3,18% do total emitido em 2023.
        Portanto conclui-se que as três primeiras instituições (Banco BTG Pactual XP Investimentos e Banco Itaú BBA) concentram juntas cerca de 70,24% do total do montante emitido.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=distributor&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Líderes de Distribuição por Número de Títulos de 2023',
    details: {
      title: 'Ranking de Líderes de Distribuição por Número de Títulos de 2023',
      texts: [
        `Com um total de 177 títulos e 31 classificados, houve uma diminuição de 24% em comparação com 2022, quando havia 234 títulos. 
        A Ecosec lidera o ranking com 25 títulos, representando 14,20%, uma queda de 10,71% em relação aos 28 títulos do ano anterior. 
        Em seguida está a Guide Investimentos com 18 títulos, correspondendo a 10,23%. 
        A XP Investimentos segue na terceira posição com 17 títulos, equivalente a 9,66%. 
        Por outro lado, o Banco Itaú BBA, que ficou em terceiro lugar no Ranking dos Líderes em Distribuição por valor emitido em 2023, situa-se em quarto lugar sob este critério por ter emitido apenas 15 títulos significando 8,47% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=distributor&criteria=titlesnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Montante Classificado',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Montante Classificado',
      texts: [
        `Aumento de 22% no valor classificado em comparação com 2022, totalizando R$ 16,78 bilhões em 2023.
        O ranking inclui um total de 3 instituições.
        A Fitch lidera com R$ 7,04 bilhões, representando 41,94%, seguida pela S&P com R$ 6,28 bilhões, equivalente a 37,45%.
        Em terceiro lugar está a Moodys com R$ 3,46 bilhões, correspondendo a 20,61%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=agencyrating&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados',
      texts: [
        `Houve uma queda de 13% no número de operações em comparação com o ano anterior.
        Em 2023, foram realizadas um total de 59 operações.`,
        `
        A agência líder em classificação de risco é a Fitch, com 25 operações, representando 42,37% do total.
        Em seguida vem a S&P, com 22 operações, correspondendo a 37,29%.
        Por último, temos a Moodys, que realizou 12 operações e representa 20,34% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=agencyrating&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Servicers por Montante Emitido de CRA em 2023',
    details: {
      title: 'Ranking de Servicers por Montante Emitido de CRA em 2023',
      texts: [
        `Com um total de R$2,38 bilhões emitidos, divididos entre 10 Servicers, 80,19% estão concentrados nos top 3.
        A ACE (Agriculture Collateral Experts) lidera o ranking pelo terceiro ano consecutivo com R$1,29 bilhão, representando 58,90% do total.
        Ceres fica em segundo lugar com R$378,18 milhões (17,23%), seguida pela Opea Assessoria de Crédito em terceiro lugar com R$243 milhões e a Vert Consultoria e Assessoria Financeira em quarto lugar com R$150 milhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=servicer&criteria=amount&timeRange=yearly',
  },
  {
    name: 'Ranking de Servicers por Número de Operações de 2023',
    details: {
      title: 'Ranking de Servicers por Número de Operações de 2023',
      texts: [
        `
        Pelo citado ranking constatamos 41 operações e 10 Servicers, em que 80,39%, se concentra nos 3 primeiros colocados, a seguir:
        `,
        `
        ACE lidera, com 20 operações, correspondente a 52,63% do total.
        Na 2ª posição, está Ceres, com 10 operações, correspondente a 26,32% do total.
        E, na 3ª posição Opea Assessoria de Crédito, com 3 operações, correspondente a 7,89%.
        `,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=servicer&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de CRA por Montante Negociado (+180 dias) em 2023',
    details: {
      title: 'Ranking de CRA por Montante Negociado (+180 dias) em 2023',
      texts: [
        `
        Em 2023 o montante negociado no mercado secundário de CRA chegou a impressionante cifra de R$ 39,86 bilhões, composta pelo somatório de negócios registrados no mercado de balcão organizado da B3.
        Elevação de 76,92% comparado com 2022, quando girava em torno de R$ 22,10 bilhões.
        `,
        'A Vert foi a emissora do CRA mais negociado , com R$ 4,44 bilhões, correspondente a 9,58% do total.',
        'Na 2ª posição a Vert como emissora, do CRA ECTP , registrou a cifra de R$2,89 bilhões, correspondente a 6,23%.',

        'E, na 3ª posição, o CRA da Virgo , com R$ 2,31 bilhões, correspondente a 4,98% do total.',
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitycra&criteria=amountnegotiated&timeRange=yearly',
  },
  {
    name: 'Ranking de CRA por Número de Negócios (+180 dias) em 2023',
    details: {
      title: 'Ranking de CRA por Número de Negócios (+180 dias) em 2023',
      texts: [
        'O número de negócios no mercado secundário de CRA também atingiu 309.994 ultrapassando em 147%, a soma de negociações registradas em 2022.',
        'O CRA líder foi emitido pela Vert, tendo tido 30.962 negociações, correspondente a 6,60% do total.',
        'Na sequência vem um CRA da Virgo, com 28.617 negociações, correspondente a 6,10% do total.',
        'E, na 3ª posição, o CRA da série 2 da emissão 38 da True, com 5.254 negociações, correspondente a 4,33% atinente ao total de 2023.',
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitycra&criteria=tradesnumber&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras por Saldo Devedor de CRA',
    details: {
      title: 'Ranking de Securitizadoras por Saldo Devedor de CRA',
      texts: [
        'O presente ranking classifica as securitizadoras com base no saldo devedor consolidado dos CRA ainda em circulação, independentemente do ano de emissão.',
        `EcoSec mantém a liderança desse ranking, totalizando R$ 40,2 bilhões.
        Em seguida, vem a Virgo, com R$ 27,2 bilhões, ocupando a terceira posição; VERT aparece logo depois com R$ 22,9 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  },
  {
    name: 'Ranking de Securitizadoras por Número de Operações de CRA em Circulação',
    details: {
      title:
        'Ranking de Securitizadoras por Número de Operações de CRA em Circulação',
      texts: [
        `Em relação ao número de operações de CRA em circulação, a EcoSec continua sendo destaque em 2023, com 313 operações ainda ativas. 
        A securitizadora Virgo ocupa a segunda posição, com 151 operações. 
        Opea está em terceiro lugar, com 96 operações, enquanto a VERT registra 68.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=securitizer&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Saldo Devedor de CRA',
    details: {
      title: 'Ranking de Agentes Fiduciários por Saldo Devedor de CRA',
      texts: [
        `Quando os agentes fiduciários são organizados de acordo com o critério do saldo devedor dos CRA, a Oliveira Trust ocupa a liderança, com R$ 47,1 bilhões.
        A segunda posição é ocupada pela Pentágono, que totaliza R$ 36,0 bilhões.
        A Vórtx fica em terceiro lugar, com R$ 21,6 bilhões.
        Após adquirir o controle da Simplific Pavarini há algum tempo, a Vórtx e a Simplific Pavarini juntas alcançam um total de R$ 35,6 bilhões; no entanto, isso ainda posicionaria a Vórtx em terceiro lugar nesse ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  },
  {
    name: 'Ranking de Agentes Fiduciários por Número de Operações de CRA em Circulação',
    details: {
      title:
        'Ranking de Agentes Fiduciários por Número de Operações de CRA em Circulação',
      texts: [
        `No que se refere ao número de operações de CRA ativas, a ordenação dos agentes fiduciários sofre uma significativa alteração. 
        A Oliveira Trust mantém sua posição em primeiro lugar, com 349 operações ativas, enquanto a Vórtx assume o segundo lugar, com 201 operações, e a Pentágono ocupa o terceiro lugar, com 141 operações. 
        Seguindo a mesma lógica do ranking anterior, se adicionarmos as operações da Vórtx e da Simplific Pavarini, teríamos um total de 245 operações, o que colocaria a Vórtx em segundo lugar.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=fiduciaryagent&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Auditores de Securitizadoras por Saldo Devedor de CRA',
    details: {
      title: 'Ranking de Auditores de Securitizadoras por Saldo Devedor de CRA',
      texts: [
        `Em relação ao Ranking de Auditores de Securitizadoras por Saldo Devedor, a KPMG mantém a primeira colocação, com R$ 43,6 bilhões.
        A PwC fica em segundo lugar, com R$ 40,2 bilhões; enquanto a BDO RCS segue na terceira posição, com R$ 28,3 bilhões.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=stockauditor&criteria=amount&timeRange=default',
  },
  {
    name: 'Ranking de Auditores de Securitizadoras por Número de Operações de CRA em Circulação',
    details: {
      title:
        'Ranking de Auditores de Securitizadoras por Número de Operações de CRA em Circulação',
      texts: [
        `Composto pelo critério do número de operações, os auditores invertem suas posições.
        A PwC se torna líder, auditando 183 operações, enquanto a BDO RCS é responsável por 121 operações.
        Ao mesmo tempo, a KPMG atua como auditora de 111 operações.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=CRA&year=2023&ranking=stockauditor&criteria=operations&timeRange=default',
  },
];
