import { Login } from 'src/types/auth';
import { CreateUser } from 'src/types/user';

import { publicApi } from '../../api/api';

export const login = async (values: Login) => {
  return publicApi.post('/auth/local', {
    identifier: values.identifier,
    password: values.password,
    keepMeSigned: !!values.keepMeSigned,
  });
};

export const register = async ({
  email,
  password,
  name,
  otherName,
  type,
  document,
  rdstation,
  acceptTerms,
  emailAdmin,
  invitationToken,
}: CreateUser) => {
  return publicApi.post('/auth/local/register', {
    email,
    username: email,
    password,
    name,
    otherName,
    type,
    document,
    acceptTerms,
    rdstation,
    invitationToken,
    ...(emailAdmin && {
      contactDetails: {
        emailAdmin,
      },
    }),
  });
};

export const validateEmail = async ({ code }: { code: string }) => {
  const { data } = await publicApi.get(
    `/user-email-validations/validate?code=${code}`,
  );
  return data;
};

export const createUserEmail = async ({
  email,
  userType,
  planPriceId,
  planCount,
}: {
  email: string;
  userType: string;
  planPriceId?: string;
  planCount: number;
}) => {
  const { data } = await publicApi.post('/user-email-validations', {
    data: {
      email,
      userType,
      planPriceId,
      planCount,
    },
  });

  return data;
};

export const refreshConfimationEmail = async ({ id }: { id?: number }) => {
  return publicApi.post('/user-email-validations/send-email', {
    id,
  });
};

export const forgotPassword = async (value: { identifier: string }) => {
  return publicApi.post('/auth/forgot-password', {
    identifier: value.identifier,
  });
};

export const resetPassword = async (values: {
  code: string;
  password: string;
  passwordConfirmation: string;
}) => {
  return publicApi.post('/auth/reset-password', {
    code: values.code,
    password: values.password,
    passwordConfirmation: values.passwordConfirmation,
  });
};

export const getUrlRedirectDemo = async () => {
  const {
    data: { data },
  } = await publicApi.get('/platform-register');
  return data;
};
