import {
  fiagroPrimaryCharts,
  fiagroSecondaryCharts,
  fiagroStockCharts,
} from './charts/FIAGRO';
import {
  YearbookDescriptions,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fiagroOpeningTexts = [
  `
Os Fundos de Investimento nas Cadeias Produtivas Agroindustriais (FIAGROs) surgiram em março de 2021, com base na Lei 14.130/2021, alterada posteriormente pela Lei 14.421/2022. Buscando um maior espaço para o agronegócio no mercado de crédito e de capitais, o FIAGRO tem atraído investidores oferecendo opções de financiamento privado para atividades rurais e imobiliárias no setor agrícola.
`,
  'Pouco tempo depois, a Comissão de Valores Mobiliários (CVM) editou a Resolução CVM  39, em caráter provisório e experimental.',
  'A autarquia optou por aproveitar a plataforma regulatória vigente, que permite que sejam imediatamente constituídos e registrados três diferentes tipos de FIAGROs.',
  '•         FIAGRO-Direitos Creditórios: Fundo de Investimento em Direitos Creditório (FIDC) constituído nos termos da Instrução CVM 356. ',
  '•         FIAGRO-Imobiliários: FIIs constituídos nos termos da Instrução CVM 472.',
  '•         FIAGRO-Participações: Fundo de Investimento em Participações (FIP) constituído nos termos da Instrução CVM 578.',
  'Contudo, os comentários do público a respeito de uma norma específica sobre FIAGROs estão em análise pela CVM, com expectativa de ser editada ainda em 2024.',
  'Até lá, permanece em vigor a Resolução CVM 39, que submete cada tipo de FIAGRO a um regime regulatório específico.',
  `Os FIAGROS-FIDCs são grandes investidores em ativos como duplicatas, faturas, recebíveis de contratos comerciais, CCB, CPR-F, CDCA, CRA, debêntures, notas comerciais, entre outros. Por outro lado, o FIAGRO-FII foca na compra de imóveis rurais e também pode adquirir títulos de securitização no agronegócio. Ele atua como um investimento imobiliário que canaliza recursos para o setor produtivo por meio dos ativos financeiros que adquire.
   Os ativos incluem Certificados de Recebíveis do Agronegócio (CRAs), Letras de Crédito do Agronegócio (LCAs), Certificados de Recebíveis Imobiliários (CRIs) lastreados em créditos relacionados a imóveis rurais, e cotas de Fundos de Investimento.`,
  `Já o FIAGRO-FIP é um Fundo de Investimento em Participações que tem como objetivo investir em participações societárias em empresas do agronegócio, com poder de influenciar as decisões das empresas investidas. Ele atua como um vetor de investimento de capital em negócios com potencial de crescimento no setor agroindustrial. 
  Esse tipo de fundo pode adquirir ações, debêntures simples e outros títulos ou valores mobiliários conversíveis em ações de companhias abertas ou fechadas, além de participar em sociedades limitadas. Ele é direcionado a investidores qualificados e profissionais, sendo constituído na forma fechada. Uma característica importante do FIAGRO-FIP é a necessidade de participação ativa no processo decisório das empresas investidas, influenciando sua política estratégica e gestão.`,
];

export const fiagroYearbookDescriptions: YearbookDescriptions = {
  primary: [
    `
  A introdução a uma análise detalhada das dinâmicas de mercado primário para os FIAGROs-FIDCs e para os FIAGROs-FIIs fornece um panorama fundamental para compreender as tendências e as transformações que caracterizam o setor financeiro voltado ao agronegócio. 
  Em 2023, observou-se um crescimento notável nas emissões de FIAGROs-FIDCs, atingindo R$ 4,74 bilhões, um aumento significativo de 157,61% em relação ao ano anterior.
  Este movimento reflete um interesse crescente e a confiança dos investidores nestes veículos de investimento, sublinhando o papel crescente que desempenham no financiamento do agronegócio brasileiro.
  `,
    `
  A manutenção de uma forte representação de fundos com condomínio fechado, apesar da emergência de fundos com condomínio aberto, destaca a preferência contínua por estruturas de investimento mais tradicionais, mesmo com outras opções disponíveis.
  `,
    `No contexto dos FIAGROs-FIIs, a redução de 6,30% nas emissões em 2023, comparada ao ano anterior, não diminui o sucesso e a importância crescente desses fundos desde sua criação em 2021. 
  A significativa participação dos FIAGROs-FIIs do tipo Fazenda, que representaram 76,36% do total emitido, destaca a atratividade e o foco contínuo no setor agropecuário como um segmento de investimento vital. 
  A diversificação dentro deste setor, especialmente por meio de fundos especializados em práticas sustentáveis e na criação e na gestão de créditos de carbono, reflete um compromisso com a sustentabilidade e a inovação no financiamento do agronegócio.
  `,
    `Esta introdução fornece uma visão abrangente das tendências de mercado, destacando o crescimento, as preferências estruturais e a diversificação no âmbito dos FIAGROs-FIDCs e dos FIAGROs-FIIs. 
    O mercado de FIAGROs-FIPs não será analisado nesta edição dos Anuários Uqbar. 
    Como se verá, este é um segmento altamente concentrado e pouco transparente. 
    Para conhecimento, existem atualmente 13 fundos em funcionamento normal, com a soma de valor patrimonial líquido da ordem de R$ 16,80 bilhões. 
    Os três principais FIAGRO-FIP detêm juntos a fatia de 98% desta modalidade de FIAGROs. 
    Os FIAGROs em questão investem em ações da J&F Investimentos S.A. e JBS S.A..
  `,
  ],
  secondary: [
    `A análise do mercado secundário de FIAGROs-FIDCs e FIAGROs-FIIs, em 2023, revela um cenário de crescimento exponencial e de transformação significativa na dinâmica de negociação desses instrumentos. 
    No segmento dos FIAGROs-FIDCs, o mercado secundário testemunhou um aumento notável de quase 600% no montante financeiro negociado em comparação ao ano anterior, acompanhado de um aumento substancial no número de negociações. 
    Esta evolução reflete um crescente interesse e confiança dos investidores no FIAGRO, culminando em um montante de R$ 777,45 milhões negociados através de 253.657 transações em 2023. 
    A mudança de cenário é evidenciada pela transição de negociações em ambientes de balcão para a inclusão significativa do ambiente de bolsa, marcando 2023 como um ano de diversificação e de expansão no acesso e de liquidez do mercado de FIAGROss-FIDCs.
    `,
    `Paralelamente, o mercado de FIAGROs-FIIs também registrou um desempenho recorde em 2023, com o montante negociado alcançando R$ 7,20 bilhões e um número inédito de 16.351.991 transações. 
    O destaque foi o FIAGRO Valora CRA liderando as negociações com R$ 1,12 bilhão em montante negociado e 3.600.900 transações. 
    Este recorde sublinha a atratividade e o potencial dos FIAGROs-FIIs como uma opção de investimento diversificada e robusta, refletindo a crescente integração e a relevância do setor agropecuário nos mercados financeiros.`,
  ],
  stock: [
    `O ano de 2023 marcou um período significativo de crescimento e de consolidação para os Fundos de Investimento em Direitos Creditórios do Agronegócio (FIAGROs-FIDCs) e os Fundos de Investimento Imobiliário do Agronegócio (FIAGROs-FIIs), evidenciando a crescente relevância e a atração desses veículos de investimento no setor agropecuário brasileiro. 
    No âmbito dos FIAGROs-FIDCs, o mercado viu um aumento expressivo tanto no número de fundos quanto no patrimônio líquido (PL), com um salto para 37 fundos totalizando um PL de R$4,60 bilhões, representando um crescimento substancial em comparação com o ano anterior. 
    Esse desenvolvimento é exemplificado pelo FIAGRO Insumos Milenio Terramagna, que se destacou com o maior PL entre seus pares.`,
    `Paralelamente, a composição de Direitos Creditórios desses fundos, incluindo recebíveis diversos e debêntures, registrou um avanço notável, alcançando um total de R$ 3,69 bilhões ao final de 2023. 
    Este incremento de 81,77% em relação ao ano anterior sublinha a expansão e a diversificação das atividades financiadas através dos FIAGROs-FIDCs, refletindo o dinamismo e a robustez do setor agropecuário como um campo fértil para investimentos.`,
    `No segmento dos FIAGROs-FIIs, observou-se uma trajetória impressionante de crescimento no PL, iniciando com R$ 559 milhões em agosto de 2021 e atingindo mais de R$ 16 bilhões ao final de dezembro de 2023.
     A diversificação de ativos, principalmente em CRAs e fazendas, além de silos e armazéns, revela uma estratégia de investimento focalizada na robustez e na sustentabilidade do agronegócio.`,
    `Além disso, a gestão de ativos financeiros emergiu como a finalidade predominante dos FIAGROs-FIIs, representando uma média anual de 66,01% do PL, com uma particular ênfase na gestão ativa, que alcançou 97,19% do PL em 2023. 
     Esta preferência por uma gestão ativa, permitindo maior liberdade na seleção de ativos, destaca a busca por otimização de rendimentos e pela adaptação estratégica às mudanças do mercado.`,
  ],
  rankings: [
    `No cenário dos FIAGROs-FIIs, em 2023 observa-se um panorama diversificado e em expansão, refletido nos rankings elaborados pela Uqbar. 
  O Ranking de FIAGRO-FII por Montante Emitido evidencia a concentração de capital em poucos fundos, destacando-se o FIAGRO Kinea Crédito Agro na liderança. 
  Similarmente, os rankings que exploram o PL por Administrador e o PL por Gestor nos FIAGROs-FIIs ressaltam a predominância de grandes players no mercado.`,
    `Por outro lado, no universo dos FIAGROs-FIDCs, os rankings de PL por Administrador e PL por Gestor também evidenciam a concentração de mercado e a expertise específica de algumas gestoras e administradoras, refletindo a confiança do mercado em suas capacidades de gestão. 
  O Ranking de número de FIAGROs-FIDCs por Gestor e por Administrador aponta para uma diversidade de entidades envolvidas na gestão e na administração desses fundos, mostrando um ambiente competitivo e inovador.`,
  ],
};

export const fiagroPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiagroPrimaryCharts.emissionsFIDC,
    name: 'Emissões de FIAGROs-FIDCs',
    section: 'primary',
    details: {
      title: 'Emissões de FIAGROs-FIDCs',
      texts: [
        `As emissões de FIAGROs-FIDCs no mercado primário registraram a cifra de R$ 4,74 bilhões, o que representa um aumento de 157,6% em relação ao ano de 2022 (R$1,84 bilhão). 
      Sob a ótica dos novos FIAGROs-FIDCs, 2023 fechou o ano com 30 participantes, 17 a mais do que no ano anterior, que fechou com 13 participantes`,
      ],
      value: 4.74,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsByTypeOfCondominium,
    name: 'Emissões por tipo de Condomínio',
    section: 'primary',
    details: {
      title: 'Condomínio fechado mantém forte representação em 2023',
      texts: [
        `No mercado de FIAGROs-FIDCs, os anos compreendidos entre 2021 e 2022 foram marcados pelo predomínio de fundos com condomínio fechado.
        Em 2023, a cifra permaneceu relevante, entretanto surgiram os fundos com o condomínio aberto com a tímida representatividade de 0,77% (R$ 37 milhões).`,
      ],
      value: 4.74,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsFII,
    name: 'Emissões de FIAGROs-FIIs',
    section: 'primary',
    details: {
      title: 'Emissões de FIAGROs-FIIs',
      texts: [
        `Em 2023, as emissões do FIAGROs-FIIs totalizaram R$ 6,25 bilhões, representando uma redução de 6,58% em comparação com 2022 (R$ 6,67 bilhões). 
        No entanto, as emissões do FIAGROs-FIIs estão em seu terceiro ano de operação, desde quando atingiram R$ 2,18 bilhões em seu início. 
        Criado em março de 2021, tem ganhado destaque e já integra a carteira de vários Fundos de Investimento em Cotas (FICs) em busca de diversificação.
        `,
      ],
      value: 6.25,
      valueTitle: 'Montante Emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroPrimaryCharts.emissionsByTypeOfImmobile,
    name: 'Emissões por tipo de Imóvel',
    section: 'primary',
    details: {
      title: 'Participação dos FIAGROs-FIIs do tipo de imóvel Fazenda',
      texts: [
        `Os FIAGROs-FIIs do tipo Fazenda tiveram participação de 76,36% do montante emitido em 2023. 
        A modalidade Especial que tem FIAGROs-FIIs diferenciados como, por exemplo, os que visam realizar o manejo sustentável dos recursos naturais e florestais exclusivamente para certificação, criação, gerenciamento e negociação de Créditos de Carbono.
        `,
      ],
    },
  },
];

export const fiagroSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fiagroSecondaryCharts.amountNegotiatedFIDC,
    section: 'secondary',
    name: 'Montante Negociado de FIAGROs-FIDCs',
    details: {
      title:
        'Crescimento do montante negociado e número de negócios no FIAGRO-FIDC',
      texts: [
        `O mercado secundário de cotas de FIAGROs-FIDCs mostra um crescimento de quase 600% no valor financeiro negociado em 2023 em comparação com o ano anterior. 
        Além de um grande crescimento também no número de negócios realizados. 
        Nota-se que o crescimento das cotas do mercado secundário já é uma realidade desde o ano de 2022, quando evidenciado um crescimento 10 vezes maior frente ao número de 2021.
        `,
        `Em números absolutos, no ano de 2023 o crescimento acelerado deste veículo financeiro chegou à cifra de R$ 777,45 milhões, com 253.657 negócios, contra apenas 562 negócios no ano de 2022, que resultou em um valor financeiro de R$ 111,20 milhões. 
        Em 2023 o montante de negociação em ambiente de balcão ficou em R$ 9,41 bilhões com 369 negociações.`,
        `Observa-se também que o crescimento exponencial no ano de 2023 foi alavancada com a entrada do ambiente de bolsa no cenário, que registrou um montante de R$ 142,92 milhões em negociações com um surpreendente volume de 252.254 negociações. 
        Paralelamente, o balcão viu um salto monumental em seu volume de negociações, alcançando R$ 634,53 bilhões com 1.403 negociações. Nota-se, entretanto, que no mercado de bolsa as negociações estiveram concentradas em cotas de um único fundo: o FIAGRO BTG Pactual Crédito Agrícola.`,
      ],
      value: 777.45,
      valueTitle: 'Montante Negociado 2023',
      valueUnit: 'R$ (milhões)',
    },
  },
  {
    chart: fiagroSecondaryCharts.amountNegotiatedFII,
    section: 'secondary',
    name: 'Montante Negociado de FIAGROs-FIIs',
    details: {
      title: 'Montante negociado de cotas de FIAGROs-FIIs é recorde em 2023',
      texts: [
        `O mercado secundário de cotas é composto por indicadores como o montante negociado e o número de negócios com cotas de FIAGROs-FIIs na B3. 
        Em 2023, o montante negociado atingiu R$7,20 bilhões. 
        O número de negócios obteve o recorde de 16.351.991 transações. 
        O fundo mais negociado, em 2023, foi o FIAGRO Valora CRA com R$ 1,12 bilhão em montante negociado e 3.600.900 em número de negócios.
        `,
      ],
      value: 7.2,
      valueTitle: 'Montante Negociado 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
];

export const fiagroStock: YearbookMarketChartInfo[] = [
  {
    chart: fiagroStockCharts.liquidFIDC,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIDCs',
    details: {
      title: 'Patrimônio líquido dos FIAGROs-FIDCs em 2023',
      texts: [
        `O mercado registrou, em termos de quantidade, 37 fundos no total, perfazendo PL de R$4,60 bilhões. 
        Em comparação com dezembro de 2022, o número e o patrimônio líquido de FIDCs aumentaram em 146,67% e 70,37%, respectivamente. 
        O FIAGRO-FIDC com maior PL ao fim de 2023 foi o FIAGRO Insumos Milenio Terramagna, com R$ 587,98 milhões.`,
      ],
      value: 4.6,
      valueTitle: 'Patrimômio Líquido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroStockCharts.creditoryFIDC,
    section: 'stock',
    name: 'Direitos Creditórios dos FIAGROs-FIDCs',
    details: {
      title: 'Soma de Direitos Creditórios dos FIAGROs-FIDCs em 2023',
      texts: [
        'A carteira dos FIAGROs-FIDCs pode ser composta por recebíveis, duplicatas, CPR-F e, também, debêntures, Certificados de Recebíveis do Agronegócio, entre outros, e deve estar alinhada com as atividades permitidas pela legislação para os FIAGROs.',
        'A soma de Direitos Creditórios, ao final de 2023, chegou à cifra de R$ 3,69 bilhões, crescimento de 81,77% comparado com o mesmo mês do ano anterior, que registrava R$ 2,03 bilhões.',
      ],
      value: 3.69,
      valueTitle: 'Direitos Creditórios 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fiagroStockCharts.liquidFII,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIIs',
    details: {
      title: 'FIAGROs-FIIs finalizam 2023 com Patrimônio Líquido recorde',
      texts: [
        `Os dados refletem uma trajetória impressionante de crescimento no patrimônio líquido (PL) dos FIAGROs-FIIs ao longo do período entre agosto de 2021 e dezembro de 2023. 
        Iniciando com um PL de aproximadamente R$ 559 milhões, em agosto de 2021, distribuídos entre três CNPJs distintos de FIIs, observa-se uma tendência ascendente constante, culminando em um PL de mais de R$ 16 bilhões em dezembro de 2023, espalhados por 45 CNPJs diferentes de FIAGROs-FIIs.`,
        'Nesse quesito, grande parte dos fundos tem como tipo de ativo os CRAs e as Fazendas, assumindo a cifra de R$ 14,96 bilhões do PL no fim de dezembro, seguidos por Silos e Armazéns.',
      ],
    },
  },
  {
    chart: fiagroStockCharts.liquidByFinalityFII,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIIs por finalidade',
    details: {
      title:
        'Gestão de Ativos Financeiros é a principal finalidade de FIAGROs-FIIs.',
      texts: [
        `Os FIAGROs-FIIs finalizaram o mês de dezembro de 2023 com 67,64% do PL do mercado e média anual de 66,01% do PL. 
        A principal finalidade advinda dos FIAGROs-FIIs é a Gestão de Ativos Financeiros e este segmento representa a maior parte do mercado. 
        Em seguida, vem o segmento Diversas, que compõe uma conjunção de diferentes tipos de finalidade, com 22,52% do PL e, por fim, Renda, com 9,84% do PL.`,
      ],
    },
  },
  {
    chart: fiagroStockCharts.liquidByManagementTypeFii,
    section: 'stock',
    name: 'Patrimônio líquido dos FIAGROs-FIIs por tipo de gestão',
    details: {
      title: 'FIAGROS-FIIs de Gestão de Ativa são destaque em PL',
      texts: [
        `Os FIAGROS-FIIs de gestão ativa, em que gestores possuem o maior grau de liberdade nos ativos que farão parte da carteira, registraram 97,15% do PL em 2023, aumento de 0,21 p.p. referente a 2022 (96,94%). 
        Gestão passiva representa apenas 2,85% do PL.`,
      ],
    },
  },
];

export const fiagroRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking de FIAGROs-FIIs por Montante Emitido',
    details: {
      title: 'Ranking de FIAGROs-FIIs por Montante Emitido',
      texts: [
        `O ranking de FIAGROs-FIIs por Montante Emitido no mercado primário, em 2023, foi composto por 24 fundos. 
      Os três primeiros colocados do ranking possuem uma concentração de 48,33%, com a liderança do FIAGRO Kinea Crédito Agro, R$ 1,38 bilhão, representando 22,12% do total emitido, seguido pelo FIAGRO Itaú Asset Rural com R$ 1,01 bilhão, correspondendo a 16,21%. 
    No terceiro lugar está o FIAGRO XP Crédito Agro, com R$ 625,00 milhões em montante emitido, correspondendo a 10,00%.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'FIAGRO Kinea Crédito Agro',
          percentage: 22.12,
          value: 1380000000,
          institutionId: 8654987,
          slug: 'fiagro-kinea-credito-agro-8654987',
          uqbarId: 8654987,
          position: 1,
        },
        {
          name: 'FIAGRO Itaú Asset Rural',
          percentage: 16.21,
          value: 1010000000,
          institutionId: 8691783,
          slug: 'fiagro-itau-asset-rural-8691783',
          uqbarId: 8691783,
          position: 2,
        },
        {
          name: 'FIAGRO XP Crédito Agro',
          percentage: 10,
          value: 625000000,
          institutionId: 8814662,
          slug: 'fiagro-xp-credito-agro-8814662',
          uqbarId: 8814662,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de PL por Administrador no FIAGRO-FII',
    details: {
      title: 'Ranking de PL por Administrador no FIAGRO-FII',
      texts: [
        `Os três primeiros colocados no Ranking de PL por Administrador detêm, juntos, a impressionante fatia de 65,65% do mercado. 
        A Oliveira Trust conquista o topo com uma significativa parcela de 29,59% do total de PL. 
        Na segunda posição aparece a Vórtx, com 21,6% do PL, enquanto o BTG Serviços Financeiros garante o terceiro lugar, com 14,46% do PL.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Intrag DTVM',
          percentage: 29.59,
          value: 4680000000,
          institutionId: 6361,
          slug: 'intrag-dtvm-1656120119724',
          uqbarId: 6361,
          position: 1,
        },
        {
          name: 'XP Investimentos',
          percentage: 21.6,
          value: 3660000000,
          institutionId: 6659,
          slug: 'xp-investimentos-cctvm-1656120133570',
          uqbarId: 6659,
          position: 2,
        },
        {
          name: 'Banco Genial',
          percentage: 14.46,
          value: 2100000000,
          institutionId: 7137,
          slug: 'banco-genial-1656120153177',
          uqbarId: 7137,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de PL por Gestor no FIAGRO-FII',
    details: {
      title: 'Ranking de PL por Gestor no FIAGRO-FII',
      texts: [
        `O Ranking de PL por Gestor tem um total de 37 gestoras. 
        As três primeiras detêm 37,71% do montante total. 
        Na primeira posição está a Kinea Investimentos, com 16,13% do PL, seguida por XP Vista Asset Management com 11,64% do PL e, na terceira posição, Itaú Unibanco Asset Management, com 9,94% do PL.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Kinea Investimentos',
          percentage: 16.13,
          value: 2600000000,
          institutionId: 6578,
          slug: 'kinea-investimentos-1656120130106',
          uqbarId: 6578,
          position: 1,
        },
        {
          name: 'XP Vista Asset Management',
          percentage: 11.64,
          value: 1870000000,
          institutionId: 7370,
          slug: 'xp-vista-asset-management-1656120162696',
          uqbarId: 7370,
          position: 2,
        },
        {
          name: 'Itaú Unibanco Asset Management',
          percentage: 9.94,
          value: 1600000000,
          institutionId: 8000,
          slug: 'itau-unibanco-asset-management-1656120242203',
          uqbarId: 8000,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Número de FIAGRO-FII por Administrador',
    details: {
      title: 'Ranking de Número de FIAGRO-FII por Administrador',
      texts: [
        `De 2021 a 2023, o número de FIAGROs-FIIs saiu de apenas 13 para 45. 
        A XP investimentos faz a administração de nove desses fundos, sendo a primeira do ranking Uqbar. 
        Ela é seguida pelo Banco Daycoval, que administra sete fundos. A terceira posição do ranking está com o Banco Genial, administrador de seis fundos.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Operações',
      ranks: [
        {
          name: 'XP Investimentos',
          percentage: 19.57,
          value: 9,
          institutionId: 6659,
          slug: 'xp-investimentos-cctvm-1656120133570',
          uqbarId: 6659,
          position: 1,
        },
        {
          name: 'Banco Daycoval',
          percentage: 15.22,
          value: 7,
          institutionId: 6499,
          slug: 'banco-daycoval-1656120126734',
          uqbarId: 6499,
          position: 2,
        },
        {
          name: 'Banco Genial',
          percentage: 13.04,
          value: 6,
          institutionId: 7137,
          slug: 'banco-genial-1656120153177',
          uqbarId: 7137,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Número de FIAGRO-FII por Gestor',
    details: {
      title: 'Ranking de Número de FIAGRO-FII por Gestor',
      texts: [
        `A gestora com maior número de FIAGROs-FIIs é a 051 Capital, gerindo os fundos com os tickers FZDA11, FZDB11 e FLEM11, que corresponde às Fazendas Xingu, Tabuleiro I e Tabuleiro II, com o objetivo de produção de culturas como soja, milho e algodão. 
        Em segundo lugar figura a Kinea Investimentos, seguida pela XP Vista Asset. `,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Operações',
      ranks: [
        {
          name: '051 Capital',
          percentage: 6.4,
          value: 3,
          institutionId: 1846,
          slug: 'zero-cinco-um-capital-8907157',
          uqbarId: 1846,
          position: 1,
        },
        {
          name: 'Kinea Investimentos',
          percentage: 4.3,
          value: 2,
          institutionId: 6578,
          slug: 'kinea-investimentos-1656120130106',
          uqbarId: 6578,
          position: 2,
        },
        {
          name: 'XP Vista Asset Management',
          percentage: 4.3,
          value: 2,
          institutionId: 7370,
          slug: 'xp-vista-asset-management-1656120162696',
          uqbarId: 7370,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Número de Negócios de FIAGRO-FII por Administrador',
    details: {
      title: 'Ranking de Número de Negócios de FIAGRO-FII por Administrador',
      texts: [
        `O número de negociações de FIAGRO-FII por Administrador em 2023 tem como primeiro do ranking a administradora BTG Pactual Serviços Financeiros, com 28,83% do total, seguida por XP Investimentos, com 25,00%, e, em terceiro lugar, o Banco Genial com, 18,85%. 
        Neste ranking, o FIAGRO-FII com maior número de negociações é o FIAGRO Sparta com 5.096.513 negociações em 2023.`,
      ],
    },
    criteria: 'numeric',
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Núm de Negócios',
      ranks: [
        {
          name: 'BTG Pactual Serviços Financeiros',
          percentage: 28.83,
          value: 5096513,
          institutionId: 6613,
          slug: 'btg-pactual-servicos-financeiros-dtvm-1656120131495',
          uqbarId: 6613,
          position: 1,
        },
        {
          name: 'XP Investimentos',
          percentage: 25,
          value: 4418157,
          institutionId: 1886,
          slug: 'xp-investimentos-cctvm-1656120133570',
          uqbarId: 6659,
          position: 2,
        },
        {
          name: 'Banco Genial',
          percentage: 18.85,
          value: 3331496,
          institutionId: 7137,
          slug: 'banco-genial-1656120153177',
          uqbarId: 7137,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Montante Negociado de FIAGRO-FII por Administrador',
    details: {
      title: 'Ranking de Montante Negociado de FIAGRO-FII por Administrador',
      texts: [
        `O montante negociado de FIAGRO-FII por Administrador em 2023 tem como primeiro do ranking a administradora BTG Pactual Serviços Financeiros, com 20,98% do total. 
        Ela é seguida pela XP Investimentos, com 20,18%, e pela Singulare com 16,31%. 
        Neste ranking, o FIAGRO-FII com maior montante negociado é o FIAGRO Valora CRA, com cerca de R$ 870 milhões.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'BTG Pactual Serviços Financeiros',
          percentage: 20.98,
          value: 1730000000,
          institutionId: 6613,
          slug: 'btg-pactual-servicos-financeiros-dtvm-1656120131495',
          uqbarId: 6613,
          position: 1,
        },
        {
          name: 'XP Investimentos',
          percentage: 20.18,
          value: 1660000000,
          institutionId: 6659,
          slug: 'xp-investimentos-cctvm-1656120133570',
          uqbarId: 6659,
          position: 2,
        },
        {
          name: 'Singulare',
          percentage: 16.31,
          value: 1340000000,
          institutionId: 7264,
          slug: 'singulare',
          uqbarId: 7264,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Número de Negócios de FIAGRO-FII por Gestor',
    details: {
      title: 'Ranking de Número de Negócios de FIAGRO-FII por Gestor',
      texts: [
        'O número de negociações de FIAGRO-FII por Gestor, em 2023, tem como primeiro do ranking a gestora Valora Gestão de Investimentos, com 20,37% do total, seguida por Riza Gestora de Recursos, com 17,88% e, em terceiro lugar, XP Vista Asset, com 11,25%.',
      ],
    },
    criteria: 'numeric',
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Núm de Negócios',
      ranks: [
        {
          name: 'Valora Gestão de Investimentos',
          percentage: 20.37,
          value: 3600908,
          institutionId: 6605,
          slug: 'valora-gestao-de-investimentos-1656120131143',
          uqbarId: 6605,
          position: 1,
        },
        {
          name: 'Riza Gestora de Recursos',
          percentage: 17.88,
          value: 3160076,
          institutionId: 7543,
          slug: 'riza-gestora-de-recursos-1656120169830',
          uqbarId: 7543,
          position: 2,
        },
        {
          name: 'XP Vista Asset',
          percentage: 11,
          value: 1987986,
          institutionId: 7370,
          slug: 'xp-vista-asset-management-1656120162696',
          uqbarId: 7370,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de Montante Negociado de FIAGRO-FII por Gestor',
    details: {
      title: 'Ranking de Montante Negociado de FIAGRO-FII por Gestor',
      texts: [
        `O montante negociado de FIAGRO-FII por Gestor, em 2023, tem como primeiro do ranking a gestora Valora Gestão de investimentos, com 14,37% do total, seguida por XP Vista Asset, com 13,32%, e, em terceiro lugar do ranking, Kijani Gestora, com 9,90%. 
        Os três primeiros do ranking de gestores atuam em fundos que somam 37,59% do montante negociado, que corresponde à cifra de R$ 3,09 bilhões.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Valora Gestão de Investimentos',
          percentage: 14.37,
          value: 1180000000,
          institutionId: 6605,
          slug: 'valora-gestao-de-investimentos-1656120131143',
          uqbarId: 6605,
          position: 1,
        },
        {
          name: 'XP Vista Asset',
          percentage: 11,
          value: 1100000000,
          institutionId: 7370,
          slug: 'xp-vista-asset-management-1656120162696',
          uqbarId: 7370,
          position: 2,
        },
        {
          name: 'Kijani Gestora',
          percentage: 9.9,
          value: 814500000,
          institutionId: 1845,
          slug: 'kijani-gestora-8907089',
          uqbarId: 1845,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de PL FIAGRO-FIDC por Administrador',
    details: {
      title: 'Ranking de PL FIAGRO-FIDC por Administrador',
      texts: [
        `Os três primeiros colocados no Ranking de PL por Administrador detêm, juntos, a impressionante fatia de 65,65% do mercado. 
        A Oliveira Trust conquista o topo com uma significativa parcela de 29,59% do total de PL. 
        Na segunda posição aparece a Vórtx, com 21,6% do PL, enquanto o BTG Serviços Financeiros garante o terceiro lugar, com 14,46% do PL.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Oliveira Trust',
          percentage: 29.51,
          value: 1300000000,
          institutionId: 7720,
          slug: 'oliveira-trust-dtvm-sa',
          uqbarId: 7720,
          position: 1,
        },
        {
          name: 'Vórtx',
          percentage: 21.54,
          value: 993100000,
          institutionId: 7090,
          slug: 'vortx-distribuidora-de-titulos-e-valores-mobiliarios-ltda-1656120228314',
          uqbarId: 7090,
          position: 2,
        },
        {
          name: 'BTG Pactual Serviços Financeiros',
          percentage: 14.43,
          value: 665000000,
          institutionId: 6613,
          slug: 'btg-pactual-servicos-financeiros-dtvm-1656120131495',
          uqbarId: 6613,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de PL FIAGRO-FIDC por Gestor',
    details: {
      title: 'Ranking de PL FIAGRO-FIDC por Gestor',
      texts: [
        `As três primeiras detêm 43,02% do montante total. 
        Na primeira posição está Milenio Capital com 19,83% do PL, seguida por BTG Pactual Gestora de Recursos, com 12,04% do PL e, na terceira posição, Opea Gestora de Recursos com 11,15% do PL.`,
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Montante',
      ranks: [
        {
          name: 'Milenio Capital',
          percentage: 19.83,
          value: 911900000,
          institutionId: 7311,
          slug: 'milenio-capital',
          uqbarId: 7311,
          position: 1,
        },
        {
          name: 'BTG Pactual Gestora de Recursos',
          percentage: 12.04,
          value: 555300000,
          institutionId: 6482,
          slug: 'btg-pactual-servicos-financeiros-dtvm-1656120131495',
          uqbarId: 6482,
          position: 2,
        },
        {
          name: 'Opea Gestora de Recursos',
          percentage: 11.15,
          value: 512900000,
          institutionId: 1845,
          slug: 'opea-gestora-de-recursos-8944693',
          uqbarId: 1845,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de número de FIAGRO-FIDC por Gestor',
    details: {
      title: 'Ranking de número de FIAGRO-FIDC por Gestor',
      texts: [
        'Com a gestão de três FIAGRO-FIDCs cada uma, a Daycoval Asset, a Struttura Capital e a Buriti Investimentos colocam-se do primeiro ao terceiro lugar, respectivamente, seguidas por outras cinco gestoras com dois fundos FIAGRO-FIDCs cada.',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Operações',
      ranks: [
        {
          name: 'Daycoval Asset',
          percentage: 7.7,
          value: 3,
          institutionId: 6407,
          slug: 'daycoval-asset-management-1656120122536',
          uqbarId: 6407,
          position: 1,
        },
        {
          name: 'Struttura Capital',
          percentage: 7.7,
          value: 3,
          institutionId: 1879,
          slug: 'struttura-capital-gestao-de-investimentos-8926646',
          uqbarId: 1879,
          position: 2,
        },
        {
          name: 'Buriti Investimentos',
          percentage: 7.7,
          value: 3,
          institutionId: 1863,
          slug: 'buriti-investimentos',
          uqbarId: 1863,
          position: 3,
        },
      ],
    },
  },
  {
    name: 'Ranking de número de FIAGRO-FIDC por Administrador',
    details: {
      title: 'Ranking de número de FIAGRO-FIDC por Administrador',
      texts: [
        'O primeiro lugar do ranking, com sete fundos administrados, é ocupado pela Vórtx, seguida por Banco Daycoval e Singulare, cada uma com seis fundos.',
      ],
    },
    queryData: {
      market: 'FIAGRO',
      valueLabel: 'Operações',
      ranks: [
        {
          name: 'Vórtz',
          percentage: 17.9,
          value: 7,
          institutionId: 7090,
          slug: 'vortx-distribuidora-de-titulos-e-valores-mobiliarios-ltda-1656120228314',
          uqbarId: 7090,
          position: 1,
        },
        {
          name: 'Banco Daycoval',
          percentage: 15.4,
          value: 6,
          institutionId: 6499,
          slug: 'banco-daycoval-1656120126734',
          uqbarId: 6499,
          position: 2,
        },
        {
          name: 'Singulare',
          percentage: 15.4,
          value: 6,
          institutionId: 7264,
          slug: 'singulare',
          uqbarId: 7264,
          position: 3,
        },
      ],
    },
  },
];
