import theme from 'src/theme';
import styled from 'styled-components';

import * as YearbookSection from '../../components/Section';

export const Section = styled.section`
  position: relative;
  min-height: 100dvh;
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #ff8211;
  border-top-right-radius: 160px;
  border-bottom-left-radius: 160px;
  padding-top: 8rem;
  padding-bottom: 16rem;
  z-index: 998;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding: 2rem 0;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
  }
`;

export const Title = styled(YearbookSection.Title)`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const BackgroundEnd = styled.div`
  position: absolute;
  bottom: -8rem;
  width: 100%;
  border-bottom-left-radius: 60px;
  background-color: #ff4949;
  height: 20rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    bottom: -3rem;
    border-top-right-radius: 60px;
    height: 8rem;
  }
`;

export const Player = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 4rem;
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 4rem 0 0;
  }
`;

export const VideosContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-color: #f6f6f6;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    right: 0;
    width: calc(100% - 160px - 8rem);
    height: 8rem;

    @media screen and (max-width: 1366px) {
      width: calc(100% - 8rem);
      margin-left: 8rem;
    }

    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      width: calc(100% - 3.5rem);
      margin-left: 3.5rem;
    }
  }
`;

export const VideosContainerWrapper = styled.div`
  position: relative;
  z-index: 998;
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);
  padding: 1rem;

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 0;
  }
`;

export const VideosList = styled.div`
  margin-top: 1rem;
  display: flex;
  overflow-x: auto;
  gap: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const SelectedVideoContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  padding-top: 56.25%;
`;

export const SelectedVideo = styled.iframe`
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SelectedVideoTitle = styled.h2`
  font-size: 1.1rem;
  margin: 0;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const SelectedVideoAuthor = styled.p`
  font-size: 1rem;
  margin: 0;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const Content = styled.div`
  top: -12rem;
  position: relative;
  z-index: 998;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    top: inherit;
  }
`;
