import { MouseEvent } from 'react';

import { Star, StarOutline } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useMutation } from 'react-query';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { addFavorite, removeFavorite } from 'src/services/favorite';

import { CustomTooltip } from '../CustomTooltip';

export const FavoriteRowButton = ({
  type,
  rowId,
  favoriteId,
  handleAddFavorite,
  handleRemoveFavorite,
  iconWidth,
  iconHeight,
}: {
  type: 'operation' | 'service' | 'event';
  rowId: number;
  favoriteId: number | undefined;
  handleAddFavorite: (rowId: number, favorite: any) => void;
  handleRemoveFavorite: (rowId: number) => void;
  iconWidth?: string;
  iconHeight?: string;
}) => {
  const { auth } = useAuthContext();

  const addFavoriteMutation = useMutation(addFavorite, {
    onSuccess: ({ data }) => {
      handleAddFavorite(rowId, data);
    },
  });

  const { isLoading: addFavoriteLoading } = addFavoriteMutation;

  const removeFavoriteMutation = useMutation(removeFavorite, {
    onSuccess: ({ data }) => {
      handleRemoveFavorite(rowId);
    },
  });

  const { isLoading: removeFavoriteLoading } = removeFavoriteMutation;

  const handleAddFavoriteMutation = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!addFavoriteLoading && !removeFavoriteLoading) {
      addFavoriteMutation.mutate({
        data: {
          user: auth?.user?.id,
          [type]: rowId,
        },
      });
    }
  };

  const handleRemoveFavoriteMutation = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!addFavoriteLoading && !removeFavoriteLoading && favoriteId) {
      removeFavoriteMutation.mutate(favoriteId);
    }
  };

  const handleStartIcon = () => {
    if (favoriteId) {
      return (
        <CustomTooltip title="Remover dos meus favoritos" placement="right">
          <span>
            <IconButton
              sx={{ padding: 0, '&:hover': { background: 'transparent' } }}
              onClick={handleRemoveFavoriteMutation}>
              <Star
                sx={{
                  height: iconHeight ?? '22px',
                  width: iconWidth ?? '22px',
                  color: '#FF8211',
                }}
              />
            </IconButton>
          </span>
        </CustomTooltip>
      );
    }
    return (
      <CustomTooltip title="Adicionar nos meus favoritos" placement="right">
        <span>
          <IconButton
            sx={{ padding: 0, '&:hover': { background: 'transparent' } }}
            onClick={handleAddFavoriteMutation}>
            <StarOutline
              sx={{
                height: iconHeight ?? '22px',
                width: iconWidth ?? '22px',
                color: '#FF8211',
              }}
            />
          </IconButton>
        </span>
      </CustomTooltip>
    );
  };

  return <Box>{auth?.isLoggedIn && type && handleStartIcon()}</Box>;
};
