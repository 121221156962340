import * as React from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type TabMobileProps = {
  items: Array<any>;
  currentTab: any;
  handleChange: (tab: number) => void;
};
export const TabMobile = ({
  items,
  currentTab,
  handleChange,
}: TabMobileProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (tab?: number) => {
    setAnchorEl(null);
    if (tab !== undefined) handleChange(tab);
  };

  return (
    <div>
      <Box onClick={handleClick} border={'1px solid var(--gray-5, #E0E0E0)'}>
        <MenuItem key={currentTab.name}>
          <ListItemIcon style={{ color: 'black' }}>
            {currentTab.tabMobile.icon}
          </ListItemIcon>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>
              {currentTab.tabMobile.label}
            </Typography>
            <ListItemIcon style={{ color: 'black' }}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ListItemIcon>
          </Box>
        </MenuItem>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        marginThreshold={0}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%',
            left: 0,
            right: 0,
          },
        }}>
        {items
          .filter((item) => item.tabMobile.shouldShow)
          .map((item, index) => (
            <MenuItem onClick={() => handleClose(index)} key={item.name}>
              <ListItemIcon
                style={currentTab.name === item.name ? { color: 'black' } : {}}>
                {item.tabMobile.icon}
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    color: currentTab.name === item.name ? 'black' : '#828282',
                  }}>
                  {item.tabMobile.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
