import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { createFavoriteRadar } from 'src/services/radar';
import { setBannerMessage } from 'src/store/display';
import { State } from 'src/types/radar';
import { useQueryParams } from 'src/utils/hooks';
import * as Yup from 'yup';

type Props = {
  state: State;
  openDialog: boolean;
  mode: 'indicadores' | 'assembleias' | 'dashboard';
  setOpenDialog: (value: boolean) => void;
  loading: boolean;
  refetchFavoriteRadar: any;
};

export default function RadarFavoriteDialog({
  state,
  openDialog,
  mode,
  setOpenDialog,
  loading,
  refetchFavoriteRadar,
}: Props) {
  const { auth } = useAuthContext();
  const dispatch = useDispatch();
  const [{ query }, setQuery] = useQueryParams<{
    market: 'cri' | 'cra' | 'fii' | 'fidc';
    mode: 'indicadores' | 'assembleias' | 'dashboard';
  }>({ market: 'fidc', mode: 'indicadores' });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
  });

  const saveFavoriteRadar = useMutation(createFavoriteRadar, {
    onSuccess: ({ data }) => {
      setOpenDialog(false);
      dispatch(
        setBannerMessage({
          message: 'Filtro salvo com sucesso!',
          type: 'success',
          autoclose: true,
        }),
      );
      setQuery({ favoriteId: data?.data?.id });
      refetchFavoriteRadar();
    },
    onError: () => {
      setOpenDialog(false);
      dispatch(
        setBannerMessage({
          message: 'Não foi possível salvar o filtro',
          type: 'error',
          autoclose: true,
        }),
      );
    },
  });

  const handleSaveFavoriteRadar = (name: string) => {
    saveFavoriteRadar.mutate({
      name,
      data: {
        indicator: state.indicator,
        segments: state.segments,
        type: state.type,
        documentType: state.documentType,
        ...(query.market === 'fidc' && {
          serviceProviders: state.serviceProviders,
        }),
        operations: state.operations,
        dashboards: state.dashboards,
        layouts: state.layouts,
      },
      type: mode,
      user: auth?.user?.id,
      market: query?.market?.toUpperCase(),
    });
  };

  const handleSubmit = (name: string, resetForm: any) => {
    handleSaveFavoriteRadar(name);
    resetForm();
  };

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: validationSchema,
    onSubmit: (value, { resetForm }) => {
      return handleSubmit(value.name, resetForm);
    },
  });

  const handleCloseDialog = (e: {}) => {
    formik.handleReset(e);
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth={false}
      sx={{
        maxWidth: 850,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            padding: '39px 58px 0px',
            [theme.breakpoints.down('sm')]: {
              padding: '20px 24px',
            },
          })}>
          <Typography fontSize={18} fontWeight={700} mb={7} textAlign="center">
            Salvar novo radar
          </Typography>
          <Typography fontSize={16} fontWeight={400} mb={2} color={'secondary'}>
            Nome do radar*
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              variant="outlined"
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              placeholder="Dê um nome para esse conjunto de parâmetros que será salvo nos seu radar"
              inputProps={{ maxLength: 30 }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            gap: '24px',
            justifyContent: 'center',
            padding: '85px 0 49px 0',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
              padding: '24px',
              gap: '12px',
            },
            '& > button': {
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
                marginLeft: '0 !important',
              },
            },
          })}>
          <Button variant="contained" type="submit">
            {!loading ? (
              'Salvar'
            ) : (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            )}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            type="reset"
            onClick={handleCloseDialog}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
