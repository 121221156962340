import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import image from '../../assets/imgs/imageDemonstration.png';
import logo from '../../assets/imgs/logoUqbar.svg';

const Image = styled.img`
  position: absolute;
  left: 331px;
  top: 60px;
  @media only screen and (max-width: 1200px) {
    left: 283px;
  }
  @media only screen and (max-width: 768px) {
    top: 75px;
    width: 135px;
    left: 173px;
  }
`;

type Props = {
  title?: string;
  subTitle?: string;
  labelButton?: string;
  url?: string;
  sx?: SxProps<Theme>;
};

function CardDemonstration({ title, subTitle, labelButton, url, sx }: Props) {
  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        marginTop: '1rem',
        position: 'relative',
        border: '1px solid #C8C9CC',
        marginBottom: '1rem',
        ...sx,
      }}>
      <Grid item container xs={12} sx={{ minHeight: 200 }}>
        <Grid item xs={12} sm={5} md={4} lg={5}>
          <CardMedia
            image={image}
            title={title}
            sx={(theme) => ({
              width: 397,
              minHeight: 180,
              height: '100%',
              [theme.breakpoints.down('lg')]: {
                width: 350,
              },
              [theme.breakpoints.down('md')]: {
                width: 200,
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          lg={7}
          container
          flexDirection="column"
          alignItems="flex-end">
          <CardContent
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 130,
              maxWidth: 450,
              textAlign: 'right',
              [theme.breakpoints.down('lg')]: {
                width: 405,
              },
              [theme.breakpoints.down('md')]: {
                width: 350,
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}>
            <Typography fontSize={18} fontWeight={700}>
              {title}
            </Typography>
            <Typography fontSize={12}>{subTitle}</Typography>
          </CardContent>
          {labelButton && url && (
            <CardActions
              sx={{
                justifyContent: 'flex-end',
                paddingRight: 2,
              }}>
              <Button
                component={Link}
                to={url}
                color="primary"
                variant="contained">
                {labelButton}
              </Button>
            </CardActions>
          )}
        </Grid>
      </Grid>
      <Hidden smDown>
        <Image src={logo} alt="UQBAR" />
      </Hidden>
    </Card>
  );
}

export default CardDemonstration;
