import { endOfMonth, isAfter, startOfMonth } from 'date-fns';
import qs from 'qs';
import { State } from 'src/types/radar';

import { api, publicApi } from '../../api/api';

/**
 * Public
 *  */
export const getRadarSegments = async (market: string) => {
  const { data } = await publicApi.get(
    `/radar/${market.toLowerCase()}/segments`,
  );
  return data;
};

export const getRadarIndicators = async (market: string) => {
  const query = qs.stringify(
    {
      filters: {
        market: {
          name: {
            $eq: market.toUpperCase(),
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/radar-indicadores?${query}`);
  return data?.data;
};

/**
 * Authenticated
 *  */
export const getRadarData = async (values: any) => {
  const { data } = await api.post('/radar', values);
  return data?.data;
};

export const createFavoriteRadar = async (values: any) => {
  return api.post('/favorite-radars', {
    data: {
      ...values,
    },
  });
};

export const getFavoriteRadar = async (market: string, type: string) => {
  const query = qs.stringify(
    {
      filters: {
        market: {
          name: {
            $eq: market.toUpperCase(),
          },
        },
        type,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/favorite-radars?${query}`);
  return data;
};

export const deleteFavoriteRadar = async (id: number) => {
  const { data } = await api.delete(`/favorite-radars/${id}`);
  return data;
};

export const getServiceProviders = async (values: any) => {
  const { data } = await api.post('/radar/service-providers', values);
  return data;
};

export const getRadarConvocations = async ({
  filters,
  state,
}: {
  filters: any;
  state: State;
}) => {
  const filterDate = state?.date && new Date(state?.date)?.setDate(1);
  const todayDate = new Date();
  const startDate = filterDate && startOfMonth(new Date(filterDate));
  const endDate = filterDate && endOfMonth(new Date(filterDate));
  const betweenDates = filterDate && [startDate, endDate];
  const hasMonthPassed = endDate && isAfter(endOfMonth(new Date()), endDate);
  const hashIds = state?.operations?.length
    ? state.operations.map((operation: any) => operation.hashId)
    : undefined;

  const query = qs.stringify(
    {
      filters: {
        operation: {
          market: {
            name: {
              $eq: filters?.market?.toUpperCase(),
            },
          },
          hashId: hashIds,
        },
        type: {
          $in: state.type?.length ? state.type : [],
        },
        documentType: {
          $in: state.documentType?.length ? state.documentType : [],
        },
        ...(betweenDates
          ? {
              date: {
                $between: betweenDates,
              },
            }
          : {
              date: {
                $gte: todayDate,
              },
            }),
      },
      populate: ['market', 'operation', 'operation.market'],
      pagination: {
        pageSize: 100,
      },
      sort: [`date:${hasMonthPassed ? 'desc' : 'asc'}`],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/convocations?${query}`);
  return data;
};

export const getRadarOperationsConvocations = async ({
  market,
}: {
  market: string;
}) => {
  const query = qs.stringify(
    {
      fields: ['id'],
      filters: {
        operation: {
          market: {
            name: {
              $eq: market?.toUpperCase(),
            },
          },
        },
      },
      populate: {
        operation: {
          fields: ['hashId', 'name'],
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/convocations?${query}`);
  return data;
};

export const getDashboards = async (market: string, ids?: number[]) => {
  const query = qs.stringify(
    {
      filters: {
        id: ids,
        market: {
          name: {
            $eq: market.toUpperCase(),
          },
        },
      },
      populate: ['market'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/metabase-charts?${query}`);
  return data;
};
