// Material ui components
import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { Dialog } from '../../../components/Dialog';

// Styles
const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'justify',
    margin: theme.spacing(2, 3),
  },
  button: {
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
}));

export default function CourseSubscriptionModal({
  open,
  handleClose,
  handleSubscription,
}) {
  // Styles
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogContent>
        <Typography className={classes.text}>
          Solicitações de cancelamento do treinamento devem ser feitas, por
          escrito, com antecedência mínima de 5 (cinco) dias da data do evento.
          Pedidos de cancelamento formulados fora do prazo estipulado
          acarretarão a incidência de multa por rescisão no percentual de 20%
          (vinte por cento) do valor do treinamento, estando a Uqbar autorizada
          a reter o referido valor do reembolso a ser feito ao cliente. Em
          hipótese de não cancelamento da participação no treinamento e/ou não
          comparecimento na data do evento, o valor total da inscrição permanece
          devido, não fazendo jus a qualquer reembolso. O treinamento será
          gravado pela Uqbar exclusivamente para fins internos, não sendo
          disponibilizada a gravação para os clientes.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          className={classes.button}
          variant="contained"
          onClick={handleSubscription}>
          Aceito
        </Button>
      </DialogActions>
    </Dialog>
  );
}
