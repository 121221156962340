import React from 'react';

import { v4 as uuid } from 'uuid';

import * as S from './styles';

type Props = {
  texts: JSX.Element[];
  images: Array<JSX.Element | null>;
};

const StackedDisplay = (props: Props) => {
  return (
    <S.Wrapper>
      {props.texts.map((element, index) => (
        <div key={uuid()}>
          <>{element}</>
          <>{props.images[index]}</>
        </div>
      ))}
    </S.Wrapper>
  );
};

export default StackedDisplay;
