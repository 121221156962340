import { makeStyles } from '@material-ui/core';

export const useAnalyticalStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '29.05px',
    '&:last-child': {
      marginLeft: '1.5rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  },
  gridDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1.2rem',
    '& > .updated': {
      color: '#6D6E71',
      fontWeight: 500,
    },
    '& > .baseMonth': {
      color: '#6D6E71',
      fontWeight: 700,
      marginTop: '0.5rem',
    },
  },
  textCriteria: {
    color: '#6D6E71',
    fontWeight: 500,
    marginBottom: 0,
    '& > p': {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  readMoreButton: {
    margin: 0,
    padding: 0,
    fontSize: '0.875rem',
    '& > span': {
      justifyContent: 'flex-start',
    },
  },
  gridSwitch: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  resumeSwitch: {
    '@media print': {
      display: 'none',
    },
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));
