import { useEffect, useRef, useState } from 'react';

import { PlayCircle } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { getVideos } from 'src/services/gallery';
import { Video } from 'src/types/institution';
import { getLargestImageUrl } from 'src/utils/image';

import VideoCarouselModal from './VideoCarouselModal';

function Videos({ institutionId }: { institutionId: number }) {
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [page, setPage] = useState(1);
  const refBox = useRef<HTMLDivElement>(null);

  const { data: videos } = useQuery({
    queryKey: ['videos', page],
    queryFn: () => getVideos({ institutionId, page }),
    keepPreviousData: true,
  });

  useEffect(() => {
    const offsetTop = refBox.current?.offsetTop ?? 0;

    window.scrollTo({
      top: offsetTop - 200,
      behavior: 'smooth',
    });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box
      ref={refBox}
      sx={(theme) => ({
        padding: '0 64px',
        [theme.breakpoints.down('sm')]: { padding: '0 32px' },
      })}>
      <If condition={videos?.data?.length === 0}>
        <Box width={'100%'}>
          <Typography
            fontSize={18}
            fontWeight={500}
            fontStyle={'italic'}
            textAlign={'center'}
            color={'#828282'}>
            Não há registros para exibir
          </Typography>
        </Box>
      </If>
      <If condition={videos?.data?.length > 0}>
        <Grid
          container
          columnSpacing={{ sm: 4, md: 3, lg: 1 }}
          rowSpacing={{ xs: 4, md: 3, lg: 4 }}>
          {videos?.data?.map((video: Video, key: number) => (
            <Grid
              container
              item
              justifyContent="center"
              xs={12}
              sm={6}
              md={4}
              key={`${key}-${video.id}`}>
              <Card sx={{ width: '271px', height: '100%', borderRadius: 0 }}>
                <CardActionArea
                  onClick={() => {
                    setIndex(key);
                    setOpen(true);
                  }}>
                  <CardMedia
                    sx={{ aspectRatio: '4 / 3', filter: 'brightness(0.5)' }}
                    image={getLargestImageUrl(video.thumbnail)}
                    title={video.title}
                    component="img"
                    alt={video.title}
                  />
                  <PlayCircle
                    color="secondary"
                    sx={(theme) => ({
                      position: 'absolute',
                      inset: 0,
                      margin: 'auto',
                      fontSize: '64px',
                      color: theme.palette.primary.contrastText,
                    })}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <If condition={videos?.meta?.pagination?.pageCount > 1}>
          <Box display={'flex'} justifyContent={'center'} mt={6}>
            <Pagination
              count={videos?.meta?.pagination?.pageCount}
              shape="rounded"
              onChange={handleChange}
            />
          </Box>
        </If>
        <VideoCarouselModal
          open={open}
          onClose={() => setOpen(false)}
          content={videos?.data ?? []}
          selected={index}
          setSelected={setIndex}
        />
      </If>
    </Box>
  );
}

export default Videos;
