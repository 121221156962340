import { useEffect, useState } from 'react';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import {
  findProfessionalById,
  requestProfessionalOwner,
  updateProfessional,
} from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { ScrollToTop } from 'src/utils/scroll';

import About from './About';
import { Achievements } from './Achievements';
import { Activities } from './Activities';
import { CertificationsList } from './CertificationsList';
import Contact from './Contacts';
import { ExperiencesList } from './ExperiencesList';
import Header from './Header';
import { RelatedInstitutions } from './RelatedInstitutions';
import { ProfessionalRelatedOperations } from './RelatedOperations';
import { SegmentsAndMarkets } from './SegmentsAndMarkets';

export default function ProfessionalProfile() {
  const { hashId } = useParams<{ hashId: string }>();
  const { auth } = useAuthContext();
  const dispatch = useDispatch();
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const {
    data,
    isLoading: professionalLoading,
    refetch,
  } = useQuery('professional', () => findProfessionalById(hashId));

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('professionalOwnerPlanFree'),
    {
      enabled: data?.accountOwnerIsFree,
    },
  );

  const updateProfessionalMutation = useMutation(updateProfessional, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar os dados do profissional',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Perfil profissional atualizado',
          }),
        );
        refetch();
      }
    },
  });

  const requestProfessionalOwnerMutation = useMutation(
    requestProfessionalOwner,
    {
      onSuccess: () => {
        dispatch(
          setBannerMessage({
            message: 'E-mail enviado com sucesso',
            type: 'success',
            autoclose: true,
          }),
        );
      },
      onError: () => {
        dispatch(
          setBannerMessage({
            message: 'Ocorreu um erro. Tente novamente',
            type: 'error',
            autoclose: true,
          }),
        );
      },
    },
  );

  const handleUpdate = (values: any) => {
    if (data?.id) {
      updateProfessionalMutation.mutateAsync({
        id: data.id,
        values,
      });
    }
  };

  if (professionalLoading) return <CircularProgress />;

  return (
    <ScrollToTop>
      {!!data && <Header professional={data} refetch={refetch} />}
      <Box display="flex" flexDirection="column">
        <If
          condition={
            !!data?.professionalSegments?.length || !!data?.markets?.length
          }>
          <Box sx={{ order: 1 }}>
            <SegmentsAndMarkets
              segments={data?.professionalSegments}
              markets={data?.markets}
            />
          </Box>
        </If>

        <If condition={data?.relatedInstitutions || data?.institutionName}>
          <Box sx={{ order: data?.accountOwnerIsFree ? 2 : 5 }}>
            <div id="relatedInstitution" />
            <RelatedInstitutions
              relatedInstitutions={data?.relatedInstitutions}
              professional={data}
            />
          </Box>
        </If>

        <If condition={!data?.hasOwner}>
          <Section>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography sx={{ maxWidth: 630, fontWeight: 700 }}>
                  Para reivindicar seu perfil profissional clique em reivindicar
                  perfil
                </Typography>
              </Grid>
              <Grid item>
                <CustomTooltip
                  placement="top"
                  title={
                    auth.isLoggedIn
                      ? ''
                      : 'Realize o login para acessar funcionalidade'
                  }>
                  <span>
                    <Button
                      disabled={!auth.isLoggedIn}
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        requestProfessionalOwnerMutation.mutateAsync(data?.id)
                      }>
                      Reivindicar perfil
                    </Button>
                  </span>
                </CustomTooltip>
              </Grid>
            </Grid>
          </Section>
        </If>
        <If condition={data?.hasOwner && data?.accountOwnerIsFree}>
          <Box sx={{ order: 3, mt: 6 }}>
            <CardDemonstration
              title={contentPayWallBanner?.professionalOwnerPlanFree?.title}
              subTitle={
                contentPayWallBanner?.professionalOwnerPlanFree?.subTitle
              }
            />
          </Box>
        </If>
        <If condition={data?.hasOwner && !data?.accountOwnerIsFree}>
          <If condition={data?.profileText}>
            <Box sx={{ order: 3 }}>
              <About
                professional={data}
                handleUpdate={handleUpdate}
                isSuccess={updateProfessionalMutation.isSuccess}
                isLoading={updateProfessionalMutation.isLoading}
              />
            </Box>
          </If>
          <If condition={!!data?.emails?.length || !!data?.phones?.length}>
            <Box sx={{ order: 4 }}>
              <Contact
                professional={data}
                handleUpdate={handleUpdate}
                isSuccess={updateProfessionalMutation.isSuccess}
                isLoading={updateProfessionalMutation.isLoading}
              />
            </Box>
          </If>
          <If condition={data?.relatedOperations?.length}>
            <Box sx={{ order: 6 }}>
              <ProfessionalRelatedOperations
                relatedOperations={data?.relatedOperations}
                professional={data}
                handleUpdate={handleUpdate}
                isSuccess={updateProfessionalMutation.isSuccess}
                isLoading={updateProfessionalMutation.isLoading}
              />
            </Box>
          </If>
          <If condition={data?.achievements?.length}>
            <Box sx={{ order: 7 }}>
              <Achievements achievements={data?.achievements} />
            </Box>
          </If>
          <If condition={data?.experiences?.length}>
            <Box sx={{ order: 8 }}>
              <ExperiencesList
                experiences={data?.experiences}
                professional={data}
                handleUpdate={handleUpdate}
                isSuccess={updateProfessionalMutation.isSuccess}
                isLoading={updateProfessionalMutation.isLoading}
              />
            </Box>
          </If>
          <If condition={data?.certifications?.length}>
            <Box sx={{ order: 9 }}>
              <CertificationsList
                certifications={data?.certifications}
                professional={data}
                handleUpdate={handleUpdate}
                isSuccess={updateProfessionalMutation.isSuccess}
                isLoading={updateProfessionalMutation.isLoading}
              />
            </Box>
          </If>
          <If condition={data?.activities?.length}>
            <Box sx={{ order: 10 }}>
              <Activities activities={data?.activities} professional={data} />
            </Box>
          </If>
        </If>
      </Box>
      <If condition={showTopButton}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'sticky',
            bottom: 5,
            left: 0,
            zIndex: 999,
            marginTop: '-32px',
          }}>
          <Paper
            elevation={3}
            onClick={goToTop}
            sx={{
              backgroundColor: '#F1F1F1',
              opacity: 0.5,
              cursor: 'pointer',
              width: 62,
              height: 62,
              borderRadius: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                opacity: 1,
              },
            }}>
            <KeyboardArrowUpRoundedIcon sx={{ fontSize: 40 }} />
          </Paper>
        </Box>
      </If>
    </ScrollToTop>
  );
}
