import { useEffect, useState } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
// Declarative routing
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

// Material ui components
// Images
import breakDefault from '../../../assets/imgs/articles/detail.png';
import { If } from '../../../components/If';
import { PanelLogin } from '../../../components/PanelLogin';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { getOperationRelatedArticles } from '../../../services/operation';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    minHeight: 180,
    height: '100%',
  },
  content: {
    padding: theme.spacing(0, 0, 1, 1),
    height: 135,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar:vertical': {
      width: theme.spacing(1.5),
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(1),
      border: '2px solid white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  typography: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.common.black,
  },
  button: {
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    width: '100%',
    marginTop: '1rem',
  },
  boxCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  cardActions: {
    justifyContent: 'space-between',
    padding: '1rem',
  },
  chip: {
    minWidth: 60,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: '0.2rem',
    marginTop: '0.2rem',
  },
  boxLink: {
    minWidth: '70px',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export default function OperationRelatedArticles({ hash }) {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const limit = 3;
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(0);
  const { data, isLoading, refetch } = useQuery('relatedArticles', () =>
    getOperationRelatedArticles({ start: from, limit, operationId: hash }),
  );

  useEffect(() => {
    refetch();
  }, [hash, from]);

  const onChangePagination = (event, value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setFrom((page - 1) * limit);
  }, [page]);

  if (isLoading) return <CircularProgress size="1.8rem" />;

  return (
    <Box p={2}>
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions
            .operacoes_artigos_relacionados
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions
            .operacoes_artigos_relacionados
        }>
        <Grid container spacing={1}>
          <If condition={data.data?.length > 0}>
            {data.data?.map(
              ({ id, title, slug, headerImage, publishedAt, markets }) => (
                <Card key={id} className={classes.card}>
                  <Grid item container xs={12}>
                    <Grid item xs={12} sm={6}>
                      <CardMedia
                        image={headerImage?.url ?? breakDefault}
                        title={title}
                        className={classes.image}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.boxCard}>
                        <CardContent>
                          <Typography variant="h5" className={classes.title}>
                            {title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p">
                            {`Publicado em ${moment(publishedAt).format('DD/MM/yyyy')}`}
                          </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                          <Box>
                            <If condition={markets?.length}>
                              {markets.map((categorie) => (
                                <Chip
                                  key={categorie.id}
                                  label={categorie.name}
                                  color="secondary"
                                  className={classes.chip}
                                />
                              ))}
                            </If>
                          </Box>
                          <Box className={classes.boxLink}>
                            <Typography>
                              <Link
                                to={`/artigo/${slug}/${id}`}
                                component={RouterLink}
                                color="secondary">
                                Ver mais
                              </Link>
                            </Typography>
                          </Box>
                        </CardActions>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              ),
            )}
          </If>
          <If condition={!data.data?.length}>
            <Box p={2}>
              <Typography>
                Ops… Não existe nenhum artigo relacionado a essa operação.
              </Typography>
            </Box>
          </If>
        </Grid>
        <If condition={data.data?.length > 0}>
          <Box pt={3} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(data?.meta?.pagination?.total / limit)}
              page={page}
              onChange={onChangePagination}
              shape="rounded"
              size="large"
            />
          </Box>
        </If>
      </If>
    </Box>
  );
}
