import { useEffect, useState } from 'react';

import { AddCircle, Cancel } from '@mui/icons-material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import SubTitle from 'src/components/UI/Subtitle';
import TabTemplate from 'src/components/UI/TabTemplate';
import { setBannerMessage } from 'src/store/display';

import { List } from './styles';
import { ValidateForm } from './validation';
import { useAuthContext } from '../../../context/AuthContextProvider';
import {
  createWidgetKey,
  findRestrictions,
  manageRestriction,
} from '../../../services/widget';

type Data = {
  restrictions: {
    domain: string;
    __component: 'widget.website';
  }[];
  updatedAt: number;
  id?: number;
};

export function WidgetManagement() {
  const dispatch = useDispatch();
  const { auth, reloadUser } = useAuthContext();
  const [data, setData] = useState<Data>({
    restrictions: [],
    updatedAt: 0,
  });

  const { refetch } = useQuery(
    ['profile', 'widget'],
    () => findRestrictions(auth?.user?.widget?.id),
    {
      onSuccess: (result) => {
        setData(result);
      },
      enabled: !!auth?.user?.widget?.id && !auth.isLoading,
      retry: false,
    },
  );

  const createRestrictions = useMutation(createWidgetKey, {
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Domínio cadastrado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      await reloadUser();
      await refetch();
    },
    onError: () => {
      dispatch(
        setBannerMessage({
          message:
            'Ocorreu um erro durante o cadastro do domínio, tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
  });

  const manageRestrictions = useMutation(manageRestriction, {
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'Domínios atualizados com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      refetch();
    },
    onError: () => {
      dispatch(
        setBannerMessage({
          message:
            'Ocorreu um erro durante a atualização dos domínios, tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      restrictions: [{ domain: '', __component: 'widget.website' }],
      updateAt: 0,
    },
    validationSchema: ValidateForm,
    onSubmit: (values) => {
      if (data.id) {
        manageRestrictions.mutate(values);
      } else {
        createRestrictions.mutate(values.restrictions);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (data) {
      formik.setValues({
        id: data.id ?? 0,
        restrictions: data.restrictions,
        updateAt: data.updatedAt,
      });
    }
  }, [data]);

  const getDomainError = (
    fieldName: keyof { domain: string },
    index: number,
  ) => {
    const error = formik.errors.restrictions?.[index] as
      | { domain: string }
      | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Box>
      <TabTemplate
        title="Widget"
        titleIcon={<WidgetsIcon />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
        breadcrumbs1="Widget"
      />
      <Paper elevation={2} sx={{ marginTop: 9, padding: '56px 30px 10px' }}>
        <SubTitle title="Widget" />
        <Box sx={() => ({ padding: '32px 30px' })}>
          <Typography fontSize={14} paddingBottom={5}>
            Incorpore o widget dos rankings Uqbar em qualquer site, informe
            abaixo os domínios das páginas que terão permissão para embutir o
            componente.
          </Typography>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <FormikProvider value={formik}>
              <FieldArray
                name="restrictions"
                render={(arrayHelpers) => (
                  <>
                    {formik.values?.restrictions &&
                      formik.values?.restrictions?.length > 0 &&
                      formik.values?.restrictions.map((_, index) => (
                        <Grid
                          key={`restriction-${index}`}
                          xs={12}
                          md={6}
                          item
                          sx={{
                            paddingBottom: '16px',
                          }}>
                          <Grid xs={12} sx={{ flexWrap: 'nowrap' }} container>
                            <Grid item xs={10} sx={{ width: '100%' }}>
                              <FormControl fullWidth variant="outlined">
                                <TextField
                                  required
                                  name={`restrictions[${index}].domain`}
                                  value={
                                    formik.values.restrictions[index].domain
                                  }
                                  onChange={formik.handleChange}
                                  placeholder="*.seudominio.com"
                                  error={
                                    formik?.touched.restrictions &&
                                    formik?.touched?.restrictions[index]
                                      ?.domain &&
                                    Boolean(getDomainError('domain', index))
                                  }
                                  helperText={
                                    formik?.touched.restrictions &&
                                    formik?.errors.restrictions &&
                                    formik?.touched?.restrictions[index]
                                      ?.domain &&
                                    getDomainError('domain', index)
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <If
                              condition={
                                formik.values?.restrictions?.length < 10 &&
                                formik.values?.restrictions?.length - index ===
                                  1
                              }>
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  disabled={
                                    formik.values?.restrictions?.length > 9
                                  }
                                  sx={(theme) => ({
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                      color: theme.palette.secondary.light,
                                    },
                                  })}
                                  onClick={() => {
                                    if (formik.values?.restrictions?.length > 9)
                                      return;
                                    arrayHelpers.push({
                                      domain: '',
                                      __component: 'widget.website',
                                    });
                                  }}>
                                  <AddCircle fontSize="large" color="primary" />
                                </IconButton>
                              </Grid>
                            </If>
                            <If
                              condition={
                                formik.values?.restrictions?.length > 1 &&
                                index > 0
                              }>
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  sx={(theme) => ({
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                      color: theme.palette.secondary.light,
                                    },
                                  })}
                                  onClick={() => arrayHelpers.pop()}>
                                  <Cancel fontSize="large" color="primary" />
                                </IconButton>
                              </Grid>
                            </If>
                          </Grid>
                        </Grid>
                      ))}
                  </>
                )}
              />
            </FormikProvider>

            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: '32px' }}>
              {manageRestrictions.isLoading || createRestrictions.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </form>
        </Box>

        <SubTitle title="Como restringir minha chave a sites específicos?" />
        <Box sx={() => ({ padding: '32px 30px' })}>
          <Typography fontSize={14} paddingBottom={3}>
            Veja alguns exemplos de configuração de domínios permitidos:
          </Typography>
          <List>
            <li>
              Uma URL específica com um caminho exato:{' '}
              <strong>www.uqbar.com.br/caminho</strong>
            </li>
            <li>
              Qualquer caminho em um único subdomínio usando um asterisco (*):{' '}
              <strong>sub.uqbar.com.br/*</strong>
            </li>
            <li>Um domínio e todos os subdomínios, use um asterisco (*):</li>
            <ul>
              <li>
                <strong>uqbar.com.br</strong>
              </li>
              <li>
                <strong>*.uqbar.com.br</strong>
              </li>
            </ul>
            <li>
              Uma URL com uma porta que não é padrão:{' '}
              <strong>www.uqbar.com.br:8000</strong>
            </li>
          </List>
        </Box>
      </Paper>
    </Box>
  );
}
