import qs from 'qs';

import { publicApi } from '../../api/api';

export const getTestemonials = async (pagination) => {
  const query = qs.stringify(
    {
      populate: '*',
      pagination,
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await publicApi.get(`/testimonials?${query}`);
  return data;
};
