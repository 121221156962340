import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

// Material ui components
// Global components
import OperationCRAAsset from './OperationCRAAsset';
import OperationCRAGeneralData from './OperationCRAGeneralData';
import OperationInvestors from './OperationCRAInvestors';
import OperationCRALiability from './OperationCRALiability';
import OperationCRAResume from './OperationCRAResume';
import OperationCRAServiceProvider from './OperationCRAServiceProvider';
import { If } from '../../../components/If';
import MetaTags from '../../../components/Seo/MetaTags';
import Subheader from '../../../components/Subheader';
// Local components
import { TabSection } from '../../../components/TabSection';
import ipanema from '../../../graphql/clients/ipanema';
import { GET_CRA_CADASTRAL_DATA_BY_OPERATION_HASH_ID } from '../../../graphql/queries';
import OperationDocuments from '../OperationDocuments';
// Apollo client
import OperationRelatedArticles from '../OperationRelatedArticles';
import OperationSecondary from '../OperationSecondary';

export default function OperationCRA() {
  const { tab, hashId, market, slug } = useParams();
  const history = useHistory();
  const [value, setValue] = useState(
    parseInt(window.localStorage.getItem('operationTabIndex')) || 'resumo',
  );
  const tabs = [
    { label: 'Resumo', value: 'resumo' },
    { label: 'Dados Gerais', value: 'dados' },
    { label: 'Prestadores de Serviço', value: 'prestadores' },
    { label: 'Ativo', value: 'ativo' },
    { label: 'Passivo', value: 'passivo' },
    { label: 'Secundário', value: 'secundario' },
    { label: 'Investidores', value: 'investidores' },
    { label: 'Documentos', value: 'documentos' },
    { label: 'Artigos Relacionados', value: 'artigos' },
  ];

  const [data, setData] = useState({});
  const { loading, error } = useQuery(
    GET_CRA_CADASTRAL_DATA_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      onCompleted: ({ getCraCadastralDataByOperationHashId }) =>
        setData(getCraCadastralDataByOperationHashId),
    },
  );

  const handleTabChange = (tabIndex) => {
    setValue(tabIndex);
    if (tabIndex === 'resumo') {
      history.push(`/operacao/${market}/${slug}/${hashId}`);
    } else {
      history.push(`/operacao/${market}/${slug}/${hashId}/${tabIndex}`);
    }
  };

  useEffect(() => {
    setValue(tab ?? 'resumo');
  }, []);

  useEffect(() => {
    window.localStorage.setItem('operationTabIndex', JSON.stringify(value));
    return function clean() {
      localStorage.removeItem('operationTabIndex');
    };
  }, [value]);

  if (loading) return <CircularProgress color="secondary" />;
  if (error) return null;

  const name = data?.operationName ?? '';

  return (
    <>
      <MetaTags
        title={`${name} - CRA`}
        description={`Encontre as principais informações sobre ${name}`}
      />
      <Subheader
        title={name}
        market={market?.toUpperCase()}
        breadcrumbs1="Certificados"
        breadcrumbs2={`${name} ${market?.toUpperCase()}`}
        favoriteType="operation"
      />
      <Box py={2}>
        <TabSection tabList={tabs} value={value} setValue={handleTabChange}>
          <Paper square>
            <If condition={value === 'resumo'}>
              <OperationCRAResume hashId={hashId} />
            </If>
            <If condition={value === 'dados'}>
              <OperationCRAGeneralData hashId={hashId} />
            </If>
            <If condition={value === 'prestadores'}>
              <OperationCRAServiceProvider hashId={hashId} />
            </If>
            <If condition={value === 'ativo'}>
              <OperationCRAAsset hashId={hashId} operationName={name} />
            </If>
            <If condition={value === 'passivo'}>
              <OperationCRALiability hash={hashId} />
            </If>
            <If condition={value === 'secundario'}>
              <OperationSecondary
                hash={hashId}
                market={market?.toUpperCase()}
              />
            </If>
            <If condition={value === 'documentos'}>
              <OperationDocuments hash={hashId} />
            </If>
            <If condition={value === 'artigos'}>
              <OperationRelatedArticles hash={hashId} />
            </If>
            <If condition={value === 'investidores'}>
              <OperationInvestors
                hash={hashId}
                market={market.toUpperCase()}
                operationName={name}
              />
            </If>
          </Paper>
        </TabSection>
      </Box>
    </>
  );
}
