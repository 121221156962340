import { CircularProgress, Paper } from '@mui/material';
import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { findProfessionalById } from 'src/services/professional';
import { Institution } from 'src/types/institution';
import { ScrollToTop } from 'src/utils/scroll';

import { ProfessionalForm } from './ProfessionalForm';

export default function ProfessionalProfile({
  relatedInstitution,
}: {
  relatedInstitution: Institution;
}) {
  const { auth } = useAuthContext();

  const {
    data: professional,
    isLoading: professionalLoading,
    refetch: refecthProfessional,
  } = useQuery(
    'professional',
    () => findProfessionalById(String(auth?.user?.professional)),
    {
      enabled: !!auth.user?.id && !!auth?.user?.professional,
    },
  );

  if ((auth?.user?.professional && !auth.isFetched) || professionalLoading)
    return <CircularProgress />;

  return (
    <ScrollToTop>
      <Paper
        elevation={2}
        sx={(theme) => ({
          marginTop: 4,
          padding: '26px 34px',
          [theme.breakpoints.down('sm')]: { padding: '26px 16px' },
        })}>
        <ProfessionalForm
          data={professional}
          professionalRefetch={refecthProfessional}
          institution={relatedInstitution}
        />
      </Paper>
    </ScrollToTop>
  );
}
