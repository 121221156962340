import React from 'react';

import { useHistory } from 'react-router-dom';

import * as S from './styles';

type Props = {
  path: string;
};

const Link = ({ path, children }: React.PropsWithChildren<Props>) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(path);
  };

  return <S.Link onClick={handleClick}>{children}</S.Link>;
};

export default Link;
