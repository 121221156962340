import qs from 'qs';

import { api, embedApi } from '../../api/api';

export const findRestrictions = async (widgetId) => {
  const query = qs.stringify(
    {
      populate: ['restrictions'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/widgets/${widgetId}?${query}`);
  return data.data;
};

export const manageRestriction = async (widget) => {
  const { data } = await api.put(`/widgets/${widget.id}`, {
    data: {
      restrictions: widget.restrictions,
    },
  });
  return data;
};

export const createWidgetKey = async (restrictions) => {
  const { data } = await api.post('/widgets', { data: { restrictions } });
  return data;
};

export const validateWidget = async () => {
  const { data } = await embedApi.get('/widget/validate');
  return data;
};
