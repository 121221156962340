import theme from 'src/theme';
import styled from 'styled-components';

import * as YearbookSection from '../../components/Section';

export const Section = styled.section`
  padding: 2rem 0 2rem;
  min-height: 100dvh;
  position: relative;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding-bottom: 0;
  }
`;

export const Title = styled(YearbookSection.Title)`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const Description = styled(YearbookSection.Paragraph)`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);
  margin-top: 2rem;

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const DescriptionParagraph = styled(YearbookSection.Paragraph)`
  color: #585858;
`;

export const Content = styled.div`
  display: flex;
  gap: 4rem;
  margin-top: 4rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    gap: 2rem;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 0.5rem;
  }
`;

export const WidgetTitle = styled.h4`
  font-weight: 900;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 1.1rem;
  }
`;

export const WidgetContentParagraph = styled(YearbookSection.Paragraph)`
  color: #585858;
  font-size: 1.1rem;
  margin: 0.5rem 0;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 0.8rem;
  }
  p {
    margin: 0;
  }
`;

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: auto;
  min-width: 50%;
  max-width: 50%;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 8rem;
    max-width: inherit;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 1rem;
  }
`;

export const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 0 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const Button = styled.button`
  align-self: flex-end;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  background-color: #ff8211;
  color: white;
  padding: 1rem 4rem;
  border-radius: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 2rem 1rem 2rem;
  margin-right: calc(80px + 4rem);
  cursor: pointer;
  border: none;
  font-family: 'Inter', sans-serif;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    align-self: center;
    margin-right: 0;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 1rem 2rem;
  }
`;
