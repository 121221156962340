import { useEffect, useRef, useState } from 'react';

// Material ui components
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Global components
import { If } from '../../../../../components/If';
import PanelFiiLiabilityQuote from '../../../../../components/PanelFiiLiabilityQuote';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
//Apolo client
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FII_QUOTES_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    height: 730,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  sector: {
    margin: '50px 24px',
    width: 554,
    height: 650,
    position: 'absolute',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  slider: {
    transition: 'all 0.5s',
  },
  button: {
    border: '1px solid',
    height: 30,
    minWidth: 30,
  },
}));

function Seniors({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={730} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Seniores
        </Typography>
      </Box>
      <Box className={classes.sector}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(
              (
                {
                  quoteSerie,
                  quoteCompetence,
                  quoteNetWorth,
                  quoteEquityValue,
                  quoteTicker,
                  emissionsInMonth,
                  lastDistributed,
                },
                index,
              ) => (
                <PanelFiiLiabilityQuote
                  key={index}
                  quoteSerie={quoteSerie}
                  quoteCompetence={quoteCompetence}
                  quoteNetWorth={quoteNetWorth}
                  quoteEquityValue={quoteEquityValue}
                  quoteTicker={quoteTicker}
                  emissionsInMonth={emissionsInMonth}
                  lastDistributed={lastDistributed}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1}>
          <KeyboardArrowLeftIcon titleAccess="Seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Mezzanines({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={730} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Mezaninos
        </Typography>
      </Box>
      <Box className={classes.sector}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(
              (
                {
                  quoteSerie,
                  quoteCompetence,
                  quoteNetWorth,
                  quoteEquityValue,
                  quoteTicker,
                  emissionsInMonth,
                  lastDistributed,
                },
                index,
              ) => (
                <PanelFiiLiabilityQuote
                  key={index}
                  quoteSerie={quoteSerie}
                  quoteCompetence={quoteCompetence}
                  quoteNetWorth={quoteNetWorth}
                  quoteEquityValue={quoteEquityValue}
                  quoteTicker={quoteTicker}
                  emissionsInMonth={emissionsInMonth}
                  lastDistributed={lastDistributed}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="Seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Subordinates({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={730} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Subordinadas
        </Typography>
      </Box>
      <Box className={classes.sector}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(
              (
                {
                  quoteSerie,
                  quoteCompetence,
                  quoteNetWorth,
                  quoteEquityValue,
                  quoteTicker,
                  emissionsInMonth,
                  lastDistributed,
                },
                index,
              ) => (
                <PanelFiiLiabilityQuote
                  key={index}
                  quoteSerie={quoteSerie}
                  quoteCompetence={quoteCompetence}
                  quoteNetWorth={quoteNetWorth}
                  quoteEquityValue={quoteEquityValue}
                  quoteTicker={quoteTicker}
                  emissionsInMonth={emissionsInMonth}
                  lastDistributed={lastDistributed}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="Seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Uniques({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={730} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Único
        </Typography>
      </Box>
      <Box className={classes.sector}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(
              (
                {
                  quoteSerie,
                  quoteCompetence,
                  quoteNetWorth,
                  quoteEquityValue,
                  quoteTicker,
                  emissionsInMonth,
                  lastDistributed,
                },
                index,
              ) => (
                <PanelFiiLiabilityQuote
                  key={index}
                  quoteSerie={quoteSerie}
                  quoteCompetence={quoteCompetence}
                  quoteNetWorth={quoteNetWorth}
                  quoteEquityValue={quoteEquityValue}
                  quoteTicker={quoteTicker}
                  emissionsInMonth={emissionsInMonth}
                  lastDistributed={lastDistributed}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="Seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

export default function OperationFIDCLiabilityQuote({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_DETAILS_FII_QUOTES_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fii_passivo'
      ],
    },
  );

  if (loading) return <CircularProgress color="secondary" />;
  if (error) return null;

  return (
    <div>
      <Subtitle title="Cotas" />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo']
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo']
        }>
        {data?.getFiiQuotesByOperationHashId?.seniors.length > 0 && (
          <Seniors
            classes={classes}
            data={data?.getFiiQuotesByOperationHashId?.seniors}
          />
        )}
        {data?.getFiiQuotesByOperationHashId?.mezzanines.length > 0 && (
          <Mezzanines
            classes={classes}
            data={data?.getFiiQuotesByOperationHashId?.mezzanines}
          />
        )}
        {data?.getFiiQuotesByOperationHashId?.subordinates.length > 0 && (
          <Subordinates
            classes={classes}
            data={data?.getFiiQuotesByOperationHashId?.subordinates}
          />
        )}
        {data?.getFiiQuotesByOperationHashId?.uniques.length > 0 && (
          <Uniques
            classes={classes}
            data={data?.getFiiQuotesByOperationHashId?.uniques}
          />
        )}
      </If>
    </div>
  );
}
