import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItem,
  ListItemText,
  Select as MaterialSelect,
  MenuItem,
} from '@material-ui/core';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 350,
      width: 'auto',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export function DocumentsSelectCustom({
  classes,
  values,
  handleChange,
  loading,
  options,
  name,
  label,
  md = 3,
  disabled,
}) {
  return (
    <Grid item xs={12} md={md}>
      <ListItem className={classes.listItem}>
        <FormControl className={classes.itemInput}>
          <InputLabel id={`${name}-${label}`}>{label}</InputLabel>
          <MaterialSelect
            labelId={`${name}-${label}`}
            id={name}
            name={name}
            multiple
            value={values}
            onChange={handleChange}
            input={<Input />}
            disabled={loading || disabled}
            renderValue={(selected) =>
              name === 'spread' ? selected?.join('; ') : selected?.join(', ')
            }
            MenuProps={MenuProps}>
            {options?.map((option, index) => (
              <MenuItem key={`${index}-${option}`} value={option}>
                <Checkbox checked={values?.some((value) => value === option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </MaterialSelect>
        </FormControl>
      </ListItem>
    </Grid>
  );
}
