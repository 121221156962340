import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import { RegisterImage } from 'src/types/image';

import { publicApi } from '../../api/api';

export const getUqbarMedias = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}) => {
  const query = qs.stringify(
    {
      sort: ['publishDate:desc'],
      pagination: {
        page,
        pageSize,
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data, meta },
  } = await publicApi.get(`/uqbarmedias?${query}`);
  return { data, total: meta?.pagination?.total };
};

export const getImageRegister = async (type: string) => {
  const query = qs.stringify(
    {
      populate: [type, `${type}.image`],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await publicApi.get<StrapiResponse<RegisterImage>>(
    `/platform-register?${query}`,
  );
  return data;
};

export const getSeo = async () => {
  const query = qs.stringify(
    {
      populate: ['seo'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/seo-uqbar-na-midia?${query}`);
  return data;
};
