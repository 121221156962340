import { Box, Chip, Grid, Hidden } from '@mui/material';
import { InvestorsTitle } from 'src/types/operation';

type Props = {
  titles: any;
  selectedTitle: any;
  setSelectedTitle: (value: any) => void;
};

export default function OperationHeader({
  titles,
  selectedTitle,
  setSelectedTitle,
}: Props) {
  return (
    <Box
      sx={(theme) => ({
        paddingTop: theme.spacing(1),
      })}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          container
          spacing={1}
          sx={(theme) => ({
            padding: theme.spacing(1, 0),
            justifyContent: 'flex-start',
            [theme.breakpoints.down('xs')]: {
              margin: theme.spacing(2, 0),
              justifyContent: 'center',
            },
          })}>
          {titles?.map(({ title }: InvestorsTitle, index: number) => (
            <>
              <Hidden xsDown>
                <Grid item key={`${title}-${index}`}>
                  <Chip
                    label={title?.toUpperCase()}
                    color={title === selectedTitle ? 'primary' : 'default'}
                    onClick={() => setSelectedTitle(title)}
                    variant="outlined"
                    sx={(theme) =>
                      title === selectedTitle
                        ? {
                            border: 0,
                            boxShadow: theme.shadows[1],
                            color: theme.palette.common.white,
                            fontWeight: 500,
                            backgroundColor: theme.palette.primary.main,
                            '&.MuiChip-root:hover': {
                              backgroundColor: theme.palette.primary.main,
                            },
                            '&.MuiChip-root:focus': {
                              backgroundColor: theme.palette.primary.main,
                            },
                          }
                        : {
                            border: 0,
                            fontWeight: 500,
                            boxShadow: theme.shadows[1],
                          }
                    }
                  />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid xs={6} sm={2} md={2} item key={`${title}-${index}`}>
                  <Chip
                    label={title?.toUpperCase()}
                    color={title === selectedTitle ? 'primary' : 'default'}
                    onClick={() => setSelectedTitle(title)}
                    variant="outlined"
                    sx={(theme) =>
                      title === selectedTitle
                        ? {
                            border: 0,
                            boxShadow: theme.shadows[1],
                            color: theme.palette.common.white,
                            fontWeight: 500,
                            backgroundColor: theme.palette.primary.main,
                            '&.MuiChip-root:hover': {
                              backgroundColor: theme.palette.primary.main,
                            },
                            '&.MuiChip-root:focus': {
                              backgroundColor: theme.palette.primary.main,
                            },
                          }
                        : {
                            border: 0,
                            fontWeight: 500,
                            boxShadow: theme.shadows[1],
                          }
                    }
                  />
                </Grid>
              </Hidden>
            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
