import { useEffect, useRef, useState } from 'react';

import { Box, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import { flatten } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';

import MarketplaceItemCard from '../../../components/MarketplaceItemCard';
import { getMarketplaceInstitutionServicesTypeProject } from '../../../services/institution';
import { Service } from '../../../types/marketplace';
import { useIsElementVisible, useQueryParams } from '../../../utils/hooks';
import Filters from '../Filters';

type ServiceState = {
  id: number;
  title: string;
  description: string;
  priceRange: string;
  slug: string;
  markets: Market[];
  segments: Segment[];
  type: 'Oferta' | 'Demanda' | 'Serviço' | 'Projeto';
};

export default function MarketplaceProjects({
  type,
}: {
  type: 'servicos' | 'projetos';
}) {
  const [{ query }, setQuery] = useQueryParams<{
    keyword?: string;
    segments?: string[];
    markets?: string[];
    institutions?: string[];
  }>({
    keyword: undefined,
    segments: [],
    markets: [],
    institutions: [],
  });
  const lastRef = useRef(null);
  const isLastVisible = useIsElementVisible(lastRef);
  const history = useHistory();

  const [total, setTotal] = useState<number>(0);
  const [lists, setLists] = useState<Service[]>([]);

  const {
    isLoading: demandsLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['marketplace', 'projects', query],
    ({ pageParam = 1 }) =>
      getMarketplaceInstitutionServicesTypeProject<Service>({
        ...query,
        page: pageParam,
        pageSize: 9,
      }),
    {
      onSuccess(data) {
        const page = data.pages.at(-1);
        setTotal(page?.meta?.pagination?.total ?? 0);
        setLists(flatten(data.pages.map((i: any) => i.data)));
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.data.length !== 0 ? nextPage : undefined;
      },
    },
  );

  const isLoading = demandsLoading || isFetchingNextPage;

  const handleOpenDetails = (service: ServiceState) => {
    history.push(
      `/marketplace/${service.type === 'Serviço' ? 'servico' : 'projeto'}/${service.slug}/${service.id}`,
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isLastVisible && lists?.length < total && !isLoading) {
        void fetchNextPage();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [isLastVisible, lists, isLoading]);

  return (
    <Box>
      <Filters query={query} setQuery={setQuery} type={type} />
      {lists.length > 0 && (
        <Grid container spacing={4}>
          {lists?.map((item: Service) => {
            const service = item as Service;
            return (
              <Grid item xs={12} sm={6} md={6} lg={4} key={service.id}>
                <MarketplaceItemCard
                  image={
                    service.institution?.logo?.formats?.thumbnail?.url ??
                    service.institution?.logo?.url ??
                    service.professional?.photo?.formats?.thumbnail?.url ??
                    service.professional?.photo?.url
                  }
                  imageTitle={
                    service.institution?.fantasyName ??
                    service.professional?.fullName
                  }
                  subheader={
                    <>
                      {service.markets?.map((market) => (
                        <Chip
                          size="small"
                          key={market.name}
                          label={market.name}
                          variant={'outlined'}
                          sx={(theme) => ({
                            color: theme.palette.grey['500'],
                            marginRight: 0.5,
                            marginTop: 0.5,
                            fontSize: '8.5px',
                          })}
                        />
                      ))}
                    </>
                  }
                  service={service}
                  type={service.type as 'Oferta' | 'Demanda'}
                  setData={setLists}
                  leftButtonIsVisible={true}
                  ownerType={
                    service.professional?.fullName
                      ? 'professional'
                      : 'institution'
                  }
                  actionRightButton={() => handleOpenDetails(service)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {lists?.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          padding="24px 0px">
          <Typography>Nenhum resultado encontrado</Typography>
        </Box>
      )}
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          padding="24px 0px">
          <CircularProgress color="secondary" size="3rem" />
        </Box>
      )}
      <div ref={lastRef} />
    </Box>
  );
}
