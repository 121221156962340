import { Place } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Institution } from 'src/types/institution';

import { Map } from './Map';

type Props = {
  institution: Institution;
};

function Address({ institution }: Props) {
  const { auth } = useAuthContext();
  return (
    <>
      {institution?.addresses[0]?.latitude &&
      institution?.addresses[0]?.longitude &&
      auth?.isLoggedIn ? (
        <Grid container spacing={1.9}>
          <Grid item xs={12} sm={4} md={4}>
            <Section
              title="Endereço"
              hasPaywall={true}
              paywallBanner="institutionProfile">
              <Box
                key={institution?.addresses[0].id}
                display="flex"
                sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                <IconButton
                  sx={{
                    marginRight: 2,
                    width: 26,
                    height: 26,
                    cursor: 'default',
                  }}>
                  <Place sx={{ fontSize: '1.5rem', color: 'black' }} />
                </IconButton>
                <Box display="flex" flexDirection="column">
                  <Typography>{`${institution?.addresses[0].line1}${institution.addresses[0].number ? `, ${institution.addresses[0].number}` : ''}`}</Typography>
                  {institution?.addresses[0].line2 && (
                    <Typography>{institution.addresses[0].line2}</Typography>
                  )}
                  <Typography>
                    {`${institution?.addresses[0].neighborhood ?? ''} ${
                      institution?.addresses[0].zipcode
                        ? `- ${institution?.addresses[0].zipcode}`
                        : ''
                    }`}
                  </Typography>
                  <Typography>
                    {`${institution?.addresses[0].city ?? ''} ${
                      institution?.addresses[0].state
                        ? `- ${institution?.addresses[0].state}`
                        : ''
                    }`}
                  </Typography>
                </Box>
              </Box>
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                height: '300px',
              },
            })}>
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '100%',
                paddingTop: 6,
                [theme.breakpoints.down('sm')]: {
                  paddingTop: 1,
                },
              })}>
              <Map institution={institution} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Section
          title="Endereço"
          hasPaywall={true}
          paywallBanner="institutionProfile">
          <Grid container columnSpacing={6} rowSpacing={2}>
            <Grid item>
              <Box
                key={institution?.addresses[0].id}
                display="flex"
                sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                <IconButton
                  sx={{
                    marginRight: 2,
                    width: 26,
                    height: 26,
                    cursor: 'default',
                  }}>
                  <Place sx={{ fontSize: '1.5rem', color: 'black' }} />
                </IconButton>
                <Box display="flex" flexDirection="column">
                  <Typography>{`${institution?.addresses[0].line1}${institution.addresses[0].number ? `, ${institution.addresses[0].number}` : ''}${institution.addresses[0].line2 ? ` - ${institution.addresses[0].line2}` : ''}`}</Typography>
                  <Typography>
                    {`${institution?.addresses[0].neighborhood ?? ''} ${
                      institution?.addresses[0].zipcode
                        ? `- ${institution?.addresses[0].zipcode}`
                        : ''
                    }`}
                  </Typography>
                  <Typography>
                    {`${institution?.addresses[0].city ?? ''} ${
                      institution?.addresses[0].state
                        ? `- ${institution?.addresses[0].state}`
                        : ''
                    }`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
    </>
  );
}

export default Address;
