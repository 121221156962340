import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getEventSchedule } from 'src/services/events';
import { Event, Presentation } from 'src/types/event';

import Content from './Content';
import Header from './Header';

export type EventPresentationFilters = {
  markets: number[];
  dates: string[];
  filterSaved: boolean;
};

export default function Schedule({
  event,
  refetch,
}: {
  event: Event;
  refetch: () => void;
}) {
  const [filters, setFilters] = useState({
    markets: [],
    dates: [],
    filterSaved: false,
  });
  const [dateFilterOptions, setDateFilterOptions] = useState<any[]>([]);

  const { id } = useParams<{ id: string }>();

  const { data, isLoading, isFetched } = useQuery(
    ['eventSchedule', filters],
    () => getEventSchedule({ eventId: id, filters }),
  );

  const { data: endedSchedules, isFetched: isFetchedEnded } = useQuery(
    ['endedEventSchedule', filters],
    () => getEventSchedule({ eventId: id, filters, isEnded: true }),
  );

  useEffect(() => {
    if (isFetched && isFetchedEnded && !dateFilterOptions.length) {
      setDateFilterOptions(
        _.uniq([
          ...(data?.map((schedule: Presentation) => schedule.date) || []),
          ...(endedSchedules?.map((schedule: Presentation) => schedule.date) ||
            []),
        ]).sort(),
      );
    }
  }, [isFetched, isFetchedEnded]);

  return (
    <Box
      sx={(theme) => ({
        p: '26px 36px 112px 34px',
        [theme.breakpoints.down('sm')]: {
          p: 0,
        },
      })}>
      <Header
        event={event}
        dateFilterOptions={dateFilterOptions}
        setFilters={setFilters}
        filters={filters}
      />
      <Content
        event={event}
        schedules={data}
        endedSchedules={endedSchedules}
        isLoading={isLoading}
        refetchEvent={refetch}
        setFilters={setFilters}
        filters={filters}
      />
    </Box>
  );
}
