import { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  createFilterOptions,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Hidden,
  Link,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FieldArrayRenderProps, FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { findOperationsByName } from 'src/services/operation';
import { RelatedOperation } from 'src/types/professional';

import * as S from '../../style';

const helperText =
  'Caso não encontre uma operação, entre em contato através do e-mail conteudo@uqbar.com.br';

export default function RelatedOperationForm({
  open,
  onClose,
  formik,
  index,
  arrayHelpers,
  actionType,
  handleUpdate,
}: {
  open: boolean;
  onClose: () => void;
  formik: any;
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  actionType: 'create' | 'update' | 'delete' | undefined;
  handleUpdate: (values: any) => void;
}) {
  const [operationName, setOperationName] = useState<string>('');
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const {
    isLoading: operationsLoading,
    refetch: operationsRefetch,
    data: operationsOptions,
  } = useQuery('institutions', () => findOperationsByName(operationName), {
    enabled: !!operationName,
  });

  const handleCancel = () => {
    if (actionType === 'create') {
      arrayHelpers.pop();
    } else if (actionType === 'update') {
      formik.handleReset();
    }
    onClose();
  };

  const handleSave = async () => {
    const validationResult = await formik.validateForm();

    if (isEmpty(validationResult)) {
      handleUpdate(formik.values.relatedOperations);
      onClose();
    }
  };

  const operationsRefetchDebounce = useCallback(
    debounce(operationsRefetch, 500),
    [],
  );

  const filterOptionOperations = createFilterOptions<any>();

  const getOperationErrors = (
    fieldName: keyof { id: string },
    index: number,
  ) => {
    const operationErrors = formik.errors.relatedOperations as FormikErrors<
      RelatedOperation[]
    >;
    if (!!operationErrors && !!operationErrors[index]) {
      const error = operationErrors[index]?.operation as
        | { id: string }
        | undefined;
      return error ? error[fieldName] : '';
    }
    return '';
  };

  const getErrors = <T extends { [key: string]: any }>(
    fieldName: keyof T,
    index: number,
  ) => {
    const error = formik.errors.relatedOperations?.[index] as T | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{
        maxWidth: 750,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <DialogContent
        sx={(theme) => ({
          padding: '40px 56px 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '20px 24px',
          },
        })}>
        <Typography fontSize={18} fontWeight={700} mb={3} textAlign="center">
          Operações relacionadas
        </Typography>
        <Grid
          container
          spacing={3}
          sx={(theme) => ({
            padding: theme.spacing(2),
            flexDirection: 'column',
          })}>
          <Grid item xs={12}>
            <S.FieldTitle>Nome da operação*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                autoHighlight
                filterOptions={filterOptionOperations}
                loading={operationsLoading}
                options={operationsOptions?.data ?? []}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, newValue, reason) => {
                  if (!newValue?.id || reason === 'clear') {
                    formik.setFieldValue(
                      `relatedOperations[${index}].operation`,
                      {
                        name: '',
                        hashId: '',
                        slug: '',
                      },
                    );
                  } else {
                    formik.setFieldValue(
                      `relatedOperations[${index}].operation`,
                      newValue,
                    );
                  }
                }}
                value={formik.values.relatedOperations[index]?.operation}
                inputValue={operationName}
                onInputChange={(event, newInputValue) => {
                  setOperationName(newInputValue);
                  operationsRefetchDebounce();
                }}
                getOptionLabel={(option: any) => {
                  if (typeof option === 'string') {
                    return option;
                  } else if (option.name !== '' && option.market) {
                    return `${option.name} [${option.market?.name}]`;
                  } else {
                    return '';
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={`relatedOperations[${index}].operation`}
                    placeholder="Selecione o nome da operação em que atuou, ex: BR Properties FII"
                    error={Boolean(getOperationErrors('id', index))}
                    helperText={
                      getOperationErrors('id', index)
                        ? getOperationErrors('id', index)
                        : helperText
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <S.FieldTitle>Função que desempenhou na operação*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              <Grid
                container
                sx={(theme) => ({
                  flexDirection: 'row',
                  alignItems: 'center',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                })}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    width: '100%',
                  }}>
                  <TextField
                    fullWidth
                    name={`relatedOperations[${index}].role`}
                    value={formik.values.relatedOperations[index].role}
                    onChange={formik.handleChange}
                    placeholder="Ex: Estruturador"
                    error={Boolean(getErrors<{ role: string }>('role', index))}
                    helperText={getErrors<{ role: string }>('role', index)}
                  />
                </Grid>
                {formik.values.relatedOperations[index]?.operation?.name && (
                  <Hidden smDown>
                    <Grid item xs={8}>
                      <Typography
                        sx={(theme) => ({
                          marginLeft: '16px',
                          [theme.breakpoints.down('sm')]: {
                            margin: 0,
                          },
                        })}>
                        {'da operação '}
                        <Link
                          href={`/operacao/${formik.values.relatedOperations[
                            index
                          ]?.operation?.market?.name?.toLocaleLowerCase()}/${
                            formik.values.relatedOperations[index]?.operation
                              ?.slug
                          }/${
                            formik.values.relatedOperations[index]?.operation
                              ?.hashId
                          }`}
                          color="#FF8211"
                          underline="always"
                          target="_blank">
                          {
                            formik.values.relatedOperations[index]?.operation
                              ?.name
                          }
                        </Link>
                        {` no mercado de ${
                          formik.values.relatedOperations[
                            index
                          ]?.operation?.market?.name?.toUpperCase() ?? ''
                        }`}
                      </Typography>
                    </Grid>
                  </Hidden>
                )}
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          gap: '28px',
          justifyContent: 'center',
          padding: '16px 84px 37px 72px',
          flexWrap: 'wrap',
          [theme.breakpoints.down('sm')]: {
            padding: '0 52px 40px 40px',
          },
          '& > button': {
            [theme.breakpoints.down('sm')]: {
              minWidth: '100%',
              marginLeft: '0 !important',
            },
          },
        })}>
        <Button fullWidth={isMobile} variant="contained" onClick={handleSave}>
          Salvar
        </Button>
        <Button
          fullWidth={isMobile}
          variant="outlined"
          color="secondary"
          onClick={() => setOpenLeaveDialog(true)}>
          Cancelar
        </Button>
      </DialogActions>
      <ConfirmLeaveDialog
        title={`Tem certeza que deseja fechar a ${
          actionType === 'create' ? 'adição' : 'edição'
        } da operação?`}
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleCancel}
        labelButton={actionType === 'create' ? 'Continuar' : 'Continuar edição'}
      />
    </Dialog>
  );
}
