import { createRef, useEffect, useState } from 'react';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useInfiniteQuery, useQuery } from 'react-query';

import MediaCard from './MediaCard';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { getInformativeTextByLocation } from '../../services/informative';
import { getSeo, getUqbarMedias } from '../../services/media';
import { useIsElementVisible } from '../../utils/hooks';
import { ScrollToTop } from '../../utils/scroll';

type MediaType = {
  id: number;
  title: string;
  publishDate: string;
  link: string;
  image: string;
  vehicle: string;
};

export default function UQBARMedia() {
  const { data: seoMedias } = useQuery('seo-medias', getSeo);
  const { data: tooltipData } = useQuery('info', () =>
    getInformativeTextByLocation('uqbarmedia'),
  );

  const lastRef = createRef<HTMLDivElement>();
  const [data, setData] = useState<MediaType[]>([]);
  const [total, setTotal] = useState(0);

  const isLastVisible = useIsElementVisible(lastRef);

  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['uqbarmedias'],
      ({ pageParam = 1 }) => getUqbarMedias({ page: pageParam, pageSize: 9 }),
      {
        onSuccess: (data) => {
          const page = data.pages.at(-1);
          if (page) {
            setTotal(page.total);
            setData((prev) => [...prev, ...page.data]);
          }
        },
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.data.length !== 0 ? nextPage : undefined;
        },
        refetchOnMount: 'always',
        cacheTime: 0,
      },
    );

  useEffect(() => {
    if (isLastVisible && data?.length < total) {
      fetchNextPage();
    }
  }, [isLastVisible, data]);

  if (isLoading) return <CircularProgress variant={'indeterminate'} />;

  return (
    <ScrollToTop>
      <MetaTags
        title="Uqbar na Mídia | UQBAR"
        description="Tire suas principais dúvidas com o nosso FAQ."
        seo={seoMedias ? seoMedias.data?.seo : undefined}
      />
      <Box component="section">
        <Subheader
          title="Uqbar na Mídia"
          breadcrumbs1="Uqbar na Mídia"
          tooltipText={tooltipData?.data?.uqbarmedia?.informationText}
        />
        <Box py={3}>
          <Grid container spacing={5}>
            {data?.map(({ id, title, publishDate, link, image, vehicle }) => (
              <Grid key={id} item xs={12} md={4}>
                <MediaCard
                  title={title}
                  publishDate={publishDate}
                  link={link}
                  image={image}
                  vehicle={vehicle}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {!isFetchingNextPage && data.length < total && <div ref={lastRef} />}
        {isFetchingNextPage && hasNextPage && (
          <CircularProgress variant={'indeterminate'} size="1.8rem" />
        )}
      </Box>
    </ScrollToTop>
  );
}
