import { useMemo, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Link, Typography } from '@mui/material';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { FeaturedOperation, Institution } from 'src/types/institution';
import { truncateText } from 'src/utils/string';

import FeaturedOperationForm from '../FeaturedOperationForm';

const FeaturedOperationsList = ({
  institution,
  handleUpdate,
  setAction,
  isLoading,
}: {
  institution?: Institution;
  handleUpdate: (values: any) => void;
  setAction: (value: string) => void;
  isLoading: boolean;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<
    FeaturedOperation | undefined
  >();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'operation',
        header: 'Operação destacada',
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Link
              sx={{ fontWeight: 600 }}
              component={RouterLink}
              to={`/operacao/${cell.getValue()?.market?.name?.toLowerCase()}/${
                cell.getValue()?.slug
              }/${cell.getValue()?.hashId}`}>
              {cell.getValue()?.name}
            </Link>
            <Typography fontSize={14}>
              {truncateText(cell.row.original.description, 70)}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 50,
        Cell: ({ cell }: { cell: any }) => (
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            width="100%">
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(cell.row.original, cell.row.index)}>
              Editar
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleOpenDialog(cell.row.original)}>
              Excluir
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const handleEdit = (operation: FeaturedOperation, index: number) => {
    setAction('edit');
    setSelectedOperation(operation);
    setSelectedIndex(index);
    setOpenEdit(true);
  };

  const handleOpenDialog = (operation: FeaturedOperation) => {
    setSelectedOperation(operation);
    setOpenDialog(true);
    setAction('remove');
  };

  const handleRemove = () => {
    if (selectedOperation?.id && institution) {
      let updatedItems = institution?.featuredOperations;
      if (Array.isArray(updatedItems)) {
        updatedItems = [...updatedItems].filter(
          (item: any) => item.id !== selectedOperation?.id,
        );
      }
      handleUpdate(updatedItems);
      setOpenDialog(false);
      setSelectedOperation(undefined);
      setSelectedIndex(undefined);
    }
  };

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: institution?.featuredOperations ?? [],
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
    },
  });

  return (
    <>
      <MaterialTable table={table} />
      <ConfirmDialog
        title={`Tem certeza que deseja remover a operação “${selectedOperation ? selectedOperation.operation.name : ''}”?`}
        subTitle="Ao remover esta operação, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar uma nova operação dentro do perfil institucional."
        submitAction={handleRemove}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasActionButton
      />
      <FeaturedOperationForm
        open={openEdit}
        institution={institution}
        onClose={() => setOpenEdit(false)}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
        selectedOperation={selectedOperation}
        setSelectedOperation={setSelectedOperation}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </>
  );
};

export default FeaturedOperationsList;
