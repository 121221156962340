import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
`;

export const Slide = styled.div`
  min-width: 100dvw;
  background-color: transparent;
`;

export const Content = styled.div`
  position: sticky;
  background-color: transparent;
  left: 0;
  min-width: 100%;
`;
