// Material ui components
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import SubTitle from 'src/components/v2/SubTitle';

import DateChip from '../../../../../components/DateChip';
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRA_ASSET_DATA_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cra/queries';
// Global components

export default function OperationCRAAssetData({ hash }) {
  const { auth } = useAuthContext();
  const [data, setData] = useState({
    competence: null,
    totalCreditoryRights: 0,
    totalDueByDeadline: 0,
    totalUnpaidOverdue: 0,
    totalPeriodPrepayments: 0,
  });
  const { loading, error } = useQuery(GET_CRA_ASSET_DATA_BY_OPERATION_HASH_ID, {
    variables: { hash },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions['operacoes_cra_ativo'],
    onCompleted: ({ getCraAssetDataByOperationHashId }) =>
      setData(getCraAssetDataByOperationHashId),
  });

  if (error) return null;

  return (
    <div>
      <SubTitle title="Dados" />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <DateChip
          title="Competência"
          date={data?.competence}
          loading={loading}
        />
      </Box>
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              first
              info
              numeric
              million
              title="Total de Direitos Creditórios"
              value={data?.totalCreditoryRights}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              numeric
              million
              title="Direitos Creditórios a Vencer"
              value={data?.totalDueByDeadline}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              numeric
              million
              title="Direitos Creditórios Vencidos e Não Pagos"
              value={data?.totalUnpaidOverdue}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              numeric
              million
              title="Direitos Creditórios Pré-pagos"
              value={data?.totalPeriodPrepayments}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
