import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import CNPJFormat from 'src/components/FormsUI/CNPJFormat';
import PhoneFormat from 'src/components/FormsUI/PhoneFormat';
import RoleSelectField from 'src/components/FormsUI/RoleSelectField';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { FieldTitle } from 'src/pages/Register/styles';
import { updateUser } from 'src/services/user';
import { setBannerMessage } from 'src/store/display';
import { User } from 'src/types/user';
import { pickData } from 'src/utils/object';

import { ValidateLegalPerson } from '../../validations';

interface ResponsibilityOptions {
  inputValue?: string;
  name: string;
}

export const allowedFields = [
  'id',
  'company',
  'companyRole',
  'contactDetails',
  'corporateEmail',
  'corporatePhone',
  'fullName',
  'institutionCnpj',
  'name',
  'otherName',
  'phones',
  'type',
  'onboarding',
];

function LegalPersonForm({
  user,
  responsibilityOptions,
}: {
  user: User;
  responsibilityOptions: ResponsibilityOptions[];
}) {
  const dispatch = useDispatch();
  const { reloadUser } = useAuthContext();
  const updateUserMutation = useMutation(updateUser, {
    onError: (error: any) => {
      if (error) {
        dispatch(
          setBannerMessage({
            message: 'Ocorreu um erro durante a atualização, tente novamente',
            type: 'error',
            autoclose: false,
          }),
        );
      }
    },
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Dados essenciais atualizados com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      reloadUser();
    },
  });

  const formik = useFormik({
    initialValues: {
      document: user.document ?? '',
      otherName: user.otherName ?? '',
      name: user.name ?? '',
      companyRole: user.companyRole ?? '',
      email: user.email ?? '',
      contactDetails: {
        ...user.contactDetails,
        emailAdmin: user.contactDetails?.emailAdmin ?? '',
      },
      phones: [
        { ...user.phones?.at(0), number: user.phones?.at(0)?.number ?? '' },
      ],
    },
    validationSchema: ValidateLegalPerson,
    onSubmit: (values) => {
      const newValues = { ...user, ...values };
      const pickedData = pickData(newValues, allowedFields) as User;
      updateUserMutation.mutateAsync({ data: pickedData });
    },
  });

  const getPhoneError = (fieldName: keyof { number: string }) => {
    const error = formik.errors.phones?.[0] as { number: string } | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} sx={{ padding: '48px 20px' }}>
        <Grid item xs={12} md={6}>
          <FieldTitle>CNPJ*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="document"
              disabled
              value={formik.values.document}
              InputProps={{
                inputComponent: CNPJFormat,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldTitle>Nome fantasia*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="otherName"
              placeholder="Insira o nome fantasia"
              value={formik.values.otherName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.otherName && Boolean(formik.errors.otherName)
              }
              helperText={formik.touched.otherName && formik.errors.otherName}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldTitle>Razão social*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="name"
              placeholder="Insira a razão social"
              disabled
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <RoleSelectField
            id="LegalPerson-select-companyRole"
            options={responsibilityOptions}
            label="Cargo do administrador"
            name="companyRole"
            placeholder="Selecione ou digite o cargo"
            value={formik.values.companyRole}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldTitle>E-mail da conta*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField name="email" disabled value={formik.values.email} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldTitle>Telefone</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="phones[0].number"
              placeholder="Insira seu Telefone"
              value={formik.values.phones?.at(0)?.number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phones?.at(0)?.number &&
                Boolean(getPhoneError('number'))
              }
              helperText={
                formik.touched.phones?.at(0)?.number && getPhoneError('number')
              }
              InputProps={{
                inputComponent: PhoneFormat as any,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldTitle>E-mail do administrador*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="contactDetails.emailAdmin"
              placeholder="Insira o e-mail do administrador"
              value={formik.values.contactDetails.emailAdmin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactDetails?.emailAdmin &&
                Boolean(formik.errors.contactDetails?.emailAdmin)
              }
              helperText={
                formik.touched.contactDetails?.emailAdmin &&
                formik.errors.contactDetails?.emailAdmin
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            sx={{ height: 40, fontWeight: 700 }}>
            {updateUserMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Editar dados'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default LegalPersonForm;
