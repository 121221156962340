import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// material ui components
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { acceptInvitation, rejectInvitation } from 'src/services/invitation';
import { setBannerMessage } from 'src/store/display';
import { Invitation } from 'src/types/user';

import AcceptInviteDialog from './AcceptInviteDialog';

type AcceptInviteProps = {
  invitation: Invitation;
};

export function AcceptInvite({ invitation }: AcceptInviteProps) {
  const { auth } = useAuthContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [success, setSuccess] = useState<boolean>(false);
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'lg'),
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const acceptInvitationMutation = useMutation(acceptInvitation, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Ocorreu um erro. Tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: () => {
      setSuccess(true);
    },
  });

  const { isLoading: loadingAcceptInvitation } = acceptInvitationMutation;

  const rejectInvitationMutation = useMutation(rejectInvitation, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Ocorreu um erro. Tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: () => {
      history.push('/');
      dispatch(
        setBannerMessage({
          message: 'Convite recusado',
          type: 'success',
          autoclose: true,
        }),
      );
    },
  });

  const { isLoading: loadingRejectInvitation } = rejectInvitationMutation;

  useEffect(() => {
    if (invitation.status !== 'Pendente') {
      history.push('/');
      dispatch(
        setBannerMessage({
          message: 'Convite expirado',
          type: 'error',
          autoclose: true,
        }),
      );
    }
  }, []);

  const handleInvitedChoose = (accept: boolean, token: string) => {
    if (accept) {
      acceptInvitationMutation.mutate(token);
    } else {
      rejectInvitationMutation.mutate(token);
    }
  };

  const handleClick = () => {
    if (!auth.isLoggedIn) {
      history.push('/login');
    }
    setSuccess(false);
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#FFFFFF',
        padding: '200px 200px',
        [theme.breakpoints.between('sm', 'lg')]: {
          padding: '150px 100px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '150px 50px',
        },
      })}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          sx={(theme) => ({
            fontSize: '16px',
            textAlign: 'right',
            [theme.breakpoints.down('lg')]: {
              textAlign: 'center',
            },
          })}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '40px',
              marginBottom: '32px',
            }}>
            Seja-bem vindo a Uqbar
          </Typography>
          <Box>
            <Typography sx={{ marginBottom: '32px' }}>
              Você foi convidado a participar de uma assinatura.
            </Typography>
            <Typography sx={{ marginBottom: '32px' }}>
              {
                'Ao aceitar este convite, você sairá de seu plano atual e será membro convidado do plano de '
              }
              <b>{invitation.subscription.owner}.</b>
            </Typography>
            <Typography>
              <b>
                Neste novo plano, você terá benefícios incríveis e conteúdos
                exclusivos:
              </b>
            </Typography>
          </Box>
        </Grid>
        <Divider
          orientation={isSmall || isMobile ? 'horizontal' : 'vertical'}
          variant="middle"
          sx={(theme) => ({
            color: 'black',
            height: '500px',
            margin: '0 20px 0 40px',
            [theme.breakpoints.down('lg')]: {
              color: 'black',
              width: '100%',
              height: '1px',
              margin: '40px 0',
            },
          })}
        />
        <Grid
          item
          sm={12}
          md={12}
          lg={5}
          sx={(theme) => ({
            fontSize: '14px',
            lineHeight: '32px',
            paddingTop: '150px',
            [theme.breakpoints.down('lg')]: {
              paddingTop: 0,
            },
          })}>
          <ul>
            <li>
              Acesso a mais de <b>3 mil análises, artigos e notícias</b>
            </li>
            <li>
              Acesso ao <b>detalhamento</b> de milhares de{' '}
              <b>FIDCs, CRIs, CRAs e FIIs</b>
            </li>
            <li>
              Acesso irrestrito aos <b>Rankings Uqbar</b>
            </li>
            <li>
              Acesso ao <b>diretório de empresas e profissionais</b>
            </li>
            <li>
              Inclusão de <b>perfil empresarial e profissional</b>
            </li>
            <li>
              20% de desconto em <b>treinamentos Uqbar</b>
            </li>
            <li>
              20% de desconto em <b>dados personalizados</b>
            </li>
          </ul>
          <Grid
            item
            sm={12}
            md={12}
            sx={(theme) => ({
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              margin: theme.spacing(4, 4),
              [theme.breakpoints.down('lg')]: {
                justifyContent: 'center',
              },
            })}>
            <Grid
              item
              md={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}>
              <Button
                color="primary"
                sx={
                  loadingAcceptInvitation || loadingRejectInvitation
                    ? {
                        pointerEvents: 'none',
                        marginTop: theme.spacing(2),
                      }
                    : {
                        padding: theme.spacing(2, 2),
                        marginTop: theme.spacing(2),
                        lineHeight: '1',
                      }
                }
                variant="contained"
                onClick={() => handleInvitedChoose(true, invitation.token)}>
                {loadingAcceptInvitation || loadingRejectInvitation ? (
                  <CircularProgress color="secondary" size="1.8rem" />
                ) : (
                  'Aceitar convite'
                )}
              </Button>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                margin: theme.spacing(0, 3),
                display: 'flex',
                flexDirection: 'row',
              }}>
              <Button
                color="secondary"
                sx={
                  loadingAcceptInvitation || loadingRejectInvitation
                    ? {
                        marginTop: theme.spacing(2),
                        pointerEvents: 'none',
                      }
                    : {
                        marginTop: theme.spacing(2),
                      }
                }
                variant="outlined"
                onClick={() => handleInvitedChoose(false, invitation.token)}>
                {loadingAcceptInvitation || loadingRejectInvitation ? (
                  <CircularProgress size="1.8rem" />
                ) : (
                  'Recusar'
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AcceptInviteDialog
        open={success}
        handleClick={handleClick}
        title="Parabéns! Seu plano foi alterado com sucesso"
        subTitle="É muito bom ter você com a gente."
        labelButton={auth.isLoggedIn ? 'Fechar' : 'Ir para o login'}
      />
    </Box>
  );
}
