/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect } from 'react';

import { Container } from './styles';
import { DataContext } from '../../../../context/DataContext';
import ArticleIcon from '../../icons/Article';
import { handleFormatDate } from '../../utils/handleDate';
import { handleParseDecimal } from '../../utils/handleParseDecimal';
import ActionButtons from '../ActionButtons';

const Security = ({ formik }) => {
  const { security, calculator } = formik;
  const { setPickedSecurity, securityTypes, pickedSecurity, simulateResult } =
    useContext(DataContext);

  const getFirstAmortizationDate = () => {
    const matches = simulateResult?.principal.filter(function (elm) {
      return +elm.value > 0;
    });
    const date = matches && matches[0].date;
    return date && handleFormatDate(date);
  };

  const getLastAmortizationDate = () => {
    const date = simulateResult?.total[simulateResult.total?.length - 1].date;
    return date && handleFormatDate(date);
  };

  useEffect(() => {
    if (pickedSecurity) {
      setPickedSecurity(pickedSecurity);
      security.setValues(pickedSecurity);
      security.setFieldValue(
        'payment.index_factor',
        handleParseDecimal(pickedSecurity.payment.index_factor, false),
      );
      security.setFieldValue(
        'payment.index_spread',
        handleParseDecimal(pickedSecurity.payment.index_spread, false),
      );
      calculator.setFieldValue(
        'price.rate.index_def_id',
        pickedSecurity.payment.index_def_id,
      );
      calculator.setFieldValue(
        'price.rate.index_factor',
        handleParseDecimal(pickedSecurity.payment.index_factor, false),
      );
      calculator.setFieldValue(
        'price.rate.index_spread',
        handleParseDecimal(pickedSecurity.payment.index_spread, false),
      );
    }
  }, [pickedSecurity]);

  return (
    <Container>
      <div className="box-security">
        <ArticleIcon />
        Resumo do título
      </div>
      <ActionButtons formik={{ security, calculator }} />
      <div className="security-content">
        <div className="info">
          <div className="sidemarker"></div>
          <div className="security-info">
            <div>
              Título de Crédito:{' '}
              <strong>
                {security.values.type
                  ? securityTypes?.map(
                      (index) =>
                        index.key === security.values.type && index.text,
                    )
                  : 'Carregando...'}
              </strong>
            </div>
            <div>
              Saldo:{' '}
              <strong>
                {security.values.payment.balance
                  ? parseFloat(security.values.payment.balance).toFixed(2)
                  : 'Carregando...'}
              </strong>
            </div>
            <div>
              Data de Emissão:{' '}
              <strong>
                {security.values.start_date
                  ? handleFormatDate(security.values.start_date)
                  : 'Carregando...'}
              </strong>
            </div>
            <div>
              Amortização (pagamentos):{' '}
              <strong>{security.values.payment.principal_payments || 1}</strong>
            </div>
            <div>
              Primeira Amortização:{' '}
              <strong>{getFirstAmortizationDate() || 'Não definida'}</strong>
            </div>
            <div>
              Última Amortização:{' '}
              <strong>{getLastAmortizationDate() || 'Não definida'}</strong>
            </div>
            <div>
              {security.values.type === 'fixed'
                ? 'Juros'
                : 'Rendimento esperado'}
              : <strong>{getLastAmortizationDate() || 'Não definida'}</strong>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Security;
