import { Dispatch, SetStateAction, useState } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePickerProps } from '@mui/lab';
import { Box, Button } from '@mui/material';
import {
  BaseSingleInputFieldProps,
  DateCalendar,
  DateValidationError,
  DesktopDatePicker,
  FieldSection,
  LocalizationProvider,
  UseDateFieldProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { Dayjs } from 'dayjs';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setState: Dispatch<SetStateAction<string | null>>;
  handleChange: (e: any, key: 'date') => void;
  label?: string;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    setState,
    handleChange,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  const formatedLabel = label?.replace('/', '/01/') ?? undefined;
  const dateValue = formatedLabel && new Date(formatedLabel);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          order: 3,
        },
      })}>
      <Button
        variant="outlined"
        id={id}
        disabled={disabled}
        ref={ref}
        aria-label={ariaLabel}
        startIcon={
          <CalendarTodayIcon
            color="primary"
            sx={{
              width: '1em',
              height: '1em',
              fontSize: '1.5rem',
              display: 'inline-block',
              mr: 0,
              ml: 0,
            }}
          />
        }
        sx={(theme) => ({
          color: label ? `${theme.palette.primary.main} !important` : '#828282',
          backgroundColor: 'transparent !important',
          fontSize: '14px',
          fontWeight: 600,
          textTransform: 'none',
          letterSpacing: 0,
          minWidth: 'unset',
          border: 'none',
          gap: '5px',
          paddingInline: '0',
          '&:hover': {
            border: 'none !important',
          },
        })}
        onClick={() => {
          if (label) return setState('date');
          setOpen?.((prev) => !prev);
        }}>
        {label ?? 'Data'}
      </Button>
      <DateCalendar
        views={['month', 'year']}
        openTo="month"
        value={dateValue}
        onChange={(e) => handleChange(e, 'date')}
        slotProps={{ calendarHeader: { sx: { display: 'none' } } }}
        sx={(theme) => ({
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
          },
        })}
      />
    </Box>
  );
}

export default function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
) {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DesktopDatePicker
        className="datePicker"
        orientation="landscape"
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{
          field: {
            setOpen,
            setState: props.setState,
            handleChange: props.onAccept,
          } as any,
        }}
        {...props}
        open={!props.isMobile && open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        minDate={new Date(2023, 0, 1)}
      />
    </LocalizationProvider>
  );
}
