// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Global components
import Competence from '../../../../../components/Competence';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

export default function OperationFIDCAssetData({ hash }) {
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_ativo_dados'
      ],
    },
  );

  if (error) return null;

  return (
    <div>
      <Subtitle
        title="Dados"
        isIncongruitiesAlert={
          data?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
            ?.isIncongruitiesAlert
        }
      />
      <Competence value={hash} searchType="HASH_ID" />
      <Box py={2}>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_ativo_dados'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_ativo_dados'
            ]
          }>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg>
              <PaperPanelInfo
                first
                info
                title="Ativo-lastro"
                value={
                  data
                    ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                    ?.underlyingAssets
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg>
              <PaperPanelInfo
                numeric
                title="Ativo"
                value={
                  data
                    ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                    ?.asset
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg>
              <PaperPanelInfo
                numeric
                title="Direitos Creditórios"
                value={
                  data
                    ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                    ?.amountCreditsDue
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg>
              <PaperPanelInfo
                info
                title="Risco de Crédito"
                value={
                  data
                    ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                    ?.creditRiskCategory
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <PaperPanelInfo
                info
                title="Tipos de Direitos Creditórios"
                value={data?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId?.typeCreditsDue?.join(
                  ', ',
                )}
                loading={loading}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </div>
  );
}
