import { Box } from '@mui/material';
import styled from 'styled-components';

export const SectionPlans = styled(Box)`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='1306' height='1788' viewBox='0 0 1306 1788' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M42.6023 16.5979C20.4493 57.1618 3.42851 102.06 0.44013 148.989C-1.96357 187.048 5.50738 224.903 22.1384 258.898C57.934 332.306 127.121 382.96 194.36 422.101C273.422 468.083 358.526 501.807 443.63 533.432C538.868 568.849 636.965 598.849 724.862 652.076C799.312 697.177 863.887 758.599 903.58 839.117C938.596 910.223 953.084 989.454 964.193 1067.87C976.731 1156.18 986.41 1245.03 1008.43 1331.57C1035.65 1438.3 1081.19 1541.84 1147.13 1628.79C1198.91 1697.05 1264.26 1750.35 1338 1787.87V-9H57.6091C52.412 -0.6028 47.3447 7.92984 42.6023 16.5979Z' fill='%23F1F1F1'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: top right;
  padding-bottom: 100px;
`;
