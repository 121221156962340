import { useMediaQuery } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './alice-carousel.css';

export function CarouselCustom({ items }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const responsiveOptions = {
    0: {
      items: 1,
    },
    800: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <ArrowForwardIosIcon
        color={isDisabled ? 'disabled' : 'primary'}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: 0,
          top: '50%',
        }}
      />
    );
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <ArrowBackIosIcon
        color={isDisabled ? 'disabled' : 'primary'}
        style={{ cursor: 'pointer', position: 'absolute', left: 0, top: '50%' }}
      />
    );
  };

  return (
    <AliceCarousel
      disableDotsControls
      disableButtonsControls={isSmall}
      items={items}
      responsive={responsiveOptions}
      renderPrevButton={renderPrevButton}
      renderNextButton={renderNextButton}
      mouseTracking
    />
  );
}
