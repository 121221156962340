import { useEffect, useState } from 'react';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useMutation, useQuery } from 'react-query';

import HeaderArticles from './HeaderArticles';
import SingleArticle from './SingleArticle';
import { AllArticlesStyles } from './styles';
import MetaTags from '../../components/Seo/MetaTags';
import SkeletonGrid from '../../components/SkeletonGrid';
import Subheader from '../../components/Subheader';
import { filterArticles } from '../../services/elastic';
import { getInformativeTextByLocation } from '../../services/informative';

export default function AllArticles() {
  const institutionName = new URL(window.location).searchParams.get(
    'instituicao',
  );
  const classes = AllArticlesStyles();
  const limit = 20;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(0);
  const [sort, setSort] = useState('desc');
  const [filters, setFilters] = useState({
    keyword: '',
    categories: [],
    markets: [],
    relatedInstitutions: institutionName ? [institutionName] : [],
    relatedProfessionals: [],
    relatedOperations: [],
  });
  const [articles, setArticles] = useState([
    {
      hashId: null,
      name: null,
      markets: null,
      publishedAt: '01/01/1900',
      slug: null,
      headerImage: null,
    },
  ]);
  const [scroll, setScroll] = useState(false);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const onChangePagination = (event, value) => {
    setPage(value);
    setScroll((prevState) => !prevState);
  };

  const { data } = useQuery('info', () =>
    getInformativeTextByLocation('article'),
  );

  const filteredArticles = useMutation(filterArticles, {
    onSuccess: ({ data }) => {
      setArticles(data.data);
      setTotal(data.total);
    },
  });

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    setFrom((page - 1) * limit);
  }, [page]);

  useEffect(() => {
    filteredArticles.mutate({ data: { filters, from, sort } });
  }, [filters, from, sort]);

  useEffect(() => {
    window.scrollTo({
      top: isSmall ? 850 : 0,
      behavior: 'smooth',
    });
  }, [scroll]);

  const { isLoading: loading, isError } = filteredArticles;

  return (
    <Box component="section">
      <MetaTags
        title="Artigos / Análise"
        description="Acesse diariamente inteligência de mercado sobre FII, FIDC, CRI e CRA, além de encontrar acervo com mais de 3 mil artigos sobre estes mercados."
      />
      <Subheader
        title="Artigos"
        breadcrumbs1="Artigos"
        tooltipText={data?.data?.article?.informationText}
      />
      <Box>
        <HeaderArticles
          classes={classes}
          filters={filters}
          sort={sort}
          setSort={setSort}
          setFilters={setFilters}
        />
        <Grid container spacing={1}>
          {loading ? (
            <SkeletonGrid
              height="250px"
              width="auto"
              skeletonNumber={limit}
              sm={12}
              md={12}
              lg={12}
            />
          ) : isError ? null : (
            articles?.map(
              ({ id, name, markets, publishedAt, slug, headerImage }) => (
                <Grid item md={12} className={classes.articleGrid} key={id}>
                  <SingleArticle
                    id={id}
                    name={name}
                    markets={markets}
                    publishedAt={publishedAt}
                    slug={slug}
                    headerImage={headerImage}
                  />
                </Grid>
              ),
            )
          )}
        </Grid>
        <Box pt={3} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(total / limit)}
            page={page}
            onChange={onChangePagination}
            shape="rounded"
            siblingCount={isSmall ? 0 : 1}
            size="large"
          />
        </Box>
      </Box>
    </Box>
  );
}
