// Material ui components
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

// Utils
import defaultArticleImgHome from '../../../assets/imgs/articles/home.png';
import SubtitleLink from '../../../components/SubtitleLink';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { getArticlesToHome } from '../../../services/article';
import { getLargestImageUrl } from '../../../utils/image';
import { truncateText } from '../../../utils/string';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset !important',
      maxHeight: 'none !important',
    },
  },
  media: {
    height: 95,
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.between('xs', 'md')]: {
      height: 115,
    },
  },
  listItem: {
    padding: theme.spacing(1, 0),
    '&:last-child': {
      padding: theme.spacing(2, 0, 0),
    },
  },
  articleTitle: {
    fontWeight: 500,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
}));

export default function Article() {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery('articlesHome', () =>
    getArticlesToHome(auth.isLoggedIn ? 4 : 3),
  );

  if (error) return null;

  return (
    <Paper
      elevation={0}
      className={classes.paper}
      style={{
        minHeight: auth.isLoggedIn ? '580px' : '480px',
        maxHeight: auth.isLoggedIn ? '580px' : '480px',
      }}>
      <SubtitleLink
        title="Artigos recentes"
        url="/artigos"
        labelLink="Ver todos os artigos"
      />
      <Box pt={1} mt={2.3}>
        {loading && (
          <Skeleton variant="rect" animation="wave" width="100%" height={470} />
        )}
        <List component="nav" aria-label="lista de artigos" disablePadding>
          {data?.map(({ title, slug, id, headerImage }, index) => (
            <Box key={id}>
              <ListItem
                button
                className={classes.listItem}
                component={Link}
                to={`/artigo/${slug}/${id}`}
                title={title}>
                <Grid container>
                  <Grid item xs={12} sm={4} md={3} lg={4}>
                    <img
                      className={classes.media}
                      src={
                        getLargestImageUrl(headerImage) ?? defaultArticleImgHome
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={9}
                    lg={8}
                    container
                    alignItems="center">
                    <Typography
                      color="primary"
                      className={classes.articleTitle}>
                      {truncateText(title, 125)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {index < data.length - 1 && <Divider />}
            </Box>
          ))}
        </List>
      </Box>
    </Paper>
  );
}
