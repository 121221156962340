import { useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { updateInstitution } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';

import FeaturedOperationForm from './FeaturedOperationForm';
import FeaturedOperationsList from './FeaturedOperationsList';

function FeaturedOperations({
  institution,
  institutionRefetch,
}: {
  institution?: Institution;
  institutionRefetch: () => void;
}) {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const [action, setAction] = useState<string | undefined>();

  const updateInstitutionMutation = useMutation(updateInstitution, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: `Não foi possível ${
              action === 'edit'
                ? 'editar'
                : action === 'remove'
                  ? 'remover'
                  : 'adicionar'
            } a operação`,
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: `Operação ${
              action === 'edit'
                ? 'atualizada'
                : action === 'remove'
                  ? 'removida'
                  : 'adicionada'
            } com sucesso`,
          }),
        );
      }
      setOpenForm(false);
      setAction(undefined);
      institutionRefetch();
    },
  });

  const { isLoading } = updateInstitutionMutation;

  const handleUpdate = (values: any) => {
    if (institution?.id) {
      updateInstitutionMutation.mutateAsync({
        id: institution.id,
        values: {
          data: {
            featuredOperations: values,
          },
        },
      });
    }
  };

  return (
    <Box sx={{ padding: '32px 24px 24px' }}>
      <Typography fontSize={14}>
        Destaque operações para serem exibidas com prioridade no perfil
        institucional, insira o nome da operação e faça uma breve descrição de
        até 120 caracteres.
      </Typography>
      <Button
        startIcon={<AddCircleIcon color="primary" fontSize="large" />}
        color="secondary"
        onClick={() => {
          setAction('add');
          setOpenForm(true);
        }}
        sx={{
          textTransform: 'none',
          letterSpacing: 'unset',
          textDecoration: 'underline !important',
          fontSize: '16px',
          fontWeight: 700,
          padding: '16px 8px',
          margin: '16px 0',
          color: '#FF8211',
        }}>
        Adicionar operação
      </Button>
      <If condition={institution?.featuredOperations?.length}>
        <FeaturedOperationsList
          institution={institution}
          handleUpdate={handleUpdate}
          isLoading={isLoading}
          setAction={setAction}
        />
      </If>
      <FeaturedOperationForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
        institution={institution}
      />
    </Box>
  );
}

export default FeaturedOperations;
