import { ReactNode, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import Avatar from 'react-avatar';

import { ConfirmDialog } from '../ConfimDialog';
import { CustomTooltip } from '../CustomTooltip';
import { If } from '../If';

const getColorStatus = (status: string) => {
  switch (status) {
    case 'Ativo':
      return 'success';
    case 'Pendente':
      return 'primary';
    case 'Inativo':
      return 'info';
    default:
      return 'info';
  }
};

const getActions = (status: string, type: string, actions: Action[]) => {
  switch (status) {
    case 'Ativo':
      if (type === 'owner') {
        return actions.filter((a) => a.type === 'owner');
      } else {
        return actions.filter((a) => a.type === 'inactiveUser');
      }
    case 'Pendente':
      return actions.filter(
        (a) => a.type !== 'inactiveUser' && a.type !== 'owner',
      );
    case 'Inativo':
      return actions.filter((a) => a.type === 'resendInvite');
    default:
      return actions;
  }
};

type Label = {
  field: string;
  headerName: string;
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
};

type Action = {
  label: string;
  trigger?: (id: number) => void;
  loading?: boolean;
  icon: ReactNode;
  type: string;
  tooltip?: string;
};

type Data = {
  name: string;
  status: string;
  email: string;
  id: number;
  type: string;
  updatedAt?: string | undefined;
};

type DataActionState = {
  trigger: () => void;
  title?: string;
  subTitle?: string;
  loading: boolean;
};

export default function UsersTable({
  labels,
  datas,
  actions,
}: {
  labels: Label[];
  datas: Data[];
  actions: Action[];
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dataAction, setDataAction] = useState<DataActionState>({
    trigger: () => {},
    title: '',
    subTitle: '',
    loading: false,
  });

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (action: Action, data: Data) => {
    setOpenDialog((prevState) => !prevState);
    let title;
    let subTitle;
    switch (action.type) {
      case 'resendInvite':
        title = `Tem certeza que deseja reenviar o convite de “${data.name}”?`;
        subTitle =
          'Ao reenviar, o usuário receberá um novo e-mail com o convite.';
        break;
      case 'inactiveInvite':
        title = `Tem certeza que deseja inativar o convite de “${data.name}”?`;
        subTitle =
          'Ao inativar, você invalidará o convite enviado anteriormente ao usuário.';
        break;
      case 'inactiveUser':
        title = `Tem certeza que deseja inativar o acesso de “${data.name}”?`;
        subTitle =
          'Ao inativar o acesso, o usuário voltará ao plano gratuito e perderá os benefícios proporcionados pelo plano atual.';
        break;
    }

    setDataAction({
      trigger: () => action.trigger && action.trigger(data.id),
      title,
      subTitle,
      loading: action.loading ? action.loading : false,
    });
  };

  const handleConfimation = async () => {
    dataAction.trigger();

    if (!dataAction.loading) {
      handleCloseDialog();
    }
  };

  const handleDisabledActions = (type: string, data: Data) => {
    switch (type) {
      case 'resendInvite':
        return data?.updatedAt
          ? isBefore(Date.now(), addMinutes(parseISO(data?.updatedAt), 0))
          : false;
      case 'inactiveInvite':
        return false;
      case 'inactiveUser':
        return false;
      case 'owner':
        return true;
      default:
        return false;
    }
  };

  return (
    <TableContainer sx={{ marginTop: '75px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {labels?.map((label: Label, index: number) => (
              <TableCell
                sx={{ fontWeight: 700 }}
                align={label.align}
                key={`${label.headerName}-${index}`}>
                {label.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {datas?.map((data: Data, dataIndex: number) => (
            <TableRow key={`table-data-${dataIndex}`}>
              <TableCell width={30}>
                <Avatar name={data.name} maxInitials={2} round size="32" />
              </TableCell>
              <TableCell sx={{ maxWidth: 400 }}>
                <Typography fontSize={14} fontWeight={600}>
                  {data.name}
                </Typography>
                <Typography fontSize={14}>{data.email}</Typography>
              </TableCell>
              <TableCell align="center">
                <Chip
                  sx={(theme) => ({
                    minWidth: 80,
                    width: 80,
                    height: 22,
                    fontSize: '12px',
                    color: theme.palette.primary.contrastText,
                    borderRadius: '2px',
                  })}
                  color={getColorStatus(data.status)}
                  label={data.status}
                />
              </TableCell>
              <If condition={actions}>
                <TableCell align="right">
                  {getActions(data.status, data.type, actions)?.map(
                    (action: Action, actionIndex: number) => (
                      <Box
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        key={`${action.label}-${dataIndex}-${actionIndex}`}>
                        <CustomTooltip
                          title={
                            handleDisabledActions(action.type, data) &&
                            action.tooltip
                              ? action.tooltip
                              : ''
                          }
                          placement="top"
                          arrow>
                          <span>
                            <Button
                              sx={{
                                color: '#4F4F4F',
                                fontSize: '14px',
                                fontWeight: 400,
                                textTransform: 'none',
                                letterSpacing: 0,
                                justifyContent: 'flex-start',
                                '&:hover': {
                                  background: 'transparent',
                                },
                              }}
                              variant="text"
                              onClick={() => handleOpenDialog(action, data)}
                              disabled={handleDisabledActions(
                                action.type,
                                data,
                              )}
                              startIcon={action.icon}>
                              {action.label}
                            </Button>
                          </span>
                        </CustomTooltip>
                      </Box>
                    ),
                  )}
                </TableCell>
              </If>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={dataAction.title}
        subTitle={dataAction.subTitle}
        submitAction={handleConfimation}
        hasActionButton={Boolean(handleConfimation)}
      />
    </TableContainer>
  );
}
