import { Box, makeStyles, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getOurClients } from '../../../services/ourClients';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  description: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#797979',
    textAlign: 'center',
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'unset',
    },
  },
  itemName: {
    fontSize: '1rem',
    fontWeight: 600,
    margin: theme.spacing(3, 0, 4),
  },
  logo: {
    height: 65,
    maxWidth: 180,
  },
  box: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 10px',
    },
  },
}));

export default function OurClients() {
  const classes = useStyles();
  const { data, isLoading } = useQuery('our-clients', () =>
    getOurClients({ page: 1, pageSize: 3 }),
  );

  if (isLoading && !data?.data.length) return null;

  return (
    <>
      {data?.data.length > 0 && (
        <Box className={classes.box}>
          <Box>
            <Typography variant={'h3'} className={classes.title}>
              Organizações que confiam na Uqbar
            </Typography>
          </Box>
          <Box className={classes.items}>
            {data?.data.map(({ id, logo, name, order }) => (
              <Box order={order} key={id} p={4}>
                <img
                  className={classes.logo}
                  src={logo.formats?.thumbnail?.url || logo.url}
                  alt={name}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}
