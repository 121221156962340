import { useState } from 'react';

import {
  Box,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from 'react-query';

import { Carrousel } from '../../../components/Carrousel';
import { getBenefits } from '../../../services/benefits';
import { Theme } from '../../../types/theme';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  description: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#797979',
    textAlign: 'center',
  },
  item: {
    maxWidth: 410.66,
    textAlign: 'center',
    flexBasis: '33%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: 'auto',
    },
  },
  itemName: {
    fontSize: '1rem',
    fontWeight: 600,
    margin: theme.spacing(3, 0, 4),
  },
  buttonMore: {
    backgroundColor: '#FF8211',
    fontSize: '1.15rem',
    fontWeight: 600,
    padding: theme.spacing(1),
    maxWidth: 300,
    marginTop: theme.spacing(6),
  },
}));

type Props = {
  buttonText: string;
  url: string;
};

export default function Benefits(props: Props) {
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('xs'),
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));
  const pageSize = isExtraSmall ? 1 : isSmall ? 2 : 3;
  const [page, setPage] = useState(0);
  const { data, isLoading } = useQuery(['benefits', page], () =>
    getBenefits({ page: page + 1, pageSize }),
  );

  const handleClick = () => {
    window.open(props.url);
  };
  return (
    <Box my={12.5}>
      <Typography variant={'h3'} className={classes.title}>
        Benefícios
      </Typography>
      <Box p={1.5} mb={5}>
        <Typography className={classes.description}>
          A Uqbar conecta todas as partes que compõem o mercado de Finanças
          Estruturadas do país: operações financeiras, instituições
          participantes e profissionais atuantes. Tenha acesso a dados, artigos,
          contatos, rankings e mais em poucos cliques:
        </Typography>
      </Box>
      <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
        {isLoading ? (
          Array(3)
            .fill(null)
            .map((v, index) => (
              <Box key={index} p={4}>
                <Skeleton
                  width={200}
                  height={65}
                  variant={'rect'}
                  animation={'wave'}
                />
              </Box>
            ))
        ) : (
          <Carrousel
            totalItems={data?.meta?.pagination?.total}
            page={page}
            setPage={setPage}>
            {data?.data?.map(({ id, image, name, description }) => (
              <Box key={id} p={4} className={classes.item}>
                <img
                  src={image.formats?.thumbnail?.url || image.url}
                  alt={name}
                  height={90}
                />
                <Typography variant="h4" className={classes.itemName}>
                  {name}
                </Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Box>
            ))}
          </Carrousel>
        )}
      </Box>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          disableElevation
          className={classes.buttonMore}
          onClick={handleClick}>
          {props.buttonText}
        </Button>
      </Box>
    </Box>
  );
}
