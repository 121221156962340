/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { PlayArrow } from '@material-ui/icons';

import { DataContext } from '../../../../context/DataContext';

const SimulateSecurityAction = ({ security }) => {
  const { loading, simulateByParam } = useContext(DataContext);

  const handleSimulateSecurityByParam = () => {
    simulateByParam(security.values);
  };

  return (
    <Button
      onClick={handleSimulateSecurityByParam}
      startIcon={<PlayArrow style={{ color: 'black' }} />}
      className="btn-simulate"
      style={{ padding: '6px 10px', minWidth: 130 }}>
      <span style={{ color: 'black' }}>
        {!loading ? 'Calcular' : <CircularProgress size="1.2rem" />}
      </span>
    </Button>
  );
};

export default SimulateSecurityAction;
