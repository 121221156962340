// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationFIDCResumeBusinessDC from './OperationFIDCResumeBusinessDC';
import OperationFIDCResumeData from './OperationFIDCResumeData';
import OperationFIDCResumeGeneralData from './OperationFIDCResumeGeneralData';
import OperationFIDCResumeQuotes from './OperationFIDCResumeQuotes';
import OperationFIDCResumeServiceProvider from './OperationFIDCResumeServiceProvider';
import OperationFIDCResumeWallet from './OperationFIDCResumeWallet';
export default function OperationFIDCResume({ hash }) {
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <OperationFIDCResumeGeneralData hash={hash} />
        </Grid>
        <Grid item xs={12} md>
          <OperationFIDCResumeServiceProvider hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCResumeData hash={hash} />
        </Grid>
        <Grid item xs={12} md={6}>
          <OperationFIDCResumeWallet hash={hash} />
        </Grid>
        <Grid item xs={12} md>
          <OperationFIDCResumeBusinessDC hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCResumeQuotes hash={hash} />
        </Grid>
      </Grid>
    </Box>
  );
}
