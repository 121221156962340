import styled from 'styled-components';

type WrapperProps = {
  small: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  width: ${(props) => (props.small ? '40%' : '100%')};
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-right: auto;
`;

export const Title = styled.div`
  color: #ff8811;
  letter-spacing: 2.8px;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Authors = styled.div`
  display: flex;
  padding: 20px 5px;
  column-gap: 10px;

  & > span {
    font-weight: bold;
    font-size: 16px;
    & > span {
      text-decoration: none;
      & > a {
        text-decoration: none;
        color: #000000;
      }
    }

    &:nth-child(n + 2) {
      padding-left: 5px;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
`;
export const Subtitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 20px;
`;
