import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItem,
  ListItemText,
  Select as MaterialSelect,
  MenuItem,
} from '@material-ui/core';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export function ArticlesSelectCustom({
  classes,
  values,
  handleChange,
  loading,
  options,
  name,
  label,
  md = 3,
  disabled,
  multiple = true,
}) {
  return (
    <Grid item xs={12} md={md}>
      <ListItem className={classes.listItem}>
        <FormControl className={classes.itemInput}>
          <InputLabel id={`${name}-${label}`}>{label}</InputLabel>
          <MaterialSelect
            labelId={`${name}-${label}`}
            id={name}
            name={name}
            multiple={multiple}
            value={values}
            onChange={handleChange}
            input={<Input />}
            disabled={loading || disabled}
            renderValue={(selected) =>
              multiple ? selected?.join(', ') : selected
            }
            MenuProps={multiple && MenuProps}>
            {options?.map((option, index) => (
              <MenuItem key={`${index}-${option}`} value={option}>
                {multiple && (
                  <Checkbox
                    checked={values.some((value) => value === option)}
                  />
                )}
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </MaterialSelect>
        </FormControl>
      </ListItem>
    </Grid>
  );
}
