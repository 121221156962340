import { useEffect, useMemo, useState } from 'react';

import { useDragScroll } from 'src/utils/hooks';

import Video from './Video';
import VideosSwitch from './VideosSwitch';
import * as VideoGallery from './styles';
import { marketVideos } from '../../presentation/data';
import { YearbookMarket, YearbookMarketVideo } from '../../types';
import { getVideoEmbedUrl } from '../../utils';

interface VideoGalleryProps {
  market: YearbookMarket;
}

export default function VideoGallerySection({ market }: VideoGalleryProps) {
  const [selectedVideo, setSelectedVideo] =
    useState<YearbookMarketVideo | null>(null);
  const [videosFilter, setVideosFilter] = useState('related');
  const [wrapperRef] = useDragScroll();

  const videoEmbedUrl = useMemo(
    () => (selectedVideo ? getVideoEmbedUrl(selectedVideo.videoId) : ''),
    [selectedVideo],
  );

  const relatedVideos = useMemo(
    () =>
      marketVideos.filter(
        (video) => video.market === market.name && video !== selectedVideo,
      ),
    [market, selectedVideo, market],
  );

  const videos = useMemo(
    () => (videosFilter === 'related' ? relatedVideos : marketVideos),
    [videosFilter, selectedVideo, market],
  );

  useEffect(() => {
    setSelectedVideo(relatedVideos[0]);
  }, []);

  function handleVideoFilter(value: string) {
    setVideosFilter(value);
  }

  function handleSelectVideo(video: YearbookMarketVideo) {
    setSelectedVideo(video);
  }

  return (
    <VideoGallery.Section data-yearbook-section="Galeria de Vídeos">
      <VideoGallery.TitleWrapper>
        <VideoGallery.BackgroundWrapper>
          <VideoGallery.Title>Galeria de Vídeos</VideoGallery.Title>
        </VideoGallery.BackgroundWrapper>
        <VideoGallery.BackgroundEnd />
      </VideoGallery.TitleWrapper>
      <VideoGallery.Content>
        <VideoGallery.Player>
          {videoEmbedUrl && (
            <VideoGallery.SelectedVideoContainer>
              <VideoGallery.SelectedVideo
                width="100%"
                height="100%"
                src={videoEmbedUrl}
                title={selectedVideo?.title}
                allow="autoplay; clipboard-write; encrypted-media; web-share"
                allowFullScreen
              />
            </VideoGallery.SelectedVideoContainer>
          )}
          <VideoGallery.SelectedVideoTitle>
            {selectedVideo?.title}
          </VideoGallery.SelectedVideoTitle>
          <VideoGallery.SelectedVideoAuthor>
            {selectedVideo?.author}
          </VideoGallery.SelectedVideoAuthor>
        </VideoGallery.Player>
        <VideoGallery.VideosContainer>
          <VideoGallery.VideosContainerWrapper>
            <VideosSwitch select={handleVideoFilter} selected={videosFilter} />
            <VideoGallery.VideosList ref={wrapperRef}>
              {videos.map((video, idx) => (
                <Video key={idx} video={video} select={handleSelectVideo} />
              ))}
            </VideoGallery.VideosList>
          </VideoGallery.VideosContainerWrapper>
        </VideoGallery.VideosContainer>
      </VideoGallery.Content>
    </VideoGallery.Section>
  );
}
