import React from 'react';

import { v4 as uuid } from 'uuid';

import { pageData, rankingsData } from './data';
import {
  formatChartProps,
  formatPieChartProps,
  YEARS,
} from '../chart-formater';
import {
  mapContractTypeData,
  mapCreditRisk,
  mapInvestorData,
  mapNegotiation,
  mapOfferType,
  mapSupply,
  mapTerm,
} from '../chart-map-data';
import ColumnChart from '../components/Charts/ColumnChart';
import ColumnLineChart from '../components/Charts/ColumnLineChart';
import PieChart from '../components/Charts/PieChart';
import Page from '../components/Page';
import ScrollHandler from '../components/Page/ScrollHandler';
import Text from '../components/Page/Text';
import Ranking from '../components/Widgets/Ranking';
import { Banners, ChartsDataRequestPayload } from '../types';
import { marketPageFns } from '../utils';

type Props = {
  open: boolean;
  indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>;
  chartsData: ChartsDataRequestPayload[];
  banners: Banners;
};

const COLLECTION_ID = 16;

const CRA = (props: Props) => {
  const banners = marketPageFns.getMarketBanners(props.banners, 'CRA');
  const chartData = marketPageFns.getChartData(props.chartsData, COLLECTION_ID);

  const [
    issuancesChart,
    esgChart,
    termsChart,
    sectorChart,
    contractTypeChart,
    debtorChart,
    offertTypeChart,
    remTypeChart,
    creditRiskType,
    supplyChart,
    negotiationsChart,
    investorsChart,
  ] = chartData;

  const [opening, intro, secondary, supply, ranking, teixeiraText] =
    pageData.map(marketPageFns.formatPageData(props.indexes, banners));

  const getPageProps = marketPageFns.getPageProps(props.open);

  const isReady = marketPageFns.checkIsReady(
    props.indexes,
    pageData,
    chartData,
    12,
  );

  return isReady ? (
    <>
      <Page {...getPageProps(opening)}>
        <Text text={opening.text} />
      </Page>

      <Page {...getPageProps(intro)}>
        <ScrollHandler
          textEl={intro.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(issuancesChart)({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,

            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(mapOfferType(offertTypeChart))({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(mapCreditRisk(creditRiskType))({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(debtorChart)({
                chart: 'column',
                categories: YEARS,
                field: 'perfil',
              })}
            />,
            <PieChart
              key={uuid()}
              market="CRA"
              {...formatPieChartProps(mapContractTypeData(contractTypeChart))}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(remTypeChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(mapTerm(termsChart))({
                chart: 'column',
                categories: YEARS,
                field: 'prazo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(sectorChart)({
                chart: 'column',
                categories: YEARS,
                field: 'segmento',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(esgChart)({
                chart: 'column',
                categories: YEARS,
                field: 'esg',
              })}
            />,
            <PieChart
              key={uuid()}
              market="CRA"
              {...formatPieChartProps(mapInvestorData(investorsChart))}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(secondary)}>
        <ScrollHandler
          textEl={secondary.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(mapNegotiation(negotiationsChart))({
                chart: 'columnLine',
                categories: YEARS,
                field: 'quantity',
                mapName: {
                  amount: 'Montante negociado',
                  quantity: 'Número de negócios',
                },
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(supply)}>
        <ScrollHandler
          textEl={supply.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="CRA"
              {...formatChartProps(mapSupply(supplyChart))({
                chart: 'columnLine',
                categories: YEARS,
                mapName: {
                  amount: 'Saldo Devedor',
                  quantity: 'Número de negócios',
                },
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(ranking)}>
        <ScrollHandler
          textEl={ranking.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={rankingsData.map((source, index) =>
            source ? <Ranking urlSource={source} key={index} /> : null,
          )}
        />
      </Page>
      <Page {...getPageProps(teixeiraText)}>
        <Text text={teixeiraText.text} />
      </Page>
    </>
  ) : (
    <></>
  );
};

export default React.memo(CRA);
