import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 204px;
  object-fit: cover;

  @media screen and (max-width: 425px) {
    height: 120px;
  }
`;
