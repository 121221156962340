import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { AddCircle, EventAvailable } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { flatten } from 'lodash';
import { useInfiniteQuery, useQuery } from 'react-query';
import { CustomTooltip } from 'src/components/CustomTooltip';
import TabTemplate from 'src/components/UI/TabTemplate';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Event, EventFilter } from 'src/types/event';
import { useIsElementVisible, useQueryParams } from 'src/utils/hooks';

import CreateEventModal from './CreateEventModal';
import HeaderEvents from './HeaderEvents';
import CardEvent from '../../components/CardEvent';
import MetaTags from '../../components/Seo/MetaTags';
import SkeletonGrid from '../../components/SkeletonGrid';
import { getEvents } from '../../services/events';
import { getInformativeTextByLocation } from '../../services/informative';
import { ScrollToTop } from '../../utils/scroll';

export default function AllEvents() {
  const { auth } = useAuthContext();
  const lastRef = useRef(null);
  const isLastVisible = useIsElementVisible(lastRef);
  const [openDialog, setOpenDialog] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [total, setTotal] = useState(0);
  const [{ query }, setQuery] = useQueryParams<EventFilter>({
    eventState: 'upcoming',
    date: null,
    format: '',
    eventPresentationType: '',
  });
  const { data } = useQuery('info', () =>
    getInformativeTextByLocation('events'),
  );

  const {
    isLoading: eventsLoading,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['events', query],
    ({ pageParam = 1 }) =>
      getEvents({ page: pageParam, pageSize: 9, filters: query }),
    {
      onSuccess: (data) => {
        const page = data.pages.at(-1);
        setTotal(page?.meta?.pagination?.total);
        setEvents(flatten(data.pages.map((i: any) => i.data)));
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data?.length !== 0 ? nextPage : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: 300000,
    },
  );

  const isLoading = eventsLoading || isFetchingNextPage;

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isLastVisible && events?.length < total && !isLoading) {
        fetchNextPage();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [isLastVisible, events, isLoading]);

  type EventListProps = {
    events: Event[];
    setEvents: Dispatch<SetStateAction<Event[]>>;
    refetch: () => void;
  };

  const EventList = ({ events, setEvents, refetch }: EventListProps) => (
    <>
      {events.length ? (
        events.map((event) => (
          <CardEvent
            key={event.id}
            event={event}
            setEvents={setEvents}
            refetchEvents={refetch}
          />
        ))
      ) : (
        <Grid item sm={12}>
          <Typography textAlign={'center'} color={'#4F4F4F'}>
            Não foram encontrados eventos para esse período, mas continue ligado
            para não perder nenhuma novidade.
          </Typography>
        </Grid>
      )}
    </>
  );

  return (
    <ScrollToTop>
      <MetaTags
        title="Eventos | Mercados de Crédito e de Capitais"
        description="Acompanhe a nossa agenda e fique por dentro dos principais eventos dos mercados de crédito e de capitais."
      />
      <Box component="section">
        <TabTemplate
          title="Eventos"
          breadcrumbs1="Eventos"
          titleIcon={<EventAvailable />}
          subtitle={data?.data?.events?.informationText}
          tabContent={[]}
          tabIndex={0}
          handleChange={() => {}}
        />
        <Box textAlign={'right'}>
          <CustomTooltip
            title="Faça login para acessar esta funcionalidade"
            disableHoverListener={auth.isLoggedIn}
            disableTouchListener={auth.isLoggedIn}
            placement="top">
            <span>
              <Button
                startIcon={<AddCircle color="primary" fontSize="large" />}
                color="primary"
                onClick={() => setOpenDialog(true)}
                disabled={!auth.isLoggedIn}
                sx={(theme) => ({
                  textTransform: 'none',
                  textDecoration: 'underline !important',
                  textWrap: 'nowrap',
                  letterSpacing: 'unset',
                  fontSize: '14px',
                  fontWeight: 700,
                  padding: '0 24px 0 0',
                  marginBottom: '2em',
                  display: 'none',
                  [theme.breakpoints.down('md')]: {
                    display: 'inline-flex',
                  },
                })}>
                Cadastrar evento
              </Button>
            </span>
          </CustomTooltip>
        </Box>
        <Paper elevation={3}>
          <HeaderEvents
            query={query}
            setQuery={setQuery}
            setOpenDialog={setOpenDialog}
          />
          <Grid
            container
            columnSpacing={{ lg: 9, md: 5, sm: 3 }}
            rowSpacing={{ lg: 7, md: 4, xs: 2 }}
            sx={(theme: Theme) => ({
              padding: '56px 51px 52px 68px',
              [theme.breakpoints.down('lg')]: {
                padding: 'clamp(12px, 3vw, 50px)',
                paddingTop: '27px',
              },
            })}>
            {isLoading && !events.length ? (
              <SkeletonGrid
                height="220px"
                width="auto"
                skeletonNumber={9}
                xs={12}
                sm={6}
                md={4}
                borderRadius={'6px'}
              />
            ) : (
              <EventList
                events={events}
                setEvents={setEvents}
                refetch={refetch}
              />
            )}
          </Grid>
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              padding="24px 0px">
              <CircularProgress color="secondary" size="3rem" />
            </Box>
          )}
          <div ref={lastRef} />
        </Paper>
        <CreateEventModal
          isOpen={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      </Box>
    </ScrollToTop>
  );
}
