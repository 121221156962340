import { Box, BoxProps, Chip, ChipProps } from '@mui/material';
import styled from 'styled-components';

export const CustomChip = styled(Chip)<ChipProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: 1.225,
  '&.MuiChip-outlined': {
    color: '#333',
    borderColor: '#000',
  },
  '&.MuiChip-filled': {
    color: '#47484C',
    backgroundColor: '#E0E0E0',
  },
  '& .MuiChip-label': {
    padding: theme.spacing(0, 2.75),
  },
}));

export const ContentBox = styled(Box)<BoxProps>({
  widht: '100%',
});

export const CustomVerticalDivider = styled(Box)<BoxProps>({
  height: '100%',
  border: '1px solid #828282',
});
