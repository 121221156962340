import { ReactNode } from 'react';

import { styled, ToggleButton, ToggleButtonProps } from '@mui/material';
import { EventFilter } from 'src/types/event';

type Props = {
  value: keyof EventFilter;
  selected: boolean;
  children: ReactNode;
  startIcon: ReactNode;
  handleChange: (event: any, key: keyof EventFilter) => void;
};

export default function CustomToggleButton({
  value,
  selected,
  children,
  startIcon,
  handleChange,
}: Props) {
  const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps>(
    ({ theme }) => ({
      color: selected ? `${theme.palette.primary.main} !important` : '#828282',
      backgroundColor: 'transparent !important',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: 0,
      minWidth: 'unset',
      border: 'none',
      gap: '5px',
      paddingInline: '0',
      [theme.breakpoints.down('sm')]: {
        order: 1,
      },
    }),
  );

  return (
    <CustomToggleButton
      value={value}
      selected={selected}
      onChange={(e) => handleChange(e, value)}>
      {startIcon}
      {children}
    </CustomToggleButton>
  );
}
