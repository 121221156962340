export default {
  operations: [
    {
      title: 'Visualização por mercado',
      description:
        'Ao selecionar um mercado visualize as operações correspondentes.',
      image:
        'https://static.uqbaronline.com/production-backend/fcdeb12a9957b785dc737510653e0f5f.gif?updated_at=2023-10-09T20:27:38.699Z',
    },
    {
      title: 'Modo visão avançada',
      description:
        'Ao habilitar a visão avançada você visualiza os dados por indicadores e encontra outras informações sobre a operação.',
      image:
        'https://static.uqbaronline.com/production-backend/96d580c40ff45beb6018581c60b50a17.gif?updated_at=2023-10-09T20:28:39.827Z',
    },
    {
      title: 'Filtros',
      description:
        'Ao habilitar os filtros você pode buscar as informações por coluna e personalizar o modo de filtro.',
      image:
        'https://static.uqbaronline.com/production-backend/aecc2847f340ec4bedd450885a5f03d0.gif?updated_at=2023-10-09T20:27:24.199Z',
    },
    {
      title: 'Ação de coluna',
      description:
        'Explore recursos utilizando as ações dentro da coluna com possibilidade de ordenar, fixar ou ocultar.',
      image:
        'https://static.uqbaronline.com/production-backend/340e29109911f1ea7d5a5ab50b14327d.gif?updated_at=2023-10-09T20:27:23.085Z',
    },
    {
      title: 'Mover coluna',
      description:
        'Mova as colunas criando um modo de visualização personalizado para você.',
      image:
        'https://static.uqbaronline.com/production-backend/29b830c668b0a925d792ec34f1938a14.gif?updated_at=2023-10-09T20:32:54.965Z',
    },
    {
      title: 'Colunas',
      description:
        'Selecione as colunas que deseja visualizar e crie uma tabela de acordo com suas necessidades.',
      image:
        'https://static.uqbaronline.com/production-backend/357a7a298f490dd022729b701dc7283e.gif?updated_at=2023-10-09T20:27:29.479Z',
    },
  ],
  indicators: [
    {
      title: 'Visualização por mercado',
      description:
        'Ao selecionar um mercado visualize os indicadores correspondentes.',
      image:
        'https://static.uqbaronline.com/production-backend/f9314fdd31d174cf8a05f5a16a41f49a.gif',
    },
    {
      title: 'Filtrar por indicadores e período.',
      description:
        'Escolha qual indicador deseja ver nas tabelas, bem como o período nos filtros do radar.',
      image:
        'https://static.uqbaronline.com/production-backend/08fab978f3a11205fa4817fb776b000d.gif',
    },
    {
      title: 'Configurar características',
      description:
        'Selecione as características que deseja visualizar em cada tabela para customizar seu resultado.',
      image:
        'https://static.uqbaronline.com/production-backend/a4b7dfa674dabecd537451b1cd71797b.gif',
    },
    {
      title: 'Prestadores de serviço',
      description:
        'Filtre os resultados do seu radar por um gestor, administrador ou custodiante (apenas mercado FIDC).',
      image:
        'https://static.uqbaronline.com/production-backend/56c70436d2ee4fff2bc3ca2ac7b9fb6d.gif',
    },
    {
      title: 'Meu radar',
      description:
        'Salve suas configurações customizadas no seu radar para poder acessar de forma rápida essa visualização.',
      image:
        'https://static.uqbaronline.com/production-backend/abf434c3afe6f07859dfcf9d53890c98.gif',
    },
  ],
  convocations: [
    {
      title: 'Visualização por mercado',
      description:
        'Ao selecionar um mercado visualize as assembleias publicadas.',
      image:
        'https://static.uqbaronline.com/production-backend/962a59105e85edc80e50c304e60300e6.gif',
    },
    {
      title: 'Filtros',
      description:
        'Filtre por período, operações, tipo ou espécie de assembléia.',
      image:
        'https://static.uqbaronline.com/production-backend/9d238b2e499bf08c067466675e0293a8.gif',
    },
    {
      title: 'Favoritos',
      description:
        'Encontre facilmente suas operações preferidas ou favorite novas para destacá-las no filtro de operações.',
      image:
        'https://static.uqbaronline.com/production-backend/65b413d11242ed8191daf423029ff677.gif',
    },
    {
      title: 'Meu radar',
      description:
        'Salve suas configurações customizadas no seu radar para poder acessar de forma rápida essa visualização.',
      image:
        'https://static.uqbaronline.com/production-backend/4a7ca72c2772598c5c7584fe2159bed6.gif',
    },
  ],
};
