// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

// Global components
import GeneralDataItem from '../../../../../components/GeneralDataItem';
import GeneralDataItemConditional from '../../../../../components/GeneralDataItemConditional';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_GENERAL_DATA_BY_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIIResumeGeneralData({ hash }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_FII_GENERAL_DATA_BY_HASH_ID, {
    variables: { hash },
    client: ipanema,
  });

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Dados Gerais
      </Typography>
      <Divider className={classes.divider} />
      <Box py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <GeneralDataItem
              title="CNPJ"
              subtitle={data.getFiiGeneralDataByHashId?.operationCnpj}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GeneralDataItem
              title="Ticker"
              subtitle={data.getFiiGeneralDataByHashId?.operationTickers}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GeneralDataItem
              title="Prazo"
              subtitle={data.getFiiGeneralDataByHashId?.operationTerm}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GeneralDataItemConditional
              title="Multiclasses"
              condition={data.getFiiGeneralDataByHashId?.isMulticlasses}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
