import { useCallback, useEffect, useMemo, useState } from 'react';

import ChartDetailsCard from '../../../components/ChartDetailsCard';
import ColumnChart from '../../../components/Charts/ColumnChart';
import LineColumnChart from '../../../components/Charts/LineColumnChart';
import PieChart from '../../../components/Charts/PieChart';
import DataSelector from '../../../components/DataSelector';
import ScrollWrapper from '../../../components/ScrollWrapper';
import { withLinks } from '../../../link-mapper';
import { descriptions, marketData } from '../../../presentation/data';
import { YearbookMarket, YearbookMarketChartInfo } from '../../../types';
import * as PrimaryMarket from '../styles';

interface PrimaryMarketSectionProps {
  market: YearbookMarket;
}

export function PrimaryMarketSection({ market }: PrimaryMarketSectionProps) {
  const [selectedChartInfo, setSelectedChartInfo] =
    useState<YearbookMarketChartInfo | null>(null);

  const items = useMemo(
    () => marketData[market.name].filter((info) => info.section === 'primary'),
    [market],
  );

  const description = useMemo(
    () => descriptions[market.name].primary,
    [market.name],
  );

  const ChartComponent = useMemo(() => {
    if (selectedChartInfo?.chart?.chartType === 'line-column')
      return LineColumnChart;
    else if (selectedChartInfo?.chart?.chartType === 'column')
      return ColumnChart;
    else return PieChart;
  }, [selectedChartInfo]);

  const getChartInfoByName = useCallback(
    (name: string) => {
      return marketData[market.name].find(
        (info) => info.section === 'primary' && info.name === name,
      );
    },
    [market],
  );

  const selectChartInfo = useCallback(
    (selectedName: string) => {
      const chartInfo = getChartInfoByName(selectedName) ?? null;
      setSelectedChartInfo(chartInfo);
    },
    [market],
  );

  useEffect(() => {
    setSelectedChartInfo(items[0]);
  }, [market]);

  return (
    <PrimaryMarket.Section data-yearbook-section="Mercado Primário">
      <PrimaryMarket.Title>Mercado Primário</PrimaryMarket.Title>
      <PrimaryMarket.Description>
        {description.map((text, idx) => (
          <PrimaryMarket.DescriptionParagraph
            key={`${market}-${idx}`}
            dangerouslySetInnerHTML={{ __html: withLinks(text) }}
          />
        ))}
      </PrimaryMarket.Description>
      <ScrollWrapper
        items={items}
        select={selectChartInfo}
        selected={selectedChartInfo}>
        {selectedChartInfo && (
          <PrimaryMarket.Content>
            <ChartDetailsCard details={selectedChartInfo.details} />
            <PrimaryMarket.ChartContent>
              <DataSelector
                select={selectChartInfo}
                selected={selectedChartInfo}
                items={items}
                id="primary-market-selector"
              />
              <PrimaryMarket.ChartTip>
                Passe o mouse sobre o gráfico para visualizar a legenda dos
                dados.
              </PrimaryMarket.ChartTip>
              <PrimaryMarket.ChartWrapper>
                {selectedChartInfo?.chart?.chartType && (
                  <ChartComponent
                    data={selectedChartInfo.chart.data as any}
                    categories={selectedChartInfo.chart.categories}
                    numeric={selectedChartInfo.chart.numeric}
                    stacked={selectedChartInfo.chart.stacked}
                    monetary={selectedChartInfo.chart.monetary}
                    animationEnabled={selectedChartInfo.chart.animated ?? true}
                  />
                )}
                {selectedChartInfo.link && (
                  <PrimaryMarket.Button href={selectedChartInfo.link}>
                    Acessar plataforma Uqbar
                  </PrimaryMarket.Button>
                )}
              </PrimaryMarket.ChartWrapper>
            </PrimaryMarket.ChartContent>
          </PrimaryMarket.Content>
        )}
      </ScrollWrapper>
    </PrimaryMarket.Section>
  );
}
