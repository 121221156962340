import { Card, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { API } from 'src/types/api';

import * as S from '../styles';

export default function APICard({ data }: { data: API }) {
  const history = useHistory();

  return (
    <Card
      sx={(theme) => ({
        width: '100%',
        bgcolor: '#FFF',
        borderRadius: '12px',
        p: theme.spacing(3.5, 4.25, 4, 4),
        boxShadow: '0px 4px 4px 0px #0000001F',
        cursor: 'pointer',
      })}
      onClick={() => history.push(`/apis/${data.apiEndpoint}`)}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={3.375}>
        <Typography color="#333" fontWeight={700} fontSize="1.125rem">
          {data.name}
        </Typography>
        <Stack direction="row" spacing={1}>
          {data.plans.map((plan, planKey) => (
            <S.CustomChip
              key={`${data.id}-${planKey}`}
              variant="outlined"
              label={plan.name}
            />
          ))}
        </Stack>
      </Stack>
      <Typography color="#4F4F4F" textAlign="justify" maxWidth="816px">
        {data.description}
      </Typography>
      <Stack direction="row" spacing={1.75} mt={4}>
        <S.CustomChip label={data.market.name} variant="outlined" />
        <S.CustomChip label={data.indicator} />
      </Stack>
    </Card>
  );
}
