// Apollo client
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
// Material ui components
import {
  Box,
  Divider,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import CloseIcon from '@material-ui/icons/Close';
// Charts.js
import { Doughnut } from 'react-chartjs-2';

// Images
import chartDefault from '../../../../../assets/imgs/chart-default.svg';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import {
  GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
  GET_FIDC_WALLET_BY_CNPJ,
  GET_FIDC_WALLET_DETAILS_BY_CNPJ,
} from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  link: {
    cursor: 'pointer',
  },
  img: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    color: theme.palette.grey[600],
    borderRadius: '50%',
    margin: theme.spacing(1),
    padding: theme.spacing(0.3),
  },
}));

export default function OperationFIDCResumeWallet({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [change, setChange] = useState(false);
  const { data, loading, error } = useQuery(
    GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_resumo_carteira'
      ],
    },
  );

  useEffect(() => {
    clicked && setChange(true);
  }, [clicked]);

  function handleClick(_, chart) {
    if (chart.length !== 0 && chart[0].index === 0) {
      setClicked(true);
    }
  }

  function handleHover(e, chart) {
    if (chart.length !== 0 && chart[0].index === 0) {
      e.native.target.style.cursor = 'pointer';
    } else {
      e.native.target.style.cursor = '';
    }
  }

  function handleClickBack() {
    setChange(!change);
    setClicked(!change);
  }

  function ChartDoughnutGeneral({ cnpj }) {
    const { data, loading } = useQuery(GET_FIDC_WALLET_BY_CNPJ, {
      variables: { cnpj },
      client: ipanema,
    });

    if (loading) return null;

    return data?.getFidcWalletByCnpj ? (
      <Doughnut
        height={293}
        data={{
          labels: data.getFidcWalletByCnpj
            ? data.getFidcWalletByCnpj?.dataset.labels
            : [],
          datasets: [
            {
              data: data.getFidcWalletByCnpj
                ? data.getFidcWalletByCnpj?.dataset.data
                : [],
              backgroundColor: [
                'rgb(109, 216, 253)',
                'rgb(235, 129, 114)',
                'rgb(247, 217, 130)',
                'rgb(163, 160, 251)',
              ],
              borderWidth: 0,
              hoverOffset: 4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              position: 'right',
              labels: {
                generateLabels(chart) {
                  const data = chart.data;
                  if (data.labels?.length && data.datasets?.length) {
                    return data.labels.map((label, i) => {
                      const meta = chart.getDatasetMeta(0);
                      const style = meta.controller.getStyle(i);

                      return {
                        text: label,
                        fontColor: '#000000',
                        lineWidth: 2,
                        fillStyle: 'transparent',
                        strokeStyle: style.backgroundColor,
                        hidden: !chart.getDataVisibility(i),
                        borderRadius: 6,

                        // Extra data used for toggling the correct item
                        index: i,
                      };
                    });
                  }
                  return [];
                },
                boxWidth: 12,
              },
            },
          },
          onClick: handleClick,
          onHover: handleHover,
          maintainAspectRatio: false,
          layout: {
            padding: 8,
          },
        }}
      />
    ) : (
      <img
        src={chartDefault}
        height="293"
        alt="Não foi possivel carregar o gráfico"
        className={classes.img}
      />
    );
  }

  function ChartDoughnutCreditRights({ cnpj }) {
    const { data, loading, error } = useQuery(GET_FIDC_WALLET_DETAILS_BY_CNPJ, {
      variables: { cnpj },
      client: ipanema,
    });

    if (loading) return null;
    if (error) return null;

    return data?.getFidcWalletDetailsByCnpj ? (
      <>
        <Box
          display="flex"
          justifyContent="flex-end"
          position="absolute"
          right={0}>
          <Link onClick={handleClickBack} className={classes.link}>
            <CloseIcon titleAccess="Fechar" className={classes.closeIcon} />
          </Link>
        </Box>
        <Doughnut
          height={293}
          data={{
            labels: data.getFidcWalletDetailsByCnpj
              ? data.getFidcWalletDetailsByCnpj?.dataset.labels
              : [],
            datasets: [
              {
                data: data.getFidcWalletDetailsByCnpj
                  ? data.getFidcWalletDetailsByCnpj?.dataset.data
                  : [],
                backgroundColor: [
                  'rgb(109, 216, 253)',
                  'rgb(235, 129, 114)',
                  'rgb(247, 217, 130)',
                  'rgb(163, 160, 251)',
                ],
                borderWidth: 0,
                hoverOffset: 4,
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  generateLabels(chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.labels.map((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const style = meta.controller.getStyle(i);

                        return {
                          text: label,
                          fontColor: '#000000',
                          lineWidth: 2,
                          fillStyle: 'transparent',
                          strokeStyle: style.backgroundColor,
                          hidden: !chart.getDataVisibility(i),
                          borderRadius: 6,

                          // Extra data used for toggling the correct item
                          index: i,
                        };
                      });
                    }
                    return [];
                  },
                  boxWidth: 12,
                },
              },
            },
            maintainAspectRatio: false,
            layout: {
              padding: 8,
            },
          }}
        />
      </>
    ) : (
      <img
        src={chartDefault}
        height="293"
        alt="Dados não disponíveis"
        className={classes.img}
      />
    );
  }

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Carteira
      </Typography>
      <Divider className={classes.divider} />
      <Paper square className={classes.paper}>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_resumo_carteira'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_resumo_carteira'
            ]
          }>
          {change ? (
            <ChartDoughnutCreditRights
              cnpj={
                data
                  ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                  ?.cnpj
              }
            />
          ) : (
            <ChartDoughnutGeneral
              cnpj={
                data
                  ?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                  ?.cnpj
              }
            />
          )}
        </If>
      </Paper>
    </Box>
  );
}
