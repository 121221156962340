import { useEffect, useRef, useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import SubTitle from 'src/components/UI/Subtitle';
import TabTemplate from 'src/components/UI/TabTemplate';
import { setBannerMessage } from 'src/store/display';
import { Article } from 'src/types/article';
import { Event } from 'src/types/event';
import { Institution } from 'src/types/institution';
import { Service } from 'src/types/marketplace';
import { Operation } from 'src/types/operation';
import { Professional } from 'src/types/professional';
import { truncateText } from 'src/utils/string';

import { If } from '../../components/If';
import { useAuthContext } from '../../context/AuthContextProvider';
import { findAllFavorites, removeFavorite } from '../../services/favorite';
import { useIsElementVisible } from '../../utils/hooks';
import { ScrollToTop } from '../../utils/scroll';

type DataState = {
  id: number;
  createdAt: string;
  professional: Professional;
  operation: Operation;
  institution: Institution;
  article: Article;
  service: Service;
  event: Event;
};

type SelectedItemState = { id?: number; title?: string };

export function Favorites() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useAuthContext();
  const lastRef = useRef(null);
  const [data, setData] = useState<DataState[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectedItemState>({});
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('type');
  const [sort, setSort] = useState([
    'article.id:asc',
    'operation.market.id:asc',
    'institution.id:asc',
    'professional.id:asc',
  ]);

  const isLastVisible = useIsElementVisible(lastRef);

  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['favorites', sort],
      ({ pageParam = 1 }) =>
        findAllFavorites({
          page: pageParam,
          pageSize: 15,
          sort,
        }),
      {
        onSuccess: (data) => {
          const page = data.pages.at(-1);
          setTotal(page?.meta?.pagination?.total);
          if (page?.meta?.pagination?.page === 1) {
            setData(page.data);
          } else {
            setData((prev) => [...prev, ...page.data]);
          }
        },
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.data.length !== 0 ? nextPage : undefined;
        },
        refetchOnMount: 'always',
        cacheTime: 0,
      },
    );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isLastVisible && data?.length < total) {
        fetchNextPage();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [isLastVisible, data]);

  const removeFavoriteMutation = useMutation(removeFavorite, {
    onSuccess: (data, variables) => {
      setData((prev) => prev.filter((row: DataState) => row.id != variables));
      setOpenDialog(false);
      dispatch(
        setBannerMessage({
          message: 'Item removido da lista de favoritos com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
    },
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Ocorreu um erro ao desfavoritar, tente novamente',
          type: 'error',
          autoclose: false,
        }),
      );
    },
  });

  const { isLoading: removeFavoriteLoading } = removeFavoriteMutation;

  const handleRemoveFavorite = (id?: number) => {
    if (!removeFavoriteLoading && id) {
      removeFavoriteMutation.mutate(id);
    }
  };

  const handleClick = (type: string, row: any) => {
    if (type === 'operacao') {
      history.push(
        `/operacao/${row?.market?.name.toLowerCase()}/${row?.slug}/${
          row?.hashId
        }`,
      );
    } else if (type === 'service') {
      history.push(
        `/marketplace/${row.type === 'Serviço' ? 'servico' : 'projeto'}/${row.slug}/${row.id}`,
      );
    } else if (type === 'event') {
      history.push(`/evento/${row?.slug}/${row?.id}`);
    } else {
      history.push(`/${type}/${row?.slug}/${row?.id}`);
    }
  };

  const handleClickRemove = (row: any) => {
    let selected: SelectedItemState = {};
    if (row.article) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar o artigo “${row.article.title}”?`,
      };
    }
    if (row.professional) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar o profissional “${row.professional.fullName}”?`,
      };
    }
    if (row.operation) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar a operação “${row.operation.name}”?`,
      };
    }
    if (row.institution) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar a instituição “${row.institution.fantasyName}”?`,
      };
    }
    if (row.service) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar o ${row.service.type?.toLowerCase()} “${row.service.title}”?`,
      };
    }
    if (row.event) {
      selected = {
        id: row.id,
        title: `Tem certeza que deseja desfavoritar o evento “${row.event.title}”?`,
      };
    }
    setSelectedItem(selected);
    setOpenDialog(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const nextOrder = isAsc ? 'desc' : 'asc';
    setOrder(nextOrder);
    setOrderBy(property);
    if (property === 'name') {
      const defaultOrder = [
        `article.title:${nextOrder}`,
        `operation.name:${nextOrder}`,
        `institution.fantasyName:${nextOrder}`,
        `professional.fullName:${nextOrder}`,
        `service.title:${nextOrder}`,
        `event.title:${nextOrder}`,
      ];
      setSort(isAsc ? defaultOrder.reverse() : defaultOrder);
    } else if (property === 'type') {
      const defaultOrder = [
        'article.id:asc',
        'operation.market.id:asc',
        'institution.id:asc',
        'professional.id:asc',
        'service.id:asc',
        'event.id:asc',
      ];
      setSort(isAsc ? defaultOrder.reverse() : defaultOrder);
    } else if (property === 'date') {
      setSort([`createdAt:${nextOrder}`]);
    }
  };

  return (
    <ScrollToTop>
      <TabTemplate
        title="Meus favoritos"
        titleIcon={<StarIcon />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
        breadcrumbs1="Meus favoritos"
      />
      <Paper elevation={2} sx={{ marginTop: 9, padding: '56px 30px 10px' }}>
        <SubTitle title="Favoritos" />
        <Box
          sx={(theme) => ({
            padding: '32px 30px',
            [theme.breakpoints.down('sm')]: {
              padding: '32px 10px',
            },
          })}>
          <Typography fontSize={14}>
            Esses são todos os itens favoritados por você dentro da plataforma,
            consulte de maneira rápida os itens preferidos por você.
          </Typography>

          <TableContainer sx={{ marginTop: '55px' }}>
            <Table aria-label="table investors operations">
              <TableHead>
                <TableRow>
                  <TableCell width="35%" sx={{ fontWeight: 700 }}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'name')}>
                      Página
                      {orderBy ? <span /> : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="30%" sx={{ fontWeight: 700 }}>
                    <TableSortLabel
                      active={orderBy === 'type'}
                      direction={orderBy === 'type' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'type')}>
                      Tipo de conteúdo
                      {orderBy ? <span /> : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="25%" sx={{ fontWeight: 700 }}>
                    <TableSortLabel
                      active={orderBy === 'date'}
                      direction={orderBy === 'date' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'date')}>
                      Adicionado em
                      {orderBy ? <span /> : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" sx={{ fontWeight: 700 }}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow key={`${item.id}-item-${index}`}>
                    <If condition={item.article}>
                      <TableCell
                        onClick={() => handleClick('artigo', item.article)}>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.article?.title, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>Artigo</TableCell>
                    </If>
                    <If condition={item.operation}>
                      <TableCell
                        onClick={() => handleClick('operacao', item.operation)}>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.operation?.name, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>{`Operação ${
                        item.operation?.market?.name
                          ? `(${item.operation?.market?.name})`
                          : ''
                      }`}</TableCell>
                    </If>
                    <If condition={item.institution}>
                      <TableCell
                        onClick={() =>
                          handleClick('instituicao', item.institution)
                        }>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.institution?.fantasyName, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>Instituição</TableCell>
                    </If>
                    <If condition={item.professional}>
                      <TableCell
                        onClick={() =>
                          handleClick('profissional', item.professional)
                        }>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.professional?.fullName, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>Profissional</TableCell>
                    </If>
                    <If condition={item.service}>
                      <TableCell
                        onClick={() => handleClick('service', item.service)}>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.service?.title, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.service?.type}</TableCell>
                    </If>
                    <If condition={item.event}>
                      <TableCell
                        onClick={() => handleClick('event', item.event)}>
                        <Typography
                          fontSize={14}
                          maxWidth={254}
                          sx={{ cursor: 'pointer' }}>
                          {truncateText(item.event?.title, 50)}
                        </Typography>
                      </TableCell>
                      <TableCell>Evento</TableCell>
                    </If>
                    <TableCell>
                      {format(parseISO(item.createdAt), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        sx={{
                          color: '#1D1D1D',
                          fontSize: '12px',
                          fontWeight: 400,
                          textTransform: 'none',
                          letterSpacing: 0,
                          padding: 0,
                          justifyContent: 'flex-start',
                          '&:hover': {
                            background: 'transparent',
                          },
                        }}
                        variant="text"
                        onClick={() => handleClickRemove(item)}
                        startIcon={<StarIcon color="primary" />}>
                        Desfavoritar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <If condition={(!data || data?.length === 0) && !isLoading}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      rowSpan={2}
                      component="td"
                      scope="row">
                      <Typography>Nenhum favorito encontrado</Typography>
                    </TableCell>
                  </TableRow>
                </If>
                <If
                  condition={(isFetchingNextPage && hasNextPage) || isLoading}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      rowSpan={2}
                      component="td"
                      scope="row">
                      <Typography>
                        <CircularProgress size="1.8rem" />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </If>
              </TableBody>
            </Table>
          </TableContainer>
          <div ref={lastRef} />
        </Box>
      </Paper>
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={selectedItem.title}
        submitAction={() => handleRemoveFavorite(selectedItem?.id)}
        hasActionButton={true}
      />
    </ScrollToTop>
  );
}
