import { Box, Grid, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

export default function YearbookSection({
  data,
  name,
  description,
  publishDate,
  isFeatured = false,
}: {
  data: any;
  description: string;
  name?: string;
  publishDate?: string;
  isFeatured?: boolean;
}) {
  const getSubcategoryFontSize = (textLength: number) => {
    if (textLength <= 9) {
      return '18px';
    } else if (textLength > 9 && textLength <= 25) {
      return '14px';
    } else {
      return '12px';
    }
  };

  const handleClick = (url: string) => {
    window.open(url, '_blank', 'noreferrer noopener');
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {name && (
          <Typography
            sx={(theme) => ({
              color: '#47484C',
              fontSize: '18px',
              fontWeight: 700,
              width: 'fit-content',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
              },
            })}>
            {name}
          </Typography>
        )}
        {publishDate && (
          <Typography
            sx={(theme) => ({
              marginLeft: 'auto',
              color: isFeatured ? 'white' : '#828282',
              fontSize: '14px',
              width: 'fit-content',
              height: 'fit-content',
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}>
            Publicado em {format(parseISO(publishDate), 'dd/MM/yyyy')}
          </Typography>
        )}
      </Box>
      <Grid
        container
        sx={(theme) => ({
          backgroundColor: '#F5F5F5',
          padding: '24px',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '8px',
          [theme.breakpoints.down('md')]: {
            gap: 3,
          },
          [theme.breakpoints.down('sm')]: {
            gap: 3,
            padding: '16px',
          },
        })}>
        <Grid item md={3} sm={12} xs={12}>
          {publishDate && (
            <Typography
              sx={(theme) => ({
                marginLeft: 'auto',
                color: '#828282',
                fontSize: '14px',
                width: 'fit-content',
                height: 'fit-content',
                display: 'none',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '12px',
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'right',
                  marginBottom: '8px',
                },
              })}>
              Publicado em {format(parseISO(publishDate), 'dd/MM/yyyy')}
            </Typography>
          )}
          <Typography fontSize={12}>{description}</Typography>
        </Grid>
        <Grid
          container
          item
          md={9}
          sm={12}
          sx={(theme) => ({
            gap: 3,
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
              justifyContent: 'center',
              padding: '0 64px',
              gap: 4,
            },
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'center',
              padding: 0,
              gap: 2,
            },
          })}>
          {data?.map((subcategory: any) => (
            <Box
              key={subcategory.id}
              onClick={() =>
                handleClick(subcategory.link ?? subcategory?.document?.url)
              }
              sx={(theme) => ({
                padding: '16px',
                borderRadius: '12px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12)',
                minWidth: '132px',
                maxWidth: '132px',
                maxHeight: '132px',
                minHeight: '132px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: theme.palette.primary.main,
                  '& > img': {
                    display: 'inline !important',
                  },
                  '& > p': {
                    display: 'none',
                  },
                },
                [theme.breakpoints.down('sm')]: {
                  minWidth: '110px',
                  maxWidth: '110px',
                  maxHeight: '110px',
                  minHeight: '110px',
                },
              })}>
              {subcategory?.icon?.url ? (
                <img
                  src={subcategory?.icon?.url}
                  alt={subcategory.name}
                  style={{
                    display: 'none',
                    fill: 'white',
                    minWidth: '32px',
                    minHeight: '32px',
                  }}
                />
              ) : null}
              <Typography
                sx={(theme) => ({
                  fontSize: getSubcategoryFontSize(subcategory.name?.length),
                  fontWeight: 700,
                  textAlign: 'center',
                  color: theme.palette.primary.main,
                })}>
                {subcategory.name}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
