import { Grid, Typography } from '@material-ui/core';
export default function Testimonials({ ...props }) {
  const { testmonials, classes } = props;
  return (
    <Grid container spacing={4} className={classes.testmonials}>
      {testmonials.map(({ id, name, testmonial }) => (
        <Grid item key={id}>
          <Typography className={classes.testmonial}>{testmonial}</Typography>
          <Typography className={classes.testmonialName}>- {name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}
