import {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_SortingState,
} from 'material-react-table';
import { useInfiniteQuery } from 'react-query';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { buildFilters } from 'src/pages/AllOperations/utils';
import OperationInvestorsInfiniteTable from 'src/pages/Operation/OperationInvestorsInfiniteTable';
import { convertSorting } from 'src/pages/Operation/utils';
import { getAll } from 'src/services/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  operationCnpj: string;
  operationName: string;
};

const PAGE_SIZE = 100;

export default function OperationFIDCInvestorsMovements({
  operationCnpj,
  operationName,
}: Props) {
  const { auth } = useAuthContext();
  const market = 'fidc';
  const indicator = 'movimentacoes-dos-investidores';
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>({});

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'investidor',
        header: 'Investidor',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'contains',
          'startsWith',
          'endsWith',
        ],
      },
      {
        accessorKey: 'cnpjFundo',
        header: 'CNPJ do investidor',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'contains',
          'startsWith',
          'endsWith',
        ],
      },
      {
        accessorKey: 'movimentacao',
        header: 'Tipo de movimentação',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'contains',
          'startsWith',
          'endsWith',
        ],
      },
      {
        accessorKey: 'quantidadePosicaoFinal',
        accessorFn: (row: any) => row.quantidadePosicaoFinal,
        header: 'Quantidade',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertNumber(cell.getValue()) : '-',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
      {
        accessorKey: 'valorMercadoPosicaoFinal',
        accessorFn: (row: any) => row.valorMercadoPosicaoFinal,
        header: 'Valor de mercado',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
      {
        accessorKey: 'dataCompetencia',
        accessorFn: (row: any) => row.dataCompetencia,
        header: 'Mês de referência',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => {
          if (!cell.getValue()) return '-';
          const formattedDate = format(new Date(cell.getValue()), 'MMMM/yyyy', {
            locale: ptBR,
          });
          return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        filterFn: 'equals',
        filterVariant: 'date',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
    ],
    [],
  );

  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      'operation-investors-details',
      indicator,
      market,
      operationCnpj,
      columnFilters,
      sorting,
    ],
    queryFn: ({ pageParam = 1 }) => {
      const columnFiltersParams = buildFilters(columnFilters, columnFilterFns);
      const sortingParams = convertSorting(sorting);

      return getAll({
        market: market,
        indicator: indicator,
        cnpj: operationCnpj,
        permission: 'operacoes_fidic_movimentacoes_investidores',
        mode: 'meta',
        page: pageParam,
        pageSize: PAGE_SIZE,
        filters: columnFiltersParams,
        sorting: sortingParams,
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    enabled: !!operationCnpj && auth.isLoggedIn,
    retry: false,
    useErrorBoundary: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    cacheTime: 0,
    retryOnMount: false,
  });

  const flatData = useMemo(
    () => data?.pages.flatMap((page) => page.data) ?? [],
    [data],
  );

  const totalCount = data?.pages?.[0]?.totalCount ?? 0;
  const totalFetched = flatData.length;
  const previousScrollTopRef = useRef<number>(0);

  const extraExportColumns = [
    {
      accessorKey: 'cnpjFundoCota',
      header: 'CNPJ da Operação',
    },
    {
      accessorKey: 'operationName',
      header: 'Nome da Operação',
    },
  ];

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (
        containerRefElement &&
        hasNextPage &&
        !isFetching &&
        totalFetched < totalCount
      ) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalCount, hasNextPage],
  );

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop } = target;

    if (scrollTop !== previousScrollTopRef.current) {
      previousScrollTopRef.current = scrollTop;
      fetchMoreOnBottomReached(target);
    }
  };

  useEffect(() => {
    const filterFns = columns.reduce(
      (obj: any, item: any) => ({
        ...obj,
        [item.accessorKey]: item._filterFn ?? item.filterFn,
      }),
      {},
    );
    setColumnFilterFns(filterFns);
  }, [setColumnFilterFns]);

  return (
    <Box
      py={2}
      px={4}
      sx={{
        '@media print': {
          zoom: '60%',
        },
      }}>
      <SubTitle
        title={`Movimentações dos investidores do ${market.toUpperCase()} ${operationName}`}
      />
      {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
        <PanelLogin small={undefined} message={undefined} />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22.1px',
            }}>
            Confira as movimentações de compra e venda realizadas pelos
            investidores ao longo do tempo, entenda as tendências e os padrões
            de investimento. Informações disponíveis apenas para investidores
            que sejam do fundo de investimentos.
          </Typography>

          <OperationInvestorsInfiniteTable
            data={flatData}
            columns={columns}
            sorting={sorting}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setSorting={setSorting}
            columnFilterFns={columnFilterFns}
            setColumnFilterFns={setColumnFilterFns}
            isLoading={isFetching}
            operationName={operationName}
            market={market}
            indicator={indicator}
            hasBottom={true}
            permission={'operacoes_fidic_movimentacoes_investidores'}
            exportPermission={'operacoes_movimentacoes_investidores_exportar'}
            extraExportColumns={extraExportColumns}
            handleScroll={handleScroll}
            totalCount={totalCount}
          />
        </>
      )}
    </Box>
  );
}
