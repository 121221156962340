import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery } from 'react-query';

import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import {
  getFAQ,
  getInformativeTextByLocation,
} from '../../services/informative';
import { ScrollToTop } from '../../utils/scroll';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.6em',
    fontWeight: theme.typography.fontWeightBold,
  },
  accordion: {
    backgroundColor: theme.palette.grey[200],
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionBox: {
    margin: theme.spacing(1, 0),
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color: theme.palette.secondary.main,
    },
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  accordionDetails: {
    width: '100%',
    padding: '8px 16px 8px',
  },
  section: {
    margin: theme.spacing(2, 0),
  },
  heading: {
    fontSize: '1.4em',
  },
  content: {
    padding: theme.spacing(1),
  },
}));

export default function FAQ() {
  const classes = useStyles();
  const { data } = useQuery('events', () => getFAQ());

  const { data: tooltipData } = useQuery('info', () =>
    getInformativeTextByLocation('faq'),
  );

  return (
    <ScrollToTop>
      <MetaTags
        title="FAQ | UQBAR"
        description="Tire suas principais dúvidas com o nosso FAQ."
      />
      <Box component="section">
        <Subheader
          title="FAQ"
          breadcrumbs1="FAQ"
          tooltipText={tooltipData?.data?.faq?.informationText}
        />
        <Box py={3}>
          {data?.data?.sections?.map((section, index) => (
            <Box key={index} className={classes.section}>
              <Typography className={classes.title}>{section.title}</Typography>
              {section?.questions?.map(({ question, answer }, index) => (
                <Box
                  className={classes.accordionBox}
                  key={`question-${index}}`}>
                  <Accordion className={classes.accordion} elevation={0}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography className={classes.heading}>
                        {question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Typography
                        className={classes.content}
                        dangerouslySetInnerHTML={{
                          __html: answer,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </ScrollToTop>
  );
}
