import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  position: relative;
`;

type TitleProps = {
  padding?: string;
  margin?: string;
};

export const TitleWrapper = styled.div<TitleProps>`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

export const Title = styled.div`
  color: #ff8811;
  letter-spacing: 14px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  padding: 0 10%;
`;

export const MenuWrapper = styled.div`
  width: 300px;
  max-width: 300px;
  border-right: 5px solid black;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 60%;
  padding-bottom: 300px;
  margin-left: auto;
  margin-right: auto;
`;
