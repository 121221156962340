import { useState } from 'react';

import { Facebook, LinkedIn, WhatsApp } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import CopyLinkButton from 'src/components/CopyUrlButton';
import { Presentation } from 'src/types/event';

interface CustomShareButtonProps {
  Icon: any;
  onClick?: () => void;
  data: Presentation;
}

const shareButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
};

export const CustomShareButton = ({ Icon, data }: CustomShareButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const host = process.env.REACT_APP_PUBLIC_URL ?? window.location.origin;
  const eventUrl = `${host}/evento/${data.event.slug}/${data.event.id}/programacao?presentationId=${data.id}`;
  const message =
    data?.state === 'ended'
      ? `Eu assisti à palestra ${data?.name}`
      : `Eu vou assistir à palestra ${data?.name}`;

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
        <Box display="flex" gap={2}>
          <Icon style={{ color: 'black', fontSize: '26px' }} />
        </Box>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: '30px 50px',
          }}>
          <WhatsappShareButton
            title={message}
            separator=" | "
            url={eventUrl}
            style={shareButtonStyle}>
            <WhatsApp fontSize="small" sx={{ color: '#47484C' }} />
            <Typography color="#828282" fontWeight={500}>
              Via Whatsapp
            </Typography>
          </WhatsappShareButton>
          <LinkedinShareButton
            title={message}
            source={host}
            url={eventUrl}
            style={shareButtonStyle}>
            <LinkedIn fontSize="small" sx={{ color: '#47484C' }} />
            <Typography color="#828282" fontWeight={500}>
              Via Linkedin
            </Typography>
          </LinkedinShareButton>
          <FacebookShareButton
            quote={message}
            url={eventUrl}
            style={shareButtonStyle}>
            <Facebook fontSize="small" sx={{ color: '#47484C' }} />
            <Typography color="#828282" fontWeight={500}>
              Via Facebook
            </Typography>
          </FacebookShareButton>
          <Box style={shareButtonStyle}>
            <CopyLinkButton size="1.25rem" params={`presentationId=${data.id}`}>
              <Typography color="#828282" fontWeight={500}>
                Copiar link
              </Typography>
            </CopyLinkButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
