// Material ui components
import { Box, Grid } from '@mui/material';

// Local components
import OperationCRIAssetAssignors from './OperationCRIAssetAssignors';
import OperationCRIAssetCharacteristics from './OperationCRIAssetCharacteristics';
import OperationCRIAssetReceivables from './OperationCRIAssetReceivables';
import OperationCriAssetWalletPDD from './OperationCriAssetWalletPDD';
import { PanelLogin } from '../../../../components/PanelLogin';
// Providers
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRIAsset({
  hashId,
  operationName,
}: {
  hashId: string;
  operationName: string;
}) {
  const { auth } = useAuthContext();
  return (
    <Box p={3}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cri_ativo'] ? (
        <Box p={2}>
          <PanelLogin message={null} small={null} />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRIAssetCharacteristics hash={hashId} />
          </Grid>
          <Grid item xs={12}>
            <OperationCRIAssetAssignors hash={hashId} />
          </Grid>
          <OperationCriAssetWalletPDD
            operationId={hashId}
            operationName={operationName}
          />
          <OperationCRIAssetReceivables
            hash={hashId}
            operationName={operationName}
          />
        </Grid>
      )}
    </Box>
  );
}
