import { useState } from 'react';

import { Grid, ListItem } from '@material-ui/core';
import { useQuery } from 'react-query';

import { InputSearch } from '../../../components/InputSearch';
import { SelectCustom } from '../../../components/SelectCustom';
import { getMarkets } from '../../../services/institution';

export default function HeaderProfessionals({
  classes,
  setFilters,
  filters,
  setLastFound,
  setSortHistory,
}) {
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [search, setSearch] = useState('');

  const { isLoading: marketsLoading } = useQuery('markets', getMarkets, {
    onSuccess: ({ data }) => {
      setMarkets(
        data.filter((market) =>
          ['CRA', 'CRI', 'FII', 'FIDC'].includes(market.name),
        ),
      );
    },
  });

  const handleSelectedMarkets = (event) => {
    const { value } = event.target;
    setSelectedMarkets(value);
    setFilters({
      ...filters,
      markets: value.map(({ name }) => name),
    });
    setLastFound(null);
    setSortHistory({});
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    setFilters({
      ...filters,
      keyword: value,
    });
    setLastFound(null);
    setSortHistory({});
  };

  return (
    <Grid container className={classes.container}>
      <InputSearch
        classes={classes}
        label="Pesquise Nome, Empresa, Cargo ou Área"
        handleChange={handleChange}
        value={search}
      />
      <Grid item xs={12} md={3}>
        <ListItem className={classes.listItem}>
          <SelectCustom
            name="markets"
            classes={classes}
            handleChange={handleSelectedMarkets}
            options={markets}
            loading={marketsLoading}
            label="Mercados"
            values={selectedMarkets}
          />
        </ListItem>
      </Grid>
    </Grid>
  );
}
