import { useMemo } from 'react';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import { MaterialReactTable, MRT_TableInstance } from 'material-react-table';

export default function MaterialTable({
  table,
}: {
  table: MRT_TableInstance<any>;
}) {
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        ...globalTheme,
        components: {
          ...globalTheme.components,
          MuiInputBase: {
            styleOverrides: {
              root: {
                '& > .MuiInputAdornment-positionStart': {
                  display: 'none',
                },
                '&::after': {
                  display: 'none',
                },
                backgroundColor: '#F1F1F1',
                borderRadius: '6px',
              },
              input: {
                fontSize: '12px',
                padding: '5px 10px',
                height: '23px',
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                  borderBottom: 'none',
                },
              },
              underline: {
                '&:before': {
                  borderBottom: 'none',
                },
                '&:after': {
                  borderBottom: 'none',
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                '& > .Mui-TableHeadCell-Content': {
                  '& > .Mui-TableHeadCell-Content-Labels': {
                    '& > .Mui-TableHeadCell-Content-Wrapper': {
                      whiteSpace: 'nowrap',
                    },
                  },
                  '& > .Mui-TableHeadCell-Content-Actions': {
                    '& > button:nth-of-type(1)': {
                      '& > svg': {
                        visibility: 'hidden',
                      },
                      '&:hover': {
                        '& > svg': {
                          visibility: 'visible',
                        },
                      },
                    },
                    '& > button:nth-of-type(2)': {
                      opacity: 1,
                    },
                  },
                },
              },
              head: {
                justifyContent: table.getState().showColumnFilters
                  ? 'flex-start !important'
                  : 'center',
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                '& .MuiBox-root': {
                  '& > button:nth-of-type(2)': {
                    transform: !table.getIsAllColumnsVisible()
                      ? 'translateX(95%)'
                      : 'none',
                  },
                  '& > button:nth-of-type(3)': {
                    transform: 'translateX(-105%)',
                  },
                },
                '& .Mui-disabled': {
                  display: 'none',
                },
              },
            },
          },
        },
      }),
    [globalTheme, table.getIsAllColumnsVisible()],
  );

  return (
    <ThemeProvider theme={tableTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
