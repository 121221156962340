// material ui components
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px solid black',
    width: '100%',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    flexWrap: 'nowrap',
  },
  phrase: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
  },
  button: {
    margin: '8px 0px',
    backgroundColor: 'black',
    color: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '8px',
    width: '40%',
    '&:hover': {
      backgroundColor: 'black',
      fontWeight: theme.typography.fontWeightBold,
    },
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
}));

export default function CTAAddon({ data }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Box className={classes.contentContainer}>
        <img
          src={
            data?.image?.formats
              ? data?.image?.formats?.thumbnail?.url
              : data?.logo?.url
          }
          alt="CTA"
          width="92px"
        />
      </Box>
      <Box width="75%" className={classes.contentContainer}>
        <Typography className={classes.phrase}>{data.phrase}</Typography>
        <Button
          onClick={() => window.open(data.link)}
          className={classes.button}>{`${data.cta}`}</Button>
      </Box>
    </Grid>
  );
}
