// Material ui components
import {
  Box,
  Divider,
  makeStyles,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
// Typechecking
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';
// Material ui icons

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  reportProblemOutlinedIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function Subtitle({
  title,
  isIncongruitiesAlert,
  hasSelectType,
  isSelectedType,
  handleChangeType,
  titleType1,
  titleType2,
}) {
  const classes = useStyles();
  return (
    <Box my={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" className={classes.title}>
          &mdash;{title}
        </Typography>
        {isIncongruitiesAlert && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            arrow
            title="Alerta para dados com incongruências"
            placement="right">
            <ReportProblemOutlinedIcon
              className={classes.reportProblemOutlinedIcon}
              color="secondary"
              titleAccess="alerta"
            />
          </Tooltip>
        )}
        {hasSelectType && (
          <Box>
            <span>{titleType1}</span>
            <Switch
              color="secondary"
              checked={isSelectedType}
              onChange={handleChangeType}
              inputProps={{ 'aria-label': 'trocar tipo de exibição' }}
              style={{ color: '#000' }}
            />
            <span>{titleType2}</span>
          </Box>
        )}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
}

Subtitle.propTypes = {
  title: PropTypes.string.isRequired,
};
