import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { RadarSegments, State } from 'src/types/radar';

type Props = {
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  filters?: RadarSegments;
  arrayDeadlineOrder: string[];
  isLoading: boolean;
  disabled: boolean;
  classes: any;
};

export default function IndicatorSegments({
  state,
  setState,
  filters,
  arrayDeadlineOrder,
  isLoading,
  disabled,
  classes,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setFilterChecked = (segment: string, option: string) => {
    let checkedSegment = state?.segments?.[segment] ?? [];

    if (checkedSegment.indexOf(option) !== -1) {
      checkedSegment = checkedSegment.filter((selected) => selected !== option);
    } else {
      checkedSegment.push(option);
    }

    setState({
      ...state,
      segments: {
        ...state.segments,
        [segment]: checkedSegment,
      },
    });
  };
  const setAllFilterChecked = (segment: string, options: string[]) => {
    let checkedSegment = state?.segments?.[segment] ?? [];

    if (options?.length === checkedSegment?.length) {
      checkedSegment = [];
    } else {
      checkedSegment = [...options];
    }

    setState({
      ...state,
      segments: {
        ...state.segments,
        [segment]: checkedSegment,
      },
    });
  };
  function getTotalChecked(obj?: typeof state.segments | RadarSegments) {
    if (!obj) {
      return 0;
    }
    return Object.values(obj).reduce((total, item) => {
      if (Array.isArray(item)) {
        return total + item.length;
      } else if (item.options && Array.isArray(item.options)) {
        return total + item.options.length;
      }
      return total;
    }, 0);
  }
  function setAllChecked(
    filters: RadarSegments,
    segments: typeof state.segments,
  ) {
    const allValues: typeof state.segments = {};

    if (getTotalChecked(segments) !== getTotalChecked(filters)) {
      Object.entries(filters).forEach(([key, value]) => {
        allValues[key] = value.options;
      });
    }

    setState({
      ...state,
      segments: allValues,
    });
  }

  return (
    <>
      <Button
        id="lock-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        disabled={disabled}
        sx={(theme) => ({
          color:
            getTotalChecked(state.segments) > 0 &&
            getTotalChecked(state.segments) !== getTotalChecked(filters)
              ? theme.palette.primary.main
              : '#4F4F4F',
        })}>
        Características:{' '}
        {getTotalChecked(state.segments) === getTotalChecked(filters)
          ? 'Todas'
          : getTotalChecked(state.segments) > 0
            ? `+${getTotalChecked(state.segments)}`
            : 'Nenhum'}
      </Button>
      {!disabled && filters && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxHeight: '40dvh',
            },
          }}
          className={classes.optionsBox}
          aria-disabled={disabled}>
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&.Mui-selected': {
                backgroundColor: '#F1F1F1',
              },
            }}
            selected={
              getTotalChecked(state.segments) === getTotalChecked(filters)
            }
            onClick={() => setAllChecked(filters, state.segments)}>
            <Checkbox
              disabled={disabled || isLoading}
              checked={
                getTotalChecked(state.segments) === getTotalChecked(filters)
              }
              inputProps={{ 'aria-label': 'checkbox' }}
              color="secondary"
              className={classes.checkbox}
              size="small"
            />
            <Typography fontSize={14} fontWeight={700} color={'#000'}>
              Todas
            </Typography>
          </MenuItem>
          {Object.entries(filters)?.map((filter) => {
            return (
              <Box key={filter[0]}>
                <MenuItem
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() =>
                    setAllFilterChecked(filter[0], filter[1].options)
                  }>
                  <Checkbox
                    disabled={disabled || isLoading}
                    checked={
                      state?.segments?.[filter[0]]?.length ===
                      filter[1].options.length
                    }
                    inputProps={{ 'aria-label': 'checkbox' }}
                    color="primary"
                    className={classes.checkbox}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={600} color={'#000'}>
                    {filter[1].label}
                  </Typography>
                </MenuItem>
                {filter[0] === 'prazo'
                  ? filter[1].options
                      .sort(
                        (a, b) =>
                          arrayDeadlineOrder.indexOf(a) -
                          arrayDeadlineOrder.indexOf(b),
                      )
                      .map((option, index) => (
                        <MenuItem
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => setFilterChecked(filter[0], option)}>
                          <Checkbox
                            disabled={disabled || isLoading}
                            checked={state?.segments?.[filter[0]]?.includes(
                              option,
                            )}
                            inputProps={{ 'aria-label': 'checkbox' }}
                            color="primary"
                            className={classes.checkbox}
                            size="small"
                          />
                          <Typography
                            fontSize={12}
                            fontWeight={600}
                            color={'#47484C'}>
                            {option}
                          </Typography>
                        </MenuItem>
                      ))
                  : filter[1].options.map((option, index) => (
                      <MenuItem
                        key={index}
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => setFilterChecked(filter[0], option)}>
                        <Checkbox
                          disabled={disabled || isLoading}
                          checked={state?.segments?.[filter[0]]?.includes(
                            option,
                          )}
                          inputProps={{ 'aria-label': 'checkbox' }}
                          color="primary"
                          className={classes.checkbox}
                          size="small"
                        />
                        <Typography
                          fontSize={12}
                          fontWeight={600}
                          color={'#47484C'}>
                          {option}
                        </Typography>
                      </MenuItem>
                    ))}
              </Box>
            );
          })}
        </Menu>
      )}
    </>
  );
}
