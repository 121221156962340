import { useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Create } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useReactToPrint } from 'react-to-print';
import SubTitle from 'src/components/v2/SubTitle';
import { InvestorsTitle } from 'src/types/operation';

import OperationHeader from './OperationHeader';
import { If } from '../../../components/If';
import { PanelLogin } from '../../../components/PanelLogin';
import { useAuthContext } from '../../../context/AuthContextProvider';
import ipanema from '../../../graphql/clients/ipanema';
import { GET_INVESTORS_BY_OPERATION_HASH_ID } from '../../../graphql/queries';
import OperationInvestorsTable from '../OperationInvestorsTable';

type Props = {
  hash: string;
  market: string;
  operationName: string;
};

export default function OperationPrimaryInvestors({
  hash,
  market,
  operationName,
}: Props) {
  const { auth } = useAuthContext();
  const primaryRef = useRef(null);
  const [selectedTitle, setSelectedTitle] = useState('');

  const { data, loading } = useQuery(GET_INVESTORS_BY_OPERATION_HASH_ID, {
    variables: { hash },
    client: ipanema,
    skip: !auth.isLoggedIn,
    onCompleted: ({ getInvestorsByOperationHashId }) => {
      if (getInvestorsByOperationHashId?.length > 0) {
        setSelectedTitle(getInvestorsByOperationHashId[0].title);
      }
    },
  });

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'profile',
        header: 'Tipo de investidor',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'investorsNumber',
        header: 'Número de investidores',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => cell.getValue() ?? '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'amount',
        accessorFn: (row: any) =>
          row.amount?.replaceAll('.', '').replaceAll(',', '.'),
        header: 'Montante Investido (R$)',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue()
            ? `${parseFloat(cell.getValue()).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}`
            : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'titleNumber',
        accessorFn: (row: any) =>
          row.titleNumber?.replace('.', '').replace(',', '.'),
        header: 'Títulos Adquiridos',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue()
            ? parseFloat(cell.getValue()).toLocaleString('pt-BR', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : '-',
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.operacoes_investidores;

  const handlePrint = useReactToPrint({
    content: () => primaryRef.current,
  });

  const extraExportColumns = [
    {
      accessorKey: 'operationName',
      header: 'Nome da Operação',
    },
  ];

  const investorsTitle = data?.getInvestorsByOperationHashId?.find(
    (title: InvestorsTitle) => title.title === selectedTitle,
  );

  const getData = () =>
    investorsTitle?.investors?.length > 0 ? investorsTitle.investors : [];

  return (
    <Box p={4} ref={primaryRef}>
      <SubTitle
        title={`Investidores primários do ${market.toUpperCase()} ${operationName}`}
      />
      <Box>
        {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
          <Box p={2}>
            <PanelLogin small={undefined} message={undefined} />
          </Box>
        ) : (
          <>
            <If condition={loading}>
              <CircularProgress size="1.8rem" color="secondary" />
            </If>
            <If condition={!loading}>
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22.1px',
                }}>
                Perfil dos investidores primários de cada um dos títulos da
                operação no momento da emissão.
              </Typography>
              <If
                condition={
                  hasPermission && !data?.getInvestorsByOperationHashId?.length
                }>
                <Box
                  sx={(theme) => ({
                    padding: theme.spacing(4, 1),
                  })}>
                  <Paper
                    elevation={0}
                    square
                    sx={(theme) => ({
                      background:
                        'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
                      [theme.breakpoints.down('sm')]: {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    })}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          sx={(theme) => ({
                            padding: theme.spacing(6, 6),
                            fontWeight: '500',
                            fontSize: '1.15rem',
                          })}>
                          O perfil dos investidores dessa operação não está
                          disponível. Você dispõe dessa informação e gostaria de
                          compartilhá-la? Clique no ícone{' '}
                          <Create sx={{ fontSize: '1.15rem' }} /> acima.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </If>
              <If condition={data?.getInvestorsByOperationHashId?.length}>
                <OperationHeader
                  titles={data?.getInvestorsByOperationHashId}
                  selectedTitle={selectedTitle}
                  setSelectedTitle={setSelectedTitle}
                />
                <OperationInvestorsTable
                  operationName={operationName}
                  columns={columns}
                  data={getData()}
                  isLoading={loading}
                  indicator={selectedTitle}
                  market={market}
                  handlePrint={handlePrint}
                  hasBottom={false}
                  permission={'operacoes_investidores'}
                  exportPermission={'operacoes_investidores_exportar'}
                  extraExportColumns={extraExportColumns}
                />
              </If>
            </If>
          </>
        )}
      </Box>
    </Box>
  );
}
