import { useEffect, useState } from 'react';

import { Close, Edit } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import _ from 'lodash';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import Section from 'src/components/ProfileSection';
import { Certification, Professional } from 'src/types/professional';

import CertificationsForm from './CertificationsForm';

type Props = {
  certifications?: Certification[];
  professional?: Professional;
  handleUpdate: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
};

export function CertificationsList({
  certifications,
  professional,
  handleUpdate,
  isSuccess,
  isLoading,
}: Props) {
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? certifications?.length : 3;
  const buttonText = showAll ? 'Ver Menos' : 'Ver Mais';
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState<number>(0);
  const [itemToRemove, setItemToRemove] = useState<Certification | undefined>();

  const handleDelete = () => {
    if (professional?.id && itemToRemove?.id) {
      let updatedItems = professional.experiences;
      if (Array.isArray(updatedItems)) {
        updatedItems = [...updatedItems].filter(
          (item: any) => item.id !== itemToRemove?.id,
        );
      }

      const values = _.omit(professional, 'id');
      handleUpdate({
        data: {
          ...values,
          experiences: updatedItems,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setConfirmDialogOpen(false);
      setItemToRemove(undefined);
      setEditDialogOpen(false);
    }
  }, [isSuccess]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <Section
      title="Certificações"
      canEdit={professional?.isOwner}
      isEditing={isEditing}
      setIsEditing={setIsEditing}>
      <Grid container>
        {!!certifications?.length &&
          certifications.slice(0, itemsToShow).map((certification, index) => (
            <Grid
              item
              container
              xs={12}
              key={certification.id}
              sx={{ '&:nth-child(n + 2)': { marginTop: 3 } }}>
              <Grid item xs={10} md={11}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '16px',
                    fontWeight: 600,
                    [theme.breakpoints.down('sm')]: { fontSize: '14px' },
                  })}>
                  {certification.name}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    padding: '10px 0px 16px',
                    fontSize: '14px',
                    [theme.breakpoints.down('sm')]: { fontSize: '12px' },
                  })}>
                  {certification.issuer}
                </Typography>
                {certification.issuedAt && (
                  <Typography fontSize={12} fontWeight={600}>
                    Emitida em
                    <span style={{ textTransform: 'capitalize' }}>
                      {` ${format(
                        parseISO(certification.issuedAt),
                        'MMM/yyyy',
                        {
                          locale: ptBr,
                        },
                      )}`}
                    </span>
                  </Typography>
                )}
              </Grid>
              {isEditing && (
                <Grid item container xs={2} md={1} justifyContent="flex-end">
                  <IconButton
                    sx={{
                      marginRight: 0.5,
                      width: 20,
                      height: 20,
                    }}
                    onClick={() => {
                      setEditItemIndex(index);
                      setEditDialogOpen(true);
                    }}>
                    <Edit
                      sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      width: 20,
                      height: 20,
                    }}
                    onClick={() => {
                      if (certification.id) {
                        setItemToRemove(certification);
                      }
                      setConfirmDialogOpen(true);
                    }}>
                    <Close
                      sx={{ fontSize: '1rem', color: 'rgba(79, 79, 79, 1)' }}
                    />
                  </IconButton>
                </Grid>
              )}
              {index < certifications.slice(0, itemsToShow).length - 1 && (
                <Grid item xs={12}>
                  <Divider sx={{ marginTop: 3 }} />
                </Grid>
              )}
            </Grid>
          ))}
        {certifications?.length && certifications?.length > 3 && (
          <Button
            variant="text"
            color="primary"
            sx={{
              fontWeight: 500,
              marginTop: 2,
              minWidth: 'unset',
              padding: 0,
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
            onClick={toggleShowAll}>
            {buttonText}
          </Button>
        )}
      </Grid>
      <CertificationsForm
        open={editDialogOpen}
        index={editItemIndex}
        handleClose={() => setEditDialogOpen(false)}
        professional={professional}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        submitAction={handleDelete}
        title={`Tem certeza que deseja remover seu certificado “${itemToRemove?.name}”?`}
        subTitle="Ao remover seu certificado, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar um novo certificado dentro do perfil profissional."
        hasActionButton={true}
      />
    </Section>
  );
}
