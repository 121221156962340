import { SyntheticEvent } from 'react';

import { Box, Theme, useMediaQuery } from '@mui/material';
import { format, startOfWeek } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import _ from 'lodash';
import {
  MRT_ColumnDef,
  MRT_FilterFns,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import Export from 'src/pages/AllOperations/Export';
import { getPlansWithPermission } from 'src/services/subscription';
import { convertNumber } from 'src/utils/number';
import { buildNamesInline } from 'src/utils/string';

type Props = {
  hasPermission: boolean;
  columns: MRT_ColumnDef<any>[];
  data: any;
  isLoading: boolean;
  institutionName: string;
  market: string;
  handlePrint?: () => void;
};

export default function OperationsTable({
  hasPermission,
  data,
  columns,
  isLoading,
  institutionName,
  market,
  handlePrint,
}: Props) {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { data: plansToUpgrade, isLoading: plansToUpgradeLoading } = useQuery(
    ['upgradedPlan', 'instituicao_operacoes'],
    () => getPlansWithPermission('instituicao_operacoes'),
    {
      enabled: !hasPermission,
      retry: false,
    },
  );

  const fakeColumns: MRT_ColumnDef<any>[] = Array(3)
    .fill({})
    .map((value, index) => ({
      accessorKey: `column${index + 1}`,
      header: `Coluna${index + 1}`,
    }));

  const fakeDataRow = fakeColumns.reduce((acc, column, index) => {
    return {
      ...acc,
      [column.accessorKey as string]: `Dado ${index + 1}`,
    };
  }, {});

  const fakeRows = Array(isSmall ? 5 : 10).fill(fakeDataRow);

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    filename: `${institutionName}_${market}_operacoes`,
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [...columns]
      .map((c) => `"${c.header}"`)
      .filter((c) => !['"Ação"', '""'].includes(c)),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const headerKeys = [...columns]
      .map((c) => c.accessorKey)
      .filter(
        (c) => !['operation', 'favorite'].includes(c as string),
      ) as string[];
    csvExporter.generateCsv(
      rows.map((row) => {
        return {
          ...headerKeys.reduce((acc, key) => {
            const value = _.get(row.original, key, '');
            return {
              ...acc,
              [key]: _.isFinite(value) ? convertNumber(value) : value,
            };
          }, {}),
        };
      }),
    );
  };

  const parseValue = (id: string, value: string | number): string | number => {
    if (!value) return value;
    switch (id) {
      case 'date':
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value.toString().trim())) {
          value = Number(
            value
              .toString()
              .trim()
              .replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3$2$1'),
          );
        }
        break;
    }
    return value;
  };
  const normalizeFilteredValue =
    (filter: any) =>
    (row: any, id: string, filterValue: string | number, ...args: any) => {
      filterValue = parseValue(id, filterValue);
      return filter(row, id, filterValue, ...args);
    };
  const normalizeFilteredRangeValue =
    (filter: any) =>
    (
      row: any,
      id: string,
      filterValue: [string | number, string | number],
      ...args: any
    ) => {
      const filterArray = filterValue.map((value) => parseValue(id, value));
      return filter(row, id, filterArray, ...args);
    };

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    filterFns: {
      ...MRT_FilterFns,
      between: normalizeFilteredRangeValue(MRT_FilterFns.between),
      betweenInclusive: normalizeFilteredRangeValue(
        MRT_FilterFns.betweenInclusive,
      ),
      contains: normalizeFilteredValue(MRT_FilterFns.contains),
      empty: normalizeFilteredValue(MRT_FilterFns.empty),
      endsWith: normalizeFilteredValue(MRT_FilterFns.endsWith),
      equals: normalizeFilteredValue(MRT_FilterFns.equals),
      greaterThan: normalizeFilteredValue(MRT_FilterFns.greaterThan),
      greaterThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.greaterThanOrEqualTo,
      ),
      lessThan: normalizeFilteredValue(MRT_FilterFns.lessThan),
      lessThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.lessThanOrEqualTo,
      ),
      notEmpty: normalizeFilteredValue(MRT_FilterFns.notEmpty),
      notEquals: normalizeFilteredValue(MRT_FilterFns.notEquals),
      startsWith: normalizeFilteredValue(MRT_FilterFns.startsWith),
    },
    localization: {
      ...MRT_Localization_PT_BR,
      ...MaterialTableDefaultProps.localization,
      noRecordsToDisplay:
        'Ops! Não encontramos operações desta instituição para este mercado',
    },
    columns: hasPermission ? columns : fakeColumns,
    layoutMode: 'grid',
    initialState: {
      sorting: [
        {
          id: 'operation.name',
          desc: false,
        },
      ],
    },
    data: hasPermission ? data : fakeRows,
    muiTableContainerProps: {
      onCopy: (e: SyntheticEvent) => e.preventDefault(),
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
        userSelect: 'none',
        '&::selection, &::-moz-selection': {
          background: 'none',
        },
      }),
    },
    enableRowVirtualization: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: true,
    enablePagination: false,
    enableFilters: true,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiBottomToolbarProps: {
      sx: {
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        minHeight: 'unset',
      },
    },
    state: {
      isLoading,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadCellProps: {
      sx: {
        justifyContent: 'center',
        minHeight: '65px',
      },
    },
    renderBottomToolbarCustomActions: () => (
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          p: '4px',
          width: '100%',
          justifyContent: data?.length > 1 ? 'space-between' : 'flex-end',
        }}>
        {data?.length > 1 && (
          <span style={{}}>{`Total de ${data?.length} linhas`}</span>
        )}
        <span style={{ fontWeight: 'bold' }}>
          {`Atualizado em: ${format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            'dd/MM/yyyy',
          )}`}
        </span>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          width: '100%',
          alignItems: 'flex-start',
          marginLeft: '-7px',
        }}>
        <Box sx={{ display: 'flex' }}>
          <Export
            handleExport={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            handlePrint={handlePrint}
            permission="instituicao_operacoes_exportar"
            hasData={
              !!data?.length && !!table.getPrePaginationRowModel().rows?.length
            }
            pdfEnabled={!!handlePrint}
          />
        </Box>
      </Box>
    ),
  });

  return (
    <Box py={2} aria-roledescription="instituicao_operacoes">
      <Box
        sx={
          !hasPermission
            ? {
                '-webkit-filter': 'blur(5px)',
                pointerEvents: 'none',
              }
            : {}
        }>
        <MaterialTable table={table} />
      </Box>
      {!hasPermission && !plansToUpgradeLoading && (
        <Box position="relative">
          <Box
            sx={(theme) => ({
              width: '100%',
              position: 'absolute',
              top: -450,
              left: 0,
              [theme.breakpoints.down('md')]: {
                top: -300,
              },
              [theme.breakpoints.down('sm')]: {
                top: -400,
              },
            })}>
            <CardDemonstration
              title={`Recurso disponível apenas no(s) plano ${buildNamesInline(
                plansToUpgrade?.data,
              )}`}
              subTitle={
                'Faça um upgrade em sua assinatura para ter acesso às operações desta instituição.'
              }
              labelButton={'Fazer upgrade'}
              url={'/planos'}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
