import * as Yup from 'yup';

const domainRegex =
  /^((http|https):\/\/.)?[-a-zA-Z0-9@:%._+~#=*]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

export const ValidateFormDialog = Yup.object().shape({
  domain: Yup.string()
    .required('Campo obrigatório')
    .matches(domainRegex, 'Domínio inválido'),
});

export const ValidateForm = Yup.object().shape({
  restrictions: Yup.array(
    Yup.object({
      domain: Yup.string()
        .required('Campo obrigatório')
        .matches(domainRegex, 'Domínio inválido'),
    }),
  ),
});
