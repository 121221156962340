import { makeStyles } from '@material-ui/core';

export const AllDocumentsStyle = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  container: {
    margin: theme.spacing(1, 2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  marketSelected: {
    border: 0,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    '&.MuiChip-root:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiChip-root:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  market: {
    border: 0,
    fontWeight: 500,
    boxShadow: theme.shadows[1],
  },
  marketGrid: {
    padding: theme.spacing(1, 0),
  },
  title: {
    fontSize: '2.125rem',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    lineHeight: 1.235,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  headerDivider: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
  customTooltip: {
    maxWidth: '15em',
    fontSize: '12px',
  },
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  itemInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
  notificationBox: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(20, 20),
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    backdropFilter: 'blur(10px)',
  },
  notificationTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: '29px',
  },
  notificationSubTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '17px',
    marginTop: '1rem',
  },
  panelLoginLink: {
    color: theme.palette.secondary.main,
    fontSize: '0.875rem',
    cursor: 'pointer',
    textDecoration: 'unset',

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
}));
