import { useState } from 'react';

import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import PasswordField from 'src/components/FormsUI/PasswordField';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { FieldTitle } from 'src/pages/Register/styles';
import { setBannerMessage } from 'src/store/display';

import { CheckStep } from '../../../../components/CheckStep';
import { Dialog } from '../../../../components/Dialog';
import { If } from '../../../../components/If';
import {
  changeEmail,
  refreshCodeChangeEmail,
  sendConfimationCodeChangeEmail,
} from '../../../../services/user';
import {
  ValidateChangeEmail,
  ValidateConfirmationCode,
} from '../../validations';

type InitialData = {
  password: string;
  newEmail: string;
  code: string;
};

function EditEmailForm({
  currentEmail,
  data,
  setData,
  handleClose,
  handleForm,
}: {
  currentEmail?: string;
  handleClose: () => void;
  handleForm: (status: boolean) => void;
  data: InitialData;
  setData: (data: InitialData) => void;
}) {
  const dispatch = useDispatch();
  const changeEmailRequest = useMutation(changeEmail, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        if (error?.response?.data?.error?.message.includes('Senha')) {
          formik.setErrors({
            password: error?.response?.data?.error?.message,
          });
        } else if (error?.response?.data?.error?.message.includes('E-mail')) {
          formik.setErrors({
            newEmail: error?.response?.data?.error?.message,
          });
        }
      } else {
        dispatch(
          setBannerMessage({
            message:
              'Ocorreu um erro durante a alteração de e-mail, tente novamente',
            type: 'error',
            autoclose: false,
          }),
        );
        formik.resetForm();
        setData({ password: '', newEmail: '', code: '' });
        handleClose();
      }
      handleForm(false);
    },
    onSuccess: () => {
      handleForm(true);
    },
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: ValidateChangeEmail,
    onSubmit: (values) => {
      setData({ ...data, ...values });
      changeEmailRequest.mutate({
        password: values.password,
        newEmail: values.newEmail,
        currentEmail,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <FieldTitle>Senha atual*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <PasswordField
              name="password"
              placeholder="Insira sua senha atual"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password || Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FieldTitle>Novo e-mail*</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="newEmail"
              placeholder="Digite o novo e-mail"
              value={formik.values.newEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.newEmail || Boolean(formik.errors.newEmail)}
              helperText={formik.touched.newEmail && formik.errors.newEmail}
            />
          </FormControl>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 0 10px',
            gap: '24px',
          }}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{ height: 40, fontWeight: 600 }}>
              {changeEmailRequest.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleClose()}
              sx={{ height: 40, fontWeight: 600 }}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

function ActivateEmailForm({
  data,
  handleActivation,
}: {
  handleActivation: (status: boolean) => void;
  data: InitialData;
}) {
  const [error, setError] = useState({ status: false, message: '' });
  const updateAccountEmail = useMutation(sendConfimationCodeChangeEmail, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        formik.setErrors({
          code: error?.response?.data?.error?.message,
        });
      } else {
        setError({
          message:
            'Ocorreu um erro durante a validação de código, tente novamente',
          status: true,
        });
      }
      handleActivation(false);
    },
    onSuccess: () => {
      handleActivation(true);
    },
  });

  const refreshCode = useMutation(refreshCodeChangeEmail);

  const formik = useFormik({
    initialValues: data,
    validationSchema: ValidateConfirmationCode,
    onSubmit: (values) => {
      updateAccountEmail.mutate({
        newEmail: values.newEmail,
        code: values.code,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <FieldTitle>Código de confirmação</FieldTitle>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="code"
              placeholder="Digite o código de confirmação"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </FormControl>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 0 10px',
            gap: '24px',
          }}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{ height: 40, fontWeight: 600 }}>
              {updateAccountEmail.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Confirmar'
              )}
            </Button>
          </Grid>

          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                refreshCode.mutate({
                  newEmail: data.newEmail,
                });
              }}
              sx={{ height: 40, fontWeight: 600 }}>
              {refreshCode.isLoading ? (
                <CircularProgress sx={{ color: '#000' }} />
              ) : (
                'Reenviar código'
              )}
            </Button>
          </Grid>
        </Grid>
        {error.status && (
          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#EB5757',
                textAlign: 'center',
                fontSize: '0.75rem',
              }}>
              {error.message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default function ProfileEmailModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { auth, reloadUser } = useAuthContext();
  const [data, setData] = useState<InitialData>({
    password: '',
    newEmail: '',
    code: '',
  });
  const [steps] = useState([
    {
      label: 'E-mail',
      stepNo: 1,
      status: false,
      componentWhenDone: <Check />,
    },
    {
      label: 'Validação',
      stepNo: 2,
      status: false,
      componentWhenDone: <Check />,
    },
    {
      label: 'Atualização realizada',
      stepNo: 3,
      status: false,
      componentWhenDone: <Check />,
    },
  ]);
  const [currStep, setCurrStep] = useState(steps[0]);

  const handleForm = (status: boolean) => {
    if (status) {
      setCurrStep(steps[1]);
    }
  };
  const handleActivation = (status: boolean) => {
    if (status) {
      setCurrStep(steps[2]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title="Alterar e-mail">
      <DialogContent>
        <Box maxWidth={460} sx={{ margin: '48px auto' }}>
          <CheckStep steps={steps} currStep={currStep} />
        </Box>
        <Grid container>
          <Grid item sm={12} md={12}>
            <If condition={currStep?.stepNo === 1}>
              <EditEmailForm
                currentEmail={auth.user?.email}
                data={data}
                setData={setData}
                handleClose={handleClose}
                handleForm={handleForm}
              />
            </If>
            <If condition={currStep?.stepNo === 2}>
              <Typography fontWeight={700} textAlign="center" marginBottom={4}>
                Consulte sua caixa de entrada no e-mail e digite o código.
              </Typography>
              <ActivateEmailForm
                data={data}
                handleActivation={handleActivation}
              />
            </If>
            <If condition={currStep?.stepNo === 3}>
              <Grid
                container
                sx={(theme) => ({
                  width: '522px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  gap={3}>
                  <Check color="primary" sx={{ fontSize: '60px' }} />
                  <Typography fontSize={18} fontWeight={700} textAlign="center">
                    E-mail alterado com sucesso
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center" pt={6}>
                  <Button
                    onClick={() => {
                      handleClose();
                      setData({ password: '', newEmail: '', code: '' });
                      reloadUser();
                    }}
                    variant="contained"
                    sx={{ height: 40, fontWeight: 600 }}>
                    Concluir
                  </Button>
                </Grid>
              </Grid>
            </If>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
