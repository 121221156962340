import { useEffect, useRef, useState } from 'react';

// Rest client
// material ui components
import { Check } from '@material-ui/icons';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AcceptInviteDialog from 'src/components/AcceptInvite/AcceptInviteDialog';
import ProfessionalForm from 'src/components/SocialProfileDialog/ProfessionalForm';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getMarkets, getSegments } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { useSessionStorage } from 'src/utils/hooks';
import { ScrollToTop } from 'src/utils/scroll';

import { AcceptInvite } from '../../../components/AcceptInvite';
import { CheckStep } from '../../../components/CheckStep';
import { If } from '../../../components/If';
import { getInvitationByToken } from '../../../services/invitation';
import { RegisterForm } from '../RegisterForm';

const INITIAL_VALUES = {
  email: '',
  name: '',
  otherName: '',
  document: '',
  password: '',
  repeatPassword: '',
  acceptTerms: false,
};

export default function Invite() {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef<HTMLDivElement>();
  const { handleLogin, reloadUser } = useAuthContext();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [data, setData] = useSessionStorage(
    '@App:registerData',
    INITIAL_VALUES,
  );
  const searchParams = new URLSearchParams(history.location.search);
  const token = searchParams.get('token') ?? '';

  const [steps] = useState([
    {
      label: 'Criar conta',
      stepNo: 1,
      status: false,
      componentWhenDone: <Check />,
    },
    {
      label: 'Informações essenciais',
      stepNo: 2,
      status: false,
      componentWhenDone: <Check />,
    },
    {
      label: 'Dados profissionais',
      stepNo: 3,
      status: false,
      componentWhenDone: <Check />,
    },
  ]);
  const [currStep, setCurrStep] = useState(steps[1]);

  const {
    data: invitation,
    isLoading,
    isError,
  } = useQuery('invitation', () => getInvitationByToken(token), {
    retry: false,
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'NotFoundError') {
        history.push('/');
        dispatch(
          setBannerMessage({
            message: 'Convite expirado',
            type: 'error',
            autoclose: true,
          }),
        );
      } else {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: true,
          }),
        );
      }
      history.push('/');
    },
    onSuccess: (inv) => {
      setData({
        ...data,
        invitationToken: token,
        email: inv.email,
        name: inv.name,
        otherName: inv.otherName,
        document: inv.document,
        company: inv.company,
        isRegistered: inv.isRegistered,
        subscription: inv.subscription,
        relatedInstitutions: inv.relatedInstitutions,
      });
    },
  });

  useEffect(() => {
    if (data?.id) {
      handleRegister(true);
    }
  }, [data]);

  const { data: markets, isLoading: isLoadingMarkets } = useQuery(
    'markets',
    getMarkets,
  );
  const { data: segments, isLoading: isLoadingSegments } = useQuery(
    'segments',
    getSegments,
  );

  const handleRegister = (status: boolean) => {
    if (status) {
      setCurrStep(steps[2]);
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSetRegisterForm = (values: any) => {
    setData(values);
  };

  const handleFinishedRegister = () => {
    setOpenFeedbackDialog(true);
  };

  const handleClick = () => {
    const jwt = sessionStorage.getItem('@App:tempToken');
    if (jwt) {
      sessionStorage.setItem('@App:token', jwt);
      setOpenFeedbackDialog(false);
      handleLogin(true);
      reloadUser();
      sessionStorage.removeItem('@App:registerData');
      sessionStorage.removeItem('@App:tempToken');
      history.push('/');
    }
  };

  if (isLoading || isLoadingMarkets || isLoadingSegments)
    return <CircularProgress />;

  return (
    <ScrollToTop>
      <Box
        sx={(theme) => ({
          paddingTop: '125px',
          backgroundColor: '#FFFFFF',
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '175px',
          },
        })}
        component="section"
        ref={formRef}>
        {data.isRegistered && !isError && invitation && (
          <AcceptInvite invitation={{ ...invitation, token }} />
        )}
        <If
          condition={
            !isLoading &&
            !isLoadingMarkets &&
            !isLoadingSegments &&
            !data.isRegistered
          }>
          <CheckStep steps={steps} currStep={currStep} />
          <Grid container>
            <Grid item xs={12}>
              {/* Identification step */}
              <If condition={currStep?.stepNo === 2}>
                <RegisterForm
                  data={data}
                  handleSetRegisterForm={handleSetRegisterForm}
                  registerType="private_person"
                />
              </If>
              {/* Email confirmation step */}
              <If condition={currStep?.stepNo === 3}>
                <Box sx={{ maxWidth: '950px', margin: '100px auto' }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography
                        fontSize={28}
                        fontWeight={700}
                        textAlign="center">
                        Dados profissionais
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ProfessionalForm
                        markets={markets.data ?? []}
                        segments={segments.data ?? []}
                        user={data}
                        institution={data?.relatedInstitutions ?? undefined}
                        isInvited={true}
                        handleFinished={handleFinishedRegister}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </If>
            </Grid>
          </Grid>
        </If>
      </Box>
      <AcceptInviteDialog
        open={openFeedbackDialog}
        handleClick={handleClick}
        title="Parabéns! Agora você é membro da plataforma Uqbar"
        subTitle="É muito bom ter você com a gente."
        labelButton="Ir para o início"
      />
    </ScrollToTop>
  );
}
