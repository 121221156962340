import React from 'react';

import { pageData as CRA } from './CRA/data';
import { pageData as CRI } from './CRI/data';
import { pageData as FIDC } from './FIDC/data';
import { pageData as FII } from './FII/data';
import { PageData } from './types';

export type ProductPagedata = {
  [k: string]: PageData[];
};

export const pageData: ProductPagedata = {
  CRA,
  CRI,
  FIDC,
  FII,
};

export const setIndexes = (data: PageData) => {
  const ref = React.createRef();

  return { ...data, ref };
};
