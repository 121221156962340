import { Professional } from 'src/types/professional';

import * as AuthorInfo from './styles';

interface ArticleProps {
  link: string;
  author?: Professional;
}

export default function ArticleAuthorInfo({ author, link }: ArticleProps) {
  return (
    <AuthorInfo.Wrapper>
      <AuthorInfo.Profile>
        <AuthorInfo.ProfileImage src={author?.photo?.url} />
      </AuthorInfo.Profile>
      <AuthorInfo.Content>
        <AuthorInfo.AuthorName href={link} target="_blank">
          {author?.fullName}
        </AuthorInfo.AuthorName>
        <AuthorInfo.AuthorBio>{author?.profileText}</AuthorInfo.AuthorBio>
      </AuthorInfo.Content>
    </AuthorInfo.Wrapper>
  );
}
