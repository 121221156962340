import { CircularProgress } from '@material-ui/core';

import Subtitle from '../../../../../components/Subtitle';
import TableLiabilityOperation from '../../../../../components/TableLiabilityOperation';

export default function OperationCRALiabilityTitle({ data, loading }) {
  if (loading) return <CircularProgress color="secondary" />;

  return (
    <div>
      <Subtitle title="Títulos" />
      <TableLiabilityOperation market="CRA" rows={data} />
    </div>
  );
}
