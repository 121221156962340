import { useEffect, useState } from 'react';

import { Box, SelectChangeEvent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import OperationCharts from 'src/components/OperationCharts';
import SubTitle from 'src/components/v2/SubTitle';
import { CraWalletPDD } from 'src/types/operation';
import {
  calculatePercentageValue,
  convertNumber,
  convertValue,
} from 'src/utils/number';

type Props = {
  data: CraWalletPDD[] | undefined;
  selectOptions: string[] | undefined;
  operationName: string;
  hasPermission: boolean | undefined;
};

function OperationCraAssetWallet({
  data,
  selectOptions,
  operationName,
  hasPermission,
}: Props) {
  const [period, setPeriod] = useState('');
  const market = 'cra';
  const indicator = 'carteira de recebíveis';
  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string);
  };

  useEffect(() => {
    if (selectOptions?.length) {
      setPeriod(selectOptions[0]);
    }
  }, [selectOptions]);

  const handleExport = () => {
    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      filename: `${operationName}_${market}_${indicator}`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Data de referência',
        'Direitos creditórios',
        'A vencer com parcelas em atraso',
        'A vencer',
        'Vencidos',
        'Nome da operação',
      ],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (data) {
      const dataToExport = data.map((row: CraWalletPDD) => ({
        dataReferencia: format(
          parse(row.dataReferencia, 'yyyy-MM-dd', new Date()),
          'MM/yyyy',
        ),
        direitosCreditorios: convertNumber(row.direitosCreditorios ?? 0),
        creditosAVencerComParcelasAtraso: convertNumber(
          row.creditosAVencerComParcelasAtraso ?? 0,
        ),
        creditosAVencerSemParcelasAtraso: convertNumber(
          row.creditosAVencerSemParcelasAtraso ?? 0,
        ),
        creditosVencidos: convertNumber(row.creditosVencidos ?? 0),
        operationName,
      }));

      csvExporter.generateCsv(dataToExport);
    }
  };

  const selectedData = data?.find((op) => op.dataReferencia === period);

  const series = [
    {
      name: 'A vencer com parcelas em atraso',
      data: [selectedData?.creditosAVencerComParcelasAtraso],
    },
    {
      name: 'A vencer',
      data: [selectedData?.creditosAVencerSemParcelasAtraso],
    },
    {
      name: 'Vencidos',
      data: [selectedData?.creditosVencidos],
    },
  ];

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: 'receivablesCraWallet',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      stacked: true,
      width: '100%',
      height: 70,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '25%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) =>
        `${convertNumber(calculatePercentageValue(value, selectedData?.direitosCreditorios, 2))}%`,
    },
    noData: {
      text: 'Nenhum dado encontrado',
    },
    yaxis: {
      max: Math.round(selectedData?.direitosCreditorios ?? 0),
    },
    colors: ['#F28181', '#0099FF', '#FDA85E'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      show: true,
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        formatter: (value) =>
          `${convertNumber(calculatePercentageValue(value, selectedData?.direitosCreditorios, 2))}% (${convertValue(value)})`,
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <Box>
      <SubTitle title="Carteira de recebíveis" />
      {hasPermission ? (
        <OperationCharts
          title={
            <>
              Direitos creditórios{' '}
              <span style={{ fontWeight: 600 }}>
                {convertValue(selectedData?.direitosCreditorios ?? 0)}
              </span>
            </>
          }
          valueSelect={period}
          handleChangeSelect={handleChange}
          type="bar"
          series={series}
          options={options}
          selectOptions={selectOptions}
          permission="operacoes_cra_carteira_de_recebiveis_e_pdd_exportar"
          handleExport={handleExport}
          hasData={data && data?.length > 0}
          enabledExport={true}
        />
      ) : null}
    </Box>
  );
}

export default OperationCraAssetWallet;
