import { Badge, styled as MuiStyled } from '@mui/material';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 204px;
  object-fit: cover;

  @media screen and (max-width: 425px) {
    height: 120px;
  }
`;

export const StyledBadge = MuiStyled(Badge)(({ theme }) => ({
  width: '100%',
  '& .MuiBadge-badge': {
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    bottom: 0,
    right: '4%',
  },
}));
