import { useMemo } from 'react';

import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { findProfessionalById } from 'src/services/professional';

import ArticleAuthorInfo from './AuthorInfo';
import ArticleAccordionSummary from './Summary';
import * as Article from './styles';
import { withLinks } from '../../link-mapper';
import { ArticleProps } from '../../types';
import { getArticleAuthorId } from '../../utils';

export default function YearbookArticle({
  article,
  active,
  select,
}: ArticleProps) {
  const authorId = useMemo(() => {
    return getArticleAuthorId(article.authorLink);
  }, [article.authorLink]);

  const { data: author } = useQuery(['article-author', authorId], () =>
    findProfessionalById(authorId),
  );

  const getAdvertisementImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2024/banners/${imageName}.png`);
  };

  return (
    <Article.Accordion>
      <ArticleAccordionSummary
        author={{ link: article.authorLink, name: author?.fullName ?? '' }}
        select={select}
        article={article}
        active={active}
      />
      <If condition={active}>
        <Article.ContentWrapper>
          {article.ads.image && (
            <Article.Ads
              href={article.ads.link}
              image={getAdvertisementImage(article.ads.image).default}
              target="_blank"
            />
          )}
          <Article.Content>
            {article.texts.map((text) => (
              <Article.ContentParagraph
                key={article.title}
                dangerouslySetInnerHTML={{ __html: withLinks(text) }}
              />
            ))}
          </Article.Content>
          <ArticleAuthorInfo author={author} link={article.authorLink} />
        </Article.ContentWrapper>
      </If>
    </Article.Accordion>
  );
}
