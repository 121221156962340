import React from 'react';

import LateralMenu from './Lateral';
import VerticalMenu from './Vertical';
import { MenuProps } from '../../types';

const Menu = (props: MenuProps) => {
  return (
    <>
      <LateralMenu {...props} />
      <VerticalMenu {...props} />
    </>
  );
};

export default Menu;
