import { ReactNode } from 'react';

import { Box } from '@mui/material';

export default function Detail({
  icon,
  children,
}: {
  icon: ReactNode;
  children: ReactNode;
}) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        columnGap: '20px',
        padding: '0 4px',
        '& > svg': {
          fontSize: '25px',
          color: '#4F4F4F',
        },
        [theme.breakpoints.down('sm')]: {
          columnGap: '14px',
        },
      })}>
      {icon}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}>
        {children}
      </Box>
    </Box>
  );
}
