import theme from 'src/theme';
import styled from 'styled-components';

interface AdvertisementProps {
  image?: string;
}

export const Advertisement = styled.a<AdvertisementProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 380px;
  margin-top: 12rem;
  background-color: #767676;
  background-image: url(${(props) => props.image});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;

  &::after {
    content: 'Publicidade';
    position: absolute;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    top: 0;
    right: 0;

    border-bottom-left-radius: 20px;
    font-weight: 400;
    color: #767676;
    background-color: white;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 8rem !important;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 4rem !important;
    height: 180px;
  }
`;
