import React from 'react';

import ArrowDownIcon from './ArrowDownIcon';
import * as S from './styles';
import { If } from '../../../../../components/If';
import { MenuPlaceholder } from '../layout-elements';

type Props = {
  title?: string;
  menuOpened?: boolean;
};

const SectionMarker = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    const position = ref.current?.offsetTop;
    const height = ref.current?.offsetHeight;
    if (position && height) {
      const scrollPosition = position + height;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <S.Wrapper
      ref={ref}
      onClick={scrollDown}
      color={props.title ? 'gray' : 'default'}>
      <MenuPlaceholder open={!!props.menuOpened} />
      <div>
        <ArrowDownIcon />
        <If condition={props.title}>
          <span>{props.title}</span>
        </If>
      </div>
    </S.Wrapper>
  );
};

export default SectionMarker;
