import { ReactNode, useState } from 'react';

import {
  ArrowDownward,
  ArrowForward,
  Business,
  Call,
  MarkEmailRead,
  Message,
  Person,
  Place,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import MetaTags from 'src/components/Seo/MetaTags';
import { ServiceSendMessageDialog } from 'src/components/ServiceSendMessageDialog';
import SubHeader from 'src/components/UI/SubHeader/SubHeader';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { SegmentsAndMarkets } from 'src/pages/Institution/SegmentsAndMarkets';
import Services from 'src/pages/Institution/Services';
import { findServiceById } from 'src/services/institution';
import { getSmallestImageUrl } from 'src/utils/image';
import { convertValue } from 'src/utils/number';
import { ScrollToTop } from 'src/utils/scroll';

const Instruction = ({ title, icon }: { title: string; icon: ReactNode }) => {
  return (
    <Box
      sx={() => ({
        backgroundColor: '#FFFFFF',
        position: 'relative',
        border: '1px solid var(--gray-5, #E0E0E0)',
        borderRadius: '5px',
        padding: '12px',
        width: '210px',
        height: '160px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: 2,
      })}>
      <IconButton
        sx={{
          marginTop: 2,
          backgroundColor: '#FF8211',
          cursor: 'default',
          '&:hover': {
            backgroundColor: '#FF8211',
          },
        }}>
        {icon}
      </IconButton>
      <Typography fontSize={16} fontWeight={600} textAlign="center">
        {title}
      </Typography>
    </Box>
  );
};

export default function MarketPlaceItemDetails() {
  const { auth } = useAuthContext();
  const { hashId } = useParams<{ hashId: string }>();
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const { data } = useQuery(['service', hashId], () => findServiceById(hashId));

  return (
    <ScrollToTop>
      <MetaTags
        title={data?.data?.title}
        description={
          data?.data?.description ||
          `Encontre as principais informações sobre o ${data?.data?.type.toLowerCase()} ${data?.data?.title}`
        }
      />
      <SubHeader
        title=""
        subTitle=""
        breadcrumbs1={{
          title: 'Marketplace',
          link: `/marketplace/${data?.data?.type === 'Serviço' ? 'servicos' : 'projetos'}`,
        }}
        breadcrumbs2="Ver mais"
      />
      <Box py={1}>
        <Section>
          <Grid container md={12}>
            <Grid item sx={{ flexBasis: '10%' }}>
              {data?.data?.institution ? (
                <>
                  {data?.data?.institution?.logo ? (
                    <Avatar
                      variant="square"
                      imgProps={{
                        sx: {
                          objectFit: 'contain',
                          backgroundColor: 'white',
                        },
                      }}
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: '5px solid #FAFAFA',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}
                      src={getSmallestImageUrl(data?.data?.institution?.logo)}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: `5px solid ${theme.palette.background.paper}`,
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}>
                      <Business sx={{ fontSize: '94px' }} />
                    </Avatar>
                  )}
                </>
              ) : (
                <>
                  {data?.data?.professional?.photo ? (
                    <Avatar
                      variant="circular"
                      imgProps={{
                        sx: {
                          objectFit: 'contain',
                          backgroundColor: 'white',
                        },
                      }}
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: '5px solid #FAFAFA',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}
                      src={getSmallestImageUrl(data?.data?.professional?.photo)}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: `5px solid ${theme.palette.background.paper}`,
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}>
                      <Person sx={{ fontSize: '94px' }} />
                    </Avatar>
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              item
              flexDirection="column"
              justifyContent="center"
              sx={(theme) => ({
                flexBasis: '76%',
                paddingLeft: '26px',
                [theme.breakpoints.down('sm')]: {
                  flexBasis: '100%',
                },
              })}>
              <Grid
                item
                container
                sx={{
                  marginBottom: '16px',
                }}>
                <Typography
                  sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                    lineHeight: '35.2px',
                  }}>
                  {data?.data?.title}
                </Typography>
                <Box
                  sx={{
                    marginLeft: 1,
                  }}>
                  <FavoriteButton type="service" />
                </Box>
              </Grid>
              <Grid>
                <Link
                  href={
                    data?.data?.type === 'Serviço'
                      ? `/instituicao/${data?.data?.institution?.slug}/${data?.data?.institution?.id}`
                      : `/profissional/${data?.data?.professional?.slug}/${data?.data?.professional?.id}`
                  }
                  color="#FF8211"
                  underline="always"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 600,
                  }}>
                  {data?.data?.institution?.fantasyName ??
                    data?.data?.professional?.fullName}
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-end"
              sx={(theme) => ({
                position: 'absolute',
                width: 'fit-content',
                top: '36px',
                right: '26px',
                [theme.breakpoints.down('md')]: {
                  top: '90px',
                },
                [theme.breakpoints.down('sm')]: {
                  top: '72px',
                },
              })}
              padding="5px">
              {!data?.data?.isOwner && (
                <CustomTooltip
                  title={
                    !auth?.isLoggedIn
                      ? 'Faça seu login.'
                      : auth?.user?.subscription?.plan?.type === 'free'
                        ? 'Funcionalidade não disponível, faça upgrade.'
                        : ''
                  }
                  placement="top">
                  <span>
                    <Button
                      variant="contained"
                      onClick={() => setOpenSendMessage(true)}
                      sx={{ fontSize: '14px' }}
                      disabled={
                        data?.data?.type === 'Serviço'
                          ? !auth?.isLoggedIn
                          : auth?.user?.subscription?.plan?.type === 'free'
                      }>
                      Tenho interesse
                    </Button>
                  </span>
                </CustomTooltip>
              )}
            </Grid>
          </Grid>
        </Section>
        <If
          condition={
            !!data?.data?.segments?.length || !!data?.data?.markets?.length
          }>
          <SegmentsAndMarkets
            segments={data?.data?.segments}
            markets={data?.data?.markets}
          />
        </If>
        <Section title={`Sobre este ${data?.data?.type?.toLowerCase()}`}>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
            }}
            dangerouslySetInnerHTML={{
              __html: data?.data?.description,
            }}
          />
          <Typography marginTop={2} fontSize={16}>
            <strong style={{ fontWeight: 600 }}>Faixa de preço:</strong>
            {data?.data?.fromPriceRange
              ? ` ${convertValue(data?.data?.fromPriceRange)} até ${convertValue(data?.data?.toPriceRange)}`
              : ' Valores a consultar'}
          </Typography>
        </Section>
        {data?.data?.institution?.services?.length ||
        data?.data?.professional?.services?.length ? (
          <Services
            data={{
              services:
                data?.data?.institution?.services ??
                data?.data?.professional?.services,
              name:
                data?.data?.institution?.fantasyName ??
                data?.data?.professional?.fullName,
              image:
                data?.data?.institution?.logo ??
                data?.data?.professional?.photo,
              ownerType: data?.data?.institution
                ? 'institution'
                : 'professional',
            }}
            title={
              data?.data?.institution
                ? 'Serviços e projetos relacionados desta empresa'
                : 'Projetos relacionados ao profissional'
            }
          />
        ) : null}
        <Section
          title={`${data?.data?.institution ? 'Instituição' : 'Profissional'} responsável`}>
          <Grid container md={12}>
            <Grid item sx={{ flexBasis: '10%' }}>
              {data?.data?.institution ? (
                <>
                  {data?.data?.institution?.logo ? (
                    <Avatar
                      variant="square"
                      imgProps={{
                        sx: {
                          objectFit: 'contain',
                          backgroundColor: 'white',
                        },
                      }}
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: '5px solid #FAFAFA',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}
                      src={getSmallestImageUrl(data?.data?.institution?.logo)}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: `5px solid ${theme.palette.background.paper}`,
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}>
                      <Business sx={{ fontSize: '94px' }} />
                    </Avatar>
                  )}
                </>
              ) : (
                <>
                  {data?.data?.professional?.photo ? (
                    <Avatar
                      variant="circular"
                      imgProps={{
                        sx: {
                          objectFit: 'contain',
                          backgroundColor: 'white',
                        },
                      }}
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: '5px solid #FAFAFA',
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}
                      src={getSmallestImageUrl(data?.data?.professional?.photo)}
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      sx={(theme) => ({
                        width: '120px',
                        height: '120px',
                        border: `5px solid ${theme.palette.background.paper}`,
                        [theme.breakpoints.down('sm')]: {
                          width: '120px',
                          height: '120px',
                        },
                      })}>
                      <Person sx={{ fontSize: '94px' }} />
                    </Avatar>
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              item
              flexDirection="column"
              justifyContent="center"
              sx={(theme) => ({
                flexBasis: '76%',
                paddingLeft: '26px',
                [theme.breakpoints.down('sm')]: {
                  flexBasis: 'auto',
                  paddingLeft: 0,
                },
              })}>
              <Grid marginBottom="20px">
                <Link
                  href={
                    data?.data?.type === 'Serviço'
                      ? `/instituicao/${data?.data?.institution?.slug}/${data?.data?.institution?.id}`
                      : `/profissional/${data?.data?.professional?.slug}/${data?.data?.professional?.id}`
                  }
                  color="#FF8211"
                  underline="always"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 600,
                  }}>
                  {data?.data?.institution?.fantasyName ??
                    data?.data?.professional?.fullName}
                </Link>
              </Grid>
              <Grid item>
                <Box
                  display="flex"
                  sx={{ '&:nth-child(n + 2)': { marginTop: 2 } }}>
                  {(data?.data?.institution?.addresses?.length ||
                    data?.data?.professional?.location) && (
                    <>
                      <IconButton
                        sx={{
                          marginRight: 1,
                          width: 26,
                          height: 26,
                          cursor: 'default',
                        }}>
                        <Place sx={{ fontSize: '1.5rem', color: 'black' }} />
                      </IconButton>
                      <Box display="flex" flexDirection="column">
                        {data?.data?.institution?.addresses?.length > 0 ? (
                          <>
                            <Typography>
                              {`${data?.data?.institution?.addresses[0].line1}${data?.data?.institution.addresses[0].number ? `, ${data?.data?.institution.addresses[0].number}` : ''}`}
                            </Typography>
                            <Typography>
                              {`${data?.data?.institution?.addresses[0].city ?? ''} ${
                                data?.data?.institution?.addresses[0].state
                                  ? `- ${data?.data?.institution?.addresses[0].state}`
                                  : ''
                              }`}
                            </Typography>
                          </>
                        ) : (
                          <Typography>
                            {data?.data?.professional?.location}, Brasil
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid
                container
                item
                justifyContent="flex-end"
                sx={{ flexBasis: '10%' }}>
                {data?.data?.institution ? (
                  <Business
                    sx={{
                      fontSize: '250px',
                      color: '#F1F1F1',
                      position: 'absolute',
                      top: 0,
                      right: '20px',
                    }}
                  />
                ) : (
                  <Person
                    sx={{
                      fontSize: '250px',
                      color: '#F1F1F1',
                      position: 'absolute',
                      top: 0,
                      right: '20px',
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Section>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '100px 0',
          }}>
          <Typography
            sx={(theme) => ({
              fontSize: '20px',
              fontWeight: 600,
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                marginBottom: 2,
              },
            })}>
            Como funciona o Uqbar Marketplace?
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 6,
              gap: 2,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            })}>
            <Instruction
              title="Envie uma proposta"
              icon={<Message sx={{ color: 'white' }} />}
            />
            {isMobile ? (
              <ArrowDownward
                sx={{
                  color: '#BDBDBD',
                  fontSize: '32px',
                }}
              />
            ) : (
              <ArrowForward
                sx={{
                  color: '#BDBDBD',
                  fontSize: '32px',
                }}
              />
            )}
            <Instruction
              title={
                data?.data?.type === 'Serviço'
                  ? 'O dono da oferta de serviço é notificado'
                  : 'O dono da demanda de projeto é notificado'
              }
              icon={<MarkEmailRead sx={{ color: 'white' }} />}
            />
            {isMobile ? (
              <ArrowDownward
                sx={{
                  color: '#BDBDBD',
                  fontSize: '32px',
                }}
              />
            ) : (
              <ArrowForward
                sx={{
                  color: '#BDBDBD',
                  fontSize: '32px',
                }}
              />
            )}
            <Instruction
              title="Caso tenha interesse, entra em contato"
              icon={<Call sx={{ color: 'white' }} />}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={(theme) => ({
              padding: '0 220px',
              fontSize: '16px',
              textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                padding: 0,
              },
            })}>
            Todas as tratativas de negócio são realizadas fora da plataforma
            Uqbar, ainda está com dúvidas? Acesse nosso&nbsp;
            <Link href="/faq" color="#FF8211" underline="always">
              FAQ aqui.
            </Link>
          </Typography>
        </Box>
        <ServiceSendMessageDialog
          service={data?.data}
          open={openSendMessage}
          onClose={() => setOpenSendMessage(false)}
        />
      </Box>
    </ScrollToTop>
  );
}
