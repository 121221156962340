import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import OperationWalletTable from 'src/pages/Operation/OperationWalletTable';
import { getFIIWalletCRICRA } from 'src/services/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  operationCnpj: string;
  setHasData: Dispatch<
    SetStateAction<{
      cri: boolean;
      fii: boolean;
      fidc: boolean;
      fiiProperty: boolean;
    }>
  >;
};

export default function WalletCRICRA({ operationCnpj, setHasData }: Props) {
  const { auth } = useAuthContext();

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'institution',
        header: 'Securitizadora',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <Link
              component={RouterLink}
              to={`/instituicao/${cell.getValue().slug}/${cell.getValue().id}`}>
              {cell.getValue().fantasyName}
            </Link>
          ) : (
            'Securitizadora inexistente'
          ),
      },
      {
        accessorKey: 'emissaoTratada',
        header: 'Emissão',
      },
      {
        accessorKey: 'serieTratada',
        header: 'Série',
      },
      {
        accessorKey: 'quantidade',
        header: 'Quantidade',
        accessorFn: (row) => parseInt(row.quantidade),
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertNumber(cell.getValue()) : '-',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
      },
      {
        accessorKey: 'operation',
        header: 'Ação',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <Link
              component={RouterLink}
              to={`/operacao/${cell.getValue().market}/${
                cell.getValue().slug
              }/${cell.getValue().operationId}`}>
              Ver operação
            </Link>
          ) : (
            'Operação não encontrada'
          ),
      },
    ],
    [],
  );

  const { data, isLoading } = useQuery(
    ['operation-wallet-cri-cra', operationCnpj],
    () =>
      getFIIWalletCRICRA({
        cnpj: operationCnpj,
      }),
    {
      onSuccess: (data) => {
        setHasData((prev) => ({
          ...prev,
          cri: data.length > 0,
        }));
      },
      enabled: !!operationCnpj,
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const getData = () => (data?.length > 0 ? data : []);

  const hasPermission =
    auth?.user?.subscription?.plan?.permissions?.operacoes_fii_ativo_carteira;

  if (!data?.length) return null;

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '22px',
          marginLeft: 1,
        }}>
        CRI/CRA
      </Typography>
      <OperationWalletTable
        hasPermission={!!hasPermission}
        data={getData()}
        columns={columns}
        isLoading={isLoading}
      />
    </Box>
  );
}
