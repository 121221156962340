import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import { useAuthContext } from '../../../context/AuthContextProvider';
import { convertValue } from '../../../utils/number';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '0.875rem',
    color: '#6D6E71',
    lineHeight: '16.94px',
  },
  value: {
    fontSize: '1.125rem',
    lineHeight: '21.78px',
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0, 4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  item: {
    marginTop: '1.125rem',
  },
}));

const Item = ({ classes, title, value }) => {
  return (
    <Box className={classes.item}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};

export const StatisticsResume = ({
  data,
  state,
  showStatistics,
  setShowStatistics,
  numberCriterias,
  valueLabel,
}) => {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const hasPermission =
    auth?.user?.subscription?.plan?.permissions['rankings_completo'];

  const totalAmount = data?.reduce((acc, curr) => acc + curr.value, 0);
  const totalPercentage = data?.reduce((acc, curr) => acc + curr.percentage, 0);
  const top3 = data
    ?.filter((filter) => filter.position < 4)
    ?.reduce((acc, curr) => acc + curr.percentage, 0);

  return (
    <Box className={classes.container}>
      <Item
        classes={classes}
        title="Total"
        value={
          numberCriterias.includes(state.criteria)
            ? totalAmount
            : valueLabel.includes('Montante')
              ? `${convertValue(totalAmount, null, true, 'currency')}`
              : `${convertValue(totalAmount, null, false, 'decimal')}`
        }
      />
      <Item
        classes={classes}
        title="Número de ranqueados"
        value={data?.length}
      />
      <Item
        classes={classes}
        title="Concentração (top 3)"
        value={convertValue(top3 / totalPercentage, null, false, 'percent')}
      />
      <Button
        variant="text"
        color="secondary"
        onClick={() => setShowStatistics((prev) => !prev)}
        disabled={!hasPermission}>
        {showStatistics ? 'Ver ranking' : 'Ver histórico'}
      </Button>
    </Box>
  );
};
