import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { ExportToCsv } from 'export-to-csv';
import WidgetFormDialog from 'src/pages/Widget/WidgetFormDialog';

import ClipboardIcon from '../../../components/Icons/ClipboardIcon';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { convertNumber } from '../../../utils/number';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    fontSize: '0.875rem',
    color: '#6D6E71',
    fontWeight: 700,
  },
  box: {
    width: 160,
  },
  item: {
    fontSize: '1rem',
    fontWeight: 500,
    padding: theme.spacing(2, 3),
  },
  icon: {
    '& > *:first-child': {
      fontSize: '30px',
    },
  },
  paper: {
    margin: theme.spacing(4, 0, 2, 0),
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderStyle: 'solid',
    borderColor: theme.palette.grey.A100,
    borderWidth: 1,
    borderRadius: 5,
  },
  input: {
    margin: theme.spacing(0, 1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  boxIframe: {
    '& iframe': {
      width: '100%',
      height: 400,
      borderStyle: 'solid',
      borderColor: theme.palette.grey.A100,
      borderWidth: 1,
      borderRadius: 8,
    },
  },
  button: {
    '@media print': {
      display: 'none',
    },
  },
}));

const MenuItemCustom = ({ classes, disabled, label, handleAction }) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener={!disabled}
      disableHoverListener={!disabled}
      arrow
      title={'Faça o upgrade do seu plano para utilizar essa funcionalidade'}
      placement="top"
      classes={{ tooltip: classes.tooltip }}>
      <span>
        <MenuItem
          disabled={disabled}
          className={classes.item}
          onClick={() => handleAction()}>
          {label}
        </MenuItem>
      </span>
    </Tooltip>
  );
};

function ExportSelect({
  hasPermission,
  data,
  state,
  valueLabel,
  criteriaLabel,
  rankingLabel,
  handlePrint,
}) {
  const classes = useStyles();

  const { auth } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [item, setItem] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const exportWidget = auth?.user?.widget?.key
    ? `<iframe src="${window.location.origin}/embed/${auth?.user?.widget?.key}/rankings${window.location.search}" referrerpolicy="origin" frameborder="0" width="640" height="500"></iframe>`
    : null;

  const handleWidget = () => {
    setAnchorEl(false);
    setItem('widget');
  };

  const exportToCSV = () => {
    const headers = ['#', 'Nome', 'Percentual (%)', valueLabel];

    const options = {
      fieldSeparator: ',',
      showLabels: true,
      filename: `${state.market}_${rankingLabel}_${criteriaLabel}_${state.year}`,
      headers,
    };

    const dataToExport = data.map((rank) => ({
      position: rank.position,
      name: rank.name,
      percentage: convertNumber(rank.percentage / 100, 4, 4),
      value: convertNumber(rank.value, 2, 2),
    }));

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        disabled={!hasPermission}
        className={classes.button}
        classes={{ iconSizeMedium: classes.icon }}
        startIcon={<GetAppRoundedIcon color="secondary" />}>
        <Typography className={classes.exportButton}>Exportar</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.box}>
          <MenuItemCustom
            classes={classes}
            disabled={
              !auth?.user?.subscription?.plan?.permissions?.rankings_export
            }
            label="CSV"
            handleAction={exportToCSV}
          />
          <MenuItemCustom
            classes={classes}
            disabled={
              !auth?.user?.subscription?.plan?.permissions?.rankings_export
            }
            label="PDF"
            handleAction={handlePrint}
          />
          <MenuItemCustom
            classes={classes}
            disabled={!auth?.user?.subscription?.plan?.permissions?.widget}
            label="Widget"
            handleAction={handleWidget}
          />
        </Box>
      </Popover>
      {auth?.user?.subscription?.plan?.permissions?.widget &&
        item === 'widget' && (
          <Dialog
            id="export-widget"
            open={true}
            onClose={() => setItem(null)}
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ padding: '16px 8px' }}>
              <IconButton onClick={() => setItem(null)}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {!auth?.user?.widget?.key && (
                <WidgetFormDialog onClose={() => setItem(null)} />
              )}
              {auth?.user?.widget?.key && (
                <>
                  <Typography variant="subtitle2" align="center">
                    Quer embutir os Rankings Uqbar no seu site?
                  </Typography>
                  <Box component="form" className={classes.paper}>
                    <InputBase
                      className={classes.input}
                      value={exportWidget}
                      fullWidth
                      readOnly
                    />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <Tooltip
                      title="Clique aqui para copiar"
                      open={true}
                      arrow
                      placement="top-end">
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        onClick={() =>
                          navigator.clipboard.writeText(exportWidget)
                        }>
                        <ClipboardIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary">
                    Ao incorporar o conteúdo da Uqbar em seu site ou aplicativo
                    você concorda com os Termos de Uso e a Política de
                    Privacidade da Uqbar
                  </Typography>
                  <Box
                    mt={2}
                    className={classes.boxIframe}
                    dangerouslySetInnerHTML={{ __html: exportWidget }}
                  />
                </>
              )}
            </DialogContent>
          </Dialog>
        )}
    </>
  );
}

export default ExportSelect;
