/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';

import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { DataContext } from '../../../../context/DataContext';
import Benchmark from '../Benchmark';

const InterestMenu = ({
  formik,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  handleChangeMenu,
}) => {
  const { enableFields, expanded, timeMeasures, securityTypes } =
    useContext(DataContext);
  const [title, setTitle] = useState('Carregando...');

  useEffect(() => {
    if (formik.values.type) {
      securityTypes?.map((type) => {
        formik.values.type === type.key && setTitle(type.labels.interest);
      });
    }
  }, [formik.values.type, securityTypes]);

  return (
    <Accordion
      expanded={expanded === 'panel4'}
      onChange={handleChangeMenu('panel4')}
      disabled={!enableFields}>
      <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Typography component={'span'}>
          <div className="item">
            <TrendingUpIcon />
            4. {title}
            <Tooltip title={title}>
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="space-between">
          <Grid item style={{ width: '47%' }}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Carência *"
                value={formik.values.payment.interest_start || 1}
                onChange={(event) => {
                  formik.setFieldValue(
                    'payment.interest_start',
                    event.target.value,
                  );
                }}
                type="number"
                InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                inputProps={{
                  min: 1,
                  max: 100,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '47%' }}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={formik.values.payment.interest_step || 1}
                onChange={(event) =>
                  formik.setFieldValue(
                    'payment.interest_step',
                    event.target.value,
                  )
                }
                label="Frequência *"
                InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                type="number"
                inputProps={{ min: 0, max: 100 }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '47%', marginTop: '1rem' }}>
            <Autocomplete
              fullWidth
              size="small"
              disableClearable
              onBlur={formik.handleBlur}
              value={
                (formik.values.payment.model &&
                  timeMeasures?.find(
                    (timeMeasure) =>
                      timeMeasure.key ===
                      formik.values.payment?.interest_time_measure,
                  )) ||
                ''
              }
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'payment.interest_time_measure',
                  newValue.key,
                );
              }}
              options={timeMeasures ? timeMeasures : []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Base de cálculo"
                  InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                />
              )}
            />
          </Grid>
          <Grid item style={{ width: '47%', marginTop: '0.5rem' }}>
            <Benchmark formikObj={formik} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InterestMenu;
