function descendingComparator(a, b, orderBy) {
  let aValue = a;
  let bValue = b;
  const properties = orderBy.split('.');
  for (const property of properties) {
    aValue = aValue[property];
    bValue = bValue[property];
  }
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export const makeColumnFilterModeOptions = (type) => {
  if (type === 'string') {
    return {
      columnFilterModeOptions: [
        'contains',
        'startsWith',
        'endsWith',
        'equals',
        'notEquals',
        'empty',
        'notEmpty',
      ],
      filterFn: 'contains',
    };
  }
  if (type === 'number') {
    return {
      columnFilterModeOptions: [
        'between',
        'betweenInclusive',
        'greaterThan',
        'greaterThanOrEqualTo',
        'lessThan',
        'equals',
        'notEquals',
        'lessThanOrEqualTo',
        'empty',
        'notEmpty',
      ],
      filterFn: 'equals',
    };
  }
  return {};
};
