import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import styled from 'styled-components';

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  span {
    &:first-child {
      color: black;
      font-weight: 700;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  p:nth-child(2) {
    color: ${(props) => props.theme.palette.orange2.default};
  }
`;

export const Menu = styled(MuiMenu)`
  .MuiPaper-root {
    width: 260px;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  display: flex;
  column-gap: 20px;
  padding: 15px 20px;

  &:hover {
    cursor: pointer;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }

  &:last-child {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.045em;
  }
`;
