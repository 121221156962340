import React, { useContext } from 'react';

import {
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoIcon from '@material-ui/icons/Info';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';

import { DataContext } from '../../../../context/DataContext';

const IssuanceMenu = ({
  formik,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  handleChangeMenu,
}) => {
  const { enableFields, expanded } = useContext(DataContext);

  const disabledDate = (date) => {
    return date.getDate() !== 1;
  };

  return (
    <Accordion
      expanded={expanded === 'panel2'}
      onChange={handleChangeMenu('panel2')}
      disabled={!enableFields}>
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Typography component={'span'}>
          <div className="item">
            <CalendarTodayIcon />
            2. Emissão
            <Tooltip title="Emissão">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            width: '100%',
          }}>
          <FormControl>
            <CurrencyInput
              id="input-price"
              name="input-name"
              placeholder="Saldo"
              value={
                formik.values.payment.balance
                  ? formik.values.payment.balance
                  : 0
              }
              decimalsLimit={2}
              onValueChange={(value) => {
                formik.setFieldValue('payment.balance', value);
              }}
              onBlur={formik.handleBlur}
              prefix="R$ "
              className="input-price"
            />
          </FormControl>
          <Typography component={'span'}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              invalidDateMessage="Formato Inválido"
              variant="inline"
              views={['year', 'month']}
              format="MM/yyyy"
              shouldDisableDate={disabledDate}
              value={formik.values.start_date || null}
              onChange={(newValue) => {
                const dateObj = new Date(newValue);
                const momentObj = moment(dateObj);
                const momentString = momentObj.format('YYYY-MM-01');
                formik.setFieldValue('start_date', momentString);
              }}
            />
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default IssuanceMenu;
