import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  Select as MaterialSelect,
  MenuItem,
} from '@material-ui/core';
import { Typography } from '@mui/material';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export function SelectCustom({
  classes,
  values,
  handleChange,
  loading,
  options,
  name,
  label,
  disabled,
  multiple = true,
  error,
  helperText,
  required,
  keyValue,
  placeholder,
}) {
  return (
    <FormControl required={required} className={classes.itemInput}>
      <InputLabel id={`${name}-${label}`}>{label}</InputLabel>
      <MaterialSelect
        labelId={`${name}-${label}`}
        id={name}
        name={name}
        multiple={multiple}
        value={values}
        onChange={handleChange}
        input={<Input error={error} />}
        disabled={loading || disabled}
        MenuProps={multiple && MenuProps}
        displayEmpty
        renderValue={(selected) => {
          if (!selected?.length) {
            return (
              <Typography sx={{ color: '#BDBDBD' }}>{placeholder}</Typography>
            );
          }
          return multiple ? selected?.map((s) => s.name).join(', ') : selected;
        }}>
        {options?.map((option) => (
          <MenuItem
            key={option[keyValue]}
            value={multiple ? option : option[keyValue]}>
            {multiple && (
              <Checkbox
                checked={values.some((value) => value.id === option.id)}
              />
            )}
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </MaterialSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
