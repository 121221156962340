import { useEffect, useRef, useState } from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { getImagesByInstitution } from 'src/services/gallery';
import { getLargestImageUrl } from 'src/utils/image';

import CarouselModal from './CarouselModal';
import Photo from './Photo';

function Photos({ institutionId }: { institutionId: number }) {
  const [selected, setSelected] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const refBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const offsetTop = refBox.current?.offsetTop ?? 0;

    window.scrollTo({
      top: offsetTop - 200,
      behavior: 'smooth',
    });
  }, [page]);

  const { data, isLoading } = useQuery(
    ['gallery-images', institutionId, page],
    () => getImagesByInstitution({ institutionId, page }),
  );

  const handleClickOpen = (value: number) => {
    setOpen(true);
    setSelected(value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Box
      ref={refBox}
      sx={(theme) => ({
        padding: theme.spacing(0, 8),
        [theme.breakpoints.down('lg')]: { padding: theme.spacing(0, 4) },
      })}>
      <Grid
        container
        columnSpacing={{ sm: 4, md: 3, lg: 1 }}
        rowSpacing={{ xs: 4, md: 3, lg: 4 }}>
        {data?.data.length ? (
          data.data.map((image, index) => (
            <Grid
              container
              justifyContent="center"
              key={image.id}
              item
              xs={12}
              sm={6}
              md={4}
              onClick={() => handleClickOpen(index)}
              sx={{ cursor: 'pointer' }}>
              <Photo src={getLargestImageUrl(image.image)} />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontStyle: 'italic',
                color: '#828282',
                fontWeight: 500,
              }}>
              Não há registros para exibir
            </Typography>
          </Box>
        )}
      </Grid>
      {data?.meta && data?.meta?.pagination?.pageCount > 1 && (
        <Box
          sx={{
            marginTop: 7,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Pagination
            count={data?.meta?.pagination?.pageCount}
            onChange={handleChange}
            page={page}
            shape="rounded"
          />
        </Box>
      )}
      <CarouselModal
        open={open}
        onClose={() => setOpen(false)}
        content={data?.data ?? []}
        selected={selected}
        setSelected={setSelected}
      />
    </Box>
  );
}

export default Photos;
