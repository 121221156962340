import theme from 'src/theme';
import styled from 'styled-components';

interface SectionTitleProps {
  fontSize?: string;
}

export const Title = styled.h1<SectionTitleProps>`
  color: black;
  font-family: 'Rubik Mono One', 'Inter', sans-serif;
  font-size: ${(props) => props.fontSize ?? '2.25rem'};
  margin: 0;
  text-transform: uppercase;
  cursor: default;
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 1.25rem;
  }
`;

export const Subtitle = styled.h2`
  color: #8d8d8d;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin: 0;
  cursor: default;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 1rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 150%;
  font-weight: 600;
  text-align: justify;
  cursor: default;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 0.8rem;
  }
`;
