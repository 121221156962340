import {
  crsArticles,
  crsOpeningTexts,
  crsPrimaryMarkets,
  crsRankings,
  crsSecondaryMarkets,
  crsStock,
  crsYearbookDescriptions,
} from '../data/CRs';
import {
  fiagroOpeningTexts,
  fiagroPrimaryMarkets,
  fiagroRankings,
  fiagroSecondaryMarkets,
  fiagroStock,
  fiagroYearbookDescriptions,
} from '../data/FIAGRO';
import {
  fidcArticles,
  fidcOpeningTexts,
  fidcPrimaryMarkets,
  fidcRankings,
  fidcSecondaryMarkets,
  fidcStockMarkets,
  fidcYearbookDescriptions,
} from '../data/FIDC';
import {
  fiiOpeningTexts,
  fiiPrimaryMarkets,
  fiiRankings,
  fiiSecondaryMarkets,
  fiiStockMarkets,
  fiiYearbookDescriptions,
} from '../data/FII';
import {
  AdvertisementBanner,
  Sponsor,
  SponsorLevel,
  YearbookDescriptions,
  YearbookMarket,
  YearbookMarketArticle,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
  YearbookMarketVideo,
} from '../types';

interface AdvertisementList {
  [key: string]: AdvertisementBanner[];
}

interface MarketDataList {
  [key: string]: YearbookMarketChartInfo[];
}

interface MarketRankingList {
  [key: string]: YearbookMarketRanking[];
}

interface MarketArticlesList {
  [key: string]: YearbookMarketArticle[];
}

interface MarketDescriptionsList {
  [key: string]: YearbookDescriptions;
}

interface OpeningsData {
  [key: string]: string[];
}

export const markets: YearbookMarket[] = [
  {
    name: 'CRs',
  },
  {
    name: 'FII',
  },
  {
    name: 'FIDC',
  },
  {
    name: 'FIAGRO',
  },
];

export const advertisements: AdvertisementList = {
  CRs: [
    {
      url: 'https://tozzinifreire.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
      banner: 'tozzini',
    },
  ],
  FIDC: [
    {
      url: 'https://www.mastercctvm.com.br',
      banner: 'master',
    },
    {
      url: 'https://www.nuclea.com.br/',
      banner: 'nuclea',
    },
    {
      url: 'https://plataforma.crdc.com.br:8443/  ',
      banner: 'crdc',
    },
    {
      url: 'https://www.madronalaw.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
      banner: 'madrona',
    },
  ],
  FII: [
    {
      url: 'https://www.singulare.com.br',
      banner: 'singulare',
    },
  ],
  FIAGRO: [
    {
      url: 'https://www.singulare.com.br',
      banner: 'singulare',
    },
  ],
};

export const descriptions: MarketDescriptionsList = {
  FIAGRO: fiagroYearbookDescriptions,
  CRs: crsYearbookDescriptions,
  FII: fiiYearbookDescriptions,
  FIDC: fidcYearbookDescriptions,
};

export const openings: OpeningsData = {
  CRs: crsOpeningTexts,
  FII: fiiOpeningTexts,
  FIDC: fidcOpeningTexts,
  FIAGRO: fiagroOpeningTexts,
};

export const paywallData = {
  title: 'Acesso não autorizado',
  subtitle:
    'Você precisa realizar login na plataforma para visualizar o anuário, você pode efetuar login ou se cadastrar utilizando o botão abaixo.',
  buttonLabel: 'Entrar',
  url: '/login',
};

export const sponsors: Sponsor[] = [
  {
    level: SponsorLevel.DIAMOND,
    site: 'https://www.ouropretoinvestimentos.com.br/',
    markets: ['CRs', 'FIAGRO', 'FII', 'FIDC'],
    image: 'ouro',
  },
  {
    level: SponsorLevel.DIAMOND,
    site: 'https://www.singulare.com.br/',
    markets: ['CRs', 'FIAGRO', 'FII', 'FIDC'],
    image: 'singulare',
  },
  {
    site: 'https://nextauditores.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
    level: SponsorLevel.BRONZE,
    markets: ['CRs', 'FIAGRO', 'FII', 'FIDC'],
    image: 'next',
  },
  {
    site: 'https://www.cesconbarrieu.com.br/',
    level: SponsorLevel.BRONZE,
    markets: ['FII'],
    image: 'cescon',
  },
  {
    site: 'https://www.mastercctvm.com.br/',
    level: SponsorLevel.SILVER,
    markets: ['FIDC'],
    image: 'master',
  },
  {
    site: 'https://www.madronalaw.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
    level: SponsorLevel.SILVER,
    markets: ['FIDC'],
    image: 'madrona',
  },
  {
    site: 'https://www.nuclea.com.br/',
    level: SponsorLevel.SILVER,
    markets: ['FIDC'],
    image: 'nuclea',
  },
  {
    site: 'https://plataforma.crdc.com.br:8443/',
    level: SponsorLevel.SILVER,
    markets: ['FIDC'],
    image: 'crdc',
  },
  {
    site: 'https://www.idtrust.com.br/',
    level: SponsorLevel.BRONZE,
    markets: ['FIDC'],
    image: 'idtrust',
  },
  {
    site: 'https://solisinvestimentos.com.br/',
    level: SponsorLevel.BRONZE,
    markets: ['FIDC'],
    image: 'solis',
  },
  {
    site: 'https://terconbr.com.br/',
    level: SponsorLevel.BRONZE,
    markets: ['FIDC'],
    image: 'tercon',
  },
  {
    site: 'https://tozzinifreire.com.br/?utm_campaign=perfil_institucional&utm_source=plataforma_uqbar',
    level: SponsorLevel.PLATINUM,
    markets: ['CRs'],
    image: 'tozzini',
  },
  {
    site: 'https://www.flha.com.br/',
    level: SponsorLevel.BRONZE,
    markets: ['CRs'],
    image: 'flha',
  },
];

export const marketData: MarketDataList = {
  CRs: [...crsPrimaryMarkets, ...crsSecondaryMarkets, ...crsStock],
  FII: [...fiiPrimaryMarkets, ...fiiSecondaryMarkets, ...fiiStockMarkets],
  FIDC: [...fidcPrimaryMarkets, ...fidcSecondaryMarkets, ...fidcStockMarkets],
  FIAGRO: [...fiagroPrimaryMarkets, ...fiagroSecondaryMarkets, ...fiagroStock],
};

export const marketRankings: MarketRankingList = {
  CRs: crsRankings,
  FII: fiiRankings,
  FIDC: fidcRankings,
  FIAGRO: fiagroRankings,
};

export const articlesData: MarketArticlesList = {
  CRs: crsArticles,
  FIDC: fidcArticles,
  FII: [],
  FIAGRO: [],
};

export const marketVideos: YearbookMarketVideo[] = [
  {
    title:
      'Certificados de Recebíveis: Panorama Regulatório de 2023 e Perspectivas para 2024',
    author: 'Tozzini Freire Advogados',
    market: 'CRs',
    videoId: '925554695',
  },
  {
    title: 'Singulare',
    author: 'Singulare',
    market: 'FIDC',
    videoId: '927564696',
  },
];
