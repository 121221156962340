import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';

function ProgressBar(props: LinearProgressProps & { value: number }) {
  return (
    <Box>
      <Typography
        fontSize={14}
        sx={(theme) => ({
          padding: '0.5rem 0',
          [theme.breakpoints.down('md')]: {
            padding: '1rem 0',
          },
        })}>{`Seu perfil está ${props.value}% preenchido`}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ProgressBar;
