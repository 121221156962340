import { useCallback, useEffect, useMemo, useState } from 'react';

import ChartDetailsCard from '../../../components/ChartDetailsCard';
import ColumnChart from '../../../components/Charts/ColumnChart';
import LineColumnChart from '../../../components/Charts/LineColumnChart';
import PieChart from '../../../components/Charts/PieChart';
import DataSelector from '../../../components/DataSelector';
import ScrollWrapper from '../../../components/ScrollWrapper';
import { withLinks } from '../../../link-mapper';
import { descriptions, marketData } from '../../../presentation/data';
import { YearbookMarket, YearbookMarketChartInfo } from '../../../types';
import { getAdvertisementByPosition } from '../../../utils';
import * as SecondaryMarket from '../styles';

interface SecondaryMarketSectionProps {
  market: YearbookMarket;
}

export function SecondaryMarketSection({
  market,
}: SecondaryMarketSectionProps) {
  const [selectedChartInfo, setSelectedChartInfo] =
    useState<YearbookMarketChartInfo | null>(null);

  const items = useMemo(
    () =>
      marketData[market.name].filter((info) => info.section === 'secondary'),
    [market],
  );

  const description = useMemo(
    () => descriptions[market.name].secondary,
    [market.name],
  );

  const getAdvertisementImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2024/banners/${imageName}.png`);
  };

  const ads = useMemo(
    () => getAdvertisementByPosition(market.name, 4),
    [market],
  );

  const ChartComponent = useMemo(() => {
    if (selectedChartInfo?.chart?.chartType === 'line-column')
      return LineColumnChart;
    else if (selectedChartInfo?.chart?.chartType === 'column')
      return ColumnChart;
    else return PieChart;
  }, [selectedChartInfo]);

  const getChartInfoByName = useCallback(
    (name: string) => {
      return marketData[market.name].find(
        (info) => info.section === 'secondary' && info.name === name,
      );
    },
    [market],
  );

  const selectChartInfo = useCallback(
    (selectedName: string) => {
      const chartInfo = getChartInfoByName(selectedName) ?? null;
      setSelectedChartInfo(chartInfo);
    },
    [market],
  );

  useEffect(() => {
    setSelectedChartInfo(items[0]);
  }, [market]);

  return (
    <SecondaryMarket.Section data-yearbook-section="Mercado Secundário">
      <SecondaryMarket.Title>Mercado Secundário</SecondaryMarket.Title>
      <SecondaryMarket.Description>
        {description.map((text, idx) => (
          <SecondaryMarket.DescriptionParagraph
            key={`${market}-${idx}`}
            dangerouslySetInnerHTML={{ __html: withLinks(text) }}
          />
        ))}
      </SecondaryMarket.Description>
      <ScrollWrapper
        items={items}
        select={selectChartInfo}
        selected={selectedChartInfo}>
        {selectedChartInfo && (
          <SecondaryMarket.Content>
            <ChartDetailsCard details={selectedChartInfo.details} />
            <SecondaryMarket.ChartContent>
              <DataSelector
                select={selectChartInfo}
                selected={selectedChartInfo}
                items={items}
                id="secondary-market-selector"
              />
              <SecondaryMarket.ChartTip>
                Passe o mouse sobre o gráfico para visualizar a legenda dos
                dados.
              </SecondaryMarket.ChartTip>
              <SecondaryMarket.ChartWrapper>
                {selectedChartInfo?.chart?.chartType && (
                  <ChartComponent
                    data={selectedChartInfo.chart.data as any}
                    categories={selectedChartInfo.chart.categories}
                    numeric={selectedChartInfo.chart.numeric}
                    stacked={selectedChartInfo.chart.stacked}
                    monetary={selectedChartInfo.chart.monetary}
                    animationEnabled={selectedChartInfo.chart.animated ?? true}
                  />
                )}
                {selectedChartInfo.link && (
                  <SecondaryMarket.Button href={selectedChartInfo.link}>
                    Acessar plataforma Uqbar
                  </SecondaryMarket.Button>
                )}
              </SecondaryMarket.ChartWrapper>
            </SecondaryMarket.ChartContent>
          </SecondaryMarket.Content>
        )}
      </ScrollWrapper>
      {ads && (
        <SecondaryMarket.Ads
          image={getAdvertisementImage(ads.banner).default}
          href={ads.url}
          target="_blank"
        />
      )}
    </SecondaryMarket.Section>
  );
}
