import { useState } from 'react';

import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useQuery } from 'react-query';
import { CustomTooltip } from 'src/components/CustomTooltip';

import { useAuthContext } from '../../../context/AuthContextProvider';
import { getPlansWithPermission } from '../../../services/subscription';
import { buildNamesInline } from '../../../utils/string';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    fontSize: '0.875rem',
    color: '#828282',
    fontWeight: 600,
  },
  box: {
    width: 160,
  },
  item: {
    fontSize: '1rem',
    fontWeight: 500,
    padding: theme.spacing(2, 3),
  },
  icon: {
    '& > *:first-child': {
      fontSize: '30px',
    },
  },
  paper: {
    margin: theme.spacing(4, 0, 2, 0),
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderStyle: 'solid',
    borderColor: theme.palette.grey.A100,
    borderWidth: 1,
    borderRadius: 5,
  },
  input: {
    margin: theme.spacing(0, 1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  boxIframe: {
    '& iframe': {
      width: '100%',
      height: 400,
      borderStyle: 'solid',
      borderColor: theme.palette.grey.A100,
      borderWidth: 1,
      borderRadius: 8,
    },
  },
  button: {
    '@media print': {
      display: 'none',
    },
  },
}));

const MenuItemCustom = ({ classes, disabled, label, handleAction }) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener={!disabled}
      disableHoverListener={!disabled}
      arrow
      title={'Faça o upgrade do seu plano para utilizar essa funcionalidade'}
      placement="top"
      classes={{ tooltip: classes.tooltip }}>
      <span>
        <MenuItem
          disabled={disabled}
          className={classes.item}
          onClick={() => handleAction()}>
          {label}
        </MenuItem>
      </span>
    </Tooltip>
  );
};

function Export({
  handlePrint,
  handleExport,
  permission,
  hasData = false,
  pdfEnabled = true,
}) {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const hasPermission =
    !!auth.user?.subscription?.plan?.permissions[permission];

  const { data: plansToUpgrade, isLoading: plansToUpgradeLoading } = useQuery(
    ['upgradedPlan', permission],
    () => getPlansWithPermission(permission),
    {
      enabled: !!permission && !hasPermission,
      retry: false,
    },
  );

  const isDisabled =
    !auth.isLoggedIn || !hasPermission || !hasData || plansToUpgradeLoading;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <CustomTooltip
        title={
          !hasData
            ? ''
            : !auth.isLoggedIn || !hasPermission
              ? `Recurso disponível apenas para o(s) plano(s) ${buildNamesInline(plansToUpgrade?.data)}`
              : ''
        }
        placement="top"
        arrow>
        <span aria-roledescription={permission}>
          <Button
            variant="text"
            onClick={handleClick}
            className={classes.button}
            classes={{ iconSizeMedium: classes.icon }}
            startIcon={
              <GetAppRoundedIcon
                color={isDisabled ? 'disabled' : 'secondary'}
              />
            }
            disabled={isDisabled}>
            <Typography
              className={classes.exportButton}
              style={{ color: isDisabled ? '#00000042' : undefined }}>
              Exportar
            </Typography>
          </Button>
        </span>
      </CustomTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box className={classes.box}>
          <MenuItemCustom
            classes={classes}
            disabled={!hasPermission}
            label="CSV"
            handleAction={() => {
              setAnchorEl(null);
              handleExport();
            }}
          />
          {pdfEnabled && (
            <MenuItemCustom
              classes={classes}
              disabled={!hasPermission}
              label="PDF"
              handleAction={() => {
                setAnchorEl(null);
                handlePrint();
              }}
            />
          )}
        </Box>
      </Popover>
    </>
  );
}

export default Export;
