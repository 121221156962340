import { Box, Card, CardContent, Typography } from '@mui/material';

export default function CardAssignor({
  document,
  name,
  lock,
  hasLink,
}: {
  document: string;
  name: string;
  lock: boolean;
  hasLink: boolean;
}) {
  return (
    <Card square sx={{ display: 'flex', minHeight: '7rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}>
        <CardContent
          sx={{ padding: '20px', '&:last-child': { paddingBottom: '20px' } }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              '-webkit-filter': lock ? 'blur(5px)' : 'none',
            }}>
            {lock ? 'Nome do Cedente' : name}
          </Typography>
          {document && (
            <Box display="flex" alignItems="center" mt={1}>
              <Typography
                sx={(theme) => ({
                  whiteSpace: 'nowrap',
                  '-webkit-filter': lock ? 'blur(5px)' : 'none',
                  color: hasLink
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                  textDecoration: hasLink ? 'underline' : 'none',
                })}>
                {lock ? '000.000.000/0000-00' : `CNPJ - ${document}`}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}
