// Material ui components
import {
  Box,
  Container,
  Grid,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { Masonry } from '@mui/lab';
import { parse } from 'qs';
import { useQueryParams } from 'src/utils/hooks';

import BannerAdvertisement from '../../../components/BannerAdvertisement';
import Article from '../Article';
import Indicators from '../Indicators';
import Institutions from '../Institutions';
import NextEvents from '../NextEvents';
import Operations from '../Operations';
import Professionals from '../Professionals';
import Radar from '../Radar';
import Trainings from '../Trainings';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(12),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(21),
    },
  },
}));

export default function Dashboard() {
  const [, setQuery] = useQueryParams();
  const classes = useStyles();
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const planDetails = sessionStorage.getItem('@App:planDetails');

  if (planDetails) {
    try {
      const planDetailsParsed = JSON.parse(planDetails);
      setQuery(parse(planDetailsParsed));
    } finally {
      sessionStorage.removeItem('@App:planDetails');
    }
  }

  return (
    <Box component="section">
      <Grid container>
        <Container className={classes.container}>
          <Grid item xs={12}>
            <BannerAdvertisement location="Dashboard_Area1" />
          </Grid>
          <Box margin="8px -8px 0px -8px">
            <Masonry
              columns={isMedium ? 1 : 2}
              spacing={2}
              style={{
                margin: '8px 0px 0px 0px',
              }}>
              <Radar />
              <Indicators />
              <Operations />
              <Institutions isLoggedIn={true} />
              <Article />
              <NextEvents />
            </Masonry>
          </Box>
          <Grid item xs={12}>
            <Trainings />
          </Grid>
          <Grid item xs={12}>
            <Professionals />
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
}
