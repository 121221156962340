/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { DataContext } from '../../../../context/DataContext';
import { findPropPaths } from '../../utils/findPropPaths';

const Benchmark = ({ formikObj }) => {
  const { pickedSecurity, indexes } = useContext(DataContext);
  const formikInitialValues = formikObj.values;
  const [index_factorPATH, setIndex_factorPATH] = useState(null);
  const [index_def_idPATH, setIndex_def_idPATH] = useState(null);
  const [index_spreadPATH, setIndex_spreadPATH] = useState(null);
  const regex = /^\d+(\.\d{0,2})?$/;

  useEffect(() => {
    setIndex_factorPATH(
      findPropPaths(
        formikInitialValues,
        (props) => props === 'index_factor',
      )[0],
    );
    setIndex_def_idPATH(
      findPropPaths(
        formikInitialValues,
        (props) => props === 'index_def_id',
      )[0],
    );
    setIndex_spreadPATH(
      findPropPaths(
        formikInitialValues,
        (props) => props === 'index_spread',
      )[0],
    );
  }, [pickedSecurity]);

  const getValueIndexSpread = () => {
    return index_spreadPATH
      ?.split('.')
      .reduce((o, i) => o[i], formikInitialValues);
  };

  const getValueIndexDefId = () => {
    return (
      index_def_idPATH
        ?.split('.')
        .reduce((o, i) => o[i], formikInitialValues) &&
      indexes?.find(
        (index) =>
          index.id ===
          index_def_idPATH
            ?.split('.')
            .reduce((o, i) => o[i], formikInitialValues),
      )
    );
  };

  const getValueIndexFactor = () => {
    return index_factorPATH
      ?.split('.')
      .reduce((o, i) => o[i], formikInitialValues);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <TextField
          disabled={!pickedSecurity?.id}
          label="%"
          variant="outlined"
          onChange={(event) => {
            const value = event.target.value;
            if (value.match(regex) || value === '') {
              formikObj.setFieldValue(`${index_factorPATH}`, value);
            }
          }}
          value={getValueIndexFactor() || '0.0'}
          onBlur={formikObj.handleBlur}
          size="small"
          fullWidth
          inputProps={{
            maxLength: 5,
            min: 0,
            step: 0.1,
          }}
        />
      </div>
      <div style={{ display: 'none', width: '100%' }}>
        <Autocomplete
          disabled={!pickedSecurity?.id}
          style={{ width: '10rem' }}
          fullWidth
          size="small"
          disableClearable
          onBlur={formikObj.handleBlur}
          value={getValueIndexDefId() || ''}
          options={indexes ? indexes : []}
          onChange={(event, newValue) => {
            formikObj.setFieldValue(`${index_def_idPATH}`, newValue.id);
          }}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => <TextField {...params} label="Indexador" />}
        />
      </div>
      <div style={{ width: '100%', marginTop: '0.5rem' }}>
        <TextField
          label="Juros * (%aa)"
          variant="standard"
          onChange={(event) => {
            const value = event.target.value;
            if (value.match(regex) || value === '') {
              formikObj.setFieldValue(`${index_spreadPATH}`, value);
            }
          }}
          value={getValueIndexSpread() || '0.0'}
          onBlur={formikObj.handleBlur}
          size="small"
          fullWidth
          inputProps={{
            maxLength: 5,
            min: 0,
            step: 0.1,
          }}
        />
      </div>
    </>
  );
};

export default Benchmark;
