import { ChartsList } from '../../types';

const monthCategories = [
  '2021-08-01',
  '2021-09-01',
  '2021-10-01',
  '2021-11-01',
  '2021-12-01',
  '2022-01-01',
  '2022-02-01',
  '2022-03-01',
  '2022-04-01',
  '2022-05-01',
  '2022-06-01',
  '2022-07-01',
  '2022-08-01',
  '2022-09-01',
  '2022-10-01',
  '2022-11-01',
  '2022-12-01',
  '2023-01-01',
  '2023-02-01',
  '2023-03-01',
  '2023-04-01',
  '2023-05-01',
  '2023-06-01',
  '2023-07-01',
  '2023-08-01',
  '2023-09-01',
  '2023-10-01',
  '2023-11-01',
  '2023-12-01',
];

export const fiagroPrimaryCharts: ChartsList = {
  emissionsFIDC: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante emitido',
        type: 'column',
        data: [72522549.27, 1837614671.58, 4743938056.78],
      },
      {
        name: 'Valores distintos de id',
        type: 'line',
        data: [3, 13, 30],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  emissionsByTypeOfCondominium: {
    chartType: 'column',
    data: [
      {
        name: 'Condominio Fechado',
        data: [100, 100, 99.23],
      },
      {
        name: 'Condomínio Aberto',
        data: [0, 0, 0.77],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  emissionsFII: {
    chartType: 'column',
    numeric: true,
    stacked: false,
    data: [
      {
        name: 'Montante emitido',
        data: [2179226533.7, 6673152928.94, 6252948473.67],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  emissionsByTypeOfImmobile: {
    chartType: 'column',
    data: [
      {
        name: 'Fazenda',
        data: [100, 63.29, 76.36],
      },
      {
        name: 'Especial',
        data: [0, 36.71, 23.64],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
};

export const fiagroSecondaryCharts: ChartsList = {
  amountNegotiatedFIDC: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante negociado',
        type: 'column',
        data: [9406441.34, 111203582.69, 777448908.95],
      },
      {
        name: 'Número de negociações',
        type: 'line',
        data: [369, 562, 253657],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  amountNegotiatedFII: {
    chartType: 'line-column',
    data: [
      {
        name: 'Montante negociado',
        type: 'column',
        data: [75192593.5, 187521338.67, 7198496229.43],
      },
      {
        name: 'Número de negociações',
        type: 'line',
        data: [61738, 236094, 16351991],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
};

export const fiagroStockCharts: ChartsList = {
  liquidFIDC: {
    chartType: 'line-column',
    data: [
      {
        name: 'Patrimônio Líquido',
        type: 'column',
        data: [223957559.44, 2703179774.96, 4597982577.48],
      },
      {
        name: 'Núm. de FIAGROs-FIDC',
        type: 'line',
        data: [4, 15, 37],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  creditoryFIDC: {
    chartType: 'column',
    numeric: true,
    stacked: false,
    data: [
      {
        name: 'Direitos creditórios',
        data: [152309131.25, 2033278414.58, 3687060446.14],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
  liquidFII: {
    chartType: 'line-column',
    data: [
      {
        name: 'Patrimômio Líquido',
        type: 'column',
        data: [
          559056881.31, 559780066.89, 990413165.53, 1440927898.69,
          2189748388.48, 2421260701.63, 3336466347.05, 3433260410.24,
          3661741595.72, 4051405171, 4679360963.59, 5187157086.53,
          5694603641.65, 6133275045.93, 7236455267.41, 7917176089.67,
          9257510982.38, 10139088910.22, 10247041797.6, 10761404323.46,
          11692964498.34, 12224717684.73, 12696159319.6, 13370635610.36,
          14057093182.43, 14728998691.84, 15874999777.97, 15930386153.81,
          16124130839.03,
        ],
      },
      {
        name: 'Núm. de FIAGROs-FII',
        type: 'line',
        data: [
          3, 3, 5, 9, 13, 15, 17, 18, 23, 25, 27, 28, 28, 28, 28, 28, 32, 33,
          34, 36, 37, 39, 40, 40, 41, 43, 44, 45, 45,
        ],
      },
    ],
    categories: monthCategories,
  },
  liquidByFinalityFII: {
    chartType: 'column',
    data: [
      {
        name: 'Gestão de Ativos Financeiros',
        data: [
          39.888725685203575, 39.96755161058, 36.80976715761934,
          50.99992043724734, 67.20207480830578, 66.74726864405885,
          75.88003976957421, 76.50496207237563, 76.87450553748056,
          74.35258032867073, 74.13326780391432, 71.77253566308529,
          71.994979504352, 73.97578302494055, 72.95565555426353,
          75.10418536406233, 72.97496315184705, 75.46340033193357,
          74.80880895250579, 72.53175111146089, 72.25512407858106,
          73.47636330792196, 73.11151921077537, 73.78830406032105,
          74.05119614224935, 71.97400039340813, 67.6448450956337,
          67.49721036679551, 67.64199168540192,
        ],
      },
      {
        name: 'Diversas',
        data: [
          0, 0, 29.26064106235084, 25.679994733005586, 16.922699648484503,
          18.895648207233567, 13.70123296955135, 13.370194368329653,
          12.772325563787886, 12.035998034628298, 13.70065713669899,
          16.917413387937984, 17.653072051713934, 16.401174014811676,
          18.8738608742735, 17.34012160507288, 19.214222604062215,
          17.517754382740303, 17.20002809330592, 19.90071309216858,
          20.76534051193523, 19.82996392945774, 19.9488174866397,
          18.942713324395253, 18.03399887857735, 18.37562516866439,
          22.999477676634584, 22.837146848696474, 22.52234863834972,
        ],
      },
      {
        name: 'Renda',
        data: [
          60.11127431479644, 60.032448389420004, 33.929591780029824,
          23.320084829747078, 15.875225543209712, 14.357083148707595,
          10.418727260874446, 10.124843559294716, 10.353168898731568,
          13.611421636700985, 12.166075059386698, 11.310050948976732,
          10.351948443934072, 9.62304296024777, 8.17048357146296,
          7.55569303086478, 7.810814244090722, 7.01884528532612,
          7.99116295418828, 7.567535796370517, 6.979535409483713,
          6.693672762620309, 6.939663302584949, 7.268982615283698,
          7.914804979173298, 9.650374437927477, 9.355677227731714,
          9.665642784508014, 9.83565967624836,
        ],
      },
    ],
    categories: monthCategories,
  },
  liquidByManagementTypeFii: {
    chartType: 'column',
    data: [
      {
        name: 'Gestão Ativa',
        data: [100, 96.94, 97.15],
      },
      {
        name: 'Gestão Passiva',
        data: [0, 3.06, 2.85],
      },
    ],
    categories: ['2021', '2022', '2023'],
  },
};
