import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import theme from 'src/theme';

import { Content, Slide, Wrapper } from './styles';

interface ScrollItem {
  name: string;
}

interface ScrollWrapperProps extends PropsWithChildren {
  select: (name: string) => void;
  selected: ScrollItem | null;
  items: ScrollItem[];
}

export default function ScrollWrapper({
  items,
  select,
  selected,
  children,
}: ScrollWrapperProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      const vw = window.innerWidth;
      if (!wrapperRef.current || vw <= theme.breakpoints.values.md) return;
      wrapperRef.current.scrollLeft += e.deltaY > 0 ? vw : -vw;

      const currentSlide = Math.round(
        wrapperRef.current.scrollLeft / wrapperRef.current.clientWidth,
      );
      const item = items[currentSlide];
      if (!item || selected?.name === item.name) return;

      select(item.name);
      e.preventDefault();
    },
    [select, items, selected],
  );

  useEffect(() => {
    wrapperRef.current?.addEventListener('wheel', handleWheel);
    return () => {
      wrapperRef.current?.removeEventListener('wheel', handleWheel);
    };
  }, [select, items, selected]);

  if (items.length < 1) return null;

  return (
    <Wrapper ref={wrapperRef}>
      <Content>{children}</Content>
      {items.slice(1).map((item) => (
        <Slide key={item.name} />
      ))}
    </Wrapper>
  );
}
