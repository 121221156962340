import theme from 'src/theme';
import styled from 'styled-components';

import * as Yearbook from '../../components/Advertisement';
import * as YearbookSection from '../../components/Section';

interface ParagraphProps {
  color?: string;
}

export const Section = styled.section`
  position: relative;
  top: -8rem;
  margin-bottom: -2rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: -4rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: -6rem;
  }
`;

export const Texts = styled.div`
  padding: 0 calc(80px + 4rem) 0 calc(160px + 8rem);
  z-index: 998;

  @media screen and (max-width: 1366px) {
    padding-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 3.5rem;
  }
`;

export const Title = styled(YearbookSection.Title)`
  padding: 0 calc(80px + 4rem) 0 calc(160px + 8rem);
  margin-bottom: 4rem;
  position: relative;
  z-index: 998;

  @media screen and (max-width: 1366px) {
    padding-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 2rem;
    padding-left: 3.5rem;
  }
`;

export const Paragraph = styled(YearbookSection.Paragraph)<ParagraphProps>`
  color: ${(props) => props.color ?? '#3F3F3F'};
  position: relative;
  z-index: 998;
  margin-bottom: 1rem;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 0.9rem;
  }
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  background-color: #ff4949;
  width: 100%;
  right: 0;
  padding: 16rem 0 1rem 0;
  border-bottom-right-radius: 150px;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    border-bottom-right-radius: 100px;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding-top: 8rem;
    border-bottom-right-radius: 75px;
  }
`;

export const Advertisement = styled(Yearbook.Advertisement)`
  margin-top: 8rem;
`;
