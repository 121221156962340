import { useCallback, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Close, InsertDriveFile, UploadFileRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { If } from 'src/components/If';
import { createDocument, updateDocument } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Document } from 'src/types/institution';
import { formatBytes } from 'src/utils/number';
import * as Yup from 'yup';

import * as S from './../../style';

const MAX_FILE_SIZE = 5000000;

type Props = {
  open: boolean;
  onClose: () => void;
  institutionId: number | undefined;
  selectedDocument?: Document | undefined;
  institutionRefetch: () => void;
};

function DocumentsModal({
  open,
  onClose,
  institutionId,
  selectedDocument,
  institutionRefetch,
}: Props) {
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('pdf', acceptedFiles[0]);
    }
    if (rejectedFiles.length) {
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        formik.setErrors({
          pdf: 'O documento excede o tamanho máximo. Max: 5MB',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        formik.setErrors({
          pdf: 'Formato não suportado',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'too-many-files') {
        formik.setErrors({
          pdf: 'Selecione somente um documento',
        });
      }
    }
  }, []);

  const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
    pdf: Yup.mixed().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      title: selectedDocument?.title ?? '',
      description: selectedDocument?.description ?? '',
      pdf: selectedDocument?.pdf ?? null,
    } as { title: string; description: string; pdf: File | null },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      const { pdf, ...pdfData } = values;
      if (selectedDocument) {
        formData.append(
          'data',
          JSON.stringify({
            title: pdfData.title,
            description: pdfData.description,
          }),
        );
        updateDocumentMutation.mutate({
          id: selectedDocument?.id,
          values: formData,
        });
      } else if (pdf) {
        formData.append('files.pdf', pdf);
        formData.append(
          'data',
          JSON.stringify({
            ...pdfData,
            institution: institutionId,
          }),
        );
        createDocumentMutation.mutate(formData);
      }
    },
  });

  const createDocumentMutation = useMutation(createDocument, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Erro ao salvar documento, tente novamente',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Documento criado com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const updateDocumentMutation = useMutation(updateDocument, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar o documento',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Documento atualizado com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'application/pdf': [] },
    onDrop,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    noDragEventsBubbling: true,
  });

  const handleRemove = () => {
    formik.setFieldValue('pdf', null);
  };

  const handleLeaveDialog = () => {
    setOpenLeaveDialog(false);
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      sx={{
        maxWidth: 750,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <form
        onSubmit={formik.handleSubmit}
        onChange={(e) => e.stopPropagation()}>
        <DialogContent
          sx={(theme) => ({
            padding: '40px 56px 0px',
            [theme.breakpoints.down('sm')]: {
              padding: '20px 24px',
            },
          })}>
          <Typography fontSize={18} fontWeight={700} mb={3} textAlign="center">
            {`${selectedDocument ? 'Editar' : 'Adicionar'} documento`}
          </Typography>
          <Typography fontSize={14} mb={3} textAlign="center">
            {`${
              selectedDocument
                ? 'Edite o título ou descrição do seu documento.'
                : 'Faça o upload do arquivo em pdf, incluindo título e descrição.'
            }`}
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                paddingBottom: '16px',
              }}>
              <S.FieldTitle>Título*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="title"
                  placeholder="Digite o título para o documento"
                  variant="outlined"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </FormControl>
            </Grid>
            <TextAreaProfile
              name="description"
              label="Descrição*"
              placeholder="Digite a descrição do arquivo"
              variant="outlined"
              maxCaracters={160}
              value={formik.values.description}
              handleChange={formik.handleChange}
              errors={formik.errors.description}
              touched={formik.touched.description}
            />
            {!selectedDocument &&
              (!formik.values.pdf ? (
                <Box
                  {...getRootProps()}
                  sx={{
                    width: '100%',
                    height: 150,
                    backgroundColor: '#FAFAFA',
                    border: '1px dashed #E0E0E0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    margin: '8px 0 20px',
                    cursor: 'pointer',
                  }}>
                  <input {...getInputProps()} />
                  <UploadFileRounded
                    sx={{ fontSize: '48px', color: '#BDBDBD' }}
                  />
                  <Typography
                    textAlign="center"
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                      fontSize: '0.875rem',
                    })}>
                    <span style={{ textDecoration: 'underline' }}>
                      Clique aqui
                    </span>{' '}
                    ou arraste um arquivo
                  </Typography>
                  {Boolean(formik.errors.pdf) && formik.touched.pdf && (
                    <FormHelperText sx={{ color: '#EB5757' }}>
                      {formik.errors.pdf}
                    </FormHelperText>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    height: 62,
                    width: '100%',
                    padding: '0 16px',
                    backgroundColor: '#FAFAFA',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '8px 0 20px',
                  }}>
                  <Box sx={{ display: 'flex', textAlign: 'start' }}>
                    <InsertDriveFile
                      sx={{ fontSize: '40px', color: '#BDBDBD' }}
                    />
                    <Box ml={1}>
                      <If condition={formik.values.pdf}>
                        <Typography fontSize={14} fontWeight={600}>
                          {formik.values.pdf?.name}
                        </Typography>
                        <Typography sx={{ color: '#828282', fontSize: '14px' }}>
                          {formatBytes(formik.values.pdf?.size)}
                        </Typography>
                      </If>
                    </Box>
                  </Box>
                  <IconButton onClick={handleRemove}>
                    <Close />
                  </IconButton>
                </Box>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            gap: '16px',
            justifyContent: 'center',
            padding: '40px 0 56px 0',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
              padding: '0 24px 40px 24px',
            },
            '& > button': {
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
                marginLeft: '0 !important',
              },
            },
          })}>
          <Button variant="contained" type="submit">
            {createDocumentMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
          <Button
            variant="outlined"
            type="reset"
            color="secondary"
            onClick={() => setOpenLeaveDialog(true)}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
      <ConfirmLeaveDialog
        title="Tem certeza que deseja fechar a adição de documentos?"
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleLeaveDialog}
        labelButton="Continuar edição"
      />
    </Dialog>
  );
}

export default DocumentsModal;
