import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import {
  deleteFavoriteRadar,
  getDashboards,
  getFavoriteRadar,
} from 'src/services/radar';
import { Dashboard, FavoriteRadar, State } from 'src/types/radar';
import { hasChanged } from 'src/utils/validations';

import DashboardModule from './DashboardModule';
import GridLayout from './GridLayout';
import RadarFavorite from '../RadarFavorite';

export default function RadarDashboard({ query }: { query: any }) {
  const { auth } = useAuthContext();
  const history = useHistory();
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.radar_dashboard;
  const dashboardsRef = useRef<Dashboard[]>([]);
  const layouts = useRef<any[]>();
  const [allowPersonalization, setAllowPersonalization] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);
  const [favoriteRadars, setFavoriteRadars] = useState<FavoriteRadar[]>();
  const [selectedFavoriteRadar, setSelectedFavoriteRadar] =
    useState<FavoriteRadar>();
  const [selectedRemoveFavoriteRadar, setSelectedRemoveFavoriteRadar] =
    useState<FavoriteRadar>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [triggerExit, setTriggerExit] = useState({
    shouldLeave: false,
    path: '',
  });

  const { refetch, isLoading } = useQuery(
    [query.market, 'dashboard'],
    () =>
      getDashboards(
        query.market,
        state.dashboards?.map(({ id }) => id),
      ),
    {
      onSuccess: ({ data }) => {
        if (!dashboardsRef.current.length) {
          dashboardsRef.current = data;
        }
      },
      enabled: !auth.isLoading && hasPermission,
    },
  );

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('radarDashboard'),
    {
      enabled: !hasPermission && !auth.isLoading,
    },
  );

  const { isLoading: isLoadingFavoriteRadar, refetch: refetchFavoriteRadar } =
    useQuery(
      [query.market, 'favoriteRadar'],
      () => getFavoriteRadar(query.market, query.mode),
      {
        onSuccess: ({ data }: { data: FavoriteRadar[] }) => {
          setFavoriteRadars(data);
        },
        enabled: auth.isLoggedIn && hasPermission,
      },
    );

  const removeFavoriteRadar = useMutation(
    (value: number) => deleteFavoriteRadar(value),
    {
      onSuccess: () => {
        refetchFavoriteRadar();
      },
    },
  );

  const handleDeleteFavoriteRadar = () => {
    setSelectedFavoriteRadar(undefined);
    if (selectedRemoveFavoriteRadar?.id) {
      removeFavoriteRadar.mutate(selectedRemoveFavoriteRadar.id);
      setOpenDialog(false);
    }
  };

  const handleOpenModal = () => {
    setOpenDialog((prevState) => !prevState);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const INITIAL_STATE = {
    dashboards: dashboardsRef.current.sort((a, b) => a.id - b.id),
    layouts: layouts.current,
  };
  const [state, setState] = useState<State>(INITIAL_STATE);

  useEffect(() => {
    if (dashboardsRef.current.length || layouts?.current?.length) {
      dashboardsRef.current = [];
      layouts.current = [];
      INITIAL_STATE.dashboards = [];
      INITIAL_STATE.layouts = [];
      setAllowPersonalization(false);
      setStateChanged(false);
      setTriggerExit({
        shouldLeave: false,
        path: '',
      });
    }
  }, [query.market]);

  useEffect(() => {
    setState(INITIAL_STATE);
  }, [dashboardsRef.current, query.market]);

  useEffect(() => {
    if (!selectedFavoriteRadar) {
      setStateChanged(hasChanged(INITIAL_STATE, state));
    } else {
      setStateChanged(hasChanged(selectedFavoriteRadar.data, state));
    }
  }, [INITIAL_STATE, state, auth.isFetched, selectedFavoriteRadar]);

  useEffect(() => {
    if (!query.favoriteId || !favoriteRadars?.length) {
      return setSelectedFavoriteRadar(undefined);
    }
    const favoriteRadar = favoriteRadars.find(
      (radar) => radar.id === +query.favoriteId,
    );
    const newValue = { ...INITIAL_STATE, ...favoriteRadar?.data };
    setState(newValue);
    if (favoriteRadar) {
      setSelectedFavoriteRadar({
        ...favoriteRadar,
        data: newValue,
      });
    }
  }, [query.favoriteId, favoriteRadars]);

  useEffect(() => {
    refetch();
  }, [state]);

  const handleGoToIntendedPage = useCallback(
    (location: string) => history.push(location),
    [history],
  );

  const handleUnload = (event: any) => {
    const urlParams = new URLSearchParams(event.search);
    const hasNewFavoriteId = Boolean(urlParams.get('favoriteId'));

    if (hasNewFavoriteId) {
      return true;
    }
    if (stateChanged) {
      setOpenLeaveDialog(true);
      const path = event.pathname + event.search;
      setTriggerExit((prevState) => ({ ...prevState, path }));
      if (triggerExit.shouldLeave) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let unblock: any;
    if (stateChanged) {
      if (triggerExit.shouldLeave) {
        handleGoToIntendedPage(triggerExit.path);
      }
      if (!unblock) {
        window.addEventListener('beforeunload', (event) => {
          event.preventDefault();
          event.returnValue = '';
        });
        unblock = history.block((location): any => handleUnload(location));
      }
    } else {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    }

    return () => {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.shouldLeave,
    triggerExit.path,
    stateChanged,
    query.market,
    query.favoriteId,
  ]);

  const Dashboard = useMemo(
    () => (
      <GridLayout
        key={`grid-${query.market}`}
        state={state}
        setState={setState}
        setStateChanged={setStateChanged}
        layouts={layouts}
        allowPersonalization={allowPersonalization}
      />
    ),
    [state, allowPersonalization],
  );

  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        margin: '0',
      }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={'#FFF'}
        gap={2}
        pt={2.5}
        pb={1.375}
        px={2.25}
        sx={(theme) => ({
          overflowX: 'auto',
          overflowY: 'hidden',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        })}>
        <Stack
          direction={'row'}
          gap={2}
          alignItems={'center'}
          sx={{
            whiteSpace: 'nowrap',
            '& > .MuiButtonGroup-root, & > span > .MuiButtonBase-root': {
              fontWeight: '700',
              fontSize: '14px',
              paddingInline: '0',
              boxShadow: '0px 4px 4px 0px #00000026',
              height: '48px',
              margin: '0',
              backgroundColor: 'transparent',
              minWidth: 'fit-content',
              '.MuiSvgIcon-root': {
                fontSize: '20px',
              },
            },
            '& > span > .MuiButtonBase-root': {
              padding: '16px 8px 16px 16px',
            },
          }}>
          <RadarFavorite
            state={state}
            setState={setState}
            mode={query.mode}
            initialState={INITIAL_STATE}
            favoriteRadars={favoriteRadars}
            loadingFavoriteRadar={isLoadingFavoriteRadar}
            selectedFavoriteRadar={selectedFavoriteRadar}
            setSelectedFavoriteRadar={setSelectedFavoriteRadar}
            handleOpenModal={handleOpenModal}
            setSelectedRemoveFavoriteRadar={setSelectedRemoveFavoriteRadar}
            stateChanged={stateChanged}
            disabled={
              !hasPermission || (!stateChanged && favoriteRadars?.length === 0)
            }
            refetchFavoriteRadar={refetchFavoriteRadar}
          />
          <DashboardModule
            INITIAL_STATE={INITIAL_STATE}
            state={state}
            setState={setState}
            disabled={!allowPersonalization}
            isLoading={isLoading}
          />
        </Stack>
        <Button
          color="primary"
          variant="contained"
          sx={{
            minWidth: 'unset',
            textWrap: 'nowrap',
            height: '48px',
            borderRadius: '5px',
            color: '#FFF',
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '25.2px',
          }}
          onClick={() => setAllowPersonalization((prev) => !prev)}
          disabled={!hasPermission}>
          {allowPersonalization
            ? 'Sair do modo de personalização'
            : 'Personalizar visualização'}
        </Button>
      </Stack>
      <Box
        p={2}
        bgcolor={'#EDEDED'}
        position={'relative'}
        sx={{
          '& .react-resizable-handle': {
            width: '31px',
            height: 'unset',
            mr: 1,
            mb: 1,
            aspectRatio: '1',
            borderBottomRightRadius: '5px',
            clipPath: 'polygon(100% 0,0 100%,100% 100%)',
            WebkitMask:
              'radial-gradient(5px at bottom 5px left calc(5px/tan(22.5deg)),#000 98%,#0000 101%), radial-gradient(5px at right  5px top  calc(5px/tan(22.5deg)),#000 98%,#0000 101%), conic-gradient(from 112.5deg at calc(100% - calc(50%*(1 + 1/tan(22.5deg)) - 5px/(3*sqrt(2) - 4))) calc(100% - calc(50%*(1 + 1/tan(22.5deg)) - 5px/(3*sqrt(2) - 4))),#000 45deg,#0000 0)',
            background: '#FF8211',
          },
          '& .react-resizable-handle::after': {
            content: 'unset',
          },
        }}>
        {!hasPermission ? (
          <Box
            sx={{
              position: 'relative',
              minHeight: '300px',
            }}>
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                top: '10%',
                left: 0,
                px: '10px',
              }}>
              <CardDemonstration
                title={contentPayWallBanner?.['radarDashboard']?.title}
                subTitle={contentPayWallBanner?.['radarDashboard']?.subTitle}
                labelButton={
                  contentPayWallBanner?.['radarDashboard']?.labelButton
                }
                url={contentPayWallBanner?.['radarDashboard']?.url}
              />
            </Box>
          </Box>
        ) : isLoading ? (
          <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress size="3rem" color="primary" />
          </Box>
        ) : (
          Dashboard
        )}
      </Box>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={`Tem certeza que deseja excluir o filtro ${selectedRemoveFavoriteRadar?.name}?`}
        subTitle={''}
        submitAction={handleDeleteFavoriteRadar}
        hasActionButton={Boolean(handleDeleteFavoriteRadar)}
      />
      <ConfirmLeaveDialog
        title="Tem certeza que deseja sair da personalização?"
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={() => {
          setTriggerExit((obj) => ({
            ...obj,
            shouldLeave: true,
          }));
          setOpenLeaveDialog(false);
        }}
        labelButton="Continuar edição"
      />
    </Box>
  );
}
