import { useEffect, useState } from 'react';

import { Box, Paper } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';

import HeaderInstitutions from './HeaderInstitutions';
import TableInstitutions from './TableInstitutions';
import { AllInstitutionsStyles } from './styles';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { useAuthContext } from '../../context/AuthContextProvider';
import { filterInstitutions } from '../../services/elastic';
import { getInformativeTextByLocation } from '../../services/informative';

export default function AllInstitutions() {
  const { auth } = useAuthContext();
  const classes = AllInstitutionsStyles();
  const [filters, setFilters] = useState({
    keyword: '',
    segments: [],
    markets: [],
  });
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState({ 'name.keyword': 'asc' });
  const [lastFound, setLastFound] = useState(null);
  const [sortHistory, setSortHistory] = useState({});
  const [total, setTotal] = useState(0);
  const [institutions, setInstitutions] = useState([]);

  const { data } = useQuery('info', () =>
    getInformativeTextByLocation('institution'),
  );

  const filteredInstitutions = useMutation(filterInstitutions, {
    onSuccess: ({ data }) => {
      let filteredResult;
      if (filters?.keyword) {
        filteredResult = data.data.filter(
          ({ name, cnpj }) =>
            name?.toUpperCase().includes(filters.keyword.toUpperCase()) ||
            (!!filters.keyword?.replace(/\D/g, '') &&
              cnpj
                ?.replace(/\D/g, '')
                ?.includes(filters.keyword?.replace(/\D/g, ''))),
        );
      }
      setInstitutions(filteredResult ?? data.data);
      setTotal(data.total);
    },
  });

  useEffect(() => {
    if (!auth.isLoading) {
      filteredInstitutions.mutate({
        data: {
          filters,
          size,
          sort,
          lastFound,
          userId: auth?.user?.id,
        },
      });
    }
  }, [filters, lastFound, size, sort, auth]);

  const { isLoading } = filteredInstitutions;

  return (
    <Box>
      <MetaTags
        title="Instituições dos Mercados de Crédito e de Capitais"
        description="Encontre as principais envolvidas nos mercados de crédito e de capitais. Pesquise por nome e filtre por mercado e segmento de atuação."
      />
      <Subheader
        title="Instituições"
        breadcrumbs1="Instituições"
        tooltipText={data?.data?.institution?.informationText}
      />
      <Paper className={classes.paper}>
        <HeaderInstitutions
          classes={classes}
          setFilters={setFilters}
          filters={filters}
          setLastFound={setLastFound}
          setSortHistory={setSortHistory}
        />
        <TableInstitutions
          classes={classes}
          rows={institutions}
          loading={isLoading}
          sortHistory={sortHistory}
          setSortHistory={setSortHistory}
          setLastFound={setLastFound}
          setSize={setSize}
          setSort={setSort}
          total={total}
          size={size}
          filters={filters}
        />
      </Paper>
    </Box>
  );
}
