// Material ui components
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { Skeleton } from '@material-ui/lab';
import Avatar from 'react-avatar';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import SubtitleLink from '../../../components/SubtitleLink';
import { getInstitutionsToHome } from '../../../services/institution';
import { getSmallestImageUrl } from '../../../utils/image';
import { truncateText } from '../../../utils/string';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    minHeight: '480px',
    maxHeight: '480px',
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  rowMarkets: {
    fontWeight: 500,
    fontSize: '14px',
    margin: theme.spacing(0, 1, 0, 0),
  },
  rowSegments: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#797979',
    fontSize: '14px',
  },
  avatar: {
    '& > img': {
      border: '1px solid #C2C2C2',
      objectFit: 'contain',
    },
  },
  blur: {
    '-webkit-filter': 'blur(5px)',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

export default function Institutions({ isLoggedIn }) {
  const classes = useStyles();

  const {
    data: institutions,
    isLoading: loading,
    error,
  } = useQuery('institutionsHome', () => getInstitutionsToHome());

  if (error) return null;

  const sortMarkets = (markets) => {
    const sortedArray = [...markets];
    return sortedArray?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <SubtitleLink
        title="Instituições"
        url="/instituicoes"
        labelLink="Ver todas as instituições"
      />
      <Box py={3.3}>
        {loading && (
          <Skeleton variant="rect" animation="wave" width="100%" height={370} />
        )}
        <TableContainer>
          <Table aria-label="institutions table">
            <TableBody>
              {institutions?.map((row, index) => (
                <TableRow
                  key={row.id}
                  className={index === 2 ? classes.tableRow : ''}
                  style={{
                    minHeight: '116.1px',
                    height: '116.1px',
                  }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      width: '60px',
                      verticalAlign: 'middle',
                    }}>
                    {row.logo ? (
                      <Avatar
                        round
                        size="60"
                        maxInitials={2}
                        name={row.fantasyName}
                        src={getSmallestImageUrl(row?.logo)}
                        className={classes.avatar}
                      />
                    ) : (
                      <Avatar
                        round
                        size="60"
                        name={row.fantasyName}
                        className={classes.avatar}>
                        <BusinessIcon fontSize="large" />
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      minWidth: '240px',
                      verticalAlign: 'middle',
                    }}>
                    <RouterLink
                      to={`/instituicao/${row.slug}/${row.id}`}
                      className={classes.link}
                      title={row.fantasyName}>
                      {truncateText(row.fantasyName, 30)}
                    </RouterLink>
                    <Typography
                      className={!isLoggedIn ? classes.blur : undefined}>
                      {isLoggedIn ? row.cnpj : '000.000.000-00'}
                    </Typography>
                    <Grid container>
                      {sortMarkets(row.markets)?.map((market, index) => (
                        <Grid item key={`institution-${index}`}>
                          <Typography className={classes.rowMarkets}>
                            {market.name}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    <Typography className={classes.rowSegments}>
                      {row.segments
                        ?.slice(0, 2)
                        ?.map((segment) => segment.name)
                        ?.join(', ')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
