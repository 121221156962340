import { Button as ButtonMui } from '@material-ui/core';
import styled from 'styled-components';

import { Tabs } from '../../components/Tab/styles';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;
`;

type WrapperProps = {
  image: string;
  hide: boolean;
};

export const Main = styled.div`
  height: calc(100vh - 30px);
  display: flex;
  transition: background-image 1.5s ease-in-out;
  background-color: white;
`;

export const BackgroundImage = styled.div<WrapperProps>`
  position: absolute;
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: ${(props) => (props.hide ? '0' : '1')};
  transition: all 1.5s linear;
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
`;

export const LogoWrapper = styled.div`
  margin: auto;
  max-width: 50%;
  z-index: 997;

  svg {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  width: 100%;
  max-width: 1024px;
  margin-bottom: 80px;
  align-self: center;
`;

export const Button = styled(ButtonMui)`
  height: 84px;
  border-color: #ff8811;
  border-width: 2px;
  font-size: 40px;
  color: #ff8811;
  font-weight: 700;

  &:hover {
    height: 84px;
    border-color: white;
    border-width: 2px;
    font-size: 40px;
    color: #ff8811;
    font-weight: 700;
    background-color: rgba(225, 225, 225, 0.8);
  }
`;

type TabsProps = {
  headermode: boolean;
  ontransition: boolean;
  hide: boolean;
};

export const TabWrapper = styled.div<TabsProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 150px;
  transition: all 1.5s ease 0s;
  display: ${(props) => (props.hide ? 'none' : 'block')};
  z-index: 999;

  @keyframes hideShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  ${(props) =>
    props.headermode
      ? `
      top: 0;
      right: 0;
      width: 640px;

      ${Tabs} {
        &.MuiTabs-root {
          & > .MuiTabs-scroller {
            & > .MuiTabs-flexContainer {
              button {
                span {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .MuiTabs-indicator {  
          display: ${props.headermode ? 'none' : 'block'}
        }
   
      }
 
      `
      : `
      top: 80%;
      right: 0;

  `}

  @media screen and (max-width: 1000px) {
    top: ${(props) => (props.headermode ? '30px' : '80%')};
    right: ${(props) => (props.headermode ? 'calc(50% - 340px)' : '0')};
  }
`;

export const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
`;
