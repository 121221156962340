import styled from 'styled-components';

export const List = styled.u`
  font-size: 14px;
  text-decoration: none;

  li {
    margin: 5px;
  }

  ul {
    margin: 0;
    list-style-type: lower-alpha;
  }
`;
