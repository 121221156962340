import { useState } from 'react';

import {
  Alert,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import uniq from 'lodash/uniq';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import { notify } from 'src/pages/Calculator/utils/handleNotify';
import { getFundClassAndSeries, getFundsHistory } from 'src/services/operation';

const PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 12,
  },
  {
    label: '24 Meses',
    value: 24,
  },
  {
    label: 'Tudo',
    value: null,
  },
];

export default function OperationFIDCLiabilityRentabilityHistory({
  cnpj,
  name,
}) {
  const theme = useTheme();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartXAxis, setChartXAxis] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(12);
  const [classSerieSelected, setClassSerieSelected] = useState('');
  const [classAndSeriesOptions, setClassAndSeriesOptions] = useState([]);

  useQuery(
    ['fund-classes', cnpj],
    () => getFundClassAndSeries({ cnpj, name }),
    {
      onSuccess: (data) => {
        if (!data.length) {
          setClassAndSeriesOptions([]);
          setClassSerieSelected('');
          return;
        }

        const classAndSeries = uniq(
          data.map(({ classe_serie }) => classe_serie),
        );

        const initialClassSerie = classAndSeries[0];

        setClassAndSeriesOptions(classAndSeries);
        setClassSerieSelected(initialClassSerie);
      },
    },
  );

  const { isLoading } = useQuery(
    ['fund-histories', cnpj, selectedPeriod, classSerieSelected],
    () =>
      getFundsHistory({
        CNPJs: [cnpj],
        period: selectedPeriod,
        classSerie: classSerieSelected,
      }),
    {
      onSuccess: (histories) => {
        const formattedHistories = histories
          .map(({ data_competencia, rentabilidade_mensal }) => ({
            data_competencia: new Date(data_competencia),
            rentabilidade_mensal,
          }))
          .sort((a, b) => a.data_competencia - b.data_competencia);

        const allDates = formattedHistories.map(({ data_competencia }) =>
          format(data_competencia, 'MM/yyyy', { locale: ptBR }),
        );

        const rentabilityHistory = formattedHistories.map(
          ({ rentabilidade_mensal }) => rentabilidade_mensal,
        );

        setChartSeries([
          {
            name,
            data: rentabilityHistory,
            color: theme.palette.primary.main,
          },
        ]);
        setChartXAxis(allDates);
      },
      onError: () => notify('Erro ao buscar histórico do fundo A', 'error'),
    },
  );

  const handleClickPeriod = (period) => {
    setSelectedPeriod(period);
  };

  const handleChangeClassSerie = (event) => {
    const { value } = event.target;
    setClassSerieSelected(value);
  };

  return (
    <div>
      <Typography mt={4} variant="h6">
        -Histórico de rentabilidade
      </Typography>
      <Stack my={2} flexDirection="row" justifyContent="space-between" gap={1}>
        <Select
          placeholder="Classe"
          value={classSerieSelected}
          onChange={handleChangeClassSerie}>
          {classAndSeriesOptions.map((classAndSeriesOption) => (
            <MenuItem key={classAndSeriesOption} value={classAndSeriesOption}>
              {classAndSeriesOption
                .replace(/\?nica/g, 'Única')
                .replace(/\?nico/g, 'Único')
                .replace(/S\?rie/g, 'Série')}
            </MenuItem>
          ))}
        </Select>
        <Stack my={2} flexDirection="row" gap={1}>
          {!isLoading &&
            PERIOD_OPTIONS.map((option) => (
              <Typography
                key={option.value}
                sx={{
                  cursor: 'pointer',
                  textDecoration:
                    option.value === selectedPeriod ? 'underline' : 'none',
                }}
                variant="caption"
                onClick={() => handleClickPeriod(option.value)}>
                {option.label}
              </Typography>
            ))}
        </Stack>
      </Stack>
      <Stack mb={2}>
        <Alert severity="info">
          Os dados de rentabilidade das cotas de FIDC apresentados são
          provenientes dos informes mensais disponibilizados à Comissão de
          Valores Mobiliários (CVM). A Uqbar não se responsabiliza por eventuais
          divergências ou inconsistências nas informações fornecidas pelos
          administradores dos fundos.
        </Alert>
      </Stack>
      <ReactApexChart
        series={chartSeries}
        options={{
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: '',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: chartXAxis,
          },
        }}
      />
    </div>
  );
}
