import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
`;
