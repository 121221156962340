import theme from 'src/theme';
import styled from 'styled-components';

import { Advertisement } from '../../components/Advertisement';

export const Section = styled.section`
  padding: 2rem 0 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding-top: 0;
    gap: 2rem;
  }
`;

export const Ads = styled(Advertisement)`
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: 1rem !important;
  }
`;
