import { Event } from './event';

export interface AdvertisementPopUp {
  event: Event;
  image: any;
  title: string;
  description: string;
  content: string;
  CTAs: CTAPopUp[];
  publishedAt: string;
}

export enum CTAPopUpType {
  BUTTON_API = 'button_api',
  BUTTON_LINK = 'button_link',
  NOT_INTERESTED_CHECK = 'not_interested_check',
}

export interface CTAPopUp {
  type: CTAPopUpType;
  label: string;
  labelColor: string;
  backgroundColor: string;
  actionURL: string;
}
