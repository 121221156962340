import {
  Card,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  imageTitleTopTextTop: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      padding: theme.spacing(0.75, 1.5),
    },
  },
  imageTitleTopTitle: {
    padding: theme.spacing(6, 2),
    fontWeight: theme.typography.fontWeightBold,
    '& span': {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
    },
    lineHeight: '1.73em',
    fontSize: '2.125rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      padding: theme.spacing(2),
    },
  },
  media: {
    height: '100%',
  },
}));

export default function ImageTitleTop({ title, image, type, ...other }) {
  const classes = useStyles();
  return (
    <Card elevation={0} square {...other}>
      <CardMedia
        image={image}
        src="img"
        title={title}
        alt={title}
        className={classes.media}>
        <Grid container>
          <Grid item className={classes.type}>
            {type && (
              <Typography className={classes.imageTitleTopTextTop}>
                {type}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Typography
          componente="h1"
          variant="h1"
          className={classes.imageTitleTopTitle}>
          <span>{title}</span>
        </Typography>
      </CardMedia>
    </Card>
  );
}

ImageTitleTop.propTypes = {
  title: PropTypes.string,
  textTop: PropTypes.string,
  image: PropTypes.string,
  height: PropTypes.string,
};
