import { useState } from 'react';

import {
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Avatar from 'react-avatar';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { If } from 'src/components/If';

import { useTableRankingStyles } from './styles';
import CardDemonstration from '../../../components/CardDemonstration/CardDemonstration';
import CollapseTableRanking from '../../../components/CollapseTableRanking';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { useEmbedContext } from '../../../context/EmbedContextProvider';
import { addFavorite, removeFavorite } from '../../../services/favorite';
import { getPayWallBanner } from '../../../services/informative';
import { duplicateArray } from '../../../utils/array';
import { convertValue } from '../../../utils/number';
import { getComparator, stableSort } from '../../../utils/table';
import ExportSelect from '../ExportSelect';

const noDetailsCriterias = ['debitbalance'];

export const TableRanking = ({
  rank,
  valueLabel,
  state,
  hasConsolidated,
  month,
  searchName,
  criteriaLabel,
  rankingLabel,
  numberCriterias,
  loading,
  percentageCriterias,
  openLinkNewTab = false,
  handlePrint,
  yearbookMode = false,
  exportDisabled = false,
}) => {
  const { widgetKey } = useEmbedContext();
  const { auth } = useAuthContext();
  const classes = useTableRankingStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('position');
  const hasPermission =
    !widgetKey &&
    auth?.user?.subscription?.plan?.permissions['rankings_completo'];
  const maxVisibleRanking =
    hasPermission || rank?.length < 5 ? rank?.length : 5;

  const hasDetailsPermission =
    auth?.user?.subscription?.plan?.permissions['rankings_detalhes'];

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('rank'),
    {
      enabled: !widgetKey,
    },
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatNumberByCriteria = (criteria, value) => {
    if (criteria === 'numeric')
      return convertValue(value * 1e9, null, false, 'decimal', undefined, 0, 0);
    return value;
  };

  const getFilteredRank = () => {
    const result = stableSort(rank, getComparator(order, orderBy))?.filter(
      ({ name }) => name?.match(new RegExp(searchName, 'i')),
    );

    if (yearbookMode) {
      if (Array.isArray(result)) {
        return result.slice(0, 3);
      }
      return result;
    }

    return hasPermission || widgetKey ? result : duplicateArray(result);
  };

  const renderCell = (column, label) => {
    if (widgetKey) {
      return label;
    }
    return (
      <TableSortLabel
        active={true}
        IconComponent={() => <ImportExportIcon color="primary" />}
        direction={orderBy === column ? order : 'asc'}
        onClick={(e) => handleRequestSort(e, column)}
        disabled={!hasPermission}>
        {label}
      </TableSortLabel>
    );
  };

  function Row({ row, canVisible, rowIndex }) {
    const [open, setOpen] = useState(false);
    const [isFavorited, setIsFavorited] = useState(row.favorited?.id);
    const canFavorite =
      !widgetKey && auth.isLoggedIn && (row.operationId || row.institutionId);

    const addFavoriteMutation = useMutation(addFavorite, {
      onSuccess: ({ data }) => setIsFavorited(data?.id),
    });

    const { isLoading: addFavoriteLoading } = addFavoriteMutation;

    const removeFavoriteMutation = useMutation(removeFavorite, {
      onSuccess: () => setIsFavorited(undefined),
    });

    const { isLoading: removeFavoriteLoading } = removeFavoriteMutation;

    const handleAddFavorite = (type, id) => {
      if (!addFavoriteLoading && !removeFavoriteLoading) {
        addFavoriteMutation.mutate({
          data: {
            user: auth?.user?.id,
            [type]: parseInt(id),
          },
        });
      }
    };

    const handleRemoveFavorite = (id) => {
      if (!addFavoriteLoading && !removeFavoriteLoading) {
        removeFavoriteMutation.mutate(id);
      }
    };

    return (
      <>
        <TableRow
          className={`${classes.tableRow} ${canVisible ? '' : classes.blur}`}>
          {!widgetKey && (
            <TableCell>
              {!yearbookMode && (
                <Tooltip
                  enterTouchDelay={10}
                  disableFocusListener
                  disableTouchListener={
                    !!row.operationId || !!row.institutionId
                  }
                  disableHoverListener={
                    !!row.operationId || !!row.institutionId
                  }
                  arrow
                  title={
                    !auth.isLoggedIn
                      ? 'Necessário estar logado para favoritar esse item'
                      : 'Não é possível favoritar esse item'
                  }
                  placement="right">
                  <span>
                    <IconButton
                      disabled={
                        !canFavorite ||
                        addFavoriteLoading ||
                        removeFavoriteLoading
                      }
                      onClick={
                        isFavorited
                          ? () => handleRemoveFavorite(isFavorited)
                          : () =>
                              handleAddFavorite(
                                row.operationId ? 'operation' : 'institution',
                                row.operationId || row.institutionId,
                              )
                      }>
                      {isFavorited ? (
                        <StarIcon fontSize="small" color="secondary" />
                      ) : (
                        <StarBorderIcon
                          fontSize="small"
                          color={!canFavorite ? 'disabled' : 'secondary'}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </TableCell>
          )}
          <TableCell className={classes.textBold}>{row.position}</TableCell>
          <TableCell
            align="center"
            style={{ padding: yearbookMode ? '25px 0px' : '50px 16px' }}>
            <Avatar
              round
              size="60"
              maxInitials={2}
              name={row.name}
              src={row.logo}
              className={classes.avatar}
            />
          </TableCell>
          <TableCell style={{ width: 300 }}>
            {row.operationId && canVisible ? (
              <RouterLink
                to={{
                  pathname: `/operacao/${state.market.toLowerCase()}/${
                    row.slug
                  }/${row.uqbarId}`,
                  state: { state, id: row.id, hasConsolidated },
                }}
                className={classes.link}
                target={openLinkNewTab ? '_blank' : ''}>
                {row.name}
              </RouterLink>
            ) : row.institutionId && canVisible ? (
              <RouterLink
                underline="none"
                to={{
                  pathname: `/instituicao/${row.slug}/${row.institutionId}`,
                  state: { state, id: row.id, hasConsolidated },
                }}
                className={classes.link}
                target={openLinkNewTab ? '_blank' : ''}>
                {row.name}
              </RouterLink>
            ) : (
              row.name
            )}
          </TableCell>
          <TableCell>
            {percentageCriterias?.includes(state.criteria)
              ? '-'
              : `${convertValue(row.percentage, null, false, 'decimal', 'BRL', 2, 2, true).trim()}%`}
          </TableCell>
          <TableCell>
            {numberCriterias?.includes(state.criteria)
              ? formatNumberByCriteria(state.criteria, row.value)
              : valueLabel.includes('Montante')
                ? `${convertValue(row.value, null, true, 'currency')}`
                : `${convertValue(row.value, null, false, 'decimal')}`}
          </TableCell>
          {!yearbookMode &&
            !widgetKey &&
            !noDetailsCriterias.includes(state.criteria) && (
              <TableCell align="right">
                <Tooltip
                  disableFocusListener
                  disableTouchListener={canVisible && hasDetailsPermission}
                  disableHoverListener={canVisible && hasDetailsPermission}
                  arrow
                  title={'Disponível apenas para planos pagos'}
                  placement="left"
                  classes={{ tooltip: classes.tooltip }}>
                  <span>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                      placeholder="Detalhe"
                      color="secondary"
                      disabled={!canVisible || !hasDetailsPermission}
                      className={classes.detailButton}>
                      {open ? (
                        <>
                          <Typography>Detalhe</Typography>
                          <ArrowDropUpIcon />
                        </>
                      ) : (
                        <>
                          <Typography>Detalhe</Typography>
                          <ArrowDropDownIcon />
                        </>
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            )}
        </TableRow>
        {!widgetKey && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <CollapseTableRanking
                open={open}
                itemId={row.uqbarId}
                state={state}
                hasConsolidated={hasConsolidated}
                month={month}
                year={parseInt(state.year, 10)}
              />
            </TableCell>
          </TableRow>
        )}
        {!yearbookMode &&
          !widgetKey &&
          !hasPermission &&
          rowIndex === maxVisibleRanking && (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <CardDemonstration
                title={contentPayWallBanner?.rank?.title}
                subTitle={contentPayWallBanner?.rank?.subTitle}
                labelButton={contentPayWallBanner?.rank?.labelButton}
                url={contentPayWallBanner?.rank?.url}
              />
            </TableCell>
          )}
      </>
    );
  }

  if (loading) return <CircularProgress color="secondary" />;

  return (
    <>
      <Hidden smUp>
        <Box display="flex" justifyContent="flex-end">
          <If condition={!exportDisabled}>
            <ExportSelect
              hasPermission={hasPermission}
              data={getFilteredRank()}
              state={state}
              valueLabel={valueLabel}
              criteriaLabel={criteriaLabel}
              rankingLabel={rankingLabel}
              handlePrint={handlePrint}
            />
          </If>
        </Box>
      </Hidden>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="ranking table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {!widgetKey && <TableCell />}
              <TableCell>{renderCell('position', '')}</TableCell>
              <TableCell />
              <TableCell className={classes.textHead}>
                {renderCell('name', 'Nome')}
              </TableCell>
              <TableCell className={classes.textHead}>
                {renderCell('percentage', 'Percentual')}
              </TableCell>
              <TableCell className={classes.textHead}>
                {renderCell('value', valueLabel)}
              </TableCell>
              {!yearbookMode && !widgetKey && (
                <TableCell align="right">
                  <Hidden xsDown>
                    <If condition={!exportDisabled}>
                      <ExportSelect
                        hasPermission={hasPermission}
                        data={getFilteredRank()}
                        state={state}
                        valueLabel={valueLabel}
                        criteriaLabel={criteriaLabel}
                        rankingLabel={rankingLabel}
                        handlePrint={handlePrint}
                      />
                    </If>
                  </Hidden>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rank?.length ? (
              getFilteredRank().map((row, index) => (
                <Row
                  key={row.position}
                  row={row}
                  canVisible={index < maxVisibleRanking}
                  rowIndex={index}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  style={{ border: 'none' }}
                  colSpan={7}>
                  Não encontramos dados para este ranking.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
