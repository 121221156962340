import { api, publicApi } from '../../api/api';
import { Invitation } from '../../types/user';

export const getInvitationByToken = async (
  token: string,
): Promise<Invitation> => {
  const { data } = await publicApi.get(`/invite/${token}`);
  if (data) return data as Invitation;
  throw new Error('Convite inválido');
};

export const createInvitation = async (values: any) => {
  const { data } = await api.post('/invite', values);
  return data;
};

export const resendInvitation = async (id: number) => {
  const { data } = await api.patch(`/invite/${id}`);
  return data;
};

export const cancelInvitation = async (id: number) => {
  const { data } = await api.delete(`/invite/${id}`);
  return data;
};

export const removeInvitedUser = async (id: number) => {
  const { data } = await api.delete(`/invite/${id}`);
  return data;
};

export const acceptInvitation = async (token: string) => {
  const { data } = await publicApi.post(`/invite/${token}/accept`);
  return data;
};

export const rejectInvitation = async (token: string) => {
  const { data } = await publicApi.post(`/invite/${token}/reject`);
  return data;
};
