import { makeStyles } from '@material-ui/core';

export const useTableRankingStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  textHead: {
    fontWeight: 700,
  },
  blur: {
    '-webkit-filter': 'blur(5px)',
  },
  avatar: {
    '& > img': {
      border: '1px solid #C2C2C2',
      objectFit: 'contain',
    },
  },
  detailButton: {
    '@media print': {
      display: 'none',
    },
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));
