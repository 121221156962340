import { useState } from 'react';

import {
  Avatar,
  Box,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { Carrousel } from '../../../components/Carrousel';
import { getTestemonials } from '../../../services/testimonials';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 4),
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  description: {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#47484C',
    textAlign: 'center',
  },
  item: {
    maxWidth: 410.66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(7, 5, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(7, 2, 0),
    },
  },
  itemName: {
    fontSize: '1.25rem',
    fontWeight: 500,
    textAlign: 'center',
    margin: theme.spacing(3, 0),
  },
  avatar: {
    width: 150,
    height: 150,
  },
}));

function Testimonials() {
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const pageSize = isExtraSmall ? 1 : isSmall ? 2 : 3;
  const [page, setPage] = useState(0);

  const { data } = useQuery(['testemonials', page], () =>
    getTestemonials({ page: page + 1, pageSize }),
  );

  if (!data?.data.length) return null;

  return (
    <>
      {data?.data?.length > 0 && (
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.title}>
            O que nossos assinantes falam sobre a Uqbar
          </Typography>
          <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
            <Carrousel
              totalItems={data?.meta?.pagination?.total}
              page={page}
              setPage={setPage}>
              {data.data.map(({ id, avatar, name, testimony }) => (
                <Box key={id} className={classes.item}>
                  <Avatar
                    src={avatar.formats?.thumbnail?.url || avatar.url}
                    alt={name}
                    className={classes.avatar}
                  />
                  <Typography variant="h4" className={classes.itemName}>
                    {name}
                  </Typography>
                  <Typography className={classes.description}>
                    {testimony}
                  </Typography>
                </Box>
              ))}
            </Carrousel>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default Testimonials;
