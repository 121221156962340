// Manage date
// Material ui components
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import GetAppIcon from '@material-ui/icons/GetApp';
import { format, isValid } from 'date-fns';

// Images
import pdf from '../../assets/imgs/file-pdf-regular.svg';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  logo: {
    width: 45,
    height: '100%',
    backgroundSize: 'contain',
  },
  category: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap',
    width: 240,
    [theme.breakpoints.between('md', 'sm')]: {
      width: 280,
    },
    [theme.breakpoints.up('lg')]: {
      width: 260,
    },
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
  },
  description: {
    whiteSpace: 'nowrap',
    width: 250,
  },
  dividerSecondary: {
    height: 2,
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.5,
  },
  boxCategory: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

export default function CardServiceDocuments({
  category,
  description,
  created,
  urlDownload,
}) {
  const classes = useStyles();

  return (
    <Paper square className={classes.paper}>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <img src={pdf} alt="logo" className={classes.logo} />
          <Box className={classes.boxCategory}>
            <Typography
              component="div"
              variant="subtitle1"
              className={classes.category}>
              <Box title={category} textOverflow="ellipsis" overflow="hidden">
                {category}
              </Box>
            </Typography>
            <Typography
              component="div"
              variant="subtitle2"
              className={classes.description}>
              <Box textOverflow="ellipsis" overflow="hidden">
                {description}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.dividerSecondary} />
        <Grid container alignItems="center">
          <Grid item xs>
            <IconButton
              color="secondary"
              aria-label="url download"
              href={urlDownload}>
              <GetAppIcon />
            </IconButton>
          </Grid>
          <Grid item xs container justifyContent="flex-end">
            <Typography variant="caption">
              {isValid(created) && format(created, 'dd/MM/yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
