import { useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import PreviewLiveScreen from 'src/pages/Event/Presentation/PreviewLiveScreen';
import { getUnseenInLiveEvent } from 'src/services/events';
import { useLocalStorage } from 'src/utils/hooks';

import { LOCAL_STORAGE_NO_SHOW_KEY } from './constants';
import { Styled } from './styles';

export default function EventPresentationOngoingPopUp() {
  const { auth } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const [noShow, setNoShow] = useLocalStorage(LOCAL_STORAGE_NO_SHOW_KEY, []);
  const { data, isLoading } = useQuery(
    ['ongoing-events', auth?.user?.id],
    () => getUnseenInLiveEvent(noShow),
    {
      retry: false,
    },
  );

  const [closed, setClosed] = useState(false);
  const [redirectedTo, setRedirectedTo] = useState('');
  const isInEventPage = useMemo(
    () =>
      location.pathname.startsWith(
        `/evento/${data?.event?.slug}/${data?.event?.id}/palestra/${data?.id}/live`,
      ),
    [location, data],
  );

  const saveNoShowCheck = () => {
    setNoShow([...noShow, data.id]);
  };

  const handleClose = () => {
    saveNoShowCheck();
    setClosed(true);
  };

  const handleClick = async () => {
    let url = `/evento/${data.event.slug}/${data.event.id}/palestra/${data.id}/live`;
    if (!auth.isLoggedIn) {
      url = '/login';
    } else if (!data.subscribed) {
      url = `/evento/${data.event.slug}/${data.event.id}/programacao?presentationId=${data.id}`;
    }
    setRedirectedTo(url);
    history.replace(url);
    setClosed(true);
  };

  if (
    isLoading ||
    !data ||
    closed ||
    location.pathname === redirectedTo ||
    isInEventPage ||
    !data.event?.bannerImage?.url
  ) {
    return null;
  }

  const isNewAdvPopUp = !noShow?.includes(data.id);

  return (
    <Modal
      open={isNewAdvPopUp && !closed}
      onClose={handleClose}
      aria-labelledby="anuncio-pop-up-title"
      aria-describedby="anuncio-pop-up-description">
      <Styled.Wrapper>
        <Styled.Content>
          <Box
            sx={{
              position: 'absolute',
              top: 15,
              right: 15,
              cursor: 'pointer',
            }}>
            <Close onClick={handleClose} />
          </Box>
          <Styled.SideWrapper imgURL={data.event?.bannerImage?.url} />
          <Styled.SideWrapper>
            <Box
              sx={{
                p: { xs: '15px', md: '30px', lg: '60px' },
                overflowY: 'auto',
              }}>
              <Grid container spacing={2} gap={6}>
                <Grid item xs={12}>
                  <Typography
                    id="evento-pop-up-title"
                    variant="h5"
                    component="h1">
                    {data.event?.title}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {data && !!data.liveUrl && (
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      sx={{
                        py: 0,
                      }}>
                      <PreviewLiveScreen
                        presentation={data}
                        width="360px"
                        height="230px"
                      />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    id="evento-pop-up-title"
                    variant="h6"
                    component="h1"
                    textAlign="center">
                    O evento está acontecendo agora!
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid item>
                    <Button
                      onClick={handleClick}
                      fullWidth
                      variant="contained"
                      sx={{ fontSize: '18px' }}>
                      Assistir agora
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Styled.SideWrapper>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  );
}
