import { useState } from 'react';

import { Box, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Avatar from 'react-avatar';
import { Link as RouterLink } from 'react-router-dom';

import FacebookIcon from '../../../components/Icons/FacebookIcon';
import LinkedintIcon from '../../../components/Icons/LinkedinIcon';
import TwitterIcon from '../../../components/Icons/TwitterIcon';
import { If } from '../../../components/If';
import { formatUrl } from '../../../utils/string';

// styles
const useStyles = makeStyles((theme) => ({
  authorLink: {
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  seeMore: {
    fontSize: '0.9rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '& span': {
      marginLeft: theme.spacing(0.5),
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  socialIcons: {
    fontSize: 'large',
    margin: theme.spacing(0, 0.5),
  },
  socialLink: {
    margin: theme.spacing(0, 0.2),
  },
  profileText: {
    fontSize: '0.9rem',
    display: '-webkit-box',
    overflow: 'hidden',
    textAlign: 'justify',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  profileTextExpanded: {
    fontSize: '0.9rem',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));

export default function ArticleAuthorDetails({ author }) {
  const [seeMore, setSeeMore] = useState(false);
  const classes = useStyles();

  return (
    <div style={{ marginTop: '32px' }}>
      <Typography variant="h6">Sobre o Autor(a)</Typography>
      <Box display="flex" flexDirection="row" alignItems="start" py={1}>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Avatar
            name={`${author?.fullName}`}
            maxInitials={2}
            round
            size="100"
            src={author?.photo?.formats?.thumbnail?.url}
          />
          {(author?.linkedin ||
            author?.facebook ||
            author?.twitter ||
            author?.email) && (
            <Box display="flex" justifyContent="center" py={1}>
              {author?.linkedin?.showInPlatform && (
                <Link
                  target="_blank"
                  rel="noopener"
                  href={formatUrl(author?.linkedin?.url)}
                  className={classes.socialLink}>
                  <Paper square elevation={2}>
                    <LinkedintIcon
                      className={classes.socialIcons}
                      color="secondary"
                      titleAccess="Linkedin"
                    />
                  </Paper>
                </Link>
              )}
              {author?.facebook?.showInPlatform && (
                <Link
                  target="_blank"
                  rel="noopener"
                  href={formatUrl(author?.facebook?.url)}
                  className={classes.socialLink}>
                  <Paper square elevation={2}>
                    <FacebookIcon
                      className={classes.socialIcons}
                      color="secondary"
                      titleAccess="Facebook"
                    />
                  </Paper>
                </Link>
              )}
              {author?.twitter?.showInPlatform && (
                <Link
                  target="_blank"
                  rel="noopener"
                  href={formatUrl(author?.twitter?.url)}
                  className={classes.socialLink}>
                  <Paper square elevation={2}>
                    <TwitterIcon
                      className={classes.socialIcons}
                      color="secondary"
                      titleAccess="Twitter"
                    />
                  </Paper>
                </Link>
              )}
              {author?.email?.showInPlatform && (
                <Link
                  target="_blank"
                  rel="noopener"
                  href={`mailto: ${author?.email?.email}`}
                  className={classes.socialLink}>
                  <Paper square elevation={2}>
                    <MailOutlineIcon
                      className={classes.socialIcons}
                      color="secondary"
                      titleAccess="Email"
                    />
                  </Paper>
                </Link>
              )}
            </Box>
          )}
        </Box>
        <Box padding="16px 0px">
          <Link
            className={classes.authorLink}
            component={RouterLink}
            underline="none"
            to={`/profissional/${author.slug}/${author.id}`}>
            <Typography>
              <b>{author?.fullName}</b>
            </Typography>
          </Link>
          <Typography
            className={
              seeMore ? classes.profileTextExpanded : classes.profileText
            }>
            {author?.profileText}
          </Typography>
          <If condition={author?.profileText?.length > 170 && !seeMore}>
            <Typography
              className={classes.seeMore}
              onClick={() => setSeeMore(true)}>
              <AddCircleOutlineIcon fontSize="small" />
              <span>Ver mais</span>
            </Typography>
          </If>
          <If condition={author?.profileText?.length > 170 && seeMore}>
            <Typography
              className={classes.seeMore}
              onClick={() => setSeeMore(false)}>
              <RemoveCircleOutlineIcon fontSize="small" />
              <span>Ver menos</span>
            </Typography>
          </If>
        </Box>
      </Box>
    </div>
  );
}
