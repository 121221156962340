import { useEffect, useRef, useState } from 'react';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Box, Paper, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { Institution } from 'src/types/institution';
import { PayWall } from 'src/types/paywall';

import About from '../About';
import Address from '../Address';
import Contact from '../Contacts';
import Documents from '../Documents';
import { FeaturedOperations } from '../FeaturedOperations';
import { RelatedArticles } from '../RelatedArticles';
import { SegmentsAndMarkets } from '../SegmentsAndMarkets';
import Services from '../Services';

export default function InstitutionProfile({
  data,
  payWallBanner,
}: {
  data: Institution;
  payWallBanner?: PayWall;
}) {
  const history = useHistory();
  const [showTopButton, setShowTopButton] = useState(false);
  const servicesRef = useRef<null | HTMLDivElement>(null);
  const documentsRef = useRef<null | HTMLDivElement>(null);
  const searchParams = new URLSearchParams(history.location.search);
  const refScrollTo = searchParams.get('to') ?? '';

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  useEffect(() => {
    if (refScrollTo === 'servicos' && servicesRef.current) {
      window.scrollTo({
        top: servicesRef.current.offsetTop - 100,
        behavior: 'smooth',
      });
    }
    if (refScrollTo === 'documentos' && documentsRef.current) {
      window.scrollTo({
        top: documentsRef.current.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  }, [history.location]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <If condition={!data?.verified}>
        <Section>
          <Box padding="16px 0">
            <Typography fontSize={16} fontWeight={700} textAlign="center">
              Este perfil ainda não está cadastrado em nossa plataforma, caso
              seja o proprietário desta instituição clique em “reivindicar
              perfil”.
            </Typography>
          </Box>
        </Section>
      </If>
      <If condition={data?.verified}>
        <If condition={!!data?.segments?.length || !!data?.markets?.length}>
          <SegmentsAndMarkets
            segments={data?.segments}
            markets={data?.markets}
          />
        </If>
        <If condition={data?.businessProfile}>
          <About institution={data} />
        </If>
        <If
          condition={
            (!!data?.emails?.length || !!data?.phones?.length) && !data?.isFree
          }>
          <Contact institution={data} />
        </If>
        <If condition={data?.services?.length}>
          <Box ref={servicesRef}>
            <Services
              data={{
                services: data?.services,
                name: data?.fantasyName,
                image: data?.logo,
                ownerType: 'institution',
              }}
            />
          </Box>
        </If>
        <If condition={data?.featuredOperations?.length}>
          <FeaturedOperations featuredOperations={data.featuredOperations} />
        </If>
        <If condition={data?.documents?.length}>
          <Box ref={documentsRef}>
            <Documents institution={data} />
          </Box>
        </If>
        <If condition={!!data?.relatedArticles?.length}>
          <RelatedArticles institution={data} />
        </If>
        <If condition={!!data?.addresses?.length && !data?.isFree}>
          <Address institution={data} />
        </If>
        <If
          condition={
            data?.verified &&
            data?.isFree &&
            !data?.userLoggedIsInstitutionOwner
          }>
          <Box sx={{ order: 3, mt: 6 }}>
            <CardDemonstration
              title={payWallBanner?.institutionOwnerPlanFree?.title}
              subTitle={payWallBanner?.institutionOwnerPlanFree?.subTitle}
            />
          </Box>
        </If>
      </If>
      <If condition={showTopButton}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'sticky',
            bottom: 5,
            left: 0,
            zIndex: 999,
            marginTop: 5,
          }}>
          <Paper
            elevation={3}
            onClick={goToTop}
            sx={{
              backgroundColor: '#F1F1F1',
              opacity: 0.5,
              cursor: 'pointer',
              width: 62,
              height: 62,
              borderRadius: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                opacity: 1,
              },
            }}>
            <KeyboardArrowUpRoundedIcon sx={{ fontSize: 40 }} />
          </Paper>
        </Box>
      </If>
    </Box>
  );
}
