import theme from 'src/theme';
import styled from 'styled-components';

import * as YearbookSection from '../../components/Section';
import { Advertisement } from '../Advertisement';

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const Ads = styled(Advertisement)`
  margin: 2rem 0 !important;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 1rem 0 !important;
  }
`;

export const Content = styled.div`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const ContentParagraph = styled(YearbookSection.Paragraph)`
  text-align: justify;
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 1rem;
  }
  p {
    margin: 0;
  }
`;
