import { useEffect, useState } from 'react';

import { Share } from '@mui/icons-material';
import { Button } from '@mui/material';
import { CustomTooltip } from 'src/components/CustomTooltip';

function ShareButton({
  getUrlToShare,
  disabled,
}: {
  getUrlToShare: () => string;
  disabled: boolean;
}) {
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    let timer: any;
    if (openTooltip) {
      timer = setTimeout(() => {
        setOpenTooltip(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [openTooltip]);

  return (
    <CustomTooltip
      title="Link copiado"
      open={openTooltip}
      disableHoverListener
      disableTouchListener>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(getUrlToShare());
          setOpenTooltip(true);
        }}
        color="secondary"
        sx={(theme) => ({
          fontSize: '0.875rem',
          color: '#828282',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: theme.palette.grey.A100,
          },
        })}
        variant="text"
        disabled={disabled}>
        <Share
          fontSize="medium"
          color={disabled ? 'disabled' : 'primary'}
          style={{ cursor: 'pointer', marginRight: 4 }}
        />
        Compartilhar
      </Button>
    </CustomTooltip>
  );
}

export default ShareButton;
