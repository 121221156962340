import {
  Avatar,
  Box,
  Collapse,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function FacilitatorsDetails(
  { classes, state, setState },
  props,
) {
  return (
    <div {...props} className={classes.facilitatorsContainer}>
      <Collapse in={state.facilitatorsDetails}>
        <Paper square className={classes.facilitatorsPaper}>
          <Box p={1} display="flex" justifyContent="flex-end">
            <HighlightOffIcon
              fontSize="large"
              htmlColor="#FFFFFF"
              titleAccess="Fechar"
              onClick={() => setState({ ...state, facilitatorsDetails: false })}
            />
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid container justifyContent="center" item xs={12} md={2}>
              <Avatar
                alt={state.name}
                src={state.photo}
                className={classes.facilitatorsAvatarExtraLarge}
              />
            </Grid>
            <Grid item xs>
              <Box p={2} maxWidth={700}>
                <Typography
                  gutterBottom
                  className={classes.facilitatorsDetailsName}>
                  {state.name}
                </Typography>
                <Typography className={classes.facilitatorsDetailsDescription}>
                  {state.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </div>
  );
}
