import { useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { deleteImage } from 'src/services/gallery';
import { setBannerMessage } from 'src/store/display';
import { Image, Institution } from 'src/types/institution';

import PhotosList from './PhotosList';
import PhotosModal from './PhotosModal';

type Props = {
  institution: Institution | undefined;
  institutionRefetch: () => void;
};

function Photos({ institution, institutionRefetch }: Props) {
  const [openPhotosDialog, setOpenPhotosDialog] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleRemoveImage = (values: Image) => {
    if (values?.id) {
      deleteImageMutation.mutateAsync(values.id);
    }
  };

  const deleteImageMutation = useMutation(deleteImage, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível remover a imagem',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Imagem removida com sucesso',
          }),
        );
      }
      institutionRefetch();
    },
  });

  return (
    <Box sx={{ padding: '32px 24px 24px' }}>
      <Box>
        <Typography fontSize={14}>
          Adicione imagens que são exibidas na aba “galeria” em seu perfil
          institucional.
        </Typography>
        <Box sx={{ '& li~li': { marginTop: 2 } }}>
          <ul style={{ paddingLeft: '16px' }}>
            <li>Para adicionar imagens, faça upload de arquivos PNG e JPEG;</li>
            <li>Adicione legendas às imagens;</li>
            <li>As imagens devem ter até 2MB.</li>
            <li>Orientação da imagem recomendada: vertical</li>
          </ul>
        </Box>
        <Button
          startIcon={<AddCircle color="primary" fontSize="large" />}
          color="primary"
          onClick={() => setOpenPhotosDialog(true)}
          sx={{
            textTransform: 'none',
            textDecoration: 'underline !important',
            letterSpacing: 'unset',
            fontSize: '16px',
            fontWeight: 700,
            padding: 0,
            marginLeft: '-4px',
          }}>
          Adicionar foto
        </Button>
      </Box>
      <If condition={institution?.galleryImages?.length}>
        <PhotosList
          institution={institution}
          handleRemoveImage={handleRemoveImage}
          institutionRefetch={institutionRefetch}
        />
      </If>
      <PhotosModal
        open={openPhotosDialog}
        onClose={() => setOpenPhotosDialog(false)}
        institutionId={institution?.id}
        institutionRefetch={institutionRefetch}
      />
    </Box>
  );
}

export default Photos;
