import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useAuthContext } from '../../context/AuthContextProvider';

// Styles
const useStyles = makeStyles(() => ({
  container: {
    width: 1000,
    maxWidth: '100%',
  },
  formControl: {
    width: '100%',
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

function getFirstAvailableMarket(markets, data) {
  return markets?.filter(
    (marketName) => data[marketName].rankings.length > 0,
  )[0];
}

export default function RankingFilters({
  markets,
  state,
  setState,
  rankings,
  data,
  hasConsolidated,
  hasYear,
  hasCriteria,
  search,
  setSearch,
  typeRank,
}) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handleChange = (event) => {
    const select = event.target;

    const indexPrimaryFilter = rankings
      ?.map((data) => data.code)
      .indexOf(select.value);

    setState({
      ...state,
      ranking: select.value,
      criteria:
        state.market === 'FII' && select.value === 'manager'
          ? data[state.market].rankings[indexPrimaryFilter].criterias[1].code
          : data[state.market].rankings[indexPrimaryFilter].criterias[0].code,
    });
  };

  return (
    <Grid
      container
      spacing={isExtraSmall ? 1 : 4}
      alignItems="center"
      className={classes.container}>
      <Grid item xs={12} sm={6} md={typeRank === 'ranking_general' ? 2 : 4}>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id={'rankings_select'}>Ranking</InputLabel>
          <Select
            labelId={'rankings_select'}
            disabled={!getFirstAvailableMarket(markets, data)}
            value={state?.ranking}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'ranking' }}
            MenuProps={MENU_PROPS}>
            {rankings?.map(({ name, code }) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {hasCriteria && (
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id={'criteria_select'}>Critério</InputLabel>
            <Select
              labelId={'criteria_select'}
              disabled={!getFirstAvailableMarket(markets, data)}
              value={state?.criteria}
              onChange={(e) =>
                setState({
                  ...state,
                  criteria: e.target.value,
                })
              }
              inputProps={{ 'aria-label': 'criterias' }}
              MenuProps={MENU_PROPS}>
              {rankings
                ?.filter((filter) => filter.code === state.ranking)
                .map((filter) =>
                  filter.criterias?.map(({ name, code }) => (
                    <MenuItem key={code} value={code}>
                      {name}
                    </MenuItem>
                  )),
                )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {hasYear && (
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id={'period_select'}>Período</InputLabel>
            <Select
              labelId={'period_select'}
              value={state.year}
              onChange={(e) =>
                setState({
                  ...state,
                  year: e.target.value,
                })
              }
              inputProps={{ 'aria-label': 'year' }}
              MenuProps={MENU_PROPS}>
              {data &&
                data[state.market]?.years?.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {hasConsolidated && (
        <Grid item xs={12} sm={6} md={2}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id={'view_select'}>Visão</InputLabel>
            <Select
              labelId={'view_select'}
              value={state.timeRange}
              onChange={(e) =>
                setState({
                  ...state,
                  timeRange: e.target.value,
                })
              }
              inputProps={{ 'aria-label': 'year' }}
              MenuProps={MENU_PROPS}>
              <MenuItem value="yearly">Anual</MenuItem>
              <MenuItem value="consolidated">Acumulado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {typeRank === 'ranking_general' && (
        <Grid item xs={12} sm={6} md={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener={
              auth?.user?.subscription?.plan?.permissions.rankings_completo
            }
            disableHoverListener={
              auth?.user?.subscription?.plan?.permissions.rankings_completo
            }
            arrow
            title={
              'É necessário obter um plano para utilizar essa funcionalidade'
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}>
            <TextField
              className={classes.itemInput}
              placeholder="Ex: Uqbar"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              label="Busca por nome"
              disabled={
                !auth?.user?.subscription?.plan?.permissions.rankings_completo
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
