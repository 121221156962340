import { makeStyles } from '@material-ui/core';

export const OperationSecondaryStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  itemInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
  exportButton: {
    border: 'none',
  },
  exportIcon: {
    height: '20px',
    width: '20px',
    margin: theme.spacing(0, 1.5, 0, 0.5),
  },
  box: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  tableCell: {
    fontSize: '0.8em',
  },
  notificationBox: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    backdropFilter: 'blur(10px)',
  },
}));
