import { useEffect, useState } from 'react';

import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import OperationCharts from 'src/components/OperationCharts';
import SubTitle from 'src/components/v2/SubTitle';
import { WalletReceivable } from 'src/types/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  data: WalletReceivable[];
  selectOptions: string[] | undefined;
  operationName: string;
  hasPermission: boolean | undefined;
  isLoading: boolean;
};

function OperationCRAAssetReceivablesPayment({
  data,
  selectOptions,
  operationName,
  hasPermission,
  isLoading,
}: Props) {
  const [period, setPeriod] = useState('');
  const market = 'cra';
  const indicator = 'pre pagamento';

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string);
  };

  useEffect(() => {
    if (selectOptions?.length) {
      setPeriod(selectOptions[0]);
    }
  }, [selectOptions]);

  const handleExport = () => {
    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      filename: `${operationName}_${market}_${indicator}`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Data de referência', 'Pré-pagamentos', 'Nome da operação'],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (data) {
      const dataToExport = data.map((row: WalletReceivable) => ({
        dataReferencia: format(
          parse(row.dataReferencia, 'yyyy-MM-dd', new Date()),
          'MM/yyyy',
        ),
        prePagamentos: convertNumber(row.prePagamentos ?? 0),
        operationName,
      }));

      csvExporter.generateCsv(dataToExport);
    }
  };

  const selectedData = data?.filter((op) => op.dataReferencia === period).at(0);

  const series = [
    {
      data: data
        ?.sort((a, b) => {
          return a.dataReferencia > b.dataReferencia
            ? 1
            : a.dataReferencia < b.dataReferencia
              ? -1
              : 0;
        })
        ?.map((op) => op.prePagamentos),
    },
  ];

  const xAxisCategories = selectOptions?.length ? [...selectOptions] : [];

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: 'PDDWallet',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#E0E0E0',
          fillColor: '#E0E0E0',
          opacity: 0,
        },
      ],
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: '#F28181',
            },
          ],
        },
      },
    },
    noData: {
      text: 'Nenhum dado encontrado',
    },
    xaxis: {
      categories: xAxisCategories
        ?.sort((a, b) => {
          return a > b ? 1 : a < b ? -1 : 0;
        })
        .map((date) =>
          format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/yyyy'),
        ),
    },
    colors: ['#FDA85E'],
    tooltip: {
      theme: 'dark',
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => convertValue(value),
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <Box>
      <SubTitle title="Pré-pagamento" />
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {hasPermission ? (
            <OperationCharts
              title="Pré-pagamentos"
              subtitle={`${convertValue(selectedData?.prePagamentos ?? 0)}`}
              valueSelect={period}
              handleChangeSelect={handleChange}
              type="bar"
              series={series}
              options={options}
              selectOptions={selectOptions}
              permission="operacoes_cra_recebiveis_a_vencer_e_np_e_pre_pgto_exportar"
              handleExport={handleExport}
              hasData={data && data?.length > 0}
              enabledExport={true}
            />
          ) : null}
        </>
      )}
    </Box>
  );
}

export default OperationCRAAssetReceivablesPayment;
