import { api } from '../../api/api';

export const markAsRead = async (id: number) => {
  await api.post(`/notification/${id}/read`);
  return id;
};

export const markAllAsRead = async () => {
  return api.post('/notification/read-all');
};
