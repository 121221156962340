import { useCallback, useEffect, useState } from 'react';

import { Close, ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Cropper from 'react-easy-crop';

import { getCroppedImg } from '../../../../../../utils/canvas';

function readFile(file: File) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

type Props = {
  name: string;
  setValue: (field: string, value: any) => void;
  file?: File | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  type: 'professional' | 'institution' | 'avatar';
  title: string;
  aspectRatio?: number;
  minZoom?: number;
  cropSize?: { width: number; height: number };
};

export default function CropImage({
  name,
  setValue,
  open,
  onClose,
  onSuccess,
  type,
  file,
  title,
  aspectRatio = 30 / 5,
  minZoom = 0.1,
  cropSize,
}: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [nameFile, setNameFile] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const transformFile = async (file: File) => {
    setNameFile(file?.name);
    const imageDataUrl = (await readFile(file)) as string;

    setImageSrc(imageDataUrl);
  };

  useEffect(() => {
    if (file && type === 'institution') {
      transformFile(file);
    }
  }, [file]);

  const handleClose = () => {
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setNameFile(null);
    setCroppedAreaPixels(null);
    onClose();
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      transformFile(file);
    }
  };

  const croppedImageFn = async () =>
    getCroppedImg(imageSrc, croppedAreaPixels, nameFile);

  const saveCroppedImage = useCallback(async () => {
    const croppedImage = await croppedImageFn();
    setValue(name, croppedImage);
    setZoom(1);
    onSuccess();
  }, [imageSrc, croppedAreaPixels]);

  const coverRef = useCallback((node: any) => {
    if (node !== null) {
      node.click();
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { borderRadius: '5px' } }}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography fontWeight={600}>{title}</Typography>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid
            item
            xs={12}
            sx={{
              position: 'relative',
              width: '800px',
              height: '300px',
            }}>
            {imageSrc ? (
              <Cropper
                image={imageSrc}
                crop={crop}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                zoom={zoom}
                onZoomChange={setZoom}
                minZoom={minZoom}
                restrictPosition={false}
                zoomSpeed={0.05}
                cropSize={cropSize}
              />
            ) : (
              <Box>
                <Typography>Faça o upload da imagem</Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center">
            <IconButton
              sx={{
                backgroundColor: '#1D1D1D',
                opacity: 0.75,
                margin: '0 16px',
                '&:hover': {
                  backgroundColor: '#1D1D1D',
                  opacity: 1,
                },
              }}
              size="medium"
              onClick={() => setZoom((prevState) => prevState + 0.1)}>
              <ZoomIn sx={{ color: 'white' }} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#1D1D1D',
                opacity: 0.75,
                margin: '0 16px',
                '&:hover': {
                  backgroundColor: '#1D1D1D',
                  opacity: 1,
                },
              }}
              size="medium"
              onClick={() => setZoom((prevState) => prevState - 0.1)}>
              <ZoomOut sx={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="flex-end">
            <Button variant="contained" onClick={saveCroppedImage}>
              Salvar
            </Button>
          </Grid>
          {type !== 'institution' && (
            <input
              name={name}
              ref={coverRef}
              accept=".png, .jpg, .jpeg"
              id="cover-image"
              type="file"
              onChange={onFileChange}
              onClick={(event) => {
                event.target.addEventListener('cancel', handleClose);
              }}
              hidden
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
