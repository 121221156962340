import { Dispatch, SetStateAction, useCallback } from 'react';

import {
  Autocomplete,
  createFilterOptions,
  debounce,
  FilterOptionsState,
  FormControl,
  TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { FieldTitle } from 'src/pages/SocialProfile/components/ProfessionalProfile/ProfessionalForm/style';
import { findInstitutionByNameOrCNPJ } from 'src/services/institution';
import {
  ProfessionalInput,
  RelatedInstitutionRequest,
} from 'src/types/professional';

type Props = {
  formik: FormikProps<ProfessionalInput>;
  relatedInstitutions: RelatedInstitutionRequest;
  setSelectedInstitution: Dispatch<SetStateAction<any>>;
  setOpenConfirmInstitutionDialog: Dispatch<SetStateAction<boolean>>;
  institutionName: string | undefined;
  setInstitutionName: Dispatch<SetStateAction<string | undefined>>;
};

function InstitutionAutocomplete({
  relatedInstitutions,
  institutionName,
  setSelectedInstitution,
  setOpenConfirmInstitutionDialog,
  formik,
  setInstitutionName,
}: Props) {
  const { auth } = useAuthContext();
  const filterOptionInstitution = createFilterOptions<any>();

  const filterOptions = (options: any[], state: FilterOptionsState<any>) => {
    const results = filterOptionInstitution(options, state);

    return [{ fantasyName: 'Outra', isOther: true }, ...results];
  };

  const pendingInstitutionText = `Sua solicitação para fazer parte da instituição 
    "${relatedInstitutions.fantasyName}" ainda não foi aceita, aguarde o administrador 
    da instituição avaliar sua requisição.`;

  const {
    isLoading: institutionsLoading,
    refetch: institutionsRefetch,
    data: institutionsOptions,
  } = useQuery(
    'institutions',
    () => findInstitutionByNameOrCNPJ(institutionName),
    { enabled: !!institutionName },
  );

  const institutionsRefetchDebounce = useCallback(
    debounce(institutionsRefetch, 500),
    [],
  );
  return (
    <>
      <FieldTitle>Empresa relacionada*</FieldTitle>

      <FormControl fullWidth variant="outlined">
        <Autocomplete
          disabled={
            (!auth?.user?.isOwner || auth?.user?.type === 'legal_person') &&
            !!institutionName
          }
          fullWidth
          autoHighlight
          filterOptions={filterOptions}
          loading={institutionsLoading}
          options={institutionsOptions?.data ?? []}
          isOptionEqualToValue={(option, value) =>
            option.fantasyName === value.fantasyName
          }
          onChange={(event, newValue, reason) => {
            if (newValue?.isOther) {
              setSelectedInstitution(newValue);
              formik.setFieldValue('relatedInstitutions', {
                id: undefined,
                fantasyName: 'Outra',
              });
              if (!relatedInstitutions.isOther) {
                setInstitutionName('');
                formik.setFieldValue('institutionName', '');
                institutionsRefetchDebounce();
              }
            } else if (!newValue?.id || reason === 'clear') {
              setSelectedInstitution({ fantasyName: '' });
              formik.setFieldValue('relatedInstitutions', {
                id: undefined,
                fantasyName: '',
              });
            } else {
              setSelectedInstitution(newValue);
              formik.setFieldValue('relatedInstitutions', newValue);
              if (newValue?.id !== relatedInstitutions.id) {
                setOpenConfirmInstitutionDialog(true);
              }
            }
          }}
          value={formik.values.relatedInstitutions}
          inputValue={institutionName}
          onInputChange={(event, newInputValue) => {
            setInstitutionName(newInputValue);
            institutionsRefetchDebounce();
          }}
          getOptionLabel={(option: any) =>
            typeof option === 'string' ? option : option.fantasyName
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="relatedInstitutions"
              placeholder="Digite o nome da sua empresa"
              error={
                formik.touched.relatedInstitutions?.fantasyName &&
                Boolean(formik.errors.relatedInstitutions?.fantasyName)
              }
              helperText={
                (formik.touched.relatedInstitutions?.fantasyName &&
                  formik.errors.relatedInstitutions?.fantasyName) ||
                (relatedInstitutions.status === 'Pendente' &&
                  relatedInstitutions?.id ===
                    formik.values.relatedInstitutions.id &&
                  pendingInstitutionText)
              }
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default InstitutionAutocomplete;
