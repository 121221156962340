import {
  ArrowForwardIosRounded,
  Facebook,
  LinkedIn,
  WhatsApp,
} from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Paper, Tabs, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import CopyLinkButton from 'src/components/CopyUrlButton';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { getLargestImageUrl } from 'src/utils/image';

import InviteCode from './InviteCode';
import { Event } from '../../../types/event';
import SubscribeButton from '../MainPage/SubscribeButton';
import { Image } from '../styles';

const shareButtonStyle = {
  backgroundColor: 'black',
  borderRadius: '50%',
  width: '28px',
  height: '28px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type HeaderProps = {
  data: Event;
  refetch: () => void;
  handleChange: (tab: number) => void;
  tabIndex: number;
  tabData: any[];
};

export default function Header({
  data,
  refetch,
  handleChange,
  tabIndex,
  tabData,
}: HeaderProps) {
  const { id, slug } = useParams<{
    id: string;
    slug: string;
  }>();
  const host = process.env.REACT_APP_PUBLIC_URL ?? window.location.origin;
  const eventUrl = `${host}/evento/${slug}/${id}`;

  return (
    <Box mt={4} mb={3.75} p={0}>
      <Breadcrumbs
        aria-label="navegação-secundária"
        sx={(theme) => ({
          fontSize: theme.spacing(1.75),
          marginBottom: 2,
          '@media print': {
            display: 'none',
          },
        })}
        separator={
          <ArrowForwardIosRounded
            sx={(theme) => ({
              fontSize: theme.spacing(1.5),
              color: '#7D83984D',
              marginTop: 0.25,
            })}
          />
        }>
        <Link
          component={RouterLink}
          to="/"
          underline="none"
          sx={(theme) => ({ color: theme.palette.primary.main })}>
          Home
        </Link>
        <Link
          component={RouterLink}
          to={'/eventos'}
          underline="none"
          sx={(theme) => ({ color: theme.palette.primary.main })}>
          Eventos
        </Link>
        <Typography
          sx={(theme) => ({
            fontSize: theme.spacing(1.75),
          })}>
          {data?.title}
        </Typography>
      </Breadcrumbs>
      <Paper
        sx={{
          position: 'relative',
          minHeight: 460,
          border: '1px solid var(--gray-5, #E0E0E0)',
          boxShadow:
            '0px 16px 24px 0px rgba(22, 34, 51, 0.08), 0px 4px 8px -4px rgba(22, 34, 51, 0.08)',
        }}>
        <Box position={'relative'}>
          <Image src={getLargestImageUrl(data?.image)} />
          <SubscribeButton event={data} refetch={refetch} />

          {data?.inviteCode && <InviteCode code={data.inviteCode} />}
        </Box>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(6, 4.25, 0, 4.25),
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(4, 2.25, 0, 2.25),
            },
          })}>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <FavoriteButton type="event" id={data.id} />
            <Typography
              sx={(theme) => ({
                fontSize: '28px',
                fontWeight: 700,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '20px',
                },
              })}>
              {data.title}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <LinkedinShareButton
              title={data?.title}
              source={host}
              url={eventUrl}
              style={shareButtonStyle}>
              <LinkedIn fontSize="small" sx={{ color: 'white' }} />
            </LinkedinShareButton>
            <FacebookShareButton
              quote={data?.title}
              url={eventUrl}
              style={shareButtonStyle}>
              <Facebook fontSize="small" sx={{ color: 'white' }} />
            </FacebookShareButton>
            <WhatsappShareButton
              title={data?.title}
              separator=" | "
              url={eventUrl}
              style={shareButtonStyle}>
              <WhatsApp fontSize="small" sx={{ color: 'white' }} />
            </WhatsappShareButton>
            <CopyLinkButton />
          </Box>
        </Box>
        <Tabs
          variant="fullWidth"
          value={tabIndex}
          onChange={(_, newValue) => handleChange(newValue)}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={(theme) => ({
            width: '100%',
            px: 2,
            mt: 8,
            borderBottom: '1px solid #BDBDBD',
            '& .MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              bottom: '-1px',
            },
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 100,
              width: '100%',
              backgroundColor: theme.palette.primary.main,
            },
            '& .MuiButtonBase-root': {
              minWidth: 0,
              paddingBottom: 0,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              pb: 0,
            },
            [theme.breakpoints.down('md')]: {
              padding: 0,
              mt: 6,
              '& .MuiTabs-indicatorSpan': {
                maxWidth: 80,
              },
            },
            [theme.breakpoints.down('lg')]: {
              '& .MuiButtonBase-root': {
                flexDirection: 'column',
              },
            },
          })}>
          {tabData.map(({ tab }) => tab)}
        </Tabs>
        {tabData[tabIndex].content}
      </Paper>
    </Box>
  );
}
