import { useMemo } from 'react';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { getEventSchedule } from 'src/services/events';
import { Presentation } from 'src/types/event';

import { CarouselItem } from './CarouselItem';

export default function LiveCarousel({
  presentation,
  ignorePresentation = true,
  showPreview = false,
}: {
  presentation: Presentation;
  ignorePresentation?: boolean;
  showPreview?: boolean;
}) {
  const { data: schedules } = useQuery(['EventSchedule'], () =>
    getEventSchedule({
      eventId: presentation.event.id.toString(),
      filters: { dates: [], filterSaved: false, markets: [] },
      isEnded: false,
    }),
  );

  const filteredSchedules = useMemo(() => {
    if (!schedules) return [];
    const livePresentations = schedules.filter(
      (schedule: Presentation) => schedule.state === 'ongoing',
    );
    const upcomingPresentations = schedules
      .filter((schedule: Presentation) => schedule.state === 'upcoming')
      .slice(0, 3);
    const response = [...livePresentations, ...upcomingPresentations];
    if (ignorePresentation) {
      return response.filter(
        (schedule: Presentation) => schedule.id !== presentation.id,
      );
    }
    return response;
  }, [schedules, presentation.id]);

  const hasMultipleSchedules = filteredSchedules?.length > 1;

  return (
    <If condition={filteredSchedules?.length}>
      <Box
        display="flex"
        sx={(theme) => ({
          height: '115px',
          backgroundColor: '#FAFAFA',
          '& .alice-carousel': {
            px: 8,
            py: 0,
          },
          '& .alice-carousel__prev-btn': {
            position: 'absolute',
            top: 0,
            left: 24,
            padding: 0,
          },
          '& .alice-carousel__next-btn': {
            position: 'absolute',
            top: 0,
            right: 24,
            padding: 0,
          },
          [theme.breakpoints.down('sm')]: {
            height: '100%',
            '& .alice-carousel': {
              px: '24px',
            },
            '& .alice-carousel__prev-btn': {
              left: 0,
            },
            '& .alice-carousel__next-btn': {
              right: 0,
            },
          },
        })}>
        <AliceCarousel
          items={filteredSchedules?.map((presentation: Presentation) => (
            <CarouselItem
              key={presentation.id}
              presentation={presentation}
              showPreview={showPreview}
            />
          ))}
          autoPlay={hasMultipleSchedules}
          autoPlayInterval={15000}
          autoPlayStrategy="none"
          infinite
          disableButtonsControls={!hasMultipleSchedules}
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          swipeExtraPadding={400}
          disableDotsControls
        />
      </Box>
    </If>
  );
}

const renderPrevButton = () => {
  return (
    <IconButton
      sx={(theme) => ({
        position: 'absolute',
        top: '57px',
        left: 0,
        padding: 0,
        transform: 'translate(0, -50%)',
        color: '#828282',
        backgroundColor: 'transparent',
        height: 'fit-content',
        '&:hover': {
          color: '#FF8211',
          opacity: 1,
          backgroundColor: 'transparent',
        },
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.125rem',
        },
      })}
      disableRipple>
      <KeyboardArrowLeft sx={{ fontSize: '20px' }} />
    </IconButton>
  );
};

const renderNextButton = () => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: '57px',
        right: 0,
        padding: 0,
        transform: 'translate(0, -50%)',
        color: '#828282',
        backgroundColor: 'transparent',
        height: 'fit-content',
        '&:hover': {
          color: '#FF8211',
          opacity: 1,
          backgroundColor: 'transparent',
        },
      }}
      disableRipple>
      <KeyboardArrowRight sx={{ fontSize: '20px' }} />
    </IconButton>
  );
};
