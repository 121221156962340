import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { BroadcastChannel, LeaderElector } from 'broadcast-channel';
import { isBefore, parse } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import TagManager from 'react-gtm-module';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PlanChangedDialog from 'src/components/PlanChangedDialog';
import PlanExpiredDialog from 'src/components/PlanExpiredDialog';
import SocialProfileDialog from 'src/components/SocialProfileDialog';
import { cast } from 'src/pages/Yearbooks/presentation/utils';
import { redirectCheckout } from 'src/services/portal';
import { setBannerMessage } from 'src/store/display';
import { Permissions, User } from 'src/types/user';

import { ContextData, initialContext } from './context-type';
import { If } from '../components/If';
import { getPlansWithPermission } from '../services/subscription';
import { me, refreshToken } from '../services/user';
import { buildNamesInline, reduceUserData } from '../utils/string';

const Context = createContext(initialContext);

// used to outside react components
const AuthContextRef = createRef<ContextData>();

type Props = {
  authChannel: BroadcastChannel<any>;
  elector: LeaderElector;
};

export default function AuthContextProvider(
  props: React.PropsWithChildren<Props>,
) {
  const { children, authChannel, elector } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [showNotificationPlan, setShowNotificationPlan] = useState(false);
  const [showNotificationFreePlan, setShowNotificationFreePlan] =
    useState(false);
  const [showNotificationDisconnected, setShowNotificationDisconnected] =
    useState(false);
  const [permission, setPermission] = useState<string | undefined>();
  const [plansToUpgrade, setPlansToUpgrade] = useState([]);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [periodDemo, setPeriodDemo] = useState<{
    isDemo: boolean;
    date: Date | null;
  }>({ isDemo: false, date: null });
  const [keepMeSigned] = useState(!!localStorage.getItem('@App:token'));
  const [isExpiredDialogOpen, setIsExpiredDialogOpen] = useState(false);
  const [openSocialProfileDialog, setOpenSocialProfileDialog] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!sessionStorage.getItem('@App:token') ||
      !!localStorage.getItem('@App:token'),
  );

  const { isFetched, isLoading, data, error, refetch } = useQuery('me', me, {
    enabled: isLoggedIn,
    retry: false,
  });

  const { isLoading: plansToUpgradeLoading, refetch: plansToUpgradeRefetch } =
    useQuery(
      ['upgradedPlan', permission],
      () => getPlansWithPermission(permission),
      {
        enabled: !!permission,
        retry: false,
        onSuccess: ({ data }) => {
          setPlansToUpgrade(data);
        },
      },
    );

  const { data: refreshedTokenData } = useQuery('refreshToken', refreshToken, {
    enabled: isLoggedIn && isLeader,
    refetchInterval: 600000, // 10 minutes
    refetchIntervalInBackground: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onError() {
      handleLogout();
    },
  });

  const checkoutMutation = useMutation('redirectCheckout', redirectCheckout, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
    },
  });

  const handleToPayment = () => {
    checkoutMutation.mutate({
      priceId: data?.data?.nextSubscription?.stripePriceId,
      quantity: data?.data?.nextSubscription?.slots,
    });
  };

  if (user?.id) {
    if (window.posthog && typeof window.posthog?.people?.set === 'function') {
      window.posthog.identify(user.id, {
        email: user.email,
        name: user.fullName,
      });
      // window.posthog.alias({ distinctId: user.id, alias: user.email });
      window.posthog.people.set(reduceUserData(user));
    }
    if (process.env.REACT_APP_GTM && window.profitwell) {
      window.dataLayer.push({ event: 'start_pw', pw_user_email: user.email });
    }
  }

  elector.awaitLeadership().then(() => {
    setIsLeader(elector.isLeader);
  });

  authChannel.onmessage = (event) => {
    if (event.type === 'login' && event.jwt && !isLoggedIn) {
      copyToken(event.jwt);
      handleLogin(true);
    } else if (event.type === 'refresh' && event.jwt && isLoggedIn) {
      copyToken(event.jwt);
    } else if (event.type === 'logout' && isLoggedIn) {
      handleLogout();
    } else if (event.type === 'request') {
      const jwt = sessionStorage.getItem('@App:token');
      if (jwt) {
        void authChannel.postMessage({ type: 'login', jwt });
      }
    }
  };

  const copyToken = (jwt: string, serverDate?: number) => {
    if (jwt) {
      const jwtDecoded = jwtDecode<JwtPayload>(jwt);
      if (
        serverDate &&
        jwtDecoded.exp &&
        isBefore(new Date(jwtDecoded.exp * 1000), new Date(serverDate))
      ) {
        handleLogout();
      } else {
        if (keepMeSigned) {
          localStorage.setItem('@App:token', jwt);
        }
        sessionStorage.setItem('@App:token', jwt);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && refreshedTokenData?.data?.jwt) {
      copyToken(refreshedTokenData?.data?.jwt, refreshedTokenData?.data?.now);
      notifyAuthChannel('refresh', refreshedTokenData.data.jwt);
    }
  }, [refreshedTokenData]);

  useEffect(() => {
    if (!isLoggedIn) {
      void authChannel.postMessage({ type: 'request' });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (data && !isLoading) {
      if (process.env.REACT_APP_GTM && data.data?.id) {
        const tagManagerArgs = {
          dataLayer: {
            userId: data.data?.id,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      if (
        data?.data?.nextSubscription &&
        data?.data?.nextSubscription?.isOwner
      ) {
        dispatch(
          setBannerMessage({
            message: `Ainda não identificamos o pagamento do seu novo plano, 
               enquanto isso utilize nossa plataforma no plano 
               ${data?.data?.subscription?.plan?.name?.toLocaleLowerCase()}.
              `,
            type: 'error',
            autoclose: false,
            hasAction: !!data?.data?.nextSubscription?.stripePriceId,
            actionText: 'Efetue o pagamento aqui',
            actionCallback: handleToPayment,
          }),
        );
      } else if (
        user?.subscription?.plan?.type !==
          data?.data?.subscription?.plan?.type &&
        data?.data?.subscription?.status === 'Pago'
      ) {
        dispatch(
          setBannerMessage({
            message: '',
            type: 'error',
            autoclose: false,
          }),
        );
      }
      setUser(data.data);
      setPeriodDemo({
        isDemo:
          data?.data?.subscription?.plan?.type === 'demo' &&
          data?.data?.subscription?.status !== 'Expirado',
        date:
          parse(data?.data?.subscription?.endAt, 'yyyy-MM-dd', new Date()) ??
          null,
      });
      if (
        isLoggedIn &&
        user?.subscription?.plan?.name &&
        data?.data?.subscription?.plan?.type !== 'free' &&
        data?.data?.subscription?.plan?.name !== user?.subscription?.plan?.name
      ) {
        setShowNotificationPlan(true);
      }
      if (
        isLoggedIn &&
        user?.subscription?.plan?.name &&
        data?.data?.subscription?.plan?.type === 'free' &&
        data?.data?.subscription?.plan?.name !==
          user?.subscription?.plan?.name &&
        user?.subscription?.plan?.type !== 'demo'
      ) {
        setShowNotificationFreePlan(true);
      }
      if (data?.data.type === 'legal_person') {
        setOpenSocialProfileDialog(!data?.data?.institution);
      } else {
        setOpenSocialProfileDialog(!data?.data?.professional);
      }
    }
  }, [data]);

  useEffect(() => {
    refreshToken();
    copyToken(sessionStorage.getItem('@App:token') || '');
  }, []);

  useEffect(() => {
    setIsExpiredDialogOpen(
      user?.subscription?.status === 'Expirado' &&
        !location.pathname.includes('/planos'),
    );
  }, [user, location.pathname]);

  const reloadUser = async () => await refetch();

  const handleLogin = (success: boolean) => {
    setIsLoggedIn(success);
    void reloadUser();
  };

  const notifyAuthChannel = (type?: string, jwt?: string) => {
    void authChannel.postMessage({ type, jwt });
  };

  const handleLogout = (showNotification = false) => {
    // Remove user details from sessionStorage
    localStorage.removeItem('@App:token');
    sessionStorage.removeItem('@App:token');
    sessionStorage.removeItem('@App:tempToken');
    // Set the logged-in status to false
    setIsLoggedIn(false);
    setUser(undefined);
    setPeriodDemo({ isDemo: false, date: null });
    if (showNotification) {
      setShowNotificationDisconnected(showNotification);
    }
    if (typeof window.posthog?.people?.set === 'function') {
      window.posthog.reset();
    }
  };

  const upgradedPlanByPermission = (p: string) => {
    if (
      !p ||
      user?.subscription?.plan?.permissions?.[cast<keyof Permissions>(p)]
    ) {
      return [];
    }

    setPermission(p);
    if (permission && !plansToUpgradeLoading && !plansToUpgrade?.length) {
      void plansToUpgradeRefetch();
    }

    return buildNamesInline(plansToUpgrade);
  };

  const contextValues: ContextData = {
    auth: {
      user,
      periodDemo,
      isLoggedIn,
      isLoading,
      isFetched,
      error,
      refetch,
      keepMeSigned,
    },
    reloadUser,
    handleLogin,
    handleLogout,
    notifyAuthChannel,
    upgradedPlanByPermission,
  };

  useImperativeHandle(AuthContextRef, () => contextValues);

  return (
    <Context.Provider value={cast<any>(contextValues)}>
      <If condition={isLoggedIn && !isLoading}>
        <If condition={showNotificationPlan && user?.subscription?.plan?.name}>
          <PlanChangedDialog
            open={showNotificationPlan}
            setOpen={setShowNotificationPlan}
            plan={user?.subscription?.plan}
            hasButton={true}
            buttonText="Fechar"
            buttonLink=""
          />
        </If>
        <If
          condition={
            showNotificationFreePlan && user?.subscription?.plan?.name
          }>
          <PlanChangedDialog
            open={showNotificationFreePlan}
            setOpen={setShowNotificationFreePlan}
            plan={user?.subscription?.plan}
            hasButton={true}
            buttonText="Assinar plano"
            buttonLink="/planos"
          />
        </If>
        <If condition={user?.subscription?.status === 'Expirado'}>
          <PlanExpiredDialog open={isExpiredDialogOpen} />
        </If>
        <If
          condition={
            data?.data?.subscription?.plan?.type !== 'demo' &&
            openSocialProfileDialog
          }>
          <SocialProfileDialog
            open={openSocialProfileDialog}
            user={user}
            isFetched={isFetched}
            handleClose={() => setOpenSocialProfileDialog(false)}
          />
        </If>
      </If>
      <If condition={!isLoggedIn && showNotificationDisconnected}>
        <PlanChangedDialog
          open={showNotificationDisconnected}
          setOpen={setShowNotificationDisconnected}
          hasButton={true}
          buttonText="Login"
          buttonLink="/login"
        />
      </If>
      {children}
    </Context.Provider>
  );
}

function useAuthContext() {
  return useContext(Context);
}

export { useAuthContext, AuthContextRef };
