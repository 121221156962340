import { useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Checkbox, Menu, MenuItem, Typography } from '@mui/material';
import { Market } from 'src/types/market';

export default function MarketsFilter({
  data,
  state,
  setState,
  INITIAL_STATE,
}: {
  data?: Market[];
  state: any;
  setState: any;
  INITIAL_STATE: any;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCheck = (marketId: number | number[]) => {
    setState((prev: any) => {
      if (Array.isArray(marketId)) {
        return {
          ...prev,
          markets:
            state.markets.length === INITIAL_STATE.markets.length
              ? []
              : marketId,
        };
      }

      const newIds = state.markets.includes(marketId)
        ? state.markets.filter((id: number) => id !== marketId)
        : state.markets.concat(marketId);

      return {
        ...prev,
        markets: newIds,
      };
    });
  };

  return (
    <>
      <Button
        id="markets-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        // disabled={disabled}
        sx={(theme) => ({
          color:
            state.markets.length > 0 &&
            state.markets.length !== INITIAL_STATE.markets.length
              ? theme.palette.primary.main
              : '#4F4F4F',
        })}>
        Mercado:{' '}
        {state.markets.length === INITIAL_STATE.markets.length
          ? 'Todos'
          : state.markets.length > 0
            ? `+${state.markets.length}`
            : 'Nenhum'}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'markets-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '40dvh',
            minWidth: '150px',
          },
        }}
        // aria-disabled={disabled}
      >
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&.Mui-selected': {
              backgroundColor: '#F1F1F1',
            },
          }}
          selected={state.markets.length === INITIAL_STATE.markets.length}
          onClick={() => handleCheck(INITIAL_STATE.markets)}>
          <Checkbox
            checked={INITIAL_STATE.markets.length === state.markets.length}
            inputProps={{ 'aria-label': 'checkbox' }}
            color="secondary"
            size="small"
          />
          <Typography fontSize={14} fontWeight={700} color={'#000'}>
            Todos
          </Typography>
        </MenuItem>
        {data &&
          data.map((market, index) => (
            <MenuItem
              key={index}
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={() => handleCheck(market.id)}>
              <Checkbox
                checked={state?.markets?.includes(market.id)}
                inputProps={{ 'aria-label': 'checkbox' }}
                color="primary"
                size="small"
              />
              <Typography fontSize={12} fontWeight={600} color={'#000'}>
                {market.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
