import styled from 'styled-components';

export const Scene = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 40% 55%;
  column-gap: 5%;
  justify-items: space-between;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 0;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type ImageBoxProps = {
  show: boolean;
};

export const ImageBox = styled.div<ImageBoxProps>`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  top: calc(15%);
  flex: 1;
  max-width: 100%;
  height: fit-content;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 1s ease;

  @media screen and (max-width: 1440px) {
    top: calc(20%);
  }
`;
