import { api, publicApi } from '../../api/api';

export const autoComplete = async (data) => {
  return api.post('/elastic/autocomplete', data);
};

export const filterInstitutions = async (filters) => {
  return publicApi.post('/elastic/institutions', filters);
};

export const filterProfessionals = async (filters) => {
  return publicApi.post('/elastic/professionals', filters);
};

export const filterArticles = async (filters) => {
  return publicApi.post('/elastic/articles', filters);
};

export const filterOperations = async (filters) => {
  return api.post('/elastic/operations', filters);
};

export const getOperationOptionsByMarket = async (market) => {
  const { data } = await publicApi.get(`/elastic/operations/options/${market}`);
  return data;
};

export const filterDocuments = async (filters) => {
  return publicApi.post('/elastic/documents', filters);
};

export const getDocumentOptions = async () => {
  const { data } = await publicApi.get('/elastic/documents/options/');
  return data;
};

export const getRadarForHome = async () => {
  const { data } = await api.get('/elastic/radar-home/');
  return data;
};
