import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import lodash from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import AccordionControlled from 'src/components/AccordionControlled';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { If } from 'src/components/If';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { createInstitution, updateInstitution } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution, Participator } from 'src/types/institution';
import { Media } from 'src/types/strapi';
import { formatCNPJ, validateCNPJ } from 'src/utils/document';
import { useQueryParams } from 'src/utils/hooks';
import {
  facebookRegex,
  instagramRegex,
  linkedinRegex,
  twitterRegex,
} from 'src/utils/regex';
import * as Yup from 'yup';

import AddressForm from './AddressForm';
import BasicDataForm from './BasicDataForm';
import CTA from './CTA';
import ContactForm from './ContactForm';
import Documents from './Documents';
import EditSlug from './EditSlug';
import FeaturedOperations from './FeaturedOperations';
import Header from './Header';
import Photos from './Photos';
import Videos from './Videos';
import { CompanyProfessionals } from '../CompanyProfessionals';

const MAX_FILE_SIZE = 2000000;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type Props = {
  data: Institution | undefined;
  relatedInstitutionRefetch: () => void;
  institutionRefetch: () => void;
  isRefusedSolicitation: boolean;
};

export function CompanyForm({
  data,
  relatedInstitutionRefetch,
  institutionRefetch,
  isRefusedSolicitation,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [{ query }] = useQueryParams<any>({});
  const { auth } = useAuthContext();
  const formRef = useRef<null | HTMLInputElement>(null);
  const professionalRef = useRef<null | HTMLDivElement>(null);
  const profileRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);
  const addressRef = useRef<null | HTMLDivElement>(null);
  const CTARef = useRef<null | HTMLDivElement>(null);
  const operationsRef = useRef<null | HTMLDivElement>(null);
  const documentsRef = useRef<null | HTMLDivElement>(null);
  const photosRef = useRef<null | HTMLDivElement>(null);
  const videosRef = useRef<null | HTMLDivElement>(null);
  const [image, setImage] = useState<Media | null>(data?.logo ?? null);
  const [cover, setCover] = useState<Media | null>(data?.coverImage ?? null);
  const [participators] = useState(
    data?.participators?.reduce(
      (acc: string[], participator: Participator): string[] => {
        acc.push(participator.participator.name);
        return acc;
      },
      [],
    ),
  );
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const [triggerExit, setTriggerExit] = useState({
    shouldLeave: false,
    path: '',
  });
  const [hasTouched, setHasTouched] = useState(false);
  const [professionalOpen, setProfessionalOpen] = useState(true);
  const [profileAccordionOpen, setProfileAccordionOpen] = useState(true);
  const [slugAccordionOpen, setSlugAccordionOpen] = useState(false);
  const [contactAccordionOpen, setContactAccordionOpen] = useState(false);
  const [addressAccordionOpen, setAddressAccordionOpen] = useState(false);
  const [documentsAccordionOpen, setDocumentsAccordionOpen] = useState(false);
  const [photosAccordionOpen, setPhotosAccordionOpen] = useState(false);
  const [videosAccordionOpen, setVideosAccordionOpen] = useState(false);

  const handleGoToIntendedPage = useCallback(
    (location: string) => history.push(location),
    [history],
  );

  const handleUnload = (event: any) => {
    if (hasTouched) {
      setOpenLeaveDialog(true);
      setTriggerExit((prevState) => ({ ...prevState, path: event.pathname }));
      if (triggerExit.shouldLeave) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setImage(data?.logo ?? null);
    setCover(data?.coverImage ?? null);
  }, [data]);

  useEffect(() => {
    if (!!query?.newProfessionalRequest && professionalRef?.current) {
      professionalRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [professionalRef.current]);

  useEffect(() => {
    let unblock: any;
    if (hasTouched) {
      if (triggerExit.shouldLeave) {
        handleGoToIntendedPage(triggerExit.path);
      }
      if (!unblock) {
        window.addEventListener('beforeunload', (event) => {
          event.preventDefault();
          event.returnValue = '';
        });
        unblock = history.block((location): any => handleUnload(location));
      }
    } else {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    }

    return () => {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.shouldLeave,
    triggerExit.path,
    hasTouched,
  ]);

  const createInstitutionMutation = useMutation(createInstitution, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            message: 'Não foi possível cadastrar a instituição',
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
    onSuccess: async () => {
      setHasTouched(false);
      dispatch(
        setBannerMessage({
          autoclose: true,
          type: 'success',
          message:
            'Agora sua empresa faz parte do diretório Uqbar. A Uqbar se reserva o direito de rejeitar ou remover páginas por qualquer motivo.',
        }),
      );
      relatedInstitutionRefetch();
      institutionRefetch();
    },
  });

  const updateInstitutionMutation = useMutation(updateInstitution, {
    onError: (error) => {
      setHasTouched(false);
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar os dados da instituição',
          }),
        );
      }
    },
    onSuccess: (result) => {
      setHasTouched(false);
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Perfil institucional atualizado',
          }),
        );
      }
      relatedInstitutionRefetch();
      institutionRefetch();
    },
  });

  const FORM_VALIDATION = Yup.object().shape({
    fantasyName: Yup.string().required('Campo obrigatório').nullable(),
    cnpj: Yup.string()
      .required('Campo obrigatório')
      .test('cnpj', 'O CNPJ Informado é inválido', (val) => validateCNPJ(val)),
    emails: Yup.array(
      Yup.object({
        email: Yup.string()
          .required('Campo obrigatório')
          .email('Digite um e-mail válido'),
      }),
    ).max(3),
    phones: Yup.array(
      Yup.object({
        number: Yup.string()
          .required('Campo obrigatório')
          .test(
            'phoneLen',
            'O telefone é inválido',
            (val) => !!val && val?.replace(/\D/g, '')?.length >= 10,
          ),
      }),
    ),
    logo: Yup.mixed()
      .test('required', 'A logo é obrigatória', (value) => {
        if (value || image) {
          return true;
        }
        return false;
      })
      .test(
        'fileSize',
        'A imagem da logo excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (image) {
            return true;
          }
          return value?.type && value?.size <= MAX_FILE_SIZE;
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (image) {
          return true;
        }
        return value && SUPPORTED_FORMATS.includes(value.type);
      }),
    markets: Yup.array().min(1, 'Selecione um mercado de atuação'),
    segments: Yup.array().min(1, 'Selecione um segmento de atuação'),
    addresses: Yup.object({
      zipcode: Yup.string().required('Campo obrigatório').nullable(),
      line1: Yup.string().required('Campo obrigatório').nullable(),
      state: Yup.string().required('Campo obrigatório').nullable(),
      city: Yup.string().required('Campo obrigatório').nullable(),
      number: Yup.string().required('Campo obrigatório').nullable(),
      neighborhood: Yup.string().required('Campo obrigatório').nullable(),
    }),
    linkedin: Yup.object({
      url: Yup.string().matches(linkedinRegex, 'Url inválida'),
    }),
    instagram: Yup.object({
      url: Yup.string().matches(instagramRegex, 'Url inválida'),
    }),
    facebook: Yup.object({
      url: Yup.string().matches(facebookRegex, 'Url inválida'),
    }),
    twitter: Yup.object({
      url: Yup.string().matches(twitterRegex, 'Url inválida'),
    }),
  });

  const getProgress = (values: any) => {
    const fields = [
      'logo',
      'coverImage',
      'cnpj',
      'fantasyName',
      'institutionTitle',
      'callToAction',
      'markets[0]',
      'segments[0]',
      'websiteUrl',
      'businessProfile',
      'addresses.zipcode',
      'addresses.line1',
      'addresses.number',
      'addresses.neighborhood',
      'addresses.city',
      'addresses.state',
      'facebook.url',
      'linkedin.url',
      'instagram.url',
      'twitter.url',
      'emails[0].email',
      'phones[0].number',
    ];
    let filledTotal = fields.reduce(
      (a, p) => (a += lodash.get(values, p) ? 1 : 0),
      0,
    );
    if (!values.photo && image) {
      filledTotal++;
    }
    if (!values.coverImage && cover) {
      filledTotal++;
    }
    return Math.ceil((filledTotal / fields.length) * 100);
  };

  const formik = useFormik({
    initialValues: {
      logo: null,
      coverImage: null,
      coverVideoURL: data?.coverVideoURL ?? '',
      callToAction: data?.callToAction ?? '',
      cnpj: data?.cnpj ?? auth?.user?.document,
      fantasyName: data?.fantasyName ?? auth?.user?.otherName,
      markets: data?.markets?.map(({ id }) => id) ?? [],
      segments: data?.segments?.map(({ id }) => id) ?? [],
      websiteUrl: data?.websiteUrl ?? '',
      participators: participators,
      businessProfile: data?.businessProfile ?? '',
      institutionTitle: data?.institutionTitle ?? '',
      slug: data?.slug
        ? data.slug
        : lodash.kebabCase(`${auth.user?.name} ${auth.user?.id}`),
      addresses: {
        id: data?.addresses?.length ? data?.addresses[0]?.id : undefined,
        zipcode: data?.addresses?.length ? data?.addresses[0]?.zipcode : '',
        line1: data?.addresses?.length ? data?.addresses[0]?.line1 : '',
        line2: data?.addresses?.length ? data?.addresses[0]?.line2 : '',
        number: data?.addresses?.length ? data?.addresses[0]?.number : '',
        neighborhood: data?.addresses?.length
          ? data?.addresses[0]?.neighborhood
          : '',
        city: data?.addresses?.length ? data?.addresses[0]?.city : '',
        state: data?.addresses?.length ? data?.addresses[0]?.state : '',
      },
      facebook: {
        id: data?.facebook?.id ?? undefined,
        url: data?.facebook?.url ?? '',
      },
      linkedin: {
        id: data?.linkedin?.id ?? undefined,
        url: data?.linkedin?.url ?? '',
      },
      instagram: {
        id: data?.instagram?.id ?? undefined,
        url: data?.instagram?.url ?? '',
      },
      twitter: {
        id: data?.twitter?.id ?? undefined,
        url: data?.twitter?.url ?? '',
      },
      emails: data?.emails?.length
        ? data?.emails
        : [
            {
              email: '',
            },
          ],
      phones: data?.phones?.length
        ? data?.phones
        : [
            {
              number: undefined,
            },
          ],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      const { logo, coverImage, addresses, callToAction, ...institutionData } =
        values;
      if (coverImage) {
        formData.append('files.coverImage', coverImage);
      }
      if (logo) {
        formData.append('files.logo', logo);
      }

      formData.append(
        'data',
        JSON.stringify({
          ...institutionData,
          razaoSocial: auth?.user?.name,
          cnpj: formatCNPJ(auth?.user?.document),
          addresses: [addresses],
          creationDate: new Date(),
          owner: auth?.user?.id,
          callToAction: callToAction !== '' ? callToAction : null,
          ...(!image && !logo ? { logo: null } : { logo: image?.id }),
          ...(!cover && !coverImage
            ? { coverImage: null }
            : { coverImage: cover?.id }),
        }),
      );

      if (data) {
        updateInstitutionMutation.mutateAsync({
          id: data.id,
          values: formData,
        });
      } else {
        createInstitutionMutation.mutateAsync(formData);
      }
    },
  });

  const progressValue = useMemo(
    () => getProgress(formik.initialValues),
    [formik.initialValues],
  );

  const scrollTo = (key: string) => {
    switch (key) {
      case 'logo':
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'profile':
        profileRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'contact':
        contactRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'address':
        addressRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  };

  const headerRequiredFields = ['logo'];

  const profileRequiredFields = ['cnpj', 'fantasyName', 'segments', 'markets'];

  const contactRequiredFields = [
    'emails',
    'phones',
    'instagram',
    'facebook',
    'linkedin',
    'twitter',
  ];

  const addressRequiredFields = [
    'line1',
    'city',
    'number',
    'neighborhood',
    'state',
    'zipcode',
  ];

  useEffect(() => {
    let shouldScrollTo = 'default';
    if (
      !createInstitutionMutation.isLoading ||
      !updateInstitutionMutation.isLoading
    ) {
      if (
        Object.keys(formik.errors).some((item) =>
          headerRequiredFields.includes(item),
        )
      ) {
        shouldScrollTo = 'logo';
      }
      if (
        Object.keys(formik.errors).some((item) =>
          profileRequiredFields.includes(item),
        )
      ) {
        setProfileAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'profile';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          contactRequiredFields.includes(item),
        )
      ) {
        setContactAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'contact';
        }
      }
      if (
        formik.errors?.addresses &&
        Object.keys(formik.errors.addresses).some((item) =>
          addressRequiredFields.includes(item),
        )
      ) {
        setAddressAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'address';
        }
      }
      scrollTo(shouldScrollTo);
    }
  }, [formik.errors]);

  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <section ref={formRef}>
      <TabContext value={tabValue}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          style={{ width: '100%' }}
          onChange={() => setHasTouched(true)}>
          <Header
            logoImage={image}
            coverImage={cover}
            setLogoImage={setImage}
            setCoverImage={setCover}
            institution={data}
            progressValue={progressValue}
            formik={formik}
            institutionRefetch={institutionRefetch}
          />
          <If condition={auth?.user?.subscription?.plan?.type === 'free'}>
            <Box marginTop="24px">
              <Typography fontSize={14}>
                No plano gratuito os dados serão exibidos parcialmente em seu
                perfil institucional, para exibir todas as informações faça o
                upgrade do seu plano&nbsp;
                <Link component={RouterLink} to={'/planos'}>
                  aqui.
                </Link>
              </Typography>
            </Box>
          </If>
          <Box
            sx={(theme) => ({
              display: 'flex',
              marginTop: '64px',
              '& > div': { flex: '1 1 auto', padding: '8px 0 0 26px' },
              [theme.breakpoints.down('md')]: {
                display: 'block',
                marginTop: '54px',
                '& > div': { padding: '0' },
              },
            })}>
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              value={tabValue}
              onChange={handleTabChange}
              sx={(theme) => ({
                flex: '0 0 19% !important',
                padding: '0 26px 0 33px !important',
                borderRight: '1px solid #F1F1F1',
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& button': {
                  alignItems: 'flex-start',
                  fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
                  fontWeight: 600,
                  lineHeight: 0.8,
                  padding: '8px 0px',
                  marginBottom: '16px',
                  minHeight: 'unset',
                  textTransform: 'capitalize',
                  color: theme.palette.secondary.main,
                },
                '& .Mui-selected': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  marginBottom: '14px',
                  color: '#828282 !important',
                },
                [theme.breakpoints.down('md')]: {
                  borderRight: 'none',
                  padding: '0 !important',
                  '& button': {
                    alignItems: 'center',
                    fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
                    fontWeight: 400,
                    padding: '6px 0px 20px 0',
                    marginBottom: '0',
                    minHeight: 'unset',
                    textTransform: 'capitalize',
                  },
                  '& .Mui-selected': {
                    borderBottomWidth: '3px',
                    marginBottom: '0',
                    color: `${theme.palette.secondary.main} !important`,
                  },
                  '& .MuiTabs-flexContainer': {
                    placeContent: 'space-between',
                    marginBottom: '2rem',
                  },
                },
              })}>
              <Tab label="Perfil" value="1" />
              <Tab label="Profissionais" value="2" />
              <Tab label="Galeria" value="3" />
            </Tabs>
            <TabPanel value="1">
              <AccordionControlled
                style={{ marginTop: '-26px' }}
                ref={profileRef}
                expanded={profileAccordionOpen}
                onChange={() =>
                  setProfileAccordionOpen((prevState) => !prevState)
                }
                title="Dados básicos do perfil social"
                defaultExpanded>
                <BasicDataForm
                  institution={data}
                  formik={formik}
                  isRefusedSolicitation={isRefusedSolicitation}
                  participators={participators}
                />
              </AccordionControlled>
              <AccordionControlled
                ref={CTARef}
                title="Chamada à ação personalizada"
                disabled={
                  !auth?.user?.subscription?.plan?.permissions?.instituicao_cta
                }
                disabledReason={
                  !auth?.user?.subscription?.plan?.permissions?.instituicao_cta
                    ? 'Funcionalidade não disponível, faça upgrade.'
                    : ''
                }>
                <CTA formik={formik} institution={data} />
              </AccordionControlled>
              <AccordionControlled
                ref={contactRef}
                expanded={contactAccordionOpen}
                onChange={() =>
                  setContactAccordionOpen((prevState) => !prevState)
                }
                title="Dados de contato"
                defaultExpanded>
                <ContactForm formik={formik} />
              </AccordionControlled>
              <AccordionControlled
                ref={operationsRef}
                title="Operações em destaque"
                disabled={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_operacoes_destaque
                }
                disabledReason={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_operacoes_destaque
                    ? 'Funcionalidade não disponível, faça upgrade.'
                    : ''
                }>
                <FeaturedOperations
                  institution={data}
                  institutionRefetch={institutionRefetch}
                />
              </AccordionControlled>

              <AccordionControlled
                ref={documentsRef}
                expanded={documentsAccordionOpen}
                onChange={() =>
                  setDocumentsAccordionOpen((prevState) => !prevState)
                }
                title="Documentos"
                disabled={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_documentos
                }
                disabledReason={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_documentos
                    ? 'Funcionalidade não disponível, faça upgrade.'
                    : ''
                }>
                <Documents
                  institution={data}
                  institutionRefetch={institutionRefetch}
                />
              </AccordionControlled>

              <AccordionControlled
                ref={addressRef}
                expanded={addressAccordionOpen}
                onChange={() =>
                  setAddressAccordionOpen((prevState) => !prevState)
                }
                title="Endereço">
                <AddressForm formik={formik} />
              </AccordionControlled>
              <AccordionControlled
                expanded={slugAccordionOpen}
                onChange={() => setSlugAccordionOpen((prevState) => !prevState)}
                disabled={
                  !data?.id
                    ? true
                    : auth.user?.subscription?.plan?.type
                      ? ['demo', 'free'].includes(
                          auth.user.subscription.plan.type,
                        )
                      : false
                }
                disabledReason={
                  !data?.id
                    ? 'Funcionalidade disponível apenas após criação do perfil'
                    : auth.user?.subscription?.plan?.type === 'free'
                      ? 'Funcionalidade não disponível, faça upgrade'
                      : auth.user?.subscription?.plan?.type === 'demo'
                        ? 'Funcionalidade não disponível durante demonstração'
                        : ''
                }
                title="URL Pública">
                {!!data?.id && (
                  <EditSlug
                    name="slug"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    id={data.id}
                    onBlur={formik.handleBlur}
                  />
                )}
              </AccordionControlled>
              <Box sx={{ marginTop: '48px', textAlign: 'end' }}>
                <Button variant="contained" size="medium" type="submit">
                  {createInstitutionMutation.isLoading ||
                  updateInstitutionMutation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar perfil'
                  )}
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <If condition={data}>
                <Box sx={{ marginTop: '-26px' }}>
                  <AccordionControlled
                    ref={professionalRef}
                    expanded={professionalOpen}
                    onChange={() =>
                      setProfessionalOpen((prevState) => !prevState)
                    }
                    title="Profissionais vinculados">
                    <CompanyProfessionals data={data} />
                  </AccordionControlled>
                </Box>
              </If>
            </TabPanel>
            <TabPanel value="3">
              <Box sx={{ marginTop: '-26px' }}>
                <AccordionControlled
                  ref={photosRef}
                  expanded={photosAccordionOpen}
                  onChange={() =>
                    setPhotosAccordionOpen((prevState) => !prevState)
                  }
                  title="Fotos"
                  disabled={
                    !auth?.user?.subscription?.plan?.permissions
                      ?.instituicao_galeria_imagens
                  }
                  disabledReason={
                    !auth?.user?.subscription?.plan?.permissions
                      ?.instituicao_galeria_imagens
                      ? 'Funcionalidade não disponível, faça upgrade.'
                      : ''
                  }>
                  <Photos
                    institution={data}
                    institutionRefetch={institutionRefetch}
                  />
                </AccordionControlled>
              </Box>
              <AccordionControlled
                ref={videosRef}
                expanded={videosAccordionOpen}
                onChange={() =>
                  setVideosAccordionOpen((prevState) => !prevState)
                }
                title="Vídeos"
                disabled={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_galeria_videos
                }
                disabledReason={
                  !auth?.user?.subscription?.plan?.permissions
                    ?.instituicao_galeria_videos
                    ? 'Funcionalidade não disponível, faça upgrade.'
                    : ''
                }>
                <Videos
                  institution={data}
                  institutionRefetch={institutionRefetch}
                />
              </AccordionControlled>
            </TabPanel>
          </Box>
        </form>
        <ConfirmLeaveDialog
          title="Tem certeza que deseja sair desta página?"
          open={openLeaveDialog}
          onClose={() => setOpenLeaveDialog(false)}
          handleAction={() => {
            setTriggerExit((obj) => ({
              ...obj,
              shouldLeave: true,
            }));
            setOpenLeaveDialog(false);
          }}
          labelButton="Continuar edição"
        />
      </TabContext>
    </section>
  );
}
