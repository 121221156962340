import { Box } from '@mui/material';

import OperationFIIInvestorsMovements from './OperationFIIInvestorsMovements';
import OperationFIIInvestorsPosition from './OperationFIIInvestorsPosition';

type Props = {
  operationCnpj: string;
  operationName: string;
};

export default function OperationInvestors({
  operationCnpj,
  operationName,
}: Props) {
  return (
    <Box>
      <OperationFIIInvestorsPosition
        operationCnpj={operationCnpj}
        operationName={operationName}
      />
      <OperationFIIInvestorsMovements
        operationCnpj={operationCnpj}
        operationName={operationName}
      />
    </Box>
  );
}
