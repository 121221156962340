// Material ui components
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

// Util
import { convertNumber, convertValue } from '../../utils/number';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    marginLeft: 3,
    padding: theme.spacing(3, 2),
  },
  icon: {
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function PaperDoublePanel({
  currency,
  titleLeft,
  titleRight,
  valueLeft,
  valueRight,
}) {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center">
        <Box
          position="absolute"
          alignItems="center"
          height={55}
          className={classes.box}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper square className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className={classes.title}>
                    {titleLeft}
                  </Typography>
                  <Typography variant="subtitle2">
                    {currency
                      ? `${convertValue(valueLeft, 'million')}`
                      : valueLeft}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1" className={classes.title}>
                    {titleRight}
                  </Typography>
                  <Typography variant="subtitle2">
                    {currency ? convertNumber(valueRight) : valueRight}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
