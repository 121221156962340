import { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Collapse,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Pagination } from '@material-ui/lab';
import loadash from 'lodash';
import { Link } from 'react-router-dom';

import ipanema from '../../graphql/clients/ipanema';
import { GET_ANALYTICAL_RANK } from '../../graphql/queries';
import { convertValue } from '../../utils/number';
import { If } from '../If';

const useStyles = makeStyles((theme) => ({
  collapseTableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  collapsePagination: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 4),
  },
  boxCollapse: {
    width: 'auto',
    margin: '0 auto',
  },
  noDetails: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const RowInner = ({ row, operationId, classes, market }) => {
  return (
    <TableRow className={classes.collapseTableRow}>
      {Object.entries(row)
        ?.filter((entry) => !!entry[1])
        ?.map((entry, index) => (
          <>
            {entry[0] === 'operation' ? (
              <TableCell key={index} size="medium">
                <Link
                  to={{
                    pathname: `/operacao/${market}/${loadash.kebabCase(entry[1]?.value)}/${operationId}`,
                  }}
                  className={classes.link}>
                  {entry[1].value}
                </Link>
              </TableCell>
            ) : (
              <>
                {entry[0] === 'amount' ? (
                  <TableCell key={index} width="174">
                    {convertValue(entry[1].value, null, true, 'currency')}
                  </TableCell>
                ) : (
                  <TableCell key={index}>{entry[1].value}</TableCell>
                )}
              </>
            )}
          </>
        ))}
    </TableRow>
  );
};

function CollapseTableRanking({ open, itemId, state, month, year }) {
  const classes = useStyles();
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('operation');
  const limit = 10;
  const [page, setPage] = useState(1);
  const { data, loading, error } = useQuery(GET_ANALYTICAL_RANK, {
    variables: {
      itemId,
      market: state.market.toLowerCase(),
      type: state.ranking,
      timeRange: state.timeRange,
      year,
      month,
      pagination: {
        page: (page - 1) * limit,
        pageSize: limit,
      },
      sort: {
        order,
        orderBy,
      },
    },
    fetchPolicy: 'no-cache',
    client: ipanema,
    skip: !open,
  });

  const collumnsNumber =
    data?.getAnalyticalRank?.rows?.length &&
    Object.entries(data?.getAnalyticalRank?.rows[0]?.collumns)?.filter(
      (entry) => !!entry[1],
    )?.length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const renderCell = (column, label) => {
    return (
      <TableSortLabel
        active={true}
        IconComponent={() => <ImportExportIcon color="secondary" />}
        direction={orderBy === column ? order : 'ASC'}
        onClick={(e) => handleRequestSort(e, column)}>
        {label}
      </TableSortLabel>
    );
  };

  const onChangePagination = (event, value) => {
    setPage(value);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" marginBottom="16px">
        <CircularProgress color="secondary" />
      </Box>
    );
  if (error) return null;

  const total = data?.getAnalyticalRank?.total;

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <If condition={data?.getAnalyticalRank?.rows?.length}>
        <Box className={classes.boxCollapse}>
          <Table size="small" aria-label="tabela de detalhamento">
            <TableHead>
              <TableRow className={classes.collapseTableRow}>
                {data?.getAnalyticalRank?.rows?.length > 0 &&
                  Object.entries(data?.getAnalyticalRank?.rows[0]?.collumns)
                    ?.filter((entry) => !!entry[1])
                    ?.map((entry, index) => (
                      <TableCell key={index}>
                        {renderCell(entry[0], entry[1].label)}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getAnalyticalRank?.rows.map((row, index) => (
                <RowInner
                  key={`${index}-row`}
                  row={row.collumns}
                  operationId={row.operationId}
                  classes={classes}
                  market={state.market.toLowerCase()}
                />
              ))}
            </TableBody>
            <TableRow className={classes.collapseTableRow}>
              <TableCell colSpan={collumnsNumber}>
                <Pagination
                  count={Math.ceil(total / limit)}
                  page={page}
                  onChange={onChangePagination}
                  shape="rounded"
                  siblingCount={0}
                  size="small"
                  className={classes.collapsePagination}
                />
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </If>
      <If condition={!data?.getAnalyticalRank?.rows?.length}>
        <Box className={classes.noDetails}>
          <Typography>Não possui detalhes relacionados.</Typography>
        </Box>
      </If>
    </Collapse>
  );
}

export default CollapseTableRanking;
