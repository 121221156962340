import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  max-width: 100%;
  max-height: 30%;
  border-radius: 8px;
  border-top: 20px solid #ff8211;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  background-color: #f3f3f3;
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
`;
