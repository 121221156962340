import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';

type InitialState = {
  bannerMessage: {
    message: string;
    type: 'success' | 'error';
    autoclose?: boolean;
    hasAction?: boolean;
    actionText?: string;
    actionCallback?: () => void;
  };
};

export const displaySlice = createSlice({
  name: 'display',
  initialState: {
    bannerMessage: {
      message: '',
      type: 'error',
      autoclose: true,
    },
  } as InitialState,
  reducers: {
    setBannerMessage: (
      state: InitialState,
      action: PayloadAction<InitialState['bannerMessage']>,
    ) => {
      state.bannerMessage = action.payload;
    },
  },
});

export const { setBannerMessage } = displaySlice.actions;

export const Getters = {
  bannerMessage: (store: RootState) => store.display.bannerMessage,
};

export default displaySlice.reducer;
