import { Box } from '@mui/material';
import * as DOMPurify from 'dompurify';

import * as S from './styles';
import { Chapter } from '../../types';
import { getChapterSectionElementRef } from '../../utils';

interface APIDetailsContentProps {
  chapter: Chapter;
}

export default function APIDetailsContent({ chapter }: APIDetailsContentProps) {
  const purifyContent = (content: string) =>
    DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  return (
    <Box sx={{ mt: '75px' }}>
      <S.ContentChapterTitle>{chapter.title}</S.ContentChapterTitle>
      {chapter.sections.map((section, sectionIndex) => (
        <Box
          component="section"
          mb={6}
          key={`${section.title}-${sectionIndex}`}
          id={getChapterSectionElementRef(chapter.id, section.id)}>
          <S.ContentSectionTitle>{section.title}</S.ContentSectionTitle>
          <S.ContentRichTextWrapper
            dangerouslySetInnerHTML={{
              __html: purifyContent(section.content),
            }}></S.ContentRichTextWrapper>
        </Box>
      ))}
    </Box>
  );
}
