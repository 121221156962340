import { useState } from 'react';

import { FilterList } from '@mui/icons-material';
import { Box, Button, Drawer, Hidden, List, ListItem } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getMarkets } from 'src/services/institution';
import { Event } from 'src/types/event';
import { Market } from 'src/types/market';

import HeaderFilters from './HeaderFilters';
import { EventPresentationFilters } from '..';

export default function Header({
  event,
  dateFilterOptions,
  filters,
  setFilters,
}: {
  event: Event;
  dateFilterOptions: any[];
  filters: EventPresentationFilters;
  setFilters: (values: any) => void;
}) {
  const { auth } = useAuthContext();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);

  useQuery('markets', getMarkets, {
    onSuccess: ({ data }: { data: Market[] }) => {
      setMarkets(
        data.filter((market) =>
          ['CRA', 'CRI', 'FII', 'FIDC'].includes(market.name),
        ),
      );
    },
  });

  const handleSelectedMarkets = (event: { target: { value: any } }) => {
    const { value } = event.target;
    setSelectedMarkets(value);
    setFilters({
      ...filters,
      markets: value.map(
        (name: string) => markets?.find((market) => market.name === name)?.id,
      ),
    });
  };

  const handleSelectDate = (date: string) => {
    let selectedDates = [...filters.dates];
    const parsedDate = format(parseISO(date), 'yyyy-MM-dd');
    if (selectedDates?.indexOf(parsedDate) === -1) {
      selectedDates.push(parsedDate);
    } else {
      selectedDates = selectedDates?.filter(
        (selected) => selected !== parsedDate,
      );
    }
    setFilters({
      ...filters,
      dates: selectedDates,
    });
  };

  const handleSavedClick = () => {
    if (!auth.isLoggedIn) return;
    setFilters({
      ...filters,
      filterSaved: !filters.filterSaved,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
          boxShadow: '0px 5px 6px 0px #E5E5E580',
          padding: '16px',
          margin: 0,
        },
      })}>
      <Hidden smDown>
        <HeaderFilters
          filters={filters}
          markets={markets}
          dateFilterOptions={dateFilterOptions}
          selectedMarkets={selectedMarkets}
          handleSavedClick={handleSavedClick}
          handleSelectDate={handleSelectDate}
          handleSelectedMarkets={handleSelectedMarkets}
        />
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor={'right'}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          sx={{ zIndex: 1300 }}>
          <Box width={'min(90dvw, 380px)'}>
            <List
              sx={{
                pl: 'clamp(15px, 4.5vw, 30px)',
                pt: '26px',
                '& > li > * > *': {
                  padding: 0,
                  mb: '37px !important',
                },
                '& .MuiButton-startIcon': {
                  marginRight: '5px',
                  marginLeft: '0px',
                  '& svg': {
                    fontSize: '1.5rem',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                  },
                },
              }}>
              <ListItem disablePadding>
                <Box>
                  <Button
                    startIcon={
                      <FilterList color="secondary" fontSize="large" />
                    }
                    onClick={() => setOpenDrawer(false)}
                    color="inherit"
                    sx={{
                      display: 'inline-flex',
                      color: '#47484C',
                      textTransform: 'none',
                      letterSpacing: 'unset',
                      fontSize: '14px',
                      fontWeight: 600,
                      padding: 0,
                      minWidth: 0,
                      height: 'unset',
                    }}>
                    Filtros
                  </Button>
                </Box>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                <HeaderFilters
                  filters={filters}
                  markets={markets}
                  dateFilterOptions={dateFilterOptions}
                  selectedMarkets={selectedMarkets}
                  handleSavedClick={handleSavedClick}
                  handleSelectDate={handleSelectDate}
                  handleSelectedMarkets={handleSelectedMarkets}
                />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Button
          startIcon={<FilterList color="primary" fontSize="large" />}
          onClick={() => setOpenDrawer(true)}
          color="inherit"
          sx={{
            color: '#47484C',
            textTransform: 'none',
            letterSpacing: 'unset',
            fontSize: '14px',
            fontWeight: 600,
            padding: 0,
            marginLeft: 'auto !important',
            minWidth: 0,
          }}>
          Filtros
        </Button>
      </Hidden>
    </Box>
  );
}
