import { useEffect, useMemo } from 'react';

import { useDragScroll } from 'src/utils/hooks';

import * as Selector from './styles';

interface DataSelectorItem {
  name: string;
  [key: string]: any;
}

interface DataSelectorProps {
  select: (name: string) => void;
  selected: DataSelectorItem | null;
  items: DataSelectorItem[];
  id?: string;
}

export default function DataSelector({
  select,
  selected,
  items,
  id,
}: DataSelectorProps) {
  function handleSelect(name: string) {
    select(name);
  }

  const [wrapperRef] = useDragScroll();

  const isLastItemSelected = useMemo(() => {
    const lastItem = items[items.length - 1];

    if (lastItem.name === selected?.name) return true;
    return false;
  }, [selected]);

  const isFirstItemSelected = useMemo(() => {
    const firstItem = items[0];

    if (firstItem.name === selected?.name) return true;
    return false;
  }, [selected]);

  const handleArrowSelect = (direction: string) => {
    if (!selected) return;

    const selectedIndex = items.findIndex(
      (item) => item.name === selected.name,
    );

    const nextItem = items[selectedIndex + (direction === 'left' ? -1 : 1)];
    if (!nextItem) return;

    select(nextItem.name);
  };

  useEffect(() => {
    if (!selected || !id) return;

    const selector = document.querySelector(`#${id}`);
    const item = Array.from(document.querySelectorAll(`#${id} button`)).find(
      (el) => el.textContent === selected.name,
    );
    if (!selector || !item) return;
    selector.scrollTo({
      behavior: 'smooth',
      left:
        item.getBoundingClientRect().left -
        selector.getBoundingClientRect().left +
        selector.scrollLeft,
    });
  }, [selected]);

  return (
    <Selector.Container>
      <Selector.LeftArrow
        onClick={() => handleArrowSelect('left')}
        className={!isFirstItemSelected ? 'has-next' : ''}
      />
      <Selector.Wrapper ref={wrapperRef} id={id}>
        {items.map((item) => (
          <Selector.Item
            key={item.name}
            onClick={() => handleSelect(item.name)}
            className={selected?.name === item.name ? 'active' : ''}>
            {item.name}
          </Selector.Item>
        ))}
      </Selector.Wrapper>
      <Selector.RightArrow
        onClick={() => handleArrowSelect('right')}
        className={!isLastItemSelected ? 'has-next' : ''}
      />
    </Selector.Container>
  );
}
