import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import {
  findInstitutionByCNPJ,
  getMarkets,
  getRelatedInstitution,
  getSegments,
} from 'src/services/institution';
import { User } from 'src/types/user';
import { formatCNPJ } from 'src/utils/document';

import InstitutionForm from './InstitutionForm';
import ProfessionalForm from './ProfessionalForm';
import RequestOwner from './RequestOwner';
import { If } from '../If';

type Props = {
  open: boolean;
  handleClose: () => void;
  user?: User | null;
  isFetched: boolean;
};

function SocialProfileDialog({ open, user, isFetched, handleClose }: Props) {
  const { data, isLoading: institutionRelatedLoading } = useQuery(
    'getRelatedInstitution',
    getRelatedInstitution,
    {
      enabled: isFetched && user?.type === 'private_person',
    },
  );

  const { data: institutionData, isLoading: institutionLoading } = useQuery(
    'institution',
    () => findInstitutionByCNPJ(formatCNPJ(user?.document)),
    {
      enabled: isFetched && user?.type === 'legal_person',
    },
  );

  const institution = institutionData?.length ? institutionData[0] : null;

  const institutionRelated = data && Object.keys(data).length ? data : null;

  const { data: markets, isLoading: isLoadingMarkets } = useQuery(
    'markets',
    getMarkets,
    {
      enabled: open,
    },
  );
  const { data: segments, isLoading: isLoadingSegments } = useQuery(
    'segments',
    getSegments,
    {
      enabled: open,
    },
  );

  if (
    isLoadingMarkets ||
    isLoadingSegments ||
    institutionLoading ||
    institutionRelatedLoading
  )
    return <CircularProgress />;

  return (
    <Dialog
      open={open}
      maxWidth={
        user?.type === 'legal_person' && institution?.owner ? 'md' : false
      }
      sx={{ maxWidth: 850, margin: '0 auto' }}>
      <If condition={!institution?.owner}>
        <DialogTitle
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: '30px 70px 20px' }}>
          <Typography textAlign="center" fontSize={20} fontWeight={600}>
            Parabéns! Agora você é um membro da plataforma Uqbar
          </Typography>
          <Typography
            textAlign="center"
            fontSize={14}
            maxWidth={382}
            marginTop={2}>
            {institution?.id
              ? 'O CNPJ informado foi identificado na plataforma, seu cadastro será atribuído a esta instituição.'
              : `Para aprimorar sua experiência na plataforma recomendamos que
            preencha o seu perfil 
            ${
              user?.type === 'private_person' ? 'profissional' : 'institucional'
            }`}
          </Typography>
        </DialogTitle>
      </If>
      <If condition={user?.type === 'private_person'}>
        <ProfessionalForm
          segments={segments.data}
          markets={markets.data}
          user={user}
          institution={institutionRelated}
          handleClose={handleClose}
          isInvited={false}
        />
      </If>
      <If condition={user?.type === 'legal_person' && !institution?.owner}>
        <InstitutionForm
          segments={segments.data}
          markets={markets.data}
          user={user}
          institution={institution}
          handleClose={handleClose}
        />
      </If>
      <If condition={user?.type === 'legal_person' && institution?.owner}>
        <RequestOwner handleClose={handleClose} institution={institution} />
      </If>
    </Dialog>
  );
}

export default SocialProfileDialog;
