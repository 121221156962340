import moment from 'moment';

export const formatDate = (date) => {
  if (!date || !moment(date).isValid()) {
    return date;
  }
  return moment(date).format('DD/MM/YYYY');
};

export const formatCompleteDate = (date) => {
  if (!date || !moment(date).isValid()) {
    return date;
  }
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
};
