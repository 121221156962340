import { makeStyles } from '@material-ui/core';

export const useBannerRotatingSlideStyles = makeStyles((theme) => ({
  slide: {
    width: '100vw',
    display: 'flex',
    position: 'relative',
  },
  imageContainer: {
    width: '100vw',
    position: 'relative',
  },
  textWrapper: (props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0,0,0,0.54)',
    },
    [theme.breakpoints.up('sm')]: {
      background: props?.bestReader
        ? 'linear-gradient(90deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0.54) 66%, rgba(0, 0, 0, 0) 100%)'
        : 'transparent',
    },
  }),
  textContainer: {
    position: 'absolute',
    top: theme.spacing(10),
    left: 0,
    width: '100%',
    maxHeight: 250,
    padding: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
      maxHeight: theme.spacing(50),
    },
  },

  image: {
    width: '100vw',
    height: 567,
    objectFit: 'cover',
    objectPosition: 'center',
  },

  titleContainer: {
    maxWidth: '65%',
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
    },
  },

  subtitleContainer: {
    maxWidth: '65%',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  actions: {
    position: 'absolute',
    bottom: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4, 0, 0),
      bottom: theme.spacing(8),
      gap: theme.spacing(1),
    },
  },

  buttonCtaMain: {
    backgroundColor: '#FF8211',
    padding: theme.spacing(1, 2),
    minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  buttonCtaOptional: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'white',
    minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));
