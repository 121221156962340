import { useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';
import { flatten } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import SkeletonGrid from 'src/components/SkeletonGrid';
import { getEventSpeakers } from 'src/services/events';
import { Speaker as SpeakerType } from 'src/types/event';
import { useIsElementVisible } from 'src/utils/hooks';

import Speaker from './Speaker';

export default function Speakers() {
  const { id } = useParams<{ id: string }>();
  const [speakers, setSpeakers] = useState<SpeakerType[]>([]);
  const [total, setTotal] = useState(0);
  const lastRef = useRef(null);
  const isLastVisible = useIsElementVisible(lastRef);

  const {
    isLoading: speakersLoading,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['speakers', id],
    ({ pageParam = 1 }) =>
      getEventSpeakers({
        page: pageParam,
        pageSize: 6,
        filters: { eventId: id },
      }),
    {
      onSuccess: (data) => {
        const page = data.pages.at(-1);
        setTotal(page?.meta?.pagination?.total);
        setSpeakers(flatten(data.pages.map((i: any) => i.data)));
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data?.length !== 0 ? nextPage : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60,
    },
  );

  const isLoading = speakersLoading || isFetchingNextPage;

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isLastVisible && speakers?.length < total && !isLoading) {
        fetchNextPage();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [isLastVisible, speakers, isLoading]);

  const SpeakersList = () => (
    <>
      {speakers?.map((speaker: SpeakerType) => (
        <Speaker key={speaker?.id} data={speaker} />
      ))}
    </>
  );

  return (
    <Box
      sx={(theme) => ({
        p: '26px 36px 112px 34px',
        [theme.breakpoints.down('sm')]: {
          p: '26px 16px 112px 20px',
        },
      })}>
      <Grid container rowGap={5}>
        {speakersLoading && !speakers?.length ? (
          <SkeletonGrid
            height="445px"
            width="350px"
            skeletonNumber={6}
            xs={12}
            sm={6}
            md={4}
            borderRadius={'5px'}
          />
        ) : (
          <SpeakersList />
        )}
      </Grid>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          padding="24px 0px">
          <CircularProgress color="secondary" size="3rem" />
        </Box>
      )}
      <div ref={lastRef} />
    </Box>
  );
}
