import { parse, startOfDay, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MRT_SortingState } from 'material-react-table';
import { User } from 'src/types/user';

function stringToDate(dateString: string) {
  return parse(dateString, 'MMM yyyy', new Date(), {
    locale: ptBR,
  });
}

export function handleLimitDatasetBySubscriptionPlan(
  user: User,
  datasets: any[],
  labels: string[],
) {
  if (!user) {
    return { datasets, labels };
  }

  const { availableDataPeriod, type: subscriptionPlanType } =
    user.subscription.plan;

  if (subscriptionPlanType === 'vip') {
    return { datasets, labels };
  }

  const minAllowedPeriod = startOfDay(
    subMonths(new Date(), availableDataPeriod ?? 0),
  );

  const filteredLabels = labels.filter(
    (label: string) => stringToDate(label) >= minAllowedPeriod,
  );
  const filteredDatasets = datasets.map((dataset: any) => ({
    ...dataset,
    data: dataset.data.filter(
      (_: any, index: number) =>
        stringToDate(labels[index]) >= minAllowedPeriod,
    ),
  }));

  return {
    labels: filteredLabels,
    datasets: filteredDatasets,
  };
}

export const convertSorting = (sorting: MRT_SortingState) => {
  return sorting.map((item) => ({
    [item.id]: item.desc ? 'desc' : 'asc',
  }));
};
