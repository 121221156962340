import { ReactNode, useEffect, useState } from 'react';

import { Link } from '@mui/icons-material';
import { Box } from '@mui/material';

import { CustomTooltip } from '../CustomTooltip';

export default function CopyLinkButton({
  size = '28px',
  params,
  children,
}: {
  size?: string;
  params?: string;
  children?: ReactNode;
}) {
  const [openTooltip, setOpenTooltip] = useState(false);

  const url = params
    ? `${window.location.href}?${params}`
    : window.location.href;

  useEffect(() => {
    let timer: any;
    if (openTooltip) {
      timer = setTimeout(() => {
        setOpenTooltip(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [openTooltip]);

  return (
    <>
      <CustomTooltip
        title="Link copiado"
        open={openTooltip}
        disableHoverListener
        disableTouchListener>
        <div>
          <Box
            title="Copiar Link"
            sx={(theme) => ({
              borderRadius: '50%',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              '&:hover': {
                cursor: 'pointer',
              },
            })}
            onClick={() => {
              navigator.clipboard.writeText(url);
              setOpenTooltip(true);
            }}>
            <Link sx={{ fontSize: size }} />
            {children}
          </Box>
        </div>
      </CustomTooltip>
    </>
  );
}
