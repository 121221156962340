// Material ui components
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { PictureAsPdfOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import breakDefault from '../../../assets/imgs/articles/detail.png';

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    height: 'auto',
    minHeight: 319,
    maxWidth: 379,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      minWidth: 295,
      width: '99%',
      margin: '1px',
    },
  },
  media: {
    position: 'relative',
    height: 195,
    margin: theme.spacing(1.875),
  },
  duration: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    background: '#000',
    color: '#fff',
    padding: '4px',
    margin: '0 0.4rem ',
    borderRadius: '2px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.75rem',
  },
  content: {
    minHeight: 95,
    '&.MuiCardContent-root:last-child': {
      padding: theme.spacing(0.375, 1.875, 0),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.125em',
    lineHeight: '22px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
  },
  uploadedAt: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.875em',
    lineHeight: '17px',
  },
}));

export default function VideoCard({
  id,
  title,
  uploadedAt,
  thumbnail,
  collection,
  slug,
  duration,
  document,
  allowed = false,
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (!allowed) return;
    history.push(`/video/${collection.slug}/${slug}/${id}`);
  };

  return (
    <Card className={classes.card}>
      <CardMedia
        onClick={handleClick}
        className={classes.media}
        image={thumbnail ?? breakDefault}
        title={title}>
        <Box className={classes.duration}>
          {moment.utc(duration * 1000).format('HH:mm:ss')}
        </Box>
      </CardMedia>
      <CardContent className={classes.content}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={10} onClick={handleClick}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.uploadedAt}>
              {moment(uploadedAt).format('DD/MM/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              disabled={!allowed || !document}
              href={document?.url}
              target="_blank"
              color="primary"
              onTouchStart={(event) => event.stopPropagation()}
              onMouseDown={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              title="Realize o download do documento da apresentação">
              <PictureAsPdfOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
