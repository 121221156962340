import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AddAPhotoOutlined, PlayCircle } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Grid,
  InputLabel,
  styled,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Link } from 'react-router-dom';
import defaultCoverImage from 'src/assets/imgs/defaultCoverImage.png';
import ProgressBar from 'src/components/ProgressBar';
import { CoverVideoModal } from 'src/pages/Institution/Header/CoverVideoModal';
import { Institution, InstitutionInput } from 'src/types/institution';
import { Media } from 'src/types/strapi';

import LogoImageInput from './LogoImageInput';
import { Image } from './styles';
import CoverImageModal from '../CoverImageModal';

type Props = {
  logoImage: Media | null;
  coverImage: Media | null;
  setLogoImage: Dispatch<SetStateAction<Media | null>>;
  setCoverImage: Dispatch<SetStateAction<Media | null>>;
  institution: Institution | undefined;
  progressValue: number;
  formik: FormikProps<InstitutionInput>;
  institutionRefetch: () => void;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  width: '100%',
  '& .MuiBadge-badge': {
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    bottom: 0,
    right: '4%',
  },
}));

function Header({
  logoImage,
  setLogoImage,
  coverImage,
  setCoverImage,
  institution,
  progressValue,
  formik,
  institutionRefetch,
}: Props) {
  const [preview, setPreview] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  useEffect(() => {
    if (!formik.values.coverImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(formik.values.coverImage);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [formik.values.coverImage]);

  const handleClear = () => {
    if (setCoverImage) {
      setCoverImage(null);
    }
    setPreview(undefined);
    formik.setFieldValue('coverImage', null);
  };

  return (
    <>
      <Box sx={{ position: 'relative', padding: '0 0 20px' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center">
          <StyledBadge
            overlap={'circular'}
            color="primary"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <InputLabel
                sx={{ lineHeight: 0, color: '#FFFFFF', cursor: 'pointer' }}
                htmlFor="button-file">
                <AddAPhotoOutlined
                  fontSize="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                />
              </InputLabel>
            }>
            <Image src={preview ?? coverImage?.url ?? defaultCoverImage} />
          </StyledBadge>
          {institution?.verified && institution?.coverVideoURL && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
              }}>
              <PlayCircle
                color="secondary"
                sx={(theme) => ({
                  fontSize: '80px',
                  color: theme.palette.primary.contrastText,
                  opacity: 0.75,
                  '&:hover': {
                    transition: 'all .2s ease-in',
                    cursor: 'pointer',
                    opacity: 1,
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '70px',
                  },
                })}
                onClick={() => setOpenVideoModal(true)}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: 'fit-content',
            left: 33,
            top: 66,
            [theme.breakpoints.down('sm')]: {
              left: 0,
              top: 22,
            },
          })}>
          <LogoImageInput
            type="square"
            name="logo"
            value={formik.values.logo}
            setValue={formik.setFieldValue}
            error={formik.touched.logo && Boolean(formik.errors.logo)}
            helperText={formik.errors.logo}
            image={logoImage}
            setImage={setLogoImage}
            institution={institution}
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          marginTop: '4rem',
          paddingLeft: '21%',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '0',
          },
        })}>
        <Grid
          container
          sx={{
            marginTop: '20px',
          }}>
          <Grid item xs={12} sm={12}>
            <Typography fontSize={18} fontWeight={700}>
              Complete as informações
            </Typography>
          </Grid>
          <Grid item xs={12} sm={institution ? 10 : 12}>
            <ProgressBar color="success" value={progressValue} />
          </Grid>
          {institution && (
            <Grid
              item
              xs={12}
              sm={2}
              container
              sx={(theme) => ({
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'flex-start',
                  marginTop: '2.5rem',
                },
              })}>
              <Button
                variant="contained"
                component={Link}
                to={`/instituicao/${institution.slug}/${institution.id}/perfil`}>
                Ver perfil
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <CoverImageModal
        open={open}
        onClose={() => setOpen(false)}
        institution={institution}
        form={formik}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        handleClear={handleClear}
        institutionRefetch={institutionRefetch}
      />
      {institution?.coverVideoURL && (
        <CoverVideoModal
          open={openVideoModal}
          onClose={() => setOpenVideoModal(false)}
          url={institution?.coverVideoURL}
        />
      )}
    </>
  );
}

export default Header;
