import { subDays } from 'date-fns';
import qs from 'qs';
import {
  Profitability,
  ProfitabilityFilter,
  State,
} from 'src/types/profitability';

import { api } from '../../api/api';

export const getProfitabilityFilters = async (
  market: string,
): Promise<ProfitabilityFilter> => {
  const { data } = await api.get<ProfitabilityFilter>(
    `/meta-cri-cra-curva-rentabilidade/filters?market=${market}`,
  );
  return data;
};

export const getProfitabilityData = async (
  state: State,
  periodRange?: number | null,
): Promise<{ data: Profitability[]; meta: any }> => {
  const query = qs.stringify(
    {
      filters: {
        mercado: state.mercado,
        ...(state.metodo ? { metodo: { $startsWith: state.metodo } } : {}),
        ...(state.pulverizacao
          ? { pulverizacao: { $eq: state.pulverizacao } }
          : {}),
        ...(state.tipo_devedor
          ? { tipo_devedor: { $contains: state.tipo_devedor } }
          : {}),
        ...(state.ativo_lastro
          ? { ativo_lastro: { $contains: state.ativo_lastro } }
          : {}),
        ...(state.classificacao_risco
          ? { classificacao_risco: { $eq: state.classificacao_risco } }
          : {}),
        ...(periodRange
          ? { data: { $gte: subDays(new Date(), Number(periodRange)) } }
          : {}),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data: profitabilityData, meta },
  } = await api.get<{ data: Profitability[]; meta: any }>(
    `/meta-cri-cra-curva-rentabilidades?${query}`,
  );
  return { data: profitabilityData, meta };
};
