import { useEffect, useState } from 'react';

// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { handleLimitDatasetBySubscriptionPlan } from 'src/pages/Operation/utils';

import { TablePatrimony } from './TablePatrimony';
import Grupo3946 from '../../../../../assets/imgs/Grupo3946.svg';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_NET_WORTH_DATASET } from '../../../../../graphql/queries';

// Charts.js

// Global components
// Image

// Styles
const useStyles = makeStyles((theme) => ({
  itens: {
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    border: 'none',
  },
  range: {
    borderBottom: '2px solid #FF8211',
  },
  exportButton: {
    float: 'right',
  },
  exportIcon: {
    height: '20px',
    width: '20px',
    margin: theme.spacing(0, 1.5, 0, 0.5),
  },
}));

export default function OperationFIDCLiabilityPatrimony({ hash, name }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const [select, setSelect] = useState([]);
  const [data, setData] = useState({});
  const [networth, setNetworth] = useState(true);
  const [outflow, setOutflow] = useState(false);
  const [issue, setIssue] = useState(false);
  const [noGraph, setNoGraph] = useState(false);
  const [range, setRange] = useState(0);
  const [isTable, setIsTable] = useState(false);

  const { loading, error } = useQuery(GET_DETAILS_FIDC_NET_WORTH_DATASET, {
    variables: {
      filter: {
        operationHashId: hash,
        range,
        select,
      },
    },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions[
      'operacoes_fidic_passivo_patrimonio'
    ],
    onCompleted: (data) => {
      const { datasets, labels } = data?.getDetailsFidcNetWorthDataset || {};

      const newData = {
        getDetailsFidcNetWorthDataset: handleLimitDatasetBySubscriptionPlan(
          auth?.user,
          datasets,
          labels,
        ),
      };
      setData(newData);
    },
  });

  const handleChangeType = (event) => {
    setIsTable(event.target.checked);
  };

  useEffect(() => {
    if (isTable) {
      setSelect(['NETWORTH', 'OUTFLOW', 'ISSUE']);
      setRange(0);
    } else {
      setSelect(['NETWORTH']);
    }
  }, [isTable]);

  useEffect(() => {
    if (!networth && !outflow && !issue) {
      setNoGraph(true);
    }
    if (networth || outflow || issue) {
      setNoGraph(false);
    }
    networth
      ? setSelect((arr) => [...arr, 'NETWORTH'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'NETWORTH')]);
    outflow
      ? setSelect((arr) => [...arr, 'OUTFLOW'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'OUTFLOW')]);
    issue
      ? setSelect((arr) => [...arr, 'ISSUE'])
      : setSelect((arr) => [...arr.filter((a) => a !== 'ISSUE')]);
  }, [outflow, issue, networth, noGraph]);

  if (loading) return null;
  if (error) return null;

  return (
    <div>
      <Subtitle
        title="Patrimônio"
        hasSelectType
        isSelectedType={isTable}
        handleChangeType={handleChangeType}
        titleType1="Gráfico"
        titleType2="Tabela"
      />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_passivo_patrimonio'
          ]
        }>
        <Box py={2}>
          <PanelLogin />
        </Box>
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_passivo_patrimonio'
          ]
        }>
        <If condition={!isTable}>
          <Box py={2}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Chip
                  label="Patrimônio Líquido"
                  color={networth ? 'secondary' : 'default'}
                  variant={networth ? 'default' : 'outlined'}
                  onClick={() => setNetworth(!networth)}
                  className={classes.itens}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Emissões"
                  color={issue ? 'secondary' : 'default'}
                  variant={issue ? 'default' : 'outlined'}
                  onClick={() => setIssue(!issue)}
                  className={classes.itens}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Amortizações"
                  color={outflow ? 'secondary' : 'default'}
                  variant={outflow ? 'default' : 'outlined'}
                  onClick={() => setOutflow(!outflow)}
                  className={classes.itens}
                />
              </Grid>
            </Grid>
          </Box>
          <Box px={4}>
            <Box py={2}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(12)}
                    className={range === 12 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    12 meses
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(60)}
                    className={range === 60 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    60 meses
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="button"
                    onClick={() => setRange(0)}
                    className={range === 0 ? classes.range : ''}
                    style={{ cursor: 'pointer' }}>
                    Ótimo
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {!data?.getDetailsFidcNetWorthDataset?.labels?.length &&
            !data?.getDetailsFidcNetWorthDataset?.datasets?.length ? (
              <Box display="flex" justifyContent="center">
                <img src={Grupo3946} alt="sem gráfico" />
              </Box>
            ) : (
              <Bar
                data={{
                  labels: data?.getDetailsFidcNetWorthDataset?.labels,
                  datasets: data?.getDetailsFidcNetWorthDataset?.datasets,
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${
                            tooltipItem.label
                          }: ${tooltipItem.raw.toLocaleString('pt-BR')}`;
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      ticks: {
                        callback: (value) => {
                          return value.toLocaleString('pt-BR');
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </Box>
        </If>
        <If condition={isTable}>
          {data?.getDetailsFidcNetWorthDataset?.datasets?.length &&
          data?.getDetailsFidcNetWorthDataset?.labels?.length ? (
            <TablePatrimony
              name={name}
              rows={data?.getDetailsFidcNetWorthDataset?.datasets}
              periods={data?.getDetailsFidcNetWorthDataset?.labels}
              loading={loading}
              classes={classes}
            />
          ) : (
            <Typography>Nenhum registro encontrado</Typography>
          )}
        </If>
      </If>
    </div>
  );
}
