import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SendIcon from '@mui/icons-material/Send';
import UsersTable from 'src/components/UsersTable';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { User } from 'src/types/user';

type Label = {
  field: string;
  headerName: string;
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
};

type Invitation = {
  document: string;
  email: string;
  id: number;
  name: string;
  otherName: string;
  status: string;
  user: User;
  updatedAt?: string;
};

export default function InvitationList({
  invitations,
  resendInviteAction,
  cancelInviteAction,
  resendInviteLoading,
  cancelInvitedLoading,
  inactiveUserAction,
  inactiveUserLoading,
}: {
  invitations: Invitation[];
  resendInviteAction: (id: number) => void;
  cancelInviteAction: (id: number) => void;
  inactiveUserAction: (id: number) => void;
  resendInviteLoading: boolean;
  cancelInvitedLoading: boolean;
  inactiveUserLoading: boolean;
}) {
  const { auth } = useAuthContext();
  const actions = [
    {
      label: 'Reenviar convite',
      trigger: resendInviteAction,
      loading: resendInviteLoading,
      icon: <SendIcon />,
      type: 'resendInvite',
      tooltip:
        'Convite reenviado, aguarde o período de 5min para enviar novamente',
    },
    {
      label: 'Inativar convite',
      trigger: cancelInviteAction,
      loading: cancelInvitedLoading,
      icon: <CancelScheduleSendIcon />,
      type: 'inactiveInvite',
    },
    {
      label: 'Inativar usuário',
      trigger: inactiveUserAction,
      loading: inactiveUserLoading,
      icon: <PersonOffIcon />,
      type: 'inactiveUser',
    },
    {
      label: 'Administrador',
      icon: <ManageAccountsIcon />,
      type: 'owner',
    },
  ];
  const labels = [
    {
      field: 'avatar',
      headerName: '',
      align: 'inherit',
    },
    {
      field: 'user',
      headerName: 'Usuário',
      align: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      align: 'center',
    },
  ] as Label[];
  const content = [];
  if (auth.user) {
    content.push({
      name: auth.user.name,
      email: auth.user.email,
      status: 'Ativo',
      id: auth.user.id,
      type: 'owner',
    });
  }
  invitations?.forEach((invitation: Invitation) => {
    content.push({
      name: `${invitation.name} ${invitation.otherName}`,
      status: invitation.status,
      email: invitation.email,
      id: invitation.id,
      type: invitation.user?.id ? 'user' : 'invite',
      updatedAt: invitation.updatedAt,
    });
  });

  return (
    <section>
      <UsersTable labels={labels} datas={content} actions={actions} />
    </section>
  );
}
