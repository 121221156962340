import { useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { deleteVideo } from 'src/services/gallery';
import { setBannerMessage } from 'src/store/display';
import { Institution, Video } from 'src/types/institution';

import VideoModal from './VideoModal';
import VideosList from './VideosList';

type Props = {
  institution: Institution | undefined;
  institutionRefetch: () => void;
};

function Videos({ institution, institutionRefetch }: Props) {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleRemoveDocument = (values: Video) => {
    if (values?.id) {
      deleteVideoMutation.mutateAsync(values.id);
    }
  };

  const deleteVideoMutation = useMutation(deleteVideo, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível remover o vídeo',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Vídeo removido com sucesso',
          }),
        );
      }
      institutionRefetch();
    },
  });

  const { isLoading } = deleteVideoMutation;

  return (
    <Box sx={{ padding: '32px 24px 24px' }}>
      <Box>
        <Typography fontSize={14}>
          Adicione vídeos para serem exibidos em seu perfil institucional.
        </Typography>
        <Box sx={{ '& li~li': { marginTop: 2 } }}>
          <ul style={{ paddingLeft: '16px' }}>
            <li>Para adicionar vídeos, insira um link do Youtube ou Vimeo;</li>
            <li>É necessário fazer upload de uma imagem para a capa.</li>
          </ul>
        </Box>
        <Button
          startIcon={<AddCircle color="primary" fontSize="large" />}
          color="primary"
          onClick={() => setOpenDialog(true)}
          sx={{
            textTransform: 'none',
            textDecoration: 'underline !important',
            letterSpacing: 'unset',
            fontSize: '16px',
            fontWeight: 700,
            padding: 0,
          }}>
          Adicionar vídeo
        </Button>
        <If condition={institution?.galleryVideos?.length}>
          <VideosList
            institution={institution}
            handleRemoveDocument={handleRemoveDocument}
            institutionRefetch={institutionRefetch}
          />
        </If>
        <VideoModal
          institutionId={institution?.id}
          onClose={() => setOpenDialog(false)}
          open={openDialog}
          institutionRefetch={institutionRefetch}
        />
      </Box>
    </Box>
  );
}

export default Videos;
