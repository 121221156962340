import { useMemo } from 'react';

import * as Card from './styles';
import { sponsors } from '../../presentation/data';
import { SponsorLevel, YearbookMarket } from '../../types';
import Sponsor from '../Sponsor';

interface SponsorsCardProps {
  level: SponsorLevel;
  market: YearbookMarket;
}

const SPONSOR_IMAGES_SIZES = {
  [SponsorLevel.BRONZE]: '160px',
  [SponsorLevel.SILVER]: '170px',
  [SponsorLevel.GOLD]: '180px',
  [SponsorLevel.PLATINUM]: '200px',
  [SponsorLevel.DIAMOND]: '230px',
};

export default function SponsorsCard({ level, market }: SponsorsCardProps) {
  const filteredSponsors = useMemo(() => {
    return sponsors.filter(
      (sponsor) =>
        sponsor.level === level && sponsor.markets.includes(market.name),
    );
  }, [level, market]);

  const getSponsorImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2024/sponsors/${imageName}.png`);
  };

  if (filteredSponsors.length === 0) return null;

  return (
    <Card.Wrapper>
      <Card.Title>Patrocinadores {level.toUpperCase()}</Card.Title>
      <Card.Container>
        {filteredSponsors.map((sponsor, idx) => (
          <Sponsor
            key={`${market}-${idx}`}
            href={sponsor.site}
            image={getSponsorImage(sponsor.image).default}
            width={SPONSOR_IMAGES_SIZES[level]}
          />
        ))}
      </Card.Container>
    </Card.Wrapper>
  );
}
