import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Institution } from 'src/types/institution';

type Props = {
  institution: Institution;
};

export function Map({ institution }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY ?? '',
    language: 'pt-br',
    region: 'br',
  });

  const containerStyle = {
    height: 'inherit',
    borderRadius: '5px',
  };

  const position: google.maps.LatLngLiteral = {
    lat: institution.addresses[0]?.latitude ?? 0,
    lng: institution.addresses[0]?.longitude ?? 0,
  };

  const options: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={18}
      options={options}>
      <Marker position={position} />
    </GoogleMap>
  ) : (
    <></>
  );
}
