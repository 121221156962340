import { makeStyles } from '@material-ui/core';

export const articleStyles = makeStyles((theme) => ({
  linkSubheader: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
  dividerSubtitle: {
    height: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.common.black,
  },
  articleDetailsTags: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary.main,
  },
  contentImage: {
    height: '25em',
    width: '100% !important',
    [theme.breakpoints.down('xs')]: {
      height: '16em',
      objectFit: 'contain',
    },
  },
  contentVideo: {
    border: '10px solid black',
    borderRadius: '10px',
    backgroundColor: 'black',
  },
  blur: {
    '-webkit-filter': 'blur(5px)',
    cursor: 'not-allowed',
  },
  payWall: {
    height: 'auto',
    minHeight: '5rem',
    position: 'relative',
  },
  payWallMessageBox: {
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
    borderRadius: '5px',
    left: 100,
    top: 50,
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  payWallText: {
    fontWeight: 700,
  },
  containerComments: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  commentsContent: {
    padding: theme.spacing(2, 0),
  },
  threadContent: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: '1px solid #d3d3d3',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(8),
      paddingLeft: theme.spacing(2),
    },
  },
  authorName: {
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));
