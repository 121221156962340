// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Global components
import GeneralDataItem from '../../../../components/GeneralDataItem';
import GeneralDataItemConditional from '../../../../components/GeneralDataItemConditional';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_DETAILS_CRI_GENERAL_DATA_BY_OPERATION_HASH_ID } from '../../../../graphql/markets/cri/queries';

export default function OperationCRIGeneralData({ hashId }) {
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_DETAILS_CRI_GENERAL_DATA_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cri_dados_gerais'
      ],
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={2}>
      <Subtitle title="Dados Gerais" />
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_cri_dados_gerais'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_cri_dados_gerais'
            ]
          }>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="CNPJ da Securitizadora"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.securitizerCnpj
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Securitizadora"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.securitizerName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº da Emissão"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.operationEmissionNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº das séries"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.operationSeriesNumber
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Quantidade de séries"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.operationSeriesQuantity
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Tipo de Estrutura"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.operationStructureType
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Data de início"
                subtitle={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.operationStartDate
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Regime Fiduciário"
                condition={
                  data?.getDetailsCriGeneralDataByOperationHashId
                    ?.isFiduciaryRegiment
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiclasses"
                condition={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getDetailsCriGeneralDataByOperationHashId
                          ?.isMulticlasses
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiseries"
                condition={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getDetailsCriGeneralDataByOperationHashId
                          ?.isMultiseries
                }
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
