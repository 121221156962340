export const abbreviatedNumber = (value: number) => {
  const bi = 10 ** 9;
  const mi = 10 ** 6;

  const suffixes = {
    [bi]: 'bi',
    [mi]: 'mi',
    1: '',
  };

  const denominator = value >= bi ? bi : value >= mi ? mi : 1;
  const suffix = suffixes[denominator];

  const formatedValue = (value / denominator).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${formatedValue} ${suffix}`.trim();
};
