import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Plan } from 'src/types/user';

import warningImage from '../../assets/imgs/warningImage.png';

type Props = {
  open: boolean;
  setOpen: any;
  hasButton: boolean;
  buttonText: string;
  buttonLink: string;
  plan?: Plan;
};

function PlanChangedDialog({
  open,
  plan,
  setOpen,
  hasButton,
  buttonText,
  buttonLink,
}: Props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const handleClick = () => {
    if (pathname === buttonLink) {
      setOpen(false);
    } else {
      setOpen(false);
      history.push(buttonLink);
    }
  };
  return (
    <Dialog open={open} maxWidth="md" sx={{ zIndex: 9999 }}>
      {plan?.type === 'free' && (
        <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label="fechar"
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}>
            <Close fontSize="medium" />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 50px 0',
          overflow: 'hidden',
          minHeight: '400px',
        }}>
        <img src={warningImage} width={185} height={185} />
        <Typography
          sx={{ padding: '0px 64px' }}
          variant="h4"
          textAlign="center">
          {plan
            ? `Você foi movido para o plano ${plan?.name.toLocaleLowerCase()}.`
            : 'Atenção! O seu acesso expirou'}
        </Typography>
        <Typography
          maxWidth={394}
          textAlign="center"
          sx={{ fontSize: '0.875rem' }}>
          {!plan
            ? 'Mas não se preocupe! Basta realizar o login novamente e utilizar a plataforma como desejar'
            : plan?.type === 'free'
              ? 'Mas não se preocupe! Assine um dos nossos planos para continuar aproveitando nossa plataforma.'
              : 'Caso queira mudar o seu plano acesse “Assinatura”, clicando no avatar do menu superior'}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '0 0 40px 0' }}>
        {hasButton && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Button
              size="large"
              variant="contained"
              onClick={handleClick}
              sx={{ fontSize: '0.875rem' }}>
              {buttonText}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default PlanChangedDialog;
