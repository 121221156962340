import { useState } from 'react';

import { AddCircle, LockOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Institution } from 'src/types/institution';

import { ServicesTable } from '../ServicesTable';

type Props = {
  type: 'Serviço' | 'Projeto';
  institution: Institution;
  canRegister: boolean;
};

export default function Register({ type, institution, canRegister }: Props) {
  const { auth } = useAuthContext();
  const [openCreateService, setOpenCreateService] = useState(false);

  const tooltipTitle =
    auth.user?.type === 'private_person'
      ? 'Preencha seu perfil profissional'
      : 'Preencha seu perfil instituional';

  return (
    <Box sx={{ padding: '25px 24px 40px' }}>
      <Box>
        <Typography fontSize={14} fontWeight={600}>
          Veja como é simples:
        </Typography>
        <Box pl="6px" mb={4}>
          <ul
            style={{
              paddingLeft: '16px',
              margin: '16px 0',
              maxWidth: 'max(80%, 700px)',
            }}>
            <li>
              {type === 'Serviço'
                ? 'Cadastre aqui suas ofertas de serviço, clique em “Adicionar serviço” e em seguida preencha os dados necessários para que sua oferta seja publicada.'
                : 'Cadastre aqui os projetos que procura parceiros, clique em “Adicionar projeto” e em seguida preencha os dados necessários para que sua solicitação seja publicada.'}
            </li>
          </ul>
          <Typography
            fontSize={14}
            sx={(theme) => ({ '& a': { color: theme.palette.primary.main } })}>
            Importante: Para utilizar este recurso o perfil institucional deve
            estar devidamente preenchido, você pode encontrá-lo{' '}
            <Link to="/perfil-social/institucional">aqui</Link>;
          </Typography>
        </Box>
        <CustomTooltip title={canRegister ? '' : tooltipTitle} placement="top">
          <span>
            <Button
              startIcon={<AddCircle color="primary" fontSize="large" />}
              endIcon={!canRegister && <LockOutlined fontSize="large" />}
              color="primary"
              onClick={() => setOpenCreateService(true)}
              sx={{
                textTransform: 'none',
                textDecoration: 'underline !important',
                letterSpacing: 'unset',
                fontSize: '1rem',
                fontWeight: 700,
                padding: 0,
                marginLeft: 0.5,
              }}
              disabled={!canRegister}>
              Adicionar {type === 'Serviço' ? 'serviço' : 'projeto'}
            </Button>
          </span>
        </CustomTooltip>
        <ServicesTable
          institution={institution}
          type={type}
          openCreateService={openCreateService}
          setOpenCreateService={setOpenCreateService}
        />
      </Box>
    </Box>
  );
}
