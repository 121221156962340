import { Dispatch, ReactNode, SetStateAction } from 'react';

import { ArrowBack } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { If } from 'src/components/If';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';

function Section({
  title,
  subTitle,
  hasPaywall = false,
  paywallBanner,
  children,
  canEdit,
  openEditDialog,
  isEditing,
  setIsEditing,
}: {
  title?: string;
  subTitle?: string;
  children: ReactNode;
  hasPaywall?: boolean;
  paywallBanner?: string;
  canEdit?: boolean;
  openEditDialog?: () => void;
  isEditing?: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
}) {
  const { auth } = useAuthContext();

  const { data } = useQuery('paywall', () => getPayWallBanner(paywallBanner), {
    enabled: !auth.isLoggedIn && !!paywallBanner,
  });

  const contentPayWallBanner = _.get(data, paywallBanner ?? '');

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#FFFFFF',
        height: !auth.isLoggedIn && hasPaywall ? 300 : 'unset',
        position: 'relative',
        border: '1px solid var(--gray-5, #E0E0E0)',
        borderRadius: '5px',
        marginTop: 6,
        padding: '36px 26px',
        [theme.breakpoints.down('sm')]: {
          height: !auth.isLoggedIn && hasPaywall ? 368 : 'unset',
        },
      })}>
      <If condition={!auth.isLoggedIn && hasPaywall}>
        <Box
          sx={(theme) => ({
            zIndex: 1,
            position: 'absolute',
            width: '90%',
            top: 32,
            left: 65,
            [theme.breakpoints.down('lg')]: {
              left: 50,
            },
            [theme.breakpoints.down('md')]: {
              width: '90%',
              left: 35,
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              top: -17,
              left: 0,
            },
          })}>
          <CardDemonstration
            title={
              contentPayWallBanner && paywallBanner
                ? contentPayWallBanner?.title
                : ''
            }
            subTitle={
              contentPayWallBanner && paywallBanner
                ? contentPayWallBanner?.subTitle
                : ''
            }
            labelButton={
              contentPayWallBanner && paywallBanner
                ? contentPayWallBanner?.labelButton
                : ''
            }
            url={
              contentPayWallBanner && paywallBanner
                ? contentPayWallBanner?.url
                : ''
            }
          />
        </Box>
      </If>
      <If condition={title}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: subTitle ? 1.5 : 3.5,
          }}>
          <Typography
            sx={(theme) => ({
              fontSize: '20px',
              fontWeight: 600,
              '-webkit-filter':
                !auth.isLoggedIn && hasPaywall ? 'blur(5px)' : 'none',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                marginBottom: 2,
              },
            })}>
            {title}
          </Typography>
          {canEdit && (
            <IconButton
              size="small"
              onClick={() => {
                if (setIsEditing) {
                  setIsEditing((prevState: boolean) => !prevState);
                } else if (openEditDialog) {
                  openEditDialog();
                }
              }}>
              {isEditing ? <ArrowBack /> : <EditIcon />}
            </IconButton>
          )}
        </Box>
      </If>
      <If condition={subTitle}>
        <Typography
          sx={(theme) => ({
            fontSize: '10px',
            marginBottom: 3.5,
            '-webkit-filter':
              !auth.isLoggedIn && hasPaywall ? 'blur(5px)' : 'none',
            [theme.breakpoints.down('sm')]: {
              fontSize: '12px',
              marginBottom: 2,
            },
          })}>
          {subTitle}
        </Typography>
      </If>
      <Box
        sx={{
          '-webkit-filter':
            !auth.isLoggedIn && hasPaywall ? 'blur(5px)' : 'none',
        }}>
        {children}
      </Box>
    </Box>
  );
}

export default Section;
