// Declarative routing
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { Institution } from 'src/types/institution';
import { getSmallestImageUrl } from 'src/utils/image';

type Props = {
  institution?: Institution;
};

export default function InstitutionProfessionals({ institution }: Props) {
  return (
    <Box>
      <If condition={!institution?.verified}>
        <Section>
          <Box padding="16px 0">
            <Typography fontSize={16} fontWeight={700} textAlign="center">
              Este perfil ainda não está cadastrado em nossa plataforma, caso
              seja o proprietário desta instituição clique em “reivindicar
              perfil”.
            </Typography>
          </Box>
        </Section>
      </If>
      <If condition={!institution?.relatedProfessionals?.length}>
        <Section>
          <Box padding="16px 0">
            <Typography fontSize={16} fontWeight={700} textAlign="center">
              Ops… Não existe nenhum registro de profissional vinculado a esta
              instituição.
            </Typography>
          </Box>
        </Section>
      </If>
      <If
        condition={
          institution?.verified && institution?.relatedProfessionals?.length
        }>
        <Container
          disableGutters
          sx={(theme) => ({
            paddingTop: theme.spacing(6),
          })}>
          <Grid container>
            <Grid item container spacing={2}>
              {institution?.relatedProfessionals?.map(
                ({
                  id,
                  fullName,
                  photo,
                  role,
                  professionalTitle,
                  markets,
                  slug,
                }) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                    <Link
                      underline="none"
                      component={RouterLink}
                      to={`/professional/${slug}/${id}`}>
                      <Card
                        elevation={2}
                        sx={{
                          height: '300px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          border: '1px solid #E0E0E0',
                          borderRadius: '5px',
                          boxShadow: 'none',
                          '&:hover': {
                            boxShadow:
                              '0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
                          },
                        }}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingBottom: 0,
                          }}>
                          <Avatar
                            src={getSmallestImageUrl(photo)}
                            imgProps={{
                              sx: {
                                objectFit: 'contain',
                                backgroundColor: 'white',
                              },
                            }}
                            sx={{
                              border: '5px solid #FAFAFA',
                              width: '120px',
                              height: '120px',
                            }}
                          />

                          <Typography
                            sx={(theme) => ({
                              fontWeight: 700,
                              fontSize: '18px',
                              lineHeight: '26px',
                              textAlign: 'center',
                              color: 'black',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              padding: '16px',
                              width: '240px',
                            })}>
                            {fullName ?? ''}
                          </Typography>
                          <Box>
                            {markets?.map((market) => (
                              <Chip
                                size="small"
                                key={market.name}
                                label={market.name}
                                variant={'outlined'}
                                sx={{ color: 'black', marginRight: 0.5 }}
                              />
                            ))}
                          </Box>
                        </CardContent>
                        <CardActions
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}>
                          <Box sx={{ marginBottom: '16px' }}>
                            <Typography
                              fontSize={16}
                              textAlign="center"
                              paddingX="16px">
                              {professionalTitle ?? role}
                            </Typography>
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                ),
              )}
            </Grid>
          </Grid>
        </Container>
      </If>
    </Box>
  );
}
