import {
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  styled,
  Typography,
} from '@mui/material';
import Avatar from 'react-avatar';
import { Service } from 'src/types/marketplace';
import { truncateText } from 'src/utils/string';

import { FavoriteRowButton } from '../FavoriteRowButton';

type MarketplaceItemCardProps = {
  image?: string;
  imageTitle?: string;
  service: Service;
  subheader: any;
  ownerType: string;
  type: 'Oferta' | 'Demanda';
  leftButtonIsVisible: boolean;
  setData: (values: any) => void;
  actionRightButton?: () => void;
};

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: 'fit-content',
  fontWeight: 500,
  lineHeight: '22px',
  fontSize: '12px',
  padding: 0,
  minWidth: 'unset',
}));

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
  width: 371,
  minHeight: '287px',
  [theme.breakpoints.down('sm')]: {
    width: 335,
  },
}));

export default function MarketplaceItemCard({
  image,
  imageTitle,
  service,
  subheader,
  ownerType,
  setData,
  actionRightButton,
}: MarketplaceItemCardProps) {
  const handleAddFavorite = (rowId: number, favorite: any) => {
    setData((prev: any) => {
      const favoritedItem = prev.map((item: any) => {
        if (item.id === rowId) {
          return { ...item, favorite };
        }

        return item;
      });

      return favoritedItem;
    });
  };

  const handleRemoveFavorite = (rowId: number) => {
    setData((prev: any) => {
      const favoritedItem = prev.map((item: any) => {
        if (item.id === rowId) {
          return { ...item, favorite: undefined };
        }

        return item;
      });

      return favoritedItem;
    });
  };

  return (
    <CustomCard>
      <CardHeader
        avatar={
          <Avatar
            src={image}
            name={imageTitle}
            maxInitials={1}
            size={'50'}
            round={ownerType === 'professional'}
            style={{
              maxWidth: 50,
              maxHeight: 50,
            }}
            className="img-avatar_custom"
          />
        }
        title={service.title}
        subheader={subheader}
        titleTypographyProps={{
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '19.5px',
        }}
        sx={{
          '& .MuiCardHeader-content': {
            height: 60,
          },
          '.img-avatar_custom > img': {
            objectFit: 'contain',
          },
          alignItems: 'flex-start',
        }}
      />

      <CardContent sx={{ height: 152, padding: '24px 16px 16px' }}>
        <Typography sx={{ fontSize: '16px', lineHeight: '19.5px' }}>
          {truncateText(service.description, 170)}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          height: 55,
          padding: '6px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <CustomButton onClick={actionRightButton}>Ver mais</CustomButton>
        <FavoriteRowButton
          type="service"
          rowId={service.id}
          favoriteId={service.favorite?.id}
          handleAddFavorite={handleAddFavorite}
          handleRemoveFavorite={handleRemoveFavorite}
        />
      </CardActions>
    </CustomCard>
  );
}
