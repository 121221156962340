import { useState } from 'react';

import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { chunk } from 'lodash';
import { useQuery } from 'react-query';
import { formatRadarText } from 'src/utils/number';

import SubtitleLink from '../../../components/SubtitleLink';
import { getRadarForHome } from '../../../services/elastic';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    marginLeft: 0,
    minHeight: 400,
    maxHeight: 400,
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24.2px',
    marginBottom: '1rem',
  },
  verticalDivider: {
    height: 70,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      width: 250,
      marginLeft: '50px',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5, 0, 7.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  text: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: '#797979',
  },
  item: {
    marginRight: 30,
  },
}));

export default function Radar() {
  const classes = useStyles();
  const [market, setMarket] = useState('fidc');
  const { data, isLoading, isFetched } = useQuery('radarHome', () =>
    getRadarForHome(),
  );

  const items = data?.markets && Object.values(data?.markets[market]);

  return (
    <Paper elevation={0} className={classes.paper}>
      {isLoading && <Skeleton width={'100%'} height={370} variant="rect" />}
      {isFetched && items?.length && (
        <>
          <Grid container>
            <Grid item xs={12} sm md={6} lg={6}>
              <SubtitleLink
                title="Radar"
                url={`/radar?market=${market}`}
                labelLink="Ver radar completo"
              />
            </Grid>
            <Grid item xs={12} sm md={6} lg={6}>
              <BottomNavigation
                value={market}
                onChange={(event, newValue) => {
                  setMarket(newValue);
                }}
                showLabels
                className={classes.root}>
                <BottomNavigationAction
                  className={classes.item}
                  label="FIDC"
                  value="fidc"
                />
                <BottomNavigationAction
                  className={classes.item}
                  label="FII"
                  value="fii"
                />
                <BottomNavigationAction
                  className={classes.item}
                  label="CRA"
                  value="cra"
                />
                <BottomNavigationAction
                  className={classes.item}
                  label="CRI"
                  value="cri"
                />
              </BottomNavigation>
            </Grid>
          </Grid>

          {Array.isArray(items)
            ? chunk(items, 2).map(([item1, item2], chunkIndex) => (
                <>
                  <Grid
                    key={`radar-line-${chunkIndex + 1}`}
                    container
                    alignItems="center">
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <Typography className={classes.value}>
                        {formatRadarText(item1.value)}
                      </Typography>
                      <Typography className={classes.text}>
                        {item1.label}
                      </Typography>
                    </Grid>
                    <Hidden smUp>
                      <Divider className={classes.divider} />
                    </Hidden>
                    <Hidden xsDown>
                      <Divider
                        orientation={'vertical'}
                        className={classes.verticalDivider}
                      />
                    </Hidden>
                    <Grid item xs className={classes.gridItem}>
                      <Typography className={classes.value}>
                        {formatRadarText(item2?.value)}
                      </Typography>
                      <Typography className={classes.text}>
                        {item2?.label}
                      </Typography>
                    </Grid>
                  </Grid>

                  {chunkIndex === 0 && <Divider className={classes.divider} />}
                </>
              ))
            : []}
        </>
      )}
    </Paper>
  );
}
