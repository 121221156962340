import React, { useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ChatIcon from '@mui/icons-material/Chat';
import StoreIcon from '@mui/icons-material/Store';
import {
  Box,
  Button,
  Unstable_Grid2 as Grid,
  Link,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Section from 'src/components/ProfileSection';
import { Activity } from 'src/types/activity';
import { Professional } from 'src/types/professional';

type Props = {
  activities: Activity[] | undefined;
  professional: Professional | undefined;
};

export function Activities({ activities, professional }: Props) {
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? 20 : 4;
  const buttonText = showAll ? 'Ver Menos' : 'Ver Mais';

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const renderActivityItem = (
    icon: React.ReactNode,
    text: string | JSX.Element,
    date: string,
  ) => (
    <Grid container spacing={2} direction="row" flexWrap="nowrap">
      <Grid sx={{ minWidth: '40px' }}>{icon}</Grid>
      <Grid container alignItems="center">
        <Grid>
          <Typography>{text}</Typography>
          <Typography sx={{ color: '#828282', fontSize: '12px' }}>
            {date}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderArticleActivity = (
    activity: Activity,
    icon: React.ReactNode,
    verb: string,
  ) => {
    return renderActivityItem(
      icon,
      <Box>
        {`${professional?.fullName} ${verb} `}
        <Link
          href={`/artigo/${activity.article?.slug}/${activity.article?.id}`}
          color="#FF8211"
          underline="always"
          title={activity.article?.title}>
          {`"${activity.article?.title?.trim()}"`}
        </Link>
      </Box>,
      moment(activity?.activityAt).format('L'),
    );
  };

  const renderActivity = (activity: Activity) => {
    switch (activity.__component) {
      case 'activity.article-owner':
        if (!activity?.article?.publishedAt) return null;
        return renderArticleActivity(
          activity,
          <ArticleIcon />,
          'publicou um artigo',
        );
      case 'activity.article-mentioned':
        if (!activity?.article?.publishedAt) return null;
        return renderArticleActivity(
          activity,
          <ChatIcon />,
          'foi mencionado(a) no artigo',
        );
      case 'activity.marketplace':
        if (!activity?.offer?.publishedAt) return null;
        return renderActivityItem(
          <StoreIcon />,
          <>
            {`${professional?.fullName} publicou uma demanda no marketplace `}
            <Link
              href={`/marketplace/${activity.offer?.type.toLocaleLowerCase()}s?modo=${activity.offer?.type.toLocaleLowerCase()}s&keyword=${
                activity.offer?.title
              }`}
              color="#FF8211"
              underline="always">
              {`"${activity.offer?.title}"`}
            </Link>
          </>,
          moment(activity?.activityAt).format('L'),
        );
      case 'activity.informative':
        return renderActivityItem(
          <CelebrationIcon />,
          `${activity.content}`,
          moment(activity?.activityAt).format('L'),
        );
      default:
        return null;
    }
  };

  const renderedActivities = activities
    ?.slice(0, itemsToShow)
    .map((activity) => (
      <React.Fragment key={`${activity.id}-${activity.__component}`}>
        {renderActivity(activity)}
      </React.Fragment>
    ));

  return (
    <Section title="Atividades recentes">
      <Grid container direction="column" spacing={2}>
        {renderedActivities}
      </Grid>
      {activities?.length && activities?.length > 4 && (
        <Button
          variant="text"
          color="primary"
          sx={{
            height: 'unset',
            fontWeight: 500,
            minWidth: 'unset',
            padding: 0,
            marginTop: 3,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={toggleShowAll}>
          {buttonText}
        </Button>
      )}
    </Section>
  );
}
