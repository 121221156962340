import { useState } from 'react';

import { GroupsSharp } from '@mui/icons-material';
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Paper } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { findInvitations } from 'src/services/user';
import { setBannerMessage } from 'src/store/display';

import InvitationList from './InvitationList';
import NewUserForm from './NewUserForm';
import { If } from '../../components/If';
import SubTitle from '../../components/UI/Subtitle';
import TabTemplate from '../../components/UI/TabTemplate';
import { useAuthContext } from '../../context/AuthContextProvider';
import {
  cancelInvitation,
  removeInvitedUser,
  resendInvitation,
} from '../../services/invitation';
import { createSessionStripe } from '../../services/portal';

export default function UsersManagement() {
  const { auth } = useAuthContext();
  const dispatch = useDispatch();
  const [invitations, setInvitations] = useState([]);
  const [open, setOpen] = useState(false);

  const { isLoading, refetch } = useQuery(
    'findSubscription',
    () => findInvitations(auth.user?.id),
    {
      enabled: !!auth.user?.subscription?.id,
      refetchInterval: 1000 * 60 * 5,
      refetchIntervalInBackground: true,
      onSuccess: (data) => {
        setInvitations(data?.invitations);
      },
    },
  );

  const resendInvite = useMutation(resendInvitation, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Erro ao reenviar convite, tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Convite reenviado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      refetch();
    },
  });

  const cancelInvite = useMutation(cancelInvitation, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Erro ao excluir convite, tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Convite cancelado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      refetch();
    },
  });

  const removeUser = useMutation(removeInvitedUser, {
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Erro ao inativar o usuário',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Usuário inativado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      refetch();
    },
  });

  const resendInviteAction = (id: number) => {
    resendInvite.mutate(id);
  };
  const cancelInviteAction = (id: number) => {
    cancelInvite.mutate(id);
  };
  const removeUserAction = (id: number) => {
    removeUser.mutate(id);
  };

  const createSession = useMutation('createSession', createSessionStripe, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
    },
  });

  const handleSubscriptionUpdate = () => {
    createSession.mutate({ type: 'subscription_update' });
  };

  return (
    <Box>
      <TabTemplate
        title="Usuários"
        titleIcon={<GroupsSharp />}
        tabContent={[]}
        tabIndex={0}
        handleChange={() => {}}
        breadcrumbs1="Usuários"
      />
      <Paper elevation={2} sx={{ marginTop: 9, padding: '56px 30px 10px' }}>
        <SubTitle
          title="Dados da assinatura"
          buttons={[
            {
              label: 'Convidar usuário',
              handle: () => setOpen(true),
              disabled: auth.user?.subscription
                ? auth.user?.subscription.activeSubscribers >=
                  auth.user?.subscription.slots
                : false,
              tooltipTitle:
                auth.user?.subscription &&
                auth.user?.subscription.activeSubscribers >=
                  auth.user?.subscription.slots
                  ? 'Todos os seus acessos já foram utilizados, contrate mais acessos para enviar novos convites'
                  : '',
            },
            {
              label: 'Contratar acessos',
              handle: handleSubscriptionUpdate,
              disabled: false,
            },
          ]}
        />

        <Box
          sx={(theme) => ({
            padding: '28px 30px 30px',
            [theme.breakpoints.down('sm')]: {
              padding: '28px 10px 30px',
            },
          })}>
          <Grid container spacing={2}>
            <Container>
              <If
                condition={
                  isLoading ||
                  resendInvite.isLoading ||
                  cancelInvite.isLoading ||
                  removeUser.isLoading
                }>
                <LinearProgress color="secondary" />
              </If>
              <Box>
                <Grid
                  container
                  rowSpacing={4}
                  alignItems="center"
                  sx={{ marginTop: 1 }}>
                  <Grid item xs={12} sm={6} md={8}>
                    <Typography fontSize={14} maxWidth={500}>
                      Gerencie o acesso dos usuários à plataforma Uqbar. Você
                      pode convidar novos usuários até o limite do seu plano ou
                      contratar mais acessos.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    spacing={2}
                    container
                    sx={(theme) => ({
                      justifyContent: 'flex-end',
                      [theme.breakpoints.down('sm')]: {
                        justifyContent: 'flex-start',
                      },
                    })}
                    xs={12}
                    sm={6}
                    md={4}>
                    <Grid item>
                      <Typography fontSize={12}>Acessos contratados</Typography>
                      <Typography fontSize={40} fontWeight={700}>
                        {auth.user?.subscription.slots}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={12}>Acessos utilizados</Typography>
                      <Typography fontSize={40} fontWeight={700}>
                        {auth.user?.subscription.activeSubscribers}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <InvitationList
                  invitations={invitations}
                  resendInviteAction={resendInviteAction}
                  cancelInviteAction={cancelInviteAction}
                  inactiveUserAction={removeUserAction}
                  inactiveUserLoading={removeUser.isLoading}
                  cancelInvitedLoading={cancelInvite.isLoading}
                  resendInviteLoading={resendInvite.isLoading}
                />
              </Box>
            </Container>
          </Grid>
        </Box>
      </Paper>
      <NewUserForm
        open={open}
        onClose={() => setOpen(false)}
        refetch={refetch}
      />
    </Box>
  );
}
