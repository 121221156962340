import React, { MutableRefObject } from 'react';

import { data } from './data';
import Page from '../components/Page';
import Text from '../components/Page/Text';

type Props = {
  open: boolean;
  pageRef: MutableRefObject<any>;
};

const LetterToReader = (props: Props) => {
  return (
    <Page open={props.open} title={data.title} pageRef={props.pageRef}>
      <Text text={data.text} />
    </Page>
  );
};

export default LetterToReader;
