import styled from 'styled-components';

type ItemProps = {
  selected: boolean;
  show: boolean;
  notransition: boolean;
};

export const Item = styled.div<ItemProps>`
  text-transform: uppercase;
  color: ${(props) => (props.selected ? '#e97e34' : 'black')};
  font-family: 'Montserrat', sans-serif;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  font-size: 14px;
  padding: 0 20px;
  transition: all 2s linear;
  transition: color 0s linear;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @keyframes showItem {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  animation-name: ${(props) => (props.show ? 'showItem' : '')};
  animation-duration: ${(props) => (props.notransition ? '0s' : '2s')};

  & + & {
    margin-top: 7%;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-height: 1000px) {
    font-size: 14px;

    & + & {
      margin-top: 4%;
    }
  }
`;

type WrapperProps = {
  open: boolean;
  hide: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: ${(props) => (props.open ? '300px' : '15px')};
  max-width: 300px;
  row-gap: 1%;
  z-index: 999;
  background-color: white;
  height: 100vh;
  transition: all 1s linear;

  @media screen and (max-width: 1000px) {
    display: none;
    background-color: red;
  }
`;

export const Items = styled.div<WrapperProps>`
  position: relative;
  padding-top: 20px;
  border-right: 5px solid #e97e34;
  height: 100%;
  overflow: hidden;

  transition: all 1s linear;
`;

type BackIconProps = {
  show: boolean;
};

export const BackIconWrapper = styled.div<BackIconProps>`
  position: absolute;
  top: ${(props) => (props.show ? '20px' : '20px')};
  left: ${(props) => (props.show ? '287px' : '1px')};
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  box-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.3),
    -1px -1px 2px rgba(0, 0, 0, 0.3);
  z-index: 999;
  transition: all 1s linear;
  transform: ${(props) => (props.show ? '' : 'rotate(180deg)')};

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 11px;
    fill: #797979;
  }
`;

type BackButtonProps = {
  show: boolean;
};

export const BackButton = styled.div<BackButtonProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: absolute;
  align-items: center;
  margin-top: auto;
  padding-left: 20px;
  bottom: 3%;

  @keyframes showItem {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  animation-name: ${(props) => (props.show ? 'showItem' : '')};
  animation-duration: 2s;

  &:hover {
    cursor: pointer;
  }
`;

export const BackButtonText = styled.div`
  text-transform: uppercase;
  color: black;
  font-weight: 500;
  font-size: 14px;
`;
