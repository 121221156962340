import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { convertValue } from '../../utils/number';
import { If } from '../If';
import PanelCraLiabilityTitle from '../PanelCraLiabilityTitle';
import PanelCriLiabilityTitle from '../PanelCriLiabilityTitle';
import PanelFidcLiabilityQuote from '../PanelFidcLiabilityQuote';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  bold: {
    fontWeight: 500,
  },
});

function Row(props) {
  const { row, market } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const hasNumber = (myString) => /\d/.test(myString);
  const hasValue = (value) => {
    return value === 'N/D' ? '-' : value;
  };
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.bold}>{row.class}</TableCell>
        <TableCell className={classes.bold}>{row.series}</TableCell>
        <TableCell>
          {market === 'FIDC'
            ? hasValue(row.benchmark)
            : hasValue(row.cetipCode)}
        </TableCell>
        <TableCell align="right">
          {market === 'FIDC'
            ? hasNumber(row.netWorth)
              ? `${convertValue(row.netWorth, 'million')}`
              : '-'
            : hasNumber(row.amountIssued)
              ? `${convertValue(row.amountIssued, 'million')}`
              : '-'}
        </TableCell>
        <TableCell align="right">
          {market === 'FIDC' ? row.competence : row.remuneration}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <If condition={market === 'FIDC'}>
                <PanelFidcLiabilityQuote
                  id={row.id}
                  classQuote={row.class}
                  series={row.series}
                  netWorth={row.netWorth}
                  quantity={row.quantity}
                  benchmark={row.benchmark}
                  rating={row.rating}
                  agencyRating={row.agencyRating}
                  bookValue={row.bookValue}
                  outflow={row.outflow}
                  outflowQuantity={row.outflowQuantity}
                  competence={row.competence}
                  netWorthUnit={row.netWorthUnit}
                  quotesEmissionsQuantity={row.quotesEmissionsQuantity}
                />
              </If>
              <If condition={market === 'CRI'}>
                <PanelCriLiabilityTitle
                  id={row.id}
                  classTitle={row.class}
                  series={row.series}
                  emissionDate={row.emissionDate}
                  amountIssued={row.amountIssued}
                  quantityIssued={row.quantityIssued}
                  remuneration={row.remuneration}
                  riskClassification={row.riskClassification}
                  cetipCode={row.cetipCode}
                  term={row.term}
                  debitBalance={row.debitBalance}
                  debitBalanceDate={row.debitBalanceDate}
                  unitValueIssued={row.unitValueIssued}
                  offerType={row.offerType}
                  leader={row.leader}
                />
              </If>
              <If condition={market === 'CRA'}>
                <PanelCraLiabilityTitle
                  id={row.id}
                  classTitle={row.class}
                  series={row.series}
                  emissionDate={row.emissionDate}
                  amountIssued={row.amountIssued}
                  quantityIssued={row.quantityIssued}
                  remuneration={row.remuneration}
                  riskClassification={row.riskClassification}
                  cetipCode={row.cetipCode}
                  term={row.term}
                  unitValueIssued={row.unitValueIssued}
                  offerType={row.offerType}
                  status={row.status}
                  leader={row.leader}
                />
              </If>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function HeadTable({ market }) {
  return (
    <TableHead>
      {market === 'FIDC' ? (
        <TableRow>
          <TableCell />
          <TableCell>Classe</TableCell>
          <TableCell>Série</TableCell>
          <TableCell>Benchmark</TableCell>
          <TableCell align="right">Patrimônio Líquido</TableCell>
          <TableCell align="right">Data de Referência</TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell />
          <TableCell>Classe</TableCell>
          <TableCell>Série</TableCell>
          <TableCell>Cód. Cetip</TableCell>
          <TableCell align="right">Montante Emitido</TableCell>
          <TableCell align="right">Remuneração</TableCell>
        </TableRow>
      )}
    </TableHead>
  );
}

export default function TableLiabilityOperation({
  market = 'FIDC' | 'CRI' | 'CRA',
  rows,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <HeadTable market={market} />
        <TableBody>
          {rows.map((row, index) => (
            <Row key={index} market={market} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
