import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import { Institution } from 'src/types/institution';
import { OperationPerformance } from 'src/types/operation';

import { api, publicApi } from '../../api/api';
import { StrapiResponseMeta } from '../../types/strapi';

export const getInstitutionsRelatedArticles = async () => {
  const query = qs.stringify(
    {
      pagination: {
        pageSize: '100',
      },
      filters: {
        relatedArticles: {
          id: {
            $notNull: true,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/instituicoes?${query}`);
  return data;
};

export const getMarkets = async () => {
  const query = qs.stringify(
    {
      pagination: {
        pageSize: 100,
      },
      sort: ['name:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/mercados?${query}`);
  return data;
};

export const getSegments = async () => {
  const { data } = await publicApi.get(
    '/segmentos?sort[0]=code&pagination[pageSize]=100',
  );
  return data;
};

export const getCategories = async () => {
  const { data } = await publicApi.get(
    '/categorias?sort[0]=title&pagination[pageSize]=100',
  );
  return data;
};

export const findInstitutionByNameOrCNPJ = async (searchText?: string) => {
  const query = qs.stringify(
    {
      fields: ['id', 'razaoSocial', 'fantasyName'],
      filters: {
        $or: [
          {
            fantasyName: {
              $containsi: searchText,
            },
          },
          {
            cnpj: searchText,
          },
        ],
      },
      sort: ['fantasyName:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/instituicoes?${query}`);
  return data;
};

export const findInstitutionByCNPJ = async (cnpj: string) => {
  const query = qs.stringify(
    {
      populate: [
        'addresses',
        'contactDetails',
        'emails',
        'facebook',
        'linkedin',
        'twitter',
        'logo',
        'coverImage',
        'markets',
        'owner',
        'phones',
        'segments',
        'services',
        'benefits',
      ],
      filters: {
        cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get<StrapiResponse<Institution[]>>(`/instituicoes?${query}`);
  return data;
};

export const findInstitutionById = async (id: number, isProfile: boolean) => {
  const query = qs.stringify(
    {
      populate: {
        seo: true,
        addresses: true,
        contactDetails: true,
        emails: true,
        facebook: true,
        linkedin: true,
        twitter: true,
        instagram: true,
        logo: true,
        coverImage: true,
        markets: true,
        phones: true,
        segments: true,
        relatedProfessionals: {
          fields: ['id', 'slug', 'fullName', 'role', 'professionalTitle'],
          populate: ['photo', 'markets'],
        },
        participators: {
          populate: ['participator', 'markets'],
        },
        atuacao: true,
        services: {
          filters: {
            publishedAt: {
              $notNull: true,
            },
          },
          populate: ['markets'],
        },
        benefits: true,
        relatedArticles: {
          fields: ['id', 'slug', 'publishedAt', 'title'],
        },
        featuredOperations: {
          populate: ['operation', 'operation.market'],
        },
        documents: {
          populate: ['pdf'],
        },
        ...(!isProfile
          ? {
              galleryImages: {
                populate: ['image'],
              },
              galleryVideos: {
                populate: ['thumbnail'],
              },
            }
          : null),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/instituicoes/${id}?${query}`);
  return data;
};

export const getRelatedInstitution = async () => {
  const { data } = await api.get('/related-institutions', {});
  return data;
};

export const createInstitution = async (values: any) => {
  const { data } = await api.post('/instituicoes', values);
  return data;
};

export const updateInstitution = async ({
  id,
  values,
}: {
  id: number;
  values: any;
}) => {
  const { data } = await api.put(`/instituicoes/${id}`, values);
  return data;
};

export const getFilteredOperations = async (id: number, market: any) => {
  const query = qs.stringify(
    {
      filters: {
        institution: { id },
        operation: {
          market: {
            name: market,
          },
        },
      },
      populate: {
        performance: '*',
        operation: {
          populate: '*',
        },
      },
      sort: ['operation.name:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(
    `/institution-operation-performances?${query}`,
  );

  return data;
};

export const getChartDataOperations = async (
  institutionId: number,
  market: string,
  performance: string,
) => {
  const query = qs.stringify(
    {
      institution: institutionId,
      market,
      performance,
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<OperationPerformance[]>(
    `/institution-operation-by-performance?${query}`,
  );

  return data;
};

export const getAtuacoes = async () => {
  const query = qs.stringify(
    {
      sort: ['name:asc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await api.get(`/atuacoes?${query}`);
  return data;
};
export const getArticlesRelatedInstitutions = async ({
  start,
  limit,
  institutionId,
}: any) => {
  const query = qs.stringify(
    {
      pagination: {
        start,
        limit,
      },
      filters: {
        relatedInstitutions: {
          id: {
            $eq: institutionId,
          },
        },
        publishedAt: {
          $null: false,
        },
      },
      populate: ['headerImage', 'markets'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/artigos?${query}`);
  return data;
};

export const getProfessionalRequests = async (institutionId?: number) => {
  const query = qs.stringify(
    {
      filters: {
        status: {
          $in: ['Aprovado', 'Pendente'],
        },
        institution: {
          id: {
            $eq: institutionId,
          },
        },
      },
      pagination: {
        limit: 100,
      },
      sort: ['status:desc', 'createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/institution-professionals?${query}`);
  return data;
};

export const updateProfessionalRequest = async ({ id, status }: any) => {
  const { data } = await api.put(`/institution-professionals/${id}`, {
    data: {
      status,
    },
  });
  return data;
};

export const createProfessionalRequest = async (institution: any) => {
  const { data } = await api.post('/institution-professionals', {
    data: {
      institution,
    },
  });
  return data;
};

export const requestOwnershipInstitution = async (id: number) => {
  return api.post('/solicitacoes', { data: { institutionId: id } });
};

export const getRequestOwnership = async () => {
  const {
    data: { data },
  } = await api.get('/owned/solicitacoes');
  return data;
};

export const getInstitutionsToHome = async () => {
  const {
    data: { data },
  } = await api.get('/institutions-home');
  return data;
};

export const toggleInstitutionServices = async ({ id, values }: any) => {
  const { data } = await api.put(`/instituicoes/${id}`, values);
  return data;
};

export const findServiceById = async (id: string) => {
  const query = qs.stringify(
    {
      populate: {
        markets: true,
        segments: true,
        institution: {
          populate: {
            services: {
              filters: {
                publishedAt: { $notNull: true },
                id: {
                  $ne: id,
                },
              },
              populate: ['markets'],
            },
            logo: true,
            addresses: true,
          },
        },
        professional: {
          populate: {
            services: {
              filters: {
                publishedAt: { $notNull: true },
                id: {
                  $ne: id,
                },
              },
              populate: ['markets'],
            },
            photo: true,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/instituicao-servicos/${id}?${query}`);
  return data;
};

export const createService = async (values: any) => {
  const { data } = await api.post('/instituicao-servicos', {
    data: values,
  });
  return data;
};

export const updateService = async ({ id, values }: any) => {
  const { data } = await api.put(`/instituicao-servicos/${id}`, {
    data: values,
  });
  return data;
};

export const deleteService = async (id: number) => {
  const { data } = await api.delete(`/instituicao-servicos/${id}`);
  return data;
};

export const createBenefit = async (values: any) => {
  const { data } = await api.post('/instituicao-beneficios', values);
  return data;
};

export const updateBenefit = async ({ id, values }: any) => {
  const { data } = await api.put(`/instituicao-beneficios/${id}`, values);
  return data;
};

export const deleteBenefit = async (id: number) => {
  const { data } = await api.delete(`/instituicao-beneficios/${id}`);
  return data;
};

export const searchMarketplaceInstitutions = async (options: {
  type: 'Serviço' | 'Projeto';
}) => {
  const query = qs.stringify(
    {
      sort: ['fantasyName:asc'],
      fields: ['fantasyName', 'id'],
      pagination: {
        pageSize: 100,
      },
      filters: {
        services: {
          status: 'Ativo',
          type: options.type,
          publishedAt: { $notNull: true },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get<StrapiResponse<Institution[]>>(`/instituicoes?${query}`);
  return data;
};

export const getMarketplaceInstitutionServicesTypeServices = async <
  T,
>(options: {
  page: number;
  pageSize: number;
  markets?: string[];
  segments?: string[];
  institutions?: string[];
  keyword?: string;
}): Promise<{ data: T[]; meta: StrapiResponseMeta }> => {
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      pagination: {
        page: options.page,
        pageSize: options.pageSize,
      },
      populate: {
        markets: true,
        segments: true,
        institution: {
          populate: ['logo'],
        },
      },
      filters: {
        status: 'Ativo',
        type: 'Serviço',
        publishedAt: { $notNull: true },
        ...(options.markets?.length
          ? {
              markets: {
                id: {
                  $in: options.markets,
                },
              },
            }
          : {}),
        ...(options.segments?.length
          ? {
              segments: {
                id: {
                  $in: options.segments,
                },
              },
            }
          : {}),
        ...(options.institutions?.length
          ? {
              institution: {
                id: {
                  $in: options.institutions,
                },
              },
            }
          : {}),
        ...(options.keyword
          ? {
              title: {
                $containsi: options.keyword,
              },
            }
          : {}),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/instituicao-servicos?${query}`);
  return data;
};

export const getMarketplaceInstitutionServicesTypeProject = async <T>(options: {
  page: number;
  pageSize: number;
  markets?: string[];
  segments?: string[];
  institutions?: string[];
  keyword?: string;
}): Promise<{ data: T[]; meta: StrapiResponseMeta }> => {
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      pagination: {
        page: options.page,
        pageSize: options.pageSize,
      },
      populate: {
        markets: true,
        segments: true,
        institution: {
          populate: ['logo'],
        },
        professional: {
          populate: ['photo'],
        },
      },
      filters: {
        status: 'Ativo',
        type: 'Projeto',
        publishedAt: { $notNull: true },
        ...(options.markets?.length
          ? {
              markets: {
                id: {
                  $in: options.markets,
                },
              },
            }
          : {}),
        ...(options.segments?.length
          ? {
              segments: {
                id: {
                  $in: options.segments,
                },
              },
            }
          : {}),
        ...(options.institutions?.length
          ? {
              institution: {
                id: {
                  $in: options.institutions,
                },
              },
            }
          : {}),
        ...(options.keyword
          ? {
              $or: [
                {
                  title: {
                    $containsi: options.keyword,
                  },
                },
                {
                  professional: {
                    fullName: {
                      $containsi: options.keyword,
                    },
                  },
                },
              ],
            }
          : {}),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/instituicao-servicos?${query}`);
  return data;
};

export const getMarketplaceProfessionals = async <T>(options: {
  page: number;
  pageSize: number;
  markets?: number[];
  segments?: number[];
  keyword?: string;
}): Promise<{ data: T[]; meta: StrapiResponseMeta }> => {
  const query = qs.stringify(
    {
      sort: ['fullName:asc'],
      pagination: {
        page: options.page,
        pageSize: options.pageSize,
      },
      populate: ['photo'],
      filters: {
        services: {
          status: 'Ativo',
          type: 'Demanda',
          publishedAt: { $notNull: true },
        },
        ...(options.markets?.length
          ? {
              markets: {
                name: {
                  $in: options.markets,
                },
              },
            }
          : {}),
        ...(options.segments?.length
          ? {
              segments: {
                name: {
                  $in: options.segments,
                },
              },
            }
          : {}),
        ...(options.keyword
          ? {
              fullName: {
                $containsi: options.keyword,
              },
            }
          : {}),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/profissionais?${query}`);
  return data;
};

export const checkSlug = async (slug: string) => {
  const { data } = await api.patch('/instituicao/check-slug', { slug });
  return data;
};

export const createDocument = async (formData: any) => {
  const { data } = await api.post('/documents', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return data;
};

export const deleteDocument = async (id: number) => {
  const { data } = await api.delete(`/documents/${id}`);
  return data;
};

export const updateDocument = async ({
  id,
  values,
}: {
  id: number;
  values: any;
}) => {
  const { data } = await api.put(`/documents/${id}`, values);
  return data;
};
