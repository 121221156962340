import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { Presentation } from 'src/types/event';

const LiveScreen = ({ presentation }: { presentation: Presentation }) => {
  const { liveUrl } = presentation;
  const vimeoEventId = liveUrl?.match(/\/event\/(\d+)/)?.[1];
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        height={isMobile ? '800px' : '540px'}
        width={'100%'}
        maxWidth={'1250px'}
        minWidth={'280px'}
        sx={{
          '& iframe': { width: '100%', height: '100%', border: 'none' },
        }}>
        <Box flex={isMobile ? '100%' : '76.56%'}>
          <iframe
            title="vimeo-player"
            allow="accelerometer; autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            src={`https://vimeo.com/event/${vimeoEventId}/embed`}
            seamless></iframe>
        </Box>
        <Box flex={isMobile ? '100%' : '23.44%'}>
          <iframe
            title="vimeo-chat"
            src={`https://vimeo.com/live/interaction_tools/live_event/${vimeoEventId}?module=chat&theme=light`}
            seamless></iframe>
        </Box>
      </Stack>
    </>
  );
};

export default LiveScreen;
