// Material ui components
import { useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

// Util
import { convertNumber } from '../../utils/number';
import PaperPanelLiability from '../PaperPanelLiability';
import { RatingOperationModal } from '../RatingOperationModal';

// Styles
const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paper: {
    width: '100%',
    minWidth: '900px',
    padding: theme.spacing(3),
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
    marginTop: '1rem',
  },
  serie: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  content: {
    padding: theme.spacing(2),
  },
  verticalDivider: {
    width: '2px',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(#ccc 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'right',
    backgroundSize: '2px 22px',
    backgroundRepeat: 'repeat-y',
  },
  centerGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2.5rem',
  },
}));

export default function PanelFidcLiabilityQuote({
  id,
  classQuote,
  series,
  netWorth,
  quantity,
  benchmark,
  rating,
  agencyRating,
  bookValue,
  outflow,
  outflowQuantity,
  netWorthUnit,
  quotesEmissionsQuantity,
  competence,
}) {
  const classes = useStyles();
  const hasNumber = (myString) => /\d/.test(myString);

  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  return (
    <Paper elevation={2} square className={classes.paper}>
      <Grid container>
        <Grid item xs container alignItems="center">
          <Typography variant="h6" className={classes.bold}>
            {classQuote}
          </Typography>
          <Typography
            variant="h6"
            className={classes.bold}
            style={{ marginLeft: '3rem' }}>
            Série:
          </Typography>
          <Chip
            color="secondary"
            size="small"
            label={series}
            className={classes.serie}
          />
        </Grid>
        <Grid item xs container alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle1" className={classes.bold}>
            Competência:
          </Typography>
          <Chip
            label={competence}
            size="small"
            color="secondary"
            className={classes.serie}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-evenly" className={classes.content}>
        <Grid item xs={3}>
          <Typography className={classes.title}>Patrimônio Líquido</Typography>
          <PaperPanelLiability
            title="Valor Consolidado"
            value={netWorth}
            currency={hasNumber(netWorth)}
          />
          <PaperPanelLiability
            title="Quantidade"
            value={quantity}
            decimal={true}
          />
          <PaperPanelLiability
            title="Valor Unitário"
            value={netWorthUnit}
            currency={hasNumber(netWorthUnit)}
          />
        </Grid>
        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className={classes.verticalDivider}
        />
        <Grid item xs className={classes.centerGrid}>
          <Box>
            <PaperPanelLiability title="Benchmark" value={benchmark} />
            <PaperPanelLiability
              title="Classificação de Risco"
              value={
                rating && agencyRating ? `${rating} - ${agencyRating}` : 'N/D'
              }
            />
            <Button
              color="secondary"
              onClick={() => setOpenModal((prev) => !prev)}
              style={{ padding: 0 }}>
              VER HISTÓRICO
            </Button>
          </Box>
        </Grid>
        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className={classes.verticalDivider}
        />
        <Grid item xs style={{ marginLeft: '1rem' }}>
          <Typography className={classes.title}>Emissões no mês</Typography>
          <Grid container>
            <Grid
              item
              xs={6}
              container
              alignItems="baseline"
              direction="column">
              <PaperPanelLiability
                title="Montante"
                value={bookValue}
                currency={hasNumber(bookValue)}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="baseline"
              direction="column">
              <PaperPanelLiability
                title="Quantidade"
                value={
                  hasNumber(quotesEmissionsQuantity)
                    ? convertNumber(quotesEmissionsQuantity)
                    : 'N/D'
                }
              />
            </Grid>
          </Grid>
          <Typography className={classes.title} style={{ marginTop: '2rem' }}>
            Amortizações no mês
          </Typography>
          <Grid container>
            <Grid item xs={6} direction="column">
              <PaperPanelLiability
                title="Montante"
                value={outflow}
                currency={hasNumber(outflow)}
              />
            </Grid>
            <Grid item xs={6} direction="column">
              <PaperPanelLiability
                title="Valor Unitário"
                value={outflowQuantity}
                currency={hasNumber(outflowQuantity)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RatingOperationModal
        open={openModal}
        onClose={handleModalClose}
        securityId={id}
      />
    </Paper>
  );
}
