import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { requestOwnershipInstitution } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';

import image from '../../../assets/imgs/warningImage.png';

function RequestOwner({
  institution,
  handleClose,
}: {
  institution: Institution | null;
  handleClose: () => void;
}) {
  const dispatch = useDispatch();

  const requestOwner = useMutation(requestOwnershipInstitution, {
    onSuccess: () => {
      handleClose();
      dispatch(
        setBannerMessage({
          message: 'Solicitação criada com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
    },
  });

  const handleClick = () => {
    if (institution) {
      requestOwner.mutateAsync(institution.id);
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 20px 0px',
          overflow: 'hidden',
          minHeight: '455px',
        }}>
        <img src={image} width={185} height={185} />
        <Typography variant="h4" textAlign="center">
          Identificamos que esta instituição já possui um proprietário.
        </Typography>
        <Typography
          sx={{ fontSize: '0.875rem' }}
          maxWidth={372}
          textAlign="center">
          Confirme abaixo sua solicitação para fazer parte desta instituição
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '32px' }}>
        <Button
          sx={{ fontSize: '0.875rem' }}
          variant="contained"
          size="large"
          onClick={handleClick}>
          {requestOwner.isLoading ? (
            <CircularProgress sx={{ color: '#FFFFFF' }} />
          ) : (
            'Confirmar solicitação'
          )}
        </Button>
      </DialogActions>
    </>
  );
}

export default RequestOwner;
