import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Professional } from 'src/types/professional';
import styled from 'styled-components';
import * as Yup from 'yup';

const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
`;

type Props = {
  open: boolean;
  index: number;
  professional?: Professional;
  handleClose: () => void;
  handleUpdate: (values: any) => void;
  isLoading: boolean;
};

function ExperiencesForm({
  open,
  index,
  professional,
  handleClose,
  handleUpdate,
  isLoading,
}: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const FORM_VALIDATION = Yup.object().shape({
    experiences: Yup.array(
      Yup.object({
        companyName: Yup.string().required('Campo obrigatório'),
        professionalTitle: Yup.string().required('Campo obrigatório'),
        startDate: Yup.string().required('Campo obrigatório').nullable(),
        endDate: Yup.string().required('Campo obrigatório').nullable(),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      experiences: professional?.experiences ?? [],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (professional) {
        const rest = _.omit(professional, 'id');
        handleUpdate({
          data: {
            ...rest,
            experiences: values.experiences,
          },
        });
      }
    },
  });

  const getErrors = <T extends { [key: string]: any }>(
    fieldName: keyof T,
    index: number,
  ) => {
    const error = formik.errors.experiences?.[index] as T | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={(e) => {
        formik.handleReset(e);
        handleClose();
      }}
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
      }}
      PaperProps={{
        sx: {
          height: 'fit-content',
          borderRadius: '16px',
          padding: isMobile ? '8px 20px 10px 20px' : '16px',
        },
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          Editar experiência profissional
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            padding: isMobile ? '16px 0' : '16px',
          }}>
          <Grid
            container
            spacing={3}
            sx={(theme) => ({ padding: theme.spacing(2) })}>
            <Grid item xs={12} md={12}>
              <FieldTitle>Nome da empresa*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name={`experiences[${index}].companyName`}
                  value={formik.values.experiences[index].companyName}
                  onChange={formik.handleChange}
                  placeholder="Ex: Bradesco"
                  error={Boolean(
                    getErrors<{ companyName: string }>('companyName', index),
                  )}
                  helperText={getErrors<{ companyName: string }>(
                    'companyName',
                    index,
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FieldTitle>Título*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name={`experiences[${index}].professionalTitle`}
                  value={formik.values.experiences[index].professionalTitle}
                  onChange={formik.handleChange}
                  placeholder="Ex: Gerente investimentos financeiro"
                  error={Boolean(
                    getErrors<{ professionalTitle: string }>(
                      'professionalTitle',
                      index,
                    ),
                  )}
                  helperText={getErrors<{ professionalTitle: string }>(
                    'professionalTitle',
                    index,
                  )}
                />
              </FormControl>
            </Grid>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}>
              <Grid item xs={12} md={6}>
                <FieldTitle>Data de início*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <DatePicker
                    views={['month', 'year']}
                    format="MM/yyyy"
                    slotProps={{
                      textField: {
                        name: `experiences[${index}].startDate`,
                        placeholder: 'MM/AAAA',
                        error: Boolean(
                          getErrors<{ startDate: string }>('startDate', index),
                        ),
                      },
                    }}
                    onChange={(value) => {
                      formik.setFieldValue(
                        `experiences[${index}].startDate`,
                        value && isValid(value)
                          ? format(value, 'yyyy-MM-dd')
                          : value,
                      );
                    }}
                    value={
                      formik.values.experiences[index].startDate &&
                      parseISO(formik.values.experiences[index].startDate)
                    }
                  />

                  {Boolean(
                    getErrors<{ startDate: string }>('startDate', index),
                  ) && (
                    <FormHelperText sx={{ color: '#EB5757' }}>
                      {getErrors<{ startDate: string }>('startDate', index)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldTitle>Data de término*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <DatePicker
                    views={['month', 'year']}
                    format="MM/yyyy"
                    slotProps={{
                      textField: {
                        name: `experiences[${index}].endDate`,
                        placeholder: 'MM/AAAA',
                        error: Boolean(
                          getErrors<{ endDate: string }>('endDate', index),
                        ),
                      },
                    }}
                    onChange={(value) => {
                      formik.setFieldValue(
                        `experiences[${index}].endDate`,
                        value && isValid(value)
                          ? format(value, 'yyyy-MM-dd')
                          : value,
                      );
                    }}
                    value={
                      formik.values.experiences[index].endDate
                        ? parseISO(formik.values.experiences[index].endDate)
                        : undefined
                    }
                  />

                  {Boolean(
                    getErrors<{ endDate: string }>('endDate', index),
                  ) && (
                    <FormHelperText sx={{ color: '#EB5757' }}>
                      {getErrors<{ endDate: string }>('endDate', index)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '0 16px 16px', gap: 2 }}>
          <Hidden smDown>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar edição'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                handleClose();
              }}
              disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar edição'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar edição'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    handleClose();
                  }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar edição'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ExperiencesForm;
