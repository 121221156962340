import { useEffect, useState } from 'react';

import { Box, Paper } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';

import HeaderProfessionals from './HeaderProfessionals';
import TableProfessionals from './TableProfessionals';
import { AllProfessionalsStyles } from './styles';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { filterProfessionals } from '../../services/elastic';
import { getInformativeTextByLocation } from '../../services/informative';

export default function AllProfessionals() {
  const classes = AllProfessionalsStyles();
  const [filters, setFilters] = useState({ keyword: '', markets: [] });
  const [lastFound, setLastFound] = useState(0);
  const [sortHistory, setSortHistory] = useState({});
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState({ 'name.keyword': 'asc' });
  const [total, setTotal] = useState(0);
  const [professionals, setProfessionals] = useState([]);

  const { data } = useQuery('info', () =>
    getInformativeTextByLocation('professional'),
  );

  const filteredProfessionals = useMutation(filterProfessionals, {
    onSuccess: ({ data }) => {
      let filteredResult;
      if (filters?.keyword) {
        filteredResult = data.data.filter(
          ({ name, role, company, sectors }) =>
            name?.toUpperCase()?.includes(filters.keyword?.toUpperCase()) ||
            role?.toUpperCase()?.includes(filters.keyword?.toUpperCase()) ||
            company?.toUpperCase()?.includes(filters.keyword?.toUpperCase()) ||
            sectors.some((sector) =>
              sector.toUpperCase().includes(filters.keyword?.toUpperCase()),
            ),
        );
      }
      setProfessionals(filteredResult ?? data.data);
      setTotal(data.total);
    },
  });

  useEffect(() => {
    filteredProfessionals.mutate({
      data: {
        filters,
        size,
        sort,
        lastFound,
      },
    });
  }, [filters, lastFound, size, sort]);

  const { isLoading } = filteredProfessionals;

  return (
    <Box>
      <MetaTags
        title="Profissionais do Mercado de Crédito e Capitais"
        description="Encontre o profissional dos mercados de crédito e de capitais que está buscando. Pesquise por nome e mercado e entre em contato diretamente em poucos cliques"
      />
      <Subheader
        title="Profissionais"
        breadcrumbs1="Profissionais"
        tooltipText={data?.data?.professional?.informationText}
      />
      <Paper className={classes.paper}>
        <HeaderProfessionals
          classes={classes}
          setFilters={setFilters}
          filters={filters}
          setLastFound={setLastFound}
          setSortHistory={setSortHistory}
        />
        <TableProfessionals
          classes={classes}
          rows={professionals}
          loading={isLoading}
          sortHistory={sortHistory}
          setSortHistory={setSortHistory}
          setLastFound={setLastFound}
          setSize={setSize}
          setSort={setSort}
          total={total}
          size={size}
          filters={filters}
        />
      </Paper>
    </Box>
  );
}
