// Declarative routing
import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// Material ui components
// Apollo client

import CardServiceProvider from '../../../../../components/CardServiceProvider';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import {
  GET_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID,
  GET_INSTITUTION_BY_CNPJ,
} from '../../../../../graphql/queries';
// Image
// Providers

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

function CardServiceProviderWrap({ title, name, logo, cnpj }) {
  const { data, loading, error } = useQuery(GET_INSTITUTION_BY_CNPJ, {
    variables: { cnpj },
  });

  if (loading) return null;
  if (error) return null;

  return (
    <Link
      component={RouterLink}
      underline="none"
      to={`/instituicao/${data.findInstitutionByCnpj?.slug}/${data.findInstitutionByCnpj?.hashId}`}>
      <CardServiceProvider title={title} name={name} logo={logo} link />
    </Link>
  );
}

export default function OperationFIDCResumeServiceProvider({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
    },
  );

  const canViewServiceProviders =
    auth?.user?.subscription?.plan?.permissions[
      'operacoes_fidic_prestadores_de_servicos'
    ];

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Prestadores de Serviço
      </Typography>
      <Divider className={classes.divider} />
      <Box py={4}>
        {loading || error ? null : (
          <Grid container spacing={1}>
            {data.getFidcServiceProvidersByOperationHashId.administrators
              .length > 0 &&
              data.getFidcServiceProvidersByOperationHashId.administrators.map(
                ({ logo, name, cnpj }) => (
                  <Grid item xs={12} sm={6} md={12} lg={6} key={cnpj}>
                    <CardServiceProviderWrap
                      classes={classes}
                      title="Administrador"
                      logo={logo}
                      name={name}
                      cnpj={cnpj}
                    />
                  </Grid>
                ),
              )}
            {data.getFidcServiceProvidersByOperationHashId.managers.length >
              0 &&
              data.getFidcServiceProvidersByOperationHashId.managers.map(
                ({ logo, name, cnpj }) => (
                  <Grid item xs={12} sm={6} md={12} lg={6} key={cnpj}>
                    <CardServiceProvider
                      alert={!canViewServiceProviders}
                      lock={!canViewServiceProviders}
                      title="Gestor"
                      logo={logo}
                      name={name}
                    />
                  </Grid>
                ),
              )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
