// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

// Global components
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
// Apollo client
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_WALLET_BY_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIIResumeWallet({ hash }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_FII_WALLET_BY_HASH_ID, {
    variables: { hash },
    client: ipanema,
  });

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Carteira
      </Typography>
      <Divider className={classes.divider} />
      <Box py={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <PaperPanelInfo
              title="Finalidade"
              value={
                loading
                  ? null
                  : error
                    ? null
                    : data?.getFiiWalletByHashId?.operationFinality
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PaperPanelInfo
              title="Tipo de Gestão"
              value={
                loading
                  ? null
                  : error
                    ? null
                    : data?.getFiiWalletByHashId?.operationTypeOfManager ?? '-'
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PaperPanelInfo
              title="Categoria"
              value={
                loading
                  ? null
                  : error
                    ? null
                    : data?.getFiiWalletByHashId?.operationCategory
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PaperPanelInfo
              title="Tipo de imóvel"
              value={
                loading
                  ? null
                  : error
                    ? null
                    : data?.getFiiWalletByHashId?.operationPropertyType
              }
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
