import {
  fidcPrimaryMarketCharts,
  fidcSecondaryMarketCharts,
  fidcStockMarketCharts,
} from './charts/FIDC';
import {
  YearbookDescriptions,
  YearbookMarketArticle,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
} from '../types';

export const fidcOpeningTexts = [
  `
O Anuário Uqbar de FIDCs é uma ferramenta crucial para compreender a dinâmica complexa e em constante evolução do mercado de Fundos de Investimento em Direitos Creditórios (FIDCs) ao longo do ano de 2023. 
Ao fornecer uma análise detalhada das tendências, desafios e oportunidades que influenciaram o cenário dos FIDCs, este recurso abrangente oferece insights valiosos para investidores, gestores de fundos e outros participantes do mercado financeiro. 
Através de uma meticulosa coleta e análise de dados, o Anuário oferece uma visão holística das emissões, desempenho do mercado secundário, estoque de FIDCs e rankings setoriais, proporcionando uma compreensão aprofundada das dinâmicas do mercado.
`,
  `
É importante ressaltar a entrada em vigor da Resolução CVM 175, que teve um impacto significativo no mercado de FIDCs. Implementada em 2023, essa resolução introduziu mudanças regulatórias substanciais, visando promover maior transparência, padronização e governança no setor de FIDCs. 
Ao exigir uma estruturação mais rigorosa e uma divulgação mais transparente das informações, a Resolução CVM 175 redefiniu as práticas de mercado e influenciou diretamente as estratégias de investimento e alocação de recursos.
`,
  `
Além de fornecer uma visão retrospectiva do mercado, o Anuário Uqbar de FIDCs contextualiza as informações dentro do quadro regulatório e das tendências emergentes. 
Ao fazer isso, o Anuário não apenas oferece uma análise detalhada das atividades do mercado, mas também fornece uma visão prospectiva, destacando as oportunidades e desafios que podem surgir no futuro. 
Em suma, o Anuário Uqbar de FIDCs é uma ferramenta indispensável para qualquer pessoa interessada em compreender o mercado de FIDCs e suas implicações no cenário financeiro mais amplo.
`,
];

export const fidcArticles: YearbookMarketArticle[] = [
  {
    title: 'O impacto das mudanças da RCVM 175 pela ótica da Singulare',
    ads: { image: 'singulare', link: 'https://www.singulare.com.br/' },
    authorLink:
      'https://www.uqbar.com.br/profissional/daniel-doll-lemos-757/1236',
    partner: {
      name: 'Singulare',
      link: 'https://www.uqbar.com.br/instituicao/singulare/7264/perfil',
    },
    texts: [
      'A Resolução CVM 175 representa uma evolução significativa na regulação dos fundos de investimento no Brasil, consolidando e atualizando as normas anteriores para refletir as mudanças no mercado e nas práticas de investimento.',
      'As principais mudanças com a nova norma foram a criação do sistema de classes e subclasse para fundos financeiros, a limitação da responsabilidade dos cotistas e a melhor definição das responsabilidades entre administradores e gestores de fundos.',
      'O gestor passou a ter maior autonomia e a assumir responsabilidades adicionais, como o controle do enquadramento da carteira e a gestão de liquidez. Isso implica em um aumento do risco regulatório e possíveis custos adicionais, exigindo processos mais rigorosos e profissionais qualificados para garantir a conformidade com as novas regras.',
      'Já os administradores tiveram que revisar os processos internos, rever os acordos operacionais, atualizar os regulamentos dos Fundos junto aos Gestores e investir em tecnologia para atender às novas exigências.',
      `Para apoiar os gestores, a Singulare, na figura de administradora de fundos, continuou executando os processos que foram atribuídos a eles pela nova resolução. 
      Consequentemente, houve uma significativa migração de fundos do mercado para a Singulare, resultando em um aumento substancial do volume de trabalho a ser realizado em um prazo limitado.
      `,
      'As adaptações nos regulamentos também têm gerado bastante esforço para atendimento ao prazo, devido à quantidade de mais de 600 FIDCs administrados.',
      'Além disso, a Singulare integrou-se às principais Infraestruturas de Mercado Financeiro (IMFs), para garantir a conformidade com a resolução para serviços de registro de direitos creditórios, e está investindo em automações e treinamentos de novas rotinas, como a das tratativas de correspondências judiciais e extrajudiciais, as quais passaram a ser responsabilidade dos gestores.',
      'Embora tenha imposto desafios iniciais para os gestores e administradores de fundos se adaptarem a um ambiente regulatório mais exigente, com impactos nos processos e operações, a Resolução CVM 175 promove o aprimoramento da transparência, da governança e da segurança para os investidores, contribuindo para a atratividade, o crescimento e o fortalecimento do mercado de FIDCs no cenário financeiro nacional.',
    ],
  },
];

export const fidcYearbookDescriptions: YearbookDescriptions = {
  primary: [
    `
    O mercado de Fundos de Investimento em Direitos Creditórios (FIDCs) tem sido objeto de análise e observação atenta, especialmente em relação às emissões ocorridas nos últimos anos. 
    As emissões de FIDCs no mercado primário aumentaram significativamente, evidenciando uma tendência ascendente que merece análise detalhada. 
    Esta seção examinará as principais tendências nas emissões de FIDCs, com foco em diversos aspectos, incluindo o tipo de condomínio, a padronização dos fundos, e os ativos lastro mais proeminentes.
    `,
    `
    A análise das emissões de FIDCs por tipo de condomínio destaca a predominância dos fundos fechados, apesar de uma mudança notável em comparação com anos anteriores. 
    A divisão entre fundos fechados e abertos revela insights importantes sobre a preferência dos investidores e as condições de mercado que moldam essas emissões.
    `,
    `
    A crescente importância das emissões de FIDCs padronizados em 2023 sugere uma mudança nas preferências dos investidores, bem como uma adaptação às exigências regulatórias e às condições do mercado. 
    A comparação entre fundos padronizados e não padronizados oferece uma visão detalhada das dinâmicas que impulsionam essas escolhas de estruturação.
    `,
    `
    A análise dos ativos lastro mais proeminentes em 2023 destaca a posição dos recebíveis comerciais como líderes do mercado, além de destacar mudanças significativas nas participações de outros tipos de ativos.
    `,
  ],
  secondary: [
    `
    O mercado secundário de cotas de Fundos de Investimento em Direitos Creditórios (FIDCs) é um indicador vital da liquidez e da dinâmica do referido mercado. 
    Os dados revelam uma tendência notável de crescimento tanto em volume financeiro negociado quanto em número de transações ao longo do ano de 2023, comparado com o período anterior. 
    Está seção revelará detalhes dos indicadores de liquidez do mercado secundário de FIDCs, com destaque para o aumento significativo no número de negócios realizados e no valor financeiro negociado.
    `,
  ],
  stock: [
    `
    O ano de 2023 testemunhou um contínuo crescimento e estabilidade no mercado de Fundos de Investimento em Direitos Creditórios (FIDCs), refletido em recordes significativos no patrimônio líquido (PL) e na quantidade de fundos em operação. 
    Está seção analisará em detalhes o estoque do Anuário Uqbar de FIDCs, com base nos dados robustos fornecidos pelo mercado, destacando as principais tendências e dinâmicas observadas ao longo do ano.
    `,
    `
    A predominância do tipo de condomínio fechado no mercado de FIDCs se manteve em 2023, representando uma parcela significativa do patrimônio líquido total. 
    Uma análise comparativa revelará insights importantes sobre a estabilidade dessa tendência ao longo do tempo e seu impacto nas estratégias de investimento e alocação de recursos.
    `,
    `
    Os FIDCs padronizados continuaram a representar a maioria do patrimônio líquido dos FIDCs em 2023, demonstrando uma preferência histórica por parte dos investidores e uma aderência às regulamentações vigentes. 
    Uma análise das variações ao longo do tempo destacará padrões sazonais e tendências de longo prazo nessa categoria de fundos.
    `,
    `
    O desempenho dos FIDCs em termos de patrimônio líquido foi liderado pelos fundos de Recebíveis Comerciais em 2023, seguidos por outros ativos lastro notáveis, como Crédito Pessoal e Multiclasse.
    `,
    `
    Os FIDCs multicedente-multissacado (MM) apresentaram uma dinâmica particular em 2023, com um aumento no patrimônio líquido apesar de uma leve queda no número de fundos.
    `,
    `
    Essa análise abrangente do estoque do Anuário Uqbar de FIDCs oferecerá insights valiosos para investidores, gestores de fundos e outros participantes do mercado, fornecendo uma compreensão aprofundada das tendências e dinâmicas que moldam esse setor-chave do mercado de capitais.
    `,
  ],
  rankings: [
    `O Anuário Uqbar de FIDCs é uma fonte crucial de informações para compreender o mercado de Fundos de Investimento em Direitos Creditórios (FIDCs). 
    Ao examinar os rankings deste anuário, obtemos uma visão holística das dinâmicas competitivas e das tendências predominantes no setor em 2023.`,
    'Destacando-se neste ano, observamos o impressionante desempenho do longevo FIDC For-Te, líder no ranking de Montante Emitido.',
    `Além disso, o mercado secundário de cotas de FIDC registrou um aumento extraordinário no número de operações, impulsionado, em parte, pela atipicidade de um FIAGRO. 
    Essa dinâmica ressalta a crescente liquidez desse segmento.`,
    `No que diz respeito aos assessores jurídicos, o domínio dos três primeiros colocados no ranking de Montante Emitido é notável, concentrando mais de dois terços do montante total. 
    Isso evidencia a importância estratégica desses players no processo de emissão de FIDCs e na estruturação jurídica dos mesmos.`,
    'Ao analisar as agências de classificação de risco, fica evidente a liderança da Austin, tanto em termos de montante classificado quanto de número de títulos classificados.',
    'O destaque do Tapso no ranking de Montante de PL - Acumulado ressalta sua posição proeminente no mercado.',
    'No cenário dos administradores e gestores de FIDCs, vemos um aumento significativo tanto no montante de PL quanto no número de operações, indicando uma expansão substancial desses setores.',
    'Esses destaques não apenas oferecem uma visão panorâmica das principais tendências em 2023, mas também fornecem insights valiosos para investidores, gestores de fundos e outros stakeholders interessados no mercado de FIDCs.',
  ],
};
export const fidcPrimaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDC,
    name: 'Emissões de FIDC',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC',
      texts: [
        `
        As emissões de FIDCs no mercado primário registraram um aumento, totalizando R$ 155,73 bilhões em montante emitido, o que representa uma elevação de 50,26% em relação ao ano de 2022 (R$ 103,27 bilhões). 
        No entanto, em comparação com 2021, o ano de 2023 apresentou um aumento de 37,64% no montante emitido, e em relação a 2020, o aumento foi de 111,50%.
        `,
      ],
      value: 155.73,
      valueTitle: 'Montante emitido 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByCondominumType,
    name: 'Emissões de FIDC por Tipo de Condomínio',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC por tipo de Condomínio',
      texts: [
        `No mercado de FIDCs em 2023, o tipo de condomínio mais representativo continuou sendo o Fechado, como nos anos anteriores, respondendo por 75,23% das emissões. 
        Em comparação com 2022, houve um aumento de 8,11 p.p. Já os fundos do tipo de Condomínio Aberto representaram 24,77% das emissões em 2023, registrando uma queda de 8,11 p.p. em relação a 2022. `,
      ],
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByFundType,
    name: 'Emissões de FIDC por Tipo de Fundo',
    section: 'primary',
    details: {
      title: 'Emissões de FIDC Padronizados ganham espaço em 2023',
      texts: [
        `Em 2023, a maioria das emissões foi executada por FIDCs da modalidade Padronizada, representando 72,29% do montante total. Os 27,71% restantes foram emitidos por meio de FIDCs da modalidade Não Padronizada. 
        Essa predominância obteve um aumento em relação a 2022, quando os Padronizados corresponderam a 58,50% do total de emissões. 
        A preferência histórica dos Padronizados se justifica por ser mais palatável aos investidores, além do fato que os requisitos para investimento em cotas de FIDC NP serem mais restritivos. Por outro lado, as emissões de FIDC NP, que desfrutam de um caráter anticíclico, também fluem ao sabor das condições conjunturais do mercado. `,
      ],
    },
  },
  {
    chart: fidcPrimaryMarketCharts.emissionsFIDCByProductType,
    name: 'Emissões de FIDC por Ativo-Lastro',
    section: 'primary',
    details: {
      title: 'Recebíveis Comerciais é o destaque como Ativo Lastro em 2023',
      texts: [
        `Em 2023, os Recebíveis Comerciais continuam sendo o destaque como ativo lastro no mercado de FIDCs, representando 35,84% do montante emitido. 
        Houve um aumento de 3,32 p.p. em relação ao ano anterior. O Crédito Pessoal se manteve em segundo, respondendo por 17,76% do total. 
        Já o ativo-lastro Crédito Pessoa Jurídica teve uma participação de 11,19%, o que representa um aumento de 3,76 p.p. em relação a 2022. `,
      ],
    },
  },
];
export const fidcSecondaryMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcSecondaryMarketCharts.negotiatedQuotasInFIDC,
    name: 'Emissões de FIDC por Tipo de Fundo',
    section: 'secondary',
    details: {
      title: 'Indicadores de liquidez do secundário de FIDC',
      texts: [
        `
        Os dados do mercado secundário de cotas de FIDC revelam um aumento (69,25%) no valor financeiro negociado em 2023, em comparação com o ano anterior, e um crescimento significativo no número de negócios realizados. 
        Em 2022, foram realizados 21.314 negócios, resultando em um valor financeiro total de R$ 9,69 bilhões. 
        Já em 2023, houve um aumento expressivo (1261,73%) no número de negócios, com um total de 290.240, e com o valor financeiro negociado de R$ 16,41 bilhões. 
        A quantidade expressiva de negociações em 2023 se deve ao FIAGRO Crédito Agrícola, administrado pelo BTG Pactual Serviços Financeiros, que obteve 251.755 negociações em Bolsa, algo atípico para os FIDCs que tem a maioria das suas negociações no mercado de Balcão.  
        `,
      ],
      value: 16.41,
      valueTitle: 'Montante Negociado 2023',
      valueUnit: 'R$ (bilhões)',
    },
  },
];
export const fidcStockMarkets: YearbookMarketChartInfo[] = [
  {
    chart: fidcStockMarketCharts.plFidc,
    name: 'PL de FIDC',
    section: 'stock',
    details: {
      title: 'Patrimônio líquido dos FIDCs em 2023',
      texts: [
        `O mercado de FIDCs encerrou 2023 com mais um recorde no patrimônio líquido e em termos de quantidade, atingindo R$ 447,72 bilhões e com 2353 fundos em operação no total. 
        Em comparação com o ano de 2022, o patrimônio líquido obteve um aumento de 34,83% e um acréscimo de 474 em relação à quantidade deles.  `,
      ],
      value: 447.72,
      valueTitle: 'PL em dez/23',
      valueUnit: 'R$ (bilhões)',
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByCondominumType,
    name: 'PL de FIDC por Tipo de Condomínio',
    section: 'stock',
    details: {
      title: 'Condomínio fechado predomina o mercado de FIDCs',
      texts: [
        `
        Em 2023, o tipo de condomínio fechado continuou a predominar no mercado de FIDCs, representando 81,11% do patrimônio líquido, ou seja, cerca de R$ 363,50 bilhões em PL. 
        Já o condomínio aberto representou 18,87% do PL, totalizando em torno de R$ 84,19 bilhões. 
        No ano anterior, o tipo de condomínio fechado correspondia a 81,42% do PL e o aberto 18,58%, o que, em termos de porcentagem, não apresenta grande variação, mas, em 2022, o condomínio fechado representava R$ 269,27 bilhões do patrimônio líquido, enquanto o aberto R$ 61,47 bilhões. 
        `,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByFundType,
    name: 'PL de FIDC por Tipo de Fundo',
    section: 'stock',
    details: {
      title: 'FIDCs Padronizados representam a maior parte do patrimônio',
      texts: [
        `
        Os FIDCs Padronizados, que se enquadram na ICVM 356 em relação aos direitos creditórios, responderam por 58,85% do patrimônio líquido (PL) dos FIDCs em dezembro de 2023, enquanto os FIDCs Não-Padronizados (cujo direitos creditórios são diversos e não aderentes à ICVM 356) representaram 41,13% do PL. 
        Historicamente, os FIDCs padronizados têm maior participação no mercado de FIDCs. 
        De acordo com o gráfico ao lado, o mês de fevereiro de 2019 foi o que registrou a maior participação desses FIDCs, atingindo 49,55%. 
        `,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFIDCByProduct,
    name: 'PL de FIDC por Ativo-Lastro',
    section: 'stock',
    details: {
      title: 'PL de FIDC por Ativo-lastro',
      texts: [
        `Em 2023, os FIDCs de Recebíveis Comerciais tiveram um desempenho destacado em termos de patrimônio líquido (PL), correspondendo a 35,15% do PL total dos FIDCs. 
        Em segundo lugar, os FIDCs Crédito Pessoal somaram 13,01% do PL, seguidos pelos FIDCs de Multiclasse, que ocuparam o terceiro lugar, com 11,47% do PL. 
        Historicamente, tem sido observado que os FIDCs de Recebíveis Comerciais têm a maior participação percentual na composição do PL dos FIDCs.  `,
      ],
    },
  },
  {
    chart: fidcStockMarketCharts.plFidcMM,
    name: 'PL dos FIDC Multicedente-Multissacado',
    section: 'stock',
    details: {
      title: 'PL dos FIDC Multicedente Multissacado',
      texts: [
        `No ano de 2023, os FIDCs multicedente-multissacado (MM), que têm carteira composta direitos creditórios de diversos cedentes e sacados, apresentaram um patrimônio líquido de R$ 41,14 bilhões registrando um aumento de 4,45% em comparação com dezembro de 2022. 
        Porém o número de FIDCs diminuiu de 393 para 382, o que representa uma queda de 2,8% em relação ao final de 2022. 
        Em relação a dezembro de 2021, o patrimônio líquido dos FIDCs MM teve um aumento de 35,65% e a quantidade desses fundos passou de 379 para 382. `,
      ],
    },
  },
];

export const fidcRankings: YearbookMarketRanking[] = [
  {
    name: 'Ranking FIDCs por Montante Emitido',
    details: {
      title: 'Ranking FIDCs por Montante Emitido',
      texts: [
        `O ranking de FIDCs por Montante Emitido do mercado primário fecha 2023 com um total de 1482 classificados, atingindo a marca de R$ 155,81 bilhões. 
        Os três primeiros colocados do ranking representam uma concentração de 10,38%, sendo o líder o For-Te, que ocupava o terceiro lugar no ano anterior, tendo emitido R$ 6,89 bilhões. 
        Na segunda colocação, correspondendo a 3,12%, está o Soma III com R$ 4,86 bilhões e, logo após, na terceira posição, temos o Clientes BRF II com R$ 4,42 bilhões, correspondendo a 2,84%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=stock&criteria=amountissued&timeRange=consolidated',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Montante Emitido',
    details: {
      title: 'Ranking de Assessores Jurídicos por Montante Emitido',
      texts: [
        `O ranking mencionado apresenta 13 Assessores Jurídicos ranqueados e um montante emitido de R$ 10,73 bilhões. 
        A seguir, detalharemos os cinco primeiros colocados: Machado Meyer ficou em primeiro lugar, com R$ 5,75 bilhões, representando 53,63% do total; A Barcellos Tucunduva ficou em segundo lugar, com R$ 1,85 bilhões, representando 17,25% do total; A terceira posição ficou com Pinheiro Neto, que atingiu a cifra de R$ 765,41 milhões, ou 7,13%; na quarta posição está FreitasLeite, com R$ 747,67 milhões, ou 6,97%; E, na quinta posição, Martinelli Advocacia Empresarial com R$ 472,45 milhões, ou 4,40%. 
        Com esses dados, percebe-se que 78,01% do total de montante emitido está concentrado nos três primeiros assessores jurídicos do ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=legaladvisor&criteria=amountissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Assessores Jurídicos por Número de Operações',
    details: {
      title: 'Ranking de Assessores Jurídicos por Número de Operações',
      texts: [
        `O ranking em questão apresentou 13 Assessores Jurídicos e um total de 62 operações, destacando a liderança da Barcellos Tucunduva com 19 operações (30,65% do total) seguido pela Martinelli Advocacia Empresarial com 9 operações (14,52% do total).
        Os 4 primeiros colocados no ranking, que incluem também Freitas Leite,  Pinheiro Neto e Franco Leutewiler Henriques Advogados empatados na quarta colocação, somam 75,82% das operações realizadas.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=legaladvisor&criteria=operationsissued&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Montante Classificado',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Montante Classificado',
      texts: [
        `No ranking apresentado, o montante de R$ 21,37 bilhões em 2023 representou uma queda de 7,73% em relação ao ano anterior. 
        A lista inclui 6 instituições, sendo que a Liberum Ratings lidera com R$ 6,95 bilhões (32,53%), seguida pela Austin com R$ 6,57 bilhões (30,74%) e a S&P em terceiro lugar com R$ 3,94 bilhões (18,43%). 
        Os três primeiros ranqueados concentram 81,7% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=agencyrating&criteria=amountclassified&timeRange=yearly',
  },
  {
    name: 'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados',
    details: {
      title:
        'Ranking de Agências de Classificação de Risco por Número de Títulos Classificados',
      texts: [
        `Foram classificados um total de 545 títulos e identificadas 5 instituições no ranking de Agências de Classificação de Risco por número de Títulos Classificados. 
        A Austin lidera com 306 títulos, o que corresponde a 56,15% do total. Em segundo lugar, a Liberum Ratings com 142 títulos (26,06%) e a SR em terceiro com 41 títulos (7,52%). 
        As três primeiras instituições do ranking concentram 89,73% do total de títulos classificados.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=agencyrating&criteria=titlesnumberclassified&timeRange=yearly',
  },
  {
    name: 'Ranking FIDC por Montante de PL',
    details: {
      title: 'Ranking FIDC por Montante de PL',
      texts: [
        `O ranking de FIDCs por Montante de PL do mercado de capitais totalizou R$ 63,22 bilhões em 2023, um aumento de 56,18% em relação a 2022, quando registrou R$ 40,48 bilhões. 
        Os três primeiros colocados do ranking são responsáveis por 33,85% do total: o BTG Pactual Consignado, que tem como ativo-lastro empréstimos consignados representados por CCBs, lidera com R$ 13,77 bilhões, correspondente a 21,85%, seguida pela D Mais, caracterizado como recebíveis comerciais, com R$ 4,65 bilhões, correspondente a 7,39%, e BTG Pactual Consignado II, com lastro em crédito pessoal representados, também, por CCBs com R$ 3,36 bilhões, correspondente a 5,33%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=stock&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking FIDC por Montante de PL - Acumulado',
    details: {
      title: 'Ranking FIDC por Montante de PL - Acumulado',
      texts: [
        `Em 2023, o ranking por montante de patrimônio líquido de todos os FIDCs em atividade registra 2503 ranqueados, acréscimo de 792 registros em relação ao ano anterior, totalizando R$ 453,23 bilhões. 
        Valor esse que representa um aumento de 47,17% comparados à 2022, quando o montante era de R$ 307,96 bilhões. 
        A concentração de PL fica em 11,12% nos três primeiros colocados, onde o Tapso segue na liderança, seguido pelo BTG Pactual Consignados e, logo depois, o Cielo, que caiu da segunda para a terceira posição esse ano.`,
        '• Tapso, líder com R$ 25,62 bilhões, correspondente a 5,73% do total;',
        '• BTG Pactual Consignados, na 2ª posição, com R$ 13,77 bilhões, correspondente a 3,08%;',
        '• Cielo, na 3ª posição, com R$ 10,77 bilhões, correspondente a 2,41%.',
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=stock&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking Administradores por Montante de PL',
    details: {
      title: 'Ranking Administradores por Montante de PL',
      texts: [
        `O ranking de administradores do mercado de FIDC apresenta 33 instituições ranqueadas e um montante de R$ 70,6 bilhões. 
        Em comparação a 2022, houve um aumento de 74,36%. 
        Os três primeiros colocados detêm 49,97% do total, sendo que o BTG Pactual Serviços Financeiros DTVM lidera o ranking com R$ 22,93 bilhões, correspondentes a 32,48%. 
        A Reag DTVM ocupa a segunda posição com R$ 6,19 bilhões, correspondentes a 8,76%. 
        Já a Singulare ocupa a terceira posição com R$ 6,16 bilhões, correspondentes a 8,73%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=admin&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por Número de Operações',
    details: {
      title: 'Ranking de Administradores por Número de Operações',
      texts: [
        'Com um total de 603 operações e 33 empresas ranqueadas, as seguintes instituições foram identificadas no ranking de Administradores por Número de Operações:',
        ' • Singulare, com 127 operações, correspondendo a 21,06% do total;',
        ' • Id CTVM, com 51 operações, correspondendo a 8,46%;',
        ' • Banco Daycoval, com 50 operações, correspondendo a 8,29%.',
        'Dessa forma, conclui-se que as três primeiras instituições do ranking concentraram 37,81% do número total de operações.',
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=admin&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Administradores por PL - Acumulado',
    details: {
      title: 'Ranking de Administradores por PL - Acumulado',
      texts: [
        `O ranking de administradores por montante de PL de todos os FIDC em atividade é composto por 52 instituições e totalizou a cifra de R$ 446,51 bilhões, representando um aumento de 36,97% em relação a 2022, quando o montante girava em torno de R$ 325,99 bilhões. 
        É importante destacar que os três primeiros colocados do ranking concentram quase metade do total, ou seja, 44,81%. A liderança é ocupada pela Oliveira Trust, com R$ 85,86 bilhões, correspondente a 19,23% do total. 
        Em segundo lugar está a Singulare, com R$ 65,84 bilhões, o que equivale a 14,75% do montante. 
        Na terceira posição, aparece a BTG Pactual Serviços Financeiros DTVM, com R$ 48,32 bilhões e 10,82% do total.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=admin&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Administradores por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Administradores por Número de Operações - Acumulado',
      texts: [
        `O Ranking de Administradores de FIDCs por número de operações em atividade apresentou, no final de 2023, o total de 2.331 operações ativas e um total de 52 ranqueados. 
        O top 3 de Administradores compõe 39,81% de participação no ranking. 
        A instituição que aparece em primeiro lugar é a Singulare, com 620 operações ativas, somando assim um total de 26,62% na composição do ranking. 
        Em seguida figura a BRL Trust DTVM, somando 7,17% do total do ranking com um total de 167 operações ativas. 
        E, em terceiro lugar, BTG Pactual Serviços Financeiros DTVM, com 141 operações ativas, equivalendo assim a 6,05% do total do ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=admin&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Auditores por Montante de PL - Acumulado',
    details: {
      title: 'Ranking de Auditores por Montante de PL - Acumulado',
      texts: [
        `O Ranking de Auditores de FIDCs por montante de patrimônio líquido (PL) dos fundos ativos em dezembro de 2023 registra R$ 428,83 bilhões e um total de 35 empresas de auditoria ranqueadas. 
        O top 3 de Auditores ficou concentrado em 59,86% do total do ranking. Next Auditores, no primeiro lugar, com R$ 95,93 bilhões de patrimônio líquido por operações ativas, totalizando 23,87% de participação no ranking. 
        Em seguida, KPMG, somando 23,43% de participação no ranking, com PL de R$ 94,16 bilhões por operações ativas. E, em terceiro lugar, Ernst & Young, com R$ 69,98 bilhões de PL por operações ativas, somando um total de 17,41% do ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=auditor&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Auditores por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Auditores por Número de Operações - Acumulado',
      texts: [
        `O Ranking de Auditores de FIDCs por número de operações em atividade resultou, no ano de 2023, no total de 2093 operações ativas e 35 empresas de auditoria ranqueadas. 
        As três empresas que mais se destacaram em quantidade de operações somaram juntas o equivalente a 63,40% de participação no ranking. 
        No primeiro lugar, Next Auditores, com um total de 901 operações, valor este que equivale a 44,78% do ranking. Em seguida, KPMG, com 251 operações, totalizando 12,48% de participação no ranking. 
        E, em terceiro lugar, BDO RCS Auditores Independentes, com 182 operações ativas, somando 9,05% de participação.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=auditor&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Custodiantes por Montante de PL em 2023',
    details: {
      title: 'Ranking de Custodiantes por Montante de PL em 2023',
      texts: [
        `O Ranking de Custodiantes de FIDCs por montante de patrimônio líquido (PL) computou, no ano de 2023, a cifra de R$ 63,24 bilhões de PL dos fundos que entraram em operação, distribuídos em um total de 32 empresas ranqueadas. 
        Comparado com 2022, o PL subiu em 53,53%.  O top 3 de Custodiantes concentrou-se em 55,08% do total do ranking, sendo o Banco BTG Pactual a primeira colocada com montante de PL em R$ 22,70 bilhões. 
        Em 2022 a BRL Trust DTVM ficou em primeiro lugar com R$ 8,67 bilhões de PL.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=custodian&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Custodiantes por Número de Operações em 2023',
    details: {
      title: 'Ranking de Custodiantes por Número de Operações em 2023',
      texts: [
        `O Ranking de Custodiantes de FIDCs por número de Operações em atividade em 2023 registrou o total de 608 operações ativas, 71,26% maior que em 2022. 
        As operações estão distribuídas em um total de 35 empresas ranqueadas. 
        As três empresas que compuseram o top 3 do ranking somaram juntas 36,89% de participação e são: Singulare (com 125 operações), Id CTVM (com 45 operações) e Banco BTG Pactual (com 44 operações)`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=custodian&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Custodiantes por Montante de PL - Acumulado',
    details: {
      title: 'Ranking de Custodiantes por Montante de PL - Acumulado',
      texts: [
        `O Ranking de Custodiantes de FIDCs por montante de PL dos fundos operacionais apresentou o total de R$ 445,53 bilhões, 37,57% a mais que em 2022. 
        As operações estão distribuídas em um total de 58 empresas ranqueadas. 
        As 3 primeiras colocadas somaram juntas 43,52% no nível de concentração do ranking. 
        São elas: Oliveira Trust (com R$ 86,64 bilhões de PL), Singulare (com R$ 65,98 bilhões de PL) e Banco BTG Pactual (com R$ 41,29 bilhões de PL).`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=custodian&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Custodiantes por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Custodiantes por Número de Operações - Acumulado',
      texts: [
        `O Ranking de Custodiantes de FIDCs por número de operações em atividade registrou, em dezembro de 2023, o total de 2342 operações ativas com um total de 58 ranqueados. 
        As três empresas que compuseram o top 3 do ranking somaram juntas 39,47% de participação e são: Singulare (com 627 operações e 26,79% de participação), BRL Trust DTVM (com 167 operações e 7,14% de participação) e Reag DTVM (com 130 operações e 5,56% de participação). 
        Comparado com 2022, o número de operações teve um aumento de 30,69%.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=custodian&criteria=operations&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Montante de PL em 2023',
    details: {
      title: 'Ranking de Gestores por Montante de PL em 2023',
      texts: [
        `O Ranking de Gestores de FIDCs por montante de patrimônio líquido (PL) dos fundos que entraram em operação em 2023 apresenta a cifra de R$ 70,95 bilhões, 75,2% maior que em 2022, e um total de 183 gestores ranqueados. 
        O top 3 de Gestores ficou concentrado em 38,42% do total do ranking. No primeiro lugar, O Banco BTG Pactual, com R$ 17,12 bilhões de patrimônio líquido por operações ativas, somando 24,28% de participação no ranking. 
        A seguir aparece a Reditus Investimentos LTDA, totalizando 8,36% de participação no ranking, com PL de R$ 5,89 bilhões por operações ativas. 
        E, em terceiro lugar, Oliveira Trust Servicer, com R$ 4,36 bilhões de PL por operações ativas, somando um total de 6,19% do ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=manager&criteria=amountnetworth&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Número de Operações em 2023',
    details: {
      title: 'Ranking de Gestores por Número de Operações em 2023',
      texts: [
        `O Ranking de Gestores de FIDCs por número de operações que iniciaram em 2023 perfez o total de 494 operações ativas e 165 gestores ranqueados. 
        O top 3 gestores por quantidade de operações apresentou o equivalente a 13,38% de participação no ranking. 
        No primeiro lugar, Tercon Investimentos, com um total de 25 operações, valor este que equivale a 5,12% do ranking. 
        Em seguida, Ouro Preto Gestão de Recursos, com 22 operações, totalizando 4,51% de participação no ranking. 
        E, em terceiro lugar, ID Gestora, com 19 operações ativas, somando 3,89% de participação.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=manager&criteria=operations&timeRange=yearly',
  },
  {
    name: 'Ranking de Gestores por Montante de PL - Acumulado',
    details: {
      title: 'Ranking de Gestores por Montante de PL - Acumulado',
      texts: [
        `O Ranking de Gestores de FIDCs por montante de patrimônio líquido (PL) dos fundos ativos registrou, em dezembro de 2023, R$ 447,67 bilhões e um total de 335 gestores ranqueados. 
        Comparado com dezembro de 2022, observa-se um aumento de 36,61% no PL e de 25% no número de gestores ranqueados. 
        O top 3 de Gestores ficou concentrado em 20,11% do total do ranking tendo a Oliveira Trust Servicer no primeiro lugar, com R$ 33,96 bilhões de PL por operações ativas, totalizando 7,61% de participação no ranking. 
        Em seguida, Oliveira Trust, somando 7,15% de participação no ranking, com PL de R$ 31,92 bilhões por operações ativas. 
        E, em terceiro lugar, Banco BTG Pactual, com R$ 24,08 bilhões de PL por operações ativas, totalizando 5,40% de participação no ranking.`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=manager&criteria=amountnetworth&timeRange=consolidated',
  },
  {
    name: 'Ranking de Gestores por Número de Operações - Acumulado',
    details: {
      title: 'Ranking de Gestores por Número de Operações - Acumulado',
      texts: [
        `O Ranking de Gestores de FIDCs por número de operações em atividade computou, em dezembro de 2023, o total de 2366 operações ativas com um total de 335 ranqueados. 
        Os três gestores que compuseram o top 3 do ranking somaram juntos 15,21% de participação e são: Tercon Investimentos (com 176 operações e 7,49% de participação), Ouro Preto Gestão de Recursos (com 100 operações e 4,26% de participação) e REAG Administradora de Recursos (com 84 operações e 3,57% de participação).`,
      ],
    },
    endpointUrl:
      'www.uqbar.com.br/rankings/dados?market=FIDC&year=2023&ranking=manager&criteria=operations&timeRange=consolidated',
  },
];
