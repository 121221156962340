import { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Hidden,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { findOperationsByName } from 'src/services/operation';
import { FeaturedOperation, Institution } from 'src/types/institution';
import * as Yup from 'yup';

import * as S from '../../style';

const FORM_VALIDATION = Yup.object().shape({
  operation: Yup.object({
    id: Yup.string().required('Campo obrigatório'),
  }),
  description: Yup.string().required('Campo obrigatório').nullable(),
});

export default function FeaturedOperationForm({
  open,
  onClose,
  handleUpdate,
  isLoading,
  selectedOperation,
  institution,
  selectedIndex,
  setSelectedOperation,
  setSelectedIndex,
}: {
  open: boolean;
  onClose: () => void;
  handleUpdate: (values: any) => void;
  isLoading: boolean;
  selectedOperation?: FeaturedOperation;
  institution?: Institution;
  selectedIndex?: number;
  setSelectedOperation?: (values: any) => void;
  setSelectedIndex?: (values: any) => void;
}) {
  const [operationName, setOperationName] = useState<string>('');
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const {
    isLoading: operationsLoading,
    refetch: operationsRefetch,
    data: operationsOptions,
  } = useQuery('institutions', () => findOperationsByName(operationName), {
    enabled: !!operationName,
    onSuccess: ({ data }) => {
      if (
        operationName.length > 3 &&
        data?.length === 0 &&
        !formik.values.operation?.id
      ) {
        formik.setFieldError(
          'operation.id',
          'Esta operação não foi localizada na plataforma Uqbar, para incluir envie um e-mail para: conteudo@uqbar.com.br',
        );
      } else {
        formik.setFieldError('operation.id', undefined);
      }
    },
  });

  const operationsRefetchDebounce = useCallback(
    debounce(operationsRefetch, 500),
    [],
  );

  const filterOptionOperations = createFilterOptions<any>();

  const formik = useFormik({
    initialValues: {
      id: selectedOperation?.id,
      operation: selectedOperation?.operation ?? {
        id: undefined,
        name: '',
        slug: '',
        hashId: '',
        market: undefined,
      },
      description: selectedOperation?.description ?? '',
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (institution?.id) {
        if (values?.id && selectedIndex) {
          const updatedItems = [...institution.featuredOperations];
          updatedItems[selectedIndex] = {
            id: values.id,
            operation: values.operation,
            description: values.description,
          };
          handleUpdate(updatedItems);
          onClose();
          if (setSelectedOperation && setSelectedIndex) {
            setSelectedOperation(undefined);
            setSelectedIndex(undefined);
          }
        } else {
          handleUpdate([...institution.featuredOperations, values]);
        }
        formik.resetForm();
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        formik.handleReset(e);
        onClose();
      }}
      sx={{
        margin: '0 auto',
      }}
      PaperProps={{
        sx: {
          maxWidth: '1000px',
          width: '700px',
          height: 'fit-content',
          borderRadius: '16px',
          padding: isMobile ? '8px 20px 10px 20px' : '16px',
        },
      }}>
      <DialogTitle>
        <Typography textAlign="center" fontSize={18} fontWeight={700}>
          Operações em destaque
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            padding: isMobile ? '16px 0' : '16px',
          }}>
          <Grid
            container
            spacing={3}
            sx={(theme) => ({
              padding: theme.spacing(2),
              flexDirection: 'column',
            })}>
            <Grid item xs={12} sm={12} md={12}>
              <S.FieldTitle>Nome da operação*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  autoHighlight
                  freeSolo
                  filterOptions={filterOptionOperations}
                  loading={operationsLoading}
                  options={operationsOptions?.data ?? []}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, newValue, reason) => {
                    if (!newValue?.id || reason === 'clear') {
                      formik.setFieldValue('operation', {
                        name: '',
                        hashId: '',
                        slug: '',
                      });
                    } else {
                      formik.setFieldValue('operation', newValue);
                    }
                  }}
                  value={formik.values.operation}
                  inputValue={operationName}
                  onInputChange={(event, newInputValue) => {
                    setOperationName(newInputValue);
                    operationsRefetchDebounce();
                  }}
                  getOptionLabel={(option: any) => {
                    if (option.name !== '') {
                      return `${option.name}${option?.market?.name ? ` [${option.market?.name}]` : ''}`;
                    } else {
                      return '';
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="operation"
                      placeholder="Selecione o nome da operação que quer destacar, ex: BR Properti FII"
                      error={Boolean(formik.errors.operation?.id)}
                      helperText={formik.errors.operation?.id}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <TextAreaProfile
              name="description"
              label="Descrição*"
              value={formik.values.description}
              handleChange={formik.handleChange}
              placeholder="Descreva detalhes da operação"
              variant="outlined"
              maxCaracters={120}
              errors={formik.errors.description}
              touched={formik.touched.description}
            />
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', padding: '0 16px 16px', gap: 2 }}>
          <Hidden smDown>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar'
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                formik.handleReset(e);
                onClose();
              }}
              disabled={isLoading}>
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Cancelar'
              )}
            </Button>
          </Hidden>
          <Hidden smUp>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => {
                    formik.handleReset(e);
                    onClose();
                  }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Cancelar'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </DialogActions>
      </form>
    </Dialog>
  );
}
