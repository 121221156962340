import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import emailImage from '../../../../assets/imgs/emailNotebook.png';

type Props = {
  open: boolean;
  onClose: () => void;
  handleAction: () => void;
};

function EmailDialog({ open, onClose, handleAction }: Props) {
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 20px 0',
          overflow: 'hidden',
          minHeight: '380px',
        }}>
        <img src={emailImage} width={185} height={185} />
        <Typography variant="h4" textAlign="center">
          Enviamos um link de verificação para o seu e-mail.
        </Typography>
        <Typography
          maxWidth={370}
          textAlign="center"
          sx={{ fontSize: '0.875rem' }}>
          Consulte sua caixa de entrada e acesse o link para finalizar o
          cadastro
        </Typography>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>
          Não recebeu o e-mail?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '20px 0 40px' }}>
        <Button
          size="large"
          variant="contained"
          onClick={handleAction}
          sx={{ fontSize: '0.875rem' }}>
          Reenviar link
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmailDialog;
