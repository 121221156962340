import React from 'react';

import { ApolloProvider } from '@apollo/client';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as ThemeProviderv5 } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import store from 'src/store';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import AdvertisementPopUp from './components/AdvertisementPopUp';
import EventPresentationOngoingPopUp from './components/EventPresentationOngoingPopUp';
import AuthContextProvider from './context/AuthContextProvider';
import EmbedContextProvider from './context/EmbedContextProvider';
import contentManager from './graphql/clients/contentManager';
import WidgetRankings from './pages/Widget/Rankings';
import Routes, { YearbookRoutes } from './routes';
import theme from './theme';
import themev5 from './themev5';

import 'chart.js/auto';
import 'moment/locale/pt-br';

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.NODE_ENV !== 'development' &&
  !process.env.FAST_REFRESH
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
    ],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const authChannel = new BroadcastChannel('auth');
const elector = createLeaderElection(authChannel);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProviderv5 theme={themev5}>
        <ThemeProvider theme={theme}>
          <StyledComponentsThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ApolloProvider client={contentManager}>
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <CssBaseline />
                    <React.StrictMode>
                      <Switch>
                        <Route path="/embed/:widgetKey/:resource">
                          <EmbedContextProvider>
                            <Route path="/embed/:widgetKey/rankings">
                              <WidgetRankings />
                            </Route>
                          </EmbedContextProvider>
                        </Route>
                        <AuthContextProvider
                          authChannel={authChannel}
                          elector={elector}>
                          <AdvertisementPopUp />
                          <EventPresentationOngoingPopUp />
                          <Route
                            exact
                            path="/anuarios2023/:market?/:section?"
                            render={({ match }) => {
                              const marketParam =
                                '/' + (match.params.market ?? '');
                              const sectionParam = match.params.section
                                ? `/${match.params.section}`
                                : '';
                              return (
                                <Redirect
                                  to={`/anuarios/2023${marketParam}${sectionParam}`}
                                />
                              );
                            }}
                          />
                          <Route
                            exact
                            path="/anuarios/:year/:market?/:section?"
                            render={({ match }) => (
                              <YearbookRoutes year={match.params.year} />
                            )}
                          />
                          <Routes />
                        </AuthContextProvider>
                      </Switch>
                    </React.StrictMode>
                  </BrowserRouter>
                </QueryClientProvider>
              </ApolloProvider>
            </MuiPickersUtilsProvider>
          </StyledComponentsThemeProvider>
        </ThemeProvider>
      </ThemeProviderv5>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);
