import CampaignIcon from '@mui/icons-material/Campaign';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import * as S from './styles';

const BannerInfoDemo = ({ date }: { date: string }) => {
  return (
    <S.Banner>
      <S.MessageWrapper>
        <S.Message>
          <CampaignIcon />
          <span>{`Seu período de demonstração está ativo até ${format(new Date(date), 'dd/MM/yyyy')}`}</span>
        </S.Message>
        <Button
          component={Link}
          to="/planos"
          variant="contained"
          sx={{ width: 'clamp(90px, 33.5vw, 150px)', minWidth: 'unset' }}>
          Contratar plano
        </Button>
      </S.MessageWrapper>
    </S.Banner>
  );
};

export default BannerInfoDemo;
