import { useCallback } from 'react';

import { ApexOptions } from 'apexcharts';
import { format, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactApexChart from 'react-apexcharts';
import { Dashboard } from 'src/types/radar';

export default function ComboChart({ dashboard }: { dashboard: Dashboard }) {
  const dateName: string =
    dashboard.settings.visualization_settings['graph.dimensions'][0];
  const keyNames: string[] =
    dashboard.settings.visualization_settings['graph.metrics'];
  const dateUnit = dashboard.settings.result_metadata?.find(
    (meta: any) => meta.name === dateName,
  ).unit;
  const uniqueDates = [
    ...new Set(
      dashboard.content.map((item: any) => {
        if (dateUnit === 'year') {
          return item[dateName].length === '4'
            ? parse(item[dateName], 'yyyy', new Date())
            : parseISO(item[dateName]);
        }
        return item[dateName].includes('/')
          ? parse(item[dateName], 'd/M/yyyy', new Date())
          : parseISO(item[dateName]);
      }),
    ),
  ] as Date[];

  const { scale = 1e-9 } = dashboard.settings.visualization_settings
    .column_settings?.length
    ? Object.values(
        dashboard.settings.visualization_settings.column_settings as any[],
      )[0]
    : {};

  const formatValue = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(value);

  const formatDate = (date: Date) =>
    dateUnit === 'year'
      ? `${date.getFullYear()}`
      : format(date, 'MMMM yyyy', {
          locale: ptBR,
        });

  const processData = useCallback(
    (content: Dashboard['content']) => {
      const series = [];
      for (const keyName of keyNames) {
        const contentKey = dashboard.settings.result_metadata?.find(
          (meta: any) => meta.name === keyName,
        ).display_name;
        const data = content?.map((row: any) => {
          if (isNaN(Number(row[contentKey]))) {
            return (
              parseFloat(
                row[contentKey].replace(/[^\d,]+/g, '').replace(',', '.'),
              ) / scale
            );
          } else if (typeof row[contentKey] === 'string') {
            return parseFloat(
              row[contentKey].replace(/[^\d,]+/g, '').replace(',', '.'),
            );
          }
          return row[contentKey];
        });

        const type =
          dashboard.settings.visualization_settings.series_settings[keyName]
            ?.display;
        const color = type === 'bar' ? '#88BF4D' : '#509EE3';

        const serie = {
          name:
            dashboard.settings.visualization_settings.series_settings[keyName]
              ?.title ?? contentKey,
          color,
          type,
          data,
        };

        series.push(serie);
      }

      return series;
    },
    [dashboard],
  );

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: dashboard.name,
      type: 'line',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: uniqueDates?.map(formatDate),
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return formatValue(value);
          },
        },
      },
      {
        show: false,
      },
    ],
    legend: {
      position: 'top',
    },
    labels: uniqueDates?.map(formatDate),
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: (value, opts) => {
        if (Number(value) > 100000000) {
          return formatValue(Number(value));
        }
        return parseFloat(`${value}`).toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        });
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return formatValue(Number(y));
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toLocaleString('pt-BR', {
                maximumFractionDigits: 2,
              });
            }
            return y;
          },
        },
      ],
    },
  };

  const series = processData(dashboard.content);

  return (
    <ReactApexChart
      options={options}
      series={series}
      width="100%"
      height="100%"
    />
  );
}
