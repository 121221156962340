import { Grid, InputAdornment, ListItem, TextField } from '@material-ui/core';
// Material ui icons
import SearchIcon from '@material-ui/icons/Search';

export function InputSearch({
  classes,
  label,
  value,
  handleChange,
  disabled,
  md = 6,
}) {
  return (
    <Grid item xs={12} md={md}>
      <ListItem className={classes.listItem} style={{ marginTop: '1rem' }}>
        <TextField
          className={classes.itemInput}
          placeholder={label}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </ListItem>
    </Grid>
  );
}
