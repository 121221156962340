import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Button,
  Divider,
  FormControl,
  ListItemButton,
  ListItemText,
  Menu,
} from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from '@mui/x-date-pickers/locales';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

export default function ConvocationDate({
  INITIAL_STATE,
  state,
  setState,
  data,
  disabled,
}: {
  INITIAL_STATE: any;
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  data?: any;
  disabled: boolean;
}) {
  const [value, setValue] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = (newDate?: any) => {
    setAnchorEl(null);
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (newDate: string | null) => {
    if (!newDate) return;
    setValue(newDate);
  };
  const handleReset = () => {
    setValue(undefined);
    handleClose();
  };
  useEffect(() => {
    if (!open) {
      const newDateTime = new Date(value ?? '').getTime();
      const dateTime = new Date(state.date).getTime();
      if (newDateTime !== dateTime)
        setState((prev: any) => ({ ...prev, date: value }));
    }
  }, [value, open]);

  return (
    <>
      <Button
        id="lock-button"
        aria-controls={open ? 'lock-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        endIcon={
          <ExpandMoreRoundedIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
          />
        }
        onClick={handleClickMenu}
        disabled={disabled}
        sx={(theme) => ({
          color:
            state.date !== INITIAL_STATE.date
              ? theme.palette.primary.main
              : '#4F4F4F',
        })}>
        Período:{' '}
        {state.date === INITIAL_STATE.date
          ? 'Futuros'
          : format(new Date(state.date), 'MM/yy')}
      </Button>
      {data && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxHeight: '40dvh',
            },
          }}
          sx={{
            '& .MuiList-root': {
              py: 0,
            },
          }}
          aria-disabled={disabled}>
          <FormControl fullWidth variant="outlined">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBr}
              localeText={
                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              }>
              <DateCalendar
                views={['month', 'year']}
                openTo="month"
                value={value}
                onChange={handleChange}
                onYearChange={handleClose}
                sx={{
                  '&.MuiDateCalendar-root': {
                    height: 'fit-content',
                    paddingBottom: 2,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          {state.date !== INITIAL_STATE.date && (
            <div>
              <Divider aria-hidden="true" />
              <ListItemButton
                onClick={handleReset}
                sx={(theme) => ({
                  fontSize: 14,
                  fontWeight: 700,
                  textAlign: 'center',
                  color: theme.palette.secondary.main,
                  '&:hover': {
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                  },
                })}>
                <ListItemText disableTypography>
                  Ver períodos futuros
                </ListItemText>
              </ListItemButton>
            </div>
          )}
        </Menu>
      )}
    </>
  );
}
