// Material ui components
import { useEffect, useRef, useState } from 'react';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

import ArticleAddons from './ArticleAddons';
import ArticleAuthorDetails from './ArticleAuthorDetails';
import ArticleDetails from './ArticleDetails';
import ArticleMoreNews from './ArticleMoreNews';
import ArticleRelatedSubjects from './ArticleRelatedSubjects';
import ArticleSocialMedias from './ArticleSocialMedias';
import Comments from './Comments';
import CommentForm from './CommentsForm';
import { articleStyles } from './styles';
import breakDefault from '../../assets/imgs/articles/detail.png';
// Global components
import BannerAdvertisement from '../../components/BannerAdvertisement';
import { If } from '../../components/If';
import ImageTitleTop from '../../components/ImageTitleTop';
import MetaTags from '../../components/Seo/MetaTags';
import Subheader from '../../components/Subheader';
import { useAuthContext } from '../../context/AuthContextProvider';
import {
  createComment,
  deleteComment,
  getArticle,
  getComments,
  updateComment,
} from '../../services/article';
import { getProfessionalPhotoByOwner } from '../../services/professional';
import { generateHash } from '../../utils/crypto';
import { useSessionStorage } from '../../utils/hooks';
import { getLargestImageUrl } from '../../utils/image';
import { normalizeContent } from '../../utils/normalize-content';
import { isBot } from '../../utils/regex';
import { ScrollToTop } from '../../utils/scroll';
// Local components

export default function Article() {
  const [articlesIds, setArticlesIds] = useSessionStorage(
    '@App:canReadArticles',
    [],
  );
  const classes = articleStyles();
  const commentRef = useRef();
  const { auth } = useAuthContext();
  const { hashId } = useParams();
  const [encodeHashId, setEncondeHashId] = useState(null);
  const [enabledRequest, setEnabledRequest] = useState(false);
  const [avatarUser, setAvatarUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentError, setCommentError] = useState({
    status: false,
    message: '',
  });
  const hasPermission =
    auth.isLoggedIn || articlesIds?.some((a) => a === encodeHashId);
  const maxArticles = isBot(navigator.userAgent) ? Infinity : 1;

  const { data, isLoading: loading } = useQuery(
    ['article', hashId],
    () => getArticle(hashId, hasPermission),
    {
      enabled: enabledRequest,
    },
  );

  const { isLoading: loadingComments, refetch } = useQuery(
    ['comments', hashId],
    () => getComments(hashId),
    {
      onSuccess: (data) => {
        setComments(data.filter((comment) => comment.blocked === false));
      },
    },
  );

  const { isLoading: loadingCurrentUser } = useQuery(
    ['professionalPhoto', auth?.user?.id],
    () => getProfessionalPhotoByOwner(auth?.user?.id),
    {
      enabled: !!auth.user?.id,
      onSuccess: (data) => {
        setAvatarUser(data[0]?.photo?.formats?.thumbnail?.url);
      },
    },
  );

  const addComments = useMutation((data) => createComment(data), {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      setCommentError({
        status: true,
        message: 'Não foi possível publicar o comentário',
      });
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  });

  const editComments = useMutation((data) => updateComment(data), {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      setCommentError({
        status: true,
        message: 'Não foi possível editar o comentário',
      });
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  });

  const removeComments = useMutation((data) => deleteComment(data), {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      setCommentError({
        status: true,
        message: 'Não foi possível deletar o comentário',
      });
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
    },
  });

  const headerImage = getLargestImageUrl(data?.headerImage);

  useEffect(() => {
    generateHash(hashId).then((result) => setEncondeHashId(result));
  }, [hashId]);

  useEffect(() => {
    if (!encodeHashId) return;

    if (
      articlesIds.length < maxArticles &&
      !articlesIds.includes(encodeHashId) &&
      !auth.isLoggedIn
    ) {
      setArticlesIds((prev) => [...prev, encodeHashId]);
    }

    setEnabledRequest((prevState) => !prevState);
  }, [encodeHashId]);

  const handleAddComment = (values) => {
    addComments.mutateAsync({
      articleId: hashId,
      comment: {
        ...values,
      },
    });
  };

  const handleReplyComment = (values) => {
    addComments.mutateAsync({
      articleId: hashId,
      comment: {
        ...values,
      },
    });
  };

  const handleEditComment = (values) => {
    editComments.mutateAsync({
      articleId: hashId,
      comment: {
        ...values,
      },
    });
  };

  const handleRemoveComment = (id, authorId) => {
    removeComments.mutateAsync({
      articleId: hashId,
      id,
      authorId,
    });
  };

  if (loading) return <CircularProgress color="secondary" />;
  if (!data) return <>Artigo não encontrado</>;

  return (
    <ScrollToTop>
      <Box component="section">
        <MetaTags
          title={data.title}
          description={data.introtext}
          imageUrl={headerImage}
          type="article"
          seo={data.seo}
          article={{
            publishedTime: data.publishedAt,
            modifiedTime: data.updatedAt,
            author: data.author?.fullName,
            section: data.market,
          }}
        />
        <Subheader
          title="Artigo"
          favoriteType="article"
          breadcrumbs1={
            <Link
              component={RouterLink}
              to="/artigos"
              underline="none"
              className={classes.link}>
              Artigos
            </Link>
          }
          breadcrumbs2={data.title}
        />
        <Paper square className={classes.container}>
          <Grid container spacing={2}>
            <Grid item container md={8}>
              <Grid item xs={12}>
                <ImageTitleTop
                  image={headerImage ?? breakDefault}
                  title={data.title}
                  type={data.marketName}
                />
                <ArticleDetails
                  author={data.author}
                  categories={data.categories}
                  markets={data.markets}
                  publishedAt={data.publishedAt}
                  updatedAt={data.updatedAt}
                  estimatedReadingTime={data.estimatedReadingTime}
                />
                <Divider className={classes.dividerSubtitle} />
                <If condition={hasPermission}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: normalizeContent(data.introtext ?? '', classes),
                    }}
                    component="h2"
                  />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: normalizeContent(data.content ?? '', classes),
                    }}
                  />
                  <If condition={data.addons}>
                    <ArticleAddons addons={data.addons} />
                  </If>
                  <If condition={data.author}>
                    <ArticleAuthorDetails author={data.author} />
                  </If>
                </If>
                <If condition={!hasPermission}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: normalizeContent(data.introtext ?? '', classes),
                    }}
                    component="h2"
                  />
                  <Box className={classes.payWall}>
                    <Box className={classes.blur}>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aliquam a tempor tortor. Cras a fermentum dui, eu
                        feugiat lorem. Vestibulum sed justo id nunc suscipit
                        finibus sit amet vitae eros. Duis dignissim vulputate
                        dolor, ut faucibus eros dignissim et. Integer sit amet
                        nulla et turpis fermentum mattis. Nunc vitae urna mi.
                        Aenean vehicula in metus id congue. Praesent non massa
                        id mauris tincidunt condimentum. Sed mollis diam vitae
                        luctus efficitur. Aliquam eleifend arcu quis consectetur
                        gravida. Aenean vestibulum ipsum porttitor blandit
                        efficitur. Sed vitae ante quis lacus malesuada luctus
                        quis et ipsum. Etiam accumsan, est id tincidunt
                        scelerisque, nunc arcu faucibus ante, id pretium lorem
                        lectus vel odio. Quisque commodo purus ut magna
                        vulputate, at feugiat lacus laoreet.
                      </Typography>
                      <Typography>
                        Nullam sapien nisl, scelerisque vel lorem eu, bibendum
                        dictum arcu. Aenean interdum volutpat nulla, et euismod
                        orci consequat et. Suspendisse sit amet finibus odio.
                        Praesent aliquam facilisis massa, nec ullamcorper eros.
                        Aliquam nec ultricies urna. Integer sed lobortis massa,
                        non pharetra orci. Curabitur convallis arcu ipsum, eu
                        pharetra sem commodo at. Quisque quis elementum ipsum.
                        Sed dapibus rhoncus quam non molestie. Maecenas vehicula
                        ut enim id luctus. Donec tincidunt vulputate velit, quis
                        euismod orci tincidunt id. Praesent tellus enim,
                        suscipit non lorem quis, egestas mollis libero. Morbi
                        rhoncus, turpis et tempor molestie, justo sapien
                        pellentesque mauris, eget elementum magna mi at arcu.
                        Sed tellus diam, eleifend non nunc non, maximus porta
                        sapien. Nullam blandit est dolor, ac suscipit felis
                        consectetur nec. Aenean suscipit velit id nibh lacinia
                        iaculis.
                      </Typography>
                    </Box>
                    <Box className={classes.payWallMessageBox}>
                      <Typography className={classes.payWallText}>
                        Quer continuar lendo o artigo?{' '}
                        <span style={{ color: '#000' }}>
                          Faça seu{' '}
                          <Link
                            style={{ textDecoration: 'underline' }}
                            component={RouterLink}
                            to="/planos">
                            cadastro
                          </Link>{' '}
                          na Uqbar
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </If>
              </Grid>
            </Grid>
            <Grid item xs={12} md>
              <Grid item xs={12}>
                <BannerAdvertisement location="Article_Area1" />
              </Grid>
              <Grid item xs={12}>
                <Box py={2}>
                  <Typography>
                    <b>&mdash;Compartilhe o artigo</b>
                  </Typography>
                  <Divider className={classes.divider} />
                  <Box p={1} display="flex" alignItems="center" width="100%">
                    <ArticleSocialMedias
                      title={data?.title}
                      slug={data?.slug}
                      hashId={data?.id}
                    />
                  </Box>
                </Box>
              </Grid>
              <If
                condition={
                  data?.relatedInstitutions?.length ||
                  data?.relatedProfessionals?.length ||
                  data?.relatedOperations?.length
                }>
                <Grid item xs={12}>
                  <Box py={2}>
                    <Typography>
                      <b>&mdash;Neste Artigo</b>
                    </Typography>
                    <Divider className={classes.divider} />
                    <ArticleRelatedSubjects
                      relatedInstitutions={data?.relatedInstitutions}
                      relatedProfessionals={data?.relatedProfessionals}
                      relatedOperations={data?.relatedOperations}
                    />
                  </Box>
                </Grid>
              </If>
              <Grid item xs={12}>
                <Box py={2}>
                  <Typography>
                    <b>&mdash;Mais Notícias do Mercado</b>
                  </Typography>
                  <Divider className={classes.divider} />
                  {data?.markets?.length > 0 && (
                    <ArticleMoreNews
                      articleId={hashId}
                      market={data?.markets[0].name}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} innerRef={commentRef}>
              <Typography>
                <b>&mdash; O que você achou do artigo?</b>
              </Typography>
              <Divider className={classes.divider} />
              <Box style={{ padding: '1rem 0' }}>
                <CommentForm
                  classes={classes}
                  handleSubmit={handleAddComment}
                  loading={addComments.isLoading || loadingCurrentUser}
                  submitLabel="Comentar"
                  currentUserAvatar={avatarUser}
                  isComment={true}
                  placeholder="Digite seu comentário"
                  commentError={commentError}
                  setCommentError={setCommentError}
                />
              </Box>
              <Typography>
                <b>&mdash;Comentários</b>
              </Typography>
              <Divider className={classes.divider} />
              <If condition={loadingComments}>
                <CircularProgress color="secondary" />
              </If>
              <If condition={!loadingComments}>
                {comments.length ? (
                  comments.map((comment) => (
                    <Comments
                      key={comment?.id}
                      threadId={comment.id}
                      classes={classes}
                      comment={comment}
                      handleReplyComment={handleReplyComment}
                      handleEditComment={handleEditComment}
                      handleRemoveComment={handleRemoveComment}
                      loading={addComments.isLoading || editComments.isLoading}
                      commentError={commentError}
                      setCommentError={setCommentError}
                      hasPermission={hasPermission}
                    />
                  ))
                ) : (
                  <Box p={2}>
                    <Typography>
                      Seja o primeiro a comentar neste artigo
                    </Typography>
                  </Box>
                )}
              </If>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
