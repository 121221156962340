import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Avatar from 'react-avatar';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import Header from './Header';
import { findRankingToHome } from '../../../services/ranking';
import { convertValue } from '../../../utils/number';
import { truncateText } from '../../../utils/string';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    minHeight: '400px',
    maxHeight: '400px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableContainer: {
    padding: theme.spacing(0, 2),
    minHeight: '400px',
    maxHeight: '400px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  avatar: {
    '& > img': {
      border: '1px solid #C2C2C2',
      objectFit: 'contain',
    },
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

const numberCriterias = [
  'operations',
  'negotiatedefundnumber',
  'tradesnumber',
  'titlesnumber',
  'operationsissued',
  'trades',
  'negotiatedefundnumber',
  'titlesnumberclassified',
  'fundnumber',
];

const percentageCriterias = [
  'pricevariance',
  'dividendyield',
  'effectivereturn',
  'marketcapitalization',
];

export default function Rankings() {
  const classes = useStyles();
  const {
    data: ranking,
    isLoading: loading,
    error,
  } = useQuery('rankingHome', findRankingToHome);

  if (error) return null;
  if (!ranking) return null;

  return (
    <Paper elevation={0} className={classes.paper}>
      <Header ranking={ranking?.data} />
      <Box pt={3}>
        {loading && <Skeleton width={'100%'} height={370} variant="rect" />}
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="ranking table">
            <TableBody>
              {ranking?.data?.ranks?.map((row, index) => (
                <TableRow
                  key={`row-${index}`}
                  className={index === 2 ? classes.tableRow : ''}>
                  <TableCell className={classes.textBold}>
                    {row.position}
                  </TableCell>
                  <TableCell align="center" style={{ padding: '16px 16px' }}>
                    <Avatar
                      round
                      size="60"
                      maxInitials={2}
                      name={row.name}
                      src={row.logo}
                      className={classes.avatar}
                    />
                  </TableCell>
                  <TableCell style={{ width: 150 }}>
                    {row.operationId ? (
                      <RouterLink
                        to={{
                          pathname: `/operacao/${ranking?.data.market.toLowerCase()}/${
                            row.slug
                          }/${row.id}`,
                          state: { id: row.id },
                        }}
                        className={classes.link}
                        title={row.name}>
                        {truncateText(row.name, 12)}
                      </RouterLink>
                    ) : row.institutionId ? (
                      <RouterLink
                        underline="none"
                        to={{
                          pathname: `/instituicao/${row.slug}/${row.institutionId}`,
                          state: { id: row.id },
                        }}
                        className={classes.link}
                        title={row.name}>
                        {truncateText(row.name, 12)}
                      </RouterLink>
                    ) : (
                      <p title={row.name}>{truncateText(row.name, 12)}</p>
                    )}
                  </TableCell>
                  <TableCell>
                    {percentageCriterias?.includes(ranking?.data.criteria)
                      ? '-'
                      : `${convertValue(
                          row.percentage,
                          null,
                          false,
                          'decimal',
                        )}%`}
                  </TableCell>
                  <TableCell>
                    {numberCriterias?.includes(ranking?.data.criteria)
                      ? row.value
                      : ranking?.data.valueLabel.includes('Montante')
                        ? `${convertValue(row.value, null, true, 'currency')}`
                        : `${convertValue(row.value, null, false, 'decimal')}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
