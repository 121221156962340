import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { useMutation } from 'react-query';

import SearchBarAutocomplete from './SearchBarAutocomplete';
import { autoComplete } from '../../services/elastic';
// Material ui components
// Local components

const useStyles = makeStyles((theme) => ({
  autoComplete: (props) => ({
    backgroundColor: '#D9D9D9',
    borderRadius: '9px',
    maxWidth: props.maxWidth,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  }),
  inputRoot: {
    padding: '3.5px !important',
    fontSize: '0.875rem',
  },
  option: {
    // Hover
    fontSize: '14px',
    paddingLeft: 0,
    '&[data-focus="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default function SearchBar(props) {
  const classes = useStyles(props);
  const [inputValue, setInputValue] = useState('');

  const searchAutoComplete = useMutation(autoComplete);

  useEffect(() => {
    const handler = setTimeout(() => {
      searchAutoComplete.mutate({ data: { keyword: inputValue } });
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const { isLoading: loading, data } = searchAutoComplete;

  return (
    <SearchBarAutocomplete
      classes={classes}
      loading={loading}
      data={data?.data}
      searchTerm={inputValue}
      setInputValue={setInputValue}
    />
  );
}
