import { useEffect, useRef, useState } from 'react';

import { Hidden } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MetaTags from 'src/components/Seo/MetaTags';
import { useSectionInScreen } from 'src/utils/hooks';

import { markets, paywallData } from './data';
import introBackground from '../../../../assets/imgs/yearbook/2024/anuario-bg-2024.jpg';
import introLogo from '../../../../assets/imgs/yearbook/2024/anuario-logo-2024.png';
import MenuNavigator from '../components/MenuNavigator';
import ArticlesSection from '../sections/Articles';
import * as Intro from '../sections/Intro';
import LetterToReaderSection from '../sections/LetterToReader';
import { PrimaryMarketSection } from '../sections/Markets/PrimaryMarket';
import { SecondaryMarketSection } from '../sections/Markets/SecondaryMarket';
import { StockSection } from '../sections/Markets/Stock';
import OpeningSection from '../sections/Opening';
import RankingsSection from '../sections/Rankings';
import SponsorsSection from '../sections/Sponsors';
import VideoGallerySection from '../sections/VideoGallery';
import * as Presentation from '../styles';
import { YearbookMarket, YearbookRouteParams } from '../types';
import { getMarketByName } from '../utils';

interface YearbookPresentation2024Props {
  paywallBlur: boolean;
}

export default function YearbookPresentation2024({
  paywallBlur,
}: YearbookPresentation2024Props) {
  const [selectedMarket, setSelectedMarket] = useState<YearbookMarket | null>(
    null,
  );
  const params = useParams<YearbookRouteParams>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const currentSection = useSectionInScreen();

  function selectMarket(market: YearbookMarket) {
    history.push(`/anuarios/2024/${market.name}/Inicio`);
    setSelectedMarket(market);
  }

  useEffect(() => {
    if (params.market)
      history.push(`/anuarios/2024/${params.market}/${currentSection}`);
  }, [currentSection]);

  function getMarketIconPath(name: string) {
    return require(`src/assets/imgs/yearbook/2024/icons/${name}.svg`);
  }

  useEffect(() => {
    if (params.market) {
      const market = getMarketByName(params.market);

      if (market) setSelectedMarket(market);
    }
  }, []);

  function scrollToPartners() {
    const element = document.querySelector(
      '[data-yearbook-section="Patrocinadores"]',
    );
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Presentation.Wrapper ref={wrapperRef} blur={paywallBlur}>
      <MetaTags
        title="Anuários Uqbar 2024"
        description="Mais do que uma publicação, uma experiência completa nos mercados de CRA, CRI, FIDC e FII"
      />
      {paywallBlur && (
        <Presentation.PayWallBannerWrapper>
          <CardDemonstration
            title={paywallData.title}
            subTitle={paywallData.subtitle}
            labelButton={paywallData.buttonLabel}
            url={paywallData.url}
          />
        </Presentation.PayWallBannerWrapper>
      )}
      <Intro.Section
        blur={paywallBlur}
        data-yearbook-section="Inicio"
        backgroundImage={introBackground}>
        {!paywallBlur && (
          <MenuNavigator
            markets={markets}
            selectMarket={selectMarket}
            selectedMarket={selectedMarket}
          />
        )}
        <Intro.LogoWrapper>
          <Intro.Logo src={introLogo} alt="Logo do Anuário 2024 da UQBAR" />
          {selectedMarket && (
            <Intro.CurrentMarket>
              <Intro.CurrentMarketIcon
                src={getMarketIconPath(selectedMarket.name).default}
              />
              {selectedMarket?.name}
            </Intro.CurrentMarket>
          )}
        </Intro.LogoWrapper>
        <Hidden smUp>
          <Intro.DownIconContainer>
            <Intro.DownIcon onClick={scrollToPartners} />
          </Intro.DownIconContainer>
        </Hidden>
      </Intro.Section>
      {selectedMarket && !paywallBlur && (
        <>
          <SponsorsSection market={selectedMarket} />
          <LetterToReaderSection />
          <OpeningSection market={selectedMarket} />
          <PrimaryMarketSection market={selectedMarket} />
          <SecondaryMarketSection market={selectedMarket} />
          <StockSection market={selectedMarket} />
          <RankingsSection market={selectedMarket} />
          <ArticlesSection market={selectedMarket} />
          <VideoGallerySection market={selectedMarket} />
        </>
      )}
    </Presentation.Wrapper>
  );
}
