import { makeStyles } from '@material-ui/core';

export const useInstitutionRankingStyles = makeStyles((theme) => ({
  surveyText: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  competence: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  position: {
    fontSize: '0.875rem',
    padding: theme.spacing(0, 1),
  },
  tableRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  adm: {
    fontWeight: theme.typography.fontWeightBold,
  },
  gridDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1.2rem',
    '& > .updated': {
      color: '#6D6E71',
      fontWeight: 500,
    },
    '& > .baseMonth': {
      color: '#6D6E71',
      fontWeight: 700,
      marginTop: '0.5rem',
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '29.05px',
    margin: theme.spacing(3, 0, 4),
  },
}));
