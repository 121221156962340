import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { If } from '../../../../components/If';
import { convertNumber, convertValue } from '../../../../utils/number';

export default function DataTable({
  classes,
  data,
  market,
  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table secondary operations">
        <TableHead>
          <TableRow>
            <TableCell>Classe</TableCell>
            <TableCell>
              {['cra', 'cri'].includes(market?.toLowerCase())
                ? 'Título'
                : 'Série'}
            </TableCell>
            <TableCell>Data</TableCell>
            <TableCell>{'Preço (mínimo)'}</TableCell>
            <TableCell>{'Preço (máximo)'}</TableCell>
            <TableCell>{'Preço (último)'}</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Núm Neg.</TableCell>
            <TableCell>Volume</TableCell>
            <TableCell>Ambiente</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>{item.class}</TableCell>
              <TableCell className={classes.tableCell}>
                {['cra', 'cri'].includes(market?.toLowerCase())
                  ? item.title === ''
                    ? '-'
                    : item.title
                  : item.series === ''
                    ? '-'
                    : item.series}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {format(parseISO(item.date.split('T')[0]), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {convertValue(item.minimumPrice)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {convertValue(item.maximumPrice)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {item.lastPrice ? convertValue(item.lastPrice) : '-'}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {convertNumber(item.quantity, 2)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {convertNumber(item.dealsNumber, 2)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {convertValue(item.amount)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {item.environment}
              </TableCell>
            </TableRow>
          ))}
          <If condition={!data || data?.length === 0}>
            <TableRow>
              <TableCell
                colSpan={10}
                rowSpan={10}
                component="td"
                scope="row"
                className={classes.notificationBox}>
                <Typography>Nenhum resultado encontrado</Typography>
              </TableCell>
            </TableRow>
          </If>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 30, 50]}
              count={parseInt(total, 10)}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={'Registros por página'}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
