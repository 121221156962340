import React from 'react';

import * as S from './styles';
import { abbreviatedNumber } from './utils';

type Props = {
  title?: string;
  data: Array<{
    name: string;
    type: 'column' | 'line' | 'area';
    data: number[];
  }>;
  categories: string[];
  market: 'CRA' | 'CRI' | 'FIDC' | 'FII';
  disableAnimation?: boolean;
};

const ColumnLineChart = (props: Props) => {
  return (
    <S.StyledBox>
      <span>{props.title}</span>
      <S.StyledChart
        series={props.data}
        type="line"
        options={{
          chart: {
            type: 'line',
            stacked: false,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: !props.disableAnimation,
            },
          },

          dataLabels: {
            formatter: (val: any) => {
              if (isNaN(Number(val))) {
                return val;
              }

              return Number(val).toFixed(2);
            },
          },
          stroke: {
            width: [0, 2, 5],
            curve: 'smooth',
          },
          plotOptions: {
            bar: {},
          },
          tooltip: {
            y: {
              formatter: function (value: any, opts: any) {
                if (isNaN(Number(value))) {
                  return value;
                }

                if (opts.seriesIndex === 1) {
                  return value.toLocaleString('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  });
                }

                const formatedValue = abbreviatedNumber(value);

                return `R$ ${formatedValue}`;
              },
            },
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: 'vertical',
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          labels: props.categories,
          markers: {
            size: 0,
          },
          grid: {
            show: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: 'date',
            labels: {
              show: true,
            },
          },
          yaxis: [
            {
              labels: {
                show: false,
              },
              min: 0,
            },
            {
              opposite: true,
              labels: {
                show: false,
              },
            },
          ],
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: {
                  animations: {
                    enabled: false,
                  },
                },
              },
            },
          ],
          colors: [
            '#A61F69',
            '#FD841F',
            '#600446',
            '#F2CD5C',
            '#35D0BA',
            '#CD104D',
          ],
        }}
      />
      <S.Button
        color="secondary"
        variant="outlined"
        size="large"
        onClick={() =>
          window.open(
            `/operacoes?market=${props.market.toLocaleLowerCase()}`,
            '_blank',
          )
        }>
        Explorar na Plataforma Uqbar
      </S.Button>
    </S.StyledBox>
  );
};

export default React.memo(ColumnLineChart);
