// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
// Material ui icons
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
// Global components
import PaperPanelInfo from 'src/components/PaperPanelInfo';
import ipanema from 'src/graphql/clients/ipanema';
import { GET_FIDC_CADASTRAL_DATA_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID } from 'src/graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
  },
  reportProblemOutlinedIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  infoOutlinedIcon: {
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function OperationFIDCResumeData({ hash }) {
  const classes = useStyles();
  const { data, loading } = useQuery(
    GET_FIDC_CADASTRAL_DATA_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
    },
  );

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title}>
          &mdash;Dados
        </Typography>
        {data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
          ?.isIncongruitiesAlert && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            arrow
            title="Alerta para dados com incongruências"
            placement="right">
            <ReportProblemOutlinedIcon
              className={classes.reportProblemOutlinedIcon}
              color="secondary"
              titleAccess="alerta"
            />
          </Tooltip>
        )}
      </Box>
      <Divider className={classes.divider} />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              first
              title="Ativo-lastro"
              value={
                data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
                  ?.underlyingAssets ?? 'N/D'
              }
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              numeric
              title="Patrimônio Líquido"
              value={
                data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
                  ?.netWorth ?? 'N/D'
              }
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              numeric
              title="Ativo"
              value={
                data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
                  ?.asset ?? 'N/D'
              }
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              numeric
              title="Passivo"
              value={
                data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
                  ?.liability ?? 'N/D'
              }
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              title="Risco de Crédito"
              value={
                data?.getFidcCadastralDataFromMonthAndYearByOperationHashId
                  ?.creditRiskCategory ?? 'N/D'
              }
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
