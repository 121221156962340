import React, { useState } from 'react';

import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { Storefront } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsIcon from '@mui/icons-material/Groups';
import StarIcon from '@mui/icons-material/Star';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkIcon from '@mui/icons-material/Work';
import Divider from '@mui/material/Divider';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import { cast } from 'src/pages/Yearbooks/presentation/utils';
import { v4 as uuid } from 'uuid';

import * as S from './styles';
import { useAuthContext } from '../../../context/AuthContextProvider';
import theme from '../../../theme';
import { If } from '../../If';

type Props = {
  classes: any;
};

export default function HeaderLoginMenu(props: Props) {
  const { classes } = props;
  const history = useHistory();
  const { auth, handleLogout, notifyAuthChannel } = useAuthContext();
  const isMobile = useMediaQuery<typeof theme>((theme) =>
    theme.breakpoints.down('xs'),
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isCompany = cast<any>(auth.user)?.type === 'legal_person';

  const handleClick = (event: React.SyntheticEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onClickLogout() {
    handleClose();
    handleLogout();
    notifyAuthChannel('logout');
    history.push('/');
  }

  const menuItems = [
    {
      label: 'Assinatura',
      onClick: () => history.push('/assinatura'),
      Icon: CreditCardIcon,
      index: 1,
    },
    {
      label: 'Configurações da conta',
      onClick: () => history.push('/perfil/dados'),
      Icon: AccountCircleIcon,
      index: 3,
    },
    {
      label: 'Perfil social',
      onClick: () => history.push('/perfil-social'),
      Icon: WorkIcon,
      index: 4,
    },
    {
      label: 'Marketplace',
      onClick: () => history.push('/marketplace/gerenciar'),
      Icon: Storefront,
      index: 5,
    },
    {
      label: 'Meus favoritos',
      onClick: () => history.push('/favoritos'),
      Icon: StarIcon,
      index: 6,
    },
  ];

  if (auth?.user?.subscription?.plan?.allowAddUser && auth?.user?.isOwner) {
    menuItems.push({
      label: 'Usuários',
      onClick: () => history.push('/usuarios'),
      Icon: GroupsIcon,
      index: 2,
    });
  }

  if (
    auth?.user?.subscription?.plan?.type !== 'free' &&
    auth?.user?.subscription?.status === 'Pago' &&
    auth?.user?.widget?.key
  ) {
    menuItems.push({
      label: 'Widget',
      onClick: () => history.push('/widget'),
      Icon: WidgetsIcon,
      index: 5,
    });
  }

  const userName = isCompany
    ? auth.user?.name
    : `${auth?.user?.name || ''} ${auth?.user?.otherName || ''}`;
  const planName = auth.user?.subscription?.plan?.name;

  return (
    <>
      {auth.isLoggedIn ? (
        <If condition={auth.user}>
          <Box>
            <Avatar
              name={
                isCompany
                  ? auth.user?.name
                  : `${auth?.user?.name} ${auth?.user?.otherName}`
              }
              maxInitials={isCompany ? 1 : 2}
              round
              size="40"
              className={classes.avatar}
              onClick={handleClick}
            />

            <S.Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <S.MenuHeader>
                {/* @ts-ignore */}
                <Test id="menu-text-name">
                  <span>{userName}</span>
                </Test>
                {/* @ts-ignore */}
                <Test id="menu-text-planName">
                  <Typography variant="body2">
                    {planName ? `Plano ${planName}` : ''}
                  </Typography>
                </Test>
              </S.MenuHeader>

              <Divider />
              {menuItems
                .sort(({ index: a }, { index: b }) => a - b)
                .map(({ Icon, label, onClick }) => (
                  <S.MenuItem
                    key={uuid()}
                    onClick={() => {
                      onClick();
                      handleClose();
                    }}>
                    <Icon />
                    <span>{label}</span>
                  </S.MenuItem>
                ))}
              <Divider />
              <S.MenuItem onClick={onClickLogout}>
                <span>SAIR</span>
              </S.MenuItem>
            </S.Menu>
          </Box>
        </If>
      ) : (
        <Button
          variant={isMobile ? 'contained' : 'text'}
          className={classes.button}
          onClick={() => {
            history.push('/login');
            handleClose();
          }}>
          Login
        </Button>
      )}
    </>
  );
}
