import { makeStyles } from '@material-ui/core';

export const AllVideosStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.75rem',
    display: 'inline-block',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.8rem',
    display: 'inline-block',
    marginBottom: theme.spacing(2),
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  collectionContainer: {
    margin: theme.spacing(2, 0),
  },
  collectionHeader: {
    margin: theme.spacing(0, 3, 7, 0),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  videosContainer: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
  },
  divider: {
    height: theme.spacing(0.2),
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
  },
  gridVideo: {
    margin: theme.spacing(3, 0),
  },
  headerGrid: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  notificationBox: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(10, 10),
    background:
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    backdropFilter: 'blur(10px)',
  },
  notificationTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '1.2rem',
    lineHeight: '29px',
    textAlign: 'center',
  },
  notificationSubTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '17px',
    marginTop: '1rem',
    textAlign: 'center',
  },
}));
