//Qa Test ids

import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Grid, Hidden, Skeleton, Typography } from '@mui/material';
import qs from 'qs';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Test from 'react-test-attributes';
import { getImageRegister } from 'src/services/media';
import { getDaysDemoPlan } from 'src/services/plans';

import * as S from '../styles';

function RegisterType({
  setRegisterType,
}: {
  setRegisterType: (
    nextParams: any,
    method?: 'push' | 'remove' | undefined,
  ) => void;
}) {
  const history = useHistory();
  const state = history.location.state;
  const { data: imageData, isLoading } = useQuery('resgisterImage', () =>
    getImageRegister('registerImage'),
  );

  const { data: plan, isLoading: isLoadingPlan } = useQuery<
    { id: number; trialPeriodDays: number }[]
  >('demoPlan', getDaysDemoPlan);

  const handleClick = (type: string) => {
    const search = qs.stringify({
      type,
    });
    setRegisterType({ type });

    history.push({ pathname: '/cadastro/identificacao', search, state });
  };

  return (
    <Grid
      container
      sx={(theme) => ({
        height: 'calc(100vh - 75px)',
        [theme.breakpoints.down('sm')]: { height: 'calc(100vh + 15px)' },
      })}>
      <Hidden mdDown>
        <Grid item md={6} xl={5} sx={{ height: 'calc(100vh - 75px)' }}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={740} height="100vh" />
          ) : (
            <>
              <S.Image
                src={imageData?.registerImage.image.url}
                alt={imageData?.registerImage.description}
              />
              <Typography
                sx={(theme) => ({
                  position: 'absolute',
                  zIndex: 99,
                  bottom: 50,
                  left: 75,
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '#FFFFFF',
                  maxWidth: 500,
                  lineHeight: '32px',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '18px',
                    maxWidth: 400,
                    lineHeight: '26px',
                  },
                })}>
                {imageData?.registerImage?.description}
              </Typography>
            </>
          )}
        </Grid>
      </Hidden>
      <Grid
        item
        md={6}
        xl={7}
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Box>
          {isLoadingPlan ? (
            <Skeleton variant="text" />
          ) : (
            <Typography
              sx={(theme) => ({
                textAlign: 'center',
                maxWidth: 425,
                fontSize: '18px',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                  padding: theme.spacing(0, 2),
                },
              })}>
              {`Cadastre-se e experimente nosso plano demonstração por ${
                plan?.at(0)?.trialPeriodDays
              } dias gratuitos com acesso à todos os benefícios.`}
            </Typography>
          )}
          <Typography
            sx={(theme) => ({
              fontSize: '24px',
              padding: theme.spacing(7, 0),
              fontWeight: 700,
              textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                fontSize: '22px',
              },
            })}>
            Você é Pessoa fisica ou Pessoa Juridica?
          </Typography>
        </Box>
        <Box
          width="75%"
          display="flex"
          alignItems="center"
          gap={4}
          justifyContent="center"
          sx={(theme) => ({
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          })}>
          {/* @ts-ignore */}
          <Test id="register-button-pf">
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                flexDirection: 'column',
                width: 148,
                height: 148,
                fontWeight: 400,
              }}
              onClick={() => handleClick('private_person')}>
              <PersonIcon sx={{ fontSize: '3.25rem' }} />
              Pessoa Física
            </Button>
          </Test>
          {/* @ts-ignore */}
          <Test id="register-button-pj">
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                flexDirection: 'column',
                width: 148,
                height: 148,
                fontWeight: 400,
              }}
              onClick={() => handleClick('legal_person')}>
              <BusinessIcon sx={{ fontSize: '3.25rem' }} />
              Pessoa Jurídica
            </Button>
          </Test>
        </Box>
      </Grid>
    </Grid>
  );
}

export default RegisterType;
