import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import addUserImage from 'src/assets/imgs/addUserImage.png';
import { Dialog } from 'src/components/Dialog';
import CPFFormat from 'src/components/FormsUI/CPFFormat';
import { FieldTitle } from 'src/pages/Register/styles';
import { createInvitation } from 'src/services/invitation';
import { setBannerMessage } from 'src/store/display';
import { validateCPF } from 'src/utils/document';
import * as Yup from 'yup';

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  otherName: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .required('Campo obrigatório')
    .email('Digite um e-mail válido'),
  document: Yup.string()
    .required('Campo obrigatório')
    .test(
      'len',
      'CPF inválido',
      (val) => val?.length === 11 && validateCPF(val),
    ),
});

function NewUserForm({
  open,
  onClose,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}) {
  const dispatch = useDispatch();
  const inviteUser = useMutation(createInvitation, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        formik.setErrors(error?.response?.data?.error?.details);
      } else {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: false,
          }),
        );
        handleClose();
      }
    },
    onSuccess: async () => {
      dispatch(
        setBannerMessage({
          message: 'Convite enviado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      handleClose();
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      otherName: '',
      document: '',
      email: '',
    },
    validationSchema: FORM_VALIDATION,
    onSubmit: (values) => {
      inviteUser.mutate(values);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="Convidar novo usuário"
      maxWidth={false}
      sx={{ maxWidth: 800, margin: '0 auto' }}>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: 550,
            [theme.breakpoints.down('md')]: {
              height: '100vh',
            },
          })}>
          <img src={addUserImage} width={185} height={185} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldTitle>Nome*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="name"
                  placeholder="Insira o nome do convidado"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldTitle>Sobrenome*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="otherName"
                  placeholder="Insira o sobrenome do convidado"
                  value={formik.values.otherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.otherName && Boolean(formik.errors.otherName)
                  }
                  helperText={
                    formik.touched.otherName && formik.errors.otherName
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldTitle>E-mail*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="email"
                  placeholder="Digite o e-mail"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldTitle>CPF*</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="document"
                  placeholder="Digite o CPF"
                  value={formik.values.document}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.document && Boolean(formik.errors.document)
                  }
                  helperText={formik.touched.document && formik.errors.document}
                  InputProps={{
                    inputComponent: CPFFormat,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '50px' }}>
          <Button type="submit" variant="contained">
            {inviteUser.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Convidar'
            )}
          </Button>
          <Button
            type="reset"
            variant="outlined"
            onClick={handleClose}
            color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewUserForm;
