/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import { Dialog } from '../../../../components/Dialog';
import { DataContext } from '../../../../context/DataContext';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    lineHeight: '23px',
  },
  subTitle: {
    margin: theme.spacing(2, 0),
    lineHeight: '20px',
  },
}));

const NewSecurityAction = ({ security }) => {
  const classes = useStyles();
  const [newName, setNewName] = useState('');
  const [openNewSecurity, setOpenNewSecurity] = useState(false);
  const { newSecurity, setPickedSecurity } = useContext(DataContext);

  const handleNewSecurity = async () => {
    setOpenNewSecurity(false);
    const securityCreated = await newSecurity(security.values, newName);
    if (securityCreated) {
      security.setValues(securityCreated);
      setPickedSecurity(securityCreated);
    }
  };

  const handleCloseNewSecurity = () => {
    setNewName('');
    setOpenNewSecurity(false);
  };

  return (
    <>
      <Button
        color="primary"
        data-hover="Salvar"
        className="hovertext"
        style={{ padding: '6px 10px', minWidth: 0 }}
        onClick={() => setOpenNewSecurity(true)}>
        <Save style={{ color: 'black' }} />
      </Button>
      <Dialog
        open={openNewSecurity}
        onClose={handleCloseNewSecurity}
        maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" className={classes.title}>
            Escreva o nome do novo título
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            Seu título será salvo no campo “Meus títulos”, podendo ser
            consultado quando você quiser.
          </Typography>
          <TextField
            id="name"
            label="Nome do título"
            type="text"
            fullWidth
            variant="outlined"
            value={newName || ''}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseNewSecurity}>
            Cancelar
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleNewSecurity}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewSecurityAction;
