import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';

import WalletFII from './WalleltFII';
import WalletCRICRA from './WalletCRICRA';
import WalletFIDC from './WalletFIDC';
import WalletProperty from './WalletProperty';

function OperationFIIWallet({ operationCnpj }: { operationCnpj: string }) {
  const { auth } = useAuthContext();
  const [hasData, setHasData] = useState({
    cri: true,
    fii: true,
    fiiProperty: true,
    fidc: true,
  });

  if (!hasData.cri && !hasData.fii && !hasData.fidc && !hasData.fiiProperty)
    return null;

  return (
    <Box mt={10}>
      <SubTitle title={'Carteira'} />
      {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
        <PanelLogin small={undefined} message={undefined} />
      ) : (
        <>
          <Grid item xs={12}>
            <WalletCRICRA
              operationCnpj={operationCnpj}
              setHasData={setHasData}
            />
          </Grid>
          <Grid item xs={12}>
            <WalletFII operationCnpj={operationCnpj} setHasData={setHasData} />
          </Grid>
          <Grid item xs={12}>
            <WalletProperty
              operationCnpj={operationCnpj}
              setHasData={setHasData}
            />
          </Grid>
          <Grid item xs={12}>
            <WalletFIDC operationCnpj={operationCnpj} setHasData={setHasData} />
          </Grid>
        </>
      )}
    </Box>
  );
}

export default OperationFIIWallet;
