import { Box, Theme, useMediaQuery } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { useAuthContext } from 'src/context/AuthContextProvider';

type Props = {
  hasPermission: boolean;
  columns: MRT_ColumnDef<any>[];
  data: any;
  isLoading: boolean;
};

export default function OperationWalletTable({
  hasPermission,
  data,
  columns,
  isLoading,
}: Props) {
  const { upgradedPlanByPermission } = useAuthContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const plansToUpgrade = upgradedPlanByPermission(
    'operacoes_fii_ativo_carteira',
  );

  const fakeColumns: MRT_ColumnDef<any>[] = Array(isSmall ? 3 : 5)
    .fill({})
    .map((value, index) => ({
      accessorKey: `column${index + 1}`,
      header: `Coluna${index + 1}`,
    }));

  const fakeDataRow = fakeColumns.reduce((acc, column, index) => {
    return {
      ...acc,
      [column.accessorKey as string]: `Dado ${index + 1}`,
    };
  }, {});

  const fakeRows = Array(isSmall ? 5 : 10).fill(fakeDataRow);

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    localization: {
      ...MRT_Localization_PT_BR,
      ...MaterialTableDefaultProps.localization,
      noRecordsToDisplay: 'Não há registros para exibir',
    },
    columns: hasPermission ? columns : fakeColumns,
    initialState: {
      sorting: [
        {
          id: 'valor',
          desc: true,
        },
      ],
    },
    data: hasPermission ? data : fakeRows,
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    layoutMode: 'grid',
    enableRowVirtualization: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableSorting: true,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiBottomToolbarProps: {
      sx: {
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        minHeight: 'unset',
      },
    },
    state: {
      isLoading,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadCellProps: {
      height: '65px',
      sx: {
        justifyContent: 'center',
      },
    },
    renderBottomToolbarCustomActions: () => (
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          p: '4px',
          width: '100%',
          justifyContent: data?.length > 1 ? 'space-between' : 'flex-end',
        }}>
        {data?.length > 1 && (
          <span style={{}}>{`Total de ${data?.length} linhas`}</span>
        )}
        <span style={{ fontWeight: 'bold' }}>
          Mês base:
          <span style={{ textTransform: 'capitalize' }}>
            {data?.length && data[0]?.dataReferencia
              ? ` ${format(parseISO(data[0].dataReferencia), 'MMMM/yyyy', {
                  locale: ptBR,
                })}`
              : ''}
          </span>
        </span>
      </Box>
    ),
  });

  return (
    <Box py={6}>
      <Box
        sx={
          !hasPermission
            ? {
                '-webkit-filter': 'blur(5px)',
                pointerEvents: 'none',
              }
            : {}
        }>
        <MaterialTable table={table} />
      </Box>
      {!hasPermission && (
        <Box position="relative">
          <Box
            sx={(theme) => ({
              width: '100%',
              position: 'absolute',
              top: -450,
              left: 0,
              [theme.breakpoints.down('md')]: {
                top: -300,
              },
              [theme.breakpoints.down('sm')]: {
                top: -400,
              },
            })}>
            <CardDemonstration
              title={`Recurso disponível apenas no plano ${plansToUpgrade}`}
              subTitle={
                'Faça um upgrade em sua assinatura para ter acesso à movimentações do mercado.'
              }
              labelButton={'Fazer upgrade'}
              url={'/planos'}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
