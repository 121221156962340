import { useState } from 'react';

import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { FeaturedOperation } from 'src/types/institution';

type Props = {
  featuredOperations?: FeaturedOperation[];
};

export function FeaturedOperations({ featuredOperations }: Props) {
  const { auth } = useAuthContext();
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? featuredOperations?.length : 4;
  const buttonText = showAll ? 'Ver Menos' : 'Ver Mais';

  const fakeOperations = Array(1)
    .fill({})
    .map((value, index) => ({
      description: `descrição${index + 1}`,
      id: index + 1,
      operation: {
        id: 0,
        name: `operação${index + 1}`,
        market: {
          id: index + 1,
          description: '',
          name: `mercado${index + 1}`,
        },
        slug: `slug${index + 1}`,
        hashId: `${index} + 1`,
      },
    }));

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const operations = auth.isLoggedIn ? featuredOperations : fakeOperations;

  const renderedOperations = operations
    ?.slice(0, itemsToShow)
    .map((featuredOperation, index) => (
      <Grid
        item
        key={`${featuredOperation.id}-featuredOperation`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
        <TimelineIcon />
        <Box>
          <Link
            sx={{ fontWeight: 600 }}
            component={RouterLink}
            to={`/operacao/${featuredOperation?.operation?.market?.name?.toLowerCase()}/${
              featuredOperation?.operation.slug
            }/${featuredOperation?.operation?.hashId}`}>
            {featuredOperation?.operation?.name}
          </Link>
          <Typography textAlign="justify">
            {featuredOperation.description}
          </Typography>
        </Box>
      </Grid>
    ));

  return (
    <Section
      title="Operações em destaque"
      subTitle="As informações abaixo não foram verificadas pela Uqbar"
      hasPaywall={true}
      paywallBanner="institutionProfile">
      <Grid container direction="column" spacing={2}>
        {renderedOperations}
      </Grid>
      {featuredOperations?.length && featuredOperations?.length > 4 && (
        <Button
          variant="text"
          color="primary"
          sx={{
            height: 'unset',
            fontWeight: 500,
            minWidth: 'unset',
            padding: 0,
            marginTop: 3,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={toggleShowAll}>
          {buttonText}
        </Button>
      )}
    </Section>
  );
}
