import * as React from 'react';

import { Box, Container, Paper } from '@mui/material';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import LiveCarousel from '../../pages/Event/Presentation/Live/LiveCarousel';
import { getPresentationsInLiveByEvent } from '../../services/events';

export default function PresentationOngoing() {
  const route = useRouteMatch(
    '/evento/:slug/:id/palestra/:presentationId/live',
  );
  const { data, isLoading } = useQuery(
    ['presentation', 'ongoing'],
    () => getPresentationsInLiveByEvent(),
    {
      refetchInterval: 60000,
      enabled: !route?.isExact,
    },
  );

  if (isLoading || !data?.[0] || route?.isExact) return <></>;

  return (
    <Paper
      sx={(theme) => ({
        top: '75px',
        position: 'relative',
        zIndex: 1,
        marginBottom: '8px',
        backgroundColor: '#FAFAFA',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        [theme.breakpoints.down('lg')]: {
          position: 'unset',
          mt: '130px',
          mb: '-80px',
        },
        [theme.breakpoints.down('sm')]: {
          position: 'unset',
          mt: '220px',
          mb: '-100px',
        },
      })}>
      <Box>
        <Container
          sx={(theme) => ({
            px: 0,
            [theme.breakpoints.down('sm')]: {
              '& .alice-carousel': {
                px: '0 !important',
              },
              '& .alice-carousel__prev-btn, & .alice-carousel__next-btn': {
                top: '100px !important',
              },
              '& .alice-carousel__stage-item > div > div:last-child div': {
                py: '16px !important',
              },
            },
          })}>
          <LiveCarousel
            presentation={data[0]}
            ignorePresentation={false}
            showPreview={true}
          />
        </Container>
      </Box>
    </Paper>
  );
}
