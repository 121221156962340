import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    .menu {
      display: flex;
      margin-top: 0.5rem;
    }

    .hovertext {
      position: relative;
    }

    .hovertext:before {
      content: attr(data-hover);
      visibility: hidden;
      opacity: 0;
      width: 140px;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      transition: opacity 0.5s ease-in-out;

      position: absolute;
      z-index: 1;
      left: 0;
      top: 110%;
    }

    .hovertext:hover:before {
      opacity: 1;
      visibility: visible;
    }

    .btn-simulate {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75rem;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
    }
  `}
`;
