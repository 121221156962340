import React from 'react';

import { v4 as uuid } from 'uuid';

import { pageData, rankingsData } from './data';
import {
  formatChartProps,
  formatMonthlyColumnLine,
  formatMonthlyColumnSpread,
  YEARS,
} from '../chart-formater';
import { fiFund, fiFundPl } from '../chart-map-data';
import ColumnChart from '../components/Charts/ColumnChart';
import ColumnLineChart from '../components/Charts/ColumnLineChart';
import Page from '../components/Page';
import ScrollHandler from '../components/Page/ScrollHandler';
import Text from '../components/Page/Text';
import Ranking from '../components/Widgets/Ranking';
import { Banners, ChartsDataRequestPayload } from '../types';
import { marketPageFns } from '../utils';

type Props = {
  indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>;
  open: boolean;
  chartsData: ChartsDataRequestPayload[];
  banners: Banners;
};

const COLLECTION_ID = 31;

const FIDC = (props: Props) => {
  const banners = marketPageFns.getMarketBanners(props.banners, 'FIDIC');
  const chartData = marketPageFns.getChartData(props.chartsData, COLLECTION_ID);

  const [
    assetContractChart,
    condoChart,
    fundTypeChart,
    issuancesChart,
    negotiationChart,
    plChart,
    collateralAssetPLChart,
    condoPLChart,
    fundPLChart,
    multicedingPlChart,
  ] = chartData;

  const [
    intro,
    primaryMarket,
    secondaryMarket,
    supply,
    rankings,
    motaText,
    santosText,
    peixotoText,
    speziaText,
    moreiraText,
    camposRedighieriText,
  ] = pageData.map(marketPageFns.formatPageData(props.indexes, banners));

  const getPageProps = marketPageFns.getPageProps(props.open);

  const isReady = marketPageFns.checkIsReady(
    props.indexes,
    pageData,
    chartData,
    10,
  );

  return isReady ? (
    <>
      <Page {...getPageProps(intro)}>
        <Text text={intro.text} />
      </Page>
      <Page {...getPageProps(primaryMarket)} doubleColumn>
        <ScrollHandler
          textEl={primaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FIDC"
              {...formatChartProps(issuancesChart)({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatChartProps(condoChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatChartProps(fiFund(fundTypeChart))({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,

            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatChartProps(assetContractChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(secondaryMarket)} doubleColumn>
        <ScrollHandler
          textEl={secondaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FIDC"
              {...formatChartProps(negotiationChart)({
                chart: 'columnLine',
                categories: YEARS,
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(supply)} doubleColumn>
        <ScrollHandler
          textEl={supply.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FIDC"
              {...formatMonthlyColumnLine(YEARS)(plChart, {
                amount: { source: 'Soma de pl', value: 'Patrimônio Líquido' },
                quantity: {
                  source: 'Valores distintos de cnpj',
                  value: 'Núm. de FIDC',
                },
              })}
              disableAnimation
            />,
            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatMonthlyColumnSpread(YEARS)(condoPLChart, {
                amount: 'Soma de pl',
                date: 'data_ref',
                name: 'condominio',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatMonthlyColumnSpread(YEARS)(fiFundPl(fundPLChart), {
                amount: 'Soma de pl',
                date: 'data_ref',
                name: 'type',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FIDC"
              {...formatMonthlyColumnSpread(YEARS)(collateralAssetPLChart, {
                amount: 'Soma de pl',
                date: 'data_ref',
                name: 'ativo_lastro',
              })}
            />,
            <ColumnLineChart
              key={uuid()}
              market="FIDC"
              {...formatMonthlyColumnLine(YEARS)(multicedingPlChart, {
                amount: { source: 'Soma de pl', value: 'Patrimônio Líquido' },
                quantity: {
                  source: 'Valores distintos de cnpj',
                  value: 'Núm. de FIDC MM',
                },
              })}
              disableAnimation
            />,
          ]}
        />
      </Page>

      <Page {...getPageProps(rankings)}>
        <ScrollHandler
          textEl={rankings.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={rankingsData.map((source, index) =>
            source ? <Ranking urlSource={source} key={index} /> : null,
          )}
        />
      </Page>
      <Page {...getPageProps(motaText)}>
        <Text text={motaText.text} />
      </Page>
      <Page {...getPageProps(santosText)}>
        <Text text={santosText.text} />
      </Page>
      <Page {...getPageProps(peixotoText)}>
        <Text text={peixotoText.text} />
      </Page>
      <Page {...getPageProps(speziaText)}>
        <Text text={speziaText.text} />
      </Page>
      <Page {...getPageProps(moreiraText)}>
        <Text text={moreiraText.text} />
      </Page>
      <Page {...getPageProps(camposRedighieriText)}>
        <Text text={camposRedighieriText.text} />
      </Page>
    </>
  ) : (
    <></>
  );
};

export default React.memo(FIDC);
