import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { getIndicators } from '../../../services/indicators';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    minHeight: 400,
    maxHeight: 400,
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      maxHeight: 'none',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24.2px',
    marginBottom: '2rem',
  },
  verticalDivider: {
    height: 70,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      width: 250,
      marginLeft: '50px',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5, 0, 7.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5),
    },
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  text: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: '#797979',
  },
}));

export default function Indicators() {
  const classes = useStyles();
  const { data } = useQuery(['indicators'], () => getIndicators());

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography component="h3" className={classes.title}>
        Indicadores da plataforma
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs className={classes.gridItem}>
          <Link to="/profissionais" className={classes.value}>
            {data?.professional}
          </Link>
          <Typography className={classes.text}>
            Número de profissionais
          </Typography>
        </Grid>
        <Hidden smUp>
          <Divider className={classes.divider} />
        </Hidden>
        <Hidden xsDown>
          <Divider
            orientation={'vertical'}
            className={classes.verticalDivider}
          />
        </Hidden>
        <Grid item xs className={classes.gridItem}>
          <Link to="/instituicoes" className={classes.value}>
            {data?.institution}
          </Link>
          <Typography className={classes.text}>
            Número de instituições
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container alignItems="center">
        <Grid item xs className={classes.gridItem}>
          <Link to="/artigos" className={classes.value}>
            {data?.article}
          </Link>
          <Typography className={classes.text}>Artigos publicados</Typography>
        </Grid>
        <Hidden smUp>
          <Divider className={classes.divider} />
        </Hidden>
        <Hidden xsDown>
          <Divider
            orientation={'vertical'}
            className={classes.verticalDivider}
          />
        </Hidden>
        <Grid item xs className={classes.gridItem}>
          <Link to="/operacoes" className={classes.value}>
            {data?.operation}
          </Link>
          <Typography className={classes.text}>Número de operações</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
