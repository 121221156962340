import { Box } from '@material-ui/core';
import { useQuery } from 'react-query';

import MainPageCalculator from './MainPageCalculator';
import { Paywall } from '../../components/Paywall';
import Subheader from '../../components/Subheader';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getInformativeTextByLocation } from '../../services/informative';
import { ScrollToTop } from '../../utils/scroll';

const Calculator = () => {
  const { auth, upgradedPlanByPermission } = useAuthContext();
  const { data, isLoading } = useQuery('info', () =>
    getInformativeTextByLocation('calculator'),
  );

  return (
    <ScrollToTop>
      <Subheader
        title="Calculadora"
        breadcrumbs1="Calculadora"
        tooltipText={data?.data?.calculator?.informationText}
      />
      <Box mt={2}>
        <Paywall
          isLoading={isLoading}
          allow={auth?.user?.subscription?.plan?.permissions?.calculadora}
          content={`Assine o plano ${upgradedPlanByPermission('calculadora')} e tenha acesso a calculadora da Uqbar.`}>
          <MainPageCalculator />
        </Paywall>
      </Box>
    </ScrollToTop>
  );
};

export default Calculator;
