import axios from 'axios';

import { advertisements, markets } from './presentation/data';
import { RankingWidgetParams } from './types';

export function getMarketByName(marketName: string) {
  return markets.find(({ name }) => marketName === name);
}

export function getSectionElement(sectionName: string) {
  const element = document.querySelector(
    `[data-yearbook-section="${sectionName}"]`,
  );
  return element;
}

export function getAdvertisementByPosition(marketName: string, pos: number) {
  return advertisements[marketName][pos - 1];
}

export function scrollSmoothToElement(el: Element | null) {
  window.scrollTo({
    behavior: 'smooth',
    top: window.scrollY + (el?.getBoundingClientRect().top ?? 0),
  });
}

export function getYearbookSectionsName(attribute = 'data-yearbook-section') {
  const sections = [...document.querySelectorAll('section')];

  const yearbookSections = sections.filter((section) =>
    section.hasAttribute(attribute),
  );

  return yearbookSections.map((section) =>
    section.getAttribute(attribute),
  ) as string[];
}

export function extractRankingParams(url: string): RankingWidgetParams | null {
  const paramsMatch = url.match(/www\.uqbar\.com\.br\/rankings\/dados\?(.*)/);

  if (paramsMatch) {
    const params = paramsMatch[1].split('&').map((i) => i.split('='));

    return Object.fromEntries(params);
  }

  return null;
}

export function getVideoEmbedUrl(videoId: string) {
  return `https://player.vimeo.com/video/${videoId}`;
}

export async function getVideoThumbnailUrl(videoId: string): Promise<string> {
  const { data } = await axios.get(
    `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`,
  );
  return data.thumbnail_url;
}

export function getArticleAuthorId(linkUrl: string) {
  return linkUrl.split('/').slice(-1)[0];
}
