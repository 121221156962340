import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
} from '@mui/material';
import { If } from 'src/components/If';
import { Institution } from 'src/types/institution';
import { useQueryParams } from 'src/utils/hooks';

import Photos from './Photos';
import Videos from './Videos';

function Gallery({ institution }: { institution: Institution }) {
  const [{ query }, setQuery] = useQueryParams<{
    type: 'fotos' | 'videos';
  }>({ type: 'fotos' });
  const options = [
    { label: 'Fotos', value: 'fotos' },
    { label: 'Vídeos', value: 'videos' },
  ];

  const handleChangeType = (type: string) => {
    setQuery({
      ...query,
      type,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        marginTop: 5,
        padding: '36px 42px',
        [theme.breakpoints.down('sm')]: {
          padding: '36px 16px',
        },
      })}>
      <Box sx={{ width: '100%' }}>
        <BottomNavigation
          value={query.type}
          onChange={(event, newValue) => {
            handleChangeType(newValue);
          }}
          showLabels
          sx={{
            height: 48,
            width: 'max-content',
            justifyContent: 'flex-start',
          }}>
          {options?.map((type, index) => (
            <BottomNavigationAction
              key={`${type}-${index}`}
              label={type.label}
              value={type.value}
            />
          ))}
        </BottomNavigation>
        <Divider />

        <Box mt={9}>
          <If condition={query.type === 'fotos'}>
            <Photos institutionId={institution.id} />
          </If>
          <If condition={query.type === 'videos'}>
            <Videos institutionId={institution.id} />
          </If>
        </Box>
      </Box>
    </Box>
  );
}

export default Gallery;
