import * as showdown from 'showdown';

export const YEARBOOK_LINKS = [
  {
    names: ['FIAGRO Valora CRA'],
    url: 'https://uqbar.com.br/operacao/fii/fiagro-valora-cra-8658287/8658287',
  },
  {
    names: ['FIAGRO Insumos Milenio Terramagna'],
    url: 'https://uqbar.com.br/operacao/fidc/fiagro-insumos-milenio-terramagna-8688996/8688996',
  },
  {
    names: ['FIAGRO Kinea Crédito Agro'],
    url: 'https://uqbar.com.br/operacao/fii/fiagro-kinea-credito-agro-8654987/8654987',
  },
  {
    names: ['FIAGRO Itaú Asset Rural'],
    url: 'https://uqbar.com.br/operacao/fii/fiagro-itau-asset-rural-8691783/8691783',
  },
  {
    names: ['FIAGRO XP Crédito Agro'],
    url: 'https://uqbar.com.br/operacao/fii/fiagro-xp-credito-agro-8814662/8814662',
  },
  {
    names: ['FIAGRO Sparta'],
    url: 'https://uqbar.atlassian.net/wiki/pages/resumedraft.action?draftId=547160071',
  },
  {
    names: ['CRA mais negociado'],
    url: 'https://uqbar.com.br/operacao/cra/vert-77-1/8889237',
  },
  {
    names: ['CRA ECTP'],
    url: 'https://uqbar.com.br/operacao/cra/vert-70-1/8907195',
  },
  {
    names: ['CRA da Virgo'],
    url: 'https://uqbar.com.br/operacao/cra/virgo-125-1/8888463',
  },
  {
    names: ['CRA líder'],
    url: 'https://uqbar.com.br/operacao/cra/vert-77-1/8889237',
  },
  {
    names: ['emissão 38 da True'],
    url: 'https://uqbar.com.br/operacao/cra/true-38-2/8917794',
  },
];

const mapLinks = (text: string) => {
  let newText = text;
  YEARBOOK_LINKS.forEach((link) =>
    link.names.forEach(
      (name) => (newText = newText.replaceAll(name, `[${name}](${link.url})`)),
    ),
  );

  return newText;
};

export const withLinks = (text: string) => {
  const mdConverter = new showdown.Converter({ openLinksInNewWindow: true });

  return mdConverter.makeHtml(mapLinks(text.trim()));
};
