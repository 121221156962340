import { useEffect, useState } from 'react';

import { Box, Paper, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import MarketplaceProjects from './Projects';
import MarketplaceServices from './Services';
import MetaTags from '../../components/Seo/MetaTags';
import SubHeader from '../../components/UI/SubHeader/SubHeader';
import TabNavigation from '../../components/UI/tabNavigation';
import { getInformativeTextByLocation } from '../../services/informative';
import { ScrollToTop } from '../../utils/scroll';

type Marketplace = {
  type: 'servicos' | 'projetos';
  title: string;
  disabled: boolean;
};

export default function Marketplace() {
  const theme = useTheme();
  const params = useParams<{ type: 'servicos' | 'projetos' }>();
  const { replace } = useHistory();
  if (!params.type) {
    replace('/marketplace/servicos');
    params.type = 'servicos';
  }
  const [scroll, setScroll] = useState(false);

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo({
      top: isSmall ? 200 : 0,
      behavior: 'smooth',
    });
  }, [scroll]);

  const marketplaceTypes: Marketplace[] = [
    {
      type: 'servicos',
      title: 'Serviços',
      disabled: false,
    },
    {
      type: 'projetos',
      title: 'Projetos',
      disabled: false,
    },
  ];

  const marketplaceType =
    marketplaceTypes.find(({ type }) => type === params.type) ||
    marketplaceTypes[0];

  const { data: infoData } = useQuery('info', () =>
    getInformativeTextByLocation('marketplace'),
  );

  return (
    <ScrollToTop>
      <Box
        sx={{
          '@media print': {
            zoom: '80%',
          },
        }}>
        <MetaTags title="Marketplace Uqbar" description="" />
        <SubHeader
          title="Marketplace"
          subTitle={infoData?.data?.marketplace?.informationText}
          breadcrumbs1={{ title: 'Marketplace', link: '/marketplace' }}
          breadcrumbs2={marketplaceType.title}
        />
        <Paper
          square
          elevation={0}
          sx={{
            padding: theme.spacing(3, 4),
            [theme.breakpoints.down('xs')]: {
              padding: theme.spacing(3, 2),
            },
          }}>
          <TabNavigation
            value={params.type}
            handleSelect={(value) => replace(`/marketplace/${value}`)}
            disabled={marketplaceTypes
              .filter(({ disabled }) => disabled)
              .map(({ type }) => type)}
            items={marketplaceTypes.map(({ type, title }) => ({
              label: title,
              value: type,
            }))}
          />
          {params.type === 'projetos' && (
            <MarketplaceProjects type={marketplaceType.type} />
          )}
          {params.type === 'servicos' && (
            <MarketplaceServices type={marketplaceType.type} />
          )}
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
