// Material ui componentes
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationFIDCLiabilityData from './OperationFIDCLiabilityData';
import OperationFIDCLiabilityPatrimony from './OperationFIDCLiabilityPatrimony';
import OperationFIDCLiabilityQuote from './OperationFIDCLiabilityQuote';
import OperationFIDCLiabilityRentabilityHistory from './OperationFIDCLiabilityRentabilityHistory';

export default function OperationFIDCLiability({ cnpj, hash, name }) {
  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12}>
          <OperationFIDCLiabilityData hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCLiabilityPatrimony hash={hash} name={name} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCLiabilityQuote hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIDCLiabilityRentabilityHistory name={name} cnpj={cnpj} />
        </Grid>
      </Grid>
    </Box>
  );
}
