import { useState } from 'react';

import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Person,
  Twitter,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import defaultCoverImage from 'src/assets/imgs/defaultCoverImage.png';
import defaultImage from 'src/assets/imgs/uploadImage.png';
import { FavoriteButton } from 'src/components/FavoriteButton';
import { SocialNetworkButton } from 'src/components/SocialNetworkButton';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Professional } from 'src/types/professional';
import { getSmallestImageUrl } from 'src/utils/image';
import { formatUrl, truncateText } from 'src/utils/string';

import IntroductionForm from './IntroductionForm';
import { Image } from './styles';
import MetaTags from '../../../components/Seo/MetaTags';

type Props = {
  professional: Professional;
  refetch: () => void;
};

function Header({ professional, refetch }: Props) {
  const { auth } = useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    const offsetTop =
      document.getElementById('relatedInstitution')?.offsetTop ?? 0;

    window.scrollTo({
      top: offsetTop - 50,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <MetaTags
        title={professional.fullName}
        description={professional.profileText}
        imageUrl={professional.coverImage?.url ?? defaultCoverImage}
        type="profile"
        seo={professional.seo}
        profile={{
          firstName: professional.name,
          lastName: professional.surname,
        }}
      />
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          position: 'relative',
          padding: '0 0 20px',
          borderRadius: '0px 0px 5px 5px',
          borderRight: '1px solid var(--gray-5, #E0E0E0)',
          borderBottom: '1px solid var(--gray-5, #E0E0E0)',
          borderLeft: '1px solid var(--gray-5, #E0E0E0)',
          minHeight: '230px',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center">
          <Image src={professional?.coverImage?.url ?? defaultCoverImage} />
        </Box>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: 'fit-content',
            left: 33,
            top: 100,
            [theme.breakpoints.down('sm')]: {
              left: 20,
              top: 95,
            },
          })}>
          {professional?.photo ? (
            <Avatar
              variant="circular"
              imgProps={{
                sx: {
                  objectFit: 'contain',
                  backgroundColor: 'white',
                },
              }}
              sx={(theme) => ({
                width: '200px',
                height: '200px',
                border: '5px solid #FAFAFA',
                [theme.breakpoints.down('sm')]: {
                  width: '110px',
                  height: '110px',
                },
              })}
              src={getSmallestImageUrl(professional?.photo)}
            />
          ) : (
            <Avatar
              variant="circular"
              sx={(theme) => ({
                width: '200px',
                height: '200px',
                border: `5px solid ${theme.palette.background.paper}`,
                [theme.breakpoints.down('sm')]: {
                  width: '110px',
                  height: '110px',
                },
              })}>
              <Person sx={{ fontSize: '94px' }} />
            </Avatar>
          )}
        </Box>
        <Grid
          container
          sx={(theme) => ({
            minHeight: 150,
            padding: '30px 26px 0px 270px',
            [theme.breakpoints.down('md')]: {
              padding: '30px 20px 0px 270px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: '100px 20px 0px 20px',
            },
          })}>
          <Grid item md={9} rowSpacing={2} container alignItems="center">
            <Grid item xs={12} container>
              <Grid item xs={11} container rowSpacing={1} alignItems="center">
                <Typography
                  sx={(theme) => ({
                    fontSize: '32px',
                    fontWeight: 700,
                    lineHeight: '35.2px',
                    color: '#1D1D1D',
                    marginRight: '10px',
                    display: 'flex',
                    [theme.breakpoints.down('sm')]: {
                      marginRight: '9px',
                      fontSize: '28px',
                    },
                  })}>
                  {professional?.fullName}
                </Typography>
                <FavoriteButton type="professional" />
              </Grid>
              <Hidden mdUp>
                {professional?.isOwner && (
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => setOpenDialog(true)}
                      sx={{
                        padding: 0,
                        '&:hover': { background: 'transparent' },
                      }}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                }}>
                {professional?.professionalTitle || professional?.role}
              </Typography>
              <Grid
                container
                sx={{
                  paddingTop: '8px',
                }}>
                {professional?.location && (
                  <>
                    <LocationOn
                      fontSize="small"
                      sx={{
                        margin: '0 6px 0 -4px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                      }}>
                      {`${professional?.location}, Brasil`}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={(theme) => ({
                flexDirection: 'row',
                flexWrap: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                },
              })}>
              <Grid
                container
                item
                sm={6}
                md={10}
                sx={{ height: 'fit-content' }}>
                {(professional?.twitter?.url ||
                  professional?.linkedin?.url ||
                  professional?.facebook?.url ||
                  professional?.instagram?.url) && (
                  <>
                    <Grid
                      container
                      item
                      sx={(theme) => ({
                        height: 'fit-content',
                        '-webkit-filter': !auth.isLoggedIn
                          ? 'blur(5px)'
                          : 'none',
                        [theme.breakpoints.only('sm')]: {
                          alignItems: 'flex-start',
                        },
                      })}>
                      {professional?.twitter?.url && (
                        <SocialNetworkButton
                          disabled={!auth.isLoggedIn}
                          url={formatUrl(professional.twitter.url)}
                          icon={<Twitter />}
                        />
                      )}
                      {professional?.linkedin?.url && (
                        <SocialNetworkButton
                          disabled={!auth.isLoggedIn}
                          url={formatUrl(professional.linkedin.url)}
                          icon={<LinkedIn />}
                        />
                      )}
                      {professional?.facebook?.url && (
                        <SocialNetworkButton
                          disabled={!auth.isLoggedIn}
                          url={formatUrl(professional.facebook.url)}
                          icon={<Facebook />}
                        />
                      )}
                      {professional?.instagram?.url && (
                        <SocialNetworkButton
                          disabled={!auth.isLoggedIn}
                          url={formatUrl(professional.instagram.url)}
                          icon={<Instagram />}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={3}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: { paddingTop: 2 },
            })}>
            <Hidden mdDown>
              {professional?.isOwner && (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  alignItems="flex-start">
                  <IconButton
                    onClick={() => setOpenDialog(true)}
                    sx={{
                      padding: 0,
                      '&:hover': { background: 'transparent' },
                    }}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Hidden>
            {professional?.relatedInstitutions?.logo && (
              <Grid
                container
                columnGap={1}
                item
                onClick={handleClick}
                sx={(theme) => ({
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  [theme.breakpoints.down('md')]: {
                    justifyContent: 'flex-start',
                  },
                })}>
                <Paper
                  elevation={2}
                  sx={{
                    height: 'fit-content',
                  }}>
                  <Avatar
                    variant="square"
                    sx={{
                      width: '41px',
                      height: '41px',
                      '& > img': {
                        objectFit: 'contain',
                      },
                    }}
                    title={professional?.relatedInstitutions?.fantasyName}
                    src={
                      professional?.relatedInstitutions?.logo?.formats
                        ?.thumbnail?.url ?? defaultImage
                    }
                  />
                </Paper>
                <Typography
                  sx={(theme) => ({
                    fontSize: '14px',
                    color: '#1D1D1D',
                    fontWeight: 700,
                    maxWidth: 150,
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: 200,
                    },
                  })}>
                  {truncateText(
                    professional?.relatedInstitutions?.fantasyName,
                    30,
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <IntroductionForm
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          professional={professional}
          refetch={refetch}
        />
      </Box>
    </>
  );
}

export default Header;
