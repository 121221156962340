import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { CustomTooltip } from 'src/components/CustomTooltip';
import themev5 from 'src/themev5';
import { State } from 'src/types/radar';
import { hasChanged } from 'src/utils/validations';

import Charts from '../Charts';

export default function GridLayout({
  state,
  setState,
  setStateChanged,
  layouts,
  allowPersonalization,
}: {
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  setStateChanged: Dispatch<SetStateAction<boolean>>;
  layouts: MutableRefObject<any[] | undefined>;
  allowPersonalization: boolean;
}) {
  const breakpoints = themev5.breakpoints;
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const getDataGrid = (index: number, graphId: number) => {
    const graphWidth = 6;
    const maxColumns = 12;
    const itemsPerRow = maxColumns / graphWidth;
    const row = Math.floor(index / itemsPerRow);
    const column = (index % itemsPerRow) * (maxColumns / itemsPerRow);

    return {
      i: graphId,
      x: column,
      y: row * 3,
      w: graphWidth,
      h: 8,
      minW: 3,
      minH: 6,
    };
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      rowHeight={30}
      width={1200}
      breakpoints={breakpoints.values}
      cols={{ lg: 12, md: 9, sm: 6, xs: 3 }}
      isResizable={allowPersonalization}
      isDraggable={allowPersonalization}
      onLayoutChange={(current) => {
        if (!layouts.current?.length) {
          layouts.current = current;
        }
        state.layouts = current;
        const hasPersonalizationChanged = hasChanged(current, layouts.current);
        setStateChanged(hasPersonalizationChanged);
      }}>
      {state.dashboards?.map((dashboard, idx) => {
        const layout = state.layouts?.filter(
          (layout) => layout.i == dashboard.id,
        )[0];
        const dataGrid = layout ?? getDataGrid(idx, dashboard.id);

        return (
          <Stack
            key={dashboard.id}
            bgcolor={'#FFF'}
            borderRadius={2}
            p={2}
            pt={1.375}
            gap={2}
            data-grid={dataGrid}>
            <Stack
              direction="row"
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Stack direction="row" alignItems={'center'}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  lineHeight="19.6px"
                  padding={dashboard.description ? '0' : '6.5px 0'}
                  color={'#000'}>
                  {dashboard.name}
                </Typography>
                {dashboard.description && (
                  <CustomTooltip title={dashboard.description}>
                    <IconButton sx={{ color: '#4F4F4F' }}>
                      <InfoIcon sx={{ fontSize: '1.05rem' }} />
                    </IconButton>
                  </CustomTooltip>
                )}
              </Stack>
              {allowPersonalization && (
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    minWidth: '32px',
                    bgcolor: '#000',
                    color: '#FF8211',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setState((prev) => {
                      const newDashboards = prev.dashboards?.filter(
                        (s) => s.id != dashboard.id,
                      );
                      return { ...prev, dashboards: newDashboards };
                    });
                  }}>
                  <Typography fontWeight={700} fontSize={32}>
                    -
                  </Typography>
                </Box>
              )}
            </Stack>
            <Box height="100%" width="100%">
              <Charts dashboard={dashboard} />
            </Box>
          </Stack>
        );
      })}
    </ResponsiveGridLayout>
  );
}
