// Material ui components
import { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
// Apollo client
import { useQuery } from 'react-query';

// Global components
import Course from '../../../components/Course';
import MetaTags from '../../../components/Seo/MetaTags';
import SkeletonGrid from '../../../components/SkeletonGrid';
import Subheader from '../../../components/Subheader';
import { getInformativeTextByLocation } from '../../../services/informative';
import { getTrainings } from '../../../services/training';
import { ScrollToTop } from '../../../utils/scroll';

export default function AllTrainings() {
  const limit = 21;
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(0);
  const [pages, setPages] = useState(0);
  const [courses, setCourses] = useState([]);

  const { data } = useQuery('info', () =>
    getInformativeTextByLocation('training'),
  );

  const onChangePagination = (event, value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setFrom((page - 1) * limit);
  }, [page]);

  const { isLoading, error, refetch } = useQuery(
    'trainings',
    () => getTrainings({ from, limit }),
    {
      onSuccess: ({ data: data, meta }) => {
        setCourses(data);
        setPages(meta.pagination.total);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [from]);

  return (
    <ScrollToTop>
      <MetaTags
        title="Treinamentos | Mercados de Crédito e de Capitais"
        description="Precisando se atualizar? Encontre os principais treinamentos sobre diferentes temas dos mercados de crédito e de capitais."
      />
      <Box component="section">
        <Subheader
          title="Treinamento"
          breadcrumbs1="Treinamento"
          tooltipText={data?.data?.training?.informationText}
        />
        <Box py={2}>
          <Grid container spacing={1}>
            {isLoading ? (
              <SkeletonGrid
                height="21.7rem"
                width="auto"
                skeletonNumber={limit}
              />
            ) : error ? null : (
              courses.map(
                ({ id, title, previewImage, type, markets, slug, resume }) => (
                  <Grid item xs={12} sm={6} md={4} key={id}>
                    <Course
                      hashId={id}
                      title={title}
                      image={previewImage}
                      type={type}
                      markets={markets}
                      slug={slug}
                      resume={resume}
                    />
                  </Grid>
                ),
              )
            )}
          </Grid>
          <Box pt={3} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(pages / limit)}
              page={page}
              onChange={onChangePagination}
              shape="rounded"
              size="large"
            />
          </Box>
        </Box>
      </Box>
    </ScrollToTop>
  );
}
