import TabMui from '@material-ui/core/Tab';
import TabsMui from '@material-ui/core/Tabs';
import styled from 'styled-components';

type TabsProps = {
  noborder: boolean;
  nomarker: boolean;
};

export const Tabs = styled(TabsMui)<TabsProps>`
  z-index: 999;
  &.MuiTabs-root {
    & > .MuiTabs-scroller {
      & > .MuiTabs-flexContainer {
        border-bottom: ${(props) =>
          props.noborder ? 'none' : '1px solid rgba(0, 0, 0, 0.29)'};

        button {
          span {
            color: black;
            font-size: 24px;
          }

          &.Mui-selected {
            span {
              color: #ff8211;
            }
          }
        }
      }
    }
  }

  .MuiTabs-indicator {
    height: 5px;
    border-radius: 5px 5px 0 0;
    margin-left: 40px;
    max-width: 80px;
    opacity: ${(props) => (props.nomarker ? '0' : '1')};

    @media screen and (max-width: 599px) {
      margin-left: auto;
    }
  }
`;

export const Tab = styled(TabMui)`
  &.MuiTab-root {
    background-color: transparent;
    color: black;
    border: none;
  }
`;
