import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { createWidgetKey } from 'src/services/widget';
import { setBannerMessage } from 'src/store/display';

import { ValidateFormDialog } from '../WidgetManagement/validation';

function WidgetFormDialog({ onClose }: { onClose: () => void }) {
  const { reloadUser } = useAuthContext();
  const dispatch = useDispatch();
  const createRestrictions = useMutation(createWidgetKey, {
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'Domínio cadastrado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      reloadUser();
    },
    onError: () => {
      dispatch(
        setBannerMessage({
          message:
            'Ocorreu um erro durante o cadastro do domínio, tente novamente',
          type: 'error',
          autoclose: false,
        }),
      );
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: { domain: '' },
    validationSchema: ValidateFormDialog,
    onSubmit: (values) => {
      const restrictions = [];
      restrictions.push({
        domain: values.domain,
        __component: 'widget.website',
      });
      createRestrictions.mutate(restrictions);
    },
  });

  return (
    <Box>
      <Typography
        fontSize={24}
        fontWeight={700}
        textAlign="center"
        paddingBottom={7}>
        Adicionar widget
      </Typography>
      <Typography fontSize={14}>
        Incorpore o widget dos rankings Uqbar em qualquer site, informe abaixo o
        domínio da página que terá permissão para embutir o componente.
      </Typography>
      <Typography fontSize={14} paddingY={4}>
        Para adicionar mais domínios, acesse a seção “Widget” no menu de
        configurações.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          name="domain"
          placeholder="*.seudominio.com"
          value={formik.values.domain}
          onChange={formik.handleChange}
          error={formik.touched.domain && Boolean(formik.errors.domain)}
          helperText={formik.touched.domain && formik.errors.domain}
        />

        <Grid container marginTop={6} justifyContent="center" gap={2}>
          <Grid item>
            <Button type="submit" variant="contained">
              {createRestrictions.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="reset"
              variant="outlined"
              color="secondary"
              onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default WidgetFormDialog;
