import { Dispatch, MouseEvent, SetStateAction, useRef, useState } from 'react';

import { Delete } from '@mui/icons-material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FavoriteRadar, State } from 'src/types/radar';
import { useQueryParams } from 'src/utils/hooks';

import RadarFavoriteDialog from './RadarFavoriteDialog';
import RadarIcon from '../../../components/Icons/RadarIcon';

type Props = {
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  initialState: State;
  mode: 'indicadores' | 'assembleias' | 'dashboard';
  favoriteRadars?: FavoriteRadar[];
  loadingFavoriteRadar: boolean;
  selectedFavoriteRadar?: FavoriteRadar;
  setSelectedFavoriteRadar: Dispatch<SetStateAction<FavoriteRadar | undefined>>;
  handleOpenModal: () => void;
  setSelectedRemoveFavoriteRadar: Dispatch<
    SetStateAction<FavoriteRadar | undefined>
  >;
  stateChanged: boolean;
  disabled: boolean;
  refetchFavoriteRadar: any;
};

export default function RadarFavorite({
  state,
  setState,
  initialState,
  mode,
  favoriteRadars,
  loadingFavoriteRadar,
  selectedFavoriteRadar,
  setSelectedFavoriteRadar,
  handleOpenModal,
  setSelectedRemoveFavoriteRadar,
  stateChanged,
  disabled,
  refetchFavoriteRadar,
}: Props) {
  const [{ query }] = useQueryParams<{
    market: 'cri' | 'cra' | 'fii' | 'fidc';
    mode: 'indicadores' | 'assembleias' | 'dashboard';
  }>({ market: 'fidc', mode: 'indicadores' });

  const handleListItemClick = (item: FavoriteRadar) => {
    if (item.id !== selectedFavoriteRadar?.id) {
      setSelectedFavoriteRadar(item);
      setState({ ...state, ...item.data });
    }
  };

  const handleDeleteFavoriteRadar = (e: MouseEvent, item: FavoriteRadar) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedRemoveFavoriteRadar(item);
    handleOpenModal();
  };

  const [open, setOpen] = useState(false);
  const [openDialogSaveRadar, setOpenDialogSaveRadar] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleBack = () => {
    if (selectedFavoriteRadar) {
      setSelectedFavoriteRadar(undefined);
    }
    setState(initialState);
  };

  return (
    <>
      <CustomTooltip
        title={
          disabled
            ? 'Para acessar o radar é necessário ter ao menos um filtro salvo'
            : ''
        }
        placement="top">
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="radar favorito"
          disableElevation
          size={'small'}
          disabled={disabled}
          disableFocusRipple={disabled}
          disableRipple={disabled}
          sx={(theme) => ({
            '& .MuiButtonBase-root.MuiButton-root': {
              height: '100%',
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 700,
              backgroundColor: 'transparent',
              border: 'none !important',
              color:
                selectedFavoriteRadar || stateChanged
                  ? theme.palette.primary.main
                  : '#333',
              '& .MuiButton-startIcon > .MuiSvgIcon-root': {
                fontSize: '1.745rem !important',
              },
            },
            '& .Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26) !important',
            },
          })}>
          <Button
            disabled={disabled}
            disableFocusRipple={disabled}
            disableRipple={disabled}
            sx={(theme) => ({
              ...(stateChanged && {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.primary.contrastText} !important`,
              }),
            })}
            startIcon={<RadarIcon style={{ marginTop: '8px' }} />}
            onClick={
              stateChanged ? () => setOpenDialogSaveRadar(true) : handleToggle
            }>
            {stateChanged
              ? 'Salvar parâmetros'
              : selectedFavoriteRadar
                ? selectedFavoriteRadar.name
                : 'Meu radar'}
          </Button>
          <Button
            disabled={disabled}
            disableFocusRipple={disabled}
            disableRipple={disabled}
            size="small"
            aria-controls={open ? 'radar-favorito-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="Selecionar radar favorito"
            aria-haspopup="menu"
            sx={{ px: 0, color: '#333 !important' }}
            onClick={handleToggle}>
            <ExpandMoreRoundedIcon
              sx={{ transform: open ? 'rotate(180deg)' : 'unset' }}
            />
          </Button>
          <Popper
            sx={{
              zIndex: 1,
              maxWidth: '32ch',
              width: 'auto',
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-start">
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper
                  sx={{
                    minWidth: '25ch',
                  }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="split-button-menu"
                      autoFocusItem
                      variant="selectedMenu"
                      onClick={() => handleClose()}
                      sx={{
                        mt: '2px',
                        p: 0,
                        maxHeight: '35dvh',
                        overflowY: 'auto',
                      }}>
                      {!loadingFavoriteRadar &&
                        favoriteRadars?.map((radar) => (
                          <ListItemButton
                            key={radar.id}
                            selected={selectedFavoriteRadar?.id === radar.id}
                            component={Link}
                            to={`/radar?market=${query?.market?.toLowerCase()}&mode=${mode}&favoriteId=${
                              radar.id
                            }`}
                            onClick={() => handleListItemClick(radar)}
                            sx={(theme) => ({
                              '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected .MuiSvgIcon-root':
                                {
                                  backgroundColor: theme.palette.primary.main,
                                  color: '#FFF',
                                },
                              '&.Mui-selected .MuiSvgIcon-root': {
                                backgroundColor: 'transparent',
                              },
                            })}>
                            <ListItemText
                              primary={radar.name}
                              sx={{
                                marginRight: '20px',
                                '& .MuiTypography-body1': {
                                  fontSize: 14,
                                  fontWeight: 700,
                                },
                              }}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={(e) =>
                                  handleDeleteFavoriteRadar(e, radar)
                                }
                                edge="end"
                                aria-label="delete">
                                <Delete sx={{ fontSize: '1rem' }} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ))}
                    </MenuList>
                  </ClickAwayListener>
                  {(selectedFavoriteRadar || stateChanged) && (
                    <div>
                      <Divider aria-hidden="true" />
                      <ListItemButton
                        onClick={handleBack}
                        component={Link}
                        to={`/radar?market=${query.market}&mode=${mode}`}
                        sx={(theme) => ({
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: 'center',
                          color: theme.palette.secondary.main,
                          '&:hover': {
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                          },
                        })}>
                        <ListItemText disableTypography>
                          Restaurar visão geral
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  )}
                </Paper>
              </Grow>
            )}
          </Popper>
        </ButtonGroup>
      </CustomTooltip>
      <RadarFavoriteDialog
        state={state}
        mode={mode}
        loading={loadingFavoriteRadar}
        openDialog={openDialogSaveRadar}
        setOpenDialog={setOpenDialogSaveRadar}
        refetchFavoriteRadar={refetchFavoriteRadar}
      />
    </>
  );
}
