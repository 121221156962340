/* eslint-disable react/prop-types */
import React from 'react';

import { Box } from '@material-ui/core';

import { Container } from './styles';
import LoadSecurityAction from '../LoadSecurityAction';
import NewSecurityAction from '../NewSecurityAction';
import ResetSecurityAction from '../ResetSecurityAction';
import SimulateSecurityAction from '../SimulateSecurityAction';

const ActionButtons = ({ formik }) => {
  return (
    <Container>
      <div className="menu">
        <Box display="flex" justifyContent="space-between" width="100%">
          <ResetSecurityAction />
          <NewSecurityAction security={formik.security} />
          <LoadSecurityAction formik={formik} />
          <SimulateSecurityAction security={formik.security} />
        </Box>
      </div>
    </Container>
  );
};

export default ActionButtons;
