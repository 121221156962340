import { Dispatch, SetStateAction, useState } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { EventFilter } from 'src/types/event';

import FilterList from './FilterList';

type Props = {
  query: EventFilter;
  setQuery: (nextParams: any, method?: 'push' | 'remove' | undefined) => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export default function HeaderEvents({
  setQuery,
  query,
  setOpenDialog,
}: Props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const handleChange = (event: any, key: keyof EventFilter) => {
    if (key === 'favorite' && query[key]) return handleClear(key);

    const value = event?.target?.value ?? event;

    setQuery({
      ...query,
      [key]: key === 'favorite' ? Boolean(value) : value,
    });
  };

  const handleClear = (key: keyof EventFilter, value?: string) => {
    setQuery({
      ...query,
      [key]: value ?? null,
    });
  };

  return (
    <Stack
      direction={'row'}
      justifyContent={'start'}
      alignItems="center"
      height={'72px'}
      pl={'clamp(0px, 1vw, 16px)'}
      pr={'clamp(12px, 3vw, 30px)'}
      boxShadow={'0px 5px 6px 0px #E5E5E580'}>
      <Select
        name="event"
        value={query.eventState}
        onChange={(e) => handleChange(e, 'eventState')}
        displayEmpty
        inputProps={{ 'aria-label': 'eventos' }}
        sx={(theme) => ({
          '&': {
            color: theme.palette.secondary.main,
            ...theme.typography.h5,
            lineHeight: 'normal',
            marginRight: 'clamp(10px, 2.75vw, 58px)',
            fontSize: 'clamp(16px, 2.75vw, 24px)',
            [theme.breakpoints.down('md')]: {
              marginRight: '0',
            },
          },
          '& fieldset': {
            border: 'none',
          },
          '& .MuiSvgIcon-root': {
            fill: '#47484C',
            fontSize: '1.745rem',
            right: 0,
            top: 'clamp(15px, 2vw, 18.5px)',
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
        })}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: (theme) => ({
            '& li:hover': {
              backgroundColor: `${theme.palette.primary.main} !important`,
              color: '#FFF',
            },
            '& .Mui-selected': {
              backgroundColor: 'unset !important',
            },
          }),
        }}
        IconComponent={ExpandMoreRoundedIcon}>
        <MenuItem value={'upcoming'}>Próximos eventos</MenuItem>
        <MenuItem value={'ongoing'}>Eventos em andamento</MenuItem>
        <MenuItem value={'ended'}>Eventos encerrados</MenuItem>
      </Select>
      <Drawer
        anchor={'right'}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ zIndex: 1300 }}>
        <Box width={'min(90dvw, 380px)'}>
          <List
            sx={{
              pl: 'clamp(15px, 4.5vw, 30px)',
              pt: '26px',
              '& > li > * > *': {
                padding: 0,
                mb: '37px !important',
              },
              '& .MuiButton-startIcon': {
                marginRight: '5px',
                marginLeft: '0px',
                '& svg': {
                  fontSize: '1.5rem',
                  width: '1em',
                  height: '1em',
                  display: 'inline-block',
                },
              },
            }}>
            <ListItem disablePadding>
              <Box>
                <Button
                  startIcon={
                    <FilterListIcon color="secondary" fontSize="large" />
                  }
                  onClick={() => setOpenDrawer(false)}
                  color="inherit"
                  sx={{
                    display: 'inline-flex',
                    color: '#47484C',
                    textTransform: 'none',
                    letterSpacing: 'unset',
                    fontSize: '14px',
                    fontWeight: 600,
                    padding: 0,
                    minWidth: 0,
                    height: 'unset',
                  }}>
                  Filtros
                </Button>
              </Box>
            </ListItem>
            <ListItem disablePadding>
              <FilterList
                query={query}
                setOpenDialog={setOpenDialog}
                setQuery={setQuery}
                handleChange={handleChange}
                handleClear={handleClear}
                isMobile={true}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      {!isMobile && (
        <FilterList
          query={query}
          setOpenDialog={setOpenDialog}
          setQuery={setQuery}
          handleChange={handleChange}
          handleClear={handleClear}
          isMobile={false}
        />
      )}
      <Button
        startIcon={<FilterListIcon color="primary" fontSize="large" />}
        onClick={() => setOpenDrawer(true)}
        color="inherit"
        sx={{
          display: isMobile ? 'inline-flex' : 'none',
          color: '#47484C',
          textTransform: 'none',
          letterSpacing: 'unset',
          fontSize: '14px',
          fontWeight: 600,
          padding: 0,
          marginLeft: 'auto !important',
          minWidth: 0,
        }}>
        Filtros
      </Button>
    </Stack>
  );
}
