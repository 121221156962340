import qs from 'qs';

import { api } from '../../api/api';

export const findFavoriteByType = async ({
  article,
  operation,
  institution,
  professional,
  service,
  event,
  presentation,
}: {
  article: string;
  operation: string;
  institution: string;
  professional: string;
  service: string;
  event: string;
  presentation: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        article,
        operation: {
          hashId: operation,
        },
        institution,
        professional,
        service,
        event,
        presentation,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/favorites?${query}`);
  return data;
};

export const findAllFavorites = async ({
  page,
  pageSize,
  sort,
}: {
  page: number;
  pageSize: number;
  sort: string[];
}) => {
  const query = qs.stringify(
    {
      filters: {
        presentation: {
          id: {
            $null: true,
          },
        },
      },
      pagination: {
        page,
        pageSize,
      },
      sort,
      populate: [
        'article',
        'operation',
        'operation.market',
        'professional',
        'institution',
        'service',
        'event',
      ],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/favorites?${query}`);
  return data;
};

export const addFavorite = async (values: any) => {
  const { data } = await api.post('/favorites', values);
  return data;
};

export const removeFavorite = async (id: number) => {
  const { data } = await api.delete(`/favorites/${id}`);
  return data;
};

export const findFavoriteByOperationMarket = async (market: string) => {
  const query = qs.stringify(
    {
      filters: {
        operation: {
          market: {
            name: market,
          },
        },
      },
      populate: 'operation',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/favorites?${query}`);
  return data;
};
