// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

// Global components
import GeneralDataItemConditional from '../../../../components/GeneralDataItemConditional';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_GENERAL_DATA_BY_OPERATION_HASH_ID } from '../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
}));

export default function OperationFIDCGeneralData({ hash }) {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_DETAILS_FIDC_GENERAL_DATA_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_dados_gerais'
      ],
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={2}>
      <Subtitle title="Dados Gerais" />
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_dados_gerais'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fidic_dados_gerais'
            ]
          }>
          <Grid container>
            <Grid item xs>
              <Box p={2}>
                <Typography variant="subtitle1" className={classes.title}>
                  CNPJ
                </Typography>
                <Typography variant="subtitle2">
                  {
                    data?.getDetailsFidcGeneralDataByOperationHashId
                      ?.operationCnpj
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box p={2}>
                <Typography variant="subtitle1" className={classes.title}>
                  Tipo de Condomínio
                </Typography>
                <Typography variant="subtitle2">
                  {
                    data?.getDetailsFidcGeneralDataByOperationHashId
                      ?.operationCondominiumType
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box p={2}>
                <Typography variant="subtitle1" className={classes.title}>
                  Prazo Mínimo de Resgate
                </Typography>
                <Typography variant="subtitle2">
                  {
                    data?.getDetailsFidcGeneralDataByOperationHashId
                      ?.operationMinimumRedemptionTerm
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <GeneralDataItemConditional
                title="Multiseries"
                condition={
                  data?.getDetailsFidcGeneralDataByOperationHashId
                    ?.isMultiseries
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <GeneralDataItemConditional
                title="Cotas Negociáveis"
                condition={
                  data?.getDetailsFidcGeneralDataByOperationHashId
                    ?.isStockListed
                }
              />
            </Grid>
            <Grid item xs>
              <GeneralDataItemConditional
                title="Fundo Exclusivo"
                condition={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getDetailsFidcGeneralDataByOperationHashId
                          ?.isExclusiveFund
                }
              />
            </Grid>
            <Grid item xs>
              <GeneralDataItemConditional
                title="Cotistas Vinculados"
                condition={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getDetailsFidcGeneralDataByOperationHashId
                          ?.isLinkedShareholders
                }
              />
            </Grid>
            <Grid item xs>
              <GeneralDataItemConditional
                title="Não-Padronizado"
                condition={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getDetailsFidcGeneralDataByOperationHashId?.isNP
                }
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
