import styled from 'styled-components';

import { Tabs } from './components/Tab/styles';

export const PresentationWrapper = styled.div`
  position: relative;
`;

export const PayWallBannerWrapper = styled.div`
  position: absolute;
  min-width: 1000px;
  top: 36%;
  left: 28%;
  z-index: 999;
`;

export const Liner = styled.div`
  position: fixed;
  top: 50vh;
  width: 100vw;
  height: 1px;
`;

type WrapperProps = {
  blured: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  filter: blur(${(props) => (props.blured ? '20px' : '0')});
`;

type PropsTabWrapper = {
  hide: boolean;
};

export const TabWrapper = styled.div<PropsTabWrapper>`
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  background-color: white;
  width: 100%;
  justify-content: flex-end;

  ${Tabs} {
    &.MuiTabs-root {
      & > .MuiTabs-scroller {
        & > .MuiTabs-flexContainer {
          button {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    top: 30px;

    justify-content: center;
  }
`;
