import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Stack, useTheme } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { deleteFavoriteRadar, getFavoriteRadar } from 'src/services/radar';
import {
  FavoriteRadar,
  Indicator,
  RadarSegments,
  State,
} from 'src/types/radar';
import { hasChanged } from 'src/utils/validations';

import IndicatorDate from './IndicatorDate';
import IndicatorSegments from './IndicatorSegments';
import IndicatorServiceProviders from './IndicatorServiceProviders';
import IndicatorType from './IndicatorType';
import RadarFavorite from '../../RadarFavorite';
import { RadarStyles } from '../../styles';

export default function IndicatorsFilters({
  query,
  INITIAL_STATE,
  state,
  setState,
  filters,
  indicators,
  isLoadingIndicators,
  isLoadingSegments,
  refetchIndicators,
  radarMutation,
  arrayDeadlineOrder,
  hasPermission,
  alert,
}: {
  query: any;
  INITIAL_STATE: State;
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  filters?: RadarSegments;
  indicators?: Indicator[];
  refetchIndicators: any;
  isLoadingIndicators: boolean;
  isLoadingSegments: boolean;
  radarMutation: any;
  arrayDeadlineOrder: string[];
  hasPermission: boolean;
  alert: boolean;
}) {
  const theme = useTheme();
  const markets = ['cri', 'cra', 'fii', 'fidc'];
  const classes = RadarStyles(theme);
  const { auth } = useAuthContext();

  const [stateChanged, setStateChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [favoriteRadars, setFavoriteRadars] = useState<FavoriteRadar[]>();
  const [selectedFavoriteRadar, setSelectedFavoriteRadar] =
    useState<FavoriteRadar>();
  const [selectedRemoveFavoriteRadar, setSelectedRemoveFavoriteRadar] =
    useState<FavoriteRadar>();

  const { isLoading: isLoadingRadar } = radarMutation;
  const { isLoading: isLoadingFavoriteRadar, refetch: refetchFavoriteRadar } =
    useQuery(
      'favoriteRadar',
      () => getFavoriteRadar(query.market, query.mode),
      {
        onSuccess: ({ data }: { data: FavoriteRadar[] }) => {
          setFavoriteRadars(data);
        },
        enabled: hasPermission && markets.includes(query.market),
      },
    );

  const removeFavoriteRadar = useMutation(
    (value: number) => deleteFavoriteRadar(value),
    {
      onSuccess: () => {
        refetchFavoriteRadar();
      },
    },
  );

  useEffect(() => {
    setSelectedFavoriteRadar(undefined);
    refetchFavoriteRadar();
    refetchIndicators();
  }, [query.market]);

  useEffect(() => {
    const keysToIgnore = [
      'dateStart',
      'dateEnd',
      ...(query.market !== 'fidc' ? ['serviceProviders'] : []),
    ];
    !selectedFavoriteRadar
      ? setStateChanged(hasChanged(INITIAL_STATE, state, keysToIgnore))
      : setStateChanged(
          hasChanged(selectedFavoriteRadar.data, state, keysToIgnore),
        );
  }, [state, hasPermission, selectedFavoriteRadar]);

  useEffect(() => {
    if (!query.favoriteId || !favoriteRadars?.length) {
      return setState(INITIAL_STATE);
    }
    const favoriteRadar = favoriteRadars.find(
      (radar) => radar.id === Number(query?.favoriteId),
    );
    const newValue = { ...INITIAL_STATE, ...favoriteRadar?.data };
    setState(newValue);
    if (favoriteRadar) {
      setSelectedFavoriteRadar({
        ...favoriteRadar,
        data: newValue,
      });
      radarMutation.mutate({ ...newValue, market: query.market });
    }
  }, [query?.favoriteId, favoriteRadars, filters]);

  const handleOpenModal = () => {
    setOpenDialog((prevState) => !prevState);
  };

  const handleDeleteFavoriteRadar = () => {
    setSelectedFavoriteRadar(undefined);
    if (selectedRemoveFavoriteRadar?.id) {
      removeFavoriteRadar.mutate(selectedRemoveFavoriteRadar.id);
      setOpenDialog(false);
    }
    if (filters) {
      setState({
        ...state,
        segments: Object.entries(filters).reduce((acc, curr) => {
          return {
            ...acc,
            [curr[0]]: curr[1].options,
          };
        }, {}),
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack
      direction={'row'}
      bgcolor={'#FFF'}
      gap={2}
      alignItems={'center'}
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        pb: 0.625,
        '&::-webkit-scrollbar': {
          height: 10,
          width: 8,
          WebkitAppearance: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: theme.palette.grey['400'],
        },
        '& > *': {
          fontWeight: '700 !important',
          fontSize: '14px !important',
          paddingInline: '0 !important',
          boxShadow: '0px 4px 4px 0px #00000026 !important',
          height: '48px !important',
          margin: '0 !important',
          backgroundColor: 'transparent !important',
          minWidth: 'fit-content !important',
          '.MuiSvgIcon-root': {
            fontSize: '20px !important',
          },
        },
        '& > .MuiButtonBase-root': {
          padding: '16px 8px 16px 16px !important',
        },
      }}>
      <RadarFavorite
        state={state}
        setState={setState}
        mode={query.mode}
        initialState={INITIAL_STATE}
        favoriteRadars={favoriteRadars}
        loadingFavoriteRadar={isLoadingFavoriteRadar}
        selectedFavoriteRadar={selectedFavoriteRadar}
        setSelectedFavoriteRadar={setSelectedFavoriteRadar}
        handleOpenModal={handleOpenModal}
        setSelectedRemoveFavoriteRadar={setSelectedRemoveFavoriteRadar}
        stateChanged={stateChanged}
        disabled={
          !hasPermission ||
          alert ||
          (!stateChanged && favoriteRadars?.length === 0)
        }
        refetchFavoriteRadar={refetchFavoriteRadar}
      />
      <IndicatorType
        indicator={state.indicator as string}
        indicators={indicators}
        setState={setState}
        disabled={!hasPermission}
        isLoading={isLoadingIndicators}
      />
      <IndicatorDate
        classes={classes}
        setState={setState}
        disabled={!hasPermission}
        plan={auth?.user?.subscription?.plan}
      />
      <IndicatorSegments
        state={state}
        setState={setState}
        arrayDeadlineOrder={arrayDeadlineOrder}
        isLoading={isLoadingRadar || isLoadingSegments}
        filters={filters}
        classes={classes}
        disabled={!hasPermission}
      />
      {query.market === 'fidc' && state.serviceProviders && (
        <IndicatorServiceProviders
          classes={classes}
          disabled={!hasPermission}
          setState={setState}
          state={state as State & Required<Pick<State, 'serviceProviders'>>}
        />
      )}
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={`Tem certeza que deseja excluir o filtro ${selectedRemoveFavoriteRadar?.name}?`}
        subTitle={''}
        submitAction={handleDeleteFavoriteRadar}
        hasActionButton={Boolean(handleDeleteFavoriteRadar)}
      />
    </Stack>
  );
}
