import { Dispatch, SetStateAction, useState } from 'react';

import { DiamondOutlined, ExpandMoreRounded } from '@mui/icons-material';
import {
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  addMonths,
  format,
  monthsToYears,
  parseISO,
  startOfYear,
  subYears,
} from 'date-fns';
import { useHistory } from 'react-router-dom';
import { State } from 'src/types/radar';
import { Plan } from 'src/types/user';

type Props = {
  classes: any;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
  plan?: Plan | undefined;
};

export default function IndicatorDate({
  classes,
  setState,
  disabled,
  plan,
}: Props) {
  const [period, setPeriod] = useState('last');
  const history = useHistory();

  const getYearPeriodSelectByPlan = (): Array<{
    year: number;
    needUpgrade: boolean;
  }> => {
    const defaultPeriod = 60; // 5 years
    const monthPeriod = plan?.availableDataPeriod ?? defaultPeriod;
    const totalYears = monthsToYears(defaultPeriod);
    const planYears = monthsToYears(monthPeriod);

    const createYearArray = (
      start: number,
      end: number,
      needUpgrade: boolean,
    ) =>
      Array.from({ length: end - start + 1 }, (_, i) => ({
        year: start + i,
        needUpgrade,
      }));

    const yearsWithPlan = createYearArray(1, planYears, false);
    const yearsWithoutPlan = createYearArray(planYears + 1, totalYears, true);

    return [...yearsWithPlan, ...yearsWithoutPlan];
  };

  const handlePeriodChange = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    const foundPeriod = getYearPeriodSelectByPlan().find(
      (period) =>
        format(subYears(new Date(), period.year), 'yyyy') === selected,
    );

    if (foundPeriod?.needUpgrade) {
      history.push('/planos');
    }

    if (selected) {
      setPeriod(selected);
    }

    setPeriod(selected);
    let dateStart: string;
    let dateEnd: string;
    if (selected !== 'last') {
      const date = startOfYear(parseISO(selected));
      dateStart = date.toJSON();
      dateEnd = addMonths(date, 11).toJSON();
    }
    setState((prevState) => ({
      ...prevState,
      dateStart,
      dateEnd,
    }));
  };

  return (
    <Select
      value={period}
      name="period"
      onChange={handlePeriodChange}
      className={classes.select}
      inputProps={{ 'aria-label': 'Período' }}
      IconComponent={ExpandMoreRounded}
      renderValue={(value) =>
        `Período: ${value === 'last' ? 'Últimos 12 meses' : value}`
      }
      sx={(theme) => ({
        '&': {
          color: period ? theme.palette.primary.main : '#4F4F4F',
        },
        '& fieldset': {
          border: 'none',
        },
        '& .MuiSelect-select': {
          padding: '16px !important',
          pr: '32px !important',
        },
        '& .MuiSvgIcon-root': {
          fill: disabled
            ? undefined
            : period
              ? theme.palette.primary.main
              : '#4F4F4F',
          right: '4px',
        },
      })}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: (theme) => ({
          '& li:hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
          '& li:hover > div': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
          '& li:hover .MuiTypography-root': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
          '& li:hover .MuiSvgIcon-root': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
          '& .Mui-selected': {
            backgroundColor: 'unset !important',
          },
        }),
      }}
      disabled={disabled}>
      <MenuItem key="twelve" value="last" className={classes.select}>
        Últimos 12 meses
      </MenuItem>
      {getYearPeriodSelectByPlan().map((value) => {
        const year = format(subYears(new Date(), value.year), 'yyyy');
        return (
          <MenuItem
            key={year}
            value={year}
            className={classes.select}
            style={{ gap: '4px' }}>
            {year}
            <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
              {value.needUpgrade && (
                <>
                  <Typography color="text.primary">- Upgrade</Typography>
                  <DiamondOutlined
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                    })}
                    fontSize="small"
                  />
                </>
              )}
            </ListItemIcon>
          </MenuItem>
        );
      })}
    </Select>
  );
}
