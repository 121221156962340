import { useEffect, useState } from 'react';

import { Box, Paper } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';

import DocumentsHeader from './DocumentsHeader';
import DocumentsTable from './DocumentsTable';
import { AllDocumentsStyle } from './styles';
import MetaTags from '../../components/Seo/MetaTags';
import SubHeaderWithMarkets from '../../components/SubHeaderWithMarkets';
import { useAuthContext } from '../../context/AuthContextProvider';
import { filterDocuments, getDocumentOptions } from '../../services/elastic';
import { getInformativeTextByLocation } from '../../services/informative';

const INITIAL_VALUES = {
  market: 'FIDC',
  type: [],
  dateRange: {
    start: null,
    end: null,
  },
};

export default function AllDocuments() {
  const { auth } = useAuthContext();
  const classes = AllDocumentsStyle();
  const [filters, setFilters] = useState(INITIAL_VALUES);
  const [from, setFrom] = useState(0);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const markets = ['FIDC', 'CRI', 'CRA', 'FII'];
  const { data: infoData } = useQuery('info', () =>
    getInformativeTextByLocation('documents'),
  );
  const [data, setData] = useState([]);

  const { data: options, isLoading: optionsLoading } = useQuery(
    ['options'],
    () => getDocumentOptions(),
    {
      enabled: auth.isLoggedIn,
    },
  );

  const filteredDocuments = useMutation(filterDocuments, {
    onSuccess: ({ data }) => {
      setData(data.data);
      setTotal(data.total);
    },
  });

  useEffect(() => {
    if (!auth.isLoading) {
      filteredDocuments.mutate({
        data: { filters, from, size, userId: auth?.user?.id },
      });
    }
  }, [filters, from, size, auth]);

  const handleSelectMarket = (market) => {
    setFilters({
      ...INITIAL_VALUES,
      market,
    });
  };

  return (
    <Box>
      <MetaTags
        title="Central de documentos"
        description="Seção dedicada a disponibilização dos documentos das operações de FIDC, CRI, CRA e FII."
      />
      <SubHeaderWithMarkets
        title="Central de documentos"
        state={filters}
        setState={handleSelectMarket}
        selectedMarket={filters?.market}
        markets={markets}
        tooltipText={infoData?.data?.documents?.informationText}
      />
      <Paper className={classes.paper}>
        <DocumentsHeader
          classes={classes}
          options={options}
          loadingOptions={optionsLoading}
          setFilters={setFilters}
          filters={filters}
        />
        <DocumentsTable
          classes={classes}
          rows={data}
          total={total}
          setFrom={setFrom}
          size={size}
          setSize={setSize}
          loading={filteredDocuments.isLoading}
        />
      </Paper>
    </Box>
  );
}
