// Material ui components
import { Grid, Typography } from '@mui/material';
import { CustomTooltip } from 'src/components/CustomTooltip';

import * as S from './styles';
import SubtitleLink from '../../../../components/SubtitleLink';

type Props = {
  ranking:
    | {
        label: string;
        market: string;
        month: number;
        valueLabel: string;
        criteria: string;
        year: number;
        timeRange: string;
        type: string;
        ranks: Array<{
          id: number;
          institutionId: number;
          operationId: number | null;
          slug: string;
          name: string;
          position: number;
          value: number;
          percentage: number;
          uqbarId: number;
        }>;
      }
    | undefined;
};
const RankingHeader = (props: Props) => {
  return (
    <Grid container>
      <SubtitleLink
        title="Ranking Uqbar"
        url={`/rankings?market=${props.ranking?.market}&year=${props.ranking?.year}&ranking=${props.ranking?.type}&criteria=${props.ranking?.criteria}&timeRange=${props.ranking?.timeRange}`}
        labelLink="Ver todos os rankings"
      />

      <S.DetailsWrapper item xs={12} sm md={6} lg={6}>
        <S.TitleWrapper>
          <S.Title variant="h3">{props.ranking?.label}</S.Title>
        </S.TitleWrapper>
        <S.Details>
          <CustomTooltip title="Mercado">
            <Typography>{props.ranking?.market?.toUpperCase()}</Typography>
          </CustomTooltip>
          <CustomTooltip title="Critério">
            <Typography>{props.ranking?.valueLabel}</Typography>
          </CustomTooltip>
          <CustomTooltip title="Período">
            <Typography>{props.ranking?.year}</Typography>
          </CustomTooltip>
          <CustomTooltip title="Visão">
            <Typography>
              {props.ranking?.timeRange === 'yearly' ? 'Anual' : 'Consolidado'}
            </Typography>
          </CustomTooltip>
        </S.Details>
      </S.DetailsWrapper>
    </Grid>
  );
};

export default RankingHeader;
