import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FieldArrayRenderProps } from 'formik';
import { isEmpty } from 'lodash';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';

import * as S from '../../style';

const ExperienceForm = ({
  open,
  onClose,
  formik,
  index,
  arrayHelpers,
  actionType,
  handleUpdate,
}: {
  open: boolean;
  onClose: () => void;
  formik: any;
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  actionType: 'create' | 'update' | 'delete' | undefined;
  handleUpdate: (values: any) => void;
}) => {
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const handleCancel = () => {
    if (actionType === 'create') {
      arrayHelpers.pop();
    } else if (actionType === 'update') {
      formik.handleReset();
    }
    onClose();
  };

  const handleSave = async () => {
    const validationResult = await formik.validateForm();

    if (isEmpty(validationResult)) {
      handleUpdate(formik.values.experiences);
      onClose();
    }
  };

  const getErrors = <T extends { [key: string]: any }>(
    fieldName: keyof T,
    index: number,
  ) => {
    const error = formik.errors.experiences?.[index] as T | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{
        maxWidth: 750,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <DialogContent
        sx={(theme) => ({
          padding: '40px 56px 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '20px 24px',
          },
        })}>
        <Typography fontSize={18} fontWeight={700} mb={3} textAlign="center">
          Experiência profissional
        </Typography>
        <Grid
          container
          spacing={3}
          sx={(theme) => ({ padding: theme.spacing(2) })}>
          <Grid item xs={12} md={12}>
            <S.FieldTitle>Nome da empresa*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              <TextField
                name={`experiences[${index}].companyName`}
                value={formik.values.experiences[index].companyName}
                onChange={formik.handleChange}
                placeholder="Ex: Bradesco"
                error={Boolean(
                  getErrors<{ companyName: string }>('companyName', index),
                )}
                helperText={getErrors<{ companyName: string }>(
                  'companyName',
                  index,
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <S.FieldTitle>Título*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              <TextField
                name={`experiences[${index}].professionalTitle`}
                value={formik.values.experiences[index].professionalTitle}
                onChange={formik.handleChange}
                placeholder="Ex: Gerente investimentos financeiro"
                error={Boolean(
                  getErrors<{ professionalTitle: string }>(
                    'professionalTitle',
                    index,
                  ),
                )}
                helperText={getErrors<{ professionalTitle: string }>(
                  'professionalTitle',
                  index,
                )}
              />
            </FormControl>
          </Grid>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}>
            <Grid item xs={12} md={6}>
              <S.FieldTitle>Data de início*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  views={['month', 'year']}
                  format="MM/yyyy"
                  maxDate={new Date()}
                  slotProps={{
                    textField: {
                      name: `experiences[${index}].startDate`,
                      placeholder: 'MM/AAAA',
                      error: Boolean(
                        getErrors<{ startDate: string }>('startDate', index),
                      ),
                    },
                  }}
                  onChange={(value) => {
                    formik.setFieldValue(
                      `experiences[${index}].startDate`,
                      value && isValid(value)
                        ? format(value, 'yyyy-MM-dd')
                        : value,
                    );
                  }}
                  value={
                    formik.values.experiences[index].startDate &&
                    parseISO(formik.values.experiences[index].startDate)
                  }
                />

                {Boolean(
                  getErrors<{ startDate: string }>('startDate', index),
                ) && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {getErrors<{ startDate: string }>('startDate', index)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <S.FieldTitle>Data de término*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  views={['month', 'year']}
                  format="MM/yyyy"
                  disabled={!formik.values.experiences[index].startDate}
                  maxDate={new Date()}
                  minDate={
                    formik.values.experiences[index].startDate
                      ? parseISO(formik.values.experiences[index].startDate)
                      : undefined
                  }
                  slotProps={{
                    textField: {
                      name: `experiences[${index}].endDate`,
                      placeholder: 'MM/AAAA',
                      error: Boolean(
                        getErrors<{ endDate: string }>('endDate', index),
                      ),
                    },
                  }}
                  onChange={(value) => {
                    formik.setFieldValue(
                      `experiences[${index}].endDate`,
                      value && isValid(value)
                        ? format(value, 'yyyy-MM-dd')
                        : value,
                    );
                  }}
                  value={
                    formik.values.experiences[index].endDate
                      ? parseISO(formik.values.experiences[index].endDate)
                      : undefined
                  }
                />

                {Boolean(getErrors<{ endDate: string }>('endDate', index)) && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {getErrors<{ endDate: string }>('endDate', index)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </LocalizationProvider>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          gap: '28px',
          justifyContent: 'center',
          padding: '16px 84px 37px 72px',
          flexWrap: 'wrap',
          [theme.breakpoints.down('sm')]: {
            padding: '0 52px 40px 40px',
          },
          '& > button': {
            [theme.breakpoints.down('sm')]: {
              minWidth: '100%',
              marginLeft: '0 !important',
            },
          },
        })}>
        <Button variant="contained" onClick={handleSave}>
          Salvar
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpenLeaveDialog(true)}>
          Cancelar
        </Button>
      </DialogActions>
      <ConfirmLeaveDialog
        title={`Tem certeza que deseja fechar a ${
          actionType === 'create' ? 'adição' : 'edição'
        } de experiência?`}
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleCancel}
        labelButton={actionType === 'create' ? 'Continuar' : 'Continuar edição'}
      />
    </Dialog>
  );
};

export default ExperienceForm;
