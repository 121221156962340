import {
  Download,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionsProps,
  CardContent,
  CardMedia,
  CardProps,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import { CustomTooltip } from 'src/components/CustomTooltip';
import Section from 'src/components/ProfileSection';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Institution } from 'src/types/institution';
import styled from 'styled-components';

type Props = {
  institution: Institution;
};

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
  width: 242,
  height: 303,
  border: '1px solid #E0E0E0',
  borderRadius: '5px',
  boxShadow: 'none',
  position: 'relative',
  '& .download-area': {
    display: 'none',
  },
  '&:hover .download-area': {
    display: 'flex',
  },
}));

const CustomCardActions = styled(Card)<CardActionsProps>(() => ({
  width: '100%',
  height: '100%',
  padding: 0,
  backgroundColor: 'rgb(29 29 29 / 70%)',
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function Documents({ institution }: Props) {
  const { auth } = useAuthContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const renderPrevButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowLeft />
      </IconButton>
    );
  };

  const renderNextButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowRight />
      </IconButton>
    );
  };

  const responsive = {
    0: { items: 1 },
    650: { items: 2 },
    980: { items: 3 },
    1150: { items: 4 },
  };

  return (
    <Section title="Documentos">
      <Box
        sx={(theme) => ({
          width: '100%',
          overflow: 'hidden',
          '& > div': {
            padding: 0,
          },
          '& .alice-carousel > div': {
            padding: '0 54px',
            [theme.breakpoints.down('sm')]: {
              padding: '0 20px',
            },
          },
          '& .alice-carousel__stage-item': {
            maxWidth: '242px',
            marginRight: '17px',
          },
          '& .alice-carousel__prev-btn': {
            position: 'absolute',
            top: '50%',
            padding: 0,
          },
          '& .alice-carousel__next-btn': {
            position: 'absolute',
            top: '50%',
            right: 0,
            padding: 0,
          },
        })}>
        <AliceCarousel
          items={institution?.documents.map((document, key) => [
            <CustomCard key={`${key}-${document.id}`}>
              <CardMedia
                sx={{ height: 122 }}
                image={document?.pdf?.previewUrl}
                title={document?.pdf?.name}
              />
              <CardContent sx={{ padding: '16px 12px 20px 12px' }}>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: 600 }}>
                  {document?.title}
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: 1.3 }}>
                  {document?.description}
                </Typography>
                <CustomCardActions className="download-area">
                  <CustomTooltip
                    title="Faça login ou cadastre-se"
                    disableFocusListener
                    disableTouchListener={auth?.isLoggedIn}
                    disableHoverListener={auth?.isLoggedIn}
                    arrow
                    placement="top">
                    <span>
                      <Button
                        variant="contained"
                        startIcon={<Download />}
                        disabled={!auth.isLoggedIn}
                        sx={{
                          '&:disabled': {
                            backgroundColor: '#BDBDBD',
                            color: 'white',
                          },
                        }}>
                        <Link
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 700,
                          }}
                          underline="none"
                          target="_blank"
                          download={document?.pdf?.name}
                          rel="noopener noreferrer"
                          href={document?.pdf?.url}>
                          Baixar
                        </Link>
                      </Button>
                    </span>
                  </CustomTooltip>
                </CustomCardActions>
              </CardContent>
            </CustomCard>,
          ])}
          mouseTracking
          responsive={responsive}
          disableDotsControls
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          swipeExtraPadding={400}
        />
      </Box>
    </Section>
  );
}
