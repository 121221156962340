import { useCallback, useState } from 'react';

import {
  AddCircle,
  Close,
  Delete,
  InsertDriveFile,
  UploadFileRounded,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  InputAdornment,
  ListItemText,
  MobileStepper,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getHours, getMinutes, set } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import lodash from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import womanLaptopImg from 'src/assets/imgs/woman-laptop.svg';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { CustomTooltip } from 'src/components/CustomTooltip';
import PhoneFormat from 'src/components/FormsUI/PhoneFormat';
import { If } from 'src/components/If';
import { createEvent } from 'src/services/events';
import { findInstitutionByNameOrCNPJ } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { formatBytes } from 'src/utils/number';
import * as Yup from 'yup';

import { renderSchedulesErrors } from './renderSchedulesErrors';
import * as S from './styles';

const INITIAL_VALUES = {
  title: '',
  subscribeUrl: '',
  startDate: null,
  endDate: null,
  theme: '',
  image: null as File | null,
  organizer: '',
  description: '',
  institution: { id: null, fantasyName: '', isOther: false },
  type: '',
  format: '',
  isUqbar: false,
  contact: {
    name: '',
    email: '',
    phone: '',
  },
  schedules: [{ startDate: null, endDate: null }],
};

const FORMAT_OPTIONS = [
  { id: 1, name: 'On-line' },
  { id: 2, name: 'Presencial' },
  { id: 3, name: 'Híbrido' },
];

const TYPE_OPTIONS = [
  { id: 1, name: 'Gratuito' },
  { id: 2, name: 'Pago' },
];

const MAX_FILE_SIZE = 2000000;

interface InstitutionOptions {
  id?: number;
  fantasyName: string;
  isOther?: boolean;
}

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  institution?: Institution;
};

const CreateEventModal = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const [institutions, setInstitutions] = useState<InstitutionOptions[]>([]);
  const [hasTouched, setHasTouched] = useState(false);
  const [step, setStep] = useState(1);
  const [institutionName, setInstitutionName] = useState<string>('');
  const dispatch = useDispatch();

  const { isLoading: institutionsLoading, refetch: institutionsRefetch } =
    useQuery(
      'institutions',
      () => findInstitutionByNameOrCNPJ(institutionName),
      {
        onSuccess: ({ data }) => {
          setInstitutions(data);
        },
      },
    );
  const institutionsRefetchDebounce = useCallback(
    debounce(institutionsRefetch, 500),
    [],
  );
  const errorMessages: Record<string, string> = {
    required: 'Campo obrigatório',
  };

  const ValidateForm = Yup.object().shape({
    title: Yup.string().required(errorMessages.required),
    subscribeUrl: Yup.string()
      .required(errorMessages.required)
      .matches(/^[http|https]+:\/\//, 'O link deve conter http:// ou https://'),
    theme: Yup.string().required(errorMessages.required),
    image: Yup.mixed()
      .required(errorMessages.required)
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 20MB',
        (value) => {
          return !value || (value?.type && value?.size <= MAX_FILE_SIZE);
        },
      ),
    description: Yup.string().required(errorMessages.required),
    institution: Yup.object().shape({
      fantasyName: Yup.string().required(errorMessages.required),
    }),
    type: Yup.string().required(errorMessages.required),
    format: Yup.string().required(errorMessages.required),
    contact: Yup.object({
      email: Yup.string()
        .required(errorMessages.required)
        .email('Digite um E-mail válido'),
      phone: Yup.string()
        .required('Campo obrigatório')
        .test('len', 'Número telefone inválido', (val) =>
          val ? val?.replace(/\D/g, '')?.length >= 10 : true,
        ),
    }),
    schedules: Yup.array().of(
      Yup.object({
        startDate: Yup.date()
          .typeError('Data inválida')
          .required(errorMessages.required)
          .min(new Date(), 'A data inicial deve ser maior que a data atual'),
        endDate: Yup.date()
          .nullable()
          .required(errorMessages.required)
          .when('startDate', (startDate: Date, schema: any) => {
            return schema.test({
              test: (endDate: Date) => {
                if (!endDate) return true;
                return endDate > startDate;
              },
              message: 'A data final deve ser maior que a data inicial',
            });
          }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: ValidateForm,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      const { image, ...eventData } = values;
      formData.append('files.image', image as File);
      formData.append(
        'data',
        JSON.stringify({
          ...eventData,
          slug: lodash.kebabCase(`${values.title} ${values.format}`),
          publishedAt: null,
          startDate: new Date(),
          endDate: new Date(),
        }),
      );
      createEventMutation.mutateAsync(formData);
    },
  });

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('image', acceptedFiles[0]);
    }
    if (rejectedFiles.length) {
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        formik.setErrors({
          image: 'A imagem da capa excede o tamanho máximo. Max: 2MB',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        formik.setErrors({
          image: 'Formato não suportado',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'too-many-files') {
        formik.setErrors({
          image: 'Selecione somente um arquivo',
        });
      }
    }
  }, []);

  const handleClose = () => {
    props.handleClose();
    formik.resetForm();
    setStep(1);
    setOpenLeaveDialog(false);
  };

  const createEventMutation = useMutation(createEvent, {
    onError: (error: any) => {
      dispatch(
        setBannerMessage({
          message: 'Não foi possível cadastrar seu evento.',
          type: 'error',
          autoclose: true,
        }),
      );
    },
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'Solicitação de divulgação do evento concluída',
          type: 'success',
          autoclose: true,
        }),
      );
      setHasTouched(false);
      handleClose();
    },
  });

  const handlePreviousStep = () => {
    setStep((s) => s - 1);
  };

  const handleNextStep = async () => {
    const validationResult = await formik.validateForm();
    let fieldsToTest: string[] = [];
    switch (step) {
      case 1:
        fieldsToTest = ['title', 'theme', 'description', 'type', 'format'];
        break;
      case 2:
        fieldsToTest = ['contact', 'institution', 'subscribeUrl', 'image'];
        break;
      default:
        break;
    }
    const setTouched = fieldsToTest.reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    formik.setTouched({ ...formik.touched, ...setTouched });

    const hasErrors = fieldsToTest.some((key) =>
      Object.keys(validationResult).includes(key),
    );
    if (!hasErrors) {
      formik.setErrors({});
      setStep((s) => s + 1);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
    },
    onDrop,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  });

  type endAdornmentProps = {
    field: 'title' | 'theme' | 'description';
    total: number;
  };

  const EndAdornment = ({ field, total }: endAdornmentProps) => (
    <InputAdornment position="end" sx={{ color: '#BDBDBD' }}>
      <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '19px',
        }}>
        {formik.values[field].length}/{total}
      </Typography>
    </InputAdornment>
  );

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="md"
      onClose={() => {
        if (hasTouched) {
          setOpenLeaveDialog(true);
        } else {
          handleClose();
        }
      }}
      sx={{ padding: 4 }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxHeight: '100%',
          overflowY: 'auto',
          height: 'auto',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        },
      }}>
      <DialogContent
        sx={{
          padding: '20px 48px',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        }}>
        <form
          onSubmit={formik.handleSubmit}
          onChange={() => setHasTouched(true)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 -32px',
            }}>
            <IconButton
              aria-label="fechar"
              color="inherit"
              size="small"
              onClick={() => {
                if (hasTouched) {
                  setOpenLeaveDialog(true);
                } else {
                  handleClose();
                }
              }}>
              <Close
                fontSize="small"
                sx={{
                  color: 'black',
                }}
              />
            </IconButton>
          </Box>
          <S.Title>
            <Typography variant="h5">Cadastrar evento</Typography>
          </S.Title>
          <Grid container spacing={2} gap={2} paddingTop={2}>
            {step === 1 && (
              <>
                <Grid xs={12}>
                  <S.FieldTitle>Dê um nome para o seu evento*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="title"
                      placeholder="Nome do evento"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 52 }}
                      value={formik.values.title}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                      InputProps={{
                        endAdornment: <EndAdornment field="title" total={52} />,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <S.FieldTitle>Informe o tema do seu evento*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="theme"
                      placeholder="Tema do evento"
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 70 }}
                      value={formik.values.theme}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.theme && Boolean(formik.errors.theme)
                      }
                      helperText={formik.touched.theme && formik.errors.theme}
                      InputProps={{
                        endAdornment: <EndAdornment field="theme" total={70} />,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <S.FieldTitle>Descreva seu evento*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="description"
                      placeholder="Ex: Evento voltado para...."
                      multiline
                      minRows={4}
                      maxRows={4}
                      inputProps={{ maxLength: 1500 }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                    <Typography
                      sx={{
                        width: '100%',
                        textAlign: 'right',
                        position: 'relative',
                        top: formik.errors.description ? '-50px' : '-30px',
                        right: '20px',
                        fontSize: '12px',
                        lineHeight: '19px',
                        opacity: 0.25,
                      }}>
                      Número de caracteres:{' '}
                      {formik.values.description?.length || 0}
                      /1500
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid xs={12} sx={{ marginTop: '-20px' }}>
                  <S.FieldTitle>Selecione o tipo de evento*</S.FieldTitle>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={formik.touched.type && Boolean(formik.errors.type)}>
                    <Select
                      name="type"
                      placeholder="Pago, gratuito"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      displayEmpty
                      renderValue={(selected: any) => {
                        if (!selected?.length) {
                          return (
                            <Typography sx={{ color: '#BDBDBD' }}>
                              Pago, gratuito
                            </Typography>
                          );
                        }
                        return selected;
                      }}>
                      {TYPE_OPTIONS.map((opt) => (
                        <MenuItem value={opt.name} key={opt.id}>
                          <ListItemText primary={opt.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.type && Boolean(formik.errors.type) && (
                      <FormHelperText sx={{ color: '#EB5757' }}>
                        {formik.errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={12} sx={{ marginTop: '-20px' }}>
                  <S.FieldTitle>Selecione o formato do evento*</S.FieldTitle>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={
                      formik.touched.format && Boolean(formik.errors.format)
                    }>
                    <Select
                      name="format"
                      placeholder="Online, presencial, híbrido"
                      value={formik.values.format}
                      onChange={formik.handleChange}
                      displayEmpty
                      renderValue={(selected: any) => {
                        if (!selected?.length) {
                          return (
                            <Typography sx={{ color: '#BDBDBD' }}>
                              Online, presencial, híbrido
                            </Typography>
                          );
                        }
                        return selected;
                      }}>
                      {FORMAT_OPTIONS.map((opt) => (
                        <MenuItem value={opt.name} key={opt.id}>
                          <ListItemText primary={opt.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.format && Boolean(formik.errors.format) && (
                      <FormHelperText sx={{ color: '#EB5757' }}>
                        {formik.errors.format}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            {step === 2 && (
              <>
                <Grid xs={12}>
                  <S.FieldTitle>E-mail para contato*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="contact.email"
                      placeholder="nome@exemplo.com.br"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 70 }}
                      value={formik.values.contact.email}
                      error={
                        formik.touched.contact &&
                        Boolean(formik.errors.contact?.email)
                      }
                      helperText={
                        formik.touched.contact && formik.errors.contact?.email
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <S.FieldTitle>Telefone para contato*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="contact.phone"
                      placeholder="(xx) xxxxx-xxxx"
                      onChange={formik.handleChange}
                      InputProps={{
                        inputComponent: PhoneFormat,
                      }}
                      value={formik.values.contact.phone}
                      error={
                        formik.touched.contact &&
                        Boolean(formik.errors.contact?.phone)
                      }
                      helperText={
                        formik.touched.contact && formik.errors.contact?.phone
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <S.FieldTitle>Organização responsável*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      fullWidth
                      autoHighlight
                      loading={institutionsLoading}
                      options={institutions}
                      isOptionEqualToValue={(option, value) => {
                        if (typeof option === 'string') return false;
                        if (typeof value === 'string') return false;
                        return option.fantasyName === value.fantasyName;
                      }}
                      onBlur={formik.handleBlur}
                      onChange={(event, newValue, reason) => {
                        if (typeof newValue === 'string') return;
                        if (!newValue) {
                          formik.setFieldValue('institution', {
                            id: null,
                            fantasyName: '',
                            isOther: false,
                          });
                        } else {
                          formik.setFieldValue('institution', newValue);
                        }
                      }}
                      onInputChange={(e, value) => {
                        setInstitutionName(value);
                        institutionsRefetchDebounce();
                      }}
                      value={formik.values.institution as any}
                      getOptionLabel={(option: InstitutionOptions | string) =>
                        typeof option === 'string' ? option : option.fantasyName
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="relatedInstitutions"
                          placeholder="Selecione ou digite sua empresa"
                          error={
                            formik.touched.institution &&
                            Boolean(formik.errors.institution?.fantasyName)
                          }
                          helperText={
                            formik.touched.institution &&
                            formik.errors.institution?.fantasyName
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <S.FieldTitle>Link de inscrição para o evento*</S.FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="subscribeUrl"
                      placeholder="Link para a página de inscrição do evento"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ maxLength: 70 }}
                      value={formik.values.subscribeUrl}
                      error={
                        formik.touched.subscribeUrl &&
                        Boolean(formik.errors.subscribeUrl)
                      }
                      helperText={
                        formik.touched.subscribeUrl &&
                        formik.errors.subscribeUrl
                      }
                    />
                  </FormControl>
                </Grid>
                <S.FieldTitle>
                  Envie uma imagem de capa (thumbnail) para o evento*
                </S.FieldTitle>
                <If condition={!formik.values.image}>
                  <Box
                    {...getRootProps()}
                    sx={{
                      width: '100%',
                      height: 150,
                      backgroundColor: '#FAFAFA',
                      border: '1px dashed #E0E0E0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      margin: '8px 0 20px',
                      cursor: 'pointer',
                    }}>
                    <input {...getInputProps()} />
                    <UploadFileRounded
                      sx={{ fontSize: '48px', color: '#BDBDBD' }}
                    />
                    <Typography
                      textAlign="center"
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        fontSize: '0.875rem',
                      })}>
                      <span style={{ textDecoration: 'underline' }}>
                        Clique aqui
                      </span>{' '}
                      ou arraste um arquivo
                    </Typography>
                    <Typography
                      textAlign="center"
                      sx={(theme) => ({
                        color: '#BDBDBD',
                        fontSize: '0.875rem',
                      })}>
                      JPEG, PNG, JPG, Dimensões ideais 1200x400px, máx 2MB
                    </Typography>
                    {formik.touched.image && Boolean(formik.errors.image) && (
                      <FormHelperText sx={{ color: '#EB5757' }}>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Box>
                </If>
                <If condition={formik.values.image}>
                  <Box
                    sx={{
                      height: 62,
                      width: '100%',
                      padding: '0 16px',
                      backgroundColor: '#FAFAFA',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <Box sx={{ display: 'flex', textAlign: 'start' }}>
                      <InsertDriveFile
                        sx={{ fontSize: '40px', color: '#BDBDBD' }}
                      />
                      <Box ml={1}>
                        <Typography fontSize={14} fontWeight={600}>
                          {formik.values.image?.name}
                        </Typography>
                        <Typography sx={{ color: '#828282', fontSize: '14px' }}>
                          {formatBytes(formik.values.image?.size)}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => formik.setFieldValue('image', null)}>
                      <Close />
                    </IconButton>
                  </Box>
                </If>
              </>
            )}
            {step === 3 && (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBR}>
                {formik.values.schedules.map((schedule, index) => (
                  <Box key={index}>
                    <Grid container xs={12} gap={2}>
                      <Grid xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <S.FieldTitle>Dia do evento*</S.FieldTitle>
                          {index !== 0 && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                const schedules = formik.values.schedules;
                                schedules.splice(index, 1);
                                formik.setFieldValue('schedules', schedules);
                              }}>
                              <Typography
                                sx={() => ({
                                  color: '#BDBDBD',
                                  fontSize: '0.875rem',
                                  marginRight: '10px',
                                })}>
                                Excluir
                              </Typography>
                              <Delete sx={{ fontSize: '18px' }} />
                            </IconButton>
                          )}
                        </Box>
                        <FormControl fullWidth variant="outlined">
                          <DatePicker
                            views={['year', 'month', 'day']}
                            slotProps={{
                              textField: {
                                name: 'dueDate',
                                placeholder: 'DD/MM/AAAA',
                                onBlur: () =>
                                  formik.setFieldTouched(
                                    `schedules[${index}]`,
                                    true,
                                  ),
                                onFocus: () =>
                                  formik.setFieldTouched(
                                    `schedules[${index}]`,
                                    false,
                                  ),
                              },
                            }}
                            onChange={(value) => {
                              if (
                                value === null ||
                                (value as string).toString() === 'Invalid Date'
                              ) {
                                formik.setFieldValue(`schedules[${index}]`, {
                                  startDate: null,
                                  endDate: null,
                                });
                                return;
                              }
                              formik.setFieldValue(`schedules[${index}]`, {
                                startDate: value,
                                endDate: new Date(
                                  (value as any).getTime() + 3600 * 1000,
                                ),
                              });
                            }}
                            value={formik.values.schedules[index].startDate}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <S.FieldTitle>Duração*</S.FieldTitle>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          gap: '24px',
                        }}>
                        <Grid
                          container
                          xs={6}
                          gap={1}
                          sx={{
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                          }}>
                          <Typography fontSize={14}>De</Typography>
                          <TimePicker
                            slotProps={{ textField: { placeholder: '00:00' } }}
                            value={formik.values.schedules[index].startDate}
                            onChange={(newValue: any) => {
                              if (newValue?.toString() === 'Invalid Date')
                                return;
                              const hours = getHours(newValue);
                              const minutes = getMinutes(newValue);
                              const value = set(
                                formik.values.schedules[index]
                                  .startDate as unknown as Date,
                                { hours, minutes },
                              );
                              formik.setFieldValue(
                                `schedules[${index}].startDate`,
                                value,
                              );
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          xs={6}
                          gap={1}
                          sx={{
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                          }}>
                          <Typography fontSize={14}>Até</Typography>
                          <TimePicker
                            slotProps={{ textField: { placeholder: '00:00' } }}
                            value={formik.values.schedules[index].endDate}
                            onChange={(newValue: any) => {
                              if (newValue?.toString() === 'Invalid Date')
                                return;
                              const hours = getHours(newValue);
                              const minutes = getMinutes(newValue);
                              const value = set(
                                formik.values.schedules[index]
                                  .endDate as unknown as Date,
                                { hours, minutes },
                              );
                              formik.setFieldValue(
                                `schedules[${index}].endDate`,
                                value,
                              );
                            }}
                          />
                        </Grid>
                      </FormControl>
                      {renderSchedulesErrors(
                        formik.errors.schedules?.[index],
                        formik.touched.schedules?.[index],
                      )}
                    </Grid>
                  </Box>
                ))}
              </LocalizationProvider>
            )}
            {step === 3 && (
              <CustomTooltip placement="top">
                <span>
                  <Button
                    startIcon={<AddCircle color="primary" fontSize="large" />}
                    color="primary"
                    onClick={() => {
                      formik.setFieldValue('schedules', [
                        ...formik.values.schedules,
                        { startDate: null, endDate: null },
                      ]);
                    }}
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'underline !important',
                      letterSpacing: 'unset',
                      fontSize: '1rem',
                      fontWeight: 700,
                      padding: 0,
                      marginLeft: 0.5,
                    }}>
                    Cadastrar dia adicional
                  </Button>
                </span>
              </CustomTooltip>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            gap={4}
            sx={{
              justifyContent: 'center',
              marginTop: '50px',
              position: 'relative',
            }}>
            {step === 1 && (
              <Grid>
                <S.Button variant="contained" onClick={handleNextStep}>
                  Próximo
                </S.Button>
              </Grid>
            )}
            {step === 2 && (
              <Box display="flex" gap={3}>
                <S.Button variant="contained" onClick={handleNextStep}>
                  Próximo
                </S.Button>
                <S.Button variant="outlined" onClick={handlePreviousStep}>
                  Voltar
                </S.Button>
              </Box>
            )}
            {step === 3 && (
              <Box display="flex" gap={3}>
                <S.Button
                  variant="contained"
                  type="submit"
                  disabled={
                    createEventMutation.isLoading ||
                    !!Object.keys(formik.errors).length
                  }>
                  {createEventMutation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFF' }} />
                  ) : (
                    'Salvar'
                  )}
                </S.Button>
                <S.Button variant="outlined" onClick={handlePreviousStep}>
                  Voltar
                </S.Button>
              </Box>
            )}
            {!isMobile && (
              <img
                src={womanLaptopImg}
                width="214px"
                height="180px"
                style={{
                  position: 'absolute',
                  left: '-35px',
                }}
              />
            )}
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              marginTop: '24px',
            }}>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={step - 1}
              sx={{
                maxWidth: 120,
                '& .MuiMobileStepper-dot': {
                  backgroundColor: 'rgba(0, 0, 0, 0.26)',
                  width: '14px',
                  height: '14px',
                  marginX: '8px',
                },
                '& .MuiMobileStepper-dotActive': {
                  backgroundColor: 'rgba(51, 51, 51, 1)',
                  width: '14px',
                  height: '14px',
                  marginX: '8px',
                },
              }}
              nextButton={null}
              backButton={null}
            />
          </Grid>
          <ConfirmLeaveDialog
            open={openLeaveDialog}
            onClose={() => setOpenLeaveDialog(false)}
            handleAction={() => {
              handleClose();
            }}
            labelButton="Continuar"
            labelCloseButton="Fechar"
            title="Tem certeza que deseja fechar o formulário?"
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEventModal;
