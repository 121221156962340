// Material ui componentes
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationFIILiabilityData from './OperationFIILiabilityData';
import OperationFIILiabilityPatrimony from './OperationFIILiabilityPatrimony';
import OperationFIILiabilityQuote from './OperationFIILiabilityQuote';
import { PanelLogin } from '../../../../components/PanelLogin';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationFIILiability({ hash }) {
  const { auth } = useAuthContext();
  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo'] ? (
        <Box p={2}>
          <PanelLogin />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationFIILiabilityData hash={hash} />
          </Grid>
          <Grid item xs={12}>
            <OperationFIILiabilityPatrimony hash={hash} />
          </Grid>
          <Grid item xs={12}>
            <OperationFIILiabilityQuote hash={hash} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
