import axios, { AxiosRequestConfig } from 'axios';

import { AuthContextRef } from '../context/AuthContextProvider';
import { EmbedContextRef } from '../context/EmbedContextProvider';
import getUniqueIdentifier from '../utils/unique-identifier';

const buildDeviceId = async () => {
  const deviceId = await getUniqueIdentifier();
  localStorage.setItem('@App:deviceId', deviceId);
  return deviceId;
};

const getJwt = () => {
  if (sessionStorage.getItem('@App:tempToken')) {
    return sessionStorage.getItem('@App:tempToken');
  }
  return (
    sessionStorage.getItem('@App:token') || localStorage.getItem('@App:token')
  );
};

const injectContentTypeAndDeviceId = async (config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    'x-device-id':
      localStorage.getItem('@App:deviceId') ?? (await buildDeviceId()),
  };
  return config;
};

const axiosDefaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
};

const axiosInstance = axios.create(axiosDefaultOptions);
axiosInstance.interceptors.request.use(injectContentTypeAndDeviceId);
axiosInstance.interceptors.request.use((config) => {
  const jwt = getJwt();
  if (jwt) {
    config.headers = {
      ...config.headers,
      authorization: `Bearer ${jwt}`,
    };
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (AuthContextRef.current) {
        AuthContextRef.current.handleLogout(true);
        AuthContextRef.current.notifyAuthChannel();
      }
    }
    return Promise.reject(error);
  },
);

const axiosPublicInstance = axios.create(axiosDefaultOptions);
axiosPublicInstance.interceptors.request.use(injectContentTypeAndDeviceId);

const axiosEmbedInstance = axios.create(axiosDefaultOptions);
axiosEmbedInstance.interceptors.request.use(injectContentTypeAndDeviceId);
axiosEmbedInstance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    'widget-key': EmbedContextRef.current.widgetKey,
    'widget-referer': document.referrer || document.location.toString(),
  };
  return config;
});

export const api = axiosInstance;
export const publicApi = axiosPublicInstance;
export const embedApi = axiosEmbedInstance;
