import theme from 'src/theme';
import styled from 'styled-components';

export const Wrapper = styled.summary`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const TitleWrapper = styled.div``;

export const PartnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-family: 'Rubik Mono One', 'Inter', sans-serif;
  display: inline;
  line-height: 125%;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    display: block;
    font-size: 1.25rem;
  }
`;

export const Author = styled.a`
  margin-left: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: #8d8d8d;
  text-decoration: none;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 0;
  }
`;

export const Partner = styled.a`
  padding: 1rem;
  border-radius: 30px;
  color: #ef781c;
  background-color: #ffd3b1;
  font-weight: bold;
  text-decoration: none;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    text-align: center;
    font-size: 0.8rem;

    &::before {
      content: 'Patrocínio: ';
    }
  }
`;

export const ReadMore = styled.button`
  border: none;
  background: transparent;
  outline: none !important;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  align-items: center;
`;
