import { Switch, withStyles } from '@material-ui/core';

export const PlanSwitch = withStyles((theme) => ({
  root: {
    width: 64,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 5,
    color: '#D5D5D5',
    '&$checked': {
      transform: 'translateX(32px)',
      color: '#D5D5D5',
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.secondary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
