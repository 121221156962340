import { useState } from 'react';

import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import SubTitle from 'src/components/UI/Subtitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  findInstitutionById,
  getRequestOwnership,
} from 'src/services/institution';
import { Institution } from 'src/types/institution';
import { ScrollToTop } from 'src/utils/scroll';

import { CompanyForm } from './CompanyForm';

export default function InstitutionalProfile({
  relatedInstitution,
  relatedLoading,
  refetch,
}: {
  relatedInstitution: any;
  relatedLoading: boolean;
  refetch: () => void;
}) {
  const { auth } = useAuthContext();
  const [solicitationStatus, setSolicitationStatus] = useState<string | null>(
    null,
  );
  const [institution, setInstitution] = useState<Institution | undefined>();

  const { isLoading: loadingInstitution, refetch: institutionRefetch } =
    useQuery(
      ['institution', relatedInstitution?.id],
      () => findInstitutionById(relatedInstitution.id, false),
      {
        onError: () => {},
        onSuccess: (data) => {
          setInstitution(data);
        },
        enabled: !!relatedInstitution?.id,
      },
    );

  const { isLoading: loadingSolicitations } = useQuery(
    'solicitations',
    getRequestOwnership,
    {
      onError: () => {},
      onSuccess: (data) => {
        if (data.length) {
          const solicitationsStatus = data.map(
            (solicitation: any) => solicitation.status,
          );

          if (solicitationsStatus.includes('Pendente')) {
            setSolicitationStatus('Pendente');
          } else if (
            solicitationsStatus.includes('Aceito') ||
            relatedInstitution?.id
          ) {
            setSolicitationStatus('Aceito');
          } else {
            setSolicitationStatus(null);
          }
        } else {
          setSolicitationStatus('Aceito');
        }
      },
    },
  );

  return (
    <ScrollToTop>
      <Paper
        elevation={2}
        sx={(theme) => ({
          marginTop: 4,
          padding: '26px 34px',
          [theme.breakpoints.down('sm')]: { padding: '26px 16px' },
        })}>
        <If
          condition={
            loadingInstitution || loadingSolicitations || relatedLoading
          }>
          <LinearProgress />
        </If>
        {institution?.owner?.id !== auth.user?.id &&
        solicitationStatus === 'Pendente' ? (
          <Box>
            <SubTitle title="Recebemos a sua solicitação" />
            <Typography
              sx={(theme) => ({
                padding: '32px 22px',
                [theme.breakpoints.down('sm')]: {
                  padding: '32px 10px',
                },
              })}>
              A Uqbar fará uma análise da sua solicitação. Se ela for aceita,
              você verá nessa aba o perfil da sua instituição. Se ela for
              recusada, você verá nessa aba um formulário para cadastrar uma
              instituição nova.
            </Typography>
          </Box>
        ) : (
          <CompanyForm
            data={institution}
            relatedInstitutionRefetch={refetch}
            institutionRefetch={institutionRefetch}
            isRefusedSolicitation={!solicitationStatus}
          />
        )}
      </Paper>
    </ScrollToTop>
  );
}
