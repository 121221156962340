// Material ui components
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationCRIResumeGeneralData from './OperationCRIResumeGeneralData';
import OperationCRIResumeReceivables from './OperationCRIResumeReceivables';
import OperationCRIResumeServiceProvider from './OperationCRIResumeServiceProvider';
import OperationCRIResumeTitles from './OperationCRIResumeTitles';

export default function OperationCRIResume({ hashId }) {
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <OperationCRIResumeGeneralData hashId={hashId} />
        </Grid>
        <Grid item xs={12} md>
          <OperationCRIResumeServiceProvider hashId={hashId} />
        </Grid>
        <Grid item xs={12}>
          <OperationCRIResumeReceivables hashId={hashId} />
        </Grid>
        <Grid item xs={12}>
          <OperationCRIResumeTitles hashId={hashId} />
        </Grid>
      </Grid>
    </Box>
  );
}
