import { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Alert } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { Dialog } from '../../../../components/Dialog';
import { If } from '../../../../components/If';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { requestArticle } from '../../../../services/misc';
import { TextAreaCustom } from '../TextAreaCustom';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
  checkbox: {
    width: '100%',
  },
  inputFile: {
    display: 'none',
  },
  labelFile: {
    width: '100%',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '0.5rem',
    },
  },
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  messageError: {
    color: theme.palette.red.default,
    margin: '0',
    fontSize: '0.85rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '400',
    lineHeight: '1.66',
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  buttonFile: {
    minWidth: '190px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  modalTitle: {
    width: '100%',
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: '1.25rem',
    marginBottom: theme.spacing(4),
  },
  modalContent: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
  termsText: {
    textAlign: 'justify',
    fontSize: '0.875rem',
  },
  termsBox: {
    height: '150px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    margin: '24px 0 8px 0',
    padding: '16px',
  },
  noFileBox: {
    marginLeft: '8px',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
    },
  },
  subTitle: {
    marginTop: theme.spacing(2),
    lineHeight: '15px',
  },
  title: {
    fontWeight: 600,
    lineHeight: '23px',
  },
}));

const INITIAL_VALUES = {
  links: '',
  articleIdea: '',
  articleUtility: '',
  market: '',
  accept: false,
};

const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export default function ArticleRequestModal({
  open,
  handleClose,
  success,
  setSuccess,
}) {
  const classes = useStyles();
  const { auth } = useAuthContext();

  const FORM_VALIDATION = Yup.object()
    .shape({
      articleIdea: Yup.string().required('Campo obrigatório'),
      articleUtility: Yup.string().required('Campo obrigatório'),
      otherMarkets: Yup.string().when('othersCheckbox', {
        is: (othersCheckbox) => othersCheckbox,
        then: Yup.string().required(
          'Campo obrigatório se a opção Outros estiver selecionada',
        ),
      }),
      document: Yup.mixed()
        .required('Documento é obrigatório')
        .test(
          'fileFormat',
          'Formato não suportado',
          (value) => value && SUPPORTED_FORMATS.includes(value.type),
        ),
      accept: Yup.boolean()
        .required('Campo obrigatório')
        .oneOf([true], 'É necessário concordar com as regras para submissão'),
    })
    .test('markets', null, () => {
      if (markets.length > 0 || openOthers) {
        return true;
      }
      return new Yup.ValidationError(
        'Pelo menos um mercado precisa ser selecionado',
        null,
        'markets',
      );
    });

  const [submitError, setSubmitError] = useState({
    status: false,
    message: '',
  });
  const [markets, setMarkets] = useState([]);
  const [openOthers, setOpenOthers] = useState(false);

  const requestArticleMutation = useMutation(requestArticle, {
    onSuccess: () => {
      setSuccess(true);
      setSubmitError({
        status: false,
        message: '',
      });
    },
    onError: () => {
      setSubmitError({
        status: true,
        message:
          'Não foi possível efetuar a solicitação. Entre em contato com a administração.',
      });
    },
  });

  const { isLoading } = requestArticleMutation;

  const handleSelectMarket = (market) => {
    if (!market) {
      setOpenOthers((prevState) => !prevState);
    } else {
      if (markets.includes(market)) {
        setMarkets(markets.filter((m) => m !== market));
      } else {
        setMarkets([...markets, market]);
      }
    }
  };

  const parseMarkets = (otherMarkets = '') => {
    if (otherMarkets) {
      return `${markets?.join(', ')}, ${otherMarkets}`;
    }
    return markets?.join(', ');
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={FORM_VALIDATION}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append('files.document', values.document);
          formData.append(
            'data',
            JSON.stringify({
              user: auth?.user?.id,
              links: values.links,
              articleIdea: values.articleIdea,
              articleUtility: values.articleUtility,
              market: parseMarkets(values.otherMarkets),
              status: 'Pendente',
            }),
          );
          requestArticleMutation.mutate(formData);
        }}>
        {({ handleChange, values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <DialogContent classes={{ root: classes.modalContent }}>
                <Box>
                  <Grid container>
                    <If condition={!success}>
                      <Typography variant="h5" className={classes.modalTitle}>
                        Preencha o formulário
                      </Typography>
                      <Collapse in={submitError.status}>
                        <Alert
                          onClose={() => {
                            setSubmitError({ status: false, message: '' });
                          }}
                          className={classes.alert}
                          severity="error">
                          {submitError.message}
                        </Alert>
                      </Collapse>
                      <TextAreaCustom
                        name="articleIdea"
                        label="Mote *"
                        helperText="Sumarize a ideia do artigo"
                        minRows={3}
                        value={values.articleIdea}
                        handleChange={handleChange}
                        error={errors.articleIdea}
                        touched={touched.articleIdea}
                      />
                      <TextAreaCustom
                        name="articleUtility"
                        label="Pertinência *"
                        helperText="Qual a relevância para os leitores?"
                        minRows={3}
                        value={values.articleUtility}
                        handleChange={handleChange}
                        error={errors.articleUtility}
                        touched={touched.articleUtility}
                      />
                      <TextAreaCustom
                        name="links"
                        label="Outras publicações"
                        helperText="Links para artigos publicados"
                        minRows={3}
                        value={values.links}
                        handleChange={handleChange}
                        error={errors.links}
                        touched={touched.links}
                      />
                      <Grid item xs={12}>
                        <ListItem className={classes.listItem} disableGutters>
                          <InputLabel className={classes.labelFile}>
                            Mercado *
                          </InputLabel>
                          <Grid container>
                            {['FIDC', 'FII', 'CR', 'CRI', 'CRA'].map(
                              (market, index) => (
                                <Grid
                                  key={`${index}-market`}
                                  xs={6}
                                  sm={6}
                                  md={6}>
                                  <FormControlLabel
                                    required
                                    onChange={() => {
                                      handleSelectMarket(market);
                                      setFieldValue(
                                        `${market.toLowerCase()}Checkbox`,
                                        !markets.includes(market),
                                      );
                                    }}
                                    checked={markets.includes(market)}
                                    name={`${market.toLowerCase()}Checkbox`}
                                    control={<Checkbox size="small" />}
                                    label={market}
                                    className={classes.checkbox}
                                  />
                                </Grid>
                              ),
                            )}
                            <Grid xs={6} sm={6} md={6}>
                              <FormControlLabel
                                required
                                onChange={() => {
                                  handleSelectMarket(null);
                                  setFieldValue('othersCheckbox', !openOthers);
                                }}
                                checked={openOthers}
                                name="othersCheckbox"
                                control={<Checkbox size="small" />}
                                label="Outros"
                                className={classes.checkbox}
                              />
                            </Grid>
                            <If condition={openOthers}>
                              <TextField
                                name="otherMarkets"
                                type="text"
                                label="Outros mercados"
                                value={values.otherMarkets}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.otherMarkets}
                                helperText={errors.otherMarkets}
                              />
                            </If>
                            {errors.markets ? (
                              <span
                                className={classes.messageError}
                                style={{
                                  flexBasis: '100%',
                                  height: '0',
                                  marginBottom: '16px',
                                }}>
                                {errors.markets}
                              </span>
                            ) : null}
                          </Grid>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItem className={classes.listItem} disableGutters>
                          <input
                            name="document"
                            accept=".pdf, .doc, .docx"
                            className={classes.inputFile}
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => {
                              setFieldValue('document', event.target.files[0]);
                            }}
                          />
                          <InputLabel
                            className={classes.labelFile}
                            htmlFor="contained-button-file">
                            Documento *
                            <Box
                              display="flex"
                              alignItems="center"
                              marginTop="8px"
                              fontSize="0.875rem"
                              flexWrap="wrap">
                              <Button
                                variant="contained"
                                color="secondary"
                                component="span"
                                className={classes.buttonFile}
                                startIcon={<InsertDriveFileIcon />}>
                                Upload
                              </Button>
                              <Box className={classes.noFileBox}>
                                {!values?.document
                                  ? 'Nenhum arquivo selecionado'
                                  : values?.document.name}
                              </Box>
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  'Apenas os seguintes formatos são aceitos: .pdf, .doc e .docx.'
                                }
                                classes={{ tooltip: classes.tooltip }}>
                                <InfoOutlinedIcon
                                  className={classes.icon}
                                  fontSize="medium"
                                  color="secondary"
                                />
                              </Tooltip>
                            </Box>
                          </InputLabel>
                          {errors.document ? (
                            <span
                              className={classes.messageError}
                              style={{
                                flexBasis: '100%',
                                height: '0',
                                marginBottom: '0.5rem',
                              }}>
                              {errors.document}
                            </span>
                          ) : null}
                        </ListItem>
                      </Grid>
                      <Box className={classes.termsBox}>
                        <Typography className={classes.termsText}>
                          <b>Condições para submissão.</b>
                          <br />
                          <br />
                          Como parte do processo de submissão, os autores são
                          obrigados a verificar a conformidade da submissão em
                          relação a todos os itens listados a seguir. As
                          submissões que não estiverem de acordo com as normas
                          serão devolvidas aos autores.
                          <br />
                          <br />
                          • A contribuição é original e inédita, caso contrário,
                          deve ser justificada.
                          <br />
                          • Ausência de tom político.
                          <br />
                          • Ausência de linguagem pejorativa.
                          <br />
                          • Ausência de cunho publicitário explícito.
                          <br />
                          • O arquivo da submissão está em formato .DOC, .DOCX
                          ou .PDF.
                          <br />
                          • Todos os artigos devem conter: título, indicação de
                          pelo menos três palavras-chave e resumo, que não
                          ultrapassem 100, 12 e 500 caracteres cada.
                          <br />
                          • URLs para as referências devem ser informadas quando
                          possível.
                          <br />
                          • As imagens utilizadas no artigo devem ser submetidas
                          em separado na melhor resolução possível
                          <br />
                          • Os dados utilizados em gráficos precisam ser
                          providos quando possível
                          <br />
                          <br />
                          <br />
                          <b>Diretrizes para os Autores.</b>
                          <br />
                          <br />
                          1. A Uqbar aceita para publicação artigos
                          exclusivamente de seus assinantes do Plano Equipe ou
                          superior.
                          <br />
                          2. Os trabalhos devem ser encaminhados em português.
                          <br />
                          3. Os artigos devem ter no mínimo 500 e no máximo
                          5.000 palavras com espaços, incluindo as referências
                          bibliográficas, as notas, o título, o resumo, as
                          palavras-chave e os dados de autoria.
                          <br />
                          4. A publicação de artigos está condicionada à
                          verificação e à validação do time de conteúdo da
                          Uqbar.
                          <br />
                          5. As notas de rodapé devem ser exclusivamente
                          explicativas. Todas as notas deverão ser numeradas e
                          aparecer no pé de página.
                          <br />
                          6. Deve-se indicar os locais onde os quadros,
                          gráficos, mapas, imagens etc. em formato png, jpg e
                          jpeg devem ser inseridos.
                          <br />
                          7. Utilizar letras maiúsculas em negrito para o título
                          principal; nos subtítulos das seções, use negrito e a
                          primeira letra maiúscula, seguida de minúsculas.
                          <br />
                          8. Para ênfase ou destaque, no interior do texto,
                          utilizar apenas itálico; assinalar os parágrafos com
                          um único toque de tabulação e dar Enter apenas no
                          final do parágrafo.
                          <br />
                        </Typography>
                      </Box>
                      <Grid item xs={12}>
                        <FormControlLabel
                          required
                          onChange={() =>
                            setFieldValue('accept', !values.accept)
                          }
                          checked={values.accept}
                          name="accept"
                          control={<Checkbox size="small" />}
                          label="Concordo com as regras"
                          className={classes.checkbox}
                        />
                        {errors.accept ? (
                          <span
                            className={classes.messageError}
                            style={{
                              flexBasis: '100%',
                              height: '0',
                            }}>
                            {errors.accept}
                          </span>
                        ) : null}
                      </Grid>
                    </If>
                    <If condition={success}>
                      <Typography variant="h6" className={classes.title}>
                        Submissão enviada com sucesso
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.subTitle}>
                        Em breve alguém da Uqbar entrará em contato.
                      </Typography>
                    </If>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <If condition={!success}>
                  <Button
                    type="reset"
                    onClick={() => {
                      setOpenOthers(false);
                      setMarkets([]);
                      handleClose();
                    }}
                    color="secondary"
                    variant="outlined">
                    Cancelar
                  </Button>
                  <Button color="secondary" variant="contained" type="submit">
                    {isLoading ? (
                      <CircularProgress size="1.4rem" color="secondary" />
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                </If>
                <If condition={success}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="reset"
                    onClick={() => {
                      setOpenOthers(false);
                      setMarkets([]);
                      handleClose();
                    }}>
                    OK
                  </Button>
                </If>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
