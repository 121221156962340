import styled from 'styled-components';

import { Content as ContentEl } from '../components/layout-elements';

export const Content = styled(ContentEl)`
  max-width: 70%;
  min-height: 100vh;
`;

export const PlayerWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
  column-gap: 30px;
  width: 100%;
  height: 600px;

  @supports not (aspect-ratio: auto) {
    & {
      width: 700px;
      height: 600px;
    }
  }

  @media (max-width: 2000px) {
    display: grid;
    grid-template-columns: 1fr 500px;
    justify-items: center;
    height: auto;
  }

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    height: auto;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Video = styled.iframe`
  flex: 1;
  height: 100%;
  align-items: flex-start;
  aspect-ratio: 16/9;

  .vp-center {
    background-color: green !important;
  }
`;

type PlayListProps = {
  selected: boolean;
};

export const PlayList = styled.div`
  width: 100%;
  overflow: auto;
  display: grid;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 1400px) {
    margin-top: 50px;
    width: 100%;
  }
`;

export const PlaylistItem = styled.div<PlayListProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 150px 1fr;
  height: 86px;
  background-color: ${(props) => (props.selected ? '#E4E5E9' : 'transparent')};
  overflow: hidden;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const ListWrapper = styled.div`
  display: grid;
  row-gap: 20px;
  margin-left: 40px;
`;

export const ItemText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  height: 86px;

  & > span:first-child {
    font-size: 13px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
  }

  & > span:nth-child(2) {
    font-size: 12px;
  }
`;

export const Description = styled.div`
  position: relative;
  width: 100%;
  align-self: flex-start;
  margin-top: 10px;
`;

export const SubTitle = styled.div`
  width: 100%;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
`;

export const Sponsor = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  padding: 20px 0;
`;

export const SponsorText = styled.div`
  width: 100%;
  padding: 5px 0;
`;
