import { useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ExportToCsv } from 'export-to-csv';

import csvSvg from '../../../../../../assets/imgs/csv.svg';
import { convertNumber, toCurrency } from '../../../../../../utils/number';

export function TableWallet({ name, rows, periods, loading, classes }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportDataToCSV = () => {
    const labels = rows?.map((row) => row.label);

    const headers = ['Data', ...labels];

    const options = {
      fieldSeparator: ',',
      showLabels: true,
      filename: `${name}_carteira`,
      headers,
    };

    const dataToExport = periods.map((period) => {
      const periodIndex = periods.findIndex((p) => p === period);
      const parsedColumns = rows.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.slug]: convertNumber(curr?.data[periodIndex], 2),
        }),
        {},
      );
      return {
        date: period,
        ...parsedColumns,
      };
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  };

  if (loading) return <CircularProgress size="1.8rem" color="secondary" />;

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.exportButton}
        onClick={exportDataToCSV}>
        <img src={csvSvg} alt="export" className={classes.exportIcon} />
        Exportar
      </Button>
      <TableContainer innerRef={tableRef}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Data</TableCell>
              {[...rows]?.reverse()?.map((row, index) => (
                <TableCell
                  key={`${index}-header`}
                  align="center"
                  className={classes.tableCell}>
                  {row.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...periods]
              ?.reverse()
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((period, index) => {
                const periodIndex = periods.findIndex((p) => p === period);
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      {period}
                    </TableCell>
                    {[...rows]?.reverse()?.map((row, index) => (
                      <TableCell
                        key={`${index}-row`}
                        align="center"
                        className={classes.tableCell}>
                        {toCurrency(row?.data[periodIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                count={periods?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={'Registros por página'}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
