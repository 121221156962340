// Material ui components
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Global Components
import { convertNumber, convertValue, toCurrency } from '../../utils/number';
import DateChip from '../DateChip';
// util

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
  },
  paper: {
    marginLeft: 3,
    padding: theme.spacing(3, 2),
    minHeight: 150,
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  infoOutlinedIcon: {
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blur: {
    '-webkit-filter': 'blur(5px)',
  },
  dateChip: {
    alignSelf: 'end',
    paddingBottom: '1rem',
    width: '50%',
    display: 'flex',
  },
}));

export default function PaperPanelInfo({
  number,
  numeric,
  million,
  title,
  value,
  first,
  operationTicker,
  operationB3Ticker,
  operationCodeTipif,
  operationCodeIsIn,
  operationLastDistributed,
  date,
  dateTitle,
  lock,
  loading,
}) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        zIndex="mobileStepper"
        height={55}
        className={classes.box}
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            square
            className={classes.paper}
            style={first && { backgroundColor: '#fafafa' }}>
            <Box width="100%">
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" />
              ) : (
                <>
                  {numeric && !lock ? (
                    <Typography variant="subtitle2">
                      {value && !isNaN(value) ? (
                        <>
                          {million
                            ? `${convertValue(value)}`
                            : toCurrency(value)}
                        </>
                      ) : (
                        'N/D'
                      )}
                    </Typography>
                  ) : number ? (
                    <Typography variant="subtitle2">
                      {value && !isNaN(value) ? (
                        <>{convertNumber(value)}</>
                      ) : (
                        'N/D'
                      )}
                    </Typography>
                  ) : operationTicker ? (
                    <Typography variant="subtitle2">
                      {operationTicker}
                    </Typography>
                  ) : operationB3Ticker ? (
                    <Typography variant="subtitle2">{`B3: ${operationB3Ticker}`}</Typography>
                  ) : operationCodeTipif ? (
                    <Typography variant="subtitle2">
                      {`IF: ${operationCodeTipif}`}
                    </Typography>
                  ) : operationCodeIsIn ? (
                    <Typography variant="subtitle2">{`ISIN: ${operationCodeIsIn}`}</Typography>
                  ) : operationLastDistributed ? (
                    <Typography variant="subtitle2">
                      {operationLastDistributed
                        ? `${toCurrency(operationLastDistributed)} / cota`
                        : 'N/D'}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      className={`${lock && classes.blur}`}>
                      {value ? value : 'N/D'}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {date && !loading && (
              <Box className={classes.dateChip}>
                <DateChip title={dateTitle} date={date} loading={loading} />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

PaperPanelInfo.defaultProps = {
  million: true,
};
