import { useState } from 'react';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { If } from 'src/components/If';

function PasswordField({ ...props }: TextFieldProps) {
  const [type, setType] = useState('password');
  const handleChangeInputType = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };
  return (
    <TextField
      {...props}
      type={type}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => handleChangeInputType()}>
            <If condition={type === 'password'}>
              <VisibilityOffOutlinedIcon />
            </If>
            <If condition={type === 'text'}>
              <VisibilityOutlinedIcon />
            </If>
          </IconButton>
        ),
      }}
    />
  );
}

export default PasswordField;
