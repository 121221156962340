import {
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import { useAuthContext } from '../../context/AuthContextProvider';

const useStyles = makeStyles((theme) => ({
  paywallSector: (props) => ({
    minHeight: props.minHeight ?? 350,
    width: props.width ?? 'auto',
    display: props.display ?? 'flex',
    flexDirection: props.flexDirection ?? 'column',
    justifyContent: props.justifyContent ?? 'center',
    background:
      props.background ??
      'linear-gradient(182.59deg, rgba(219, 219, 219, 0.489792) 10.58%, rgba(226, 226, 226, 0.55) 98.35%)',
    padding: props.padding ? theme.spacing(props.padding) : theme.spacing(2),
    textAlign: props.textAlign ?? 'center',
    margin: props.margin ? theme.spacing(props.margin) : 0,
    '& .paywallTitle': {
      fontWeight: '500',
      fontSize: '1.5rem',
      lineHeight: '29px',
      ...props.paywallTitle,
    },
    '& .paywallSubtitle': {
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: '17px',
      marginTop: '1rem',
      ...props.paywallSubtitle,
    },
  }),
}));

export function Paywall({
  allow = false,
  position = 'override',
  content = '',
  isLoading = false,
  children,
  ...props
}) {
  const classes = useStyles(props);
  const { auth } = useAuthContext();

  const paywallContent =
    auth.isLoading || isLoading ? (
      <CircularProgress variant="indeterminate" />
    ) : (
      <Paper elevation={0} square className={classes.paywallSector}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className="paywallTitle">{content}</Typography>
            <Typography className="paywallSubtitle">
              Para mais informações{' '}
              <Link color="secondary" href={'/planos'}>
                clique aqui.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );

  if (position === 'override') {
    return allow ? children : paywallContent;
  }

  if (position === 'before') {
    return allow ? (
      children
    ) : (
      <>
        {paywallContent}
        {children}
      </>
    );
  }

  if (position === 'after') {
    return allow ? (
      children
    ) : (
      <>
        {children}
        {paywallContent}
      </>
    );
  }
}
