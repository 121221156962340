import * as React from 'react';

import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';

import jsonSteps from './steps';
import logo from '../../assets/imgs/opsStart.svg';

const style = (step: number) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 644,
  height: 350,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.29)',
  boxShadow: 24,
  p: 4,
  paddingTop: '12px',
  paddingBottom: step === 0 ? '0px' : '48px',
  borderRadius: '30px',
  boxSizing: 'border-box',
});

const Step0 = ({ onClick, text }: { onClick: any; text: string }) => (
  <Box display="flex" height="90%" style={{ gap: '64px' }}>
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '34px',
          }}>
          {text}
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start" paddingBottom={4}>
        <Typography
          sx={{ fontWeight: 600, fontSize: '18px', paddingTop: '6px' }}>
          Vamos começar?
        </Typography>
        <IconButton
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          sx={{ color: '#828282', backgroundColor: '#F1F1F1', marginLeft: 1 }}>
          <KeyboardArrowRight fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
    <Box display="flex" alignItems="flex-end">
      <img width={251} height={251} src={logo} style={{ objectFit: 'cover' }} />
    </Box>
  </Box>
);

const StepGenerator = ({ title, description, image }: any) => (
  <Box
    display="flex"
    width="100%"
    height="90%"
    style={{ gap: '48px' }}
    justifyContent="space-between"
    sx={{ borderBottom: '1px solid #E0E0E0' }}>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '34px',
          }}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start" paddingTop={2}>
        <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>
          {description}
        </Typography>
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      <img
        width={228}
        height={228}
        src={image}
        style={{ objectFit: 'cover' }}
      />
    </Box>
  </Box>
);

function OnboardingModal({
  open,
  type,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
  type: keyof typeof jsonSteps;
}) {
  const [step, setStep] = React.useState(0);
  const onboardingText =
    type === 'operations'
      ? 'Nós da Uqbar preparamos um guia de utilização para você extrair seus dados já preparados para análise.'
      : 'Preparamos um guia de utilização para você utilizar o Radar com maestria!';

  const renderStep = React.useMemo(() => {
    const componentList = [
      <Step0
        key="0"
        text={onboardingText}
        onClick={() => setStep((s) => s + 1)}
      />,
    ];
    jsonSteps[type].forEach((step) => {
      componentList.push(
        <StepGenerator
          key={step.title}
          title={step.title}
          description={step.description}
          image={step.image}
        />,
      );
    });
    return componentList[step];
  }, [step]);

  const renderPagination = React.useMemo(() => {
    if (step === 0) return null;
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {step === 1 ? (
          <Box width={150} />
        ) : (
          <Button
            onClick={() => setStep((s) => s - 1)}
            sx={{ textTransform: 'capitalize' }}>
            Voltar
          </Button>
        )}
        <Box>
          {step} de {jsonSteps[type].length}
        </Box>
        {step === jsonSteps[type].length ? (
          <Button onClick={handleClose} sx={{ textTransform: 'capitalize' }}>
            Concluir
          </Button>
        ) : (
          <Button
            onClick={() => setStep((s) => s + 1)}
            sx={{ textTransform: 'capitalize' }}>
            Próximo
          </Button>
        )}
      </Box>
    );
  }, [step]);

  React.useEffect(() => {
    if (open) setStep(0);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="onboarding-title"
      aria-describedby="onboarding-description">
      <Box>
        <Box sx={style(step)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 -16px',
            }}>
            <IconButton
              aria-label="fechar"
              color="inherit"
              size="small"
              onClick={handleClose}>
              <Close
                fontSize="medium"
                sx={{
                  color: 'black',
                }}
              />
            </IconButton>
          </Box>
          {renderStep}
          {renderPagination}
        </Box>
      </Box>
    </Modal>
  );
}

export default OnboardingModal;
