import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  flex-wrap: wrap;
  padding: 60px 40px;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  box-shadow:
    1px 1px 10px 0.5px rgb(0, 0, 0, 0.1),
    -1px -1px 10px 0.5px rgb(0, 0, 0, 0.1);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const List = styled.ul`
  padding-left: 25px;
  font-size: 16px;
  li + li {
    margin-top: 40px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  span {
    padding: 0;
    margin-right: 10px;
    margin-left: -1px;
  }
`;

export const UpgradableWarn = styled.span`
  margin-top: 30px;
`;
