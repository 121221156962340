// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

// Global components
import GeneralDataItemConditional from '../../../../components/GeneralDataItemConditional';
// Apollo client
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_FII_DETAILS_GENERAL_DATA_BY_HASH_ID } from '../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIIGeneralData({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_FII_DETAILS_GENERAL_DATA_BY_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fii_dados_gerais'
      ],
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={2}>
      <Subtitle title="Dados Gerais" />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fii_dados_gerais'
          ]
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fii_dados_gerais'
          ]
        }>
        <Grid container>
          <Grid item xs={12} sm={4} md>
            <Box p={2}>
              <Typography variant="subtitle1" className={classes.title}>
                CNPJ
              </Typography>
              <Typography variant="subtitle2">
                {data?.getFiiDetailsGeneralDataByHashId?.operationCnpj}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md>
            <Box p={2}>
              <Typography variant="subtitle1" className={classes.title}>
                Ticker
              </Typography>
              <Typography variant="subtitle2">
                {data?.getFiiDetailsGeneralDataByHashId?.operationTickers}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md>
            <Box p={2}>
              <Typography variant="subtitle1" className={classes.title}>
                Prazo
              </Typography>
              <Typography variant="subtitle2">
                {data?.getFiiDetailsGeneralDataByHashId?.operationTerm}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={4} md>
            <Box p={2}>
              <Typography variant="subtitle1" className={classes.title}>
                Registro na CVM
              </Typography>
              <Typography variant="subtitle2">
                {
                  data?.getFiiDetailsGeneralDataByHashId
                    ?.operationCvmRegisterDate
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md>
            <GeneralDataItemConditional
              title="Multiclasses"
              condition={data?.getFiiDetailsGeneralDataByHashId?.isMulticlasses}
            />
          </Grid>
          <Grid item xs={12} sm={4} md>
            <GeneralDataItemConditional
              title="Listado na B3"
              condition={data?.getFiiDetailsGeneralDataByHashId?.isB3Listed}
            />
          </Grid>
        </Grid>
        <Paper square>
          <Box p={2}>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.title}>
              Objeto
            </Typography>
            <Typography variant="subtitle1">
              {data?.getFiiDetailsGeneralDataByHashId?.operationObject}
            </Typography>
          </Box>
        </Paper>
      </If>
    </Box>
  );
}
