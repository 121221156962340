import { useState } from 'react';

import { Button, Typography } from '@mui/material';
import { If } from 'src/components/If';
import Section from 'src/components/ProfileSection';
import { Institution } from 'src/types/institution';
import { sizeText, truncateText } from 'src/utils/string';

function About({ institution }: { institution: Institution }) {
  const [isReadMore, setIsReadMore] = useState(false);

  return (
    <Section title="Sobre">
      {Boolean(institution?.businessProfile) && (
        <>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
            }}
            dangerouslySetInnerHTML={{
              __html: isReadMore
                ? institution?.businessProfile
                : truncateText(institution?.businessProfile, 584),
            }}
          />
          {(sizeText(institution.businessProfile) <= 584 || isReadMore) &&
            institution?.cnpj && (
              <Typography marginTop={2}>
                {`CNPJ - ${institution?.cnpj}`}
              </Typography>
            )}
        </>
      )}

      <If
        condition={
          institution?.businessProfile &&
          institution?.businessProfile.length > 584
        }>
        <Button
          variant="text"
          color="primary"
          sx={{
            fontWeight: 500,
            marginTop: 2,
            minWidth: 'unset',
            padding: 0,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          onClick={() => setIsReadMore((prev) => !prev)}>
          {isReadMore ? 'ver menos' : 'ver mais'}
        </Button>
      </If>
    </Section>
  );
}

export default About;
