import React, { useRef } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import MetaTags from 'src/components/Seo/MetaTags';

import CRA from './CRA';
import CRI from './CRI';
import FIDC from './FIDC';
import FII from './FII';
import Intro from './LandingPage/Intro';
import LetterToReader from './LetterToReader';
import Sponsors from './Sponsors';
import VideoGallery from './VideoGallery';
import Menu from './components/Menu';
import ProductTab from './components/ProductTab';
import { pageData, setIndexes } from './data';
import * as S from './styles';
import { Banners, ChartsDataRequestPayload } from './types';
import { cast } from './utils';
import CardDemonstration from '../../../components/CardDemonstration/CardDemonstration';
import { getPayWallBanner } from '../../../services/informative';
import { getBanners, getChartsData } from '../../../services/yearbook';

type Props = {
  paywallBlur: boolean;
};

const Presentation = (props: Props) => {
  const [market, setMarket] = React.useState('');
  const [pageIndexes, setPageIndexes] = React.useState<
    Array<{ title: string; ref: React.MutableRefObject<any> }>
  >([]);
  const [banners, setBanners] = React.useState<Banners>([]);

  const [onTransition, setOnTransition] = React.useState(false);
  const [showMode, setShowMode] = React.useState(false);
  const [showProductTab, setShowProductTab] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [chartsData, setChartsData] = React.useState<
    ChartsDataRequestPayload[]
  >([]);

  const { market: marketUrlParams, section } = useParams<{
    market: string;
    section: string;
  }>();
  const history = useHistory();

  const videoGalleryRef = useRef(null);
  const sponsorsRef = useRef(null);
  const introRef = useRef(null);
  const letterRef = useRef(null);
  const linerRef = useRef<HTMLDivElement>(null);

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('yearbook'),
    {
      enabled: props.paywallBlur,
    },
  );

  const marketPagesData = market ? pageData[market] : [];

  const formatIndexes = (
    indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>,
  ) => {
    return [
      { title: 'Início', ref: introRef },
      {
        title: 'Patrocinadores',
        ref: sponsorsRef,
      },
      {
        title: 'Carta ao leitor',
        ref: letterRef,
      },
      ...indexes,
      {
        title: `GALERIA ANUÁRIO UQBAR 2023: ${market}`,
        ref: videoGalleryRef,
      },
    ];
  };

  const loadChartsData = async () => {
    const data: ChartsDataRequestPayload[] = await getChartsData();

    setChartsData(data);
  };

  const loadBanners = async () => {
    const banners = await getBanners();

    setBanners(banners);
  };

  React.useEffect(() => {
    loadChartsData();
    loadBanners();
  }, []);

  React.useEffect(() => {
    if (market) {
      const indexes = marketPagesData.map((d) => setIndexes(d));

      setPageIndexes(formatIndexes(indexes));
    } else {
      setPageIndexes([]);
    }
  }, [market]);

  React.useEffect(() => {
    if (pageIndexes.length > 4) {
      const handleScroll = () => {
        if (linerRef.current) {
          const lineRect = linerRef.current.getBoundingClientRect();

          pageIndexes.forEach((section, index) => {
            const sectionRect = section.ref.current?.getBoundingClientRect();

            if (
              sectionRect &&
              lineRect.bottom >= sectionRect.top &&
              lineRect.top <= sectionRect.bottom
            ) {
              setSelectedIndex(index);
              history.push(`/anuarios/2023/${market}/${section.title}`);
            }
          });
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [pageIndexes]);

  const handleOpen = (status: boolean) => {
    setShowMode(status);
    setOnTransition(true);
    setTimeout(() => {
      setOnTransition(false);
    }, 2000);
  };

  const menuProps = {
    open: showMode,
    handleOpen,
    pageIndexes,
    onTransition,
    selectedItem: selectedIndex,
    setSelectedItem: setSelectedIndex,
  };

  const getProductComponent = (market: string) => {
    if (market === 'CRA') return CRA;
    if (market === 'CRI') return CRI;
    if (market === 'FIDC') return FIDC;
    if (market === 'FII') return FII;

    return null;
  };

  const Product = getProductComponent(market);

  React.useEffect(() => {
    if (marketUrlParams) {
      setMarket(marketUrlParams);
    }
    const selectedSection = pageIndexes.find((p) => p.title === section);

    if (selectedSection) {
      setTimeout(() => {
        selectedSection?.ref?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  }, [pageIndexes]);

  React.useEffect(() => {
    if (market) {
      const sectionUrl = section ? `/${section}` : '';
      history.push(`/anuarios/2023/${market}${sectionUrl}`);
    }
  }, [market]);

  return (
    <S.PresentationWrapper>
      <MetaTags
        title="Anuários Uqbar Experience 2023"
        description="Mais do que uma publicação, uma experiência completa nos mercados de CRA, CRI, FIDC e FII"
      />
      <S.Liner ref={linerRef} />
      {props.paywallBlur && contentPayWallBanner?.yearbook && (
        <S.PayWallBannerWrapper>
          <CardDemonstration
            title={contentPayWallBanner?.yearbook?.title}
            subTitle={contentPayWallBanner?.yearbook?.subTitle}
            labelButton={contentPayWallBanner?.yearbook?.labelButton}
            url={contentPayWallBanner?.yearbook?.url}
          />
        </S.PayWallBannerWrapper>
      )}
      {!props.paywallBlur && (
        <S.TabWrapper hide={!showProductTab}>
          <ProductTab
            setMarket={setMarket}
            market={market}
            setOnTransition={setOnTransition}
            onTransition={false}
          />
        </S.TabWrapper>
      )}
      {market && !props.paywallBlur && <Menu {...menuProps} />}

      <S.Wrapper blured={props.paywallBlur}>
        <Intro
          pageRef={introRef}
          setMarket={setMarket}
          market={market}
          onTransition={onTransition}
          setOnTransition={setOnTransition}
          setShowMode={setShowMode}
          pageIndexes={pageIndexes}
          handleClose={() => handleOpen(false)}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          open={showMode}
          showProductTab={showProductTab}
          setShowProductTab={setShowProductTab}
          blockedView={props.paywallBlur}
        />

        {market && !props.paywallBlur && (
          <>
            <Sponsors
              pageRef={sponsorsRef}
              menuProps={menuProps}
              market={cast<'CRA' | 'CRI' | 'FIDC' | 'FII'>(market) || 'CRA'}
            />
            <LetterToReader pageRef={letterRef} open={showMode} />
          </>
        )}
        {Product && !props.paywallBlur && (
          <Product
            open={showMode}
            indexes={pageIndexes}
            chartsData={chartsData}
            banners={banners}
          />
        )}

        {market && !props.paywallBlur && (
          <VideoGallery
            market={market}
            pageRef={videoGalleryRef}
            menuOpened={showMode}
          />
        )}
      </S.Wrapper>
    </S.PresentationWrapper>
  );
};

export default Presentation;
