export type VideoData = {
  url: string;
  title: string;
  subtitle: string;
};

type Data = {
  cri: VideoData[];
  cra: VideoData[];
  fidc: VideoData[];
  fii: VideoData[];
};

export const data: Data = {
  cra: [
    {
      url: '809201952',
      title: 'CRAs com lastro em recebíveis pulverizados',
      subtitle: 'Ecoagro',
    },
    {
      url: '809201898',
      title: 'A Tecnologia a serviço das operações de crédito',
      subtitle: 'ACE',
    },
  ],
  cri: [
    {
      url: '809108150',
      title:
        'Securitização Imobiliária e o Mercado de Geração Distribuída de Energia',
      subtitle: 'Tozzine Freire',
    },
  ],
  fidc: [
    {
      url: '809086273',
      title:
        'Resolução CVM 175: os avanços do novo marco regulatório dos Fundos de Investimentos',
      subtitle: 'Laqus',
    },
    {
      url: '809111344',
      title: 'Conflitos de interesse no FIDC',
      subtitle: 'Laqus',
    },
    {
      url: '809113886',
      title:
        'Os CRAs estão com seus dias contados ou seguirão se consolidando cada vez mais no mercado de capitais?',
      subtitle: 'Master',
    },
    {
      url: '809109224',
      title: 'Sala Gold: FIDC, a interface entre o crédito e o capital',
      subtitle: 'Liberum',
    },
    {
      url: '809117328',
      title:
        'CVM autoriza FIDCs para investidores de varejo: O que isso significa para o mercado financeiro?',
      subtitle: 'Ouro Preto',
    },
    {
      url: '809205949',
      title: 'Inovações da Resolução 175',
      subtitle: 'Singulare',
    },
  ],
  fii: [],
};
