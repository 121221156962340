import theme from 'src/theme';
import styled from 'styled-components';

import * as YearbookSection from '../../components/Section';

export const Section = styled.section`
  min-height: 100dvh;
  position: relative;
`;

export const Title = styled(YearbookSection.Title).attrs({ fontSize: '3rem' })`
  font-weight: 400;
  line-height: 100%;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 2rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 125%;
  font-weight: 600;
  text-align: justify;
  cursor: default;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 0.9rem;
  }
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  height: calc(100% + 4rem);
  background-color: #ff8211;
  right: 0;
  border-top-left-radius: 450px;
  top: -8rem;
  padding: 4rem calc(80px + 4rem) 2rem calc(160px + 8rem);
  margin: 0 0 0 4rem;

  @media screen and (max-width: 1366px) {
    padding-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 3.5rem;
    border-top-left-radius: 225px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 999;
`;

export const Closing = styled.div`
  position: relative;
  text-align: right;
  margin-top: 4rem;
  z-index: 999;
`;

export const ClosingText = styled(YearbookSection.Title).attrs({
  fontSize: '1.25rem',
})`
  font-weight: 400;
  line-height: 180%;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 0.8rem;
  }
`;

export const LetterAuthor = styled(ClosingText)`
  text-decoration: none;
  cursor: pointer;
`;

export const EndBorder = styled.div`
  position: absolute;
  z-index: 999;
  left: -4rem;
  height: 120px;
  bottom: -1rem;
  width: 4rem;
  background-color: white;
  border-bottom-right-radius: 50px;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    left: -3.5rem;
    width: 3.5rem;
  }
`;

export const BackgroundEnd = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% + 4rem);
  background-color: #ff8211;
  border-bottom-right-radius: 180px;
  height: 220px;
  z-index: 997;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    height: 110px;
    border-bottom-right-radius: 90px;
  }
`;

export const TitleWrapper = styled.div`
  margin-top: 12rem;
  margin-bottom: 4rem;
  position: relative;
  z-index: 998;
`;
