import { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import PasswordField from 'src/components/FormsUI/PasswordField';
import { If } from 'src/components/If';
import PasswordRules from 'src/components/PasswordRules';
import SubTitle from 'src/components/UI/Subtitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { FieldTitle } from 'src/pages/Register/styles';
import { changePassword } from 'src/services/user';
import { setBannerMessage } from 'src/store/display';

import ProfileEmailModal from './ProfileEmailModal';
import { ValidateChangePassword } from '../validations';

function AccountManagement() {
  const { auth } = useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const changePasswordMutation = useMutation(changePassword, {
    onError: (error: any) => {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        formik.setErrors({
          currentPassword: error?.response?.data?.error?.message,
        });
      } else {
        dispatch(
          setBannerMessage({
            message: 'Ocorreu um erro durante a atualização, tente novamente',
            type: 'error',
            autoclose: false,
          }),
        );
      }
    },
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'Senha atualizada com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      formik.resetForm();
    },
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    validationSchema: ValidateChangePassword,
    onSubmit: (values) => {
      changePasswordMutation.mutateAsync(values);
    },
  });

  return (
    <Paper elevation={2} sx={{ marginTop: 4, padding: '56px 30px 10px' }}>
      <SubTitle title="Acesso à conta" />
      <Grid
        container
        sx={(theme) => ({
          padding: '48px 20px',
          [theme.breakpoints.down('sm')]: { padding: '48px 10px' },
        })}>
        <Grid item container>
          <Grid item xs={12} sm={4} lg={2}>
            <Typography fontWeight={700}>E-mail atual</Typography>
          </Grid>
          <Grid item xs={12} sm={8} lg={10}>
            <Typography fontSize={14}>{auth.user?.email}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ padding: '54px 0' }}>
          <Button
            variant="contained"
            sx={{ height: 40, fontWeight: 700 }}
            onClick={() => setOpenDialog(true)}>
            Alterar e-mail
          </Button>
        </Grid>
        <Box
          sx={(theme) => ({
            width: '40%',
            [theme.breakpoints.down('lg')]: {
              width: '60%',
            },
            [theme.breakpoints.down('md')]: {
              width: '80%',
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          })}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Grid
              container
              spacing={3}
              flexDirection="column"
              sx={(theme) => ({
                width: 'calc(100% + 10px)',
                [theme.breakpoints.down('sm')]: {
                  width: 'calc(100% + 30px)',
                },
              })}>
              <Grid item xs={12}>
                <Typography fontWeight={700}>Alterar senha</Typography>
              </Grid>
              <Grid item xs={12}>
                <FieldTitle>Senha atual*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <PasswordField
                    name="currentPassword"
                    placeholder="Insira sua senha atual"
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.currentPassword &&
                      Boolean(formik.errors.currentPassword)
                    }
                    helperText={
                      formik.touched.currentPassword
                        ? formik.errors.currentPassword
                        : ''
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FieldTitle>Nova senha*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <PasswordField
                    name="newPassword"
                    placeholder="Digite sua nova senha"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword
                        ? formik.errors.newPassword
                        : ''
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FieldTitle>Repetir senha*</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <PasswordField
                    name="repeatNewPassword"
                    placeholder="Repita a nova senha"
                    value={formik.values.repeatNewPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.repeatNewPassword &&
                      Boolean(formik.errors.repeatNewPassword)
                    }
                    helperText={
                      formik.touched.repeatNewPassword
                        ? formik.errors.repeatNewPassword
                        : ''
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <PasswordRules
              password={formik.values.newPassword}
              canVerify={formik.touched.newPassword}
            />
            <Grid item container sx={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={4} lg={5}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ height: 40, fontWeight: 700 }}>
                  {changePasswordMutation.isLoading ? (
                    <CircularProgress sx={{ color: '#FFFFFF' }} />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} lg={7}>
                <Button
                  type="reset"
                  variant="outlined"
                  color="secondary"
                  sx={(theme) => ({
                    height: 40,
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      marginTop: theme.spacing(1),
                    },
                  })}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      <If condition={openDialog}>
        <ProfileEmailModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      </If>
    </Paper>
  );
}

export default AccountManagement;
