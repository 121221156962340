import theme from 'src/theme';
import styled from 'styled-components';

import { Advertisement } from '../../components/Advertisement';
import * as YearbookSection from '../../components/Section';

export const Section = styled.section`
  padding: 2rem 0 2rem;
  min-height: 100dvh;
  position: relative;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 1rem 0 0;
  }
`;

export const Title = styled(YearbookSection.Title)`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const Description = styled.div`
  margin: 0 calc(80px + 4rem) 0 calc(160px + 8rem);
  margin-top: 2rem;

  @media screen and (max-width: 1366px) {
    margin-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const DescriptionParagraph = styled(YearbookSection.Paragraph)`
  color: #585858;
  p {
    margin: 0;
  }
`;

export const Content = styled.div`
  margin-top: 4rem;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`;

export const ChartContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  flex: 1;

  padding-left: 8rem;

  @media screen and (max-width: 1200px) {
    padding-left: 4rem;
  }

  @media screen and (max-width: 1000px) {
    padding-left: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0 0 0 4rem;
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 750px) {
    padding-right: 2rem;
  }
`;

export const Ads = styled(Advertisement)`
  margin-top: 0 !important;
`;

export const ChartTip = styled.p`
  color: #afafaf;
  text-align: center;
  font-weight: bold;

  margin-bottom: 0.25rem;
`;

export const Button = styled.a`
  align-self: flex-end;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  background-color: #ff8211;
  color: white;
  padding: 1rem 4rem;
  border-radius: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 2rem 1rem 2rem;

  @media screen and (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 1rem 2rem;
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    align-self: center;
  }
`;
