// Manage date
// Material ui components
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { format, parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
  chip: {
    marginLeft: '0.2rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    cursor: 'pointer',
  },
  title: {
    display: '-webkit-box',
    textAlign: 'justify',
    height: '70px',
    fontSize: '1rem',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  action: {
    padding: theme.spacing(1, 2),
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItens: 'start',
  },
  vehicleText: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    marginTop: theme.spacing(1),
  },
}));

export default function MediaCard({
  title,
  image,
  vehicle,
  publishDate,
  link,
}) {
  const classes = useStyles();

  function onClick(link) {
    window.open(link);
  }

  return (
    <Card square className={classes.card} onClick={() => onClick(link)}>
      <CardMedia
        className={classes.media}
        image={image?.formats?.small?.url ?? image?.url}
        title={title}
      />
      <CardContent>
        <Typography variant="h6" component="h5" className={classes.title}>
          {title}
        </Typography>
      </CardContent>
      <CardActions className={classes.action}>
        <Grid container spacing={1} className={classes.actionsContainer}>
          <Grid item>
            <Typography
              variant="body2"
              component="p"
              className={classes.vehicleText}>
              {vehicle?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" component="p">
              {format(
                parse(publishDate, 'yyyy-MM-dd', new Date()),
                'dd/MM/yyyy',
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.icon}>
          <OpenInNewIcon fontSize="medium" />
        </Grid>
      </CardActions>
    </Card>
  );
}
