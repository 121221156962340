import { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { If } from 'src/components/If';
import useWindowDimensions, { useSectionInScreen } from 'src/utils/hooks';

import MarketSelectorDropdown from './MarketSelectorDropdown';
import * as Menu from './styles';
import { YearbookMarket, YearbookRouteParams } from '../../types';
import {
  getSectionElement,
  getYearbookSectionsName,
  scrollSmoothToElement,
} from '../../utils';

interface MenuNavigatorProps {
  markets: YearbookMarket[];
  selectedMarket: YearbookMarket | null;
  selectMarket: (market: YearbookMarket) => void;
}

export default function MenuNavigator(props: MenuNavigatorProps) {
  const [isMarketSelectorOpen, setIsMarketSelectorOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [sections, setSections] = useState<string[]>([]);
  const currentSection = useSectionInScreen();
  const { params } = useRouteMatch<YearbookRouteParams>();
  const { screenWidth } = useWindowDimensions();

  useEffect(() => {
    if (!props.selectedMarket) setIsMarketSelectorOpen(true);
    else setIsMarketSelectorOpen(false);
  }, [props.selectedMarket]);

  function toggleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  function selectMarket(market: YearbookMarket) {
    props.selectMarket(market);
    setIsMarketSelectorOpen(false);
    navigateToSection('Inicio');
  }

  function navigateToSection(sectionName: string, wait?: boolean) {
    if (wait) {
      const observer = new MutationObserver(() => {
        const element = getSectionElement(sectionName);
        if (element) {
          scrollSmoothToElement(element);
          observer.disconnect();
        }
      });
      observer.observe(document, { subtree: true, childList: true });
    } else {
      const element = getSectionElement(sectionName);
      scrollSmoothToElement(element);
    }
  }

  useEffect(() => {
    if (params.section) navigateToSection(params.section, true);
  }, []);

  function getMarketIconPath(name: string) {
    return require(`src/assets/imgs/yearbook/2024/icons/${name}.svg`);
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const yearbookSections = getYearbookSectionsName();
      if (yearbookSections.length > 1) {
        setSections(yearbookSections);
        observer.disconnect();
      }
    });
    observer.observe(document, { subtree: true, childList: true });
  }, [props.selectedMarket]);

  return (
    <>
      <If condition={!isMobileMenuOpen}>
        <Menu.Hamburguer onClick={toggleMobileMenu} />
      </If>
      <If condition={isMobileMenuOpen || screenWidth > 1366}>
        <Menu.Container>
          <Menu.Close onClick={toggleMobileMenu} />
          {props.selectedMarket && (
            <>
              <Menu.ReadingSection>Você está lendo: </Menu.ReadingSection>
              <MarketSelectorDropdown
                selected={props.selectedMarket}
                selectMarket={selectMarket}
              />
            </>
          )}
          <If condition={!isMarketSelectorOpen}>
            <Menu.NavigationSections>
              {sections.map((sectionName) => (
                <Menu.NavigationSection
                  key={sectionName}
                  active={currentSection === sectionName}
                  onClick={() => navigateToSection(sectionName)}>
                  {sectionName}
                </Menu.NavigationSection>
              ))}
            </Menu.NavigationSections>
          </If>
          <If condition={isMarketSelectorOpen}>
            <Menu.MarketSelectorList>
              {props.markets.map((market) => {
                if (market === props.selectedMarket) return null;

                return (
                  <Menu.MarketSelector
                    expanded
                    key={market.name}
                    onClick={() => selectMarket(market)}>
                    <Menu.MarketSelectorText>
                      {market.name}
                    </Menu.MarketSelectorText>
                    <Menu.MarketSelectorIcon
                      src={getMarketIconPath(market.name).default}
                    />
                  </Menu.MarketSelector>
                );
              })}
            </Menu.MarketSelectorList>
          </If>
        </Menu.Container>
      </If>
    </>
  );
}
