import { Box, Divider, Typography } from '@mui/material';

export default function SubTitle({ title }: { title: string }) {
  return (
    <Box my={3}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography
          variant="h5"
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '26.4px',
            marginLeft: 1,
          }}>
          {title}
        </Typography>
        <Divider
          sx={() => ({
            marginY: '20px',
            height: '1px',
            backgroundColor: 'black',
            width: '100%',
          })}
        />
      </Box>
    </Box>
  );
}
