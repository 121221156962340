import { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { Dashboard } from 'src/types/radar';

const SLICE_THRESHOLD = 0.025;

const OTHER_SLICE_MIN_PERCENTAGE = 0.003;

export default function PieChart({ dashboard }: { dashboard: Dashboard }) {
  let content = dashboard.content;

  const { scale = undefined } = dashboard.settings?.visualization_settings
    ?.column_settings
    ? Object.values(
        dashboard.settings.visualization_settings?.column_settings as any[],
      )[0]
    : {};

  const metricKey = dashboard.settings.result_metadata.find(
    (column: any) =>
      column.name === dashboard.settings.visualization_settings['pie.metric'],
  ).display_name;

  if (content?.length && isNaN(content[0][metricKey])) {
    content = content.map((row: any) => ({
      ...row,
      [metricKey]:
        parseFloat(row[metricKey].replace(/[^\d,]+/g, '').replace(',', '.')) /
        (scale ?? 1),
    }));
  }

  const total = content.reduce(
    (sum: number, serie: any) => sum + serie[metricKey],
    0,
  );

  const [slices, others] = _.chain(content)
    .map((row, index) => ({
      label:
        row[_.get(dashboard.settings.visualization_settings, 'pie.dimension')],
      value: row[metricKey],
      percentage: row[metricKey] / total,
      rowIndex: Number(index),
    }))
    .partition((row) => row.percentage > SLICE_THRESHOLD)
    .value();

  const otherTotal = others.reduce((acc, other) => acc + other.value, 0);

  const otherSlice =
    others.length === 1
      ? others[0]
      : {
          label: 'Outros',
          rowIndex: -1,
          value: otherTotal,
          percentage: otherTotal / total,
        };

  if (otherSlice.value > 0) {
    if (otherSlice.percentage < OTHER_SLICE_MIN_PERCENTAGE) {
      otherSlice.value = total * OTHER_SLICE_MIN_PERCENTAGE;
    }
    slices.push(otherSlice);
  }

  const series: number[] = slices.map((slice) => slice.value);

  const labels: string[] = slices.map((slice) => slice.label);

  const formatValue = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(value);

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        fontFamily: 'Inter',
        id: dashboard.name,
        type: 'donut',
        animations: {
          enabled: false,
        },
      },
      labels,
      legend: {
        position: 'left',
        horizontalAlign: 'left',
      },
      tooltip: {
        y: {
          formatter: (value, { seriesIndex }) => {
            const slice = slices.find((_, index) => index === seriesIndex);
            return slice?.rowIndex === -1
              ? _.orderBy(others, ['value'], ['desc'])
                  .map((other) => `${other.label}: ${formatValue(other.value)}`)
                  .join('<br/>')
              : formatValue(value);
          },
          title: {
            formatter: (seriesName) =>
              seriesName !== 'Outros' ? seriesName : '',
          },
        },
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '18px',
              },
              value: {
                show: true,
                fontSize: '16px',
                formatter: (value) => formatValue(Number(value)),
              },
              total: {
                show: true,
                fontSize: '16px',
                formatter: (w) =>
                  formatValue(
                    w.globals.seriesTotals.reduce(
                      (a: number, b: number) => a + b,
                      0,
                    ),
                  ),
              },
            },
          },
        },
      },
    }),
    [dashboard, labels, slices, series],
  );

  return (
    <ReactApexChart
      type="donut"
      options={options}
      series={series}
      width="100%"
      height="100%"
    />
  );
}
