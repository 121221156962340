import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { getPlansFAQ } from 'src/services/plans';

type FAQ = {
  sections: {
    title: string;
    questions: {
      id: number;
      question: string;
      answer: string;
    }[];
  };
};

function PlansFAQ() {
  const { data } = useQuery<FAQ>('plansFaq', getPlansFAQ);

  return (
    <Box>
      <Typography
        sx={{ fontSize: '32px', textAlign: 'center', fontWeight: 700 }}>
        {data?.sections?.title}
      </Typography>
      <Box sx={{ margin: '0 auto' }}>
        {data?.sections?.questions?.map(({ question, answer }, index) => (
          <Box key={`question-${index}}`} sx={{ marginTop: '25px' }}>
            <Accordion square elevation={1}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PlansFAQ;
