/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';

import { data, VideoData } from './data';
import * as S from './styles';
import SectionMarker from '../components/SectionMarker';
import Tab from '../components/Tab';
import Title from '../components/Title';
import { Body, Main, MenuPlaceholder } from '../components/layout-elements';

type Props = {
  pageRef: React.MutableRefObject<any>;
  market: string;
  menuOpened: boolean;
};

const cast = <T,>(v: any): T => {
  return v;
};

const formatThumbUrl = (videoId: string) =>
  `https://vumbnail.com/${videoId}.jpg`;

const formatThumbUrlSet = (videoId: string) => {
  return [
    `https://vumbnail.com/${videoId}.jpg 640w`,
    `https://vumbnail.com/${videoId}_large.jpg 640w`,
    `https://vumbnail.com/${videoId}_medium.jpg 200w`,
    `https://vumbnail.com/${videoId}_small.jpg 100w`,
  ].join(',');
};

const formatVideoUrl = (videoId: string) =>
  `https://player.vimeo.com/video/${videoId}`;

const VideoGallery = (props: Props) => {
  const [selectedVideo, setSelectedVideo] = useState<VideoData>({
    url: '',
    title: '',
    subtitle: '',
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const videoList = React.useMemo(
    () =>
      selectedTab === 0
        ? [...data.cra, ...data.cri, ...data.fidc, ...data.fii]
        : data[
            cast<'fidc' | 'cra' | 'cri' | 'fii'>(
              props.market.toLocaleLowerCase(),
            )
          ],
    [selectedTab, props.market],
  );

  React.useEffect(() => {
    if (videoList.length) {
      setSelectedTab(1);
    }
  }, [props.market]);

  React.useEffect(() => {
    if (videoList[0]) {
      setSelectedVideo(videoList[0]);
    } else {
      setSelectedTab(0);
    }
  }, [videoList]);

  return (
    <Body>
      <SectionMarker
        menuOpened={props.menuOpened}
        title="Galeria dos patrocinadores"
      />
      <Main ref={props.pageRef}>
        <MenuPlaceholder open={props.menuOpened} />
        <S.Content>
          <Title title={`Galeria Anuário Uqbar 2023: ${props.market}`} />
          <S.PlayerWrapper>
            <S.VideoWrapper>
              <S.Video
                src={formatVideoUrl(selectedVideo.url)}
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                width="100%"></S.Video>
              <S.Description>
                <S.SubTitle>{selectedVideo.title}</S.SubTitle>
                <S.Sponsor>{selectedVideo.subtitle}</S.Sponsor>
              </S.Description>
            </S.VideoWrapper>

            <S.PlayList>
              <Tab
                handleChange={(_e, value) => setSelectedTab(Number(value))}
                tabValue={selectedTab}
                tabData={[{ label: 'Todos' }, { label: 'Relacionados' }]}
                noBorder
              />
              <S.ListWrapper>
                {videoList.map((videoData) => (
                  <S.PlaylistItem
                    key={videoData.url}
                    selected={videoData.url === selectedVideo.url}
                    onClick={() => setSelectedVideo(videoData)}>
                    <img
                      srcSet={formatThumbUrlSet(videoData.url)}
                      sizes="(max-width: 640px) 100vw, 640px"
                      src={formatThumbUrl(videoData.url)}
                    />
                    <S.ItemText>
                      <span>{videoData.title}</span>
                      <span>{videoData.subtitle}</span>
                    </S.ItemText>
                  </S.PlaylistItem>
                ))}
              </S.ListWrapper>
            </S.PlayList>
          </S.PlayerWrapper>
        </S.Content>
      </Main>
    </Body>
  );
};

export default VideoGallery;
