import { Box, Card, CardActionArea, CardMedia, Grid } from '@material-ui/core';

import breakDefault from '../../../assets/imgs/break-default.svg';

export default function Organizers({ classes, organizers }) {
  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={1}>
        {organizers.map(({ id, name, logo, url }) => (
          <Grid item xs={4} key={id}>
            <CardActionArea onClick={() => window.open(url)}>
              <Card square variant="outlined">
                <CardMedia
                  className={classes.organizersMedia}
                  image={logo?.formats?.thumbnail?.url ?? breakDefault}
                  title={name}
                />
              </Card>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
