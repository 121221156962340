// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Apollo client
import DateChip from '../../../../../components/DateChip';
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_QUOTES_AND_NET_WORTH_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';
// Global components

export default function OperationFIILiabilityData({ hash }) {
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_FII_QUOTES_AND_NET_WORTH_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fii_passivo'
      ],
    },
  );

  if (error) return null;

  return (
    <div>
      <Subtitle title="Dados" />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <DateChip
          title="Competência"
          date={`${data?.getFiiQuotesAndNetWorthByOperationHashId.competenceMonth}/${data?.getFiiQuotesAndNetWorthByOperationHashId.competenceYear}`}
          loading={loading}
        />
      </Box>
      <Box py={2}>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fii_passivo'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions['operacoes_fii_passivo']
          }>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <PaperPanelInfo
                numeric
                title="Patrimônio Líquido"
                value={
                  data?.getFiiQuotesAndNetWorthByOperationHashId
                    .operationNetWorth !== null
                    ? data?.getFiiQuotesAndNetWorthByOperationHashId
                        .operationNetWorth
                    : 'N/D'
                }
                loading={loading}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </div>
  );
}
