import React from 'react';

import * as S from './styles';
import authorImage from '../../../../../assets/imgs/yearbook/authors/author.png';
import { replaceStringifiedElements } from '../Page/Text';

type Props = {
  name: string;
  description: string;
  image?: string | null | undefined;
};

const Author = (props: Props) => {
  const [image, setImage] = React.useState(authorImage);

  const importImage = async () => {
    if (props.image) {
      try {
        const avatar = await require(
          `../../../../../assets/imgs/yearbook/authors/${props.image}`,
        );

        if (avatar) setImage(avatar.default);
      } catch (error) {
        setImage(authorImage);
      }
    }
  };

  React.useEffect(() => {
    importImage();
  }, [props.image]);

  return (
    <S.Wrapper>
      <S.Image src={image} />
      <S.TextWrapper>
        <S.Divider />
        <div>{replaceStringifiedElements(props.name)}</div>
        <div>{props.description}</div>
      </S.TextWrapper>
    </S.Wrapper>
  );
};

export default Author;
