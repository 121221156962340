import { useState } from 'react';

import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import { useAnalyticalStyles } from './styles';
import { CustomSwitch } from '../../../components/CustomSwitch';
import { If } from '../../../components/If';
import RankingFilters from '../../../components/RankingFilters';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { findRanking } from '../../../services/ranking';
import { truncateText } from '../../../utils/string';
import { Statistics } from '../Statistics';
import { StatisticsResume } from '../StatisticsResume';
import { TableRanking } from '../TableRanking';

const numberCriterias = [
  'operations',
  'negotiatedefundnumber',
  'tradesnumber',
  'titlesnumber',
  'operationsissued',
  'trades',
  'negotiatedefundnumber',
  'titlesnumberclassified',
  'fundnumber',
];

const percentageCriterias = [
  'pricevariance',
  'dividendyield',
  'effectivereturn',
  'marketcapitalization',
];

export default function AnalyticalRanking({
  state,
  setState,
  hasConsolidated,
  markets,
  rankings,
  filters,
  methodology,
  handlePrint,
  loadingFilters,
}) {
  const classes = useAnalyticalStyles();
  const { auth } = useAuthContext();
  const [isReadMore, setIsReadMore] = useState(false);
  const [showStatisticResume, setShowStatisticResume] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [searchName, setSearchName] = useState('');
  const hasPermission =
    auth?.user?.subscription?.plan?.permissions['rankings_completo'];

  const { data, isLoading } = useQuery(
    ['rankings', state, filters],
    () =>
      findRanking({
        filters: {
          market: state.market.toLowerCase(),
          year: state.year,
          type: state.ranking,
          criteria: state.criteria,
          timeRange: hasConsolidated ? state.timeRange : 'default',
        },
        isLoggedIn: auth.isLoggedIn,
      }),
    {
      enabled: !loadingFilters,
    },
  );

  const criteriaName =
    filters &&
    filters[state?.market].rankings
      ?.find((ranking) => ranking.code === state.ranking)
      ?.criterias?.find((criteria) => criteria.code === state.criteria)?.name;

  return (
    <Box id="rankings-analytical" py={4} px={1}>
      <Grid container>
        <Grid item xs={12} md={9}>
          <RankingFilters
            markets={markets}
            state={state}
            setState={setState}
            classes={classes}
            rankings={rankings}
            data={filters}
            hasConsolidated={hasConsolidated}
            isLinked={!!state}
            hasYear
            hasCriteria
            typeRank="ranking_general"
            search={searchName}
            setSearch={setSearchName}
          />
        </Grid>
        <Grid item xs={12} md={3} className={classes.gridDate}>
          <Typography className="updated">
            {data && `Atualizado em ${data?.updatedAt}`}
          </Typography>
          <Typography className="baseMonth">
            {data &&
              `Mês base ${format(
                new Date(data?.year, data?.month - 1),
                'MM/yyyy',
              )}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 15 }}>
        <Grid item xs={12} sm={9} container>
          <Typography color="secondary" className={classes.title}>
            Ranking Uqbar
          </Typography>
          <Typography className={classes.title}>
            {isLoading ? <Skeleton width={200} /> : data?.label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.gridSwitch}>
          <Tooltip
            disableFocusListener
            disableTouchListener={hasPermission}
            disableHoverListener={hasPermission}
            arrow
            title={
              'É necessário obter um plano para utilizar essa funcionalidade'
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}>
            <Box className={classes.resumeSwitch}>
              <CustomSwitch
                color="secondary"
                checked={state.resume}
                onChange={(e) => {
                  setShowStatisticResume(e.target.checked);
                  setShowStatistics(false);
                }}
                inputProps={{ 'aria-label': 'trocar tipo de exibição' }}
                disabled={!hasPermission || !data}
              />
              <Typography variant="caption" style={{ color: '#6D6E71' }}>
                Resumo
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Box>
        {isReadMore ? (
          <Typography
            className={classes.textCriteria}
            dangerouslySetInnerHTML={{
              __html: methodology,
            }}
          />
        ) : (
          <Typography
            className={classes.textCriteria}
            dangerouslySetInnerHTML={{
              __html: truncateText(methodology, 50),
            }}
          />
        )}
        <If condition={methodology?.length > 50}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setIsReadMore((prev) => !prev)}
            className={classes.readMoreButton}>
            {isReadMore ? 'Leia menos' : 'Leia mais'}
          </Button>
        </If>
      </Box>

      {!isLoading && showStatisticResume && (
        <StatisticsResume
          data={data?.ranks}
          setShowStatistics={setShowStatistics}
          showStatistics={showStatistics}
          state={state}
          numberCriterias={numberCriterias}
          valueLabel={data?.valueLabel}
        />
      )}

      {showStatistics ? (
        <Statistics
          state={state}
          numberCriterias={numberCriterias}
          valueLabel={data?.valueLabel}
          loading={isLoading}
        />
      ) : (
        <TableRanking
          loading={isLoading}
          rank={data?.ranks}
          valueLabel={data?.valueLabel}
          rankingLabel={data?.label}
          state={state}
          hasConsolidated={hasConsolidated}
          month={data?.month}
          searchName={searchName}
          criteriaLabel={criteriaName}
          numberCriterias={numberCriterias}
          percentageCriterias={percentageCriterias}
          handlePrint={handlePrint}
        />
      )}
    </Box>
  );
}
