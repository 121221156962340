import { useMemo } from 'react';

import { Box } from '@mui/material';
import SubscribeButton from 'src/pages/Event/MainPage/SubscribeButton';
import { getLargestImageUrl } from 'src/utils/image';
import styled from 'styled-components';

import { TabMobile } from './TabMobile';

interface TabTemplateMobileProps {
  tabIndex: number;
  menuItems: any[];
  eventData: any;
  handleChange: (tab: number) => void;
  refetch?: () => void;
}
export const TabTemplateMobile = ({
  eventData,
  menuItems,
  tabIndex,
  handleChange,
  refetch,
}: TabTemplateMobileProps) => {
  const content = useMemo(() => {
    return menuItems[tabIndex]?.content;
  }, [tabIndex]);

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          height: '130px',
        }}>
        <Image src={getLargestImageUrl(eventData.image)} />
        {!eventData?.termsOfUseLink ? (
          <SubscribeButton event={eventData} refetch={refetch} />
        ) : (
          <></>
        )}
      </Box>
      <TabMobile
        items={menuItems}
        currentTab={menuItems[tabIndex]}
        handleChange={handleChange}
      />
      {content}
    </Box>
  );
};

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 130px;
`;
