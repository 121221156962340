import { PageData } from '../types';

export const pageData: PageData[] = [
  {
    title: 'Abertura',
    subtitle: '',
    text: [
      `O mercado de Certificados de Recebíveis Imobiliários (CRI) atingiu um novo recorde em 2022, com o montante emitido ultrapassando a marca de R$ 51 bilhões. Uma característica que se destaca é o encurtamento do prazo de vencimento dos CRIs emitidos, refletindo, também, a preferência dos investidores por ativos de menor duração. 

      Uma novidade importante é a categoria de ativo-lastro de CRI chamada de Reembolso, que vem ganhando relevância. Além disso, o perfil das operações mantém-se corporativo, com risco de crédito concentrado e devedor pessoa jurídica.  Com base nas informações capturadas pela Uqbar, é possível inferir que parte dos recursos captados via CRI está sendo direcionada, em última instância, para o segmento de Apartamentos e Casas.
      
      O mercado secundário de CRI também registrou um aumento significativo, tanto em termos de montante negociado como em número de negócios. Os principais investidores de CRI continuam sendo os fundos de investimento. Com esse cenário, fica claro que o mercado de CRI continua a ser uma opção atraente tanto para investidores como para empresas que buscam alternativas de captação de recursos.`,
    ],

    authors: [],
  },
  {
    title: 'Mercado Primário',
    subtitle: '',
    text: [
      'O mercado de CRI bateu um novo recorde em 2022, com o montante emitido atingindo a marca de R$ 51 bilhões. No entanto, uma mudança significativa foi percebida no prazo dos CRI emitidos, que encurtou em relação aos anos anteriores. O perfil das operações também se manteve concentrado em devedores pessoa jurídica e risco de crédito corporativo. Além disso, uma nova categoria de ativo-lastro, o reembolso, despontou como relevante no mercado, superando, inclusive, Aluguel. Em termos de segmento imobiliário dos CRIs emitidos, Apartamentos e Casas obtém destaque significativo, seguido por Lajes Corporativas e Logístico e Industrial. O CDI voltou a ser utilizado em maior escala como componente da remuneração dos CRI, mas o IPCA se mantém hegemônico. Naturalmente, o principais investidores continuam sendo os fundos imobiliários.',
      `
      <b>Emissões de CRIs superam R$ 51 bi e batem recorde do ano anterior</b>
      O mercado primário de CRI vem apresentando um desempenho cada vez mais positivo, consolidando-se como uma importante alternativa de captação de recursos para o setor imobiliário. Em 2022, o mercado primário de CRIs atingiu a cifra de R$ 51,16 bilhões, distribuídos em 447 operações. Comparativamente, em 2021 os números haviam sido de R$ 40,53 bilhões e 477 operações. Esses números representam um crescimento significativo em relação aos anos anteriores, o que evidencia a crescente demanda por esse tipo de investimento.
      Além do volume financeiro, e do número de operações, o número de títulos emitidos também é uma métrica importante para se avaliar a força do mercado primário de CRIs. Em 2022, esse número chegou a 794, contra 820 no ano anterior.`,
      `
      <b>ICVM 476 é regime de distribuição preferido em 2022</b>
      Em 2022, o montante emitido de CRI distribuído via ICVM 476 representou 78,54% do total, enquanto os 21,46% restantes ficaram com a oferta ICVM 400, repetindo a preferência dos anos anteriores. Embora a ICVM 400 seja mais custosa do que a ICVM 476, normalmente ela é preferida nas emissões de CRIs com lastro em debêntures e que visem o perfil de investidor do varejo. Por outro lado, a ICVM 476 é suficiente para os casos em que o CRI é adquirido por um fundo imobiliário ou um fundo de investimento financeiro.
      `,
      `
      <b>Risco de Crédito dos CRIs de 2022 se torna ainda mais concentrado</b>
      Os CRIs de risco de crédito concentrado, os quais, é possível inferir, têm perfil corporativo, continuam sendo maioria em mais um ano, com um aumento de 3,27 pontos percentuais em relação ao ano anterior (de 78,38% para 81,65%). Esse fato demonstra que o ponto focal do mercado ainda está no risco de crédito concentrado, indicando que o mercado ainda não atingiu o cenário ideal em que os CRIs pulverizados, e, portanto, de perfil residencial, predominam.
      `,
      `
      <b>Pessoas Jurídicas são os principais devedores do lastro dos CRIs</b>
      Quando se olha para o horizonte de cinco anos, o ano de 2022 é marcado por um recorde no perfil do devedor, uma vez que a expressiva quantia de 91,70% do montante está relacionada a pessoas jurídicas. Em segundo lugar estão as pessoas físicas, representando 3,78% das emissões.
      `,
      `
      <b>CDI volta a ganhar espaço na remuneração dos CRIs</b>
      No mercado de CRIs, o índice IPCA foi o principal indexador utilizado nas estruturas para remuneração dos investidores, representando 60,49% do total de operações emitidas em 2022. No entanto, houve uma queda significativa de 19,18 pontos percentuais em relação a 2021. Já a Taxa CDI esteve presente em 38,94% das operações, representando um aumento de 23,07 pontos percentuais em relação ao ano anterior. Esse movimento pode ser atribuído à conjuntura econômica provocada pela pandemia. Além disso, foi registrado um percentual de 0,36% de CRIs com taxas prefixadas.
      Diferentemente dos anos anteriores, nem todas as operações utilizaram indexadores de mercado. Uma das emissões adotou como remuneração uma taxa administrativa, mais precisamente a Taxa Referencial (TR), prática que não era observada desde 2016.
      `,
      `
      <b>Mais de 50% dos CRIs possuem vencimento entre 50 e 100 meses</b>
      Em 2022, mais de 50% dos CRIs tinham um prazo de vencimento entre 50 e 100 meses. A faixa de prazos entre 50 e 100 meses representou 56,71% do montante emitido, o que significa um aumento de 23,63 p.p. em relação a 2021. Por outro lado, os prazos de vencimento entre 100 e 150 meses representaram 20,74% do total, com queda de 10,54 p.p., enquanto os prazos entre 150 e 200 meses movimentaram 9,45% do montante emitido. A faixa de prazos entre 200 e 250 meses representou 3,46% das emissões.
      `,
      `
      <b>Reembolso supera Aluguel como ativo-lastro de CRI</b>
      Em 2022, o Crédito Imobiliário continua sendo o líder em termos de ativo-lastro, assim como nos anos anteriores, representando 69,62% das emissões. No entanto, houve uma mudança no paradigma em relação ao segundo lugar, que agora é ocupado pelos CRIs lastreados em Reembolso de despesas, totalizando aproximadamente 15,16% das emissões. É interessante notar que houve uma ruptura em relação aos períodos anteriores, em que o lastro originário de Aluguéis ocupava a segunda posição.`,
      `
      <b>Segmento de Apartamentos e Casas predomina entre CRIs de 2022</b>
      Em 2022, os CRIs emitidos no segmento de Apartamentos e Casas mantiveram sua posição dominante no mercado, representando cerca de 25,5% da cifra emitida. Esse resultado é semelhante ao do ano anterior, demonstrando a força e a estabilidade desse segmento no mercado imobiliário.
      Em segundo lugar, o segmento de Lajes Corporativas teve um desempenho significativo, alcançando uma leve vantagem de apenas 0,2 pontos percentuais em relação ao segmento Logístico e Industrial. 
      Vale destacar que, apesar de manter a posição dominante, o segmento de Apartamentos e Casas não teve um crescimento significativo em relação ao ano anterior.
      O crescimento do segmento de Lajes Corporativas pode ser atribuído à retomada da economia, enquanto o segmento Logístico e Industrial também também têm relação com isso, especialmente em um contexto de crescimento do comércio eletrônico e aumento da demanda por espaços de armazenamento e distribuição de produtos.
      `,
      `
      <b>Perfil dos investidores de CRIs em 2022</b>
      No ano de 2022, os fundos de investimentos, as pessoas físicas e as instituições participantes do consórcio de distribuição foram responsáveis por 76,4% da aquisição dos títulos de securitização imobiliários. A distribuição correspondente desses investidores foi de 35,8%, 22,9% e 17,7%, respectivamente.
      `,
    ],

    authors: [],
  },
  {
    title: 'Mercado Secundário',
    subtitle: '',
    text: [
      `Como naturalmente é feito, cabe um disclaimer ao que é considerado "secundário" pela Uqbar quando se trata de mercado de balcão organizado. Quanto mais distantes do momento de suas respectivas emissões, mais as transações dos títulos estão associadas com um mercado secundário genuíno. Isto justifica, assim, para o benefício de uma análise de melhor discernimento, a segmentação, por intervalo de tempo entre emissão e negociação, das transações realizadas de CRI.
        De forma geral, subentende-se que as negociações registradas de CRI ocorridas com intervalo de tempo entre a emissão do título e sua negociação inferior a 180 dias constituem transações mais relacionadas ao mercado primário. Por outro lado, embora existam exceções, supõe-se que a maioria das negociações ocorridas com intervalo de tempo, desde a emissão, de mais de 180 dias esteja genuinamente associada ao mercado secundário.`,
      `
      <b>Crescimento impressionante no mercado secundário de CRI em 2022</b>
      O mercado secundário de CRI registrou um crescimento exponencial em 2022, com um montante negociado total de R$ 72,93 bilhões, um aumento de 81,69% em relação a 2021. Comparado a 2020, houve um crescimento de 342,81%, correspondente a R$ 16,47 bilhões. Em comparação com 2019, o crescimento foi de 417,23%, equivalente a R$ 14,10 bilhões, e em relação a 2018, houve um aumento de 778,67%, correspondente a R$ 8,30 bilhões.
      Além disso, o número de negócios realizados no mercado secundário de CRI também cresceu em 2022, atingindo a marca de 179.969, um aumento de 73,52% em relação a 2021. Em 2020, foram realizados 73.395 negócios, 145,21% menor do que em 2022. Em 2019, foram registrados 61.423 negócios, 118.546 negócios a menos do que em 2022, um aumento de 193%. Em 2018, foram realizados 43.415 negócios, o que representa um aumento de 314,53% em relação a 2022.
      `,
    ],

    authors: [],
  },
  {
    title: 'Estoque',
    subtitle: '',
    text: [
      `A tabela apresenta o saldo devedor dos CRI em dezembro de cada ano, desde 2018 até 2022. Observa-se que houve um crescimento significativo ao longo dos anos, passando de R$ 73,8 bilhões em 2018 para R$ 145,8 bilhões em 2022. 
      Pela sua natureza de mais longo prazo, os títulos de securitização prolongam no tempo o efeito de um incremento anual do montante de emissões e depósitos sobre o nível de estoque. Como o montante emitido de CRI se acelerou ao longo dos anos, e o prazo médio desses títulos passa dos dez anos, é natural que o saldo devedor dos CRI registre esse aumento paulatino.`,
      `
      A tabela apresenta os indicadores de estoque de CRI nos anos de 2018 a 2022. Observa-se um crescimento constante no estoque de CRI ao longo dos anos. Em dezembro de 2018, o estoque de CRI foi de R$ 73,8 bilhões e, em dezembro de 2019, houve um aumento de 12,4%, totalizando R$ 83,0 bilhões. Em dezembro de 2020, o estoque de CRI registrou um ligeiro aumento de 0,9%, chegando a R$ 83,7 bilhões.
      Já em dezembro de 2021, o estoque de CRI teve um crescimento significativo de 28,2%, atingindo R$ 107,4 bilhões. 
      Por fim, em dezembro de 2022, o estoque de CRI alcançou um patamar ainda mais alto, chegando a R$ 145,8 bilhões, um crescimento de 35,7% em relação ao ano anterior.
      `,
    ],

    authors: [],
  },
  {
    title: 'Rankings Uqbar',
    subtitle: '',
    text: [
      `Os Rankings Uqbar de 2022 para o mercado de CRI revelam novos participantes, como a Playbanco, uma nova securitizadora, e a retomada da liderança por participantes que outrora haviam ficado na segunda e/ou terceiras colocações. 
      Dito isso, é certo que os rankings tangibilizam um mercado ainda bastante concentrado. Seja em relação ao pequeno número de participantes, seja em relação ao percentual de participação dos primeiros colocados.`,
      `
      <b>Ranking de Operações de CRI por Montante Emitido em 2022</b>
      O ranking Uqbar de Operações  de CRI por montante emitido em 2022 apresenta três operações que correspondem a aproximadamente 7,6% da cifra anual. Em primeiro lugar, encontra-se a emissão 67 da Opea, com o montante de R$ 1,51 bilhão e dividida em três séries lastreadas em direitos creditórios devidos pela Petrobras. Na segunda posição, temos a quarta emissão da Virgo, com uma série única e um total de R$ 1,20 bilhão lastreado em direitos creditórios devidos pela CSN Cimentos. Em terceiro lugar, a Opea novamente aparece, desta vez com a emissão 1, dividida em duas séries, que correspondem a um montante total de R$ 1,14 bilhão lastreada em direitos creditórios devidos pela Rede D'Or. É importante deixar claro que as três maiores operações foram realizadas via ICVM 400, ou seja, por meio de esforços públicos.
      `,
      `
      <b>Ranking de Securitizadoras por Montante Emitido em 2022</b>
      A Opea é a securitizadora que emitiu o maior montante de CRI em 2022, totalizando expressivos R$ 17,82 bilhões, o que representa 34,97% do total emitido no ano. Além disso, a empresa tem duas operações entre as três maiores em montante emitido. Em segundo lugar, a True mantém a mesma posição do ano anterior, com R$ 12,69 bilhões, o que equivale a 24,91% do total. Em terceiro lugar, a Virgo aparece com R$ 10,71 bilhões em montante emitido no período, o que representa 21,01% do total. A empresa disruptou a primeira colocação do ano anterior.
      `,
      `
      <b>Ranking de Securitizadoras por Número de Operações em 2022</b>
      Ao analisar o ranking das securitizadoras em relação ao número de operações realizadas em 2022, nota-se que a Opea manteve sua posição de destaque, ocupando o primeiro lugar com uma participação de 25,8% e um total de 113 operações. A True também se destacou, mantendo a segunda posição do ano anterior, com uma participação de 19,41% e 85 operações realizadas. Por outro lado, a Virgo, que ficou em primeiro lugar no ano anterior, agora ocupa a terceira posição em 2022, com uma participação de 17,35% e 76 operações realizadas.
      `,
      `
      <b>Ranking de Advogados por Montante Emitido em 2022</b>
      O escritório I2A liderou em 2022 ranking de Advogados por montante emitido, com R$ 7,08 bilhões, correspondendo a 23,21% do total. O Mayer Brown ficou em segundo lugar com R$ 4,89 bilhões e 16,05% das emissões. E, em terceiro lugar, o escritório VBSO Advogados, com R$ 3,52 bilhões emitidos e 11,56% do total.
      `,
      `
      <b>Ranking de Advogados por Número de Operações em 2022</b>
      A NFA continua líder em 2022, assessorando 50 operações de CRI, representando 19,31% do total reportado. Em segundo lugar está a I2A, com 11,58% e 30 operações. A FreitasLeite ficou no terceiro lugar, com 10,42% e 27 operações.`,
      `
      <b>Ranking de Agências de Classificação de Risco por Montante Classificado em 2022</b>
      Em 2022, assim como no ano anterior, a Fitch e a S&P mantiveram as duas primeiras posições no ranking de agências de classificação de risco, enquanto a Moody's subiu para a terceira posição. A Fitch classificou 58,27% das emissões, o equivalente a um montante de R$ 7,09 bilhões. A S&P e a Moody's classificaram 28,13% e 12,73% das emissões, respectivamente, representando R$ 3,42 bilhões e R$ 1,55 bilhões em classificações.`,
      `
      <b>Ranking de Agências de Classificação de Risco por Número de Títulos Classificados em 2022</b>
      Em 2021, a Fitch ocupou a terceira posição no ranking de agências de classificação de risco em relação ao número de operações classificadas. No entanto, em 2022 a Fitch assumiu a primeira posição, classificando um total de 44 operações, o que representa 45,83% das classificações realizadas no período. A S&P e a Moody's ocupam o segundo lugar, com 37 e 10 operações classificadas, respectivamente, representando 38,54% e 10,42% do total.`,
      `
      <b>Ranking de Agentes Fiduciários por Montante Emitido em 2022</b>
      No ranking de Agentes Fiduciários por montante emitido de CRI em 2022, foram ranqueados 7 empresas, totalizando R$ 50,96 bilhões. A líder desse ranking é a Oliveira Trust, com um montante emitido de R$ 23,06 bilhões, correspondendo a 45,26% do total. Comparado ao ano anterior, a empresa teve um aumento de 89,4% no montante captado (R$ 12,24 bilhões), o que a colocou como líder do ranking. Em segundo lugar, temos a Vórtx, com um montante emitido de R$ 15,30 bilhões, ou seja, 30,03% do total. Esse valor é 30,23% menor que o valor captado pela empresa no ano anterior (R$ 21,93 bilhões), o que a levou a cair para a segunda posição no ranking. O Pentágono ocupa o terceiro lugar do ranking, com um montante emitido de R$ 9,72 bilhões, representando 19,07% do total. É importante destacar que 94,36% do total de montante emitido está concentrado em apenas três instituições: Oliveira Trust, Vórtx e Pentágono.`,
      `
      <b>Ranking de Agentes Fiduciários por Número de Operações em 2022</b>
      No ranking de Agentes Fiduciários por Número de Operações de CRI em 2022, temos 7 participantes nos pódios. A Oliveira Trust lidera o ranking com 249 operações, representando 56,85% do total, depois de ter ficado em 2º lugar no ano anterior. A Vórtx, que liderou o ranking em 2021, fica em 2º lugar com 137 operações, ou 31,28% do total. O Pentágono avança para a 3ª posição, com 29 operações, ou 6,62%, após ter ocupado a 4ª colocação no ano anterior. A Commcor e a Simplific Pavarini ficam na 4ª posição com 9 operações cada, ou 2,05% do total, e a Planner Corretora em 6º lugar, com 3 operações, ou 0,68%. É importante notar que 94,76% do total de operações estão concentrados nas instituições Oliveira Trust, Vórtx e Pentágono.`,
      `
      <b>Ranking de Líderes de Distribuição por Montante Emitido em 2022</b>
      O ranking dos líderes de distribuição apresenta a XP Investimentos na primeira posição, com R$ 9,70 bilhões em montante emitido, seguida pelo Banco Itaú BBA e USB Brasil. Em conjunto, as três primeiras instituições concentram 44,40% do total de montante emitido.
      `,
      `
      <b>Ranking de Líderes de Distribuição por Número de Títulos em 2022</b>
      Para o Ranking de Líderes de Distribuição foram computados 362 títulos em 2022, uma queda de 20,61% em relação a 2021. O Opea lidera com 40 títulos, seguido pela Terra Investimentos com 32 títulos. A True está em terceiro lugar com 28 títulos. A XP Investimentos e a Vert ocupam a quarta e quinta posição, respectivamente, ambas com 25 títulos distribuídos.
      `,
      `
      <b>Ranking de Servicers por Montante Emitido em 2022</b>
      O ranking de Servicers de CRIs totalizou R$ 7,30 bilhões divididos em 21 Servicers, com 56,34% concentrados nos 3 primeiros. Neo Service lidera com R$ 2,62 bilhões (35,91%), seguida pela MRV em 2ª posição com R$ 935,00 milhões (12,81%). Conveste Serviços Financeiros fica em 3º com R$ 556,04 milhões (7,62%). OGFI e Maximus completam o top 5.
      `,
      `
      <b>Ranking de Servicers por Número de Operações em 2022</b>
      O ranking apresenta 128 operações e 21 servicers, sendo que mais de metade está concentrado nos 3 primeiros colocados. A Neo Service lidera com 41,41% do total, seguida pela Certificadora de Créditos Imobiliários (9,38%) e Maximus (8,59%). Na 4ª posição, Conveste Serviços Financeiros e, na 5ª posição, Creditas Soluções Financeiras.
      `,
      `
      <b>Ranking de CRI por Montante Negociado em 2022 (+180 dias)</b>
      O mercado secundário de CRI em 2022 negociou um total de R$ 64,37 bilhões, representando um aumento de 79,6% em relação a 2021. Um CRI da True (série 236 da primeira emissão) liderou o ranking com R$ 2,30 bilhões, seguida por um CRI da Virgo (série 366 da quarta emissão) com R$ 2,20 bilhões e pelo CRI da série 477 da primeira emissão da True com R$ 1,57 bilhão.`,
      `
      <b>Ranking de CRI por Número de Negócios em 2022 (+180 dias)</b>
      Em 2022, o número de negócios no mercado secundário de CRI cresceu 73,54% em relação a 2021, totalizando 193.403 negociações. A série 208 da primeira emissão da Opea liderou o ranking com 9.514 negociações, seguida pela série 44 da primeira emissão da Ore com 5.439 negociações e a série 378 da primeira emissão da True com 4.263 negociações.
      `,
      `
      <b>Ranking de Securitizadoras por Saldo Devedor</b>
      O ranking de securitizadoras por preço unitário de CRI alcançou a cifra de R$ 145,84 bilhões em 2022, o que representa um aumento de 35,78% em comparação com o ano anterior, quando o valor era de R$ 107,41 bilhões. As três primeiras colocadas do ranking detêm 66,30% do total: a Opea lidera com R$ 41,11 bilhões, o que corresponde a 28,19% do estoque total; a True ocupa a segunda posição, com R$ 30,90 bilhões, representando 21,19%; a Virgo fica em terceiro lugar, com R$ 24,69 bilhões, correspondendo a 16,93% do total.
      `,
      `
      <b>Ranking de Securitizadoras por Número de Operações em Circulação</b>
      O ranking de Securitizadoras do mercado de CRI por Número de Operações contou com a participação de 21 instituições e um total de 440 operações. As três primeiras posições foram ocupadas por Opea, com 113 operações (25,80% do total), True, com 85 operações (19,41%), e Virgo, com 76 operações (17,35%). Observa-se que 74,13% do total de operações está concentrado nessas três securitizadoras.
      `,
      `
      <b>Ranking de Agentes Fiduciários por Saldo Devedor</b>
      O ranking de estoque por Agentes Fiduciários do mercado de CRI contou com a participação de sete empresas e somou um montante de R$ 146,71 bilhões, representando um aumento de 35,24% em relação ao ano anterior, quando o estoque girava em torno de R$ 108,48 bilhões. O ranking apresentou uma alta concentração, de 95%, nas três primeiras posições, que foram ocupadas pela Vórtx, líder com R$ 57,52 bilhões (39,20% do total), seguida pela Oliveira Trust, com R$ 50,34 bilhões (34,31%), e pela Pentágono, com R$ 31,51 bilhões (21,48%).
      `,
      `
      <b>Ranking de Agentes Fiduciários por Número de Operações em Circulação</b>
      O ranking de Securitizadoras do mercado de CRI por Número de Operações contou com a participação de 20 instituições e um total de 438 operações. As três primeiras posições foram ocupadas por Opea, com 113 operações (25,80% do total), True, com 85 operações (19,41%), e Virgo, com 76 operações (17,35%). Observa-se que 62,56% do total de operações está concentrado nessas três securitizadoras.
      `,
      `
      <b>Ranking de Auditores de Securitizadoras por Saldo Devedor</b>
      O ranking de Auditores de Securitizadoras por Estoque do mercado de CRI em 2022 contou com 17 participantes e somou um total de R$ 145,84 bilhões, representando um aumento de 35,7% em relação a 2021, quando o valor era de cerca de R$ 107,41 bilhões. As três primeiras posições do ranking detêm 77,73% do total, sendo lideradas pela KPMG, com R$ 48,31 bilhões e 33,13% do total, seguida pela BDO RCS Auditores Independentes, com R$ 33,77 bilhões e 23,15% do total, e pela BLB, com R$ 31,29 bilhões e 21,45% do total.
      `,
      `
      <b>Ranking de Auditores de Securitizadoras por Número de Operações em Circulação</b>
      O ranking de Auditores de Securitizadoras por Número de Operações do mercado de CRI em 2022 contou com a participação de 17 instituições e um total de 1497 operações, representando um aumento de 16,14% em relação a 2021, que contabilizou 1289 operações. As três primeiras posições foram ocupadas por KPMG, com 346 operações (23,11% do total), BDO RCS Auditores Independentes, com 334 operações (22,31%), e BLB, com 286 operações (19,10%). Observa-se que 64,52% do total de operações está concentrado nessas três empresas de auditoria.`,
    ],

    authors: [],
  },
  {
    title:
      'Inovações regulatórias no mercado de CRI em 2022 e perspectivas para 2023',
    subtitle: '',
    text: [
      `<b>O ano de 2022</b>
        Mesmo sendo um ano de eleições, 2022 foi um ano que representou mais um recorde no montante emitido de CRI, com um crescimento de 26,2% no montante emitido consolidado em relação a 2021: tivemos R$ 51,1 bilhões em operações em 2022 contra R$ 40,5 bilhões em 2021
        Do ponto de vista regulatório, foi um ano com muitas inovações, dentre as quais podemos citar (i) a entrada em vigor da Resolução CVM nº 60, aprovada no final de 2022, que criou o marco regulatório das securitizadoras; (ii) a publicação da Lei de Securitização; e (iii) o novo arcabouço legal de Ofertas Públicas.
        Merece destaque, ainda, (i) a decisão do Colegiado da CVM, que permitiu o pagamento de aluguel como destinação de CRI; e (ii) a recente inclusão das operações de CRI no âmbito do sandbox regulatório da CVM.
        <b>Marco regulatório das securitizadoras</b>
        A entrada em vigor da Resolução CVM nº 60, que trata do Marco Regulatório das Securitizadoras registradas na CVM, em maio de 2022, foi um marco importante para o mercado de securitização em 2022.
        A Resolução prevê como principal objetivo estabelecer regime próprio e específico para as securitizadoras, levando em consideração as especificidades do mercado de securitização, distinto do aplicável às demais companhias abertas. A norma também reconhece o caráter singular das securitizadoras, companhias que usualmente emitem ativos lastreados em patrimônios separados de seu próprio patrimônio.
        O Marco Regulatório das Securitizadoras aborda diversos aspectos relacionados às securitizadoras, como o registro perante a CVM, as suas obrigações, as assembleias de investidores, a prestação de serviços, o regime informacional da securitizadora e das operações, tratando também de seus aspectos operacionais e de conduta. Dessa forma, o Marco Regulatório das Securitizadoras consolidou, em uma única norma, as regras não só sobre as securitizadoras, mas também sobre os CRI e os CRA, revogando as Instruções CVM nºs 414, 443, 600 e 603, contribuindo para a racionalização da regulamentação sobre esse tema.
        <b>Nova Lei de Securitização</b>
        Outro tema importantíssimo foi a publicação, em 03 de agosto de 2022, da Lei nº 14.430, com a conversão da Medida Provisória nº 1.103, de 15 de março de 2022.
        Com a publicação da nova norma, os dispositivos antes esparsos sobre a securitização imobiliária (Lei nº 9.514, de 20 de novembro de 1997) e a securitização do agronegócio (Lei nº 11.076, de 30 de dezembro de 2004) passam a ficar concentrados em uma norma única, que agora abrange o mercado de securitização de uma maneira geral.
        Especialmente com relação às operações de securitização imobiliária, importante mencionar a possibilidade de (i) chamadas de capital, feitas de acordo com o cronograma esperado para a aquisição dos direitos creditórios; (ii) emissões em diferentes classes ou séries, inclusive com a possibilidade de aditamentos posteriores para inclusão de novas classes e séries e requisitos de complementação de lastro, quando for o caso; e (iii) aditamentos posteriores para inclusão de novas classes e séries e requisitos de complementação de lastro, quando for o caso.
        Na mesma linha, o § 2º do artigo 27 estabelece que “a companhia securitizadora, sempre que se verificar insuficiência do patrimônio separado, poderá, após restar assegurado o disposto no § 1º deste artigo, promover a sua recomposição, mediante aditivo ao termo de securitização ou instrumento equivalente, no qual serão incluídos outros direitos creditórios, com observância dos requisitos previstos nesta Seção e, quando ofertada publicamente, na forma estabelecida em regulamentação editada pela CVM”. Especialmente com relação ao mercado imobiliário, tal medida pode ser extremamente eficaz diante da possibilidade de distratos de compromissos de venda e compra de imóveis.
        A norma também trouxe grande segurança ao mercado, ao deixar claro, em seu § 4º do artigo 27, que “os dispositivos desta Lei que estabelecem a afetação ou a separação, a qualquer título, de patrimônio da companhia securitizadora a emissão específica de Certificados de Recebíveis <strong> produzem efeitos em relação a quaisquer outros débitos da companhia securitizadora, inclusive de natureza fiscal, previdenciária ou trabalhista, em especial quanto às garantias e aos privilégios que lhes são atribuídos</strong> ”, pondo fim a uma discussão antiga do mercado, sobre os efeitos da Medida Provisória nº 2.158-35/2001, que sempre era objeto de “Fator de Risco” nos Termos de Securitização.
        Por fim, também é importante mencionar a possibilidade expressa da dação em pagamento dos bens e direitos integrantes do patrimônio separado aos titulares dos Certificados de Recebíveis, trazendo maior segurança jurídica às situações de liquidação do patrimônio separado, especialmente nos casos em que os titulares dos Certificados de Recebíveis não decidam a respeito das medidas a serem adotadas. Importante destacar que, nesse caso, os titulares dos Certificados de Recebíveis se tornarão condôminos dos bens e direitos, nos termos do disposto na Lei nº 10.406, de 2002 - Código Civil.

        Ainda que tenha sido publicada após a Resolução CVM nº 60, ambas as normas possuem bastante sintonia. No entanto, resta pendente por parte da CVM um posicionamento sobre a possibilidade de revolvência nas operações de securitização imobiliária, expressamente vedada no artigo 3º do Anexo Normativo I da Resolução nº 60 e permitida de forma geral no inciso XIII do artigo 22 da Lei de Securitização.
        <b>Novo Arcabouço de Ofertas Públicas</b>
        Outro marco de grande impacto em todo o mercado foi a edição das Resoluções CVM nºs 160, 161, 162 e 163 em 13 de julho de 2022, que entraram em vigor em 02 de janeiro de 2023. Tais normas promovem o novo arcabouço regulatório brasileiro de ofertas públicas, com a revogação das Resoluções nºs 400 e 476 e a consequente extinção dos regimes de ofertas registradas e ofertas públicas dispensadas de registro, e com a criação de um regime único de ofertas públicas com diferentes ritos de registro: automático e ordinário.
        O novo arcabouço traz modificações relevantes para todo o mercado de ofertas, dentre as quais podemos citar: (i) publicidade de período de silêncio; (ii) material publicitário e entrevistas; (iii) consulta sigilosa a investidores profissionais; (iv) tratamento de conflitos de interesse e excesso de demanda a pessoas vinculadas; (v) ampliação do convênio com as entidades autorreguladoras; e (vi) procedimento de precificação (bookbuilding) para investidores qualificados.
        Documentos da Oferta
        Especificamente com relação ao mercado de securitização, vale destacar as alterações em relação aos documentos da oferta, como o modelo de prospecto específico, que deverá ter número máximo de 15 páginas para a seção 2 do prospecto, denominada Principais Características da Oferta.
        Nos termos do artigo 9º, § 3º, da Resolução nº 160, a utilização de um documento de aceitação da oferta não é exigida em relação a investidores profissionais, ainda que a oferta não seja a eles exclusivamente destinada.
        Foi reduzido, ainda, o rol de informações que devem constar obrigatoriamente na documentação das ofertas, como os avisos ao mercado e os avisos de início de distribuição. Assim, a expectativa é que seja evitada a repetição de informações que já constarão na lâmina da oferta (abaixo descrita), no prospecto, no cronograma e nos demais documentos da oferta.
        Lâmina
        Na mesma linha, também há um modelo padrão para as operações de securitização da lâmina da oferta, sendo documento obrigatório nas ofertas em que a elaboração do prospecto é exigida e deve ser elaborada pelo ofertante em conjunto com o coordenador líder em adição ao prospecto e de forma consistente com ele, servindo para sintetizar o seu conteúdo e apresentar as características essenciais da oferta, a natureza e os riscos associados ao emissor, às garantias e aos valores mobiliários.
        Na lâmina, devem ser listados os 5 (cinco) principais fatores de risco, em ordem decrescente de materialidade, com base na probabilidade de ocorrência e na magnitude do impacto negativo, caso concretizado.
        Por fim, importante mencionar que, com a publicação do novo marco, apenas securitizadoras <strong>registradas na CVM</strong> no âmbito da Resolução CVM nº 60 podem ofertar títulos de securitização.
        <b>Pagamento de aluguel como destinação de CRI</b>
        Ponto de grande relevância em 2022 foi a consolidação, por parte da CVM, do entendimento para a utilização de instrumentos de dívida emitidos com destinação dos recursos ao pagamento de aluguéis como lastro de emissão de certificados de recebíveis imobiliários.
        Nas primeiras operações com essa modelagem, a Superintendência de Supervisão de Securitização (SSE) solicitou, por meio de ofícios de exigências, “que fosse providenciado o registro [averbação] dos referidos contratos de locação nas respectivas matrículas”, como condição para o deferimento do registro.
        Em 13 de setembro de 2022, ao analisar consulta com pedido de dispensa formulado pelo Banco Itaú BBA S.A., a área técnica e o Colegiado da CVM entenderam pela desobrigação de averbação dos contratos de locação nas matrículas dos imóveis locados, o que gerou uma ampliação na possibilidade de operações de securitização imobiliária através de instrumentos de dívida.
        <b>Tokenização imobiliária</b>
        Na área de inovação financeira, vale destacar a recente publicação da Deliberação CVM nº 886, em 15 de fevereiro de 2023, que alterou a Deliberação CVM nº 875, de 30 de setembro de 2021.
        Com isso, a Vórtx Distribuidora de Títulos e Valores Mobiliários Ltda. e Vórtx QR Tokenizadora Ltda. ficam autorizadas a realizar operações de CRI com lastro em ativos tokenizados no âmbito do Sandbox Regulatório
        <b>Considerações finais</b>
        O ano de 2022 trouxe importantes inovações para o mercado de securitização imobiliária no país, com o Marco Legal das Securitizadoras, a nova Lei de Securitização e o novo arcabouço de ofertas públicas.
        Em 2023, há uma grande expectativa para o avanço das discussões envolvendo a tokenização imobiliária, e provavelmente tenhamos as primeiras operações com lastro em ativos tokenizáveis nos próximos meses.
        `,
    ],

    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/ricardo-stuber/1187">Ricardo Stuber</a>',
        description:
          'Sócio na área de Mercado de Capitais de TozziniFreire Advogados, sua expertise abrange coordenação de ofertas públicas de securitização (CRI, CRA e CR) e dívida; assessoria na estruturação de Fundos de Investimento Imobiliário (FII), Fundos de Investimento em Participações (FIP), Fundos de Investimento em Direitos Creditórios (FIDC), Fundos de Investimento do Agronegócio (FIAGRO); e estruturação de operações de financiamento. Atuou ainda como responsável pelo departamento jurídico de um grande grupo do setor de energia, no qual desenvolveu, organizou e implementou a área jurídica, incluindo assuntos societários, contratuais e trabalhistas. Ricardo é graduado em Direito pela Pontifícia Universidade Católica de São Paulo (PUC-SP), possui Certificate in Business Administration (CBA) em Administração de Empresas pelo IE Business School , na Espanha, e LL.M. em Prática Jurídica Internacional pela mesma instituição, além de extensão do LL.M., realizado no IE Law School pela Northwestern University, em Chicago, EUA. É membro da IBA (International Bar Association) e da ABA (American Bar Association).',
        image: 'Tozzini.jpg',
      },
    ],
  },
  {
    title:
      'Baixo apetite por risco leva investidores a optarem por Investimentos Imobiliários.',
    subtitle: '',
    text: [
      `<b>Número de investidores pessoa física supera a marca dos 2 milhões</b>
    Em um cenário mais desafiador para investimentos de risco, com inflação e juros elevados, os fundos imobiliários conseguiram superar uma marca esperada há muito tempo pelo setor: 2 milhões de investidores pessoas físicas.
    Conforme os dados da B3, o número de investidores com posição em custódia atingiu 2,06 milhões em janeiro de 2023, representando um aumento de 5,4% em comparação a dezembro de 2022 e de 30,25% atingido nos últimos 12 meses.
    A marca foi alcançada mesmo em um ambiente macroeconômico de menor apetite por produtos de renda variável, onde os investidores vêm naturalmente optando por alocar seus recursos em ativos de renda fixa mais conservadores e com taxas muito atrativas, dada a Selic em 13,75% ao ano.
    Para entendimento desse movimento vale relembrar que os Fundos Imobiliários ganharam popularidade a partir do ano de 2019 até 2021, onde naquela época os juros atingiram as mínimas históricas e os brasileiros foram obrigados a buscar outras alternativas de investimentos mais rentáveis do que a renda fixa. Diferentemente do que está ocorrendo hoje, onde a renda fixa é extremamente atrativa, mas mesmo assim, os investidores com baixo apetite ao risco, preferem optar pelos fundos imobiliários. Indicando os novos e diferentes desafios da conjuntura econômica atual.
    Em 2009 o número de investidores pessoa física era de 12 mil investidores. Esse número foi crescendo de forma mais tímida até o ano de 2018, quando atingiu 208 mil investidores. Já em 2019 triplicou para 645 mil investidores e terminou 2022 com 1,9 milhões de investidores.
    Hoje os Fundos Imobiliários somam R$ 201 bilhões em patrimonio líquido. São mais de 800 fundos, sendo que destes 467 fundos são listados na B3.
    O ambiente das taxas elevadas de juros em 2022 levou a uma redução nas ofertas dos FIIs, que fecharam o ano com 67 ofertas, totalizando R$ 20 bilhões. Um desempenho abaixo dos anos anteriores, onde houveram 76 ofertas em 2021 e 75 ofertas em 2020, conforme dados da B3. 
    
    <b>Segmento de Imóveis de luxo segue aquecido em 2023</b>
    A comercialização de imóveis de médio e alto padrão segue aquecida em 2023, mesmo com as altas taxas de juros, conforme as principais construtoras do Brasil.
    Segundo os dados da Abrainc (Associação Brasileira de Incorporadoras Imobiliárias)  esse segmento já representa aproximadamente 30% do total das vendas anuais do setor, uma vez que o Brasil comercializa anualmente cerca de 157 mil imóveis, sendo 46 mil unidades de médio e alto padrão.
    As cinco principais cidades no Brasil que possuem o maior valor médio do m2 dos imóveis de médio e alto padrão residenciais, de acordo com relatório FipeZap+ são, Balneário Camboriú com R$ 11.534, seguida de Itapema com R$ 10.614, ambas no Estado de Santa Catarina. Na terceira posição aparece a cidade de Vitória-ES com R$ 10.444, seguida de São Paulo na quarta posição com R$ 10.226 e Rio de Janeiro com R$ 9.859 por m².
    
    <b>Emissão de CRIs batem recorde em 2022.</b>
    O ano de 2022, registrou recordes nos volumes de emissão de títulos de crédito privado. O ano que também marcou importantes avanços nas normas estabelecidas pela CVM (CVM 160) e Anbima (Marcação a Mercado), vigentes a partir de janeiro de 2023.
    Conforme dados divulgados pela Anbima, o mercado de crédito privado encerrou 2022 com R$ 362 bilhões emitidos entre debêntures, CRAs e CRIs. Esse foi o maior montante já registrado na série histórica, distribuídos em 604 operações no decorrer do ano, ultrapassando o antigo recorde de R$ 309 bilhões registrados no ano de 2021.
    Os CRIs (Certificados de Recebíveis Imobiliários) atingiram a impressionante marca de R$ 50 bilhões emitidos em 2022. O volume representa um crescimento de 50% quando comparado com o ano anterior. Tal crescimento demonstra toda a ampliação do uso desse tipo de instrumento por companhias de pequeno, médio grande porte no Brasil.
    As emissões de debêntures atingiram R$ 271 bilhões em 2022, com um crescimento de 8% ante o ano de 2021, indicando uma larga tendência de crescimento na emissão de CRIs (50%) comparado ao crescimento das emissões de Debêntures.
    
    
    <b>Perspectivas para o mercado imobiliário em 2023</b>
    Especialistas alertam que o mercado está ansioso a espera das definições das âncoras fiscais e dos encaminhamentos da reforma tributária que nortearão os próximos anos. No setor, os indicadores de confiança se deterioram, principalmente pela expectativa das altas taxas de juros. Por outro lado, indicadores de inflação mais baixos podem compensar as expectativas e de certa forma equilibrar o ânimo dos investidores.
    De acordo com Luiz França, presidente da Abrainc “O Brasil tem uma pirâmide etária ainda jovem se comparado com outros países, implicando em uma alta taxa de formação de famílias nos próximos anos, e tudo isso reflete em uma forte demanda no setor”. 
    Ainda, se espera que o retorno do programa Minha Casa Minha Vida, amplie o acesso à moradia por famílias mais carentes, contribuindo para a diminuição do déficit habitacional.
    O Indicador de Confiança do setor Imobiliário Residencial realizado pela Abrainc em 2022 estima que as vendas na incorporação imobiliária apresentem uma alta nos próximos 12 meses.`,
    ],

    authors: [
      {
        name: '<a href="https://uqbar.com.br/profissional/ricardo-artur-spezia/1185">Ricardo Artur Spezia</a>',
        description:
          'Cofundador da Next Auditores, Ricardo. Aprendeu cedo a cercar-se das melhores pessoas para entregar o melhor trabalho, mas usa a tecnologia para implementar e garantir a consistência dos processos. Por outro lado, o que o torna único é a sua criatividade e a sua intuição, características conhecidas nas artes, mas imprescindíveis para humanizar e decodificar a linguagem do mercado financeiro. Para ele, a vida acontece agora, mas acredita que só é possível dar um passo adiante se estivermos preparados. Mestre em Ciências Contábeis pela FURB (Fundação Universidade Regional de Blumenau), Ricardo é também especialista em Estratégia para Inovação em Novos Mercados pela Wharton School (Escola de negócios da Universidade da Pensilvânia – EUA). Pelo Insper, Ricardo é especialista em Finanças Avançadas e pela FGV (Fundação Getúlio Vargas) se especializou como CFO Estrategista em Marketing de Alto Impacto, com MBA em Finanças, Auditoria e Controladoria pela mesma instituição. Pés no chão, mas disposto a ampliar seus horizontes, Ricardo é fascinado pelo novo e por diferentes culturas. Observar o mundo (e o mundo das pessoas), o transformou em um executivo completo. Seu estilo de trabalho equilibra com maestria os dois lados do cérebro, e se para números combinados entre si não existem limites, para ele, muito menos. Sua busca é pela eficiência do seu mercado e pelo sucesso de seus clientes. Sua maior motivação são os desafios que encontra pelo caminho, mas também, os que ele mesmo procura. Ao ser questionado sobre qual seu método para encontrar as soluções de problemas novos ou problemas velhos, ele diz que o segredo é um só: é preciso saber fazer as perguntas certas. Mas quando é ele quem precisa de suporte, é na família, no esporte e na fé que se abastece. ',
        image: 'Spezia.jpg',
      },
    ],
  },
];

export const rankingsData = [
  null,
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=operation&criteria=amountissued&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitizer&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitizer&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=legaladvisor&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=legaladvisor&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=agencyrating&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=agencyrating&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=fiduciaryagent&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=fiduciaryagent&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=distributor&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=distributor&criteria=titlesnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=servicer&criteria=amount&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=servicer&criteria=operations&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitycri&criteria=amountnegotiated&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitycri&criteria=tradesnumber&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=securitizer&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=fiduciaryagent&criteria=debitbalance&timeRange=yearly',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=stockauditor&criteria=amount&timeRange=default',
  'https://www.uqbar.com.br/rankings/dados?market=CRI&year=2022&ranking=stockauditor&criteria=operations&timeRange=default',
];
