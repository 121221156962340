import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import { Professional } from 'src/types/professional';

import { api, publicApi } from '../../api/api';

export const getProfessionalsRelatedArticles = async () => {
  const query = qs.stringify(
    {
      pagination: {
        pageSize: '100',
      },
      filters: {
        relatedArticles: {
          id: {
            $notNull: true,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/profissionais?${query}`);
  return data;
};

/** @deprecated */
export const findProfessionalByOwner = async (userId?: number) => {
  const query = qs.stringify(
    {
      populate: [
        'photo',
        'coverImage',
        'lastRequestApproval',
        'lastRequestApproval.institution',
        'relatedInstitutions',
        'owner',
        'professionalSegments',
        'markets',
        'professionalSector',
        'twitter',
        'linkedin',
        'instagram',
        'facebook',
        'email',
        'emails',
        'phones',
        'experiences',
        'certifications',
        'relatedOperations',
        'relatedOperations.operation',
        'relatedOperations.operation.market',
      ],
      filters: {
        owner: userId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/profissionais?${query}`);
  return data;
};

export const createProfessional = async (values: any) => {
  const { data } = await api.post('/profissionais', values);
  return data;
};

export const updateProfessional: ({
  id,
  values,
}: {
  id: number;
  values: any;
}) => Promise<any> = async ({ id, values }: { id: number; values: any }) => {
  const { data } = await api.put(`/profissionais/${id}`, values);
  return data;
};

export const findProfessionalById = async (id: string) => {
  const query = qs.stringify(
    {
      populate: {
        seo: true,
        photo: true,
        coverImage: true,
        email: true,
        facebook: true,
        linkedin: true,
        twitter: true,
        instagram: true,
        professionalSector: true,
        markets: true,
        professionalSegments: true,
        relatedInstitutions: {
          populate: ['logo'],
        },
        // owner: {
        //   populate: {
        //     subscription: {
        //       populate: [
        //         'plan',
        //       ]
        //     }
        //   },
        // },
        emails: true,
        phones: true,
        achievements: {
          populate: ['image'],
        },
        experiences: true,
        certifications: true,
        relatedOperations: {
          populate: {
            operation: {
              populate: ['market'],
            },
          },
        },
        activities: {
          on: {
            'activity.article-owner': {
              filters: {
                article: {
                  publishedAt: {
                    $notNull: true,
                  },
                },
              },
              populate: '*',
            },
            'activity.article-mentioned': {
              filters: {
                article: {
                  publishedAt: {
                    $notNull: true,
                  },
                },
              },
              populate: '*',
            },
            'activity.marketplace': {
              filters: {
                offer: {
                  publishedAt: {
                    $notNull: true,
                  },
                },
              },
              populate: '*',
            },
            'activity.informative': {
              populate: true,
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get<StrapiResponse<Professional>>(
    `/profissionais/${id}?${query}`,
  );
  if (data.activities && data.activities.length > 0) {
    data.activities.sort((a, b) => {
      const dateA = new Date(a.activityAt);
      const dateB = new Date(b.activityAt);
      return dateB.getTime() - dateA.getTime();
    });
  }
  return data;
};

export const getProfessionalPhotoByOwner = async (userId: number) => {
  const query = qs.stringify(
    {
      fields: ['id'],
      populate: ['photo'],
      filters: {
        owner: userId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/profissionais?${query}`);
  return data;
};

export const getProfessionalsForHome = async () => {
  const query = qs.stringify(
    {
      sort: ['updatedAt:desc'],
      fields: ['id', 'fullName', 'slug', 'role'],
      pagination: {
        page: 1,
        pageSize: 3,
      },
      populate: [
        'photo',
        'relatedInstitutions',
        'markets',
        'professionalSector',
      ],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/profissionais/home?${query}`);
  return data;
};

export const getResponsibilities = async () => {
  const {
    data: { data },
  } = await api.get('/responsibilities');

  return data;
};

export const checkSlug = async (slug: string) => {
  const { data } = await api.patch('/profissionais/check-slug', { slug });
  return data;
};

export const requestProfessionalOwner = async (professionalId?: number) => {
  const { data } = await api.patch('/profissionais/request-owner', {
    data: { professionalId },
  });
  return data;
};
