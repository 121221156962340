import { useState } from 'react';

import { CircularProgress, Paper } from '@mui/material';
import { useQuery } from 'react-query';
import SubTitle from 'src/components/UI/Subtitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getResponsibilities } from 'src/services/professional';

import LegalPersonForm from './LegalPersonForm';
import PrivatePersonForm from './PrivatePersonForm';

interface ResponsibilityOptions {
  inputValue?: string;
  name: string;
}

function UserData() {
  const { auth } = useAuthContext();
  const [responsibilities, setResponsibilities] = useState<
    ResponsibilityOptions[]
  >([]);

  const { isLoading: responsibilityLoading } = useQuery(
    'responsibilities',
    getResponsibilities,
    {
      onSuccess: (data) => {
        setResponsibilities(data);
      },
    },
  );

  if (!auth.user || auth.isLoading || responsibilityLoading)
    return <CircularProgress />;

  return (
    <Paper elevation={2} sx={{ marginTop: 4, padding: '56px 30px 10px' }}>
      <SubTitle title="Dados essenciais" />
      {auth.user?.type === 'private_person' ? (
        <PrivatePersonForm
          user={auth.user}
          responsibilityOptions={responsibilities}
        />
      ) : (
        <LegalPersonForm
          user={auth.user}
          responsibilityOptions={responsibilities}
        />
      )}
    </Paper>
  );
}

export default UserData;
