import { useRef } from 'react';

import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';

import WidgetAnalyticalRanking from './WidgetAnalyticalRanking';
import { getInformativeTextByLocation } from '../../../services/informative';
import { useQueryParams } from '../../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  orangeBar: {
    width: '100%',
    height: 20,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 2px 9px rgba(0, 0, 0, 0.25)',
    marginBottom: 13,
  },
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '29.05px',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: '1rem',
    lineHeight: '19.36px',
    color: '#6D6E71',
  },
  button: {
    margin: 0,
    padding: theme.spacing(1, 0),
    fontWeight: 600,
  },
}));

export default function WidgetRankings() {
  const classes = useStyles();
  const rankingRef = useRef();
  const [{ query, rawQuery }] = useQueryParams();

  const { data: infoData } = useQuery('info', () =>
    getInformativeTextByLocation('rank'),
  );

  return (
    <div className={classes.root}>
      <Box className={classes.orangeBar} />
      <Paper elevation={0} square className={classes.paper}>
        <Typography component="h4" className={classes.title}>
          Ranking Uqbar
        </Typography>
        <Typography className={classes.subTitle}>
          {infoData?.data?.rank?.informationText}
        </Typography>
        <Button
          className={classes.button}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          disableElevation
          variant="text"
          color="secondary"
          target="_blank"
          href={`${process.env.REACT_APP_PUBLIC_URL}/rankings/dados${rawQuery}`}>
          Ver ranking completo
        </Button>
      </Paper>
      <Paper square elevation={0} className={classes.paper} ref={rankingRef}>
        <WidgetAnalyticalRanking
          state={{
            ...query,
            year: JSON.parse(query?.year ?? null),
          }}
          hasConsolidated={true}
        />
        <Button
          className={classes.button}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          disableElevation
          variant="text"
          color="secondary"
          target="_blank"
          href={process.env.REACT_APP_PUBLIC_URL}>
          Ir para aplicação
        </Button>
      </Paper>
    </div>
  );
}
