import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 18px;
`;

export const GraphTitle = styled.h4`
  font-weight: 700;
  text-align: center;
  padding: 0px 24px;
`;

export const GraphReference = styled.p`
  font-size: 14px;
  text-align: center;
`;
