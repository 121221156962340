import {
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import cep from 'cep-promise';
import { FormikProps } from 'formik';
import CEPFormat from 'src/components/FormsUI/CEPFormat';
import { InstitutionInput } from 'src/types/institution';
import { BRAZIL_STATES } from 'src/utils/geo';

import { FieldTitle } from '../style';

type Props = {
  formik: FormikProps<InstitutionInput>;
};

function AddressForm({ formik }: Props) {
  return (
    <Grid container spacing={2} sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>CEP*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.zipcode"
            onChange={formik.handleChange}
            placeholder="Insira o CEP"
            value={formik.values.addresses.zipcode}
            error={
              formik?.touched?.addresses?.zipcode &&
              Boolean(formik?.errors?.addresses?.zipcode)
            }
            helperText={
              formik?.touched?.addresses?.zipcode &&
              formik?.errors?.addresses?.zipcode
            }
            InputProps={{
              inputComponent: CEPFormat,
            }}
            onBlur={(event) => {
              const zipcode = event.target.value.replace(/\D/g, '');
              if (zipcode.length === 8) {
                cep(zipcode)
                  .then((data) => {
                    formik.setFieldValue('addresses', {
                      zipcode,
                      line1: data.street,
                      state: data.state,
                      neighborhood: data.neighborhood,
                      city: data.city,
                      number: '',
                      line2: '',
                    });
                  })
                  .catch((data) => {
                    if (data.message) {
                      formik.setFieldError(
                        'addresses.zipcode',
                        'CEP não encontrado',
                      );
                    }
                  });
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Rua*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.line1"
            placeholder="Insira o nome da rua"
            onChange={formik.handleChange}
            value={formik?.values?.addresses?.line1}
            error={
              formik?.touched?.addresses?.line1 &&
              Boolean(formik?.errors?.addresses?.line1)
            }
            helperText={
              formik?.touched?.addresses?.line1 &&
              formik?.errors?.addresses?.line1
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Número*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.number"
            placeholder="Insira o número"
            onChange={formik.handleChange}
            value={formik?.values?.addresses?.number}
            error={
              formik?.touched?.addresses?.number &&
              Boolean(formik?.errors?.addresses?.number)
            }
            helperText={
              formik?.touched?.addresses?.number &&
              formik?.errors?.addresses?.number
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Complemento</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.line2"
            placeholder="Insira o complemento (Opcional)"
            onChange={formik.handleChange}
            value={formik?.values?.addresses?.line2}
            error={
              formik?.touched?.addresses?.line2 &&
              Boolean(formik?.errors?.addresses?.line2)
            }
            helperText={
              formik?.touched?.addresses?.line2 &&
              formik?.errors?.addresses?.line2
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Bairro*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.neighborhood"
            placeholder="Insira o bairro"
            onChange={formik.handleChange}
            value={formik.values.addresses.neighborhood}
            error={
              formik?.touched?.addresses?.neighborhood &&
              Boolean(formik?.errors?.addresses?.neighborhood)
            }
            helperText={
              formik?.touched?.addresses?.neighborhood &&
              formik?.errors?.addresses?.neighborhood
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Cidade*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="addresses.city"
            placeholder="Insira a cidade"
            onChange={formik.handleChange}
            value={formik?.values?.addresses?.city}
            error={
              formik?.touched?.addresses?.city &&
              Boolean(formik?.errors?.addresses?.city)
            }
            helperText={
              formik?.touched?.addresses?.city &&
              formik?.errors?.addresses?.city
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldTitle>Estado*</FieldTitle>
        <FormControl
          fullWidth
          variant="outlined"
          error={
            formik?.touched?.addresses?.state &&
            Boolean(formik?.errors?.addresses?.state)
          }>
          <Select
            name="addresses.state"
            value={formik?.values?.addresses?.state}
            onChange={formik.handleChange}
            displayEmpty
            renderValue={(selected: any) => {
              if (!selected) {
                return (
                  <Typography sx={{ color: '#BDBDBD' }}>
                    Selecione o estado
                  </Typography>
                );
              }
              return BRAZIL_STATES.get(selected);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 250,
                },
              },
              MenuListProps: {
                sx: (theme) => ({
                  maxHeight: 240,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    height: 10,
                    width: 8,
                    WebkitAppearance: 'none',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: 4,
                    backgroundColor: theme.palette.grey['400'],
                  },
                }),
              },
            }}>
            {[...BRAZIL_STATES.keys()].map((opt: string, index: number) => (
              <MenuItem value={opt} key={`state-${index}`}>
                <ListItemText primary={opt} />
              </MenuItem>
            ))}
          </Select>
          {Boolean(formik?.errors?.addresses?.state) &&
            formik?.touched?.addresses?.state && (
              <FormHelperText>
                {formik?.errors?.addresses?.state}
              </FormHelperText>
            )}
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default AddressForm;
