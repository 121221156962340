/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Dialog } from '../../../../components/Dialog';
import { DataContext } from '../../../../context/DataContext';
import { updateSecurity } from '../../utils/updateSecurity';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    lineHeight: '23px',
  },
}));

const LoadSecurityAction = ({ formik }) => {
  const { security, calculator } = formik;
  const classes = useStyles();
  const [securityTemp, setSecurityTemp] = useState(null);
  const [openLoadSecurity, setOpenLoadSecurity] = useState(false);
  const {
    setEnableFields,
    setExpanded,
    setSimulateResult,
    setPickedSecurity,
    deleteSecurity,
    securities,
    getTemplateSecurity,
  } = useContext(DataContext);

  const handleCloseLoadSecurity = () => {
    setSecurityTemp(null);
    setOpenLoadSecurity(false);
  };

  const handleDeleteSecurity = () => {
    securityTemp?.id
      ? deleteSecurity(securityTemp?.id)
      : security?.values.id && deleteSecurity(security?.values.id);

    if (securities?.length > 0) {
      setSecurityTemp(securities[0]);
    } else {
      setSecurityTemp(null);
    }

    if (securityTemp?.id === security.values.id) {
      getTemplateSecurity();
    }
  };

  const openSecurity = () => {
    if (securityTemp !== null) {
      updateSecurity({ security, calculator, securityTemp });
      setSimulateResult(null);
      handleCloseLoadSecurity();
    }
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpenLoadSecurity(true)}
        data-hover="Carregar"
        className="hovertext"
        style={{ padding: '6px 10px', minWidth: 0 }}>
        <CloudDownload style={{ color: 'black' }} />
      </Button>
      <Dialog
        open={openLoadSecurity}
        onClose={handleCloseLoadSecurity}
        maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" className={classes.title}>
            Carregar título
          </Typography>
          <Box sx={{ width: 400, marginTop: 20 }}>
            <Autocomplete
              fullWidth
              onBlur={security.handleBlur}
              disableClearable
              value={securityTemp?.name || security.values.name || ''}
              onChange={(event, newValue) => {
                if (newValue) {
                  securities?.map((security) => {
                    if (security.name === newValue) {
                      setSecurityTemp(security);
                    }
                  });
                } else {
                  security.resetForm();
                  setPickedSecurity(null);
                  setEnableFields(false);
                  setExpanded('panel1');
                }
              }}
              options={
                securities ? securities.map((security) => security.name) : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Títulos disponíveis"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteSecurity}
            color="secondary"
            variant="outlined"
            disabled={!security.values.id && !securityTemp?.id}>
            Deletar
          </Button>
          <Button
            onClick={openSecurity}
            color="secondary"
            variant="contained"
            disabled={!securityTemp?.id}>
            Carregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoadSecurityAction;
