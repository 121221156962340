import { useEffect, useMemo, useState } from 'react';

import * as Articles from './styles';
import YearbookArticle from '../../components/Article';
import { articlesData } from '../../presentation/data';
import { YearbookMarket, YearbookMarketArticle } from '../../types';
import { getAdvertisementByPosition } from '../../utils';

interface ArticlesSectionProps {
  market: YearbookMarket;
}

export default function ArticlesSection({ market }: ArticlesSectionProps) {
  const [selectedArticles, setSelectedArticles] = useState<
    YearbookMarketArticle[]
  >([]);
  const articles = useMemo(() => articlesData[market.name], [market]);

  function isSelected(article: YearbookMarketArticle) {
    return !!selectedArticles.find((art) => art === article);
  }

  const getAdvertisementImage = (imageName: string) => {
    return require(`src/assets/imgs/yearbook/2024/banners/${imageName}.png`);
  };

  const ads = useMemo(
    () => getAdvertisementByPosition(market.name, 3),
    [market],
  );

  function select(article: YearbookMarketArticle) {
    if (isSelected(article))
      setSelectedArticles(selectedArticles.filter((art) => art !== article));
    else setSelectedArticles([...selectedArticles, article]);
  }

  useEffect(() => {
    setSelectedArticles([]);
  }, [market]);

  return (
    <Articles.Section data-yearbook-section="Artigos">
      {ads && (
        <Articles.Ads
          image={getAdvertisementImage(ads.banner).default}
          href={ads.url}
          target="_blank"
        />
      )}
      {articles.map((article, idx) => (
        <YearbookArticle
          key={`${market}-${idx}`}
          article={article}
          select={select}
          active={isSelected(article)}
        />
      ))}
    </Articles.Section>
  );
}
