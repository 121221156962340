import { Box } from '@mui/material';

import * as S from './styles';
import { Chapter } from '../../types';
import { getChapterSectionElementRef } from '../../utils';

interface APIDetailsMenuProps {
  chapters: Chapter[];
  onClick: (chapterId: number, sectionId: number, elementRef: string) => void;
}

export default function APIDetailsMenu({
  chapters,
  onClick,
}: APIDetailsMenuProps) {
  return (
    <>
      {chapters.map((chapter) => (
        <Box key={`c${chapter.id}`} mb={6}>
          <S.MenuChapterTitle
            onClick={() =>
              onClick(chapter.id, 0, getChapterSectionElementRef(chapter.id, 0))
            }>
            {chapter.title}
          </S.MenuChapterTitle>

          {chapter.sections.map((section) => (
            <S.MenuSectionTitle
              key={`s-${section.id}`}
              onClick={() =>
                onClick(
                  chapter.id,
                  section.id,
                  getChapterSectionElementRef(chapter.id, section.id),
                )
              }>
              {section.title}
            </S.MenuSectionTitle>
          ))}
        </Box>
      ))}
    </>
  );
}
