// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

// Global components
import GeneralDataItem from '../../../../../components/GeneralDataItem';
import GeneralDataItemConditional from '../../../../../components/GeneralDataItemConditional';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIDCResumeGeneralData({ hash }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Dados Gerais
      </Typography>
      <Divider className={classes.divider} />
      <Box py={4}>
        <Grid container>
          <Grid item xs={12}>
            <GeneralDataItem
              title="CNPJ"
              subtitle={
                data
                  .getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                  ?.cnpj ?? 'N/D'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GeneralDataItem
              title="Tipo de Condomínio"
              subtitle={
                data
                  .getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                  ?.condominiumType
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GeneralDataItemConditional
              title="Não-Padronizado"
              condition={
                data
                  .getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
                  ?.isNP
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
