import { useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CastConnectedOutlinedIcon from '@material-ui/icons/CastConnectedOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Rating from '@material-ui/lab/Rating';
import { format, parse } from 'date-fns';
import { pt } from 'date-fns/locale';

import img_saleAllowed from '../../../assets/imgs/Ativo-1.svg';
import img_saleNotAllowed from '../../../assets/imgs/Ativo-2.svg';
import hatIcon from '../../../assets/imgs/hatIcon.svg';
import ImageTitleTop from '../../../components/ImageTitleTop';
import { toCurrency } from '../../../utils/number';
import { formatUrl } from '../../../utils/string';
import CourseSubscriptionModal from '../CourseSubscriptionModal';

function TrainingInfo({ classes, text, icon }) {
  return (
    <Card elevation={0}>
      <CardContent className={classes.cardContentRoot}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          {icon && icon}
          <Typography className={classes.titleItem}>{text}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function TrainingTop({
  classes,
  type,
  title,
  saleAllowed,
  investment,
  discountPercent,
  markets,
  courseTime,
  level,
  stars,
  image,
  subscriptionUrl,
  investmentWithoutDisccount,
  startDate,
  endDate,
}) {
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubscription = () => {
    setModalOpen(false);
    window.open(formatUrl(subscriptionUrl));
  };

  return (
    <Box my={2}>
      <Grid container spacing={1}>
        <Paper elevation={3} className={classes.courseTopPaper}>
          <Grid item container>
            <Grid item xs={12} md={8}>
              <ImageTitleTop
                className={classes.courseHeaderImage}
                image={image?.formats?.large?.url ?? image?.url}
                title={title}
                type={type}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                square
                elevation={0}
                className={classes.courseTopSaleAllowedWrap}>
                <CardMedia
                  image={saleAllowed ? img_saleAllowed : img_saleNotAllowed}
                  className={classes.courseTopSaleAllowed}
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    color="secondary"
                    className={classes.courseTopInvestment}>
                    {toCurrency(investment)}
                  </Typography>
                  {discountPercent && investment ? (
                    <Typography style={{ marginLeft: 4 }}>
                      {discountPercent}% de desconto{' '}
                      <del>{toCurrency(investmentWithoutDisccount)}</del>
                    </Typography>
                  ) : null}
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => setModalOpen(true)}
                    disabled={!saleAllowed}
                    variant="outlined"
                    className={classes.courseTopButton}>
                    Inscreva-se
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} className={classes.courseTopPaperInfo}>
          <Grid
            item
            container
            justifyContent="space-evenly"
            spacing={1}
            alignItems="center">
            <Grid item xs={4} md={2}>
              <TrainingInfo
                classes={classes}
                text={markets.map((market) => market.name).join(' | ')}
                icon={
                  <Avatar
                    alt="Uqbar"
                    src={hatIcon}
                    style={{
                      height: isExtraSmall ? 20 : 35,
                      width: isExtraSmall ? 20 : 35,
                    }}
                  />
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              className={classes.dividerTop}
              flexItem
            />
            <Grid item xs={4} md={2}>
              <TrainingInfo
                classes={classes}
                text={
                  <span className={classes.periodCourseTop}>
                    {startDate
                      ? format(
                          parse(startDate, 'yyyy-MM-dd', new Date()),
                          'dd/MM/yyyy',
                          { locale: pt },
                        )
                      : 'A definir'}
                    {endDate
                      ? ` a ${format(
                          parse(endDate, 'yyyy-MM-dd', new Date()),
                          'dd/MM/yyyy',
                          { locale: pt },
                        )}`
                      : ''}
                  </span>
                }
                icon={
                  <EventNoteIcon
                    fontSize={isExtraSmall ? 'small' : 'large'}
                    color="secondary"
                  />
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              className={classes.dividerTop}
              flexItem
            />
            <Grid item xs={4} md={2}>
              <TrainingInfo
                classes={classes}
                text={courseTime}
                icon={
                  <AccessTimeIcon
                    fontSize={isExtraSmall ? 'small' : 'large'}
                    color="secondary"
                  />
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              className={classes.dividerTop}
              flexItem
            />
            <Grid item xs={4} md={2}>
              <TrainingInfo
                classes={classes}
                text={type}
                icon={
                  <CastConnectedOutlinedIcon
                    fontSize={isExtraSmall ? 'small' : 'large'}
                    color="secondary"
                  />
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              className={classes.dividerTop}
              flexItem
            />
            <Grid item xs={4} md={2}>
              <TrainingInfo
                classes={classes}
                text={level?.value}
                icon={
                  <SignalCellularAltIcon
                    fontSize={isExtraSmall ? 'small' : 'large'}
                    color="secondary"
                  />
                }
              />
            </Grid>
            {stars && (
              <>
                <Divider
                  orientation="vertical"
                  className={classes.dividerTop}
                  flexItem
                />
                <Grid item xs={4} md={2}>
                  {isExtraSmall ? (
                    <TrainingInfo
                      classes={classes}
                      text={(parseInt(stars) / 2).toLocaleString('pt-br')}
                      icon={
                        <StarBorderIcon
                          fontSize={isExtraSmall ? 'small' : 'large'}
                          color="secondary"
                        />
                      }
                    />
                  ) : (
                    <TrainingInfo
                      classes={classes}
                      text={(parseInt(stars) / 2).toLocaleString('pt-br')}
                      icon={
                        <Rating
                          classes={{
                            iconFilled: classes.starIcon,
                          }}
                          size={isExtraSmall ? 'small' : 'medium'}
                          readOnly
                          precision={0.1}
                          value={parseInt(stars) / 2}
                        />
                      }
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <CourseSubscriptionModal
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            handleSubscription={handleSubscription}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
