import { configureStore } from '@reduxjs/toolkit';

import display from './display';

const store = configureStore({
  reducer: {
    display,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
