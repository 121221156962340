import styled from 'styled-components';

export const Header = styled.div`
  padding: 12px 16px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  & > span:first-child {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const Wrapper = styled.div`
  width: 440px;
  height: 604px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const NoItemsWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  span {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
  }
`;

export const NotificationItem = styled.div`
  padding: 10px;
`;

export const NotificationContent = styled.div`
  padding: 10px 20px 0 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;

  span {
    font-size: 14px;
    font-weight: 400;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  button {
    height: 24px;
  }
`;

export const NotificationDate = styled.div`
  padding: 15px 0;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #828282;
`;
