import { useEffect, useState } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Typography } from '@mui/material';
import { CustomTooltip } from 'src/components/CustomTooltip';

export default function InviteCode({ code }: { code: string }) {
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (openTooltip) {
      timer = setTimeout(() => {
        setOpenTooltip(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [openTooltip]);

  return (
    <Box
      bgcolor={(theme) => theme.palette.primary.main}
      width={'100%'}
      height={'63px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}>
      <Typography color={'#000'} fontSize={20} fontWeight={600}>
        Seu código de convite: {code}
      </Typography>
      <CustomTooltip
        title="Código copiado"
        open={openTooltip}
        placement="right"
        disableHoverListener
        disableTouchListener>
        <div>
          <Box
            title="Copiar código de convite"
            sx={{
              fontSize: '1.91rem',
              ml: 2.375,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              navigator.clipboard.writeText(code);
              setOpenTooltip(true);
            }}>
            <FileCopyIcon fontSize="inherit" />
          </Box>
        </div>
      </CustomTooltip>
    </Box>
  );
}
