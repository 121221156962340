import { useEffect, useState } from 'react';

import { Box, SelectChangeEvent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import OperationCharts from 'src/components/OperationCharts';
import SubTitle from 'src/components/v2/SubTitle';
import { CriWalletPDD } from 'src/types/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  data: CriWalletPDD[] | undefined;
  selectOptions: string[] | undefined;
  operationName: string;
  hasPermission: boolean | undefined;
};

function OperationCriAssetPDD({
  data,
  selectOptions,
  operationName,
  hasPermission,
}: Props) {
  const [period, setPeriod] = useState('');
  const market = 'cri';
  const indicator = 'provisão para devedores duvidosos';

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string);
  };

  useEffect(() => {
    if (selectOptions?.length) {
      setPeriod(selectOptions[0]);
    }
  }, [selectOptions]);

  const handleExport = () => {
    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      filename: `${operationName}_${market}_${indicator}`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Data de referência', 'PDD', 'Nome da operação'],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (data) {
      const dataToExport = data.map((row: CriWalletPDD) => ({
        dataReferencia: format(
          parse(row.dataReferencia, 'yyyy-MM-dd', new Date()),
          'MM/yyyy',
        ),
        pdd: convertNumber(row.reducaoValorRecuperacao ?? 0),
        operationName,
      }));

      csvExporter.generateCsv(dataToExport);
    }
  };

  const selectedData = data?.find((op) => op.dataReferencia === period);

  const series = [
    {
      data: data
        ?.sort((a, b) => {
          return a.dataReferencia > b.dataReferencia
            ? 1
            : a.dataReferencia < b.dataReferencia
              ? -1
              : 0;
        })
        ?.map((op) => op.reducaoValorRecuperacao),
    },
  ];

  const xAxisCategories = selectOptions?.length ? [...selectOptions] : [];

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: 'PDDWallet',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#E0E0E0',
          fillColor: '#E0E0E0',
          opacity: 0,
        },
      ],
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['rgba(0,0,0,0.87)'],
      },
    },
    stroke: {
      width: 2,
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: '#F28181',
            },
          ],
        },
      },
    },
    noData: {
      text: 'Nenhum dado encontrado',
    },
    xaxis: {
      categories: xAxisCategories
        ?.sort((a, b) => {
          return a > b ? 1 : a < b ? -1 : 0;
        })
        .map((date) =>
          format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/yyyy'),
        ),
    },
    colors: ['#FDA85E'],
    tooltip: {
      theme: 'dark',
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => convertValue(value),
      },
      marker: {
        show: false,
      },
      followCursor: true,
      shared: false,
      intersect: false,
    },
  };

  return (
    <Box>
      <SubTitle title="Provisão para devedores duvidosos" />
      {hasPermission ? (
        <OperationCharts
          title="PDD"
          subtitle={`${convertValue(selectedData?.reducaoValorRecuperacao ?? 0)}`}
          valueSelect={period}
          handleChangeSelect={handleChange}
          type="bar"
          series={series}
          options={options}
          selectOptions={selectOptions}
          permission="operacoes_cri_carteira_de_recebiveis_e_pdd_exportar"
          handleExport={handleExport}
          hasData={data && data?.length > 0}
          enabledExport={true}
        />
      ) : null}
    </Box>
  );
}

export default OperationCriAssetPDD;
