// Material ui components
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

// Global components
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import Subtitle from '../../../../../components/Subtitle';
//Apolo client
import TableLiabilityOperation from '../../../../../components/TableLiabilityOperation';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_DETAILS_FIDC_QUOTES_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

export default function OperationFIDCLiabilityQuote({ hash }) {
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_DETAILS_FIDC_QUOTES_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_passivo_cotas'
      ],
    },
  );

  if (loading) return <CircularProgress color="secondary" />;
  if (error) return null;

  return (
    <div>
      <Subtitle title="Cotas" />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_passivo_cotas'
          ]
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_passivo_cotas'
          ]
        }>
        {data?.getDetailsFidcQuotesByOperationHashId?.quotes.length > 0 && (
          <TableLiabilityOperation
            market="FIDC"
            rows={data?.getDetailsFidcQuotesByOperationHashId?.quotes}
          />
        )}
      </If>
    </div>
  );
}
