import camelCase from 'lodash/camelCase';

/**
 * font: https://dev.to/jankapunkt/make-text-fit-it-s-parent-size-using-javascript-m40
 */

const isOverflown = ({
  clientWidth,
  scrollWidth,
}: {
  clientWidth: number;
  scrollWidth: number;
}) => scrollWidth > clientWidth;

const withHttp = (url: string) =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

export const resizeText = (element: HTMLElement, parent: HTMLElement) => {
  let i = 12; // let's start with 12px
  let overflow = false;
  const maxSize = 128; // very huge text size

  while (!overflow && i < maxSize) {
    element.style.fontSize = `${i}px`;
    overflow = isOverflown(parent);
    if (!overflow) i++;
  }

  // revert to last state where no overflow happened:
  element.style.fontSize = `${i - 1}px`;
};

export const isNullOrEmpty = (text: any) => {
  return text === null || text === undefined || text?.length === 0;
};

export const formatCPF = (cpf: string) => {
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const formatUrl = (url: string) => {
  if (!url) {
    return 'about:blank';
  }
  return withHttp(url);
};

export const sizeText = (str: string) => str?.length;

export const truncateText = (str = '', size: number) => {
  return sizeText(str) > size ? `${str.substring(0, size - 1).trim()}...` : str;
};

export const reduceUserData = (user: any) => {
  return JSON.parse(
    JSON.stringify(user, (key, value) => {
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && !value.length) ||
        value === ''
      ) {
        return undefined;
      }

      return value;
    }),
  );
};

export const buildNamesInline = (
  items: { name: string }[],
  type: 'conjunction' | 'disjunction' = 'disjunction',
) => {
  if (!items?.length) return '';
  try {
    const parsedPlans = Array.from(new Set(items.map(({ name }) => name)));
    return new Intl.ListFormat('pt-BR', { style: 'short', type }).format(
      parsedPlans,
    );
  } catch (e) {
    // polyfill legacy
    const unit = type === 'conjunction' ? 'e' : 'ou';
    return items.reduce(
      (string, { name }, index, array) =>
        array.length === 1
          ? string + name
          : index === array.length - 1
            ? string + `${unit} ${name}`
            : index === array.length - 2
              ? string + `${name} `
              : string + `${name}, `,
      '',
    );
  }
};

export function camelCaseToWords(s: string) {
  const camelCasePhrase = camelCase(s);
  const result = camelCasePhrase.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
