import React from 'react';

import Tab from '../Tab';

export const TAB_VALUES = ['CRA', 'CRI', 'FIDC', 'FII'];

type Props = {
  setMarket: (market: string) => void;
  market: string;
  setOnTransition: (transition: boolean) => void;
  onTransition: boolean;
};

const ProductTab = (props: Props) => {
  const tabValue = TAB_VALUES.indexOf(props.market);

  const handleSelect = (market: string) => () => {
    props.setMarket('');
    if (!props.market) {
      props.setOnTransition(true);
      props.setMarket(market);
      setTimeout(() => {
        props.setOnTransition(false);
      }, 2500);
    } else {
      props.setMarket(market);
      props.setOnTransition(false);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | boolean,
  ) => {
    const marketName = newValue === false ? '' : TAB_VALUES[Number(newValue)];
    props.setMarket(marketName);
  };

  return (
    <Tab
      handleChange={handleChange}
      tabValue={tabValue > -1 ? tabValue : false}
      tabData={[
        {
          label: 'CRA',
          onClick: handleSelect('CRA'),
        },
        {
          label: 'CRI',
          onClick: handleSelect('CRI'),
        },
        {
          label: 'FIDC',
          onClick: handleSelect('FIDC'),
        },
        {
          label: 'FII',
          onClick: handleSelect('FII'),
        },
      ]}
      noBorder={!!props.market || props.onTransition}
      noMarker={props.onTransition}
    />
  );
};

export default ProductTab;
