import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import theme from 'src/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 2rem;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 0;
  }
`;

export const Item = styled.button`
  margin-right: 1rem;
  flex: 0 0 auto;
  bottom: 0;
  background-color: #cfcfcf;
  color: #666666;
  border-radius: 30px;
  padding: 0.5rem 0.75rem;
  border: 0;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  white-space: nowrap;

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0.75rem;
  }

  &.active {
    color: white;
    background-color: #ef781c;
  }
`;

const Arrow = styled(PlayArrowRoundedIcon).attrs({ fontSize: 'inherit' })`
  color: #cfcfcf;
  font-size: 3rem !important;
  cursor: pointer;

  &.has-next {
    color: #ef781c;
  }
`;

export const LeftArrow = styled(Arrow)`
  transform: rotate(180deg);
`;

export const RightArrow = styled(Arrow)`
  right: 0;
`;
