import { Box, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { convertNumber, convertValue } from '../../utils/number';
import DateChip from '../DateChip';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  lensIcon: {
    fontSize: '0.6rem',
    color: '#FFCDA0',
    marginRight: theme.spacing(1),
  },
  serie: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  series: {
    fontWeight: theme.typography.fontWeightBold,
  },
  seriesWithLink: {
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function PaperPanelLiability({
  currency,
  title,
  value,
  date,
  loading,
  onClick,
  decimal,
}) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" style={{ marginTop: '1rem' }}>
        <Typography variant="subtitle1" className={classes.typography}>
          {title}
        </Typography>
        {date && <DateChip date={date} className={classes.serie} />}
      </Box>
      {loading ? (
        <Skeleton variant="text" animation="wave" width="100%" height="2.2em" />
      ) : (
        <>
          <Typography
            variant="subtitle2"
            onClick={onClick}
            className={onClick ? classes.seriesWithLink : classes.series}>
            {currency ? (
              <>{value ? convertValue(value) : 'N/D'}</>
            ) : decimal ? (
              <>{value ? convertNumber(value, 2, 2) : 'N/D'}</>
            ) : (
              <>{value ? value : 'N/D'}</>
            )}
          </Typography>
        </>
      )}
    </>
  );
}
