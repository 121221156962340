// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
// Global components
import PaperDoublePanel from '../../../../../components/PaperDoublePanel';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import {
  GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
  GET_FIDC_CREDIT_RIGHTS_BY_CNPJ,
} from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
}));

function Panels({ cnpj }) {
  const { data, loading } = useQuery(GET_FIDC_CREDIT_RIGHTS_BY_CNPJ, {
    variables: { cnpj },
    client: ipanema,
  });
  const displayFieldAsCurrency = data?.getFidcCreditRightsByCnpj ?? false;

  if (loading) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <PaperDoublePanel
          currency={displayFieldAsCurrency}
          titleLeft="Montante Adquirido"
          valueLeft={data?.getFidcCreditRightsByCnpj?.amountAcquired ?? 'N/D'}
          titleRight="Qtd Adquirido"
          valueRight={
            data?.getFidcCreditRightsByCnpj?.quantityAcquired ?? 'N/D'
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperDoublePanel
          currency={displayFieldAsCurrency}
          titleLeft="Montante Alienado"
          valueLeft={data?.getFidcCreditRightsByCnpj?.amountAlienated ?? 'N/D'}
          titleRight="Qtd Alienado"
          valueRight={
            data?.getFidcCreditRightsByCnpj?.quantityAlienated ?? 'N/D'
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperDoublePanel
          currency={displayFieldAsCurrency}
          titleLeft="Montante Substituído"
          valueLeft={data?.getFidcCreditRightsByCnpj?.amountReplaced ?? 'N/D'}
          titleRight="Qtd Substituído"
          valueRight={
            data?.getFidcCreditRightsByCnpj?.quantityReplaced ?? 'N/D'
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperDoublePanel
          currency={displayFieldAsCurrency}
          titleLeft="Montante Recomprado"
          valueLeft={
            data?.getFidcCreditRightsByCnpj?.amountRepurchased ?? 'N/D'
          }
          titleRight="Qtd Recomprado"
          valueRight={
            data?.getFidcCreditRightsByCnpj?.quantityRepurchased ?? 'N/D'
          }
        />
      </Grid>
    </Grid>
  );
}

export default function OperationFIDCResumeBusinessDC({ hash }) {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_resumo_negocios_com_dc'
      ],
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Negócios com DC
      </Typography>
      <Divider className={classes.divider} />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_resumo_negocios_com_dc'
          ]
        }>
        <Paper className={classes.paper}>
          <PanelLogin />
        </Paper>
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_resumo_negocios_com_dc'
          ]
        }>
        <Panels
          cnpj={
            data?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
              ?.cnpj ?? 'N/D'
          }
        />
      </If>
    </Box>
  );
}
