// Material ui components
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Utils
import { Skeleton } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import SubtitleLink from '../../../components/SubtitleLink';
import { getEvents } from '../../../services/events';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 1, 3),
    maxHeight: 401,
    minHeight: 401,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'unset',
    },
  },
  listItem: {
    padding: theme.spacing(2.5, 0),
    '&:last-child': {
      padding: theme.spacing(2.5, 0, 0),
    },
  },
  day: {
    fontSize: '37px',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#797979',
    textAlign: 'center',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  dayPreview: {
    fontSize: '22px',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#797979',
    textAlign: 'center',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  month: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#797979',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  details: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  link: {
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
}));

export default function NextEvents() {
  const classes = useStyles();
  const {
    data: events,
    isLoading,
    error,
  } = useQuery('eventsHome', () =>
    getEvents({ page: 1, pageSize: 3, filters: { eventState: 'upcoming' } }),
  );

  if (error) return null;

  return (
    <Paper elevation={0} className={classes.paper}>
      <SubtitleLink
        title="Próximos eventos"
        url="/eventos"
        labelLink="Ver todos os eventos"
      />
      <Box pt={2.5}>
        {isLoading && (
          <Skeleton variant="rect" animation="wave" width="100%" height={370} />
        )}
        {events ? (
          <List component="nav" aria-label="lista de eventos" disablePadding>
            {events?.data?.map(
              (
                {
                  id,
                  slug,
                  title,
                  isPreview,
                  type,
                  format: eventFormat,
                  schedules,
                },
                index,
              ) => (
                <Box key={id}>
                  <ListItem
                    button
                    className={classes.listItem}
                    component={Link}
                    to={`/evento/${slug}/${id}`}>
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {schedules?.length && (
                          <>
                            {isPreview ? (
                              <Typography className={classes.dayPreview}>
                                A definir
                              </Typography>
                            ) : (
                              <Typography className={classes.day}>
                                {format(parseISO(schedules[0].startDate), 'dd')}
                              </Typography>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {schedules?.length && (
                          <Typography className={classes.month}>
                            {format(parseISO(schedules[0].startDate), 'MMM', {
                              locale: ptBR,
                            }).toUpperCase()}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        md={3}
                        lg={5}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <Typography className={classes.title}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        md={3}
                        lg={3}
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}>
                        <Typography className={classes.details}>
                          {eventFormat}
                        </Typography>
                        <Typography className={classes.details}>
                          {type}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {index < events?.data?.length - 1 && <Divider />}
                </Box>
              ),
            )}
          </List>
        ) : (
          <Box
            width="100%"
            height="265px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <Typography>
              Ops, não temos eventos agendados neste momento.
            </Typography>
            <Link
              className={classes.link}
              to={{ pathname: '/eventos', state: { openDialog: true } }}>
              Quer ver seu evento aqui?
            </Link>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
