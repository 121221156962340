import { useMemo } from 'react';

import { convertNumber } from 'src/utils/number';

import * as Card from './styles';
import { withLinks } from '../../link-mapper';
import { YearbookMarketChartDetails } from '../../types';

interface ChartDetailsCardProps {
  details: YearbookMarketChartDetails;
}

export default function ChartDetailsCard({ details }: ChartDetailsCardProps) {
  const value = useMemo(
    () => (details.value ? convertNumber(details.value) : 0),
    [details.value],
  );

  return (
    <Card.Wrapper>
      <Card.Container>
        <Card.Title>{details.title}</Card.Title>
        {details.texts.map((text, idx) => (
          <Card.Paragraph
            key={idx}
            dangerouslySetInnerHTML={{ __html: withLinks(text) }}
          />
        ))}
        {details.valueTitle && (
          <>
            <Card.ValueTitle>{details.valueTitle}</Card.ValueTitle>
            <Card.Value>{value}</Card.Value>
            <Card.ValueUnitType>{details.valueUnit}</Card.ValueUnitType>
          </>
        )}
      </Card.Container>
    </Card.Wrapper>
  );
}
