import { useMemo, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/PersonAdd';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import { CustomTooltip } from 'src/components/CustomTooltip';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  getProfessionalRequests,
  updateProfessionalRequest,
} from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution, ProfessionalRequest } from 'src/types/institution';
import { Professional } from 'src/types/professional';

type Props = {
  data?: Institution;
};

type Action = {
  id: number | null;
  status: string | null;
  professional: Professional | null;
};

export function CompanyProfessionals({ data }: Props) {
  const dispatch = useDispatch();
  const [requests, setRequests] = useState<ProfessionalRequest[]>([]);
  const initialAction = { id: null, status: null, professional: null };
  const [action, setAction] = useState<Action>(initialAction);
  const { auth } = useAuthContext();

  const { isLoading: professionalIsLoading, refetch: professionalRefetch } =
    useQuery('professionalRequests', () => getProfessionalRequests(data?.id), {
      onSuccess: async (data) => {
        if (data?.length) {
          const owner = data.filter(
            (request: ProfessionalRequest) =>
              request.professional?.owner?.id === auth?.user?.id,
          );
          const withoutOwner = data.filter(
            (request: ProfessionalRequest) =>
              request.professional?.owner?.id !== auth?.user?.id,
          );
          setRequests([...owner, ...withoutOwner]);
        } else {
          setRequests([]);
        }
      },
    });

  const updateProfessionalRequestMutation = useMutation(
    updateProfessionalRequest,
    {
      onSuccess: async ({ data }) => {
        if (data) {
          if (action.status === 'Aprovado') {
            dispatch(
              setBannerMessage({
                message: 'Profissional adicionado com sucesso à instituição',
                type: 'success',
                autoclose: true,
              }),
            );
          } else if (action.status === 'Rejeitado') {
            dispatch(
              setBannerMessage({
                message: 'Profissional rejeitado com sucesso',
                type: 'success',
                autoclose: true,
              }),
            );
          } else if (action.status === 'Cancelado') {
            dispatch(
              setBannerMessage({
                message: 'Profissional removido com sucesso',
                type: 'success',
                autoclose: true,
              }),
            );
          }
          professionalRefetch();
        }
        setAction(initialAction);
      },
      onError: () => {
        dispatch(
          setBannerMessage({
            message: 'Não foi possível completar a ação',
            type: 'error',
            autoclose: true,
          }),
        );
      },
    },
  );

  const isLoading =
    professionalIsLoading || updateProfessionalRequestMutation.isLoading;

  const getColorStatus = (status: string) => {
    switch (status) {
      case 'Aprovado':
        return 'success';
      case 'Pendente':
        return 'primary';
      default:
        return 'info';
    }
  };

  const getStatus = (
    status: string,
    institution: Institution,
    professional: Professional,
  ) => {
    if (professional?.owner?.id === auth?.user?.id) {
      return 'Administrador';
    }
    if (
      professional?.owner?.id !== auth?.user?.id &&
      professional?.owner?.subscription?.id === auth?.user?.subscription?.id
    ) {
      return 'Convidado';
    }
    return status;
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Nome do profissional',
        size: 50,
        sortingFn: (rowA: any, rowB: any): number => {
          const professionalA = rowA.original.professional?.fullName;
          const professionalB = rowB.original.professional?.fullName;
          return professionalA.localeCompare(professionalB);
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box alignItems={'center'} display={'flex'} gap={2}>
            <Avatar
              src={
                cell.row.original?.professional?.photo?.formats?.thumbnail?.url
              }
              sx={{
                width: '40px',
                height: '40px',
              }}
            />
            <Box>
              <Link
                underline="none"
                component={RouterLink}
                to={`/professional/${cell.row.original?.professional?.slug}/${cell.row.original?.professional?.id}`}>
                <Typography fontSize={14} fontWeight={600} color={'secondary'}>
                  {cell.row.original?.professional?.fullName}
                </Typography>
                <Typography fontSize={14} fontWeight={400} color={'#828282'}>
                  {cell.row.original?.professional?.owner?.email}
                </Typography>
              </Link>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 60,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Chip
              sx={(theme) => ({
                minWidth: 76,
                width: 'fit-content',
                height: 22,
                fontSize: '12px',
                color: theme.palette.primary.contrastText,
                borderRadius: '2px',
                fontWeight: 400,
                padding: 0,
              })}
              color={getColorStatus(cell.row.original.status)}
              label={getStatus(
                cell.row.original.status,
                cell.row.original.institution,
                cell.row.original.professional,
              )}
            />
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'left',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            {cell.row.original.status === 'Pendente' ? (
              <>
                <Button
                  startIcon={<AddIcon />}
                  size="small"
                  sx={{
                    color: '#4F4F4F',
                    fontSize: '14px',
                    fontWeight: 400,
                    textWrap: 'nowrap',
                    textTransform: 'none',
                  }}
                  disabled={
                    isLoading ||
                    !['Pendente'].includes(cell.row.original.status) ||
                    auth?.user?.subscription?.plan?.type === 'free'
                  }
                  onClick={() => {
                    updateProfessionalRequestMutation.mutate({
                      id: cell.row.original?.id,
                      status: 'Aprovado',
                      professional: cell.row.original?.professional,
                    });
                  }}>
                  Adicionar profissional
                </Button>
                <Button
                  startIcon={<CancelIcon />}
                  size="small"
                  sx={{
                    color: '#4F4F4F',
                    fontSize: '14px',
                    fontWeight: 400,
                    textWrap: 'nowrap',
                    textTransform: 'none',
                  }}
                  disabled={
                    isLoading ||
                    !['Pendente', 'Aprovado'].includes(
                      cell.row.original.status,
                    ) ||
                    auth?.user?.subscription?.plan?.type === 'free'
                  }
                  onClick={() => {
                    setAction({
                      id: cell.row.original?.id,
                      status: 'Rejeitado',
                      professional: cell.row.original?.professional,
                    });
                  }}>
                  Recusar solicitação
                </Button>
              </>
            ) : (
              <CustomTooltip
                title="Não é possível remover o administrador"
                placement="top"
                disableFocusListener
                disableTouchListener={
                  cell.row.original?.professional?.owner?.id !== auth?.user?.id
                }
                disableHoverListener={
                  cell.row.original?.professional?.owner?.id !== auth?.user?.id
                }
                arrow>
                <span>
                  <Button
                    startIcon={<PersonOffIcon />}
                    size="small"
                    disabled={
                      cell.row.original?.professional?.owner?.id ===
                      auth?.user?.id
                    }
                    sx={{
                      color: '#4F4F4F',
                      fontSize: '14px',
                      fontWeight: 400,
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      setAction({
                        id: cell.row.original?.id,
                        status: 'Cancelado',
                        professional: cell.row.original?.professional,
                      });
                    }}>
                    Remover profissional
                  </Button>
                </span>
              </CustomTooltip>
            )}
          </Box>
        ),
      },
    ],
    [isLoading, requests],
  );

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: requests,
    localization: {
      ...MaterialTableDefaultProps.localization,
      noRecordsToDisplay: isLoading
        ? 'Atualizando dados...'
        : 'Nenhum profissional vinculado',
    },
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    state: {
      isLoading: isLoading,
    },
    enableStickyHeader: true,
    enableSorting: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: '100%',
        padding: '16px 16px 40px',
      },
    },
  });

  return (
    <Container disableGutters sx={(theme) => ({ padding: theme.spacing(2) })}>
      <ConfirmDialog
        open={
          action?.status
            ? ['Rejeitado', 'Cancelado'].includes(action?.status)
            : false
        }
        title={
          action.status === 'Rejeitado'
            ? `Tem certeza que deseja rejeitar a solicitação de “${action.professional?.fullName}”?`
            : `Tem certeza que deseja remover o usuário “${action.professional?.fullName}”?`
        }
        subTitle={
          action.status === 'Rejeitado'
            ? `Ao rejeitar a solicitação de ${action.professional?.fullName}, ele não irá fazer parte da instituição “${data?.fantasyName}”`
            : 'Ao remover o usuário, a solicitação será excluída e caso deseje vincular novamente o profissional, será necessário fazer uma nova solicitação'
        }
        onClose={() => {
          setAction(initialAction);
        }}
        submitAction={() => {
          updateProfessionalRequestMutation.mutate(action);
        }}
        hasActionButton={Boolean(action?.id)}
      />
      <Grid container spacing={2}>
        <Grid item>
          <Typography fontSize={14}>
            Estes são os profissionais vinculados ao perfil público da sua
            instituição, solicitação e remoção de usuários que fazem parte desta
            instituição são gerenciadas aqui.
          </Typography>
        </Grid>
        <MaterialTable table={table} />
      </Grid>
    </Container>
  );
}
