import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip
    enterTouchDelay={100}
    leaveTouchDelay={1000}
    arrow
    {...props}
    classes={{ ...className }}>
    {children}
  </Tooltip>
))(({ theme, bg }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: bg ? bg : theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bg ? bg : theme.palette.common.black,
    fontSize: '12px',
    textAlign: 'center',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
}));
