import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  background-color: white;
`;

export const Main = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

type ContentProps = {
  bannerspace?: boolean;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 70%;
  padding-top: ${(props) => !props.bannerspace && '100px'};
  padding-bottom: 200px;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.div`
  color: #ff8811;
  letter-spacing: 2.8px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

type MenuPlaceholderProps = {
  open: boolean;
};

export const MenuPlaceholder = styled.div<MenuPlaceholderProps>`
  width: ${(props) => (props.open ? '300px' : '0px')};
  min-height: 500px;
  height: 100%;
  transition: all 1s linear;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
