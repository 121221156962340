// Material ui components
import { Box, Grid } from '@mui/material';

import OperationFIIAssetData from './OperationFIIAssetData';
import OperationFIIWallet from './OperationFIIWallet';

export default function OperationFIIAsset({
  hash,
  operationCnpj,
}: {
  hash: string;
  operationCnpj: string;
}) {
  return (
    <Box p={4}>
      <Grid container>
        <Grid item xs={12}>
          <OperationFIIAssetData hash={hash} />
        </Grid>
        <Grid item xs={12}>
          <OperationFIIWallet operationCnpj={operationCnpj} />
        </Grid>
      </Grid>
    </Box>
  );
}
