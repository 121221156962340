import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  position: relative;
`;

export const Summary = styled.div`
  position: relative;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff8211;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.125);
  padding: 0 1rem;
  z-index: 997;
`;

export const SummaryIcon = styled.img`
  width: 18px;
  height: 18px;
  filter: brightness(0) invert(1);
`;

export const SummaryTitle = styled.h4`
  font-size: 1.1rem;
  color: white;
  margin: 0.5rem;
`;

export const SummaryChevronExpandMore = styled(ExpandMore)`
  color: white;
`;

export const SummaryChevronExpandLess = styled(ExpandLess)`
  color: white;
`;

export const MarketOptions = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 2rem 0.5rem 1rem;
  top: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const MarketOptionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const MarketOption = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
  color: #ff8211;
  cursor: pointer;
  border-radius: 20px;

  &:hover {
    background-color: #f0f0f0;
  }

  &.selected,
  &:active {
    ${MarketOptionIcon} {
      filter: brightness(0) invert(1);
    }
    color: white;
    background-color: #ff8211;
  }
`;

export const MarketOptionText = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
`;
