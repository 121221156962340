import React from 'react';

import {
  Banners,
  ChartDataContentPayload,
  ChartsDataRequestPayload,
  PageData,
} from './types';

export const cast = <T>(v: any): T => {
  return v;
};

export const marketPageFns = {
  getMarketBanners: (
    banners: Banners,
    market: 'CRA' | 'CRI' | 'FIDIC' | 'FII',
  ) => banners.find((e) => e.market === market),
  getChartData: (
    chartsData: ChartsDataRequestPayload[],
    collectionId: number,
  ) => chartsData.find((e) => e.collectionId === collectionId)?.content || [],
  formatPageData:
    (
      indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>,
      marketBanners: Banners[number] | undefined,
    ) =>
    (e: PageData, idx: number) => {
      const index = indexes.find((i) => i.title === e.title);
      const banner = marketBanners?.banners.find((i) => i.sectionId === idx);

      return { ...e, ref: index?.ref, banner };
    },
  checkIsReady: (
    pageIndexes: Array<{ title: string; ref: React.MutableRefObject<any> }>,
    pageData: PageData[],
    chartData: ChartDataContentPayload[],
    chartsMinLength: number,
  ) =>
    pageIndexes.length === pageData.length + 4 &&
    chartData.length >= chartsMinLength,
  getPageProps:
    (open: boolean) =>
    (
      dataSection: PageData & { ref: React.MutableRefObject<any> | undefined },
    ) => {
      return {
        open: open,
        title: dataSection?.title,
        subtitle: dataSection?.subtitle,
        banner: dataSection?.banner,
        pageRef: dataSection?.ref,
        authors: dataSection?.authors,
      };
    },
};
