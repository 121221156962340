import { ReactNode, useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { getRadarOperationsConvocations } from 'src/services/radar';

import { useAuthContext } from '../../../../../../context/AuthContextProvider';

type Props = {
  value: any;
  handleChange: any;
  disabled: boolean;
  market: any;
};

export default function ConvocationAutocomplete({
  disabled,
  handleChange,
  value,
  market,
}: Props) {
  const { auth } = useAuthContext();
  const [inputSearch, setInputSearch] = useState('');
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [data, setData] = useState<any[]>([]);

  const { data: operationsData, isLoading } = useQuery(
    ['data', market],
    () => getRadarOperationsConvocations({ market }),
    {
      enabled: !auth.isLoading,
      staleTime: Infinity,

      onSuccess: (data) => {
        const operations = getOperationNames(data.data);
        setData(operations);
      },
      onError() {
        setError({
          status: true,
          message: 'Não foi possível carregar as opções',
        });
      },
    },
  );

  useEffect(() => {
    if (operationsData) {
      const operations = getOperationNames(operationsData.data);
      setData(operations);
    }
  }, []);

  const getOperationNames = (data: any) => {
    const uniqueNames = new Map();

    data?.forEach((item: any) => {
      const name = item?.operation?.name;
      const hashId = item?.operation?.hashId;
      const isFavorite = 'favorite' in item;
      uniqueNames.set(name, { name, hashId, isFavorite });
    });

    const sortedData = Array.from(uniqueNames.values()).sort(
      (x, y) => y.isFavorite - x.isFavorite,
    );

    return sortedData;
  };

  const handleCheckboxClick = (index: number) => {
    const newCheckedValues = [...value];
    newCheckedValues.splice(index, 1);
    handleChange(newCheckedValues);
  };

  const Group = ({ name, items }: { name: string; items: ReactNode }) => {
    return (
      <Box px={2}>
        <Box py={2}>
          <Typography
            fontSize={10}
            fontWeight={600}
            color={'#BDBDBD'}
            textAlign={'end'}>
            {name}
          </Typography>
          <Divider />
        </Box>
        {items}
      </Box>
    );
  };

  return (
    <>
      <Autocomplete
        id={'convocation-autocomplete'}
        fullWidth
        disableCloseOnSelect
        multiple
        disabled={disabled}
        loading={isLoading}
        loadingText={<CircularProgress color="inherit" size="1.5rem" />}
        options={data || []}
        noOptionsText={'Nenhuma opção encontrada.'}
        onChange={(event, newValue) => handleChange(newValue)}
        inputValue={inputSearch}
        onInputChange={(event, newInputValue) => {
          setInputSearch(newInputValue);
        }}
        getOptionLabel={(option) => option.name || ''}
        groupBy={(option) => option.isFavorite}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ '&.MuiAutocomplete-option': { padding: 0 } }}
            {...props}>
            <Checkbox size="small" checked={selected} />
            <Typography fontSize={12} fontWeight={600} color={'#4F4F4F'}>
              {option.name}
            </Typography>
          </Box>
        )}
        renderGroup={(params) => {
          const { group, children } = params;
          if (group) return <Group name={'Favoritas'} items={children} />;
          return <Group name={'Geral'} items={children} />;
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        value={value}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            error={error.status}
            helperText={error.message}
            type="text"
            variant="standard"
            {...params}
          />
        )}
        sx={{
          mb: 1,
          '&[data-focus="true"]': {
            backgroundColor: '#CCC',
            borderColor: 'transparent',
          },
        }}
      />
      {value.map((v: any, index: number) => (
        <Box key={v.name} display="flex" alignItems="center">
          <Checkbox
            disabled={disabled}
            checked={true}
            onChange={() => handleCheckboxClick(index)}
            inputProps={{ 'aria-label': 'checkbox' }}
            color="primary"
            size="small"
            sx={{
              pl: 0,
            }}
          />
          <Typography fontSize={12} fontWeight={600} color={'#4F4F4F'}>
            {v.name}
          </Typography>
        </Box>
      ))}
    </>
  );
}
