import { Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24.2px',
  },
  link: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function SubtitleLink({ title, url, labelLink }) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} component="h3">
        {title}
      </Typography>
      <Link className={classes.link} to={url}>
        {labelLink}
      </Link>
    </Box>
  );
}
