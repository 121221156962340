import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default function APIOpenApiSwagger({ spec }: { spec: object | string }) {
  const token = window.sessionStorage.getItem('@App:token');
  return (
    <SwaggerUI
      spec={spec}
      requestInterceptor={(req) => {
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
      }}
    />
  );
}
