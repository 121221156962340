import { useEffect, useState } from 'react';

import {
  Box,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format, parseISO } from 'date-fns';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { convertValue } from 'src/utils/number';
import { v4 as uuid } from 'uuid';

export default function OperationTableByDueRange({
  data,
  selectOptions,
  title,
}: {
  data: any;
  selectOptions: string[];
  title: string;
}) {
  const [period, setPeriod] = useState('');
  const [dataByPeriod, setDataByPeriod] = useState(data?.length ? data[0] : {});

  useEffect(() => {
    if (selectOptions?.length) {
      setPeriod(selectOptions[0]);
      setDataByPeriod(
        data?.find((item: any) => item.dataReferencia === selectOptions[0]) ||
          {},
      );
    }
  }, [selectOptions]);

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value);
    setDataByPeriod(
      data.find((item: any) => item.dataReferencia === event.target.value) ||
        {},
    );
  };

  const rawTotal =
    Object.entries(dataByPeriod)?.reduce((acc: number, entry: any) => {
      if (['total', 'dataReferencia'].includes(entry[0])) return acc;
      return acc + Math.abs(entry[1]);
    }, 0) || 0;

  const getValuePercentage = (value: number) => {
    const percentage = rawTotal > 0 ? (100 * value) / rawTotal : 0;
    return Math.abs(percentage);
  };

  const dataRange = [
    {
      label: 'Até 30',
      key: 'ate30dias',
    },
    {
      label: '31 a 60',
      key: '31a50dias',
    },
    {
      label: '61 a 90',
      key: '61a90dias',
    },
    {
      label: '91 a 120',
      key: '91a120dias',
    },
    {
      label: '121 a 150',
      key: '121a150dias',
    },
    {
      label: '151 a 180',
      key: '151a180dias',
    },
    {
      label: '181 a 360',
      key: '181a360dias',
    },
    {
      label: 'Acima de 361 dias',
      key: 'acima361dias',
    },
  ];

  return (
    <Box
      sx={{
        marginTop: '54px',
      }}>
      <Box
        sx={{
          border: '1px solid #E0E0E0',
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.08)',
          padding: '24px 16px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Typography fontSize={16} fontWeight={700}>
          {title}
        </Typography>
        <FormControl variant="standard" sx={{ width: 100 }}>
          <Select
            value={period}
            label="period"
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 245,
                },
              },
            }}>
            {selectOptions?.map((option: string) => (
              <MenuItem key={uuid()} value={option}>
                {format(parseISO(option), 'MM/yyyy')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ border: '1px solid #E0E0E0' }}>
        <TableContainer>
          <Table aria-label="receivable table">
            <TableHead>
              <TableRow
                sx={{
                  '& > th': {
                    fontWeight: 600,
                    padding: '10px 16px',
                  },
                }}>
                <TableCell width="30%">Faixa</TableCell>
                <TableCell width="25%">Montante</TableCell>
                <TableCell width="45%"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRange.map((item) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.key}>
                  <TableCell scope="row">{item.label}</TableCell>
                  <TableCell>
                    {convertValue(dataByPeriod[item.key] || 0)}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <CustomTooltip
                        title={
                          dataByPeriod[item.key]
                            ? `~${getValuePercentage(dataByPeriod[item.key]).toFixed(2)}%`
                            : '0.0%'
                        }
                        placement="top">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Box sx={{ width: '50%' }}>
                            <LinearProgress
                              variant="determinate"
                              color="error"
                              value={100}
                              sx={{
                                backgroundColor: '#E0E0E0',
                                '& > span': {
                                  transform: `translateX(${
                                    100 -
                                    (dataByPeriod[item.key] < 0
                                      ? getValuePercentage(
                                          dataByPeriod[item.key],
                                        )
                                      : 0)
                                  }%) !important`,
                                  backgroundColor: '#F28181',
                                },
                              }}
                            />
                          </Box>
                          <Box sx={{ width: '50%' }}>
                            <LinearProgress
                              variant="determinate"
                              color="warning"
                              value={
                                dataByPeriod[item.key] > 0
                                  ? getValuePercentage(dataByPeriod[item.key])
                                  : 0
                              }
                              sx={{
                                backgroundColor: '#E0E0E0',
                                '& > span': {
                                  backgroundColor: '#FDA85E',
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </CustomTooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  fontWeight: 700,
                  boxShadow: '0px -1px 4px 0px rgba(0, 0, 0, 0.16)',
                }}>
                <TableCell scope="row">Total</TableCell>
                <TableCell>{convertValue(dataByPeriod.total || 0)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
