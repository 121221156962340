import theme from 'src/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: calc(40px + 4rem);
  margin-bottom: 1rem;

  @media screen and (max-width: 1366px) {
    padding-right: 4rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    border-top-left-radius: 25px;
    height: 90%;
    background-color: #ff8211;
    right: 0;
    border-bottom-left-radius: 12.5px;
  }

  .MuiTableContainer-root {
    background-color: white;
    position: relative;
    z-index: 999;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  }
  .MuiTableCell-root.MuiTableCell-body {
    border: none;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding: 1rem 1rem 0 1.5rem;
  }
`;
