import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import warningImage from '../../assets/imgs/warningImage.png';

type Props = {
  open: boolean;
  onClose: () => void;
  handleAction: () => void;
  title: string;
  subTitle: string;
};

function ConfirmationDialog({
  open,
  onClose,
  handleAction,
  title,
  subTitle,
}: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  return (
    <Dialog open={open} maxWidth="md">
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '32px 20px 0',
          overflow: 'hidden',
          minHeight: '465px',
        }}>
        <img src={warningImage} width={185} height={185} />
        <Typography
          sx={{ padding: '0px 64px' }}
          variant="h4"
          textAlign="center">
          {title}
        </Typography>
        <Typography
          maxWidth={430}
          textAlign="center"
          sx={{ fontSize: '0.875rem' }}>
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: isMobile ? 'space-between' : 'center',
          padding: '0 0 40px 0',
          height: isMobile ? '140px' : undefined,
        }}
        disableSpacing={isMobile}>
        <Button
          size="large"
          variant="contained"
          onClick={handleAction}
          sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
          Sim
        </Button>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
