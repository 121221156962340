import React from 'react';

import { Scrollama, Step } from 'react-scrollama';

import * as S from './styles';

type Props = {
  texts: JSX.Element[];
  images: Array<JSX.Element | null>;
};

const Scroller = (props: Props) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  const handleOnChange = ({ data }: { data: number }) => {
    setCurrentPage(data);
  };

  return (
    <S.Scene>
      <div>
        <Scrollama offset="0.5" onStepEnter={handleOnChange}>
          {props.texts.map((text, index) => (
            <Step data={index} key={`step-${index}`}>
              <S.TextBox>{text}</S.TextBox>
            </Step>
          ))}
        </Scrollama>
      </div>
      <S.ImageBox show={true}>{props.images[currentPage]}</S.ImageBox>
    </S.Scene>
  );
};

export default Scroller;
