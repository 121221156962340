import { makeStyles } from '@material-ui/core';

export const trainingStyles = makeStyles((theme) => ({
  courseTopWrap: {
    border: '1px solid',
    marginTop: theme.spacing(2),
  },
  courseTopSaleAllowedWrap: {
    width: '100%',
    padding: theme.spacing(0.9, 0),
    textAlign: 'center',
  },
  courseTopSaleAllowed: {
    height: 70,
    width: 270,
  },
  courseTopSaleAllowedText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  courseHeaderImage: {
    height: '100%',
    minHeight: 164,
  },
  courseTopPaper: {
    width: '100%',
    display: 'flex',
    marginBottom: '0.5rem',
  },
  courseTopPaperInfo: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    marginBottom: '0.5rem',
    padding: theme.spacing(1, 0, 1),
  },
  courseTopButton: {
    border: '1px solid',
    borderRadius: 0,
    padding: theme.spacing(1, 8),
    fontSize: '1.25rem',
    margin: '1rem auto 1.3rem',
  },
  courseTopInvestment: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContentRoot: {
    display: 'flex',
    justifyContent: 'center',
    height: 100,
    width: '100%',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  typography: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  dividerTop: {
    height: '50%',
    marginTop: 25,
    backgroundColor: theme.palette.primary.main,
    margin: -1,
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    marginTop: '0.2rem',
    height: theme.spacing(0.5),
  },
  dividerDetails: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: '0.2rem',
    height: theme.spacing(0.5),
  },
  resumeStartDate: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0, 1, 1),
  },
  details: {
    padding: theme.spacing(0, 1, 1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  supportersMedia: {
    height: 100,
  },
  organizersMedia: {
    height: 80,
  },
  facilitatorsAvatarLarge: {
    border: `1px solid ${theme.palette.common.black}`,
    margin: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  facilitatorsAvatarExtraLarge: {
    border: `2px solid ${theme.palette.secondary.main}`,
    margin: theme.spacing(4, 0, 4, 4),
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  facilitatorsPaper: {
    backgroundColor: theme.palette.common.black,
  },
  facilitatorsDetailsName: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.5rem',
  },
  facilitatorsDetailsDescription: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-line',
  },
  facilitatorsItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
  contentImage: {
    height: '25em',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '16em',
      objectFit: 'contain',
    },
  },
  titleItem: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  sponsor: {
    marginTop: theme.spacing(5),
  },

  testmonials: {
    padding: theme.spacing(2),
  },

  testmonial: {
    fontWeight: theme.typography.fontWeightMedium,
    fontStyle: 'italic',
  },

  testmonialName: {
    marginTop: theme.spacing(1),
    fontStyle: 'italic',
  },
  starIcon: {
    color: theme.palette.secondary.main,
  },
  periodCourseTop: {
    display: 'block',
    width: 105,
    [theme.breakpoints.down('xs')]: {
      width: 79,
    },
  },
}));
