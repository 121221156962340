import { useEffect, useState } from 'react';

import { Clear, Search } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from 'react-query';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';

import {
  getMarkets,
  getSegments,
  searchMarketplaceInstitutions,
} from '../../../services/institution';
import { useDebounce } from '../../../utils/hooks';

interface InstitutionOptions {
  id?: number;
  fantasyName: string;
}

type Props = {
  query: {
    keyword?: string;
    segments?: string[];
    markets?: string[];
    institutions?: string[];
  };
  setQuery: (nextParams: any, method?: 'push' | 'remove' | undefined) => void;
  type: 'servicos' | 'projetos';
};

export default function Filters({ query, setQuery, type }: Props) {
  const theme = useTheme();
  const MenuProps = {
    PaperProps: {
      sx: {
        maxWidht: 300,
      },
    },
    MenuListProps: {
      sx: {
        maxHeight: 240,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 8,
          WebkitAppearance: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          backgroundColor: theme.palette.grey['400'],
        },
      },
    },
  };
  const [searchTerm, setSearchTerm] = useState(query.keyword ?? '');
  const [selectedSegments, setSelectedSegments] = useState<string[]>(
    query.segments ?? [],
  );
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>(
    query.markets ?? [],
  );
  const [institutions, setInstitutions] = useState<InstitutionOptions[]>([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState<any[]>([]);

  const { data: segmentsData, isLoading: segmentsLoading } = useQuery(
    'segments',
    getSegments,
  );
  const { data: marketsData, isLoading: marketsLoading } = useQuery(
    'markets',
    getMarkets,
  );

  const { isLoading: institutionsLoading, refetch: institutionsRefetch } =
    useQuery(
      'institutions',
      () =>
        searchMarketplaceInstitutions({
          type: type === 'projetos' ? 'Projeto' : 'Serviço',
        }),
      {
        onSuccess: (data) => {
          setInstitutions(data);
        },
      },
    );

  const debouncedSearchTerm = useDebounce(searchTerm, 700);

  const handleSelectedSegments = (event: any) => {
    const { value } = event.target;
    setSelectedSegments(value);
    setQuery({
      ...query,
      segments: value,
    });
  };

  const handleSelectedMarkets = (event: any) => {
    const { value } = event.target;
    setSelectedMarkets(value);
    setQuery({
      ...query,
      markets: value,
    });
  };

  const handleSelectedInstitutions = (event: any) => {
    const { value } = event.target;
    setSelectedInstitutions(value);
    setQuery({
      ...query,
      institutions: value,
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setQuery({
        keyword: debouncedSearchTerm,
      });
    } else {
      setQuery(
        {
          keyword: undefined,
        },
        'remove',
      );
    }
  }, [debouncedSearchTerm]);

  return (
    <Grid
      container
      alignItems="flex-end"
      sx={(theme) => ({ margin: theme.spacing(7, 0) })}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder={
            type === 'projetos'
              ? 'Busque por profissionais ou projetos'
              : 'Busque por serviços'
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            sx: { height: 56 },
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: theme.palette.grey['500'] }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!searchTerm}
                  onClick={() => setSearchTerm('')}
                  onMouseDown={() => setSearchTerm('')}
                  edge="end">
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-input': {
              height: theme.spacing(2),
            },
          }}
        />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        alignItems="flex-end"
        sx={{ maxWidth: 850, marginTop: 3 }}>
        <Grid item xs={12} md>
          <Typography fontWeight={500}>Filtre minha busca por: </Typography>
        </Grid>
        {!segmentsLoading && !!segmentsData?.data?.length && (
          <Grid item xs={12} md>
            <FormControl
              variant="standard"
              sx={(theme) => ({
                width: '100%',
                maxWidth: 182.5,
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              })}>
              <InputLabel id="segment-label">Segmentos</InputLabel>
              <Select
                maxRows={1}
                labelId="segment-label"
                value={selectedSegments}
                onChange={handleSelectedSegments}
                multiple
                renderValue={(selected) => {
                  const selectedSegments: Segment[] =
                    segmentsData?.data?.filter((segment: any) =>
                      selected.some((s: any) => s === segment.id),
                    );
                  return `${selectedSegments
                    .map(({ name }) => name)
                    .join(', ')}`;
                }}
                MenuProps={MenuProps}>
                {segmentsData?.data?.map((opt: Segment) => (
                  <MenuItem value={opt.id} key={opt.id}>
                    <Checkbox
                      checked={selectedSegments.some(
                        (value) => parseInt(value) === opt.id,
                      )}
                    />
                    <ListItemText primary={opt.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!marketsLoading && !!marketsData?.data?.length && (
          <Grid item xs={12} md>
            <FormControl
              fullWidth
              variant="standard"
              sx={(theme) => ({
                width: '100%',
                maxWidth: 182.5,
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              })}>
              <InputLabel id="market-label">Mercados</InputLabel>
              <Select
                labelId="market-label"
                value={selectedMarkets}
                onChange={handleSelectedMarkets}
                multiple
                renderValue={(selected) => {
                  const marketSelected: Market[] = marketsData?.data?.filter(
                    (market: any) => selected.some((s: any) => s === market.id),
                  );
                  return `${marketSelected.map(({ name }) => name).join(', ')}`;
                }}
                MenuProps={MenuProps}>
                {marketsData?.data?.map((opt: Market) => (
                  <MenuItem value={opt.id} key={opt.id}>
                    <Checkbox
                      checked={selectedMarkets.some(
                        (value) => parseInt(value) === opt.id,
                      )}
                    />
                    <ListItemText primary={opt.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!institutionsLoading && !!institutions?.length && (
          <Grid item xs={12} md>
            <FormControl
              fullWidth
              variant="standard"
              sx={(theme) => ({
                width: '100%',
                maxWidth: 182.5,
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              })}>
              <InputLabel id="institutions-label">Instituições</InputLabel>
              <Select
                labelId="institutions-label"
                value={selectedInstitutions}
                onChange={handleSelectedInstitutions}
                multiple
                renderValue={(selected) => {
                  const institutionSelected: InstitutionOptions[] =
                    institutions?.filter((institution: any) =>
                      selected.some((s: any) => s === institution.id),
                    );
                  return `${institutionSelected
                    .map(({ fantasyName }) => fantasyName)
                    .join(', ')}`;
                }}
                MenuProps={MenuProps}>
                {institutions?.map((opt: InstitutionOptions) => (
                  <MenuItem value={opt.id} key={opt.id}>
                    <Checkbox
                      checked={selectedInstitutions.some(
                        (value) => parseInt(value) === opt.id,
                      )}
                    />
                    <ListItemText primary={opt.fantasyName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
