import { createRef, useEffect, useState } from 'react';

import { CircularProgress, Grid, Link } from '@material-ui/core';
import { useInfiniteQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Subheader from '../../../components/Subheader';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { getVideosByCollection } from '../../../services/videos';
import { useIsElementVisible } from '../../../utils/hooks';
import { ScrollToTop } from '../../../utils/scroll';
import VideoCard from '../VideoCard';

export default function VideoCollection() {
  const lastRef = createRef<HTMLDivElement>();
  const { auth } = useAuthContext();
  const { id } = useParams<any>();
  const [collections, setCollections] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  // @ts-ignore
  const hasPermission = auth?.user?.subscription?.plan?.permissions?.videos;

  const isLastVisible = useIsElementVisible(lastRef);

  const { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['videosByCollection'],
      ({ pageParam = 1 }) =>
        getVideosByCollection({
          page: pageParam,
          pageSize: 9,
          collectionId: id,
        }),
      {
        onSuccess: (data) => {
          const page: any = data.pages.at(-1);
          setTotal(page.total);
          setCollections((prevState) => [...prevState, ...page.data]);
        },
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length + 1;
          return lastPage.data.length !== 0 ? nextPage : undefined;
        },
        refetchOnMount: 'always',
        cacheTime: 0,
      },
    );

  useEffect(() => {
    if (isLastVisible && collections?.length < total) {
      fetchNextPage();
    }
  }, [isLastVisible, collections]);

  if (isLoading) return <CircularProgress variant={'indeterminate'} />;

  return (
    <ScrollToTop>
      <Subheader
        title={collections[0]?.collection?.name ?? ''}
        breadcrumbs1={
          <Link
            component={RouterLink}
            to="/videos"
            underline="none"
            color="secondary">
            Galeria
          </Link>
        }
        breadcrumbs2={collections[0]?.collection?.name ?? ''}
      />
      <Grid item xs={12}>
        <Grid container spacing={5}>
          {collections?.map(
            ({
              id,
              title,
              uploadedAt,
              collection,
              slug,
              payload,
              document,
            }) => (
              <Grid key={id} item xs={12} md={4}>
                <VideoCard
                  id={id}
                  title={title}
                  uploadedAt={uploadedAt}
                  collection={collection}
                  slug={slug}
                  thumbnail={payload?.pictures?.formats?.thumbnail?.url}
                  duration={payload?.duration}
                  document={document}
                  allowed={hasPermission}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      {!isFetchingNextPage && collections?.length < total && (
        <div ref={lastRef} />
      )}
      {isFetchingNextPage && hasNextPage && (
        <CircularProgress variant={'indeterminate'} size="1.8rem" />
      )}
    </ScrollToTop>
  );
}
