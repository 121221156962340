import { useState } from 'react';

import { Bookmark, BookmarkBorder, CalendarToday } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Market } from 'src/types/market';

import { EventPresentationFilters } from '../..';

interface DateIconButtonProps {
  label: string;
  items: string[];
  onClick: (value: string) => void;
  selected: string[];
}
const DateIconButton = ({
  label,
  onClick,
  items,
  selected,
}: DateIconButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Box sx={{ cursor: 'pointer' }} display="flex" alignItems="center" gap={1}>
      <Box
        onClick={(e) => handleClick(e)}
        display="flex"
        gap={1}
        alignItems="center">
        <CalendarToday style={{ color: '#FF8211' }} />
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            color: selected?.length ? '#FF8211' : '#828282',
          }}>
          {label}
        </Typography>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {items.map((date, index) => (
          <MenuItem
            value={date}
            key={`${index}-date`}
            onClick={() => onClick(date)}>
            <Checkbox
              checked={selected?.some((selectedDate) => selectedDate === date)}
            />
            <ListItemText
              primary={format(parseISO(date), "dd 'de' MMMM 'de' yyyy", {
                locale: ptBR,
              })}
            />
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default function HeaderFilters({
  markets,
  selectedMarkets,
  handleSelectedMarkets,
  filters,
  dateFilterOptions,
  handleSelectDate,
  handleSavedClick,
}: {
  markets: Market[];
  selectedMarkets: string[];
  handleSelectedMarkets: (value: any) => void;
  filters: EventPresentationFilters;
  dateFilterOptions: any[];
  handleSelectDate: (value: any) => void;
  handleSavedClick: () => void;
}) {
  const { auth } = useAuthContext();

  return (
    <>
      <Box width={218}>
        <Typography
          sx={(theme) => ({
            color: '#FF8211',
            fontSize: '14px',
          })}>
          Filtrar por mercado
        </Typography>
        <FormControl fullWidth>
          <Select
            labelId={`${markets}`}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                marginTop: '-37px',
              },
            })}
            id="markets"
            name="markets"
            multiple
            value={selectedMarkets}
            onChange={handleSelectedMarkets}
            input={<Input />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              sx: {
                '& .Mui-selected': {
                  backgroundColor: 'white',
                },
              },
            }}
            displayEmpty
            renderValue={(selected) => {
              if (!selected?.length) {
                return (
                  <Typography
                    sx={{
                      color: '#BDBDBD',
                      fontSize: '14px',
                    }}>
                    Selecione um mercado
                  </Typography>
                );
              }
              return selected?.join(', ');
            }}>
            {markets?.map((option) => (
              <MenuItem key={`${option.name}-market`} value={option.name}>
                <Checkbox
                  checked={selectedMarkets.some(
                    (value) => value === option.name,
                  )}
                />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <CustomTooltip
        title="Faça login para acessar esta funcionalidade"
        disableHoverListener={auth.isLoggedIn}
        disableTouchListener={auth.isLoggedIn}
        placement="top">
        <Box
          sx={{ cursor: 'pointer' }}
          display="flex"
          alignItems="center"
          gap={1}
          onClick={handleSavedClick}>
          <IconButton
            sx={{
              padding: 0,
              '&:hover': { background: 'transparent' },
              height: '24px',
              width: 'unset',
            }}
            disabled={!auth.isLoggedIn}>
            {filters.filterSaved ? (
              <Bookmark
                sx={{
                  height: '24px',
                  width: 'unset',
                  color: auth.isLoggedIn ? '#FF8211' : '#828282',
                }}
              />
            ) : (
              <BookmarkBorder
                sx={{
                  height: '24px',
                  width: 'unset',
                  color: auth.isLoggedIn ? '#FF8211' : '#828282',
                }}
              />
            )}
          </IconButton>
          <Typography
            color={filters.filterSaved ? '#FF8211' : '#828282'}
            fontWeight={600}
            fontSize={14}>
            Salvos
          </Typography>
        </Box>
      </CustomTooltip>
      <DateIconButton
        label="Data"
        items={dateFilterOptions}
        onClick={handleSelectDate}
        selected={filters.dates}
      />
    </>
  );
}
