import { useState } from 'react';

import { If } from 'src/components/If';

import * as Selector from './styles';
import { markets } from '../../../presentation/data';
import { YearbookMarket } from '../../../types';

interface MarketSelectorDropdown {
  selected: YearbookMarket;
  selectMarket: (market: YearbookMarket) => void;
}

export default function MarketSelectorDropdown({
  selected,
  selectMarket,
}: MarketSelectorDropdown) {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  function toggleSelector() {
    setIsSelectorOpen(!isSelectorOpen);
  }

  function handleSelect(market: YearbookMarket) {
    selectMarket(market);
    setIsSelectorOpen(false);
  }

  function getMarketIconPath(name: string) {
    return require(`src/assets/imgs/yearbook/2024/icons/${name}.svg`);
  }

  return (
    <Selector.Wrapper>
      <Selector.Summary onClick={toggleSelector}>
        <Selector.SummaryIcon src={getMarketIconPath(selected.name).default} />
        <Selector.SummaryTitle>{selected.name}</Selector.SummaryTitle>
        {isSelectorOpen ? (
          <Selector.SummaryChevronExpandLess />
        ) : (
          <Selector.SummaryChevronExpandMore />
        )}
      </Selector.Summary>
      <If condition={isSelectorOpen}>
        <Selector.MarketOptions>
          {markets.map((market) => (
            <Selector.MarketOption
              key={market.name}
              className={market.name === selected.name ? 'selected' : ''}
              onClick={() => handleSelect(market)}>
              <Selector.MarketOptionIcon
                src={getMarketIconPath(market.name).default}
                style={{ mask: getMarketIconPath(market.name).default }}
                alt={market.name}
              />
              <Selector.MarketOptionText>
                {market.name}
              </Selector.MarketOptionText>
            </Selector.MarketOption>
          ))}
        </Selector.MarketOptions>
      </If>
    </Selector.Wrapper>
  );
}
