import { Dispatch, SetStateAction, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button } from '@mui/material';
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikProvider,
  useFormik,
} from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { If } from 'src/components/If';
import { updateProfessional } from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { Professional } from 'src/types/professional';
import * as Yup from 'yup';

import ExperienceForm from './ExperienceForm';
import ExperiencesList from './ExperiencesList';

function Experiences({
  openForm,
  setOpenForm,
  professional,
  professionalRefetch,
}: {
  openForm: boolean;
  setOpenForm: Dispatch<SetStateAction<boolean>>;
  professional: Professional | undefined;
  professionalRefetch: () => void;
}) {
  const dispatch = useDispatch();
  const [index, setIndex] = useState<number | undefined>();
  const [actionType, setActionType] = useState<
    'create' | 'update' | 'delete'
  >();

  const FORM_VALIDATION = Yup.object().shape({
    experiences: Yup.array(
      Yup.object({
        companyName: Yup.string().required('Campo obrigatório'),
        professionalTitle: Yup.string().required('Campo obrigatório'),
        startDate: Yup.string().required('Campo obrigatório').nullable(),
        endDate: Yup.string().required('Campo obrigatório').nullable(),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      experiences: professional?.experiences ?? [],
    },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const handleAddExperience = (arrayHelpers: FieldArrayRenderProps) => {
    setIndex(undefined);
    setOpenForm(true);
    setActionType('create');
    arrayHelpers.push({
      companyName: '',
      endDate: '',
      professionalTitle: '',
      startDate: '',
    });
  };

  const updateProfessionalMutation = useMutation(updateProfessional, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: `Não foi possível ${
              actionType === 'update'
                ? 'editar'
                : actionType === 'delete'
                  ? 'remover'
                  : 'adicionar'
            } a experiência`,
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: `Experiência ${
              actionType === 'update'
                ? 'atualizada'
                : actionType === 'delete'
                  ? 'removida'
                  : 'adicionada'
            } com sucesso`,
          }),
        );
      }
      setOpenForm(false);
      setActionType(undefined);
      professionalRefetch();
    },
  });

  const { isLoading } = updateProfessionalMutation;

  const handleUpdate = (values: any) => {
    if (professional?.id) {
      updateProfessionalMutation.mutateAsync({
        id: professional.id,
        values: {
          data: {
            experiences: values,
          },
        },
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="experiences"
        render={(arrayHelpers) => (
          <Box sx={{ padding: '32px 24px 24px' }}>
            <If condition={!openForm}>
              <Button
                startIcon={<AddCircleIcon color="primary" fontSize="large" />}
                color="secondary"
                onClick={() => handleAddExperience(arrayHelpers)}
                sx={{
                  textTransform: 'none',
                  letterSpacing: 'unset',
                  textDecoration: 'underline !important',
                  fontSize: '16px',
                  fontWeight: 700,
                  padding: '16px 8px',
                  color: '#FF8211',
                }}>
                Adicionar experiência
              </Button>
              <If condition={formik.values.experiences?.length > 0}>
                <ExperiencesList
                  rows={formik.values.experiences}
                  setIndex={setIndex}
                  setOpen={setOpenForm}
                  setActionType={setActionType}
                  index={index}
                  arrayHelpers={arrayHelpers}
                  handleUpdate={handleUpdate}
                />
              </If>
            </If>
            <If condition={openForm}>
              <ExperienceForm
                open={openForm}
                onClose={() => setOpenForm(false)}
                formik={formik}
                index={index ?? formik.values.experiences.length - 1}
                arrayHelpers={arrayHelpers}
                actionType={actionType}
                handleUpdate={handleUpdate}
              />
            </If>
          </Box>
        )}
      />
    </FormikProvider>
  );
}

export default Experiences;
