import _ from 'lodash';

import {
  ChartDataContentPayload,
  ChartDataPayload,
  CommonChartData,
} from './types';
import { cast } from './utils';

export const YEARS = ['2018', '2019', '2020', '2021', '2022'];

export const formatQuantityXAmount = (
  data: Array<CommonChartData & { quantity: number }>,
  categories: string[],
  mapName?: { amount: string; quantity: string },
): Array<{
  name: string;
  type: 'column' | 'line' | string;
  data: number[];
}> => {
  const sortedByYear = data?.length
    ? categories.map((year) => [
        year,
        cast<any>(data.find((e) => String(e.year) === year)),
      ])
    : [];
  const cleansedSortedByYear = sortedByYear.filter(([, value]) => value);

  const amountData = {
    name: mapName?.amount || 'Montante emitido',
    type: 'column',
    data: data?.length
      ? cleansedSortedByYear.map(([, data]) => data?.amount || 0)
      : [],
  };

  const quantityData = {
    name: mapName?.quantity || 'Num. Operações',
    type: 'line',
    data: data?.length
      ? cleansedSortedByYear.map(([, data]) => Math.floor(data?.quantity || 0))
      : [],
  };

  return [amountData, quantityData].filter(
    (item) => !item.data.every((v) => v === 0),
  );
};

export const formatPieData = (
  values: Array<{ amount: number; perfil: string }>,
) => {
  const total = values.map((e) => e.amount).reduce((a, b) => a + b, 0);

  const data = values.map((e) => (e.amount / total) * 100);

  return {
    categories: values.map((e) => e.perfil),
    data,
  };
};

const calcPercent = <T>(values: {
  [year: string]: Array<CommonChartData & { [k: string]: any }>;
}): T => {
  const parsedValues = Object.entries(values).map(([year, values]) => {
    const total = values.reduce((a, b) => a + b.amount, 0);

    return [
      year,
      values.map((v) => ({
        ...v,
        amount: `${((v.amount / total) * 100).toFixed(2)} %`,
      })),
    ];
  });

  return Object.fromEntries(parsedValues);
};

export const formatByField = (
  data: Array<CommonChartData & { [k: string]: any }>,
  categories: string[],
  field: string,
) => {
  const fieldValues = _.uniq(data.map((i) => i[field]));
  const sortedByYear = _.groupBy(data, (d) => d.year);

  const parsedValuesByYear = calcPercent<typeof sortedByYear>(sortedByYear);

  const sortedByCategory = fieldValues.map((key) => ({
    name: key || 'Outros',
    data: categories.map(
      (year) => parsedValuesByYear[year]?.find((i) => i[field] === key)?.amount,
    ),
  }));

  const yearsToExclude = categories.filter((_y, index) =>
    sortedByCategory.every((e) => e.data[index] === undefined),
  );

  const yearsToExcludeIndexes = yearsToExclude.map((i) =>
    categories.indexOf(i),
  );

  const filteredSortedByCategory = sortedByCategory.map((item) => ({
    ...item,
    data: item.data
      .map((value, idx) =>
        yearsToExcludeIndexes.includes(idx) ? undefined : value || 0,
      )
      .filter((value) => value !== undefined),
  }));

  return filteredSortedByCategory;
};

export const formatDate = (dateString: string) =>
  Number(dateString.substring(0, 4));

const mapChartData =
  (customFieldName: string) => (content: ChartDataPayload) => {
    const entries = Object.entries(content);
    const amountEntry = entries.find(
      ([key]) => /Soma de montante/gi.test(key) || /amount/gi.test(key),
    );
    const amount = amountEntry ? Number(amountEntry[1]) : 0;

    const restFieldEntry = entries.find(
      ([key]) =>
        !/Soma de montante/gi.test(key) &&
        !/amount/gi.test(key) &&
        !/data_emissao/.test(key) &&
        !/dat[ae]/g.test(key),
    );

    const yearEntry = entries.find(
      ([key]) => /data_emissao/.test(key) || /dat[ae]/g.test(key),
    );

    const field = restFieldEntry ? restFieldEntry[1] : 0;

    return {
      year: formatDate((yearEntry && String(yearEntry[1])) || ''),
      amount,
      [customFieldName]: field,
    };
  };

export const genChartKey = (data: ChartDataContentPayload) =>
  `${data.id}-${data.name}`;

export const formatChartProps =
  (data: ChartDataContentPayload) =>
  (params: {
    chart: 'columnLine' | 'column';
    categories: string[];
    mapName?: { amount: string; quantity: string };
    field?: string;
  }) => {
    const field = params.field || 'quantity';
    const formatedData = cast<any>(data.content.map(mapChartData(field)));

    const chartPropData =
      params.chart === 'columnLine'
        ? formatQuantityXAmount(formatedData, params.categories, params.mapName)
        : formatByField(formatedData, params.categories, field);

    const contentDates = data.content.map((c) => {
      const values = Object.values(c)
        .filter((v) => /\d{4}-\d{2}-\d{2}/.test(String(v)))
        .map((date) => String(date).slice(0, 4));

      return values;
    });

    const uniqDates = _.uniq(_.flatten(contentDates));

    return {
      title: data.name,
      categories: uniqDates,
      data: cast<any>(chartPropData),
    };
  };

export const formatPieChartProps = (
  data: Omit<ChartDataContentPayload, 'content'> & {
    content: Array<{ perfil: string; amount: number }>;
  },
) => {
  const chartPropData = formatPieData(data.content);

  return {
    title: data.name,
    data: chartPropData.data,
    categories: chartPropData.categories,
  };
};

export const formatMonthlyColumn =
  (categories: string[]) =>
  (
    sourceData: ChartDataContentPayload,
    mapping: { amount: string; date: string; name: string },
  ) => {
    const categoriesKey = _.flatten(
      categories.map((year) =>
        _.range(12).map(
          (month) => `${year}-${month < 10 ? `0${month}` : month}-01`,
        ),
      ),
    );

    const chartData = {
      ...sourceData,
      content: sourceData.content.map((c) => ({
        ...c,
        [mapping.name]: c[mapping.name] || 'Outros',
      })),
    };

    const getTotalByYear = (year: number) =>
      chartData.content
        .filter((d) => new RegExp(String(year)).test(String(d[mapping.date])))
        .reduce((acc, curr) => acc + Number(curr[mapping.amount]), 0);

    const uniqTypeNames = _.uniq(chartData.content.map((i) => i[mapping.name]));

    const groupedValues = _.groupBy(chartData.content, (i) => i[mapping.name]);

    const data = uniqTypeNames.map((name) => {
      const dataValue = categoriesKey.map((date) => {
        const foundItem = groupedValues[name].find(
          (i) => i[mapping.date] === date,
        );

        return foundItem?.[mapping.amount] || 0;
      });

      const dataFractions = _.chunk(dataValue, 12).map((chunk, index) => {
        const sum = chunk.reduce((acc, curr) => Number(acc) + Number(curr), 0);

        return Number(sum) / Number(getTotalByYear(Number(categories[index])));
      });

      return {
        name,
        data: dataFractions.map((frac) => frac * 100),
      };
    });

    return {
      title: chartData.name,
      categories,
      data: cast<any>(data),
    };
  };

export const formatMonthlyColumnSpread =
  (categories: string[]) =>
  (
    sourceData: ChartDataContentPayload,
    mapping: { amount: string; date: string; name: string },
  ) => {
    const categoriesKey = _.flatten(
      categories.map((year) =>
        _.range(12).map(
          (month) =>
            `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-01`,
        ),
      ),
    );

    const chartData = {
      ...sourceData,
      content: sourceData.content.map((c) => ({
        ...c,
        [mapping.name]: c[mapping.name] || 'Outros',
      })),
    };

    const getTotalByYearMonth = (dateString: string) => {
      return chartData.content
        .filter((d) =>
          new RegExp(dateString.slice(0, 7)).test(String(d[mapping.date])),
        )
        .reduce((acc, curr) => acc + Number(curr[mapping.amount]), 0);
    };

    const uniqTypeNames = _.uniq(chartData.content.map((i) => i[mapping.name]));

    const groupedValues = _.groupBy(chartData.content, (i) => i[mapping.name]);

    const data = uniqTypeNames.map((name) => {
      const dataValue = categoriesKey.map((date) => {
        const foundItem = groupedValues[name].find(
          (i) => i[mapping.date] === date,
        );

        return foundItem?.[mapping.amount] || 0;
      });

      const dataFractions = dataValue.map((value, index) => {
        return (
          Number(value) / Number(getTotalByYearMonth(categoriesKey[index]))
        );
      });

      return {
        name,
        data: dataFractions.map((frac) => frac * 100),
      };
    });

    return {
      title: chartData.name,
      categories: categoriesKey.map((date) =>
        date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, (match, year, month) =>
          new Date(year, month).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'long',
          }),
        ),
      ),
      data: cast<any>(data),
      disableAnimation: true,
    };
  };

export const formatMonthlyColumnLine =
  (categories: String[]) =>
  (
    chartData: ChartDataContentPayload,
    maping: {
      amount: { source: string; value: string };
      quantity: { source: string; value: string };
    },
  ) => {
    const categoryKeys = _.flatten(
      categories.map((year) =>
        _.range(12).map((month) =>
          new Date(Number(year), month).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'long',
          }),
        ),
      ),
    );

    const data = [
      {
        name: maping.amount.value,
        type: 'column',
        data: chartData.content.map((i) => Number(i[maping.amount.source])),
      },
      {
        name: maping.quantity.value,
        type: 'line',
        data: chartData.content.map((i) => Number(i[maping.quantity.source])),
      },
    ];

    return {
      title: chartData.name,
      categories: categoryKeys,
      data: cast<any>(data),
    };
  };
