import { useMemo } from 'react';

import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { format, parse } from 'date-fns';
import { Link } from 'react-router-dom';
import { Presentation, Speaker as SpeakerType } from 'src/types/event';

import PreviewLiveScreen from '../../../PreviewLiveScreen';

interface CarouselItemProps {
  presentation: Presentation;
  showPreview?: boolean;
}

export const CarouselItem = ({
  presentation,
  showPreview,
}: CarouselItemProps) => {
  const previewMediaHeight = '300px';
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const { status, link } = useMemo(() => {
    switch (presentation.state) {
      case 'ongoing':
        return {
          status: 'Acontecendo agora',
          link: `/evento/${presentation.event.title}/${presentation.event.id}/palestra/${presentation.id}/live`,
        };
      case 'upcoming':
        return {
          status: 'A seguir',
          link: `/evento/${presentation.event.title}/${presentation.event.id}/programacao?presentationId=${presentation.id}`,
        };
      default:
        return { status: '-', link: '' };
    }
  }, [presentation.state]);

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
      }}>
      <Link
        to={{ pathname: link }}
        target={status === 'A seguir' ? '_blank' : undefined}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      {showPreview &&
        !!presentation.liveUrl &&
        presentation.state === 'ongoing' &&
        isMobile && (
          <Box height={'100%'} textAlign={'left'}>
            <PreviewLiveScreen
              presentation={presentation}
              width="100%"
              height={previewMediaHeight}
            />
          </Box>
        )}
      {isMobile &&
        showPreview &&
        (presentation.state !== 'ongoing' || !presentation.liveUrl) && (
          <Box>
            <img
              src={presentation?.event?.image?.url}
              height={previewMediaHeight}
              width={'100%'}
            />
          </Box>
        )}
      <Box
        key={`${presentation.id}-presentation`}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={{ md: 3.5, xs: 2.5 }}>
        <Box
          sx={(theme) => ({
            py: 4,
            display: 'flex',
            flexShrink: 0,
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              '& span': {
                display: 'none',
              },
            },
          })}>
          <Typography
            color="#FF8211"
            fontSize={{ md: 16, xs: 14 }}
            fontWeight={700}>
            {status}
          </Typography>
          <Typography fontSize={{ md: 16, xs: 14 }} color="#4F4F4F">
            <Typography variant="inherit" component="span">
              &nbsp;|&nbsp;
            </Typography>
            {`${format(
              parse(presentation?.startTime as string, 'HH:mm:ss', new Date()),
              'HH:mm',
            )}
          - ${format(
            parse(presentation?.endTime as string, 'HH:mm:ss', new Date()),
            'HH:mm',
          )}`}
          </Typography>
        </Box>
        <Box
          width={'100%'}
          sx={{
            py: 4,
          }}>
          <Typography
            fontSize={{ md: 18, xs: 16 }}
            fontWeight={700}
            color={'#47484C'}>
            {presentation.name}
          </Typography>
          <Typography
            fontSize={{ md: 14, xs: 12 }}
            color="#4F4F4F"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: '-webkit-inline-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            })}>
            {presentation?.speakers
              ?.map((speaker: SpeakerType) => speaker.professional.fullName)
              .join(', ')}
          </Typography>
        </Box>
        {showPreview &&
          !!presentation.liveUrl &&
          presentation.state === 'ongoing' &&
          !isMobile && (
            <Box
              width={'180px'}
              textAlign={'left'}
              sx={{
                py: 0,
              }}>
              <PreviewLiveScreen presentation={presentation} />
            </Box>
          )}
        <Box width={'1px'} />
      </Box>
    </div>
  );
};
