import theme from 'src/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  gap: 2rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`;

export const Profile = styled.div`
  position: relative;
  align-self: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    border-top-right-radius: 120px;
    height: 100%;
    background-color: #ff8211;
    left: 0;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    &::before {
      width: calc(100% - 2rem);
    }
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    &::before {
      border-top-right-radius: 80px;
    }
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-left: calc(160px + 8rem);
  position: relative;
  z-index: 998;
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: calc(100% - 200px - 2rem);
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: calc(100% - 150px - 2rem);
    width: 150px;
    height: 150px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  margin: 0 calc(80px + 4rem) 0 0;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-right: 2rem;
    margin-left: 8rem;
    align-self: inherit;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: 3.5rem;
  }
`;

export const AuthorName = styled.a`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 900;
  color: black;
  text-decoration: none;
`;

export const AuthorBio = styled.p`
  font-size: 1rem;
  font-weight: 700;
  text-align: justify;
  color: #3f3f3f;
`;
