import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  DialogContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';

import ipanema from '../../graphql/clients/ipanema';
import { GET_RATING_BY_OPERATION_HASHID } from '../../graphql/queries';
import { Dialog } from '../Dialog';
import { If } from '../If';

const useStyles = makeStyles((theme) => ({
  boxTable: {
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  table: {
    minWidth: '55rem',
  },
}));

export function RatingOperationModal({ open, onClose, securityId }) {
  const classes = useStyles();
  const { hashId } = useParams();
  const { data, loading, error } = useQuery(GET_RATING_BY_OPERATION_HASHID, {
    variables: { hash: hashId, securityId },
    skip: !open && !!securityId && !!hashId,
    client: ipanema,
  });

  if (error) return error;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <If condition={loading}>
          <CircularProgress color="secondary" />
        </If>
        <If
          condition={
            !loading && !data?.getRatingByOperationHashId?.data.length
          }>
          <Typography>Nenhum resultado encontrado</Typography>
        </If>
        <If
          condition={
            !loading && data?.getRatingByOperationHashId?.data.length > 0
          }>
          <Box className={classes.boxTable}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Operação</TableCell>
                  <TableCell>Título/Cota</TableCell>
                  <TableCell>Data da Ação</TableCell>
                  <TableCell>Agência</TableCell>
                  <TableCell>Tipo da Ação</TableCell>
                  <TableCell>Nota</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getRatingByOperationHashId?.data.map(
                  (
                    {
                      operationName,
                      titleQuote,
                      dateRating,
                      agency,
                      typeAction,
                      note,
                    },
                    index,
                  ) => (
                    <TableRow key={index}>
                      <TableCell>{operationName}</TableCell>
                      <TableCell>{titleQuote}</TableCell>
                      <TableCell>
                        {format(parseISO(dateRating), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell>{agency}</TableCell>
                      <TableCell>{typeAction}</TableCell>
                      <TableCell>{note}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </Box>
        </If>
      </DialogContent>
    </Dialog>
  );
}
