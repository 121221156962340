import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Close, Image, Info, UploadFileRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { If } from 'src/components/If';
import { updateInstitution } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution, InstitutionInput } from 'src/types/institution';
import { Media } from 'src/types/strapi';
import { formatBytes } from 'src/utils/number';
import { videoRegex } from 'src/utils/regex';
import * as Yup from 'yup';

import CropImage from '../CropImage';
import * as S from '../style';

type Props = {
  open: boolean;
  onClose: () => void;
  institution: Institution | undefined;
  form: FormikProps<InstitutionInput>;
  coverImage: Media | null;
  setCoverImage: Dispatch<SetStateAction<Media | null>>;
  handleClear: () => void;
  institutionRefetch: () => void;
};

const MAX_FILE_SIZE = 2000000;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

function CoverImageModal({
  open,
  onClose,
  form,
  institution,
  setCoverImage,
  coverImage,
  handleClear,
  institutionRefetch,
}: Props) {
  const dispatch = useDispatch();
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('coverImage', acceptedFiles[0]);
      setOpenCropDialog(true);
    }
    if (rejectedFiles.length) {
      if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        formik.setErrors({
          coverImage: 'A imagem da capa excede o tamanho máximo. Max: 2MB',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        formik.setErrors({
          coverImage: 'Formato não suportado',
        });
      }
      if (rejectedFiles[0].errors[0].code === 'too-many-files') {
        formik.setErrors({
          coverImage: 'Selecione somente um arquivo',
        });
      }
    }
  }, []);

  const handleLeaveDialog = () => {
    setOpenLeaveDialog(false);
    onClose();
    setCoverImage(institution?.coverImage ?? null);
    institutionRefetch();
  };

  const updateInstitutionMutation = useMutation(updateInstitution, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Erro ao alterar capa, tente novamente',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Capa alterada com sucesso',
          }),
        );
      }
      onClose();
      institutionRefetch();
    },
  });

  const FORM_VALIDATION = Yup.object().shape({
    coverImage: Yup.mixed()
      .test(
        'fileSize',
        'A imagem da capa excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (coverImage || !value) {
            return true;
          }
          return value?.type && value?.size <= MAX_FILE_SIZE;
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (coverImage || !value) {
          return true;
        }
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .test('required', 'Imagem obrigatória', (value) => {
        if (coverImage) {
          return true;
        }
        if (formik.values.coverVideoURL && !value) {
          return false;
        }
        return true;
      }),
    coverVideoURL: Yup.string().matches(
      videoRegex,
      'URL inválida, verifique se o link está correto',
    ),
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  });

  const formik = useFormik({
    initialValues: {
      coverImage: null,
      coverVideoURL: institution?.coverVideoURL ?? '',
    } as { coverImage: File | null; coverVideoURL: string },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      if (values.coverImage === null) {
        handleClear();
      }
      if (institution?.id) {
        const rest = _.omit(institution, 'coverImage');
        if (values.coverImage) {
          form.setFieldValue('coverImage', values.coverImage);
          formData.append('files.coverImage', values.coverImage);
        }
        formData.append(
          'data',
          JSON.stringify({
            ...rest,
            coverVideoURL: values.coverVideoURL,
            ...(!values.coverImage && !coverImage
              ? { coverImage: null }
              : { coverImage: coverImage?.id }),
          }),
        );
        updateInstitutionMutation.mutateAsync({
          id: institution?.id,
          values: formData,
        });
      } else {
        form.setFieldValue('coverImage', values.coverImage);
        form.setFieldValue('coverVideoURL', values.coverVideoURL);
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message:
              'Imagem de capa alterada, salve o perfil para confirmar as alterações',
          }),
        );
        onClose();
      }
    },
  });

  const handleRemove = () => {
    formik.setFieldValue('coverImage', null);
    setCoverImage(null);
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{ maxWidth: 750, margin: '0 auto' }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ padding: '40px 56px 0px', textAlign: 'center' }}>
          <Typography fontSize={18} fontWeight={700} mb={3}>
            Imagem de capa
          </Typography>
          <Typography fontSize={14}>
            Faça o upload da imagem de capa, você também pode inserir uma URL do
            Youtube ou Vimeo de um vídeo institucional.
          </Typography>
          {!formik.values.coverImage && !coverImage?.id ? (
            <Box
              {...getRootProps()}
              sx={{
                height: 150,
                backgroundColor: '#FAFAFA',
                border: '1px dashed #E0E0E0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '32px 0 40px',
                cursor: 'pointer',
              }}>
              <input {...getInputProps()} />
              <UploadFileRounded sx={{ fontSize: '48px', color: '#BDBDBD' }} />
              <Typography
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontSize: '0.875rem',
                })}>
                <span style={{ textDecoration: 'underline' }}>Clique aqui</span>{' '}
                ou arraste um arquivo
              </Typography>
              {Boolean(formik.errors.coverImage) && (
                <FormHelperText sx={{ color: '#EB5757' }}>
                  {formik.errors.coverImage}
                </FormHelperText>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                height: 62,
                padding: '0 16px',
                backgroundColor: '#FAFAFA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '32px 0 40px',
              }}>
              <Box sx={{ display: 'flex', textAlign: 'start' }}>
                <Image sx={{ fontSize: '40px', color: '#BDBDBD' }} />
                <Box ml={1}>
                  <If condition={formik.values.coverImage}>
                    <Typography fontSize={14} fontWeight={600}>
                      {formik.values.coverImage?.name}
                    </Typography>
                    <Typography sx={{ color: '#828282', fontSize: '14px' }}>
                      {formatBytes(formik.values.coverImage?.size)}
                    </Typography>
                  </If>
                  <If condition={coverImage && !formik.values.coverImage}>
                    <Typography fontSize={14} fontWeight={600}>
                      {coverImage?.name}
                    </Typography>
                  </If>
                </Box>
              </Box>
              <IconButton onClick={handleRemove}>
                <Close />
              </IconButton>
            </Box>
          )}
          <Box sx={{ textAlign: 'start' }}>
            <S.FieldTitle>
              URL do vídeo de capa{' '}
              <CustomTooltip
                placement="top"
                title={'Exibição do vídeo junto à capa'}>
                <Info
                  fontSize="small"
                  sx={{ color: '#4F4F4F', marginLeft: '8px' }}
                />
              </CustomTooltip>
            </S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="coverVideoURL"
                placeholder="Insira um link do Youtube ou Vimeo"
                onChange={formik.handleChange}
                value={formik.values.coverVideoURL}
                error={
                  formik.touched.coverVideoURL &&
                  Boolean(formik.errors.coverVideoURL)
                }
                helperText={
                  formik.touched.coverVideoURL && formik.errors.coverVideoURL
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '40px 0' }}>
          <Button variant="contained" type="submit">
            {updateInstitutionMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
          <Button
            variant="outlined"
            type="reset"
            color="secondary"
            onClick={() => setOpenLeaveDialog(true)}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
      <CropImage
        title="Redimensionar imagem da capa"
        name="coverImage"
        setValue={formik.setFieldValue}
        open={openCropDialog}
        onClose={() => setOpenCropDialog(false)}
        onSuccess={() => setOpenCropDialog(false)}
        type="institution"
        file={formik.values.coverImage}
      />
      <ConfirmLeaveDialog
        title="Tem certeza que deseja fechar a edição da imagem?"
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleLeaveDialog}
        labelButton="Continuar edição"
      />
    </Dialog>
  );
}

export default CoverImageModal;
