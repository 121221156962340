import { useEffect, useRef, useState } from 'react';

// Material ui components
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
// Material ui icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Global component
import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import PanelQuotes from '../../../../../components/PanelQuotes';
// Apollo client
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import {
  GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
  GET_FIDC_QUOTES_BY_CNPJ,
} from '../../../../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
  paper: {
    position: 'relative',
    height: 300,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  slider: {
    transition: 'all 0.5s',
  },
  button: {
    border: '1px solid',
    height: 30,
    minWidth: 30,
  },
}));

function Quotes({ classes, cnpj }) {
  const { data, loading, error } = useQuery(GET_FIDC_QUOTES_BY_CNPJ, {
    variables: { cnpj },
    client: ipanema,
  });

  if (loading) return null;
  if (error) return null;

  return (
    <>
      {data?.getFidcQuotesByCnpj?.seniors.length > 0 && (
        <Seniors classes={classes} data={data?.getFidcQuotesByCnpj?.seniors} />
      )}
      {data?.getFidcQuotesByCnpj?.mezzanines.length > 0 && (
        <Mezzanines
          classes={classes}
          data={data?.getFidcQuotesByCnpj?.mezzanines}
        />
      )}
      {data?.getFidcQuotesByCnpj?.subordinates.length > 0 && (
        <Subordinates
          classes={classes}
          data={data?.getFidcQuotesByCnpj?.subordinates}
        />
      )}
      {data?.getFidcQuotesByCnpj?.uniques.length > 0 && (
        <Uniques classes={classes} data={data?.getFidcQuotesByCnpj?.uniques} />
      )}
    </>
  );
}

function Seniors({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={300} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Seniores
        </Typography>
      </Box>
      <Box margin="50px 24px" width={384} height={200} position="absolute">
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(({ key, series, benchmark, hasRiskRating }) => (
              <PanelQuotes
                key={key}
                series={series}
                benchmark={benchmark}
                hasRiskRating={hasRiskRating}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="Seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Subordinates({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={300} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Subordinadas
        </Typography>
      </Box>
      <Box margin="50px 24px" width={384} height={200} position="absolute">
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(({ key, series, benchmark, hasRiskRating }) => (
              <PanelQuotes
                key={key}
                series={series}
                benchmark={benchmark}
                hasRiskRating={hasRiskRating}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Mezzanines({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={300} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Mezaninos
        </Typography>
      </Box>
      <Box margin="50px 24px" width={384} height={200} position="absolute">
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(({ key, series, benchmark, hasRiskRating }) => (
              <PanelQuotes
                key={key}
                series={series}
                benchmark={benchmark}
                hasRiskRating={hasRiskRating}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

function Uniques({ classes, data }) {
  const sliderRef = useRef();
  const prevRef = useRef();
  const nextRef = useRef();
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState({});

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      sliderRef.current.style.transform = translate;
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = 100 / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      sliderRef.current.style.transform = translate;
    }
  }

  useEffect(() => {
    const width = data.length > 0 && data.length * 100;
    const length = data.length > 0 && data.length;

    setSlider({ width, length });
  }, [data.length]);

  return (
    <Paper square className={classes.paper}>
      <Box bgcolor="black" width={200} height={300} position="absolute" p={3}>
        <Typography color="secondary" className={classes.title} variant="body1">
          &mdash;Únicos
        </Typography>
      </Box>
      <Box margin="50px 24px" width={384} height={200} position="absolute">
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            className={classes.slider}>
            {data.map(({ key, series, benchmark, hasRiskRating }) => (
              <PanelQuotes
                key={key}
                series={series}
                benchmark={benchmark}
                hasRiskRating={hasRiskRating}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          className={classes.button}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeftIcon titleAccess="seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          className={classes.button}
          disabled={count === data.length ? true : false}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
    </Paper>
  );
}

export default function OperationFIDCResumeQuotes({ hash }) {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const { data, loading, error } = useQuery(
    GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_resumo_cotas'
      ],
    },
  );

  if (loading) return null;
  if (error) return null;

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title}>
          &mdash;Cotas
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_resumo_cotas'
          ]
        }>
        <Paper className={classes.paper}>
          <PanelLogin />
        </Paper>
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_resumo_cotas'
          ]
        }>
        <Quotes
          classes={classes}
          cnpj={
            data?.getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId
              ?.cnpj
          }
        />
      </If>
    </Box>
  );
}
