import { Image, Link } from './styles';

interface SponsorProps {
  image: string;
  href: string;
  width: string;
}

export default function Sponsor({ href, image, width }: SponsorProps) {
  return (
    <Link target="_blank" href={href} style={{ width }}>
      <Image src={image} />
    </Link>
  );
}
