import { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import csvSvg from '../../../../assets/imgs/csv.svg';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import {
  exportSecondary,
  getSecondaryFilters,
} from '../../../../services/operation';

export default function OperationHeader({
  classes,
  filters,
  setFilters,
  setPage,
  hash,
  market,
}) {
  const { auth } = useAuthContext();
  const { slug } = useParams();
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  const { data, isLoading: loadingFilters } = useQuery(
    'secondaryFilters',
    () => getSecondaryFilters(hash),
    {
      enabled: !!auth.user.subscription.plan.permissions.operacoes_secundario,
    },
  );

  const exportDataToCSV = (result) => {
    const headers = [
      'Classes',
      market === 'CRI' || market === 'CRA' ? 'Título' : 'Série',
      'Data',
      'Preço (mínimo)',
      'Preço (máximo)',
      'Preço (último)',
      'Quantidade',
      'Núm Neg.',
      'Volume',
      'Ambiente',
    ];

    const options = {
      fieldSeparator: ';',
      showLabels: true,
      filename: `${market}_${slug}_secundario`,
      headers,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);
  };

  const exportCSVMutation = useMutation(exportSecondary, {
    onSuccess: (data) => {
      exportDataToCSV(data);
    },
  });

  const handleSelectFilter = (event, name = '') => {
    setPage(0);
    setFilters({
      ...filters,
      [event?.target?.name ?? name]: event?.target?.value ?? '',
    });
  };

  const handleChangeStartRangeDate = (event) => {
    if (event) {
      setDateRange({
        ...dateRange,
        start:
          event && moment(event).format() !== dateRange.start
            ? moment(event).format()
            : null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          start:
            event && moment(event).format() !== dateRange.start
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setDateRange({
        ...dateRange,
        start: null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          start: '1900-01-01',
        },
      });
    }
  };

  const handleChangeEndRangeDate = (event) => {
    if (event) {
      setDateRange({
        ...dateRange,
        end:
          event && moment(event).format() !== dateRange.end
            ? moment(event).format()
            : null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          end:
            event && moment(event).format() !== dateRange.end
              ? moment(event).format()
              : null,
        },
      });
    } else {
      setDateRange({
        ...dateRange,
        end: null,
      });
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          end: new Date(),
        },
      });
    }
  };

  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ListItem className={classes.listItem}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              disableFuture
              autoOk
              clearable
              invalidDateMessage="Formato Inválido"
              variant="inline"
              name="startDate"
              format="DD/MM/YY"
              label="Período (início)"
              InputProps={{
                disabled: true,
                endAdornment: dateRange.start && (
                  <IconButton
                    size="small"
                    onClick={() => handleChangeStartRangeDate(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={
                dateRange.start && {
                  position: 'start',
                }
              }
              value={dateRange.start}
              onChange={handleChangeStartRangeDate}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ListItem className={classes.listItem}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              disableFuture
              autoOk
              clearable
              invalidDateMessage="Formato Inválido"
              variant="inline"
              name="endDate"
              format="DD/MM/YY"
              label="Período (fim)"
              InputProps={{
                disabled: true,
                endAdornment: dateRange.end && (
                  <IconButton
                    size="small"
                    onClick={() => handleChangeEndRangeDate(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={
                dateRange.end && {
                  position: 'start',
                }
              }
              value={dateRange.end}
              onChange={handleChangeEndRangeDate}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ListItem className={classes.listItem}>
            <FormControl className={classes.itemInput}>
              <InputLabel id={'frequency-label'}>Frequência</InputLabel>
              <Select
                name="frequency"
                labelId="frequency-select"
                value={filters.frequency}
                onChange={handleSelectFilter}
                disabled={loadingFilters}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}>
                <MenuItem value="daily">Diária</MenuItem>
                <MenuItem value="monthly">Mensal</MenuItem>
                <MenuItem value="yearly">Anual</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ListItem className={classes.listItem}>
            <FormControl className={classes.itemInput}>
              <InputLabel id={'class-label'}>Classe</InputLabel>
              <Select
                name="classes"
                labelId="class-select"
                value={filters.classes}
                onChange={handleSelectFilter}
                disabled={loadingFilters}
                endAdornment={
                  filters.classes !== '' && (
                    <InputAdornment
                      position="end"
                      style={{ paddingRight: '1em' }}>
                      <IconButton
                        size="small"
                        onClick={() => handleSelectFilter(null, 'classes')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}>
                {data?.classes?.map((option, index) => (
                  <MenuItem key={`${index}-${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ListItem className={classes.listItem}>
            <FormControl className={classes.itemInput}>
              <InputLabel id={'environment-label'}>Ambiente</InputLabel>
              <Select
                name="environment"
                labelId="environment-select"
                value={filters.environment}
                onChange={handleSelectFilter}
                disabled={loadingFilters}
                endAdornment={
                  filters.environment !== '' && (
                    <InputAdornment
                      position="end"
                      style={{ paddingRight: '1em' }}>
                      <IconButton
                        size="small"
                        onClick={() => handleSelectFilter(null, 'environment')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}>
                {data?.environments?.map((option, index) => (
                  <MenuItem key={`${index}-${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          style={{ alignSelf: 'end' }}>
          <ListItem className={classes.listItem}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              className={classes.exportButton}
              onClick={() => {
                exportCSVMutation.mutate({
                  data: {
                    operationId: hash,
                    filters,
                  },
                });
              }}>
              <img src={csvSvg} alt="filter" className={classes.exportIcon} />
              Exportar
            </Button>
          </ListItem>
        </Grid>
      </Grid>
    </Box>
  );
}
