import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
//Qa Test ids
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Test from 'react-test-attributes';
import CPFFormat from 'src/components/FormsUI/CPFFormat';
import PasswordField from 'src/components/FormsUI/PasswordField';
import PasswordRules from 'src/components/PasswordRules';
import { setBannerMessage } from 'src/store/display';

import { register } from '../../../services/auth';
import * as S from '../styles';
import { ValidateRegisterPrivatePerson } from '../validation';

type RegisterState = {
  email: string;
  document: string;
  name: string;
  otherName: string;
  password: string;
  repeatPassword: string;
  acceptTerms: boolean;
  invitationToken?: string;
};

export function RegisterForm({
  data,
  handleSetRegisterForm,
  registerType,
}: {
  data: RegisterState;
  handleSetRegisterForm: (values: any) => void;
  registerType: string;
}) {
  const dispatch = useDispatch();
  const submitMutation = useMutation(register, {
    onError: (error: any) => {
      if (error) {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
    onSuccess: async ({ data }) => {
      handleSetRegisterForm((prevRegister: any) => {
        return {
          ...prevRegister,
          id: data.user.id,
        };
      });
      sessionStorage.setItem('@App:tempToken', data.jwt);
      localStorage.removeItem('leadSource');
    },
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: ValidateRegisterPrivatePerson,
    onSubmit: (values) => {
      handleSetRegisterForm(values);
      submitMutation.mutateAsync({
        email: values.email,
        document: values.document,
        name: values.name,
        otherName: values.otherName,
        password: values.password,
        acceptTerms: values.acceptTerms,
        type: registerType,
        invitationToken: data?.invitationToken ?? undefined,
        rdstation: JSON.parse(localStorage.getItem('leadSource') ?? ''),
      });
    },
  });

  const { isLoading } = submitMutation;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ padding: '130px 0', maxWidth: 450, margin: '0 auto' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography fontSize={28} fontWeight={700} textAlign="center">
              Informações essenciais
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>CPF*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-cpf">
                <TextField
                  name="document"
                  placeholder="Insira seu CPF"
                  value={formik.values.document}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.document && Boolean(formik.errors.document)
                  }
                  helperText={formik.touched.document && formik.errors.document}
                  InputProps={{
                    inputComponent: CPFFormat,
                  }}
                />
              </Test>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>Nome*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-firstname">
                <TextField
                  name="name"
                  placeholder="Digite seu nome aqui"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Test>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>Sobrenome*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-secondname">
                <TextField
                  name="otherName"
                  placeholder="Digite seu sobrenome aqui"
                  value={formik.values.otherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.otherName && Boolean(formik.errors.otherName)
                  }
                  helperText={
                    formik.touched.otherName && formik.errors.otherName
                  }
                />
              </Test>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>E-mail da conta*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-email">
                <TextField
                  disabled
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Test>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={4} marginTop={7}>
          <Grid item xs={12}>
            <Typography fontSize={28} fontWeight={700} textAlign="center">
              Cadastrar senha
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>Senha*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-password">
                <PasswordField
                  name="password"
                  placeholder="Digite uma senha aqui"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password ? formik.errors.password : ''
                  }
                />
              </Test>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>Repetir senha*</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-rptpassword">
                <PasswordField
                  name="repeatPassword"
                  placeholder="Confirme sua senha"
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.repeatPassword &&
                    Boolean(formik.errors.repeatPassword)
                  }
                  helperText={
                    formik.touched.repeatPassword
                      ? formik.errors.repeatPassword
                      : ''
                  }
                />
              </Test>
            </FormControl>
          </Grid>
        </Grid>
        <PasswordRules
          password={formik.values.password}
          canVerify={formik.touched.password}
        />
        <Grid container marginTop={5}>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <Test id="register-checkbox-terms">
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '10px',
                    letterSpacing: '0.045em',
                    textTransform: 'uppercase',
                  },
                }}
                onChange={() =>
                  formik.setFieldValue(
                    'acceptTerms',
                    !formik.values.acceptTerms,
                  )
                }
                checked={formik.values.acceptTerms}
                name="acceptTerms"
                control={<Checkbox size="small" />}
                label={
                  <>
                    <span>Eu li e concordo com os </span>
                    <Link
                      target="_blank"
                      href="https://lp.uqbar.com.br/termosuqbar"
                      rel="noreferrer">
                      Termos de Uso
                    </Link>
                    <span> e </span>
                    <Link
                      target="_blank"
                      href="https://lp.uqbar.com.br/termosuqbar"
                      rel="noreferrer">
                      Política de Privacidade
                    </Link>
                  </>
                }
              />
            </Test>
            {formik.errors.acceptTerms && formik.touched.acceptTerms && (
              <Typography sx={{ fontSize: '0.75rem' }} color="error">
                {formik.errors.acceptTerms}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          marginTop={9}>
          {/* @ts-ignore */}
          <Test id="register-button-continue">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit">
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Continuar'
              )}
            </Button>
          </Test>
        </Grid>
      </Box>
    </form>
  );
}
