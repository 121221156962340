import React from 'react';

import Box from '@material-ui/core/Box';

import * as S from './styles';
import Section from '../components/Section';
import { MenuPlaceholder } from '../components/layout-elements';
import { MenuProps } from '../types';

type Props = {
  market: 'CRI' | 'CRA' | 'FIDC' | 'FII';
  pageRef: React.MutableRefObject<any>;
  menuProps: MenuProps;
};

type Data = {
  image: string;
  url: string;
};

type TierData = {
  bronze: Data[];
  silver: Data[];
  gold: Data[];
  platinum: Data[];
};

type LogosCollection = {
  CRI: TierData;
  CRA: TierData;
  FIDC: TierData;
  FII: TierData;
};

const sponsorUrl: { [key: string]: string } = {
  'Laqus.png': 'https://www.laqus.com.br',
  'IDtrust.png': 'https://www.idtrust.com.br',
  'OuroPreto.png': 'https://www.ouropretoinvestimentos.com.br',
  'Nuclea.png': 'https://www.nuclea.com.br',
  'Singulare.png': 'https://www.singulare.com.br',
  'Next.png': 'https://nextauditores.com.br',
  'Liberum.png': 'https://www.liberumratings.com.br/pt/home.php',
  'Madrona.png': 'http://www.madronalaw.com.br',
  'Master.png': 'https://www.bancomaster.com.br',
  'Kanastra.png': 'https://kanastra.com.br',
  'SolisInvestimentos.png': 'https://solisinvestimentos.com.br',
  'Tercon.png': 'https://terconbr.com.br',
  'Ecoagro.png': 'https://www.ecoagro.agr.br',
  'ACE.png': 'http://www.aceagr.com.br',
  'PinheiroNeto.png': 'https://www.pinheironeto.com.br',
  'SantosNeto.png': 'https://santosneto.com.br',
  'TozziniFreire.png': 'https://tozzinifreire.com.br',
};

const logosImagePath = {
  CRI: {
    bronze: [],
    silver: [],
    gold: ['Next.png'],
    platinum: ['TozziniFreire.png'],
  },
  CRA: {
    bronze: ['PinheiroNeto.png', 'SantosNeto.png'],
    silver: ['ACE.png'],
    gold: ['Ecoagro.png'],
    platinum: [],
  },
  FIDC: {
    bronze: ['SolisInvestimentos.png', 'Tercon.png'],
    silver: ['Madrona.png', 'Master.png', 'Kanastra.png'],
    gold: ['Singulare.png', 'Next.png', 'Liberum.png'],
    platinum: ['Laqus.png', 'IDtrust.png', 'OuroPreto.png', 'Nuclea.png'],
  },
  FII: {
    bronze: ['Next.png'],
    silver: [],
    gold: [],
    platinum: [],
  },
};

const requireImageByName = ([tier, images]: [string, string[]]) => {
  return [
    tier,
    images.map((name) => {
      try {
        const image =
          require(`../../../../assets/imgs/yearbook/sponsors-logos/${name}`)
            ?.default || '';

        return { image, url: sponsorUrl[name] };
      } catch (error) {
        return '';
      }
    }),
  ];
};

const parseLogos = (data: typeof logosImagePath): LogosCollection => {
  const logosEntries = Object.entries(data).map(([market, imageTiers]) => {
    const parsedEntries = Object.entries(imageTiers).map(requireImageByName);
    return [market, Object.fromEntries(parsedEntries)];
  });

  return Object.fromEntries(logosEntries);
};

const Sponsors = (props: Props) => {
  const logos = parseLogos(logosImagePath);

  return (
    <S.PageWrapper ref={props.pageRef}>
      <MenuPlaceholder open={props.menuProps.open} />
      <S.Content>
        <S.TitleWrapper padding="80px 0" margin="auto auto">
          <S.Title>PATROCINADORES ANUÁRIOS UQBAR 2023</S.Title>
        </S.TitleWrapper>
        <Section
          title="patrocinadores platinum"
          images={logos[props.market].platinum}
          display="flex"
          size="big"
          columnGap="80px"
        />
        <Box marginTop={'50px'}>
          <Section
            title="patrocinadores gold"
            images={logos[props.market].gold}
            display="flex"
            size="medium"
            columnGap="80px"
          />
        </Box>

        <Box marginTop={'50px'}>
          <Section
            title="patrocinadores silver"
            images={logos[props.market].silver}
            display="flex"
            size="small"
            columnGap="80px"
          />
        </Box>

        <Box marginTop={'50px'}>
          <Section
            title="patrocinadores bronze"
            images={logos[props.market].bronze}
            display="flex"
            size="smaller"
            columnGap="80px"
          />
        </Box>
      </S.Content>
    </S.PageWrapper>
  );
};

export default Sponsors;
