import styled from 'styled-components';

export const FieldTitle = styled.div`
  color: #1d1d1d;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
`;
