import { useState } from 'react';

import {
  Grid,
  ListItem,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { If } from '../../../../components/If';

const useStyles = makeStyles(() => ({
  counterText: {
    width: '100%',
    fontSize: '14px',
    textAlign: 'right',
  },
  question: {
    textAlign: 'justify',
  },
  itemInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  helperText: {
    fontSize: '0.85rem',
  },
}));

export function TextAreaCustom({
  name,
  question,
  label,
  value,
  handleChange,
  placeholder,
  helperText,
  error,
  touched,
  minRows = 1,
  required = false,
  variant = 'outlined',
  maxCharacters = 300,
}) {
  const classes = useStyles();
  const [charactersCounter, setCharactersCounter] = useState(0);
  const handleChangeField = (event, handleChange) => {
    const { value } = event.target;
    handleChange(event);
    setCharactersCounter(value.length);
  };

  return (
    <Grid item md={12}>
      <ListItem className={classes.listItem} disableGutters>
        <If condition={question}>
          <Typography className={classes.question}>
            <b>{question}</b>
          </Typography>
        </If>
        <TextField
          name={name}
          placeholder={placeholder}
          type="text"
          variant={variant}
          label={label}
          value={value}
          required={required}
          onChange={(e) => handleChangeField(e, handleChange)}
          multiline
          minRows={minRows}
          inputProps={{ maxLength: maxCharacters }}
          className={classes.itemInput}
          error={touched && error}
          helperText={error || helperText}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
        />
        <Typography className={classes.counterText}>
          Número de Caracteres: {charactersCounter}/{maxCharacters}
        </Typography>
      </ListItem>
    </Grid>
  );
}
