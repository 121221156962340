import { useCallback, useEffect, useState } from 'react';

import { Author, Title, VideoThumbnail, Wrapper } from './styles';
import { YearbookMarketVideo } from '../../../types';
import { getVideoThumbnailUrl } from '../../../utils';

interface VideoProps {
  video: YearbookMarketVideo;
  select: (video: YearbookMarketVideo) => void;
}

export default function Video({ video, select }: VideoProps) {
  const [thumbnail, setThumbnail] = useState<string>();

  const fetchThumbnail = useCallback(
    async () => setThumbnail(await getVideoThumbnailUrl(video.videoId)),
    [video.videoId],
  );

  useEffect(() => {
    fetchThumbnail();
  }, [video.videoId]);

  return (
    <Wrapper>
      <VideoThumbnail
        src={thumbnail}
        draggable={false}
        onClick={() => select(video)}
      />
      <Title>{video.title}</Title>
      <Author>{video.author}</Author>
    </Wrapper>
  );
}
