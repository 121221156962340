import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import confirmImage from 'src/assets/imgs/confirmImage.png';

type Props = {
  open: boolean;
  onClose: () => void;
  submitAction: () => void;
  title?: string;
  subTitle?: string | JSX.Element;
  hasActionButton: boolean | undefined;
};

export function ConfirmDialog({
  open,
  onClose,
  submitAction,
  title,
  subTitle,
  hasActionButton,
}: Props) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: 380,
          padding: '20px 64px',
          [theme.breakpoints.down('sm')]: {
            height: '60vh',
            padding: '20px 24px',
          },
        })}>
        <img src={confirmImage} width={185} height={185} />
        <Typography fontSize={18} fontWeight={700} textAlign="center">
          {title}
        </Typography>
        <Typography fontSize={12} maxWidth={400} textAlign="center">
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '30px' }}>
        {hasActionButton ? (
          <>
            <Button variant="contained" onClick={submitAction}>
              Sim
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancelar
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={onClose}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
