import { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import { Alert } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { Dialog } from '../../../../components/Dialog';
import CNPJFormat from '../../../../components/FormsUI/CNPJFormat';
import PhoneFormat from '../../../../components/FormsUI/PhoneFormat';
import { If } from '../../../../components/If';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { requestRanking } from '../../../../services/ranking';
import { validateCNPJ } from '../../../../utils/document';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
  checkbox: {
    width: '100%',
    alignItems: 'start',
  },
  inputFile: {
    display: 'none',
  },
  labelFile: {
    width: '100%',
    margin: theme.spacing(2, 6),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
  },
  observationLabel: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  listItem: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0),
  },
  messageError: {
    color: theme.palette.red.default,
    margin: '0',
    fontSize: '0.85rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '400',
    lineHeight: '1.66',
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  buttonFile: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  modalTitle: {
    width: '100%',
    fontWeight: 700,
    lineHeight: '28px',
    fontSize: '18px',
    textAlign: 'justify',
    marginBottom: theme.spacing(2),
  },
  modalDescription: {
    color: '#47484C',
    textAlign: 'justify',
  },
  modalContent: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
  checkBoxText: {
    fontSize: '14px',
    color: '#47484C',
    textAlign: 'justify',
    paddingTop: '9px',
  },
  noFileBox: {
    marginLeft: '24px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      fontSize: '14px',
    },
  },
  subTitle: {
    marginTop: theme.spacing(2),
    lineHeight: '15px',
  },
  title: {
    width: '100%',
    fontWeight: 700,
    lineHeight: '23px',
  },
  inputText: {
    marginBottom: theme.spacing(2),
  },
}));

const INITIAL_VALUES = {
  companyName: '',
  cnpj: '',
  fullName: '',
  email: '',
  phone: '',
  accept: false,
};

const SUPPORTED_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
];

export default function SubmitRankingModal({
  open,
  handleClose,
  success,
  setSuccess,
}) {
  const classes = useStyles();
  const { auth } = useAuthContext();

  const FORM_VALIDATION = Yup.object().shape({
    companyName: Yup.string().required('Campo obrigatório'),
    cnpj: Yup.string()
      .required('Campo obrigatório')
      .test('cnpj', 'O CNPJ Informado é inválido', (val) => validateCNPJ(val)),
    fullName: Yup.string()
      .required('Campo obrigatório')
      .matches(/[A-Za-z]+$/, 'Nome inválido'),
    email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
    phone: Yup.string()
      .required('Campo obrigatório')
      .test(
        'phoneLen',
        'O telefone é inválido',
        (val) => val?.replace(/\D/g, '')?.length >= 10,
      ),
    document: Yup.mixed()
      .required('Documento é obrigatório')
      .test(
        'fileFormat',
        'Formato não suportado',
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
    accept: Yup.boolean()
      .required('Campo obrigatório')
      .oneOf([true], 'É necessário concordar com as regras para submissão'),
  });

  const [submitError, setSubmitError] = useState({
    status: false,
    message: '',
  });

  const requestRankingMutation = useMutation(requestRanking, {
    onSuccess: () => {
      setSuccess(true);
      setSubmitError({
        status: false,
        message: '',
      });
    },
    onError: () => {
      setSubmitError({
        status: true,
        message:
          'Não foi possível efetuar a solicitação. Entre em contato com a administração.',
      });
    },
  });

  const { isLoading } = requestRankingMutation;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={FORM_VALIDATION}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={({ document, ...values }) => {
          const formData = new FormData();
          formData.append('files.document', document);
          formData.append(
            'data',
            JSON.stringify({
              user: auth?.user?.id,
              status: 'Pendente',
              ...values,
            }),
          );
          requestRankingMutation.mutate(formData);
        }}>
        {({
          handleChange,
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
        }) => {
          return (
            <Form>
              <DialogContent classes={{ root: classes.modalContent }}>
                <Box>
                  <Grid container>
                    <If condition={!success}>
                      <Typography variant="h5" className={classes.modalTitle}>
                        Criar um Formulário de Submissão de Operações para os
                        Rankings Uqbar
                      </Typography>
                      <Typography className={classes.modalDescription}>
                        Preencha o formulário abaixo e clique em Enviar. Assim,
                        você estará a um passo de figurar nos Rankings Uqbar.
                        Nossa equipe entrará em contato com alguma informação
                        adicional seja necessária.
                      </Typography>
                      <Collapse in={submitError.status}>
                        <Alert
                          onClose={() => {
                            setSubmitError({ status: false, message: '' });
                          }}
                          className={classes.alert}
                          severity="error">
                          {submitError.message}
                        </Alert>
                      </Collapse>
                      <ListItem className={classes.listItem} disableGutters>
                        <TextField
                          fullWidth
                          name="companyName"
                          type="text"
                          variant="standard"
                          label="Razão Social *"
                          value={values?.companyName}
                          onChange={handleChange}
                          error={
                            touched?.companyName && Boolean(errors?.companyName)
                          }
                          helperText={
                            touched?.companyName && errors?.companyName
                          }
                          onBlur={handleBlur}
                        />
                      </ListItem>
                      <ListItem className={classes.listItem} disableGutters>
                        <TextField
                          fullWidth
                          name="cnpj"
                          type="text"
                          variant="standard"
                          label="CNPJ *"
                          value={values?.cnpj}
                          onChange={handleChange}
                          error={touched?.cnpj && Boolean(errors?.cnpj)}
                          helperText={touched?.cnpj && errors?.cnpj}
                          InputProps={{
                            inputComponent: CNPJFormat,
                          }}
                          onBlur={handleBlur}
                        />
                      </ListItem>
                      <ListItem className={classes.listItem} disableGutters>
                        <TextField
                          fullWidth
                          name="fullName"
                          type="text"
                          variant="standard"
                          label="Nome Completo *"
                          value={values?.fullName}
                          onChange={handleChange}
                          error={touched?.fullName && Boolean(errors?.fullName)}
                          helperText={touched?.fullName && errors?.fullName}
                          onBlur={handleBlur}
                        />
                      </ListItem>
                      <ListItem className={classes.listItem} disableGutters>
                        <TextField
                          fullWidth
                          name="email"
                          type="text"
                          variant="standard"
                          label="E-mail *"
                          value={values?.email}
                          onChange={handleChange}
                          error={touched?.email && Boolean(errors?.email)}
                          helperText={touched?.email && errors?.email}
                          onBlur={handleBlur}
                        />
                      </ListItem>
                      <ListItem className={classes.listItem} disableGutters>
                        <TextField
                          fullWidth
                          name="phone"
                          type="text"
                          variant="standard"
                          label="Telefone/celular *"
                          value={values?.phone}
                          onChange={handleChange}
                          error={touched?.phone && Boolean(errors?.phone)}
                          helperText={touched?.phone && errors?.phone}
                          InputProps={{
                            inputComponent: PhoneFormat,
                          }}
                          onBlur={handleBlur}
                        />
                      </ListItem>
                      <Grid item xs={12}>
                        <ListItem className={classes.listItem} disableGutters>
                          <InputLabel className={classes.observationLabel}>
                            Observações
                          </InputLabel>
                          <TextField
                            name="observations"
                            type="text"
                            variant="outlined"
                            fullWidth
                            value={values.observations}
                            onChange={handleChange}
                            multiline
                            minRows={3}
                            className={classes.itemInput}
                            error={
                              touched?.observations &&
                              Boolean(errors?.observations)
                            }
                            helperText={
                              touched?.observations && errors?.observations
                            }
                            onBlur={handleBlur}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          required
                          onChange={() =>
                            setFieldValue('accept', !values.accept)
                          }
                          checked={values.accept}
                          name="accept"
                          control={<Checkbox size="small" />}
                          label={
                            <Typography className={classes.checkBoxText}>
                              Eu autorizo a Uqbar a utilizar exclusivamente os
                              dados e as documentações disponibilizados para
                              inclusão na base de dados de negócios e na análise
                              de business intelligence vinculada. Quaisquer
                              dados enviados à UQBAR com uma menção específica
                              de confidencialidade devem ser mantidos
                              confidenciais e, portanto, não incluídos na base
                              de dados da UQBAR.
                            </Typography>
                          }
                          className={classes.checkbox}
                        />
                        {errors.accept ? (
                          <span
                            className={classes.messageError}
                            style={{
                              flexBasis: '100%',
                              height: '0',
                            }}>
                            {errors.accept}
                          </span>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <ListItem className={classes.listItem} disableGutters>
                          <input
                            name="document"
                            accept=".xls, .xlsx"
                            className={classes.inputFile}
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => {
                              setFieldValue('document', event.target.files[0]);
                            }}
                          />
                          <InputLabel
                            className={classes.labelFile}
                            htmlFor="contained-button-file">
                            <Box
                              display="flex"
                              alignItems="center"
                              marginTop="8px"
                              flexWrap="wrap">
                              <Button
                                variant="text"
                                color="secondary"
                                component="span"
                                className={classes.buttonFile}
                                startIcon={<PublishIcon />}>
                                Upload
                              </Button>
                              <Box className={classes.noFileBox}>
                                {!values?.document
                                  ? 'Nenhum arquivo selecionado'
                                  : values?.document.name}
                              </Box>
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  'Apenas os seguintes formatos são aceitos: .xls e .xlsx.'
                                }
                                classes={{ tooltip: classes.tooltip }}>
                                <InfoOutlinedIcon
                                  className={classes.icon}
                                  fontSize="medium"
                                  color="secondary"
                                />
                              </Tooltip>
                            </Box>
                          </InputLabel>
                          {errors.document ? (
                            <span
                              className={classes.messageError}
                              style={{
                                flexBasis: '100%',
                                height: '0',
                                marginBottom: '0.5rem',
                              }}>
                              {errors.document}
                            </span>
                          ) : null}
                        </ListItem>
                      </Grid>
                    </If>
                    <If condition={success}>
                      <Typography variant="h6" className={classes.title}>
                        Submissão enviada com sucesso
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.subTitle}>
                        Em breve alguém da Uqbar entrará em contato.
                      </Typography>
                    </If>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <If condition={!success}>
                  <Button
                    type="reset"
                    onClick={() => {
                      handleClose();
                    }}
                    color="secondary"
                    variant="outlined">
                    Cancelar
                  </Button>
                  <Button color="secondary" variant="contained" type="submit">
                    {isLoading ? (
                      <CircularProgress size="1.4rem" color="secondary" />
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                </If>
                <If condition={success}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="reset"
                    onClick={() => {
                      handleClose();
                    }}>
                    OK
                  </Button>
                </If>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
