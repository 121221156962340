import { useState } from 'react';

import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { If } from 'src/components/If';
import { PanelLogin } from 'src/components/PanelLogin';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getCRIReceivablesDue } from 'src/services/operation';

import OperationCRIAssetReceivablesDue from './OperationCRIAssetReceivablesDue';
import OperationCRIAssetReceivablesOverdue from './OperationCRIAssetReceivablesOverdue';
import OperationCRIAssetReceivablesPayment from './OperationCRIAssetReceivablesPayment';

function OperationCRIAssetReceivables({
  hash,
  operationName,
}: {
  hash: string;
  operationName: string;
}) {
  const { auth } = useAuthContext();
  const [error, setError] = useState(false);
  const hasPermission =
    auth.user?.subscription.plan.permissions
      .operacoes_cri_recebiveis_a_vencer_e_np_e_pre_pgto;
  const { data, isLoading } = useQuery(
    ['operation-cri-receivables'],
    () =>
      getCRIReceivablesDue({
        operationId: hash,
      }),
    {
      onError: (error: any) => {
        if (error?.response?.data?.error?.name === 'PolicyError') {
          setError(true);
        }
      },
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const selectOptions = data?.map((option: any) => option.dataReferencia);
  const newData = data?.length ? [...data] : [];

  if (!data?.length && !error) return null;

  return (
    <Grid container columnSpacing={8} rowSpacing={4}>
      <Grid item xs={12} md={6}>
        <OperationCRIAssetReceivablesDue
          data={newData}
          isLoading={isLoading}
          hasPermission={hasPermission}
          operationName={operationName}
          selectOptions={selectOptions}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OperationCRIAssetReceivablesOverdue
          data={newData}
          isLoading={isLoading}
          hasPermission={hasPermission}
          operationName={operationName}
          selectOptions={selectOptions}
        />
      </Grid>
      <If
        condition={
          !auth.isLoggedIn ||
          auth?.user?.subscription?.plan?.type === 'free' ||
          error
        }>
        <Grid item xs={12}>
          <PanelLogin small={undefined} message={undefined} />
        </Grid>
      </If>
      <Grid item xs={12} md={6}>
        <OperationCRIAssetReceivablesPayment
          data={newData}
          operationName={operationName}
          selectOptions={selectOptions}
          hasPermission={hasPermission}
          isLoading={isLoading}
        />
      </Grid>
      <If
        condition={
          !auth.isLoggedIn ||
          auth?.user?.subscription?.plan?.type === 'free' ||
          error
        }>
        <Grid item xs={12}>
          <PanelLogin small={undefined} message={undefined} />
        </Grid>
      </If>
    </Grid>
  );
}

export default OperationCRIAssetReceivables;
