import React, { PropsWithChildren } from 'react';

import * as S from './styles';
import { PageData } from '../../types';
import Author from '../Author';
import SectionMarker from '../SectionMarker';
import Title from '../Title';
import { Body, Content, Main, MenuPlaceholder } from '../layout-elements';

type Props = {
  title: string;
  authors?: PageData['authors'];
  subtitle?: string;
  text?: string[];
  banner?: {
    url: string;
    imageSource: string;
  };
  pageRef: React.MutableRefObject<any> | undefined;
  doubleColumn?: boolean;
  open: boolean;
};

const formatText = (text: string) => {
  const paragraphs = text.split('\n').filter((i) => i);

  return paragraphs.map((p, index) => {
    const isBold = p.match(/<b>\w.+<\/b>/);

    if (isBold) {
      const sanitizedText = p.replace('<b>', '').replace('</b>', '');
      return <strong key={`paragraph-${index}`}>{sanitizedText}</strong>;
    }

    return <p key={`paragraph-${index}`}>{p}</p>;
  });
};

const Page = (props: PropsWithChildren<Props>) => {
  const handleClick = (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Body>
      <SectionMarker menuOpened={props.open} title={props.title} />
      <Main ref={props.pageRef}>
        <MenuPlaceholder open={props.open} />
        <Content>
          {props.banner && (
            <S.BannerWrapper onClick={() => handleClick(props.banner?.url)}>
              <S.Banner
                src={props.banner.imageSource}
                alt={`banner-${props.title}`}
              />
            </S.BannerWrapper>
          )}
          <Title
            half={!!props.doubleColumn}
            title={props.title}
            subtitle={props.subtitle}
            authors={props.authors?.map((a) => a.name)}
          />
          {props.children || (
            <>{props.text && props.text.map((text) => formatText(text))}</>
          )}
          {props.authors?.map((author, index) => (
            <Author
              key={`author-${author.name}-${index}`}
              name={author.name}
              description={author.description}
              image={author.image}
            />
          ))}
        </Content>
      </Main>
    </Body>
  );
};

export default Page;
