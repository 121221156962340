import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
`;

export const Details = styled.div`
    display: flex;
    justify-content: space-between;

    & > p: {
      color: '#47484C',
      font-weight: ${(props) => props.theme.typography.fontWeightMedium},
    }

    ${(props) => props.theme.breakpoints.down('sm')}: {
      padding: '16px 0px',
    },
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
  align-items: center;
  height: 24px;
`;

export const Title = styled(Typography)`
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
`;

export const DetailsWrapper = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
`;
