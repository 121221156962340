// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

// Global components
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
// Apollo client
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRI_RECEIVABLES_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cri/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationCRIResumeReceivables({ hashId }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_CRI_RECEIVABLES_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
    },
  );

  if (error) return null;

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title}>
          &mdash;Recebíveis
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              first
              info
              title="Ativo-lastro"
              value={data?.getCriReceivablesByOperationHashId?.operationProduct}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              info
              title="Categoria de Risco de Crédito"
              value={
                data?.getCriReceivablesByOperationHashId
                  ?.operationCreditRiskCategory
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              info
              title="Alienação Fiduciária de Imóvel"
              value={
                data?.getCriReceivablesByOperationHashId
                  ?.operationFiduciaryAlienation
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              info
              title="Devedor"
              value={data?.getCriReceivablesByOperationHashId?.operationDebtor}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              info
              title="Tipo de Contrato"
              value={
                data?.getCriReceivablesByOperationHashId
                  ?.operationTypeofAgreement
              }
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
