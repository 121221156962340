import { useQuery } from 'react-query';
import { getSeoHome } from 'src/services/misc';

import Dashboard from './Dashboard';
import LandingPage from './LandingPage';
import { If } from '../../components/If';
import MetaTags from '../../components/Seo/MetaTags';
import { useAuthContext } from '../../context/AuthContextProvider';
import { ScrollToTop } from '../../utils/scroll';

export default function Home() {
  const { auth } = useAuthContext();
  const { data: seoHome } = useQuery('seo-home', getSeoHome);

  return (
    <>
      <MetaTags
        title="UQBAR - Educação, Conexão e Inteligência de Mercado"
        description="Seja bem-vindo a Uqbar, a mais importante fonte de educação, conexão e inteligência do mercado de finanças estruturadas."
        seo={seoHome ? seoHome.data?.seo : undefined}
      />
      <ScrollToTop>
        <If condition={!auth.isLoggedIn}>
          <LandingPage />
        </If>
        <If condition={auth.isLoggedIn}>
          <Dashboard />
        </If>
      </ScrollToTop>
    </>
  );
}
