import ReactApexChart from 'react-apexcharts';
import styled, { css } from 'styled-components';

export const YearbookChart = styled(ReactApexChart)`
  margin-top: 2rem;

  width: calc(100% - 80px - 12rem);

  @media screen and (max-width: 1366px) {
    width: calc(100% - 80px - 4rem);
  }

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  ${(props) =>
    props.type === 'donut' &&
    css({
      width: 'fit-content !important',
    })}
`;
