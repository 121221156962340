import { useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import {
  getRadarData,
  getRadarIndicators,
  getRadarSegments,
} from 'src/services/radar';
import { Indicator, RadarSegments, State } from 'src/types/radar';

import IndicatorTable from './IndicatorTable';
import IndicatorsFilters from './IndicatorsFilters';

export default function RadarIndicators({ query }: { query: any }) {
  const { auth } = useAuthContext();
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.radar_indicators;
  const [alert, setAlert] = useState(false);
  const segmentsRef = useRef({});

  const {
    data: filters,
    isLoading: isLoadingSegments,
    isFetching: isFetchingSegments,
    refetch: refetchSegments,
  } = useQuery(
    ['segments', query.market],
    () => getRadarSegments(query.market),
    {
      enabled: false,
      onSuccess: (data: RadarSegments) => {
        const segments = Object.entries(data).reduce((acc, curr) => {
          return {
            ...acc,
            [curr[0]]: curr[1]?.options,
          };
        }, {});
        segmentsRef.current = segments;
        if (indicators) {
          const stateData = {
            ...state,
            segments,
          };

          setState(stateData);
          radarMutation.mutate({ ...stateData, market: query.market });
        }
      },
    },
  );

  const DEFAULT_INDICATOR = ['fii', 'fidc'].includes(query.market)
    ? 'pl'
    : 'montante_emitido';

  const INITIAL_STATE = {
    dateStart: undefined,
    dateEnd: undefined,
    indicator: DEFAULT_INDICATOR,
    segments: segmentsRef.current,
    ...(query.market === 'fidc' && {
      serviceProviders: {
        administrador: [],
        gestor: [],
        custodiante: [],
      },
    }),
  } as State;
  const [state, setState] = useState(INITIAL_STATE);

  const arrayDeadlineOrder = [
    'até 2 anos',
    'de 2 a 5 anos',
    'de 5 a 10 anos',
    'acima de 10 anos',
    'Não Disponível',
  ];

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('radarIndicators'),
    {
      enabled: !hasPermission,
    },
  );

  const radarMutation = useMutation(getRadarData);

  const { isLoading: isLoadingRadar, data } = radarMutation;

  const {
    data: indicators,
    isLoading: isLoadingIndicators,
    isFetching: isFetchingIndicators,
    refetch: refetchIndicators,
  } = useQuery(
    ['indicators', query.market],
    () => getRadarIndicators(query.market),
    {
      enabled: hasPermission,
      onSuccess: (data: Indicator[]) => {
        if (data) {
          refetchSegments();
        }
      },
    },
  );

  const isLoading =
    isLoadingRadar ||
    isFetchingIndicators ||
    isFetchingSegments ||
    isLoadingSegments ||
    isLoadingIndicators ||
    auth.isLoading;

  useEffect(() => {
    if (state?.segments) {
      setAlert(!Object.keys(state.segments).length);
      if (isLoading || !Object.keys(state.segments).length || !state.indicator)
        return;
      const hasMissingSegments = Object.values(state.segments).some(
        (segment) => !segment.length,
      );
      setAlert(hasMissingSegments);
      if (!hasMissingSegments && hasPermission) {
        radarMutation.mutate({ ...state, market: query.market });
      }
    }
  }, [state, hasPermission, query?.favoriteId]);

  return (
    <>
      <Box pt={2.5} pb={1.375} pl={2.25} bgcolor={'#FAFAFA'}>
        <IndicatorsFilters
          query={query}
          INITIAL_STATE={INITIAL_STATE}
          state={state}
          setState={setState}
          filters={filters}
          indicators={indicators}
          isLoadingSegments={isLoadingSegments}
          isLoadingIndicators={isLoadingIndicators}
          refetchIndicators={refetchIndicators}
          radarMutation={radarMutation}
          arrayDeadlineOrder={arrayDeadlineOrder}
          hasPermission={hasPermission}
          alert={alert}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          margin: '0 -2px',
          position: 'relative',
        }}>
        {hasPermission && isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            padding="24px 0px">
            <CircularProgress color="primary" size="3rem" />
          </Box>
        )}
        {hasPermission && !isLoading && alert && (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            padding="24px 0px">
            <Typography fontSize={14} fontWeight={700}>
              No filtro de característica, selecione ao menos um item de cada
              seção
            </Typography>
          </Box>
        )}
        {hasPermission
          ? !isLoading &&
            indicators?.length &&
            filters &&
            !alert &&
            data?.rows &&
            Object.entries(filters)?.map((filter, index) => (
              <IndicatorTable
                key={`${filter[0]}-${index}`}
                state={state}
                segment={filter}
                indicators={indicators}
                rowData={data}
                arrayDeadlineOrder={arrayDeadlineOrder}
                hasPermission={hasPermission}
                market={query.market}
              />
            ))
          : Array(3)
              .fill(null)
              .map((v, index) => (
                <Box
                  key={`fake-${index}`}
                  sx={{
                    WebkitUserSelect: hasPermission ? 'unset' : 'none',
                    userSelect: hasPermission ? 'unset' : 'none',
                    WebkitFilter: hasPermission ? 'unset' : 'blur(3.5px)',
                    filter: hasPermission ? 'unset' : 'blur(3.5px)',
                  }}>
                  <IndicatorTable
                    state={state}
                    segment={[]}
                    indicators={[]}
                    rowData={{ period: [], rows: {}, totals: {} }}
                    arrayDeadlineOrder={[]}
                    hasPermission={hasPermission}
                    market={query.market}
                  />
                </Box>
              ))}
        {!hasPermission && contentPayWallBanner?.radarIndicators && (
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              top: '10%',
              left: 0,
              px: '10px',
            }}>
            <CardDemonstration
              title={contentPayWallBanner?.radarIndicators?.title}
              subTitle={contentPayWallBanner?.radarIndicators?.subTitle}
              labelButton={contentPayWallBanner?.radarIndicators?.labelButton}
              url={contentPayWallBanner?.radarIndicators?.url}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
