// Material ui components
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import SubTitle from 'src/components/v2/SubTitle';

import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_CRA_CHARACTERISTICS_BY_OPERATION_HASH_ID } from '../../../../../graphql/markets/cra/queries';
// Global components

export default function OperationCRAAssetCharacteristics({ hash }) {
  const { auth } = useAuthContext();
  const [characteristics, setCharacteristics] = useState({
    operationProduct: null,
    operationCreditRiskCategory: null,
    operationDebtor: null,
    operationTypeofAgreement: null,
    operationFeedbackESG: null,
  });
  const { loading, error } = useQuery(
    GET_CRA_CHARACTERISTICS_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions['operacoes_cri_ativo'],
      onCompleted: ({ getCraCharacteristicsByOperationHashId }) =>
        setCharacteristics(getCraCharacteristicsByOperationHashId),
    },
  );

  if (error) return null;

  return (
    <div>
      <SubTitle title="Características" />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              first
              info
              title="Produto"
              value={characteristics?.operationProduct}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              title="Categoria de Risco de Crédito"
              value={characteristics?.operationCreditRiskCategory}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              title="Tipo de Devedor"
              value={characteristics?.operationDebtor}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              title="Tipo de Contrato"
              value={characteristics?.operationTypeofAgreement}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              info
              title="Parecer ESG"
              value={characteristics?.operationFeedbackESG}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
