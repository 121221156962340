import { useState } from 'react';
import { useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import SubmitRankingModal from './SubmitRankingModal';
import acceptIcon from '../../../assets/imgs/accept.svg';
import downArrowIcon from '../../../assets/imgs/downArrow.svg';
import graphicIcon from '../../../assets/imgs/graphic.svg';
import rigthArrowIcon from '../../../assets/imgs/rigthArrow.svg';
import searchIcon from '../../../assets/imgs/search.svg';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { rankingLogos } from '../../../services/misc';
import { findRankingFormSheet } from '../../../services/ranking';
import { count } from '../../../services/user';
import { ScrollToTop } from '../../../utils/scroll';

const useStyles = makeStyles((theme) => ({
  description: {
    textAlign: 'justify',
    color: '#6D6E71',
    fontSize: '16px',
    fontWeight: 600,
  },
  subTitle: {
    color: theme.palette.common.black,
    fontSize: '18px',
  },
  descriptionBox: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  buttonContainer: {
    marginTop: '32px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  linkContainer: {
    marginTop: '16px',
    '& span': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
  container: {
    margin: theme.spacing(6, 0),
  },
  tooltip: {
    fontSize: '12px',
    textAlign: 'center',
  },
  sheetButton: {
    marginLeft: '24px',
    [theme.breakpoints.down('xs')]: {
      margin: '24px 0px 0px 0px',
    },
  },
  stepsContainer: {
    margin: theme.spacing(6, 0, 4, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      scale: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: theme.spacing(6, 0, 4, 0),
      scale: 'none',
    },
  },
  stepsBox: {
    minWidth: '244px',
    maxWidth: '244px',
    minHeight: '250px',
    maxHeight: '250px',
    border: '2px solid #D9D9D9',
    borderRadius: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconBox: {
    width: '72px',
    height: '72px',
    backgroundColor: '#BBBBBB',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconText: {
    textAlign: 'center',
    color: '#6D6E71',
    fontSize: '18px',
    fontWeight: 600,
    padding: '16px 16px',
  },
  arrowBox: {
    margin: theme.spacing(3, 3),
  },
  link: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
  },
  logosContainer: {
    margin: theme.spacing(6, 0, 4, 0),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0, 2, -8),
      scale: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: theme.spacing(4, 0, 2, 0),
      scale: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: theme.spacing(4, 0, 2, 0),
    },
  },
  logoBox: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 4),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export default function SubmitRanking() {
  const { auth } = useAuthContext();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const { data: userCount, isLoading: countLoading } = useQuery(
    'user-count',
    count,
  );

  const { data, isLoading: sheetLoading } = useQuery(
    'sheetForm',
    findRankingFormSheet,
  );

  const { data: logos, isLoading: logosLoading } = useQuery(
    'logos',
    rankingLogos,
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSuccess(false);
      }, 500);
    }
  }, [open]);

  return (
    <ScrollToTop>
      <Box id="rankings-submit" component="section">
        {countLoading || sheetLoading || logosLoading ? (
          <Box py={3}>
            <CircularProgress color="secondary" size="1.8rem" />
          </Box>
        ) : (
          <Box py={3}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Box className={classes.descriptionBox}>
                  <Typography className={classes.description}>
                    <b className={classes.subTitle}>
                      Participe dos rankings Uqbar e ganhe relevância frente à
                      concorrência.
                    </b>
                    <br />
                    <br />
                    Os Rankings Uqbar têm o objetivo de retratar o que ocorre no
                    mercado de Finanças Estruturadas brasileiro, fornecendo ao
                    público uma visão do desempenho de cada instituição durante
                    o respectivo período de apuração dos Rankings.
                    <br />
                    <br />
                    Trata-se de uma ferramenta contundente de exposição,
                    reconhecida pelos principais veículos de comunicação e
                    potencializada pelos efeitos de rede dos mais de{' '}
                    <u>{userCount}</u> assinantes da plataforma Uqbar. E não
                    para por aí... o desempenho da sua instituição também pode
                    ser publicado em seus sites e mídias sociais.
                    <br />
                    <br />
                    Fazer parte dos Rankings Uqbar contribui para que a sua
                    instituição prossiga captando cliente com êxito,
                    transmitindo maior autoridade, excelência e confiança aos
                    seus clientes. E é justamente pelo alto poder de alcance e
                    por sua consequente capacidade de influenciar decisões de
                    negócios que os Rankings Uqbar ganham papel de destaque no
                    mercado.
                    <br />
                    <br />
                    Os Rankings Uqbar são totalmente desenvolvidos e publicado
                    pela Uqbar. A participação nos rankings é totalmente
                    gratuita. Saiba mais acessando o nosso
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      underline="none"
                      to={'/faq'}>
                      &nbsp;FAQ.
                    </Link>
                    <br />
                    <br />
                    Quer ganhar ainda mais destaque no mercado de finanças
                    estruturadas? Faça o download da
                    <Link
                      className={classes.link}
                      underline="none"
                      target="_blank"
                      download="Planilha Rankings"
                      rel="noopener noreferrer"
                      href={data?.rankFormSheet?.url}>
                      &nbsp;planilha&nbsp;
                    </Link>
                    de exemplo, preencha-o e envie aqui:
                  </Typography>
                  <Box container className={classes.buttonContainer}>
                    <Box>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener={auth?.isLoggedIn}
                        disableHoverListener={auth?.isLoggedIn}
                        arrow
                        title={'Faça seu cadastro para enviar rankings'}
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}>
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disabled={!auth?.isLoggedIn}
                            onClick={() => setOpen(true)}>
                            Submeter o formulário
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                    <Box item xs={12} md={4} className={classes.sheetButton}>
                      <Link
                        underline="none"
                        target="_blank"
                        download="Planilha Rankings"
                        rel="noopener noreferrer"
                        href={data?.rankFormSheet?.url}>
                        <Button variant="outlined" color="secondary" fullWidth>
                          Baixe a planilha de preenchimento
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.description}>
                  <b className={classes.subTitle}>
                    Etapas do processo de publicação
                  </b>
                </Typography>
                <Box className={classes.stepsContainer}>
                  <Box className={classes.stepsBox}>
                    <Box marginTop="50px">
                      <img src={graphicIcon} />
                    </Box>
                    <Typography className={classes.iconText}>
                      Envio dos dados
                    </Typography>
                  </Box>
                  <Hidden xsDown>
                    <Box className={classes.arrowBox}>
                      <img src={rigthArrowIcon} />
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box className={classes.arrowBox}>
                      <img src={downArrowIcon} />
                    </Box>
                  </Hidden>
                  <Box className={classes.stepsBox}>
                    <Box marginTop="74px" className={classes.iconBox}>
                      <img src={searchIcon} />
                    </Box>
                    <Typography className={classes.iconText}>
                      Conferencia e análise dos dados
                    </Typography>
                  </Box>
                  <Hidden xsDown>
                    <Box className={classes.arrowBox}>
                      <img src={rigthArrowIcon} />
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box className={classes.arrowBox}>
                      <img src={downArrowIcon} />
                    </Box>
                  </Hidden>
                  <Box className={classes.stepsBox}>
                    <Box marginTop="50px" className={classes.iconBox}>
                      <img src={acceptIcon} />
                    </Box>
                    <Typography className={classes.iconText}>
                      Publicação Online
                    </Typography>
                  </Box>
                </Box>
                <Typography className={classes.description}>
                  *Consulte o período de publicação
                </Typography>
              </Grid>
            </Grid>
            {logos?.data?.rankingLogos && (
              <Grid container className={classes.container}>
                <Grid item md={12}>
                  <Typography className={classes.description}>
                    <b className={classes.subTitle}>
                      Empresas que participam dos rankings Uqbar
                    </b>
                  </Typography>
                  <Box className={classes.logosContainer}>
                    <Box className={classes.logoBox}>
                      <img
                        src={logos?.data?.rankingLogos?.logo1?.image?.url}
                        alt={logos?.data?.rankingLogos?.logo1?.altText}
                      />
                    </Box>
                    <Box className={classes.logoBox}>
                      <img
                        src={logos?.data?.rankingLogos?.logo2?.image?.url}
                        alt={logos?.data?.rankingLogos?.logo2?.altText}
                      />
                    </Box>
                    <Box className={classes.logoBox}>
                      <img
                        src={logos?.data?.rankingLogos?.logo3?.image?.url}
                        alt={logos?.data?.rankingLogos?.logo3?.altText}
                      />
                    </Box>
                    <Box className={classes.logoBox}>
                      <img
                        src={logos?.data?.rankingLogos?.logo4?.image?.url}
                        alt={logos?.data?.rankingLogos?.logo4?.altText}
                      />
                    </Box>
                    <Box className={classes.logoBox}>
                      <img
                        src={logos?.data?.rankingLogos?.logo5?.image?.url}
                        alt={logos?.data?.rankingLogos?.logo5?.altText}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>
      <SubmitRankingModal
        open={open}
        handleClose={handleClose}
        success={success}
        setSuccess={setSuccess}
      />
    </ScrollToTop>
  );
}
