import { gql } from '@apollo/client';

export const GET_COMPANY_DATA_BY_CNPJ = gql`
  query GetCompanyDataByCNPJ($cnpj: String!) {
    autocomplete(keyword: $cnpj) {
      institutions {
        id
        hashId
        name
        slug
        cnpj
      }
    }
  }
`;
export const GET_INSTITUTION_BY_CNPJ = gql`
  query GetInstitutionByCNPJ($cnpj: String!) {
    findInstitutionByCnpj(cnpj: $cnpj) {
      id
      hashId
      logo {
        id
        url
        name
        ext
        formats {
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
          thumbnail {
            url
          }
        }
      }
      slug
      razaoSocial
      cnpj
      websiteUrl
      emails {
        id
        description
        email
        hashId
      }
      phones {
        id
        hashId
        number
        showInPlatform
      }
      markets {
        id
        name
      }
      segments {
        id
        name
        code
      }
      businessProfile
      relatedProfessionals {
        id
        hashId
        name
        surname
        showInPlataform
        photo {
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
        role
        linkedin
        facebook
        twitter
        email
        markets {
          name
        }
        slug
      }
      addresses {
        id
        city
        description
        hashId
        line1
        line2
        showInPlatform
        state
        zipcode
      }
      contactDetails {
        id
        hashId
        name
        email
        phoneNumber
      }
      facebook {
        id
        hashId
        url
        showInPlatform
      }
      linkedin {
        id
        hashId
        url
        showInPlatform
      }
      twitter {
        id
        hashId
        url
        showInPlatform
      }
      adminUserId
    }
  }
`;

// Operation CRA
export const GET_DETAILS_CRA_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetDetailsCraServiceProvidersByOperationHashId($hash: String!) {
    getDetailsCraServiceProvidersByOperationHashId(hash: $hash) {
      securitizers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      fiduciaryAgents {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_CRA_TITLES_BY_OPERATION_HASH_ID = gql`
  query GetCraTitlesByOperationHashId($hash: String!) {
    getCraTitlesByOperationHashId(hash: $hash) {
      operationName
      seniors {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      subordinates {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      uniques {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
      mezzanines {
        key
        class
        series
        emissionDate
        cetipCode
        amountIssued
        term
        remuneration
      }
    }
  }
`;
export const GET_CRA_LIABILITIES_BY_OPERATION_HASH_ID = gql`
  query GetCraLiabilitiesByOperationHashId($hash: String!) {
    getCraLiabilitiesByOperationHashId(hash: $hash) {
      titles {
        id
        series
        class
        emissionDate
        amountIssued
        quantityIssued
        unitValueIssued
        remuneration
        riskClassification
        cetipCode
        term
        offerType
        status
        leader {
          id
          name
          slug
        }
      }
      emissions {
        competenceDate
        emissionValue
      }
    }
  }
`;
export const GET_DETAILS_CRA_GENERAL_DATA_BY_OPERATION_HASH_ID = gql`
  query GetDetailsCraGeneralDataByOperationHashId($hash: String!) {
    getDetailsCraGeneralDataByOperationHashId(hash: $hash) {
      operationName
      market
      securitizerName
      operationSeriesNumber
      operationEmissionNumber
      operationSeriesQuantity
      operationStructureType
      securitizerCnpj
      operationStartDate
      isFiduciaryRegiment
      isMultiseries
      isMulticlasses
    }
  }
`;
export const GET_CRA_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetCraServiceProvidersByOperationHashId($hash: String!) {
    getCraServiceProvidersByOperationHashId(hash: $hash) {
      securitizers {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      fiduciaryAgents {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_CRA_RECEIVABLES_BY_OPERATION_HASH_ID = gql`
  query GetCraReceivablesByOperationHashId($hash: String!) {
    getCraReceivablesByOperationHashId(hash: $hash) {
      operationName
      liabilityId
      operationProduct
      operationCreditRiskCategory
      operationFiduciaryAlienation
      operationDebtor
      operationTypeofAgreement
      operationProduct
    }
  }
`;
export const GET_CRA_CADASTRAL_DATA_BY_OPERATION_HASH_ID = gql`
  query GetCraCadastralDataByOperationHashId($hash: String!) {
    getCraCadastralDataByOperationHashId(hash: $hash) {
      securitizer
      operationName
      market
      emissionNumber
      seriesNumber
    }
  }
`;

// Operation FIDC
export const GET_DETAILS_FIDC_GENERAL_DATA_BY_OPERATION_HASH_ID = gql`
  query GetDetailsFidcGeneralDataByOperationHashId($hash: String!) {
    getDetailsFidcGeneralDataByOperationHashId(hash: $hash) {
      operationCnpj
      operationCondominiumType
      operationMinimumRedemptionTerm
      isMultiseries
      isMulticlasses
      isStockListed
      isNP
      isExclusiveFund
      isLinkedShareholders
    }
  }
`;
export const GET_DETAILS_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetDetailsFidcServiceProvidersByOperationHashId($hash: String!) {
    getDetailsFidcServiceProvidersByOperationHashId(hash: $hash) {
      administrators {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      custodians {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      managers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      consultants {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      structurers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_FIDC_WALLET_DETAILS_BY_CNPJ = gql`
  query GetFidcWalletDetailsByCnpj($cnpj: String!) {
    getFidcWalletDetailsByCnpj(cnpj: $cnpj) {
      dataset {
        labels
        data
      }
    }
  }
`;
export const GET_FIDC_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetFidcServiceProvidersByOperationHashId($hash: String!) {
    getFidcServiceProvidersByOperationHashId(hash: $hash) {
      administrators {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      custodians {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      managers {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      consultants {
        hashId
        cnpj
        slug
        name
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_FIDC_WALLET_BY_CNPJ = gql`
  query GetWallet($cnpj: String!) {
    getFidcWalletByCnpj(cnpj: $cnpj) {
      dataset {
        labels
        data
      }
    }
  }
`;
export const GET_FIDC_CREDIT_RIGHTS_BY_CNPJ = gql`
  query GetCreditRight($cnpj: String!) {
    getFidcCreditRightsByCnpj(cnpj: $cnpj) {
      amountAcquired
      quantityAcquired
      amountAlienated
      quantityAlienated
      amountReplaced
      quantityReplaced
      amountRepurchased
      quantityRepurchased
    }
  }
`;
export const GET_FIDC_QUOTES_BY_CNPJ = gql`
  query GetQuotes($cnpj: String!) {
    getFidcQuotesByCnpj(cnpj: $cnpj) {
      seniors {
        key
        series
        benchmark
        hasRiskRating
      }
      subordinates {
        key
        series
        benchmark
        hasRiskRating
      }
      mezzanines {
        key
        series
        benchmark
        hasRiskRating
      }
      uniques {
        key
        series
        benchmark
        hasRiskRating
      }
    }
  }
`;
export const GET_DETAILS_FIDC_NET_WORTH_DATASET = gql`
  query GetDetailsFidcNetWorthDataset($filter: FidcNetWorthChartFilter!) {
    getDetailsFidcNetWorthDataset(filter: $filter) {
      labels
      datasets {
        label
        data
        backgroundColor
      }
    }
  }
`;
export const GET_DETAILS_FII_NET_WORTH_DATASET = gql`
  query GetDetailsFiiNetWorthDataset($filter: FiiNetWorthChartFilter!) {
    getDetailsFiiNetWorthDataset(filter: $filter) {
      labels
      datasets {
        label
        data
        backgroundColor
      }
    }
  }
`;
export const GET_DETAILS_FIDC_QUOTES_BY_OPERATION_HASH_ID = gql`
  query GetDetailsFidcQuotesByOperationHashId($hash: String!) {
    getDetailsFidcQuotesByOperationHashId(hash: $hash) {
      quotes {
        id
        class
        series
        benchmark
        rating
        agencyRating
        netWorth
        netWorthUnit
        quantity
        bookValue
        outflow
        outflowQuantity
        quotesEmissionsQuantity
        competence
      }
    }
  }
`;
export const GET_DETAILS_FII_QUOTES_BY_OPERATION_HASH_ID = gql`
  query getFiiQuotesByOperationHashId($hash: String!) {
    getFiiQuotesByOperationHashId(hash: $hash) {
      seniors {
        quoteClass
        quoteSerie
        quoteCompetence
        quoteNetWorth
        quoteEquityValue
        quoteTicker
        emissionsInMonth
        lastDistributed
      }
      subordinates {
        quoteClass
        quoteSerie
        quoteCompetence
        quoteNetWorth
        quoteEquityValue
        quoteTicker
        emissionsInMonth
        lastDistributed
      }
      mezzanines {
        quoteClass
        quoteSerie
        quoteCompetence
        quoteNetWorth
        quoteEquityValue
        quoteTicker
        emissionsInMonth
        lastDistributed
      }
      uniques {
        quoteClass
        quoteSerie
        quoteCompetence
        quoteNetWorth
        quoteEquityValue
        quoteTicker
        emissionsInMonth
        lastDistributed
      }
    }
  }
`;
export const GET_DETAILS_FIDC_RECEIVABLES_WALLET_DATASET = gql`
  query GetDetailsFidcReceivablesWalletDataset(
    $filter: FidcReceivablesWalletFilterDTO!
  ) {
    getDetailsFidcReceivablesWalletDataset(filter: $filter) {
      labels
      datasets {
        label
        slug
        data
        backgroundColor
      }
    }
  }
`;
export const GET_DETAILS_FIDC_CREDITS_DUE_COMPOSITION_DATASET = gql`
  query GetDetailsFidcCreditsDueCompositionDataset($cnpj: String!) {
    getDetailsFidcCreditsDueCompositionDataset(cnpj: $cnpj) {
      competenceMonth
      competenceYear
      documentDate
      deliveredDate
      segmentsDataset {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
      maturityDataset {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
      delinquenciesDataset {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
      paidInAdvanceDataset {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
    }
  }
`;
export const GET_FIDC_CADASTRAL_DATA_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID = gql`
  query GetFidcCadastralDataFromMonthAndYearByOperationHashId($hash: String!) {
    getFidcCadastralDataFromMonthAndYearByOperationHashId(hash: $hash) {
      underlyingAssets
      netWorth
      asset
      liability
      creditRiskCategory
      isIncongruitiesAlert
    }
  }
`;
export const GET_FIDC_CADASTRAL_DATA_DETAILS_FROM_MONTH_AND_YEAR_BY_OPERATION_HASH_ID = gql`
  query getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId(
    $hash: String!
  ) {
    getFidcCadastralDataDetailsFromMonthAndYearByOperationHashId(hash: $hash) {
      issuerId
      operationId
      name
      market
      cnpj
      condominiumId
      condominiumType
      isNP
      isMultiseries
      isMulticlasses
      netWorth
      underlyingAssets
      isDiversified
      isIncongruitiesAlert
      asset
      quotes
      isExclusiveFund
      isLinkedQuote
      isStockListed
      amountCreditsDue
      creditRiskCategory
      competenceMonth
      competenceYear
      typeCreditsDue
    }
  }
`;

// Operation FII
export const GET_FII_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetFiiServiceProvidersByOperationHashId($hash: String!) {
    getFiiServiceProvidersByOperationHashId(hash: $hash) {
      administrators {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      managers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      custodians {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      structurers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_DETAILS_FII_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID = gql`
  query GetDetailsFiiServiceProvidersByOperationHashId($hash: String!) {
    getDetailsFiiServiceProvidersByOperationHashId(hash: $hash) {
      administrators {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      custodians {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      managers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      auditors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      legalAdvisors {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      structurers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      servicers {
        hashId
        name
        cnpj
        slug
        startDate
        logo {
          url
          formats {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;
export const GET_FII_GENERAL_DATA_BY_HASH_ID = gql`
  query GetFiiGeneralDataByHashId($hash: String!) {
    getFiiGeneralDataByHashId(hash: $hash) {
      market
      operationName
      operationCnpj
      operationTerm
      isMulticlasses
      operationTickers
    }
  }
`;
export const GET_FII_QUOTES_AND_NET_WORTH_BY_OPERATION_HASH_ID = gql`
  query GetFiiQuotesAndNetWorthByOperationHashId($hash: String!) {
    getFiiQuotesAndNetWorthByOperationHashId(hash: $hash) {
      operationNetWorth
      operationQuotesQuantity
      operationQuotaBookValue
      competenceYear
      competenceMonth
    }
  }
`;
export const GET_FII_SECONDARY_BY_OPERATION_HASH_ID = gql`
  query GetFiiSecondaryByOperationHashId($hash: String!) {
    getFiiSecondaryByOperationHashId(hash: $hash) {
      operationHashId
      operationName
      operationCnpj
      operationNegotiatedAmount
      operationTradesQuantity
      operationQuotation
    }
  }
`;
export const GET_FII_ASSET_DATA_BY_OPERATION_HASH_ID = gql`
  query GetFiiAssetDataByOperationHashId($hash: String!) {
    getFiiAssetDataByOperationHashId(hash: $hash) {
      operationProduct
      operationPropertyType
      operationFinality
      operationTypeOfManager
    }
  }
`;
export const GET_FII_LAST_DISTRIBUTED_INCOME_BY_OPERATION_HASH_ID = gql`
  query GetFiiLastDistributedIncomeByOperationHashId($hash: String!) {
    getFiiLastDistributedIncomeByOperationHashId(hash: $hash) {
      operationHashId
      competenceYear
      competenceMonth
      operationName
      operationCnpj
      operationLastDistributedIncome
    }
  }
`;
export const GET_FII_WALLET_BY_HASH_ID = gql`
  query GetFiiWalletByHashId($hash: String!) {
    getFiiWalletByHashId(hash: $hash) {
      operationHashId
      operationName
      operationCnpj
      operationFinality
      operationCategory
      operationPropertyType
      operationTypeOfManager
    }
  }
`;
export const GET_FII_DETAILS_GENERAL_DATA_BY_HASH_ID = gql`
  query GetFiiDetailsGeneralDataByHashId($hash: String!) {
    getFiiDetailsGeneralDataByHashId(hash: $hash) {
      operationHashId
      operationName
      operationCnpj
      operationTickers
      operationTickersAsArray
      operationTerm
      isMulticlasses
      market
      isB3Listed
      operationObject
      operationCvmRegisterDate
    }
  }
`;

// Competence of FII and FIDC
export const GET_LAST_COMPETENCE = gql`
  query GetLastCompetence($value: String!, $searchType: CompetenceSearchType!) {
    getLastCompetence(value: $value, searchType: $searchType) {
      month
      year
    }
  }
`;
// Banners

// Documents
export const GET_DOCUMENTS_BY_HASH_ID = gql`
  query GetDocumentsByHashId(
    $hash: String!
    $filter: DocumentsFilter
    $pagination: Pagination!
  ) {
    getDocumentsByHashId(
      hash: $hash
      filter: $filter
      pagination: $pagination
    ) {
      categories {
        id
        name
        found
        pages
      }
      documents {
        documentId
        category
        title
        reference
        urlDownload
      }
    }
  }
`;

// Rankings
export const GET_RANK_ITEM_HISTORY = gql`
  query GetRankItemHistory(
    $itemId: String!
    $market: String!
    $type: String!
    $timeRange: timeRanges!
    $criteria: String!
  ) {
    getRankItemHistory(
      itemId: $itemId
      market: $market
      type: $type
      timeRange: $timeRange
      criteria: $criteria
    ) {
      baseYear
      baseMonth
      hasDetailled
      itemId
      valueLabel
      history {
        year
        month
        value
        percentValue
        position
        lastYearPosition
      }
    }
  }
`;
export const GET_ANALYTICAL_RANK = gql`
  query GetAnalyticalRank(
    $itemId: Int!
    $market: rankMarket!
    $type: String!
    $timeRange: String!
    $year: Int!
    $month: Int!
    $pagination: Pagination!
    $sort: Sort!
  ) {
    getAnalyticalRank(
      itemId: $itemId
      market: $market
      type: $type
      timeRange: $timeRange
      year: $year
      month: $month
      pagination: $pagination
      sort: $sort
    ) {
      rows {
        operationId
        collumns {
          operation {
            value
            label
          }
          class {
            value
            label
          }
          emission {
            value
            label
          }
          series {
            value
            label
          }
          classification {
            value
            label
          }
          referenceDate {
            value
            label
          }
          startDate {
            value
            label
          }
          mandateAssumption {
            value
            label
          }
          amount {
            value
            label
          }
        }
      }
      total
    }
  }
`;

// Activity

export const GET_RATING_BY_OPERATION_HASHID = gql`
  query GetRatingByOperationHashId($hash: String!, $securityId: Int!) {
    getRatingByOperationHashId(hash: $hash, securityId: $securityId) {
      data {
        operationName
        titleQuote
        dateRating
        agency
        typeAction
        note
      }
    }
  }
`;

export const GET_INVESTORS_BY_OPERATION_HASH_ID = gql`
  query GetInvestorsByOperationHashId($hash: String!) {
    getInvestorsByOperationHashId(hash: $hash) {
      operationId
      operationName
      title
      investors {
        profile
        investorsNumber
        amount
        titleNumber
      }
    }
  }
`;
