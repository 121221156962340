import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Paper, Typography } from '@mui/material';

import { IFrameCustom } from '../styles';

export function CoverVideoModal({
  open,
  onClose,
  url,
  description,
}: {
  open: boolean;
  onClose: () => void;
  url: string;
  description?: string;
}) {
  let embedId;
  const youtubeRegex =
    /(https:\/\/)?(?:www.)?(?:(youtu).be\/(.*)|(youtube).com\/watch\?v=(.*?))/;
  if (/(https:\/\/)?(?:www.)?(?:(youtube).com\/watch\?v=(.*?))/.test(url)) {
    embedId = new URL(url).searchParams.get('v');
  } else {
    embedId = new URL(url).pathname.split('/')[1];
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
      aria-labelledby="modal-video"
      aria-describedby="modal-video">
      <Paper
        square
        elevation={0}
        sx={{
          width: '63rem',
          maxWidth: '90%',
          backgroundColor: description ? '#FAFAFA' : '#000000',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            flexWrap: 'wrap',
            padding: 0,
          }}>
          <IconButton aria-label="fechar" onClick={onClose}>
            <Close
              htmlColor={description ? '#4F4F4F' : 'white'}
              fontSize={description ? 'small' : 'inherit'}
            />
          </IconButton>
        </Box>
        <Box width="95%" height="100%">
          <Box
            sx={{
              overflow: 'hidden',
              paddingBottom: '56.25%',
              position: 'relative',
              height: 0,
            }}>
            <IFrameCustom
              src={
                youtubeRegex.test(url)
                  ? `https://www.youtube.com/embed/${embedId}?autoplay=1`
                  : `https://player.vimeo.com/video/${embedId}?autoplay=1&autopause=0`
              }
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
          {description && (
            <Typography sx={{ padding: '26px 0' }}>{description}</Typography>
          )}
        </Box>
      </Paper>
    </Modal>
  );
}
