import { useState } from 'react';

import { useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import { subDays } from 'date-fns';

// Global components
import CardServiceDocuments from '../../../components/CardServiceDocuments';
import { If } from '../../../components/If';
import { PanelLogin } from '../../../components/PanelLogin';
import Subtitle from '../../../components/Subtitle';
import { useAuthContext } from '../../../context/AuthContextProvider';
import ipanema from '../../../graphql/clients/ipanema';
import { GET_DOCUMENTS_BY_HASH_ID } from '../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 220,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fullWidth: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  dataPicker: {
    marginTop: theme.spacing(0.5),
    marginBottom: 0,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 458,
      maxWidth: 350,
      width: 'auto',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export default function OperationDocuments({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const onChangeSelectStartDate = (date) => {
    setFidcDocumentsState({
      ...fidcDocumentsState,
      customRange: {
        ...fidcDocumentsState.customRange,
        startDate: date,
      },
    });
  };
  const onChangeSelectEndDate = (date) => {
    setFidcDocumentsState({
      ...fidcDocumentsState,
      customRange: {
        ...fidcDocumentsState.customRange,
        endDate: date,
      },
    });
  };
  const onChangeSelectCategory = (event) => {
    setFidcDocumentsState({
      ...fidcDocumentsState,
      page: 1,
      categoryId: event.target.value,
    });
  };
  const onChangeSelectPeriod = (event) => {
    setFidcDocumentsState({
      ...fidcDocumentsState,
      predefinedRange: event.target.value,
    });
  };
  const onChangePagination = (event, value) => {
    setFidcDocumentsState({ ...fidcDocumentsState, page: value });
  };
  const [fidcDocumentsState, setFidcDocumentsState] = useState({
    page: 1,
    categoryId: 0,
    predefinedRange: 'All',
    customRange: {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
    },
  });
  const { data, loading, error } = useQuery(GET_DOCUMENTS_BY_HASH_ID, {
    variables: {
      hash,
      filter: {
        categoryId: fidcDocumentsState.categoryId,
        predefinedRange: fidcDocumentsState.predefinedRange,
        customRange: fidcDocumentsState.customRange,
      },
      pagination: {
        page: fidcDocumentsState.page,
        pageSize: 6,
      },
    },
    client: ipanema,
    skip:
      !auth?.user?.subscription?.plan?.permissions[
        'operacoes_fidic_documentos'
      ] &&
      !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cri_documentos'
      ] &&
      !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cra_documentos'
      ] &&
      !auth?.user?.subscription?.plan?.permissions['operacoes_fii_documentos'],
  });

  return (
    <Box p={2}>
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_documentos'
          ] &&
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_cri_documentos'
          ] &&
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_cra_documentos'
          ] &&
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_fii_documentos'
          ]
        }>
        <PanelLogin />
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fidic_documentos'
          ] ||
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_cri_documentos'
          ] ||
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_cra_documentos'
          ] ||
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_fii_documentos'
          ]
        }>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {loading || error ? null : (
              <Grid container spacing={2}>
                <Grid item className={classes.fullWidth}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="label-category">Categoria</InputLabel>
                    <Select
                      value={fidcDocumentsState.categoryId}
                      onChange={onChangeSelectCategory}
                      label="Categoria"
                      labelId="category"
                      inputProps={{ 'aria-label': 'categoria' }}
                      disabled={
                        data?.getDocumentsByHashId?.categories.length === 0
                      }
                      MenuProps={MenuProps}>
                      {data?.getDocumentsByHashId?.categories.map(
                        ({ id, name, found }) => (
                          <MenuItem key={id} value={id}>
                            {`${name} (${found})`}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="label-period">Período</InputLabel>
                    <Select
                      displayEmpty
                      id="select-period"
                      label="Período"
                      labelId="period"
                      value={fidcDocumentsState.predefinedRange}
                      onChange={onChangeSelectPeriod}
                      MenuProps={MenuProps}>
                      <MenuItem value="M12">Últimos 12 meses</MenuItem>
                      <MenuItem value="M6">Últimos 6 meses</MenuItem>
                      <MenuItem value="M3">Últimos 3 meses</MenuItem>
                      <MenuItem value="M1">Último mês</MenuItem>
                      <MenuItem value="All">Todos</MenuItem>
                      <MenuItem value="Custom">Definir período</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {fidcDocumentsState.predefinedRange === 'Custom' && (
                  <>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            margin="normal"
                            id="start-date"
                            label="Data inicial"
                            className={classes.dataPicker}
                            format="dd/MM/yyyy"
                            value={fidcDocumentsState.customRange.startDate}
                            onChange={onChangeSelectStartDate}
                            KeyboardButtonProps={{
                              'aria-label': 'data',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            margin="normal"
                            id="end-date"
                            label="Data final"
                            className={classes.dataPicker}
                            format="dd/MM/yyyy"
                            value={fidcDocumentsState.customRange.endDate}
                            onChange={onChangeSelectEndDate}
                            KeyboardButtonProps={{
                              'aria-label': 'data',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <>
                {data?.getDocumentsByHashId?.categories.map(
                  ({ id, name, found }) =>
                    id === fidcDocumentsState.categoryId && (
                      <Subtitle key={id} title={`${name} (${found})`} />
                    ),
                )}
                <Box>
                  <Grid container spacing={3}>
                    {data?.getDocumentsByHashId?.documents?.length === 0 ? (
                      <Grid item xs={4}>
                        <Typography>Nenhum documento encontrado</Typography>
                      </Grid>
                    ) : (
                      data?.getDocumentsByHashId?.documents?.map(
                        ({
                          documentId,
                          category,
                          description,
                          reference,
                          urlDownload,
                        }) => (
                          <Grid item xs={12} sm={6} lg={4} key={documentId}>
                            <CardServiceDocuments
                              category={category}
                              created={reference}
                              description={description}
                              urlDownload={urlDownload}
                            />
                          </Grid>
                        ),
                      )
                    )}
                  </Grid>
                  <Box
                    pt={3}
                    display="flex"
                    justifyContent={isExtraSmall ? 'center' : 'flex-end'}>
                    {data?.getDocumentsByHashId?.categories.map(
                      ({ id, found, pages }) =>
                        found !== 0 &&
                        id === fidcDocumentsState.categoryId && (
                          <Pagination
                            key={id}
                            count={pages}
                            page={fidcDocumentsState.page}
                            onChange={onChangePagination}
                            siblingCount={isExtraSmall ? 0 : 1}
                            shape="rounded"
                          />
                        ),
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </If>
    </Box>
  );
}
