import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { Event } from 'src/types/event';

type FaqSectionProps = {
  event: Event;
};

export default function FaqSection({ event }: FaqSectionProps) {
  return (
    <Box maxWidth="1200px" sx={{ margin: '32px auto' }}>
      <Typography
        sx={{
          fontSize: '32px',
          textAlign: 'center',
          fontWeight: 700,
          marginBottom: '16px',
        }}>
        {event?.faq?.title}
      </Typography>
      <Box sx={{ margin: '0 auto', padding: '0 16px' }}>
        {event?.faq?.questions?.map(({ question, answer }, index) => (
          <Box key={`question-${index}}`} sx={{ marginTop: '24px' }}>
            <Accordion square elevation={1}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}`}
                id={`panel-${index}`}>
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
