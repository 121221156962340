import { useEffect, useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

// styles
const useStyles = makeStyles((theme) => ({
  linkGrid: {
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
    width: '42px',
    [theme.breakpoints.down('sm')]: {
      height: '42px',
      width: '42px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '32px',
      width: '32px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  socialContainer: {
    justifyContent: 'start',
  },
}));

export default function ArticleSocialMedias({ title, slug, hashId }) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);

  const host = process.env.REACT_APP_PUBLIC_URL ?? window.location.origin;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;
  const isSmallMobile = width < 420;
  const iconSize = isSmallMobile ? 32 : isMobile ? 32 : 42;

  return (
    <Grid
      container
      spacing={1}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={classes.socialContainer}>
      <Grid item>
        <LinkedinShareButton
          title={title}
          source={host}
          url={`${host}/artigo/${slug}/${hashId}`}>
          <LinkedinIcon size={iconSize} round={true} />
        </LinkedinShareButton>
      </Grid>
      <Grid item>
        <FacebookShareButton
          quote={title}
          url={`${host}/artigo/${slug}/${hashId}`}>
          <FacebookIcon size={iconSize} round={true} />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <TwitterShareButton
          title={title}
          url={`${host}/artigo/${slug}/${hashId}`}>
          <TwitterIcon size={iconSize} round={true} />
        </TwitterShareButton>
      </Grid>
      <Grid item>
        <TelegramShareButton
          title={title}
          url={`${host}/artigo/${slug}/${hashId}`}>
          <TelegramIcon size={iconSize} round={true} />
        </TelegramShareButton>
      </Grid>
      <Grid item>
        <WhatsappShareButton
          title={title}
          separator=" | "
          url={`${host}/artigo/${slug}/${hashId}`}>
          <WhatsappIcon size={iconSize} round={true} />
        </WhatsappShareButton>
      </Grid>
      <Grid item>
        <Grid
          title="Copiar Link"
          className={classes.linkGrid}
          onClick={() => navigator.clipboard.writeText(window.location.href)}>
          <LinkIcon fontSize={'medium'} />
        </Grid>
      </Grid>
    </Grid>
  );
}
