import { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';

import { ReactComponent as checkIcon } from '../../assets/imgs/checkIcon.svg';
import { getBanners, setBannerCount } from '../../services/advertisement';
import { useLocalStorage } from '../../utils/hooks';
import { getLargestImageUrl } from '../../utils/image';

// Styles
const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    height: 250,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: 75,
    },
    [theme.breakpoints.only('sm')]: {
      height: 150,
    },
    [theme.breakpoints.only('md')]: {
      height: 208,
    },
  },
  media: {
    maxHeight: 250,
    width: '100%',
  },
  cardAction: {
    cursor: 'default',
  },
  cta: {
    position: 'absolute',
    top: 35,
    right: '10%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: '2%',
    },
    [theme.breakpoints.only('sm')]: {
      top: 0,
      right: '5%',
    },
    [theme.breakpoints.only('md')]: {
      right: '5%',
    },
  },
  ctaTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.875rem',
    lineHeight: '36.31px',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.65rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.25rem',
    },
  },
  ctaText: {
    fontSize: '1rem',
    lineHeight: '19.36px',
    textAlign: 'right',
    maxWidth: 375,
    margin: theme.spacing(2, 0, 3),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: '0.75rem',
      maxWidth: 300,
    },
  },
  ctaButton: {
    padding: theme.spacing(1.25, 3),
    backgroundColor: '#FF8211',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
      padding: theme.spacing(0.75, 1),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.75, 1.5),
    },
  },
}));

export default function BannerAdvertisement({ location }) {
  const classes = useStyles();
  const [banner, setBanner] = useState({});
  const [notIds, setNotIds] = useLocalStorage(`@${location}`, []);
  const { isLoading, error } = useQuery(
    ['banners', location],
    () =>
      getBanners(
        notIds?.filter((id) => !!id),
        location,
      ),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (data?.meta?.removeLocalStorage) {
            setNotIds([]);
          }
          setBanner(data?.data);
          if (notIds && !notIds.includes(data?.data?.id)) {
            notIds.push(data?.data?.id);
            setNotIds(notIds);
          }
        }
      },
    },
  );

  const updateBannerMutation = useMutation(setBannerCount);

  if (Object.keys(banner)?.length === 0) return null;

  function onClick() {
    if (!isLoading && !error) {
      const newBanner = {
        ...banner,
        accessCounter: Number(banner?.accessCounter ?? 0) + 1,
      };
      if (process.env.REACT_APP_GTM) {
        window.dataLayer.push({
          event: 'banner_click_count',
          eventAction: 'click',
          category: 'Banner',
          title: newBanner.location,
          eventUrl: newBanner.url,
          eventValue: newBanner.accessCounter,
        });
      }
      window.open(banner?.url);
      setBanner(newBanner);
      updateBannerMutation.mutate(newBanner);
    }
  }

  return (
    <>
      {banner?.banner && (
        <Card elevation={0}>
          <CardActionArea
            onClick={() => {
              if (banner?.CTA) {
                return;
              }
              onClick();
            }}
            className={banner?.CTA && classes.cardAction}>
            <CardContent className={classes.cardContent}>
              <img
                src={getLargestImageUrl(banner?.banner)}
                className={classes.media}
                alt="banner"
              />
              {banner?.CTA && (
                <Box className={classes.cta}>
                  <Typography className={classes.ctaTitle}>
                    {banner?.CTA?.title}
                  </Typography>
                  <Typography className={classes.ctaText}>
                    {banner?.CTA?.text}
                  </Typography>
                  <Button
                    onClick={() => onClick()}
                    color="secondary"
                    variant="contained"
                    className={classes.ctaButton}
                    startIcon={
                      <SvgIcon component={checkIcon} viewBox="0 0 28 27" />
                    }>
                    {banner?.CTA?.labelButton}
                  </Button>
                </Box>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </>
  );
}
