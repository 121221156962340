export function getUtmUrl(url: string, campaign?: string, source?: string) {
  if (!url) return '';
  const newUrl = new URL(url);
  if (campaign) {
    newUrl.searchParams.append('utm_campaign', campaign);
  }
  if (source) {
    newUrl.searchParams.append('utm_source', source);
  }
  return newUrl.href;
}
