import qs from 'qs';

import { api } from '../../api/api';

export const getVideoList = async () => {
  const {
    data: { data },
  } = await api.get('/video-list');
  return data;
};

export const getVideosByCollection = async ({
  page,
  pageSize,
  collectionId,
}) => {
  const query = qs.stringify(
    {
      filters: {
        collection: {
          id: {
            $eq: collectionId,
          },
        },
      },
      sort: ['uploadedAt:desc'],
      pagination: {
        page,
        pageSize,
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data, meta },
  } = await api.get(`/videos?${query}`);
  return { data, total: meta?.pagination?.total };
};

export const getOtherVideosByCollection = async ({ collectionId, videoId }) => {
  const query = qs.stringify(
    {
      filters: {
        collection: {
          id: {
            $eq: collectionId,
          },
        },
        id: {
          $ne: videoId,
        },
      },
      sort: ['uploadedAt:desc'],
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    },
  );
  const {
    data: { data },
  } = await api.get(`/videos?${query}`);
  return data;
};

export const getVideoById = async (id) => {
  const {
    data: { data },
  } = await api.get(`/videos/${id}?populate=*`);
  return data;
};
