import { Box, Grid } from '@mui/material';
import { Speaker as SpeakerType } from 'src/types/event';

import SpeakerCard from './SpeakerCard';

type SpeakerProps = {
  data: SpeakerType;
};

export default function Speaker({ data }: SpeakerProps) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          width: '350px',
          height: '445px',
          perspective: '1000px',
        }}>
        <SpeakerCard data={data} />
      </Box>
    </Grid>
  );
}
