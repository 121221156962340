import * as LetterToReader from './styles';

export default function LetterToReaderSection() {
  return (
    <LetterToReader.Section data-yearbook-section="Carta ao leitor">
      <LetterToReader.BackgroundWrapper>
        <LetterToReader.TitleWrapper>
          <LetterToReader.Title>Carta</LetterToReader.Title>
          <LetterToReader.Title>Ao</LetterToReader.Title>
          <LetterToReader.Title>Leitor</LetterToReader.Title>
        </LetterToReader.TitleWrapper>
        <LetterToReader.ContentWrapper>
          <LetterToReader.Paragraph>
            2023 será, certamente, mais um daqueles anos que entrará para a
            história do mercado de capitais com a etiqueta inesquecível. De um
            início de ano marcado pela cautela, decorrente das incertezas
            causadas pela ressaca do período pandêmico, de eleições
            presidenciais polarizadas e por um cenário de taxa de juros ainda
            elevada, vimos essa situação mudar à medida que o mercado sentia-se
            mais confortável com as propostas de reformas do novo governo, o
            início do ciclo de queda da taxa básica de juros e as contínuas
            melhorias nos marcos regulatórios dos mercados financeiro e de
            capitais proporcionadas pelos respectivos órgãos reguladores.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Esse cenário mais otimista construído ao longo do ano, que fez o
            mercado de finanças estruturadas uma vez mais alcançar novos
            recordes, conforme esses Anuários têm o prazer de registrar, não
            evitou as tradicionais lombadas no caminho. Os casos Americanas e
            Light, por exemplo, trouxeram aquela pitada de dúvida para
            investidores de renda fixa, que novamente experimentaram problemas
            ao investirem em ativos de comportamento binário, as emissões de
            dívida de grandes empresas. Apesar de negativo, os eventos trouxeram
            reflexos positivos para o mercado de securitização. Investidores
            nessa classe de ativos viram suas operações vinculadas a esses nomes
            – aquelas lastreadas em carteiras de crédito pulverizado originadas
            e cedidas a veículos de securitização por essas instituições –
            performarem.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Como dito, os percalços no caminho não impediram que o ano
            registrasse novos recordes em todos os mercados cobertos por essas
            publicações. O mercado de Fundos de Investimento em Direitos
            Creditórios (FIDCs), por mais um ano, apresentou significativo
            crescimento e registrou novas marcas nos mercados primário e
            secundário, assim como no seu estoque, que alcançou novos níveis
            para o patrimônio líquido consolidado e número de fundos em operação
            no final do ano. Vale sempre ressaltar que, com exceção dos
            FIDCs-FIAGROs, esse é um mercado que trabalha sem qualquer tipo de
            incentivo fiscal para os seus investidores, e mesmo assim cresce a
            taxas de dois dígitos há vários anos.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Dentre os mercados de fundo de investimento cobertos por essas
            publicações, aquele dedicado ao setor imobiliário (FIIs) foi o que
            mais sofreu os impactos do “Americanas case”. Este acabou
            reverberando para todo o segmento de varejo e impactando aqueles
            FIIs expostos a empresas deste setor. Outro fator importante a ser
            absorvido pelos participantes deste mercado veio de mudanças na
            legislação, que alterou os requisitos mínimos para isenção de
            impostos para pessoas físicas que investem em cotas desses fundos.
            Mas, apesar dessas intempéries, o mercado apresentou recordes de
            volume e de número de negócios no secundário – indicando um
            crescente interesse por parte de investidores e maior maturidade de
            mercado – e, assim como o mercado de FIDCs, registrou novos recordes
            para o patrimônio líquido e número de fundos em operação no final do
            ano.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            A ausência de marco regulatório próprio não foi motivo para o
            mercado dos Fundos de Investimento nas Cadeias Produtivas
            Agroindustriais (FIAGROs) não crescer. Correndo nas trilhas
            regulatórias dos FIIs, FIDCs e fundos de investimento em
            participação, o mercado de FIAGROs cresceu e floresceu, em 2023, com
            destaque para os FIDCs-FIAGROs, dando mais uma demonstração do papel
            crescente que o mercado de capitais pode desempenhar no
            financiamento do agronegócio brasileiro.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Com relação ao mercado de securitização representado pelos títulos
            de crédito, os Certificados de Recebíveis (CRs), este Anuário
            mostrará que houve crescimentos e recordes estabelecidos nos
            mercados secundários e estoques das vertentes imobiliárias e do
            agronegócio. Porém, permanece a dominância de um perfil de risco
            concentrado, marcado pelas operações de risco corporativo. Por sua
            vez, operações de CRs lastreados em ativos vinculados a outros
            setores de nossa economia ainda não despontaram, apesar da
            expectativa inicial de que o novo marco legal proporcionaria uma
            redução nos custos operacionais, demonstrando que é necessário mais
            que uma lei para a criação de um novo mercado.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            A 17ª edição desses Anuários conta tudo isso e muito mais em
            detalhes, escrevendo assim mais um capítulo na história do mercado
            de finanças estruturadas no Brasil. Como esse é um mercado que não
            para de crescer e inovar, o mesmo acontece com esses Anuários. Esta
            nova edição traz dois novos Anuários, um para comemorar o
            crescimento do mercado de FIAGROs, e outro para registrar o mercado
            agora mais amplo de Certificados de Recebíveis, que desde a Lei
            14.430/22 não mais está limitado aos segmentos imobiliário e do
            agronegócio.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Por fim, uma palavra de agradecimento aos patrocinadores destas
            publicações, instituições que acreditam na importância da informação
            de qualidade, imparcial e independente. Eles escrevem seus nomes na
            história desses mercados, não apenas por serem líderes em seus
            segmentos de atuação, mas também por reconhecerem que a disseminação
            do conhecimento é um catalisador necessário para que nosso mercado
            de capitais cresça de forma sustentável.
          </LetterToReader.Paragraph>
          <LetterToReader.Paragraph>
            Desejamos a todos uma boa leitura e, como sempre, aguardamos
            ansiosos as sugestões e as críticas construtivas e os elogios
            estimulantes.
          </LetterToReader.Paragraph>
        </LetterToReader.ContentWrapper>
        <LetterToReader.Closing>
          <LetterToReader.ClosingText>
            Saudações Uqbarianas,
          </LetterToReader.ClosingText>
          <LetterToReader.LetterAuthor
            as="a"
            href="https://uqbar.com.br/profissional/carlos-augusto-lopes-ceo-uqbar/127"
            target="_blank">
            Carlos Augusto Lopes
          </LetterToReader.LetterAuthor>
          <LetterToReader.ClosingText>
            Fundador e CEO
          </LetterToReader.ClosingText>
        </LetterToReader.Closing>
        <LetterToReader.EndBorder />
        <LetterToReader.BackgroundEnd />
      </LetterToReader.BackgroundWrapper>
    </LetterToReader.Section>
  );
}
