// Global Components
// Material ui components
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

// Apollo client
import ipanema from '../../graphql/clients/ipanema';
import { GET_LAST_COMPETENCE } from '../../graphql/queries';
import DateChip from '../DateChip';

export default function Competence({ value, searchType }) {
  const { data, loading, error } = useQuery(GET_LAST_COMPETENCE, {
    variables: { value, searchType },
    client: ipanema,
  });

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <DateChip
        title="Competência"
        date={
          data?.getLastCompetence?.month && data?.getLastCompetence?.year
            ? `${
                data?.getLastCompetence.month < 10
                  ? `0${data?.getLastCompetence.month}`
                  : data?.getLastCompetence.month
              }/${data?.getLastCompetence.year}`
            : 'N/D'
        }
        loading={loading}
        error={error}
      />
    </Box>
  );
}
