import { KeyboardArrowDown, SyncAlt } from '@mui/icons-material';
import { Box } from '@mui/material';
import { MRT_Column, MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

export const MaterialTableDefaultProps = {
  localization: {
    ...MRT_Localization_PT_BR,
    columnActions: 'Ações de coluna',
    filterEmpty: 'Vazio',
    sortByColumnAsc: 'Ordenar por {column} em crescente',
    sortByColumnDesc: 'Ordenar por {column} em decrescente',
    sortedByColumnAsc: 'Ordenado por {column} em crescente',
    sortedByColumnDesc: 'Ordenado por {column} em decrescente',
  },
  icons: {
    MoreVertIcon: (props: any) => (
      <KeyboardArrowDown
        {...props}
        sx={{ color: '#FF8211', fontSize: '28px' }}
      />
    ),
    SyncAltIcon: (props: any) => (
      <Box
        component="span"
        sx={{
          '& > svg': { visibility: 'hidden' },
          '&:hover': {
            '& > svg': { visibility: 'visible' },
          },
        }}>
        <SyncAlt {...props} />
      </Box>
    ),
  },
  muiFilterTextFieldProps: (props: {
    column: MRT_Column<any, unknown>;
    rangeFilterIndex?: number | undefined;
    table: MRT_TableInstance<any>;
  }) => {
    const columnFilterHasData = !!props.table
      .getState()
      .columnFilters.find(
        (filter) => filter.id === props.column.columnDef.accessorKey,
      )?.value;
    return {
      sx: {
        '&:hover': {
          '& > p:nth-of-type(1)': {
            visibility: 'visible',
          },
        },
        '& > .MuiInputBase-root': {
          '& > .MuiInputAdornment-positionEnd': {
            display: columnFilterHasData ? 'flex' : 'none',
            marginRight: 'unset',
          },
        },
        '& > .MuiFormHelperText-root': {
          visibility: 'hidden',
        },
      },
    };
  },
};
