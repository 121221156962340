import { Grid, makeStyles, Paper } from '@material-ui/core';
import { useQuery } from 'react-query';

import CardTraining from './CardTraining';
import SubtitleLink from '../../../components/SubtitleLink';
import { useAuthContext } from '../../../context/AuthContextProvider';
// Global components
import { getTrainings } from '../../../services/training';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  trainings: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
  },
  gridItem: {
    paddingTop: '0 !important',
    [theme.breakpoints.between('sm', 'md')]: {
      minWidth: '320px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '300px',
    },
  },
}));

export default function Trainings() {
  const classes = useStyles();
  const { auth } = useAuthContext();

  const { data, isFetched } = useQuery('trainings', () =>
    getTrainings({ from: 0, limit: 3 }),
  );

  if (isFetched && !data?.data?.length) return null;

  return (
    <>
      {data?.data?.length > 0 && (
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ marginTop: auth.isLoggedIn ? '-8px' : '16px' }}>
          <SubtitleLink
            title="Próximos treinamentos"
            url="/treinamentos"
            labelLink="Ver todos os treinamentos"
          />
          <Grid container spacing={4} className={classes.trainings}>
            {data.data?.map(
              ({ id, title, previewImage, type, markets, slug, resume }) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={id}
                  className={classes.gridItem}>
                  <CardTraining
                    hashId={id}
                    title={title}
                    image={previewImage}
                    type={type === 'Online' ? 'On-line' : type}
                    markets={markets}
                    slug={slug}
                    resume={resume}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </Paper>
      )}
    </>
  );
}
