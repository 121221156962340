import React from 'react';

import * as S from './styles';
import { abbreviatedNumber } from './utils';

type Props = {
  title?: string;
  categories: string[];
  data: Array<{
    name: string;
    data: number[];
  }>;
  notStacked?: boolean;
  market: 'CRA' | 'CRI' | 'FIDC' | 'FII';
  disableAnimation?: boolean;
};

const ColumnChart = (props: Props) => {
  return (
    <S.StyledBox>
      <span>{props.title}</span>
      <S.StyledChart
        type="bar"
        series={props.data}
        options={{
          chart: {
            stacked: !props.notStacked,
            stackType: '100%',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: !props.disableAnimation,
            },
          },
          plotOptions: {
            bar: {},
          },
          tooltip: {
            y: {
              formatter: function (value: any) {
                if (isNaN(Number(value))) {
                  return value;
                }

                if (props.notStacked) {
                  const formatedNumber = abbreviatedNumber(value);

                  return `R$ ${formatedNumber}`;
                }

                const formatedNumber = Number(value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                });

                return `${formatedNumber} %`;
              },
            },
          },
          colors: [
            '#FD841F',
            '#CD104D',
            '#F2CD5C',
            '#600446',
            '#35D0BA',
            '#A61F69',
          ],
          dataLabels: {
            formatter() {
              return '';
            },
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: props.categories,
            type: 'date',
            labels: {
              show: true,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    borderRadiusWhenStacked: 'last',
                    borderRadiusApplication: 'end',
                    rangeBarGroupRows: true,
                  },
                },
                chart: {
                  animations: {
                    enabled: false,
                  },
                },
              },
            },
          ],
        }}
      />
      <S.Button
        color="secondary"
        variant="outlined"
        size="large"
        onClick={() =>
          window.open(
            `/operacoes?market=${props.market.toLocaleLowerCase()}`,
            '_blank',
          )
        }>
        Explorar na Plataforma Uqbar
      </S.Button>
    </S.StyledBox>
  );
};

export default React.memo(ColumnChart);
