import React from 'react';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { pageData, rankingsData } from './data';
import {
  formatChartProps,
  formatMonthlyColumnSpread,
  YEARS,
} from '../chart-formater';
import { mapColumnLineData } from '../chart-map-data';
import ColumnChart from '../components/Charts/ColumnChart';
import ColumnLineChart from '../components/Charts/ColumnLineChart';
import Page from '../components/Page';
import ScrollHandler from '../components/Page/ScrollHandler';
import Text from '../components/Page/Text';
import Ranking from '../components/Widgets/Ranking';
import {
  Banners,
  ChartDataContentPayload,
  ChartsDataRequestPayload,
} from '../types';
import { cast, marketPageFns } from '../utils';

type Props = {
  indexes: Array<{ title: string; ref: React.MutableRefObject<any> }>;
  open: boolean;
  chartsData: ChartsDataRequestPayload[];
  banners: Banners;
};

const COLLECTION_ID = 32;

const FII = (props: Props) => {
  const banners = marketPageFns.getMarketBanners(props.banners, 'FII');
  const chartData = marketPageFns.getChartData(props.chartsData, COLLECTION_ID);

  const [
    issuancesAssetChart,
    issuancesRealStateChart,
    issuancesChart,
    secondaryMarketChart,
    netWorthChart,
    netWorthAssetChart,
    netWorthGoalChart,
    netWorthManagement,
    netWorthRealState,
  ] = chartData;

  const [intro, primaryMarket, secondaryMarket, supply, ranking] = pageData.map(
    marketPageFns.formatPageData(props.indexes, banners),
  );

  const getPageProps = marketPageFns.getPageProps(props.open);

  const isReady = marketPageFns.checkIsReady(
    props.indexes,
    pageData,
    chartData,
    9,
  );

  const formatNetWorthChart = (chartData: ChartDataContentPayload) => {
    const categories = _.flatten(
      YEARS.map((year) =>
        _.range(12).map((month) =>
          new Date(Number(year), month).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'long',
          }),
        ),
      ),
    );

    const data = [
      {
        name: 'Soma de PL',
        type: 'column',
        data: netWorthChart.content.map((i) => Number(i['Soma de pl'])),
      },
      {
        name: 'Num. de FII',
        type: 'line',
        data: netWorthChart.content.map((i) =>
          Number(i['Valores distintos de fii_cnpj']),
        ),
      },
    ];

    return {
      title: chartData.name,
      categories,
      data: cast<any>(data),
    };
  };

  return isReady ? (
    <>
      <Page {...getPageProps(intro)}>
        <Text text={intro.text} />
      </Page>
      <Page {...getPageProps(primaryMarket)}>
        <ScrollHandler
          textEl={primaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FII"
              {...formatChartProps(issuancesChart)({
                chart: 'columnLine',
                categories: YEARS,
                field: 'tipo',
                mapName: {
                  amount: 'Montante Emitido',
                  quantity: 'Número de Negócios',
                },
              })}
            />,
            <ColumnChart
              market="FII"
              key={uuid()}
              {...formatChartProps(issuancesAssetChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FII"
              {...formatChartProps(issuancesRealStateChart)({
                chart: 'column',
                categories: YEARS,
                field: 'tipo',
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(secondaryMarket)}>
        <ScrollHandler
          textEl={secondaryMarket.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FII"
              {...formatChartProps(
                mapColumnLineData(secondaryMarketChart, {
                  date: 'data_neg',
                  amount: 'Soma de montante',
                  quantity: 'Soma de num_neg',
                }),
              )({
                chart: 'columnLine',
                categories: YEARS,
                field: 'quantity',
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(supply)}>
        <ScrollHandler
          textEl={supply.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={[
            null,
            <ColumnLineChart
              key={uuid()}
              market="FII"
              {...formatNetWorthChart(netWorthChart)}
              disableAnimation
            />,
            <ColumnChart
              key={uuid()}
              market="FII"
              {...formatMonthlyColumnSpread(YEARS)(netWorthAssetChart, {
                amount: 'Soma de pl',
                name: 'tipo_ativo',
                date: 'date',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FII"
              {...formatMonthlyColumnSpread(YEARS)(netWorthRealState, {
                amount: 'Soma de pl',
                name: 'tipo_imovel',
                date: 'date',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FII"
              {...formatMonthlyColumnSpread(YEARS)(netWorthManagement, {
                amount: 'Soma de pl',
                name: 'gestao',
                date: 'date',
              })}
            />,
            <ColumnChart
              key={uuid()}
              market="FII"
              {...formatMonthlyColumnSpread(YEARS)(netWorthGoalChart, {
                amount: 'Soma de pl',
                name: 'finalidade',
                date: 'date',
              })}
            />,
          ]}
        />
      </Page>
      <Page {...getPageProps(ranking)}>
        <ScrollHandler
          textEl={ranking.text.map((t) => (
            <Text key={uuid()} text={t} />
          ))}
          imageEl={rankingsData.map((source, index) =>
            source ? <Ranking urlSource={source} key={index} /> : null,
          )}
        />
      </Page>
    </>
  ) : (
    <></>
  );
};

export default React.memo(FII);
