import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import { PayWall } from 'src/types/paywall';

import { publicApi } from '../../api/api';

export const getInformativeTextByLocation = async (location: string) => {
  const { data } = await publicApi.get(`/informativo?populate=${location}`);
  return data;
};

export const getPayWallBanner = async (location?: string) => {
  const {
    data: { data },
  } = await publicApi.get<StrapiResponse<PayWall>>(
    `/pay-wall-banner?populate=${location}`,
  );
  return data;
};

export const getFAQ = async () => {
  const query = qs.stringify(
    {
      populate: ['sections', 'sections.questions'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/faq?${query}`);
  return data;
};
