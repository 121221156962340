import { Dispatch, SetStateAction, useState } from 'react';

import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Image } from 'src/types/institution';
import { getLargestImageUrl } from 'src/utils/image';
import styled from 'styled-components';

import Photo from '../Photo';

export const ImgCustom = styled.img`
  width: 100%;
  height: 480px;
  object-fit: contain;
  @media only screen and (max-width: 1025px) {
    height: 350px;
  }
  @media only screen and (max-width: 500px) {
    width: 270px;
    height: 270px;
  }
`;

const responsiveMain = {
  0: { items: 1 },
  650: { items: 1 },
  980: { items: 1 },
  1150: { items: 1 },
};

const responsiveThumbs = {
  0: { items: 2 },
  650: { items: 2 },
  980: { items: 3 },
  1150: { items: 6 },
};

type Props = {
  open: boolean;
  onClose: () => void;
  content: Image[];
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
};

function CarouselModal({
  open,
  onClose,
  content,
  selected,
  setSelected,
}: Props) {
  const [thumbIndex, setThumbIndex] = useState<number>(selected + 1);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const syncMainAfterChange = (e: EventObject) => {
    if (e.type === 'action') {
      setSelected(e.item);
      setThumbIndex(e.item + 1);
    }
  };

  const renderPrevButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={(theme) => ({
          position: 'absolute',
          top: '50%',
          left: -24,
          transform: 'translate(0, -50%)',
          color: '#828282',
          backgroundColor: '#F1F1F1',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: '#828282',
            opacity: 0.5,
            backgroundColor: '#F1F1F1',
          },
          fontSize: '1.5rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
          },
        })}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowLeft />
      </IconButton>
    );
  };

  const renderNextButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: -24,
          transform: 'translate(0, -50%)',
          color: '#828282',
          backgroundColor: '#F1F1F1',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: '#828282',
            opacity: 0.5,
            backgroundColor: '#F1F1F1',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowRight />
      </IconButton>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        background: 'rgba(0,0,0,0.9)',
        '& .MuiDialog-container': {
          height: '100%',
        },
      }}
      PaperProps={{
        sx: (theme) => ({
          width: '100%',
          background: 'transparent',
          boxShadow: 'none',
          [theme.breakpoints.down('sm')]: {
            margin: '10px',
          },
        }),
      }}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close
            fontSize="medium"
            sx={(theme) => ({ color: theme.palette.primary.contrastText })}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          height: '100%',
          overflow: 'hidden',
          '& > .alice-carousel': { padding: '0 24px 4px' },
        }}>
        <AliceCarousel
          activeIndex={selected}
          autoWidth
          items={content.map((image: Image) => (
            <Box
              key={image.id}
              sx={(theme) => ({
                backgroundColor: '#FAFAFA',
                padding: 2,
                [theme.breakpoints.down('lg')]: {
                  maxHeight: 500,
                },
                [theme.breakpoints.down('sm')]: {
                  maxHeight: 375,
                },
              })}>
              <ImgCustom src={getLargestImageUrl(image.image)} />
              <Typography fontSize={18} mt={2}>
                {image.description}
              </Typography>
            </Box>
          ))}
          disableDotsControls
          onSlideChanged={syncMainAfterChange}
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          swipeExtraPadding={400}
          responsive={responsiveMain}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '& > .alice-carousel': { padding: '0 24px 4px' },
          }}>
          <AliceCarousel
            activeIndex={thumbIndex}
            autoWidth
            items={content.map((image: Image, index) => (
              <Box
                style={{
                  width: '182px',
                  height: '160px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                key={image.id}
                onClick={() => setSelected(index)}>
                <Photo thumb src={getLargestImageUrl(image.image)} />
              </Box>
            ))}
            disableDotsControls
            disableButtonsControls
            responsive={responsiveThumbs}
            swipeExtraPadding={400}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CarouselModal;
