import { isEqual, omit } from 'lodash';

export const cardsRegex = {
  Visa: /^4[0-9]{12}(?:[0-9]{3})/,
  Mastercard: /^5[1-5][0-9]{14}/,
  Amex: /^3[47][0-9]{13}/,
  DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  JCB: /^(?:2131|1800|35\d{3})\d{11}/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
  elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
};
export const getCreditCardLabel = (creditCardNumber) => {
  if (cardsRegex.Visa.test(creditCardNumber)) {
    return 'visa';
  } else if (cardsRegex.Mastercard.test(creditCardNumber)) {
    return 'mastercard';
  } else if (cardsRegex.Amex.test(creditCardNumber)) {
    return 'amex';
  } else if (cardsRegex.DinersClub.test(creditCardNumber)) {
    return 'dinersclub';
  } else if (cardsRegex.Discover.test(creditCardNumber)) {
    return 'discover';
  } else if (cardsRegex.JCB.test(creditCardNumber)) {
    return 'jcb';
  } else if (cardsRegex.hipercard.test(creditCardNumber)) {
    return 'hipercard';
  } else if (cardsRegex.elo.test(creditCardNumber)) {
    return 'elo';
  }
};

export const isEmail = (email) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
  return emailRegex.test(email);
};

export const hasChanged = (obj1, obj2, keysToIgnore) => {
  const obj1WithoutKeys = omit(obj1, keysToIgnore);
  const obj2WithoutKeys = omit(obj2, keysToIgnore);

  return !isEqual(obj1WithoutKeys, obj2WithoutKeys);
};
