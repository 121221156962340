import React, { useContext } from 'react';

import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getIn } from 'formik';

import { DataContext } from '../../../../context/DataContext';
import RequestQuoteIcon from '../../icons/RequestQuote';

const SecurityMenu = ({
  formik,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  handleChangeMenu,
}) => {
  const { expanded, securityTypes } = useContext(DataContext);

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChangeMenu('panel1')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography component={'span'}>
          <div className="item">
            <RequestQuoteIcon /> 1. Título
            <Tooltip title="Título">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="full-width">
          <div>
            <Autocomplete
              size="small"
              disableClearable
              fullWidth
              onBlur={formik.handleBlur}
              value={
                (formik.values.type &&
                  securityTypes?.find(
                    (index) => index.key === formik.values.type,
                  )) ||
                ''
              }
              onChange={(event, newValue) => {
                formik.setFieldValue('type', newValue.key);
              }}
              options={securityTypes ? securityTypes : []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </div>
          <div style={{ display: 'none' }}>
            <TextField
              name="name"
              label="Nome"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.name || ''}
              onBlur={formik.handleBlur}
              size="small"
              fullWidth
              disabled={!formik.values.id}
              helperText={
                getIn(formik.touched, 'name') && getIn(formik.errors, 'name')
              }
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default SecurityMenu;
