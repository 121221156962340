import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styled from 'styled-components';

type ItemProps = {
  selected: boolean;
  show: boolean;
};

export const Item = styled.div<ItemProps>`
  text-transform: uppercase;
  color: ${(props) => (props.selected ? '#e97e34' : 'black')};
  font-family: 'Montserrat', sans-serif;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  font-size: 14px;
  padding: 5px 0;
  transition: all 2s linear;
  transition: color 0s linear;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @keyframes showItem {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  animation-name: ${(props) => (props.show ? 'showItem' : '')};
  animation-duration: 2s;

  &:hover {
    cursor: pointer;
  }

  @media (max-height: 1000px) {
    font-size: 12px;

    & + & {
      margin-top: 2%;
    }
  }
`;

type WrapperProps = {
  open: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  z-index: 999;
  background-color: white;
  transition: all 1s linear;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const Items = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-color: white;
  overflow: hidden;
  height: fit-content;
  transition: all 1s linear;
  max-height: ${(props) => (props.open ? '90vh' : '0')};
`;

export const CloseButton = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: #e97e34;

  svg {
    transform: rotate(${(props) => (props.open ? '0deg' : '180deg')});
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ArrowIcon = styled(ExpandLessIcon)`
  fill: white;
`;
