import {
  SyntheticEvent,
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import base64url from 'base64url';
import { format, parseISO } from 'date-fns';
import { ExportToCsv, Options } from 'export-to-csv';
import _ from 'lodash';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_FilterFns,
  MRT_GroupingState,
  MRT_Row,
  MRT_RowVirtualizer,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_TableState,
  MRT_VisibilityState,
  useMaterialReactTable,
} from 'material-react-table';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { CustomSwitch } from 'src/components/CustomSwitch';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FavoriteRowButton } from 'src/components/FavoriteRowButton';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import ModalButton from 'src/components/OnboardingModal/ModalButton';
import { findFavoriteByOperationMarket } from 'src/services/favorite';

import { useAuthContext } from '../../../context/AuthContextProvider';
import { getPayWallBanner } from '../../../services/informative';
import { getAll, getColumnsBasicView } from '../../../services/operation';
import Export from '../Export';
import SaveFilterModal from '../SaveFilterModal';
import ShareButton from '../Share';
import ToolbarInternal from '../ToolbarInternal';
import { QueryParams } from '../index';
import {
  buildFilters,
  COLUMN_VISIBILITY,
  parseValueToCSVBasicView,
  validateFilters,
} from '../utils';

const PAGE_SIZE = 500;

type Props = {
  state: QueryParams;
  handlePrint: () => void;
  handleSelectView: (p: string) => void;
  setState: (p: any) => void;
  operationInfo: any;
  handleModalOpen: () => void;
};

const populateFavorite = (operations: any[], favorites: any[]) => {
  return operations.map((operation) => {
    const favorite = favorites.find(
      (favorite) => favorite.operation.hashId === operation.operationId,
    );
    return {
      ...operation,
      favorite,
    };
  });
};

function BasicView({
  state,
  setState,
  operationInfo,
  handlePrint,
  handleSelectView,
  handleModalOpen,
}: Props) {
  const { auth, upgradedPlanByPermission }: any = useAuthContext();
  const hasPermission = Boolean(
    auth?.user?.subscription?.plan?.permissions?.operacoes_visao_basica,
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const [operationsData, setOperationsData] = useState<Record<string, any>[]>(
    [],
  );

  const [grouping, setGrouping] = useState<MRT_GroupingState>([]);
  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {},
  );

  const [totalCount, setTotalCount] = useState<number>(0);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const [openSaveFilterModal, setOpenSaveFilterModal] =
    useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [selectedGlobalSearchText, setSelectedGlobalSearchText] =
    useState<string>();
  const [defaultTableState, setDefaultTableState] = useState<
    Partial<MRT_TableState<any>>
  >({
    grouping: [],
    columnFilterFns: {},
    columnFilters: [],
    sorting: [],
    columnOrder: [],
    columnSizing: {},
    columnVisibility: COLUMN_VISIBILITY[state.market],
  });
  const [columnTypes, setColumnTypes] = useState<Record<string, string>>();

  const handleAddFavorite = (rowId: number, favorite: any) => {
    setOperationsData((prev: any) => {
      return prev.map((item: any) => {
        if (item.operationId === rowId) {
          return { ...item, favorite };
        }

        return item;
      });
    });
  };

  const handleRemoveFavorite = (rowId: number) => {
    setOperationsData((prev: any) => {
      return prev.map((item: any) => {
        if (item.operationId === rowId) {
          return { ...item, favorite: undefined };
        }

        return item;
      });
    });
  };

  const injectState = (newState: Partial<MRT_TableState<any>>) => {
    const allColumns: string[] = _.keys(columnTypes);

    if (!_.isEmpty(newState?.columnVisibility)) {
      setColumnVisibility((prevState) =>
        allColumns.reduce(
          (acc, cur) =>
            ({
              ...acc,
              [cur]: newState.columnVisibility?.[cur],
            }) as MRT_VisibilityState,
          prevState,
        ),
      );
    }

    if (!_.isEmpty(newState?.columnFilterFns)) {
      setColumnFilterFns((prevState) =>
        _.merge(prevState, _.pick(newState.columnFilterFns, _.keys(prevState))),
      );
    }
    if (
      _.isArray(newState?.columnFilters) &&
      !_.isEmpty(newState?.columnFilters)
    ) {
      const newFilters = newState.columnFilters.filter((f) =>
        allColumns.includes(f.id),
      );
      setColumnFilters(newFilters);
    }
    if (_.isArray(newState?.sorting) && !_.isEmpty(newState?.sorting)) {
      setSorting(newState.sorting.filter((s) => allColumns.includes(s.id)));
    }
    if (_.isArray(newState?.columnOrder) && !_.isEmpty(newState?.columnOrder)) {
      setColumnOrder([
        ...new Set([
          ...newState.columnOrder,
          ...allColumns.filter((c) => !newState.columnOrder?.includes(c)),
        ]),
      ]);
    }
    if (_.isArray(newState?.grouping) && !_.isEmpty(newState?.grouping)) {
      setGrouping(allColumns.filter((c) => newState.grouping?.includes(c)));
    }

    if (
      _.isArray(newState?.columnSizing) &&
      !_.isEmpty(newState?.columnSizing)
    ) {
      setColumnSizing((prevState) =>
        _.merge(prevState, _.pick(newState.columnSizing, _.keys(prevState))),
      );
    }
  };

  const resetTableToDefault = () => {
    setSelectedFilter(null);
    tableInstance.resetColumnFilters();
    tableInstance.resetColumnOrder();
    tableInstance.resetColumnPinning();
    tableInstance.resetColumnSizing();
    tableInstance.resetColumnVisibility();
    tableInstance.resetGlobalFilter();
    tableInstance.resetGrouping();
    tableInstance.resetSorting();
    tableInstance.resetHeaderSizeInfo();
    injectState(defaultTableState);
  };

  const handleSelectFilter = (values: any) => {
    resetTableToDefault();
    setSelectedFilter(values);
    injectState(values?.data);
  };

  const handleTableChange = (values: any, callback: (values: any) => void) => {
    setSelectedFilter(null);
    callback(values);
  };

  const isDisabledSaveFilter = (table: MRT_TableInstance<any>): boolean => {
    return (
      table.getState().isSaving ||
      _.isEqual(
        _.pick(table.getState(), _.keys(defaultTableState)),
        defaultTableState,
      )
    );
  };

  const { isLoading: isLoadingColumns, refetch: refetchColumns } = useQuery(
    ['operation-columns', state.market, state.mode],
    () =>
      getColumnsBasicView({
        market: state.market.toLowerCase(),
      }),
    {
      enabled: !!state.market && hasPermission,
      refetchOnMount: 'always',
      onSuccess: ({ data }: { data: MRT_ColumnDef<any>[] }) => {
        if (_.isEmpty(data)) return;
        setTotalCount(0);
        tableInstance?.reset?.();

        const processedColumns: MRT_ColumnDef<any>[] = [
          {
            accessorKey: 'favorite',
            accessorFn: (row) => row?.favorite?.operation,
            header: '',
            Header: function ({ table }) {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center">
                  <IconButton
                    onClick={() => setOpenSaveFilterModal(true)}
                    disabled={isDisabledSaveFilter(table)}
                    sx={{
                      '& > svg': {
                        color: '#FF8211',
                      },
                      '&:disabled': {
                        '& > svg': {
                          color: '#BDBDBD',
                        },
                      },
                    }}>
                    <SaveAltIcon sx={{ fontSize: '26px' }} />
                  </IconButton>
                  <Typography fontSize={12} textAlign="center">
                    Salvar
                  </Typography>
                </Box>
              );
            },
            muiTableHeadCellProps: (props) => ({
              sx: {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }),
            maxSize: 75,
            columnDefType: 'display',
            Cell: ({ cell }: { cell: any }) => (
              <FavoriteRowButton
                type="operation"
                rowId={cell.row.original?.operationId}
                favoriteId={cell.row.original?.favorite?.id}
                handleAddFavorite={handleAddFavorite}
                handleRemoveFavorite={handleRemoveFavorite}
              />
            ),
          },
          ...data.map((initialColumn): MRT_ColumnDef<any> => {
            switch (initialColumn.accessorKey) {
              case 'spread':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    parseFloat(
                      Number(
                        row[String(initialColumn.accessorKey)] || 0,
                      ).toFixed(2),
                    ),
                  Cell: ({ cell }) =>
                    cell.getValue<number>()?.toLocaleString?.('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || '',
                  muiTableBodyCellProps: {
                    align: 'right',
                  },
                };
              case 'np':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    Boolean(row[String(initialColumn.accessorKey)]),
                  Cell: ({ cell }) =>
                    cell.getValue<boolean>()
                      ? 'Não Padronizado'
                      : 'Padronizado',
                };
              case 'mm':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    Boolean(row[String(initialColumn.accessorKey)]),
                  Cell: ({ cell }) =>
                    cell.getValue<boolean>() ? 'FIDC MM' : 'Outros',
                };
              case 'pulverization':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    Boolean(row[String(initialColumn.accessorKey)]),
                  Cell: ({ cell }) =>
                    cell.getValue<boolean>() ? 'Sim' : 'Não',
                };
              case 'name':
                return {
                  ...initialColumn,
                  Cell: ({ cell }) => (
                    <Link
                      component={RouterLink}
                      to={`/operacao/${state.market.toLowerCase()}/${
                        cell.row.original.slug
                      }/${cell.row.original.operationId}`}>
                      {(cell.getValue() || '') as string}
                    </Link>
                  ),
                };
              default:
                switch (initialColumn.meta) {
                  case 'boolean':
                    return {
                      ...initialColumn,
                      accessorFn: (row) =>
                        Boolean(row[String(initialColumn.accessorKey)]),
                    };
                  case 'datetime':
                  case 'date':
                    return {
                      ...initialColumn,
                      muiFilterDatePickerProps: {
                        disableOpenPicker: true,
                      },
                      filterVariant: 'date',
                      accessorFn: (row) => {
                        if (!row[String(initialColumn.accessorKey)])
                          return null;
                        try {
                          return parseISO(
                            row[String(initialColumn.accessorKey)],
                          );
                        } catch (e) {
                          return null;
                        }
                      },
                      Cell: ({ cell }) =>
                        cell.getValue<Date>()?.toLocaleDateString() || '',
                    };
                  case 'biginteger':
                  case 'integer':
                    return {
                      ...initialColumn,
                      accessorFn: (row) =>
                        Number(row[String(initialColumn.accessorKey)] ?? 0),
                    };
                  case 'float':
                  case 'decimal':
                    return {
                      ...initialColumn,
                      accessorFn: (row) =>
                        parseFloat(
                          Number(
                            row[String(initialColumn.accessorKey)] ?? 0,
                          ).toFixed(2),
                        ),
                      Cell: ({ cell }) =>
                        cell.getValue<number>()?.toLocaleString?.('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || '',
                    };
                  default:
                    return {
                      ...initialColumn,
                      accessorFn: (row) =>
                        !row[String(initialColumn.accessorKey)]
                          ? ''
                          : row[String(initialColumn.accessorKey)],
                    };
                }
            }
          }),
        ];

        setProgress(0);

        setColumnVisibility(COLUMN_VISIBILITY[state.market]);

        const order = processedColumns.map((item) => String(item.accessorKey));
        setColumnOrder(order);

        const filterFns = data.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item.accessorKey]: item._filterFn ?? item.filterFn,
          }),
          {},
        );
        setColumnFilterFns(filterFns);

        setDefaultTableState((prevState) =>
          _.merge(prevState, {
            columnOrder: order,
            columnFilterFns: filterFns,
          }),
        );

        setColumnTypes(
          data.reduce(
            (acc, cur) => ({
              ...acc,
              [String(cur.accessorKey)]: String(cur.meta),
            }),
            {},
          ) || {},
        );

        setColumns(processedColumns);

        if (['CRA', 'CRI'].includes(state.market)) {
          setSorting([...sorting, { id: 'emissionDate', desc: true }]);
        }
      },
    },
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingDetails,
    isError,
    refetch: refetchData,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [
      'operation-details',
      state.market,
      state.mode,
      columnFilters,
      selectedGlobalSearchText,
    ],
    queryFn: ({ pageParam = 1 }) => {
      const filters = buildFilters(
        columnFilters,
        columnFilterFns,
        columns,
        selectedGlobalSearchText,
      );

      return getAll(
        {
          market: state.market.toLowerCase(),
          permission: 'operacoes_visao_basica',
          mode: 'operation',
          page: pageParam,
          pageSize: PAGE_SIZE,
          filters,
        },
        setProgress,
      );
    },
    getNextPageParam: (lastPage) =>
      lastPage.currentPage < lastPage.totalPages
        ? lastPage.currentPage + 1
        : undefined,
    enabled:
      !isLoadingColumns &&
      columns.length > 0 &&
      !validateFilters(columnFilters, columns, columnTypes ?? {}),
    retry: false,
    useErrorBoundary: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    onSuccess: (data) => {
      const allOperations = data.pages.flatMap((page) => page.data) ?? [];
      setTotalCount(data.pages[0].totalCount);
      if (favorites?.data?.length) {
        setOperationsData(populateFavorite(allOperations, favorites.data));
      } else {
        setOperationsData(allOperations);
      }
    },
    onError: () => {
      setTotalCount(0);
      setOperationsData([]);
    },
  });

  const totalFetched = operationsData.length;

  const previousScrollTopRef = useRef<number>(0);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (
        containerRefElement &&
        hasNextPage &&
        !isFetching &&
        totalFetched < totalCount
      ) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalCount, hasNextPage],
  );

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop } = target;

    // Verifica se o scroll é vertical
    if (scrollTop !== previousScrollTopRef.current) {
      previousScrollTopRef.current = scrollTop;
      fetchMoreOnBottomReached(target);
    }
  };

  const getUrlToShare = () => {
    const url = new URL(window.location.href);
    const shared = base64url(
      JSON.stringify(
        {
          [state.market]: {
            sorting,
            columnFilterFns,
            columnFilters,
            columnSizing,
            columnVisibility,
            columnOrder,
            grouping,
          },
        },
        (k, v) =>
          Array.isArray(v) && v.every((o) => JSON.stringify(o) === '{}')
            ? undefined
            : JSON.stringify(v) === '{}'
              ? undefined
              : v,
      ),
    );
    if (shared) url.searchParams.set('shared', shared);
    return url.href;
  };

  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    refetch: refetchFavorites,
  } = useQuery(
    ['favorite-operation', state.market],
    () => findFavoriteByOperationMarket(state.market.toUpperCase()),
    {
      enabled: false,
      onSuccess: async () => {
        await refetchData();
      },
    },
  );

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('operationBasicView'),
    {
      enabled: !hasPermission && !auth.isLoading,
    },
  );

  function normalizeFilteredValue(filter: any) {
    return function (
      row: any,
      id: string,
      filterValue: string | number,
      ...args: any
    ) {
      filterValue = parseValue(id, filterValue);
      return filter(row, id, filterValue, ...args);
    };
  }
  const normalizeFilteredRangeValue =
    (filter: any) =>
    (
      row: any,
      id: string,
      filterValue: [string | number, string | number],
      ...args: any
    ) => {
      const filterArray = filterValue.map((value) => parseValue(id, value));
      return filter(row, id, filterArray, ...args);
    };

  function parseValue(id: string, value: any) {
    if (!value) return value;
    switch (columnTypes?.[id]) {
      case 'float':
      case 'decimal':
        value = parseFloat(
          value.toString().trim().replaceAll('.', '').replaceAll(',', '.'),
        );
        break;
      case 'date':
      case 'datetime':
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value.toString().trim())) {
          value = parseISO(value.toString().trim());
        }
        break;
    }
    return value;
  }

  const fakeColumns: MRT_ColumnDef<any>[] = Array(4)
    .fill({})
    .map((_, index) => ({
      accessorKey: `column${index + 1}`,
      header: `Coluna${index + 1}`,
    }));

  const fakeDataRow = fakeColumns.reduce((acc, column, index) => {
    return {
      ...acc,
      [column.accessorKey as string]: `Dado ${index + 1}`,
    };
  }, {});

  const fakeRows = Array(10).fill(fakeDataRow);

  const tableInstance = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    filterFns: {
      ...MRT_FilterFns,
      between: normalizeFilteredRangeValue(MRT_FilterFns.between),
      betweenInclusive: normalizeFilteredRangeValue(
        MRT_FilterFns.betweenInclusive,
      ),
      fuzzy: normalizeFilteredValue(MRT_FilterFns.fuzzy),
      contains: normalizeFilteredValue(MRT_FilterFns.contains),
      empty: normalizeFilteredValue(MRT_FilterFns.empty),
      endsWith: normalizeFilteredValue(MRT_FilterFns.endsWith),
      equals: normalizeFilteredValue(MRT_FilterFns.equals),
      greaterThan: normalizeFilteredValue(MRT_FilterFns.greaterThan),
      greaterThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.greaterThanOrEqualTo,
      ),
      lessThan: normalizeFilteredValue(MRT_FilterFns.lessThan),
      lessThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.lessThanOrEqualTo,
      ),
      notEmpty: normalizeFilteredValue(MRT_FilterFns.notEmpty),
      notEquals: normalizeFilteredValue(MRT_FilterFns.notEquals),
      startsWith: normalizeFilteredValue(MRT_FilterFns.startsWith),
    },
    columns: hasPermission ? columns : fakeColumns,
    data: hasPermission ? operationsData : fakeRows,
    defaultColumn: {
      size: 250,
      maxSize: 1000,
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: hasPermission,
    rowVirtualizerInstanceRef,
    enableTopToolbar: true,
    enableBottomToolbar: hasPermission,
    enableColumnDragging: hasPermission,
    enableColumnOrdering: hasPermission,
    enableColumnResizing: hasPermission,
    enableColumnFilterModes: hasPermission,
    enableSorting: hasPermission,
    enableFilters: true,
    manualFiltering: true,
    enablePinning: false,
    enableHiding: hasPermission,
    enableColumnActions: hasPermission,
    enableRowActions: false,
    enablePagination: false,
    enableFilterMatchHighlighting: hasPermission,
    enableGlobalFilter: true,
    enableColumnFilters: true,
    onSortingChange: (values) => handleTableChange(values, setSorting),
    onColumnOrderChange: (values) => handleTableChange(values, setColumnOrder),
    onColumnSizingChange: (values) =>
      handleTableChange(values, setColumnSizing),
    onColumnVisibilityChange: (values) =>
      handleTableChange(values, setColumnVisibility),
    onColumnFiltersChange: (updater) => {
      const newFilters =
        typeof updater === 'function' ? updater(columnFilters) : updater;
      setColumnFilters(newFilters);
    },
    onColumnFilterFnsChange: (values) =>
      handleTableChange(values, setColumnFilterFns),
    onGroupingChange: (values) => handleTableChange(values, setGrouping),
    onGlobalFilterChange: setSelectedGlobalSearchText,
    rowCount: totalCount,
    state: {
      grouping,
      columnFilters,
      columnFilterFns,
      sorting,
      columnOrder,
      columnSizing,
      columnVisibility,
      density: 'compact',
      isLoading,
      showAlertBanner:
        isError || !!validateFilters(columnFilters, columns, columnTypes ?? {}),
      showColumnFilters: hasPermission,
      showGlobalFilter: hasPermission,
      showSkeletons: hasPermission && isLoadingDetails,
      showProgressBars: isFetching,
      showLoadingOverlay: isLoading || isFetching,
      isSaving: !!selectedFilter,
      globalFilter: selectedGlobalSearchText,
    },
    initialState: defaultTableState,
    muiTableBodyRowProps: { hover: hasPermission },
    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      style: {
        zIndex: table.getState().isFullScreen ? 1201 : undefined,
      },
    }),
    muiTableContainerProps: {
      onCopy: (e: SyntheticEvent) => e.preventDefault(),
      onScroll: handleScroll,
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        WebkitFilter: hasPermission ? 'unset' : 'blur(5px)',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
        userSelect: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        'o-user-select': 'none',
        '&::selection, &::-moz-selection': {
          background: 'none',
        },
      }),
    },
    muiExpandButtonProps: {
      disabled: !hasPermission,
    },
    muiTableHeadCellProps: {
      sx: {
        justifyContent: 'center',
        minHeight: '65px',
      },
    },
    muiTopToolbarProps: ({ table }) => ({
      'aria-disabled': !hasPermission,
      sx: {
        padding: '0 16px',
        backgroundColor: '#FAFAFA',
        boxShadow: '0px 5px 6px 0px rgba(229, 229, 229, 0.50)',
        borderRadius: '3px 3px 0px 0px',
        borderTop: '1px solid #E0E0E0',
        borderRight: '1px solid #E0E0E0',
        borderLeft: '1px solid #E0E0E0',
        '& > .MuiBox-root': {
          flexDirection: 'row-reverse',
          justifyContent: table.getState().isFullScreen
            ? 'flex-end'
            : 'justify-content',
          alignItems: 'center',
          '& .MuiCollapse-root:first-child': {
            display: 'none',
          },
        },
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarInternal
        table={table}
        market={state.market?.toUpperCase()}
        indicator={state.indicator}
        selectedFilter={selectedFilter}
        handleSelectFilter={handleSelectFilter}
        handleRemoveSelectedFilter={resetTableToDefault}
        type={state.mode}
        disabled={!hasPermission}
      />
    ),
    renderTopToolbarCustomActions: ({ table }) =>
      !table.getState().isFullScreen && (
        <CustomTooltip
          disableFocusListener
          disableTouchListener={
            auth?.user?.subscription?.plan?.permissions
              ?.operacoes_visao_avancada
          }
          disableHoverListener={
            auth?.user?.subscription?.plan?.permissions
              ?.operacoes_visao_avancada
          }
          arrow
          title={`A visão avançada só está disponível para os planos ${upgradedPlanByPermission('operacoes_visao_avancada')}`}
          placement="top-start">
          <Box display="flex" alignItems="center">
            <CustomSwitch
              color="secondary"
              checked={state.mode === 'advanced'}
              onChange={(e: any) => {
                handleSelectView(e.target.checked ? 'advanced' : 'basic');
              }}
              inputProps={{ 'aria-label': 'trocar tipo de exibição' }}
              disabled={
                !auth?.user?.subscription?.plan?.permissions
                  ?.operacoes_visao_avancada
              }
            />
            <Typography
              variant="caption"
              style={{ color: '#4F4F4F', fontWeight: 600, fontSize: 14 }}>
              Visão avançada
            </Typography>
          </Box>
        </CustomTooltip>
      ),
    muiToolbarAlertBannerProps: validateFilters(
      columnFilters,
      columns,
      columnTypes ?? {},
    )
      ? {
          color: 'warning',
          children: validateFilters(columnFilters, columns, columnTypes ?? {}),
        }
      : operationsData.length === 0 && columnFilters.length > 0
        ? {
            color: 'info',
            children: 'Nenhum resultado encontrado para os filtros aplicados.',
          }
        : undefined,
    muiLinearProgressProps: {
      value: progress,
      variant: 'determinate',
    },
    // memoMode: 'cells',
    enableRowNumbers: true,
    rowNumberDisplayMode: 'original',
    columnResizeMode: 'onEnd',
    muiBottomToolbarProps: {
      sx: {
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        minHeight: 'unset',
      },
    },
    positionGlobalFilter: 'right',
    muiSearchTextFieldProps: {
      sx: {
        border: 'none',
        padding: '8px',
        '& .MuiInputBase-root': { background: 'transparent' },
        '& .MuiInputBase-input': {
          '&::placeholder': {
            fontSize: '14px',
            color: '#6D6E71',
            fontWeight: 500,
          },
          '&:focus': {
            borderBottom: '2px solid #FF8211',
          },
          borderBottom: '1px solid #E4E5E9',
          fontSize: '14px',
          padding: '0 4px',
        },
        '.MuiSvgIcon-root': { color: '#FF8211', width: '20px', height: '20px' },
        '.MuiInputAdornment-root': { display: 'none' },
      },
      placeholder: 'Buscar',
      variant: 'standard',
    },
    renderBottomToolbarCustomActions: () => (
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            p: '4px',
            width: '100%',
            justifyContent: totalCount ? 'space-between' : 'flex-end',
          }}>
          {!isFetching && totalCount > 0 && (
            <Typography
              sx={{
                fontSize: 12,
                color: '#4F4F4F',
              }}>
              {`Exibindo ${totalFetched} de ${totalCount}`}
            </Typography>
          )}
          {operationInfo?.updatedAt && (
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {`Atualizado em ${format(
                parseISO(operationInfo?.updatedAt),
                'dd/MM/yyyy',
              )}`}
            </Typography>
          )}
        </Box>
      </Box>
    ),
  });

  useEffect(() => {
    setIsLoading(isLoadingColumns || isLoadingFavorites || !columns?.length);
    if (!hasPermission) {
      setIsLoading(false);
    }
  }, [isLoadingColumns, isLoadingFavorites, columns]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const shared = state.shared
      ? JSON.parse(base64url.decode(state.shared))
      : undefined;
    if (shared) {
      const newStateFromShared = (shared?.[state.market] ||
        {}) as unknown as Partial<MRT_TableState<any>>;
      injectState(newStateFromShared);
      setState({ ...state, shared: null });
    }
  }, [state.shared, isLoading]);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch {
      /* */
    }
  }, [sorting, columnFilters, selectedGlobalSearchText]);

  useEffect(() => {
    if (hasPermission) {
      resetTableToDefault();

      if (typeof window !== 'undefined') {
        setOperationsData([]);
        void refetchColumns();
        void refetchFavorites();
      }
    }
  }, [state.market, hasPermission]);

  useEffect(() => {
    if (isLoading) return;
    setState({ ...state, shared: null });
  }, [state.market, isLoading]);

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const columnHeaders: { [p: string]: string } = columns
      .filter((column) => column.accessorKey !== 'favorite')
      .reduce(
        (acc, c) => ({ ...acc, [String(c.accessorKey)]: `"${c.header}"` }),
        {},
      );
    const parsedRows = rows.map((row) =>
      Object.keys(columnHeaders).reduce(
        (acc, key) => ({
          ...acc,
          [key]: parseValueToCSVBasicView(key, row.original[key]),
        }),
        {},
      ),
    );

    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      filename: `operations_${state.market}`.toLowerCase(),
      decimalSeparator: ',',
      headers: Object.values(columnHeaders),
    } as Options;

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(parsedRows);
  };

  if (isLoading) {
    return <CircularProgress variant="determinate" value={progress} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box>
        <Box
          p={2}
          pr={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end">
          <Box>
            <ModalButton onClick={handleModalOpen} disabled={!hasPermission} />
          </Box>
          <Export
            handleExport={() => {
              if (tableInstance) {
                handleExportRows(tableInstance.getRowModel().rows);
              }
            }}
            handlePrint={handlePrint}
            permission={'operacoes_exportar'}
            hasData={!!operationsData?.length}
          />
          <ShareButton
            getUrlToShare={getUrlToShare}
            disabled={!hasPermission}
          />
        </Box>
        <MaterialTable table={tableInstance} />
      </Box>
      {!hasPermission && contentPayWallBanner?.operationBasicView && (
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            top: '40%',
            left: 0,
            px: '10px',
          }}>
          <CardDemonstration
            title={contentPayWallBanner?.operationBasicView?.title}
            subTitle={contentPayWallBanner?.operationBasicView?.subTitle}
            labelButton={contentPayWallBanner?.operationBasicView?.labelButton}
            url={contentPayWallBanner?.operationBasicView?.url}
          />
        </Box>
      )}
      <SaveFilterModal
        isOpen={openSaveFilterModal}
        handleClose={() => setOpenSaveFilterModal(false)}
        filterData={{
          type: state.mode,
          indicator: state.indicator,
          market: state.market,
          data: {
            grouping,
            columnFilterFns,
            columnFilters,
            sorting,
            columnOrder,
            columnSizing,
            columnVisibility,
          },
        }}
      />
    </Box>
  );
}

export default BasicView;
