// Material ui componentes
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';

// Local components
import OperationCRALiabilityData from './OperationCRALiabilityData';
import OperationCRALiabilityTitle from './OperationCRALiabilityTitle';
import { PanelLogin } from '../../../../components/PanelLogin';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_CRA_LIABILITIES_BY_OPERATION_HASH_ID } from '../../../../graphql/queries';

export default function OperationCRALiability({ hash }) {
  const { auth } = useAuthContext();
  const [liabilities, setLiabilities] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const { loading } = useQuery(GET_CRA_LIABILITIES_BY_OPERATION_HASH_ID, {
    variables: { hash },
    client: ipanema,
    skip: !auth?.user?.subscription?.plan?.permissions['operacoes_cra_passivo'],
    onCompleted: ({
      getCraLiabilitiesByOperationHashId: { titles, emissions },
    }) => {
      setLiabilities(titles);
      setEmissions(emissions);
    },
  });

  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cra_passivo'] ? (
        <Box p={2}>
          <PanelLogin />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRALiabilityData
              data={liabilities}
              emission={emissions}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <OperationCRALiabilityTitle data={liabilities} loading={loading} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
