import React from 'react';

import SuccessIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Getters, setBannerMessage } from 'src/store/display';
import { v4 as uuid } from 'uuid';

import Link from './Link';
import * as S from './styles';

const HeaderBannerMessage = () => {
  const { auth } = useAuthContext();
  const dispatch = useDispatch();

  const bannerMessage = useSelector(Getters.bannerMessage);

  const handleClose = () => {
    dispatch(
      setBannerMessage({ message: '', type: 'success', autoclose: true }),
    );
  };

  const parseMessage = (text: string) => {
    const replacedText = text.replace(
      /<redirect .+>.+<\/redirect>/g,
      (word) => {
        return word.replace(/ /g, '§');
      },
    );
    const slicedText = replacedText
      .split(' ')
      .map((word) => `${word.replace(/§/g, ' ')} `);

    return slicedText.map((word) => {
      const matchedTag = word.match(/<redirect path="(.+)">(.+)<\/redirect>/);

      if (matchedTag) {
        const [, path, label] = matchedTag;

        return (
          <Link path={path} key={uuid()}>
            {label}
          </Link>
        );
      }

      return word;
    });
  };

  const resetDispatch = debounce(() => {
    dispatch(
      setBannerMessage({ message: '', type: 'success', autoclose: true }),
    );
  }, 5000);

  React.useEffect(() => {
    if (bannerMessage.message && bannerMessage.autoclose) {
      resetDispatch();
    }
  }, [bannerMessage]);

  React.useEffect(() => {
    if (!auth.isLoggedIn) {
      dispatch(
        setBannerMessage({ message: '', type: 'success', autoclose: true }),
      );
    }
  }, [auth.isLoggedIn]);

  return (
    <>
      {bannerMessage.message ? (
        <S.Banner type={bannerMessage.type}>
          <S.MessageWrapper>
            <S.Message>
              {bannerMessage.type === 'success' ? (
                <SuccessIcon />
              ) : (
                <ErrorIcon />
              )}
              <span>{parseMessage(bannerMessage.message)}</span>
              {bannerMessage.hasAction && (
                <S.Link onClick={bannerMessage.actionCallback}>
                  {bannerMessage.actionText}
                </S.Link>
              )}
            </S.Message>
            <CloseIcon onClick={handleClose} />
          </S.MessageWrapper>
        </S.Banner>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderBannerMessage;
