import qs from 'qs';
import { API } from 'src/types/api';

import { api } from '../../api/api';

export const getAPIs = async ({
  markets,
  plans,
}: {
  markets: number[];
  plans: number[];
}): Promise<API[]> => {
  const query = qs.stringify(
    {
      filters: {
        market: markets,
        plans,
        publishedAt: {
          $notNull: true,
        },
      },
      sort: ['name:asc'],
      populate: ['market', 'plans'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/vendor?${query}`);
  return data;
};

export const getAPIDetails = async ({
  endpoint,
}: {
  endpoint: string;
}): Promise<API> => {
  const query = qs.stringify(
    {
      populate: {
        market: true,
        plans: true,
        docs: {
          populate: {
            chapters: {
              populate: ['sections'],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/vendor/${endpoint}/docs?${query}`);
  return data;
};

export const getAPIOpenApiSpecs = async (endpoint: string) => {
  const { data } = await api.get(`/vendor/${endpoint}/openapi`);
  return data;
};
