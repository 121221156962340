import { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import MarketsNavigation from 'src/components/UI/MarketsNavigation/MarketsNavigation';
import { Institution } from 'src/types/institution';
import { useQueryParams } from 'src/utils/hooks';

import Charts from './Charts';
import Tables from './Tables';

export const Operations = ({ institution }: { institution: Institution }) => {
  const history = useHistory();
  const [{ query }, setQuery] = useQueryParams<{
    market: 'FIDC' | 'FII' | 'CRA' | 'CRI';
  }>({ market: 'FIDC' });
  const [performance, setPerformance] = useState('');

  const handleSelectMarket = (market: string) => {
    setQuery({
      ...query,
      market,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        marginTop: 5,
        padding: '36px 42px',
        [theme.breakpoints.down('sm')]: {
          padding: '36px 16px',
        },
      })}>
      <MarketsNavigation
        value={query.market}
        handleSelectMarket={handleSelectMarket}
        markets={['FIDC', 'FII', 'CRI', 'CRA']}
      />
      <Box
        sx={{
          marginTop: '64px',
        }}>
        <Tables market={query.market} institution={institution} />
        <Charts
          market={query.market}
          institution={institution}
          performance={performance}
          setPerformance={setPerformance}
        />
        <Box
          sx={(theme) => ({
            backgroundColor: '#FAFAFA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 99,
            padding: 3,
            borderRadius: '5px',
            border: '1px solid #E0E0E0',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              height: 'auto',
            },
          })}>
          <Typography
            sx={(theme) => ({
              width: 438,
              textAlign: 'center',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                marginBottom: 2,
              },
            })}>
            Faça suas próprias análises com o auxílio da Uqbar. Habilite a visão
            avançada das operações.
          </Typography>
          <Button
            sx={{ fontSize: '0.875rem', fontWeight: 700 }}
            variant="contained"
            onClick={() =>
              history.push(`/operacoes?market=${query.market}&mode=advanced`)
            }>
            Visão avançada de operações
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
