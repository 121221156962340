import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import styled, { css } from 'styled-components';

interface MarketSelectorProps {
  active?: boolean;
  expanded?: boolean;
}

interface NavigationSectionProps {
  active?: boolean;
}

export const Hamburguer = styled(MenuIcon)`
  display: none !important;
  position: fixed;
  top: 0;
  left: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.25rem;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50%;
  background-color: rgba(3, 3, 3, 0.45);
  cursor: pointer;
  color: #ff8211;
  z-index: 9999;

  @media screen and (max-width: 1366px) {
    display: inherit !important;
  }
`;

export const Close = styled(CloseIcon)`
  display: none !important;
  position: absolute;
  top: 0.25rem;
  left: 0.5rem;
  cursor: pointer;
  color: white;

  @media screen and (max-width: 1366px) {
    display: inherit !important;
  }
`;

export const Container = styled.div`
  max-width: calc(160px + 1rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(3, 3, 3, 0.45);
  border-radius: 16px;
  padding: 1rem 0.25rem;
  position: fixed;
  left: 3.25rem;
  z-index: 9999;

  @media screen and (max-width: 1366px) {
    padding-top: 2rem;
    max-width: inherit;
    height: 100%;
    left: 0;
    border-radius: 0;
  }
`;

export const MarketSelectorText = styled.h2`
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: #ff8211;
`;

export const ReadingSection = styled.p`
  font-size: 0.8rem;
  color: #cbcbcb;
  margin: 0.25rem 0 0;
`;

export const MarketSelectorIcon = styled.img`
  width: 2rem;
  height: 2rem;
  display: none;
`;

export const MarketSelector = styled.div<MarketSelectorProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: ${(props) => (props.active ? '#ff8211' : 'white')};
  border-radius: 20px;
  width: ${(props) => (props.expanded ? '140px' : '108px')};
  height: ${(props) => (props.expanded ? '140px' : '108px')};
  margin: ${(props) => (props.expanded ? '0.25rem' : '0.25rem 1rem')};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);

  ${MarketSelectorText} {
    display: ${(props) => (props.active ? 'none' : 'block')};
  }

  ${MarketSelectorIcon} {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  &:hover {
    ${(props) => {
      if (props.active)
        return css`
          background-color: white;
          ${MarketSelectorIcon} {
            display: none;
          }
          ${MarketSelectorText} {
            display: block;
          }
        `;
      else
        return css`
          background-color: #ff8211;
          ${MarketSelectorIcon} {
            filter: brightness(0) invert(1);
            display: block;
          }
          ${MarketSelectorText} {
            display: none;
          }
        `;
    }}
  }
`;

export const MarketSelectorList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationSections = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 1rem 0.5rem;
  background-color: rgba(3, 3, 3, 0.15);
`;

export const NavigationSection = styled.a.attrs<NavigationSectionProps>(
  ({ active }) => ({
    className: active ? 'route-active' : '',
  }),
)<NavigationSectionProps>`
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  padding: 0.75rem 1rem;
  color: white;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: white;
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
  }

  &.route-active,
  &:active {
    background-color: #ff8211;
    font-weight: 500;
    color: white;
  }
`;
