import theme from 'src/theme';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 2rem calc(80px + 4rem) 2rem calc(160px + 6rem);
  position: relative;

  @media screen and (max-width: 1366px) {
    padding-left: 8rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 2rem;
  }
`;

export const Texts = styled.div`
  margin-left: 4rem;
  margin-bottom: 4rem;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 0;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 50%;
  right: 0;
  top: 8rem;
  border-top-left-radius: 50%;
  background-color: #ff8211;
  height: calc(100% - 8rem);

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    width: 70%;
    border-top-left-radius: 60%;
  }

  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    border-top-left-radius: 90%;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;
