import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AddCircle, Cancel } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import lodash, { isEmpty } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AccordionControlled from 'src/components/AccordionControlled';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import InstitutionAutocomplete from 'src/components/FormsUI/InstitutionAutocomplete';
import PhoneFormat from 'src/components/FormsUI/PhoneFormat';
import RoleSelectField from 'src/components/FormsUI/RoleSelectField';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { If } from 'src/components/If';
import { useAuthContext } from 'src/context/AuthContextProvider';
import {
  createProfessionalRequest,
  getMarkets,
  getSegments,
} from 'src/services/institution';
import {
  createProfessional,
  getResponsibilities,
  updateProfessional,
} from 'src/services/professional';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { Market } from 'src/types/market';
import {
  Professional,
  RelatedInstitutionRequest,
} from 'src/types/professional';
import { Segment } from 'src/types/segments';
import { Media } from 'src/types/strapi';
import {
  facebookRegex,
  instagramRegex,
  linkedinRegex,
  twitterRegex,
} from 'src/utils/regex';
import * as Yup from 'yup';

import Certifications from './Certifications';
import EditSlug from './EditSlug';
import Experiences from './Experiences';
import Header from './Header';
import ProfessionalRelatedOperations from './RelatedOperations';
import * as S from './style';
import { BRAZIL_STATES } from '../../../../../utils/geo';
import ConfirmInstitutionRequestDialog from '../ConfirmInstitutionRequestDialog';

const MAX_FILE_SIZE = 2000000;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const PROFESSIONAL_PERMITED_MARKETS = ['CR', 'CRA', 'CRI', 'FII', 'FIDC'];

type Props = {
  data: Professional | undefined;
  professionalRefetch: () => void;
  institution: Institution | undefined;
};

const reduceRelatedInstitution = (
  { id, razaoSocial, status, fantasyName }: any,
  isOther: boolean,
) => ({
  id,
  razaoSocial,
  fantasyName,
  status,
  isOther,
});

export function ProfessionalForm({
  data,
  professionalRefetch,
  institution,
}: Props) {
  let relatedInstitutions: RelatedInstitutionRequest = {
    id: null,
    razaoSocial: '',
    fantasyName: '',
    status: undefined,
  };
  if (data?.relatedInstitutions) {
    relatedInstitutions = reduceRelatedInstitution(
      data.relatedInstitutions,
      false,
    );
  } else if (
    data?.lastRequestApproval?.id &&
    data?.institutionName ===
      data?.lastRequestApproval?.institution?.fantasyName
  ) {
    relatedInstitutions = {
      ...reduceRelatedInstitution(
        data?.lastRequestApproval?.institution,
        false,
      ),
      status: data?.lastRequestApproval?.status,
    };
  } else if (institution?.id) {
    relatedInstitutions = reduceRelatedInstitution(institution, false);
  } else if (data?.institutionName) {
    relatedInstitutions = {
      id: null,
      razaoSocial: '',
      fantasyName: 'Outra',
      isOther: true,
    };
  }
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useAuthContext();
  const formRef = useRef<null | HTMLInputElement>(null);
  const profileRef = useRef<null | HTMLDivElement>(null);
  const relatedInstitutionRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);
  const experienceRef = useRef<null | HTMLDivElement>(null);
  const certificationRef = useRef<null | HTMLDivElement>(null);
  const relatedOperationRef = useRef<null | HTMLDivElement>(null);
  const [segments, setSegment] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [image, setImage] = useState<Media | null>(data?.photo ?? null);
  const [cover, setCover] = useState<Media | null>(data?.coverImage ?? null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string | undefined>(
    data?.professionalSegments?.join(', '),
  );
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const [triggerExit, setTriggerExit] = useState({
    shouldLeave: false,
    path: '',
  });
  const [hasTouched, setHasTouched] = useState(false);
  const [selectedInstitution, setSelectedInstitution] =
    useState<any>(relatedInstitutions);
  const [institutionName, setInstitutionName] = useState<string | undefined>(
    relatedInstitutions.isOther
      ? 'Outra'
      : relatedInstitutions.fantasyName ?? auth?.user?.company,
  );
  const [profileAccordionOpen, setProfileAccordionOpen] = useState(true);
  const [relatedInstitutionAccordionOpen, setRelatedInstitutionAccordionOpen] =
    useState(false);
  const [contactAccordionOpen, setContactAccordionOpen] = useState(false);
  const [slugAccordionOpen, setSlugAccordionOpen] = useState(false);
  const [experienceAccordionOpen, setExperienceAccordionOpen] = useState(false);
  const [certificationAccordionOpen, setCertificationAccordionOpen] =
    useState(false);
  const [relatedOperationsAccordionOpen, setRelatedOperationsAccordionOpen] =
    useState(false);
  const [openConfirmInstitutionDialog, setOpenConfirmInstitutionDialog] =
    useState(false);
  const [openExperienceForm, setOpenExperienceForm] = useState(false);
  const [openCertificationForm, setOpenCertificationForm] = useState(false);
  const [openRelatedOperationForm, setOpenRelatedOperationForm] =
    useState(false);

  const handleGoToIntendedPage = useCallback(
    (location: string) => history.push(location),
    [history],
  );

  const handleUnload = (event: any) => {
    if (hasTouched) {
      setOpenLeaveDialog(true);
      setTriggerExit((prevState) => ({ ...prevState, path: event.pathname }));
      if (triggerExit.shouldLeave) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setImage(data?.photo ?? null);
    setCover(data?.coverImage ?? null);
  }, [data]);

  useEffect(() => {
    setSelectedInstitution(relatedInstitutions);
  }, [relatedInstitutions?.isOther]);

  useEffect(() => {
    let unblock: any;
    if (hasTouched) {
      if (triggerExit.shouldLeave) {
        handleGoToIntendedPage(triggerExit.path);
      }
      if (!unblock) {
        window.addEventListener('beforeunload', (event) => {
          event.preventDefault();
          event.returnValue = '';
        });
        unblock = history.block((location): any => handleUnload(location));
      }
    } else {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    }

    return () => {
      if (unblock) {
        window.removeEventListener('beforeunload', () => {});
        unblock();
      }
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.shouldLeave,
    triggerExit.path,
    hasTouched,
  ]);

  const createProfessionalLinkMutation = useMutation(
    createProfessionalRequest,
    {
      onSuccess: () => {
        professionalRefetch();
      },
    },
  );

  const createProfessionalMutation = useMutation(createProfessional, {
    onSuccess: () => {
      dispatch(
        setBannerMessage({
          message: 'Perfil profissional criado com sucesso',
          type: 'success',
          autoclose: true,
        }),
      );
      if (institution?.isOwner) {
        createProfessionalLinkMutation.mutate(institution.id);
      } else if (selectedInstitution?.id && !selectedInstitution.status) {
        createProfessionalLinkMutation.mutate(selectedInstitution.id);
      } else {
        professionalRefetch();
      }
    },
    onError: () => {
      dispatch(
        setBannerMessage({
          message: 'Ocorreu um erro. Tente novamente',
          type: 'error',
          autoclose: true,
        }),
      );
    },
  });

  const updateProfessionalMutation = useMutation(updateProfessional, {
    onError: (error) => {
      setHasTouched(false);
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar os dados do profissional',
          }),
        );
      }
    },
    onSuccess: (result) => {
      setHasTouched(false);
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Perfil profissional atualizado',
          }),
        );
        if (
          selectedInstitution?.id &&
          selectedInstitution?.id !== relatedInstitutions?.id &&
          !institution?.isOwner
        ) {
          createProfessionalLinkMutation.mutate(selectedInstitution.id);
        } else {
          professionalRefetch();
        }
      }
    },
  });

  const { isLoading: responsibilityLoading, data: responsibilities } = useQuery(
    'responsibilities',
    getResponsibilities,
  );

  const { isLoading: segmentsLoading } = useQuery('segments', getSegments, {
    onSuccess: ({ data }) => {
      setSegment(data);
    },
  });

  const { isLoading: marketsLoading } = useQuery('markets', getMarkets, {
    onSuccess: ({ data }) => {
      setMarkets(
        data.filter((market: any) =>
          PROFESSIONAL_PERMITED_MARKETS.includes(market.name),
        ),
      );
    },
  });

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    surname: Yup.string().required('Campo obrigatório').nullable(),
    role: Yup.string().required('Campo obrigatório'),
    location: Yup.string().required('Campo obrigatório'),
    institutionStartDate: Yup.string().required('Campo obrigatório').nullable(),
    emails: Yup.array(
      Yup.object({
        email: Yup.string().email('Digite um e-mail válido'),
      }),
    ).max(3),
    phones: Yup.array(
      Yup.object({
        number: Yup.string().test(
          'phoneLen',
          'O telefone é inválido',
          (val) => !val || val?.replace(/\D/g, '')?.length >= 10,
        ),
      }),
    ),
    linkedin: Yup.object({
      url: Yup.string().matches(linkedinRegex, 'Url inválida'),
    }),
    instagram: Yup.object({
      url: Yup.string().matches(instagramRegex, 'Url inválida'),
    }),
    facebook: Yup.object({
      url: Yup.string().matches(facebookRegex, 'Url inválida'),
    }),
    twitter: Yup.object({
      url: Yup.string().matches(twitterRegex, 'Url inválida'),
    }),
    markets: Yup.array().min(1, 'Selecione um mercado de atuação'),
    professionalSegments: Yup.array().min(
      1,
      'Selecione um segmento de atuação',
    ),
    professionalSector: Yup.array().max(10, 'Máximo de áreas selecionadas'),
    relatedInstitutions: Yup.object({
      fantasyName: Yup.string().test(
        'required',
        'Campo obrigatório',
        (value) => {
          if (value || formik.values.institutionName) {
            return true;
          }
          return false;
        },
      ),
    }),
    institutionName:
      institutionName === 'Outra'
        ? Yup.string().test('required', 'Campo obrigatório', (value) => {
            if (value) {
              return true;
            }
            return false;
          })
        : Yup.string().optional(),
    photo: Yup.mixed()
      .test('required', 'Foto obrigatória', (value) => {
        if (value || image) {
          return true;
        }
        return false;
      })
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (image) {
            return true;
          }
          return !value || (value?.type && value?.size <= MAX_FILE_SIZE);
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (image) {
          return true;
        }
        return (
          !value || (value?.type && SUPPORTED_FORMATS.includes(value.type))
        );
      }),
    coverImage: Yup.mixed()
      .test(
        'fileSize',
        'A imagem da capa excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (cover || !value) {
            return true;
          }
          return value?.type && value?.size <= MAX_FILE_SIZE;
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (cover || !value) {
          return true;
        }
        return value && SUPPORTED_FORMATS.includes(value.type);
      }),
    experiences: Yup.array(
      Yup.object({
        companyName: Yup.string().required('Campo obrigatório'),
        professionalTitle: Yup.string().required('Campo obrigatório'),
        startDate: Yup.string().required('Campo obrigatório').nullable(),
        endDate: Yup.string().required('Campo obrigatório').nullable(),
      }),
    ),
    certifications: Yup.array(
      Yup.object({
        name: Yup.string().required('Campo obrigatório'),
        issuer: Yup.string().required('Campo obrigatório'),
        issuedAt: Yup.string().required('Campo obrigatório').nullable(),
      }),
    ),
    relatedOperations: Yup.array(
      Yup.object({
        operation: Yup.object({
          id: Yup.string().required('Campo obrigatório'),
        }),
        role: Yup.string().required('Campo obrigatório'),
      }),
    ),
  });

  const getProgress = (values: any) => {
    const fields = [
      'photo',
      'coverImage',
      'name',
      'surname',
      'role',
      'location',
      'institutionStartDate',
      'markets[0]',
      'professionalSegments[0]',
      'profileText',
      'professionalTitle',
      'facebook.url',
      'linkedin.url',
      'instagram.url',
      'twitter.url',
      'emails[0].email',
      'phones[0].number',
      'relatedInstitutions.fantasyName',
    ];
    let filledTotal = fields.reduce(
      (acc, field) => (acc += lodash.get(values, field) ? 1 : 0),
      0,
    );
    if (!values.photo && image) {
      filledTotal++;
    }
    if (!values.coverImage && cover) {
      filledTotal++;
    }
    return Math.ceil((filledTotal / fields.length) * 100);
  };

  const handleSubmit = (values: any) => {
    const formData = new FormData();
    const {
      photo,
      coverImage,
      emails,
      phones,
      institutionName,
      ...professionalData
    } = values;

    if (photo) {
      formData.append('files.photo', photo);
    }

    if (coverImage) {
      formData.append('files.coverImage', coverImage);
    }

    const cleanEmails = emails?.filter((value: any) => !!value.email);
    const cleanPhones = phones?.filter((value: any) => !!value.number);

    if (data) {
      formData.append(
        'data',
        JSON.stringify({
          ...professionalData,
          id: data?.id,
          cpf: auth?.user?.document,
          emails: cleanEmails,
          phones: cleanPhones,
          lastRequestApproval:
            selectedInstitution?.id &&
            relatedInstitutions?.id !== selectedInstitution?.id
              ? null
              : undefined,
          relatedInstitutions:
            (selectedInstitution?.id &&
              relatedInstitutions?.id !== selectedInstitution?.id) ||
            selectedInstitution?.isOther
              ? null
              : undefined,
          institutionName: selectedInstitution?.isOther
            ? institutionName
            : selectedInstitution?.fantasyName,
          ...(!image && !photo ? { photo: null } : { photo: image?.id }),
          ...(!cover && !coverImage
            ? { coverImage: null }
            : { coverImage: cover?.id }),
        }),
      );

      updateProfessionalMutation.mutateAsync({
        id: data.id,
        values: formData,
      });
    } else {
      formData.append(
        'data',
        JSON.stringify({
          ...professionalData,
          cpf: auth?.user?.document,
          owner: auth?.user?.id,
          creationDate: new Date(),
          emails: cleanEmails,
          phones: cleanPhones,
          lastRequestApproval: null,
          relatedInstitutions: null,
          institutionName: selectedInstitution?.isOther
            ? institutionName
            : selectedInstitution?.fantasyName,
          ...(!image && !photo ? { photo: null } : { photo: image?.id }),
          ...(!cover && !coverImage
            ? { coverImage: null }
            : { coverImage: cover?.id }),
        }),
      );
      createProfessionalMutation.mutateAsync(formData);
    }
  };

  const formik = useFormik({
    initialValues: {
      photo: null,
      coverImage: null,
      name: data?.name ?? auth?.user?.name,
      surname: data?.surname ?? auth?.user?.otherName,
      role: data?.role ?? auth?.user?.companyRole ?? '',
      location: data?.location ?? '',
      institutionStartDate: data?.institutionStartDate
        ? format(parseISO(data.institutionStartDate), 'yyyy-MM-dd')
        : undefined,
      markets: data?.markets?.map(({ id }) => id) ?? [],
      professionalSegments:
        data?.professionalSegments?.map(({ id }) => id) ?? [],
      profileText: data?.profileText ?? '',
      professionalTitle: data?.professionalTitle ?? '',
      facebook: {
        id: data?.facebook?.id ?? undefined,
        url: data?.facebook?.url ?? '',
      },
      twitter: {
        id: data?.twitter?.id ?? undefined,
        url: data?.twitter?.url ?? '',
      },
      linkedin: {
        id: data?.linkedin?.id ?? undefined,
        url: data?.linkedin?.url ?? '',
      },
      instagram: {
        id: data?.instagram?.id ?? undefined,
        url: data?.instagram?.url ?? '',
      },
      emails: data?.emails?.length
        ? data?.emails
        : [
            {
              email: '',
            },
          ],
      phones: data?.phones?.length
        ? data?.phones
        : [
            {
              number: '',
            },
          ],
      relatedInstitutions: {
        id: relatedInstitutions?.id ?? undefined,
        fantasyName:
          relatedInstitutions?.fantasyName ?? auth?.user?.company ?? '',
      },
      experiences: data?.experiences ?? [],
      certifications: data?.certifications ?? [],
      relatedOperations: data?.relatedOperations ?? [],
      slug: data?.slug
        ? data.slug
        : auth.user?.type === 'private_person'
          ? lodash.kebabCase(
              `${auth.user?.name} ${auth.user?.otherName} ${auth.user?.id}`,
            )
          : lodash.kebabCase(`${auth.user?.name} ${auth.user?.id}`),
      institutionName: data?.institutionName ?? '',
    },
    validationSchema: FORM_VALIDATION,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const headerRequiredFields = ['photo'];

  const profileRequiredFields = [
    'name',
    'surname',
    'professionalSegments',
    'markets',
    'location',
  ];

  const relatedInstitutionRequiredFields = [
    'relatedInstitutions',
    'role',
    'institutionStartDate',
  ];

  const contactRequiredFields = [
    'emails',
    'phones',
    'linkedin',
    'twitter',
    'instagram',
    'facebook',
  ];

  const scrollTo = (key: string) => {
    switch (key) {
      case 'photo':
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case 'profile':
        profileRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'contact':
        contactRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'experience':
        experienceRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'certification':
        certificationRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'relatedOperations':
        relatedOperationRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 'relatedInstitution':
        relatedInstitutionRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setImage(data?.photo ?? null);
  }, [data]);

  useEffect(() => {
    let shouldScrollTo = 'default';
    if (
      !isEmpty(formik.errors) ||
      !createProfessionalMutation.isLoading ||
      !updateProfessionalMutation.isLoading
    ) {
      if (
        Object.keys(formik.errors).some((item) =>
          headerRequiredFields.includes(item),
        )
      ) {
        shouldScrollTo = 'photo';
      }
      if (
        Object.keys(formik.errors).some((item) =>
          profileRequiredFields.includes(item),
        )
      ) {
        setProfileAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'profile';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          relatedInstitutionRequiredFields.includes(item),
        )
      ) {
        setRelatedInstitutionAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'relatedInstitution';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          contactRequiredFields.includes(item),
        )
      ) {
        setContactAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'contact';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          ['experiences'].includes(item),
        )
      ) {
        setExperienceAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'experience';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          ['certifications'].includes(item),
        )
      ) {
        setCertificationAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'certification';
        }
      }
      if (
        Object.keys(formik.errors).some((item) =>
          ['relatedOperations'].includes(item),
        )
      ) {
        setRelatedOperationsAccordionOpen(true);
        if (shouldScrollTo === 'default') {
          shouldScrollTo = 'relatedOperations';
        }
      }
      scrollTo(shouldScrollTo);
    }
  }, [formik.errors]);

  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const progressValue = useMemo(
    () => getProgress(formik.initialValues),
    [formik.initialValues],
  );

  const getEmailsError = (
    fieldName: keyof { email: string },
    index: number,
  ) => {
    const error = formik.errors.emails?.[index] as
      | { email: string }
      | undefined;
    return error ? error[fieldName] : '';
  };

  const getPhoneError = (
    fieldName: keyof { number: string },
    index: number,
  ) => {
    const error = formik.errors.phones?.[index] as
      | { number: string }
      | undefined;
    return error ? error[fieldName] : '';
  };

  return (
    <section ref={formRef}>
      <TabContext value={tabValue}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          style={{ width: '100%' }}
          onChange={() => setHasTouched(true)}>
          <Header
            setValue={formik.setFieldValue}
            photoValue={formik.values.photo}
            coverValue={formik.values.coverImage}
            photoImage={image}
            coverImage={cover}
            setPhotoImage={setImage}
            setCoverImage={setCover}
            errors={formik.errors}
            touched={formik.touched}
            professional={data}
            progressValue={progressValue}
          />
          <If condition={auth?.user?.subscription?.plan?.type === 'free'}>
            <Box marginTop="24px">
              <Typography fontSize={14}>
                No plano gratuito os dados serão exibidos parcialmente em seu
                perfil profissional, para exibir todas as informações faça o
                upgrade do seu plano&nbsp;
                <Link component={RouterLink} to={'/planos'}>
                  aqui.
                </Link>
              </Typography>
            </Box>
          </If>
          <Box
            sx={(theme) => ({
              display: 'flex',
              marginTop: '64px',
              '& > div': { flex: '1 1 auto', padding: '8px 0 0 26px' },
              [theme.breakpoints.down('md')]: {
                display: 'block',
                marginTop: '54px',
                '& > div': { padding: '0' },
              },
            })}>
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              value={tabValue}
              onChange={handleTabChange}
              sx={(theme) => ({
                flex: '0 0 19% !important',
                padding: '0 26px 0 33px !important',
                borderRight: '1px solid #F1F1F1',
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& button': {
                  alignItems: 'flex-start',
                  fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
                  fontWeight: 600,
                  lineHeight: 0.8,
                  padding: '8px 0px',
                  marginBottom: '16px',
                  minHeight: 'unset',
                  textTransform: 'capitalize',
                  color: theme.palette.secondary.main,
                },
                '& .Mui-selected': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  marginBottom: '14px',
                  color: '#828282 !important',
                },
                [theme.breakpoints.down('md')]: {
                  borderRight: 'none',
                  padding: '0 !important',
                  '& button': {
                    alignItems: 'center',
                    fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
                    fontWeight: 400,
                    padding: '6px 0px 20px 0',
                    marginBottom: '0',
                    minHeight: 'unset',
                    textTransform: 'capitalize',
                  },
                  '& .Mui-selected': {
                    borderBottomWidth: '3px',
                    marginBottom: '0',
                    color: `${theme.palette.secondary.main} !important`,
                  },
                  '& .MuiTabs-flexContainer': {
                    placeContent: 'space-between',
                    marginBottom: '2rem',
                  },
                },
              })}>
              <Tab label="Perfil" value="1" />
            </Tabs>
            <TabPanel value="1">
              <AccordionControlled
                ref={profileRef}
                expanded={profileAccordionOpen}
                onChange={() =>
                  setProfileAccordionOpen((prevState) => !prevState)
                }
                title="Perfil profissional"
                defaultExpanded>
                <Grid
                  container
                  spacing={2}
                  sx={(theme) => ({ padding: theme.spacing(2) })}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    justifyContent="flex-start"
                    flexDirection="column">
                    <S.FieldTitle>Nome*</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="name"
                        onChange={formik.handleChange}
                        placeholder="Digite o nome"
                        value={formik.values.name}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    justifyContent="flex-start"
                    flexDirection="column">
                    <S.FieldTitle>Sobrenome*</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="surname"
                        placeholder="Digite o sobrenome"
                        onChange={formik.handleChange}
                        value={formik.values.surname}
                        error={
                          formik.touched.surname &&
                          Boolean(formik.errors.surname)
                        }
                        helperText={
                          formik.touched.surname && formik.errors.surname
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justifyContent="center"
                    flexDirection="column">
                    <S.FieldTitle>Título profissional</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="professionalTitle"
                        placeholder="Ex: ANALISTA FINANCEIRO | GERENTE | AUXILIAR ADMINISTRATIVO | ESPECIALISTA EM FIDC"
                        onChange={formik.handleChange}
                        value={formik.values.professionalTitle}
                        inputProps={{
                          maxLength: 70,
                        }}
                        error={
                          formik.touched.professionalTitle &&
                          Boolean(formik.errors.professionalTitle)
                        }
                        helperText={
                          formik.touched.professionalTitle &&
                          formik.errors.professionalTitle
                        }
                      />
                    </FormControl>
                  </Grid>
                  <TextAreaProfile
                    name="profileText"
                    label="Biografia resumida"
                    value={formik.values.profileText}
                    handleChange={formik.handleChange}
                    placeholder="Escreva um texto sobre você e suas habilidades profissionais"
                    variant="outlined"
                    maxCaracters={2660}
                  />
                  <Grid
                    xs={12}
                    md={12}
                    container
                    spacing={2}
                    item
                    sx={{
                      marginTop: '-32px',
                    }}>
                    <Grid item xs={12} md={6}>
                      <S.FieldTitle>Localização atual*</S.FieldTitle>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={
                          formik?.touched?.location &&
                          Boolean(formik?.errors?.location)
                        }>
                        <Select
                          name="location"
                          value={formik?.values?.location}
                          onChange={formik.handleChange}
                          displayEmpty
                          renderValue={(selected: any) => {
                            if (!selected) {
                              return (
                                <Typography sx={{ color: '#BDBDBD' }}>
                                  Selecione o estado
                                </Typography>
                              );
                            }
                            return [...BRAZIL_STATES.values()].find(
                              (name) => name === selected,
                            );
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 250,
                              },
                            },
                            MenuListProps: {
                              sx: (theme) => ({
                                maxHeight: 240,
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  height: 10,
                                  width: 8,
                                  WebkitAppearance: 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  borderRadius: 4,
                                  backgroundColor: theme.palette.grey['400'],
                                },
                              }),
                            },
                          }}>
                          {[...BRAZIL_STATES.values()].map(
                            (opt: string, index: number) => (
                              <MenuItem value={opt} key={`state-${index}`}>
                                <ListItemText primary={opt} />
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        {Boolean(formik?.errors?.location) &&
                          formik?.touched?.location && (
                            <FormHelperText>
                              {formik?.errors?.location}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <S.FieldTitle>Segmentos*</S.FieldTitle>
                      <Tooltip
                        title={selectedSegments}
                        open={
                          tooltipOpen &&
                          formik.values.professionalSegments?.length > 0
                        }
                        placement="top">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={
                            formik.touched.professionalSegments &&
                            Boolean(formik.errors.professionalSegments)
                          }
                          onMouseEnter={() => {
                            setTooltipOpen(true);
                          }}
                          onMouseLeave={() => {
                            setTooltipOpen(false);
                          }}
                          onClick={() => {
                            setTooltipOpen(false);
                          }}
                          onMouseDown={() => {
                            setTooltipOpen(false);
                          }}>
                          <Select
                            name="professionalSegments"
                            value={formik.values.professionalSegments}
                            onChange={formik.handleChange}
                            multiple
                            disabled={segmentsLoading}
                            displayEmpty
                            renderValue={(selected: any) => {
                              if (!selected?.length) {
                                return (
                                  <Typography sx={{ color: '#BDBDBD' }}>
                                    Selecione o segmento
                                  </Typography>
                                );
                              }
                              const selectedSegments: Segment[] =
                                segments.filter((segment: any) =>
                                  selected.some((s: any) => s === segment.id),
                                );
                              setSelectedSegments(
                                selectedSegments
                                  .map(({ name }) => name)
                                  .join(', '),
                              );
                              return (
                                <Typography noWrap sx={{ maxWidth: 600 }}>
                                  {selectedSegments
                                    .map(({ name }) => name)
                                    .join(', ')}
                                </Typography>
                              );
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: 250,
                                },
                              },
                              MenuListProps: {
                                sx: (theme) => ({
                                  maxHeight: 240,
                                  overflowY: 'scroll',
                                  '&::-webkit-scrollbar': {
                                    height: 10,
                                    width: 8,
                                    WebkitAppearance: 'none',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    borderRadius: 4,
                                    backgroundColor: theme.palette.grey['400'],
                                  },
                                }),
                              },
                            }}>
                            {segments?.map((opt: Segment) => (
                              <MenuItem value={opt.id} key={opt.id}>
                                <Checkbox
                                  checked={formik.values.professionalSegments.some(
                                    (value) => value === opt.id,
                                  )}
                                />
                                <ListItemText primary={opt.name} />
                              </MenuItem>
                            ))}
                          </Select>
                          {Boolean(formik.errors.professionalSegments) &&
                            formik.touched.professionalSegments && (
                              <FormHelperText>
                                {formik.errors.professionalSegments}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <S.FieldTitle>Mercados*</S.FieldTitle>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={
                          formik.touched.markets &&
                          Boolean(formik.errors.markets)
                        }>
                        <Select
                          name="markets"
                          value={formik.values.markets}
                          onChange={formik.handleChange}
                          multiple
                          disabled={marketsLoading}
                          displayEmpty
                          renderValue={(selected: any) => {
                            if (!selected?.length) {
                              return (
                                <Typography sx={{ color: '#BDBDBD' }}>
                                  Selecione o mercado
                                </Typography>
                              );
                            }
                            const selectedMarkets: Market[] = markets.filter(
                              (market: any) =>
                                selected.some((s: any) => s === market.id),
                            );
                            return (
                              <Typography noWrap sx={{ maxWidth: 600 }}>
                                {selectedMarkets
                                  .map(({ name }) => name)
                                  .join(', ')}
                              </Typography>
                            );
                          }}>
                          {markets
                            ?.filter((m: Market) =>
                              PROFESSIONAL_PERMITED_MARKETS.includes(m.name),
                            )
                            .map((opt: Market) => (
                              <MenuItem value={opt.id} key={opt.id}>
                                <Checkbox
                                  checked={formik.values.markets.some(
                                    (value) => value === opt.id,
                                  )}
                                />
                                <ListItemText primary={opt.name} />
                              </MenuItem>
                            ))}
                        </Select>
                        {Boolean(formik.errors.markets) &&
                          formik.touched.markets && (
                            <FormHelperText>
                              {formik.errors.markets}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionControlled>
              <AccordionControlled
                ref={contactRef}
                expanded={contactAccordionOpen}
                onChange={() =>
                  setContactAccordionOpen((prevState) => !prevState)
                }
                title="Dados de contato">
                <Grid
                  container
                  spacing={2}
                  sx={(theme) => ({ padding: theme.spacing(2) })}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    sx={{
                      height: 'fit-content',
                      justifyContent: 'flex-start',
                    }}>
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="emails"
                        render={(arrayHelpers) => (
                          <>
                            {formik.values?.emails &&
                              formik.values?.emails?.length > 0 &&
                              formik.values?.emails.map((_, index) => (
                                <Grid
                                  key={`email-${index}`}
                                  xs={12}
                                  md={12}
                                  item
                                  sx={{
                                    paddingBottom: '16px',
                                  }}>
                                  <S.FieldTitle>
                                    {index === 0
                                      ? 'E-mail'
                                      : 'E-mail adicional'}
                                  </S.FieldTitle>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ flexWrap: 'nowrap' }}
                                    container>
                                    <Grid
                                      item
                                      xs={10}
                                      md={10}
                                      sx={{ width: '100%' }}>
                                      <FormControl fullWidth variant="outlined">
                                        <TextField
                                          name={`emails[${index}].email`}
                                          value={
                                            formik.values.emails[index].email
                                          }
                                          onChange={formik.handleChange}
                                          placeholder="Insira o e-mail"
                                          error={
                                            formik?.touched.emails &&
                                            formik?.touched?.emails[index]
                                              ?.email &&
                                            Boolean(
                                              getEmailsError('email', index),
                                            )
                                          }
                                          helperText={
                                            formik?.touched.emails &&
                                            formik?.errors.emails &&
                                            formik?.touched?.emails[index]
                                              ?.email &&
                                            getEmailsError('email', index)
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                    <If
                                      condition={
                                        formik.values?.emails?.length < 3 &&
                                        formik.values?.emails?.length -
                                          index ===
                                          1
                                      }>
                                      <Grid item xs={1} md={1}>
                                        <IconButton
                                          disabled={
                                            formik.values?.emails?.length > 2
                                          }
                                          sx={(theme) => ({
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                              color:
                                                theme.palette.secondary.light,
                                            },
                                          })}
                                          onClick={() => {
                                            if (
                                              formik.values?.emails?.length > 2
                                            )
                                              return;
                                            arrayHelpers.push({ email: '' });
                                          }}>
                                          <AddCircle
                                            fontSize="large"
                                            color="primary"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </If>
                                    <If
                                      condition={
                                        formik.values?.emails?.length > 1 &&
                                        index > 0
                                      }>
                                      <Grid item xs={1} md={1}>
                                        <IconButton
                                          sx={(theme) => ({
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                              color:
                                                theme.palette.secondary.light,
                                            },
                                          })}
                                          onClick={() => arrayHelpers.pop()}>
                                          <Cancel
                                            fontSize="large"
                                            color="primary"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </If>
                                  </Grid>
                                </Grid>
                              ))}
                          </>
                        )}
                      />
                    </FormikProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    sx={{
                      height: 'fit-content',
                      justifyContent: 'flex-start',
                    }}>
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="phones"
                        render={(arrayHelpers) => (
                          <>
                            {formik.values?.phones &&
                              formik.values?.phones?.length > 0 &&
                              formik.values?.phones.map((_, index) => (
                                <Grid
                                  key={`phone-${index}`}
                                  xs={12}
                                  md={12}
                                  item
                                  sx={{
                                    paddingBottom: '16px',
                                  }}>
                                  <S.FieldTitle>
                                    {index === 0
                                      ? 'Telefone'
                                      : 'Telefone adicional'}
                                  </S.FieldTitle>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ flexWrap: 'nowrap' }}
                                    container>
                                    <Grid
                                      item
                                      xs={10}
                                      md={10}
                                      sx={{ width: '100%' }}>
                                      <FormControl fullWidth variant="outlined">
                                        <TextField
                                          name={`phones[${index}].number`}
                                          InputProps={{
                                            inputComponent: PhoneFormat,
                                          }}
                                          value={
                                            formik.values.phones[index].number
                                          }
                                          onChange={formik.handleChange}
                                          placeholder="Digite o telefone"
                                          error={
                                            formik?.touched.phones &&
                                            formik?.touched?.phones[index]
                                              ?.number &&
                                            Boolean(
                                              getPhoneError('number', index),
                                            )
                                          }
                                          helperText={
                                            formik?.touched.phones &&
                                            formik?.errors.phones &&
                                            formik?.touched?.phones[index]
                                              ?.number &&
                                            getPhoneError('number', index)
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                    <If
                                      condition={
                                        formik.values?.phones?.length < 3 &&
                                        formik.values?.phones?.length -
                                          index ===
                                          1
                                      }>
                                      <Grid item xs={1} md={1}>
                                        <IconButton
                                          disabled={
                                            formik.values?.phones?.length > 2
                                          }
                                          sx={(theme) => ({
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                              color:
                                                theme.palette.secondary.light,
                                            },
                                          })}
                                          onClick={() => {
                                            if (
                                              formik.values?.phones?.length > 2
                                            )
                                              return;
                                            arrayHelpers.push({ number: '' });
                                          }}>
                                          <AddCircle
                                            fontSize="large"
                                            color="primary"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </If>
                                    <If
                                      condition={
                                        formik.values?.phones?.length > 1 &&
                                        index > 0
                                      }>
                                      <Grid item xs={1} md={1}>
                                        <IconButton
                                          sx={(theme) => ({
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                              color:
                                                theme.palette.secondary.light,
                                            },
                                          })}
                                          onClick={() => arrayHelpers.pop()}>
                                          <Cancel
                                            fontSize="large"
                                            color="primary"
                                          />
                                        </IconButton>
                                      </Grid>
                                    </If>
                                  </Grid>
                                </Grid>
                              ))}
                          </>
                        )}
                      />
                    </FormikProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <S.FieldTitle>LinkedIn</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="linkedin.url"
                        placeholder="Insira a url do seu linkedin profissional"
                        onChange={formik.handleChange}
                        value={formik.values.linkedin.url}
                        error={
                          formik?.touched?.linkedin?.url &&
                          Boolean(formik?.errors?.linkedin?.url)
                        }
                        helperText={
                          formik?.touched?.linkedin?.url &&
                          formik?.errors?.linkedin?.url
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <S.FieldTitle>Instagram</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="instagram.url"
                        placeholder="Insira a url do seu instagram profissional"
                        onChange={formik.handleChange}
                        value={formik.values.instagram.url}
                        error={
                          formik?.touched?.instagram?.url &&
                          Boolean(formik?.errors?.instagram?.url)
                        }
                        helperText={
                          formik?.touched?.instagram?.url &&
                          formik?.errors?.instagram?.url
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <S.FieldTitle>Facebook</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="facebook.url"
                        placeholder="Insira a url do seu facebook profissional"
                        onChange={formik.handleChange}
                        value={formik.values.facebook.url}
                        error={
                          formik?.touched?.facebook?.url &&
                          Boolean(formik?.errors?.facebook?.url)
                        }
                        helperText={
                          formik?.touched?.facebook?.url &&
                          formik?.errors?.facebook?.url
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <S.FieldTitle>Twitter</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name="twitter.url"
                        placeholder="Insira a url do seu twitter profissional"
                        onChange={formik.handleChange}
                        value={formik.values.twitter.url}
                        error={
                          formik?.touched?.twitter?.url &&
                          Boolean(formik?.errors?.twitter?.url)
                        }
                        helperText={
                          formik?.touched?.twitter?.url &&
                          formik?.errors?.twitter?.url
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionControlled>
              <AccordionControlled
                ref={relatedInstitutionRef}
                expanded={relatedInstitutionAccordionOpen}
                onChange={() =>
                  setRelatedInstitutionAccordionOpen((prevState) => !prevState)
                }
                title="Empresa relacionada"
                defaultExpanded>
                <Grid
                  container
                  spacing={2}
                  sx={(theme) => ({ padding: theme.spacing(2) })}>
                  <Grid item xs={12}>
                    <Typography fontSize={14}>
                      A lista de empresa relacionada exibe todos os perfis
                      institucionais cadastrados na plataforma. Ao selecionar
                      uma empresa da lista, é aberta uma solicitação para o
                      administrador analisar o vínculo com a instituição. Caso
                      sua empresa não esteja na lista, selecione “Outra” e
                      digite a qual empresa pertence.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InstitutionAutocomplete
                      formik={formik}
                      institutionName={institutionName}
                      setInstitutionName={setInstitutionName}
                      relatedInstitutions={relatedInstitutions}
                      setOpenConfirmInstitutionDialog={
                        setOpenConfirmInstitutionDialog
                      }
                      setSelectedInstitution={setSelectedInstitution}
                    />
                  </Grid>
                  {selectedInstitution.isOther && (
                    <Grid item xs={12} md={6}>
                      <S.FieldTitle>Nome da empresa*</S.FieldTitle>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          name="institutionName"
                          placeholder="Digite o nome da empresa"
                          onChange={formik.handleChange}
                          value={formik.values.institutionName}
                          error={
                            formik.touched.institutionName &&
                            Boolean(formik.errors.institutionName)
                          }
                          helperText={
                            formik.touched.institutionName &&
                            formik.errors.institutionName
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <S.FieldTitle>Data de início na instituição*</S.FieldTitle>
                    <FormControl fullWidth variant="outlined">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ptBR}>
                        <DatePicker
                          views={['year', 'month', 'day']}
                          maxDate={new Date()}
                          slotProps={{
                            textField: {
                              name: 'institutionStartDate',
                              placeholder: 'DD/MM/AAAA',
                              error:
                                formik.touched.institutionStartDate &&
                                Boolean(formik.errors.institutionStartDate),
                            },
                          }}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'institutionStartDate',
                              value && isValid(value)
                                ? format(value, 'yyyy-MM-dd')
                                : value,
                            );
                          }}
                          value={
                            formik.values.institutionStartDate &&
                            parseISO(formik.values.institutionStartDate)
                          }
                        />
                      </LocalizationProvider>
                      {Boolean(formik.errors.institutionStartDate) &&
                        formik.touched.institutionStartDate && (
                          <FormHelperText sx={{ color: '#EB5757' }}>
                            {formik.errors.institutionStartDate}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RoleSelectField
                      id="ProfessionalProfile-select-role"
                      options={responsibilities}
                      loading={responsibilityLoading}
                      label="Cargo*"
                      name="role"
                      placeholder="Selecione ou digite o cargo"
                      value={formik.values.role}
                      setFieldValue={formik.setFieldValue}
                      error={formik.errors.role}
                    />
                  </Grid>
                </Grid>
              </AccordionControlled>
              <Box onChange={(e) => e.stopPropagation()}>
                <AccordionControlled
                  ref={relatedOperationRef}
                  expanded={relatedOperationsAccordionOpen}
                  onChange={() => {
                    if (!openRelatedOperationForm) {
                      setRelatedOperationsAccordionOpen(
                        (prevState) => !prevState,
                      );
                    }
                  }}
                  title="Operações relacionadas">
                  <ProfessionalRelatedOperations
                    setOpenForm={setOpenRelatedOperationForm}
                    openForm={openRelatedOperationForm}
                    professional={data}
                    professionalRefetch={professionalRefetch}
                  />
                </AccordionControlled>
                <AccordionControlled
                  ref={experienceRef}
                  expanded={experienceAccordionOpen}
                  onChange={() => {
                    if (!openExperienceForm) {
                      setExperienceAccordionOpen((prevState) => !prevState);
                    }
                  }}
                  title="Experiência profissional">
                  <Experiences
                    setOpenForm={setOpenExperienceForm}
                    openForm={openExperienceForm}
                    professional={data}
                    professionalRefetch={professionalRefetch}
                  />
                </AccordionControlled>
                <AccordionControlled
                  ref={certificationRef}
                  expanded={certificationAccordionOpen}
                  onChange={() => {
                    if (!openCertificationForm) {
                      setCertificationAccordionOpen((prevState) => !prevState);
                    }
                  }}
                  title="Certificações">
                  <Certifications
                    setOpenForm={setOpenCertificationForm}
                    openForm={openCertificationForm}
                    professional={data}
                    professionalRefetch={professionalRefetch}
                  />
                </AccordionControlled>
              </Box>
              <AccordionControlled
                expanded={slugAccordionOpen}
                onChange={() => setSlugAccordionOpen((prevState) => !prevState)}
                disabled={
                  !data?.id
                    ? true
                    : auth.user?.subscription?.plan?.type
                      ? ['demo', 'free'].includes(
                          auth.user.subscription.plan.type,
                        )
                      : false
                }
                disabledReason={
                  !data?.id
                    ? 'Funcionalidade disponível apenas após criação do perfil'
                    : auth.user?.subscription?.plan?.type === 'free'
                      ? 'Funcionalidade não disponível, faça upgrade'
                      : auth.user?.subscription?.plan?.type === 'demo'
                        ? 'Funcionalidade não disponível durante demonstração'
                        : ''
                }
                title="URL Pública">
                {!!data?.id && (
                  <EditSlug
                    name="slug"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    id={data.id}
                    onBlur={formik.handleBlur}
                  />
                )}
              </AccordionControlled>
            </TabPanel>
          </Box>
          <Box sx={{ marginTop: '48px', textAlign: 'end' }}>
            <Button variant="contained" size="medium" type="submit">
              {createProfessionalMutation.isLoading ||
              updateProfessionalMutation.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar perfil'
              )}
            </Button>
          </Box>
        </form>
        <ConfirmInstitutionRequestDialog
          open={openConfirmInstitutionDialog}
          onClose={() => {
            setOpenConfirmInstitutionDialog(false);
            setSelectedInstitution({ fantasyName: '' });
            formik.setFieldValue('relatedInstitutions', {
              id: undefined,
              fantasyName: '',
            });
          }}
          handleAction={() => setOpenConfirmInstitutionDialog(false)}
          institution={formik.values.relatedInstitutions.fantasyName}
        />
        <ConfirmLeaveDialog
          title="Tem certeza que deseja sair desta página?"
          open={openLeaveDialog}
          onClose={() => setOpenLeaveDialog(false)}
          handleAction={() => {
            setTriggerExit((obj) => ({
              ...obj,
              shouldLeave: true,
            }));
            setOpenLeaveDialog(false);
          }}
          labelButton="Continuar edição"
        />
      </TabContext>
    </section>
  );
}
