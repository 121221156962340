import { useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ExportToCsv } from 'export-to-csv';

import csvSvg from '../../../../../../assets/imgs/csv.svg';
import { convertNumber, toCurrency } from '../../../../../../utils/number';

export function TablePatrimony({ name, rows, periods, loading, classes }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableRef = useRef();
  const networth = [...rows]
    ?.reverse()
    ?.filter((row) => row.label === 'Patrimônio Líquido');
  const issues = [...rows]
    ?.reverse()
    ?.filter((row) => row.label === 'Emissões');
  const outflow = [...rows]
    ?.reverse()
    ?.filter((row) => row.label === 'Amortizações');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportDataToCSV = () => {
    const headers = ['Data', 'Patrimônio Líquido', 'Emissões', 'Amortizações'];

    const options = {
      fieldSeparator: ',',
      showLabels: true,
      filename: `${name}_patrimonio`,
      headers,
    };

    const dataToExport = periods.map((period) => {
      const periodIndex = periods.findIndex((p) => p === period);
      return {
        date: period,
        networth: convertNumber(networth[0]?.data[periodIndex], 2),
        issues: convertNumber(issues[0]?.data[periodIndex], 2),
        outflow: convertNumber(outflow[0]?.data[periodIndex], 2),
      };
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  };

  if (loading) return <CircularProgress size="1.8rem" color="secondary" />;

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.exportButton}
        onClick={exportDataToCSV}>
        <img src={csvSvg} alt="export" className={classes.exportIcon} />
        Exportar
      </Button>
      <TableContainer innerRef={tableRef}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell align="right">Patrimônio Líquido</TableCell>
              <TableCell align="right">Emissões</TableCell>
              <TableCell align="right">Amortizações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...periods]
              ?.reverse()
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((period, index) => {
                const periodIndex = periods.findIndex((p) => p === period);
                return (
                  <TableRow key={index}>
                    <TableCell>{period}</TableCell>
                    <TableCell align="right">
                      {toCurrency(networth[0]?.data[periodIndex])}
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(issues[0]?.data[periodIndex])}
                    </TableCell>
                    <TableCell align="right">
                      {toCurrency(outflow[0]?.data[periodIndex])}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                count={periods?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={'Registros por página'}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
