import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { format, parse } from 'date-fns';
import { Link } from 'react-router-dom';
// Manage date
// Material ui components

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
  chip: {
    marginLeft: '0.2rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    cursor: 'pointer',
  },
  title: {
    height: '31px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default function Course({
  hashId,
  title,
  image,
  type,
  markets,
  slug,
  resume,
}) {
  const classes = useStyles();

  return (
    <Link
      to={`/treinamento/${slug}/${hashId}`}
      style={{ textDecoration: 'none' }}>
      <Card square className={classes.card}>
        <CardMedia
          className={classes.media}
          image={image?.formats?.small?.url}
          title={title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="h4"
            className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {resume?.startDate
              ? format(
                  parse(resume.startDate, 'yyyy-MM-dd', new Date()),
                  'dd/MM',
                )
              : 'A definir'}
            {resume?.endDate
              ? ` a ${format(
                  parse(resume.endDate, 'yyyy-MM-dd', new Date()),
                  'dd/MM',
                )}`
              : ''}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container spacing={1}>
            {type && (
              <Grid item>
                <Chip label={type} color="secondary" className={classes.chip} />
              </Grid>
            )}
            <Grid item xs>
              {markets &&
                markets.map((market) => (
                  <Chip
                    key={market?.id}
                    label={market?.name}
                    color="secondary"
                    className={classes.chip}
                  />
                ))}
            </Grid>
            <Grid item>
              <Button size="small" color="primary">
                Saiba mais
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Link>
  );
}
