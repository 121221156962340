import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

const graphqlError = onError(({ networkError, operation, forward }) => {
  if (networkError) {
    // contentManager.mutate({
    //   mutation: 'notificationAddMutation',
    //   variables: {
    //     text: 'There was a network problem. Please check your connection',
    //   },
    // });
  }
  return forward(operation);
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_URL_CONTENT_MANAGER}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  let headers;
  if (sessionStorage.getItem('@App:token')) {
    headers = {
      Authorization: `Bearer ${sessionStorage.getItem('@App:token')}`,
    };
  }
  // add the authorization to the headers
  operation.setContext({
    headers,
  });

  return forward(operation);
});

// Initializing an ApolloClient instance
const contentManager = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authMiddleware.concat(graphqlError).concat(httpLink),
});

export default contentManager;
