import { useCallback, useState } from 'react';

import { Close } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import ConfirmLeaveDialog from 'src/components/ConfirmLeaveDialog';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { If } from 'src/components/If';
import { createImage, updateImage } from 'src/services/gallery';
import { setBannerMessage } from 'src/store/display';
import { Image } from 'src/types/institution';
import { formatBytes } from 'src/utils/number';
import * as Yup from 'yup';

const MAX_FILE_SIZE = 2000000;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type Props = {
  open: boolean;
  onClose: () => void;
  institutionId: number | undefined;
  institutionRefetch: () => void;
  selectedImage?: Image | undefined;
};

function PhotosModal({
  open,
  onClose,
  institutionId,
  institutionRefetch,
  selectedImage,
}: Props) {
  const [openLeaveDialog, setOpenLeaveDialog] = useState<boolean>(false);
  const dispatch = useDispatch();

  const FORM_VALIDATION: any = Yup.object().shape({
    description: Yup.string().required('Campo obrigatório'),
    image: Yup.mixed()
      .test(
        'fileSize',
        'A imagem excede o tamanho máximo. Max: 2MB',
        (value) => {
          if (selectedImage || !value) {
            return true;
          }
          return value?.type && value?.size <= MAX_FILE_SIZE;
        },
      )
      .test('fileFormat', 'Formato não suportado', (value) => {
        if (selectedImage || !value) {
          return true;
        }
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
      .test('required', 'Imagem obrigatória', (value) => {
        if (selectedImage) {
          return true;
        }
        return !!formik.values.image ?? !!value;
      }),
  });

  const formik = useFormik({
    initialValues: {
      description: selectedImage?.description ?? '',
      image: selectedImage?.image ?? null,
    } as { image: File | null; description: string },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      const { image, description } = values;
      if (selectedImage) {
        formData.append(
          'data',
          JSON.stringify({
            description,
          }),
        );
        updateImageMutation.mutate({
          id: selectedImage?.id,
          values: formData,
        });
      } else if (image) {
        formData.append('files.image', image);
        formData.append(
          'data',
          JSON.stringify({
            description,
            institution: institutionId,
          }),
        );
        createImageMutation.mutate(formData);
      }
    },
  });

  const createImageMutation = useMutation(createImage, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Erro ao adicionar imagem, tente novamente',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Imagem adicionada com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const updateImageMutation = useMutation(updateImage, {
    onError: (error) => {
      if (error) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'error',
            message: 'Não foi possível atualizar a imagem',
          }),
        );
      }
    },
    onSuccess: (result) => {
      if (result) {
        dispatch(
          setBannerMessage({
            autoclose: true,
            type: 'success',
            message: 'Imagem atualizada com sucesso',
          }),
        );
      }
      formik.resetForm();
      onClose();
      institutionRefetch();
    },
  });

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('image', acceptedFiles[0]);
      formik.setErrors({
        image: undefined,
      });
    } else if (rejectedFiles.length) {
      let message = rejectedFiles[0].errors[0].message;
      switch (rejectedFiles[0].errors[0].code) {
        case 'file-too-large':
          message = 'A imagem excede o tamanho máximo. Max: 2MB';
          break;
        case 'file-invalid-type':
          message = 'Formato não suportado';
          break;
        case 'too-many-files':
          message = 'Selecione somente uma imagem';
          break;
      }
      formik.setErrors({
        image: message,
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
    },
    onDrop,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    noDragEventsBubbling: true,
  });

  const handleLeaveDialog = () => {
    setOpenLeaveDialog(false);
    formik.resetForm();
    onClose();
  };

  const handleRemove = () => {
    formik.setFieldValue('image', null);
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{
        maxWidth: 750,
        margin: '0 auto',
        '& .MuiPaper-root': { width: '100%' },
      }}>
      <form
        onSubmit={formik.handleSubmit}
        onChange={(e) => e.stopPropagation()}>
        <DialogContent
          sx={(theme) => ({
            padding: '40px 56px 0px',
            [theme.breakpoints.down('sm')]: {
              padding: '20px 24px',
            },
          })}>
          <Typography fontSize={18} fontWeight={700} mb={4} textAlign="center">
            {`${selectedImage ? 'Editar legenda' : 'Adicionar imagem'}`}
          </Typography>
          <If condition={!selectedImage}>
            <Typography fontSize={14} mb={4} textAlign="center">
              Adicione as imagens que serão exibidas na galeria do perfil
              institucional, você pode adicionar uma descrição de até 300
              caracteres.
            </Typography>
          </If>
          <Grid container>
            <TextAreaProfile
              name="description"
              label="Legenda*"
              placeholder="Escreva uma legenda para a imagem"
              variant="outlined"
              maxCaracters={300}
              value={formik.values.description}
              handleChange={formik.handleChange}
              errors={formik.errors.description}
              touched={formik.touched.description}
            />
            {!formik.values.image ? (
              <Box
                {...getRootProps()}
                sx={{
                  width: '100%',
                  height: 150,
                  backgroundColor: '#FAFAFA',
                  border: '1px dashed #E0E0E0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  margin: '8px 0 20px',
                  cursor: 'pointer',
                }}>
                <input {...getInputProps()} />
                <ImageIcon sx={{ fontSize: '48px', color: '#BDBDBD' }} />
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: '0.875rem',
                  })}>
                  <span style={{ textDecoration: 'underline' }}>
                    Clique aqui
                  </span>{' '}
                  ou arraste um arquivo
                </Typography>
                {Boolean(formik.errors.image) && (
                  <FormHelperText sx={{ color: '#EB5757' }}>
                    {formik.errors.image}
                  </FormHelperText>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  height: 62,
                  width: '100%',
                  padding: '0 16px',
                  backgroundColor: '#FAFAFA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '8px 0 20px',
                }}>
                <Box sx={{ display: 'flex', textAlign: 'start' }}>
                  <ImageIcon sx={{ fontSize: '40px', color: '#BDBDBD' }} />
                  <Box ml={1}>
                    <If condition={formik.values.image}>
                      <Typography fontSize={14} fontWeight={600}>
                        {formik.values.image?.name}
                      </Typography>
                      <Typography sx={{ color: '#828282', fontSize: '14px' }}>
                        {formatBytes(formik.values.image?.size)}
                      </Typography>
                    </If>
                  </Box>
                </Box>
                <If condition={!selectedImage}>
                  <IconButton onClick={handleRemove}>
                    <Close />
                  </IconButton>
                </If>
              </Box>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            gap: '16px',
            justifyContent: 'center',
            padding: '12px 0 39px 0',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
              padding: '0 24px 40px 24px',
            },
            '& > button': {
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
                marginLeft: '0 !important',
              },
            },
          })}>
          <Button variant="contained" type="submit">
            {createImageMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
          <Button
            variant="outlined"
            type="reset"
            color="secondary"
            sx={{ marginLeft: '32px !important' }}
            onClick={() => setOpenLeaveDialog(true)}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
      <ConfirmLeaveDialog
        title={
          selectedImage
            ? 'Tem certeza que deseja fechar a edição de imagens?'
            : 'Tem certeza que deseja fechar a adição de imagens?'
        }
        open={openLeaveDialog}
        onClose={() => setOpenLeaveDialog(false)}
        handleAction={handleLeaveDialog}
        labelButton={selectedImage ? 'Continuar edição' : 'Continuar'}
      />
    </Dialog>
  );
}

export default PhotosModal;
