// Material ui components
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
// Global components
import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
// Apollo client
import ipanema from '../../../../../graphql/clients/ipanema';
import { GET_FII_QUOTES_AND_NET_WORTH_BY_OPERATION_HASH_ID } from '../../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationFIIQuotesAndNetWorth({ hash }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_FII_QUOTES_AND_NET_WORTH_BY_OPERATION_HASH_ID,
    {
      variables: { hash },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions
        ?.operacoes_fii_resumo_cotas_patrimonio_liquido,
    },
  );

  return (
    <Box p={1}>
      <Typography variant="subtitle2" className={classes.title}>
        &mdash;Cotas e Patrimônio Líquido
      </Typography>
      <Divider className={classes.divider} />
      <Box py={3}>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_fii_resumo_cotas_patrimonio_liquido'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_fii_resumo_cotas_patrimonio_liquido'
            ]
          }>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <PaperPanelInfo
                numeric
                title="Patrimônio Líquido"
                value={
                  loading
                    ? 0
                    : error
                      ? 0
                      : data?.getFiiQuotesAndNetWorthByOperationHashId
                            ?.operationNetWorth !== null
                        ? data?.getFiiQuotesAndNetWorthByOperationHashId
                            ?.operationNetWorth
                        : 'N/D'
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PaperPanelInfo
                number
                title="Número de Cotas"
                value={
                  loading
                    ? null
                    : error
                      ? null
                      : data?.getFiiQuotesAndNetWorthByOperationHashId
                          ?.operationQuotesQuantity
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PaperPanelInfo
                numeric
                million={false}
                title="Valor Patrimonial da Cota"
                value={
                  loading
                    ? 0
                    : error
                      ? 0
                      : data?.getFiiQuotesAndNetWorthByOperationHashId
                          ?.operationQuotaBookValue
                }
                loading={loading}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
