import { Box, makeStyles, Tab, Tabs, withStyles } from '@material-ui/core';
// Typechecking
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tabs: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiTabs-flexContainer': {
        borderBottom: 'none',
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function TabSection({ children, tabList, value, setValue, isProfile }) {
  const classes = useStyles();
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const AntTab = withStyles((theme) => ({
    root: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
      textTransform: 'none',
      minWidth: !isProfile ? '100px' : undefined,
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      <Box component="section">
        <Box
          component="nav"
          color="inherit"
          variant="outlined"
          borderBottom={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="menu"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}>
            {tabList?.map((tab) => (
              <AntTab
                key={`menu-tab-${tab.value}-${new Date().getTime()}`}
                label={`${tab.label}`}
                value={tab.value}
                id={`tab-${tab.value}`}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={value} index={value}>
          {children}
        </TabPanel>
      </Box>
    </>
  );
}
