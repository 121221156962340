// Material ui components
import { Chip, makeStyles, Typography } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  chip: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
}));

export default function DateChip({ title, date }) {
  const classes = useStyles();
  return (
    <>
      {title && (
        <Typography variant="subtitle1" className={classes.title}>
          {`${title}:`}
        </Typography>
      )}
      <Chip
        size="small"
        color="secondary"
        label={date ?? 'N/D'}
        className={classes.chip}
      />
    </>
  );
}
