import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/AuthContextProvider';
import OperationWalletTable from 'src/pages/Operation/OperationWalletTable';
import { getFIIWalletProperty } from 'src/services/operation';
import { convertNumber } from 'src/utils/number';

type Props = {
  operationCnpj: string;
  setHasData: Dispatch<
    SetStateAction<{
      cri: boolean;
      fii: boolean;
      fidc: boolean;
      fiiProperty: boolean;
    }>
  >;
};

export default function WalletProperty({ operationCnpj, setHasData }: Props) {
  const { auth } = useAuthContext();
  const market = 'fii';
  const indicator = 'ativo-imovel';

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'nomeImovel',
        accessorFn: (row) => row?.nomeImovel,
        header: 'Nome do imóvel',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
      },
      {
        accessorKey: 'endereco',
        accessorFn: (row) => row?.endereco,
        header: 'Endereço',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
      },
      {
        accessorKey: 'area',
        accessorFn: (row) => row?.area,
        header: 'Área (m²)',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
      },
      {
        accessorKey: 'percentualVacancia',
        header: 'Percentual de Vacância',
        accessorFn: (row) => row.percentualVacancia,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue()
            ? `${convertNumber(cell.getValue() * 100, 3, 1)}%`
            : '-',
      },
      {
        accessorKey: 'percentualInadimplencia',
        header: 'Percentual de Inadimplência',
        accessorFn: (row) => row.percentualInadimplencia,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue()
            ? `${convertNumber(cell.getValue() * 100, 3, 1)}%`
            : '-',
      },
      {
        accessorKey: 'percentualReceitasFii',
        header: 'Percentual de Receitas',
        accessorFn: (row) => row.percentualReceitasFii,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue()
            ? `${convertNumber(cell.getValue() * 100, 3, 1)}%`
            : '-',
      },
    ],
    [],
  );

  const { data, isLoading } = useQuery(
    ['operation-wallet-property', indicator, market, operationCnpj],
    () =>
      getFIIWalletProperty({
        mode: 'meta',
        cnpj: operationCnpj,
        market,
        indicator,
        permission: 'operacoes_fii_ativo_carteira',
      }),
    {
      onSuccess: (data) => {
        setHasData((prev) => ({
          ...prev,
          fiiProperty: data.length > 0,
        }));
      },
      enabled: !!operationCnpj,
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const filteredData = (dataReferencia: string) => {
    return data.filter((item: any) => item.dataReferencia === dataReferencia);
  };

  const getData = () =>
    data?.length > 0 ? filteredData(data[0].dataReferencia) : [];

  const hasPermission =
    auth?.user?.subscription?.plan?.permissions?.operacoes_fii_ativo_carteira;

  if (!data?.length) return null;

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '22px',
          marginLeft: 1,
        }}>
        Imóveis
      </Typography>
      <OperationWalletTable
        hasPermission={!!hasPermission}
        data={getData()}
        columns={columns}
        isLoading={isLoading}
      />
    </Box>
  );
}
