import React from 'react';

import * as S from './styles';

type Props = {
  handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: number | boolean,
  ) => void;
  tabValue: number | boolean;
  tabData: Array<{
    label: string;
    onClick?: (tab: number | boolean) => void;
  }>;
  noBorder?: boolean;
  noMarker?: boolean;
};

const Tab = (props: Props) => {
  return (
    <S.Tabs
      value={props.tabValue}
      onChange={props.handleChange}
      centered
      noborder={!!props.noBorder}
      nomarker={!!props.noMarker}>
      {props.tabData.map(({ label, onClick }, index) => (
        <S.Tab key={`${label}-${index}`} label={label} onClick={onClick} />
      ))}
    </S.Tabs>
  );
};

export default Tab;
