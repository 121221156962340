import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import Section from 'src/components/ProfileSection';
import { Achievement as AchivementType } from 'src/types/professional';
import 'react-alice-carousel/lib/alice-carousel.css';

export const Achievements = ({
  achievements,
}: {
  achievements?: AchivementType[];
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const items =
    achievements?.map((achievement) => (
      <Card
        key={`${achievement.id}-achievement`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fafafa',
          boxShadow: 0,
        }}>
        <CardMedia
          image={achievement.image.url}
          title={achievement.title}
          sx={{
            height: '55px',
            width: '55px',
            minWidth: '55px',
            borderRadius: '4px',
          }}
        />
        <CardContent
          sx={{
            padding: '8px 16px !important',
            whiteSpace: 'wrap',
          }}>
          <Typography fontSize={12} fontWeight={600}>
            {achievement.title}
          </Typography>
        </CardContent>
      </Card>
    )) || [];

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    768: { items: 3 },
    1024: { items: 4 },
  };

  const renderPrevButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowLeft />
      </IconButton>
    );
  };

  const renderNextButton = ({ isDisabled }: any) => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
          color: 'black',
          backgroundColor: 'rgba(22, 34, 51, 0.08)',
          height: 'fit-content',
          visibility: isDisabled ? 'hidden' : 'visible',
          '&:hover': {
            color: 'black',
            backgroundColor: 'rgba(22, 34, 51, 0.08)',
          },
        }}
        size={isMobile ? 'small' : 'medium'}>
        <KeyboardArrowRight />
      </IconButton>
    );
  };

  return (
    <Section title="Conquistas">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={(theme) => ({
            width: '100%',
            overflow: 'hidden',
            '& > div': {
              padding: 0,
            },
            '& .alice-carousel > div': {
              padding: '0 54px',
              [theme.breakpoints.down('sm')]: {
                padding: '0 40px',
              },
            },
            '& .alice-carousel__stage-item': {
              verticalAlign: 'middle',
            },
            '& .alice-carousel__prev-btn': {
              position: 'absolute',
              top: '50%',
              padding: 0,
            },
            '& .alice-carousel__next-btn': {
              position: 'absolute',
              top: '50%',
              right: 0,
              padding: 0,
            },
          })}>
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            disableDotsControls
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
            swipeExtraPadding={400}
          />
        </Box>
      </Box>
    </Section>
  );
};
