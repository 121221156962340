import { useEffect, useMemo, useRef, useState } from 'react';

import { Check } from '@material-ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { redirectCheckout } from 'src/services/portal';
import { setBannerMessage } from 'src/store/display';
import { PlanPrice } from 'src/types/plans';
import { UserValidateEmail } from 'src/types/user';
import { useQueryParams, useSessionStorage } from 'src/utils/hooks';
import { ScrollToTop } from 'src/utils/scroll';

import ComplementaryRegisterForm from './ComplementaryRegisterForm';
import { EmailConfirmationForm } from './EmailConfirmationForm';
import { RegisterForm } from './RegisterForm';
import { RegisterFormLegalPerson } from './RegisterFormLegalPerson';
import RegisterType from './RegisterType';
import { CheckStep } from '../../components/CheckStep';
import { If } from '../../components/If';
import { getUrlRedirectDemo, validateEmail } from '../../services/auth';

const INITIAL_VALUES = {
  id: null,
  planPriceId: '',
  planCount: 1,
  email: '',
  name: '',
  document: '',
  otherName: '',
  emailAdmin: '',
  password: '',
  repeatPassword: '',
  confirmed: false,
  acceptTerms: false,
};

export default function Register() {
  const formRef = useRef<HTMLDivElement>();
  const { handleLogin, reloadUser } = useAuthContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const state = history.location?.state as { planPrice?: PlanPrice };
  const planPriceId = state?.planPrice?.priceId;
  const searchParams = new URLSearchParams(history.location.search);
  const code = searchParams.get('code') ?? '';
  const [{ query }, setQuery] = useQueryParams({
    type: '',
  });
  const [data, setData] = useSessionStorage(
    '@App:registerData',
    INITIAL_VALUES,
  );
  const [, setHasCountDown] = useState(false);
  const [referrer, setReferrer] = useState<string>();
  const steps = useMemo(
    () => [
      {
        label: 'Criar conta',
        stepNo: 1,
        status: false,
        componentWhenDone: <Check />,
      },
      {
        label: 'Informações essenciais',
        stepNo: 2,
        status: false,
        componentWhenDone: <Check />,
      },
      {
        label: 'Dados complementares',
        stepNo: 3,
        status: false,
        componentWhenDone: <Check />,
      },
      ...(data?.planPriceId || planPriceId
        ? [
            {
              label: 'Pagamento',
              stepNo: 4,
              status: false,
              componentWhenDone: <Check />,
            },
          ]
        : []),
    ],
    [data?.planPriceId, planPriceId],
  );
  const [currStep, setCurrStep] = useState(steps[0]);

  const { data: dataDemoRegister } = useQuery('demo', getUrlRedirectDemo);

  const { isLoading } = useQuery(
    'confirmEmail',
    () => validateEmail({ code }),
    {
      enabled: !!code,
      retry: false,
      onError: (error: any) => {
        if (error) {
          setCurrStep(steps[0]);
          dispatch(
            setBannerMessage({
              message: error?.response?.data?.error?.message,
              type: 'error',
              autoclose: true,
            }),
          );
          if (
            error?.response?.data?.error?.message.includes('Código inválido')
          ) {
            history.push('/planos');
          }
          setQuery({ code: null });
        }
      },
      onSuccess: async ({
        data: { user },
      }: {
        data: { user: UserValidateEmail };
      }) => {
        setData({
          ...data,
          email: user.email,
          planPriceId: user.planPriceId,
          planCount: user.planCount,
          confirmed: true,
        });
        setQuery({ type: user.userType, code: null });
      },
    },
  );

  useEffect(() => {
    setReferrer(localStorage.getItem('referrer') ?? '');
  }, []);

  useEffect(() => {
    if (data?.email && data?.confirmed) {
      handleEmailConfirmation(true);
    }

    if (data?.id) {
      handleRegister(true);
    }
  }, [data]);

  const checkoutMutation = useMutation('redirectCheckout', redirectCheckout, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noreferrer');
      handleUserLogin();
    },
  });

  const handleRegister = (status: boolean) => {
    if (status) {
      setCurrStep(steps[2]);
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleEmailConfirmation = (status: boolean) => {
    if (status) {
      if (referrer?.includes('uqbarday')) {
        setHasCountDown(true);
      }
      setCurrStep(steps[1]);
    }
  };

  const handleFinishedRegister = () => {
    if (!data?.planPriceId && !planPriceId) {
      sessionStorage.setItem(
        '@App:planDetails',
        JSON.stringify({
          plan: 'free',
          payment: 'success',
        }),
      );

      return handleUserLogin();
    }
    setCurrStep(steps[3]);
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePaymentClick = () => {
    checkoutMutation.mutate({
      priceId: data.planPriceId,
      quantity: data.planCount,
    });
  };

  const handleUserLogin = () => {
    const jwt = sessionStorage.getItem('@App:tempToken');
    if (jwt) {
      sessionStorage.setItem('@App:token', jwt);
      handleLogin(true);
      reloadUser();
      sessionStorage.removeItem('@App:registerData');
      sessionStorage.removeItem('@App:tempToken');
    }
  };

  const handleSetRegisterForm = (values: any) => {
    setData(values);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <ScrollToTop>
      <Box
        sx={{
          paddingTop: isMobile ? '130px' : '75px',
          backgroundColor: '#FFFFFF',
          position: 'relative',
        }}
        component="section"
        ref={formRef}>
        <Switch>
          <Route exact path={path}>
            <RegisterType setRegisterType={setQuery} />
          </Route>
          <Route exact path={`${path}/:step`}>
            <Container maxWidth="lg">
              <Box sx={{ marginTop: '90px' }}>
                <CheckStep steps={steps} currStep={currStep} />
                <Grid container>
                  <Grid item sm={12} md={12}>
                    <Box>
                      {/* Email confirmation step */}
                      <If condition={currStep?.stepNo === 1}>
                        <EmailConfirmationForm
                          email={data?.email}
                          userType={query.type}
                        />
                      </If>
                      {/* Identification step */}
                      <If condition={currStep?.stepNo === 2}>
                        <If condition={query.type === 'private_person'}>
                          <RegisterForm
                            data={data}
                            handleSetRegisterForm={handleSetRegisterForm}
                            registerType={query.type}
                          />
                        </If>
                        <If condition={query.type === 'legal_person'}>
                          <RegisterFormLegalPerson
                            data={data}
                            handleSetRegisterForm={handleSetRegisterForm}
                            registerType={query.type}
                          />
                        </If>
                      </If>
                      {/* Dados complementares step */}
                      <If condition={currStep?.stepNo === 3}>
                        <ComplementaryRegisterForm
                          registerType={query.type}
                          data={data}
                          handleFinished={handleFinishedRegister}
                        />
                      </If>
                      {/* Encerramento step */}
                      <If condition={currStep?.stepNo === 4}>
                        <Box
                          sx={{
                            padding: '130px 0',
                            maxWidth: 800,
                            margin: '0 auto',
                          }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Typography
                                fontSize={24}
                                fontWeight={700}
                                textAlign="center">
                                Etapa de cadastro concluída com sucesso! Para
                                ter acesso a todas as funcionalidades, efetue o
                                pagamento do seu plano.
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            xs={12}
                            alignItems="center"
                            justifyContent="center"
                            marginTop={9}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={handlePaymentClick}
                              disabled={checkoutMutation.isLoading}>
                              {checkoutMutation.isLoading ? (
                                <CircularProgress sx={{ color: '#FFFFFF' }} />
                              ) : (
                                'Efetuar pagamento'
                              )}
                            </Button>
                          </Grid>
                        </Box>
                      </If>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Route>
        </Switch>
      </Box>
    </ScrollToTop>
  );
}
