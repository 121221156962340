/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { styled } from '@material-ui/core/styles';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import WidgetsIcon from '@material-ui/icons/Widgets';

import { Container } from './styles';
import { DataContext } from '../../../../context/DataContext';
import AmortizationMenu from '../AmortizationMenu';
import InterestMenu from '../InterestMenu';
import IssuanceMenu from '../IssuanceMenu';
import SecurityMenu from '../SecurityMenu';

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon fontSize="small" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.type === 'dark' ? '#ffffffe' : '#fafafa',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: '#f1f1f1',
}));

const SideMenu = ({ formik }) => {
  const { security } = formik;
  const { setExpanded } = useContext(DataContext);

  const handleChangeMenu = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container>
      <div className="box-title">
        <WidgetsIcon />
        Definições
      </div>
      <SecurityMenu
        formik={security}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        AccordionDetails={AccordionDetails}
        handleChangeMenu={handleChangeMenu}
      />
      <IssuanceMenu
        formik={security}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        AccordionDetails={AccordionDetails}
        handleChangeMenu={handleChangeMenu}
      />
      <AmortizationMenu
        formik={security}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        AccordionDetails={AccordionDetails}
        handleChangeMenu={handleChangeMenu}
      />
      <InterestMenu
        formik={security}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        AccordionDetails={AccordionDetails}
        handleChangeMenu={handleChangeMenu}
      />
    </Container>
  );
};

export default SideMenu;
