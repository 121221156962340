import { useEffect, useState } from 'react';

import {
  Alert,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import uniq from 'lodash/uniq';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import { getFundsHistory } from 'src/services/operation';
import { FidcFundHistory } from 'src/types/operation';

import {
  Fund,
  SelectClassSerieValue,
  SelectFundAndSerie,
} from './SelectFundAndSerie';
import { notify } from '../Calculator/utils/handleNotify';

type ChartSerie = {
  name: string;
  data: number[];
  color: string;
};

type FlattenHistory = {
  cnpj: string;
  classSerie: string;
  color: string;
  data: number;
  competenceDate: string;
};

type SerieWithPeriod = {
  series: Record<string, ChartSerie>;
  periods: Record<string, { minDate: string; maxDate: string }>;
};

type FundSelected = Fund & {
  highlightColor: string;
  classSerie: string;
  histories: FidcFundHistory[];
};

type LocationState = {
  funds: Fund[];
};

type PeriodOption = number | null;

const PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 12,
  },
  {
    label: '24 Meses',
    value: 24,
  },
  {
    label: 'Tudo',
    value: null,
  },
];

const OperationProfitabilityComparison = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation<LocationState>();
  const { auth } = useAuthContext();

  const HIGHLIGHT_COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ];

  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.comparar_fundos;

  const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);
  const [incompleteFundAlertMessage, setIncompleteFundAlertMessage] =
    useState<string>('');
  const [isUpdatingSeries, setIsUpdatingSeries] = useState<boolean>(false);
  const [chartXAxis, setChartXAxis] = useState<string[]>([]);
  const [periodSelected, setPeriodSelected] = useState<PeriodOption>(12);
  const [fundsSelected, setFundsSelected] = useState<FundSelected[]>([]);

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('compareFunds'),
    {
      enabled: auth?.isLoading,
    },
  );

  useEffect(() => {
    const funds: FundSelected[] = location.state.funds.map((fund, index) => ({
      classSerie: '',
      histories: [],
      highlightColor: HIGHLIGHT_COLORS[index],
      ...fund,
    }));

    setFundsSelected(funds);
  }, [location.state.funds]);

  const fundSelectedKey = fundsSelected
    .map(({ cnpj, classSerie }) => cnpj + classSerie)
    .join(',');

  const { isLoading: isLoadingFundHistory } = useQuery(
    ['fund-histories', fundSelectedKey, periodSelected],
    () =>
      getFundsHistory({
        CNPJs: fundsSelected.map(({ cnpj }) => cnpj),
        period: periodSelected,
      }),
    {
      onSuccess: (histories) => {
        setFundsSelected((currentFunds) => {
          if (!currentFunds.length) return [];

          const updatedFunds = currentFunds.map((fund) => {
            const historiesFounded = histories.filter(
              (history) =>
                history.cnpj_fundo === fund.cnpj &&
                history.classe_serie === fund.classSerie,
            );

            if (historiesFounded) {
              return {
                ...fund,
                histories: historiesFounded,
              };
            }

            return fund;
          });

          handleUpdateSeries(updatedFunds);

          return updatedFunds;
        });
      },
      onError: () => notify('Erro ao buscar histórico do fundo', 'error'),
    },
  );

  const handleUpdateSeries = (funds: FundSelected[]) => {
    setIsUpdatingSeries(true);

    const classSeries = funds.map((fund) => fund.classSerie);

    if (classSeries.every((value) => value !== '')) {
      const histories = getValidHistories(funds);
      const validHistories = filterValidHistories(histories);

      const dateObjects = getDateObjects(validHistories);
      const minDate = getMinDate(dateObjects);
      const maxDate = getMaxDate(dateObjects);

      const allDates = generateAllDates(minDate, maxDate);

      const { series, periods } = buildSeriesWithPeriods(
        funds,
        validHistories,
        allDates,
      );

      const fundPeriodsMessage = Object.entries(periods)
        .map(([fundKey, period]) => {
          if (
            period.minDate === 'Sem dados' &&
            period.maxDate === 'Sem dados'
          ) {
            return `${fundKey} não possui dados disponíveis no período selecionado.`;
          } else {
            return `${fundKey} possui dados de ${period.minDate} a ${period.maxDate}`;
          }
        })
        .join('. ');

      setIncompleteFundAlertMessage(fundPeriodsMessage);

      updateChart(series, allDates);
      setIsUpdatingSeries(false);
    }
  };

  const getValidHistories = (funds: FundSelected[]): FlattenHistory[] => {
    return funds.flatMap((fund) =>
      fund.histories
        .filter((history) => history.classe_serie === fund.classSerie)
        .map((fundHistory) => ({
          cnpj: fund.cnpj,
          classSerie: fund.classSerie,
          color: fund.highlightColor,
          data: fundHistory.rentabilidade_mensal,
          competenceDate: fundHistory.data_competencia,
        })),
    );
  };

  const filterValidHistories = (
    histories: FlattenHistory[],
  ): FlattenHistory[] => {
    return histories.filter(
      (history) => history.competenceDate && !isValid(history.competenceDate),
    );
  };

  const getDateObjects = (validHistories: FlattenHistory[]): Date[] => {
    return validHistories.map((item) => new Date(item.competenceDate));
  };

  const getMinDate = (dateObjects: Date[]): Date => {
    return new Date(Math.min(...dateObjects.map((d) => d.getTime())));
  };

  const getMaxDate = (dateObjects: Date[]): Date => {
    return new Date(Math.max(...dateObjects.map((d) => d.getTime())));
  };

  const generateAllDates = (minDate: Date, maxDate: Date): string[] => {
    const allDates: string[] = [];
    const currentDate = new Date(minDate);

    while (currentDate <= maxDate) {
      allDates.push(format(currentDate, 'MM/yyyy', { locale: ptBR }));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return allDates;
  };

  const buildSeriesWithPeriods = (
    funds: FundSelected[],
    validHistories: FlattenHistory[],
    allDates: string[],
  ): SerieWithPeriod => {
    const series: Record<string, ChartSerie> = {};
    const periods: Record<
      string,
      { minDate: string | null; maxDate: string | null }
    > = {};

    funds.forEach((fund) => {
      const fundKey = `${fund.name}: ${fund.classSerie}`;

      validHistories.forEach((history) => {
        const historyDate = new Date(history.competenceDate);
        const formattedDate = format(historyDate, 'MM/yyyy', { locale: ptBR });

        if (
          history.cnpj === fund.cnpj &&
          history.classSerie === fund.classSerie
        ) {
          if (!series[fundKey]) {
            series[fundKey] = {
              name: fundKey,
              color: fund.highlightColor,
              data: new Array(allDates.length).fill(null),
            };
          }

          const index = allDates.indexOf(formattedDate);

          if (index !== -1) {
            series[fundKey].data[index] = history.data;

            if (!periods[fundKey]) {
              periods[fundKey] = {
                minDate: historyDate.toISOString(),
                maxDate: historyDate.toISOString(),
              };
            } else {
              const currentMinDate = new Date(periods[fundKey].minDate!);
              const currentMaxDate = new Date(periods[fundKey].maxDate!);

              if (historyDate < currentMinDate) {
                periods[fundKey].minDate = historyDate.toISOString();
              }

              if (historyDate > currentMaxDate) {
                periods[fundKey].maxDate = historyDate.toISOString();
              }
            }
          }
        }
      });

      if (!periods[fundKey]) {
        periods[fundKey] = { minDate: null, maxDate: null };
      }
    });

    const formattedPeriods = Object.entries(periods).reduce(
      (acc, [fundKey, period]) => {
        acc[fundKey] = {
          minDate: period.minDate
            ? format(new Date(period.minDate), 'MM/yyyy', { locale: ptBR })
            : 'Sem dados',
          maxDate: period.maxDate
            ? format(new Date(period.maxDate), 'MM/yyyy', { locale: ptBR })
            : 'Sem dados',
        };
        return acc;
      },
      {} as Record<string, { minDate: string; maxDate: string }>,
    );

    return { series, periods: formattedPeriods };
  };

  const updateChart = (
    series: Record<string, ChartSerie>,
    allDates: string[],
  ) => {
    const flattenedDates = uniq(allDates);

    setChartSeries(Object.values(series));
    setChartXAxis(flattenedDates);
  };

  const handleChangeClassSerie = async (
    value: SelectClassSerieValue,
    fundIndex: number,
  ) => {
    setFundsSelected((currentFunds) => {
      const currentFundsCopy = [...currentFunds];

      currentFundsCopy[fundIndex] = {
        ...currentFundsCopy[fundIndex],
        ...value,
        histories: [],
      };

      handleUpdateSeries(currentFundsCopy);

      return currentFundsCopy;
    });
  };

  const handleClickPeriod = async (period: PeriodOption) => {
    setPeriodSelected(period);
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(3, 2),
        },
        mx: 'auto',
      }}>
      {hasPermission ? (
        <Stack>
          <Stack gap={1} marginBottom={2}>
            <Typography variant="h4">Histórico de rentabilidade</Typography>
            <Divider />
          </Stack>
          <Stack flexDirection="column" gap={2}>
            {fundsSelected.map(
              ({ cnpj, name, highlightColor }, selectedFundIndex) => (
                <SelectFundAndSerie
                  key={cnpj}
                  label={`Fundo ${selectedFundIndex + 1}`}
                  color={highlightColor}
                  onSelectClassSerie={(value) =>
                    handleChangeClassSerie(value, selectedFundIndex)
                  }
                  value={{ cnpj, name }}
                />
              ),
            )}
          </Stack>
          <Stack>
            {incompleteFundAlertMessage && (
              <Stack mt={2}>
                <Alert severity="warning">
                  Atenção: {incompleteFundAlertMessage}.
                </Alert>
              </Stack>
            )}
            <Stack my={2}>
              <Alert severity="info">
                Os dados de rentabilidade das cotas de FIDC apresentados são
                provenientes dos informes mensais disponibilizados à Comissão de
                Valores Mobiliários (CVM). A Uqbar não se responsabiliza por
                eventuais divergências ou inconsistências nas informações
                fornecidas pelos administradores dos fundos.
              </Alert>
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
              {PERIOD_OPTIONS.map((option) => (
                <Typography
                  key={option.value}
                  sx={{
                    cursor: 'pointer',
                    textDecoration:
                      option.value === periodSelected ? 'underline' : 'none',
                  }}
                  variant="caption"
                  onClick={() =>
                    handleClickPeriod(option.value as PeriodOption)
                  }>
                  {option.label}
                </Typography>
              ))}
            </Stack>
            {!isUpdatingSeries && (
              <div
                style={{
                  filter: isLoadingFundHistory ? 'blur(5px)' : 'none',
                  pointerEvents: isLoadingFundHistory ? 'none' : 'all',
                }}>
                <ReactApexChart
                  series={chartSeries}
                  options={{
                    chart: {
                      height: 500,
                      type: 'line',
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                    },
                    title: {
                      text: '',
                      align: 'left',
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                      },
                    },
                    xaxis: {
                      categories: chartXAxis,
                    },
                  }}
                />
              </div>
            )}
          </Stack>
        </Stack>
      ) : (
        <CardDemonstration
          title={contentPayWallBanner?.profitability?.title}
          subTitle={contentPayWallBanner?.profitability?.subTitle}
          labelButton={contentPayWallBanner?.profitability?.labelButton}
          url={contentPayWallBanner?.profitability?.url}
        />
      )}
    </Paper>
  );
};

export default OperationProfitabilityComparison;
