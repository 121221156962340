import TabMui from '@mui/material/Tab';
import TabsMui from '@mui/material/Tabs';
import styled from 'styled-components';

export const Tab = styled(TabMui)`
  &.MuiTab-root {
    text-transform: none;
    color: #282828;

    &.Mui-selected {
      color: #282828;
    }
  }
`;

export const Tabs = styled(TabsMui)`
  &.MuiTabs-root {
    & > .MuiTabs-scroller {
      .MuiTabs-indicator {
        background-color: #333333;
      }
      .MuiTabs-flexContainer {
        border-bottom: 2px rgba(29, 29, 29, 0.28) solid;
      }
    }

    .Mui-selected {
      background-color: transparent;
    }
  }
`;
