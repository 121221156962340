import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 499px) {
    display: block;
    text-align: center;
  }
`;

export const Content = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  width: 1000px;
  height: 700px;

  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 700px;
    height: calc(700px * 0.7);
  }

  @media screen and (max-width: 499px) {
    width: 300px;
    height: calc(300px * 1.4 * 2);
    margin: 0 auto;
  }
`;

const SideWrapper = styled.div<{ bgColor?: string; imgURL?: string }>`
  display: inline-block;
  width: 50%;
  height: 100%;
  background-color: ${(props: any) => props.bgColor || '#FFFFFF'};
  vertical-align: top;
  background-image: ${(props: any) => `url(${props.imgURL ?? 'none'})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:nth-child(2) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  &:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  @media screen and (min-width: 500px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 499px) {
    width: 100%;
    height: 50%;
    overflow-y: unset;

    &:nth-child(2) {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      height: auto;
    }
  }
`;

export const Styled = {
  Wrapper,
  Content,
  SideWrapper,
};
