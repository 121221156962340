import { Dispatch, SetStateAction, UIEvent } from 'react';

import { Box, Theme, useMediaQuery } from '@mui/material';
import {
  endOfMonth,
  format,
  isValid,
  lastDayOfMonth,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import _ from 'lodash';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_FilterFns,
  MRT_Row,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { useAuthContext } from 'src/context/AuthContextProvider';
import Export from 'src/pages/AllOperations/Export';
import { cast } from 'src/pages/Yearbooks/presentation/utils';
import { getPlansWithPermission } from 'src/services/subscription';
import { Permissions } from 'src/types/user';
import { convertNumber } from 'src/utils/number';
import { buildNamesInline } from 'src/utils/string';

type Props = {
  columns: MRT_ColumnDef<any>[];
  columnFilters: MRT_ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
  sorting: MRT_SortingState;
  setSorting: Dispatch<SetStateAction<MRT_SortingState>>;
  columnFilterFns: MRT_ColumnFilterFnsState;
  setColumnFilterFns: Dispatch<SetStateAction<MRT_ColumnFilterFnsState>>;
  extraExportColumns: any;
  data: any;
  isLoading: boolean;
  indicator: string;
  operationName: string;
  market: string;
  hasBottom: boolean;
  permission: string;
  exportPermission: string;
  handlePrint?: () => void;
  defaultColumnSort?: string;
  handleScroll: (event: UIEvent<HTMLDivElement>) => void;
  totalCount: number;
};

export default function OperationInvestorsInfiniteTable({
  operationName,
  indicator,
  market,
  data,
  columns,
  columnFilters,
  setColumnFilters,
  sorting,
  setSorting,
  columnFilterFns,
  setColumnFilterFns,
  isLoading,
  handlePrint,
  extraExportColumns,
  hasBottom = true,
  permission,
  exportPermission,
  defaultColumnSort = 'dataCompetencia',
  handleScroll,
  totalCount,
}: Props) {
  const { auth } = useAuthContext();
  const lastUpdateAt = startOfWeek(new Date(), { weekStartsOn: 1 });
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const hasPermission =
    !!auth.user?.subscription?.plan?.permissions?.[
      cast<keyof Permissions>(permission)
    ];

  const { data: plansToUpgrade, isLoading: plansToUpgradeLoading } = useQuery(
    ['upgradedPlan', permission],
    () => getPlansWithPermission(permission),
    {
      enabled: !!permission && !hasPermission,
      retry: false,
    },
  );

  const fakeColumns: MRT_ColumnDef<any>[] = Array(isSmall ? 3 : 5)
    .fill({})
    .map((value, index) => ({
      accessorKey: `column${index + 1}`,
      header: `Coluna${index + 1}`,
    }));

  const fakeDataRow = fakeColumns.reduce((acc, column, index) => {
    return {
      ...acc,
      [column.accessorKey as string]: `Dado ${index + 1}`,
    };
  }, {});

  const fakeRows = Array(isSmall ? 5 : 10).fill(fakeDataRow);

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    filename: `${operationName}_${market}_${indicator}`,
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [...columns, ...extraExportColumns].map((c) => `"${c.header}"`),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const headerKeys = [...columns, ...extraExportColumns].map(
      (c) => c.accessorKey,
    ) as string[];
    csvExporter.generateCsv(
      rows.map((row) => {
        return {
          ...headerKeys.reduce((acc, key) => {
            const value = _.get(row.original, key, '');
            return {
              ...acc,
              [key]: _.isFinite(value) ? convertNumber(value) : value,
            };
          }, {}),
          operationName,
        };
      }),
    );
  };

  function normalizeFilteredValue(filter: any) {
    return function (
      row: any,
      id: string,
      filterValue: string | number,
      ...args: any
    ) {
      filterValue = parseValue(id, filterValue);
      return filter(row, id, filterValue, ...args);
    };
  }

  const normalizeFilteredRangeValue =
    (filter: any) =>
    (
      row: any,
      id: string,
      filterValue: [string | number, string | number],
      ...args: any
    ) => {
      const filterArray = filterValue.map((value) => parseValue(id, value));
      return filter(row, id, filterArray, ...args);
    };

  function parseValue(id: string, value: any) {
    if (!value) return value;
    if (id.includes('data') && isValid(value)) {
      return format(endOfMonth(value), 'yyyy-MM-dd');
    }
    return value;
  }

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    localization: {
      ...MRT_Localization_PT_BR,
      ...MaterialTableDefaultProps.localization,
      noRecordsToDisplay: ['cra', 'cri'].includes(market)
        ? 'Ops! Não encontramos investidores para esta operação'
        : 'Ops! Não encontramos investidores para este fundo',
    },
    columns: hasPermission ? columns : fakeColumns,
    initialState: {
      sorting: [
        {
          id: defaultColumnSort,
          desc: true,
        },
      ],
      showColumnFilters: true,
    },
    data: hasPermission ? data : fakeRows,
    filterFns: {
      ...MRT_FilterFns,
      between: normalizeFilteredRangeValue(MRT_FilterFns.between),
      betweenInclusive: normalizeFilteredRangeValue(
        MRT_FilterFns.betweenInclusive,
      ),
      equals: normalizeFilteredValue(MRT_FilterFns.equals),
      greaterThan: normalizeFilteredValue(MRT_FilterFns.greaterThan),
      greaterThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.greaterThanOrEqualTo,
      ),
      lessThan: normalizeFilteredValue(MRT_FilterFns.lessThan),
      lessThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.lessThanOrEqualTo,
      ),
    },
    muiTableContainerProps: {
      onScroll: handleScroll,
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    layoutMode: 'grid',
    enableRowVirtualization: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: true,
    enableColumnFilters: true,
    enablePagination: false,
    enableFilters: true,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    onColumnFiltersChange: (filters) => setColumnFilters(filters),
    onSortingChange: (sorting) => setSorting(sorting),
    onColumnFilterFnsChange: (columnFilterFns) =>
      setColumnFilterFns(columnFilterFns),
    muiBottomToolbarProps: hasBottom
      ? {
          sx: {
            border: '1px solid #E0E0E0',
            borderTop: 'none',
            minHeight: 'unset',
          },
        }
      : {},
    state: {
      isLoading,
      columnFilters,
      sorting,
      columnFilterFns,
      showColumnFilters: true,
      showProgressBars: isLoading,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadCellProps: {
      sx: {
        justifyContent: 'center',
        minHeight: '65px',
      },
    },
    muiFilterDatePickerProps: (props) => ({
      ...props,
      views: ['month', 'year'],
      format: 'MM/yyyy',
      openTo: 'month',
      maxDetail: 'year',
      minDetail: 'year',
      value: props.column.getFilterValue()
        ? lastDayOfMonth(new Date(props.column.getFilterValue() as string))
        : props.column.getFilterValue(),
      onChange: _.debounce((value) => {
        if (!value) {
          props.column.setFilterValue(undefined);
        } else {
          props.column.setFilterValue(lastDayOfMonth(value));
        }
      }, 1000),
    }),
    renderBottomToolbarCustomActions: hasBottom
      ? () => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '4px',
              width: '100%',
              justifyContent: data?.length > 1 ? 'space-between' : 'flex-end',
            }}>
            {data?.length > 1 && (
              <span
                style={{}}>{`Exibindo ${data?.length} de ${totalCount} linhas`}</span>
            )}
            <span style={{ fontWeight: 'bold' }}>
              {`Atualizado em: ${format(
                data?.[0]?.dataProcessamento
                  ? parseISO(data[0].dataProcessamento)
                  : lastUpdateAt,
                'dd/MM/yyyy',
              )}`}
            </span>
          </Box>
        )
      : undefined,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          width: '100%',
          alignItems: 'flex-start',
          marginLeft: '-7px',
        }}>
        <Box sx={{ display: 'flex' }}>
          <Export
            handleExport={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            handlePrint={handlePrint}
            permission={exportPermission}
            hasData={!!data?.length}
            pdfEnabled={!!handlePrint}
          />
        </Box>
      </Box>
    ),
  });

  return (
    <Box py={2} aria-roledescription={permission}>
      <Box
        sx={
          !hasPermission
            ? {
                '-webkit-filter': 'blur(5px)',
                pointerEvents: 'none',
              }
            : {}
        }>
        <MaterialTable table={table} />
      </Box>
      {!hasPermission && !plansToUpgradeLoading && (
        <Box position="relative">
          <Box
            sx={(theme) => ({
              width: '100%',
              position: 'absolute',
              top: -450,
              left: 0,
              [theme.breakpoints.down('md')]: {
                top: -300,
              },
              [theme.breakpoints.down('sm')]: {
                top: -400,
              },
            })}>
            <CardDemonstration
              title={`Recurso disponível apenas no(s) plano ${buildNamesInline(plansToUpgrade?.data)}`}
              subTitle={
                'Faça um upgrade em sua assinatura para ter acesso à movimentações e posições dos investidores.'
              }
              labelButton={'Fazer upgrade'}
              url={'/planos'}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
