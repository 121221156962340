import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Profitability } from 'src/types/profitability';
import { formatDate } from 'src/utils/date';
import { convertNumber, handleSetCurrency } from 'src/utils/number';

export function TooltipContent({ data }: { data: Profitability | undefined }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const InfoRow = ({
    label,
    value,
  }: {
    label: string;
    value: string | number | undefined;
  }) => (
    <Stack
      direction={['column', 'row']}
      spacing={[0, 1]}
      alignItems={['flex-start', 'center']}
      justifyContent={['flex-start', 'space-between']}
      gap={[0, 1]}>
      <Typography variant="subtitle2" fontWeight="bold">
        {label}:
      </Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </Stack>
  );

  return (
    <Paper
      elevation={2}
      sx={{ maxWidth: isMobile ? '100%' : 300, width: '100%' }}>
      <Box sx={{ p: 2 }}>
        <InfoRow label="Código Cetip" value={data?.codigo} />
        <Divider sx={{ my: 1 }} />
        {data?.operacao && <InfoRow label="Operação" value={data.operacao} />}
        <InfoRow label="Mercado" value={data?.mercado} />
        <InfoRow label="Rentabilidade" value={data?.metodo} />
        <InfoRow
          label="PU (Preço unitário)"
          value={handleSetCurrency(data?.preco_unitario ?? 0)}
        />
        <InfoRow
          label="PU acrescido de juros"
          value={handleSetCurrency(data?.preco_unitario_acrescido_juros ?? 0)}
        />
        <InfoRow label="Taxa" value={convertNumber(data?.taxa, 2, 2)} />
        <InfoRow label="Duration" value={convertNumber(data?.duration, 2, 2)} />
        <InfoRow label="Data" value={formatDate(data?.data)} />
      </Box>
    </Paper>
  );
}
