import { useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import qs from 'qs';
import { useQuery } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { findSummaryRanking } from '../../../services/ranking';
import { useQueryParams } from '../../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: '26.63px',
    marginTop: theme.spacing(5),
  },
  titleCritiria: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24.2px',
    marginBottom: theme.spacing(3),
  },
  text: {
    fontSize: '1rem',
    lineHeight: '19.36px',
    fontWeight: 500,
    color: '#6D6E71',
  },
  button: {
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '16.94px',
    padding: 0,
    marginTop: theme.spacing(3),
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  groupedButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > a': {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      width: '64%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
      width: '46%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '36%',
    },
  },
}));

function SummuryRanking({ state, setState, rankings, filters }) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [{ rawQuery }] = useQueryParams(state);
  const { data, isLoading, refetch } = useQuery(
    ['summaryRanking', state.market],
    () => findSummaryRanking(state.market.toLowerCase()),
    { enabled: !!state.market },
  );

  useEffect(() => {
    refetch();
  }, [state.market]);

  useEffect(() => {
    if (filters) {
      setState({
        ...state,
        year: filters[state.market]?.years[0],
      });
    }
  }, [state]);

  const findCriteria = (code) => {
    if (rankings?.length) {
      return rankings?.find((ranking) => ranking?.code === code)?.criterias[0]
        ?.code;
    }
  };

  const handleClick = (event, ranking) => {
    event.preventDefault();
    const search = qs.stringify({
      ...state,
      ranking: ranking,
      criteria: findCriteria(ranking),
      timeRange: 'yearly',
    });
    history.push({ pathname: `${url}/dados`, search });
  };

  if (isLoading) return <CircularProgress color="secondary" />;

  const getLink = (ranking) => {
    return `/rankings/dados${rawQuery}&ranking=${ranking}&criteria=${findCriteria(
      ranking,
    )}&timeRange=yearly`;
  };

  const filteredRanking = Object.entries(
    data[state.market.toLowerCase()],
  ).filter((test) => test[0] !== 'id');

  return (
    <Box id="rankings-summary" py={4}>
      <Typography className={classes.text}>
        Os rankings Uqbar contemplam quatro mercados e +80 rankings de
        participantes do mercado, fundos e certificados.
      </Typography>
      <Box className={classes.groupedButton}>
        <Button
          component={Link}
          href={data?.button1?.link}
          target="_blank"
          variant="contained"
          color="secondary"
          className={classes.link}>
          {data?.button1?.text}
        </Button>
        <Button
          component={RouterLink}
          to={'/rankings/como-participar'}
          variant="outlined"
          color="secondary"
          className={classes.link}>
          Como participar
        </Button>
      </Box>
      <Typography
        className={
          classes.title
        }>{`Os rankings disponíveis para o mercado ${state.market}`}</Typography>
      {filteredRanking.map((ranking, index) => (
        <Box key={ranking[1]?.id}>
          <Box py={4}>
            <Typography className={classes.titleCritiria}>
              {ranking[1]?.name}
            </Typography>
            <Typography className={classes.text}>
              {ranking[1]?.description}
            </Typography>
            <Button
              component={RouterLink}
              to={getLink(ranking[0])}
              onClick={(e) => handleClick(e, ranking[0])}
              className={classes.button}
              variant="text"
              color="secondary">
              {`Acesse os rankings Uqbar de ${ranking[1]?.name}`}
            </Button>
          </Box>
          {index < filteredRanking.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
}

export default SummuryRanking;
