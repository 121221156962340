import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMutation } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import SubscribeToEventDialog from 'src/components/SubscribeToEventDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { subscribeToEvent } from 'src/services/events';
import { Event } from 'src/types/event';

import { CustomTooltip } from '../CustomTooltip';
import { FavoriteRowButton } from '../FavoriteRowButton';
import { If } from '../If';
import SubscribeToEventParticipantNameDialog from '../SubscribeToEventParticipantNameDialog';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'none',
  minWidth: 'unset',
  width: 'fit-content',
  height: '23px',
  color: '#47484C',
  backgroundColor: '#D9D9D9',
  paddingInline: '16px',
  borderRadius: '30px',
  textTransform: 'none',
  letterSpacing: 'unset',
  fontSize: '12px',
  fontWeight: 600,
  position: 'absolute',
  inset: '12px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
    backgroundColor: '#D9D9D9 !important',
    '&:hover': {
      color: '#00000042',
    },
  },
}));

function CardEvent({
  event,
  setEvents,
  refetchEvents,
}: {
  event: Event;
  setEvents: Dispatch<SetStateAction<Event[]>>;
  refetchEvents: () => void;
}) {
  const { auth } = useAuthContext();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const isEventHapenning = event.state === 'ongoing';
  const hasTooltip = event.state !== 'ended' && event.isUqbar;
  const eventUrl = `/evento/${event.slug}/${event.id}`;

  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => refetchEvents(),
  });

  const handleAddFavorite = (rowId: number, favorite: any) => {
    setEvents((prev: Event[]) => {
      return prev.map((item) => {
        if (item.id === rowId) {
          return { ...item, favorite };
        }
        return item;
      });
    });
    window.scrollTo(0, scrollPosition);
  };

  const handleRemoveFavorite = (rowId: number) => {
    setEvents((prev: Event[]) => {
      return prev.map((item) => {
        if (item.id === rowId) {
          return { ...item, favorite: undefined };
        }
        return item;
      });
    });
    window.scrollTo(0, scrollPosition);
  };

  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', updateScrollPosition);

    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);

  const handleSubscribeClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (event.hasPermission) {
      if (event.termsOfUseLink) {
        history.push(eventUrl);
      } else {
        subscribeToEventMutation.mutate({ id: event.id });
      }
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubscribe = async () => {
    refetchEvents();
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        elevation={0}
        sx={{
          containerType: 'inline-size',
          height: '100%',
          borderRadius: '5px',
          backgroundColor: '#f5f5f5',
          '&:hover': {
            '.MuiCardMedia-root button': {
              display:
                event?.subscribed || event?.isSubscriptionOpen
                  ? 'inline-flex'
                  : 'none',
            },
          },
        }}>
        <CardActionArea
          component={RouterLink}
          to={eventUrl}
          sx={{ height: '100%', '&:active': { color: 'transparent' } }}
          disableRipple>
          <Box position={'relative'}>
            <CardMedia
              image={event?.image?.url}
              title={event?.title}
              sx={{
                height: '117px',
                borderRadius: '6px',
                overflow: 'hidden',
                display: 'flex',
              }}>
              <If condition={hasTooltip}>
                {event.subscribed ? (
                  <CustomButton
                    sx={(theme) => ({
                      display: 'inline-flex',
                      backgroundColor: theme.palette.primary.main,
                      color: '#FFF',
                    })}
                    startIcon={
                      <CheckCircleIcon
                        color="inherit"
                        sx={{ fontSize: '14px !important' }}
                      />
                    }
                    onClick={() => null}>
                    Inscrito
                  </CustomButton>
                ) : event.allowSubscriptions ? (
                  <CustomTooltip
                    title={
                      !auth?.isLoggedIn
                        ? 'Faça login para acessar esta funcionalidade'
                        : ''
                    }
                    placement="right">
                    <CustomButton
                      startIcon={
                        <CreateIcon
                          color="inherit"
                          sx={{ fontSize: '14px !important' }}
                        />
                      }
                      onClick={handleSubscribeClick}
                      aria-disabled={!auth?.isLoggedIn}
                      disabled={
                        !auth?.isLoggedIn || subscribeToEventMutation.isLoading
                      }>
                      Inscrever-se
                    </CustomButton>
                  </CustomTooltip>
                ) : null}
              </If>
              <If condition={isEventHapenning}>
                <Box
                  width={'100%'}
                  height={30}
                  bgcolor={'#00D3A4'}
                  display={'flex'}
                  zIndex={1}
                  sx={{
                    placeContent: 'center',
                    placeItems: 'center',
                    placeSelf: 'end',
                  }}>
                  <Typography fontSize={12} fontWeight={600} color={'#FFF'}>
                    Acontecendo agora
                  </Typography>
                </Box>
              </If>
            </CardMedia>
          </Box>
          <CardContent
            sx={{
              backgroundColor: '#F5F5F5',
              padding: '21px 11px 25px 24px',
              height: '100%',
            }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Typography
                color={'#FF8211'}
                fontWeight={700}
                fontSize={'clamp(12px, 4.5cqw, 14px)'}>
                {event.isPreview ? (
                  <>
                    Em{' '}
                    <span style={{ textTransform: 'capitalize' }}>
                      {format(parseISO(event.minStartDate), ' MMMM / yyyy', {
                        locale: ptBR,
                      })}
                    </span>
                  </>
                ) : (
                  <>
                    {event.schedules.length > 1 ? 'Começa ' : 'Em '}
                    {format(parseISO(event.minStartDate), 'dd/MM/yy', {
                      locale: ptBR,
                    })}{' '}
                    a partir das{' '}
                    {format(parseISO(event.minStartDate), 'HH:mm', {
                      locale: ptBR,
                    })}
                  </>
                )}
              </Typography>
              <Box
                mt={'-4px'}
                onClick={() => setScrollPosition(window.scrollY)}>
                <FavoriteRowButton
                  type="event"
                  rowId={event.id}
                  favoriteId={event.favorite?.id}
                  handleAddFavorite={handleAddFavorite}
                  handleRemoveFavorite={handleRemoveFavorite}
                />
              </Box>
            </Stack>
            <Typography
              color={'#47484C'}
              fontWeight={700}
              fontSize={'clamp(16px, 5.6cqw, 18px)'}
              pt={'6px'}>
              {event.title}
            </Typography>
            <Typography
              color={'#4F4F4F'}
              fontSize={'clamp(12px, 4.5cqw, 14px)'}>
              {event.format}
            </Typography>
          </CardContent>
        </CardActionArea>
        {event.hasPermission && auth?.user?.type === 'legal_person' ? (
          <SubscribeToEventParticipantNameDialog
            isOpen={openDialog}
            handleClose={handleCloseDialog}
            handleSuccess={handleSubscribe}
            event={event}
          />
        ) : (
          <SubscribeToEventDialog
            isOpen={openDialog}
            handleClose={handleCloseDialog}
            handleSuccess={handleSubscribe}
            event={event}
          />
        )}
      </Card>
    </Grid>
  );
}

export default CardEvent;
