import { useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { ConfirmDialog } from 'src/components/ConfimDialog';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import { Image, Institution } from 'src/types/institution';
import { getSmallestImageUrl } from 'src/utils/image';
import { formatBytes } from 'src/utils/number';
import { truncateText } from 'src/utils/string';

import PhotosModal from '../PhotosModal';

const PhotosList = ({
  institution,
  handleRemoveImage,
  institutionRefetch,
}: {
  institution?: Institution;
  handleRemoveImage: (values: Image) => void;
  institutionRefetch: () => void;
}) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<Image | undefined>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenDialog = (photo: Image) => {
    setSelectedPhoto(photo);
    setOpenDialog(true);
  };

  const handleEdit = (photo: Image) => {
    setSelectedPhoto(photo);
    setOpenEdit(true);
  };

  const handleRemove = () => {
    if (selectedPhoto?.id && institution) {
      handleRemoveImage(selectedPhoto);
      setOpenDialog(false);
      setSelectedPhoto(undefined);
    }
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'image.name',
        header: 'Nome do arquivo',
        size: 50,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: { cursor: 'pointer' },
          onClick: () => {
            setSelectedPhoto(cell.row.original);
            setOpenPreviewDialog(true);
          },
        }),
        Cell: ({ cell }: { cell: any }) => (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box
              style={{
                display: 'flex',
                width: 42,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                src={getSmallestImageUrl(cell.row.original.image)}
                alt={cell.row.original.image.alternativeText}
                style={{ borderRadius: '2px', maxWidth: 42, maxHeight: 42 }}
              />
            </Box>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={600}
                color={'secondary'}
                sx={(theme) => ({
                  '&:hover': { color: theme.palette.primary.main },
                })}>
                {truncateText(cell.row.original.image.name, 50)}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={'#828282'}>
                {formatBytes(cell.row.original.image.size, true)}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'uploadDate',
        header: 'Data de upload',
        size: 40,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        sortingFn: (rowA: any, rowB: any): number => {
          const dateA = new Date(rowA.original.createdAt);
          const dateB = new Date(rowB.original.createdAt);
          return dateB == dateA ? 0 : dateB < dateA ? 1 : -1;
        },
        Cell: ({ cell }: { cell: any }) => (
          <Typography fontSize={14} fontWeight={400} color={'#4F4F4F'}>
            {format(new Date(cell.row.original.createdAt), 'dd/MM/yyyy')}
          </Typography>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Ações',
        size: 10,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => (
          <Box>
            <Button
              startIcon={<EditIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
              }}
              onClick={() => handleEdit(cell.row.original)}>
              Editar
            </Button>
            <Button
              startIcon={<CancelIcon />}
              size="small"
              sx={{
                color: '#4F4F4F',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                justifyContent: 'flex-start',
                letterSpacing: 'normal',
                textWrap: 'nowrap',
              }}
              onClick={() => handleOpenDialog(cell.row.original)}>
              Excluir arquivo
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    columns,
    data: institution?.galleryImages ?? [],
    localization: MRT_Localization_PT_BR,
    muiTableContainerProps: {
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
      }),
    },
    initialState: {
      sorting: [
        {
          id: 'uploadDate',
          desc: false,
        },
      ],
    },
    enableStickyHeader: true,
    enableSorting: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
    enableColumnFilterModes: false,
    enablePagination: false,
    enableFilters: false,
    enablePinning: false,
    enableRowActions: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        padding: '16px 0 40px',
      },
    },
  });

  return (
    <>
      <MaterialTable table={table} />
      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        maxWidth={false}
        sx={{
          maxWidth: 1164,
          margin: '0 auto',
          '& .MuiPaper-root': { maxWidth: '100%' },
        }}>
        <DialogTitle>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}>
            <IconButton onClick={() => setOpenPreviewDialog(false)}>
              <Close sx={{ color: 'black' }} fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedPhoto?.image.url}
            loading="lazy"
            style={{ maxWidth: '100%' }}
          />
          <Typography fontSize={18} fontWeight={400}>
            {selectedPhoto?.description}
          </Typography>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title={`Tem certeza que deseja remover a imagem “${selectedPhoto?.image.name}”?`}
        subTitle="Ao remover a imagem, as informações serão deletadas e caso deseje inclui-lás novamente será necessário adicionar uma nova imagem no perfil institucional."
        submitAction={handleRemove}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasActionButton
      />
      <PhotosModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        institutionId={institution?.id}
        selectedImage={selectedPhoto}
        institutionRefetch={institutionRefetch}
      />
    </>
  );
};

export default PhotosList;
