import styled from 'styled-components';

interface WrapperProps {
  blur?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${(props) => (props.blur ? '#ff8211' : 'inherit')};
`;

export const PayWallBannerWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;

  .MuiPaper-root {
    max-width: 1000px;
    height: 320px;

    .MuiGrid-root {
      height: 100%;
      justify-content: center;
      .MuiGrid-container {
        height: 100%;
      }
    }
  }
`;
