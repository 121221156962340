import { useEffect, useState } from 'react';

// Typechecking
// Declarative routing
// Material ui components
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { featuredSearchs, searchHit } from 'src/services/search';

import FeaturedSearchsMenu from './FeaturedSearchsMenu';

export default function SearchBarAutocomplete({
  searchType,
  placeholder,
  data,
  loading,
  searchTerm,
  filterOptions,
  setInputValue,
  onHandleChange,
  renderInput,
  label,
  value,
  classes,
  className,
  disabled,
}) {
  const [options, setOptions] = useState([]);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { data: featuredSearchsData, refetch } = useQuery(
    ['featuredSearchs'],
    featuredSearchs,
  );

  const searchHitMutation = useMutation(searchHit, {
    onSuccess: () => {
      refetch();
    },
  });

  const handleSearchHit = (name, slug, hashId, market, sub) => {
    searchHitMutation.mutate({
      name,
      slug,
      modelId: parseInt(hashId, 10),
      type: sub,
      market,
    });
  };

  useEffect(() => {
    if (data) {
      if (searchType === 'consultant') {
        const options = data?.map((row) => ({ name: row, sub: searchType }));
        setOptions(options);
      } else {
        for (const sub in data) {
          if (data[sub].length !== 0) {
            for (const key in data[sub]) {
              const {
                id,
                hashId,
                cnpj,
                name,
                slug,
                operationTicker,
                operationCodeCetipIF,
                operationCodeISIN,
                market,
                collection,
                type,
              } = data[sub][key];
              setOptions((state) => [
                ...state,
                {
                  id,
                  hashId,
                  cnpj,
                  name,
                  slug,
                  operationTicker,
                  operationCodeCetipIF,
                  operationCodeISIN,
                  market,
                  sub: sub.slice(0, -1),
                  collection,
                  type,
                },
              ]);
            }
          }
        }
      }
    }
    return () => {
      setOptions([]);
    };
  }, [data, searchType]);

  function onChange(name, slug, hashId, market, sub, collection, type) {
    let newLocation = `/${sub}/${slug}/${hashId}`;
    setOpen(false);
    setInputValue(name);
    handleSearchHit(name, slug, hashId, market, sub);
    if (market) newLocation = `/${sub}/${market}/${slug}/${hashId}`;
    else {
      if (sub === 'event') newLocation = `/evento/${slug}/${hashId}`;
      if (sub === 'video')
        newLocation = `/video/${collection}/${slug}/${hashId}`;
      if (sub === 'marketplace') {
        newLocation = `/marketplace/${type === 'Serviço' ? 'servico' : 'projeto'}/${slug}/${hashId}`;
      }
    }
    history.push(newLocation);
  }

  function handleSubheader(option) {
    if (option.sub === 'institution') return 'Instituição';
    if (option.sub === 'professional') return 'Profissional';
    if (option.sub === 'operation')
      return `Operação - ${option.market.toUpperCase()}`;
    if (option.sub === 'article') return 'Artigos';
    if (option.sub === 'event') return 'Eventos';
    if (option.sub === 'video') return 'Videos';
    if (option.sub === 'marketplace') return 'Marketplace';
    if (option.sub === 'training') return 'Treinamento';
    return 'Outros';
  }

  const defaultFilterOptions = createFilterOptions({
    stringify: ({
      cnpj,
      name,
      operationCodeCetipIF,
      operationTicker,
      operationCodeISIN,
    }) => {
      return `${cnpj} ${name} ${operationCodeCetipIF} ${operationTicker} ${operationCodeISIN}`;
    },
  });

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      fullWidth
      blurOnSelect
      className={className ?? classes?.autoComplete}
      classes={{
        inputRoot: classes?.inputRoot,
        option: classes?.option,
        listBox: classes?.listBox,
      }}
      id="pesquisa"
      loadingText="Carregando..."
      noOptionsText={
        !searchTerm ? (
          <FeaturedSearchsMenu data={featuredSearchsData} onChange={onChange} />
        ) : (
          'Nenhuma informação encontrada. Deseja refinar sua busca?'
        )
      }
      loading={loading}
      value={searchTerm.name || value}
      popupIcon={null}
      onChange={(_, newValue) => {
        setInputValue('');
        if (onHandleChange) {
          onHandleChange(newValue);
        } else if (newValue) {
          onChange(
            newValue.name,
            newValue.slug,
            newValue.hashId || newValue.id,
            newValue.market,
            newValue.sub,
            newValue.collection,
          );
        }
      }}
      inputValue={searchTerm}
      renderOption={(option) =>
        (searchType === 'CNPJ'
          ? option.cnpj
          : option.sub === 'operation'
            ? `${option.name}`
            : option.name) ?? option
      }
      onInputChange={(_, newInputValue) => {
        if (!open) {
          setInputValue(newInputValue || searchTerm);
          return;
        }
        setInputValue(newInputValue);
      }}
      options={options || []}
      filterOptions={filterOptions ?? defaultFilterOptions}
      groupBy={(option) => handleSubheader(option)}
      getOptionLabel={(option) =>
        option && `${searchType === 'CNPJ' ? option.cnpj : option.name}`
      }
      getOptionSelected={(option, value) => option?.cnpj === value?.cnpj}
      renderGroup={(params) => (
        <Box key={params.key} sx={{ padding: '6px 24px' }}>
          {params.key === 0 && (
            <Typography
              sx={{
                color: '#4F4F4F',
                fontSize: '12px',
                fontWeight: 600,
                marginBottom: '8px',
              }}>
              Resultados da busca
            </Typography>
          )}
          {params.children}
          <Box marginBottom={1}>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={'#BDBDBD'}
              textAlign={'end'}>
              {params.group}
            </Typography>
            <Divider sx={{ margin: '4px 0' }} />
          </Box>
        </Box>
      )}
      renderInput={
        renderInput ??
        ((params) => (
          <TextField
            {...params}
            variant="outlined"
            aria-label="pesquisa"
            label={label ?? ''}
            placeholder={
              placeholder ??
              'Busque por instituições, profissionais, eventos e operações financeiras.'
            }
          />
        ))
      }
    />
  );
}

SearchBarAutocomplete.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  searchTerm: PropTypes.string,
  setInputValue: PropTypes.func,
};
