import { Box } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { ptBR } from '@mui/x-date-pickers/locales';
import {
  endOfMonth,
  getDate,
  isSameDay,
  isToday,
  startOfMonth,
} from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

function buildDays(
  props: PickersDayProps<Date> & { highlightedDays?: number[] },
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some((hDay) => isSameDay(hDay, day));

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disableHighlightToday
      selected={isSelected}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'100%'}>
        {getDate(day)}
        {isToday(day) && <span>●</span>}
      </Box>
    </PickersDay>
  );
}
type Props = {
  highlightedDays: Date[];
  referenceDate: Date;
};
export default function DateCalendarServerRequest({
  highlightedDays,
  referenceDate,
}: Props) {
  return (
    <LocalizationProvider
      adapterLocale={ptBr}
      dateAdapter={AdapterDateFns}
      localeText={
        ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      }>
      <DateCalendar
        value={referenceDate}
        readOnly
        dayOfWeekFormatter={(day) => day}
        minDate={startOfMonth(referenceDate)}
        maxDate={endOfMonth(referenceDate)}
        reduceAnimations
        referenceDate={referenceDate}
        slots={{
          day: buildDays,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
        views={['day']}
        sx={(theme) => ({
          '&.MuiDateCalendar-root': {
            width: '100%',
            height: 'unset',
            maxHeight: 'unset',
          },
          '.MuiPickersArrowSwitcher-root': {
            display: 'none',
          },
          '.MuiDayCalendar-weekDayLabel': {
            width: theme.spacing(6),
            fontWeight: 'bolder',
          },
          '.MuiPickersDay-today': {
            border: 'unset !important',
            color: theme.palette.text.primary,
          },
          '.MuiPickersDay-root': {
            color: theme.palette.text.secondary,
            borderRadius: theme.spacing(1),
            width: theme.spacing(6),
            height: theme.spacing(6),
            backgroundColor: theme.palette.grey.A200,
            alignItems: 'start',
            fontWeight: 'bolder',
          },
          '.MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 700,
          },
          '.MuiPickersCalendarHeader-root': {
            paddingLeft: 1.5,
          },
        })}
      />
    </LocalizationProvider>
  );
}
